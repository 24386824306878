import { Component, ViewChild, TemplateRef, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray, Validators, ValidatorFn, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { Clipboard } from '@angular/cdk/clipboard';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { appState } from '@services/app-state';

import { TableColumn } from '@noammazuz/vzn-data-table';

import { TranslateService } from '@ngx-translate/core';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';

// import { environment } from '@environments/environment';

@Component({
  templateUrl: "./logtable.component.html"
})
export class LogtableComponent {

  @ViewChild("tableEl") tableEl: any;

  @ViewChild("stateTemplate") stateTemplate: TemplateRef<any>;
  // @ViewChild("followTemplate") followTemplate: TemplateRef<any>;
  // @ViewChild("pushDialogTemplate") pushDialogTemplate: TemplateRef<any>;

  @ViewChild("dateTimezoneTmpl") dateTimezoneTmpl: TemplateRef<any>;
  @ViewChild("dateTimezoneFullTmpl") dateTimezoneFullTmpl: TemplateRef<any>;
  
  @ViewChild("checkboxHdrTmpl") checkboxHdrTmpl: TemplateRef<any>;

  @ViewChild("quizDaysTmpl") quizDaysTmpl: TemplateRef<any>;
  @ViewChild("checkTmpl") checkTmpl: TemplateRef<any>;
  @ViewChild("checkboxTmpl") checkboxTmpl: TemplateRef<any>;
  @ViewChild("lastNtqTmpl") lastNtqTmpl: TemplateRef<any>;
  // @ViewChild("isFollowTmpl") isFollowTmpl: TemplateRef<any>;
  @ViewChild("totalReportsTmpl") totalReportsTmpl: TemplateRef<any>;
  @ViewChild("foodDaysTmpl") foodDaysTmpl: TemplateRef<any>;
  @ViewChild("bloodDaysTmpl") bloodDaysTmpl: TemplateRef<any>;
  @ViewChild("contentHoverTmpl") contentHoverTmpl: TemplateRef<any>;

  @ViewChild('pushTitle') pushTitle: ElementRef<HTMLInputElement>;
  @ViewChild('pushContent') pushContent: ElementRef<HTMLTextAreaElement>;

  appState = appState;
  logRestService = null;
  columns: Array<{}> = [];
  query: any;

  allClubsOptions: any[] = [];

  busy = false;

  chosenIds: string[] = [];
  chosenLogs: any[] = [];

  someChosen = false;
  allChosen = false;

  allNotesUnfiltered = [];

  // typeOptions = [
  //   { viewValue: 'ספורטאי', value: 'player' },
  //   { viewValue: 'הורה', value: 'parent' },
  //   { viewValue: 'תזונאי', value: 'nutri' },
  //   { viewValue: 'מאמן', value: 'coach' },
  //   { viewValue: 'מנהל', value: 'admin' },
  // ];

  userTypes = {
    'player': 'ספורטאי/ת',
    'parent': 'הורה',
    'nutri': 'תזונאי',
    'coach': 'מאמן',
    'admin': 'מנהל',
  }

  typeNameOptions = [];

  optionsLogType = [
    // { viewValue: 'פוש', value: 'push' },
    { viewValue: 'פוש נשלח', value: 'push-1' },
    { viewValue: 'פוש לא נשלח', value: 'push-0' },
    { viewValue: 'וואטסאפ נשלח', value: 'wap-1' },
    { viewValue: 'וואטסאפ לא נשלח', value: 'wap-0' },
  ];
  
  foodDaysOptions = [
    { viewValue: '0', value: '0' },
    { viewValue: '1', value: '1' },
    { viewValue: '2', value: '2' },
    { viewValue: '+3', value: '3' },
  ];

  totalReportsOptions = [
    { viewValue: 'נותרו דוחות', value: '-1' },
    { viewValue: '0', value: '0' },
    { viewValue: '1', value: '1' },
    { viewValue: '2', value: '2' },
    { viewValue: '+3', value: '3' },
  ];

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private dialogService: ConfirmDialogService,
    private restApiService: RestApiService<any>,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private http: HttpClient,
    private clipboard: Clipboard
  ) {
    this.logRestService = this.restApiService.service('msg-log');
  }

  async ngOnInit() {
    // await this.fetchNotes();
  }

  async ngAfterViewInit() {
    const clubs = await this.restApiService.service('club').find({
      query: {
        $paginate: false,
        $select: ['_id', 'clb_name'],
        $sort: {
          clb_name: 1
        },
        clb_is_deleted: {
          $ne: true
        },
      }
    });
    this.allClubsOptions = clubs.map((club: any) => {
      return { value: club._id, viewValue: club.clb_name };
    });
    this.typeNameOptions = this.getTypeNameOptions();
    this.columns = this.createColumns();
    this.query = {
      is_deleted: {
        $ne: true
      },
      $sort: {
        // updatedAt: -1
        createdAt: -1
      },
    };
    this.changeDetectorRef.detectChanges();
  }

  getTypeNameOptions() {
    let typeNameOptions: any[];
    // if (this.appState.user.usr_type === 'superadmin') {
      typeNameOptions = [
        { viewValue: 'ספורטאי', value: 'ספורטאי' },
        { viewValue: 'ספורטאית', value: 'ספורטאית' },
        { viewValue: 'ספורטאי/ת', value: 'ספורטאי/ת' },
        { viewValue: 'הורה', value: 'הורה' },
        { viewValue: 'תזונאי', value: 'תזונאי' },
        { viewValue: 'מאמן', value: 'מאמן' },
        { viewValue: 'מנהל', value: 'מנהל' }
      ];
    // } else {
    //   typeNameOptions = [
    //     { viewValue: 'ספורטאי', value: 'ספורטאי' },
    //     { viewValue: 'ספורטאית', value: 'ספורטאית' },
    //     { viewValue: 'ספורטאי/ת', value: 'ספורטאי/ת' },
    //     { viewValue: 'הורה', value: 'הורה' },
    //     // { viewValue: 'תזונאי', value: 'תזונאי' },
    //     { viewValue: 'מאמן', value: 'מאמן' },
    //     { viewValue: 'מנהל', value: 'מנהל' }
    //   ];
    // }
    return typeNameOptions;
  }

  createColumns() {
    // let columns: Array<TableColumn>;
    let columns: Array<any>;

    if (this.appState.user.usr_type === 'superadmin') {
      columns = [
        // { prop: 'choose_box', name: '', customTemplate: this.checkboxTmpl, maxWidth: 70, headerTemplate: 'boolHdTmpl' },
        { prop: 'choose_box', name: '', customTemplate: this.checkboxTmpl, maxWidth: 70, customHdrTemplate: this.checkboxHdrTmpl, sortable: false },
        { prop: 'usr_type_name', name: 'סוג משתמש', cellTemplate: 'optionsTmpl', filterType: 'options', options: this.typeNameOptions, headerClass: 'hide', cellClass: 'hide', sortable: false },
        // { prop: 'usr_fname', name: 'שם פרטי', filterType: 'like' },
        { prop: 'usr_fname', name: 'שם פרטי', sortable: false },
        { prop: 'usr_lname', name: 'שם משפחה', filterType: 'like', sortable: false },
        // { prop: 'usr_phone', name: 'טלפון', filterType: 'like' },
        { prop: 'usr_birth_date', name: 'תאריך לידה', customTemplate: this.dateTimezoneTmpl, sortable: false },
        { prop: 'usr_club_id', name: 'מועדון', cellTemplate: 'optionsTmpl', filterType: 'options', options: this.allClubsOptions, sortable: false },
        { prop: 'usr_team_name', name: 'קבוצה', sortable: false },
        { prop: 'sent_at', name: 'תאריך שליחה מקורי', customTemplate: this.dateTimezoneFullTmpl, sortable: false },
        { prop: 'ok_at', name: 'תאריך שליחה בפועל', customTemplate: this.dateTimezoneFullTmpl, sortable: false },
        { prop: 'body', name: 'תוכן', customTemplate: this.contentHoverTmpl, maxWidth: 450, sortable: false },
        { prop: 'quiz_days', name: 'סטטוס טפסים', customTemplate: this.quizDaysTmpl, sortable: false },
        // { prop: 'food_days', name: 'יומן אכילה', customTemplate: this.foodDaysTmpl, filterType: 'options', options: this.foodDaysOptions },
        { prop: 'food_days', name: 'יומן אכילה', customTemplate: this.foodDaysTmpl, sortable: false },
        { prop: 'blood_days', name: 'בדיקת דם', customTemplate: this.bloodDaysTmpl, sortable: false },
        // { prop: 'usr_is_follow', name: 'מעקב', customTemplate: this.isFollowTmpl, maxWidth: 70, sortable: false },
        { prop: 'last_ntq_date', name: 'מדידה אחרונה', customTemplate: this.lastNtqTmpl, sortable: false },
        // { prop: 'total_reports', name: 'דוחות', customTemplate: this.totalReportsTmpl, filterType: 'options', options: this.totalReportsOptions },
        { prop: 'total_reports', name: 'דוחות', customTemplate: this.totalReportsTmpl, sortable: false },
        { prop: 'last_rpt_date', name: 'דו״ח אחרון', customTemplate: this.dateTimezoneTmpl, sortable: false },
        { prop: 'usr_last_login', name: 'התחבר לאחרונה', customTemplate: this.dateTimezoneTmpl, sortable: false },
        // { prop: 'usr_manual_plan', name: 'ידני', customTemplate: this.editPlanTmpl, maxWidth: 70 },
        // { prop: 'remove_button', name: '', iconName: 'remove_circle_outline', cellTemplate: 'iconBtnTmpl', iconOnClick: this.removeLog.bind(this), maxWidth: 70, sortable: false },
        { prop: 'log_type', name: 'סוג התראה', cellTemplate: 'optionsTmpl', filterType: 'options', options: this.optionsLogType, headerClass: 'hide', cellClass: 'hide', sortable: false },
      ];
    } else {
      columns = [];
    }

    return columns;
  }

  onRowDblClick(row: any) {
    console.log('');
    // if (row.usr_type === 'player') {
    //   // this.router.navigate([`Nutritionistform/${row._id}`]);
    //   window.open(`Nutritionistform/${row._id}`);
    // } else {
    //   if (this.appState.user.usr_type === 'superadmin') {
    //     // this.router.navigate([`Profile/${row._id}`]);
    //     window.open(`Profile/${row._id}`);
    //   }
    // }
  }

  removeLog(row) {
    const id = row._id;

    const options = {
      title: 'מחיקת התראה',
      message: 'האם למחוק את ההתראה?',
      cancelText: 'ביטול',
      confirmText: 'אישור'
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
        if (confirmed) {
          this.logRestService.patch(id, { is_deleted: true }).then(res => {
            this._snackBar.open('התראה נמחקה', null, {
              duration: 5000,
            });
          this.tableEl.setTableData();

          }).catch(res => {
            if (res.message) {
              this._snackBar.open(res.message, null, {
                duration: 3000,
              });
            }
          });
        }
    });
  }

  newNtq(e, row) {
    console.log('');
    // if (row.usr_type !== 'player') {
    //   return;
    // }
    // e.stopPropagation();
    
    // const options = {
    //   title: 'מדידה חדשה',
    //   message: 'להוסיף מדידה חדשה למשתמש?',
    //   cancelText: 'לא',
    //   confirmText: 'כן'
    // };
    // this.dialogService.open(options);
    // this.dialogService.confirmed().subscribe(async confirmed => {
    //     if (confirmed) {
    //       const res = await this.restApiService.service('nuttestquiz').create({
    //         ntq_usr_id: row._id,
    //         ntq_form_is_real: true
    //       });
    //       if (res && res._id) {
    //         window.open(`Nuttestquiz/${res._id}`);
    //       }
    //     }
    // });
  }

  selectRow(e, row, bulk?: boolean) {
    const index = this.chosenIds.findIndex(_id => _id === row._id);
    // const index = this.chosenUsers.findIndex(usr => usr && usr._id === row._id);
    if (e.checked && index === -1) {
      const {_id, owner_id, to_id, title, body, log_type, sent_at, ok_at, usr_fname, usr_lname, usr_type, usr_type_name, usr_notification_token, usr_phone, usr_email, usr_club_id, usr_club_ids, usr_parent_1_phone, usr_parent_2_phone, usr_subscribe, usr_club_name, usr_gender, usr_child, usr_agreement, usr_birth_date} = row;
      const stripped = {_id, owner_id, to_id, title, body, log_type, sent_at, ok_at, usr_fname, usr_lname, usr_type, usr_type_name, usr_notification_token, usr_phone, usr_email, usr_club_id, usr_club_ids, usr_parent_1_phone, usr_parent_2_phone, usr_subscribe, usr_club_name, usr_gender, usr_child, usr_agreement, usr_birth_date};
      this.chosenIds.push(_id);
      this.chosenLogs.push(stripped);
    } else {
      if (!e.checked && index !== -1) {
        this.chosenIds.splice(index, 1);
        this.chosenLogs.splice(index, 1);
      }
      // if (!this.chosenIds || !this.chosenIds.length) {
      //   this.allChosen = false;
      // }
    }
    if (!bulk) {
      this.updateAllChosen();
    }
  }

  unselectRow(user) {
    console.log('');
    // const msg = `(${this.userTypes[user.usr_type]}) - ${user.usr_fname} ${user.usr_lname} - ${user.usr_phone}`;
    // const options = {
    //   title: 'ביטול בחירה',
    //   message: 'האם לבטל בחירה של ' + msg + ' ?',
    //   cancelText: 'לא',
    //   confirmText: 'כן'
    // };
    // this.dialogService.open(options);
    // this.dialogService.confirmed().subscribe(confirmed => {
    //     if (confirmed) {
    //       this.selectRow({checked: false}, {_id: user._id});
    //     }
    // });
  }

  tableLoading(e) {
    // this.busy = e;
    setTimeout(() => {this.busy = e;});
  }

  async userStatusPopup(e, row: any) {
    if (row.usr_type !== 'player') {
      return;
    }
    e.stopPropagation();
    this.tableLoading(true);

    // const userState = await this.restApiService.service('user-state').get(row._id).catch(e => { console.error(e) });
    // const userPlayer = await this.restApiService.service('users').get(row._id).catch(e => { console.error(e) });
    
    const [userPlayer] = await this.restApiService.service('users').find({
      query: {
        $paginate: false,
        $limit: 1,
        _id: row._id,
        $extradetails: true
      }
    });
    // if (!userPlayer) {
    //   throw 'no user';
    // }
    // const isQa = !environment.production;

    // const state = isQa ? (userPlayer?.stateUserQa || userPlayer?.usr_state?.stateUserQa) : (userPlayer?.stateUser || userPlayer?.usr_state?.stateUser);
    const state = userPlayer?.stateUser || userPlayer?.usr_state?.stateUser;
    if (state) {
      const totalReports = row['total_reports'] || 0;
      const reportCount = row['usr_report_count'] || 0;
      const currentPlan = `${totalReports} / ${reportCount}`;
      const userName = (row['usr_fname'] || '') + ' ' + (row['usr_lname'] || '');
      const newFormGroup = this.formBuilder.group({
        _id: [row._id, Validators.required],
        currentPlan: [currentPlan],
        userName: [userName],
        state: [state]
      });

      const title = row.usr_gender === 'נקבה' ? 'סטטוס ספורטאית' : 'סטטוס ספורטאי';
      const dialogRef = this.dialog.open(CustomDialogComponent, {
        minWidth: '35%',
        data: { 
          title: title, 
          // ok: this.translate.instant('general.ok'),
          // cancel: this.translate.instant('general.cancel'),
          ok: 'מעבר לטופס תזונאי',
          cancel: 'חזרה',
          form: newFormGroup, 
          template: this.stateTemplate
        }
      });
      
      dialogRef.afterClosed().subscribe(async result => {
        if (!result) {
          return;
        }
        window.open(`Nutritionistform/${row._id}`);
      });
    }

    this.tableLoading(false);

  }

  // async isFollowPopup(e, row: any) {
  //   if (row.usr_type !== 'player') {
  //     return;
  //   }
  //   e.stopPropagation();
  //   this.tableLoading(true);

  //   const userPlayer = await this.restApiService.service('users').get(row._id).catch(e => { console.error(e) });
  //   if (userPlayer && userPlayer['usr_notes'] && userPlayer['usr_notes']['A']) {
  //     const totalReports = row['total_reports'] || 0;
  //     const reportCount = row['usr_report_count'] || 0;
  //     const currentPlan = `${totalReports} / ${reportCount}`;
  //     const userName = (row['usr_fname'] || '') + ' ' + (row['usr_lname'] || '');
  //     const isFemale = userPlayer.usr_gender === 'נקבה';
  //     const newFormGroup = this.formBuilder.group({
  //       _id: [row._id, Validators.required],
  //       currentPlan: [currentPlan],
  //       userName: [userName],
  //       isFemale: [isFemale],
  //       notes: [userPlayer['usr_notes']]
  //     });

  //     const title = row.usr_gender === 'נקבה' ? 'מעקב ספורטאית' : 'מעקב ספורטאי';
  //     const dialogRef = this.dialog.open(CustomDialogComponent, {
  //       minWidth: '35%',
  //       data: { 
  //         title: title, 
  //         // ok: this.translate.instant('general.ok'),
  //         // cancel: this.translate.instant('general.cancel'),
  //         ok: 'מעבר לטופס תזונאי',
  //         cancel: 'חזרה',
  //         form: newFormGroup, 
  //         template: this.followTemplate
  //       }
  //     });
      
  //     dialogRef.afterClosed().subscribe(async result => {
  //       if (!result) {
  //         return;
  //       }
  //       window.open(`Nutritionistform/${row._id}`);
  //     });
  //   } else {
  //     this._snackBar.open('לא קיימת היסטוריית מעקב', null, {
  //       duration: 3000,
  //     });
  //   }

  //   this.tableLoading(false);

  // }

  // async fetchNotes() {
  //   const res = await this.restApiService.service('note').find({
  //     query: {
  //       $paginate: false,
  //       $sort: {
  //         title: 1,
  //         content: 1
  //       },
  //       $limit: 9999,
  //       is_deleted: {
  //         $ne: true
  //       },
  //       $or:[{is_visible: true}, {is_visible_all: true}, {is_scenario: true}]
  //     }
  //   });
    
  //   this.allNotesUnfiltered = res;
  // }

  // async openPushDialog() {
  //   if (!this.chosenIds || !this.chosenIds.length) {
  //     return;
  //   }

  //   const pushCustomValidator = (formGroup: FormGroup) => {
  //     if (!formGroup.value.push_method_push && !formGroup.value.push_method_wap) {
  //       return {
  //         msg: 'דרוש לבחור אופן שליחה'
  //       };
  //     }
  //     return null;
  //   };

  //   let newPush = this.formBuilder.group({
  //     push_to_type: ['user', Validators.required],
  //     push_title: [''],
  //     push_content: ['', Validators.required],
  //     push_method_push: [false],
  //     push_method_wap: [false],
  //   },
  //   {
  //     validators: [pushCustomValidator]
  //   });

  //   let title = 'שליחת התראה ידנית';
  //   const dialogRef = this.dialog.open(CustomDialogComponent, {
  //     minWidth: '35%',
  //     data: { 
  //       title: title,
  //       ok: this.translate.instant('general.ok'),
  //       cancel: this.translate.instant('general.cancel'), 
  //       form: newPush, 
  //       template: this.pushDialogTemplate 
  //     }
  //   });
    
  //   dialogRef.afterClosed().subscribe(async result => {
  //     console.log('The dialog was closed');
  //     if (!result) {
  //       console.log("user canceled");
  //       return;
  //     }
  //     await this.onSubmitPushArray(newPush);
  //     setTimeout(() => {
  //       this.onSubmitWapArray(newPush);
  //     }, 1500);
  //   });
  // }

  // async onSubmitPushArray(pushForm: FormGroup) {
  //   try {
  //     const usersList = this.chosenUsers.map((usr: any) => {
  //       const {_id, usr_fname, usr_lname, usr_type, usr_type_name, usr_notification_token, usr_phone, usr_parent_1_phone, usr_parent_2_phone, usr_parent_1_fname, usr_parent_2_fname, usr_parent_1_lname, usr_parent_2_lname, usr_subscribe, usr_club_name, usr_gender, usr_agreement} = usr;
  //       return {_id, usr_fname, usr_lname, usr_type, usr_type_name, usr_notification_token, usr_phone, usr_parent_1_phone, usr_parent_2_phone, usr_parent_1_fname, usr_parent_2_fname, usr_parent_1_lname, usr_parent_2_lname, usr_subscribe, usr_club_name, usr_gender, usr_agreement};
  //     });
  //     const pushToType = pushForm.value.push_to_type;
  //     const pushTitle = pushForm.value.push_title;
  //     const pushBody = pushForm.value.push_content;

  //     if (pushForm.value.push_method_push) {
  //       this._snackBar.open('שולח התראות...', 'סגור', {
  //         duration: 0,
  //       });
  
  //       const resCount = await this.restApiService.service('notifications').create({
  //         usersList, pushToType, pushTitle, pushBody
  //       });
        
  //       this._snackBar.open(`נשלחו ${resCount} התראות`, 'סגור', {
  //         duration: 0,
  //       });

  //       // const res = await this.restApiService.service('notifications').create({
  //       //   deviceToken: usersList.map((user: any) => user.usr_notification_token).filter(el => el),
  //       //   title: pushTitle,
  //       //   body: pushBody,
  //       //   payload: {
  //       //       msg_type: 'custom',
  //       //   }
  //       // });

  //       // console.log(res);
  //     }
      
  //   } catch (e) {
  //     console.error(e);
  //     this._snackBar.open('שגיאה בשליחת ההתראה', null, {
  //       duration: 3000,
  //     });
  //   }
  // }

  // async onSubmitWapArray(pushForm: FormGroup) {
  //   try {
  //     const usersList = this.chosenUsers.map((usr: any) => {
  //       const {_id, usr_fname, usr_lname, usr_type, usr_type_name, usr_phone, usr_parent_1_phone, usr_parent_2_phone, usr_parent_1_fname, usr_parent_2_fname, usr_parent_1_lname, usr_parent_2_lname, usr_subscribe, usr_club_name, usr_gender, usr_agreement} = usr;
  //       return {_id, usr_fname, usr_lname, usr_type, usr_type_name, usr_phone, usr_parent_1_phone, usr_parent_2_phone, usr_parent_1_fname, usr_parent_2_fname, usr_parent_1_lname, usr_parent_2_lname, usr_subscribe, usr_club_name, usr_gender, usr_agreement};
  //     });
  //     const pushToType = pushForm.value.push_to_type;
  //     const pushTitle = pushForm.value.push_title;
  //     const pushBody = pushForm.value.push_content;

  //     if (pushForm.value.push_method_wap) {
  //       this._snackBar.open('שולח Whatsapp...', 'סגור', {
  //         duration: 0,
  //       });
  
  //       const resCount = await this.restApiService.service('wap').create({
  //         usersList, pushToType, pushTitle, pushBody
  //       });
          
  //       this._snackBar.open(`נשלחו ${resCount} הודעות Whatsapp (נבחרו ${usersList.length})`, 'סגור', {
  //         duration: 0,
  //       });
  //     }
      
  //   } catch (e) {
  //     console.error(e);
  //     this._snackBar.open('שגיאה בשליחת Whatsapp', null, {
  //       duration: 3000,
  //     });
  //   }
  // }

  updateSelected(e) {
    console.log(e)
  }

  checkboxHdrChange(checked: boolean) {
    if (checked) {
      this.selectAll();
    } else {
      this.unselectAll(true);
      // this.chosenIds = [];
      // this.chosenUsers = [];
    }
    // this.allChosen = e;
  }

  selectAll() {
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // minWidth: '35%',
      data: { 
        title: 'בחירת הכל',
        subtitle: 'האם לבחור את כל ההתראות או רק עמוד נוכחי?',
        cancel: 'ביטול',
        ok: 'עמוד נוכחי',
        extraButton: { value: 'all', text: 'כולם' },
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        this.updateAllChosen();
        return;
      }
      if (result === 'all') {
        this.busy = true;
        const chosenLogs = await this.getAllLogs(this.tableEl._query.query);
        const chosenIds: string[] = chosenLogs.map((log: any) => log._id);
        this.chosenIds = chosenIds;
        this.chosenLogs = chosenLogs;
        this.busy = false;
      } else {
        for (const row of this.tableEl.rows || []) {
          this.selectRow({checked: true}, row, true);
        }
      }
      this.updateAllChosen();
    });
  }

  updateAllChosen() {
    console.log('updateAllChosen')
    if (this.chosenIds.length > 0) {
      if (this.tableEl?.tablePage.totalElements === this.chosenIds.length) { // || this.tableEl?.rows?.length === this.chosenIds.length
        this.allChosen = true;
        this.someChosen = false;
      } else {
        this.allChosen = false;
        this.someChosen = true;
      }
    } else {
      this.allChosen = false;
      this.someChosen = false;
    }
  }

  unselectAll(isCheckbox?: boolean) {
    if (!this.chosenIds || !this.chosenIds.length) {
      return;
    }

    const options = {
      title: 'ביטול בחירה',
      message: 'האם לבטל בחירה של ' + this.chosenIds.length + ' התראות?',
      cancelText: 'לא',
      confirmText: 'כן'
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
        if (confirmed) {
          this.chosenIds = [];
          this.chosenLogs = [];
          this.allChosen = false;
          this.someChosen = false;
          return;
        }
        if (isCheckbox) {
          // this.allChosen = true;
          this.updateAllChosen();
        }
    });
  }

  async getAllLogs(currentQuery: any) {
    let query: any;
    if (!currentQuery) {
      query = this.query;
    } else {
      query = {...currentQuery };
      delete query.$skip;
      delete query.$limit;
      query.$skip = 0;
      query.$limit = 9999;
    }

    const logs = await this.restApiService.service('msg-log').find({ 
      query
    });

    return logs.data;
  }

  // addText(nativeEl: any, formControl: FormControl, textToAdd: string) {
  //   const val = formControl.value;
  //   const selStart = nativeEl?.selectionStart || val.length;
  //   const brackets = `{{${textToAdd}}}`;
  //   formControl.setValue(`${val.substring(0, selStart)}${brackets}${val.substring(selStart)}`);
  //   setTimeout(() => {
  //     if (nativeEl) {
  //       nativeEl.selectionStart = selStart + brackets.length;
  //       nativeEl.focus();
  //     }
  //   });
  // }

  phoneFormat(user: any) {
    const prefix = '+972';
    let { usr_phone = '' } = user;
    const substr = usr_phone.substring(0, 1);
    if (substr === '0') {
      usr_phone = prefix + usr_phone.substring(1);
    }
    if (substr === '1') {
      usr_phone = '+' + usr_phone;
    }
    return usr_phone;
  }

  sendAgainPopup() {
    const options = {
      title: 'שלח שוב',
      message: 'האם לשלוח את ההודעות המסומנות שוב?',
      cancelText: 'ביטול',
      confirmText: 'אישור'
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(async confirmed => {
        if (confirmed) {
          await this.sendAgain();
        }
    });
  }

  async sendAgain() {
    // const chosenIds = this.chosenIds;
    const chosenLogs = this.chosenLogs;

    let pushIdsArr: string[] = [];
    let wapIdsArr: string[] = [];
    // let pushArr: any[] = [];
    // let wapArr: any[] = [];

    for (const log of chosenLogs) {
      if (log.log_type === 'push') {
        pushIdsArr.push(log._id);
        // pushArr.push(log);
      }
      if (log.log_type === 'wap') {
        wapIdsArr.push(log._id);
        // wapArr.push(log);
      }
    }

    try {
      this._snackBar.open('שולח שוב התראות...', 'סגור', {
        duration: 0,
      });
  
      const resPushCount = await this.restApiService.service('notifications').create({
        chosenIds: pushIdsArr
      });
  
      const resWapCount = await this.restApiService.service('wap').create({
        chosenIds: wapIdsArr
      });
  
      await this.tableEl.setTableData();

      this._snackBar.open(`נשלחו שוב ${(+(resPushCount) + +(resWapCount))} התראות`, 'סגור', {
        duration: 0,
      });
  
      // this._snackBar.open(`נשלחו ${resCount} הודעות Whatsapp (נבחרו ${usersList.length})`, 'סגור', {
      //   duration: 0,
      // });
      
      // this._snackBar.open(`נשלחו ${resCount} התראות`, 'סגור', {
      //   duration: 0,
      // });
    } catch (e) {
      console.error(e);
      this._snackBar.open('שגיאה בשליחת Whatsapp', null, {
        duration: 3000,
      });
    }

  }

  async markAsOk() {
    // const chosenIds = this.chosenIds;
    const chosenLogs = this.chosenLogs;

    let pushIdsArr: string[] = [];
    let wapIdsArr: string[] = [];
    // let pushArr: any[] = [];
    // let wapArr: any[] = [];

    for (const log of chosenLogs) {
      if (log.log_type === 'push') {
        pushIdsArr.push(log._id);
        // pushArr.push(log);
      }
      if (log.log_type === 'wap') {
        wapIdsArr.push(log._id);
        // wapArr.push(log);
      }
    }

    try {
      this._snackBar.open('מעדכן התראות...', 'סגור', {
        duration: 0,
      });
  
      const resPushCount = await this.restApiService.service('notifications').create({
        markAsOkIds: pushIdsArr
      });
  
      const resWapCount = await this.restApiService.service('wap').create({
        markAsOkIds: wapIdsArr
      });

      await this.tableEl.setTableData();
  
      this._snackBar.open(`עודכנו ${(+(resPushCount) + +(resWapCount))} התראות`, 'סגור', {
        duration: 0,
      });
  
    } catch (e) {
      console.error(e);
      this._snackBar.open('שגיאה בעדכון התראות', null, {
        duration: 3000,
      });
    }

  }

  copyToClipboard(text: string) {
    this.clipboard.copy(text);
    this._snackBar.open('הערה הועתקה', null, {
      duration: 1000,
    });

  }

}
