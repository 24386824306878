import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

import { AuthService, RestApiService } from '@noammazuz/vzn-feathers';

import { appState } from '@services/app-state';
import { Apputils } from '@services/app-utils';

import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
})
export class LoginPageComponent implements OnInit {
  showLogin: boolean;
  appState = appState;
  form: FormGroup;

  isFlutter = Apputils.isInNativeFlutter();
  waLink: SafeUrl;

  redirectUri = '';

  constructor(
    public authService: AuthService<any>,
    private restApiService: RestApiService<any>,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    public translate: TranslateService,
    public sanitizer: DomSanitizer,
  ) {
    this.form = this.formBuilder.group({
      phoneNumber: [''],
      verificationCode: [''],
    });

  }

  async ngOnInit() {
    this.redirectUri = this.route.snapshot.queryParams?.redirectUri;
    console.log('this.redirectUri', this.redirectUri)
    let user = await this.authService.isAuth().catch(err => { console.error(err); });
    if (user) {
      await this.authSuccess(user, this.redirectUri);
    } else {
      this.showLogin = true;
    }
    const waLink = 'https://wa.me/972559943214';
    this.waLink = this.sanitizer.bypassSecurityTrustUrl(waLink);
  }

  async authSuccess(e, redirectUri?: string) {
    if (!e || !e._id) { return }
    
    const logged = await this.restApiService.service('users').patch(e._id, { last_login: true });
    console.log('logged ', logged);

    this.appState.user = e;

    console.log("calling flutter action from login-page");
    Apputils.flutterAction(Apputils.FlutterActions.GET_TOKEN);

    const usrId = this.appState.user._id;
    let navTo = '';
    switch (this.appState.user.usr_type) {
      case 'superadmin':
        navTo = 'Userstable';
        break;
    
      case 'admin':
        navTo = 'Club';
        break;
    
      case 'player':
        navTo = this.appState.user['usr_agreement'] && this.appState.user['usr_confirmed'] ? `Userprogress` : `Profile/${usrId}`;
        break;
    
      case 'nutri':
        navTo = 'Userstable';
        break;
    
      case 'coach':
        // navTo = `Coach`;
        navTo = this.appState.user.usr_agreement ? `Coach` : `Profile/${usrId}`;
        break;
    
      case 'parent':
        navTo = this.appState.user['usr_agreement'] ? `Userparent` : `Profile/${usrId}`;
        break;
    
      default:
        // navTo = '';
        navTo = 'Profile';
        break;
    }
    this.router.navigate([redirectUri || navTo], { replaceUrl: true });
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }
}
