import { Component, Input, Output, EventEmitter, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { TranslateService } from '@ngx-translate/core';
import { RestApiService } from '@noammazuz/vzn-feathers';

import { appState } from '@services/app-state';
import { ConfirmDialogService } from '../../../../shared/confirm-dialog/confirm-dialog.service';
import { CustomDialogComponent } from '../../../../shared/custom-dialog.component';

@Component({
  selector: 'bloodtest-section',
  templateUrl: "./bloodtest.component.html"
})
export class BloodtestComponent {
  @ViewChild("newTestTemplate") newTestTemplate: TemplateRef<any>;

  @Output() addNoteEvent: EventEmitter<any> = new EventEmitter();
  @Output() editNoteEvent: EventEmitter<any> = new EventEmitter();
  
  @Output() busyState: EventEmitter<boolean> = new EventEmitter();
  @Output() updateFollow: EventEmitter<any> = new EventEmitter();

  @Output() futureQuiz: EventEmitter<boolean> = new EventEmitter();

  @Input() user: any;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onNew: EventEmitter<any> = new EventEmitter();
  
  // @Input() allNotes = [];
  @Output() linkedNotesEvent: EventEmitter<any> = new EventEmitter();
  @Input() notesList = [];

  @Input() sharedNotesFormA: FormGroup;
  // @Input() sharedNotesFormB: FormGroup;
  @Input() allFdqValues: any;

  @Input() oldNotesFlag?: boolean;

  quizPrefix = 'btq';

  notesFormA: FormGroup;
  notesFormB: FormGroup;

  oldValues = [];

  selectedIdA: string;
  selectedIdB: string;

  selectedA: any;
  selectedB: any;

  restService: any;

  appState = appState;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private dialogService: ConfirmDialogService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public restApiService: RestApiService<any>
  ) {

    this.restService = this.restApiService.service('bloodtestquiz');

    this.notesFormA = this.formBuilder.group({
      _id: [''],
      btq_locked: [false],
      btq_notes: this.formBuilder.group({
        A: this.formBuilder.array([]),
        B: this.formBuilder.array([]),
        C: this.formBuilder.array([]),
        D: this.formBuilder.array([]),
        E: this.formBuilder.array([]),
        F: this.formBuilder.array([]),
        G: this.formBuilder.array([]),
        H: this.formBuilder.array([]),
        I: this.formBuilder.array([]),
        J: this.formBuilder.array([]),
        
        s1: this.formBuilder.array([]),
        s4: this.formBuilder.array([]),
        s6: this.formBuilder.array([]),
      }),

      btq_blood_values: this.formBuilder.group({
        hem: [{ value: '', disabled: false }],
        prt: [{ value: '', disabled: false }],
        trn: [{ value: '', disabled: false }],
        irn: [{ value: '', disabled: false }],
        b12: [{ value: '', disabled: false }],
        mag: [{ value: '', disabled: false }],
        vid: this.formBuilder.group({
            unit_type: [{ value: 'ng/ml', disabled: false }],
            unit_value: [{ value: '', disabled: false }],
        }),
        pol: this.formBuilder.group({
            unit_type: [{ value: 'ng/ml', disabled: false }],
            unit_value: [{ value: '', disabled: false }],
        }),
        mcv: [{ value: '', disabled: false }],
        mch: [{ value: '', disabled: false }],
        glu: [{ value: '', disabled: false }],
        col: [{ value: '', disabled: false }],
        tri: [{ value: '', disabled: false }],
        hdl: [{ value: '', disabled: false }],
        ldl: [{ value: '', disabled: false }],
        alk: [{ value: '', disabled: false }],
        a1c: [{ value: '', disabled: false }],
        tsh: [{ value: '', disabled: false }],
        cpk: [{ value: '', disabled: false }],

        pol_calc: [{ value: '', disabled: false }],
        vid_calc: [{ value: '', disabled: false }],
        irn_trn_calc: [{ value: '', disabled: false }],
      }),

      notes_btq_a: [''],
      notes_btq_b: [''],
      notes_btq_c: [''],
      notes_btq_d: [''],
      notes_btq_e: [''],
      notes_btq_f: [''],
      notes_btq_g: [''],
      notes_btq_h: [''],
      notes_btq_i: [''],
      notes_btq_j: [''],
        
      // s1: this.formBuilder.array([]),
      // s4: this.formBuilder.array([]),
      // s6: this.formBuilder.array([]),
    });
    
    this.notesFormA.get('btq_blood_values').valueChanges.subscribe(value => {
      const calc = this.calcBloodValues(value);
      this.notesFormA.get('btq_blood_values').patchValue(calc, { emitEvent: false });
      // console.log(value)
    });

    this.notesFormB = this.formBuilder.group({
      _id: [''],
      btq_locked: [false],
      btq_notes: this.formBuilder.group({
        A: this.formBuilder.array([]),
        B: this.formBuilder.array([]),
        C: this.formBuilder.array([]),
        D: this.formBuilder.array([]),
        E: this.formBuilder.array([]),
        F: this.formBuilder.array([]),
        G: this.formBuilder.array([]),
        H: this.formBuilder.array([]),
        I: this.formBuilder.array([]),
        J: this.formBuilder.array([]),
      }),

      notes_btq_a: [''],
      notes_btq_b: [''],
      notes_btq_c: [''],
      notes_btq_d: [''],
      notes_btq_e: [''],
      notes_btq_f: [''],
      notes_btq_g: [''],
      notes_btq_h: [''],
      notes_btq_i: [''],
      notes_btq_j: [''],
    });

  }

  async newClonePopupGuard() {
    this.onNew.emit(() => { this.newClonePopup() });
  }
  newClonePopup() {
    const options = {
      title: 'שכפול בדיקות דם',
      message: 'האם אתה בטוח שאתה רוצה לשכפל את בדיקות הדם האחרונות לדוח זה?',
      cancelText: 'לא',
      confirmText: 'כן'
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.cloneQuiz();
      }
    });
  }

  async newQuizPopupGuard() {
    this.onNew.emit(() => { this.newQuizPopup() });
  }
  async newQuizPopup() {
    const hasFirst = await this.restService.find({
      query: {
          $limit: 1,
          // $paginate: false,
          $select: ['_id', 'btq_past_tests'],
          btq_usr_id: this.user._id,
          btq_form_is_real: true,
          $or: [{ btq_past_tests: 'כן' }, { btq_past_tests: 'לא' }],
          btq_is_clone:{
              $ne: true
          }
      },
    });
    let newFormGroup: FormGroup;
    if (hasFirst && hasFirst.data && hasFirst.data.length) {
      newFormGroup = this.formBuilder.group({
        emailBox: [true],
      });
    } else {
      newFormGroup = this.formBuilder.group({
        firstBox: [false],
        emailBox: [true],
      });
    }
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // minWidth: '35%',
      data: { 
        title: 'המלצה על בדיקות חדשות',
        // subtitle: 'האם אתה רוצה להמליץ על בדיקות דם חדשות?',
        form: newFormGroup, 
        template: this.newTestTemplate,
        ok: this.translate.instant('general.yes'),
        cancel: this.translate.instant('general.no'),
        // extraButton: { value: 'future', text: 'בהמשך' }
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }
      if (result === 'future') {
        this.futureQuiz.emit(newFormGroup.value.firstBox);
        return;
      }
      if (newFormGroup.value.firstBox) {
        await this.restService.create({
          btq_usr_id: this.user._id,
          btq_form_is_real: true,
          btq_locked: true,
          btq_past_tests: 'לא'
        });
      }
      await this.newQuiz(newFormGroup.value.emailBox);
    });
  }

  async cloneQuiz() {
    try {
      let res = await this.restService.find({ query: {   
        $limit: 1,
        $paginate: false,
        btq_usr_id: this.user._id,
        btq_form_is_real: true,
        $sort: {
          createdAt: -1
        }
      } });
  
      console.log(res);
      if (res.length > 0) {
        let data = res[0];
        delete data._id;
        delete data.createdAt;
        delete data.updatedAt;
        delete data.btq_notes;

        delete data.notes_btq_a;
        delete data.notes_btq_b;
        delete data.notes_btq_c;
        delete data.notes_btq_d;
        delete data.notes_btq_e;
        delete data.notes_btq_f;
        delete data.notes_btq_g;
        delete data.notes_btq_h;
        delete data.notes_btq_i;
        delete data.notes_btq_j;
        
        // data.btq_is_clone = true;
        // delete data.savedAt;
        delete data.btq_past_tests;
        delete data.btq_has_tests;
        delete data.btq_test_reason;
        delete data.btq_test_reasons_text;

        delete data.btq_locked;
        data.btq_locked = true;
        // console.log(data)
        await this.restService.create(data);
        this.fetchOldValues(true);
      }

    } catch (e) {
      console.error(e);
    }
  }

  async newQuiz(recommendMail = false) {
    
    const res = await this.restApiService.service('actions').create({
      cmd: 'new-btq',
      cmdData: {
        btq_usr_id: this.user._id,
        btq_form_is_real: true,
        recommend: recommendMail
      }
    });
    console.log('newQuiz res', res)

    // await this.restService.create({
    //   btq_usr_id: this.user._id,
    //   btq_form_is_real: true,
    //   recommend: recommendMail
    // });
    this.fetchOldValues(true);
  }

  async deleteQuiz(quizId: string) {
    this.onDelete.emit(() => { this.deleteOk(quizId) });
  }

  async deleteOk(quizId: string) {
    await this.restService.delete(quizId);
    this.fetchOldValues(true);
  }

  async toggleQuizLock(event: MatSlideToggleChange, selected: FormGroup) {
    const lockedField = 'btq_locked';
    if (selected && selected.value && selected.value._id) {
      const res = await this.restService.patch(selected.value._id, {
        [lockedField]: event.checked
      });
      if (res && res[lockedField] === event.checked) {
        event.source.checked = res[lockedField];
        selected.controls[lockedField].reset(res[lockedField]);
        return;
      }
    }
    event.source.checked = !event.checked;
  }

  async fetchOldValues(reset?: boolean, silent?: boolean) {
    try {
      let res = await this.restService.find({ query: {   
        // $limit: 1,
        $paginate: false,
        // $select: [ '_id', 'btq_test_date', 'btq_past_tests', 'btq_usr_id' ],
        // $select: [ '_id', 'createdAt', 'btq_usr_id' ],
        btq_usr_id: this.user._id,
        btq_form_is_real: true,
        // $sort: {
        //   btq_test_date: -1
        // }
        $sort: {
          createdAt: -1
        }
      } });
  
      console.log(res);
      this.oldValues = res;

      // let filteredData = res.filter((element) => {
      //   return element.btq_test_date || element.btq_past_tests;
      // });
      // this.oldValues = filteredData;
  
      if(reset){
        await this.resetToLastTwo(silent);
      }

    } catch (e) {
      console.error(e);
    }
  }

  async resetToLastTwo(silent?: boolean) {
    const values = this.oldValues;
    if (values.length === 0) {
      delete this.selectedIdA;
      delete this.selectedA;
      this.notesFormA.reset();
      delete this.selectedIdB;
      delete this.selectedB;
      this.notesFormB.reset();
      return;
    }
    if (values.length === 1) {
      delete this.selectedIdB;
      delete this.selectedB;
      this.notesFormB.reset();
    }
    // delete this.selectedIdA;
    if (values[0] && values[0]._id && values[0]._id !== this.selectedIdA) {
      delete this.selectedA;
      this.notesFormA.reset();
      this.selectedIdA = values[0]._id;
      await this.selectDateA(this.selectedIdA);
    }
    if (silent) {
      return;
    }
    // delete this.selectedIdB;
    if (values[1] && values[1]._id && values[1]._id !== this.selectedIdB) {
      delete this.selectedB;
      this.notesFormB.reset();
      this.selectedIdB = values[1]._id;
      await this.selectDateB(this.selectedIdB);
    }
  }

  // async fetchNotes(prefix: string) {
  //   this.notesList = await this.restApiService.service('note').find({
  //     query: {
  //       $sort: {
  //         title: 1,
  //         content: 1
  //       },
  //       $limit: -1,
  //       is_deleted: {
  //         $ne: true
  //       },
  //       quiz_prefix: prefix
  //     }
  //   })
  // }

  async ngOnInit() {
    // await this.fetchNotes('btq');
    await this.fetchOldValues(true);
  }

  resetNotes(notesGroup: FormGroup, dataNotes) {
    for (const noteKey in notesGroup.value) {
      let notes: FormArray = notesGroup.get(noteKey) as FormArray;
      notes.clear();
      if (dataNotes && dataNotes[noteKey]) {
        for (const n of dataNotes[noteKey]) {
          notes.push(new FormControl());
        }
      }
    }
  }

  refreshFormA(value) {
    const notesGroup = this.notesFormA.get('btq_notes') as FormGroup;
    this.resetNotes(notesGroup, value['btq_notes']);
    this.notesFormA.reset(value);
    // this.enableBloodValues(this.notesFormA);
    this.selectedA = { ...value, btq_blood_values: { ...value?.btq_blood_values, ...this.calcBloodValues(value?.btq_blood_values) } };
    // this.selectedA = value;
    // this.calcBloodValues(this.selectedA);
  }

  refreshFormB(value) {
    const notesGroup = this.notesFormB.get('btq_notes') as FormGroup;
    this.resetNotes(notesGroup, value['btq_notes']);
    this.notesFormB.reset(value);
    this.selectedB = { ...value, btq_blood_values: { ...value?.btq_blood_values, ...this.calcBloodValues(value?.btq_blood_values) } };
    // this.selectedB = value;
    // this.calcBloodValues(this.selectedB);
  }

  async selectDateA(newVal){
    // const data = await this.restService.get(newVal).catch(e => { console.error(e) });
    // const res = await this.restService.find({
    //   query: {
    //     $nutri: true,
    //     $limit: 1,
    //     _id: newVal,
    //   },
    // }).catch(e => { console.error(e) });
    // const data = res?.data[0];
    const data = this.oldValues.find(val => val._id === newVal);
    if (data) {
      this.notesFormA.reset();
      this.refreshFormA(data); 
    }
  }
  async selectDateB(newVal){
    // const data = await this.restService.get(newVal).catch(e => { console.error(e) });
    // const res = await this.restService.find({
    //   query: {
    //     $nutri: true,
    //     $limit: 1,
    //     _id: newVal,
    //   },
    // }).catch(e => { console.error(e) });
    // const data = res?.data[0];
    const data = this.oldValues.find(val => val._id === newVal);
    if (data) {
      this.notesFormB.reset();
      this.refreshFormB(data); 
    }
  }

  // async submitForm(){
  //   this.busyState.emit(true);
  //   const form = this.notesFormA;
  //   let res: any;

  //   if (form && form.value._id && form.value._id !== '') { 
  //     res = await this.restService.patch(form.value._id, form.value);
  //     if (res && res._id) {
  //       form.reset(res);
  //       this.showStatus("נשמר בהצלחה", null);
  //       if (res.follow_flag) {
  //         this.updateFollow.emit(res);
  //         setTimeout(() => {
  //           this._snackBar.open('המשתמש נכנס למעקב', 'סגור', {
  //             duration: 0,
  //           });
  //         }, 2000);
  //       }
  //     }
  //   }
  //   this.busyState.emit(false);
  //   return res;
  // }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];
  }

  getDirty() {
    const formA = this.notesFormA;
    const formB = this.notesFormB;
    // return (formA && formA.dirty) || (formB && formB.dirty);
    return (formA && formA.dirty);
  }

  getLocked() {
    const lockedField = 'btq_locked';
    const formA = this.notesFormA;
    // const formB = this.notesFormB;
    // return (formA && formA.dirty) || (formB && formB.dirty);
    return (formA && formA.value[lockedField]);
  }

  getLastId() {
    const formA = this.notesFormA;
    return formA && formA.value._id || '';
  }

  calcBloodValues(btq_blood_values) {
    const blood = btq_blood_values;
    if (!blood) return {};
    
    const pol = blood && blood.pol;
    const pol_calc = pol && pol.unit_value ? (pol.unit_type == 'nmol/L' ? (+(pol.unit_value)*0.44130626654898497).toFixed(1) : pol.unit_value) : '';
    const vid = blood && blood.vid;
    const vid_calc = vid && vid.unit_value ? (vid.unit_type == 'nmol/L' ? (+(vid.unit_value)*0.4).toFixed() : vid.unit_value) : '';
    const irn = blood && blood.irn;
    const trn = blood && blood.trn;
    // const irn_trn_calc = (irn && trn) ? (+(irn) / +(trn)).toFixed(2) : '';
    const irn_trn_calc = (irn && trn) ? (+(irn) / ((+(trn))+(+(irn)))).toFixed(2) : '';

    return { pol_calc, vid_calc, irn_trn_calc };
  }

  // enableBloodValues(notesForm) {
  //     const group = notesForm.get('btq_blood_values');
  //     if (!group) {
  //         return;
  //     }
  //     const keys = this.getObjKeys(group.value);
  //     for (let i = 0; i < keys.length; i++) {
  //         const ctrl = group.get(keys[i]);
  //         if (ctrl && ctrl.value && (ctrl.value.length || (ctrl.value.unit_value && ctrl.value.unit_value.length))) {
  //             ctrl.enable();
  //         }
  //     }
  // }
  
  checkedFn(event, notesForm, fieldName, isGroup?) {
      const formControl = notesForm.get('btq_blood_values').get(fieldName);

      if (event.checked) {
          formControl.enable();
          return;
      }
      formControl.disable();
      if (isGroup) {
          formControl.get('unit_value').reset('');
      } else {
          formControl.reset('');
      }
  }
  
  linkedNote(note: any) {
    this.linkedNotesEvent.emit(note);
  }

  addNote(contentAndField: any = {}, quiz_prefix: string, quiz_note_type: string) {
    const { content, content_F, acFormField } = contentAndField;
    this.addNoteEvent.emit({ content, content_F, quiz_prefix, quiz_note_type, acFormField });
  }

  editNote(idAndField: any = {}, quiz_prefix: string, quiz_note_type: string) {
    const { id, field } = idAndField;
    this.editNoteEvent.emit({ id, field, quiz_prefix, quiz_note_type });
  }

  addNoteToNotesObj(quiz_note_type: string, newNote: FormControl) {
    const notesGroup = this.notesFormA.get('btq_notes') as FormGroup;
    const fieldArray = notesGroup.get(quiz_note_type) as FormArray;
    if (!newNote.value?.note_id || !fieldArray.value || fieldArray.value.findIndex(val => val && val.note_id === newNote.value.note_id) > -1) {
      return;
    }
    fieldArray.push(newNote);
    fieldArray.markAsDirty();
  }

  removeNote(quiz_note_type: string, noteId: string) {
    const notesGroup = this.notesFormA.get('btq_notes') as FormGroup;
    const fieldArray = notesGroup.get(quiz_note_type) as FormArray;
    if (fieldArray) {
      const idx = fieldArray.value.findIndex(val => val && val.note_id === noteId); // || (val.note_custom && val.note_custom === noteObj.note_custom)
      if (idx > -1) {
        fieldArray.removeAt(idx);
        fieldArray.markAsDirty();
      }      
    }
  }

}

