<div class="basic-form">

        <div class="row">
            <h1 *ngIf="appState.user.usr_type === 'superadmin'">{{'club.QUIZ_TITLE' | translate}}</h1>
            <h1 *ngIf="appState.user.usr_type !== 'superadmin'">{{'club.QUIZ_TITLE_B' | translate}}</h1>
        </div>

        <span class="mt125"></span>

        <mat-tab-group [selectedIndex]="0">

            <mat-tab [disabled]="busy || currentEditedForm" label="{{'club.CLUBS' | translate}}" *ngIf="appState.user.usr_type === 'superadmin'">
                <form novalidate class="basic-form">
                    <!-- <ng-container [ngTemplateOutlet]="clubTemplate" [ngTemplateOutletContext]="{
                        context: formContext,
                        formGroupContext: clubsForm,
                        onAddTeamClick: addClubTeam,
                        onRemoveTeamClick: removeClubTeam,
                        arrFieldName: 'clubs_arr',
                        addOnClick: addClubItem,
                        removeOnClick: removeClubItem,
                        saveOnClick: saveClubItem,
                        onAddAdminClick: addClubAdmin,
                        onRemoveAdminClick: removeClubAdmin
                    }">
                    </ng-container> -->
                    <mat-card [formGroup]="clubsForm">
                        <mat-accordion [multi]="true">
                            <mat-expansion-panel [disabled]="busy" #exp *ngFor="let control of clubsForm.get('clubs_arr').controls; let i = index; let last = last;">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <h3>{{ control.value.clb_name || 'מועדון ללא שם' }}</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ng-container [ngTemplateOutlet]="clubTemplate" [ngTemplateOutletContext]="{
                                    isExpanded: exp.expanded,
                                    arrFieldName: 'clubs_arr',
                                    controlIndex: i,
                                    formGroupContext: control,
                                    removeOnClick: removeClubItem,
                                    saveOnClick: saveClubItem
                                }">
                                </ng-container>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="add-button-container row m-t-0">
                            <button class="w45" [disabled]="busy || currentEditedForm" (click)="addClubItem(formContext, 'clubs_arr')" type="button" mat-raised-button color="primary" mat-button>{{'club.ADD_CLUB_BUTTON' | translate}}<mat-icon>add_circle_outline</mat-icon>
                            </button>
                        </div>
                    </mat-card>
                </form>
            </mat-tab>

            <mat-tab [disabled]="busy || currentEditedForm" label="{{'club.TEAMS' | translate}}" *ngIf="appState.user.usr_type === 'admin' || appState.user.usr_type === 'nutri' || appState.user.usr_type === 'coach'">
                <form novalidate class="basic-form">
                    <!-- <ng-container [ngTemplateOutlet]="teamTemplate" [ngTemplateOutletContext]="{
                        context: formContext,
                        formGroupContext: teamsForm,
                        onAddUserClick: addTeamUser,
                        onRemoveUserClick: removeTeamUser,
                        arrFieldName: 'teams_arr',
                        addOnClick: addTeamItem,
                        removeOnClick: removeTeamItem,
                        saveOnClick: saveTeamItem
                    }">
                    </ng-container> -->
                    <mat-card [formGroup]="teamsForm">
                        <mat-accordion [multi]="true">
                            <mat-expansion-panel #exp *ngFor="let control of teamsForm.get('teams_arr').controls; let i = index; let last = last;">
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <h3>{{ control.value.tem_name || 'קבוצה ללא שם' }}</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <ng-container [ngTemplateOutlet]="teamTemplate" [ngTemplateOutletContext]="{
                                    isExpanded: exp.expanded,
                                    arrFieldName: 'teams_arr',
                                    controlIndex: i,
                                    formGroupContext: control,
                                    onAddUserClick: addTeamUser,
                                    onRemoveUserClick: removeTeamUser,
                                    removeOnClick: removeTeamItem,
                                    saveOnClick: saveTeamItem
                                }">
                                </ng-container>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div *ngIf="appState.user.usr_type === 'admin'" class="add-button-container row m-t-0">
                            <button class="w45" [disabled]="busy || currentEditedForm || !appState.user['usr_club_id']" (click)="addTeamItem(formContext, 'teams_arr', appState.user['usr_club_id'])" type="button" mat-raised-button color="primary" mat-button>{{'club.ADD_TEAM_BUTTON' | translate}}<mat-icon>add_circle_outline</mat-icon>
                            </button>
                        </div>
                    </mat-card>
                </form>
            </mat-tab>

            <mat-tab [disabled]="busy || currentEditedForm" label="{{'club.ADMINS' | translate}}" *ngIf="appState.user.usr_type === 'superadmin'">
                <form novalidate class="basic-form">
                    <mat-card>
                        <!-- <ng-container [ngTemplateOutlet]="userTemplate" [ngTemplateOutletContext]="{
                        context: formContext,
                        formGroupContext: usersForm,
                        arrFieldName: 'admins_arr',
                        userType: appUtils.UserTypes.Admin,
                        showEmailField: true,
                        addOnClick: addUserItem,
                        removeOnClick: removeUserItem,
                        editOnClick: editUserItem,
                        saveOnClick: saveUserItem,
                        cancelOnClick: cancelEditUserItem,
                        usrFnamePlaceholder: ('club.ADMIN_FIRST_NAME' | translate),
                        usrLnamePlaceholder: ('club.ADMIN_LAST_NAME' | translate),
                        addUserButtonText: ('club.ADD_ADMIN_BUTTON' | translate),
                        removeUserButtonText: ('club.REMOVE_ADMIN_BUTTON' | translate),
                        editUserButtonText: ('club.EDIT_ADMIN_BUTTON' | translate)
                    }">
                        </ng-container> -->
                        <ng-container [ngTemplateOutlet]="filteredUsersTemplate" [ngTemplateOutletContext]="{
                            formGroupContext: usersForm,
                            arrFieldName: 'admins_arr',
                            userType: appUtils.UserTypes.Admin,
                            showEmailField: true,
                            deleteOnClick: deleteUserItem,
                            editOnClick: editUserItem,
                            saveOnClick: saveUserItem,
                            cancelOnClick: cancelEditUserItem,
                            usrFnamePlaceholder: ('club.ADMIN_FIRST_NAME' | translate),
                            usrLnamePlaceholder: ('club.ADMIN_LAST_NAME' | translate),
                            deleteUserButtonText: ('club.DELETE_ADMIN_BUTTON' | translate),
                            editUserButtonText: ('club.EDIT_ADMIN_BUTTON' | translate)
                        }">
                        </ng-container>
                    </mat-card>                
                </form>
            </mat-tab>

            <mat-tab [disabled]="busy || currentEditedForm" label="{{'club.NUTRIS' | translate}}" *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin'">
                <form novalidate class="basic-form">
                    <mat-card>
                        <!-- <ng-container [ngTemplateOutlet]="userTemplate" [ngTemplateOutletContext]="{
                        context: formContext,
                        formGroupContext: usersForm,
                        arrFieldName: 'nutris_arr',
                        userType: appUtils.UserTypes.Nutri,
                        showEmailField: true,
                        addOnClick: addUserItem,
                        removeOnClick: removeUserItem,
                        editOnClick: editUserItem,
                        saveOnClick: saveUserItem,
                        cancelOnClick: cancelEditUserItem,
                        usrFnamePlaceholder: ('club.NUTRI_FIRST_NAME' | translate),
                        usrLnamePlaceholder: ('club.NUTRI_LAST_NAME' | translate),
                        addUserButtonText: ('club.ADD_NUTRI_BUTTON' | translate),
                        removeUserButtonText: ('club.REMOVE_NUTRI_BUTTON' | translate),
                        editUserButtonText: ('club.EDIT_NUTRI_BUTTON' | translate)
                    }">
                        </ng-container> -->
                        <ng-container [ngTemplateOutlet]="filteredUsersTemplate" [ngTemplateOutletContext]="{
                            formGroupContext: usersForm,
                            arrFieldName: 'nutris_arr',
                            userType: appUtils.UserTypes.Nutri,
                            showEmailField: true,
                            deleteOnClick: deleteUserItem,
                            editOnClick: editUserItem,
                            saveOnClick: saveUserItem,
                            cancelOnClick: cancelEditUserItem,
                            usrFnamePlaceholder: ('club.NUTRI_FIRST_NAME' | translate),
                            usrLnamePlaceholder: ('club.NUTRI_LAST_NAME' | translate),
                            deleteUserButtonText: ('club.DELETE_NUTRI_BUTTON' | translate),
                            editUserButtonText: ('club.EDIT_NUTRI_BUTTON' | translate)
                        }">
                        </ng-container>
                    </mat-card>                
                </form>
            </mat-tab>

            <mat-tab [disabled]="busy || currentEditedForm" label="{{'club.COACHES' | translate}}" *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin'">
                <form novalidate class="basic-form">
                    <mat-card>
                        <!-- <ng-container [ngTemplateOutlet]="userTemplate" [ngTemplateOutletContext]="{
                        context: formContext,
                        formGroupContext: usersForm,
                        arrFieldName: 'coaches_arr',
                        userType: appUtils.UserTypes.Coach,
                        showEmailField: true,
                        addOnClick: addUserItem,
                        removeOnClick: removeUserItem,
                        editOnClick: editUserItem,
                        saveOnClick: saveUserItem,
                        cancelOnClick: cancelEditUserItem,
                        usrFnamePlaceholder: ('club.COACH_FIRST_NAME' | translate),
                        usrLnamePlaceholder: ('club.COACH_LAST_NAME' | translate),
                        addUserButtonText: ('club.ADD_COACH_BUTTON' | translate),
                        removeUserButtonText: ('club.REMOVE_COACH_BUTTON' | translate),
                        editUserButtonText: ('club.EDIT_COACH_BUTTON' | translate)
                    }">
                        </ng-container> -->
                        <ng-container [ngTemplateOutlet]="filteredUsersTemplate" [ngTemplateOutletContext]="{
                            formGroupContext: usersForm,
                            arrFieldName: 'coaches_arr',
                            userType: appUtils.UserTypes.Coach,
                            showEmailField: true,
                            deleteOnClick: deleteUserItem,
                            editOnClick: editUserItem,
                            saveOnClick: saveUserItem,
                            cancelOnClick: cancelEditUserItem,
                            usrFnamePlaceholder: ('club.COACH_FIRST_NAME' | translate),
                            usrLnamePlaceholder: ('club.COACH_LAST_NAME' | translate),
                            deleteUserButtonText: ('club.DELETE_COACH_BUTTON' | translate),
                            editUserButtonText: ('club.EDIT_COACH_BUTTON' | translate)
                        }">
                        </ng-container>
                    </mat-card>                
                </form>
            </mat-tab>

            <mat-tab #playersTab [disabled]="busy || currentEditedForm" label="{{'club.PLAYERS' | translate}}" *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin' || appState.user.usr_type === 'nutri' || appState.user.usr_type === 'coach'">
                <form novalidate class="basic-form">
                    <mat-card *ngIf="playersTab.isActive">
                        <!-- <ng-container [ngTemplateOutlet]="userTemplate" [ngTemplateOutletContext]="{
                        context: formContext,
                        formGroupContext: usersForm,
                        arrFieldName: 'players_arr',
                        userType: appUtils.UserTypes.Player,
                        showEmailField: false,
                        addOnClick: addUserItem,
                        removeOnClick: removeUserItem,
                        editOnClick: editUserItem,
                        saveOnClick: saveUserItem,
                        cancelOnClick: cancelEditUserItem,
                        usrFnamePlaceholder: ('club.PLAYER_FIRST_NAME' | translate),
                        usrLnamePlaceholder: ('club.PLAYER_LAST_NAME' | translate),
                        addUserButtonText: ('club.ADD_PLAYER_BUTTON' | translate),
                        removeUserButtonText: ('club.REMOVE_PLAYER_BUTTON' | translate),
                        editUserButtonText: ('club.EDIT_PLAYER_BUTTON' | translate)
                    }">
                        </ng-container> -->
                        <ng-container [ngTemplateOutlet]="filteredUsersTemplate" [ngTemplateOutletContext]="{
                            formGroupContext: usersForm,
                            arrFieldName: 'players_arr',
                            userType: appUtils.UserTypes.Player,
                            showEmailField: false,
                            deleteOnClick: deleteUserItem,
                            editOnClick: editUserItem,
                            saveOnClick: saveUserItem,
                            cancelOnClick: cancelEditUserItem,
                            usrFnamePlaceholder: ('club.PLAYER_FIRST_NAME' | translate),
                            usrLnamePlaceholder: ('club.PLAYER_LAST_NAME' | translate),
                            deleteUserButtonText: ('club.DELETE_PLAYER_BUTTON' | translate),
                            editUserButtonText: ('club.EDIT_PLAYER_BUTTON' | translate)
                        }">
                        </ng-container>
                    </mat-card>
                </form>                
            </mat-tab>

        </mat-tab-group>

        <!-- <button [disabled]="busy || currentEditedForm" mat-raised-button color="primary">{{'club.SAVE' | translate}}</button> -->

</div>

<ng-template #userTemplate let-teamIndex="teamIndex" let-isExpanded="isExpanded" let-arrFieldName="arrFieldName" let-formGroupContext="formGroupContext" let-controlIndex="controlIndex" let-userType="userType" let-showClubField="showClubField" let-showEmailField="showEmailField" let-removeOnClick="removeOnClick" let-deleteOnClick="deleteOnClick" let-editOnClick="editOnClick" let-saveOnClick="saveOnClick" let-cancelOnClick="cancelOnClick"
let-usrFnamePlaceholder="usrFnamePlaceholder" let-usrLnamePlaceholder="usrLnamePlaceholder" let-removeUserButtonText="removeUserButtonText" let-deleteUserButtonText="deleteUserButtonText" let-editUserButtonText="editUserButtonText"
>

    <div class="d-column" [formGroup]="formGroupContext" *ngIf="isExpanded">

        <div class="buttons-row mb75" *ngIf="appState.user.usr_type === 'superadmin' || (appState.user.usr_type === 'admin' && userType !== 'nutri')">
            <button [disabled]="busy || (currentEditedForm && currentEditedForm != formGroupContext.value._id)" *ngIf="!formGroupContext.value.isEditMode" (click)="editOnClick(formContext, arrFieldName, controlIndex)" type="button" mat-raised-button color="accent" mat-button>{{editUserButtonText}} 
                <mat-icon>edit</mat-icon></button>                                        
            <button [disabled]="busy" *ngIf="formGroupContext.value.isEditMode" (click)="saveOnClick(formContext, arrFieldName, controlIndex)" type="button" mat-raised-button color="accent" mat-button>{{'club.SAVE_CHANGES' | translate}}
                <mat-icon>save</mat-icon></button>                                        
            <button [disabled]="busy" *ngIf="formGroupContext.value.isEditMode" (click)="cancelOnClick(formContext, arrFieldName, controlIndex)" type="button" mat-raised-button color="accent" mat-button>{{'club.CANCEL_CHANGES' | translate}}
                <mat-icon>undo</mat-icon></button>
            <button *ngIf="deleteUserButtonText" [disabled]="busy || currentEditedForm" (click)="deleteOnClick(formContext, arrFieldName, controlIndex)" type="button" mat-raised-button color="warn" mat-button>{{deleteUserButtonText}}
                <mat-icon>remove_circle_outline</mat-icon></button>
            <button *ngIf="removeUserButtonText" [disabled]="busy || currentEditedForm" (click)="removeOnClick(formContext, teamIndex, formGroupContext)" type="button" mat-raised-button color="warn" mat-button>{{removeUserButtonText}}
                    <mat-icon>remove_circle_outline</mat-icon></button>
        </div>

        <mat-form-field class="w45">
            <input [readonly]="!formGroupContext.value.isEditMode" formControlName="usr_fname" matInput placeholder="{{usrFnamePlaceholder}}">
        </mat-form-field>

        <mat-form-field class="w45">
            <input [readonly]="!formGroupContext.value.isEditMode" formControlName="usr_lname" matInput placeholder="{{usrLnamePlaceholder}}">
        </mat-form-field>

        <mat-form-field class="w45" *ngIf="showClubField && appState.user.usr_type === 'superadmin' && userType !== 'nutri'" [ngClass]="{'block-select': !formGroupContext.value.isEditMode}">
            <mat-select formControlName="usr_club_id" placeholder="שיוך למועדון">
                <mat-option [value]="''">ללא שיוך</mat-option>
                <ng-container *ngFor="let val of clubsForm.value.clubs_arr">
                    <mat-option [value]="val._id">
                        {{ val['clb_name'] }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w45" *ngIf="showClubField && appState.user.usr_type === 'superadmin' && userType === 'nutri'" [ngClass]="{'block-select': !formGroupContext.value.isEditMode}">
            <mat-select multiple formControlName="usr_club_ids" placeholder="שיוך למועדון">
                <!-- <mat-option [value]="''">ללא שיוך</mat-option> -->
                <ng-container *ngFor="let val of clubsForm.value.clubs_arr">
                    <mat-option [value]="val._id">
                        {{ val['clb_name'] }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w45">
            <input [readonly]="true" formControlName="usr_phone" matInput placeholder="{{'club.PHONE' | translate}}">
        </mat-form-field>

        <mat-form-field *ngIf="showEmailField" class="w45">
            <input [readonly]="!formGroupContext.value.isEditMode" formControlName="usr_email" matInput placeholder="{{'club.EMAIL' | translate}}">
        </mat-form-field>

        <!-- <div class="buttons-row" *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin'">
            <button [disabled]="busy || currentEditedForm" (click)="removeOnClick(formContext, arrFieldName, controlIndex)" type="button" mat-raised-button color="warn" mat-button>{{removeUserButtonText}}
                <mat-icon>remove_circle_outline</mat-icon></button>
        </div> -->
    </div>

</ng-template>

<ng-template #filteredUsersTemplate let-idsArr="idsArr" let-teamIndex="teamIndex" let-clubId="clubId" let-formGroupContext="formGroupContext" let-arrFieldName="arrFieldName" let-userType="userType" let-showEmailField="showEmailField" let-removeOnClick="removeOnClick" let-deleteOnClick="deleteOnClick" let-editOnClick="editOnClick" let-saveOnClick="saveOnClick" let-cancelOnClick="cancelOnClick"
let-usrFnamePlaceholder="usrFnamePlaceholder" let-usrLnamePlaceholder="usrLnamePlaceholder" let-removeUserButtonText="removeUserButtonText" let-deleteUserButtonText="deleteUserButtonText" let-editUserButtonText="editUserButtonText"
>
    <mat-card [formGroup]="formGroupContext">
        <mat-accordion [multi]="true">

            <ng-container *ngFor="let control of usersForm.get(arrFieldName).controls; let i = index; let last = last;">
                <mat-expansion-panel [disabled]="busy" #exp *ngIf="(!idsArr && !clubId) || (clubId && (control.value.usr_club_id === clubId || (control.value.usr_club_ids && control.value.usr_club_ids.includes(clubId)))) || (idsArr && idsArr.includes(control.value._id))">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h4>
                                {{ (control.value.usr_fname || control.value.usr_lname) ? control.value.usr_fname + ' ' + control.value.usr_lname : control.value.usr_phone }}
                            </h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ng-container [ngTemplateOutlet]="userTemplate" [ngTemplateOutletContext]="{
                        isExpanded: exp.expanded,
                        controlIndex: i,
                        teamIndex: teamIndex,
                        formGroupContext: control,
                        arrFieldName: arrFieldName,
                        userType: userType,
                        showClubField: (!idsArr && !clubId),
                        showEmailField: showEmailField,
                        removeOnClick: removeOnClick,
                        deleteOnClick: deleteOnClick,
                        editOnClick: editUserItem,
                        saveOnClick: saveUserItem,
                        cancelOnClick: cancelEditUserItem,
                        usrFnamePlaceholder: usrFnamePlaceholder,
                        usrLnamePlaceholder: usrLnamePlaceholder,
                        removeUserButtonText: removeUserButtonText,
                        deleteUserButtonText: deleteUserButtonText,
                        editUserButtonText: editUserButtonText
                    }">
                    </ng-container>

                </mat-expansion-panel>
            </ng-container>

            <!-- <mat-expansion-panel #exp formArrayName="{{arrFieldName}}" *ngFor="let control of context.usersForm.get(arrFieldName).controls; let i = index; let last = last;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h4>
                            {{ (control.value.usr_fname || control.value.usr_lname) ? control.value.usr_fname + ' ' + control.value.usr_lname : control.value.usr_phone }}
                        </h4>
                    </mat-panel-title>
                </mat-expansion-panel-header>

            </mat-expansion-panel> -->
        </mat-accordion>

        <div *ngIf="!usersForm.get(arrFieldName).controls.length" class="text-center font-bold">
            {{ "club.NO_DATA_FOUND" | translate }}
        </div>

    </mat-card>

</ng-template>

<ng-template #teamTemplate let-isExpanded="isExpanded" let-arrFieldName="arrFieldName" let-formGroupContext="formGroupContext" let-controlIndex="controlIndex" let-onAddUserClick="onAddUserClick" let-onRemoveUserClick="onRemoveUserClick" let-removeOnClick="removeOnClick" let-saveOnClick="saveOnClick">
    <!-- <mat-card [formGroup]="formGroupContext">
        <mat-accordion [multi]="true">
            <mat-expansion-panel #exp formArrayName="{{arrFieldName}}" *ngFor="let control of context.teamsForm.get(arrFieldName).controls; let i = index; let last = last;">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <h3>{{ control.value.tem_name || 'קבוצה ללא שם' }}</h3>
                    </mat-panel-title>
                </mat-expansion-panel-header> -->
                
            <!-- </mat-expansion-panel>
        </mat-accordion> -->
        <!-- <div class="repeat-container" formArrayName="{{arrFieldName}}" *ngFor="let control of context.teamsForm.get(arrFieldName).controls; let i = index; let last = last;"> -->
            <!-- <div class="spinner" *ngIf="exp.expanded && !control.value._id">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div> -->

            <div [formGroup]="formGroupContext" *ngIf="isExpanded">
                                     
                <div class="buttons-row mb75" *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin'">
                    <button [disabled]="busy || currentEditedForm" (click)="saveOnClick(formContext, arrFieldName, controlIndex)" type="button" mat-raised-button color="accent" mat-button>{{'club.SAVE_CHANGES' | translate}}
                    <mat-icon>save</mat-icon></button>
                </div>
                <div class="row m-0">
                    <mat-form-field class="w45">
                        <input [readonly]="!(appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin')" formControlName="tem_name" matInput placeholder="{{'club.TEAM_NAME' | translate}}">
                    </mat-form-field>
                    
                    <!-- <mat-form-field class="w45" *ngIf="appState.user.usr_type === 'superadmin'" [ngClass]="{'block-select': busy}">
                        <mat-select formControlName="tem_club_id" placeholder="שיוך למועדון">
                            <mat-option [value]="''">ללא שיוך</mat-option>
                            <ng-container *ngFor="let val of clubsForm.value.clubs_arr">
                                <mat-option [value]="val._id">
                                    {{ val['clb_name'] }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field> -->
                </div>   

                <mat-accordion [multi]="true" [displayMode]="'flat'">

                <ng-container *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin'">
                <!-- nutris list expansion panel: -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>{{'club.TEAM_NUTRIS' | translate}}</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div *ngIf="appState.user.usr_type === 'superadmin'">
                        <mat-form-field class="w45">
                            <input type="text" placeholder="{{'club.SELECT_NUTRI' | translate}}" matInput formControlName="extra_field_input_nutris" [matAutocomplete]="nutrisAutoComplete">
                            <mat-autocomplete #nutrisAutoComplete="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let option of formGroupContext.value.extra_field_nutris_options | async" [value]="option">
                                {{option.usr_fname}} {{option.usr_lname}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>                                                     
                        <button [disabled]="busy || currentEditedForm" (click)="onAddUserClick(formContext, arrFieldName, controlIndex, 'tem_nutris_ids_arr', formGroupContext.value.extra_field_input_nutris, 'club.NUTRI_CHOOSE_FROM_AC_LIST', 'club.NUTRI_EXIST_IN_TEAM')" type="button" mat-raised-button color="primary" mat-button>{{'club.ADD_NUTRI_BUTTON' | translate}}
                            <mat-icon>add_circle_outline</mat-icon></button>                                          
                    </div>

                    <ng-container *ngIf="formGroupContext.value.tem_nutris_ids_arr && formGroupContext.value.tem_nutris_ids_arr.length > 0" [ngTemplateOutlet]="filteredUsersTemplate" [ngTemplateOutletContext]="{
                        teamIndex: controlIndex,
                        idsArr: formGroupContext.value.tem_nutris_ids_arr,
                        formGroupContext: usersForm,
                        arrFieldName: 'nutris_arr',
                        userType: appUtils.UserTypes.Nutri,
                        showEmailField: true,
                        removeOnClick: removeUserItem,
                        editOnClick: editUserItem,
                        saveOnClick: saveUserItem,
                        cancelOnClick: cancelEditUserItem,
                        usrFnamePlaceholder: ('club.NUTRI_FIRST_NAME' | translate),
                        usrLnamePlaceholder: ('club.NUTRI_LAST_NAME' | translate),
                        removeUserButtonText: ('club.REMOVE_NUTRI_BUTTON' | translate),
                        editUserButtonText: ('club.EDIT_NUTRI_BUTTON' | translate)
                    }">

                        <!-- <h4>{{'club.NUTRI_LIST' | translate}}</h4>

                        <ng-container *ngFor="let user of (nutris | userFilter : control.value.tem_nutris_ids_arr)">
                            <mat-grid-list cols="2" rowHeight="55px">
                                <mat-grid-tile                                            
                                [colspan]="1"
                                [rowspan]="1"
                                [style.background]="'#F2F2F2'">
                                    <span>{{user.usr_fname}} {{user.usr_lname}}</span>                                          
                                </mat-grid-tile>
                                <mat-grid-tile                                            
                                [colspan]="1"
                                [rowspan]="1"
                                [style.background]="'#F2F2F2'">
                                    <button [disabled]="context.busy" (click)="onRemoveUserClick(context, arrFieldName, i, 'tem_nutris_ids_arr', user._id)" type="button" mat-raised-button color="warn" mat-button>{{'club.REMOVE_NUTRI_BUTTON' | translate}}
                                        <mat-icon>remove_circle_outline</mat-icon></button>                                            
                                </mat-grid-tile>
                            </mat-grid-list>      
                        </ng-container> -->

                    </ng-container>

                </mat-expansion-panel>

                </ng-container>
                
                <ng-container *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin'">
                <!-- <span class="category-gap"></span> -->

                <!-- coaches list expansion panel: -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>{{'club.TEAM_COACHES' | translate}}</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div>
                        <mat-form-field class="w45">
                            <input type="text" placeholder="{{'club.SELECT_COACH' | translate}}" matInput formControlName="extra_field_input_coaches" [matAutocomplete]="coachesAutoComplete">
                            <mat-autocomplete #coachesAutoComplete="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let option of formGroupContext.value.extra_field_coaches_options | async" [value]="option">
                                {{option.usr_fname}} {{option.usr_lname}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>                                                     
                        <button [disabled]="busy || currentEditedForm" (click)="onAddUserClick(formContext, arrFieldName, controlIndex, 'tem_coaches_ids_arr', formGroupContext.value.extra_field_input_coaches, 'club.COACH_CHOOSE_FROM_AC_LIST', 'club.COACH_EXIST_IN_TEAM')" type="button" mat-raised-button color="primary" mat-button>{{'club.ADD_COACH_BUTTON' | translate}}
                            <mat-icon>add_circle_outline</mat-icon></button>                                          
                    </div>

                    <ng-container *ngIf="formGroupContext.value.tem_coaches_ids_arr && formGroupContext.value.tem_coaches_ids_arr.length > 0" [ngTemplateOutlet]="filteredUsersTemplate" [ngTemplateOutletContext]="{
                        teamIndex: controlIndex,
                        idsArr: formGroupContext.value.tem_coaches_ids_arr,
                        formGroupContext: usersForm,
                        arrFieldName: 'coaches_arr',
                        userType: appUtils.UserTypes.Coach,
                        showEmailField: true,
                        removeOnClick: removeUserItem,
                        editOnClick: editUserItem,
                        saveOnClick: saveUserItem,
                        cancelOnClick: cancelEditUserItem,
                        usrFnamePlaceholder: ('club.COACH_FIRST_NAME' | translate),
                        usrLnamePlaceholder: ('club.COACH_LAST_NAME' | translate),
                        removeUserButtonText: ('club.REMOVE_COACH_BUTTON' | translate),
                        editUserButtonText: ('club.EDIT_COACH_BUTTON' | translate)
                    }">
                    </ng-container>

                    <!-- <ng-container *ngIf="control.value.tem_coaches_ids_arr && control.value.tem_coaches_ids_arr.length > 0">

                        <h4>{{'club.COACH_LIST' | translate}}</h4>
                        
                        <ng-container *ngFor="let user of (coaches | userFilter : control.value.tem_coaches_ids_arr)">
                            <mat-grid-list cols="2" rowHeight="55px">
                                <mat-grid-tile                                            
                                [colspan]="1"
                                [rowspan]="1"
                                [style.background]="'#F2F2F2'">
                                    <span>{{user.usr_fname}} {{user.usr_lname}}</span>                                          
                                </mat-grid-tile>
                                <mat-grid-tile                                            
                                [colspan]="1"
                                [rowspan]="1"
                                [style.background]="'#F2F2F2'">
                                    <button [disabled]="context.busy" (click)="onRemoveUserClick(context, arrFieldName, i, 'tem_coaches_ids_arr', user._id)" type="button" mat-raised-button color="warn" mat-button>{{'club.REMOVE_COACH_BUTTON' | translate}}
                                        <mat-icon>remove_circle_outline</mat-icon></button>                                            
                                </mat-grid-tile>
                            </mat-grid-list>      
                        </ng-container>

                    </ng-container> -->

                </mat-expansion-panel>

                <!-- <span class="category-gap"></span> -->
                </ng-container>
                
                <!-- players list expansion panel: -->
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>{{'club.TEAM_PLAYERS' | translate}}</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin'">
                        <mat-form-field class="w45">
                            <input type="text" placeholder="{{'club.SELECT_PLAYER' | translate}}" matInput formControlName="extra_field_input_players" [matAutocomplete]="playersAutoComplete">
                            <mat-autocomplete #playersAutoComplete="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let option of formGroupContext.value.extra_field_players_options | async" [value]="option">
                                {{option.usr_fname}} {{option.usr_lname}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>                                                     
                        <button [disabled]="busy || currentEditedForm" (click)="onAddUserClick(formContext, arrFieldName, controlIndex, 'tem_players_ids_arr', formGroupContext.value.extra_field_input_players, 'club.PLAYER_CHOOSE_FROM_AC_LIST', 'club.PLAYER_EXIST_IN_TEAM')" type="button" mat-raised-button color="primary" mat-button>{{'club.ADD_PLAYER_BUTTON' | translate}}
                            <mat-icon>add_circle_outline</mat-icon></button>                                          
                    </div>

                    <ng-container *ngIf="formGroupContext.value.tem_players_ids_arr && formGroupContext.value.tem_players_ids_arr.length > 0" [ngTemplateOutlet]="filteredUsersTemplate" [ngTemplateOutletContext]="{
                        teamIndex: controlIndex,
                        idsArr: formGroupContext.value.tem_players_ids_arr,
                        formGroupContext: usersForm,
                        arrFieldName: 'players_arr',
                        userType: appUtils.UserTypes.Player,
                        showEmailField: false,
                        removeOnClick: removeUserItem,
                        editOnClick: editUserItem,
                        saveOnClick: saveUserItem,
                        cancelOnClick: cancelEditUserItem,
                        usrFnamePlaceholder: ('club.PLAYER_FIRST_NAME' | translate),
                        usrLnamePlaceholder: ('club.PLAYER_LAST_NAME' | translate),
                        removeUserButtonText: ('club.REMOVE_PLAYER_BUTTON' | translate),
                        editUserButtonText: ('club.EDIT_PLAYER_BUTTON' | translate)
                    }">
                    </ng-container>

                    <!-- <ng-container *ngIf="control.value.tem_players_ids_arr && control.value.tem_players_ids_arr.length > 0">

                        <h4>{{'club.PLAYER_LIST' | translate}}</h4>     

                        <ng-container *ngFor="let user of (players | userFilter : control.value.tem_players_ids_arr)">
                            <mat-grid-list cols="2" rowHeight="55px">
                                <mat-grid-tile                                            
                                [colspan]="1"
                                [rowspan]="1"
                                [style.background]="'#F2F2F2'">
                                    <span>{{user.usr_fname}} {{user.usr_lname}}</span>                                          
                                </mat-grid-tile>
                                <mat-grid-tile                                            
                                [colspan]="1"
                                [rowspan]="1"
                                [style.background]="'#F2F2F2'">
                                    <button *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin'" [disabled]="context.busy" (click)="onRemoveUserClick(context, arrFieldName, i, 'tem_players_ids_arr', user._id)" type="button" mat-raised-button color="warn" mat-button>{{'club.REMOVE_PLAYER_BUTTON' | translate}}
                                        <mat-icon>remove_circle_outline</mat-icon></button>                                           
                                </mat-grid-tile>
                            </mat-grid-list>      
                        </ng-container>

                    </ng-container> -->

                </mat-expansion-panel>

                <span class="category-gap"></span>
                
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>{{'club.TEAM_PRACTICES' | translate}}</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <team-practice *ngIf="formGroupContext.value._id" [parentFormId]="formGroupContext.value._id" [restService]="teamPracticeRestService"></team-practice>

                </mat-expansion-panel>

                <span class="category-gap"></span>                                    

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>{{'club.TEAM_REPORT_GAME_MINUTES' | translate}}</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <team-report *ngIf="formGroupContext.value._id" #teamReportEl [busy]="busy" [parentFormId]="formGroupContext.value._id" [restService]="teamReportRestService" [players]="players" [teamPlayersIds]="formGroupContext.value.tem_players_ids_arr"></team-report>
                                                               

                </mat-expansion-panel>

                </mat-accordion>

                <span class="category-gap"></span>

                <div class="buttons-row" *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin'">
                    <button [disabled]="busy || currentEditedForm" (click)="removeOnClick(formContext, arrFieldName, controlIndex)" type="button" mat-raised-button color="warn" mat-button>{{'club.DELETE_TEAM_BUTTON' | translate}}
                        <mat-icon>remove_circle_outline</mat-icon></button>
                </div>
            </div>

        <!-- </div> -->                
<!-- 
            </mat-expansion-panel>
        </mat-accordion>

        <ng-container *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin'">
            <ng-container *ngIf="appState.user.usr_type === 'superadmin'">
                <h4 class="m-b-0">הוספת קבוצה</h4>
            </ng-container>
            <div class="add-button-container row m-t-0">
                <mat-form-field class="w45 m-l-15" *ngIf="appState.user.usr_type === 'superadmin'">
                    <mat-select placeholder="שיוך למועדון" [(ngModel)]="chosenClub" [ngModelOptions]="{standalone: true}" required>
                        <ng-container *ngFor="let val of context.clubsForm.value.clubs_arr">
                            <mat-option [value]="val._id">
                                {{ val['clb_name'] }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                    <mat-error>
                        אנא בחר מועדון
                    </mat-error>
                </mat-form-field>
                <button class="w45" [disabled]="busy || currentEditedForm || !chosenClub" (click)="addOnClick(context, arrFieldName)" type="button" mat-raised-button color="primary" mat-button>{{'club.ADD_TEAM_BUTTON' | translate}}<mat-icon>add_circle_outline</mat-icon>
                </button>
            </div>
        </ng-container>
    </mat-card> -->

</ng-template>

<ng-template #clubTemplate let-isExpanded="isExpanded" let-arrFieldName="arrFieldName" let-formGroupContext="formGroupContext" let-controlIndex="controlIndex" let-removeOnClick="removeOnClick" let-saveOnClick="saveOnClick">

    <!-- <mat-card [formGroup]="formGroupContext">


    <mat-accordion [multi]="true">
        <mat-expansion-panel #exp formArrayName="{{arrFieldName}}" *ngFor="let control of context.clubsForm.get(arrFieldName).controls; let i = index; let last = last;">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    <h3>{{ control.value.clb_name || 'מועדון ללא שם' }}</h3>
                </mat-panel-title>
            </mat-expansion-panel-header> -->
        <!-- <div class="repeat-container" formArrayName="{{arrFieldName}}" *ngFor="let control of context.clubsForm.get(arrFieldName).controls; let i = index;"> -->
            <div [formGroup]="formGroupContext" *ngIf="isExpanded">
                <div>
                    <mat-form-field class="w45">
                        <input formControlName="clb_name" matInput placeholder="{{'club.CLUB_NAME' | translate}}">
                    </mat-form-field>
                </div>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>{{'club.CLUB_TEAMS' | translate}}</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div>
                        <mat-accordion [multi]="true">
                            <ng-container *ngFor="let control of teamsForm.get('teams_arr').controls; let i = index; let last = last;">
                                <mat-expansion-panel [disabled]="busy" #exp *ngIf="control.value.tem_club_id === formGroupContext.value._id">
                                    <mat-expansion-panel-header>
                                        <mat-panel-title>
                                            <h3>{{ control.value.tem_name || 'קבוצה ללא שם' }}</h3>
                                        </mat-panel-title>
                                    </mat-expansion-panel-header>

                                    <ng-container [ngTemplateOutlet]="teamTemplate" [ngTemplateOutletContext]="{
                                        isExpanded: exp.expanded,
                                        arrFieldName: 'teams_arr',
                                        controlIndex: i,
                                        formGroupContext: control,
                                        onAddUserClick: addTeamUser,
                                        onRemoveUserClick: removeTeamUser,
                                        removeOnClick: removeTeamItem,
                                        saveOnClick: saveTeamItem
                                    }">
                                    </ng-container>
                                    
                                </mat-expansion-panel>
                            </ng-container>
                        </mat-accordion>
                        <div class="add-button-container row m-t-0">
                            <button class="w45" [disabled]="busy || currentEditedForm || !formGroupContext.value._id" (click)="addTeamItem(formContext, 'teams_arr', formGroupContext.value._id)" type="button" mat-raised-button color="primary" mat-button>{{'club.ADD_TEAM_BUTTON' | translate}}<mat-icon>add_circle_outline</mat-icon>
                            </button>
                        </div>
                    </div>

                </mat-expansion-panel>

                <span class="category-gap"></span>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>{{'club.CLUB_ADMINS' | translate}}</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <ng-container *ngIf="formGroupContext.value._id" [ngTemplateOutlet]="filteredUsersTemplate" [ngTemplateOutletContext]="{
                        clubId: formGroupContext.value._id,
                        formGroupContext: usersForm,
                        arrFieldName: 'admins_arr',
                        userType: appUtils.UserTypes.Admin,
                        showEmailField: true,
                        removeOnClick: removeUserItem,
                        editOnClick: editUserItem,
                        saveOnClick: saveUserItem,
                        cancelOnClick: cancelEditUserItem,
                        usrFnamePlaceholder: ('club.ADMIN_FIRST_NAME' | translate),
                        usrLnamePlaceholder: ('club.ADMIN_LAST_NAME' | translate),
                        removeUserButtonText: '',
                        editUserButtonText: ('club.EDIT_ADMIN_BUTTON' | translate)
                    }">
                    </ng-container>

                    <!-- <div> -->
                        <!-- <mat-form-field class="w45">
                            <input type="text" placeholder="{{'club.SELECT_ADMIN' | translate}}" matInput formControlName="extra_field_input_admins" [matAutocomplete]="adminsAutoComplete">
                            <mat-autocomplete #adminsAutoComplete="matAutocomplete" [displayWith]="displayFn">
                                <mat-option *ngFor="let option of control.value.extra_field_admins_options | async" [value]="option">
                                {{option.usr_fname}} {{option.usr_lname}}
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field> -->
                        <!-- <button [disabled]="context.busy" (click)="onAddAdminClick(context, arrFieldName, i, 'clb_admins_ids_arr', control.value.extra_field_input_admins, 'club.ADMIN_CHOOSE_FROM_AC_LIST', 'club.ADMIN_EXIST_IN_CLUB')" type="button" mat-raised-button color="primary" mat-button>{{'club.ADD_ADMIN_BUTTON' | translate}}
                            <mat-icon>add_circle_outline</mat-icon></button> -->
                    <!-- </div> -->

                    <!-- <ng-container *ngIf="control.value.clb_admins_ids_arr && control.value.clb_admins_ids_arr.length > 0"> -->

                        <!-- <h4>{{'club.ADMIN_LIST' | translate}}</h4> -->
                                                                
                        <!-- <ng-container *ngFor="let user of getSubsetArrayFromIds(admins, control.value.clb_admins_ids_arr);"> -->
                        <!-- <ng-container *ngFor="let user of (admins | clubFilter : control.value._id : 'usr_club_id')">
                            <mat-grid-list cols="2" rowHeight="55px">
                                <mat-grid-tile                                            
                                [colspan]="1"
                                [rowspan]="1"
                                [style.background]="'#F2F2F2'">
                                    <span>{{user.usr_fname}} {{user.usr_lname}}</span>                                          
                                </mat-grid-tile>
                                <mat-grid-tile                                            
                                [colspan]="1"
                                [rowspan]="1"
                                [style.background]="'#F2F2F2'">
                                </mat-grid-tile>
                            </mat-grid-list>      
                        </ng-container> -->

                    <!-- </ng-container> -->
                </mat-expansion-panel>

                <span class="category-gap"></span>

                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>{{'club.CLUB_NUTRIS' | translate}}</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    
                    <div>
                        <mat-slide-toggle formControlName="clb_is_other_nut">מלווה על ידי תזונאי אחר</mat-slide-toggle>
                    </div>

                    <ng-container *ngIf="formGroupContext.value._id" [ngTemplateOutlet]="filteredUsersTemplate" [ngTemplateOutletContext]="{
                        clubId: formGroupContext.value._id,
                        formGroupContext: usersForm,
                        arrFieldName: 'nutris_arr',
                        userType: appUtils.UserTypes.Nutri,
                        showEmailField: true,
                        removeOnClick: removeUserItem,
                        editOnClick: editUserItem,
                        saveOnClick: saveUserItem,
                        cancelOnClick: cancelEditUserItem,
                        usrFnamePlaceholder: ('club.NUTRI_FIRST_NAME' | translate),
                        usrLnamePlaceholder: ('club.NUTRI_LAST_NAME' | translate),
                        removeUserButtonText: '',
                        editUserButtonText: ('club.EDIT_NUTRI_BUTTON' | translate)
                    }">
                    </ng-container>
                </mat-expansion-panel>

                <span class="category-gap"></span>

                <!-- <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            <h3>{{'club.CLUB_SETTINGS' | translate}}</h3>
                        </mat-panel-title>
                    </mat-expansion-panel-header>

                    <div>
                        <mat-slide-toggle formControlName="clb_measure_enabled">{{'club.CLUB_MEASURE_ENABLED' | translate}}</mat-slide-toggle>
                    </div>

                </mat-expansion-panel> -->

                <!-- <span class="category-gap"></span> -->

                <div class="buttons-row mt125">

                    <button [disabled]="busy || currentEditedForm" (click)="saveOnClick(formContext, arrFieldName, controlIndex)" type="button" mat-raised-button color="accent" mat-button>{{'club.SAVE_CHANGES' | translate}}
                    <mat-icon>save</mat-icon></button>

                    <button [disabled]="busy || currentEditedForm" (click)="removeOnClick(formContext, arrFieldName, controlIndex)" type="button" mat-raised-button color="warn" mat-button>{{'club.DELETE_CLUB_BUTTON' | translate}}
                        <mat-icon>remove_circle_outline</mat-icon></button>

                </div>
            </div>

        <!-- </div> -->

        <!-- </mat-expansion-panel>
    </mat-accordion>

        <div class="add-button-container">
            <button [disabled]="busy || currentEditedForm" (click)="addOnClick(context, arrFieldName)" type="button" mat-raised-button color="primary" mat-button>{{'club.ADD_CLUB_BUTTON' | translate}}<mat-icon>add_circle_outline</mat-icon>
            </button>
        </div>


    </mat-card> -->

</ng-template>
