<div class="full-loader" *ngIf="busy || (selectedTab === 10 && submitInProgress)">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>

<div class="quiz nutquiz" [ngClass]="{'loading-margin': (selectedTab === 10 && submitInProgress)}">

    <form novalidate class="basic-form" [formGroup]="nutritionquizForm" (ngSubmit)="onSubmit(nutritionquizForm, quizPrefix)">

        <div class="row">
            <h1>{{'nutrition.QUIZ_TITLE' | translate}}</h1>
            
            <button type="button" *ngIf="appState.user && appState.user.usr_type == 'parent'" class="plus-button" mat-raised-button [routerLink]="['/Userparent']">
                <span>{{'general.undo' | translate}} </span>
                <mat-icon>undo</mat-icon>
            </button>
        </div>

        <mat-card *ngIf="!nutritionquizForm.value._id">
            <div class="spinner">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
        </mat-card>

        <div *ngIf="nutritionquizForm.value._id">
            
        <mat-tab-group [(selectedIndex)]="selectedTab" (selectedTabChange)="refreshCategories()">
            <mat-tab [disabled]="busy" label="{{ 'nutquiz.SECTION_1_TITLE' | translate }}">
            
            <h2 class="sticky-header">
                {{ 'nutquiz.SECTION_1_TITLE' | translate }}
            </h2>

            <span #nuq_style_el></span>
            
            <mat-card>

                <h4>{{'nutquiz.Q_1' | translate}}</h4>
                <div class="mb50">
                    <mat-radio-group class="d-column" formControlName="nuq_style">
                        <mat-radio-button value="הכל">{{ 'nutquiz.Q_1_A_1' | translate }}</mat-radio-button>
                        <mat-radio-button value="דגים">{{ 'nutquiz.Q_1_A_2' | translate }}</mat-radio-button>
                        <mat-radio-button value="צמחוני">{{ 'nutquiz.Q_1_A_3' | translate }}</mat-radio-button>
                        <mat-radio-button value="טבעוני">{{ 'nutquiz.Q_1_A_4' | translate }}</mat-radio-button>
                        <mat-radio-button value="אחר">{{ 'nutquiz.Q_1_A_5' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-form-field *ngIf="nutritionquizForm.value.nuq_style == 'אחר'" class="w65">
                    <input formControlName="nuq_style_other" matInput placeholder="{{ 'general.description' | translate }}">
                </mat-form-field>

                <ng-container *ngIf="nutritionquizForm.value.nuq_style == 'דגים' || nutritionquizForm.value.nuq_style == 'צמחוני' || nutritionquizForm.value.nuq_style == 'טבעוני'">
                    <h4>
                        <span>{{'nutquiz.Q_2' | translate}}</span>
                        <span *ngIf="nutritionquizForm.value.nuq_style === 'דגים'">
                            {{'nutquiz.Q_1_A_2' | translate}}
                        </span>
                        <span *ngIf="nutritionquizForm.value.nuq_style === 'צמחוני'">
                            {{'nutquiz.Q_1_A_3' | translate}}
                        </span>
                        <span *ngIf="nutritionquizForm.value.nuq_style === 'טבעוני'">
                            {{'nutquiz.Q_1_A_4' | translate}}
                        </span>
                        <span>?</span>
                    </h4>
                    <div class="mb50">
                        <mat-checkbox class="checkbox normal" (change)="checkedFnArray('מאמין שזה בריא יותר', $event.checked, 'nuq_food_does_not_eat_reason_arr')" [checked]="nutritionquizForm.value.nuq_food_does_not_eat_reason_arr?.includes('מאמין שזה בריא יותר')" [disabled]="isLocked">{{ 'nutquiz.Q_2_A_1' | translate }}</mat-checkbox>
                        <mat-checkbox class="checkbox normal" (change)="checkedFnArray('מטעמי איכות הסביבה', $event.checked, 'nuq_food_does_not_eat_reason_arr')" [checked]="nutritionquizForm.value.nuq_food_does_not_eat_reason_arr?.includes('מטעמי איכות הסביבה')" [disabled]="isLocked">{{ 'nutquiz.Q_2_A_2' | translate }}</mat-checkbox>
                        <mat-checkbox class="checkbox normal" (change)="checkedFnArray('לא רוצה לפגוע בחיות', $event.checked, 'nuq_food_does_not_eat_reason_arr')" [checked]="nutritionquizForm.value.nuq_food_does_not_eat_reason_arr?.includes('לא רוצה לפגוע בחיות')" [disabled]="isLocked">{{ 'nutquiz.Q_2_A_3' | translate }}</mat-checkbox>
                        <mat-checkbox class="checkbox normal" (change)="checkedFnArray('אחר', $event.checked, 'nuq_food_does_not_eat_reason_arr')" [checked]="nutritionquizForm.value.nuq_food_does_not_eat_reason_arr?.includes('אחר')" [disabled]="isLocked">{{ 'nutquiz.Q_2_A_4' | translate }}</mat-checkbox>
                    </div>
                    <mat-form-field *ngIf="nutritionquizForm.value.nuq_food_does_not_eat_reason_arr?.includes('אחר')" class="w65">
                        <input formControlName="nuq_food_does_not_eat_reason_other" matInput placeholder="{{ 'general.description' | translate }}">
                    </mat-form-field>
                </ng-container>

            </mat-card>

            <br>

            <mat-card *ngIf="!(listCantEat.length && listCantAvoid.length)">
                <div class="spinner">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                </div>
            </mat-card>

            <mat-card *ngIf="listCantEat.length && listCantAvoid.length">

                <h4>{{ 'nutquiz.Q_3' | translate }}</h4>
                
                <mat-checkbox class="checkbox normal" formControlName="nuq_cant_eat_all">{{ 'nutquiz.Q_3_A_1' | translate }}</mat-checkbox>
                
                <ng-container *ngFor="let opt of listCantEat">
                    <mat-checkbox class="checkbox normal" (change)="checkedFnArray(opt.value, $event.checked, 'nuq_cant_eat_arr')" [checked]="nutritionquizForm.value.nuq_cant_eat_arr?.includes(opt.value)" [disabled]="isLocked || nutritionquizForm.value.nuq_cant_eat_all">{{opt.text}}</mat-checkbox>
                </ng-container>

                <mat-checkbox class="checkbox normal" formControlName="nuq_cant_eat_is_other">{{ 'nutquiz.Q_3_A_2' | translate }}</mat-checkbox>

                <mat-form-field *ngIf="nutritionquizForm.value.nuq_cant_eat_is_other" class="w65">
                    <input formControlName="nuq_cant_eat_other" matInput placeholder="{{ 'general.description' | translate }}">
                </mat-form-field>


                <h4>{{ 'nutquiz.Q_4' | translate }}</h4>
                
                <mat-checkbox class="checkbox normal" formControlName="nuq_cant_avoid_all">{{ 'nutquiz.Q_4_A_1' | translate }}</mat-checkbox>
                
                <ng-container *ngFor="let opt of listCantAvoid">
                    <mat-checkbox class="checkbox normal" (change)="checkedFnArray(opt.value, $event.checked, 'nuq_cant_avoid_arr')" [checked]="nutritionquizForm.value.nuq_cant_avoid_arr?.includes(opt.value)" [disabled]="isLocked || nutritionquizForm.value.nuq_cant_avoid_all">{{opt.text}}</mat-checkbox>
                </ng-container>

                <mat-checkbox class="checkbox normal" formControlName="nuq_cant_avoid_is_other">{{ 'nutquiz.Q_4_A_2' | translate }}</mat-checkbox>

                <mat-form-field *ngIf="nutritionquizForm.value.nuq_cant_avoid_is_other" class="w65">
                    <input formControlName="nuq_cant_avoid_other" matInput placeholder="{{ 'general.description' | translate }}">
                </mat-form-field>

            </mat-card>

            <br>

            </mat-tab>
            <mat-tab [disabled]="busy" label="{{ 'nutquiz.SECTION_2_TITLE' | translate }}">

            <h2 class="sticky-header">
                {{ 'nutquiz.SECTION_2_TITLE' | translate }}
            </h2>

            <mat-card>
        
                <ng-template #newFoodTemplate let-context="context" let-arrFieldName="arrFieldName" let-fieldName="fieldName" let-listFixed="listFixed">
                
                    <mat-card class="mat-elevation-z3 mt125">
                
                        <mat-form-field>
                            <mat-select multiple [formControlName]="arrFieldName+'_fixed'" placeholder="{{'general.description' | translate}}">
                                <mat-option *ngFor="let item of listFixed" [value]="item.value">{{ item.text }}</mat-option>
                            </mat-select>
                        </mat-form-field>
        
                        <div class="repeat-container" formArrayName="{{arrFieldName}}" *ngFor="let item of context.nutritionquizForm.get(arrFieldName).controls; let i = index; let last = last;">
                            <div [formGroupName]="i">
                
                                <span #nuq_new_food_description_el id={{arrFieldName}}></span>
                                <div class="mb50">
                                    <mat-form-field class="w65">
                                        <input #nuq_new_food_description_control id={{arrFieldName}} formControlName="description" matInput placeholder="{{ 'nutrition.NEW_FOOD_3' | translate }}">
                                    </mat-form-field>
                                </div>
                                <div class="mb50">
                                    <h4 class="m-t-0 m-b-5">{{'nutrition.NEW_FOOD_4' | translate}}</h4>
                                    <mat-radio-group formControlName="feedback">
                                        <mat-radio-button value="ממש טעים">{{ 'nutrition.NEW_FOOD_5' | translate }}</mat-radio-button>
                                        <mat-radio-button value="בסדר">{{ 'nutrition.NEW_FOOD_6' | translate }}</mat-radio-button>
                                        <mat-radio-button value="לא טעים">{{ 'nutrition.NEW_FOOD_7' | translate }}</mat-radio-button>
                                    </mat-radio-group>                
                                </div>
        
                                <div class="mt125">
                                    <button (click)="removeNewFood(context, fieldName, arrFieldName, i)" type="button" mat-raised-button color="warn" mat-button [disabled]="isLocked">{{ 'nutrition.NEW_FOOD_8' | translate }}<mat-icon>remove_circle_outline</mat-icon></button>
                                </div>
                
                            </div>
                        </div>
                
                        <div class="add-button-container" *ngIf="context.nutritionquizForm.get(arrFieldName+'_fixed').value && context.nutritionquizForm.get(arrFieldName+'_fixed').value.includes('אחר')">
                            <button (click)="addNewFood(context, arrFieldName)" type="button" mat-raised-button color="primary" mat-button [disabled]="isLocked">{{ 'nutrition.NEW_FOOD_9' | translate }}<mat-icon>add_circle_outline</mat-icon></button>
                        </div>
                    </mat-card>
                
                </ng-template>
        
                <span #nuq_new_food_el></span>
                <h3>{{'nutrition.NEW_FOOD' | translate}}</h3>
                <div class="mb50">
                    <mat-radio-group formControlName="nuq_new_food" (change)="newFoodOnChange($event, 'nuq_new_food_arr')">
                        <mat-radio-button value="כן">{{ 'nutrition.NEW_FOOD_1' | translate }}</mat-radio-button>
                        <mat-radio-button value="לא">{{ 'nutrition.NEW_FOOD_2' | translate }}</mat-radio-button>
                    </mat-radio-group>                
                </div>
        
                <ng-container *ngIf="nutritionquizForm.value.nuq_new_food === 'כן'" [ngTemplateOutlet]="newFoodTemplate" [ngTemplateOutletContext]="{
                    context: formContext,
                    fieldName: 'nuq_new_food',
                    arrFieldName: 'nuq_new_food_arr',
                    listFixed: listNewFood
                }">
                </ng-container>

                <span #nuq_eat_vegetables_el></span>
                <h3>{{'nutrition.EAT_VEGETABLES' | translate}}</h3>
                <div class="mb50">
                    <mat-radio-group formControlName="nuq_eat_vegetables">
                        <mat-radio-button value="כן">{{ 'nutrition.EAT_VEGETABLES_1' | translate }}</mat-radio-button>
                        <mat-radio-button value="לא">{{ 'nutrition.EAT_VEGETABLES_2' | translate }}</mat-radio-button>
                    </mat-radio-group>                
                </div>
                <div *ngIf="nutritionquizForm.value.nuq_eat_vegetables === 'כן'" class="w65">
                    <mat-form-field>
                        <mat-select formControlName="nuq_eat_vegetables_day" placeholder="{{'nutrition.EAT_VEGETABLES_3' | translate}}">
                            <mat-option value="0-1">{{ 'nutrition.EAT_VEGETABLES_4' | translate }}</mat-option>
                            <mat-option value="2">{{ 'nutrition.EAT_VEGETABLES_5' | translate }}</mat-option>
                            <mat-option value="3+">{{ 'nutrition.EAT_VEGETABLES_6' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <span #nuq_eat_fruits_el></span>
                <h3>{{'nutrition.EAT_FRUITS' | translate}}</h3>
                <div class="mb50">
                    <mat-radio-group formControlName="nuq_eat_fruits">
                        <mat-radio-button value="כן">{{ 'nutrition.EAT_FRUITS_1' | translate }}</mat-radio-button>
                        <mat-radio-button value="לא">{{ 'nutrition.EAT_FRUITS_2' | translate }}</mat-radio-button>
                    </mat-radio-group>                
                </div>
                <div *ngIf="nutritionquizForm.value.nuq_eat_fruits === 'כן'" class="w65">
                    <mat-form-field>
                        <mat-select formControlName="nuq_eat_fruits_day" placeholder="{{'nutrition.EAT_FRUITS_3' | translate}}">
                            <mat-option value="0-1">{{ 'nutrition.EAT_FRUITS_4' | translate }}</mat-option>
                            <mat-option value="2">{{ 'nutrition.EAT_FRUITS_5' | translate }}</mat-option>
                            <mat-option value="3+">{{ 'nutrition.EAT_FRUITS_6' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <span #nuq_eat_snacks_el></span>
                <h3>{{'nutrition.EAT_SNACKS' | translate}}</h3>
                <div class="mb50">
                    <mat-radio-group formControlName="nuq_eat_snacks">
                        <mat-radio-button value="כן">{{ 'nutrition.EAT_SNACKS_1' | translate }}</mat-radio-button>
                        <mat-radio-button value="לא">{{ 'nutrition.EAT_SNACKS_2' | translate }}</mat-radio-button>
                    </mat-radio-group>                
                </div>
                <div *ngIf="nutritionquizForm.value.nuq_eat_snacks === 'כן'" class="w65">
                    <!-- <mat-form-field>
                        <mat-select multiple formControlName="nuq_eat_snacks_type" placeholder="{{'nutrition.EAT_SNACKS_3' | translate}}">
                            <mat-option value="חטיפים מתוקים">{{ 'nutrition.EAT_SNACKS_4' | translate }}</mat-option>
                            <mat-option value="חטיפים מלוחים">{{ 'nutrition.EAT_SNACKS_5' | translate }}</mat-option>
                            <mat-option value="חטיפים מלוחים ומתוקים">{{ 'nutrition.EAT_SNACKS_6' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    
                    <h3>{{'nutrition.EAT_SNACKS_3' | translate}}</h3>
                    <div class="mb50">
                        <mat-radio-group class="d-column" formControlName="nuq_eat_snacks_type">
                            <mat-radio-button value="חטיפים מתוקים">{{ 'nutrition.EAT_SNACKS_4' | translate }}</mat-radio-button>
                            <mat-radio-button value="חטיפים מלוחים">{{ 'nutrition.EAT_SNACKS_5' | translate }}</mat-radio-button>
                            <mat-radio-button value="חטיפים מלוחים ומתוקים">{{ 'nutrition.EAT_SNACKS_6' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    
                    <h3>{{'nutrition.EAT_SNACKS_7' | translate}}</h3>
                    <div class="mb50">
                        <mat-radio-group class="d-column" formControlName="nuq_eat_snacks_freq">
                            <mat-radio-button value="לעיתים רחוקות">{{ 'nutrition.EAT_SNACKS_8' | translate }}</mat-radio-button>
                            <mat-radio-button value="0-1 בשבוע">{{ 'nutrition.EAT_SNACKS_9' | translate }}</mat-radio-button>
                            <mat-radio-button value="2-3 בשבוע">{{ 'nutrition.EAT_SNACKS_10' | translate }}</mat-radio-button>
                            <mat-radio-button value="4-6 בשבוע">{{ 'nutrition.EAT_SNACKS_11' | translate }}</mat-radio-button>
                            <mat-radio-button value="1 ביום">{{ 'nutrition.EAT_SNACKS_12' | translate }}</mat-radio-button>
                            <mat-radio-button value="1-2 ביום">{{ 'nutrition.EAT_SNACKS_13' | translate }}</mat-radio-button>
                            <mat-radio-button value="2+ ביום">{{ 'nutrition.EAT_SNACKS_14' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
        
                    <ng-container *ngIf="nutritionquizForm.value.nuq_eat_snacks_freq && nutritionquizForm.value.nuq_eat_snacks_freq !== 'לעיתים רחוקות' && nutritionquizForm.value.nuq_eat_snacks_freq !== '0-1 בשבוע'">
                        <h3>{{'nutrition.EAT_SNACKS_15' | translate}}</h3>
                        <div class="mb50">
                            <mat-radio-group class="d-column" formControlName="nuq_eat_snacks_time">
                                <mat-radio-button value="מתכנן">{{ 'nutrition.EAT_SNACKS_16' | translate }}</mat-radio-button>
                                <mat-radio-button value="אחרי הארוחה">{{ 'nutrition.EAT_SNACKS_17' | translate }}</mat-radio-button>
                                <mat-radio-button value="בין צהריים לערב">{{ 'nutrition.EAT_SNACKS_18' | translate }}</mat-radio-button>
                                <mat-radio-button value="אין משהו קבוע">{{ 'nutrition.EAT_SNACKS_19' | translate }}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </ng-container>
        
                </div>
                    
                <span #nuq_eat_near_screen_el></span>

                <h3>{{'nutrition.Q_5' | translate}}</h3>
                <div class="mb50">
                    <mat-radio-group formControlName="nuq_eat_near_screen">
                        <mat-radio-button value="מול מסך">{{ 'nutrition.Q_5_A' | translate }}</mat-radio-button>
                        <mat-radio-button value="ללא מסכים">{{ 'nutrition.Q_5_B' | translate }}</mat-radio-button>
                    </mat-radio-group>                
                </div>
                <div *ngIf="nutritionquizForm.value.nuq_eat_near_screen == 'מול מסך'" class="w65">
                    <mat-form-field>
                        <mat-select formControlName="nuq_eat_near_screen_status" placeholder="{{'nutrition.Q_6' | translate}}">
                            <mat-option value="לעיתים נדירות">{{ 'nutrition.Q_6_A' | translate }}</mat-option>
                            <mat-option value="1-2 פעמים בשבוע">{{ 'nutrition.Q_6_B' | translate }}</mat-option>
                            <mat-option value="3-5 פעמים בשבוע">{{ 'nutrition.Q_6_C' | translate }}</mat-option>
                            <mat-option value="6+ פעמים בשבוע">{{ 'nutrition.Q_6_D' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <span #nuq_eat_outside_el></span>
            
                <h3>{{'nutrition.Q_7' | translate}}</h3>
                <div class="mb50">
                    <mat-radio-group formControlName="nuq_eat_outside" [ngClass]="{'mb50' : nutritionquizForm.value.nuq_eat_outside == 'כן'}">
                        <mat-radio-button value="כן">{{ 'nutrition.Q_7_A' | translate }}</mat-radio-button>
                        <mat-radio-button value="לעיתים רחוקות">{{ 'nutrition.Q_7_B' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="nutritionquizForm.value.nuq_eat_outside == 'כן'" class="w65">
                    <mat-form-field>
                        <mat-select formControlName="nuq_eat_outside_status" placeholder="{{'nutrition.Q_8' | translate}}">
                            <mat-option value="1-2 פעמים בשבוע">{{ 'nutrition.Q_8_A' | translate }}</mat-option>
                            <mat-option value="3-5 פעמים בשבוע">{{ 'nutrition.Q_8_B' | translate }}</mat-option>
                            <mat-option value="6+ פעמים בשבוע">{{ 'nutrition.Q_8_C' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
        
            </mat-card>

            <br>
            
            </mat-tab>
            <mat-tab [disabled]="busy" label="{{ 'nutquiz.SECTION_3_TITLE' | translate }}">

            <h2 class="sticky-header">
                {{ 'nutquiz.SECTION_3_TITLE' | translate }}
            </h2>

            <mat-card>

                <span #nuq_drink_estimate_el></span>
                <h3 class="m-b-0">{{'nutrition.DRINK_ESTIMATE' | translate}}</h3>
                <div class="mb50">
                    <mat-form-field class="w65">
                        <mat-select formControlName="nuq_drink_estimate"><!--  placeholder="{{ 'nutrition.DRINK_ESTIMATE_22' | translate }}" -->
                            <mat-option value="0.2">{{ 'nutrition.DRINK_ESTIMATE_1' | translate }}</mat-option>
                            <mat-option value="0.4">{{ 'nutrition.DRINK_ESTIMATE_2' | translate }}</mat-option>
                            <mat-option value="0.6">{{ 'nutrition.DRINK_ESTIMATE_3' | translate }}</mat-option>
                            <mat-option value="0.8">{{ 'nutrition.DRINK_ESTIMATE_4' | translate }}</mat-option>
                            <mat-option value="1.0">{{ 'nutrition.DRINK_ESTIMATE_5' | translate }}</mat-option>
                            <mat-option value="1.2">{{ 'nutrition.DRINK_ESTIMATE_6' | translate }}</mat-option>
                            <mat-option value="1.4">{{ 'nutrition.DRINK_ESTIMATE_7' | translate }}</mat-option>
                            <mat-option value="1.6">{{ 'nutrition.DRINK_ESTIMATE_8' | translate }}</mat-option>
                            <mat-option value="1.8">{{ 'nutrition.DRINK_ESTIMATE_9' | translate }}</mat-option>
                            <mat-option value="2.0">{{ 'nutrition.DRINK_ESTIMATE_10' | translate }}</mat-option>
                            <mat-option value="2.2">{{ 'nutrition.DRINK_ESTIMATE_11' | translate }}</mat-option>
                            <mat-option value="2.4">{{ 'nutrition.DRINK_ESTIMATE_12' | translate }}</mat-option>
                            <mat-option value="2.6">{{ 'nutrition.DRINK_ESTIMATE_13' | translate }}</mat-option>
                            <mat-option value="2.8">{{ 'nutrition.DRINK_ESTIMATE_14' | translate }}</mat-option>
                            <mat-option value="3.0">{{ 'nutrition.DRINK_ESTIMATE_15' | translate }}</mat-option>
                            <mat-option value="3.2">{{ 'nutrition.DRINK_ESTIMATE_16' | translate }}</mat-option>
                            <mat-option value="3.4">{{ 'nutrition.DRINK_ESTIMATE_17' | translate }}</mat-option>
                            <mat-option value="3.6">{{ 'nutrition.DRINK_ESTIMATE_18' | translate }}</mat-option>
                            <mat-option value="3.8">{{ 'nutrition.DRINK_ESTIMATE_19' | translate }}</mat-option>
                            <mat-option value="4.0">{{ 'nutrition.DRINK_ESTIMATE_20' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
        
                <span #nuq_personal_bottle_el></span>
                <h3>{{'nutrition.PERSONAL_BOTTLE' | translate}}</h3>
                <div class="mb50">
                    <mat-radio-group class="d-column" formControlName="nuq_personal_bottle">
                        <mat-radio-button value="תמיד">{{ 'nutrition.PERSONAL_BOTTLE_1' | translate }}</mat-radio-button>
                        <mat-radio-button value="בדרך כלל">{{ 'nutrition.PERSONAL_BOTTLE_2' | translate }}</mat-radio-button>
                        <mat-radio-button value="לעיתים רחוקות">{{ 'nutrition.PERSONAL_BOTTLE_3' | translate }}</mat-radio-button>
                        <mat-radio-button value="אף פעם">{{ 'nutrition.PERSONAL_BOTTLE_4' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
        
                <span #nuq_drink_sugar_el></span>
                <h3>{{'nutrition.DRINK_SUGAR' | translate}}</h3>
                <div class="mb50">
                    <mat-radio-group formControlName="nuq_drink_sugar">
                        <mat-radio-button value="כן">{{ 'nutrition.DRINK_SUGAR_1' | translate }}</mat-radio-button>
                        <mat-radio-button value="לא">{{ 'nutrition.DRINK_SUGAR_2' | translate }}</mat-radio-button>
                    </mat-radio-group>                
                </div>
                <div *ngIf="nutritionquizForm.value.nuq_drink_sugar === 'כן'" class="w65">
                    <mat-form-field>
                        <mat-select formControlName="nuq_drink_sugar_freq" placeholder="{{'nutrition.DRINK_SUGAR_3' | translate}}">
                            <mat-option value="לעיתים נדירות">{{ 'nutrition.DRINK_SUGAR_4' | translate }}</mat-option>
                            <mat-option value="1-2 פעמים בשבוע">{{ 'nutrition.DRINK_SUGAR_5' | translate }}</mat-option>
                            <mat-option value="3-5 פעמים בשבוע">{{ 'nutrition.DRINK_SUGAR_6' | translate }}</mat-option>
                            <mat-option value="כמעט כל יום">{{ 'nutrition.DRINK_SUGAR_7' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                
                <span #nuq_drink_caffeine_el></span>
                <h3>{{'nutrition.DRINK_CAFFEINE' | translate}}</h3>
                <div class="mb50">
                    <mat-radio-group formControlName="nuq_drink_caffeine">
                        <mat-radio-button value="כן">{{ 'nutrition.DRINK_CAFFEINE_1' | translate }}</mat-radio-button>
                        <mat-radio-button value="לא">{{ 'nutrition.DRINK_CAFFEINE_2' | translate }}</mat-radio-button>
                    </mat-radio-group>                
                </div>
                <div *ngIf="nutritionquizForm.value.nuq_drink_caffeine === 'כן'" class="w65">
                    <mat-form-field>
                        <mat-select multiple formControlName="nuq_drink_caffeine_items" placeholder="{{'nutrition.DRINK_CAFFEINE_3' | translate}}">
                            <mat-option value="קפה">{{ 'nutrition.DRINK_CAFFEINE_4' | translate }}</mat-option>
                            <mat-option value="תה">{{ 'nutrition.DRINK_CAFFEINE_5' | translate }}</mat-option>
                            <mat-option value="משקה אנרגיה">{{ 'nutrition.DRINK_CAFFEINE_6' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    
                    <h3>{{'nutrition.DRINK_CAFFEINE_7' | translate}}</h3>
                    <div class="mb50">
                        <mat-radio-group class="d-column" formControlName="nuq_drink_caffeine_cups">
                            <mat-radio-button value="משחק">{{ 'nutrition.DRINK_CAFFEINE_8' | translate }}</mat-radio-button>
                            <mat-radio-button value="לעיתים רחוקות">{{ 'nutrition.DRINK_CAFFEINE_9' | translate }}</mat-radio-button>
                            <mat-radio-button value="1">{{ 'nutrition.DRINK_CAFFEINE_10' | translate }}</mat-radio-button>
                            <mat-radio-button value="2-3">{{ 'nutrition.DRINK_CAFFEINE_11' | translate }}</mat-radio-button>
                            <mat-radio-button value="3-5">{{ 'nutrition.DRINK_CAFFEINE_12' | translate }}</mat-radio-button>
                            <mat-radio-button value="5+">{{ 'nutrition.DRINK_CAFFEINE_13' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
        
                    <h3>{{'nutrition.DRINK_CAFFEINE_14' | translate}}</h3>
                    <div class="mb50">
                        <mat-radio-group class="d-column" formControlName="nuq_drink_caffeine_last">
                            <mat-radio-button value="בוקר">{{ 'nutrition.DRINK_CAFFEINE_15' | translate }}</mat-radio-button>
                            <mat-radio-button value="צהריים">{{ 'nutrition.DRINK_CAFFEINE_16' | translate }}</mat-radio-button>
                            <mat-radio-button value="אחרי הצהריים">{{ 'nutrition.DRINK_CAFFEINE_17' | translate }}</mat-radio-button>
                            <mat-radio-button value="ערב">{{ 'nutrition.DRINK_CAFFEINE_18' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
        
                </div>

                <span #nuq_urine_color_el></span>
                <h2>{{ 'nutquiz.Q_URINE_COLOR' | translate }}</h2>
                
                <mat-card>

                    <mat-grid-list class="pointer" [cols]="urineHexColorPlate.length" rowHeight="3em">
                        <mat-grid-tile *ngFor="let urineColor of urineHexColorPlate; let i = index;"   
                        (click)="setUrineColor(urineColor)"
                        [colspan]="1"
                        [rowspan]="1"
                        [style.padding]="'4px'"
                        [style.border]="nutritionquizForm.value.nuq_urine_color == urineColor ? '2.5px solid red' : '2px solid blue'"
                        [style.background]="urineColor">
                        </mat-grid-tile>
                    </mat-grid-list>  

                </mat-card>

            </mat-card>

            <br>
            
            </mat-tab>
            <mat-tab [disabled]="busy" label="{{ 'nutquiz.SECTION_4_TITLE' | translate }}">
                
            <h2 class="sticky-header">
                {{ 'nutquiz.SECTION_4_TITLE' | translate }}
            </h2>

            <mat-card *ngIf="!allProductsNutStyle">
                <div class="spinner">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                </div>
            </mat-card>

            <ng-container *ngIf="allProductsNutStyle && allProductsNutStyle.length">

                <mat-card>

                    <h3>{{ 'nutquiz.SECTION_4_SUBTITLE' | translate }}</h3>

                    <div *ngFor="let cat of allCategoriesGroups">
                        <nut-ingredient-category compType="ingredient" [nameType]="cat.name" [isOtherControl]="nutritionquizForm.controls['nuq_is_other_' + cat.name]" [otherFoodFormArr]="nutritionquizForm.controls['nuq_other_' + cat.name]" [disableAllNotEat]="nutritionquizForm.controls['nuq_disabled_' + cat.name]" [isLocked]="isLocked" [showErrors]="showErrors" [isError]="cat.name !== 'drink' && !validateObjectIngredients[cat.name]" [title]="cat.title" [allOptions]="allOptionsIngredients[cat.name]" [userChosenCategories]="myChosenCategories" (outputChosenCategories)="updateChosenCategories($event, 'checked')" (removedIngCat)="removeAllChosenProductsAfterRemoveIngredientCategory($event)"></nut-ingredient-category>
                    </div>
    
                </mat-card>
    
            </ng-container>

            <br>

            </mat-tab>
            <mat-tab [disabled]="busy" label="{{ 'nutquiz.SECTION_5_TITLE' | translate }}">

            <h2 class="sticky-header">
                {{ 'nutquiz.SECTION_5_TITLE' | translate }}
            </h2>

            <mat-card *ngIf="!allProductsNutStyle">
                <div class="spinner">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                </div>
            </mat-card>

            <ng-container *ngIf="allProductsNutStyle && allProductsNutStyle.length">

                <span #nuq_eat_breakfast_el></span>

                <mat-card>
                    <!-- start questions -->
        
                    <h3>{{ 'nutquiz.Q_EAT_BREAKFAST' | translate }}</h3>
                    <div class="mb50">
                        <mat-radio-group class="d-column" formControlName="nuq_eat_breakfast">
                            <mat-radio-button value="תמיד">{{ 'nutquiz.OPTION_A' | translate }}</mat-radio-button>
                            <mat-radio-button value="כמעט תמיד">{{ 'nutquiz.OPTION_B' | translate }}</mat-radio-button>
                            <mat-radio-button value="לפעמים">{{ 'nutquiz.OPTION_C' | translate }}</mat-radio-button>
                            <mat-radio-button value="לעיתים רחוקות">{{ 'nutquiz.OPTION_D' | translate }}</mat-radio-button>
                            <mat-radio-button value="אף פעם">{{ 'nutquiz.OPTION_E' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <div *ngIf="nutritionquizForm.value.nuq_eat_breakfast == 'לפעמים' || nutritionquizForm.value.nuq_eat_breakfast == 'לעיתים רחוקות' || nutritionquizForm.value.nuq_eat_breakfast == 'אף פעם'" class="w65">
                        <span #nuq_eat_breakfast_reason_el></span>
        
                        <h3>{{ 'nutquiz.Q_EAT_BREAKFAST_REASON' | translate }}</h3>
                        <div class="mb50">
                            <!-- <mat-radio-group class="d-column" formControlName="nuq_eat_breakfast_reason">
                                <mat-radio-button value="אין לי תיאבון">אין לי תיאבון</mat-radio-button>
                                <mat-radio-button value="לא מספיק">לא מספיק</mat-radio-button>
                                <mat-radio-button value="זה בסדר אני אוכל כריך בבית הספר">זה בסדר אני אוכל כריך בבית הספר</mat-radio-button>
                                <mat-radio-button value="אין סיבה מיוחדת">אין סיבה מיוחדת</mat-radio-button>
                                <mat-radio-button value="אחר">אחר</mat-radio-button>
                            </mat-radio-group> -->
                            <div formArrayName="nuq_eat_breakfast_reasons">
                                <p *ngFor="let opt of checkboxOptions">
                                    <mat-checkbox class="checkbox normal" (change)="updateChkbxArray(opt, $event.checked, 'nuq_eat_breakfast_reasons')" [checked]="nutritionquizForm.value.nuq_eat_breakfast_reasons.includes(opt)" [disabled]="isLocked">{{opt}}</mat-checkbox>
                                </p>
                            </div>
                        </div>
        
                        <!-- <mat-form-field *ngIf="nutritionquizForm.value.nuq_eat_breakfast_reason == 'אחר'" class="w65">
                            <input formControlName="nuq_eat_breakfast_reason_other_details" matInput placeholder="פרט">
                        </mat-form-field> -->
                        <mat-form-field *ngIf="nutritionquizForm.value.nuq_eat_breakfast_reasons && nutritionquizForm.value.nuq_eat_breakfast_reasons.includes('אחר')" class="w65">
                            <input formControlName="nuq_eat_breakfast_reason_other_details" matInput placeholder="{{'general.description' | translate}}">
                        </mat-form-field>
                    </div>
                </mat-card>

                <ng-container *ngIf="nutritionquizForm.value.nuq_eat_breakfast == 'תמיד' || nutritionquizForm.value.nuq_eat_breakfast == 'כמעט תמיד' || nutritionquizForm.value.nuq_eat_breakfast == 'לפעמים'">

                    <mat-card *ngIf="allMealsGroups['BREAKFAST']">
    
                        <h3>{{ 'nutquiz.BREAKFAST_SUBTITLE' | translate }}</h3>
        
                        <div *ngFor="let cat of allCategoriesGroups">
                            <nut-ingredient-category mealType="BREAKFAST" compType="frequency" [nameType]="cat.name" [isLocked]="isLocked" [title]="cat.title" [allOptions]="allOptionsMeals['BREAKFAST'] && allOptionsMeals['BREAKFAST'][cat.name] && allOptionsMeals['BREAKFAST'][cat.name].options" [userChosenCategories]="myChosenCategories" (outputChosenCategories)="updateChosenCategories($event, 'weekly', 'BREAKFAST')" (outputProductChosen)="newItemByBarcodeDialog($event)"></nut-ingredient-category>
                        </div>
        
                    </mat-card>
    
                    <mat-card>
                        <!-- middle questions -->
    
                        <h4>{{ 'nutquiz.BREAKFAST_TIME' | translate }}</h4>

                        <span #nuq_breakfast_time_el></span>

                        <mat-form-field class="w65">
                            <mat-select formControlName="nuq_breakfast_time" placeholder="{{ 'nutquiz.SELECT_TIME' | translate }}">
                                <mat-option *ngFor="let time of allHoursFrom5" [value]="time">{{ time }}</mat-option>
                            </mat-select>
                        </mat-form-field>
    
                        <!-- meal photo -->
                        <h4>
                            <div>{{ 'nutquiz.BREAKFAST_PHOTO' | translate }} </div>
                            <div>{{ 'nutquiz.COULD_HELP_PHOTO' | translate }}</div>
                        </h4>
                        <div>
                            <vzn-media-files
                                [uploadFolder]="base_image_upload_folder_path + 'BREAKFAST' + '_'"
                                [width]="'auto'"
                                [height]="'120px'"
                                [assetMargin]="'5px'"
                                [uploadUrlService]="uploadUrlService"
                                [formArrayName]="'nuq_breakfast_assets'"
                                [acceptedFiles]="'image/jpeg, image/x-png, image/png, image/webp'"
                                [limitToOne]="true"
                                [disabled]="isLocked"
                                [addPhotoIconName]="'add_a_photo'"
                                (onAssetClick)="openImageDialog($event.url)"  
                            >
                            </vzn-media-files>
                        </div>
                    </mat-card>
    
                    <mat-card>
                        <!-- end questions -->
                            
                        <mat-checkbox class="checkbox normal" formControlName="nuq_is_breakfast_details">
                            <h4>
                                <div>{{ 'nutquiz.BREAKFAST_TELL' | translate }} </div>
                                <div>{{ 'nutquiz.COULD_HELP' | translate }}</div>
                            </h4>
                        </mat-checkbox>
    
                        <ng-container *ngIf="nutritionquizForm.value.nuq_is_breakfast_details">
                            <div *ngFor="let cat of allCategoriesGroups">
                                <nut-ingredient-category mealType="BREAKFAST" compType="typical" [showErrors]="showErrors" [nameType]="cat.name" [isLocked]="isLocked" [title]="cat.title" [allOptions]="allOptionsMeals['BREAKFAST'] && allOptionsMeals['BREAKFAST'][cat.name] && allOptionsMeals['BREAKFAST'][cat.name].options" [userChosenProducts]="myChosenProducts" [userChosenCategories]="myChosenCategories" (outputChosenCategories)="updateChosenCategories($event, 'checkedTypical', 'BREAKFAST')" (outputProductChosen)="newItemByBarcodeDialog($event)"></nut-ingredient-category>
                            </div>
                        </ng-container>
                    </mat-card>
                    
                </ng-container>
    
            </ng-container>
        
                <br>

            </mat-tab>
            <mat-tab [disabled]="busy" label="{{ 'nutquiz.SECTION_6_TITLE' | translate }}">

                <h2 class="sticky-header">
                    {{ 'nutquiz.SECTION_6_TITLE' | translate }}
                </h2>

            <mat-card *ngIf="!allProductsNutStyle">
                <div class="spinner">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                </div>
            </mat-card>

            <ng-container *ngIf="allProductsNutStyle && allProductsNutStyle.length">

                <span #nuq_eat_elevenses_el></span>

                <mat-card>
                    <!-- start questions -->
        
                    <h3 *ngIf="!isUnderNineteen">{{ 'nutquiz.Q_EAT_ELEVENSES' | translate }}</h3>
                    <h3 *ngIf="isUnderNineteen">{{ 'nutquiz.Q_EAT_ELEVENSES_UNDER_19' | translate }}</h3>
                    <div class="mb50">
                        <mat-radio-group class="d-column" formControlName="nuq_eat_elevenses">
                            <mat-radio-button value="תמיד">{{ 'nutquiz.OPTION_A' | translate }}</mat-radio-button>
                            <mat-radio-button value="כמעט תמיד">{{ 'nutquiz.OPTION_B' | translate }}</mat-radio-button>
                            <mat-radio-button value="לפעמים">{{ 'nutquiz.OPTION_C' | translate }}</mat-radio-button>
                            <mat-radio-button value="לעיתים רחוקות">{{ 'nutquiz.OPTION_D' | translate }}</mat-radio-button>
                            <mat-radio-button value="אף פעם">{{ 'nutquiz.OPTION_E' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div>

                    <ng-container *ngIf="isUnderNineteen && (nutritionquizForm.value.nuq_eat_elevenses == 'תמיד' || nutritionquizForm.value.nuq_eat_elevenses == 'כמעט תמיד' || nutritionquizForm.value.nuq_eat_elevenses == 'לפעמים')">

                        <span #nuq_eat_elevenses_enough_food_el></span>
                    
                        <h3>{{ 'nutquiz.Q_EAT_ELEVENSES_ENOUGH_FOOD' | translate }}</h3>
                        <div class="mb50">
                            <mat-radio-group formControlName="nuq_eat_elevenses_enough_food">
                                <mat-radio-button value="כן">{{ 'nutquiz.Q_EAT_ELEVENSES_ENOUGH_FOOD_A_1' | translate }}</mat-radio-button>
                                <mat-radio-button value="לא">{{ 'nutquiz.Q_EAT_ELEVENSES_ENOUGH_FOOD_A_2' | translate }}</mat-radio-button>
                            </mat-radio-group>
                        </div>

                    </ng-container>

                    <ng-container *ngIf="isUnderNineteen">

                        <span #nuq_eat_elevenses_no_school_el></span>

                        <h3>{{ 'nutquiz.Q_EAT_ELEVENSES_NO_SCHOOL' | translate }}</h3>
                        <div class="mb50">
                            <mat-radio-group class="d-column" formControlName="nuq_eat_elevenses_no_school">
                                <mat-radio-button value="תמיד">{{ 'nutquiz.OPTION_A' | translate }}</mat-radio-button>
                                <mat-radio-button value="כמעט תמיד">{{ 'nutquiz.OPTION_B' | translate }}</mat-radio-button>
                                <mat-radio-button value="לפעמים">{{ 'nutquiz.OPTION_C' | translate }}</mat-radio-button>
                                <mat-radio-button value="לעיתים רחוקות">{{ 'nutquiz.OPTION_D' | translate }}</mat-radio-button>
                                <mat-radio-button value="אף פעם">{{ 'nutquiz.OPTION_E' | translate }}</mat-radio-button>
                            </mat-radio-group>
                        </div>

                    </ng-container>

                </mat-card>

                <ng-container *ngIf="isUnderNineteen ? ((nutritionquizForm.value.nuq_eat_elevenses == 'תמיד' || nutritionquizForm.value.nuq_eat_elevenses == 'כמעט תמיד' || nutritionquizForm.value.nuq_eat_elevenses == 'לפעמים') || (nutritionquizForm.value.nuq_eat_elevenses_no_school == 'תמיד' || nutritionquizForm.value.nuq_eat_elevenses_no_school == 'כמעט תמיד' || nutritionquizForm.value.nuq_eat_elevenses_no_school == 'לפעמים')) : (nutritionquizForm.value.nuq_eat_elevenses == 'תמיד' || nutritionquizForm.value.nuq_eat_elevenses == 'כמעט תמיד' || nutritionquizForm.value.nuq_eat_elevenses == 'לפעמים')">

                    <mat-card *ngIf="allMealsGroups['ELEVENSES']">
    
                        <h3>{{ 'nutquiz.ELEVENSES_SUBTITLE' | translate }}</h3>
        
                        <div *ngFor="let cat of allCategoriesGroups">
                            <nut-ingredient-category mealType="ELEVENSES" compType="frequency" [nameType]="cat.name" [isLocked]="isLocked" [title]="cat.title" [allOptions]="allOptionsMeals['ELEVENSES'] && allOptionsMeals['ELEVENSES'][cat.name] && allOptionsMeals['ELEVENSES'][cat.name].options" [userChosenCategories]="myChosenCategories" (outputChosenCategories)="updateChosenCategories($event, 'weekly', 'ELEVENSES')" (outputProductChosen)="newItemByBarcodeDialog($event)"></nut-ingredient-category>
                        </div>
        
                    </mat-card>
    
                    <mat-card>
                        <!-- middle questions -->
    
                        <h4 *ngIf="!isUnderNineteen">{{ 'nutquiz.ELEVENSES_TIME' | translate }}</h4>
                        <h4 *ngIf="isUnderNineteen">{{ 'nutquiz.ELEVENSES_TIME_UNDER_19' | translate }}</h4>

                        <span #nuq_elevenses_time_el></span>

                        <mat-form-field class="w65">
                            <mat-select formControlName="nuq_elevenses_time" placeholder="{{ 'nutquiz.SELECT_TIME' | translate }}">
                                <mat-option *ngFor="let time of allHoursFrom8" [value]="time">{{ time }}</mat-option>
                            </mat-select>
                        </mat-form-field>
    
                        <!-- meal photo -->
                        <h4>
                            <div>{{ 'nutquiz.ELEVENSES_PHOTO' | translate }} </div>
                            <div>{{ 'nutquiz.COULD_HELP_PHOTO' | translate }}</div>
                        </h4>
                        <div>
                            <vzn-media-files
                                [uploadFolder]="base_image_upload_folder_path + 'ELEVENSES' + '_'"
                                [width]="'auto'"
                                [height]="'120px'"
                                [assetMargin]="'5px'"
                                [uploadUrlService]="uploadUrlService"
                                [formArrayName]="'nuq_elevenses_assets'"
                                [acceptedFiles]="'image/jpeg, image/x-png, image/png, image/webp'"
                                [limitToOne]="true"
                                [disabled]="isLocked"
                                [addPhotoIconName]="'add_a_photo'"
                                (onAssetClick)="openImageDialog($event.url)"  
                            >
                            </vzn-media-files>
                        </div>
                    </mat-card>
    
                    <mat-card>
                        <!-- end questions -->
                            
                        <mat-checkbox class="checkbox normal" formControlName="nuq_is_elevenses_details">
                            <h4>
                                <div>{{ 'nutquiz.ELEVENSES_TELL' | translate }} </div>
                                <div>{{ 'nutquiz.COULD_HELP' | translate }}</div>
                            </h4>
                        </mat-checkbox>
    
                        <ng-container *ngIf="nutritionquizForm.value.nuq_is_elevenses_details">
                            <div *ngFor="let cat of allCategoriesGroups">
                                <nut-ingredient-category mealType="ELEVENSES" compType="typical" [showErrors]="showErrors" [nameType]="cat.name" [isLocked]="isLocked" [title]="cat.title" [allOptions]="allOptionsMeals['ELEVENSES'] && allOptionsMeals['ELEVENSES'][cat.name] && allOptionsMeals['ELEVENSES'][cat.name].options" [userChosenProducts]="myChosenProducts" [userChosenCategories]="myChosenCategories" (outputChosenCategories)="updateChosenCategories($event, 'checkedTypical', 'ELEVENSES')" (outputProductChosen)="newItemByBarcodeDialog($event)"></nut-ingredient-category>
                            </div>
                        </ng-container>
                    </mat-card>
                    
                </ng-container>
    
            </ng-container>
        
                <br>
    
            </mat-tab>
            <mat-tab [disabled]="busy" label="{{ 'nutquiz.SECTION_7_TITLE' | translate }}">

                <h2 class="sticky-header">
                    {{ 'nutquiz.SECTION_7_TITLE' | translate }}
                </h2>

            <mat-card *ngIf="!allProductsNutStyle">
                <div class="spinner">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                </div>
            </mat-card>

            <ng-container *ngIf="allProductsNutStyle && allProductsNutStyle.length">

                <span #nuq_eat_lunch_el></span>

                <mat-card>
                    <!-- start questions -->
        
                    <!-- <h3>{{ 'nutquiz.Q_EAT_LUNCH' | translate }}</h3>
                    <div class="mb50">
                        <mat-radio-group class="d-column" formControlName="nuq_eat_lunch">
                            <mat-radio-button value="תמיד">{{ 'nutquiz.OPTION_A' | translate }}</mat-radio-button>
                            <mat-radio-button value="כמעט תמיד">{{ 'nutquiz.OPTION_B' | translate }}</mat-radio-button>
                            <mat-radio-button value="לפעמים">{{ 'nutquiz.OPTION_C' | translate }}</mat-radio-button>
                            <mat-radio-button value="לעיתים רחוקות">{{ 'nutquiz.OPTION_D' | translate }}</mat-radio-button>
                            <mat-radio-button value="אף פעם">{{ 'nutquiz.OPTION_E' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div> -->
                    <!-- <ng-container *ngIf="nutritionquizForm.value.nuq_eat_lunch == 'תמיד' || nutritionquizForm.value.nuq_eat_lunch == 'כמעט תמיד' || nutritionquizForm.value.nuq_eat_lunch == 'לפעמים'"> -->

                        <h3>{{ 'nutquiz.Q_EAT_LUNCH_DAIRY_MEAT' | translate }}</h3>
                        <div class="mb50">
                            <mat-radio-group class="d-column" formControlName="nuq_eat_lunch_dairy_meat">
                                <mat-radio-button value="לרוב בשרית">{{ 'nutquiz.OPTION_DAIRY_MEAT_1' | translate }}</mat-radio-button>
                                <mat-radio-button value="לרוב חלבית">{{ 'nutquiz.OPTION_DAIRY_MEAT_2' | translate }}</mat-radio-button>
                                <mat-radio-button value="גם וגם">{{ 'nutquiz.OPTION_DAIRY_MEAT_3' | translate }}</mat-radio-button>
                            </mat-radio-group>
                        </div>

                    <!-- </ng-container> -->
                </mat-card>

                <!-- <ng-container *ngIf="nutritionquizForm.value.nuq_eat_lunch == 'תמיד' || nutritionquizForm.value.nuq_eat_lunch == 'כמעט תמיד' || nutritionquizForm.value.nuq_eat_lunch == 'לפעמים'"> -->

                    <mat-card *ngIf="allMealsGroups['LUNCH']">
    
                        <h3>{{ 'nutquiz.LUNCH_SUBTITLE' | translate }}</h3>
        
                        <div *ngFor="let cat of allCategoriesGroups">
                            <nut-ingredient-category mealType="LUNCH" compType="frequency" [nameType]="cat.name" [isLocked]="isLocked" [title]="cat.title" [allOptions]="allOptionsMeals['LUNCH'] && allOptionsMeals['LUNCH'][cat.name] && allOptionsMeals['LUNCH'][cat.name].options" [userChosenCategories]="myChosenCategories" (outputChosenCategories)="updateChosenCategories($event, 'weekly', 'LUNCH')" (outputProductChosen)="newItemByBarcodeDialog($event)"></nut-ingredient-category>
                        </div>
        
                    </mat-card>
    
                    <mat-card>
                        <!-- middle questions -->
    
                        <h4>{{ 'nutquiz.LUNCH_TIME' | translate }}</h4>

                        <span #nuq_lunch_time_el></span>

                        <mat-form-field class="w65">
                            <mat-select formControlName="nuq_lunch_time" placeholder="{{ 'nutquiz.SELECT_TIME' | translate }}">
                                <mat-option *ngFor="let time of allHoursFrom11" [value]="time">{{ time }}</mat-option>
                            </mat-select>
                        </mat-form-field>
    
                        <!-- meal photo -->
                        <h4>
                            <div>{{ 'nutquiz.LUNCH_PHOTO' | translate }} </div>
                            <div>{{ 'nutquiz.COULD_HELP_PHOTO' | translate }}</div>
                        </h4>
                        <div>
                            <vzn-media-files
                                [uploadFolder]="base_image_upload_folder_path + 'LUNCH' + '_'"
                                [width]="'auto'"
                                [height]="'120px'"
                                [assetMargin]="'5px'"
                                [uploadUrlService]="uploadUrlService"
                                [formArrayName]="'nuq_lunch_assets'"
                                [acceptedFiles]="'image/jpeg, image/x-png, image/png, image/webp'"
                                [limitToOne]="true"
                                [disabled]="isLocked"
                                [addPhotoIconName]="'add_a_photo'"
                                (onAssetClick)="openImageDialog($event.url)"  
                            >
                            </vzn-media-files>
                        </div>
                    </mat-card>
    
                    <mat-card>
                        <!-- end questions -->
                            
                        <mat-checkbox class="checkbox normal" formControlName="nuq_is_lunch_details">
                            <h4>
                                <div>{{ 'nutquiz.LUNCH_TELL' | translate }} </div>
                                <div>{{ 'nutquiz.COULD_HELP' | translate }}</div>
                            </h4>
                        </mat-checkbox>
    
                        <ng-container *ngIf="nutritionquizForm.value.nuq_is_lunch_details">
                            <div *ngFor="let cat of allCategoriesGroups">
                                <nut-ingredient-category mealType="LUNCH" compType="typical" [showErrors]="showErrors" [nameType]="cat.name" [isLocked]="isLocked" [title]="cat.title" [allOptions]="allOptionsMeals['LUNCH'] && allOptionsMeals['LUNCH'][cat.name] && allOptionsMeals['LUNCH'][cat.name].options" [userChosenProducts]="myChosenProducts" [userChosenCategories]="myChosenCategories" (outputChosenCategories)="updateChosenCategories($event, 'checkedTypical', 'LUNCH')" (outputProductChosen)="newItemByBarcodeDialog($event)"></nut-ingredient-category>
                            </div>
                        </ng-container>
                    </mat-card>
                    
                <!-- </ng-container> -->
    
            </ng-container>
        
                <br>

            </mat-tab>
            <mat-tab [disabled]="busy" label="{{ 'nutquiz.SECTION_8_TITLE' | translate }}">

                <h2 class="sticky-header">
                    {{ 'nutquiz.SECTION_8_TITLE' | translate }}
                </h2>

            <mat-card *ngIf="!allProductsNutStyle">
                <div class="spinner">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                </div>
            </mat-card>

            <ng-container *ngIf="allProductsNutStyle && allProductsNutStyle.length">

                <span #nuq_eat_tea_el></span>

                <mat-card>
                    <!-- start questions -->
        
                    <h3>{{ 'nutquiz.Q_EAT_TEA' | translate }}</h3>
                    <div class="mb50">
                        <mat-radio-group class="d-column" formControlName="nuq_eat_tea">
                            <mat-radio-button value="תמיד">{{ 'nutquiz.OPTION_A' | translate }}</mat-radio-button>
                            <mat-radio-button value="כמעט תמיד">{{ 'nutquiz.OPTION_B' | translate }}</mat-radio-button>
                            <mat-radio-button value="לפעמים">{{ 'nutquiz.OPTION_C' | translate }}</mat-radio-button>
                            <mat-radio-button value="לעיתים רחוקות">{{ 'nutquiz.OPTION_D' | translate }}</mat-radio-button>
                            <mat-radio-button value="אף פעם">{{ 'nutquiz.OPTION_E' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <ng-container *ngIf="nutritionquizForm.value.nuq_eat_tea == 'תמיד' || nutritionquizForm.value.nuq_eat_tea == 'כמעט תמיד' || nutritionquizForm.value.nuq_eat_tea == 'לפעמים'">

                        <h3>{{ 'nutquiz.Q_EAT_TEA_DAIRY_MEAT' | translate }}</h3>
                        <div class="mb50">
                            <mat-radio-group class="d-column" formControlName="nuq_eat_tea_dairy_meat">
                                <mat-radio-button value="לרוב בשרית">{{ 'nutquiz.OPTION_DAIRY_MEAT_1' | translate }}</mat-radio-button>
                                <mat-radio-button value="לרוב חלבית">{{ 'nutquiz.OPTION_DAIRY_MEAT_2' | translate }}</mat-radio-button>
                                <mat-radio-button value="גם וגם">{{ 'nutquiz.OPTION_DAIRY_MEAT_3' | translate }}</mat-radio-button>
                            </mat-radio-group>
                        </div>

                    </ng-container>
                </mat-card>

                <ng-container *ngIf="nutritionquizForm.value.nuq_eat_tea == 'תמיד' || nutritionquizForm.value.nuq_eat_tea == 'כמעט תמיד' || nutritionquizForm.value.nuq_eat_tea == 'לפעמים'">

                    <mat-card *ngIf="allMealsGroups['TEA']">
    
                        <h3>{{ 'nutquiz.TEA_SUBTITLE' | translate }}</h3>
        
                        <div *ngFor="let cat of allCategoriesGroups">
                            <nut-ingredient-category mealType="TEA" compType="frequency" [nameType]="cat.name" [isLocked]="isLocked" [title]="cat.title" [allOptions]="allOptionsMeals['TEA'] && allOptionsMeals['TEA'][cat.name] && allOptionsMeals['TEA'][cat.name].options" [userChosenCategories]="myChosenCategories" (outputChosenCategories)="updateChosenCategories($event, 'weekly', 'TEA')" (outputProductChosen)="newItemByBarcodeDialog($event)"></nut-ingredient-category>
                        </div>
        
                    </mat-card>
    
                    <mat-card>
                        <!-- middle questions -->
    
                        <h4>{{ 'nutquiz.TEA_TIME' | translate }}</h4>

                        <span #nuq_tea_time_el></span>

                        <mat-form-field class="w65">
                            <mat-select formControlName="nuq_tea_time" placeholder="{{ 'nutquiz.SELECT_TIME' | translate }}">
                                <mat-option *ngFor="let time of allHoursFrom14" [value]="time">{{ time }}</mat-option>
                            </mat-select>
                        </mat-form-field>
    
                        <!-- meal photo -->
                        <h4>
                            <div>{{ 'nutquiz.TEA_PHOTO' | translate }} </div>
                            <div>{{ 'nutquiz.COULD_HELP_PHOTO' | translate }}</div>
                        </h4>
                        <div>
                            <vzn-media-files
                                [uploadFolder]="base_image_upload_folder_path + 'TEA' + '_'"
                                [width]="'auto'"
                                [height]="'120px'"
                                [assetMargin]="'5px'"
                                [uploadUrlService]="uploadUrlService"
                                [formArrayName]="'nuq_tea_assets'"
                                [acceptedFiles]="'image/jpeg, image/x-png, image/png, image/webp'"
                                [limitToOne]="true"
                                [disabled]="isLocked"
                                [addPhotoIconName]="'add_a_photo'"
                                (onAssetClick)="openImageDialog($event.url)"  
                            >
                            </vzn-media-files>
                        </div>
                    </mat-card>
    
                    <mat-card>
                        <!-- end questions -->
                            
                        <mat-checkbox class="checkbox normal" formControlName="nuq_is_tea_details">
                            <h4>
                                <div>{{ 'nutquiz.TEA_TELL' | translate }} </div>
                                <div>{{ 'nutquiz.COULD_HELP' | translate }}</div>
                            </h4>
                        </mat-checkbox>
    
                        <ng-container *ngIf="nutritionquizForm.value.nuq_is_tea_details">
                            <div *ngFor="let cat of allCategoriesGroups">
                                <nut-ingredient-category mealType="TEA" compType="typical" [showErrors]="showErrors" [nameType]="cat.name" [isLocked]="isLocked" [title]="cat.title" [allOptions]="allOptionsMeals['TEA'] && allOptionsMeals['TEA'][cat.name] && allOptionsMeals['TEA'][cat.name].options" [userChosenProducts]="myChosenProducts" [userChosenCategories]="myChosenCategories" (outputChosenCategories)="updateChosenCategories($event, 'checkedTypical', 'TEA')" (outputProductChosen)="newItemByBarcodeDialog($event)"></nut-ingredient-category>
                            </div>
                        </ng-container>
                    </mat-card>
                    
                </ng-container>
    
            </ng-container>
        
                <br>

            </mat-tab>
            <mat-tab [disabled]="busy" label="{{ 'nutquiz.SECTION_9_TITLE' | translate }}">

                <h2 class="sticky-header">
                    {{ 'nutquiz.SECTION_9_TITLE' | translate }}
                </h2>

            <mat-card *ngIf="!allProductsNutStyle">
                <div class="spinner">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                </div>
            </mat-card>

            <ng-container *ngIf="allProductsNutStyle && allProductsNutStyle.length">

                <span #nuq_eat_dinner_el></span>

                <mat-card>
                    <!-- start questions -->
        
                    <!-- <h3>{{ 'nutquiz.Q_EAT_DINNER' | translate }}</h3>
                    <div class="mb50">
                        <mat-radio-group class="d-column" formControlName="nuq_eat_dinner">
                            <mat-radio-button value="תמיד">{{ 'nutquiz.OPTION_A' | translate }}</mat-radio-button>
                            <mat-radio-button value="כמעט תמיד">{{ 'nutquiz.OPTION_B' | translate }}</mat-radio-button>
                            <mat-radio-button value="לפעמים">{{ 'nutquiz.OPTION_C' | translate }}</mat-radio-button>
                            <mat-radio-button value="לעיתים רחוקות">{{ 'nutquiz.OPTION_D' | translate }}</mat-radio-button>
                            <mat-radio-button value="אף פעם">{{ 'nutquiz.OPTION_E' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div> -->
                    <!-- <ng-container *ngIf="nutritionquizForm.value.nuq_eat_dinner == 'תמיד' || nutritionquizForm.value.nuq_eat_dinner == 'כמעט תמיד' || nutritionquizForm.value.nuq_eat_dinner == 'לפעמים'"> -->

                        <h3>{{ 'nutquiz.Q_EAT_DINNER_DAIRY_MEAT' | translate }}</h3>
                        <div class="mb50">
                            <mat-radio-group class="d-column" formControlName="nuq_eat_dinner_dairy_meat">
                                <mat-radio-button value="לרוב בשרית">{{ 'nutquiz.OPTION_DAIRY_MEAT_1' | translate }}</mat-radio-button>
                                <mat-radio-button value="לרוב חלבית">{{ 'nutquiz.OPTION_DAIRY_MEAT_2' | translate }}</mat-radio-button>
                                <mat-radio-button value="גם וגם">{{ 'nutquiz.OPTION_DAIRY_MEAT_3' | translate }}</mat-radio-button>
                            </mat-radio-group>
                        </div>

                    <!-- </ng-container> -->
                </mat-card>

                <!-- <ng-container *ngIf="nutritionquizForm.value.nuq_eat_dinner == 'תמיד' || nutritionquizForm.value.nuq_eat_dinner == 'כמעט תמיד' || nutritionquizForm.value.nuq_eat_dinner == 'לפעמים'"> -->

                    <mat-card *ngIf="allMealsGroups['DINNER']">
    
                        <h3>{{ 'nutquiz.DINNER_SUBTITLE' | translate }}</h3>
        
                        <div *ngFor="let cat of allCategoriesGroups">
                            <nut-ingredient-category mealType="DINNER" compType="frequency" [nameType]="cat.name" [isLocked]="isLocked" [title]="cat.title" [allOptions]="allOptionsMeals['DINNER'] && allOptionsMeals['DINNER'][cat.name] && allOptionsMeals['DINNER'][cat.name].options" [userChosenCategories]="myChosenCategories" (outputChosenCategories)="updateChosenCategories($event, 'weekly', 'DINNER')" (outputProductChosen)="newItemByBarcodeDialog($event)"></nut-ingredient-category>
                        </div>
        
                    </mat-card>
    
                    <mat-card>
                        <!-- middle questions -->
    
                        <h4>{{ 'nutquiz.DINNER_TIME' | translate }}</h4>

                        <span #nuq_dinner_time_el></span>

                        <mat-form-field class="w65">
                            <mat-select formControlName="nuq_dinner_time" placeholder="{{ 'nutquiz.SELECT_TIME' | translate }}">
                                <mat-option *ngFor="let time of allHoursFrom17" [value]="time">{{ time }}</mat-option>
                            </mat-select>
                        </mat-form-field>
    
                        <!-- meal photo -->
                        <h4>
                            <div>{{ 'nutquiz.DINNER_PHOTO' | translate }} </div>
                            <div>{{ 'nutquiz.COULD_HELP_PHOTO' | translate }}</div>
                        </h4>
                        <div>
                            <vzn-media-files
                                [uploadFolder]="base_image_upload_folder_path + 'DINNER' + '_'"
                                [width]="'auto'"
                                [height]="'120px'"
                                [assetMargin]="'5px'"
                                [uploadUrlService]="uploadUrlService"
                                [formArrayName]="'nuq_dinner_assets'"
                                [acceptedFiles]="'image/jpeg, image/x-png, image/png, image/webp'"
                                [limitToOne]="true"
                                [disabled]="isLocked"
                                [addPhotoIconName]="'add_a_photo'"
                                (onAssetClick)="openImageDialog($event.url)"  
                            >
                            </vzn-media-files>
                        </div>
                    </mat-card>
    
                    <mat-card>
                        <!-- end questions -->
                            
                        <mat-checkbox class="checkbox normal" formControlName="nuq_is_dinner_details">
                            <h4>
                                <div>{{ 'nutquiz.DINNER_TELL' | translate }} </div>
                                <div>{{ 'nutquiz.COULD_HELP' | translate }}</div>
                            </h4>
                        </mat-checkbox>
    
                        <ng-container *ngIf="nutritionquizForm.value.nuq_is_dinner_details">
                            <div *ngFor="let cat of allCategoriesGroups">
                                <nut-ingredient-category mealType="DINNER" compType="typical" [showErrors]="showErrors" [nameType]="cat.name" [isLocked]="isLocked" [title]="cat.title" [allOptions]="allOptionsMeals['DINNER'] && allOptionsMeals['DINNER'][cat.name] && allOptionsMeals['DINNER'][cat.name].options" [userChosenProducts]="myChosenProducts" [userChosenCategories]="myChosenCategories" (outputChosenCategories)="updateChosenCategories($event, 'checkedTypical', 'DINNER')" (outputProductChosen)="newItemByBarcodeDialog($event)"></nut-ingredient-category>
                            </div>
                        </ng-container>
                    </mat-card>
                    
                <!-- </ng-container> -->
    
            </ng-container>
        
                <br>

            </mat-tab>
            <mat-tab [disabled]="busy" label="{{ 'nutquiz.SECTION_10_TITLE' | translate }}">

                <h2 class="sticky-header">
                    {{ 'nutquiz.SECTION_10_TITLE' | translate }}
                </h2>

            <mat-card *ngIf="!allProductsNutStyle">
                <div class="spinner">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                </div>
            </mat-card>

            <ng-container *ngIf="allProductsNutStyle && allProductsNutStyle.length">
                
                <span #nuq_eat_night_el></span>

                <mat-card>
                    <!-- start questions -->
        
                    <h3>{{ 'nutquiz.Q_EAT_NIGHT' | translate }}</h3>
                    <div class="mb50">
                        <mat-radio-group class="d-column" formControlName="nuq_eat_night">
                            <mat-radio-button value="תמיד">{{ 'nutquiz.OPTION_A' | translate }}</mat-radio-button>
                            <mat-radio-button value="כמעט תמיד">{{ 'nutquiz.OPTION_B' | translate }}</mat-radio-button>
                            <mat-radio-button value="לפעמים">{{ 'nutquiz.OPTION_C' | translate }}</mat-radio-button>
                            <mat-radio-button value="לעיתים רחוקות">{{ 'nutquiz.OPTION_D' | translate }}</mat-radio-button>
                            <mat-radio-button value="אף פעם">{{ 'nutquiz.OPTION_E' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <ng-container *ngIf="nutritionquizForm.value.nuq_eat_night == 'תמיד' || nutritionquizForm.value.nuq_eat_night == 'כמעט תמיד' || nutritionquizForm.value.nuq_eat_night == 'לפעמים'">

                        <h3>{{ 'nutquiz.Q_EAT_NIGHT_DAIRY_MEAT' | translate }}</h3>
                        <div class="mb50">
                            <mat-radio-group class="d-column" formControlName="nuq_eat_night_dairy_meat">
                                <mat-radio-button value="לרוב בשרית">{{ 'nutquiz.OPTION_DAIRY_MEAT_1' | translate }}</mat-radio-button>
                                <mat-radio-button value="לרוב חלבית">{{ 'nutquiz.OPTION_DAIRY_MEAT_2' | translate }}</mat-radio-button>
                                <mat-radio-button value="גם וגם">{{ 'nutquiz.OPTION_DAIRY_MEAT_3' | translate }}</mat-radio-button>
                            </mat-radio-group>
                        </div>

                    </ng-container>
                </mat-card>

                <ng-container *ngIf="nutritionquizForm.value.nuq_eat_night == 'תמיד' || nutritionquizForm.value.nuq_eat_night == 'כמעט תמיד' || nutritionquizForm.value.nuq_eat_night == 'לפעמים'">

                    <mat-card *ngIf="allMealsGroups['NIGHT']">
    
                        <h3>{{ 'nutquiz.NIGHT_SUBTITLE' | translate }}</h3>
        
                        <div *ngFor="let cat of allCategoriesGroups">
                            <nut-ingredient-category mealType="NIGHT" compType="frequency" [nameType]="cat.name" [isLocked]="isLocked" [title]="cat.title" [allOptions]="allOptionsMeals['NIGHT'] && allOptionsMeals['NIGHT'][cat.name] && allOptionsMeals['NIGHT'][cat.name].options" [userChosenCategories]="myChosenCategories" (outputChosenCategories)="updateChosenCategories($event, 'weekly', 'NIGHT')" (outputProductChosen)="newItemByBarcodeDialog($event)"></nut-ingredient-category>
                        </div>
        
                    </mat-card>
    
                    <mat-card>
                        <!-- middle questions -->
    
                        <h4>{{ 'nutquiz.NIGHT_TIME' | translate }}</h4>

                        <span #nuq_night_time_el></span>

                        <mat-form-field class="w65">
                            <mat-select formControlName="nuq_night_time" placeholder="{{ 'nutquiz.SELECT_TIME' | translate }}">
                                <mat-option *ngFor="let time of allHoursFrom20" [value]="time">{{ time }}</mat-option>
                            </mat-select>
                        </mat-form-field>
    
                        <!-- meal photo -->
                        <h4>
                            <div>{{ 'nutquiz.NIGHT_PHOTO' | translate }} </div>
                            <div>{{ 'nutquiz.COULD_HELP_PHOTO' | translate }}</div>
                        </h4>
                        <div>
                            <vzn-media-files
                                [uploadFolder]="base_image_upload_folder_path + 'NIGHT' + '_'"
                                [width]="'auto'"
                                [height]="'120px'"
                                [assetMargin]="'5px'"
                                [uploadUrlService]="uploadUrlService"
                                [formArrayName]="'nuq_night_assets'"
                                [acceptedFiles]="'image/jpeg, image/x-png, image/png, image/webp'"
                                [limitToOne]="true"
                                [disabled]="isLocked"
                                [addPhotoIconName]="'add_a_photo'"
                                (onAssetClick)="openImageDialog($event.url)"  
                            >
                            </vzn-media-files>
                        </div>
                    </mat-card>
    
                    <mat-card>
                        <!-- end questions -->
                            
                        <mat-checkbox class="checkbox normal" formControlName="nuq_is_night_details">
                            <h4>
                                <div>{{ 'nutquiz.NIGHT_TELL' | translate }} </div>
                                <div>{{ 'nutquiz.COULD_HELP' | translate }}</div>
                            </h4>
                        </mat-checkbox>
    
                        <ng-container *ngIf="nutritionquizForm.value.nuq_is_night_details">
                            <div *ngFor="let cat of allCategoriesGroups">
                                <nut-ingredient-category mealType="NIGHT" compType="typical" [showErrors]="showErrors" [nameType]="cat.name" [isLocked]="isLocked" [title]="cat.title" [allOptions]="allOptionsMeals['NIGHT'] && allOptionsMeals['NIGHT'][cat.name] && allOptionsMeals['NIGHT'][cat.name].options" [userChosenProducts]="myChosenProducts" [userChosenCategories]="myChosenCategories" (outputChosenCategories)="updateChosenCategories($event, 'checkedTypical', 'NIGHT')" (outputProductChosen)="newItemByBarcodeDialog($event)"></nut-ingredient-category>
                            </div>
                        </ng-container>
                    </mat-card>
                    
                </ng-container>
    
            </ng-container>

            <br>


        </mat-tab>
        <mat-tab [disabled]="busy" label="{{ 'nutquiz.SECTION_11_TITLE' | translate }}">

            <span #nuq_more_food_comments_el></span>

            <h2 class="sticky-header">
                {{ 'nutquiz.SECTION_11_TITLE' | translate }}
            </h2>

            <mat-card>
                <h3>{{'nutrition.Q_9' | translate}}</h3>

                <div class="mb50">
                    <mat-radio-group formControlName="nuq_more_food_comments">
                        <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                        <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                    </mat-radio-group>                
                </div>

                <mat-form-field *ngIf="nutritionquizForm.value.nuq_more_food_comments == 'כן'" class="w65">
                    <input formControlName="nuq_more_food_comments_text" matInput placeholder="{{'general.extraNotes' | translate}}">
                </mat-form-field>
            </mat-card>

            <br>

        </mat-tab>
        
        </mat-tab-group>

        <div class="centered-row">
            <button type="button" (click)="goBackStep()" class="full-width m-l-10 m-r-10" mat-raised-button color="primary" [disabled]="selectedTab === 0 || isLocked">הקודם</button>
            <button type="button" (click)="goNextStep()" class="full-width m-l-10 m-r-10" mat-raised-button color="primary" [disabled]="(selectedTab === 10 && (submitInProgress || !allProductsNutStyle)) || isLocked">
                {{ selectedTab < 10 ? 'הבא' : ('general.save' | translate) }}
            </button>
        </div>

            <!-- <button mat-raised-button color="primary" class="full-width" [disabled]="isLocked">{{'general.save' | translate}}</button> -->
            <div *ngIf="isLocked" class="font-12 text-center text-danger">
                {{'general.savedQuiz' | translate}}
            </div>

        </div>
    </form>

    <ng-template #mealDialogTemplate let-context="context" let-form="form">

        <div class="m-b-20 m-0 centered-row between">

            <button type="button" mat-icon-button (click)="goDone(form)" class="meal-dialog-float-button done" [disabled]="form.invalid">
                <mat-icon>check</mat-icon>
            </button>

            <h3 class="m-0">
                {{ form.value.extra_field_form_title }}
            </h3>

            <button type="button" mat-icon-button (click)="goBack(form)" class="meal-dialog-float-button back">
                <mat-icon>redo</mat-icon>
            </button>
            
        </div>

        <div #topline style="height: 1px;"></div>

        <form novalidate class="basic-form mat-body" [formGroup]="form">

            <!-- <ng-container *ngIf="!form.value.fdq_day_info_item_name">
                <div class="centered-row m-0">
                    <mat-form-field class="" [floatLabel]="'never'">
                        <input type="text" placeholder="{{'food_diary.MEAL_SEARCH' | translate}}" matInput formControlName="extra_field_meal_search">
                    </mat-form-field>
                    <ng-container *ngIf="!mealSearched">
                        <div *ngIf="form.value.extra_field_meal_search.length > 0" matSuffix class="m-r-5 m-l-5">
                            <button type="button" (click)="searchFoodServer(form)" mat-stroked-button color="primary">
                                {{'food_diary.MEAL_SEARCH_FOOD' | translate}}
                            </button>
                        </div>
                        <div *ngIf="form.value.extra_field_meal_search.length === 0" (click)="showScanner(form)" class="barcode-container pointer">
                            <span class="barcode-span"></span>
                        </div>
                    </ng-container>
                    <div *ngIf="mealSearched" class="m-r-5 m-l-5">
                        <button type="button" (click)="addCustomItem(form)" mat-stroked-button color="primary" [disabled]="form.value.extra_field_meal_search.length === 0 || searchInProgress">
                            {{'food_diary.MEAL_CUSTOM' | translate}}
                        </button>
                    </div>
                </div>

                <div *ngIf="mealSearched && searchInProgress" class="meals-loader-container bg">
                    <div class="spinner no-margin">
                        <div class="double-bounce1"></div>
                        <div class="double-bounce2"></div>
                    </div>
                </div>

                <ng-container *ngIf="mealSearched && serverMealsBySearch && serverMealsBySearch.length > 0">
                    <div class="last-items-container">
                        <div class="repeat-container w65 p-b-0 m-b-0 m-auto"></div>
                        <div class="repeat-container w65 p-b-0 m-b-0 m-auto" *ngFor="let item of serverMealsBySearch;">
                            <div class="centered-row m-0">
                                <div class="last-items-img">
                                    <img *ngIf="(item | mediaAssets).url" [src]="(item | mediaAssets).url" />
                                </div>
                                <div class="full-width">
                                    <div class="m-b-5 m-t-5">
                                        <div class="font-bold">{{ item.title || '' }}</div>
                                    </div>
                                </div>
                                <div class="m-r-10">
                                    <button mat-icon-button type="button" (click)="addFromServer(form, item)">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="!mealSearched && allLastItemsFiltered && allLastItemsFiltered.length > 0">
                    <div class="centered-row mb125">
                        <span class="text-center text-info font-16">{{ 'food_diary.MEAL_LAST_ADDED_ITEMS' | translate }}</span>
                    </div>

                    <div class="last-items-container" [hidden]="!openLastItems">
                        <div class="repeat-container w65 p-b-0 m-b-0 m-auto"></div>
                        <div class="repeat-container w65 p-b-10 m-b-0 m-auto" *ngFor="let item of lastMealsBySearch; let i = index; let last = last;">
                            <div class="centered-row m-0">
                                <div *ngIf="item.fdq_day_info_assets && item.fdq_day_info_assets[0]" class="last-items-img">
                                    <img [src]="item.fdq_day_info_assets[0]['url']" />
                                </div>
                                <div class="full-width">
                                    <div class="m-b-5 m-t-5">
                                        <div class="font-italic font-bold">{{ item.fdq_day_info_item_name }}</div>
                                    </div>
                                    <div *ngIf="item.fdq_day_info_portion && item.fdq_day_info_quantity" class="row m-0">
                                        <div class="">
                                            <span>{{ item.fdq_day_info_portion }} </span>
                                            <span *ngIf='!(item.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || item.fdq_day_info_quantity == "כמות מדודה (גרם)")'>{{ item.fdq_day_info_quantity }} </span>
                                            <span *ngIf='item.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || item.fdq_day_info_quantity == "כמות מדודה (גרם)"'>
                                                כמות מדודה 
                                                ({{ item.fdq_day_info_weight_or_volume }} {{item.fdq_day_info_units }})
                                            </span>
                                        </div>
                                        <div *ngIf="item.fdq_day_info_item_calories" class="">
                                            <span class="font-bold">{{ item.fdq_day_info_item_calories }} </span>
                                            <span>{{'food_diary.MEAL_CALORIES' | translate}} </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-r-10">
                                    <button mat-icon-button type="button" (click)="cloneItemServer(form, item)">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </ng-container> -->

            <!-- <div class="centered-row m-0 m-b-15" *ngIf="false && !form.value.fdq_day_info_item_name">
                <mat-form-field class="" [floatLabel]="'never'">
                    <input type="text" placeholder="{{'food_diary.MEAL_CHOOSE' | translate}}" matInput formControlName="extra_field_input_items" [matAutocomplete]="itemsAutoComplete">
                    <mat-autocomplete class="meal-ac" #itemsAutoComplete="matAutocomplete" [displayWith]="displayFnItems">
                        <mat-option *ngFor="let option of form.value.extra_field_items_options | async" [value]="option">
                        {{option.title}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <div>
                    <div>
                        <button class="m-r-20" [disabled]="!(form.value.extra_field_input_items && form.value.extra_field_input_items._id)" (click)="chooseAcItem(form)" type="button" mat-raised-button color="primary" mat-button>
                            {{'food_diary.MEAL_SELECT' | translate}} <mat-icon>add_circle_outline</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="!isMinor">
                        <button class="m-r-20 m-t-10" [disabled]="(form.value.extra_field_input_items && form.value.extra_field_input_items._id)" (click)="chooseCustomItem(form)" type="button" mat-stroked-button color="primary" mat-button>
                            {{'food_diary.MEAL_CUSTOM' | translate}}
                        </button>
                    </div>
                    <div *ngIf="isMinor">
                        <button class="m-r-20 m-t-10" [disabled]="(form.value.extra_field_input_items._id)" (click)="chooseCustomItem(form)" type="button" mat-raised-button color="accent" mat-button>
                            {{'food_diary.MEAL_CUSTOM_MINOR' | translate}}
                        </button>
                    </div>
                </div>
            </div> -->

            <ng-container *ngIf="form.value.fdq_day_info_item_name">

                <!-- <div *ngIf="isMinor && form.value.fdq_day_info_item_type === 'MEAL'" class="p-x-12 m-0 mb125 centered-row between">
                    <mat-form-field class="w70">
                        <mat-select formControlName="fdq_day_info_time" placeholder="{{ 'food_diary.MEAL_TIME' | translate }}">
                            <mat-option *ngFor="let time of allHours" [value]="time">{{ time }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="w27 text-center">
                        <div (click)="showScanner(form)" class="barcode-container pointer">
                            <span class="barcode-span"></span>
                        </div>
                    </div>
                </div> -->
                <!-- <mat-form-field *ngIf="!(isMinor && form.value.fdq_day_info_item_type === 'MEAL')" class="mb125 p-x-12">
                    <mat-select formControlName="fdq_day_info_time" placeholder="{{ 'food_diary.MEAL_TIME' | translate }}">
                        <mat-option *ngFor="let time of allHours" [value]="time">{{ time }}</mat-option>
                    </mat-select>
                </mat-form-field> -->

                <div class="m-b-15">
                    <!-- <div class="font-16 font-bold m-b-15">
                        {{ form.value.fdq_day_info_item_type === 'MEAL' ? mealTitle : form.value.fdq_day_info_item_name }}
                    </div> -->
                    <div class="font-16 font-bold m-b-15">
                        {{ form.value.fdq_day_info_item_name }}
                    </div>
                    
                    <div *ngIf="form.value.fdq_day_info_item_type === 'FOOD' || form.value.fdq_day_info_item_type === 'DRINK'" style="display: flex; justify-content: flex-end;">
                        <div *ngIf="form.value.fdq_day_info_assets && form.value.fdq_day_info_assets[0]" class="">
                            <img style="max-height: 120px; width: auto;" [src]="form.value.fdq_day_info_assets[0]['url']" />
                        </div>
                    </div>
                    <div *ngIf="form.value.fdq_day_info_item_type === 'MEAL'" style="display: flex; justify-content: flex-end;">
                        <vzn-media-files
                            [uploadFolder]="uploadFolderBase + '/' + mealName + '_'"
                            [width]="'auto'"
                            [height]="'120px'"
                            [assetMargin]="'5px'"
                            [uploadUrlService]="uploadUrlService"
                            [formArrayName]="'fdq_day_info_assets'"
                            [acceptedFiles]="'image/jpeg, image/x-png, image/png, image/webp'"
                            [limitToOne]="true"
                            [disabled]="nutritionistFormMode || isLocked"
                            [addPhotoIconName]="'add_a_photo'"
                            (onAssetClick)="openImageDialog($event.url)"  
                        >
                        </vzn-media-files>
                    </div>
                    
                </div>

                <div class="mat-error custom-error mb75" *ngIf="form.errors">
                    {{ form.errors.msg }}
                </div>

                <mat-form-field *ngIf="form.value.fdq_day_info_item_type === 'MEAL'" class="p-x-12">
                    <input formControlName="fdq_day_info_item_description" matInput placeholder="{{'food_diary.MEAL_DESC' | translate}}">
                </mat-form-field>
                
                <mat-form-field *ngIf="form.value.fdq_day_info_item_type === 'FOOD' || form.value.fdq_day_info_item_type === 'MEAL'" class="p-x-12">
                    <mat-select formControlName="fdq_day_info_quantity" placeholder="{{'food_diary.F_QUANTITY_Q' | translate}}">

                        <ng-container *ngIf="form.value.fdq_day_info_item_real_barcode">
                            <ng-container *ngFor="let mida of ((form.value.fdq_day_info_item_nut_midas && form.value.fdq_day_info_item_nut_midas.food_energy) || [])">
                                <mat-option *ngIf="mida && mida.sum" [value]="mida.title">
                                    <span *ngIf="!(mida.title.includes('ל-100 גרם') || mida.title.includes('ל-100 מל'))">{{ mida.title }} </span>
                                    ({{mida.sum}} {{"food_diary.F_WEIGHT_VALUE" | translate}})
                                </mat-option>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!form.value.fdq_day_info_item_real_barcode">
                            <ng-container *ngFor="let mida of ((form.value.fdq_day_info_item_nut_midas && form.value.fdq_day_info_item_nut_midas.food_energy) || [])">
                                <mat-option [value]="mida.title">
                                    <span>{{ mida.title }} </span>
                                    <span *ngIf="mida.sum != 100">({{mida.sum}} {{"food_diary.F_WEIGHT_VALUE" | translate}})</span>
                                </mat-option>
                            </ng-container>
                        </ng-container>
                        
                        <mat-option value="כמות מדודה (גרם)">{{'food_diary.F_QUANTITY_A_12' | translate}}</mat-option>
                        <ng-container *ngIf="form.value.fdq_day_info_item_type === 'MEAL'">
                            <mat-option value="יחידה קטנה">{{'food_diary.F_QUANTITY_A_1' | translate}}</mat-option>
                            <mat-option value="יחידה בינונית">{{'food_diary.F_QUANTITY_A_2' | translate}}</mat-option>
                            <mat-option value="יחידה גדולה">{{'food_diary.F_QUANTITY_A_3' | translate}}</mat-option>
                            <mat-option value="כפית">{{'food_diary.F_QUANTITY_A_4' | translate}}</mat-option>
                            <mat-option value="כף">{{'food_diary.F_QUANTITY_A_5' | translate}}</mat-option>
                            <mat-option value="כוס">{{'food_diary.F_QUANTITY_A_6' | translate}}</mat-option>
                            <mat-option value="קערה קטנה">{{'food_diary.F_QUANTITY_A_13' | translate}}</mat-option>
                            <mat-option value="קערה בינונית">{{'food_diary.F_QUANTITY_A_14' | translate}}</mat-option>
                            <mat-option value="קערה גדולה">{{'food_diary.F_QUANTITY_A_15' | translate}}</mat-option>
                            <mat-option value="פרוסה">{{'food_diary.F_QUANTITY_A_7' | translate}}</mat-option>
                            <mat-option value="מנה קטנה">{{'food_diary.F_QUANTITY_A_8' | translate}}</mat-option>
                            <mat-option value="מנה בינונית">{{'food_diary.F_QUANTITY_A_9' | translate}}</mat-option>
                            <mat-option value="מנה גדולה">{{'food_diary.F_QUANTITY_A_10' | translate}}</mat-option>
                            <mat-option value="חבילה">{{'food_diary.F_QUANTITY_A_11' | translate}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="form.value.fdq_day_info_item_type === 'DRINK'" class="p-x-12">
                    <mat-select formControlName="fdq_day_info_quantity" placeholder="{{'food_diary.D_QUANTITY_Q' | translate}}">

                        <ng-container *ngIf="form.value.fdq_day_info_item_real_barcode">
                            <ng-container *ngFor="let mida of ((form.value.fdq_day_info_item_nut_midas && form.value.fdq_day_info_item_nut_midas.food_energy) || [])">
                                <mat-option *ngIf="mida && mida.sum" [value]="mida.title">
                                    <span *ngIf="!(mida.title.includes('ל-100 גרם') || mida.title.includes('ל-100 מל'))">{{ mida.title }} </span>
                                    ({{mida.sum}} {{"food_diary.D_VOLUME_VALUE" | translate}})
                                </mat-option>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!form.value.fdq_day_info_item_real_barcode">
                            <ng-container *ngFor="let mida of ((form.value.fdq_day_info_item_nut_midas && form.value.fdq_day_info_item_nut_midas.food_energy) || [])">
                                <mat-option [value]="mida.title">
                                    <span>{{ mida.title }} </span>
                                    <span *ngIf="mida.sum != 100">({{mida.sum}} {{"food_diary.D_VOLUME_VALUE" | translate}})</span>
                                </mat-option>
                            </ng-container>
                        </ng-container>

                        <!-- <mat-option value='כוס (200 מ"ל)'>{{'food_diary.D_QUANTITY_A_1' | translate}}</mat-option> -->
                        <!-- <mat-option value='פחית (333 מ"ל)'>{{'food_diary.D_QUANTITY_A_2' | translate}}</mat-option> -->
                        <!-- <mat-option value='בקבוק (500 מ"ל)'>{{'food_diary.D_QUANTITY_A_3' | translate}}</mat-option> -->
                        <mat-option value='כמות מדודה (מ"ל)'>{{'food_diary.D_QUANTITY_A_4' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf='form.value.fdq_day_info_quantity === "כמות מדודה (גרם)"' class=" p-x-12">
                    <input type="text" digitOnly inputmode="numeric" pattern="[0-9]*" min="0" maxlength="6" formControlName="fdq_day_info_weight_or_volume" matInput placeholder='{{"food_diary.F_WEIGHT_VALUE" | translate}}'>
                </mat-form-field>
                <mat-form-field *ngIf='form.value.fdq_day_info_quantity === "כמות מדודה (מ\"ל)"' class=" p-x-12">
                    <input type="text" digitOnly inputmode="numeric" pattern="[0-9]*" min="0" maxlength="6" formControlName="fdq_day_info_weight_or_volume" matInput placeholder='{{"food_diary.D_VOLUME_VALUE" | translate}}'>
                </mat-form-field>

                <mat-form-field class="p-x-12">
                    <mat-select formControlName="fdq_day_info_portion" [placeholder]="form.value.fdq_day_info_item_type === 'FOOD' ? ('food_diary.F_PORTION_NO' | translate) : ('food_diary.D_PORTION_NO' | translate)">
                        <mat-option value="¼">{{'food_diary.F_PORTION_NO_A_0' | translate}}</mat-option>
                        <mat-option value="½">{{'food_diary.F_PORTION_NO_A_1' | translate}}</mat-option>
                        <mat-option value="1">{{'food_diary.F_PORTION_NO_A_2' | translate}}</mat-option>
                        <mat-option value="1½">{{'food_diary.F_PORTION_NO_A_3' | translate}}</mat-option>
                        <mat-option value="2">{{'food_diary.F_PORTION_NO_A_4' | translate}}</mat-option>
                        <mat-option value="2½">{{'food_diary.F_PORTION_NO_A_5' | translate}}</mat-option>
                        <mat-option value="3">{{'food_diary.F_PORTION_NO_A_6' | translate}}</mat-option>
                        <mat-option value="3½">{{'food_diary.F_PORTION_NO_A_7' | translate}}</mat-option>
                        <mat-option value="4">{{'food_diary.F_PORTION_NO_A_8' | translate}}</mat-option>
                        <mat-option value="4½">{{'food_diary.F_PORTION_NO_A_9' | translate}}</mat-option>
                        <mat-option value="5">{{'food_diary.F_PORTION_NO_A_10' | translate}}</mat-option>
                        <ng-container *ngFor="let num of [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]">
                            <mat-option [value]="num">{{ num }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <div class="nut-chips-container" *ngIf="inMealNut">
                    <mat-chip-list aria-label="Nutrition values">
                        <ng-container *ngFor="let key of nutArray">
                            <mat-chip color="primary" selected>
                                <span>{{nutNames[key]}}</span>
                                <span class="bubble">{{inMealNut[key]}}</span>
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>

                <div class="text-center" *ngIf="inMealExtraNut">
                    <button mat-icon-button type="button" (click)="openExtra = false" *ngIf="openExtra">
                        <mat-icon>expand_less</mat-icon>
                    </button>
                    <button mat-icon-button type="button" (click)="openExtra = true" *ngIf="!openExtra">
                        <mat-icon>expand_more</mat-icon>
                    </button>
                </div>
                <div class="nut-stacked" *ngIf="inMealExtraNut && openExtra">
                    <div class="nut-chips-container stacked">
                        <mat-chip-list class="mat-chip-list-stacked" aria-label="Nutrition extra values">
                            <ng-container *ngFor="let key of nutExtraArray">
                                <mat-chip selected>
                                    <span>{{nutExtraNames[key]}}</span>
                                    <span class="bubble">{{inMealExtraNut[key]}}</span>
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </div>
                </div>

                <div *ngIf="form.value.fdq_day_info_item_real_barcode" class="mt125 mb75 font-12 font-italic mat-body text-warning">{{ 'food_diary.EXTRA_NOTE_PRODUCTS' | translate }}</div>

                <!-- <ng-container *ngIf="form.value.fdq_day_info_item_type === 'MEAL' && allLastItemsFilteredCustom && allLastItemsFilteredCustom.length > 0">
                    <div class="centered-row mb125 mt125">
                        <span class="text-center text-info font-16">{{ 'food_diary.MEAL_LAST_ADDED_ITEMS' | translate }}</span>
                    </div>

                    <div class="last-items-container" [hidden]="!openLastItems">
                        <div class="repeat-container w65 p-b-0 m-b-0 m-auto"></div>
                        <div class="repeat-container w65 p-b-10 m-b-0 m-auto" *ngFor="let item of allLastItemsFilteredCustom; let i = index; let last = last;">
                            <div class="centered-row m-0">
                                <div *ngIf="item.fdq_day_info_assets && item.fdq_day_info_assets[0]" class="last-items-img">
                                    <img [src]="item.fdq_day_info_assets[0]['url']" />
                                </div>
                                <div class="full-width">
                                    <div class="m-b-10">
                                        <div class="font-italic font-bold">{{ item.fdq_day_info_item_name }}</div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="">
                                            <span>{{ item.fdq_day_info_portion }} </span>
                                            <span *ngIf='!(item.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || item.fdq_day_info_quantity == "כמות מדודה (גרם)")'>{{ item.fdq_day_info_quantity }} </span>
                                            <span *ngIf='item.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || item.fdq_day_info_quantity == "כמות מדודה (גרם)"'>
                                                כמות מדודה 
                                                ({{ item.fdq_day_info_weight_or_volume }} {{item.fdq_day_info_units }})
                                            </span>
                                        </div>
                                        <div *ngIf="item.fdq_day_info_item_calories" class="">
                                            <span class="font-bold">{{ item.fdq_day_info_item_calories }} </span>
                                            <span>{{'food_diary.MEAL_CALORIES' | translate}} </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-r-10">
                                    <button mat-icon-button type="button" (click)="cloneItemCustom(form, item)">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container> -->

            </ng-container>

        </form>
    </ng-template>

    <ng-template #waterDialogTemplate let-context="context" let-form="form">

        <div class="m-b-20 m-0 centered-row between">

            <button type="button" mat-icon-button (click)="goDone(form)" class="meal-dialog-float-button done" [disabled]="form.invalid">
                <mat-icon>check</mat-icon>
            </button>
            
            <h3 class="m-0">
                {{ form.value.extra_field_form_title }}
            </h3>

            <button type="button" mat-icon-button (click)="goBack(form)" class="meal-dialog-float-button back">
                <mat-icon>redo</mat-icon>
            </button>

        </div>

        <div mat-dialog-content>
            <form novalidate class="basic-form" [formGroup]="form">

                <!-- <mat-form-field class="mb125 p-x-12">
                    <mat-select formControlName="fdq_day_info_time" placeholder="{{ 'food_diary.DRINK_TIME' | translate }}">
                        <mat-option *ngFor="let time of allHours" [value]="time">{{ time }}</mat-option>
                    </mat-select>
                </mat-form-field> -->

                <mat-form-field class="p-x-12">
                    <mat-select formControlName="fdq_day_info_quantity" placeholder="{{'food_diary.D_QUANTITY_WATER' | translate}}">
                        <mat-option value='כוס (200 מ"ל)'>{{'food_diary.D_QUANTITY_A_1' | translate}}</mat-option>
                        <mat-option value='בקבוק (500 מ"ל)'>{{'food_diary.D_QUANTITY_A_3' | translate}}</mat-option>
                        <mat-option value='כמות מדודה (מ"ל)'>{{'food_diary.D_QUANTITY_A_4' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field
                    *ngIf='form.value.fdq_day_info_quantity =="כמות מדודה (מ\"ל)"'
                    class="p-x-12">
                    <input type="text" digitOnly inputmode="numeric" pattern="[0-9]*" min="0" maxlength="6" formControlName="fdq_day_info_weight_or_volume" matInput placeholder='{{"food_diary.D_VOLUME_VALUE" | translate}}'>
                </mat-form-field>

                <mat-form-field class="p-x-12">
                    <mat-select formControlName="fdq_day_info_portion" placeholder='{{"food_diary.D_PORTION_NO" | translate}}'>
                        <mat-option value="¼">{{'food_diary.F_PORTION_NO_A_0' | translate}}</mat-option>
                        <mat-option value="½">{{'food_diary.F_PORTION_NO_A_1' | translate}}</mat-option>
                        <mat-option value="1">{{'food_diary.F_PORTION_NO_A_2' | translate}}</mat-option>
                        <mat-option value="1½">{{'food_diary.F_PORTION_NO_A_3' | translate}}</mat-option>
                        <mat-option value="2">{{'food_diary.F_PORTION_NO_A_4' | translate}}</mat-option>
                        <mat-option value="2½">{{'food_diary.F_PORTION_NO_A_5' | translate}}</mat-option>
                        <mat-option value="3">{{'food_diary.F_PORTION_NO_A_6' | translate}}</mat-option>
                        <mat-option value="3½">{{'food_diary.F_PORTION_NO_A_7' | translate}}</mat-option>
                        <mat-option value="4">{{'food_diary.F_PORTION_NO_A_8' | translate}}</mat-option>
                        <mat-option value="4½">{{'food_diary.F_PORTION_NO_A_9' | translate}}</mat-option>
                        <mat-option value="5">{{'food_diary.F_PORTION_NO_A_10' | translate}}</mat-option>
                        <ng-container *ngFor="let num of [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]">
                            <mat-option [value]="num">{{ num }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

            </form>
        </div>
    </ng-template>
    
</div>
