<div class="full-loader" *ngIf="!showLogin">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>

<ng-container *ngIf="showLogin">

<div class="login-register">
    <div class="login-lang">
        <button [matMenuTriggerFor]="langSwitcher" mat-icon-button>
            <mat-icon class="m-0">language</mat-icon>
        </button>
        <mat-menu #langSwitcher="matMenu" class="lang-switcher">
            <button mat-menu-item (click)="useLanguage('he')" [disabled]="(translate.currentLang === 'he' || translate.currentLang === 'he_F')">
                עברית
            </button>
            <button mat-menu-item (click)="useLanguage('en')" [disabled]="translate.currentLang === 'en'">
                English
            </button>
        </mat-menu>
    </div>
</div>
<vzn-login [authService]="authService" (authSuccess)="authSuccess($event, redirectUri)" loginBy="phone"></vzn-login>

<div class="login-rights-block">
    <div class="" *ngIf="(translate.currentLang === 'he' || translate.currentLang === 'he_F')">
        כל הזכויות שמורות לאול אראונד פלייר פרו בע"מ ח.פ. 516109766
        כתובת- שמואל הנביא 3 הוד השרון ישראל 4538308
    </div>
    <div class="dir-rtl" *ngIf="translate.currentLang === 'en'">
        All rights reserved to All Around Player Pro LTD B.N. 516109766
        Address 3 Shmuel Hanavi St Hod Hasharon Israel 4538308
    </div>
</div>

<a *ngIf="waLink" [href]="waLink" class="wa-float" target="_blank">
    <i class="fa fa-whatsapp"></i>
</a>

</ng-container>
