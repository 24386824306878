export module appState {
    export let user = {
        _id : '',
        usr_type : '',
        usr_fname : '',
        usr_lname : '',
        usr_gender : '',
        usr_phone : '',
        usr_email : '',
        usr_club_id: '',
        usr_agreement: false,
        usr_state: null,
    }
    export let accessToken = ''
    
    export let quizUser: any

    export let allNotesUnfiltered: any = []
    export let allNotes: any = {}
    export let isLoadingNotes: boolean = false

    // export let lastBarcode = ''
}
