<div>

    <form novalidate class="basic-form" [formGroup]="sleepingquizForm" (ngSubmit)="onSubmit(sleepingquizForm, quizPrefix)">

        <div class="row">
            <h1>{{'sleeping.QUIZ_TITLE' | translate}}</h1>
            <!-- <button type="button" *ngIf="isOtherUser" class="plus-button" mat-raised-button [routerLink]="['/Nutritionistform/', sleepingquizForm.value.slq_usr_id]">
                חזור לטופס תזונאי
                <mat-icon>undo</mat-icon>
            </button> -->
            
            <button type="button" *ngIf="appState.user && appState.user.usr_type == 'parent'" class="plus-button" mat-raised-button [routerLink]="['/Userparent']">
                <span>{{'general.undo' | translate}} </span>
                <mat-icon>undo</mat-icon>
            </button>
        </div>
        
        <mat-card *ngIf="!sleepingquizForm.value._id || sleepingquizForm.value._id==''">
            <div class="spinner">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
        </mat-card>

        <div [hidden]="!sleepingquizForm.value._id || sleepingquizForm.value._id==''">

        <mat-card>
            <h3 [ngClass]="{ 'required-question': showErrors && sleepingquizForm.get('slq_weekday_sleeping_time').invalid }">{{'sleeping.Q_1' | translate}}</h3>
            <div class="w27">
                <mat-form-field>
                    <mat-select formControlName="slq_weekday_sleeping_time">
                        <mat-option [value]="hour" *ngFor="let hour of sleepingHours">{{ hour }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <h3 [ngClass]="{ 'required-question': showErrors && sleepingquizForm.get('slq_weekday_wakeup_time').invalid }">{{'sleeping.Q_2' | translate}}</h3>
            <div class="w27">
                <mat-form-field>
                    <mat-select formControlName="slq_weekday_wakeup_time">
                        <mat-option [value]="hour" *ngFor="let hour of wakingHours">{{ hour }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <h3 [ngClass]="{ 'required-question': showErrors && sleepingquizForm.get('slq_weekend_sleeping_time').invalid }">{{'sleeping.Q_3' | translate}}</h3>
            <div class="w27">
                <mat-form-field>
                    <mat-select formControlName="slq_weekend_sleeping_time">
                        <mat-option [value]="hour" *ngFor="let hour of sleepingHours">{{ hour }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <h3 [ngClass]="{ 'required-question': showErrors && sleepingquizForm.get('slq_weekend_wakeup_time').invalid }">{{'sleeping.Q_4' | translate}}</h3>
            <div class="w27">
                <mat-form-field>
                    <mat-select formControlName="slq_weekend_wakeup_time">
                        <mat-option [value]="hour" *ngFor="let hour of wakingHours">{{ hour }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <h3 [ngClass]="{ 'required-question': showErrors && sleepingquizForm.get('slq_waking_difficulty').invalid }">{{'sleeping.Q_5' | translate}}</h3>
            <div class="w27">
                <mat-radio-group formControlName="slq_waking_difficulty">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <h3 [ngClass]="{ 'required-question': showErrors && sleepingquizForm.get('slq_tiredness_during_day').invalid }">{{'sleeping.Q_6' | translate}}</h3>
            <div class="w27">
                <mat-radio-group formControlName="slq_tiredness_during_day">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <h3 [ngClass]="{ 'required-question': showErrors && sleepingquizForm.get('slq_sleeping_difficulty').invalid }">{{'sleeping.Q_7' | translate}}</h3>
            <div class="w27">
                <mat-radio-group formControlName="slq_sleeping_difficulty" [ngClass]="{'mb50' : sleepingquizForm.value.slq_sleeping_difficulty == 'כן'}">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <ng-container *ngIf="sleepingquizForm.value.slq_sleeping_difficulty == 'כן'">

                <mat-form-field>
                    <input formControlName="slq_sleeping_difficulty_description" matInput placeholder="{{'sleeping.Q_7_A' | translate}}">
                </mat-form-field>
                <div class="w45">
                    <mat-form-field>
                        <mat-select formControlName="slq_sleeping_difficulty_duration" placeholder="{{'sleeping.Q_7_B' | translate}}">
                            <mat-option value="0-15">{{ 'sleeping.Q_7_C' | translate }}</mat-option>
                            <mat-option value="15-30">{{ 'sleeping.Q_7_D' | translate }}</mat-option>
                            <mat-option value="30-45">{{ 'sleeping.Q_7_E' | translate }}</mat-option>
                            <mat-option value="45+">{{ 'sleeping.Q_7_F' | translate }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>


            </ng-container>

            <h3 [ngClass]="{ 'required-question': showErrors && sleepingquizForm.get('slq_screen_habits').invalid }">{{'sleeping.Q_8' | translate}}</h3>
            <div class="w27">
                <mat-radio-group formControlName="slq_screen_habits">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>


            <h3 [ngClass]="{ 'required-question': showErrors && sleepingquizForm.get('slq_nap_habits').invalid }">{{'sleeping.Q_9' | translate}}</h3>
            <div class="w27">
                <mat-radio-group formControlName="slq_nap_habits" [ngClass]="{'mb50' : sleepingquizForm.value.slq_nap_habits == 'כן'}">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div class="w45" *ngIf="sleepingquizForm.value.slq_nap_habits == 'כן'">

                <mat-form-field>
                    <mat-select formControlName="slq_nap_habits_frequency" placeholder="{{'sleeping.Q_9_A' | translate}}">
                        <mat-option value="0-1">{{ 'sleeping.Q_9_B' | translate }}</mat-option>
                        <mat-option value="2-4">{{ 'sleeping.Q_9_C' | translate }}</mat-option>
                        <mat-option value="5-7">{{ 'sleeping.Q_9_D' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field>
                    <mat-select formControlName="slq_nap_habits_time" placeholder="{{'sleeping.Q_11_A' | translate}}">
                        <mat-option value="0-30">{{ 'sleeping.Q_11_B' | translate }}</mat-option>
                        <mat-option value="30-60">{{ 'sleeping.Q_11_C' | translate }}</mat-option>
                        <mat-option value="60-90">{{ 'sleeping.Q_11_D' | translate }}</mat-option>
                        <mat-option value="90+">{{ 'sleeping.Q_11_E' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <h3 [ngClass]="{ 'required-question': showErrors && sleepingquizForm.get('slq_more_comments').invalid }">{{'sleeping.Q_10' | translate}}</h3>
            <div>
                <mat-radio-group formControlName="slq_more_comments" [ngClass]="{'mb50' : sleepingquizForm.value.slq_more_comments == 'כן'}">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <mat-form-field *ngIf="sleepingquizForm.value.slq_more_comments == 'כן'" class="w45">
                <input formControlName="slq_more_comments_text" matInput placeholder="{{'general.extraNotes' | translate}}">
            </mat-form-field>
        </mat-card>
        <br>

        <button mat-raised-button color="primary" class="full-width" [disabled]="isLocked || isBusy">{{'general.save' | translate}}</button>
        <div *ngIf="isLocked" class="font-12 text-center text-danger">
            {{'general.savedQuiz' | translate}}
        </div>
        <!-- <div *ngIf="isOtherUser" class="mt125 row">
            <div>
            </div>
            <button type="button" class="plus-button" mat-raised-button [routerLink]="['/Nutritionistform/', sleepingquizForm.value.slq_usr_id]">
                חזור לטופס תזונאי
                <mat-icon>undo</mat-icon>
            </button>
        </div> -->
        </div>
    </form>

</div>
