import { Component, ViewChild, TemplateRef, ChangeDetectorRef, ElementRef } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray, Validators, ValidatorFn, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { appState } from '@services/app-state';

import { TableColumn } from '@noammazuz/vzn-data-table';

import { TranslateService } from '@ngx-translate/core';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';

import * as moment from 'moment-timezone';
import fileDownload from 'js-file-download';
import { Parser } from 'json2csv';
// import encoding from 'encoding';

import { environment } from '@environments/environment';

@Component({
  templateUrl: "./userstable.component.html"
})
export class UserstableComponent {

  // isQa = !environment.production;

  @ViewChild("tableEl") tableEl: any;

  @ViewChild("exportCsvTemplate") exportCsvTemplate: TemplateRef<any>;

  @ViewChild("manualTemplate") manualTemplate: TemplateRef<any>;
  @ViewChild("stateTemplate") stateTemplate: TemplateRef<any>;
  @ViewChild("followTemplate") followTemplate: TemplateRef<any>;
  @ViewChild("pushDialogTemplate") pushDialogTemplate: TemplateRef<any>;
  @ViewChild("newQuizzesTemplate") newQuizzesTemplate: TemplateRef<any>;

  @ViewChild("measurementTemplate") measurementTemplate: TemplateRef<any>;

  @ViewChild("dateTimezoneTmpl") dateTimezoneTmpl: TemplateRef<any>;
  
  @ViewChild("checkboxHdrTmpl") checkboxHdrTmpl: TemplateRef<any>;

  // @ViewChild("editPlanTmpl") editPlanTmpl: TemplateRef<any>;
  // @ViewChild("daysTmpl") daysTmpl: TemplateRef<any>;
  @ViewChild("quizDaysTmpl") quizDaysTmpl: TemplateRef<any>;
  @ViewChild("checkTmpl") checkTmpl: TemplateRef<any>;
  @ViewChild("checkboxTmpl") checkboxTmpl: TemplateRef<any>;
  @ViewChild("lastNtqTmpl") lastNtqTmpl: TemplateRef<any>;
  // @ViewChild("isFollowTmpl") isFollowTmpl: TemplateRef<any>;
  @ViewChild("iconsTmpl") iconsTmpl: TemplateRef<any>;
  @ViewChild("totalMeetingsTmpl") totalMeetingsTmpl: TemplateRef<any>;
  @ViewChild("totalReportsTmpl") totalReportsTmpl: TemplateRef<any>;
  @ViewChild("foodDaysTmpl") foodDaysTmpl: TemplateRef<any>;
  @ViewChild("bloodDaysTmpl") bloodDaysTmpl: TemplateRef<any>;

  @ViewChild('pushTitle') pushTitle: ElementRef<HTMLInputElement>;
  @ViewChild('pushContent') pushContent: ElementRef<HTMLTextAreaElement>;

  appState = appState;
  usersRestService = null;
  // clubRestService = null;
  columns: Array<{}> = [];
  query: any;

  allClubsOptions: any[] = [];

  busy = false;

  chosenIds: string[] = [];
  chosenUsers: any[] = [];

  someChosen = false;
  allChosen = false;

  allNotesUnfiltered;

  // typeOptions = [
  //   { viewValue: 'ספורטאי', value: 'player' },
  //   { viewValue: 'הורה', value: 'parent' },
  //   { viewValue: 'תזונאי', value: 'nutri' },
  //   { viewValue: 'מאמן', value: 'coach' },
  //   { viewValue: 'מנהל', value: 'admin' },
  // ];

  userTypes = {
    'player': 'ספורטאי/ת',
    'parent': 'הורה',
    'nutri': 'תזונאי',
    'coach': 'מאמן',
    'admin': 'מנהל',
  }

  typeNameOptions = [];

  optionsShare = [
    { viewValue: 'מאשר', value: 'מאשר' },
    { viewValue: 'לא מאשר', value: 'לא מאשר' },
  ];
  // optionsSubscribe = [
  //   { viewValue: 'מאשר', value: true },
  //   { viewValue: 'לא מאשר', value: false },
  // ];
  
  foodDaysOptions = [
    { viewValue: '0', value: '0' },
    { viewValue: '1', value: '1' },
    { viewValue: '2', value: '2' },
    { viewValue: '+3', value: '3' },
  ];

  totalReportsOptions = [
    { viewValue: 'נותרו דוחות', value: '-1' },
    { viewValue: '0', value: '0' },
    { viewValue: '1', value: '1' },
    { viewValue: '2', value: '2' },
    { viewValue: '+3', value: '3' },
  ];

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private dialogService: ConfirmDialogService,
    private restApiService: RestApiService<any>,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef,
    private http: HttpClient
  ) {
    this.usersRestService = this.restApiService.service('users');
    // this.clubRestService = this.restApiService.service('club');
  }

  // async ngOnInit() {
  //   await this.fetchNotes();
  //   // await this.compare();
  // }

  async ngAfterViewInit() {
    const clubs = await this.restApiService.service('club').find({
      query: {
        $paginate: false,
        $select: ['_id', 'clb_name'],
        $sort: {
          clb_name: 1
        },
        clb_is_deleted: {
          $ne: true
        },
      }
    });
    this.allClubsOptions = clubs.map((club: any) => {
      return { value: club._id, viewValue: club.clb_name };
    });
    this.typeNameOptions = this.getTypeNameOptions();
    this.columns = this.createColumns();
    this.query = {
      $userstable: true,
      // $select: ['_id', 'createdAt', 'usr_notification_token', 'usr_fname', 'usr_lname', 'usr_phone', 'usr_email', 'usr_gender', 'usr_birth_date', 'usr_club_id', 'usr_club_ids', 'usr_type', 'usr_manual_plan', 'usr_is_follow', 'usr_report_count', 'usr_last_login', 'usr_parent_1_phone', 'usr_parent_2_phone', 'usr_subscribe', 'usr_state', 'usr_blood_time', 'usr_blood_saved'],
      usr_is_deleted: {
        $ne: true
      },
      usr_type: {
        $nin: ['superadmin']
      },
      $sort: {
        // usr_fname: 1
        // savedAtDays: -1
        quiz_days: -1
      }
    };
    this.changeDetectorRef.detectChanges();
  }

  getTypeNameOptions() {
    let typeNameOptions: any[];
    // if (this.appState.user.usr_type === 'superadmin') {
      typeNameOptions = [
        { viewValue: 'ספורטאי', value: 'ספורטאי' },
        { viewValue: 'ספורטאית', value: 'ספורטאית' },
        { viewValue: 'ספורטאי/ת', value: 'ספורטאי/ת' },
        { viewValue: 'הורה', value: 'הורה' },
        { viewValue: 'תזונאי', value: 'תזונאי' },
        { viewValue: 'מאמן', value: 'מאמן' },
        { viewValue: 'מנהל', value: 'מנהל' }
      ];
    // } else {
    //   typeNameOptions = [
    //     { viewValue: 'ספורטאי', value: 'ספורטאי' },
    //     { viewValue: 'ספורטאית', value: 'ספורטאית' },
    //     { viewValue: 'ספורטאי/ת', value: 'ספורטאי/ת' },
    //     { viewValue: 'הורה', value: 'הורה' },
    //     // { viewValue: 'תזונאי', value: 'תזונאי' },
    //     { viewValue: 'מאמן', value: 'מאמן' },
    //     { viewValue: 'מנהל', value: 'מנהל' }
    //   ];
    // }
    return typeNameOptions;
  }

  createColumns() {
    // let columns: Array<TableColumn>;
    let columns: Array<any>;

    if (this.appState.user.usr_type === 'superadmin') {
      columns = [
        // { prop: 'choose_box', name: '', customTemplate: this.checkboxTmpl, maxWidth: 70, headerTemplate: 'boolHdTmpl' },
        { prop: 'choose_box', name: '', customTemplate: this.checkboxTmpl, maxWidth: 70, customHdrTemplate: this.checkboxHdrTmpl, sortable: false },
        { prop: 'icons_box', name: '', customTemplate: this.iconsTmpl, maxWidth: 120, sortable: false },
        { prop: 'usr_type_name', name: 'סוג משתמש', cellTemplate: 'optionsTmpl', filterType: 'options', options: this.typeNameOptions },
        { prop: 'usr_fname', name: 'שם פרטי', filterType: 'like' },
        { prop: 'usr_lname', name: 'שם משפחה', filterType: 'like' },
        { prop: 'usr_phone', name: 'טלפון', filterType: 'like' },
        { prop: 'usr_birth_date', name: 'תאריך לידה', customTemplate: this.dateTimezoneTmpl },
        // { prop: 'usr_club_id', name: 'מועדון', cellTemplate: 'optionsTmpl', filterType: 'options', options: { service: this.clubRestService, query: {$select: ['_id', 'clb_name'], $sort: {clb_name: 1}}, fieldName: 'clb_name'} },
        { prop: 'usr_club_id', name: 'מועדון', cellTemplate: 'optionsTmpl', filterType: 'options', options: this.allClubsOptions },
        { prop: 'usr_team_name', name: 'קבוצה' },
        { prop: 'quiz_days', name: 'סטטוס טפסים', customTemplate: this.quizDaysTmpl },
        { prop: 'food_days', name: 'יומן אכילה', customTemplate: this.foodDaysTmpl, filterType: 'options', options: this.foodDaysOptions },
        { prop: 'blood_days', name: 'בדיקת דם', customTemplate: this.bloodDaysTmpl },
        // { prop: 'usr_is_follow', name: 'מעקב', customTemplate: this.isFollowTmpl, maxWidth: 70 },
        { prop: 'usr_sessions_total', name: 'ייעוצים', customTemplate: this.totalMeetingsTmpl },
        { prop: 'usr_measurement_date', name: 'תאריך מדידה', customTemplate: this.dateTimezoneTmpl },
        { prop: 'last_ntq_date', name: 'מדידה אחרונה', customTemplate: this.lastNtqTmpl },
        { prop: 'total_reports', name: 'דוחות', customTemplate: this.totalReportsTmpl, filterType: 'options', options: this.totalReportsOptions },
        { prop: 'last_rpt_date', name: 'דו״ח אחרון', customTemplate: this.dateTimezoneTmpl },
        { prop: 'usr_last_login', name: 'התחבר לאחרונה', customTemplate: this.dateTimezoneTmpl },
        // { prop: 'usr_manual_plan', name: 'ידני', customTemplate: this.editPlanTmpl, maxWidth: 70 },
        { prop: 'remove_button', name: '', iconName: 'remove_circle_outline', cellTemplate: 'iconBtnTmpl', iconOnClick: this.removeUser.bind(this), maxWidth: 70, sortable: false },
        { prop: 'usr_share_reports', name: 'אישור שיתוף', cellTemplate: 'optionsTmpl', filterType: 'options', options: this.optionsShare, headerClass: 'hide', cellClass: 'hide' },
        { prop: 'usr_share_reports_parents', name: 'אישור שיתוף הורים', cellTemplate: 'optionsTmpl', filterType: 'options', options: this.optionsShare, headerClass: 'hide', cellClass: 'hide' },
        { prop: 'usr_subscribe', name: 'אישור הודעות', cellTemplate: 'optionsTmpl', filterType: 'options', options: this.optionsShare, headerClass: 'hide', cellClass: 'hide' },
        { prop: 'usr_subscribe_parents', name: 'אישור הודעות הורים', cellTemplate: 'optionsTmpl', filterType: 'options', options: this.optionsShare, headerClass: 'hide', cellClass: 'hide' },
        // { prop: '_id', name: 'מזהה מערכת' },
        // { prop: 'choose_box', name: '', customTemplate: this.checkboxTmpl, flexGrow: 0, maxWidth: 70 },
        // { prop: 'usr_type', name: 'סוג משתמש', cellTemplate: 'optionsTmpl', filterType: 'options', options: typeOptions },
        // { prop: 'usr_club_name', name: 'מועדון' },
        // { prop: 'createdAtDays', name: 'טפסים פתוחים (ימים)', customTemplate: this.daysTmpl },
        // { prop: 'savedAtDays', name: 'ממתין לדוח (ימים)', customTemplate: this.daysTmpl },
        // { prop: 'last_ntq_date', name: 'תאריך מדידה אחרון', cellTemplate: 'dateNoTimeTmpl' },
        // { prop: 'usr_purchase_date', name: 'תאריך תחילת חבילה', cellTemplate: 'dateNoTimeTmpl' },
        // { prop: 'usr_payment_plan', name: 'סוג חבילה', cellTemplate: 'optionsTmpl', filterType: 'options', options: planOptions },
        // { prop: 'total_reports', name: 'מספר דוחות מתחילת החבילה' },
        // { prop: 'usr_report_count', name: 'דוחות שנרכשו' },
        // { prop: 'total_reports', name: 'דוחות שהופקו' },
      ];
    } else {
      columns = [
        { prop: 'choose_box', name: '', customTemplate: this.checkboxTmpl, maxWidth: 70, customHdrTemplate: this.checkboxHdrTmpl, sortable: false },
        { prop: 'icons_box', name: '', customTemplate: this.iconsTmpl, maxWidth: 120, sortable: false },
        { prop: 'usr_type_name', name: 'סוג משתמש', cellTemplate: 'optionsTmpl', filterType: 'options', options: this.typeNameOptions },
        { prop: 'usr_fname', name: 'שם פרטי', filterType: 'like' },
        { prop: 'usr_lname', name: 'שם משפחה', filterType: 'like' },
        { prop: 'usr_phone', name: 'טלפון', filterType: 'like' },
        { prop: 'usr_birth_date', name: 'תאריך לידה', customTemplate: this.dateTimezoneTmpl },
        // { prop: 'usr_club_id', name: 'מועדון', cellTemplate: 'optionsTmpl', filterType: 'options', options: { service: this.clubRestService, query: {$select: ['_id', 'clb_name'], $sort: {clb_name: 1}}, fieldName: 'clb_name'} },
        { prop: 'usr_club_id', name: 'מועדון', cellTemplate: 'optionsTmpl', filterType: 'options', options: this.allClubsOptions },
        { prop: 'usr_team_name', name: 'קבוצה' },
        { prop: 'quiz_days', name: 'סטטוס טפסים', customTemplate: this.quizDaysTmpl },
        { prop: 'food_days', name: 'יומן אכילה', customTemplate: this.foodDaysTmpl, filterType: 'options', options: this.foodDaysOptions },
        { prop: 'blood_days', name: 'בדיקת דם', customTemplate: this.bloodDaysTmpl },
        // { prop: 'usr_is_follow', name: 'מעקב', customTemplate: this.isFollowTmpl, maxWidth: 70 },
        { prop: 'usr_sessions_total', name: 'ייעוצים', customTemplate: this.totalMeetingsTmpl },
        { prop: 'usr_measurement_date', name: 'תאריך מדידה', customTemplate: this.dateTimezoneTmpl },
        { prop: 'last_ntq_date', name: 'מדידה אחרונה', customTemplate: this.lastNtqTmpl },
        { prop: 'total_reports', name: 'דוחות', customTemplate: this.totalReportsTmpl, filterType: 'options', options: this.totalReportsOptions },
        { prop: 'last_rpt_date', name: 'דו״ח אחרון', customTemplate: this.dateTimezoneTmpl },
        { prop: 'usr_last_login', name: 'התחבר לאחרונה', customTemplate: this.dateTimezoneTmpl },
      ];
    }

    return columns;
  }

  onRowDblClick(row: any) {
    if (row.usr_type === 'player') {
      // this.router.navigate([`Nutritionistform/${row._id}`]);
      window.open(`Nutritionistform/${row._id}`);
    } else {
      if (this.appState.user.usr_type === 'superadmin') {
        // this.router.navigate([`Profile/${row._id}`]);
        window.open(`Profile/${row._id}`);
      }
    }
  }

  removeUser(row) {
    let userId = row._id;
    let userName = row.usr_fname + ' ' + row.usr_lname;

    const options = {
      title: 'מחיקת משתמש',
      message: 'האם למחוק את המשתמש - ' + userName +' ?',
      cancelText: 'ביטול',
      confirmText: 'אישור'
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
        if (confirmed) {
          this.usersRestService.patch(userId, { usr_is_deleted: true }).then(res => {
            this._snackBar.open(`משתמש נמחק ( ${res['usr_fname']} ${res['usr_lname']} )`, null, {
              duration: 5000,
            });
          this.tableEl.setTableData();

          }).catch(res => {
            if (res.message) {
              this._snackBar.open(res.message, null, {
                duration: 3000,
              });
            }
          });
        }
    });
  }

  newNtq(e, row) {
    if (row.usr_type !== 'player') {
      return;
    }
    e.stopPropagation();
    
    const options = {
      title: 'מדידה חדשה',
      message: 'להוסיף מדידה חדשה למשתמש?',
      cancelText: 'לא',
      confirmText: 'כן'
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(async confirmed => {
        if (confirmed) {
          const res = await this.restApiService.service('nuttestquiz').create({
            ntq_usr_id: row._id,
            ntq_form_is_real: true
          });
          if (res && res._id) {
            window.open(`Nuttestquiz/${res._id}`);
          }
        }
    });
  }

  selectRow(e, row, bulk?: boolean) {
    const index = this.chosenIds.findIndex(_id => _id === row._id);
    // const index = this.chosenUsers.findIndex(usr => usr && usr._id === row._id);
    if (e.checked && index === -1) {
      const {_id, createdAt, usr_fname, usr_lname, usr_type, usr_type_name, usr_notification_token, usr_phone, usr_email, usr_club_id, usr_club_ids, usr_parent_1_phone, usr_parent_2_phone, usr_subscribe, usr_club_name, usr_gender, usr_child, usr_agreement, usr_birth_date} = row;
      const stripped = {_id, createdAt, usr_fname, usr_lname, usr_type, usr_type_name, usr_notification_token, usr_phone, usr_email, usr_club_id, usr_club_ids, usr_parent_1_phone, usr_parent_2_phone, usr_subscribe, usr_club_name, usr_gender, usr_child, usr_agreement, usr_birth_date};
      this.chosenIds.push(_id);
      this.chosenUsers.push(stripped);
    } else {
      if (!e.checked && index !== -1) {
        this.chosenIds.splice(index, 1);
        this.chosenUsers.splice(index, 1);
      }
      // if (!this.chosenIds || !this.chosenIds.length) {
      //   this.allChosen = false;
      // }
    }
    if (!bulk) {
      this.updateAllChosen();
    }
  }

  unselectRow(user) {
    const msg = `(${this.userTypes[user.usr_type]}) - ${user.usr_fname} ${user.usr_lname} - ${user.usr_phone}`;
    const options = {
      title: 'ביטול בחירה',
      message: 'האם לבטל בחירה של ' + msg + ' ?',
      cancelText: 'לא',
      confirmText: 'כן'
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
        if (confirmed) {
          this.selectRow({checked: false}, {_id: user._id});
        }
    });
  }

  tableLoading(e) {
    // this.busy = e;
    setTimeout(() => {this.busy = e;});
  }

  async editPlanPopup(e, row: any) {
    if (row.usr_type !== 'player') {
      return;
    }
    e.stopPropagation();
    
    const totalReports = row['total_reports'] || 0;
    const reportCount = row['usr_report_count'] || 0;
    const currentPlan = `${totalReports} / ${reportCount}`;
    const userName = (row['usr_fname'] || '') + ' ' + (row['usr_lname'] || '');
    // const now = moment().milliseconds(0).second(0).minute(0).hour(0);
    const newFormGroup = this.formBuilder.group({
      _id: [row._id, Validators.required],
      currentPlan: [currentPlan],
      userName: [userName],
      usr_manual_plan: [true],
      usr_report_count: ['', Validators.required]
      // usr_purchase_date: [now, Validators.required],
      // usr_payment_plan: ['', Validators.required],
    });
    const title = `עריכת מספר דוחות ידנית`;
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // minWidth: '35%',
      data: { 
        title: title, 
        ok: this.translate.instant('general.ok'),
        cancel: this.translate.instant('general.cancel'),
        form: newFormGroup, 
        template: this.manualTemplate
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }
      const formValue = newFormGroup.value;
      delete formValue.currentPlan;
      delete formValue.userName;
      try {
        const res = await this.usersRestService.patch(formValue._id, formValue);
        if (res && res['usr_report_count'] == formValue.usr_report_count) {
          this._snackBar.open('מספר דוחות הוגדר בהצלחה', null, {
            duration: 3000,
          });
          row.usr_report_count = res['usr_report_count'];
          row.total_reports = res['total_reports'];
          row.usr_manual_plan = res['usr_manual_plan'];
        }
      } catch (e) {
        console.error(e);
      }
    });
  }

  async userStatusPopup(e, row: any) {
    if (row.usr_type !== 'player') {
      return;
    }
    e.stopPropagation();
    this.tableLoading(true);

    // const userState = await this.restApiService.service('user-state').get(row._id).catch(e => { console.error(e) });
    // const userPlayer = await this.restApiService.service('users').get(row._id).catch(e => { console.error(e) });
    
    const [userPlayer] = await this.restApiService.service('users').find({
      query: {
        $paginate: false,
        $limit: 1,
        _id: row._id,
        $extradetails: true
      }
    });
    // if (!userPlayer) {
    //   throw 'no user';
    // }
    // const isQa = !environment.production;

    // const state = isQa ? (userPlayer?.stateUserQa || userPlayer?.usr_state?.stateUserQa) : (userPlayer?.stateUser || userPlayer?.usr_state?.stateUser);
    const state = userPlayer?.stateUser || userPlayer?.usr_state?.stateUser;
    if (state) {
      const totalReports = row['total_reports'] || 0;
      const reportCount = row['usr_report_count'] || 0;
      const currentPlan = `${totalReports} / ${reportCount}`;
      const userName = (row['usr_fname'] || '') + ' ' + (row['usr_lname'] || '');
      const newFormGroup = this.formBuilder.group({
        _id: [row._id, Validators.required],
        currentPlan: [currentPlan],
        userName: [userName],
        state: [state]
      });

      const title = row.usr_gender === 'נקבה' ? 'סטטוס ספורטאית' : 'סטטוס ספורטאי';
      const dialogRef = this.dialog.open(CustomDialogComponent, {
        minWidth: '35%',
        data: { 
          title: title, 
          // ok: this.translate.instant('general.ok'),
          // cancel: this.translate.instant('general.cancel'),
          ok: 'מעבר לטופס תזונאי',
          cancel: 'חזרה',
          form: newFormGroup, 
          template: this.stateTemplate
        }
      });
      
      dialogRef.afterClosed().subscribe(async result => {
        if (!result) {
          return;
        }
        window.open(`Nutritionistform/${row._id}`);
      });
    }

    this.tableLoading(false);

  }

  async isFollowPopup(e, row: any) {
    if (row.usr_type !== 'player') {
      return;
    }
    if (!this.allNotesUnfiltered) {
      await this.fetchNotes();
    }
    e.stopPropagation();
    this.tableLoading(true);

    const userPlayer = await this.restApiService.service('users').get(row._id).catch(e => { console.error(e) });
    if (userPlayer && userPlayer['usr_notes'] && userPlayer['usr_notes']['A']) {
      const totalReports = row['total_reports'] || 0;
      const reportCount = row['usr_report_count'] || 0;
      const currentPlan = `${totalReports} / ${reportCount}`;
      const userName = (row['usr_fname'] || '') + ' ' + (row['usr_lname'] || '');
      const isFemale = userPlayer.usr_gender === 'נקבה';
      const newFormGroup = this.formBuilder.group({
        _id: [row._id, Validators.required],
        currentPlan: [currentPlan],
        userName: [userName],
        isFemale: [isFemale],
        notes: [userPlayer['usr_notes']]
      });

      const title = row.usr_gender === 'נקבה' ? 'מעקב ספורטאית' : 'מעקב ספורטאי';
      const dialogRef = this.dialog.open(CustomDialogComponent, {
        minWidth: '35%',
        data: { 
          title: title, 
          // ok: this.translate.instant('general.ok'),
          // cancel: this.translate.instant('general.cancel'),
          ok: 'מעבר לטופס תזונאי',
          cancel: 'חזרה',
          form: newFormGroup, 
          template: this.followTemplate
        }
      });
      
      dialogRef.afterClosed().subscribe(async result => {
        if (!result) {
          return;
        }
        window.open(`Nutritionistform/${row._id}`);
      });
    } else {
      this._snackBar.open('לא קיימת היסטוריית מעקב', null, {
        duration: 3000,
      });
    }

    this.tableLoading(false);

  }

  async fetchNotes() {
    const res = await this.restApiService.service('note').find({
      query: {
        $paginate: false,
        $sort: {
          title: 1,
          content: 1
        },
        $limit: 9999,
        is_deleted: {
          $ne: true
        },
        $or:[{is_visible: true}, {is_visible_all: true}, {is_scenario: true}]
      }
    });
    
    this.allNotesUnfiltered = res;
  }

  async openPushDialog() {
    if (!this.chosenIds || !this.chosenIds.length) {
      return;
    }

    const pushCustomValidator = (formGroup: FormGroup) => {
      if (!formGroup.value.push_method_push && !formGroup.value.push_method_wap) {
        return {
          msg: 'דרוש לבחור אופן שליחה'
        };
      }
      return null;
    };

    let newPush = this.formBuilder.group({
      push_to_type: ['user', Validators.required],
      push_title: [''],
      push_content: ['', Validators.required],
      push_method_push: [false],
      push_method_wap: [false],
    },
    {
      validators: [pushCustomValidator]
    });

    let title = 'שליחת התראה ידנית';
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      minWidth: '35%',
      data: { 
        title: title,
        ok: this.translate.instant('general.ok'),
        cancel: this.translate.instant('general.cancel'), 
        form: newPush, 
        template: this.pushDialogTemplate 
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed');
      if (!result) {
        console.log("user canceled");
        return;
      }
      await this.onSubmitPushArray(newPush);
      setTimeout(() => {
        this.onSubmitWapArray(newPush);
      }, 1500);
    });
  }

  async onSubmitPushArray(pushForm: FormGroup) {
    try {
      const usersList = this.chosenUsers.map((usr: any) => {
        const {_id, usr_fname, usr_lname, usr_type, usr_type_name, usr_notification_token, usr_phone, usr_parent_1_phone, usr_parent_2_phone, usr_parent_1_fname, usr_parent_2_fname, usr_parent_1_lname, usr_parent_2_lname, usr_subscribe, usr_club_name, usr_gender, usr_agreement} = usr;
        return {_id, usr_fname, usr_lname, usr_type, usr_type_name, usr_notification_token, usr_phone, usr_parent_1_phone, usr_parent_2_phone, usr_parent_1_fname, usr_parent_2_fname, usr_parent_1_lname, usr_parent_2_lname, usr_subscribe, usr_club_name, usr_gender, usr_agreement};
      });
      const pushToType = pushForm.value.push_to_type;
      const pushTitle = pushForm.value.push_title;
      const pushBody = pushForm.value.push_content;

      if (pushForm.value.push_method_push) {
        this._snackBar.open('שולח התראות...', 'סגור', {
          duration: 0,
        });
  
        const resCount = await this.restApiService.service('notifications').create({
          usersList, pushToType, pushTitle, pushBody
        });
        
        this._snackBar.open(`נשלחו ${resCount} התראות`, 'סגור', {
          duration: 0,
        });

        // const res = await this.restApiService.service('notifications').create({
        //   deviceToken: usersList.map((user: any) => user.usr_notification_token).filter(el => el),
        //   title: pushTitle,
        //   body: pushBody,
        //   payload: {
        //       msg_type: 'custom',
        //   }
        // });

        // console.log(res);
      }
      
    } catch (e) {
      console.error(e);
      this._snackBar.open('שגיאה בשליחת ההתראה', null, {
        duration: 3000,
      });
    }
  }

  async onSubmitWapArray(pushForm: FormGroup) {
    try {
      const usersList = this.chosenUsers.map((usr: any) => {
        const {_id, usr_fname, usr_lname, usr_type, usr_type_name, usr_phone, usr_parent_1_phone, usr_parent_2_phone, usr_parent_1_fname, usr_parent_2_fname, usr_parent_1_lname, usr_parent_2_lname, usr_subscribe, usr_club_name, usr_gender, usr_agreement} = usr;
        return {_id, usr_fname, usr_lname, usr_type, usr_type_name, usr_phone, usr_parent_1_phone, usr_parent_2_phone, usr_parent_1_fname, usr_parent_2_fname, usr_parent_1_lname, usr_parent_2_lname, usr_subscribe, usr_club_name, usr_gender, usr_agreement};
      });
      const pushToType = pushForm.value.push_to_type;
      const pushTitle = pushForm.value.push_title;
      const pushBody = pushForm.value.push_content;

      if (pushForm.value.push_method_wap) {
        this._snackBar.open('שולח Whatsapp...', 'סגור', {
          duration: 0,
        });
  
        const resCount = await this.restApiService.service('wap').create({
          usersList, pushToType, pushTitle, pushBody
        });
          
        this._snackBar.open(`נשלחו ${resCount} הודעות Whatsapp (נבחרו ${usersList.length})`, 'סגור', {
          duration: 0,
        });
      }
      
    } catch (e) {
      console.error(e);
      this._snackBar.open('שגיאה בשליחת Whatsapp', null, {
        duration: 3000,
      });
    }
  }

  updateSelected(e) {
    console.log(e)
  }

  checkboxHdrChange(checked: boolean) {
    if (checked) {
      this.selectAll();
    } else {
      this.unselectAll(true);
      // this.chosenIds = [];
      // this.chosenUsers = [];
    }
    // this.allChosen = e;
  }

  selectAll() {
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // minWidth: '35%',
      data: { 
        title: 'בחירת הכל',
        subtitle: 'האם לבחור את כל המשתמשים או רק עמוד נוכחי?',
        cancel: 'ביטול',
        ok: 'עמוד נוכחי',
        extraButton: { value: 'all', text: 'כולם' },
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        this.updateAllChosen();
        return;
      }
      if (result === 'all') {
        // this.chosenIds = [];
        // this.chosenUsers = [];
        this.busy = true;
        const chosenUsers = await this.getAllUsers(this.tableEl._query.query);
        const chosenIds: string[] = chosenUsers.map((user: any) => user._id);
        this.chosenIds = chosenIds;
        this.chosenUsers = chosenUsers;
        this.busy = false;
      } else {
        for (const row of this.tableEl.rows || []) {
          this.selectRow({checked: true}, row, true);
        }
      }
      this.updateAllChosen();
    });
  }

  updateAllChosen() {
    console.log('updateAllChosen')
    if (this.chosenIds.length > 0) {
      if (this.tableEl?.tablePage.totalElements === this.chosenIds.length) { // || this.tableEl?.rows?.length === this.chosenIds.length
        this.allChosen = true;
        this.someChosen = false;
      } else {
        this.allChosen = false;
        this.someChosen = true;
      }
    } else {
      this.allChosen = false;
      this.someChosen = false;
    }
  }

  unselectAll(isCheckbox?: boolean) {
    if (!this.chosenIds || !this.chosenIds.length) {
      return;
    }

    const options = {
      title: 'ביטול בחירה',
      message: 'האם לבטל בחירה של כולם (' + this.chosenIds.length + ' משתמשים)?',
      cancelText: 'לא',
      confirmText: 'כן'
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
        if (confirmed) {
          this.chosenIds = [];
          this.chosenUsers = [];
          this.allChosen = false;
          this.someChosen = false;
          return;
        }
        if (isCheckbox) {
          // this.allChosen = true;
          this.updateAllChosen();
        }
    });
  }

  async getAllUsers(currentQuery: any) {
    let query: any;
    if (!currentQuery) {
      query = this.query;
    } else {
      query = {...currentQuery }; // , $paginate: false
      // delete query.$userstable;
      delete query.$skip;
      delete query.$limit;
      query.$skip = 0;
      query.$limit = 9999;
    }

    const users = await this.restApiService.service('users').find({ 
      query
    });

    return users.data;
  }

  addText(nativeEl: any, formControl: FormControl, textToAdd: string) {
    const val = formControl.value;
    const selStart = nativeEl?.selectionStart || val.length;
    const brackets = `{{${textToAdd}}}`;
    formControl.setValue(`${val.substring(0, selStart)}${brackets}${val.substring(selStart)}`);
    setTimeout(() => {
      if (nativeEl) {
        nativeEl.selectionStart = selStart + brackets.length;
        nativeEl.focus();
      }
    });
  }

  newQuizzesPopup() {
    let newFormGroup: FormGroup = this.formBuilder.group({
      // due_date: [''],
      all: [true],
      ntq: [true],
      // prq: [true],
      // slq: [true],
      // heq: [true],
      // nuq: [true],
      // fdq: [true],

      btq: [false],
      recommend: [false],
    });

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // minWidth: '35%',
      data: { 
        title: 'יצירת שאלונים', // 'יצירת שאלונים עתידית',
        // subtitle: 'אנא בחר שאלונים',
        ok: this.translate.instant('general.ok'),
        cancel: this.translate.instant('general.cancel'),
        form: newFormGroup, 
        template: this.newQuizzesTemplate
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }
      // const usersList = this.chosenUsers;
      // const chosenQuizzes = newFormGroup.value;
      // const res = await this.restApiService.service('new-quizzes').create({ usersList, chosenQuizzes });

      // const chosenIds = this.chosenIds;
      const chosenIds = this.chosenUsers.filter((user: any) => user.usr_type === 'player').map((user: any) => user._id);
      let chosenQuizzes = { ...newFormGroup.value };
      if (!chosenQuizzes.all && !chosenQuizzes.ntq && !chosenQuizzes.btq) {
        return;
      }
      this.busy = true;
      if (chosenQuizzes.all) {
        // chosenQuizzes.ntq = true;
        chosenQuizzes.prq = true;
        chosenQuizzes.slq = true;
        chosenQuizzes.heq = true;
        chosenQuizzes.nuq = true;

        chosenQuizzes.fdq = true;
      }
      delete chosenQuizzes.all;
      const res = await this.restApiService.service('new-quizzes').create({ chosenIds, chosenQuizzes });
      console.log('new-quizzes: ', res);
      if (res) {
        this._snackBar.open(`נוצרו שאלונים ${res} ספורטאים`, 'סגור', {
          duration: 0,
        });
        await this.tableEl.setTableData();
      }
      this.busy = false;
    });
  }

  exportCsvPopup() {
    let newForm = this.formBuilder.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      // nameField: [true],
      // phoneField: [true],
      birthdayField: [true],
      emailField: [true],
    });

    let title = 'הפקת CSV';
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      minWidth: '35%',
      data: { 
        title: title,
        ok: this.translate.instant('general.ok'),
        cancel: this.translate.instant('general.cancel'), 
        form: newForm, 
        template: this.exportCsvTemplate 
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }
      const fromDate = moment(result.form.value.fromDate);
      const toDate = moment(result.form.value.toDate);
      if (fromDate.isValid() && toDate.isValid()) {
        this.exportCsv(fromDate, toDate, result.form.value.birthdayField, result.form.value.emailField);
      }
    });
  }

  exportCsv(fromDate, toDate, birthdayField?, emailField?) {
    this.busy = true;
    const chosenUsers = this.chosenUsers.filter((user: any) => (user.usr_fname || user.usr_lname) && moment(user.createdAt).isBetween(fromDate, toDate));
    if (chosenUsers.length === 0) {
      this._snackBar.open('אין משתמשים להפקה', null, {
        duration: 4000,
      });
      this.busy = false;
      return;
    }
    const chosen = chosenUsers.map((user: any) => {
      let obj = {
      'Name': this.nameFormat(user),
      'Given Name': '',
      'Additional Name': '',
      'Family Name': '',
      // 'Birthday': this.birthdayFormat(user),
      // 'E-mail 1 - Type': '*',
      // 'E-mail 1 - Value': user.usr_email || '',
      'Phone 1 - Type': '',
      // 'Phone 1 - Value': user.usr_phone || '',
      'Phone 1 - Value': this.phoneFormat(user),
      }
      if (birthdayField) {
        obj['Birthday'] = this.birthdayFormat(user);
      }
      if (emailField) {
        obj['E-mail 1 - Type'] = '*';
        obj['E-mail 1 - Value'] = user.usr_email || '';
      }
      return obj;
    });
    
    const parser = new Parser();
    const nowFormat = moment().format('YYYY-MM-DD');
    const exportFileName = `aap_export_${nowFormat}.csv`;
    // const output: Buffer = encoding.convert(parser.parse(chosen), 'utf-8', 'utf-8');
    const output: Buffer = parser.parse(chosen);
    fileDownload(output, exportFileName);
    this.busy = false;
  }

  birthdayFormat(user: any) {
    return user.usr_birth_date ? moment(user.usr_birth_date).format('YYYY-MM-DD') : '';
  }

  nameFormat(user: any) {
    const {_id, usr_fname, usr_lname, usr_type, usr_type_name, usr_phone, usr_email, usr_club_name, usr_gender, usr_child, usr_birth_date} = user;
    let res = '';
    switch (usr_type) {
      case 'player':
        let age_player = '';
        if (usr_birth_date) {
            // const birthDate: moment.Moment = moment(usr_birth_date);
            // const nowDate: moment.Moment = moment();
            // const years = nowDate.diff(birthDate, "years");
            // const ageInMonths = nowDate.diff(birthDate, "months");
            // const months = ageInMonths - years * 12;
            // age_player = String(years) + '.' + String(months);
            
            age_player = moment(usr_birth_date).format('MM-YYYY');
        }
        res = `${(usr_fname || '')} ${(usr_lname || '')} ${(usr_club_name || '')} ${(usr_gender || '')} ${age_player}`;
        break;
    
      case 'parent':
        // let gender = usr_type_name;
        let gender = 'הורה של';
        let usr_desc_arr: string[] = [];
        if (usr_child && usr_child.length) {
          for (const child of usr_child) {
            const {_id, usr_fname, usr_lname, usr_club_name, usr_gender, usr_birth_date, usr_parent_1_phone, usr_parent_2_phone, usr_parent_1_type, usr_parent_2_type} = child;
            let age_player = '';
            if (usr_birth_date) {
                // const birthDate: moment.Moment = moment(usr_birth_date);
                // const nowDate: moment.Moment = moment();
                // const years = nowDate.diff(birthDate, "years");
                // const ageInMonths = nowDate.diff(birthDate, "months");
                // const months = ageInMonths - years * 12;
                // age_player = String(years) + '.' + String(months);
            
                age_player = moment(usr_birth_date).format('MM-YYYY');
            }
            const descStr = `${(usr_fname || '')} ${(usr_lname || '')} ${(usr_club_name || '')} ${(usr_gender || '')} ${age_player}`;
            usr_desc_arr.push(descStr);

            if (usr_parent_1_phone === usr_phone) {
              gender = usr_parent_1_type + ' של';
            }
            if (usr_parent_2_phone === usr_phone) {
              gender = usr_parent_2_type + ' של';
            }
          }
        }
        if (usr_gender === 'נקבה') {
          gender = 'אמא של';
        }
        if (usr_gender === 'זכר') {
          gender = 'אבא של';
        }
        const usr_desc = usr_desc_arr.join(', ');
        res = `${(usr_fname || '')} ${gender} ${usr_desc}`;
        break;
    
      case 'admin':
      case 'coach':
        res = `${(usr_fname || '')} ${(usr_lname || '')} ${(usr_type_name || '')} ${(usr_club_name || '')}`;
        break;
    
      case 'nutri':
        res = `${(usr_fname || '')} ${(usr_lname || '')} ${(usr_type_name || '')}`;
        break;
    
      default:
        res = `ללא סוג - ${(usr_fname || '')} ${(usr_lname || '')} ${(usr_club_name || '')}`;
        break;
    }
    return res;
  }

  phoneFormat(user: any) {
    const prefix = '+972';
    let { usr_phone = '' } = user;
    const substr = usr_phone.substring(0, 1);
    if (substr === '0') {
      usr_phone = prefix + usr_phone.substring(1);
    }
    if (substr === '1') {
      usr_phone = '+' + usr_phone;
    }
    return usr_phone;
  }

  async onChangeFileUpload(e: any) {
    if (!e || !e.target) {
      return;
    }
    const file = e.target.files[0];
    let formData = new FormData(); 
    formData.append("file", file);
    // const res = await this.restApiService.service('import-users').create(formData);
    // this._snackBar.open('מעבד קובץ...', 'סגור', {
    //   duration: 0,
    // });
    this._snackBar.open('מעבד קובץ...', null, {
      duration: 0,
    });

    const token = window.localStorage.getItem('feathers-jwt');
    
    const res = await this.http.post(environment.apiUrl + '/import-users', formData, {
      headers: new HttpHeaders({
          'Authorization': `Bearer ${token}`
      })
    }).toPromise<any>();
    console.log(res);

    let msg = '';
    if (res?.createdTeams) {
      msg += `${res.createdTeams} קבוצות חדשות \n`
    }
    if (res?.createdRecords) {
      msg += `${res.createdRecords} משתמשים חדשים \n`
    }
    if (res?.patchedRecords) {
      msg += `${res.patchedRecords} משתמשים קיימים שנערכו \n`
    }
    if (!msg) {
      msg = 'אין תוצאות'
    } else {
      await this.tableEl.setTableData();
    }
    this._snackBar.open(msg, 'סגור', {
      duration: 0,
      panelClass: 'pre-wrap'
    });

    setTimeout(() => {
      e.target.value = null;
      e.target.files = [];
    });
  }

  async newMeasureReport() {
    const chosenPlayerIds: string[] = this.chosenUsers.filter((user: any) => user.usr_type === 'player').map((user: any) => user._id);
    if (chosenPlayerIds.length === 0) {
      this._snackBar.open('יש לבחור לפחות ספורטאי אחד', 'סגור', {
        duration: 5000,
      });
      return;
    }
    try {
      const res = await this.restApiService.service('measurereport').create({
        chosen_users: chosenPlayerIds
      });
      window.open(`Measurereport/${res._id}`);
    } catch (err) {
      console.error(err);
      this._snackBar.open('שגיאה בהפקת דוח מדידות', null, {
        duration: 2000,
      });
    }
  }

  // async compare() {
  //   const query = {
  //     $limit: 9999,
  //     $skip: 0,
  //     $userstable: true,
  //     $select: ['_id', 'createdAt', 'usr_notification_token', 'usr_fname', 'usr_lname', 'usr_phone', 'usr_gender', 'usr_birth_date', 'usr_club_id', 'usr_type', 'usr_type_name', 'usr_manual_plan', 'usr_is_follow', 'usr_report_count', 'usr_last_login', 'usr_payment_plan', 'usr_purchase_date'],
  //     usr_type: {
  //       $nin: ['superadmin']
  //     },
  //     usr_is_deleted: {
  //       $ne: true
  //     },
  //     $sort: {
  //       quiz_days: -1
  //     }
  //   };
  //   const res = await this.restApiService.service('users').find({ 
  //     query
  //   });

  //   const filtered = res.data.filter(usr => usr.has_plan_old && !usr.has_plan);
  //   let count = 0;
  //   for (const usr of filtered) {
  //     count++;
  //     const calc = (usr.total_reports || 0) + (usr.reports_left_old || 0);
  //     console.log(count, ' - ', usr.usr_phone, usr.usr_fname, usr.usr_lname, usr.usr_payment_plan, 'total-past: ', usr.total_reports, 'remaining: ', usr.reports_left_old, 'new-total: ', calc);
  //     // const res = await this.restApiService.service('users').patch(usr._id, {usr_report_count: calc});
  //     // console.log(Boolean(res && res.usr_report_count == calc));
  //   }
  //   console.log('count: ', count);
  //   console.log(filtered);
  //   console.log(JSON.stringify(filtered));
  // }

  measurementPopup() {
    let newFormGroup: FormGroup = this.formBuilder.group({
      usr_measurement_date: [''],
    });

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      data: { 
        title: 'הוספת תאריך מדידה',
        ok: this.translate.instant('general.ok'),
        cancel: this.translate.instant('general.cancel'),
        form: newFormGroup, 
        template: this.measurementTemplate
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }
      const chosenIds = this.chosenUsers.filter((user: any) => user.usr_type === 'player').map((user: any) => user._id);

      if (!newFormGroup.value.usr_measurement_date) {
        return;
      }

      this.busy = true;
      // const res = await this.usersRestService.patch(null, { usr_measurement_date: newFormGroup.value.usr_measurement_date }, {
      //   query: {
      //     _id: {
      //       $in: chosenIds,
      //     }
      //   }
      // });
      const res = await this.restApiService.service('actions').create({cmd: 'measurement-date-p', cmdData: {
        chosenIds,
        usr_measurement_date: newFormGroup.value.usr_measurement_date
      }});
      if (res) {
        this._snackBar.open(`מדידה עודכנה בהצלחה ל-${res} ספורטאים`, 'סגור', {
          duration: 0,
        });
        await this.tableEl.setTableData();
      }
      this.busy = false;
    });
  }
}
