import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, ValidatorFn } from '@angular/forms';
import { appState } from '@services/app-state';

import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from 'src/shared/image-dialog.component';

import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './bloodtestquiz.component.html',
})
export class BloodtestquizComponent {

    profileForm: FormGroup;
    bloodtestquizForm: FormGroup;

    quizPrefix = 'btq';

    restService: any;
    uploadUrlService: any;
    
    base_image_upload_folder_path = '';

    appState = appState;
    showErrors = false;

    oldBlood: any;

    // formOwnerUsr: any;

    isLocked = false;
    isBusy = false;

    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        public router: Router,
        private _snackBar: MatSnackBar,
        public sanitizer: DomSanitizer,
        public restApiService: RestApiService<any>,
        public dialog: MatDialog,
        private translate: TranslateService,
    ) {
        this.restService = this.restApiService.service('bloodtestquiz');
        this.uploadUrlService = this.restApiService.service('upload-url');
        this.profileForm = this.formBuilder.group({
            usr_blood_known_situation: [
                '',
                // [
                //     this.conditionalValidator(
                //         () => this.bloodtestquizForm.value.btq_past_tests === 'כן',
                //         Validators.required,
                //         null,
                //     ),
                // ],
            ],
            usr_blood_known_situation_text: [
                '',
                // [
                //     this.conditionalValidator(
                //         () => this.profileForm.value.usr_blood_known_situation === 'כן',
                //         Validators.required,
                //         null,
                //     ),
                // ],
            ],
            usr_blood_chronic_value: [
                '',
                // [
                //     this.conditionalValidator(
                //         () => this.bloodtestquizForm.value.btq_past_tests === 'כן',
                //         Validators.required,
                //         null,
                //     ),
                // ],
            ],
            usr_blood_chronic_value_text: [
                '',
                // [
                //     this.conditionalValidator(
                //         () => this.profileForm.value.usr_blood_chronic_value === 'כן',
                //         Validators.required,
                //         null,
                //     ),
                // ],
            ],
        });

        this.profileForm.get('usr_blood_known_situation').valueChanges.subscribe(value => {
            if (value === 'כן') {
                this.profileForm.get('usr_blood_known_situation_text')?.setValidators(Validators.required);
            } else {
                this.profileForm.get('usr_blood_known_situation_text')?.clearValidators();
            }
            this.profileForm.get('usr_blood_known_situation_text')?.updateValueAndValidity();
        });
        this.profileForm.get('usr_blood_chronic_value').valueChanges.subscribe(value => {
            if (value === 'כן') {
                this.profileForm.get('usr_blood_chronic_value_text')?.setValidators(Validators.required);
            } else {
                this.profileForm.get('usr_blood_chronic_value_text')?.clearValidators();
            }
            this.profileForm.get('usr_blood_chronic_value_text')?.updateValueAndValidity();
        });

        this.bloodtestquizForm = this.formBuilder.group({
            _id: [''],
            btq_form_is_real: [false],
            btq_usr_id: [appState.user._id],
            btq_past_tests: [
                '',
                [this.conditionalValidator(() => (this.oldBlood && !this.oldBlood.length), Validators.required, null)],
            ],
            btq_has_tests: [
                '',
                // [
                //     this.conditionalValidator(
                //         () => this.bloodtestquizForm.value.btq_past_tests == 'כן',
                //         Validators.required,
                //         null,
                //     ),
                // ],
            ],
            btq_test_reason: [
                '',
                [
                    this.conditionalValidator(
                        () => this.bloodtestquizForm.get('btq_has_tests')?.value == 'כן',
                        Validators.required,
                        null,
                    ),
                ],
            ],
            btq_test_reasons_text: [
                '',
                [
                    this.conditionalValidator(
                        () => {
                            return (
                                this.bloodtestquizForm.get('btq_has_tests')?.value == 'כן' &&
                                (this.bloodtestquizForm.get('btq_test_reason')?.value == 'אחר' ||
                                    this.bloodtestquizForm.get('btq_test_reason')?.value == 'סביב בירור רפואי')
                            );
                        },
                        Validators.required,
                        null,
                    ),
                ],
            ],

            btq_test_date: [
                '',
                [
                    this.conditionalValidator(
                        () => {
                            return (
                                this.bloodtestquizForm.get('btq_has_tests')?.value == 'כן' ||
                                (!this.bloodtestquizForm.get('btq_has_tests')?.value && this.oldBlood && this.oldBlood.length)
                            );
                        },
                        Validators.required,
                        null,
                    ),
                ],
            ],
            btq_general_feeling: [
                '',
                [
                    this.conditionalValidator(
                        () => {
                            return (
                                // this.bloodtestquizForm.value.btq_has_tests == 'כן' ||
                                (!this.bloodtestquizForm.get('btq_has_tests')?.value && this.oldBlood && this.oldBlood.length)
                            );
                        },
                        Validators.required,
                        null,
                    ),
                ],
            ],
            btq_general_feeling_text: [
                '',
                [
                    this.conditionalValidator(
                        () => this.bloodtestquizForm.get('btq_general_feeling')?.value == 'כן',
                        Validators.required,
                        null,
                    ),
                ],
            ],
            btq_assets_arr: this.formBuilder.array([], 
                [
                    this.requiredIfValidator(
                        ()=> {
                            return (
                                this.bloodtestquizForm.get('btq_has_tests')?.value == 'כן' ||
                                (!this.bloodtestquizForm.get('btq_has_tests')?.value && this.oldBlood && this.oldBlood.length)
                            );
                        }
                    )
                ]
            ),
            btq_more_comments: [
                '',
                [
                    this.conditionalValidator(
                        () => {
                            return (
                                this.bloodtestquizForm.get('btq_has_tests')?.value == 'כן' ||
                                (!this.bloodtestquizForm.get('btq_has_tests')?.value && this.oldBlood && this.oldBlood.length)
                            );
                        },
                        Validators.required,
                        null,
                    ),
                ],
            ],
            btq_more_comments_text: [
                '',
                [
                    this.conditionalValidator(
                        () => this.bloodtestquizForm.get('btq_more_comments')?.value == 'כן',
                        Validators.required,
                        null,
                    ),
                ],
            ],
            btq_blood_values: this.formBuilder.group({
                hem: [{ value: '', disabled: true }],
                prt: [{ value: '', disabled: true }],
                trn: [{ value: '', disabled: true }],
                irn: [{ value: '', disabled: true }],
                b12: [{ value: '', disabled: true }],
                mag: [{ value: '', disabled: true }],
                vid: this.formBuilder.group({
                    unit_type: [{ value: 'ng/ml', disabled: true }],
                    unit_value: [{ value: '', disabled: true }],
                }),
                pol: this.formBuilder.group({
                    unit_type: [{ value: 'ng/ml', disabled: true }],
                    unit_value: [{ value: '', disabled: true }],
                }),
                mcv: [{ value: '', disabled: true }],
                mch: [{ value: '', disabled: true }],
                glu: [{ value: '', disabled: true }],
                col: [{ value: '', disabled: true }],
                tri: [{ value: '', disabled: true }],
                hdl: [{ value: '', disabled: true }],
                ldl: [{ value: '', disabled: true }],
                alk: [{ value: '', disabled: true }],
                a1c: [{ value: '', disabled: true }],
                tsh: [{ value: '', disabled: true }],
                cpk: [{ value: '', disabled: true }],
            }),
        });

        this.bloodtestquizForm.get('btq_past_tests').valueChanges.subscribe(value => {
            if (value === 'כן') {
                this.profileForm?.get('usr_blood_known_situation')?.setValidators(Validators.required);
                this.profileForm?.get('usr_blood_chronic_value')?.setValidators(Validators.required);
                
                this.bloodtestquizForm?.get('btq_has_tests')?.setValidators(Validators.required);
            } else {
                this.profileForm?.get('usr_blood_known_situation')?.clearValidators();
                this.profileForm?.get('usr_blood_chronic_value')?.clearValidators();
                
                this.bloodtestquizForm?.get('btq_has_tests')?.clearValidators();
            }
            this.profileForm?.get('usr_blood_known_situation')?.updateValueAndValidity();
            this.profileForm?.get('usr_blood_chronic_value')?.updateValueAndValidity();
            
            this.bloodtestquizForm?.get('btq_has_tests')?.updateValueAndValidity();
        });

        // window['rf'] = this.bloodtestquizForm;

        this.route.params.subscribe(async (params) => {
            if (params.id) {
                this.base_image_upload_folder_path = `bloodtestquiz/btq_assets_${params.id}/`;
                const data = await this.restService.get(params.id);
                const quizUsrId = data.btq_usr_id;
                if (!quizUsrId) {
                    return;
                }

                this.isLocked = data && data.btq_locked;
                if (this.appState.user.usr_type === 'parent') {
                    // this.isLocked = true;
                }
                if (this.appState.user.usr_type === 'superadmin' || this.appState.user.usr_type === 'nutri') {
                    this.isLocked = false;
                    this.appState.quizUser = data.quizOwner;
                }

                if (this.translate.currentLang !== 'en') {
                    if (data.quizOwner?.usr_gender === 'נקבה') {
                        this.translate.use('he_F');
                    } else {
                        this.translate.use('he');
                    }
                }

                this.buildAssetsArray(this.bloodtestquizForm, 'btq_assets_arr', data);
                this.bloodtestquizForm.patchValue(data);
                this.enableBloodValues();

                if (this.isLocked) {
                    this.bloodtestquizForm.disable();
                }

                const oldBlood = await this.fetchOldBlood(quizUsrId, params.id);
                
                if (oldBlood && oldBlood.length === 0) {
                    // const usr = await this.restApiService.service('users').get(quizUsrId).catch(e => { console.error(e) });
                    const usr = data.quizOwner;
                    if (usr) {
                        // this.formOwnerUsr = usr;
                        this.profileForm.get('usr_blood_known_situation').setValue(usr.usr_blood_known_situation);
                        this.profileForm.get('usr_blood_known_situation_text').setValue(usr.usr_blood_known_situation_text);
                        this.profileForm.get('usr_blood_chronic_value').setValue(usr.usr_blood_chronic_value);
                        this.profileForm.get('usr_blood_chronic_value_text').setValue(usr.usr_blood_chronic_value_text);
                        
                        if (this.isLocked) {
                            this.profileForm.disable();
                        }
                    }
                }

                this.oldBlood = oldBlood;

                this.profileForm?.updateValueAndValidity();
                this.bloodtestquizForm?.updateValueAndValidity();

            } else {
                const res = await this.restService.getLast({
                    btq_usr_id: this.appState.user._id,
                    btq_is_clone:{
                        $ne: true
                    }
                });
                const navTo = res[0]?._id ? `Bloodtestquiz/${res[0]._id}` : '/';
                this.router.navigate([navTo], { replaceUrl: true });
                return;
            }
        });
    }

    ngOnDestroy() {
        if (this.translate.currentLang !== 'en') {
          if (this.appState.user.usr_gender === 'נקבה') {
              this.translate.use('he_F');
          } else {
              this.translate.use('he');
          }
        }
      }
    
    async fetchOldBlood(userId: string, quizId: string) {
        try {
            const res = await this.restService.find({
                query: {
                    $limit: 1,
                    $paginate: false,
                    $select: ['_id', 'btq_test_date', 'btq_past_tests', 'btq_usr_id'],
                    btq_usr_id: userId,
                    btq_form_is_real: true,
                    $or: [{ btq_past_tests: 'כן' }, { btq_past_tests: 'לא' }],
                    _id: {
                        $ne: quizId,
                    },
                    btq_is_clone:{
                        $ne: true
                    }
                },
            });

            return res;
        } catch (e) {
            console.error(e);
            return [];
        }
    }

    requiredIfValidator(predicate: Function): ValidatorFn | any {
        return ((formArray: FormArray) => {
          if (!formArray.parent) {
            return null;
          }
          if (predicate()) {
            return Validators.required(formArray); 
          }
          return null;
        })
      }

    conditionalValidator(
        predicate: Function,
        validator: Function,
        parentKeyAndValueEquals?: any,
        parentKeyAndValueNotEquals?: any,
    ): Function {
        return (formControl) => {
            if (!formControl.parent) {
                return null;
            }

            let error = null;

            if (parentKeyAndValueEquals) {
                if (formControl.parent.get(parentKeyAndValueEquals.key).value == parentKeyAndValueEquals.value) {
                    error = validator(formControl);
                }
            } else if (parentKeyAndValueNotEquals) {
                if (formControl.parent.get(parentKeyAndValueNotEquals.key).value != parentKeyAndValueNotEquals.value) {
                    error = validator(formControl);
                }
            } else {
                if (predicate()) {
                    error = validator(formControl);
                }
            }

            return error;
        };
    }

    resetField(fieldName, value) {
        this.bloodtestquizForm.controls[fieldName].reset(value);
    }

    async onSubmit(form: FormGroup, prefix: string) {
        this.isBusy = true;

        this.clearValuesFromUnusedFields();
        const validateRes = this.validateForm();

        if (!validateRes.valid) {
            // if (validateRes.scrollIntoNativeElement) {
            //   validateRes.scrollIntoNativeElement.nativeElement.scrollIntoView();
            // }
            this.showErrors = true;
            this.showStatus(validateRes.msg, null);
            
            this.isBusy = false;
            return;
        } else {
            this.showErrors = false;
        }

        this.resetField(`${prefix}_form_is_real`, true);

        this.clearAllIds();

        if (form.value._id) {
            const res = await this.restService.patch(form.value._id, form.value).catch(e => { console.error(e); });
            if (res && res._id && res[`${prefix}_usr_id`]) {
                if (res.btq_past_tests === 'כן' && res.btq_usr_id) {
                    const profileRes = await this.restApiService.service('users').patch(res.btq_usr_id, this.profileForm.value).catch(e => { console.error(e); });

                    console.log('profileRes', profileRes);
                }
                this.buildAssetsArray(form, `${prefix}_assets_arr`, res);
                form.patchValue(res);
                
                this.showStatus(this.translate.instant('general.savedSuccessfully'), null);
                if (this.appState.user.usr_type === 'player'){
                  setTimeout(()=>{
                    this.router.navigate([`Userprogress`]);
                    this.isBusy = false;
                  }, 2000);
                  return;
                }
            } else {
                this.showStatus('אירעה שגיאה', null);
            }
        }
        
        this.isBusy = false;
    }

    validateForm() {
        if (this.bloodtestquizForm.value?.btq_past_tests === 'לא' || (this.bloodtestquizForm.value?.btq_past_tests === 'כן' && this.bloodtestquizForm.value?.btq_has_tests === 'לא')) {
            this.bloodtestquizForm?.get('btq_test_reason')?.clearValidators();
            this.bloodtestquizForm?.get('btq_test_reasons_text')?.clearValidators();
            this.bloodtestquizForm?.get('btq_test_date')?.clearValidators();
            this.bloodtestquizForm?.get('btq_assets_arr')?.clearValidators();
            this.bloodtestquizForm?.get('btq_more_comments')?.clearValidators();
            this.bloodtestquizForm?.get('btq_more_comments_text')?.clearValidators();

            this.bloodtestquizForm?.get('btq_test_reason')?.updateValueAndValidity();
            this.bloodtestquizForm?.get('btq_test_reasons_text')?.updateValueAndValidity();
            this.bloodtestquizForm?.get('btq_test_date')?.updateValueAndValidity();
            this.bloodtestquizForm?.get('btq_assets_arr')?.updateValueAndValidity();
            this.bloodtestquizForm?.get('btq_more_comments')?.updateValueAndValidity();
            this.bloodtestquizForm?.get('btq_more_comments_text')?.updateValueAndValidity();
        }
        this.bloodtestquizForm.updateValueAndValidity();
        this.profileForm.updateValueAndValidity();
        // this.bloodtestquizForm.get('btq_assets_arr').updateValueAndValidity();
        if (!this.bloodtestquizForm.valid) {
            return {
                valid: false,
                msg: 'נא לתקן את השדות האדומים',
            };
        }

        if (
            this.bloodtestquizForm.value.btq_past_tests == 'כן' && this.oldBlood && this.oldBlood.length === 0 && !this.profileForm.valid
        ) {
            return {
                valid: false,
                msg: 'נא לתקן את השדות האדומים',
            };
        }

        return {
            valid: true,
            msg: 'ולידציה עברה בהצלחה',
        };
    }

    clearAllIds() {
        this.clearIdsIfNotExistFromArrayItems(this.bloodtestquizForm.value.btq_assets_arr);
    }

    clearValuesFromUnusedFields() {
        if (this.profileForm.value.usr_blood_known_situation == 'לא') {
            this.profileForm.controls['usr_blood_known_situation_text'].reset('');
        }
        if (this.profileForm.value.usr_blood_chronic_value == 'לא') {
            this.profileForm.controls['usr_blood_chronic_value_text'].reset('');
        }
        
        const btqValue = { ...this.bloodtestquizForm.value };

        if (btqValue.btq_past_tests == 'לא') {
            this.resetField('btq_has_tests', '');
            this.resetField('btq_test_date', '');
            this.resetField('btq_test_reason', '');
            this.resetField('btq_test_reasons_text', '');
        }
        if (btqValue.btq_has_tests == 'לא') {
            this.resetField('btq_test_date', '');
            this.resetField('btq_test_reason', '');
            this.resetField('btq_test_reasons_text', '');
        }
        if (btqValue.btq_test_reason == 'בדיקת מעקב שגרתית') {
            this.resetField('btq_test_reasons_text', '');
        }

        if (btqValue.btq_general_feeling == 'לא') {
            this.resetField('btq_general_feeling_text', '');
        }

        if (btqValue.btq_known_situation == 'לא') {
            this.resetField('btq_known_situation_text', '');
        }

        if (btqValue.btq_chronic_value == 'לא') {
            this.resetField('btq_chronic_value_text', '');
        }

        if (btqValue.btq_more_comments == 'לא') {
            this.resetField('btq_more_comments_text', '');
        }
    }

    clearIdsIfNotExistFromArrayItems(arr: any) {
        const arrLen = arr.length;
        for (let i = 0; i < arrLen; i++) {
            if (String(arr[i]._id).length != 0) {
                continue;
            }

            delete arr[i]._id;
        }
    }

    showStatus(message: string, action: string) {
        this._snackBar.open(message, action, {
            duration: 2000,
        });
    }

    getObjKeys(obj) {
        return Object.keys(obj) || [];
    }

    buildAssetsArray(form: FormGroup, key: string, data: any) {
        if (!form || !key || !data || !data[key]) {
          return;
        }
        const assetsArray: FormArray = form.get(key) as FormArray;
        assetsArray.clear();
        for (let i = 0; i < data[key].length; i++) { 
          const newFormElement = this.formBuilder.control({});
          assetsArray.push(newFormElement);
        }
        // assetsArray.patchValue(data[key]);
    }

    onUploadDoneAsset(event) {
        console.log('onUploadDoneAsset', event);
        // this.onSubmit();
    }

    onDeleteAsset(event) {
        console.log('onDeleteAsset', event);
        // this.onSubmit();
    }

    checkedFn(event, fieldName, isGroup?) {
        const formControl = this.bloodtestquizForm.get('btq_blood_values').get(fieldName);

        if (event.checked) {
            formControl.enable();
            return;
        }
        formControl.disable();
        if (isGroup) {
            formControl.get('unit_value').reset('');
        } else {
            formControl.reset('');
        }
    }

    enableBloodValues() {
        const group = this.bloodtestquizForm.get('btq_blood_values');
        if (!group) {
            return;
        }
        const keys = this.getObjKeys(group.value);
        for (let i = 0; i < keys.length; i++) {
            const ctrl = group.get(keys[i]);
            if (ctrl && ctrl.value && (ctrl.value.length || (ctrl.value.unit_value && ctrl.value.unit_value.length))) {
                ctrl.enable();
            }
        }
    }

  assetClick(e) {
    const fileExtension = this.getFileExtension(e.url);
    // const imgSrc = fileExtension === 'pdf' ? '../assets/images/pdf_preview.png' : e.url;
    // this.openImageDialog(imgSrc, e.url);
    const isPdf = fileExtension === 'pdf';
    this.openImageDialog(e.url, e.url, isPdf);
  }

  openImageDialog(imgSrc: string, downloadSrc?: string, isPdf?: boolean) {
    const options = {
      maxWidth: "90vw",
      panelClass: "p-0-dialog",
      data: {
        imgSrc,
        downloadSrc,
        hideDownload: Boolean(this.appState.user.usr_type !== 'superadmin' && this.appState.user.usr_type !== 'nutri'),
        isPdf
      }
    };
    this.dialog.open(ImageDialogComponent, options);
  }  

  getFileExtension(fileName) {
    return fileName.split('.').pop().toLowerCase();
  }
}
