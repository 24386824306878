import { CanDeactivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmDialogService } from './confirm-dialog/confirm-dialog.service';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}

@Injectable()
export class PendingChangesGuard implements CanDeactivate<ComponentCanDeactivate> {

  constructor(private dialogService: ConfirmDialogService,) {};

  canDeactivate(component: ComponentCanDeactivate): boolean | Observable<boolean> {
    // if there are no pending changes, just allow deactivation; else confirm first
    return component.canDeactivate() ?
      true :
      this.openConfirmDialog();
  }

  openConfirmDialog() {
    const options = {
      title: 'נתונים לא נשמרו',
      message: '',
      cancelText: 'לא',
      confirmText: 'כן',
      errorMessage: 'האם לצאת מהטופס? יש שינויים שלא נשמרו'
    };
    this.dialogService.open(options);
    return this.dialogService.confirmed();
  }
}