import { VznFeathersService } from '@noammazuz/vzn-feathers';

export interface PagingResponse {
    total: number;
    limit: number;
    skip: number;
    data: any[];
}

export interface PaginationResponse<T> {
    data: T[];
    skip: number;
    limit: number;
    total: number;
}

export class BaseService {
    protected pRest;
    protected pServiceName: string;
    protected feathersService: VznFeathersService<any>;

    idField = 'id';

    constructor(feathersService: VznFeathersService<any>, serviceName?: string) {
        this.feathersService = feathersService;
        if (serviceName) {
            this.setService(serviceName);
        }
    }

    setService(serviceName: string) {
        this.pServiceName = serviceName;
        this.pRest = this.feathersService.restClient.service(this.pServiceName);
    }

    summerize(query: any, columnList: string[]) {
        return this.pRest.find();
    }

    create(object: any) {
        throw new Error('You have to implement the method create!');
    }

    update(object: any) {
        throw new Error('You have to implement the method update!');
    }

    remove(id: string, query: any) {
        return this.pRest.remove(id, query);
    }
}
