<div class="full-loader" *ngIf="busy || !nuttestquizForm">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>

<div *ngIf="nuttestquizForm" [ngClass]="{'loading-margin': busy}">

    <form novalidate class="basic-form" [formGroup]="nuttestquizForm" (ngSubmit)="onSubmit()">

        <div class="row">
            <h1>{{'measurement.QUIZ_TITLE' | translate}}</h1>
        </div>

        <mat-card>
            <div class="w45">
                <mat-form-field>
                    <input #formDate matInput [matDatepickerFilter]="dateFilter" [matDatepicker]="dpTestdate" placeholder="{{'measurement.INSERT_DATE' | translate}}" formControlName="ntq_test_date">
                    <mat-datepicker-toggle matSuffix [for]="dpTestdate"></mat-datepicker-toggle>
                    <mat-datepicker [dateClass]="dateClass" #dpTestdate disabled="false"></mat-datepicker>
                </mat-form-field>
            </div>


            <div class="nut-field-row mb125">
                <mat-form-field class="w45">
                    <!-- <input type="number" min="100" max="240" inputmode="numeric" pattern="[0-9]*" formControlName="ntq_height" matInput placeholder="{{'measurement.HEIGHT' | translate}}"> -->
                    <input digitOnly type="text" maxlength="5" inputmode="decimal" pattern="^\d+(\.\d{1})?$" decimal="true" min="1" max="241" formControlName="ntq_height" matInput placeholder="{{'measurement.HEIGHT' | translate}}" />
                    <mat-error>
                        {{'measurement.HEIGHT_ERROR' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="nut-field-row mb125">
                <mat-form-field class="w45">
                    <!-- <input type="number" min="20" max="220" inputmode="numeric" pattern="[0-9]*" formControlName="ntq_weight" matInput placeholder="{{'measurement.WEIGHT' | translate}}"> -->
                    <input digitOnly type="text" maxlength="5" inputmode="decimal" pattern="^\d+(\.\d{1})?$" decimal="true" min="1" max="221" formControlName="ntq_weight" matInput placeholder="{{'measurement.WEIGHT' | translate}}" />
                    <mat-error>
                        {{'measurement.WEIGHT_ERROR' | translate}}
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="nut-field-row mb125">
                <div class="w35">
                    <mat-checkbox class="checkbox" formControlName="ntq_fat_percent_bool">{{'measurement.FAT_PERCENT' | translate}}</mat-checkbox>
                    <div class="font-12 font-italic" [hidden]="nuttestquizForm.controls['ntq_weight'].value">{{'measurement.FAT_PERCENT_ERROR' | translate}}</div>
                </div>
            </div>

            <div [hidden]="!nuttestquizForm.controls['ntq_weight'].value || !nuttestquizForm.controls['ntq_fat_percent_bool'].value">
                <div class="nut-field-row mb75">
                    <mat-form-field class="w35">
                        <!-- <input type="number" min="1" formControlName="ntq_fat_percent_num" matInput placeholder="{{'measurement.FAT_PERCENT_INSERT' | translate}}"> -->
                        <input digitOnly type="text" maxlength="5" inputmode="decimal" pattern="^\d+(\.\d{1})?$" decimal="true" min="1" max="99" formControlName="ntq_fat_percent_num" matInput placeholder="{{'measurement.FAT_PERCENT_INSERT' | translate}}" />
                    </mat-form-field>
                </div>
            </div>

        </mat-card>

        <br>


        <button mat-raised-button color="primary" class="full-width" [disabled]="isLocked || busy">{{'general.save' | translate}}</button>
        <div *ngIf="isLocked" class="font-12 text-center text-danger">
            {{'general.savedQuiz' | translate}}
        </div>


    </form>
</div>
