import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MaterialModule } from './material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HealthquizComponent } from '../components/healthquiz/healthquiz.component';
import { FooddiaryquizComponent } from '../components/fooddiaryquiz/fooddiaryquiz.component';
import { FooddiaryquizDayComponent } from '../components/fooddiaryquiz/fooddiaryquiz-day.component';
import { FoodDiaryQuizMealSegmentComponent, MediaAssetsPipe } from '../components/fooddiaryquiz/fooddiaryquiz-meal-segment.component';

import { ClubComponent, ClubFilterPipe, UserFilterPipe } from '../components/club/club.component';
import { TeamPracticeComponent } from '../components/club/team-practice.component';
import { TeamReportComponent } from '../components/club/team-report.component';

import { NutritionquizComponent } from '../components/nutritionquiz/nutritionquiz.component';

import { NutquizComponent, LabelByIdPipe } from '../components/nutquiz/nutquiz.component';
import { NutingredientcategoryComponent } from '../components/nutquiz/nut-ingredient-category.component';

import { SleepingquizComponent } from '../components/sleepingquiz/sleepingquiz.component';
import { PracticequizComponent } from '../components/practicequiz/practicequiz.component';
import { BloodtestquizComponent } from '../components/bloodtestquiz/bloodtestquiz.component';
import { NuttestquizComponent } from '../components/nuttestquiz/nuttestquiz.component';
import { MeasurementComponent } from '../components/measurement/measurement.component';

import { MeasurereportComponent } from '../components/measurereport/measurereport.component';

import { NutritionistformComponent, FdqDetailsPipe } from '../components/nutritionistform/nutritionistform.component';
import { UserDetailsComponent } from '../components/nutritionistform/sections/user-details/user-details.component';
import { AntropometricsComponent, PercentageFlagPipe } from '../components/nutritionistform/sections/antropometrics/antropometrics.component';
import { ChartsComponent } from '../components/nutritionistform/sections/charts/charts.component';
import { PracticesComponent, PracticeMetPipe } from '../components/nutritionistform/sections/practices/practices.component';
import { SleepingComponent } from '../components/nutritionistform/sections/sleeping/sleeping.component';
import { BloodtestComponent } from '../components/nutritionistform/sections/bloodtest/bloodtest.component';
import { HealthComponent } from '../components/nutritionistform/sections/health/health.component';
import { NutritionComponent } from '../components/nutritionistform/sections/nutrition/nutrition.component';
import { FoodDiaryComponent } from '../components/nutritionistform/sections/food-diary/food-diary.component';

import { ProfileComponent } from "../components/profile/profile.component";
import { ShareReportsDialogComponent } from '../components/profile/share-reports-dialog.component';
import { UserprogressComponent } from "../components/userprogress/userprogress.component";
import { UserstableComponent } from "../components/userstable/userstable.component";
import { UserreportComponent } from '../components/userreport/userreport.component';
import { UserparentComponent } from "../components/userparent/userparent.component";
import { CoachComponent } from "../components/coach/coach.component";
import { UserpracticeComponent } from "../components/userpractice/userpractice.component";
import { NotestableComponent } from "../components/notestable/notestable.component";
import { UsernotesComponent } from "../components/usernotes/usernotes.component";
import { LogtableComponent } from "../components/logtable/logtable.component";

import { PlansComponent } from '../components/plans/plans.component';

import { MatExpansionModule } from '@angular/material/expansion';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatDialogModule } from '@angular/material/dialog';

import { DigitOnlyModule } from '@uiowa/digit-only';

import { PdfViewerModule } from 'ng2-pdf-viewer';

import { VznDataTableModule, TranslationService } from '@noammazuz/vzn-data-table';
import { AuthService, RestApiService, VznFeathersModule } from '@noammazuz/vzn-feathers';
import { VznMediaModule } from '@noammazuz/vzn-media';
import { VznLoginModule } from '@noammazuz/vzn-login';

import { environment } from '@environments/environment';

import { LoginPageComponent } from '../pages/login-page/login-page.component';
import { SplashScreenComponent } from '../pages/splash-screen/splash-screen.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';

import { FullComponent } from '../layouts/full/full.component';

import { SharedModule } from '../shared/shared.module';

import { ConfirmDialogModule } from '../shared/confirm-dialog/confirm-dialog.module';
import { CustomDialogComponent } from '../shared/custom-dialog.component';
import { ImageDialogComponent } from '../shared/image-dialog.component';
import { BarcodeDialogComponent } from '../shared/barcode-dialog.component';

import { Injector, APP_INITIALIZER } from '@angular/core';
import { LOCATION_INITIALIZED } from '@angular/common';

import { PendingChangesGuard } from '../shared/pending-changes.guard';
import { CarouselModule } from 'ngx-owl-carousel-o';

import { ChartModule } from 'angular-highcharts';

import { BarcodeScannerLivestreamModule } from 'ngx-barcode-scanner';

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    // AppHeaderComponent,
    // AppSidebarComponent,
    HealthquizComponent,
    FooddiaryquizComponent,
    FooddiaryquizDayComponent,
    FoodDiaryQuizMealSegmentComponent,
    MediaAssetsPipe,
    ClubComponent,
    TeamPracticeComponent,
    TeamReportComponent,
    NutritionquizComponent,
    NutquizComponent,
    LabelByIdPipe,
    NutingredientcategoryComponent,
    SleepingquizComponent,
    PracticequizComponent,
    BloodtestquizComponent,
    NuttestquizComponent,
    MeasurementComponent,
    MeasurereportComponent,
    NutritionistformComponent,
    FdqDetailsPipe,
    UserDetailsComponent,
    AntropometricsComponent,
    PercentageFlagPipe,
    ChartsComponent,
    PracticesComponent,
    PracticeMetPipe,
    SleepingComponent,
    BloodtestComponent,
    HealthComponent,
    NutritionComponent,
    FoodDiaryComponent,
    LoginPageComponent, 
    SplashScreenComponent,
    ProfileComponent, UserprogressComponent, UserstableComponent, UserreportComponent, UserparentComponent, 
    CoachComponent,
    UserpracticeComponent,
    NotestableComponent,
    UsernotesComponent,
    LogtableComponent,
    CustomDialogComponent,
    ImageDialogComponent,
    ShareReportsDialogComponent,
    BarcodeDialogComponent,
    ClubFilterPipe,
    UserFilterPipe,
    PlansComponent
  ],
  imports: [
    MatDialogModule,
    NgxDatatableModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    CarouselModule,
    DigitOnlyModule,
    PdfViewerModule,
    SharedModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    VznDataTableModule,
    VznFeathersModule.forRoot(environment.vznFeathers),
    VznMediaModule.forRoot({
      defaultImgSrc: 'assets/images/image_placeholder.png',
      maxImageSize: 1500
    }),
    VznLoginModule,
    ConfirmDialogModule,
    ChartModule,
    BarcodeScannerLivestreamModule
  ],
  providers: [
    TranslationService,
    TranslateService,
    // VznConfig, 
    RestApiService, AuthService, PendingChangesGuard,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true
    }
  ],

  entryComponents: [
    CustomDialogComponent,
    ImageDialogComponent,
    ShareReportsDialogComponent,
    BarcodeDialogComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  const x = () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'he';
      const langs = ['en', 'he', 'he_F'];

      translate.addLangs(langs);
      translate.getTranslation('en');
      translate.setDefaultLang(langToSet);
      translate.use(langToSet).subscribe(() => {
        console.info(`Successfully initialized '${langToSet}' language.'`);
      }, err => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
  return x;
}
