import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { SpinnerComponent } from './spinner.component';
import { NotesComponent, NoteDetailsPipe, ReversePipe, DateFormatPipe } from './notes/notes.component';
import { MaterialModule } from '../app/material.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, MaterialModule],
  declarations: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    NotesComponent,
    NoteDetailsPipe,
    ReversePipe,
    DateFormatPipe
  ],
  exports: [
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective,
    SpinnerComponent,
    NotesComponent,
    NoteDetailsPipe,
    ReversePipe,
    DateFormatPipe
   ],
  providers: [ MenuItems ]
})
export class SharedModule { }
