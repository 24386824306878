<div class="full-loader" *ngIf="busy">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>

<div class="coach-page" *ngIf="columns.length > 0 && query?.$teamid" [ngClass]="{'loading-margin': busy}">
    <!-- <h1>מסך מאמן</h1> -->
    <div class="text-center" *ngIf="coachTeams.length > 0">
        <mat-form-field class="w65">
            <!-- <button type="button" [disabled]="!chosenTeam || query.$teamid == chosenTeam" matSuffix class="top-button" mat-stroked-button (click)="changeTeam($event)">הצג</button> -->
            <mat-select [(ngModel)]="chosenTeam" (selectionChange)="changeTeam($event.value)">
                <ng-container *ngFor="let val of coachTeams">
                    <mat-option [value]="val._id">
                        {{ val['tem_name'] }}
                        {{ query.$teamid == val._id ? ' (קבוצה נוכחית)' : '' }}
                    </mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </div>
    <br>

    <vzn-data-table #tableEl [restService]="usersRestService" [hidePaginator]="true" [pageSize]="100" [columns]="columns" [query]="query" (loading)="tableLoading($event)">
    </vzn-data-table>

    <div class="font-14" style="margin: 50px auto;">
        <div class="mb50 m-r-10">
            <span class="font-bold valid-color">ישראל ישראלי</span>
            <span> - נותרו דוחות בחבילה</span>
        </div>
        <div class="mb50">
            <mat-icon class="userstable-status-icon invalid">priority_high</mat-icon>
            <span class="font-bold">לא השלים את כל השאלונים</span>
            <span> - מומלץ להזכיר לו להשלים ⚠️</span>
        </div>
        <div class="mb50">
            <mat-icon class="userstable-status-icon valid">check</mat-icon>
            <span class="font-bold">🏅 השלים את כל השאלונים/עשה בדיקות דם 🏅</span>
        </div>
        <div class="mb50 m-r-10">
            <span class="invalid-color font-bold">
                לא נרשם
            </span>
            <span>
                 - לא השלים את תהליך ההרשמה ⚠️
            </span>
        </div>
        <div class="mb50 m-r-10">
            <span class="invalid-color font-bold">
                הורה לא נרשם
            </span>
            <span>
                 - הורה לא השלים הרשמה ולא אישר תקנון ⚠️
            </span>
        </div>
        <div class="mb50 m-r-10">
            <span class="invalid-color font-bold">
                לא העלה בדיקות ישנות
            </span>
            <span>
                 - לא דיווח על בדיקות דם ישנות ⚠️
            </span>
        </div>
        <div class="mb50 m-r-10">
            <span class="invalid-color font-bold">
                נשלחה המלצה
            </span>
            <span>
                - קיבל המלצה לבדיקות דם אך עדיין לא העלה תוצאות 🩸   
            </span>
        </div>
        <div class="mb50 m-r-10">
            <span class="invalid-color font-bold">
                לא ביצע
            </span>
            <span>
                - קיבל המלצה לבדיקות דם לפני מעל 100 ימים אך לא ביצע את הבדיקה 🤷🏼‍♂️   
            </span>
        </div>
        <div class="mb50">
            <mat-icon class="userstable-status-icon">visibility_off</mat-icon>
            <span class="font-bold"> השחקן וההורה לא אישרו שיתוף מידע 😔</span>
        </div>
    </div>

</div>

<!-- <ng-template #daysTmpl let-value="value" ngx-datatable-cell-template>
    <mat-icon class="userstable-status-icon invalid" *ngIf="(value && value > -1) || value === 0">priority_high</mat-icon>
    <mat-icon class="userstable-status-icon valid" *ngIf="value == -1">check</mat-icon>
</ng-template>  -->

<!-- <ng-template #savedTmpl let-value="value" ngx-datatable-cell-template>
    <mat-icon class="userstable-status-icon invalid" *ngIf="value === false">priority_high</mat-icon>
    <mat-icon class="userstable-status-icon valid" *ngIf="value === true">check</mat-icon>
</ng-template>  -->

<ng-template #quizDaysTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <ng-container *ngIf="row.notAgreement">
        <span class="invalid-color">
            לא נרשם
        </span>
    </ng-container>
    <ng-container *ngIf="row.notAgreementParents">
        <span class="invalid-color">
            הורה לא נרשם
        </span>
    </ng-container>

    <div *ngIf="row && row.usr_type ==='player' && !row.notAgreement && !row.notAgreementParents">

        <mat-icon class="userstable-status-icon" *ngIf="row.notShared">visibility_off</mat-icon>

        <mat-icon class="userstable-status-icon invalid" *ngIf="!row.notShared && row['quiz_saved'] == 2">priority_high</mat-icon>
        <mat-icon class="userstable-status-icon valid" *ngIf="!row.notShared && (row['quiz_saved'] == 1 || row['quiz_saved'] == 3)">check</mat-icon>

        <!-- <span class="valid-color" *ngIf="row['quiz_saved'] == 3">
            <span>({{ value }})</span>
            <span *ngIf="row['usr_state'] && row['usr_state']['stateTable']" class="dl dir-ltr nowrap-text">
                ({{ row.usr_state.stateTable.done }} / {{ row.usr_state.stateTable.totalSeen }})
            </span>
        </span>

        <span class="invalid-color" *ngIf="row['quiz_saved'] == 2">
            <span>({{ value }})</span>
            <span *ngIf="row['usr_state'] && row['usr_state']['stateTable']" class="dl dir-ltr nowrap-text">
                ({{ row.usr_state.stateTable.done }} / {{ row.usr_state.stateTable.totalSeen }})
            </span>
        </span>

        <mat-icon class="userstable-status-icon valid" *ngIf="row['quiz_saved'] == 1">check</mat-icon> -->
    </div>
</ng-template>

<ng-template #practiceTmpl let-value="value" let-row="row" ngx-datatable-cell-template>
    <ng-container *ngIf="value">
        <div *ngIf="value.totalCount*1 > 0" class="pointer" (click)="goToPractice(row._id)">
            <span class="font-italic">{{ value.totalCount }}</span> ({{ value.totalTime }})
        </div>
        <div *ngIf="value.totalCount*1 === 0" class="pointer" (click)="goToPractice(row._id)">0</div>
    </ng-container>
</ng-template> 

<ng-template #reportTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <ng-container *ngIf="row && row.usr_type ==='player' && !row.notAgreement && !row.notAgreementParents">
        <mat-icon class="userstable-status-icon" *ngIf="row.notShared">visibility_off</mat-icon>

        <div *ngIf="value && !row.notShared" class="pointer" (click)="goToReport(value._id)">
            <span>הצג</span>
            <!-- <span>{{ value.createdAt | date:'dd/MM/yyyy' }}</span> -->
        </div>
    </ng-container>
</ng-template> 

<ng-template #bloodDaysTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player' && !row.notAgreement && !row.notAgreementParents">

        <mat-icon class="userstable-status-icon" *ngIf="row.notShared">visibility_off</mat-icon>

        <ng-container *ngIf="!row.notShared">
            <ng-container *ngIf="row['blood_saved'] == 31">
                <mat-icon class="userstable-status-icon valid">check</mat-icon>
            </ng-container>

            <ng-container *ngIf="row['blood_saved'] == 32">
                <span class="invalid-color">
                    <div>לא העלה</div>
                    <div>בדיקות ישנות</div>
                </span>
            </ng-container>

            <ng-container *ngIf="row['blood_saved'] == 41">
                <mat-icon class="userstable-status-icon valid">check</mat-icon>
                <!-- <span class="valid-color" *ngIf="row['last_btq_date']">{{ row['last_btq_date'] | dateFormat : 'DD/MM/yyyy' }}</span> -->
            </ng-container>

            <ng-container *ngIf="row['blood_saved'] == 42">
                <span class="invalid-color">
                    נשלחה המלצה
                </span>
            </ng-container>

            <ng-container *ngIf="row['blood_saved'] == 43">
                <span class="invalid-color">
                    לא ביצע
                </span>
            </ng-container>
        </ng-container>

    </div>
</ng-template>

<ng-template #nameHasReportTmpl let-value="value" let-row="row" ngx-datatable-cell-template>
    <ng-container *ngIf="value">
        <span *ngIf="row.has_plan" class="font-bold valid-color">
            {{ value }}
        </span>
        <span *ngIf="!row.has_plan">
            {{ value }}
        </span>
    </ng-container>
</ng-template> 

<!-- <ng-template #bloodDaysTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'">
        <ng-container *ngIf="row['blood_saved'] == 3">
            <mat-icon class="userstable-status-icon invalid">priority_high</mat-icon>
            <span>({{ value }})</span>
        </ng-container>
        <span *ngIf="row['blood_saved'] == 2">
            {{ value }}
        </span>
        <ng-container *ngIf="row['blood_saved'] == 1">
            <mat-icon class="userstable-status-icon valid">check</mat-icon>
            <span class="valid-color" *ngIf="row['last_btq_date']">{{ row['last_btq_date'] | dateFormat : 'DD/MM/yyyy' }}</span>
        </ng-container>
    </div>
</ng-template> -->
