<div>

    <h3 *ngIf="compType === 'ingredient' || (compType === 'frequency' && allOptionsChosen && allOptionsChosen.length) || (compType === 'typical' && allOptionsForTypical && allOptionsForTypical.length)" [ngClass]="{'error-class text-danger font-bold': showErrors && isError}">
        {{ title }}
    </h3>

    <ng-container *ngIf="compType === 'ingredient'">

        <ng-container *ngIf="nameType !== 'drink'">
            <!-- <mat-checkbox class="checkbox normal" [(ngModel)]="disableAllNotEat" (ngModelChange)="disableAll($event)" [disabled]="isLocked">
                <span>לא אוכל מזונות שהם</span>
                {{title}}
            </mat-checkbox> -->
            <mat-checkbox class="checkbox normal" [formControl]="disableAllNotEat" [disabled]="isLocked">
                <span class="font-bold">לא אוכל מזונות שהם </span>
                <span class="font-bold">{{title}}</span>
            </mat-checkbox>
        </ng-container>
        <!-- <ng-container *ngIf="isDrink">
            <mat-checkbox class="checkbox normal" [checked]="true" [disabled]="true">
                <span class="font-bold">מים</span>
            </mat-checkbox>
        </ng-container> -->
        
        <ng-container [ngTemplateOutlet]="categoryListIngredient" [ngTemplateOutletContext]="{
            context: formContext,
            options: allOptions,
            isChildren: false,
            isError: false
        }">

        </ng-container>

        <!-- <mat-checkbox class="checkbox normal" [(ngModel)]="isOther" (ngModelChange)="updateValidation()" [disabled]="disableAllNotEat?.value || isLocked">
            <span class="font-bold">אחר</span>
        </mat-checkbox>

        <ng-container *ngIf="isOther">

            <div class="repeat-container" [formArray]="otherFoodFormArr" *ngFor="let item of otherFoodFormArr.controls; let i = index; let last = last;">
                <div>

                    <div class="mb50">
                        <mat-form-field class="w65">
                            <input [formControl]="item" matInput placeholder="פירוט">
                        </mat-form-field>
                    </div>

                    <div>
                        <button (click)="removeOnClick(i)" type="button" mat-raised-button color="warn" mat-button [disabled]="isLocked">הסר פריט<mat-icon>remove_circle_outline</mat-icon></button>
                    </div>


                </div>
            </div>

            <div class="add-button-container">
                <button (click)="addNewFood()" type="button" mat-raised-button color="primary" mat-button [disabled]="isLocked">הוסף פריט<mat-icon>add_circle_outline</mat-icon></button>
            </div>
        </ng-container> -->

        <mat-checkbox class="checkbox normal" [formControl]="isOtherControl" [disabled]="disableAllNotEat?.value || isLocked">
            <span class="font-bold">אחר</span>
        </mat-checkbox>

        <ng-container *ngIf="isOtherControl.value">

            <div class="repeat-container" [formArray]="otherFoodFormArr" *ngFor="let item of otherFoodFormArr.controls; let i = index; let last = last;">
                <div>

                    <div class="mb50">
                        <mat-form-field class="w65">
                            <input [formControl]="item" matInput placeholder="פירוט">
                        </mat-form-field>
                    </div>

                    <div>
                        <button (click)="removeOnClick(i)" type="button" mat-raised-button color="warn" mat-button [disabled]="isLocked">הסר פריט<mat-icon>remove_circle_outline</mat-icon></button>
                    </div>


                </div>
            </div>

            <div class="add-button-container">
                <button (click)="addNewFood()" type="button" mat-raised-button color="primary" mat-button [disabled]="isLocked">הוסף פריט<mat-icon>add_circle_outline</mat-icon></button>
            </div>
        </ng-container>
        
    </ng-container>
    
    <ng-container *ngIf="compType === 'frequency'">

        <!-- <ng-container *ngIf="isDrink">
            <mat-checkbox class="checkbox normal" [checked]="true" [disabled]="true">
                <span class="font-bold">מים</span>
            </mat-checkbox>
        </ng-container> -->
        
        <ng-container *ngFor="let opt of allOptionsChosen">
            <!-- <div *ngIf="opt.checked">
                <h5>{{opt.label}}</h5>

                <mat-radio-group [disabled]="isLocked" class="d-column" [(ngModel)]="opt.frequency[mealType]" (ngModelChange)="changedFrequency($event)">
                    <mat-radio-button class="mb50" *ngFor="let freqOpt of frequencyOptions" [value]="freqOpt.value">{{ freqOpt.title }}</mat-radio-button>
                </mat-radio-group>
            </div> -->

            <mat-checkbox class="checkbox normal" [(ngModel)]="opt.weekly[mealType]" (ngModelChange)="changedWeekly(opt, $event)" [disabled]="isLocked">
                <span class="font-bold">{{opt.label}}</span>
            </mat-checkbox>

        </ng-container>

    </ng-container>

    <ng-container *ngIf="compType === 'typical'">

        <!-- <ng-container *ngIf="isDrink">
            <mat-checkbox class="checkbox normal" (change)="typicalProductCheckboxChanged({_id: 'water'}, $event)" [checked]="userChosenProducts && userChosenProducts[mealType] && userChosenProducts[mealType].water" [disabled]="isLocked">
                <span class="">מים</span>
            </mat-checkbox>
            <div *ngIf="userChosenProducts && userChosenProducts[mealType] && userChosenProducts[mealType].water && userChosenProducts[mealType].water.fdq_day_info_portion && userChosenProducts[mealType].water.fdq_day_info_quantity" style="margin: 5px 25px;" class="row m-0">
                <div class="">
                    <span>{{ userChosenProducts[mealType].water.fdq_day_info_portion }} </span>
                    
                    <span *ngIf='!(userChosenProducts[mealType].water.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || userChosenProducts[mealType].water.fdq_day_info_quantity == "כמות מדודה (גרם)")'>{{ userChosenProducts[mealType].water.fdq_day_info_quantity }} </span>
                    <span *ngIf='userChosenProducts[mealType].water.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || userChosenProducts[mealType].water.fdq_day_info_quantity == "כמות מדודה (גרם)"'>
                        כמות מדודה 
                        ({{ userChosenProducts[mealType].water.fdq_day_info_weight_or_volume }} {{userChosenProducts[mealType].water.fdq_day_info_units }})
                    </span>
                </div>
            </div>
        </ng-container> -->

        <div *ngFor="let opt of allOptionsForTypical">
            <ng-container *ngIf="opt._id === 'water'">
                <mat-checkbox class="checkbox normal" (change)="typicalProductCheckboxChanged({_id: 'water'}, $event)" [checked]="userChosenProducts && userChosenProducts[mealType] && userChosenProducts[mealType].water" [disabled]="isLocked">
                    <span class="">מים</span>
                </mat-checkbox>
                <div *ngIf="userChosenProducts && userChosenProducts[mealType] && userChosenProducts[mealType].water && userChosenProducts[mealType].water.fdq_day_info_portion && userChosenProducts[mealType].water.fdq_day_info_quantity" style="margin: 5px 25px;" class="row m-0">
                    <div class="">
                        <span>{{ userChosenProducts[mealType].water.fdq_day_info_portion }} </span>
                        
                        <span *ngIf='!(userChosenProducts[mealType].water.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || userChosenProducts[mealType].water.fdq_day_info_quantity == "כמות מדודה (גרם)")'>{{ userChosenProducts[mealType].water.fdq_day_info_quantity }} </span>
                        <span *ngIf='userChosenProducts[mealType].water.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || userChosenProducts[mealType].water.fdq_day_info_quantity == "כמות מדודה (גרם)"'>
                            כמות מדודה 
                            ({{ userChosenProducts[mealType].water.fdq_day_info_weight_or_volume }} {{userChosenProducts[mealType].water.fdq_day_info_units }})
                        </span>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="opt._id !== 'water'">

                <mat-checkbox class="checkbox normal" [(ngModel)]="opt.checkedTypical[mealType]" (ngModelChange)="changedTypical(opt, $event)" [disabled]="isLocked">
                    <span class="">{{opt.label}}</span>
                </mat-checkbox>

                <div style="margin-right: 25px; margin-left: 25px;">
                    <ng-container *ngIf="opt.checkedTypical[mealType] && opt.childrenProducts && opt.childrenProducts[nameType] && opt.childrenProducts[nameType].length > 0">
                        <ng-container *ngFor="let product of opt.childrenProducts[nameType]">
                            <div class="centered-row m-0">
                                <mat-checkbox class="checkbox normal" (change)="typicalProductCheckboxChanged(product, $event)" [checked]="userChosenProducts && userChosenProducts[mealType] && userChosenProducts[mealType][product._id]" [disabled]="isLocked"></mat-checkbox>
    
                                <div *ngIf="(product | mediaAssets).url" class="last-items-img">
                                    <img [src]="(product | mediaAssets).url" />
                                </div>
                                <div class="full-width m-t-10">
                                    <div class="m-b-5 m-t-5">
                                        <div class="font-bold" [ngClass]="{'text-danger': showErrors && checkIfIsTypicalError(opt)}">{{ product.label || product.q_title || product.title || '' }}</div>
                                    </div>
                                    <div *ngIf="userChosenProducts && userChosenProducts[mealType] && userChosenProducts[mealType][product._id] && userChosenProducts[mealType][product._id].fdq_day_info_portion && userChosenProducts[mealType][product._id].fdq_day_info_quantity" class="row m-0">
                                        <div class="">
                                            <span>{{ userChosenProducts[mealType][product._id].fdq_day_info_portion }} </span>
                                            <span *ngIf='!(userChosenProducts[mealType][product._id].fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || userChosenProducts[mealType][product._id].fdq_day_info_quantity == "כמות מדודה (גרם)")'>{{ userChosenProducts[mealType][product._id].fdq_day_info_quantity }} </span>
                                            <span *ngIf='userChosenProducts[mealType][product._id].fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || userChosenProducts[mealType][product._id].fdq_day_info_quantity == "כמות מדודה (גרם)"'>
                                                כמות מדודה 
                                                ({{ userChosenProducts[mealType][product._id].fdq_day_info_weight_or_volume }} {{userChosenProducts[mealType][product._id].fdq_day_info_units }})
                                            </span>
                                        </div>
                                        <div *ngIf="userChosenProducts[mealType][product._id].fdq_day_info_item_calories" class="">
                                            <span class="font-bold">{{ userChosenProducts[mealType][product._id].fdq_day_info_item_calories }} </span>
                                            <span>{{'food_diary.MEAL_CALORIES' | translate}} </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        
                        <button type="button" class="m-t-15" mat-stroked-button (click)="customProductAdd({_id: 'custom'})" [disabled]="isLocked">
                            <span class="font-bold">הוספת פריט מזון אחר</span>
                        </button>
                        <!-- <mat-checkbox class="checkbox normal" (click)="customProductAdd({_id: 'custom'})" [checked]="userChosenProducts && userChosenProducts[mealType] && userChosenProducts[mealType].custom && userChosenProducts[mealType].custom[nameType] && userChosenProducts[mealType].custom[nameType].length > 0" [disabled]="isLocked">פריט מזון אחר</mat-checkbox> -->

                        <ng-container *ngIf="userChosenProducts && userChosenProducts[mealType] && userChosenProducts[mealType].custom && userChosenProducts[mealType].custom[nameType] && userChosenProducts[mealType].custom[nameType].length > 0">
                            <ng-container *ngFor="let customProduct of userChosenProducts[mealType].custom[nameType]">
                                <div *ngIf="customProduct" class="centered-row m-0" style="margin-right: 25px; margin-left: 25px;">
                                    <mat-checkbox class="checkbox normal" (click)="customProductRemove(customProduct)" [checked]="true" [disabled]="isLocked"></mat-checkbox>
        
                                    <div *ngIf="(customProduct | mediaAssets).url" class="last-items-img">
                                        <img [src]="(customProduct | mediaAssets).url" />
                                    </div>
                                    <div class="full-width m-t-10">
                                        <div class="m-b-5 m-t-5">
                                            <div class="font-bold">{{ customProduct.fdq_day_info_item_name || '' }}</div>
                                        </div>
                                        <div *ngIf="customProduct.fdq_day_info_portion && customProduct.fdq_day_info_quantity" class="row m-0">
                                            <div class="">
                                                <span>{{ customProduct.fdq_day_info_portion }} </span>
                                                <span *ngIf='!(customProduct.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || customProduct.fdq_day_info_quantity == "כמות מדודה (גרם)")'>{{ customProduct.fdq_day_info_quantity }} </span>
                                                <span *ngIf='customProduct.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || customProduct.fdq_day_info_quantity == "כמות מדודה (גרם)"'>
                                                    כמות מדודה 
                                                    ({{ customProduct.fdq_day_info_weight_or_volume }} {{customProduct.fdq_day_info_units }})
                                                </span>
                                            </div>
                                            <div *ngIf="customProduct.fdq_day_info_item_calories" class="">
                                                <span class="font-bold">{{ customProduct.fdq_day_info_item_calories }} </span>
                                                <span>{{'food_diary.MEAL_CALORIES' | translate}} </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                    </ng-container>
                </div>

            </ng-container>



            <ng-container *ngIf="false && opt._id !== 'water'">

            <mat-checkbox class="checkbox normal" (change)="typicalCategoryCheckboxChanged(opt, $event.checked)" [checked]="opt.checkedTypical[mealType]" [disabled]="isLocked">{{opt.label}}</mat-checkbox>
            <div style="margin-right: 25px; margin-left: 25px;">
                <ng-container *ngIf="opt.checkedTypical[mealType] && opt.childrenProducts && opt.childrenProducts[nameType] && opt.childrenProducts[nameType].length > 0">
                    <!-- <ng-container *ngFor="let product of opt.childrenProducts">
                        <mat-checkbox class="checkbox normal" (change)="typicalProductCheckboxChanged(product, $event)" [checked]="userChosenProducts && userChosenProducts[mealType] && userChosenProducts[mealType][product._id]" [disabled]="isLocked">{{product.label || product.q_title || product.title}}</mat-checkbox>
                        <div *ngIf="userChosenProducts && userChosenProducts[mealType] && userChosenProducts[mealType][product._id] && userChosenProducts[mealType][product._id].fdq_day_info_portion && userChosenProducts[mealType][product._id].fdq_day_info_quantity" class="row m-0">
                            <div class="font-italic text-info" style="margin-right: 20px; margin-left: 20px; font-size: 14px;">
                                <span>{{ userChosenProducts[mealType][product._id].fdq_day_info_portion }} </span>
                                
                                <span *ngIf='!(userChosenProducts[mealType][product._id].fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || userChosenProducts[mealType][product._id].fdq_day_info_quantity == "כמות מדודה (גרם)")'>{{ userChosenProducts[mealType][product._id].fdq_day_info_quantity }} </span>
                                <span *ngIf='userChosenProducts[mealType][product._id].fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || userChosenProducts[mealType][product._id].fdq_day_info_quantity == "כמות מדודה (גרם)"'>
                                    כמות מדודה 
                                    ({{ userChosenProducts[mealType][product._id].fdq_day_info_weight_or_volume }} {{userChosenProducts[mealType][product._id].fdq_day_info_units }})
                                </span>
                            </div>
                            <div *ngIf="userChosenProducts[mealType][product._id].fdq_day_info_item_calories" class="">
                                <span class="font-bold">{{ userChosenProducts[mealType][product._id].fdq_day_info_item_calories }} </span>
                                <span>{{'food_diary.MEAL_CALORIES' | translate}} </span>
                            </div>
                        </div>
                    </ng-container> -->
                    <ng-container *ngFor="let product of opt.childrenProducts[nameType]">
                        <div class="centered-row m-0">
                            <mat-checkbox class="checkbox normal" (change)="typicalProductCheckboxChanged(product, $event)" [checked]="userChosenProducts && userChosenProducts[mealType] && userChosenProducts[mealType][product._id]" [disabled]="isLocked"></mat-checkbox>

                            <!-- <div *ngIf="item.fdq_day_info_assets && item.fdq_day_info_assets[0]" class="last-items-img">
                                <img [src]="item.fdq_day_info_assets[0]['url']" />
                            </div> -->
                            <div *ngIf="(product | mediaAssets).url" class="last-items-img">
                                <img [src]="(product | mediaAssets).url" />
                            </div>
                            <!-- <div class="full-width">
                                <div class="m-b-10">
                                    <div class="font-italic font-bold">{{ item.fdq_day_info_item_name }}</div>
                                </div>
                                <div class="row m-0">
                                    <div class="">
                                        <span>{{ item.fdq_day_info_portion }} </span>
                                        <span *ngIf='!(item.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || item.fdq_day_info_quantity == "כמות מדודה (גרם)")'>{{ item.fdq_day_info_quantity }} </span>
                                        <span *ngIf='item.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || item.fdq_day_info_quantity == "כמות מדודה (גרם)"'>
                                            כמות מדודה 
                                            ({{ item.fdq_day_info_weight_or_volume }} {{item.fdq_day_info_units }})
                                        </span>
                                    </div>
                                    <div *ngIf="item.fdq_day_info_item_calories" class="">
                                        <span class="font-bold">{{ item.fdq_day_info_item_calories }} </span>
                                        <span>{{'food_diary.MEAL_CALORIES' | translate}} </span>
                                    </div>
                                </div>
                            </div> -->
                            <div class="full-width m-t-10">
                                <div class="m-b-5 m-t-5">
                                    <div class="font-bold">{{ product.label || product.q_title || product.title || '' }}</div>
                                </div>
                                <div *ngIf="userChosenProducts && userChosenProducts[mealType] && userChosenProducts[mealType][product._id] && userChosenProducts[mealType][product._id].fdq_day_info_portion && userChosenProducts[mealType][product._id].fdq_day_info_quantity" class="row m-0">
                                    <div class="">
                                        <span>{{ userChosenProducts[mealType][product._id].fdq_day_info_portion }} </span>
                                        <!-- <span>{{ userChosenProducts[mealType][product._id].fdq_day_info_quantity }} </span> -->
                                        <span *ngIf='!(userChosenProducts[mealType][product._id].fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || userChosenProducts[mealType][product._id].fdq_day_info_quantity == "כמות מדודה (גרם)")'>{{ userChosenProducts[mealType][product._id].fdq_day_info_quantity }} </span>
                                        <span *ngIf='userChosenProducts[mealType][product._id].fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || userChosenProducts[mealType][product._id].fdq_day_info_quantity == "כמות מדודה (גרם)"'>
                                            כמות מדודה 
                                            ({{ userChosenProducts[mealType][product._id].fdq_day_info_weight_or_volume }} {{userChosenProducts[mealType][product._id].fdq_day_info_units }})
                                        </span>
                                    </div>
                                    <div *ngIf="userChosenProducts[mealType][product._id].fdq_day_info_item_calories" class="">
                                        <span class="font-bold">{{ userChosenProducts[mealType][product._id].fdq_day_info_item_calories }} </span>
                                        <span>{{'food_diary.MEAL_CALORIES' | translate}} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </ng-container>
                <!-- product.checkedTypical[mealType] -->
            </div>

            </ng-container>
        </div>

    </ng-container>
    

    <ng-template #categoryListIngredient let-context="context" let-options="options" let-isChildren="isChildren" let-isError="isError">
        <div *ngFor="let opt of options">
            <mat-checkbox class="checkbox normal" [color]="!isChildren ? 'primary' : 'warn'" (change)="updateChkbxArray(opt, $event.checked)" [checked]="opt.checked" [disabled]="opt._id === 'water' || disableAllNotEat?.value || isLocked">
                <span [ngClass]="{'font-bold': !isChildren, 'error-class text-danger font-bold': isError}">{{opt.label}}</span>
            </mat-checkbox>
            <div style="margin-right: 25px; margin-left: 25px;">
                <ng-container *ngIf="opt.checked && opt.children && opt.children.length" [ngTemplateOutlet]="categoryListIngredient" [ngTemplateOutletContext]="{
                    context: formContext,
                    options: opt.children,
                    isChildren: true,
                    isError: showErrors && !checkIfIsError(opt.children)
                }">

                </ng-container>
            </div>
        </div>
    </ng-template>

</div>
