import { Component, ViewChildren, QueryList, Inject, Input, ViewChild, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Apputils } from '@services/app-utils';
import { TranslateService } from '@ngx-translate/core';

import { TableColumn } from '@noammazuz/vzn-data-table';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';


// export interface DialogData {
//   title: string;
//   form: FormGroup;
//   template: any;
//   context: any;
// }

// @Component({
//   selector: 'club-dialog',
//   // templateUrl: './club-dialog.html',
//   template: `
//   <div mat-dialog-content>
//     <p class="dialog-paragraph">{{data.title}}</p>
//     <ng-container [ngTemplateOutlet]="data.template" [ngTemplateOutletContext]="{context: data.context, form: data.form}"></ng-container>
//   </div>
//   <div mat-dialog-actions>
//   <button mat-button (click)="onNoClick()">{{'food_diary.MEAL_ITEM_CANCEL' | translate}}</button>
//   <button mat-button [disabled]="data.context.submitDisabled" [mat-dialog-close]="data" cdkFocusInitial>{{'food_diary.MEAL_ITEM_SAVE' | translate}}</button>
//   </div>
// `
// })
// export class ClubDialogComponent {

//   constructor(
//     public dialogRef: MatDialogRef<ClubDialogComponent>,
//     @Inject(MAT_DIALOG_DATA) public data: DialogData) {
//     let form: FormGroup = data.form;
//   }

//   onNoClick(): void {
//     this.dialogRef.close();
//   }

// }

@Component({
  selector: "team-practice",
  templateUrl: "./team-practice.component.html"
})
export class TeamPracticeComponent {
  @ViewChild("userDialogTemplate") userDialogTemplate: TemplateRef<any>;
  @ViewChild("tableEl") tableEl: any;

  @Input() parentFormId: string;
  @Input() mealName: string;
  @Input() mealTitle: string;
  @Input() restService;
  @Input() uploadUrlService;
  @Input() uploadFolderBase: string;

  columns: Array<{}> = [];
  currentUploadFolder: string;
  query: any;

  formContext: any;
  allHours = Apputils.allHours;
  lastChosenHour: string;
  durations: Array<string> = Apputils.getPracticeDurations();

  practiceTypes = Apputils.practiceTypes;
  
  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, private translate: TranslateService) {
    this.formContext = this;
  }

  ngOnInit() {
    this.columns = this.createColumns();
    this.query = { tem_practice_tem_id: this.parentFormId };
  }

  addTeamPractice() {

    console.log("addBreakfastItem");

    let teamPractice: FormGroup = this.createTeamPractice(this.formContext);
    teamPractice.get("tem_practice_tem_id").setValue(this.parentFormId);

    this.addOrEditTeamPracticeDialog(teamPractice);
  }

  addOrEditTeamPracticeDialog(teamPractice: FormGroup) {

    let title: string = this.translate.instant("club.ADD_TEAM_PRACTICE_MODAL_TITLE");

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      width: '50%',
      data: { 
        title: title, 
        ok: this.translate.instant('general.ok'),
        cancel: this.translate.instant('general.cancel'),
        form: teamPractice, 
        template: this.userDialogTemplate, 
        context: this.formContext 
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      if (!result) {
        console.log("user canceled");
        return;
      }
      this.lastChosenHour = teamPractice.value.fdq_day_info_time;

      // clean item fields before sending if needed:
      this.cleanTeamPracticeUnusedFields(teamPractice);

      console.log("user clicked ok, now saving");
      this.onSubmitMeal(teamPractice);
    });
  }

  createTeamPractice(context) {
    return context.formBuilder.group({
      _id: '',
      tem_practice_tem_id: '',
      tem_practice_type: ['', [Validators.required]],
      tem_practice_type_other_text: '',
      tem_practice_times_week: ['', [Validators.required]],
      tem_practice_duration_minutes: ['', [Validators.required]],
    })
  }

  cleanTeamPracticeUnusedFields(teamPractice) {
    if (teamPractice.value.tem_practice_type != "אחר") {
      teamPractice.get("tem_practice_type_other_text").reset('');
    }
  }

  async onSubmitMeal(teamPractice: FormGroup) {

    if (!teamPractice.value._id || teamPractice.value._id === '' || teamPractice.value._id === null) {
      delete teamPractice.value._id;
      let res: any = await this.restService.create(teamPractice.value);
    } else {
      let res: any = await this.restService.patch(teamPractice.value._id, teamPractice.value);
    }
    this.tableEl.setTableData();
  }

  async removeRowOnClick(row: any) {
    console.log(`Going to remove line with id: ${row._id}`);

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // width: '50%',
      data: { 
        title: this.translate.instant("club.TEAM_PRACTICE_REMOVE_ITEM"),
        subtitle: this.translate.instant("club.TEAM_PRACTICE_REMOVE_ITEM_EXPLAIN") + " " + row.tem_practice_type + "?",
        ok: this.translate.instant('general.yes'),
        cancel: this.translate.instant('general.no'),
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        console.log("user canceled");
        return;
      }
      console.log("user clicked ok, now removing");
      let res = await this.restService.delete(row._id);
      console.log("removed. Now refreshing table.");
      this.tableEl.setTableData();

    });

  }

  editRowOnClick(row: any) {
    console.log(row);
    let teamPractice: FormGroup = this.createTeamPractice(this.formContext);
    teamPractice.patchValue(row);
    this.addOrEditTeamPracticeDialog(teamPractice);
  }

  createColumns() {
    let columns: Array<TableColumn> = [
      { prop: 'tem_practice_type', name: 'סוג אימון' },
      { prop: 'tem_practice_duration_minutes', name: 'משך אימון בדקות' },
      { prop: 'tem_practice_times_week', name: 'מספר פעמים בשבוע' },
      { prop: 'remove_button', name: '', iconName: 'remove_circle_outline', cellTemplate: 'iconBtnTmpl', iconOnClick: this.removeRowOnClick.bind(this), maxWidth: 100 },
    ];

    return columns;
  }

}
