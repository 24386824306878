<span #reportHeader></span>
<div>

    <ng-container *ngIf="currentTeamReportForm" [ngTemplateOutlet]="reportTemplate" [ngTemplateOutletContext]="{
        context: formContext,
        form: currentTeamReportForm,
        isReadOnly: false
    }">
    </ng-container>

</div>

<div class="buttons-row mt125 mb125">
    <button *ngIf="!currentTeamReportForm" [disabled]="busy || internalBusy" (click)="addTeamReport()" type="button" mat-raised-button color="primary" mat-button>{{'club.ADD_TEAM_REPORT' | translate}}
        <mat-icon>add_circle_outline</mat-icon></button>

    <button *ngIf="currentTeamReportForm" [disabled]="busy || internalBusy" (click)="saveTeamReport()" type="button" mat-raised-button color="accent" mat-button>{{'club.SAVE_TEAM_REPORT' | translate}}
        <mat-icon>save</mat-icon></button>

    <button *ngIf="currentTeamReportForm" (click)="cancelTeamReport()" type="button" mat-raised-button color="accent" mat-button>{{'club.CANCEL_CHANGES' | translate}}
        <mat-icon>undo</mat-icon></button>      

</div>  

<h4> {{'club.TEAM_REPORT_SUMMARY_TABLE_TITLE' | translate}} </h4>


<div>
    <mat-form-field class="w30">
        <mat-select [(ngModel)]="selectedReportRange" (ngModelChange)="changeRange($event)">
            <ng-container *ngFor="let val of reportsRanges; let i = index">
                <mat-option [value]="val">
                    עונה {{val['startDate'] | date:'yyyy'}}-{{val['endDate'] | date:'yyyy'}}
                </mat-option>
            </ng-container>
        </mat-select>
    </mat-form-field>    
</div>

<div *ngIf="query != undefined" fxLayout="row" fxLayoutWrap="wrap"
fxLayoutAlign="center none">

    <vzn-data-table #tableEl [restService]="restService" [pageSize]="25"
        [columns]="columns" [query]="query">
    </vzn-data-table>
    <!-- <vzn-data-table #tableEl [hidePaginator]="true" [restService]="restService" [pageSize]="25"
        [columns]="columns" [query]="query">
    </vzn-data-table> -->

</div>

<ng-container *ngIf="currentViewTeamReport">
    <div class="m-0 row">
        <h4> {{'club.TEAM_REPORT_VIEW_REPORT_TITLE' | translate}} {{currentViewTeamReport.value.tem_report_against_name}} </h4>

        <button (click)="cancelViewTeamReport()" type="button" mat-icon-button color="accent" mat-button><mat-icon>undo</mat-icon></button> 
    </div>

    <ng-container [ngTemplateOutlet]="reportTemplate" [ngTemplateOutletContext]="{
        context: formContext,
        form: currentViewTeamReport,
        isReadOnly: true
    }">
    </ng-container>
</ng-container>


<div class="mt125 mb125">
    <button [disabled]="busy || internalBusy" (click)="showAverageReport()" type="button" mat-raised-button color="primary" mat-button>{{'club.TEAM_REPORT_SHOW_AVERAGE_REPORT' | translate}}</button>   
</div>

<ng-container *ngIf="currentAveragePlayersMinutesReportArr && currentAveragePlayersMinutesReportArr.length != 0">
    <h4> {{'club.TEAM_REPORT_AVERAGE_REPORT_TITLE' | translate}} </h4>

    <div>
        <mat-grid-list cols="2" rowHeight="55px">
            <mat-grid-tile                                            
            [colspan]="1"
            [rowspan]="1"
            [style.background]="'#F2F2F2'">
                <h5>{{'club.TEAM_REPORT_PLAYER_NAME' | translate}}</h5>                                          
            </mat-grid-tile>
            <mat-grid-tile                                            
            [colspan]="1"
            [rowspan]="1"
            [style.background]="'#F2F2F2'">
                <h5>{{'club.TEAM_REPORT_AVERAGE_REPORT_MINUTES' | translate}}</h5>                       
            </mat-grid-tile>
        </mat-grid-list>    

        <mat-grid-list cols="2" rowHeight="55px" *ngFor="let playerData of currentAveragePlayersMinutesReportArr">
            <mat-grid-tile                                            
            [colspan]="1"
            [rowspan]="1"
            [style.background]="'#F2F2F2'">
                <span>{{ appUtils.getFullnameById(playersArr, playerData._id) }}</span>                                          
            </mat-grid-tile>
            <mat-grid-tile                                            
            [colspan]="1"
            [rowspan]="1"
            [style.background]="'#F2F2F2'">
                <span>{{playerData.minutes | number : '1.1-1'}}</span>
            </mat-grid-tile>
        </mat-grid-list>  
        
        <mat-grid-list cols="2" rowHeight="55px">
            <mat-grid-tile                                            
            [colspan]="1"
            [rowspan]="1"
            [style.background]="'#F2F2F2'">
                <h5>{{'club.TEAM_REPORT_TOTAL_MINUTES' | translate}}</h5>                                          
            </mat-grid-tile>
            
            <mat-grid-tile                                            
            [colspan]="1"
            [rowspan]="1"
            [style.background]="'#F2F2F2'">
                {{ getTotalMinutesFromArr(currentAveragePlayersMinutesReportArr) | number : '1.1-1' }}
            </mat-grid-tile>
        </mat-grid-list>        
    </div>
</ng-container>













    <ng-template #reportTemplate let-context="context" let-form="form" let-isReadOnly="isReadOnly">

        <form novalidate class="basic-form" [formGroup]="form">
        
            <mat-form-field class="w45">
                <input readonly #formDate matInput [matDatepicker]="dpStarted" placeholder="{{'club.TEAM_REPORT_MATCH_DATE' | translate}}"
                    formControlName="tem_report_match_date">
                <mat-datepicker-toggle [disabled]="isReadOnly" matSuffix [for]="dpStarted"></mat-datepicker-toggle>
                <mat-datepicker #dpStarted></mat-datepicker>
            </mat-form-field>        
    
            <mat-form-field class="w45">
                <input [readonly]="isReadOnly" formControlName="tem_report_against_name" matInput placeholder="{{'club.TEAM_REPORT_MATCH_AGAINST' | translate}}">
            </mat-form-field>           
    
            <mat-form-field class="w45">
                <input [readonly]="isReadOnly" formControlName="tem_report_result" matInput placeholder="{{'club.TEAM_REPORT_MATCH_RESULT' | translate}}">
            </mat-form-field>           
    
            <mat-grid-list cols="2" rowHeight="55px">
                <mat-grid-tile                                            
                [colspan]="1"
                [rowspan]="1"
                [style.background]="'#F2F2F2'">
                    <h5>{{'club.TEAM_REPORT_PLAYER_NAME' | translate}}</h5>                                          
                </mat-grid-tile>
                <mat-grid-tile                                            
                [colspan]="1"
                [rowspan]="1"
                [style.background]="'#F2F2F2'">
                    <h5>{{'club.TEAM_REPORT_MINUTES' | translate}}</h5>                       
                </mat-grid-tile>
            </mat-grid-list>    
    
            <div formArrayName="tem_report_players_minutes_arr" *ngFor="let control of form.get('tem_report_players_minutes_arr').controls; let i = index; let last = last;">
                <div [formGroupName]="i">
    
                    <mat-grid-list cols="2" rowHeight="55px">
                        <mat-grid-tile                                            
                        [colspan]="1"
                        [rowspan]="1"
                        [style.background]="'#F2F2F2'">
                            <span>{{ appUtils.getFullnameById(playersArr, control.value._id) }}</span>                                          
                        </mat-grid-tile>
                        <mat-grid-tile                                            
                        [colspan]="1"
                        [rowspan]="1"
                        [style.background]="'#F2F2F2'">
    
                            <mat-form-field class="w45">
                                <input type="text" digitOnly [readonly]="isReadOnly" formControlName="minutes" matInput placeholder="">
                            </mat-form-field>                         
                        
                        </mat-grid-tile>
                    </mat-grid-list>    
    
                </div>
            </div>
            <mat-grid-list cols="2" rowHeight="55px">
                <mat-grid-tile                                            
                [colspan]="1"
                [rowspan]="1"
                [style.background]="'#F2F2F2'">
                    <h5>{{'club.TEAM_REPORT_TOTAL_MINUTES' | translate}}</h5>                                          
                </mat-grid-tile>
                
                <mat-grid-tile                                            
                [colspan]="1"
                [rowspan]="1"
                [style.background]="'#F2F2F2'">
                    {{ getTotalMinutesFromArr(form.value.tem_report_players_minutes_arr) | number : '1.0-0' }}
                </mat-grid-tile>
            </mat-grid-list>    
    
        </form>

    </ng-template>   
     
