<div class="antro-form">
    <mat-expansion-panel disabled expanded>
        <mat-expansion-panel-header>
            <!-- <mat-panel-title class="font-18 font-bold">
                אימונים ומאזן אנרגיה
            </mat-panel-title> -->
            <mat-panel-title class="font-16 font-bold">
                <h4 class="m-auto">אימונים</h4>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row mb125">
            <button class="plus-button" mat-raised-button (click)="newQuizGuard()">
                {{'general.newquiz' | translate}}
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <div *ngIf="oldValues.length > 0" class="big-content">
            <!-- <div class="row mb125">
                <mat-form-field *ngIf="oldValues.length > 0" class="w30">
                    <mat-select [(ngModel)]="selectedIdA" (ngModelChange)="selectDateA($event)">
                        <ng-container *ngFor="let val of oldValues; let i = index">
                            <mat-option [value]="val._id">
                                {{val['createdAt'] | date:'dd/MM/yyyy' }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div> -->

            <div class="row mb125">
                <mat-form-field *ngIf="oldValues.length > 0" class="w40">
                    <!-- <button type="button" *ngIf="selectedA" matSuffix [routerLink]="['/Practicequiz/', selectedIdA]" class="top-button" mat-stroked-button>עבור לטופס</button> -->
                    <a (click)="$event.stopPropagation()" target="quizwindow" *ngIf="selectedA" matSuffix [routerLink]="['/Practicequiz/', selectedIdA]" class="top-button" mat-stroked-button>עבור לטופס</a>
                    <mat-select [disabled]="true" [(ngModel)]="selectedIdA" (ngModelChange)="selectDateA($event)">
                        <ng-container *ngFor="let val of oldValues; let i = index">
                            <mat-option *ngIf="selectedIdB != val._id" [value]="val._id">
                                {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <button *ngIf="oldValues.length > 1" mat-stroked-button color="primary" class="w15 wrap-text"
                    (click)="fetchOldValues(true)">אפס לשתי המדידות האחרונות</button>

                <mat-form-field *ngIf="oldValues.length > 1" class="w40">
                    <!-- <button type="button" *ngIf="selectedB" matSuffix [routerLink]="['/Practicequiz/', selectedIdB]" class="top-button" mat-stroked-button>עבור לטופס</button> -->
                    <a (click)="$event.stopPropagation()" target="quizwindow" *ngIf="selectedB" matSuffix [routerLink]="['/Practicequiz/', selectedIdB]" class="top-button" mat-stroked-button>עבור לטופס</a>
                    <mat-select [(ngModel)]="selectedIdB" (ngModelChange)="selectDateB($event)">
                        <ng-container *ngFor="let val of oldValues; let i = index">
                            <mat-option *ngIf="selectedIdA != val._id" [value]="val._id">
                                {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <h3>אימונים</h3>
            <div class="row">
                <div class="w40" *ngIf="selectedA">

                    <p *ngIf="selectedA['prq_day_off_practice']">
                        <span class="font-bold">{{ selectedA['prq_day_off_practice'] }}</span> יום חופש אחד בשבוע
                    </p>

                    <p *ngIf="selectedA['prq_practice_avg']">
                        סה&quot;כ דק אימון עצמאי ליום בממוצע: <span class="font-bold">{{ selectedA['prq_practice_avg'] }}</span>
                    </p>
                    <!-- <p *ngIf="selectedA['prq_longest']">
                        משך האימון הארוך ביותר: <span class="font-bold">{{ selectedA['prq_longest'] }} דק</span>
                    </p>
                    <p *ngIf="selectedA['prq_match_avg']">
                        ממוצע דק משחק: <span class="font-bold">{{ selectedA['prq_match_avg'] }}</span>
                    </p> -->
                    <div class="mb125"
                        *ngIf="selectedA['prq_extra_practice_arr'] && selectedA['prq_extra_practice_arr'].length">
                        <p class="font-16">סוגי אימונים אישיים (METs)</p>
                        <ul>
                            <li *ngFor="let practice of selectedA['prq_extra_practice_arr']">
                                <div>
                                    {{practice.practice_type}}{{practice.practice_type_other_details ? ' - '+practice.practice_type_other_details : ''}},
                                    ({{ practice.practice_type | practiceMet : practiceTypes }}),
                                    {{practice.practice_length}} דקות, {{practice.practice_frequency}} פעמים בשבוע.
                                </div>
                                <div class="mb50 font-bold font-12" *ngIf="practice.practice_remarks">
                                    {{practice.practice_remarks}}</div>
                            </li>
                        </ul>
                    </div>
                    <p *ngIf="selectedA['prq_extra_practice'] === 'לא'" class="font-bold">
                        לא מתאמן באופן עצמאי
                    </p>
                    
                    <div class="mb125"
                        *ngIf="selectedA['prq_team_practice_arr'] && selectedA['prq_team_practice_arr'].length">
                        <p class="font-16">סוגי אימונים קבוצתיים (METs)</p>
                        <ul>
                            <li *ngFor="let practice of selectedA['prq_team_practice_arr']">
                                <div>
                                    {{practice.tem_practice_type}}{{practice.tem_practice_type_other_text ? ' - '+practice.tem_practice_type_other_text : ''}},
                                    ({{ practice.tem_practice_type | practiceMet : practiceTypes }}),
                                    {{practice.tem_practice_duration_minutes}} דקות,
                                    {{practice.tem_practice_times_week}} פעמים בשבוע.
                                </div>
                                <!-- <div class="mb50 font-bold font-12" *ngIf="practice.practice_remarks">{{practice.practice_remarks}}</div> -->
                            </li>
                        </ul>
                    </div>
                    <!-- <div class="mb50"
                        *ngIf="selectedA['prq_practice_timing_days'] && selectedA['prq_practice_timing_days'].length">
                        <p>יש ימים שהאימונים הם מיד אחרי בית הספר</p>
                        <div *ngFor="let day of weekDays">
                            <mat-checkbox disabled class="checkbox"
                                [checked]="selectedA['prq_practice_timing_days'].includes(day.value)">{{day.name}}
                            </mat-checkbox>
                        </div>
                    </div> -->
                    <!-- <notes *ngIf="notesFormA.value._id" [notesArr]="notesList['notes_prq_a']"
                        [selectedForm]="notesFormA" [fieldName]="'notes_prq_a'"></notes> -->

                    <div class="mb125">
                        <div *ngIf="selectedA['prq_strength_practice'] === 'כן'">
                            <div *ngIf="selectedA['prq_strength_practice_type_group']" class="mb75 font-bold">
                                מבצע אימוני כושר במסגרת קבוצתית
                            </div>
                            <div *ngIf="selectedA['prq_strength_practice_type_alone']" class="mb75 font-bold">
                                מבצע אימוני כושר עצמאית
                            </div>
                            <div *ngIf="selectedA['prq_strength_practice_plan']" class="mb50">
                                {{ selectedA['prq_strength_practice_plan'] === 'אחר' ? selectedA['prq_strength_practice_plan_other'] : selectedA['prq_strength_practice_plan'] }} 
                                את תוכנית הכוח
                            </div>
                            <!-- <ng-container *ngIf="selectedA['prq_strength_practice_type_alone']">
                                <div class="mb50 font-bold">
                                    מבצע אימוני כושר עצמאית
                                </div>
                                <div *ngIf="selectedA['prq_strength_practice_plan']" class="mb50">
                                    {{ selectedA['prq_strength_practice_plan'] === 'אחר' ? selectedA['prq_strength_practice_plan_other'] : selectedA['prq_strength_practice_plan'] }} 
                                    את תוכנית הכוח
                                </div>
                            </ng-container> -->
                            <!-- <p *ngIf="!selectedA['prq_strength_practice_type_alone']" class="font-bold">
                                לא מתאמן באופן עצמאי
                            </p> -->
                        </div>
                        <p *ngIf="selectedA['prq_strength_practice'] === 'לא'" class="font-bold">
                            לא מבצע אימוני כושר
                        </p>

                    </div>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    
                    <p *ngIf="selectedB['prq_day_off_practice']">
                        <span class="font-bold">{{ selectedB['prq_day_off_practice'] }}</span> יום חופש אחד בשבוע
                    </p>

                    <p *ngIf="selectedB['prq_practice_avg']">
                        סה&quot;כ דק אימון עצמאי ליום בממוצע: <span class="font-bold">{{ selectedB['prq_practice_avg'] }}</span>
                    </p>
                    <!-- <p *ngIf="selectedB['prq_longest']">
                        משך האימון הארוך ביותר: <span class="font-bold">{{ selectedB['prq_longest'] }} דק</span>
                    </p>
                    <p *ngIf="selectedB['prq_match_avg']">
                        ממוצע דק משחק: <span class="font-bold">{{ selectedB['prq_match_avg'] }}</span>
                    </p> -->
                    <div class="mb125"
                        *ngIf="selectedB['prq_extra_practice_arr'] && selectedB['prq_extra_practice_arr'].length">
                        <p class="font-16">סוגי אימונים אישיים (METs)</p>
                        <ul>
                            <li *ngFor="let practice of selectedB['prq_extra_practice_arr']">
                                <div>
                                    {{practice.practice_type}}{{practice.practice_type_other_details ? ' - '+practice.practice_type_other_details : ''}},
                                    ({{ practice.practice_type | practiceMet : practiceTypes }}),
                                    {{practice.practice_length}} דקות, {{practice.practice_frequency}} פעמים בשבוע.
                                </div>
                                <div class="mb50 font-bold font-12" *ngIf="practice.practice_remarks">
                                    {{practice.practice_remarks}}</div>
                            </li>
                        </ul>
                    </div>
                    <p *ngIf="selectedB['prq_extra_practice'] === 'לא'" class="font-bold">
                        לא מתאמן באופן עצמאי
                    </p>
                    
                    <div class="mb125"
                        *ngIf="selectedB['prq_team_practice_arr'] && selectedB['prq_team_practice_arr'].length">
                        <p class="font-16">סוגי אימונים קבוצתיים (METs)</p>
                        <ul>
                            <li *ngFor="let practice of selectedB['prq_team_practice_arr']">
                                <div>
                                    {{practice.tem_practice_type}}{{practice.tem_practice_type_other_text ? ' - '+practice.tem_practice_type_other_text : ''}},
                                    ({{ practice.tem_practice_type | practiceMet : practiceTypes }}),
                                    {{practice.tem_practice_duration_minutes}} דקות,
                                    {{practice.tem_practice_times_week}} פעמים בשבוע.
                                </div>
                                <!-- <div class="mb50 font-bold font-12" *ngIf="practice.practice_remarks">{{practice.practice_remarks}}</div> -->
                            </li>
                        </ul>
                    </div>
                    <!-- <notes *ngIf="notesFormB.value._id" [notesArr]="notesList['notes_prq_a']"
                        [selectedForm]="notesFormB" [fieldName]="'notes_prq_a'"></notes> -->

                    <div class="mb125">
                        <div *ngIf="selectedB['prq_strength_practice'] === 'כן'">
                            <div *ngIf="selectedB['prq_strength_practice_type_group']" class="mb75 font-bold">
                                מבצע אימוני כושר במסגרת קבוצתית
                            </div>
                            <div *ngIf="selectedB['prq_strength_practice_type_alone']" class="mb75 font-bold">
                                מבצע אימוני כושר עצמאית
                            </div>
                            <div *ngIf="selectedB['prq_strength_practice_plan']" class="mb50">
                                {{ selectedB['prq_strength_practice_plan'] === 'אחר' ? selectedB['prq_strength_practice_plan_other'] : selectedB['prq_strength_practice_plan'] }} 
                                את תוכנית הכוח
                            </div>
                            <!-- <ng-container *ngIf="selectedB['prq_strength_practice_type_alone']">
                                <div class="mb50 font-bold">
                                    מבצע אימוני כושר עצמאית
                                </div>
                                <div *ngIf="selectedB['prq_strength_practice_plan']" class="mb50">
                                    {{ selectedB['prq_strength_practice_plan'] === 'אחר' ? selectedB['prq_strength_practice_plan_other'] : selectedB['prq_strength_practice_plan'] }} 
                                    את תוכנית הכוח
                                </div>
                            </ng-container> -->
                            <!-- <p *ngIf="!selectedB['prq_strength_practice_type_alone']" class="font-bold">
                                לא מתאמן באופן עצמאי
                            </p> -->
                        </div>
                        <p *ngIf="selectedB['prq_strength_practice'] === 'לא'" class="font-bold">
                            לא מבצע אימוני כושר
                        </p>

                    </div>
                </div>
            </div>

            <h4 class="ul-text">הערות מאזן אנרגיה</h4>
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('prq_notes')" fieldKey="A" [oldNotes]="notesFormA.get('notes_prq_a')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'prq', 'A')" (editNoteEvent)="editNote($event, 'prq', 'A')"></notes>
                </div>
                
                <div class="w40" *ngIf="selectedB">
                    <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('prq_notes')" fieldKey="A" [oldNotes]="notesFormB.get('notes_prq_a')" [timeCreated]="selectedB.createdAt"></notes>
                </div>
            </div>

            <br>
            <!-- <h3></h3> -->
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <p *ngIf="selectedA['prq_longest_practice']">
                        משך האימון הארוך ביותר: <span class="font-bold">{{ selectedA['prq_longest_practice'] }} דק</span>
                    </p>
                    <p *ngIf="selectedA['prq_longest_match']">
                        משך המשחק הארוך ביותר: <span class="font-bold">{{ selectedA['prq_longest_match'] }} דק</span>
                    </p>
                    <p *ngIf="selectedA['prq_match_avg']">
                        ממוצע דק משחק: <span class="font-bold">{{ selectedA['prq_match_avg'] }}</span>
                    </p>
                    <!-- <p *ngIf="selectedA['prq_morning_practice'] == 'כן'">
                        <span class="font-bold">יש אימוני בוקר</span>
                    </p>
                    <p *ngIf="selectedA['prq_morning_practice'] == 'לא'">
                        <span class="font-bold">אין אימוני בוקר</span>
                    </p> -->
                    <p *ngIf="selectedA['prq_morning_practice'] && selectedA['prq_morning_practice_days'] && selectedA['prq_morning_practice_days'].length">
                        יש אימוני בוקר בימים 
                        <ng-container *ngFor="let day of weekDays">
                            <span class="day-span font-bold" *ngIf="selectedA['prq_morning_practice_days'].includes(day.value)">
                                {{ day.name }}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="selectedA['prq_earliest_practice_time']">
                            בשעה 
                            <span class="font-bold">
                                {{ selectedA['prq_earliest_practice_time'] }}
                            </span>
                        </ng-container>
                    </p>
                    
                    <div class="mb50" *ngIf="selectedA['prq_morning_practice'] === 'כן' && !selectedA['prq_morning_practice_days']?.length">
                        <div class="mb50">
                            יש אימוני בוקר בימים: 
                        </div>
                        <ng-container *ngFor="let day of weekDays">
                            <div *ngIf="selectedA['prq_morning_practice_time_' + day.value]" class="mb50">
                                <span class="font-bold m-r-10 m-l-10">{{ day.name }}</span>
                                <span>{{ selectedA['prq_morning_practice_time_' + day.value] }}</span>
                            </div>
                        </ng-container>
                    </div>
                    <p class="font-bold"
                        *ngIf="selectedA['prq_morning_practice'] === 'לא'">
                        אין אימוני בוקר
                    </p>
                    
                    <!-- <p *ngIf="selectedA['prq_day_off_practice']">
                        <span class="font-bold">{{ selectedA['prq_day_off_practice'] }}</span> יום חופש אחד בשבוע
                    </p> -->
                    <p class=""
                        *ngIf="selectedA['prq_practice_timing'] && selectedA['prq_practice_timing_days'] && selectedA['prq_practice_timing_days'].length">
                        ימים שהאימונים הם מיד אחרי בית הספר ואיני מספיק לאכול ארוחת צהרים בבית: 
                        <ng-container *ngFor="let day of weekDays">
                            <span class="day-span font-bold" *ngIf="selectedA['prq_practice_timing_days'].includes(day.value)">
                                {{ day.name }}
                            </span>
                        </ng-container>
                    </p>
                    <p class="font-bold"
                        *ngIf="selectedA['prq_practice_timing'] && selectedA['prq_practice_timing_days'] && selectedA['prq_practice_timing_days'].length == 0">
                        אין ימים שהאימונים הם מיד אחרי בית הספר ואיני מספיק לאכול ארוחת צהרים בבית
                    </p>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <p *ngIf="selectedB['prq_longest_practice']">
                        משך האימון הארוך ביותר: <span class="font-bold">{{ selectedB['prq_longest_practice'] }} דק</span>
                    </p>
                    <p *ngIf="selectedB['prq_longest_match']">
                        משך המשחק הארוך ביותר: <span class="font-bold">{{ selectedB['prq_longest_match'] }} דק</span>
                    </p>
                    <p *ngIf="selectedB['prq_match_avg']">
                        ממוצע דק משחק: <span class="font-bold">{{ selectedB['prq_match_avg'] }}</span>
                    </p>
                    <!-- <p *ngIf="selectedB['prq_morning_practice'] == 'כן'">
                        <span class="font-bold">יש אימוני בוקר</span>
                    </p>
                    <p *ngIf="selectedB['prq_morning_practice'] == 'לא'">
                        <span class="font-bold">אין אימוני בוקר</span>
                    </p> -->
                    <p *ngIf="selectedB['prq_morning_practice'] && selectedB['prq_morning_practice_days'] && selectedB['prq_morning_practice_days'].length">
                        יש אימוני בוקר בימים 
                        <ng-container *ngFor="let day of weekDays">
                            <span class="day-span font-bold" *ngIf="selectedB['prq_morning_practice_days'].includes(day.value)">
                                {{ day.name }}
                            </span>
                        </ng-container>
                        <ng-container *ngIf="selectedB['prq_earliest_practice_time']">
                            בשעה 
                            <span class="font-bold">
                                {{ selectedB['prq_earliest_practice_time'] }}
                            </span>
                        </ng-container>
                    </p>

                    <div class="mb50" *ngIf="selectedB['prq_morning_practice'] === 'כן' && !selectedB['prq_morning_practice_days']?.length">
                        <div class="mb50">
                            יש אימוני בוקר בימים: 
                        </div>
                        <ng-container *ngFor="let day of weekDays">
                            <div *ngIf="selectedB['prq_morning_practice_time_' + day.value]" class="mb50">
                                <span class="font-bold m-r-10 m-l-10">{{ day.name }}</span>
                                <span>{{ selectedB['prq_morning_practice_time_' + day.value] }}</span>
                            </div>
                        </ng-container>
                    </div>
                    <p class="font-bold"
                        *ngIf="selectedB['prq_morning_practice'] === 'לא'">
                        אין אימוני בוקר
                    </p>
                    
                    <!-- <p *ngIf="selectedB['prq_day_off_practice']">
                        <span class="font-bold">{{ selectedB['prq_day_off_practice'] }}</span> יום חופש אחד בשבוע
                    </p> -->
                    <p class=""
                        *ngIf="selectedB['prq_practice_timing'] && selectedB['prq_practice_timing_days'] && selectedB['prq_practice_timing_days'].length">
                        ימים שהאימונים הם מיד אחרי בית הספר ואיני מספיק לאכול ארוחת צהרים בבית: 
                        <ng-container *ngFor="let day of weekDays">
                            <span class="day-span font-bold" *ngIf="selectedB['prq_practice_timing_days'].includes(day.value)">
                                {{ day.name }}
                            </span>
                        </ng-container>
                    </p>
                    <p class="font-bold"
                        *ngIf="selectedB['prq_practice_timing'] && selectedB['prq_practice_timing_days'] && selectedB['prq_practice_timing_days'].length == 0">
                        אין ימים שהאימונים הם מיד אחרי בית הספר ואיני מספיק לאכול ארוחת צהרים בבית
                    </p>
                </div>
            </div>

            <br>
            <!-- <h3></h3> -->
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <p *ngIf="selectedA['prq_drink_in_practice'] && selectedA['prq_drink_in_practice'] !== '0'">
                        <span>
                            אני שותה כ- 
                        </span>
                        <span class="font-bold">
                            {{ selectedA['prq_drink_in_practice'] }}
                        </span>
                        <span>
                             ליטר במהלך אימון
                        </span>
                    </p>
                    <p *ngIf="selectedA['prq_drink_in_practice'] === '0'" class="font-bold">
                        בקושי שותה במהלך אימון
                    </p>
                    
                    <div *ngIf="selectedA['prq_isotonic_drinks'] === 'כן'" class="mb50">
                        <div class="">שותה משקאות איזוטוניים: </div>
                        <div *ngIf="selectedA['prq_isotonic_drinks_time_arr']">
                            <span class="font-bold"> {{ selectedA['prq_isotonic_drinks_time_arr'].join(', ') }}</span>
                        </div>
                    </div>
                    <p *ngIf="selectedA['prq_isotonic_drinks'] === 'לא'" class="font-bold">
                        לא שותה משקאות איזוטוניים
                    </p>
                </div>

                <div class="w40" *ngIf="selectedB">
                    <p *ngIf="selectedB['prq_drink_in_practice'] && selectedB['prq_drink_in_practice'] !== '0'">
                        <span>
                            אני שותה כ- 
                        </span>
                        <span class="font-bold">
                            {{ selectedB['prq_drink_in_practice'] }}
                        </span>
                        <span>
                             ליטר במהלך אימון
                        </span>
                    </p>
                    <p *ngIf="selectedB['prq_drink_in_practice'] === '0'" class="font-bold">
                        בקושי שותה במהלך אימון
                    </p>
                    
                    <div *ngIf="selectedB['prq_isotonic_drinks'] === 'כן'" class="mb50">
                        <div class="">שותה משקאות איזוטוניים: </div>
                        <div *ngIf="selectedB['prq_isotonic_drinks_time_arr']">
                            <span class="font-bold"> {{ selectedB['prq_isotonic_drinks_time_arr'].join(', ') }}</span>
                        </div>
                    </div>
                    <p *ngIf="selectedB['prq_isotonic_drinks'] === 'לא'" class="font-bold">
                        לא שותה משקאות איזוטוניים
                    </p>
                </div>
            </div>

            <br>
            <!-- <h3></h3> -->
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <div *ngIf="selectedA['prq_eat_before_practice'] === 'תמיד' || selectedA['prq_eat_before_practice'] === 'בדרך כלל' || selectedA['prq_eat_before_practice'] === 'לעיתים רחוקות'" class="mb50">
                        <div class="">
                            <span>{{ selectedA['prq_eat_before_practice'] }} </span>
                            <span>אני אוכל בשעה שלפני אימון: </span>
                        </div>
                        <div *ngIf="selectedA['prq_eat_before_practice_items_arr']">
                            <span class="font-bold"> {{ selectedA['prq_eat_before_practice_items_arr'].filter(otherFilter).join(', ') }}</span>
                            <div *ngIf="selectedA['prq_eat_before_practice_items_arr'].includes('אחר')">
                                {{ selectedA.prq_eat_before_practice_other }}
                            </div>
                        </div>
                    </div>
                    <p *ngIf="selectedA['prq_eat_before_practice'] === 'אף פעם'" class="font-bold">
                        לא אוכל לפני אימון
                    </p>
                    
                    <div *ngIf="selectedA['prq_eat_on_breaks'] === 'כן'" class="mb50">
                        <div class="">בהפסקות של משחקים או בין אימונים אני אוכל: </div>
                        <div *ngIf="selectedA['prq_eat_on_breaks_items_arr']">
                            <span class="font-bold"> {{ selectedA['prq_eat_on_breaks_items_arr'].filter(otherFilter).join(', ') }}</span>
                            <div *ngIf="selectedA['prq_eat_on_breaks_items_arr'].includes('אחר')">
                                {{ selectedA.prq_eat_on_breaks_other }}
                            </div>
                        </div>
                    </div>
                    <p *ngIf="selectedA['prq_eat_on_breaks'] === 'לא'" class="font-bold">
                        לא אוכל בהפסקות בין אימונים או משחקים
                    </p>
                </div>

                <div class="w40" *ngIf="selectedB">
                    <div *ngIf="selectedB['prq_eat_before_practice'] === 'תמיד' || selectedB['prq_eat_before_practice'] === 'בדרך כלל' || selectedB['prq_eat_before_practice'] === 'לעיתים רחוקות'" class="mb50">
                        <div class="">
                            <span>{{ selectedB['prq_eat_before_practice'] }} </span>
                            <span>אני אוכל בשעה שלפני אימון: </span>
                        </div>
                        <div *ngIf="selectedB['prq_eat_before_practice_items_arr']">
                            <span class="font-bold"> {{ selectedB['prq_eat_before_practice_items_arr'].filter(otherFilter).join(', ') }}</span>
                            <div *ngIf="selectedB['prq_eat_before_practice_items_arr'].includes('אחר')">
                                {{ selectedB.prq_eat_before_practice_other }}
                            </div>
                        </div>
                    </div>
                    <p *ngIf="selectedB['prq_eat_before_practice'] === 'אף פעם'" class="font-bold">
                        לא אוכל לפני אימון
                    </p>
                    
                    <div *ngIf="selectedB['prq_eat_on_breaks'] === 'כן'" class="mb50">
                        <div class="">בהפסקות של משחקים או בין אימונים אני אוכל: </div>
                        <div *ngIf="selectedB['prq_eat_on_breaks_items_arr']">
                            <span class="font-bold"> {{ selectedB['prq_eat_on_breaks_items_arr'].filter(otherFilter).join(', ') }}</span>
                            <div *ngIf="selectedB['prq_eat_on_breaks_items_arr'].includes('אחר')">
                                {{ selectedB.prq_eat_on_breaks_other }}
                            </div>
                        </div>
                    </div>
                    <p *ngIf="selectedB['prq_eat_on_breaks'] === 'לא'" class="font-bold">
                        לא אוכל בהפסקות בין אימונים או משחקים
                    </p>
                </div>
            </div>

            <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <h4 class="ul-text">הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('prq_notes')" fieldKey="B" [oldNotes]="notesFormA.get('notes_prq_b')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'prq', 'B')" (editNoteEvent)="editNote($event, 'prq', 'B')"></notes>
                    </div>
                    
                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('prq_notes')" fieldKey="B" [oldNotes]="notesFormB.get('notes_prq_b')" [timeCreated]="selectedB.createdAt"></notes>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's2', subPrefix: '', title: 'הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון' }"></ng-container>
            </ng-container>

            <!-- <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS3">

            </ng-container>
            
            <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS4">

            </ng-container>
            
            <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS5">

            </ng-container>
            
            <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS6">

            </ng-container>
            
            <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS7">

            </ng-container>
            
            <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS8">

            </ng-container>
            
            <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS9">

            </ng-container> -->
            
            <!-- <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: '', title: 'הערות תזונה כלליות' }"></ng-container>
            </ng-container> -->
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's4', subPrefix: '', title: 'הערות ארוחת בוקר' }"></ng-container>
            </ng-container>
            <!-- <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's5', subPrefix: '', title: 'הערות ארוחת ביניים 1' }"></ng-container>
            </ng-container> -->
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's6', subPrefix: '', title: 'הערות ארוחת צהריים' }"></ng-container>
            </ng-container>
            <!-- <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's7', subPrefix: '', title: 'הערות ארוחת ביניים 2' }"></ng-container>
            </ng-container> -->
            <!-- <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's8', subPrefix: '', title: 'הערות ארוחת ערב' }"></ng-container>
            </ng-container> -->
            <!-- <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's9', subPrefix: '', title: 'הערות ארוחת לילה' }"></ng-container>
            </ng-container> -->
            <!-- <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's10', subPrefix: '', title: 'הערות נוזלים ללא אימון' }"></ng-container>
            </ng-container> -->
            
            <div class="row">
                <h3 class="w40 d-flex">
                    <mat-checkbox *ngIf="selectedA" [formControl]="notesFormA.get('prq_show_calories')" class="m-l-10"></mat-checkbox>
                    <span *ngIf="selectedA">מאזן אנרגיה</span>
                </h3>
                <h3 class="w40 d-flex">
                    <mat-checkbox *ngIf="selectedB" [formControl]="notesFormB.get('prq_show_calories')" class="m-l-10"></mat-checkbox>
                    <span *ngIf="selectedB">מאזן אנרגיה</span>
                </h3>
            </div>
            <!-- <h3>מאזן אנרגיה</h3> -->
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <p *ngIf="selectedA['prq_daily_rmr']">
                        חילוף חומרים במנוחה: <span class="font-bold">{{ selectedA['prq_daily_rmr'] }}</span>
                    </p>
                    <p *ngIf="selectedA['prq_daily_no_practice']">
                        הוצאה קלורית יומית ללא אימון: <span class="font-bold">{{ selectedA['prq_daily_no_practice'] }}</span>
                    </p>
                    <p *ngIf="selectedA['prq_daily_kcal'] || selectedA['prq_daily_kcal']===0">
                        הוצאה קלורית יומית ממוצעת עבור האימונים: <span class="font-bold">{{ selectedA['prq_daily_kcal'] }}</span>
                    </p>
                    <p *ngIf="selectedA['prq_daily_no_practice'] || selectedA['prq_daily_kcal']">
                        הוצאה קלורית כוללת: <span class="font-bold">{{ ((+selectedA['prq_daily_no_practice'] || 0) + (+selectedA['prq_daily_kcal'] || 0)) }}</span>
                    </p>
                    <div *ngIf="notesFormA.value._id && (selectedA['prq_daily_no_practice'] || selectedA['prq_daily_kcal'])" class="row">
                        <p class="font-bold">
                            הפרש קלורי רצוי:
                        </p>
                        <div class="kcal-diff">
                            <mat-form-field>
                                <mat-select [formControl]="notesFormA.get('notes_prq_c')">
                                    <mat-option value=''>---</mat-option>
                                    <mat-option value="+500"><div class="dir-ltr">+500</div></mat-option>
                                    <mat-option value="+400"><div class="dir-ltr">+400</div></mat-option>
                                    <mat-option value="+300"><div class="dir-ltr">+300</div></mat-option>
                                    <mat-option value="+200"><div class="dir-ltr">+200</div></mat-option>
                                    <mat-option value="+100"><div class="dir-ltr">+100</div></mat-option>
                                    <mat-option value="0"><div class="dir-ltr">0</div></mat-option>
                                    <mat-option value="-100"><div class="dir-ltr">-100</div></mat-option>
                                    <mat-option value="-200"><div class="dir-ltr">-200</div></mat-option>
                                    <mat-option value="-300"><div class="dir-ltr">-300</div></mat-option>
                                    <mat-option value="-400"><div class="dir-ltr">-400</div></mat-option>
                                    <mat-option value="-500"><div class="dir-ltr">-500</div></mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <p *ngIf="notesFormA.value.notes_prq_c && notesFormA.value.notes_prq_c !== '' && (selectedA['prq_daily_no_practice'] || selectedA['prq_daily_kcal'])">
                        הכנסה קלורית מומלצת: <span class="font-bold">{{ (((+selectedA['prq_daily_no_practice'] || 0) + (+selectedA['prq_daily_kcal'] || 0)) + +(notesFormA.value.notes_prq_c)) }}</span>
                    </p>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <p *ngIf="selectedB['prq_daily_rmr']">
                        חילוף חומרים במנוחה: <span class="font-bold">{{ selectedB['prq_daily_rmr'] }}</span>
                    </p>
                    <p *ngIf="selectedB['prq_daily_no_practice']">
                        הוצאה קלורית יומית ללא אימון: <span class="font-bold">{{ selectedB['prq_daily_no_practice'] }}</span>
                    </p>
                    <p *ngIf="selectedB['prq_daily_kcal'] || selectedB['prq_daily_kcal']===0">
                        הוצאה קלורית יומית ממוצעת עבור האימונים: <span class="font-bold">{{ selectedB['prq_daily_kcal'] }}</span>
                    </p>
                    <p *ngIf="selectedB['prq_daily_no_practice'] || selectedB['prq_daily_kcal']">
                        הוצאה קלורית כוללת: <span class="font-bold">{{ ((+selectedB['prq_daily_no_practice'] || 0) + (+selectedB['prq_daily_kcal'] || 0)) }}</span>
                    </p>
                    <div *ngIf="notesFormB.value._id && (selectedB['prq_daily_no_practice'] || selectedB['prq_daily_kcal'])" class="row">
                        <p class="font-bold">
                            הפרש קלורי רצוי:
                        </p>
                        <div class="kcal-diff">
                            <mat-form-field>
                                <mat-select [readonly]="true" [formControl]="notesFormB.get('notes_prq_c')">
                                    <mat-option value=''>---</mat-option>
                                    <mat-option value="+500"><div class="dir-ltr">+500</div></mat-option>
                                    <mat-option value="+400"><div class="dir-ltr">+400</div></mat-option>
                                    <mat-option value="+300"><div class="dir-ltr">+300</div></mat-option>
                                    <mat-option value="+200"><div class="dir-ltr">+200</div></mat-option>
                                    <mat-option value="+100"><div class="dir-ltr">+100</div></mat-option>
                                    <mat-option value="0"><div class="dir-ltr">0</div></mat-option>
                                    <mat-option value="-100"><div class="dir-ltr">-100</div></mat-option>
                                    <mat-option value="-200"><div class="dir-ltr">-200</div></mat-option>
                                    <mat-option value="-300"><div class="dir-ltr">-300</div></mat-option>
                                    <mat-option value="-400"><div class="dir-ltr">-400</div></mat-option>
                                    <mat-option value="-500"><div class="dir-ltr">-500</div></mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <p *ngIf="notesFormB.value.notes_prq_c && notesFormB.value.notes_prq_c !== '' && (selectedB['prq_daily_no_practice'] || selectedB['prq_daily_kcal'])">
                        הכנסה קלורית מומלצת: <span class="font-bold">{{ (((+selectedB['prq_daily_no_practice'] || 0) + (+selectedB['prq_daily_kcal'] || 0)) + +(notesFormB.value.notes_prq_c)) }}</span>
                    </p>
                </div>
            </div>

            <!-- <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS1">

            </ng-container> -->

            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's1', subPrefix: '', title: 'הערות לתחילת הדוח' }"></ng-container>
            </ng-container>

            <ng-template #templateShared let-fieldKey="fieldKey" let-subPrefix="subPrefix" let-title="title">
                <h4 class="font-bold text-info ul-text">{{ title }}</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && notesFormA && sharedNotesFormA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [fieldKey]="fieldKey" [subKey]="subPrefix" [isFemale]="(user.usr_gender === 'נקבה')" [selectedGroup]="notesFormA.get(quizPrefix + '_notes')" [sharedGroup]="sharedNotesFormA.get('fdq_notes')" [notesArr]="notesList" (addNoteEvent)="addNote($event, quizPrefix, fieldKey, subPrefix)" (editNoteEvent)="editNote($event, quizPrefix, fieldKey, subPrefix)"></notes>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="selectedB._id" [fieldKey]="fieldKey" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="selectedB[quizPrefix + '_notes']"></notes>
                    </div>
                </div>                
            </ng-template>

            <ng-template #templateS1>
                <h4 class="font-bold text-info ul-text">הערות לתחילת הדוח</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s1" (addNoteEvent)="addNote($event, quizPrefix, 's1')" (editNoteEvent)="editNote($event, quizPrefix, 's1')"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s1" (addNoteEvent)="addNote($event, quizPrefix, 's1')" (editNoteEvent)="editNote($event, quizPrefix, 's1')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s1" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s1"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s1"></notes>
                    </div>
                </div>                
            </ng-template>
            <ng-template #templateS2>
                <h4 class="font-bold text-info ul-text">הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s2" (addNoteEvent)="addNote($event, quizPrefix, 's2')" (editNoteEvent)="editNote($event, quizPrefix, 's2')"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s2" (addNoteEvent)="addNote($event, quizPrefix, 's2')" (editNoteEvent)="editNote($event, quizPrefix, 's2')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s2" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s2"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s2"></notes>
                    </div>
                </div>                
            </ng-template>
            <ng-template #templateS3>
                <h4 class="font-bold text-info ul-text">הערות תזונה כלליות</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s3" (addNoteEvent)="addNote($event, quizPrefix, 's3')" (editNoteEvent)="editNote($event, quizPrefix, 's3')"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s3" (addNoteEvent)="addNote($event, quizPrefix, 's3')" (editNoteEvent)="editNote($event, quizPrefix, 's3')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s3" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s3"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s3"></notes>
                    </div>
                </div>                
            </ng-template>
            <ng-template #templateS4>
                <h4 class="font-bold text-info ul-text">הערות ארוחת בוקר</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s4" (addNoteEvent)="addNote($event, quizPrefix, 's4')" (editNoteEvent)="editNote($event, quizPrefix, 's4')"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s4" (addNoteEvent)="addNote($event, quizPrefix, 's4')" (editNoteEvent)="editNote($event, quizPrefix, 's4')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s4" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s4"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s4"></notes>
                    </div>
                </div>                
            </ng-template>
            <ng-template #templateS5>
                <h4 class="font-bold text-info ul-text">הערות ארוחת ביניים 1</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s5" (addNoteEvent)="addNote($event, quizPrefix, 's5')" (editNoteEvent)="editNote($event, quizPrefix, 's5')"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s5" (addNoteEvent)="addNote($event, quizPrefix, 's5')" (editNoteEvent)="editNote($event, quizPrefix, 's5')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s5" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s5"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s5"></notes>
                    </div>
                </div>                
            </ng-template>
            <ng-template #templateS6>
                <h4 class="font-bold text-info ul-text">הערות ארוחת צהריים</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s6" (addNoteEvent)="addNote($event, quizPrefix, 's6')" (editNoteEvent)="editNote($event, quizPrefix, 's6')"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s6" (addNoteEvent)="addNote($event, quizPrefix, 's6')" (editNoteEvent)="editNote($event, quizPrefix, 's6')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s6" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s6"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s6"></notes>
                    </div>
                </div>                
            </ng-template>
            <ng-template #templateS7>
                <h4 class="font-bold text-info ul-text">הערות ארוחת ביניים 2</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s7" (addNoteEvent)="addNote($event, quizPrefix, 's7')" (editNoteEvent)="editNote($event, quizPrefix, 's7')"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s7" (addNoteEvent)="addNote($event, quizPrefix, 's7')" (editNoteEvent)="editNote($event, quizPrefix, 's7')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s7" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s7"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s7"></notes>
                    </div>
                </div>                
            </ng-template>
            <ng-template #templateS8>
                <h4 class="font-bold text-info ul-text">הערות ארוחת ערב</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s8" (addNoteEvent)="addNote($event, quizPrefix, 's8')" (editNoteEvent)="editNote($event, quizPrefix, 's8')"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s8" (addNoteEvent)="addNote($event, quizPrefix, 's8')" (editNoteEvent)="editNote($event, quizPrefix, 's8')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s8" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s8"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s8"></notes>
                    </div>
                </div>                
            </ng-template>
            <ng-template #templateS9>
                <h4 class="font-bold text-info ul-text">הערות ארוחת לילה</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s9" (addNoteEvent)="addNote($event, quizPrefix, 's9')" (editNoteEvent)="editNote($event, quizPrefix, 's9')"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s9" (addNoteEvent)="addNote($event, quizPrefix, 's9')" (editNoteEvent)="editNote($event, quizPrefix, 's9')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s9" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s9"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s9"></notes>
                    </div>
                </div>                
            </ng-template>
            <ng-template #templateS10>
                <h4 class="font-bold text-info ul-text">הערות נוזלים ללא אימון</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s10" (addNoteEvent)="addNote($event, quizPrefix, 's10')" (editNoteEvent)="editNote($event, quizPrefix, 's10')"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s10" (addNoteEvent)="addNote($event, quizPrefix, 's10')" (editNoteEvent)="editNote($event, quizPrefix, 's10')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s10" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s10"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s10"></notes>
                    </div>
                </div>                
            </ng-template>

            <div class="row">
                <div class="w40 buttons-row" *ngIf="selectedA">
                    <button class="plus-button" mat-raised-button (click)="deleteQuiz(selectedIdA)">
                        {{'general.deleteQuiz' | translate}}
                        <mat-icon>delete</mat-icon>
                    </button>
                    <!-- <button [routerLink]="['/Practicequiz/', selectedIdA]" class="plus-button" mat-raised-button>עבור לטופס</button> -->
                    <a target="quizwindow" [routerLink]="['/Practicequiz/', selectedIdA]" class="plus-button" mat-raised-button>עבור לטופס</a>
                    <mat-slide-toggle [checked]="notesFormA.value.prq_locked" (change)="toggleQuizLock($event, notesFormA)">{{'general.lockQuiz' | translate}}</mat-slide-toggle>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">
                </div> -->
                <div class="w40 buttons-row" *ngIf="selectedB">
                    <button class="plus-button" mat-raised-button (click)="deleteQuiz(selectedIdB)">
                        {{'general.deleteQuiz' | translate}}
                        <mat-icon>delete</mat-icon>
                    </button>
                    <!-- <button [routerLink]="['/Practicequiz/', selectedIdB]" class="plus-button" mat-raised-button>עבור לטופס</button> -->
                    <a target="quizwindow" [routerLink]="['/Practicequiz/', selectedIdB]" class="plus-button" mat-raised-button>עבור לטופס</a>
                    <mat-slide-toggle [checked]="notesFormB.value.prq_locked" (change)="toggleQuizLock($event, notesFormB)">{{'general.lockQuiz' | translate}}</mat-slide-toggle>
                </div>
            </div>

            <!-- <div class="mt125 text-center">
                <button mat-raised-button color="primary" class="w30" (click)="submitForm()">שמור הערות</button>
            </div> -->
        </div>
    </mat-expansion-panel>



</div>
