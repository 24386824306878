import { ChangeDetectionStrategy, Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

export interface DialogData {
  cancelText: string,
  confirmText: string,
  message: string,
  title: string,
  errorMessage?: string,
  errorMessageBottom?: string,
}

@Component({
changeDetection: ChangeDetectionStrategy.OnPush,
selector: 'app-confirm-dialog',
template: `
<h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content>
    <div class="mat-body text-center text-bold text-danger mb125" *ngIf="data.errorMessage">{{data.errorMessage}}</div>
    <div class="mat-body mb125">{{data.message}}</div>
    <div class="mat-body text-center text-bold text-danger mb125" *ngIf="data.errorMessageBottom">{{data.errorMessageBottom}}</div>
  </div>
  <div mat-dialog-actions>
    <button *ngIf="data.cancelText" mat-button (click)="cancel()">{{data.cancelText}}</button>
    <button *ngIf="data.confirmText" mat-button (click)="confirm()" cdkFocusInitial>{{data.confirmText}}</button>
  </div>
`,
})

export class ConfirmDialogComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private mdDialogRef: MatDialogRef<ConfirmDialogComponent>) { }
  public cancel() {
    this.close(false);
  }
  public close(value) {
    this.mdDialogRef.close(value);
  }
  public confirm() {
    this.close(true);
  }
  @HostListener("keydown.esc") 
  public onEsc() {
    this.close(false);
  }
}
