import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';


export interface DialogData {
    title: string;
    subtitle: string;
    accept: string;
    not_accept: string;
    value: string;
    ok: string;
    cancel: string;
  }

@Component({
    template: `
    <h1 mat-dialog-title>{{data.title}}</h1>
      <div>
        <div class="mat-body mb125">{{data.subtitle}}</div>
        <div class="mb125">
          <mat-radio-group [(ngModel)]="radioValue">
            <mat-radio-button value="מאשר">{{data.accept}}</mat-radio-button>
            <mat-radio-button value="לא מאשר">{{data.not_accept}}</mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <div mat-dialog-actions>
        <button mat-button (click)="onNoClick()">{{data.cancel}}</button>
        <button mat-button [disabled]="!radioValue" [mat-dialog-close]="radioValue" cdkFocusInitial>{{data.ok}}</button>
      </div>
    `,
  })
  
  export class ShareReportsDialogComponent {
  
    radioValue: string;

    constructor(
      public dialogRef: MatDialogRef<ShareReportsDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: DialogData) {
        this.radioValue = data.value;
      }
  
      
    onNoClick(): void {
      this.dialogRef.close();
    }
  
  }