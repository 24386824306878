<div class="usernotes-container">
    <div *ngIf="!user" class="row m-0">
        <h1>ניהול התראות</h1>
    </div>
    
    <mat-card *ngIf="busy">
        <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
        </div>
    </mat-card>

    <div *ngIf="query" [hidden]="busy">
        <vzn-data-table
            #tableEl
            [restService]="notesRestService"
            [hidePaginator]="true"
            [pageSize]="9999"
            [columns]="columns"
            [query]="query"
            (loading)="tableLoading($event)"
        >
        </vzn-data-table>
    </div>
</div>

<ng-template #noteTypeTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    {{ quizNoteTypeOptions[row.quiz_prefix][value] }}
</ng-template>

<ng-template #contentHoverTmpl let-value="value" ngx-datatable-cell-template>
    <div
        (click)="tooltip.toggle()"
        #tooltip="matTooltip"
        [matTooltip]="value"
        matTooltipClass="font-16"
        aria-label="Content info"
        class="pointer"
    >
        {{ value && value.length > 25 ? (value | slice: 0:20) + '...' : value }}
    </div>
</ng-template>

<ng-template #checkTmpl let-value="value" ngx-datatable-cell-template>
    <mat-icon class="userstable-status-icon valid" *ngIf="value">check</mat-icon>
</ng-template>

<ng-template #daysTmpl let-value="value" ngx-datatable-cell-template>
    <ng-container *ngIf="value && value.length > 0">
        <span *ngFor="let day of value; let last = last">
            {{ weekDays[day] }}
            <ng-container *ngIf="!last">, </ng-container>
        </span>
    </ng-container>
</ng-template>

<ng-template #freqTmpl let-value="value" ngx-datatable-cell-template>
    {{ noteFreq[value] }}
</ng-template>

<ng-template #isActiveTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <button mat-icon-button (click)="changeGuard(row, !row.is_active)" [disabled]="busyChange">
        <mat-icon>{{ row.is_active ? 'notifications_active' : 'notifications_off' }}</mat-icon>
    </button>
</ng-template>
