// import { appState } from '@services/app-state';

export module Apputils {

    export enum FlutterActions {
        GET_TOKEN = "getToken",
        SHOW_BARCODE_SCANNER = "showBarcodeScanner",
    }

    export enum FlutterResultCode {
        SUCCESS = "SUCCESS",
        ERROR = "ERROR",
    }

    export enum UserTypes {
        Admin = "admin",
        Nutri = "nutri",
        Coach = "coach",
        Player = "player"
        // more roles possible
    }

    export interface ResultJS {
        action: string;
        result: string;
        error: string;
    }

    export let printResultJS = (resultJS: Apputils.ResultJS) => {
        console.log("action: " + resultJS.action + " result: " + resultJS.result + " error: " + resultJS.error);
    }

    export let flutterCallbacks = {};
    
    export let initFlutterBroadcast = () => {
        console.log("initFlutterBroadcast called");        
        Apputils.flutterCallbacks = {};

        window["broadcastToJS"] = function(json: string) {
            console.log("broadcastToJS called from Flutter.");
            let decodedJson = JSON.parse(json);
            Object.keys(Apputils.flutterCallbacks).forEach(key => {
                if (Apputils.flutterCallbacks[key] != undefined && Apputils.flutterCallbacks[key] != null && typeof(Apputils.flutterCallbacks[key]) === 'function') {
                    console.log("Calling: " + key + " with param: " + String(json));
                    Apputils.flutterCallbacks[key](decodedJson as Apputils.ResultJS);
                }
            });

            return "ok";
        }
    }

    export let registerFlutterCallback = (key: string, callback: Function) => {
        Apputils.flutterCallbacks[key] = callback;
    }

    export let unRegisterFlutterCallback = (key: string) => {
        Apputils.flutterCallbacks[key] = null;
        delete Apputils.flutterCallbacks[key];
    }

    export let flutterAction = (param) => {
        console.log("flutterAction");
        if (Apputils.isInNativeFlutter()) {
            window["AAPJSAction"].postMessage(param);
        } else {
            console.log('not running inside a Flutter webview');
        }        
    }

    export let isInNativeFlutter = () => {
        return window["AAPJSAction"] !== undefined;
    }

    export let getSuffix = (fileName) => {
        return fileName.split('.').pop();
    }
    export let getFileName = (fullPath) => {
        return fullPath.replace(/^.*[\\\/]/, '')
    }

    export let getPracticeDurations = (): Array<string> => {
        let arr: Array<string> = [];
        for (let i = 5; i <= 180; i += 5) {
            arr.push(i.toString());
        }
        return arr;
    }

    export let roundToQuarter = (num: number) => {
        let integerSegment = Math.trunc(num);
        let decimalSegment = num - integerSegment;

        decimalSegment = Math.round(decimalSegment * 4) / 4;

        return (integerSegment + decimalSegment);
    }

    export let getUsersByIdsArr = (sourceArray: Array<any>, userIdsArr: Array<any>) => {
        return userIdsArr.map((userId) => Apputils.getUserById(sourceArray, userId)).filter((user: any) => user?._id);
    }

    export let getFullnameById = (usersArr: Array<any>, id: string) => {
        let user: any = Apputils.getUserById(usersArr, id);
        return (user.usr_fname || '') + " " + (user.usr_lname || '');
    }

    export let getUserById = (usersArr: Array<any>, id: string): Object => {
        let res: Array<any> = usersArr.filter((element) => element._id === id);
        return res.length == 0 ? {} : res[0];
    }

    export let practiceTypes = [
        { id: 1, title: 'אימון כדורסל', met: 6, hideTeam: false },
        { id: 2, title: 'אימון אישי', met: 6, hideTeam: true },
        { id: 3, title: 'זריקות לסל', met: 4.5, hideTeam: false },
        { id: 4, title: 'משחק בשכונה', met: 4.5, hideTeam: true },
        { id: 5, title: 'חדר כוח', met: 3, hideTeam: false },
        { id: 6, title: 'אימון אתלטיקה', met: 6, hideTeam: false },
        { id: 7, title: 'אימון פונקציונלי', met: 4, hideTeam: false },
        { id: 21, title: 'משחק כדורסל', met: 8, hideTeam: false },
        { id: 8, title: `ריצה 10 קמ"ש- 6 דק'/ק"מ`, met: 10.5, hideTeam: false },
        { id: 9, title: `ריצה 10.9 קמ"ש- 5.5 דק'/ק"מ`, met: 11.4, hideTeam: false },
        { id: 10, title: `ריצה 12 קמ"ש- 5 דק'/ק"מ`, met: 12.4, hideTeam: false },
        { id: 11, title: `ריצה 13.5 קמ"ש- 4.5 דק'/ק"מ`, met: 13.7, hideTeam: false },
        { id: 12, title: `ריצה 15 קמ"ש- 4 דק'/ק"מ`, met: 15.3, hideTeam: false },
        { id: 13, title: 'פילטיס', met: 3, hideTeam: false },
        { id: 14, title: 'טניס- אימון סרווים', met: 3, hideTeam: false },
        { id: 15, title: 'טניס- אימון', met: 7, hideTeam: false },
        { id: 16, title: 'טניס- משחק יחידים', met: 8, hideTeam: false },
        { id: 17, title: 'טניס- משחק זוגות', met: 5.5, hideTeam: false },
        { id: 18, title: 'כדורגל- אימון', met: 7, hideTeam: false },
        { id: 19, title: 'כדורגל- משחק', met: 10, hideTeam: false },
        { id: 20, title: 'כדורעף- אימון', met: 4.5, hideTeam: false },
        { id: 22, title: 'כדוריד- אימון', met: 8, hideTeam: false },
        { id: 23, title: 'כדוריד- משחק', met: 12, hideTeam: false },
    ];
    // based on: https://www.healthdirect.gov.au/urine-colour-chart
    export let urineHexColorPlate = [
        "#FCFCF0",
        "#FBFADA",
        "#F7F5BE",
        "#EEED96",
        "#E0D97A",
        "#DAC851",
        "#CCB04F",
        "#AE9644",
    ]

    export let allHours = [
        '00:00',
        '00:15',
        '00:30',
        '00:45',
        '01:00',
        '01:15',
        '01:30',
        '01:45',
        '02:00',
        '02:15',
        '02:30',
        '02:45',
        '03:00',
        '03:15',
        '03:30',
        '03:45',
        '04:00',
        '04:15',
        '04:30',
        '04:45',
        '05:00',
        '05:15',
        '05:30',
        '05:45',
        '06:00',
        '06:15',
        '06:30',
        '06:45',
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00',
        '12:15',
        '12:30',
        '12:45',
        '13:00',
        '13:15',
        '13:30',
        '13:45',
        '14:00',
        '14:15',
        '14:30',
        '14:45',
        '15:00',
        '15:15',
        '15:30',
        '15:45',
        '16:00',
        '16:15',
        '16:30',
        '16:45',
        '17:00',
        '17:15',
        '17:30',
        '17:45',
        '18:00',
        '18:15',
        '18:30',
        '18:45',
        '19:00',
        '19:15',
        '19:30',
        '19:45',
        '20:00',
        '20:15',
        '20:30',
        '20:45',
        '21:00',
        '21:15',
        '21:30',
        '21:45',
        '22:00',
        '22:15',
        '22:30',
        '22:45',
        '23:00',
        '23:15',
        '23:30',
        '23:45',
    ];


    export let sleepingHours = [
        '20:00',
        '20:15',
        '20:30',
        '20:45',
        '21:00',
        '21:15',
        '21:30',
        '21:45',
        '22:00',
        '22:15',
        '22:30',
        '22:45',
        '23:00',
        '23:15',
        '23:30',
        '23:45',
        '00:00',
        '00:15',
        '00:30',
        '00:45',
        '01:00',
        '01:15',
        '01:30',
        '01:45',
        '02:00',
        '02:15',
        '02:30',
        '02:45',
        '03:00',
        '03:15',
        '03:30',
        '03:45',
        '04:00',
        '04:15',
        '04:30',
        '04:45',
        '05:00',
        '05:15',
        '05:30',
        '05:45',
        '06:00',
        '06:15',
        '06:30',
        '06:45',
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00',
        '12:15',
        '12:30',
        '12:45',
        '13:00',
        '13:15',
        '13:30',
        '13:45',
        '14:00',
        '14:15',
        '14:30',
        '14:45',
        '15:00',
        '15:15',
        '15:30',
        '15:45',
        '16:00',
        '16:15',
        '16:30',
        '16:45',
        '17:00',
        '17:15',
        '17:30',
        '17:45',
        '18:00',
        '18:15',
        '18:30',
        '18:45',
        '19:00',
        '19:15',
        '19:30',
        '19:45',
    ];

    export let wakingHours = [
        '05:00',
        '05:15',
        '05:30',
        '05:45',
        '06:00',
        '06:15',
        '06:30',
        '06:45',
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00',
        '12:15',
        '12:30',
        '12:45',
        '13:00',
        '13:15',
        '13:30',
        '13:45',
        '14:00',
        '14:15',
        '14:30',
        '14:45',
        '15:00',
        '15:15',
        '15:30',
        '15:45',
        '16:00',
        '16:15',
        '16:30',
        '16:45',
        '17:00',
        '17:15',
        '17:30',
        '17:45',
        '18:00',
        '18:15',
        '18:30',
        '18:45',
        '19:00',
        '19:15',
        '19:30',
        '19:45',
        '20:00',
        '20:15',
        '20:30',
        '20:45',
        '21:00',
        '21:15',
        '21:30',
        '21:45',
        '22:00',
        '22:15',
        '22:30',
        '22:45',
        '23:00',
        '23:15',
        '23:30',
        '23:45',
        '00:00',
        '00:15',
        '00:30',
        '00:45',
        '01:00',
        '01:15',
        '01:30',
        '01:45',
        '02:00',
        '02:15',
        '02:30',
        '02:45',
        '03:00',
        '03:15',
        '03:30',
        '03:45',
        '04:00',
        '04:15',
        '04:30',
        '04:45',
    ];

    export let schoolStartHours = [
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00',
        '12:15',
        '12:30',
        '12:45',
        '13:00',
        '13:15',
        '13:30',
        '13:45',
        '14:00',
        '14:15',
        '14:30',
        '14:45',
        '15:00',
        '15:15',
        '15:30',
        '15:45',
        '16:00',
        '16:15',
        '16:30',
        '16:45',
        '17:00',
        '17:15',
        '17:30',
        '17:45',
        '18:00',
        '18:15',
        '18:30',
        '18:45',
        '19:00',
        '19:15',
        '19:30',
        '19:45',
        '20:00',
        '20:15',
        '20:30',
        '20:45',
        '21:00',
        '21:15',
        '21:30',
        '21:45',
        '22:00',
        '22:15',
        '22:30',
        '22:45',
        '23:00',
        '23:15',
        '23:30',
        '23:45',
        '00:00',
        '00:15',
        '00:30',
        '00:45',
        '01:00',
        '01:15',
        '01:30',
        '01:45',
        '02:00',
        '02:15',
        '02:30',
        '02:45',
        '03:00',
        '03:15',
        '03:30',
        '03:45',
        '04:00',
        '04:15',
        '04:30',
        '04:45',
        '05:00',
        '05:15',
        '05:30',
        '05:45',
        '06:00',
        '06:15',
        '06:30',
        '06:45',
    ];

    export let schoolEndHours = [
        '11:00',
        '11:15',
        '11:30',
        '11:45',
        '12:00',
        '12:15',
        '12:30',
        '12:45',
        '13:00',
        '13:15',
        '13:30',
        '13:45',
        '14:00',
        '14:15',
        '14:30',
        '14:45',
        '15:00',
        '15:15',
        '15:30',
        '15:45',
        '16:00',
        '16:15',
        '16:30',
        '16:45',
        '17:00',
        '17:15',
        '17:30',
        '17:45',
        '18:00',
        '18:15',
        '18:30',
        '18:45',
        '19:00',
        '19:15',
        '19:30',
        '19:45',
        '20:00',
        '20:15',
        '20:30',
        '20:45',
        '21:00',
        '21:15',
        '21:30',
        '21:45',
        '22:00',
        '22:15',
        '22:30',
        '22:45',
        '23:00',
        '23:15',
        '23:30',
        '23:45',
        '00:00',
        '00:15',
        '00:30',
        '00:45',
        '01:00',
        '01:15',
        '01:30',
        '01:45',
        '02:00',
        '02:15',
        '02:30',
        '02:45',
        '03:00',
        '03:15',
        '03:30',
        '03:45',
        '04:00',
        '04:15',
        '04:30',
        '04:45',
        '05:00',
        '05:15',
        '05:30',
        '05:45',
        '06:00',
        '06:15',
        '06:30',
        '06:45',
        '07:00',
        '07:15',
        '07:30',
        '07:45',
        '08:00',
        '08:15',
        '08:30',
        '08:45',
        '09:00',
        '09:15',
        '09:30',
        '09:45',
        '10:00',
        '10:15',
        '10:30',
        '10:45',
    ];
}


