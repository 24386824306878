<div class="full-loader" *ngIf="busy">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>

<div *ngIf="query != undefined" [ngClass]="{'loading-margin': busy}">
    <div class="fixed-buttons">
        <button type="button" mat-fab color="accent" [disabled]="!chosenIds.length" (click)="openPushDialog()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <div class="row m-0">
        <h1>
            <span>טבלת משתמשים</span>
            <span>
                <button type="button" class="m-l-5 m-r-5" mat-icon-button (click)="fileUpload.click()">
                    <mat-icon>file_upload</mat-icon>
                </button>
                <input #fileUpload type="file" accept=".xlsx, .xls, .csv" (change)="onChangeFileUpload($event)" style="display: none;">
            </span>
        </h1>
        <div class="centered-row m-0" *ngIf="chosenIds.length">
            <button type="button" class="m-l-5 m-r-5" mat-stroked-button (click)="exportCsvPopup()">
                CSV
                <mat-icon>file_download</mat-icon>
            </button>
            <button type="button" class="m-l-5 m-r-5" mat-stroked-button (click)="newQuizzesPopup()">
                יצירת שאלונים
                <mat-icon>add</mat-icon>
            </button>
            <button type="button" class="m-l-5 m-r-5" mat-stroked-button (click)="newMeasureReport()">
                דוח מדידות
                <mat-icon>addchart</mat-icon>
            </button>
            <button type="button" class="m-l-5 m-r-5" mat-stroked-button (click)="measurementPopup()">
                הוספת תאריך מדידה
            </button>
        </div>
        <div class="centered-row m-0" *ngIf="chosenIds.length">
            <div class="mat-body text-danger font-bold m-l-20">
                {{ chosenIds.length }} 
                משתמשים נבחרו
            </div>
            <button type="button" class="plus-button" mat-raised-button (click)="unselectAll()">
                ביטול הכל
                <mat-icon>remove</mat-icon>
            </button>
        </div>
    </div>
    <vzn-data-table #tableEl [restService]="usersRestService" [pageSize]="50" [columns]="columns" (rowDblClick)="onRowDblClick($event)" [query]="query" (loading)="tableLoading($event)">
    </vzn-data-table>

    <div style="display: flex; flex-wrap: wrap; margin: 20px 0;">
        
        <div class="font-14" style="margin: 20px 0; flex-basis: 30%;">
            <div class="mb75 font-bold">
                סטטוס
            </div>
            <div class="mb50">
                <span class="invalid-color m-r-10">
                    לא נרשם
                </span>
                <span>
                     - לא השלים את תהליך ההרשמה ⚠️
                </span>
            </div>
            <div class="mb50">
                <span class="invalid-color m-r-10">
                    הורה לא נרשם
                </span>
                <span>
                     - הורה לא השלים הרשמה ולא אישר תקנון ⚠️
                </span>
            </div>
            <div class="mb50">
                <span class="valid-color m-r-10">(1/1) 2</span>
                <span> - סיים למלא שאלונים לשלב זה</span>
            </div>
            <div class="mb50">
                <span class="invalid-color m-r-10">(0/1) 2</span>
                <span> - לא סיים למלא שאלונים לשלב זה</span>
            </div>
            <div class="mb50">
                <mat-icon class="userstable-status-icon valid">check</mat-icon>
                <span> השאלונים נעולים</span>
            </div>
        </div>

        <div class="font-14" style="margin: 20px 0; flex-basis: 40%;">
            <div class="mb75 font-bold">
                בדיקות דם
            </div>
            <div class="mb50">
                <mat-icon class="userstable-status-icon valid">check</mat-icon>
                <span class="valid-color"> (dd/mm/yyyy) </span>
                <span> - שאלון בדיקות דם הוזן (תאריך הבדיקה)</span>
            </div>
            <div class="mb50">
                <mat-icon class="userstable-status-icon invalid">check</mat-icon>
                <span class="invalid-color"> (dd/mm/yyyy) </span>
                <span> - שאלון בדיקות דם לא הוזן (תאריך הבדיקה)</span>
            </div>
            <div class="mb50">
                <span class="invalid-color m-r-10">N/A</span>
                <span> - עשה בדיקות בעבר אבל לא העלה תוצאות</span>
            </div>
            <div class="mb50">
                <mat-icon class="userstable-status-icon valid">clear</mat-icon>
                <span> - לא עשה בדיקות דם בעבר</span>
            </div>
            <div class="mb50">
                <span class="m-r-10">5</span>
                <span> - נשלחה המלצה לבדיקות דם (מספר הימים שעברו מאז ההמלצה)</span>
            </div>
            <div class="mb50">
                <mat-icon class="userstable-status-icon invalid">priority_high</mat-icon>
                <span class="invalid-color"> (5) </span>
                <span> - העלה בדיקת דם ממתין להתייחסות (מספר הימים שעברו מהעלאת התוצאות)</span>
            </div>
            <div class="mb50">
                <mat-icon class="userstable-status-icon invalid">clear</mat-icon>
                <span> - נשלחה המלצה לפני יותר מ- 100 ימים אך לא ביצע בדיקות</span>
            </div>
            <div class="mb50">
                <span class="m-r-10">ריק</span>
                <span> - לא השלים שאלון בדיקות דם ראשוני</span>
            </div>
        </div>
        
        <div class="font-14" style="margin: 20px 0; flex-basis: 30%;">
            <div class="mb75 font-bold">
                שליחת הודעות / שיתוף
            </div>
            <div class="mb50">
                <mat-icon class="userstable-status-icon">notifications_off</mat-icon>
                <span> לא אישר שליחת הודעות</span>
            </div>
            <div class="mb50">
                <mat-icon class="userstable-status-icon">unsubscribe</mat-icon>
                <span> הורה לא אישר שליחת הודעות</span>
            </div>
            <div class="mb50">
                <mat-icon class="userstable-status-icon">visibility_off</mat-icon>
                <span> לא אישר שיתוף</span>
            </div>
            <div class="mb50">
                <mat-icon class="userstable-status-icon">stop_screen_share</mat-icon>
                <span> הורה לא אישר שיתוף</span>
            </div>
        </div>

    </div>
</div>

<ng-template #pushDialogTemplate let-form="form">
    <div class="basic-form mat-body" [formGroup]="form">

        <ng-container *ngIf="chosenIds.length">
            <div class="text-danger font-bold ul-text m-b-10">
                {{ chosenIds.length }} 
                משתמשים נבחרו
            </div>
            <div class="last-items-container m-b-10 m-r-10">
                <div class="m-b-5" *ngFor="let user of chosenUsers">
                    <span class="pointer font-12 font-bold" (click)="unselectRow(user)"> X </span>
                    <span class="font-italic">({{ user.usr_type_name || userTypes[user.usr_type] }})</span>
                     - 
                    {{user.usr_fname}} {{user.usr_lname}} - {{user.usr_phone}}
                </div>
            </div>
        </ng-container>
        
        <mat-radio-group class="m-b-10" formControlName="push_to_type">
            <mat-radio-button value="user">יוזר</mat-radio-button>
            <mat-radio-button value="parent">הורה</mat-radio-button>
        </mat-radio-group>

        <mat-form-field class="m-b-5">
            <input #pushTitle formControlName="push_title" matInput placeholder="כותרת ההתראה">
            <button type="button" matSuffix mat-icon-button [matMenuTriggerFor]="menuTitle">
                <mat-icon>more_vert</mat-icon>
            </button>
        </mat-form-field>
          
        <!-- <div class="centered-row m-b-10">
            <button type="button" mat-stroked-button color="accent" class="m-r-5 m-l-5" (click)="addText(form.controls.push_content, 'שם_פרטי')">שם פרטי</button>
            <button type="button" mat-stroked-button color="accent" class="m-r-5 m-l-5" (click)="addText(form.controls.push_content, 'שם_משפחה')">שם משפחה</button>
            <button *ngIf="form.value.push_to_type === 'parent'" type="button" mat-stroked-button color="accent" class="m-r-5 m-l-5" (click)="addText(form.controls.push_content, 'שם_פרטי_ילד')">שם פרטי ילד</button>
            <button *ngIf="form.value.push_to_type === 'parent'"  type="button" mat-stroked-button color="accent" class="m-r-5 m-l-5" (click)="addText(form.controls.push_content, 'שם_משפחה_ילד')">שם משפחה ילד</button>
        </div> -->

        <mat-form-field class="m-b-5">
            <textarea #pushContent formControlName="push_content" matInput cdkTextareaAutosize cdkAutosizeMinRows="3" placeholder="תוכן ההתראה"></textarea>
            <button type="button" matSuffix mat-icon-button [matMenuTriggerFor]="menuContent">
                <mat-icon>more_vert</mat-icon>
            </button>
        </mat-form-field>

        <mat-menu #menuTitle="matMenu">
            <button mat-menu-item (click)="addText(pushTitle, form.controls.push_title, 'שם_פרטי')">
                <span>שם פרטי</span>
            </button>
            <button mat-menu-item (click)="addText(pushTitle, form.controls.push_title, 'שם_משפחה')">
                <span>שם משפחה</span>
            </button>
            <button *ngIf="form.value.push_to_type === 'parent'" mat-menu-item (click)="addText(pushTitle, form.controls.push_title, 'שם_פרטי_ילד')">
                <span>שם פרטי ילד</span>
            </button>
            <button *ngIf="form.value.push_to_type === 'parent'" mat-menu-item (click)="addText(pushTitle, form.controls.push_title, 'שם_משפחה_ילד')">
                <span>שם משפחה ילד</span>
            </button>
        </mat-menu>

        <mat-menu #menuContent="matMenu">
            <button mat-menu-item (click)="addText(pushContent, form.controls.push_content, 'שם_פרטי')">
                <span>שם פרטי</span>
            </button>
            <button mat-menu-item (click)="addText(pushContent, form.controls.push_content, 'שם_משפחה')">
                <span>שם משפחה</span>
            </button>
            <button *ngIf="form.value.push_to_type === 'parent'" mat-menu-item (click)="addText(pushContent, form.controls.push_content, 'שם_פרטי_ילד')">
                <span>שם פרטי ילד</span>
            </button>
            <button *ngIf="form.value.push_to_type === 'parent'" mat-menu-item (click)="addText(pushContent, form.controls.push_content, 'שם_משפחה_ילד')">
                <span>שם משפחה ילד</span>
            </button>
        </mat-menu>

        <mat-checkbox class="m-b-5" formControlName="push_method_push">
            Push notification
        </mat-checkbox>
        <mat-checkbox *ngIf="appState.user.usr_type === 'superadmin'" class="m-b-5" formControlName="push_method_wap">
            Whatsapp message
        </mat-checkbox>
    </div>
</ng-template>

<ng-template #manualTemplate let-context="context" let-form="form">
    <div class="basic-form mat-body" [formGroup]="form">

        <div class="text-center mb125">
            <span class="font-bold"> {{form.value.userName}} </span>
            <span class="dl dir-ltr"> ({{form.value.currentPlan}}) </span>
        </div>

        <mat-form-field>
            <input type="text" digitOnly maxlength="2" inputmode="numeric" pattern="[0-9]*" formControlName="usr_report_count" matInput placeholder="מספר דוחות רצוי">
        </mat-form-field>
        <!-- <mat-form-field>
            <mat-select formControlName="usr_report_count" placeholder="מספר דוחות רצוי">
                <mat-option value="0">0</mat-option>
                <mat-option value="1">1</mat-option>
                <mat-option value="3">3</mat-option>
                <mat-option value="4">4</mat-option>
                <mat-option value="5">5</mat-option>
            </mat-select>
        </mat-form-field> -->

    </div>
</ng-template>

<ng-template #stateTemplate let-context="context" let-form="form">
    <div class="basic-form mat-body" [formGroup]="form">

        <div class="text-center mb125">
            <span class="font-bold"> {{form.value.userName}} </span>
            <span class="dl dir-ltr"> ({{form.value.currentPlan}}) </span>
        </div>

        <mat-card *ngIf="form.value.state">
            <div class="d-column" style="line-height: 35px;">

                <mat-checkbox *ngIf="form.value.state['heq']?.exist && !form.value.state['heq']?.locked" class="checkbox mb50" disabled [checked]="form.value.state['heq']?.done">
                    <!-- <button mat-stroked-button class="font-16">
                        {{'status.health' | translate}}
                    </button> -->
                    שאלון בריאות
                </mat-checkbox>
                
                <mat-checkbox *ngIf="form.value.state['prq']?.exist && !form.value.state['prq']?.locked" class="checkbox mb50" disabled [checked]="form.value.state['prq']?.done">
                    <!-- <button mat-stroked-button class="font-16">
                        {{'status.practice' | translate}}
                    </button> -->
                    אימונים ומאזן אנרגיה
                </mat-checkbox>
                
                <mat-checkbox *ngIf="form.value.state['slq']?.exist && !form.value.state['slq']?.locked" class="checkbox mb50" disabled [checked]="form.value.state['slq']?.done">
                    <!-- <button mat-stroked-button class="font-16">
                        {{'status.sleeping' | translate}}
                    </button> -->
                    הרגלי שינה
                </mat-checkbox>
                
                <mat-checkbox *ngIf="form.value.state['nuq']?.exist && !form.value.state['nuq']?.locked" class="checkbox mb50" disabled [checked]="form.value.state['nuq']?.done">
                    <!-- <button mat-stroked-button class="font-16">
                        {{'status.nutrition' | translate}}
                    </button> -->
                    הרגלי אכילה- שאלון תזונה
                </mat-checkbox>
                <ng-container *ngIf="form.value.state['btq']?.exist && !form.value.state['btq']?.locked">
                    <mat-checkbox class="checkbox mb50" disabled [checked]="form.value.state['btq_first']?.done || form.value.state['btq']?.done" *ngIf="(!form.value.state['btq_first']?.done && !form.value.state['btq']?.done) || (form.value.state['btq_first']?.done && form.value.state['btq']?.done)">
                        <!-- <button mat-stroked-button class="font-16">
                            {{'status.bloodtest_first' | translate}}
                        </button> -->
                        <!-- בדיקת דם ראשוני -->
                        בדיקת דם
                    </mat-checkbox>
                    <mat-checkbox class="checkbox mb50" disabled *ngIf="form.value.state['btq_first']?.done && !form.value.state['btq']?.done">
                        <!-- <button mat-stroked-button class="font-16">
                            {{'status.bloodtest' | translate}}
                        </button> -->
                        בדיקת דם
                    </mat-checkbox>
                </ng-container>

                <!-- <mat-checkbox *ngIf="form.value.state['fdq']?.exist && !form.value.state['fdq']?.locked" class="checkbox mb50" disabled [checked]="form.value.state['fdq']?.done">
                    יומן אכילה ולוז (3 ימים)
                </mat-checkbox> -->

                <mat-checkbox *ngIf="form.value.state['fdq']?.exist && !form.value.state['fdq']?.hidden && !form.value.state['fdq']?.locked" class="checkbox mb50" disabled [checked]="form.value.state['fdq'].done">
                    יומן אכילה ולוז (3 ימים)
                </mat-checkbox>

                <mat-checkbox *ngIf="form.value.state['measure_enabled'] && form.value.state['ntq']?.exist && !form.value.state['ntq']?.locked" class="checkbox mb75" disabled [checked]="form.value.state['ntq']?.done">
                    <!-- <button mat-stroked-button class="font-16">
                        {{'status.measurement' | translate}}
                    </button> -->
                    נתונים אנתרופומטריים
                </mat-checkbox>
                
            </div>
        </mat-card>

    </div>
</ng-template>

<ng-template #followTemplate let-context="context" let-form="form">
    <div class="basic-form mat-body" [formGroup]="form">

        <div class="text-center mb125">
            <span class="font-bold"> {{form.value.userName}} </span>
            <span class="dl dir-ltr"> ({{form.value.currentPlan}}) </span>
        </div>

        <div class="mb125" *ngIf="form.value.notes && allNotesUnfiltered">
            <notes [isFemale]="form.value.isFemale" [isFollowMode]="true" [isReadonly]="true" [allNotes]="allNotesUnfiltered" [selectedGroup]="form.get('notes')" fieldKey="A"></notes>
        </div>
    </div>
</ng-template>

<ng-template #newQuizzesTemplate let-form="form">
    <div class="basic-form mat-body mb75" [formGroup]="form">
        
        <ng-container *ngIf="chosenIds.length">
            <div class="text-danger font-bold ul-text m-b-10">
                {{ chosenIds.length }} 
                משתמשים נבחרו
            </div>
            <div class="last-items-container m-b-10 m-r-10">
                <div class="m-b-5" *ngFor="let user of chosenUsers">
                    <span class="pointer font-12 font-bold" (click)="unselectRow(user)"> X </span>
                    <span class="font-italic">({{ user.usr_type_name || userTypes[user.usr_type] }})</span>
                     - 
                    {{user.usr_fname}} {{user.usr_lname}} - {{user.usr_phone}}
                </div>
            </div>
        </ng-container>

        <!-- <div class="mb75">
            <input formControlName="due_date" matInput [matDatepicker]="dpInput" [min]="minDate" placeholder="תאריך יצירה">
            <mat-datepicker-toggle matSuffix [for]="dpInput"></mat-datepicker-toggle>
            <mat-datepicker #dpInput></mat-datepicker>
        </div> -->
        
        <div class="mt125">
            <mat-checkbox class="mb50 checkbox" formControlName="all">
                סט שאלונים ללא מדידת משתמש וללא בדיקות דם
            </mat-checkbox>
            <mat-checkbox class="mb50 checkbox" formControlName="ntq">
                שאלון מדידה (על ידי המשתמש)
            </mat-checkbox>
        </div>

        <!-- <div>
            <mat-checkbox class="mb50 checkbox" formControlName="ntq">אנתרופומטריים</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="prq">אימונים</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="slq">שינה</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="heq">בריאות</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="nuq">תזונה</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="fdq">יומן אכילה</mat-checkbox>
        </div> -->

        <div class="mt125">
            <mat-checkbox class="mb50 checkbox" formControlName="btq">
                המלצה על בדיקות דם חדשות
            </mat-checkbox>
            <mat-checkbox class="mb50 checkbox" formControlName="recommend">
                שליחת מייל המלצה
            </mat-checkbox>
        </div>
    </div>
</ng-template>

<ng-template #dateTimezoneTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngIf="value">{{ value | dateFormat : 'DD/MM/yyyy' }}</span>
</ng-template>

<ng-template #checkboxHdrTmpl let-prop="column.prop">
    <div class="pointer" (click)="checkboxHdrChange(!allChosen)">
        <mat-checkbox class="block-events" [indeterminate]="someChosen" [checked]="allChosen"></mat-checkbox>
    </div>
  </ng-template>

<ng-template #checkTmpl let-value="value" ngx-datatable-cell-template>
    <mat-icon class="userstable-status-icon valid" *ngIf="value">check</mat-icon>
</ng-template> 

<ng-template #checkboxTmpl let-value="value" let-row="row" ngx-datatable-cell-template>
    <mat-checkbox [checked]="chosenIds.includes(row._id)" (change)="selectRow($event, row)"></mat-checkbox>
</ng-template> 

<!-- <ng-template #daysTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'" (dblclick)="userStatusPopup($event, row)" class="pointer">
        <ng-container *ngIf="(value && value > -1) || value === 0">
            <mat-icon class="userstable-status-icon invalid">priority_high</mat-icon>
            <span>({{ value }})</span>
        </ng-container>
        <mat-icon class="userstable-status-icon valid" *ngIf="value == -1">check</mat-icon>
    </div>
</ng-template>  -->

<!-- <ng-template #editPlanTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'" (dblclick)="editPlanPopup($event, row)" class="pointer">
        <span *ngIf="!value">-</span>
        <mat-icon class="userstable-status-icon valid" *ngIf="value">check</mat-icon>
    </div>
</ng-template>  -->

<ng-template #lastNtqTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'" (dblclick)="newNtq($event, row)" class="pointer">
        <span *ngIf="!value">-</span>
        <span *ngIf="value">{{ value | dateFormat : 'DD/MM/yyyy' }}</span>
    </div>
</ng-template>

<!-- <ng-template #isFollowTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'" (dblclick)="isFollowPopup($event, row)" class="pointer">
        <span *ngIf="!value">-</span>
        <mat-icon class="userstable-status-icon valid" *ngIf="value">check</mat-icon>
    </div>
</ng-template> -->

<ng-template #iconsTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'">
        <div class="d-flex">
            <mat-icon *ngIf="row.usr_share_reports !== 'מאשר'">visibility_off</mat-icon>
            <mat-icon *ngIf="!row.usr_subscribe">notifications_off</mat-icon>
            <!-- <ng-container *ngIf="+(row.usr_age_formatted || 0) < 18">
                <mat-icon *ngIf="!row.usr_share_reports_parents">stop_screen_share</mat-icon>
                <mat-icon *ngIf="!row.usr_subscribe_parents">unsubscribe</mat-icon>
            </ng-container> -->
        </div>
        <div *ngIf="+(row.usr_age_formatted || 0) < 18" class="d-flex">
            <mat-icon *ngIf="!row.usr_share_reports_parents">stop_screen_share</mat-icon>
            <mat-icon *ngIf="!row.usr_subscribe_parents">unsubscribe</mat-icon>
        </div>
    </div>
</ng-template>

<ng-template #totalReportsTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'" (dblclick)="editPlanPopup($event, row)" class="pointer">
        <span class="dl dir-ltr">
            {{ value || 0 }} / {{ row['usr_report_count'] || 0 }}
        </span>
    </div>
</ng-template>

<ng-template #totalMeetingsTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player' && value">
        <span class="dl dir-ltr">
            {{ row.usr_previous_sessions?.length || 0 }} / {{ row.usr_sessions_total || 0 }}
        </span>
    </div>
</ng-template>

<ng-template #foodDaysTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="value && row && row.usr_type ==='player'">
        <span class="dl dir-ltr">
            {{ value }} / 3
        </span>
    </div>
</ng-template>

<ng-template #quizDaysTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'" (dblclick)="userStatusPopup($event, row)" class="pointer">
        <ng-container *ngIf="row.notAgreement">
            <span class="invalid-color">
                לא נרשם
            </span>
        </ng-container>
        <ng-container *ngIf="row.notAgreementParents">
            <span class="invalid-color">
                הורה לא נרשם
            </span>
        </ng-container>

        <span class="valid-color" *ngIf="row['quiz_saved'] == 3">
            <span> {{ value }} </span>
            <span *ngIf="row['usr_state'] && row['usr_state']['stateTable']" class="dl dir-ltr nowrap-text">
                ({{ row.usr_state.stateTable.done }} / {{ row.usr_state.stateTable.totalSeen }})
            </span>
        </span>

        <span class="invalid-color" *ngIf="row['quiz_saved'] == 2">
            <span> {{ value }} </span>
            <span *ngIf="row['usr_state'] && row['usr_state']['stateTable']" class="dl dir-ltr nowrap-text">
                ({{ row.usr_state.stateTable.done }} / {{ row.usr_state.stateTable.totalSeen }})
            </span>
        </span>

        <mat-icon class="userstable-status-icon valid" *ngIf="row['quiz_saved'] == 1">check</mat-icon>
    </div>
</ng-template>

<ng-template #quizDaysTmplOld let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'" (dblclick)="userStatusPopup($event, row)" class="pointer">
        <ng-container *ngIf="row['quiz_saved'] == 3">
            <mat-icon class="userstable-status-icon invalid">priority_high</mat-icon>
            <span>({{ value }})</span>
        </ng-container>
        <span *ngIf="row['quiz_saved'] == 2">
            {{ value }} 
            <!-- <span *ngIf="row['usr_state'] && row['usr_state']['stateUser']" class="dl dir-ltr nowrap-text">
                ({{ row.usr_state.stateUser.done }} / {{ row.usr_state.stateUser.totalSeen }})
            </span> -->
            <span *ngIf="row['usr_state'] && row['usr_state']['stateTable']" class="dl dir-ltr nowrap-text">
                ({{ row.usr_state.stateTable.done }} / {{ row.usr_state.stateTable.totalSeen }})
            </span>
            <!-- <span *ngIf="!isQa && row['usr_state'] && row['usr_state']['stateUser']" class="dl dir-ltr nowrap-text">
                ({{ row.usr_state.stateUser.done }} / {{ row.usr_state.stateUser.totalSeen }})
            </span>
            <span *ngIf="isQa && row['usr_state'] && row['usr_state']['stateUserQa']" class="dl dir-ltr nowrap-text">
                ({{ row.usr_state.stateUserQa.done }} / {{ row.usr_state.stateUserQa.totalSeen }})
            </span> -->
        </span>
        <mat-icon class="userstable-status-icon valid" *ngIf="row['quiz_saved'] == 1">check</mat-icon>
    </div>
</ng-template>

<ng-template #bloodDaysTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'">

        <ng-container *ngIf="row['blood_saved'] == 11">
            <mat-icon class="userstable-status-icon valid">check</mat-icon>
            <span class="valid-color" *ngIf="row['last_btq_date']">{{ row['last_btq_date'] | dateFormat : 'DD/MM/yyyy' }}</span>
        </ng-container>

        <ng-container *ngIf="row['blood_saved'] == 12">
            <mat-icon class="userstable-status-icon invalid">check</mat-icon>
            <span class="invalid-color" *ngIf="row['last_btq_date']">{{ row['last_btq_date'] | dateFormat : 'DD/MM/yyyy' }}</span>
        </ng-container>

        <ng-container *ngIf="row['blood_saved'] == 13">
            <span class="invalid-color">N/A</span>
        </ng-container>

        <ng-container *ngIf="row['blood_saved'] == 14">
            <mat-icon class="userstable-status-icon valid">clear</mat-icon>
        </ng-container>

        <ng-container *ngIf="row['blood_saved'] == 21">
            <mat-icon class="userstable-status-icon valid">check</mat-icon>
            <span class="valid-color" *ngIf="row['last_btq_date']">{{ row['last_btq_date'] | dateFormat : 'DD/MM/yyyy' }}</span>
        </ng-container>

        <ng-container *ngIf="row['blood_saved'] == 22">
            <mat-icon class="userstable-status-icon invalid">clear</mat-icon>
        </ng-container>

        <ng-container *ngIf="row['blood_saved'] == 23">
            <span>{{ value }}</span>
        </ng-container>

        <ng-container *ngIf="row['blood_saved'] == 24">
            <mat-icon class="userstable-status-icon invalid">priority_high</mat-icon>
            <span class="invalid-color">({{ value }})</span>
        </ng-container>
    </div>
</ng-template>

<!-- <ng-template #bloodDaysTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'">
        <ng-container *ngIf="row['blood_saved'] == 3">
            <mat-icon class="userstable-status-icon invalid">priority_high</mat-icon>
            <span>({{ value }})</span>
        </ng-container>
        <span *ngIf="row['blood_saved'] == 2">
            {{ value }}
        </span>
        <ng-container *ngIf="row['blood_saved'] == 1">
            <mat-icon class="userstable-status-icon valid">check</mat-icon>
            <span class="valid-color" *ngIf="row['last_btq_date']">{{ row['last_btq_date'] | dateFormat : 'DD/MM/yyyy' }}</span>
        </ng-container>
    </div>
</ng-template> -->

<ng-template #exportCsvTemplate let-form="form">
    <div class="basic-form mat-body mb75" [formGroup]="form">

        <div class="centered-row">
            <mat-form-field class="m-r-10 m-l-10">
                <input disabled formControlName="fromDate" matInput [matDatepicker]="fromDate" placeholder="תאריך התחלה">
                <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
                <mat-datepicker #fromDate disabled="false"></mat-datepicker>
            </mat-form-field>
            <mat-form-field class="m-r-10 m-l-10">
                <input disabled formControlName="toDate" matInput [matDatepicker]="toDate" [min]="form.value.fromDate" placeholder="תאריך סיום">
                <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
                <mat-datepicker #toDate disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>

        <div class="mt125">
            <mat-checkbox class="m-b-5" formControlName="birthdayField">
                יום הולדת
            </mat-checkbox>
            <mat-checkbox class="m-b-5" formControlName="emailField">
                אימייל
            </mat-checkbox>
        </div>
        
    </div>
</ng-template>

<ng-template #measurementTemplate let-form="form">
    <div class="basic-form mat-body" [formGroup]="form">

        <ng-container *ngIf="chosenIds.length">
            <div class="text-danger font-bold ul-text m-b-10">
                {{ chosenIds.length }} 
                משתמשים נבחרו
            </div>
            <div class="last-items-container m-b-10 m-r-10">
                <div class="m-b-5" *ngFor="let user of chosenUsers">
                    <span class="pointer font-12 font-bold" (click)="unselectRow(user)"> X </span>
                    <span class="font-italic">({{ user.usr_type_name || userTypes[user.usr_type] }})</span>
                     - 
                    {{user.usr_fname}} {{user.usr_lname}} - {{user.usr_phone}}
                </div>
            </div>
        </ng-container>

        <mat-form-field>
            <input readonly #timingDate formControlName="usr_measurement_date" matInput [matDatepicker]="dpInput" placeholder="תאריך מדידה">
            <mat-datepicker-toggle matSuffix [for]="dpInput"></mat-datepicker-toggle>
            <mat-datepicker #dpInput disabled="false"></mat-datepicker>
        </mat-form-field>

    </div>
</ng-template>
