<div class="full-loader" *ngIf="busy">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>

<div *ngIf="userId" class="food-diary-form basic-form" [ngClass]="{'loading-margin': busy}">

    <div class="row">
        <h1>{{'food_diary.QUIZ_TITLE' | translate}}</h1>
        <!-- <button type="button" *ngIf="!nutritionistFormMode && backToUser.length" class="plus-button" mat-raised-button [routerLink]="['/Nutritionistform/', backToUser]">
            חזור לטופס תזונאי
            <mat-icon>undo</mat-icon>
        </button> -->
        
        <button type="button" *ngIf="appState.user && appState.user.usr_type == 'parent'" class="plus-button" mat-raised-button [routerLink]="['/Userparent']">
            <span>חזור </span>
            <mat-icon>undo</mat-icon>
        </button>
    </div>

    <span class="mt125"></span>

    <mat-card>
        <!-- <div *ngIf="quizDaysDatesArr.length > 0" class="centered-row">
            <div class="datepicker-filled-date" style="width: 15px; height: 15px;"></div>
            <div class="font-14 font-bold m-r-10">ימים קיימים</div>
        </div> -->
        <div *ngIf="lastUpdated" class="font-italic font-14" [ngStyle]="{ 'text-align': translate.currentLang === 'en' ? 'right' : 'left' }">
            <span>{{ 'food_diary.LAST_UPDATED_AT' | translate }} </span>
            <span>{{ lastUpdated }}</span>
        </div>
        <div class="centered-row w65">
            <button type="button" [disabled]="busy" (click)="dayPrevious()" mat-icon-button aria-label="Previous Day">
                <mat-icon>{{ translate.currentLang === 'en' ? 'keyboard_arrow_left' : 'keyboard_arrow_right' }}</mat-icon>
            </button>
            <mat-form-field class="w70">
                <input (click)="dpStarted.open()" #formDate readonly matInput [matDatepicker]="dpStarted" placeholder="{{'food_diary.TODAY_DATE' | translate}}" [value]="chosenFormDate" [min]="minDate" [max]="maxDate" (dateInput)="findDayIdByDate($event.value, userId)">
                <mat-datepicker-toggle matSuffix [for]="dpStarted"></mat-datepicker-toggle>
                <mat-datepicker [dateClass]="dateClass" #dpStarted></mat-datepicker>
            </mat-form-field>
            <button type="button" [disabled]="busy" (click)="dayNext()" mat-icon-button aria-label="Next Day">
                <mat-icon>{{ translate.currentLang === 'en' ? 'keyboard_arrow_right' : 'keyboard_arrow_left' }}</mat-icon>
            </button>
        </div>
    </mat-card>

    <div *ngIf="formOwnerUsr">
        <fooddiaryquiz-day [nutritionistFormMode]="false" [dayData]="dayData" [userId]="userId" [userGender]="userGender" [userAge]="userAge" [isLocked]="isLocked" (dayCreated)="newDay($event)" (busyState)="setBusy($event)" [isBusy]="busy"></fooddiaryquiz-day>
    </div>

</div>
