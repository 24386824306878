import { Component, Input, Output, EventEmitter, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { appState } from '@services/app-state';
import { Apputils } from '@services/app-utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { CustomDialogComponent } from '../../../../shared/custom-dialog.component';
import { ConfirmDialogService } from '../../../../shared/confirm-dialog/confirm-dialog.service';

import { MatExpansionPanel } from '@angular/material/expansion';

@Component({
  selector: 'user-details-section',
  templateUrl: "./user-details.component.html"
})
export class UserDetailsComponent {
  @ViewChild("followTemplate") followTemplate: TemplateRef<any>;
  
  @ViewChild("follow_el") follow_el: ElementRef; 
  @ViewChild("exp") exp: MatExpansionPanel;

  @Output() busyState: EventEmitter<boolean> = new EventEmitter();
  // @Output() updateFollow: EventEmitter<any> = new EventEmitter();

  @Input() user: any;
  @Output() openImage: EventEmitter<any> = new EventEmitter();

  @Input() notesList = [];
  
  allHours = Apputils.allHours;

  notesForm: FormGroup;

  userData: any;

  restService: any;

  appState = appState;

  constructor(
    private dialogService: ConfirmDialogService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private translate: TranslateService,
    public restApiService: RestApiService<any>
  ) {

    this.restService = this.restApiService.service('users');

    this.notesForm = this.createNewForm();
  }

  imageClick(src: string) {
    this.openImage.emit(src);
  }

  async ngOnInit() {
    try {
      this.refreshForm(this.user);
    } catch (e) {
      console.error(e);
    }
  }

  resetField(notesForm, fieldName, value) {
    notesForm.controls[fieldName].reset(value);
  }

  async submitForm(cmd: string,withoutStatus?:boolean){
    this.busyState.emit(true);
    const form = this.notesForm;
    let res: any;

    // if (!form?.valid) {
    //   // this.showStatus("נא למלא את השדות הנחוצים", null);
    //   this.showStatus("נא למלא שעה וסוג ייעוץ קרוב", null);
    //   this.busyState.emit(false);
    //   return;
    // }

    if (form && form.value._id && form.value._id !== '') { 
      // res = await this.restService.patch(form.value._id, form.value);
      res = await this.restApiService.service('actions').create({cmd, cmdData: form.value});
      if (res && res._id) {
        form.reset(res);
        if(withoutStatus){
          // continue
        }else{
          this.showStatus("נשמר בהצלחה", null);
        }
        if (res.follow_flag) {
          // this.updateFollow.emit(res);
        }
      }
    }
    this.busyState.emit(false);
    return res;
  }

  async refreshForm(value) {
    // this.notesForm.patchValue(value);
    const notesGroup = this.notesForm.get('usr_notes') as FormGroup;
    this.resetNotes(notesGroup, value['usr_notes']);
    // this.notesForm.reset(value);
    this.notesForm.patchValue(value);

    this.userData = value;
  }

  createNewForm(): FormGroup {
    const form = this.formBuilder.group({
        _id: [''],
        notes_usr_decription: [''],
        
        usr_notes: this.formBuilder.group({
            A: this.formBuilder.array([]),
        }),

        usr_is_follow: [false],

        usr_report_count: [''],

        // usr_is_measurement: [false],
        usr_measurement_date: [''],
        usr_measurement_hour: [''],

        // usr_sessions_done: [''],
        usr_sessions_total: [''],

        usr_session_date: [''],
        usr_session_hour: [''],
        usr_session_type: [''],

        usr_previous_sessions: [null],
        usr_is_open_Q: [false], // New checkbox field
        future_schedule: [''], // New date input field
        athlete_lvl: [''], // New date input field
    });

    form.get('usr_session_date').valueChanges
    .subscribe(value => {
        if (this.appState?.user?.usr_type !== 'superadmin') {
            return;
        }
        if (value) {
            form.get('usr_session_hour').setValidators(Validators.required);
            form.get('usr_session_type').setValidators(Validators.required);
        } else {
            form.get('usr_session_hour').clearValidators();
            form.get('usr_session_type').clearValidators();
        }
        form.get('usr_session_hour').updateValueAndValidity();
        form.get('usr_session_type').updateValueAndValidity();
    });

    return form;
}

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];
  }

  getDirty() {
    const form = this.notesForm;
    return (form && form.dirty);
  }

  newFollowPopup() {
    const newForm = this.formBuilder.group({
      note_custom: ['', Validators.required]
    });
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // maxWidth: "90vw",
      minWidth: '35%',
      // minWidth: '300px',
      // panelClass: "meal-dialog",
      data: { 
        title: 'נקודות למעקב', 
        ok: this.translate.instant('general.ok'),
        cancel: this.translate.instant('general.cancel'),
        form: newForm,  // this.notesForm
        template: this.followTemplate
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }

      let field: FormArray = this.notesForm.controls['usr_notes'].get('A') as FormArray;
      let checkboxField: FormControl = this.notesForm.controls['usr_is_follow'] as FormControl;
      const now = new Date().getTime();
      const note = result.form.value.note_custom.trim();
      if (note && field) {
        console.log('new follow: ', note);
        const newNote = new FormControl({
          note_custom: note,
          added_at: now,
        });
        field.push(newNote);
        field.markAsDirty();
        if (checkboxField && !checkboxField.value) {
          checkboxField.setValue(true);
          checkboxField.markAsDirty();
        }
        // this.showStatus("הערת מעקב נוספה בהצלחה, השינויים לא נשמרו", null);
        this._snackBar.open('הערת מעקב נוספה בהצלחה, השינויים לא נשמרו', 'סגור', {
          duration: 0,
        });
        const timeout = this.exp.expanded ? 100 : 800;
        this.exp.open();
        if (this.follow_el) {
          setTimeout(() => {
            this.follow_el.nativeElement.scrollIntoView();
          }, timeout);
        }
      }
    });
  }

  resetNotes(notesGroup: FormGroup, dataNotes) {
    for (const noteKey in notesGroup.value) {
      let notes: FormArray = notesGroup.get(noteKey) as FormArray;
      notes.clear();
      if (dataNotes && dataNotes[noteKey]) {
        for (const n of dataNotes[noteKey]) {
          notes.push(new FormControl());
        }
      }
    }
  }

  submitFormAdminDialog() {
    const form = this.notesForm;
    if (!form?.valid) {
      // this.showStatus("נא למלא את השדות הנחוצים", null);
      this.showStatus("נא למלא שעה וסוג ייעוץ קרוב", null);
      return;
    }

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // minWidth: '35%',
      data: { 
        title: 'עדכון נתונים',
        subtitle: 'האם לעדכן נתונים ולשלוח הודעה בנוגע לחבילה חדשה או רק לעדכן נתונים ללא שליחת הודעה?',
        cancel: 'ביטול',
        ok: 'עדכן בלבד',
        extraButton: { value: 'notify', text: 'שלח הודעה' },
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }
      const cmd = result === 'notify' ? 'patch-u-n' : 'patch-u';
      await this.submitForm(cmd);
    });
  }

  sessionIsDoneDialog() {
    
    const form = this.notesForm;
    if (form?.value?._id) {
      const { _id, usr_session_date, usr_session_hour, usr_session_type } = form.value;
      if (usr_session_date && usr_session_hour && usr_session_type) {
    this.submitForm('patch-u-n',true)
        
        const options = {
          title: 'האם הייעוץ התקיים?',
          // message: 'האם הייעוץ התקיים?',
          cancelText: this.translate.instant("general.no"),
          confirmText: this.translate.instant("general.yes")
        };
        this.dialogService.open(options);
        this.dialogService.confirmed().subscribe(async confirmed => {
          if (confirmed) {
            await this.sessionIsDone();
          }
        });
      } else {
        this.showStatus("נא למלא תאריך, שעה וסוג ייעוץ קרוב", null);
      }
    }
  }

  async sessionIsDone() {
    const form = this.notesForm;
    if (form?.value?._id) {
      const { _id, usr_session_date, usr_session_hour, usr_session_type } = form.value;
      if (usr_session_date && usr_session_hour && usr_session_type) {
        const res = await this.restApiService.service('actions').create({cmd: 'session-is-done', cmdData: {
          _id,
          usr_session_date,
          usr_session_hour,
          usr_session_type,
        }});

        console.log(res)
        if (res?._id) {
          form.reset(res);
          this.showStatus("נשמר בהצלחה", null);
          
          if (res.follow_flag) {
            // this.updateFollow.emit(res);
            
          }
        }
      } else {
        this.showStatus("נא למלא תאריך, שעה וסוג ייעוץ קרוב", null);
      }
    }
  }
}

