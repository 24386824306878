import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { appState } from '@services/app-state';
import { Appcalcs } from '@services/app-calcs';
import { RestApiService } from '@noammazuz/vzn-feathers';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { ComponentCanDeactivate } from 'src/shared/pending-changes.guard';
import { Observable } from 'rxjs';

@Component({
  templateUrl: "./nuttestquiz.component.html"
})
export class NuttestquizComponent implements OnInit , ComponentCanDeactivate
 {
@HostListener('window:beforeunload', ['$event'])
unloadNotification($event: any) {
  if (!this.canDeactivate() && !this.formIsSaved) {
      $event.returnValue = "There are unsaved changes.";
  }
}
  nuttestquizForm: FormGroup;
  userFormGroup: FormGroup;
  oldValues = [];
  selectedValues: any = {
    ntq_height: -1,
    ntq_weight: -1,
    ntq_wingspan: -1,
    ntq_fat_percent: -1,
    ntq_factor: -1,
    ntq_dev_status: -1,
    ntq_height_trend: -1,
    ntq_bmi_status: -1,
    ntq_bmi_trend: -1,
    ntq_fat_per_status: -1,
    ntq_fat_per_trend: -1,
  };

  computedSelected: any = {};

  quizOwner: any;

  uploadFolder: string;
  restService: any;

  calcs = Appcalcs;
  calcDiff = Appcalcs.calcDiff;

  appState = appState;

  showErrors: boolean = false;
  showComputed: boolean = false;

  notesArr = [];
  allNotesGroups = [];
  allNotesIds = [];
  checkAllNotes = false;

  busy = true;

  isFemale = false;
  formIsSaved = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public restApiService: RestApiService<any>,
    private translate: TranslateService,
  ) {}
 
  ngOnInit(): void {
    const now = moment().milliseconds(0).second(0).minute(0).hour(0);
    this.showComputed = true;

    this.restService = this.restApiService.service('nuttestquiz');

    this.nuttestquizForm = this.formBuilder.group({
      _id: [''],
      ntq_locked: [false],
      ntq_form_is_real: [false],
      ntq_usr_id: [''],
      ntq_test_date: [now, Validators.required],
      ntq_height: ['', Validators.required],
      ntq_weight: ['', Validators.required],
      ntq_wingspan: [''],
      ntq_fat_percent: ['', Validators.required],
      ntq_fat_caliper_status: ['תואם גיל'],
      ntq_fat_caliper_a: [''],
      ntq_fat_caliper_b: [''],
      ntq_fat_caliper_c: [''],
      ntq_fat_caliper_d: [''],
      ntq_fat_percent_num: [''],
      ntq_factor: ['', Validators.required],
      ntq_dev_status: ['ללא התייחסות', Validators.required],
      ntq_height_trend: ['ללא התייחסות', Validators.required],
      ntq_bmi_status: ['ללא התייחסות', Validators.required],
      ntq_bmi_trend: ['ללא התייחסות', Validators.required],
      ntq_fat_per_status: ['ללא התייחסות', Validators.required],
      ntq_fat_per_trend: ['ללא התייחסות', Validators.required],
      usr_cycle_date: ['']
    });

    this.userFormGroup = this.formBuilder.group({
      _id: [''],
      notes_usr_decription: [''],
    });

    this.route.params.subscribe(async params => {
      if (params.id) {
        this.uploadFolder = `nuttestquiz/ntq_assets_${params.id}/`;

        let data = await this.restService.get(params.id).catch(e => { console.error(e) });

        if (!data) {
          this.router.navigate([`/`]);
          return;
        }

        if (data.quizOwner?._id) {
          await this.fetchOldValues(data._id, data.quizOwner);
          this.quizOwner = data.quizOwner;

          this.isFemale = this.quizOwner.usr_gender === 'נקבה';

          this.userFormGroup.patchValue(data.quizOwner);

          const rptRes = await this.restApiService.service('userreport').getLast({
            rpt_usr_id: data.quizOwner._id,
            rpt_is_published: true,
            rpt_is_hidden: {
              $ne: true
            },
          });

          if (rptRes[0]) {
            const allResults = await this.restApiService.service('userreport').get(rptRes[0]._id).catch(err => { console.error(err); });

            const allNotesGroups = [
              allResults?.ntq?.ntq_notes?.['A'],
              allResults?.btq?.btq_notes?.['A'],
              allResults?.btq?.btq_notes?.['B'],
              allResults?.btq?.btq_notes?.['C'],
              allResults?.btq?.btq_notes?.['D'],
              allResults?.slq?.slq_notes?.['A'],
              allResults?.fdq?.fdq_notes?.['s1'],
            ].filter(x => x?.length);

            const allNotesIds = [];
            for (const notesGroup of allNotesGroups) {
              for (const note of notesGroup) {
                if (note.note_id) {
                  allNotesIds.push(note.note_id);
                }
              }
            }

            this.allNotesIds = allNotesIds;
            this.allNotesGroups = allNotesGroups;

            if (allNotesIds.length) {
              this.notesArr = await this.restApiService.service('note').find({
                query: {
                  $paginate: false,
                  $limit: 9999,
                  _id: {
                    $in: allNotesIds
                  },
                  is_deleted: {
                    $ne: true
                  }
                }
              });
            }
          }
        }

        if (this.translate.currentLang !== 'en') {
          if (data.quizOwner?.usr_gender === 'נקבה') {
            this.translate.use('he_F');
          } else {
            this.translate.use('he');
          }
        }

        this.nuttestquizForm.patchValue(data);

        if (this.appState.user.usr_type === 'superadmin' || this.appState.user.usr_type === 'nutri') {
          this.appState.quizUser = data.quizOwner;
        }

        this.busy = false;
      } else {
        this.router.navigate([`/`]);
      }
    });
    this.nuttestquizForm.get('ntq_fat_percent').valueChanges.subscribe(value => {
      this.updateFatPercentValidators(value);
    });

    this.nuttestquizForm.valueChanges.subscribe(val => {
      console.log(val)


      this.updateComputedSelected();
    });

    this.initializeComputedSelected();

  }


  ngOnDestroy() {
    if (this.translate.currentLang !== 'en') {
      if (this.appState.user.usr_gender === 'נקבה') {
        this.translate.use('he_F');
      } else {
        this.translate.use('he');
      }
    }
  }

  canDeactivate(): Observable<boolean> | boolean {
    return !this.getDirty();
  }
  
  getDirty() {
    debugger
    const form = this.nuttestquizForm;
    return (form && form.dirty) || this.userFormGroup.dirty;
  }
  async fetchOldValues(thisQuizId: string, userOwner) {
    const data = await this.restService.find({
      query: {
        $paginate: false,
        ntq_usr_id: userOwner._id,
        ntq_form_is_real: true,
        _id: {
          $ne: thisQuizId
        },
        $sort: {
          ntq_test_date: -1,
          createdAt: -1
        }
      }
    });

    let filtered: any[] = [];
    for (let val of data) {
      if (val && val.ntq_test_date) {
        this.calcs.updatePercents(val, userOwner);
        filtered.push(val);
      }
    }

    const keys = this.getObjKeys(this.selectedValues);
    for (const key of keys) {
      this.selectedValues[key] = filtered.findIndex(val => val[key] && val[key].length);
    }
  
    this.oldValues = filtered;

  }

  resetField(fieldName, value) {
    this.nuttestquizForm.controls[fieldName].reset(value);
  }

  async onSubmit(showFeedback?: boolean, overrideValidation?: boolean) {
    const allNotesChecked = this.validateNotesCheckboxes();
    if (!allNotesChecked) {
      this.showStatus('יש לסמן את כל ההערות מהדוח האחרון', null);
      return;
    }

    this.resetField("ntq_form_is_real", true);

    this.clearValuesFromUnusedFields();
    const validateRes = this.validateForm();

    if (!overrideValidation && !validateRes.valid) {
      this.showErrors = true;
      this.showStatus(validateRes.msg, null);
      return;
    } else {
      this.showErrors = false;
      this.showComputed = false;
    }

    this.busy = true;

    this.resetField("ntq_locked", true);

    let res: any;
    if (!this.nuttestquizForm.value._id || this.nuttestquizForm.value._id === '' || this.nuttestquizForm.value._id === null) {
    } else {
      res = await this.restService.patch(this.nuttestquizForm.value._id, this.nuttestquizForm.value);
    }
    if (this.userFormGroup?.value?._id && this.userFormGroup.value.notes_usr_decription !== this.quizOwner.notes_usr_decription) {
      const userRes = await this.restApiService.service('users').patch(this.userFormGroup.value._id, this.userFormGroup.value);
    }
    if (res && res._id) {
      this.nuttestquizForm.patchValue(res);
      this.showComputed = true;
      this.formIsSaved = true;
      if (showFeedback) {
        this.showStatus(this.translate.instant('general.savedSuccessfully'), null);
      }
    }

    this.busy = false;
  }

  validateForm() {
    if (!this.nuttestquizForm.valid) {
      return {
        valid: false,
        msg: "נא לתקן את השדות האדומים"
      };
    }

    return {
      valid: true,
      msg: "ולידציה עברה בהצלחה"
    };
  }

  clearValuesFromUnusedFields() {
    if (this.nuttestquizForm.value.ntq_fat_percent !== 'קליפר') {
      this.resetField("ntq_fat_caliper_status", '');
      this.resetField("ntq_fat_caliper_a", '');
      this.resetField("ntq_fat_caliper_b", '');
      this.resetField("ntq_fat_caliper_c", '');
      this.resetField("ntq_fat_caliper_d", '');
    }

    if (this.nuttestquizForm.value.ntq_fat_percent !== 'הזנה ידנית') {
      this.resetField("ntq_fat_percent_num", '');
    }
  }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];
  }

  changedCheckbox(checked: boolean) {
    if (!checked) {
      this.checkAllNotes = false;
    }
  }

  selectAllCheckboxes(checked: boolean) {
    for (const notesGroup of this.allNotesGroups) {
      for (const note of notesGroup) {
        note.is_checked = checked;
      }
    }
  }

  validateNotesCheckboxes() {
    const notesArr = this.notesArr || [];
    const allNotesGroups = this.allNotesGroups || [];
    for (const notesGroup of allNotesGroups) {
      for (const note of notesGroup) {
        if (note.note_id) {
          const noteDetails = notesArr.find(n => n._id === note.note_id);
          if (noteDetails.quiz_prefix) {
            if (!note.is_checked) {
              return false;
            }
          }
        }
      }
    }
    return true;
  }

  updateFatPercentValidators(value) {
    switch (value) {
      case 'קליפר':
        this.nuttestquizForm.get('ntq_fat_percent_num').clearValidators();
        this.nuttestquizForm.get('ntq_fat_caliper_status').setValidators(Validators.required);
        this.nuttestquizForm.get('ntq_fat_caliper_a').setValidators(Validators.required);
        this.nuttestquizForm.get('ntq_fat_caliper_b').setValidators(Validators.required);
        this.nuttestquizForm.get('ntq_fat_caliper_c').setValidators(Validators.required);
        this.nuttestquizForm.get('ntq_fat_caliper_d').setValidators(Validators.required);
        break;

      case 'הזנה ידנית':
        this.nuttestquizForm.get('ntq_fat_percent_num').setValidators(Validators.required);
        this.nuttestquizForm.get('ntq_fat_caliper_status').clearValidators();
        this.nuttestquizForm.get('ntq_fat_caliper_a').clearValidators();
        this.nuttestquizForm.get('ntq_fat_caliper_b').clearValidators();
        this.nuttestquizForm.get('ntq_fat_caliper_c').clearValidators();
        this.nuttestquizForm.get('ntq_fat_caliper_d').clearValidators();
        break;

      default:
        this.nuttestquizForm.get('ntq_fat_percent_num').clearValidators();
        this.nuttestquizForm.get('ntq_fat_caliper_status').clearValidators();
        this.nuttestquizForm.get('ntq_fat_caliper_a').clearValidators();
        this.nuttestquizForm.get('ntq_fat_caliper_b').clearValidators();
        this.nuttestquizForm.get('ntq_fat_caliper_c').clearValidators();
        this.nuttestquizForm.get('ntq_fat_caliper_d').clearValidators();
        break;
    }
    this.nuttestquizForm.get('ntq_fat_percent_num').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.nuttestquizForm.get('ntq_fat_caliper_status').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.nuttestquizForm.get('ntq_fat_caliper_a').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.nuttestquizForm.get('ntq_fat_caliper_b').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.nuttestquizForm.get('ntq_fat_caliper_c').updateValueAndValidity({ onlySelf: true, emitEvent: false });
    this.nuttestquizForm.get('ntq_fat_caliper_d').updateValueAndValidity({ onlySelf: true, emitEvent: false });
  }

  updateComputedSelected() {
    
      let selected = { ...this.nuttestquizForm.value };
      this.calcs.updatePercents(selected, this.quizOwner);
      this.computedSelected = selected;

      console.log(this.computedSelected)
      // debugger;
 
    
    
  }

  initializeComputedSelected() {
    // if (this.nuttestquizForm.valid) {
      let selected = { ...this.nuttestquizForm.value };
      this.calcs.updatePercents(selected, this.quizOwner);
      this.computedSelected = selected;
    // } else {
    //   this.computedSelected = {};
    // }
  }
}
