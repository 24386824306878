<div class="antro-form">
    <style>
        .required-star {
            color: red;
            margin-right: 4px;
        }
        .error-message {
            color: red;
            font-size: 12px;
            margin-top: 4px;
        }
        .required-field {
            margin-bottom: 8px;
        }
    </style>

    <mat-expansion-panel disabled expanded>
        <mat-expansion-panel-header>
            <!-- <mat-panel-title class="font-18 font-bold">
                הרגלי אכילה- שאלון תזונה
            </mat-panel-title>
            <mat-panel-description>
                העדפות תזונה, אופן האכילה, הערות הרגלי אכילה
            </mat-panel-description> -->
            <mat-panel-title class="font-16 font-bold">
                <h4 class="m-auto">הרגלי אכילה</h4>
            </mat-panel-title>
        </mat-expansion-panel-header>
        
        <div class="row mb125">
            <button class="plus-button" mat-raised-button (click)="newQuizGuard()">
                {{'general.newquiz' | translate}}
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <div class="row mb125">
            <mat-radio-group #pickyEaterGroup="ngModel" [(ngModel)]="isPickyEater" name="pickyEater" (change)="updatePickyEaterStatus()">
                <div class="radio-group-label">
                    <span>סוג אכילה</span>
                    <span class="required-star">*</span>
                </div>
                <mat-radio-button [value]="true">בררן</mat-radio-button>
                <mat-radio-button [value]="false">לא בררן</mat-radio-button>
            </mat-radio-group>
            <div *ngIf="pickyEaterGroup.invalid && (pickyEaterGroup.dirty || pickyEaterGroup.touched)" class="error-message">
                <div *ngIf="pickyEaterGroup.errors?.required">
                    חובה לבחור סוג אכילה
                </div>
            </div>
        </div>

        <div *ngIf="oldValues.length > 0" class="big-content">
            <div class="row mb125">
                <mat-form-field *ngIf="oldValues.length > 0" class="w40">
                    <!-- <button type="button" *ngIf="selectedA" matSuffix [routerLink]="['/Nutritionquiz/', selectedIdA]" class="top-button" mat-stroked-button>עבור לטופס</button> -->
                    <!-- <a (click)="$event.stopPropagation()" target="quizwindow" *ngIf="selectedA" matSuffix [routerLink]="['/Nutritionquiz/', selectedIdA]" class="top-button" mat-stroked-button>עבור לטופס</a> -->
                    <a (click)="$event.stopPropagation()" target="quizwindow" *ngIf="selectedA" matSuffix [routerLink]="['/Nutquiz/', selectedIdA]" class="top-button" mat-stroked-button>עבור לטופס</a>
                    <mat-select [disabled]="true" [(ngModel)]="selectedIdA" (ngModelChange)="selectDateA($event)">
                        <ng-container *ngFor="let val of oldValues; let i = index">
                            <mat-option *ngIf="selectedIdB != val._id" [value]="val._id">
                                {{val['createdAt'] | date:'dd/MM/yyyy' }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <button *ngIf="oldValues.length > 1" mat-stroked-button color="primary" class="w15 wrap-text" (click)="fetchOldValues(true)">אפס לשתי המדידות האחרונות</button>

                <mat-form-field *ngIf="oldValues.length > 1" class="w40">
                    <!-- <button type="button" *ngIf="selectedB" matSuffix [routerLink]="['/Nutritionquiz/', selectedIdB]" class="top-button" mat-stroked-button>עבור לטופס</button> -->
                    <!-- <a (click)="$event.stopPropagation()" target="quizwindow" *ngIf="selectedB" matSuffix [routerLink]="['/Nutritionquiz/', selectedIdB]" class="top-button" mat-stroked-button>עבור לטופס</a> -->
                    <a (click)="$event.stopPropagation()" target="quizwindow" *ngIf="selectedB" matSuffix [routerLink]="['/Nutquiz/', selectedIdB]" class="top-button" mat-stroked-button>עבור לטופס</a>
                    <mat-select [(ngModel)]="selectedIdB" (ngModelChange)="selectDateB($event)">
                        <ng-container *ngFor="let val of oldValues; let i = index">
                            <mat-option *ngIf="selectedIdA != val._id" [value]="val._id">
                                {{val['createdAt'] | date:'dd/MM/yyyy' }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>


            <!-- <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">

                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'a', title: 'הערות תזונה כלליות' }"></ng-container>

                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's10', subPrefix: '', title: 'הערות נוזלים ללא אימון' }"></ng-container>

                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's4', subPrefix: '', title: 'הערות ארוחת בוקר' }"></ng-container>

                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's5', subPrefix: '', title: 'הערות ארוחת ביניים 1' }"></ng-container>

                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'b', title: 'הערות תזונה כלליות' }"></ng-container>

                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'c', title: 'הערות תזונה כלליות' }"></ng-container>

                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's1', subPrefix: '', title: 'הערות לתחילת הדוח' }"></ng-container>

            </ng-container> -->

            <ng-template #template1 let-selected="selected">

                <h4>סוג תזונה</h4>
                <div class="font-bold">
                    <div *ngIf="selected.isPickyEater !== undefined" class="mb75">
                        <span>סוג אכילה: </span>
                        <span class="font-bold">{{selected.isPickyEater ? 'בררן' : 'לא בררן'}}</span>
                    </div>
                    <!-- <div *ngIf="selected.nuq_style === 'הכל'">{{ 'nutquiz.Q_1_A_1' | translate }}</div>
                    <div *ngIf="selected.nuq_style === 'דגים'">{{ 'nutquiz.Q_1_A_2' | translate }}</div>
                    <div *ngIf="selected.nuq_style === 'צמחוני'">{{ 'nutquiz.Q_1_A_3' | translate }}</div>
                    <div *ngIf="selected.nuq_style === 'טבעוני'">{{ 'nutquiz.Q_1_A_4' | translate }}</div> -->
                    <div *ngIf="selected.nuq_style === 'הכל'">אוכל הכל ללא הגבלות</div>
                    <div *ngIf="selected.nuq_style === 'דגים'">צמחוני (אוכל דגים)</div>
                    <div *ngIf="selected.nuq_style === 'צמחוני'">צמחוני (לא אוכל דגים)</div>
                    <div *ngIf="selected.nuq_style === 'טבעוני'">טבעוני</div>
                    <div *ngIf="selected.nuq_style === 'אחר'">{{ selected.nuq_style_other || '' }}</div>
                    <div *ngIf="selected.nuq_food_does_not_eat_reason_arr">{{ (selected.nuq_food_does_not_eat_reason_arr || []).filter(otherFilter).join(', ') }}</div>
                    <div *ngIf="selected.nuq_food_does_not_eat_reason_arr && selected.nuq_food_does_not_eat_reason_arr.includes('אחר')">{{ selected.nuq_food_does_not_eat_reason_other }}</div>
                </div>

                <h4>מזון שלא אוהב</h4>
                <div>
                    <ng-container *ngIf="selected.nuq_cant_eat_all">
                        <div class="font-bold">אין מגבלות- אוכל מה שצריך כדי להשתפר!!!</div>
                    </ng-container>

                    <ng-container *ngIf="!selected.nuq_cant_eat_all">
                        <ng-container *ngIf="selected.nuq_cant_eat_arr">
                            <div *ngFor="let item of selected.nuq_cant_eat_arr" class="">
                                {{ item | labelById : nutcategories }}
                            </div>
                        </ng-container>
                        <!-- <div *ngIf="selected.nuq_cant_eat_arr">{{ (selected.nuq_cant_eat_arr || []).join(', ') }}</div> -->
                        <div *ngIf="selected.nuq_cant_eat_is_other && selected.nuq_cant_eat_other">{{ selected.nuq_cant_eat_other }}</div>
                    </ng-container>
                </div>

                <h4>לא יכול לוותר על</h4>
                <div>
                    <ng-container *ngIf="selected.nuq_cant_avoid_all">
                        <div class="font-bold">אם צריך אצמצם צריכה של מזונות שפחות מומלצים בהתאם להנחיות שאקבל</div>
                    </ng-container>

                    <ng-container *ngIf="!selected.nuq_cant_avoid_all">
                        <ng-container *ngIf="selected.nuq_cant_avoid_arr">
                            <div *ngFor="let item of selected.nuq_cant_avoid_arr" class="">
                                {{ item | labelById : nutcategories }}
                            </div>
                        </ng-container>
                        <!-- <div *ngIf="selected.nuq_cant_avoid_arr">{{ (selected.nuq_cant_avoid_arr || []).join(', ') }}</div> -->
                        <div *ngIf="selected.nuq_cant_avoid_is_other && selected.nuq_cant_avoid_other">{{ selected.nuq_cant_avoid_other }}</div>
                    </ng-container>
                </div>

                <h4>טעימות</h4>
                <div>
                    <ng-container *ngIf="selected.nuq_new_food == 'לא'">
                        <div class="font-bold">לא טעמתי לאחרונה מזונות חדשים</div>
                    </ng-container>

                    <ng-container *ngIf="selected.nuq_new_food == 'כן' && ((selected.nuq_new_food_arr && selected.nuq_new_food_arr.length > 0) || (selected.nuq_new_food_arr_fixed && selected.nuq_new_food_arr_fixed.length > 0))">
                        <div *ngIf="selected.nuq_new_food_arr_fixed && selected.nuq_new_food_arr_fixed.filter(otherFilter).length">
                            <div class="mb75 w65">
                                <!-- <span>טעמתי </span> -->
                                <div *ngFor="let item of selected.nuq_new_food_arr_fixed.filter(otherFilter)" class="">
                                    {{ item | labelById : nutcategories }}
                                </div>
                            </div>
                        </div>
                        <div *ngIf="selected.nuq_new_food_arr && selected.nuq_new_food_arr.length">
                            <div *ngFor="let control of selected.nuq_new_food_arr" class="mb50">
                                <div class="w65">                
                                    <span>טעמתי </span>
                                    <span class="font-bold">{{ control.description }}</span>                       
                                </div>
                
                                <div *ngIf="control.feedback" class="w65">                
                                    <span>היה </span>
                                    <span class="font-bold">{{ control.feedback }}</span>                       
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- <ng-container
                        *ngIf="selected.nuq_new_food == 'כן' && ((selected.nuq_new_food_arr && selected.nuq_new_food_arr.length > 0) || (selected.nuq_new_food_arr_fixed && selected.nuq_new_food_arr_fixed.length > 0))"
                        [ngTemplateOutlet]="arrTemplate" [ngTemplateOutletContext]="{
                            data: selected,
                            arrFieldName: 'nuq_new_food_arr',
                            descriptionFieldLabel: 'טעמתי',
                            feedbackFieldLabel: 'היה'
                        }">
                    </ng-container> -->
                </div>

            </ng-template>

            <div class="row healthquiz-box">
                <div class="w40" *ngIf="selectedA">
                    <ng-container *ngTemplateOutlet="template1; context: { selected: selectedA }"></ng-container>
                </div>
                <div class="w40" *ngIf="selectedB">
                    <ng-container *ngTemplateOutlet="template1; context: { selected: selectedB }"></ng-container>
                </div>
            </div>

            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'a', title: 'הערות תזונה כלליות' }"></ng-container>
            </ng-container>


            <!-- <ng-template #template11 let-selected="selected">

            </ng-template>

            <ng-container>
                <div class="w40" *ngIf="selectedA">
                    <ng-container *ngTemplateOutlet="template11; context: { selected: selectedA }"></ng-container>
                </div>
                <div class="w40" *ngIf="selectedB">
                    <ng-container *ngTemplateOutlet="template11; context: { selected: selectedB }"></ng-container>
                </div>
            </ng-container> -->

            <ng-template #templateCat let-selected="selected" let-cat="cat">
                <h4>{{ cat.title }}</h4>
                <div *ngIf="cat.name === 'veg'">
                    <div *ngIf="selected.nuq_eat_vegetables" class="mb50">
                        <span *ngIf="selected.nuq_eat_vegetables == 'לא'" class="font-bold">לא אוכל ירקות</span>
                        <span *ngIf="selected.nuq_eat_vegetables == 'כן'" class="">אוכל ירקות </span>
                        <span *ngIf="selected.nuq_eat_vegetables == 'כן'" class="font-bold"> {{selected.nuq_eat_vegetables_day}} </span>
                        <span *ngIf="selected.nuq_eat_vegetables == 'כן'" class=""> ביום</span>
                    </div>
                </div>
                <div *ngIf="cat.name === 'fruits'">
                    <div *ngIf="selected.nuq_eat_fruits" class="mb50">
                        <span *ngIf="selected.nuq_eat_fruits == 'לא'" class="font-bold">לא אוכל פירות</span>
                        <span *ngIf="selected.nuq_eat_fruits == 'כן'" class="">אוכל פירות </span>
                        <span *ngIf="selected.nuq_eat_fruits == 'כן'" class="font-bold"> {{selected.nuq_eat_fruits_day}} </span>
                        <span *ngIf="selected.nuq_eat_fruits == 'כן'" class=""> ביום</span>
                    </div>
                </div>
                <div *ngIf="selected['nuq_disabled_' + cat.name]">
                    <span class="font-bold">לא אוכל מזונות שהם </span>
                    <span class="font-bold">{{ cat.title }}</span>
                </div>
                <div *ngIf="!selected['nuq_disabled_' + cat.name] && selected.chosenCategories && selected.chosenCategories[cat.name]">
                    <div *ngFor="let chosenCategory of selected.chosenCategories[cat.name]" class="">
                        {{ chosenCategory | labelById : nutcategories }}
                    </div>
                </div>
                <div *ngIf="!selected['nuq_disabled_' + cat.name] && selected['nuq_is_other_' + cat.name] && selected['nuq_other_' + cat.name]">
                    <ng-container *ngFor="let otherCategory of selected['nuq_other_' + cat.name]">
                        <div *ngIf="otherCategory">{{ otherCategory }}</div>
                    </ng-container>
                </div>
            </ng-template>

            <ng-template #template2 let-selected="selected">
                <h3>מרכיבי ארוחות</h3>
                <div style="display: flex; justify-content: space-between; align-items: flex-start; flex-wrap: wrap;">
                    <ng-container *ngFor="let cat of allCategoriesGroups">
                        <div style="flex-basis: 50%;">
                            <ng-container *ngTemplateOutlet="templateCat; context: { selected: selected, cat: cat }"></ng-container>
                        </div>
                    </ng-container>
                </div>
            </ng-template>

            <div class="row healthquiz-box">
                <div class="w40" *ngIf="selectedA">
                    <ng-container *ngTemplateOutlet="template2; context: { selected: selectedA }"></ng-container>
                </div>
                <div class="w40" *ngIf="selectedB">
                    <ng-container *ngTemplateOutlet="template2; context: { selected: selectedB }"></ng-container>
                </div>
            </div>

            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'd', title: 'הערות תזונה כלליות' }"></ng-container>
            </ng-container>


            <ng-template #template3 let-selected="selected" let-showOptimal="showOptimal">
                <h4>נוזלים</h4>
                <div *ngIf="selected.nuq_drink_estimate">
                    <span class="">הערכת נוזלים: </span>
                    <span class="font-bold">{{ selected.nuq_drink_estimate }}</span>
                    <span class="font-bold"> ליטר</span>
                </div>
                    
                <ng-container *ngIf="showOptimal">
                    <div *ngIf="drinkInPractice && drinkInPractice !== '0'">
                        <span>
                            נוזלים באימון:  
                        </span>
                        <span class="font-bold">
                            {{ +(drinkInPractice) }}
                        </span>
                        <span>
                            ליטר
                        </span>
                    </div>
                    <div *ngIf="drinkInPractice === '0'" class="font-bold">
                        בקושי שותה במהלך אימון
                    </div>
                </ng-container>
                
                <div *ngIf="showOptimal">
                    <span>{{'food_diary.OPTIMAL_FLUIDS' | translate}}: </span>
                    <ng-container *ngIf="userOptimalFluidsLiterNoPractice">
                        <span class="font-bold">{{ userOptimalFluidsLiterNoPractice }}</span>
                        <span> {{'food_diary.UNITS_LITER' | translate}}</span>
                    </ng-container>
                    <ng-container *ngIf="!userOptimalFluidsLiterNoPractice">
                        <span>{{'food_diary.OPTIMAL_FLUIDS_NO_WEIGHT' | translate}}</span>
                    </ng-container>
                </div>
                <div *ngIf="selected.nuq_personal_bottle">
                    <span class="font-bold">{{ selected.nuq_personal_bottle }}</span>
                    <span class=""> מסתובב עם בקבוק אישי</span>
                </div>

                <h4>צבע השתן הכהה ביותר ביום</h4>
                <div class="mt125" *ngIf="selected.nuq_urine_color">
                    <!-- <div class="status-row" style="max-width: 700px; margin: 0 auto;">
                        <div class="basis10">
                            <mat-icon class="status-icon valid" *ngIf="(darkestUrineIndex > -1 && 3 > darkestUrineIndex)">check</mat-icon>
                            <mat-icon class="status-icon invalid" *ngIf="darkestUrineIndex > 2">priority_high</mat-icon>
                        </div>
                        <div class="basis90"> -->
                            <mat-grid-list [cols]="urineHexColorPlate.length" rowHeight="3em">
                                <mat-grid-tile *ngFor="let urineColor of urineHexColorPlate; let i = index;"
                                [colspan]="1"
                                [rowspan]="1"
                                [style.padding]="'4px'"
                                [style.border]="selected.nuq_urine_color == urineColor ? '2.5px solid red' : '2px solid blue'"
                                [style.background]="urineColor">
                                </mat-grid-tile>
                            </mat-grid-list>
                        <!-- </div>
                    </div> -->
                </div>

                <h4>שתיה ממותקת</h4>
                <div *ngIf="selected.nuq_drink_sugar">
                    <span *ngIf="selected.nuq_drink_sugar == 'לא'" class="font-bold">לא שותה שתיה ממותקת</span>
                    <span *ngIf="selected.nuq_drink_sugar == 'כן'" class="">שותה </span>
                    <span *ngIf="selected.nuq_drink_sugar == 'כן'" class="font-bold"> {{selected.nuq_drink_sugar_freq}}</span>
                </div>

                <h4>קפאין</h4>
                <div *ngIf="selected.nuq_drink_caffeine">
                    <span *ngIf="selected.nuq_drink_caffeine === 'לא'" class="font-bold">לא שותה משקאות המכילים קפאין</span>
                    <div *ngIf="selected.nuq_drink_caffeine === 'כן'" class="">
                        <div *ngIf="selected.nuq_drink_caffeine_items">
                            <span class="">שותה </span>
                            <span class="font-bold"> {{ selected.nuq_drink_caffeine_items.join(', ') }}</span>
                        </div>
                        <div *ngIf="selected['nuq_drink_caffeine_cups']" class="mb75">
                            <span *ngIf="selected['nuq_drink_caffeine_cups'] === 'משחק'" class="font-bold">רק ביום של משחק</span>
                            <span *ngIf="selected['nuq_drink_caffeine_cups'] === 'לעיתים רחוקות'" class="font-bold">לעיתים רחוקות - מקסימום אחד ביום</span>
                            <span *ngIf="selected['nuq_drink_caffeine_cups'] !== 'משחק' && selected['nuq_drink_caffeine_cups'] !== 'לעיתים רחוקות'" class="font-bold">{{ selected['nuq_drink_caffeine_cups'] }} ביום</span>
                        </div>
                        <div *ngIf="selected['nuq_drink_caffeine_last']" class="">
                            <span class="">כוס המשקה המכילה קפאין המאוחרת ביותר תהיה </span>
                            <span *ngIf="selected['nuq_drink_caffeine_last'] === 'בוקר'" class="font-bold">רק בבוקר</span>
                            <span *ngIf="selected['nuq_drink_caffeine_last'] === 'צהריים'" class="font-bold">הכי מאוחר בצהריים</span>
                            <span *ngIf="selected['nuq_drink_caffeine_last'] === 'אחרי הצהריים'" class="font-bold">הכי מאוחר אחרי הצהריים</span>
                            <span *ngIf="selected['nuq_drink_caffeine_last'] === 'ערב'" class="font-bold">בערב</span>
                        </div>
                    </div>
                </div>
            </ng-template>

            <div class="row healthquiz-box">
                <div class="w40" *ngIf="selectedA">
                    <ng-container *ngTemplateOutlet="template3; context: { selected: selectedA, showOptimal: true }"></ng-container>
                </div>
                <div class="w40" *ngIf="selectedB">
                    <ng-container *ngTemplateOutlet="template3; context: { selected: selectedB }"></ng-container>
                </div>
            </div>

            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's10', subPrefix: '', title: 'הערות נוזלים ללא אימון' }"></ng-container>
            </ng-container>


            <ng-template #template4 let-selected="selected">
                <h3>אופן האכילה (מסכים)</h3>
                <div *ngIf="selected.nuq_eat_near_screen">
                    <span>אני אוכל את הארוחות שלי </span>
                    <span *ngIf="selected.nuq_eat_near_screen == 'מול מסך'" class="font-bold">מול מסך</span>
                    <span *ngIf="selected.nuq_eat_near_screen == 'מול מסך'" class="font-bold"> 
                        <ng-container [ngSwitch]="selected.nuq_eat_near_screen_status">
                            <ng-container *ngSwitchCase="'לעיתים נדירות'">לעיתים נדירות</ng-container>
                            <ng-container *ngSwitchCase="'1-2 פעמים בשבוע'">1-3 פעמים בשבוע</ng-container>
                            <ng-container *ngSwitchCase="'1-3 פעמים בשבוע'">1-3 פעמים בשבוע</ng-container>
                            <ng-container *ngSwitchCase="'3-5 פעמים בשבוע'">4-6 פעמים בשבוע</ng-container>
                            <ng-container *ngSwitchCase="'4-6 פעמים בשבוע'">4-6 פעמים בשבוע</ng-container>
                            <ng-container *ngSwitchDefault>{{selected.nuq_eat_near_screen_status}}</ng-container>
                        </ng-container>
                    </span>
                    <span *ngIf="selected.nuq_eat_near_screen == 'ללא מסכים'" class="font-bold">מסודר מול שולחן</span>
                </div>
            </ng-template>

            <div class="row healthquiz-box">
                <div class="w40" *ngIf="selectedA">
                    <ng-container *ngTemplateOutlet="template4; context: { selected: selectedA }"></ng-container>
                </div>
                <div class="w40" *ngIf="selectedB">
                    <ng-container *ngTemplateOutlet="template4; context: { selected: selectedB }"></ng-container>
                </div>
            </div>

            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'b', title: 'הערות תזונה כלליות' }"></ng-container>
            </ng-container>


            <ng-template #template5 let-selected="selected">
                <h3>אופן האכילה (אכילה בחוץ)</h3>
                <div *ngIf="selected.nuq_eat_outside">
                    <span>אני אוכל מחוץ לבית במסעדות או ברשתות המזון </span>
                    <span *ngIf="selected.nuq_eat_outside == 'לעיתים רחוקות'" class="font-bold">לעיתים רחוקות</span>
                    <span *ngIf="selected.nuq_eat_outside == 'כן'" class="font-bold">
                        <ng-container [ngSwitch]="selected.nuq_eat_outside_status">
                            <ng-container *ngSwitchCase="'0-1 פעמים בשבוע'">0-1 פעמים בשבוע</ng-container>
                            <ng-container *ngSwitchCase="'1-2 פעמים בשבוע'">1-2 פעמים בשבוע</ng-container>
                            <ng-container *ngSwitchCase="'3-5 פעמים בשבוע'">3-5 פעמים בשבוע</ng-container>
                            <ng-container *ngSwitchCase="'6+ פעמים בשבוע'">6+ פעמים בשבוע</ng-container>
                            <ng-container *ngSwitchDefault>{{selected.nuq_eat_outside_status}}</ng-container>
                        </ng-container>
                    </span>
                </div>
            </ng-template>

            <div class="row healthquiz-box">
                <div class="w40" *ngIf="selectedA">
                    <ng-container *ngTemplateOutlet="template5; context: { selected: selectedA }"></ng-container>
                </div>
                <div class="w40" *ngIf="selectedB">
                    <ng-container *ngTemplateOutlet="template5; context: { selected: selectedB }"></ng-container>
                </div>
            </div>

            <ng-template #template6 let-selected="selected">
                <h4>חטיפים ומתוקים</h4>
                <div *ngIf="selected.nuq_eat_snacks">
                    <span *ngIf="selected.nuq_eat_snacks === 'לא'" class="font-bold">לא אוכל חטיפים או ממתקים</span>
                    <div *ngIf="selected.nuq_eat_snacks === 'כן'" class="">
                        <div *ngIf="selected.nuq_eat_snacks_type">
                            <span class="">אוכל </span>
                            <span class="font-bold"> {{ selected.nuq_eat_snacks_type }}</span>
                            <span class="font-bold"> ({{ selected.nuq_eat_snacks_freq }})</span>
                        </div>
                        <div *ngIf="selected.nuq_eat_snacks_time" class="">
                            <span *ngIf="selected.nuq_eat_snacks_time === 'מתכנן'" class="font-bold">אני מתכנן את היום והשבוע כדי לא להגזים</span>
                            <span *ngIf="selected.nuq_eat_snacks_time === 'אחרי הארוחה'" class="font-bold">חייב משהו מתוק מיד לאחר ארוחה</span>
                            <span *ngIf="selected.nuq_eat_snacks_time === 'בין צהריים לערב'" class="font-bold">בדרך כלל בין ארוחת הצהרים לארוחת הערב</span>
                            <span *ngIf="selected.nuq_eat_snacks_time === 'אין משהו קבוע'" class="font-bold">מתי שיש לי מול העיניים - לא באופן קבוע</span>
                        </div>
                    </div>
                </div>
            </ng-template>

            <div class="row healthquiz-box">
                <div class="w40" *ngIf="selectedA">
                    <ng-container *ngTemplateOutlet="template6; context: { selected: selectedA }"></ng-container>
                </div>
                <div class="w40" *ngIf="selectedB">
                    <ng-container *ngTemplateOutlet="template6; context: { selected: selectedB }"></ng-container>
                </div>
            </div>

            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'c', title: 'הערות תזונה כלליות' }"></ng-container>
            </ng-container>

            <!-- <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'a', title: 'הערות תזונה כלליות' }"></ng-container>
            </ng-container> -->


            <ng-template #template7 let-selected="selected">
                <h3 *ngIf="selected.nuq_more_food_comments == 'כן'">הערות הרגלי אכילה</h3>
                <div *ngIf="selected.nuq_more_food_comments == 'כן'">
                    ברצוני לציין ש<span class="font-bold">{{selected.nuq_more_food_comments_text}}</span>
                </div>
            </ng-template>

            <div class="row healthquiz-box">
                <div class="w40" *ngIf="selectedA">
                    <ng-container *ngTemplateOutlet="template7; context: { selected: selectedA }"></ng-container>
                </div>
                <div class="w40" *ngIf="selectedB">
                    <ng-container *ngTemplateOutlet="template7; context: { selected: selectedB }"></ng-container>
                </div>
            </div>

            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's1', subPrefix: '', title: 'הערות לתחילת הדוח' }"></ng-container>
            </ng-container>




            <ng-container *ngIf="false">
            

            <h3>העדפות תזונה</h3>
            <div class="row healthquiz-box">

                <div class="w40" *ngIf="selectedA">

                    <ng-container *ngIf="selectedA.nuq_eat_no_limits">
                        <div class="font-16 font-bold">אין מגבלות מזון</div>
                    </ng-container>

                    <div *ngIf="selectedA.nuq_eat_no_limits == false && selectedA.nuq_food_does_not_eat_health_reason == true && selectedA.nuq_food_does_not_eat" class="basic-form">
                        <h4>מזונות שלא אוכל מסיבה בריאותית/מצפונית</h4>

                        <div>
                            <span>מסיבות בריאותיות, אני </span>
                            <span *ngIf="selectedA.nuq_food_does_not_eat === 'אחר'">לא אוכל </span>
                            <span *ngIf="selectedA.nuq_food_does_not_eat === 'צמחוני' || selectedA.nuq_food_does_not_eat === 'טבעוני'" class="font-bold">{{ selectedA.nuq_food_does_not_eat }} </span>
                            <span *ngIf="selectedA.nuq_food_does_not_eat === 'אחר'" class="font-bold">{{ selectedA.nuq_food_does_not_eat_other }} </span>
                            <span>כי </span>
                            <span class="font-bold">{{ (selectedA.nuq_food_does_not_eat_reason_arr || []).join(', ') }}</span>
                            <div class="font-bold" *ngIf="selectedA.nuq_food_does_not_eat_reason_arr && selectedA.nuq_food_does_not_eat_reason_arr.includes('אחר')">{{ selectedA.nuq_food_does_not_eat_reason_other }}</div>
                        </div>
                    </div>

                    <ng-container
                        *ngIf="selectedA.nuq_eat_no_limits == false && selectedA.nuq_food_does_not_like == true && (selectedA.nuq_food_does_not_like_arr.length > 0 || (selectedA.nuq_food_does_not_like_arr_fixed && selectedA.nuq_food_does_not_like_arr_fixed.length > 0))"
                        [ngTemplateOutlet]="arrTemplate" [ngTemplateOutletContext]="{
                            title: 'מזון שלא אוהב',
                            data: selectedA,
                            arrFieldName: 'nuq_food_does_not_like_arr',
                            descriptionFieldLabel: 'אני לא אוהב'
                        }">
                    </ng-container>

                    <ng-container
                        *ngIf="selectedA.nuq_eat_no_limits == false && selectedA.nuq_food_does_not_eat_health_reason == true && selectedA.nuq_food_does_not_eat_health_reason_arr.length > 0"
                        [ngTemplateOutlet]="arrTemplate" [ngTemplateOutletContext]="{
                            title: 'מזונות שלא אוכל מסיבה בריאותית/מצפונית',
                            data: selectedA,
                            arrFieldName: 'nuq_food_does_not_eat_health_reason_arr',
                            descriptionFieldLabel: 'מסיבות בריאותיות, אני לא אוכל'
                        }">
                    </ng-container>

                    <ng-container
                        *ngIf="selectedA.nuq_eat_no_limits == false && selectedA.nuq_like_but_try_avoid_eating == true && (selectedA.nuq_like_but_try_avoid_eating_arr.length > 0 || (selectedA.nuq_like_but_try_avoid_eating_arr_fixed && selectedA.nuq_like_but_try_avoid_eating_arr_fixed.length > 0))"
                        [ngTemplateOutlet]="arrTemplate" [ngTemplateOutletContext]="{
                            title: 'מזונות שאוהב אך נמנע מסיבה בריאותית',
                            data: selectedA,
                            arrFieldName: 'nuq_like_but_try_avoid_eating_arr',
                            descriptionFieldLabel: 'אני אוהב, אך משתדל להימנע מלאכול'
                        }">
                    </ng-container>

                    <ng-container
                        *ngIf="selectedA.nuq_eat_no_limits == false && selectedA.nuq_eat_a_lot == true && (selectedA.nuq_eat_a_lot_arr.length > 0 || (selectedA.nuq_eat_a_lot_arr_fixed && selectedA.nuq_eat_a_lot_arr_fixed.length > 0))"
                        [ngTemplateOutlet]="arrTemplate" [ngTemplateOutletContext]="{
                            title: 'מזונות שמרבה לאכול',
                            data: selectedA,
                            arrFieldName: 'nuq_eat_a_lot_arr',
                            descriptionFieldLabel: 'אני מרבה לאכול'
                        }">
                    </ng-container>

                </div>

                <div class="w40" *ngIf="selectedB">

                    <ng-container *ngIf="selectedB.nuq_eat_no_limits">
                        <div class="font-16 font-bold">אין מגבלות מזון</div>
                    </ng-container>

                    <div *ngIf="selectedB.nuq_eat_no_limits == false && selectedB.nuq_food_does_not_eat_health_reason == true && selectedB.nuq_food_does_not_eat" class="basic-form">
                        <h4>מזונות שלא אוכל מסיבה בריאותית/מצפונית</h4>

                        <div>
                            <span>מסיבות בריאותיות, אני </span>
                            <span *ngIf="selectedB.nuq_food_does_not_eat === 'אחר'">לא אוכל </span>
                            <span *ngIf="selectedB.nuq_food_does_not_eat === 'צמחוני' || selectedB.nuq_food_does_not_eat === 'טבעוני'" class="font-bold">{{ selectedB.nuq_food_does_not_eat }} </span>
                            <span *ngIf="selectedB.nuq_food_does_not_eat === 'אחר'" class="font-bold">{{ selectedB.nuq_food_does_not_eat_other }} </span>
                            <span>כי </span>
                            <span class="font-bold">{{ (selectedB.nuq_food_does_not_eat_reason_arr || []).join(', ') }}</span>
                            <div class="font-bold" *ngIf="selectedB.nuq_food_does_not_eat_reason_arr && selectedB.nuq_food_does_not_eat_reason_arr.includes('אחר')">{{ selectedB.nuq_food_does_not_eat_reason_other }}</div>
                        </div>
                    </div>

                    <ng-container
                        *ngIf="selectedB.nuq_eat_no_limits == false && selectedB.nuq_food_does_not_like == true && (selectedB.nuq_food_does_not_like_arr.length > 0 || (selectedB.nuq_food_does_not_like_arr_fixed && selectedB.nuq_food_does_not_like_arr_fixed.length > 0))"
                        [ngTemplateOutlet]="arrTemplate" [ngTemplateOutletContext]="{
                            title: 'מזון שלא אוהב',
                            data: selectedB,
                            arrFieldName: 'nuq_food_does_not_like_arr',
                            descriptionFieldLabel: 'אני לא אוהב'
                        }">
                    </ng-container>
                    

                    <ng-container
                        *ngIf="selectedB.nuq_eat_no_limits == false && selectedB.nuq_food_does_not_eat_health_reason == true && selectedB.nuq_food_does_not_eat_health_reason_arr.length > 0"
                        [ngTemplateOutlet]="arrTemplate" [ngTemplateOutletContext]="{
                            title: 'מזונות שלא אוכל מסיבה בריאותית/מצפונית',
                            data: selectedB,
                            arrFieldName: 'nuq_food_does_not_eat_health_reason_arr',
                            descriptionFieldLabel: 'מסיבות בריאותיות, אני לא אוכל'
                        }">
                    </ng-container>

                    <ng-container
                        *ngIf="selectedB.nuq_eat_no_limits == false && selectedB.nuq_like_but_try_avoid_eating == true && (selectedB.nuq_like_but_try_avoid_eating_arr.length > 0 || (selectedB.nuq_like_but_try_avoid_eating_arr_fixed && selectedB.nuq_like_but_try_avoid_eating_arr_fixed.length > 0))"
                        [ngTemplateOutlet]="arrTemplate" [ngTemplateOutletContext]="{
                            title: 'מזונות שאוהב אך נמנע מסיבה בריאותית',
                            data: selectedB,
                            arrFieldName: 'nuq_like_but_try_avoid_eating_arr',
                            descriptionFieldLabel: 'אני אוהב, אך משתדל להימנע מלאכול'
                        }">
                    </ng-container>

                    <ng-container
                        *ngIf="selectedB.nuq_eat_no_limits == false && selectedB.nuq_eat_a_lot == true && (selectedB.nuq_eat_a_lot_arr.length > 0 || (selectedB.nuq_eat_a_lot_arr_fixed && selectedB.nuq_eat_a_lot_arr_fixed.length > 0))"
                        [ngTemplateOutlet]="arrTemplate" [ngTemplateOutletContext]="{
                            title: 'מזונות שמרבה לאכול',
                            data: selectedB,
                            arrFieldName: 'nuq_eat_a_lot_arr',
                            descriptionFieldLabel: 'אני מרבה לאכול'
                        }">
                    </ng-container>

                </div>

            </div>

            <ng-container *ngIf="(selectedA && selectedA.nuq_eat_vegetables && selectedA.nuq_eat_fruits) || (selectedB && selectedB.nuq_eat_vegetables && selectedB.nuq_eat_fruits)">
                <h4>פירות וירקות</h4>
                <div class="row healthquiz-box">

                    <div class="w40" *ngIf="selectedA">
                        <div *ngIf="selectedA.nuq_eat_vegetables">
                            <span *ngIf="selectedA.nuq_eat_vegetables == 'לא'" class="font-bold">לא אוכל ירקות</span>
                            <span *ngIf="selectedA.nuq_eat_vegetables == 'כן'" class="">אוכל ירקות </span>
                            <span *ngIf="selectedA.nuq_eat_vegetables == 'כן'" class="font-bold"> {{selectedA.nuq_eat_vegetables_day}} </span>
                            <span *ngIf="selectedA.nuq_eat_vegetables == 'כן'" class=""> ביום</span>
                        </div>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <div *ngIf="selectedB.nuq_eat_vegetables">
                            <span *ngIf="selectedB.nuq_eat_vegetables == 'לא'" class="font-bold">לא אוכל ירקות</span>
                            <span *ngIf="selectedB.nuq_eat_vegetables == 'כן'" class="">אוכל ירקות </span>
                            <span *ngIf="selectedB.nuq_eat_vegetables == 'כן'" class="font-bold"> {{selectedB.nuq_eat_vegetables_day}} </span>
                            <span *ngIf="selectedB.nuq_eat_vegetables == 'כן'" class=""> ביום</span>
                        </div>
                    </div>

                </div>
                <div class="row healthquiz-box">

                    <div class="w40" *ngIf="selectedA">
                        <div *ngIf="selectedA.nuq_eat_fruits">
                            <span *ngIf="selectedA.nuq_eat_fruits == 'לא'" class="font-bold">לא אוכל פירות</span>
                            <span *ngIf="selectedA.nuq_eat_fruits == 'כן'" class="">אוכל פירות </span>
                            <span *ngIf="selectedA.nuq_eat_fruits == 'כן'" class="font-bold"> {{selectedA.nuq_eat_fruits_day}} </span>
                            <span *ngIf="selectedA.nuq_eat_fruits == 'כן'" class=""> ביום</span>
                        </div>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <div *ngIf="selectedB.nuq_eat_fruits">
                            <span *ngIf="selectedB.nuq_eat_fruits == 'לא'" class="font-bold">לא אוכל פירות</span>
                            <span *ngIf="selectedB.nuq_eat_fruits == 'כן'" class="">אוכל פירות </span>
                            <span *ngIf="selectedB.nuq_eat_fruits == 'כן'" class="font-bold"> {{selectedB.nuq_eat_fruits_day}} </span>
                            <span *ngIf="selectedB.nuq_eat_fruits == 'כן'" class=""> ביום</span>
                        </div>
                    </div>

                </div>
            </ng-container>

            <ng-container *ngIf="(selectedA && selectedA.nuq_new_food) || (selectedB && selectedB.nuq_new_food)">
                <h4>טעימות</h4>
                <div class="row healthquiz-box">

                    <div class="w40" *ngIf="selectedA">

                        <ng-container *ngIf="selectedA.nuq_new_food == 'לא'">
                            <div class="font-bold">לא טעמתי לאחרונה מזונות חדשים</div>
                        </ng-container>

                        <ng-container
                            *ngIf="selectedA.nuq_new_food == 'כן' && ((selectedA.nuq_new_food_arr && selectedA.nuq_new_food_arr.length > 0) || (selectedA.nuq_new_food_arr_fixed && selectedA.nuq_new_food_arr_fixed.length > 0))"
                            [ngTemplateOutlet]="arrTemplate" [ngTemplateOutletContext]="{
                                data: selectedA,
                                arrFieldName: 'nuq_new_food_arr',
                                descriptionFieldLabel: 'טעמתי',
                                feedbackFieldLabel: 'היה'
                            }">
                        </ng-container>

                    </div>

                    <div class="w40" *ngIf="selectedB">

                        <ng-container *ngIf="selectedB.nuq_new_food == 'לא'">
                            <div class="font-bold">לא טעמתי לאחרונה מזונות חדשים</div>
                        </ng-container>

                        <ng-container
                            *ngIf="selectedB.nuq_new_food == 'כן' && ((selectedB.nuq_new_food_arr && selectedB.nuq_new_food_arr.length > 0) || (selectedB.nuq_new_food_arr_fixed && selectedB.nuq_new_food_arr_fixed.length > 0))"
                            [ngTemplateOutlet]="arrTemplate" [ngTemplateOutletContext]="{
                                data: selectedB,
                                arrFieldName: 'nuq_new_food_arr',
                                descriptionFieldLabel: 'טעמתי',
                                feedbackFieldLabel: 'היה'
                            }">
                        </ng-container>

                    </div>

                </div>
            </ng-container>

            <ng-container *ngIf="(selectedA && selectedA.nuq_eat_snacks) || (selectedB && selectedB.nuq_eat_snacks)">
                <h4>חטיפים ומתוקים</h4>
                <div class="row healthquiz-box">

                    <div class="w40" *ngIf="selectedA">
                        <div *ngIf="selectedA.nuq_eat_snacks">
                            <span *ngIf="selectedA.nuq_eat_snacks === 'לא'" class="font-bold">לא אוכל חטיפים או ממתקים</span>
                            <div *ngIf="selectedA.nuq_eat_snacks === 'כן'" class="">
                                <div *ngIf="selectedA.nuq_eat_snacks_type">
                                    <span class="">אוכל </span>
                                    <span class="font-bold"> {{ selectedA.nuq_eat_snacks_type }}</span>
                                    <span class="font-bold"> ({{ selectedA.nuq_eat_snacks_freq }})</span>
                                </div>
                                <div *ngIf="selectedA.nuq_eat_snacks_time" class="">
                                    <span *ngIf="selectedA.nuq_eat_snacks_time === 'מתכנן'" class="font-bold">אני מתכנן את היום והשבוע כדי לא להגזים</span>
                                    <span *ngIf="selectedA.nuq_eat_snacks_time === 'אחרי הארוחה'" class="font-bold">חייב משהו מתוק מיד לאחר ארוחה</span>
                                    <span *ngIf="selectedA.nuq_eat_snacks_time === 'בין צהריים לערב'" class="font-bold">בדרך כלל בין ארוחת הצהרים לארוחת הערב</span>
                                    <span *ngIf="selectedA.nuq_eat_snacks_time === 'אין משהו קבוע'" class="font-bold">מתי שיש לי מול העיניים - לא באופן קבוע</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <div *ngIf="selectedB.nuq_eat_snacks">
                            <span *ngIf="selectedB.nuq_eat_snacks === 'לא'" class="font-bold">לא אוכל חטיפים או ממתקים</span>
                            <div *ngIf="selectedB.nuq_eat_snacks === 'כן'" class="">
                                <div *ngIf="selectedB.nuq_eat_snacks_type">
                                    <span class="">אוכל </span>
                                    <span class="font-bold"> {{ selectedB.nuq_eat_snacks_type }}</span>
                                    <span class="font-bold"> ({{ selectedB.nuq_eat_snacks_freq }})</span>
                                </div>
                                <div *ngIf="selectedB.nuq_eat_snacks_time" class="">
                                    <span *ngIf="selectedB.nuq_eat_snacks_time === 'מתכנן'" class="font-bold">אני מתכנן את היום והשבוע כדי לא להגזים</span>
                                    <span *ngIf="selectedB.nuq_eat_snacks_time === 'אחרי הארוחה'" class="font-bold">חייב משהו מתוק מיד לאחר ארוחה</span>
                                    <span *ngIf="selectedB.nuq_eat_snacks_time === 'בין צהריים לערב'" class="font-bold">בדרך כלל בין ארוחת הצהרים לארוחת הערב</span>
                                    <span *ngIf="selectedB.nuq_eat_snacks_time === 'אין משהו קבוע'" class="font-bold">מתי שיש לי מול העיניים - לא באופן קבוע</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </ng-container>

            <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <h4 class="ul-text">הערות תזונה כלליות</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('nuq_notes')" fieldKey="A" [oldNotes]="notesFormA.get('notes_nuq_a')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'nuq', 'A')" (editNoteEvent)="editNote($event, 'nuq', 'A')"></notes>
                    </div>
                    
                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('nuq_notes')" fieldKey="A" [oldNotes]="notesFormB.get('notes_nuq_a')" [timeCreated]="selectedB.createdAt"></notes>
                    </div>
                </div>
            </ng-container>
            <!-- <ng-container *ngIf="!(selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <ng-container *ngTemplateOutlet="templateS3; context: { subPrefix: 'a' }">
                </ng-container>
            </ng-container> -->
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'a', title: 'הערות תזונה כלליות' }"></ng-container>
            </ng-container>

            <ng-container *ngIf="selectedA || selectedB">
                <h4>נוזלים</h4>
                <div class="row healthquiz-box">

                    <div class="w40" *ngIf="selectedA">
                        <div *ngIf="selectedA.nuq_drink_estimate">
                            <span class="">הערכת נוזלים: </span>
                            <span class="font-bold">{{ selectedA.nuq_drink_estimate }}</span>
                            <span class="font-bold"> ליטר</span>
                        </div>
                        <div>
                            <span>{{'food_diary.OPTIMAL_FLUIDS' | translate}}: </span>
                            <ng-container *ngIf="userOptimalFluidsLiterNoPractice">
                                <span class="font-bold">{{ userOptimalFluidsLiterNoPractice }}</span>
                                <span> {{'food_diary.UNITS_LITER' | translate}}</span>
                            </ng-container>
                            <ng-container *ngIf="!userOptimalFluidsLiterNoPractice">
                                <span>{{'food_diary.OPTIMAL_FLUIDS_NO_WEIGHT' | translate}}</span>
                            </ng-container>
                        </div>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <div *ngIf="selectedB.nuq_drink_estimate">
                            <span class="">הערכת נוזלים: </span>
                            <span class="font-bold">{{ selectedB.nuq_drink_estimate }}</span>
                            <span class="font-bold"> ליטר</span>
                        </div>
                        <div>

                        </div>
                    </div>

                </div>
                <div class="row healthquiz-box">

                    <div class="w40" *ngIf="selectedA">
                        <div *ngIf="selectedA.nuq_personal_bottle">
                            <span class="font-bold">{{ selectedA.nuq_personal_bottle }}</span>
                            <span class=""> מסתובב עם בקבוק אישי</span>
                        </div>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <div *ngIf="selectedB.nuq_personal_bottle">
                            <span class="font-bold">{{ selectedB.nuq_personal_bottle }}</span>
                            <span class=""> מסתובב עם בקבוק אישי</span>
                        </div>
                    </div>

                </div>
            </ng-container>

            <ng-container *ngIf="(selectedA && selectedA.nuq_drink_sugar) || (selectedB && selectedB.nuq_drink_sugar)">
                <h4>שתיה ממותקת</h4>
                <div class="row healthquiz-box">

                    <div class="w40" *ngIf="selectedA">
                        <div *ngIf="selectedA.nuq_drink_sugar">
                            <span *ngIf="selectedA.nuq_drink_sugar == 'לא'" class="font-bold">לא שותה שתיה ממותקת</span>
                            <span *ngIf="selectedA.nuq_drink_sugar == 'כן'" class="">שותה </span>
                            <span *ngIf="selectedA.nuq_drink_sugar == 'כן'" class="font-bold"> {{selectedA.nuq_drink_sugar_freq}}</span>
                        </div>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <div *ngIf="selectedB.nuq_drink_sugar">
                            <span *ngIf="selectedB.nuq_drink_sugar == 'לא'" class="font-bold">לא שותה שתיה ממותקת</span>
                            <span *ngIf="selectedB.nuq_drink_sugar == 'כן'" class="">שותה </span>
                            <span *ngIf="selectedB.nuq_drink_sugar == 'כן'" class="font-bold"> {{selectedB.nuq_drink_sugar_freq}}</span>
                        </div>
                    </div>

                </div>
            </ng-container>

            <ng-container *ngIf="(selectedA && selectedA.nuq_drink_caffeine) || (selectedB && selectedB.nuq_drink_caffeine)">
                <h4>קפאין</h4>
                <div class="row healthquiz-box">

                    <div class="w40" *ngIf="selectedA">
                        <div *ngIf="selectedA.nuq_drink_caffeine">
                            <span *ngIf="selectedA.nuq_drink_caffeine === 'לא'" class="font-bold">לא שותה משקאות המכילים קפאין</span>
                            <div *ngIf="selectedA.nuq_drink_caffeine === 'כן'" class="">
                                <div *ngIf="selectedA.nuq_drink_caffeine_items">
                                    <span class="">שותה </span>
                                    <span class="font-bold"> {{ selectedA.nuq_drink_caffeine_items.join(', ') }}</span>
                                </div>
                                <div *ngIf="selectedA['nuq_drink_caffeine_cups']" class="mb75">
                                    <span *ngIf="selectedA['nuq_drink_caffeine_cups'] === 'משחק'" class="font-bold">רק ביום של משחק</span>
                                    <span *ngIf="selectedA['nuq_drink_caffeine_cups'] === 'לעיתים רחוקות'" class="font-bold">לעיתים רחוקות - מקסימום אחד ביום</span>
                                    <span *ngIf="selectedA['nuq_drink_caffeine_cups'] !== 'משחק' && selectedA['nuq_drink_caffeine_cups'] !== 'לעיתים רחוקות'" class="font-bold">{{ selectedA['nuq_drink_caffeine_cups'] }} ביום</span>
                                </div>
                                <div *ngIf="selectedA['nuq_drink_caffeine_last']" class="">
                                    <span class="">כוס המשקה המכילה קפאין המאוחרת ביותר תהיה </span>
                                    <span *ngIf="selectedA['nuq_drink_caffeine_last'] === 'בוקר'" class="font-bold">רק בבוקר</span>
                                    <span *ngIf="selectedA['nuq_drink_caffeine_last'] === 'צהריים'" class="font-bold">הכי מאוחר בצהריים</span>
                                    <span *ngIf="selectedA['nuq_drink_caffeine_last'] === 'אחרי הצהריים'" class="font-bold">הכי מאוחר אחרי הצהריים</span>
                                    <span *ngIf="selectedA['nuq_drink_caffeine_last'] === 'ערב'" class="font-bold">בערב</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <div *ngIf="selectedB.nuq_drink_caffeine">
                            <span *ngIf="selectedB.nuq_drink_caffeine === 'לא'" class="font-bold">לא שותה משקאות המכילים קפאין</span>
                            <div *ngIf="selectedB.nuq_drink_caffeine === 'כן'" class="">
                                <div *ngIf="selectedB.nuq_drink_caffeine_items">
                                    <span class="">שותה </span>
                                    <span class="font-bold"> {{ selectedB.nuq_drink_caffeine_items.join(', ') }}</span>
                                </div>
                                <div *ngIf="selectedB['nuq_drink_caffeine_cups']" class="mb75">
                                    <span *ngIf="selectedB['nuq_drink_caffeine_cups'] === 'משחק'" class="font-bold">רק ביום של משחק</span>
                                    <span *ngIf="selectedB['nuq_drink_caffeine_cups'] === 'לעיתים רחוקות'" class="font-bold">לעיתים רחוקות - מקסימום אחד ביום</span>
                                    <span *ngIf="selectedB['nuq_drink_caffeine_cups'] !== 'משחק' && selectedB['nuq_drink_caffeine_cups'] !== 'לעיתים רחוקות'" class="font-bold">{{ selectedB['nuq_drink_caffeine_cups'] }} ביום</span>
                                </div>
                                <div *ngIf="selectedB['nuq_drink_caffeine_last']" class="">
                                    <span class="">כוס המשקה המכילה קפאין המאוחרת ביותר תהיה </span>
                                    <span *ngIf="selectedB['nuq_drink_caffeine_last'] === 'בוקר'" class="font-bold">רק בבוקר</span>
                                    <span *ngIf="selectedB['nuq_drink_caffeine_last'] === 'צהריים'" class="font-bold">הכי מאוחר בצהריים</span>
                                    <span *ngIf="selectedB['nuq_drink_caffeine_last'] === 'אחרי הצהריים'" class="font-bold">הכי מאוחר אחרי הצהריים</span>
                                    <span *ngIf="selectedB['nuq_drink_caffeine_last'] === 'ערב'" class="font-bold">בערב</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </ng-container>

            <!-- <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS10">

            </ng-container> -->
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's10', subPrefix: '', title: 'הערות נוזלים ללא אימון' }"></ng-container>
            </ng-container>

            <!-- <ng-container *ngIf="(selectedA && selectedA.nuq_eat_breakfast) || (selectedB && selectedB.nuq_eat_breakfast)"> -->
            <ng-container *ngIf="selectedA || selectedB">
                <h3>ארוחת הבוקר</h3>
                <div class="row healthquiz-box">

                    <div class="w40" *ngIf="selectedA">
                        <div class="mb75">
                            <span *ngIf="selectedA.nuq_eat_breakfast" class="font-bold"> {{selectedA.nuq_eat_breakfast}}</span>
                            <span *ngIf="selectedA.nuq_eat_breakfast"> אוכל ארוחת בוקר לפני היציאה מהבית </span>
                            <span *ngIf="selectedA.nuq_eat_breakfast == 'לפעמים' || selectedA.nuq_eat_breakfast == 'לעיתים רחוקות' || selectedA.nuq_eat_breakfast == 'אף פעם'" class="font-bold">
                                <span>כי </span>
                                <!-- <span *ngIf="selectedA.nuq_eat_breakfast_reason != 'אחר'">{{selectedA.nuq_eat_breakfast_reason}}</span>
                                <span *ngIf="selectedA.nuq_eat_breakfast_reason == 'אחר'">{{selectedA.nuq_eat_breakfast_reason_other_details}}</span> -->
                                <span *ngFor="let reason of selectedA.nuq_eat_breakfast_reasons; let last = last;">
                                    {{ reason == 'אחר' ? selectedA.nuq_eat_breakfast_reason_other_details : reason }}
                                    {{ !last ? ', ' : '' }}
                                </span>
                            </span>
                        </div>
                        <div *ngIf="selectedA.nuq_school_sandwich == 'כן' || selectedA.nuq_school_sandwich == 'לא'">
                            <span>הכריכים לבית הספר </span>
                            <span *ngIf="selectedA.nuq_school_sandwich == 'לא'" class="font-bold">לא </span>
                            <span>מספיקים</span>
                        </div>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <div class="mb75">
                            <span *ngIf="selectedB.nuq_eat_breakfast" class="font-bold"> {{selectedB.nuq_eat_breakfast}}</span>
                            <span *ngIf="selectedB.nuq_eat_breakfast"> אוכל ארוחת בוקר לפני היציאה מהבית </span>
                            <span *ngIf="selectedB.nuq_eat_breakfast == 'לפעמים' || selectedB.nuq_eat_breakfast == 'לעיתים רחוקות' || selectedB.nuq_eat_breakfast == 'אף פעם'" class="font-bold">
                                <span>כי </span>
                                <!-- <span *ngIf="selectedB.nuq_eat_breakfast_reason != 'אחר'">{{selectedB.nuq_eat_breakfast_reason}}</span>
                                <span *ngIf="selectedB.nuq_eat_breakfast_reason == 'אחר'">{{selectedB.nuq_eat_breakfast_reason_other_details}}</span> -->
                                <span *ngFor="let reason of selectedB.nuq_eat_breakfast_reasons; let last = last;">
                                    {{ reason == 'אחר' ? selectedB.nuq_eat_breakfast_reason_other_details : reason }}
                                    {{ !last ? ', ' : '' }}
                                </span>
                            </span>
                        </div>
                        <div *ngIf="selectedB.nuq_school_sandwich == 'כן' || selectedB.nuq_school_sandwich == 'לא'">
                            <span>הכריכים לבית הספר </span>
                            <span *ngIf="selectedB.nuq_school_sandwich == 'לא'" class="font-bold">לא </span>
                            <span>מספיקים</span>
                        </div>
                    </div>

                </div>

                <!-- <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS4">
    
                </ng-container> -->
                <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                    <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's4', subPrefix: '', title: 'הערות ארוחת בוקר' }"></ng-container>
                </ng-container>

                <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                    <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's5', subPrefix: '', title: 'הערות ארוחת ביניים 1' }"></ng-container>
                </ng-container>

            </ng-container>

            <!-- <ng-container *ngIf="(selectedA && selectedA.nuq_eat_near_screen) || (selectedB && selectedB.nuq_eat_near_screen)"> -->
            <ng-container *ngIf="selectedA || selectedB">
                <h3>אופן האכילה (מסכים)</h3>
                <div class="row healthquiz-box">

                    <div class="w40" *ngIf="selectedA">
                        <div *ngIf="selectedA.nuq_eat_near_screen">
                            <span>אני אוכל את הארוחות שלי </span>
                            <span *ngIf="selectedA.nuq_eat_near_screen == 'מול מסך'" class="font-bold">מול מסך</span>
                            <span *ngIf="selectedA.nuq_eat_near_screen == 'מול מסך'" class="font-bold"> 
                                <ng-container [ngSwitch]="selected.nuq_eat_near_screen_status">
                                    <ng-container *ngSwitchCase="'לעיתים נדירות'">לעיתים נדירות</ng-container>
                                    <ng-container *ngSwitchCase="'1-2 פעמים בשבוע'">1-3 פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchCase="'1-3 פעמים בשבוע'">1-3 פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchCase="'3-5 פעמים בשבוע'">4-6 פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchCase="'4-6 פעמים בשבוע'">4-6 פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchDefault>{{selected.nuq_eat_near_screen_status}}</ng-container>
                                </ng-container>
                            </span>
                            <span *ngIf="selected.nuq_eat_near_screen == 'ללא מסכים'" class="font-bold">מסודר מול שולחן</span>
                        </div>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <div *ngIf="selectedB.nuq_eat_near_screen">
                            <span>אני אוכל את הארוחות שלי </span>
                            <span *ngIf="selectedB.nuq_eat_near_screen == 'מול מסך'" class="font-bold">מול מסך</span>
                            <span *ngIf="selectedB.nuq_eat_near_screen == 'מול מסך'" class="font-bold"> 
                                <ng-container [ngSwitch]="selected.nuq_eat_near_screen_status">
                                    <ng-container *ngSwitchCase="'לעיתים נדירות'">לעיתים נדירות</ng-container>
                                    <ng-container *ngSwitchCase="'1-2 פעמים בשבוע'">1-3 פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchCase="'1-3 פעמים בשבוע'">1-3 פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchCase="'3-5 פעמים בשבוע'">4-6 פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchCase="'4-6 פעמים בשבוע'">4-6 פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchDefault>{{selected.nuq_eat_near_screen_status}}</ng-container>
                                </ng-container>
                            </span>
                            <span *ngIf="selected.nuq_eat_near_screen == 'ללא מסכים'" class="font-bold">מסודר מול שולחן</span>
                        </div>
                    </div>

                </div>

                <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                    <h4 class="ul-text">הערות תזונה כלליות</h4>
                    <div class="row">
                        <div class="w40" *ngIf="selectedA">
                            <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('nuq_notes')" fieldKey="B" [oldNotes]="notesFormA.get('notes_nuq_b')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'nuq', 'B')" (editNoteEvent)="editNote($event, 'nuq', 'B')"></notes>
                        </div>
                        
                        <div class="w40" *ngIf="selectedB">
                            <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('nuq_notes')" fieldKey="B" [oldNotes]="notesFormB.get('notes_nuq_b')" [timeCreated]="selectedB.createdAt"></notes>
                        </div>
                    </div>
                </ng-container>
                <!-- <ng-container *ngIf="!(selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                    <ng-container *ngTemplateOutlet="templateS3; context: { subPrefix: 'b' }">
                    </ng-container>
                </ng-container> -->
                <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                    <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'b', title: 'הערות תזונה כלליות' }"></ng-container>
                </ng-container>

            </ng-container>

            <br>

            <!-- <ng-container *ngIf="(selectedA && selectedA.nuq_eat_outside) || (selectedB && selectedB.nuq_eat_outside)"> -->
            <ng-container *ngIf="selectedA || selectedB">
                <h3>אופן האכילה (אכילה בחוץ)</h3>
                <div class="row healthquiz-box">

                    <div class="w40" *ngIf="selectedA">
                        <div *ngIf="selectedA.nuq_eat_outside">
                            <span>אני אוכל מחוץ לבית במסעדות או ברשתות המזון </span>
                            <span *ngIf="selectedA.nuq_eat_outside == 'לעיתים רחוקות'" class="font-bold">לעיתים רחוקות</span>
                            <span *ngIf="selectedA.nuq_eat_outside == 'כן'" class="font-bold">
                                <ng-container [ngSwitch]="selected.nuq_eat_outside_status">
                                    <ng-container *ngSwitchCase="'0-1 פעמים בשבוע'">0-1 פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchCase="'1-2 פעמים בשבוע'">1-2 פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchCase="'3-5 פעמים בשבוע'">3-5 פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchCase="'6+ פעמים בשבוע'">6+ פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchDefault>{{selected.nuq_eat_outside_status}}</ng-container>
                                </ng-container>
                            </span>
                        </div>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <div *ngIf="selectedB.nuq_eat_outside">
                            <span>אני אוכל מחוץ לבית במסעדות או ברשתות המזון </span>
                            <span *ngIf="selectedB.nuq_eat_outside == 'לעיתים רחוקות'" class="font-bold">לעיתים רחוקות</span>
                            <span *ngIf="selectedB.nuq_eat_outside == 'כן'" class="font-bold">
                                <ng-container [ngSwitch]="selected.nuq_eat_outside_status">
                                    <ng-container *ngSwitchCase="'0-1 פעמים בשבוע'">0-1 פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchCase="'1-2 פעמים בשבוע'">1-2 פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchCase="'3-5 פעמים בשבוע'">3-5 פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchCase="'6+ פעמים בשבוע'">6+ פעמים בשבוע</ng-container>
                                    <ng-container *ngSwitchDefault>{{selected.nuq_eat_outside_status}}</ng-container>
                                </ng-container>
                            </span>
                        </div>
                    </div>

                </div>

                <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                    <h4 class="ul-text">הערות תזונה כלליות</h4>
                    <div class="row">
                        <div class="w40" *ngIf="selectedA">
                            <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('nuq_notes')" fieldKey="C" [oldNotes]="notesFormA.get('notes_nuq_c')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'nuq', 'C')" (editNoteEvent)="editNote($event, 'nuq', 'C')"></notes>
                        </div>
                        
                        <div class="w40" *ngIf="selectedB">
                            <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('nuq_notes')" fieldKey="C" [oldNotes]="notesFormB.get('notes_nuq_c')" [timeCreated]="selectedB.createdAt"></notes>
                        </div>
                    </div>
                </ng-container>
                <!-- <ng-container *ngIf="!(selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                    <ng-container *ngTemplateOutlet="templateS3; context: { subPrefix: 'c' }">
                    </ng-container>
                </ng-container> -->
                <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                    <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'c', title: 'הערות תזונה כלליות' }"></ng-container>
                </ng-container>

            </ng-container>

            <ng-container *ngIf="(selectedA && selectedA.nuq_more_food_comments == 'כן') || (selectedB && selectedB.nuq_more_food_comments == 'כן')">
            <!-- <ng-container *ngIf="selectedA || selectedB"> -->
                <h3>הערות הרגלי אכילה</h3>
                <div class="row healthquiz-box">
                    <div class="w40" *ngIf="selectedA">
                        <div *ngIf="selectedA.nuq_more_food_comments == 'כן'">
                            ברצוני לציין ש<span class="font-bold">{{selectedA.nuq_more_food_comments_text}}</span>
                        </div>
                    </div>
                    <div class="w40" *ngIf="selectedB">
                        <div *ngIf="selectedB.nuq_more_food_comments == 'כן'">
                            ברצוני לציין ש<span class="font-bold">{{selectedB.nuq_more_food_comments_text}}</span>
                        </div>
                    </div>
                </div>

                <!-- <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z'">
                    <h4 class="ul-text">הערות תזונה כלליות</h4>
                    <div class="row">
                        <div class="w40" *ngIf="selectedA">
                            <notes *ngIf="notesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('nuq_notes')" fieldKey="D" [oldNotes]="notesFormA.get('notes_nuq_d')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'nuq', 'D')" (editNoteEvent)="editNote($event, 'nuq', 'D')"></notes>
                        </div>
                        
                        <div class="w40" *ngIf="selectedB">
                            <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('nuq_notes')" fieldKey="D" [oldNotes]="notesFormB.get('notes_nuq_d')" [timeCreated]="selectedB.createdAt"></notes>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!(selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                    <ng-container *ngTemplateOutlet="templateS3; context: { subPrefix: 'd' }">
                    </ng-container>
                </ng-container> -->
                <!-- <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                    <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'd', title: 'הערות תזונה כלליות' }"></ng-container>
                </ng-container> -->

            </ng-container>

            <h3>&nbsp;</h3>

            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's1', subPrefix: '', title: 'הערות לתחילת הדוח' }"></ng-container>
            </ng-container>
            

            </ng-container>

            <ng-template #templateShared let-fieldKey="fieldKey" let-subPrefix="subPrefix" let-title="title">
                <h4 class="font-bold text-info ul-text">{{ title }}</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && notesFormA && sharedNotesFormA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [fieldKey]="fieldKey" [subKey]="subPrefix" [isFemale]="(user.usr_gender === 'נקבה')" [selectedGroup]="notesFormA.get(quizPrefix + '_notes')" [sharedGroup]="sharedNotesFormA.get('fdq_notes')" [notesArr]="notesList" (addNoteEvent)="addNote($event, quizPrefix, fieldKey, subPrefix)" (editNoteEvent)="editNote($event, quizPrefix, fieldKey, subPrefix)"></notes>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="selectedB._id" [fieldKey]="fieldKey" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="selectedB[quizPrefix + '_notes']"></notes>
                    </div>
                </div>                
            </ng-template>

            <div class="row">
                <div class="w40 buttons-row" *ngIf="selectedA">
                    <button class="plus-button" mat-raised-button (click)="deleteQuiz(selectedIdA)">
                        {{'general.deleteQuiz' | translate}}
                        <mat-icon>delete</mat-icon>
                    </button>
                    <!-- <button [routerLink]="['/Nutritionquiz/', selectedIdA]" class="plus-button" mat-raised-button>עבור לטופס</button> -->
                    <!-- <a target="quizwindow" [routerLink]="['/Nutritionquiz/', selectedIdA]" class="plus-button" mat-raised-button>עבור לטופס</a> -->
                    <a target="quizwindow" [routerLink]="['/Nutquiz/', selectedIdA]" class="plus-button" mat-raised-button>עבור לטופס</a>
                    <mat-slide-toggle [checked]="notesFormA.value.nuq_locked" (change)="toggleQuizLock($event, notesFormA)">{{'general.lockQuiz' | translate}}</mat-slide-toggle>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">
                </div> -->
                <div class="w40 buttons-row" *ngIf="selectedB">
                    <button class="plus-button" mat-raised-button (click)="deleteQuiz(selectedIdB)">
                        {{'general.deleteQuiz' | translate}}
                        <mat-icon>delete</mat-icon>
                    </button>
                    <!-- <button [routerLink]="['/Nutritionquiz/', selectedIdB]" class="plus-button" mat-raised-button>עבור לטופס</button> -->
                    <!-- <a target="quizwindow" [routerLink]="['/Nutritionquiz/', selectedIdB]" class="plus-button" mat-raised-button>עבור לטופס</a> -->
                    <a target="quizwindow" [routerLink]="['/Nutquiz/', selectedIdB]" class="plus-button" mat-raised-button>עבור לטופס</a>
                    <mat-slide-toggle [checked]="notesFormB.value.nuq_locked" (change)="toggleQuizLock($event, notesFormB)">{{'general.lockQuiz' | translate}}</mat-slide-toggle>
                </div>
            </div>

            <!-- <div class="mt125 text-center">
                <button mat-raised-button color="primary" class="w30" (click)="submitForm()">שמור הערות</button>
            </div> -->

        </div>
    </mat-expansion-panel>

</div>

<ng-template #arrTemplate let-title="title" let-data="data" let-arrFieldName="arrFieldName" let-descriptionFieldLabel="descriptionFieldLabel" let-feedbackFieldLabel="feedbackFieldLabel">
    <div class="basic-form">
        <h4 *ngIf="title">{{title}}</h4>
        <div *ngIf="data[arrFieldName + '_fixed'] && data[arrFieldName + '_fixed'].filter(otherFilter).length">
            <div class="mb75 w65">
                <span>{{descriptionFieldLabel}} </span>
                <span class="font-bold">
                    {{ data[arrFieldName + '_fixed'].filter(otherFilter).join(', ') }}
                </span>
            </div>
        </div>
        <div *ngFor="let control of (data[arrFieldName] || []); let i = index; let last = last;">
            <div [ngClass]="{'mb75' : !last}">

                <div class="w65">                
                    <span>{{descriptionFieldLabel}} </span>
                    <span class="font-bold">{{data[arrFieldName][i].description}}</span>                       
                </div>

                <div *ngIf="data[arrFieldName][i].feedback" class="w65">                
                    <span>{{feedbackFieldLabel}} </span>
                    <span class="font-bold">{{data[arrFieldName][i].feedback}}</span>                       
                </div>

            </div>
        </div>
    </div>
</ng-template>
