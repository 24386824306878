import { Component, ViewChild, TemplateRef, HostListener, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

import { appState } from '@services/app-state';
import { Appcalcs } from '@services/app-calcs';

import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { MatAccordion } from '@angular/material/expansion';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '@ngx-translate/core';

import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../../shared/image-dialog.component';

import * as moment from 'moment-timezone';

// import { environment } from '@environments/environment';

@Component({
  templateUrl: "./measurereport.component.html"
})
export class MeasurereportComponent {

  // isQa = !environment.production;

  @ViewChild('accordion', {static:true}) Accordion: MatAccordion;

  appState = appState;

  calcs = Appcalcs;
  calcDiff = Appcalcs.calcDiff;

  allNotes: any = [];

  allUsers: any[] = [];

  allFdqValues: any = [];

  busy = false;

  isAllShown = true;

  constructor(
    public dialog: MatDialog,
    private dialogService: ConfirmDialogService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public restApiService: RestApiService<any>
  ) {}

  ngOnInit() {

    
    this.route.params.subscribe(async params => {
      try {
        let chosenUsers: any;
        if (params.id) {
          if (['nutri', 'superadmin'].indexOf(this.appState.user.usr_type) === -1) {
            this.router.navigate([`/`]);
          }

          const data = await this.restApiService.service('measurereport').get(params.id);

          if (!data || !data.chosen_users || !data.chosen_users.length || (this.appState.user.usr_type !== 'superadmin' && data.created_by !== this.appState.user._id)) {
            this.router.navigate([`/`]);
            return;
          }

          chosenUsers = data.chosen_users;

        } else {
          if (['coach', 'nutri', 'admin', 'superadmin'].indexOf(this.appState.user.usr_type) === -1) {
            this.router.navigate([`/`]);
            return;
          }
        }

        const allUsers = await this.getAllUsers(chosenUsers);

        if (!allUsers || !allUsers.length) {
          this.router.navigate([`/`]);
          return;
        }

        // console.log('allUsers', allUsers);
        console.log('allUsers length', allUsers.length);

        const loadDetails = (user: any) => {
          if (this.appState.user.usr_type !== 'superadmin' && (user.usr_share_reports !== 'מאשר' || !user.usr_share_reports_parents)) {
            user.showNoShare = true;
            return user;
          }
          if (user.user_ntqs_filtered) {
            const userReports = user.user_reports || [];
            let userNtqs = user.user_ntqs_filtered.filter((ntq: any) => {
              return (ntq.ntq_weight && ntq.ntq_height && moment(ntq.createdAt).isAfter('2021-01-05')) && (!ntq.is_by_user || (userReports.find((rpt: any) => rpt.rpt_ntq_id === ntq._id || rpt.rpt_last_ntq_id === ntq._id)));
            });
            userNtqs = userNtqs.reverse();
            for (let val of userNtqs) {
              this.calcs.updatePercents(val, user);
            }
            user.userNtqs = userNtqs;
          }
          return user;
        }

        this.allNotes = await this.getAllNotes();

        this.allUsers = allUsers.map(loadDetails);
        this.allUsers.sort((a, b) => {

          return b.usr_team_name.localeCompare(a.usr_team_name);
        });

      } catch (e) {
        console.error(e);
        this.router.navigate([`/`]);
      }
    });

  }

  async getAllNotes() {
    return await this.restApiService.service('note').find({ 
      query: {
        // $limit: -1,
        $paginate: false,
        $limit: 9999,
        $select: ['_id', 'content', 'content_F'],
        // quiz_prefix: 'ntq',
        // quiz_note_type: 'B',
        $measurereport: true,
        $or: [
          {
            quiz_prefix: 'ntq',
            quiz_note_type: 'B',
          },
          {
            // quiz_prefix: 'fdq',
            quiz_note_type: 's11',
          },
        ],
        is_deleted: {
          $ne: true
        }
      },
    });
  }

  async getAllUsers(filterByIds?: any) {
    const query: any = {
      $userstable: true,
      usr_is_deleted: {
        $ne: true
      },
      usr_type: 'player',
      $sort: {
        // usr_fname: 1
        usr_last_login: -1
      },
      $skip: 0,
      $limit: 9999,
    };

    const queryFdq: any = {
      $paginate: false,
      $select: ['_id', 'createdAt', 'fdq_usr_id', 'fdq_notes', 'is_by_user', 'linked'],
      $sort: {
        // is_by_user: 1,
        createdAt: -1
      },
      $measurereport: true,
    };

    if (filterByIds) {
      query._id = {
        $in: filterByIds
      };
      queryFdq.fdq_usr_id = {
        $in: filterByIds
      };
    } else {
      // query.$limit = 100;
    }

    const users = await this.restApiService.service('users').find({ 
      query
    });

    let res = users.data;

    res = res.filter((user: any) => user.usr_fname);

    if (!filterByIds) {
      res.splice(100);
    }

    const byIds = res.map((user: any) => user._id);
    queryFdq.fdq_usr_id = {
      $in: byIds
    };
    
    const allFdqValues = await this.restApiService.service('fooddiaryquiz').find({ 
      query: queryFdq
    });
    this.allFdqValues = allFdqValues;

    res.sort((a, b) => a['usr_fname'].localeCompare(b['usr_fname']));
    res.sort((a, b) => (a['usr_team_name'] || '').localeCompare(b['usr_team_name']));
    res.sort((a, b) => (a['usr_club_name'] || '').localeCompare(b['usr_club_name']));

    return res;
  }

  closeAllPanels(){
      this.Accordion.closeAll();
  }
  openAllPanels(){
      this.Accordion.openAll();
  }

  imageClick(src: string) {
    this.openImageDialog(src);
  }

  openImageDialog(imgSrc: string) {
    const options = {
      maxWidth: "90vw",
      panelClass: "p-0-dialog",
      data: {
        imgSrc
      }
    };
    this.dialog.open(ImageDialogComponent, options);
  }
  
  clickReport(e, chosenId: string) {
    e.stopPropagation();
    window.open(`Userreport/${chosenId}`);
    // this.router.navigate([`Userreport/${chosenId}`]);
  }
  
  setBusy(e) {
    this.busy = e;
  }
  
  calcDiffIdx(ntqs: any, idxA: number, idxB: number, field: string, isPercent?: boolean) {
    if (ntqs && ntqs[idxA] && ntqs[idxB]) {
      let a = ntqs[idxA][field];
      let b = ntqs[idxB][field];
      if (field === 'ntq_fat') { // exception
        a = ntqs[idxA]['ntq_fat_percent_num'] ? ntqs[idxA]['ntq_fat_percent_num'] : ntqs[idxA]['ntq_fat_age'];
        b = ntqs[idxB]['ntq_fat_percent_num'] ? ntqs[idxB]['ntq_fat_percent_num'] : ntqs[idxB]['ntq_fat_age'];
      }
      const res = this.calcDiff(a, b);
      return res + (res && isPercent ? '%' : '');
    }
    return '';
  }

  newNtq(usr) {
    if (usr.usr_type !== 'player') {
      return;
    }
    
    const options = {
      title: 'מדידה חדשה',
      message: 'להוסיף מדידה חדשה למשתמש?',
      cancelText: 'לא',
      confirmText: 'כן'
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(async confirmed => {
        if (confirmed) {
          const res = await this.restApiService.service('nuttestquiz').create({
            ntq_usr_id: usr._id,
            ntq_form_is_real: true
          });
          if (res && res._id) {
            window.open(`Nuttestquiz/${res._id}`);
          }
        }
    });
  }

}
