<div class="full-loader" *ngIf="busy">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>

<div *ngIf="query != undefined" [ngClass]="{'loading-margin': busy}">
    <!-- <div class="fixed-buttons">
        <button type="button" mat-fab color="accent" [disabled]="!chosenIds.length" (click)="openPushDialog()">
            <mat-icon>add</mat-icon>
        </button>
    </div> -->

    <div class="row m-0">
        <h1>
            <span>רשימת התראות</span>
        </h1>
        <div class="centered-row m-0" *ngIf="chosenIds.length">
            <button type="button" class="m-l-5 m-r-5" mat-stroked-button (click)="sendAgainPopup()">
                שלח שוב
                <mat-icon>send</mat-icon>
            </button>
            <button type="button" class="m-l-5 m-r-5" mat-stroked-button (click)="markAsOk()">
                סמן כנקרא
                <mat-icon>done_all</mat-icon>
            </button>
        </div>
        <div class="centered-row m-0" *ngIf="chosenIds.length">
            <div class="mat-body text-danger font-bold m-l-20">
                {{ chosenIds.length }} 
                התראות נבחרו
            </div>
            <button type="button" class="plus-button" mat-raised-button (click)="unselectAll()">
                ביטול הכל
                <mat-icon>remove</mat-icon>
            </button>
        </div>
    </div>
    <vzn-data-table #tableEl [restService]="logRestService" [pageSize]="50" [columns]="columns" (rowDblClick)="onRowDblClick($event)" [query]="query" (loading)="tableLoading($event)">
    </vzn-data-table>
</div>

<!-- <ng-template #pushDialogTemplate let-form="form">
    <div class="basic-form mat-body" [formGroup]="form">

        <ng-container *ngIf="chosenIds.length">
            <div class="text-danger font-bold ul-text m-b-10">
                {{ chosenIds.length }} 
                משתמשים נבחרו
            </div>
            <div class="last-items-container m-b-10 m-r-10">
                <div class="m-b-5" *ngFor="let user of chosenUsers">
                    <span class="pointer font-12 font-bold" (click)="unselectRow(user)"> X </span>
                    <span class="font-italic">({{ user.usr_type_name || userTypes[user.usr_type] }})</span>
                     - 
                    {{user.usr_fname}} {{user.usr_lname}} - {{user.usr_phone}}
                </div>
            </div>
        </ng-container>
        
        <mat-radio-group class="m-b-10" formControlName="push_to_type">
            <mat-radio-button value="user">יוזר</mat-radio-button>
            <mat-radio-button value="parent">הורה</mat-radio-button>
        </mat-radio-group>

        <mat-form-field class="m-b-5">
            <input #pushTitle formControlName="push_title" matInput placeholder="כותרת ההתראה">
            <button type="button" matSuffix mat-icon-button [matMenuTriggerFor]="menuTitle">
                <mat-icon>more_vert</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field class="m-b-5">
            <textarea #pushContent formControlName="push_content" matInput cdkTextareaAutosize cdkAutosizeMinRows="3" placeholder="תוכן ההתראה"></textarea>
            <button type="button" matSuffix mat-icon-button [matMenuTriggerFor]="menuContent">
                <mat-icon>more_vert</mat-icon>
            </button>
        </mat-form-field>

        <mat-menu #menuTitle="matMenu">
            <button mat-menu-item (click)="addText(pushTitle, form.controls.push_title, 'שם_פרטי')">
                <span>שם פרטי</span>
            </button>
            <button mat-menu-item (click)="addText(pushTitle, form.controls.push_title, 'שם_משפחה')">
                <span>שם משפחה</span>
            </button>
            <button *ngIf="form.value.push_to_type === 'parent'" mat-menu-item (click)="addText(pushTitle, form.controls.push_title, 'שם_פרטי_ילד')">
                <span>שם פרטי ילד</span>
            </button>
            <button *ngIf="form.value.push_to_type === 'parent'" mat-menu-item (click)="addText(pushTitle, form.controls.push_title, 'שם_משפחה_ילד')">
                <span>שם משפחה ילד</span>
            </button>
        </mat-menu>

        <mat-menu #menuContent="matMenu">
            <button mat-menu-item (click)="addText(pushContent, form.controls.push_content, 'שם_פרטי')">
                <span>שם פרטי</span>
            </button>
            <button mat-menu-item (click)="addText(pushContent, form.controls.push_content, 'שם_משפחה')">
                <span>שם משפחה</span>
            </button>
            <button *ngIf="form.value.push_to_type === 'parent'" mat-menu-item (click)="addText(pushContent, form.controls.push_content, 'שם_פרטי_ילד')">
                <span>שם פרטי ילד</span>
            </button>
            <button *ngIf="form.value.push_to_type === 'parent'" mat-menu-item (click)="addText(pushContent, form.controls.push_content, 'שם_משפחה_ילד')">
                <span>שם משפחה ילד</span>
            </button>
        </mat-menu>

        <mat-checkbox class="m-b-5" formControlName="push_method_push">
            Push notification
        </mat-checkbox>
        <mat-checkbox *ngIf="appState.user.usr_type === 'superadmin'" class="m-b-5" formControlName="push_method_wap">
            Whatsapp message
        </mat-checkbox>
    </div>
</ng-template> -->

<ng-template #stateTemplate let-context="context" let-form="form">
    <div class="basic-form mat-body" [formGroup]="form">

        <div class="text-center mb125">
            <span class="font-bold"> {{form.value.userName}} </span>
            <span class="dl dir-ltr"> ({{form.value.currentPlan}}) </span>
        </div>

        <mat-card *ngIf="form.value.state">
            <div class="d-column" style="line-height: 35px;">

                <mat-checkbox *ngIf="form.value.state['heq']?.exist && !form.value.state['heq']?.locked" class="checkbox mb50" disabled [checked]="form.value.state['heq']?.done">
                    <!-- <button mat-stroked-button class="font-16">
                        {{'status.health' | translate}}
                    </button> -->
                    שאלון בריאות
                </mat-checkbox>
                
                <mat-checkbox *ngIf="form.value.state['prq']?.exist && !form.value.state['prq']?.locked" class="checkbox mb50" disabled [checked]="form.value.state['prq']?.done">
                    <!-- <button mat-stroked-button class="font-16">
                        {{'status.practice' | translate}}
                    </button> -->
                    אימונים ומאזן אנרגיה
                </mat-checkbox>
                
                <mat-checkbox *ngIf="form.value.state['slq']?.exist && !form.value.state['slq']?.locked" class="checkbox mb50" disabled [checked]="form.value.state['slq']?.done">
                    <!-- <button mat-stroked-button class="font-16">
                        {{'status.sleeping' | translate}}
                    </button> -->
                    הרגלי שינה
                </mat-checkbox>
                
                <mat-checkbox *ngIf="form.value.state['nuq']?.exist && !form.value.state['nuq']?.locked" class="checkbox mb50" disabled [checked]="form.value.state['nuq']?.done">
                    <!-- <button mat-stroked-button class="font-16">
                        {{'status.nutrition' | translate}}
                    </button> -->
                    הרגלי אכילה- שאלון תזונה
                </mat-checkbox>
                <ng-container *ngIf="form.value.state['btq']?.exist && !form.value.state['btq']?.locked">
                    <mat-checkbox class="checkbox mb50" disabled [checked]="form.value.state['btq_first']?.done || form.value.state['btq']?.done" *ngIf="(!form.value.state['btq_first']?.done && !form.value.state['btq']?.done) || (form.value.state['btq_first']?.done && form.value.state['btq']?.done)">
                        <!-- <button mat-stroked-button class="font-16">
                            {{'status.bloodtest_first' | translate}}
                        </button> -->
                        <!-- בדיקת דם ראשוני -->
                        בדיקת דם
                    </mat-checkbox>
                    <mat-checkbox class="checkbox mb50" disabled *ngIf="form.value.state['btq_first']?.done && !form.value.state['btq']?.done">
                        <!-- <button mat-stroked-button class="font-16">
                            {{'status.bloodtest' | translate}}
                        </button> -->
                        בדיקת דם
                    </mat-checkbox>
                </ng-container>

                <!-- <mat-checkbox *ngIf="form.value.state['fdq']?.exist && !form.value.state['fdq']?.locked" class="checkbox mb50" disabled [checked]="form.value.state['fdq']?.done">
                    יומן אכילה ולוז (3 ימים)
                </mat-checkbox> -->

                <mat-checkbox *ngIf="form.value.state['fdq']?.exist && !form.value.state['fdq']?.hidden && !form.value.state['fdq']?.locked" class="checkbox mb50" disabled [checked]="form.value.state['fdq'].done">
                    יומן אכילה ולוז (3 ימים)
                </mat-checkbox>

                <mat-checkbox *ngIf="form.value.state['measure_enabled'] && form.value.state['ntq']?.exist && !form.value.state['ntq']?.locked" class="checkbox mb75" disabled [checked]="form.value.state['ntq']?.done">
                    <!-- <button mat-stroked-button class="font-16">
                        {{'status.measurement' | translate}}
                    </button> -->
                    נתונים אנתרופומטריים
                </mat-checkbox>
                
            </div>
        </mat-card>

    </div>
</ng-template>

<!-- <ng-template #followTemplate let-context="context" let-form="form">
    <div class="basic-form mat-body" [formGroup]="form">

        <div class="text-center mb125">
            <span class="font-bold"> {{form.value.userName}} </span>
            <span class="dl dir-ltr"> ({{form.value.currentPlan}}) </span>
        </div>

        <div class="mb125" *ngIf="form.value.notes">
            <notes [isFemale]="form.value.isFemale" [isFollowMode]="true" [isReadonly]="true" [allNotes]="allNotesUnfiltered" [selectedGroup]="form.get('notes')" fieldKey="A"></notes>
        </div>
    </div>
</ng-template> -->

<ng-template #dateTimezoneTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngIf="value">{{ value | dateFormat : 'DD/MM/yyyy' }}</span>
</ng-template>
<ng-template #dateTimezoneFullTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <span *ngIf="value">{{ value | dateFormat : 'DD/MM/yyyy HH:mm:ss' }}</span>
</ng-template>

<ng-template #checkboxHdrTmpl let-prop="column.prop">
    <!-- <div class="pointer" (click)="checkboxHdrChange(!allChosen)">
        <mat-checkbox class="block-events" [indeterminate]="someChosen" [checked]="allChosen"></mat-checkbox>
    </div> -->
  </ng-template>

<ng-template #checkTmpl let-value="value" ngx-datatable-cell-template>
    <mat-icon class="userstable-status-icon valid" *ngIf="value">check</mat-icon>
</ng-template> 

<ng-template #checkboxTmpl let-value="value" let-row="row" ngx-datatable-cell-template>
    <mat-checkbox [checked]="chosenIds.includes(row._id)" (change)="selectRow($event, row)"></mat-checkbox>
</ng-template> 

<ng-template #lastNtqTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'" (dblclick)="newNtq($event, row)" class="pointer">
        <span *ngIf="!value">-</span>
        <span *ngIf="value">{{ value | dateFormat : 'DD/MM/yyyy' }}</span>
    </div>
</ng-template>

<!-- <ng-template #isFollowTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'" (dblclick)="isFollowPopup($event, row)" class="pointer">
        <span *ngIf="!value">-</span>
        <mat-icon class="userstable-status-icon valid" *ngIf="value">check</mat-icon>
    </div>
</ng-template> -->

<ng-template #totalReportsTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'">
        <span class="dl dir-ltr">
            {{ value || 0 }} / {{ row['usr_report_count'] || 0 }}
        </span>
    </div>
</ng-template>

<ng-template #foodDaysTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="value && row && row.usr_type ==='player'">
        <span class="dl dir-ltr">
            {{ value }} / 3
        </span>
    </div>
</ng-template>

<ng-template #quizDaysTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'" (dblclick)="userStatusPopup($event, row)" class="pointer">
        <ng-container *ngIf="row['quiz_saved'] == 3">
            <mat-icon class="userstable-status-icon invalid">priority_high</mat-icon>
            <span>({{ value }})</span>
        </ng-container>
        <span *ngIf="row['quiz_saved'] == 2">
            {{ value }}
        </span>
        <mat-icon class="userstable-status-icon valid" *ngIf="row['quiz_saved'] == 1">check</mat-icon>
    </div>
</ng-template>

<ng-template #bloodDaysTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <div *ngIf="row && row.usr_type ==='player'">
        <ng-container *ngIf="row['blood_saved'] == 3">
            <mat-icon class="userstable-status-icon invalid">priority_high</mat-icon>
            <span>({{ value }})</span>
        </ng-container>
        <span *ngIf="row['blood_saved'] == 2">
            {{ value }}
        </span>
        <ng-container *ngIf="row['blood_saved'] == 1">
            <mat-icon class="userstable-status-icon valid">check</mat-icon>
            <span class="valid-color" *ngIf="row['last_btq_date']">{{ row['last_btq_date'] | dateFormat : 'DD/MM/yyyy' }}</span>
        </ng-container>
    </div>
</ng-template>

<ng-template #contentHoverTmpl let-value="value" ngx-datatable-cell-template>
    <div [matTooltip]="value" matTooltipClass="font-14" aria-label="Content info" class="pointer">
        {{ value | slice : 0 : 30 }}...
    </div>
    
    <button mat-icon-button (click)="copyToClipboard(value)">
        <mat-icon>content_copy</mat-icon>
    </button>
</ng-template>
