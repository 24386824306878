import { Component, ViewChildren, QueryList, ViewChild, ElementRef, Pipe, PipeTransform, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';

// import { MatSelect } from '@angular/material/select';

import { appState } from '@services/app-state';
import { Apputils } from '@services/app-utils';

import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '@ngx-translate/core';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';
import { ImageDialogComponent } from '../../shared/image-dialog.component';

import { allCategoriesGroups, breakfastCategories, elevensesCategories, lunchCategories, teaCategories, dinnerCategories, nightCategories } from './categoriesData';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'labelById'
})
export class LabelByIdPipe implements PipeTransform {
  transform(itemId: string, itemsArr: any[], key = 'label'): any {
    if (itemsArr) {
      const item = itemsArr.find(val => val && val._id === itemId);
      return item && item[key] || itemId;
    }
    return null;
  }
}

@Component({
  templateUrl: "./nutquiz.component.html"
})
export class NutquizComponent {

  @ViewChild("mealDialogTemplate") mealDialogTemplate: TemplateRef<any>;
  @ViewChild("waterDialogTemplate") waterDialogTemplate: TemplateRef<any>;

  // @ViewChild("nuq_eat_no_limits_el") nuq_eat_no_limits_el: ElementRef;

  @ViewChildren("nuq_food_description_el") nuq_food_description_el: QueryList<any>

  @ViewChild("nuq_style_el") nuq_style_el: ElementRef;

  @ViewChild("nuq_eat_breakfast_el") nuq_eat_breakfast_el: ElementRef;
  @ViewChild("nuq_eat_breakfast_reason_el") nuq_eat_breakfast_reason_el: ElementRef;

  @ViewChild("nuq_eat_elevenses_el") nuq_eat_elevenses_el: ElementRef;
  @ViewChild("nuq_eat_elevenses_enough_food_el") nuq_eat_elevenses_enough_food_el: ElementRef;
  @ViewChild("nuq_eat_elevenses_no_school_el") nuq_eat_elevenses_no_school_el: ElementRef;

  @ViewChild("nuq_eat_lunch_el") nuq_eat_lunch_el: ElementRef;
  @ViewChild("nuq_eat_tea_el") nuq_eat_tea_el: ElementRef;
  @ViewChild("nuq_eat_dinner_el") nuq_eat_dinner_el: ElementRef;
  @ViewChild("nuq_eat_night_el") nuq_eat_night_el: ElementRef;

  @ViewChild("nuq_breakfast_time_el") nuq_breakfast_time_el: ElementRef;
  @ViewChild("nuq_elevenses_time_el") nuq_elevenses_time_el: ElementRef;
  @ViewChild("nuq_lunch_time_el") nuq_lunch_time_el: ElementRef;
  @ViewChild("nuq_tea_time_el") nuq_tea_time_el: ElementRef;
  @ViewChild("nuq_dinner_time_el") nuq_dinner_time_el: ElementRef;
  @ViewChild("nuq_night_time_el") nuq_night_time_el: ElementRef;

  // @ViewChild("nuq_school_sandwich_el") nuq_school_sandwich_el: ElementRef;
  @ViewChild("nuq_eat_near_screen_el") nuq_eat_near_screen_el: ElementRef;
  @ViewChild("nuq_eat_outside_el") nuq_eat_outside_el: ElementRef;

  @ViewChild("nuq_more_food_comments_el") nuq_more_food_comments_el: ElementRef;


  @ViewChild("nuq_eat_vegetables_el") nuq_eat_vegetables_el: ElementRef;
  @ViewChild("nuq_eat_fruits_el") nuq_eat_fruits_el: ElementRef;
  @ViewChild("nuq_drink_sugar_el") nuq_drink_sugar_el: ElementRef;

  @ViewChild("nuq_new_food_el") nuq_new_food_el: ElementRef;
  @ViewChildren("nuq_new_food_description_el") nuq_new_food_description_el: QueryList<any>

  @ViewChild("nuq_eat_snacks_el") nuq_eat_snacks_el: ElementRef;

  @ViewChild("nuq_drink_estimate_el") nuq_drink_estimate_el: ElementRef;
  @ViewChild("nuq_personal_bottle_el") nuq_personal_bottle_el: ElementRef;
  @ViewChild("nuq_drink_caffeine_el") nuq_drink_caffeine_el: ElementRef;

  @ViewChild("nuq_urine_color_el") nuq_urine_color_el: ElementRef;

  @ViewChildren("nuq_food_description_control") nuq_food_description_control: QueryList<any>;
  @ViewChildren("nuq_new_food_description_control") nuq_new_food_description_control: QueryList<any>;

  // @ViewChild("nuq_food_does_not_like_arr_fixed") nuq_food_does_not_like_arr_fixed: MatSelect;
  // @ViewChild("nuq_like_but_try_avoid_eating_arr_fixed") nuq_like_but_try_avoid_eating_arr_fixed: MatSelect;
  // @ViewChild("nuq_eat_a_lot_arr_fixed") nuq_eat_a_lot_arr_fixed: MatSelect;
  // @ViewChild("nuq_new_food_arr_fixed") nuq_new_food_arr_fixed: MatSelect;

  base_image_upload_folder_path = '';

  nutritionquizForm: FormGroup;

  quizPrefix = 'nuq';

  restService: any;
  uploadUrlService: any;

  checkboxOptions = [
    this.translate.instant("nutquiz.Q_EAT_BREAKFAST_REASON_A_1"),
    this.translate.instant("nutquiz.Q_EAT_BREAKFAST_REASON_A_2"),
    this.translate.instant("nutquiz.Q_EAT_BREAKFAST_REASON_A_3"),
    this.translate.instant("nutquiz.Q_EAT_BREAKFAST_REASON_A_4"),
    this.translate.instant("nutquiz.Q_EAT_BREAKFAST_REASON_A_5")
  ];

  // listFixedA = [
  //   { value: 'חציל' , text: this.translate.instant("nutrition.FOOD_1") },
  //   { value: 'קישוא' , text: this.translate.instant("nutrition.FOOD_2") },
  //   { value: 'דג' , text: this.translate.instant("nutrition.FOOD_3") },
  //   { value: 'טונה' , text: this.translate.instant("nutrition.FOOD_4") },
  //   { value: 'סרדינים' , text: this.translate.instant("nutrition.FOOD_5") },
  //   { value: 'מלפפון' , text: this.translate.instant("nutrition.FOOD_6") },
  //   { value: 'עגבניה' , text: this.translate.instant("nutrition.FOOD_7") },
  //   { value: 'פלפל' , text: this.translate.instant("nutrition.FOOD_8") },
  //   { value: 'גזר' , text: this.translate.instant("nutrition.FOOD_9") },
  //   { value: 'פטריות' , text: this.translate.instant("nutrition.FOOD_10") },
  //   { value: 'גבינה לבנה' , text: this.translate.instant("nutrition.FOOD_11") },
  //   { value: 'גבינה צהובה' , text: this.translate.instant("nutrition.FOOD_12") },
  //   { value: "קוטג'" , text: this.translate.instant("nutrition.FOOD_13") },
  //   { value: 'אחר' , text: this.translate.instant("nutrition.FOOD_14") },
  // ];
  // listFixedB = [
  //   { value: 'גלידה' , text: this.translate.instant("nutrition.FOOD_15") },
  //   { value: 'שוקולד' , text: this.translate.instant("nutrition.FOOD_16") },
  //   { value: 'ממתקים' , text: this.translate.instant("nutrition.FOOD_17") },
  //   { value: 'חטיפים מלוחים' , text: this.translate.instant("nutrition.FOOD_18") },
  //   { value: 'שתיה מתוקה' , text: this.translate.instant("nutrition.FOOD_19") },
  //   { value: 'מאפים' , text: this.translate.instant("nutrition.FOOD_20") },
  //   { value: 'אחר' , text: this.translate.instant("nutrition.FOOD_21") },
  // ];
  // listFixedC = [
  //   { value: 'שניצל' , text: this.translate.instant("nutrition.FOOD_22") },
  //   { value: "צ'יפס" , text: this.translate.instant("nutrition.FOOD_23") },
  //   { value: 'לחם' , text: this.translate.instant("nutrition.FOOD_24") },
  //   { value: 'ממתקים וקינוחים' , text: this.translate.instant("nutrition.FOOD_25") },
  //   { value: 'חטיפים מלוחים' , text: this.translate.instant("nutrition.FOOD_26") },
  //   { value: 'מוצרים עשירים בחלבון' , text: this.translate.instant("nutrition.FOOD_27") },
  //   { value: 'פחמימות' , text: this.translate.instant("nutrition.FOOD_28") },
  //   { value: 'ירקות' , text: this.translate.instant("nutrition.FOOD_29") },
  //   { value: 'אחר' , text: this.translate.instant("nutrition.FOOD_30") },
  // ];
  // listFixedNewFood = [
  //   { value: 'חציל' , text: this.translate.instant("") },
  //   { value: 'קישוא' , text: this.translate.instant("") },
  //   { value: 'דג' , text: this.translate.instant("") },
  //   { value: 'טונה' , text: this.translate.instant("") },
  //   { value: 'סרדינים' , text: this.translate.instant("") },
  //   { value: 'מלפפון' , text: this.translate.instant("") },
  //   { value: 'עגבניה' , text: this.translate.instant("") },
  //   { value: 'פלפל' , text: this.translate.instant("") },
  //   { value: 'גזר' , text: this.translate.instant("") },
  //   { value: 'פטריות' , text: this.translate.instant("") },
  //   { value: 'גבינה לבנה' , text: this.translate.instant("") },
  //   { value: 'גבינה צהובה' , text: this.translate.instant("") },
  //   { value: "קוטג'" , text: this.translate.instant("") },
  //   { value: 'אחר' , text: this.translate.instant("") },
  // ]

  selectedTab = 0;

  busy = false;

  formContext: any;

  appState = appState;
  allHours = Apputils.allHours;

  allHoursFrom5 = this.getHours(this.allHours, '05:00');
  allHoursFrom8 = this.getHours(this.allHours, '08:00');
  allHoursFrom11 = this.getHours(this.allHours, '11:00');
  allHoursFrom14 = this.getHours(this.allHours, '14:00');
  allHoursFrom17 = this.getHours(this.allHours, '17:00');
  allHoursFrom20 = this.getHours(this.allHours, '20:00');

  formInitiated = false;

  submitInProgress = false;

  isLocked = false;

  showErrors = false;

  formOwnerUsr: any;
  isUnderNineteen = false;
  isUnderEighteen = false;

  urineHexColorPlate = Apputils.urineHexColorPlate;

  allCategoriesGroups = allCategoriesGroups;

  breakfastCategories = breakfastCategories;
  elevensesCategories = elevensesCategories;
  lunchCategories = lunchCategories;
  teaCategories = teaCategories;
  dinnerCategories = dinnerCategories;
  nightCategories = nightCategories;

  validateObjectIngredients: any = {};

  // possible meal names (in order):
  // BREAKFAST
  // ELEVENSES (11:00 meal)
  // LUNCH
  // TEA (16:00 meal)
  // DINNER
  // NIGHT


  // validateObjectIngredients = {
  //   protein: false,
  //   carbs: false,
  //   fat: false,
  //   veg: false,
  //   fruits: false,
  //   sweets: false,
  //   snacks: false,
  //   sauce: false,
  //   powder: false,
  //   drink: false,
  // };

  // validateObjectFreq = {
  //   'BREAKFAST': {
  //     protein: false,
  //     carbs: false,
  //     fat: false,
  //     veg: false,
  //     fruits: false,
  //     sweets: false,
  //     snacks: false,
  //     sauce: false,
  //     powder: false,
  //     drink: false,
  //   },
  //   'ELEVENSES': {
  //     protein: false,
  //     carbs: false,
  //     fat: false,
  //     veg: false,
  //     fruits: false,
  //     sweets: false,
  //     snacks: false,
  //     sauce: false,
  //     powder: false,
  //     drink: false,
  //   },
  //   'LUNCH': {
  //     protein: false,
  //     carbs: false,
  //     fat: false,
  //     veg: false,
  //     fruits: false,
  //     sweets: false,
  //     snacks: false,
  //     sauce: false,
  //     powder: false,
  //     drink: false,
  //   },
  //   'TEA': {
  //     protein: false,
  //     carbs: false,
  //     fat: false,
  //     veg: false,
  //     fruits: false,
  //     sweets: false,
  //     snacks: false,
  //     sauce: false,
  //     powder: false,
  //     drink: false,
  //   },
  //   'DINNER': {
  //     protein: false,
  //     carbs: false,
  //     fat: false,
  //     veg: false,
  //     fruits: false,
  //     sweets: false,
  //     snacks: false,
  //     sauce: false,
  //     powder: false,
  //     drink: false,
  //   },
  //   'NIGHT': {
  //     protein: false,
  //     carbs: false,
  //     fat: false,
  //     veg: false,
  //     fruits: false,
  //     sweets: false,
  //     snacks: false,
  //     sauce: false,
  //     powder: false,
  //     drink: false,
  //   },
  // };

  // validateObjectTypical = {
  //   'BREAKFAST': {
  //     protein: false,
  //     carbs: false,
  //     fat: false,
  //     veg: false,
  //     fruits: false,
  //     sweets: false,
  //     snacks: false,
  //     sauce: false,
  //     powder: false,
  //     drink: false,
  //   },
  //   'ELEVENSES': {
  //     protein: false,
  //     carbs: false,
  //     fat: false,
  //     veg: false,
  //     fruits: false,
  //     sweets: false,
  //     snacks: false,
  //     sauce: false,
  //     powder: false,
  //     drink: false,
  //   },
  //   'LUNCH': {
  //     protein: false,
  //     carbs: false,
  //     fat: false,
  //     veg: false,
  //     fruits: false,
  //     sweets: false,
  //     snacks: false,
  //     sauce: false,
  //     powder: false,
  //     drink: false,
  //   },
  //   'TEA': {
  //     protein: false,
  //     carbs: false,
  //     fat: false,
  //     veg: false,
  //     fruits: false,
  //     sweets: false,
  //     snacks: false,
  //     sauce: false,
  //     powder: false,
  //     drink: false,
  //   },
  //   'DINNER': {
  //     protein: false,
  //     carbs: false,
  //     fat: false,
  //     veg: false,
  //     fruits: false,
  //     sweets: false,
  //     snacks: false,
  //     sauce: false,
  //     powder: false,
  //     drink: false,
  //   },
  //   'NIGHT': {
  //     protein: false,
  //     carbs: false,
  //     fat: false,
  //     veg: false,
  //     fruits: false,
  //     sweets: false,
  //     snacks: false,
  //     sauce: false,
  //     powder: false,
  //     drink: false,
  //   },
  // };

  allMealsGroups = {
    'BREAKFAST': {
      title: 'ארוחת בוקר',
      categories: this.breakfastCategories,
    },
    'ELEVENSES': {
      title: 'ארוחת ביניים 1',
      categories: this.elevensesCategories,
    },
    'LUNCH': {
      title: 'ארוחת צהרים',
      categories: this.lunchCategories,
    },
    'TEA': {
      title: 'ארוחת ביניים 2',
      categories: this.teaCategories,
    },
    'DINNER': {
      title: 'ארוחת ערב',
      categories: this.dinnerCategories,
    },
    'NIGHT': {
      title: 'ארוחת לילה',
      categories: this.nightCategories,
    },
  }

  fixedCatList = [
    '62de66d93b8fa700181f3da1',
    '6281668407c43200163314b0',
    '62ef80d59032870018e63f76',
    '62816ca207c43200163314e2',
    '63150ae95f495000183e5b4a',
    '62ea6e4db3d236001827bb3a',
    '63150a535f495000183e5b46',
    '63150a6e5f495000183e5b47',
    '628162f707c432001633149e',
    '6315097e5f495000183e5b45',
    '63150b335f495000183e5b4d',
    '63150b5c5f495000183e5b50',
    '63150c255f495000183e5b55',
    '62e1ae4ce5b3a300185a2d9a',
  ];

  fixedCantEatList = [
    '62ef80d59032870018e63f76',
    '62816ca207c43200163314e2',
    '63150ae95f495000183e5b4a',
    '62ea6e4db3d236001827bb3a',
    '63150a535f495000183e5b46',
    '63150a6e5f495000183e5b47',
    '628162f707c432001633149e',
    '6315097e5f495000183e5b45',
    '63150b335f495000183e5b4d',
    '63150b5c5f495000183e5b50',
    '63150c255f495000183e5b55',
    '62e1ae4ce5b3a300185a2d9a',
  ];

  fixedCantAvoidList = [
    '63150e6d5f495000183e5b56',
    '63150e915f495000183e5b57',
    '63150ec15f495000183e5b58',
    '63150f185f495000183e5b59',
    '62d7f4da89644100180e7638',
    '63150f725f495000183e5b5a',
    '6315097e5f495000183e5b45',
    '62814308037f6000164a4ff5',
    '63150ff55f495000183e5b5b',
    '6315100f5f495000183e5b5c',
    '631510a35f495000183e5b5d',
    '631511125f495000183e5b5e',
  ];

  nutallergies: any[] = [];
  nutcategories: any[] = [];
  nutingredients: any[] = [];
  nuttags: any[] = [];
  nuttypes: any[] = [];

  // allProducts: any[] = [];
  allProducts: any[];
  allProductsNutStyle: any[];
  allProductsByCategories: any = {};

  // allFooditems: any[] = [];

  // allCategoriesWithChildren: any = {};

  allOptionsMeals: any = {
    BREAKFAST: {},
    ELEVENSES: {},
    LUNCH: {},
    TEA: {},
    DINNER: {},
    NIGHT: {},
  }

  allOptionsIngredients = {
    protein: [],
    carbs: [],
    fat: [],
    veg: [],
    fruits: [],
    sweets: [],
    snacks: [],
    sauce: [],
    powder: [],
    drink: [],
  };

  listNewFood: any[] = [];
  listCantEat: any[] = [];
  listCantAvoid: any[] = [];

  myChosenProducts: any = {};
  myChosenCategories: any = {};

  mealDialogRef: MatDialogRef<CustomDialogComponent, any>;

  mealDialogForm: FormGroup;
  allFrags: string[] = [];

  inMealNut: any;
  inMealExtraNut: any;

  openExtra = false;

  nutArray = ['food_energy', 'protein', 'carbohydrates', 'total_fat'];
  nutExtraArray = [
    'alcohol',
    'moisture',
    'total_dietary_fiber',
    'calcium',
    'iron',
    'magnesium',
    'phosphorus',
    'potassium',
    'sodium',
    'zinc',
    'copper',
    'vitamin_a_iu',
    'vitamin_a_re',
    'carotene',
    'vitamin_e',
    'vitamin_c',
    'thiamin',
    'riboflavin',
    'niacin',
    'vitamin_B6',
    'folate',
    'vitamin_B12',
    'cholesterol',
    'satured_fat',
    'butyric',
    'caproic',
    'caprylic',
    'capric',
    'lauric',
    'myristic',
    'palmitic',
    'stearic',
    'oleic',
    'linoleic',
    'linolenic',
    'arachidonic',
    'docosahexanoic',
    'palmitoleic',
    'parinaric',
    'gadoleic',
    'eicosapentaenoic',
    'erucic',
    'docosapentaenoic',
    'mono_satured',
    'poly_satured',
    'choline',
    'fructose',
    'vitamin_d'
  ];
  nutNames = {
    'food_energy': 'קלוריות',
    'protein': 'חלבונים',
    'carbohydrates': 'פחמימות',
    'total_fat': 'שומנים',
  };
  nutExtraNames = {
    'alcohol': 'כהל',
    'moisture': 'לחות',
    'total_dietary_fiber': 'סיבים תזונתיים',
    'calcium': 'סידן',
    'iron': 'ברזל',
    'magnesium': 'מגנזיום',
    'phosphorus': 'זרחן',
    'potassium': 'אשלגן',
    'sodium': 'נתרן',
    'zinc': 'אבץ',
    'copper': 'נחושת',
    'vitamin_a_iu': 'ויטמין A (יב"ל)',
    'vitamin_a_re': 'ויטמין A (RAE)',
    'carotene': 'קרוטן',
    'vitamin_e': 'ויטמין E',
    'vitamin_c': 'ויטמין C',
    'thiamin': 'תיאמין',
    'riboflavin': 'ריבופלאוין',
    'niacin': 'ניאצין',
    'vitamin_B6': 'ויטמין B6',
    'folate': 'פולאצין',
    'vitamin_B12': 'ויטמין B12',
    'cholesterol': 'כולסטרול',
    'satured_fat': 'שומן רווי',
    'butyric': 'ח.בוטירית 4:0',
    'caproic': 'ח.קפרואית 6:0',
    'caprylic': 'ח.קפרילית 8:0',
    'capric': 'ח.קפרית 10:0',
    'lauric': 'ח.לאורית 12:0',
    'myristic': 'ח.מיריסטית 14:0',
    'palmitic': 'ח.פלמיטית 16:0',
    'stearic': 'ח.סטיארית 18:0',
    'oleic': 'ח.אולאית 18:1',
    'linoleic': 'ח.לינולאית 18:2',
    'linolenic': 'ח.לינולנית 18:3',
    'arachidonic': 'ח.ארכידונית 20:4',
    'docosahexanoic': 'ח.דוקוסאהקסנואית DHA 22:6',
    'palmitoleic': 'ח.פלמיטולאית16:1',
    'parinaric': 'ח.פארינארית 18:4',
    'gadoleic': 'ח.גדולאית 20:1',
    'eicosapentaenoic': 'ח.אייקוסאפנטאינואית EPA 20:5',
    'erucic': 'ח.ארוסית 22:1',
    'docosapentaenoic': 'ח.דוקוספנטאינואית DPA 22:5',
    'mono_satured': 'ח.שומן חד לא רווי',
    'poly_satured': 'ח.שומן רב לא רווי',
    'choline': 'כולין',
    'fructose': 'פרוקטוז',
    'vitamin_d': 'ויטמין D'
  };

  constructor(
    public dialog: MatDialog,
    private dialogService: ConfirmDialogService,
    private translate: TranslateService,
    private restApiService: RestApiService<any>,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer
  ) {
    this.restService = this.restApiService.service('nutquiz');
    this.uploadUrlService = this.restApiService.service('upload-url');

    this.formContext = this;

    this.nutritionquizForm = this.formBuilder.group({
      _id: [''],
      nuq_usr_id: [appState.user._id],//''
      nuq_form_is_real: [false],

      // nuq_food_does_not_like: [false],
      // nuq_food_does_not_like_arr: this.formBuilder.array([]), // food array:
      // nuq_food_does_not_like_arr_fixed: [null],
      // nuq_food_does_not_eat_health_reason: [false],
      // nuq_food_does_not_eat_health_reason_arr: this.formBuilder.array([]), // food array:
      // nuq_like_but_try_avoid_eating: [false],
      // nuq_like_but_try_avoid_eating_arr: this.formBuilder.array([]), // food array:
      // nuq_like_but_try_avoid_eating_arr_fixed: [null],
      // nuq_eat_a_lot: [false],
      // nuq_eat_a_lot_arr: this.formBuilder.array([]), // food array:
      // nuq_eat_a_lot_arr_fixed: [null],
      // nuq_eat_no_limits: [false],

      // nuq_food_does_not_eat: [''],
      // nuq_food_does_not_eat_other: [''],
      // // nuq_food_does_not_eat_reason_arr: this.formBuilder.array([]),


      nuq_style: [''],
      nuq_style_other: [''],

      nuq_food_does_not_eat_reason_arr: [null],
      nuq_food_does_not_eat_reason_other: [''],

      nuq_cant_eat_all: [false],
      nuq_cant_eat_arr: [null],
      nuq_cant_eat_is_other: [false],
      nuq_cant_eat_other: [''],

      nuq_cant_avoid_all: [false],
      nuq_cant_avoid_arr: [null],
      nuq_cant_avoid_is_other: [false],
      nuq_cant_avoid_other: [''],

      nuq_new_food: [''],
      nuq_new_food_arr: this.formBuilder.array([]),
      nuq_new_food_arr_fixed: [null],

      nuq_eat_vegetables: [''],
      nuq_eat_vegetables_day: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_eat_vegetables == 'כן',
        Validators.required,
        null)]],
      nuq_eat_fruits: [''],
      nuq_eat_fruits_day: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_eat_fruits == 'כן',
        Validators.required,
        null)]],

      nuq_eat_snacks: [''],
      nuq_eat_snacks_type: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_eat_snacks == 'כן',
        Validators.required,
        null)]],
      nuq_eat_snacks_freq: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_eat_snacks == 'כן',
        Validators.required,
        null)]],
      nuq_eat_snacks_time: ['', [this.conditionalValidator(() => (this.nutritionquizForm.value.nuq_eat_snacks == 'כן' && this.nutritionquizForm.value.nuq_eat_snacks_freq && this.nutritionquizForm.value.nuq_eat_snacks_freq != 'לעיתים רחוקות' && this.nutritionquizForm.value.nuq_eat_snacks_freq != '0-1 בשבוע'),
        Validators.required,
        null)]],

      nuq_eat_near_screen: [''],
      nuq_eat_near_screen_status: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_eat_near_screen == 'מול מסך',
        Validators.required,
        null)]],
      nuq_eat_outside: [''],
      nuq_eat_outside_status: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_eat_outside == 'כן',
        Validators.required,
        null)]],

      nuq_drink_estimate: [''],
      nuq_personal_bottle: [''],
      nuq_drink_sugar: [''],
      nuq_drink_sugar_freq: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_drink_sugar == 'כן',
        Validators.required,
        null)]],
      nuq_drink_caffeine: [''],
      nuq_drink_caffeine_items: [null],
      nuq_drink_caffeine_cups: [''],
      nuq_drink_caffeine_last: [''],

      // nuq_school_sandwich: [''],

      nuq_urine_color: [''],

      nuq_eat_breakfast: [''],
      // nuq_eat_breakfast_dairy_meat: [''],

      nuq_eat_breakfast_reasons: this.formBuilder.array([], [this.requiredIfValidator(() => this.nutritionquizForm.value.nuq_eat_breakfast == 'לפעמים' || this.nutritionquizForm.value.nuq_eat_breakfast == 'לעיתים רחוקות' || this.nutritionquizForm.value.nuq_eat_breakfast == 'אף פעם')]),
      nuq_eat_breakfast_reason_other_details: ['', [this.requiredIfValidator(() => this.nutritionquizForm.value.nuq_eat_breakfast_reasons.includes('אחר'))]],

      nuq_eat_elevenses: [''],
      // nuq_eat_elevenses_dairy_meat: [''],
      nuq_eat_elevenses_enough_food: [''],
      nuq_eat_elevenses_no_school: [''],

      nuq_eat_lunch: [''],
      nuq_eat_lunch_dairy_meat: [''],

      nuq_eat_tea: [''],
      nuq_eat_tea_dairy_meat: [''],

      nuq_eat_dinner: [''],
      nuq_eat_dinner_dairy_meat: [''],

      nuq_eat_night: [''],
      nuq_eat_night_dairy_meat: [''],

      nuq_breakfast_assets: this.formBuilder.array([]),
      nuq_elevenses_assets: this.formBuilder.array([]),
      nuq_lunch_assets: this.formBuilder.array([]),
      nuq_tea_assets: this.formBuilder.array([]),
      nuq_dinner_assets: this.formBuilder.array([]),
      nuq_night_assets: this.formBuilder.array([]),

      nuq_breakfast_time: [''],
      nuq_elevenses_time: [''],
      nuq_lunch_time: [''],
      nuq_tea_time: [''],
      nuq_dinner_time: [''],
      nuq_night_time: [''],

      nuq_is_breakfast_details: [false],
      nuq_is_elevenses_details: [false],
      nuq_is_lunch_details: [false],
      nuq_is_tea_details: [false],
      nuq_is_dinner_details: [false],
      nuq_is_night_details: [false],

      nuq_chosen_categories: [null],
      nuq_chosen_products: [null],

      nuq_is_other_protein: [false],
      nuq_is_other_carbs: [false],
      nuq_is_other_fat: [false],
      nuq_is_other_veg: [false],
      nuq_is_other_fruits: [false],
      nuq_is_other_sweets: [false],
      nuq_is_other_snacks: [false],
      nuq_is_other_sauce: [false],
      nuq_is_other_powder: [false],
      nuq_is_other_drink: [false],

      nuq_other_protein: this.formBuilder.array([]),
      nuq_other_carbs: this.formBuilder.array([]),
      nuq_other_fat: this.formBuilder.array([]),
      nuq_other_veg: this.formBuilder.array([]),
      nuq_other_fruits: this.formBuilder.array([]),
      nuq_other_sweets: this.formBuilder.array([]),
      nuq_other_snacks: this.formBuilder.array([]),
      nuq_other_sauce: this.formBuilder.array([]),
      nuq_other_powder: this.formBuilder.array([]),
      nuq_other_drink: this.formBuilder.array([]),

      nuq_disabled_protein: [false],
      nuq_disabled_carbs: [false],
      nuq_disabled_fat: [false],
      nuq_disabled_veg: [false],
      nuq_disabled_fruits: [false],
      nuq_disabled_sweets: [false],
      nuq_disabled_snacks: [false],
      nuq_disabled_sauce: [false],
      nuq_disabled_powder: [false],
      nuq_disabled_drink: [false],

      nuq_more_food_comments: [''],
      nuq_more_food_comments_text: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_more_food_comments == 'כן',
        Validators.required,
        null)]],

    },
      {
        validators: [this.formCustomValidator.bind(this)]
      }
    );

    // for (const field of ['nuq_food_does_not_like_arr', 'nuq_like_but_try_avoid_eating_arr', 'nuq_eat_a_lot_arr', 'nuq_new_food_arr']) {
    //   this.valueChangesFixed(field)
    // }

    // this.valueChangesFixed('nuq_new_food_arr')


    //filter all products on nut_style valueChanges
    this.nutritionquizForm.get('nuq_style').valueChanges.subscribe(newVal => {
      if (newVal) {
        this.allProductsNutStyle = this.filterProductsByUserPref(this.allProducts, this.nuttypes, newVal);
        // this.filterToCategoriesIngredients(this.allProductsNutStyle);
        this.filterToCategories(this.allProductsNutStyle);
      }
    });

    this.nutritionquizForm.get('nuq_cant_eat_all').valueChanges.subscribe(newVal => {
      // debugger
      if (newVal) {
        this.nutritionquizForm.patchValue({
          nuq_cant_eat_arr: null,
          nuq_cant_eat_is_other: false,
          nuq_cant_eat_other: '',
        }, { emitEvent: false });
        // this.nutritionquizForm.get('nuq_cant_eat_arr').disable({ emitEvent: false });
        this.nutritionquizForm.get('nuq_cant_eat_is_other').disable({ emitEvent: false });
        this.nutritionquizForm.get('nuq_cant_eat_other').disable({ emitEvent: false });
      } else {
        // this.nutritionquizForm.get('nuq_cant_eat_arr').enable({ emitEvent: false });
        this.nutritionquizForm.get('nuq_cant_eat_is_other').enable({ emitEvent: false });
        this.nutritionquizForm.get('nuq_cant_eat_other').enable({ emitEvent: false });
      }
    });
    this.nutritionquizForm.get('nuq_cant_avoid_all').valueChanges.subscribe(newVal => {
      if (newVal) {
        this.nutritionquizForm.patchValue({
          nuq_cant_avoid_arr: null,
          nuq_cant_avoid_is_other: false,
          nuq_cant_avoid_other: '',
        }, { emitEvent: false });
        // this.nutritionquizForm.get('nuq_cant_avoid_arr').disable({ emitEvent: false });
        this.nutritionquizForm.get('nuq_cant_avoid_is_other').disable({ emitEvent: false });
        this.nutritionquizForm.get('nuq_cant_avoid_other').disable({ emitEvent: false });
      } else {
        // this.nutritionquizForm.get('nuq_cant_avoid_arr').enable({ emitEvent: false });
        this.nutritionquizForm.get('nuq_cant_avoid_is_other').enable({ emitEvent: false });
        this.nutritionquizForm.get('nuq_cant_avoid_other').enable({ emitEvent: false });
      }
    });

    const dynamicDairyMeat = {
      'BREAKFAST': '',
      'ELEVENSES': '',
      'LUNCH': 'nuq_eat_lunch_dairy_meat',
      'TEA': 'nuq_eat_tea_dairy_meat',
      'DINNER': 'nuq_eat_dinner_dairy_meat',
      'NIGHT': 'nuq_eat_night_dairy_meat',
    }

    //filter ingredients on dairy_meat valueChanges
    for (const mealName in dynamicDairyMeat) {
      if (Object.prototype.hasOwnProperty.call(dynamicDairyMeat, mealName)) {
        const field = dynamicDairyMeat[mealName];
        if (field) {
          this.nutritionquizForm.get(field).valueChanges.subscribe(newVal => {

            let newProteinFilter = ['חלבון חלבי', 'חלבון בשרי', 'חלבון טבעוני',
              'חלבון פרווה', 'חלבון דגים'];
            let newCarbsFilter = ['פחמימה פרווה', 'פחמימה חלבי'];
            switch (newVal) {
              case 'לרוב בשרית':
                newProteinFilter = [
                  // 'חלבון חלבי',
                  'חלבון בשרי',
                  'חלבון טבעוני',
                  'חלבון פרווה',
                  'חלבון דגים',
                ];
                newCarbsFilter = [
                  // 'פחמימה חלבי',
                  'פחמימה פרווה',
                ];
                break;

              case 'לרוב חלבית':
                newProteinFilter = [
                  'חלבון חלבי',
                  // 'חלבון בשרי',
                  'חלבון טבעוני',
                  'חלבון פרווה',
                  'חלבון דגים',
                ];
                newCarbsFilter = [
                  'פחמימה חלבי',
                  'פחמימה פרווה',
                ];
                break;

              case 'גם וגם':
                newProteinFilter = [
                  'חלבון חלבי',
                  'חלבון בשרי',
                  'חלבון טבעוני',
                  'חלבון פרווה',
                  'חלבון דגים',
                ];
                newCarbsFilter = [
                  'פחמימה חלבי',
                  'פחמימה פרווה',
                ];
                break;

              default:
                break;
            }

            if (this.allMealsGroups[mealName]?.categories) {
              this.allMealsGroups[mealName].categories.protein.filterIngredients = newProteinFilter;
              this.allMealsGroups[mealName].categories.carbs.filterIngredients = newCarbsFilter;
            }

            if (this.allProductsNutStyle) {
              this.filterToCategories(this.allProductsNutStyle, [mealName]);
            }

            // if (newVal) {
            //   console.log('newVal', newVal);
            //   let newProteinFilter = ['חלבון חלבי', 'חלבון בשרי', 'חלבון טבעוני', 
            //   'חלבון פרווה', 'חלבון דגים'];
            //   let newCarbsFilter = ['פחמימה פרווה', 'פחמימה חלבי'];
            //   switch (newVal) {
            //     case 'לרוב בשרית':
            //       newProteinFilter = [
            //         // 'חלבון חלבי',
            //         'חלבון בשרי',
            //         'חלבון טבעוני',
            //         'חלבון פרווה', 
            //         'חלבון דגים', 
            //       ];
            //       newCarbsFilter = [
            //         // 'פחמימה חלבי',
            //         'פחמימה פרווה',
            //       ];
            //       break;

            //     case 'לרוב חלבית':
            //       newProteinFilter = [
            //         'חלבון חלבי',
            //         // 'חלבון בשרי',
            //         'חלבון טבעוני',
            //         'חלבון פרווה', 
            //         'חלבון דגים', 
            //       ];
            //       newCarbsFilter = [
            //         'פחמימה חלבי',
            //         'פחמימה פרווה',
            //       ];
            //       break;

            //     case 'גם וגם':
            //       newProteinFilter = [
            //         'חלבון חלבי',
            //         'חלבון בשרי',
            //         'חלבון טבעוני',
            //         'חלבון פרווה', 
            //         'חלבון דגים', 
            //       ];
            //       newCarbsFilter = [
            //         'פחמימה חלבי',
            //         'פחמימה פרווה',
            //       ];
            //       break;

            //     default:
            //       break;
            //   }
            //   const idxProtein = this.allMealsGroups[key]?.categories?.findIndex(cat => cat.title === 'מקור לחלבון');
            //   if (idxProtein !== undefined && idxProtein !== -1) {
            //     this.allMealsGroups[key].categories[idxProtein].filterIngredients = newProteinFilter;
            //   }
            //   const idxCarbs = this.allMealsGroups[key]?.categories?.findIndex(cat => cat.title === 'מקור לפחמימה');
            //   if (idxCarbs !== undefined && idxCarbs !== -1) {
            //     this.allMealsGroups[key].categories[idxCarbs].filterIngredients = newCarbsFilter;
            //   }
            // }
          });
        }
      }
    }

    // window["rf"] = this.nutritionquizForm;

    this.route.params.subscribe(async params => {
      // console.log('params', params);
      if (params.id) {
        const data = await this.restService.get(params.id);
        const quizUsrId = data.nuq_usr_id;
        if (!quizUsrId) {
          return;
        }
        // const usr = await this.restApiService.service('users').get(quizUsrId).catch(e => { console.error(e) });
        const usr = data.quizOwner;
        if (!usr) {
          return;
        }

        this.base_image_upload_folder_path = `nutquiz/nuq_assets_${params.id}/`;

        this.formOwnerUsr = usr;
        this.isUnderNineteen = Boolean(+(usr['usr_age_formatted'] || 0) < 19);
        this.isUnderEighteen = Boolean(+(usr['usr_age_formatted'] || 0) < 18);

        if (this.appState.user.usr_type === 'superadmin' || this.appState.user.usr_type === 'nutri') {
          this.appState.quizUser = usr;
        }

        if (this.translate.currentLang !== 'en') {
          if (data.quizOwner?.usr_gender === 'נקבה') {
            this.translate.use('he_F');
          } else {
            this.translate.use('he');
          }
        }

        this.isLocked = data && data.nuq_locked;
        if (this.appState.user.usr_type === 'parent' || this.appState.user.usr_type === 'nutri') {
          this.isLocked = true;
        }
        if (this.appState.user.usr_type === 'nutri' && data.isReportEverPublished !== undefined) {
          this.isLocked = data.isReportEverPublished;
        }
        // if (this.isUnderNineteen) {
        //     this.nutritionquizForm.get('nuq_school_sandwich').setValidators(Validators.required);
        // }
        if (!data.savedAt && !data.nuq_style) {
          const lastQuiz = await this.restService.getLast({
            nuq_usr_id: quizUsrId,
            _id: {
              $ne: params.id
            }
          });
          if (lastQuiz[0]) {
            const { nuq_style, nuq_style_other, nuq_food_does_not_eat_reason_arr, nuq_food_does_not_eat_reason_other, nuq_cant_eat_all, nuq_cant_eat_arr, nuq_cant_eat_is_other, nuq_cant_eat_other, nuq_cant_avoid_all, nuq_cant_avoid_arr, nuq_cant_avoid_is_other, nuq_cant_avoid_other, nuq_chosen_categories, nuq_chosen_products,

              nuq_is_other_protein,
              nuq_is_other_carbs,
              nuq_is_other_fat,
              nuq_is_other_veg,
              nuq_is_other_fruits,
              nuq_is_other_sweets,
              nuq_is_other_snacks,
              nuq_is_other_sauce,
              nuq_is_other_powder,
              nuq_is_other_drink,

              nuq_other_protein,
              nuq_other_carbs,
              nuq_other_fat,
              nuq_other_veg,
              nuq_other_fruits,
              nuq_other_sweets,
              nuq_other_snacks,
              nuq_other_sauce,
              nuq_other_powder,
              nuq_other_drink,

              nuq_disabled_protein,
              nuq_disabled_carbs,
              nuq_disabled_fat,
              nuq_disabled_veg,
              nuq_disabled_fruits,
              nuq_disabled_sweets,
              nuq_disabled_snacks,
              nuq_disabled_sauce,
              nuq_disabled_powder,
              nuq_disabled_drink,

            } = lastQuiz[0];

            // const strippedChosenCategories = this.stripLastChosen(nuq_chosen_categories);
            // console.log('strippedChosenCategories', strippedChosenCategories);

            const stripped = {
              nuq_style, nuq_style_other, nuq_food_does_not_eat_reason_arr, nuq_food_does_not_eat_reason_other, nuq_cant_eat_all, nuq_cant_eat_arr, nuq_cant_eat_is_other, nuq_cant_eat_other, nuq_cant_avoid_all, nuq_cant_avoid_arr, nuq_cant_avoid_is_other, nuq_cant_avoid_other, nuq_chosen_categories, nuq_chosen_products,

              nuq_is_other_protein,
              nuq_is_other_carbs,
              nuq_is_other_fat,
              nuq_is_other_veg,
              nuq_is_other_fruits,
              nuq_is_other_sweets,
              nuq_is_other_snacks,
              nuq_is_other_sauce,
              nuq_is_other_powder,
              nuq_is_other_drink,

              nuq_other_protein,
              nuq_other_carbs,
              nuq_other_fat,
              nuq_other_veg,
              nuq_other_fruits,
              nuq_other_sweets,
              nuq_other_snacks,
              nuq_other_sauce,
              nuq_other_powder,
              nuq_other_drink,

              nuq_disabled_protein,
              nuq_disabled_carbs,
              nuq_disabled_fat,
              nuq_disabled_veg,
              nuq_disabled_fruits,
              nuq_disabled_sweets,
              nuq_disabled_snacks,
              nuq_disabled_sauce,
              nuq_disabled_powder,
              nuq_disabled_drink,

            };

            this.nutritionquizForm.patchValue(stripped, { emitEvent: false });
            this.patchFormArrays(stripped);

            this.oldValuesPopup();
          }
          this.nutritionquizForm.get('nuq_usr_id').patchValue(data.nuq_usr_id, { emitEvent: false });
          this.nutritionquizForm.get('_id').patchValue(data._id, { emitEvent: false });
          if (lastQuiz[0]?.nuq_style) {
            setTimeout(() => {
              this.nutritionquizForm.get('nuq_style').patchValue(lastQuiz[0].nuq_style);
            }, 1500);
          }
        } else {
          // this.nutritionquizForm.patchValue(data, { emitEvent: false });
          this.patchFormArrays(data);
          this.nutritionquizForm.patchValue(data);
        }

        for (const key in dynamicDairyMeat) {
          if (Object.prototype.hasOwnProperty.call(dynamicDairyMeat, key)) {
            const field = dynamicDairyMeat[key];
            if (field) {
              this.nutritionquizForm.get(field).updateValueAndValidity();
            }
          }
        }

        if (this.isLocked) {
          this.nutritionquizForm.disable({ emitEvent: false });
        }
      } else {
        // const res = await this.restService.getLast({ nuq_usr_id: this.appState.user._id });
        // const navTo = res[0]?._id ? `Nutquiz/${res[0]._id}` : '/';
        // this.router.navigate([navTo], { replaceUrl: true });
        // return;
        if (this.appState.user.usr_type !== 'player') {
          this.router.navigate(['/'], { replaceUrl: true });
          return;
        }
        const res = await this.restService.getLast({ nuq_usr_id: this.appState.user._id });
        if (res && res[0]?._id) {
          const navTo = res[0]?._id ? `Nutquiz/${res[0]._id}` : '/';
          this.router.navigate([navTo], { replaceUrl: true });
        } else {
          const res = await this.restService.create({
            nuq_usr_id: this.appState.user._id,
            nuq_form_is_real: false
          });
          const navTo = res?._id ? `Nutquiz/${res._id}` : '/';
          this.router.navigate([navTo], { replaceUrl: true });
        }
        return;
      }

      // if (this.nutritionquizForm.value.nuq_eat_no_limits == true) {
      //   this.resetAndSetDisableNoLimitCheckBoxes(true);
      // }

      if (this.nutritionquizForm.value.nuq_chosen_products) {
        this.myChosenProducts = this.nutritionquizForm.value.nuq_chosen_products;
      }
      if (this.nutritionquizForm.value.nuq_chosen_categories) {
        this.myChosenCategories = this.nutritionquizForm.value.nuq_chosen_categories;
        this.fillMealsChildrenProducts();
        // this.updateChosenCategories({});
      }

      this.valueChangesFixed('nuq_new_food_arr')

      this.formInitiated = true;
    });

    // this.route.fragment.subscribe(frag => {

    //   this.allFrags.push(frag);
    //   // console.log('allFrags', this.allFrags);

    //   const lastFrag = this.allFrags.length > 1 ? this.allFrags[this.allFrags.length - 2] : '';

    //   // console.log('lastFrag', lastFrag);
    //   // console.log('frag', frag);

    //   if ((frag === 'meal' && (lastFrag === 'item' || lastFrag === 'search')) || (frag === 'search' && lastFrag === 'item')) {
    //     this.goBack(this.mealDialogForm, true);
    //   }

    //   if (this.dialog.openDialogs.length && this.mealDialogRef && ['meal', 'item', 'search'].indexOf(frag) === -1) {
    //     this.mealDialogRef.close();
    //   }

    // });
  }

  async newItemByBarcodeDialog(e: any) {
    const { product, mealType, nameType, isChecked, isCustom } = e;
    const isWater = product?._id === 'water';
    // const isCustom = product?._id === 'custom';

    if (isChecked) {
      const newMealForm = this.createMealItem(this.formContext);

      if (isCustom) {
        const nowUnix = moment().unix();
        const customId = `custom-${nowUnix}`;
        newMealForm.patchValue({
          fdq_day_info_item_id: customId,
          fdq_day_info_meal_name: mealType,
          fdq_day_info_item_type: 'MEAL',
          fdq_day_info_portion: '1',
        });
      } else if (isWater) {
        newMealForm.patchValue({
          fdq_day_info_item_id: 'water',
          fdq_day_info_meal_name: mealType,
          fdq_day_info_item_type: 'WATER',
          fdq_day_info_portion: '1',
        });
      } else {
        await this.addItemServer(newMealForm, product);
        newMealForm.get('fdq_day_info_meal_name').patchValue(mealType);
      }

      this.addOrEditMealItemDialog(newMealForm, false, nameType);
    } else {
      // this.removeProduct(product._id, mealType);
      if (isCustom) {
        this.removeCustomProduct(product.fdq_day_info_item_id, mealType, nameType);
      } else {
        this.removeProduct(product._id, mealType);
      }
    }
  }

  removeAllChosenProductsAfterRemoveIngredientCategory(catIds?: string[]) { // allProductsToRemove: any
    // const allIds = [];
    // if (allProductsToRemove) {
    //   for (const catName in allProductsToRemove) {
    //     if (Object.prototype.hasOwnProperty.call(allProductsToRemove, catName)) {
    //       const element = allProductsToRemove[catName];
    //       if (element?.length) {
    //         for (const item of element) {
    //           allIds.push(item._id);
    //         }
    //       }
    //     }
    //   }
    // }

    console.log('removeAllChosenProductsAfterRemoveIngredientCategory', catIds);

    if (!catIds?.length) {
      return;
    }

    const allIds = [];

    for (const catId of catIds) {
      if (this.allProductsByCategories?.[catId]?.length) {
        allIds.push(...this.allProductsByCategories?.[catId]);
      }
    }

    console.log('allIds', allIds);

    if (this.myChosenProducts && allIds?.length) {
      for (const mealType in this.myChosenProducts) {
        if (Object.prototype.hasOwnProperty.call(this.myChosenProducts, mealType)) {
          const element = this.myChosenProducts[mealType];
          if (typeof element === 'object') {
            for (const key in element) {
              if (allIds.includes(key)) {
                console.log('remove product after remove ingredient category', mealType, key);
                this.myChosenProducts[mealType][key] = undefined;
                delete this.myChosenProducts[mealType][key];
              }
            }
          }
        }
      }

      if (this.nutritionquizForm) {
        // console.log('this.myChosenProducts', this.myChosenProducts);
        this.nutritionquizForm.get('nuq_chosen_products').setValue(this.myChosenProducts);
        // console.log('nuq_chosen_products', this.nutritionquizForm.get('nuq_chosen_products')?.value);
      }
    }
  }

  async addItemServer(form: FormGroup, product: any) {
    const { _id, product_code, barcode } = product;

    this.busy = true;

    try {
      let res: any;
      if (barcode) {
        res = await this.restApiService.service('fooditem').find({
          query: {
            barcode: barcode,
            $limit: 1,
          },
        });
      }
      if (product_code) {
        const regex = new RegExp(product_code, 'i');
        res = await this.restApiService.service('products').find({
          query: {
            product_code: { $regex: product_code, $options: '$i' },
            // product_code: { $in: [ regex ] },
            $limit: 1,
          }
        });
      }
      if (res && res.data && res.data[0]) {
        await this.addFromServer(form, res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }

    this.busy = false;
  }

  async addFromServer(form: FormGroup, item: any) {
    const newItem = await this.newProductMapper(item);
    const { title, item_type, barcode, real_barcode, imgs, midas } = newItem;
    let foodType = 'FOOD';
    if (item_type && item_type.trim().toLowerCase() === 'liquid') {
      foodType = 'DRINK';
    }
    if (item_type && item_type.trim().toLowerCase() === 'solid') {
      foodType = 'FOOD';
    }
    if (!title || !foodType || (!barcode && !real_barcode)) {
      console.log("the item is missing some details");
      return;
    }

    const assets = imgs || [];
    const assetsArray: FormArray = form.get('fdq_day_info_assets') as FormArray;
    if (assets && assets.length > 0) {
      for (let i = 0; i < assets.length; i++) {
        const newFormElement = this.formBuilder.control({});
        assetsArray.push(newFormElement);
      }
    } else {
      assetsArray.clear();
    }

    const keysArray = ['food_energy', 'protein', 'carbohydrates', 'total_fat',
      'alcohol',
      'moisture',
      'total_dietary_fiber',
      'calcium',
      'iron',
      'magnesium',
      'phosphorus',
      'potassium',
      'sodium',
      'zinc',
      'copper',
      'vitamin_a_iu',
      'vitamin_a_re',
      'carotene',
      'vitamin_e',
      'vitamin_c',
      'thiamin',
      'riboflavin',
      'niacin',
      'vitamin_B6',
      'folate',
      'vitamin_B12',
      'cholesterol',
      'satured_fat',
      'butyric',
      'caproic',
      'caprylic',
      'capric',
      'lauric',
      'myristic',
      'palmitic',
      'stearic',
      'oleic',
      'linoleic',
      'linolenic',
      'arachidonic',
      'docosahexanoic',
      'palmitoleic',
      'parinaric',
      'gadoleic',
      'eicosapentaenoic',
      'erucic',
      'docosapentaenoic',
      'mono_satured',
      'poly_satured',
      'choline',
      'fructose',
      'vitamin_d'
    ];

    let nutData = {};
    for (const key of keysArray) {
      nutData[key] = newItem[key] || 0;
    }

    form.patchValue({
      fdq_day_info_item_id: item._id,

      fdq_day_info_item_name: title,
      fdq_day_info_item_type: foodType,
      fdq_day_info_item_barcode: barcode,
      fdq_day_info_item_real_barcode: real_barcode,
      fdq_day_info_assets: assets,
      fdq_day_info_item_nut_data: nutData,
      fdq_day_info_item_nut_midas: midas,

      fdq_day_info_item_description: '',
      fdq_day_info_quantity: '',
      fdq_day_info_units: '',
      fdq_day_info_weight_or_volume: '',
      fdq_day_info_calories: '',
      fdq_day_info_portion: '1',
    });

    // this.changeDetectorRef.detectChanges();

    // this.router.navigate([], { fragment: 'item' });

  }

  async newProductMapper(product: any) {
    if (!product?.product_code) {
      if (product.barcode) {
        const res = await this.restApiService.service('foodmida').find({
          query: {
            $limit: -1,
            // $paginate: false,
            // $limit: 9999,
            $select: ['title', 'sum'],
            barcode: product.barcode,
            title: {
              $nin: ['גרמים', 'קילוגרם']
            }
          }
        });

        // const mapped = res.map(((item: any) => ({ title: item.title, sum: +((item.sum).toFixed(1)) })));
        let mapped = [];
        if (res?.length) {
          for (const item of res) {
            const itemSum = +(item.sum);
            if (!isNaN(itemSum)) {
              mapped.push({ title: item.title, sum: +(itemSum.toFixed(1)) })
            }
          }
        }

        if (product.item_type === 'liquid' && !mapped.find((item: any) => item?.sum === 200)) {
          const cupTitle = this.translate.instant('food_diary.D_QUANTITY_A_1');
          const cupSum = 200;
          mapped.push({ title: cupTitle, sum: cupSum });
        }
        product.midas = { food_energy: mapped };
      }

      return product;
    }
    const { _id, product_code, product_info, media_assets, custom_sizes = [], nut_unit } = product;
    const real_barcode = product_info['Main_Fields']['GTIN'];
    const title = product_info['Main_Fields']['Trade_Item_Description'];
    const uom = product_info.Main_Fields.Net_Content?.UOM || ''; // the product unit, to check if the unit includes מל or ליטר
    let netValue = +(product_info.Main_Fields.Net_Content?.value || 0);
    let item_type = 'solid';
    const barcode = '';

    const imgs = [];
    if (media_assets) {
      for (const media of media_assets) {
        const { GLN, GTIN, filename, width, height } = media;
        // const url = `https://be.gs1-supplier.mk101.signature-it.com/images/Fittings/gs1-supplier-be/System_Images/Prod_Pic/${GLN}/${GLN}-${GTIN}/${filename}`;
        const url = `https://all-around-player.storage.googleapis.com/pdts/${product_code}-md.jpg`;
        if (+(width) && +(height)) {
          imgs.push({ url, width, height });
        }
      }
    }

    // let mapped = { _id, title, item_type, barcode, real_barcode, imgs };
    let mapped: any = { _id, title, barcode, real_barcode, imgs, midas: {} };

    const nutArr = product_info.Nutritional_Values?.table?.rows;
    const nutByCode = {
      '79001': 'food_energy',
      '79002': 'protein',
      '79003': 'carbohydrates',
      '79007': 'total_fat',
      '79008': 'satured_fat',
      // '79009': '',
      '79010': 'cholesterol',
      '79011': 'sodium',
      '79014': 'calcium',
    }
    if (nutArr) {
      for (const nut of nutArr) {
        if (nut && nutByCode[nut.code]) {
          if (nut.fields) {
            const resSolid = nut.fields.find((field: any) => field.col_code === '78001');
            const resLiquid = nut.fields.find((field: any) => field.col_code === '78003');

            if ((resSolid || resLiquid) && (nut_unit === 'ליטר' || nut_unit === 'קג' || uom.includes("קג") || uom.includes("ליטר") || title.includes(" ליטר") || title.includes(" ל'"))) {
              netValue = netValue * 1000;
            }

            if (resLiquid) {
              item_type = 'liquid';
              mapped[nutByCode[nut.code]] = +(resLiquid.value);
              // if (!mapped['midas'][nutByCode[nut.code]]) {

              // }
              mapped['midas'][nutByCode[nut.code]] = nut.fields.map((field: any) => ({ title: (field.col_label || ''), sum: +((+(field.value || 0) / +(resLiquid.value) * 100).toFixed(1)) }));
              // mapped['midas'][nutByCode[nut.code]] = nut.fields.map((field: any) => field.label ? ({ title: field.label, sum: +((field.labelSize || '').trim()) }) : ({ title: (field.col_label || ''), sum: +((+(field.value || 0) / +(resLiquid.value) * 100).toFixed(1)) }));
            } else if (resSolid) {
              if (nut_unit === 'מל' || nut_unit === 'ליטר' || uom.includes('מל') || uom.includes("ליטר") || title.includes(' מ"ל') || title.includes(" ליטר") || title.includes(" ל'")) {
                item_type = 'liquid';
              }

              mapped[nutByCode[nut.code]] = +(resSolid.value);
              // if (!mapped['midas'][nutByCode[nut.code]]) {

              // }
              mapped['midas'][nutByCode[nut.code]] = nut.fields.map((field: any) => ({ title: (field.col_label || ''), sum: +((+(field.value || 0) / +(resSolid.value) * 100).toFixed(1)) }));
              // mapped['midas'][nutByCode[nut.code]] = nut.fields.map((field: any) => field.label ? ({ title: field.label, sum: +((field.labelSize || '').trim()) }) : ({ title: (field.col_label || ''), sum: +((+(field.value || 0) / +(resSolid.value) * 100).toFixed(1)) }));
            }

            if (nut_unit === 'מל' || nut_unit === 'ליטר') {
              item_type = 'liquid';
            }

            if (nut_unit === 'גרם' || nut_unit === 'קג') {
              item_type = 'solid';
            }

            const solidFullPackageTitle = 'יחידה/אריזה';
            const liquidFullPackageTitle = 'בקבוק/אריזה';
            if (item_type === 'solid' && netValue && netValue <= 300) {
              const exist = mapped['midas'][nutByCode[nut.code]] && mapped['midas'][nutByCode[nut.code]].find((item: any) => item?.sum && item.sum == netValue);
              if (!exist) {
                mapped['midas'][nutByCode[nut.code]]?.push({ title: solidFullPackageTitle, sum: netValue });
              }
            }
            if (item_type === 'liquid' && netValue && netValue <= 500) {
              const exist = mapped['midas'][nutByCode[nut.code]] && mapped['midas'][nutByCode[nut.code]].find((item: any) => item?.sum && item.sum == netValue);
              if (!exist) {
                mapped['midas'][nutByCode[nut.code]]?.push({ title: liquidFullPackageTitle, sum: netValue });
              }
            }

            const customArr: any[] = Array.isArray(custom_sizes) ? custom_sizes : Object.values(custom_sizes);
            if (customArr.length) {
              for (const custom of customArr) {
                if (typeof custom !== 'object') {
                  continue;
                }
                const { size, label = '' } = custom;
                const sum = +(size || 0);
                const exist = mapped['midas'][nutByCode[nut.code]] && mapped['midas'][nutByCode[nut.code]].find((item: any) => item?.sum && item.sum == sum);
                if (!exist && sum && label) {
                  mapped['midas'][nutByCode[nut.code]]?.push({ title: label.trim(), sum });
                }
              }
            }

            if (product.item_type === 'liquid' && !mapped['midas'][nutByCode[nut.code]]?.find((item: any) => item?.sum === 200)) {
              const cupTitle = this.translate.instant('food_diary.D_QUANTITY_A_1');
              const cupSum = 200;
              mapped['midas'][nutByCode[nut.code]]?.push({ title: cupTitle, sum: cupSum });
            }

          }
          // mapped[nutByCode[nut.code]] = +(nut.fields[0]?.value || 0);
        }
      }
    }

    mapped.item_type = item_type;

    return mapped;
  }

  addOrEditMealItemDialog(mealItem: FormGroup, isEdit?: boolean, nameType?: string) {

    const mealType = mealItem.value.fdq_day_info_item_type;
    if (!mealType) {
      return;
    }

    let template: any;
    let title = '';

    if (mealType === "FOOD") {
      title = this.translate.instant(isEdit ? "food_diary.EDIT_FOOD_TITLE" : "food_diary.ADD_FOOD_TITLE");
      template = this.mealDialogTemplate;
    }
    if (mealType === "DRINK") {
      title = this.translate.instant(isEdit ? "food_diary.EDIT_DRINK_TITLE" : "food_diary.ADD_DRINK_TITLE");
      template = this.mealDialogTemplate;
    }
    if (mealType === "MEAL") {
      title = this.translate.instant(isEdit ? "food_diary.EDIT_MEAL_TITLE" : "food_diary.ADD_MEAL_TITLE");
      template = this.mealDialogTemplate;
      // if (!isEdit && !mealItem.value.fdq_day_info_item_name && this.isMinor) {
      //   mealItem.get("fdq_day_info_item_name").setValue(this.mealTitle);
      // }
      if (!isEdit && !mealItem.value.fdq_day_info_item_name) {
        mealItem.get("fdq_day_info_item_name").setValue('פריט מזון אחר');
      }
    }
    if (mealType === "WATER") {
      title = this.translate.instant(isEdit ? "food_diary.EDIT_WATER_TITLE" : "food_diary.ADD_WATER_TITLE");
      template = this.waterDialogTemplate;
      mealItem.get("fdq_day_info_item_name").setValue('מים');
      // } else {
      //   mealItem.get("fdq_day_info_time").setValue(this.lastChosenHour ? this.lastChosenHour : this.defaultTime);
    }

    mealItem.get('extra_field_form_title').setValue(title);

    this.mealDialogForm = mealItem;

    this.mealDialogRef = this.dialog.open(CustomDialogComponent, {
      disableClose: true,
      closeOnNavigation: false,
      maxWidth: '100%',
      // maxWidth: "90vw",
      // minWidth: '35%',
      // minWidth: '300px',
      panelClass: "meal-dialog",
      data: {
        // title: title, 
        ok: this.translate.instant('general.ok'),
        cancel: this.translate.instant('general.cancel'),
        form: mealItem,
        template: template,
        context: this.formContext
      }
    });

    // this.router.navigate([], { fragment: 'meal' });
    // if (mealItem.get("fdq_day_info_item_name").value && mealItem.get("fdq_day_info_item_type").value !== 'WATER') {
    //   setTimeout(() => {
    //     this.router.navigate([], { fragment: 'item' });
    //   }, 100);
    //   // this.router.navigate([], { fragment: 'item' });
    // }

    this.mealDialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed');

      mealItem.get('extra_field_form_title').setValue('');

      // this.router.navigate([], { fragment: null, replaceUrl: true });

      this.mealDialogForm = undefined;

      if (!result) {
        console.log("user canceled");
        return;
      }

      const midaOptions = mealItem.value.fdq_day_info_item_nut_midas?.food_energy || [];

      const quantity = mealItem.value.fdq_day_info_quantity;
      if (quantity != 'כמות מדודה (מ"ל)' && quantity != 'כמות מדודה (גרם)') {
        let sum = '0';
        switch (quantity) {
          case 'כוס (200 מ"ל)':
            sum = '200';
            break;
          case 'פחית (333 מ"ל)':
            sum = '333';
            break;
          case 'בקבוק (500 מ"ל)':
            sum = '500';
            break;
          default:
            const mida = midaOptions.find(val => val.title === quantity);
            if (mida) {
              sum = mida.sum;
            }
            break;
        }
        mealItem.get("fdq_day_info_weight_or_volume").setValue(sum);
      }
      const nutData = mealItem.value.fdq_day_info_item_nut_data;
      // const midas = mealItem.value.fdq_day_info_item_nut_midas;
      const amount = mealItem.value.fdq_day_info_weight_or_volume;
      const portion = mealItem.value.fdq_day_info_portion;
      const calories = this.calcKcal((nutData && nutData['food_energy'] || 0), amount, portion)
      mealItem.get("fdq_day_info_calories").setValue(calories);
      // clean item fields before sending if needed:
      this.cleanMealItemUnusedFields(mealItem);

      const description = mealItem.value.fdq_day_info_item_description ? mealItem.value.fdq_day_info_item_description.trim() : '';
      if (mealItem.value.fdq_day_info_item_type == 'MEAL' && description) {
        mealItem.get("fdq_day_info_item_name").setValue(description);
      }
      mealItem.get("fdq_day_info_item_description").setValue(description);

      if (mealItem.value.fdq_day_info_item_type === 'MEAL') {
        this.addCustomProduct(mealItem, nameType);
      } else {
        this.addToMyProducts(mealItem);
      }

    });
  }

  addToMyProducts(mealItem: FormGroup) {
    // console.log('addToMyProducts', mealItem.value);
    const mealItemValue = mealItem.value;
    const { fdq_day_info_item_id, fdq_day_info_meal_name } = mealItemValue;
    if (fdq_day_info_item_id && fdq_day_info_meal_name) {
      const _id = fdq_day_info_item_id;
      const mealType = fdq_day_info_meal_name;
      if (!this.myChosenProducts) {
        this.myChosenProducts = {
          [mealType]: {
            [_id]: mealItemValue
          }
        }
      } else {
        if (!this.myChosenProducts[mealType]) {
          this.myChosenProducts[mealType] = {
            [_id]: mealItemValue
          };
        } else {
          this.myChosenProducts[mealType][_id] = mealItemValue;
        }
      }
      console.log('addToMyProducts - this.myChosenProducts', this.myChosenProducts);

      if (this.nutritionquizForm) {
        this.nutritionquizForm.get('nuq_chosen_products').setValue(this.myChosenProducts);
      }
    }
  }

  removeProduct(_id: string, mealType: string) {
    if (this.myChosenProducts?.[mealType]?.[_id]) {
      this.myChosenProducts[mealType][_id] = undefined;
      delete this.myChosenProducts[mealType][_id];
    }
    console.log('removeProduct - this.myChosenProducts', this.myChosenProducts);

    if (this.nutritionquizForm) {
      this.nutritionquizForm.get('nuq_chosen_products').setValue(this.myChosenProducts);
    }
  }

  addCustomProduct(mealItem: FormGroup, nameType: string) {
    // console.log('addCustomProduct', mealItem.value);
    const mealItemValue = mealItem.value;
    const { fdq_day_info_item_id, fdq_day_info_meal_name } = mealItemValue;
    if (fdq_day_info_item_id && fdq_day_info_meal_name) {

      const mealType = fdq_day_info_meal_name;
      if (!this.myChosenProducts) {
        this.myChosenProducts = {
          [mealType]: {
            custom: {
              [nameType]: [mealItemValue],
            }
          }
        }
      } else {
        if (!this.myChosenProducts[mealType]) {
          this.myChosenProducts[mealType] = {
            custom: {
              [nameType]: [mealItemValue],
            }
          };
        } else {
          if (!this.myChosenProducts[mealType].custom) {
            this.myChosenProducts[mealType].custom = {
              [nameType]: [mealItemValue],
            };
          } else {
            if (!this.myChosenProducts[mealType].custom[nameType]) {
              this.myChosenProducts[mealType].custom[nameType] = [mealItemValue];
            } else {
              this.myChosenProducts[mealType].custom[nameType].push(mealItemValue);
            }
          }
        }
      }
      console.log('addCustomProduct - this.myChosenProducts', this.myChosenProducts);

      if (this.nutritionquizForm) {
        this.nutritionquizForm.get('nuq_chosen_products').setValue(this.myChosenProducts);
      }
    }
  }

  removeCustomProduct(fdq_day_info_item_id: string, mealType: string, nameType: string) {
    if (this.myChosenProducts?.[mealType]?.custom?.[nameType]?.length) {
      const currentIdx = this.myChosenProducts[mealType].custom[nameType].findIndex(p => p.fdq_day_info_item_id && p.fdq_day_info_item_id === fdq_day_info_item_id);
      if (currentIdx > -1) {
        this.myChosenProducts[mealType].custom[nameType].splice(currentIdx, 1);

        if (this.myChosenProducts[mealType].custom[nameType].length === 0) {
          this.myChosenProducts[mealType].custom[nameType] = undefined;
          delete this.myChosenProducts[mealType].custom[nameType];
        }
      }
    }
    console.log('removeCustomProduct - this.myChosenProducts', this.myChosenProducts);

    if (this.nutritionquizForm) {
      this.nutritionquizForm.get('nuq_chosen_products').setValue(this.myChosenProducts);
    }
  }

  goBack(form?: FormGroup, isFrag?: boolean) {
    // if (form?.value._id && this.mealDialogRef) {
    //   this.mealDialogRef.close();
    //   return;
    // }
    // if (form?.value.fdq_day_info_item_name) {
    //   if (isFrag) {
    //     this.clearSelection(form);
    //   } else {
    //     const lastFrag = this.allFrags.length > 1 ? this.allFrags[this.allFrags.length - 2] : 'meal';
    //     this.router.navigate([], { fragment: lastFrag, replaceUrl: true });
    //     // this.router.navigate([], { fragment: 'meal', replaceUrl: true });
    //   }
    //   return;
    // }
    // if (this.mealSearched) {
    //   if (isFrag) {
    //     this.mealSearched = false;
    //   } else {
    //     const lastFrag = this.allFrags.length > 1 ? this.allFrags[this.allFrags.length - 2] : 'meal';
    //     this.router.navigate([], { fragment: lastFrag, replaceUrl: true });
    //     // this.router.navigate([], { fragment: 'meal', replaceUrl: true });
    //   }
    //   return;
    // }
    if (this.mealDialogRef) {
      this.mealDialogRef.close();
    }
  }

  goDone(form?: FormGroup) {
    this.mealDialogRef.close({});
  }

  clearSelection(form: FormGroup) {
    let assetsArray: FormArray = form.get('fdq_day_info_assets') as FormArray;
    // for (let i = 0; i < assetsArray.length; i++) {
    //   assetsArray.removeAt(0);
    // }
    assetsArray.clear();
    // console.log(form.value.extra_field_input_items)

    form.patchValue({
      fdq_day_info_item_name: '',
      fdq_day_info_item_description: '',
      fdq_day_info_item_id: '',
      // _id: '',
      fdq_day_info_item_type: 'MEAL',
      fdq_day_info_item_barcode: '',
      fdq_day_info_item_real_barcode: '',
      fdq_day_info_item_nut_data: '',
      fdq_day_info_item_nut_midas: '',
      fdq_day_info_quantity: '',
      fdq_day_info_units: '',
      fdq_day_info_weight_or_volume: '',
      fdq_day_info_calories: '',
      fdq_day_info_portion: '1',
    }); // , { emitEvent: false }
    form.markAsUntouched();
  }

  editRowOnClick(row: any) {
    // console.log('editRow', row);
    let mealItem: FormGroup = this.createMealItem(this.formContext);
    const assets = row.fdq_day_info_assets || [];
    if (assets && assets.length > 0) {
      const assetsArray: FormArray = mealItem.get('fdq_day_info_assets') as FormArray;
      for (let i = 0; i < assets.length; i++) { // for (const asset of assets) {
        // const newFormElement = createAssetItem();
        const newFormElement = this.formBuilder.control({});
        assetsArray.push(newFormElement);
      }
    }
    mealItem.patchValue(row);
    this.addOrEditMealItemDialog(mealItem, true);
  }

  // addMealItem(mealName: string, mealItemType: string) {

  //   if (mealItemType != "MEAL" && mealItemType != "FOOD" && mealItemType != "DRINK" && mealItemType != "WATER") {
  //     console.error(`Wrong type: ${mealItemType}`);
  //     return;
  //   }

  //   let mealItem: FormGroup = this.createMealItem(this.formContext);

  //   mealItem.patchValue({
  //     fdq_day_info_meal_name: mealName,
  //     fdq_day_info_item_type: mealItemType,
  //     fdq_day_info_portion: '1',
  //   });

  //   this.addOrEditMealItemDialog(mealItem, false);
  // }

  createMealItem(context) {
    let mealItem: FormGroup = context.formBuilder.group({
      // _id: '',

      // fdq_day_info_usr_id: '',
      // fdq_day_info_fdq_day_id: '',

      // possible meal names (in order):
      // BREAKFAST
      // ELEVENSES (11:00 meal)
      // LUNCH
      // TEA (16:00 meal)
      // DINNER
      // NIGHT_SNACK
      fdq_day_info_meal_name: '',

      fdq_day_info_item_description: '',
      fdq_day_info_item_id: '',

      // whether it's FOOD / DRINK
      fdq_day_info_item_type: '',
      fdq_day_info_item_name: ['', Validators.required],
      fdq_day_info_item_barcode: '',
      fdq_day_info_item_real_barcode: '',
      fdq_day_info_item_nut_data: '',
      fdq_day_info_item_nut_midas: '',
      // fdq_day_info_time: '',
      fdq_day_info_quantity: '',
      fdq_day_info_units: '',
      fdq_day_info_weight_or_volume: '',
      fdq_day_info_calories: '',
      fdq_day_info_portion: ['', Validators.required],
      // fdq_day_info_asset: [{}],

      extra_field_form_title: [''],

      fdq_day_info_assets: context.formBuilder.array([]),
    },
      {
        validators: [context.mealCustomValidator.bind(context)]
      });

    mealItem.valueChanges.subscribe(async (val: any) => {
      context.inMealNut = context.updateInMealNut(val, context.nutArray);
      context.inMealExtraNut = context.updateInMealNut(val, context.nutExtraArray);
    });


    return mealItem;
  }

  updateInMealNut(mealItemValue: any, keysArray: string[]) {
    const nutData = mealItemValue.fdq_day_info_item_nut_data;
    const midas = mealItemValue.fdq_day_info_item_nut_midas;
    const quantity = mealItemValue.fdq_day_info_quantity;

    let portions = this.parsePortion(mealItemValue.fdq_day_info_portion);

    if (!nutData || !quantity || !portions) {
      return;
    }

    const midaOptions = midas?.food_energy || [];

    let sum = 0;
    switch (quantity) {

      case 'כמות מדודה (גרם)':
        sum = +(mealItemValue.fdq_day_info_weight_or_volume);
        break;

      case 'כמות מדודה (מ"ל)':
        sum = +(mealItemValue.fdq_day_info_weight_or_volume);
        break;

      case 'כוס (200 מ"ל)':
        sum = 200;
        break;
      case 'פחית (333 מ"ל)':
        sum = 333;
        break;
      case 'בקבוק (500 מ"ל)':
        sum = 500;
        break;
      default:
        const mida = midaOptions.find(val => val.title === quantity);
        if (mida) {
          sum = mida.sum;
        }
        break;
    }
    const total = (sum * portions);
    let resultObj = {};

    for (const key of keysArray) {
      const nutVal = +(nutData[key]) * total / 100; // nut value is per 100gr
      if (key == 'food_energy') {
        resultObj[key] = Math.trunc(nutVal);
      } else {
        resultObj[key] = Math.round(nutVal * 10) / 10 || 0;
      }
    }

    const objValues = Object.values(resultObj);
    if (objValues.every(val => !val)) {
      return;
    }

    return resultObj;

  }

  cleanMealItemUnusedFields(mealItem: FormGroup) {
    const quantity = mealItem.value.fdq_day_info_quantity;

    if (quantity == 'כמות מדודה (מ"ל)') {
      mealItem.get("fdq_day_info_units").reset('מ"ל');
      return;
    }
    if (quantity == 'כמות מדודה (גרם)') {
      mealItem.get("fdq_day_info_units").reset('גרם');
      return;
    }

    mealItem.get("fdq_day_info_units").reset('');
    // mealItem.get("fdq_day_info_weight_or_volume").reset('');

  }

  mealCustomValidator(formGroup: FormGroup) {
    if (formGroup.value.fdq_day_info_item_type == 'MEAL') {
      // if (!(formGroup.value.fdq_day_info_assets && formGroup.value.fdq_day_info_assets[0] && formGroup.value.fdq_day_info_assets[0].url)) {
      //   return {
      //     msg: "דרוש להוסיף תמונה"
      //   }
      // }
      if (!formGroup.value.fdq_day_info_item_description) {
        return {
          msg: "דרוש להוסיף תיאור"
        }
      }
    }

    // if ((formGroup.value.fdq_day_info_item_type !== 'MEAL' && !formGroup.value.fdq_day_info_quantity) || ((formGroup.value.fdq_day_info_quantity == 'כמות מדודה (מ"ל)' || formGroup.value.fdq_day_info_quantity == 'כמות מדודה (גרם)') && !formGroup.value.fdq_day_info_weight_or_volume)) {
    //   return {
    //     msg: "דרוש לציין את הכמות"
    //   }
    // }

    if (!formGroup.value.fdq_day_info_quantity || ((formGroup.value.fdq_day_info_quantity == 'כמות מדודה (מ"ל)' || formGroup.value.fdq_day_info_quantity == 'כמות מדודה (גרם)') && !formGroup.value.fdq_day_info_weight_or_volume)) {
      return {
        msg: "דרוש לציין את הכמות"
      }
    }
    return null;
  }

  parsePortion(portions: string): number {
    let parsedPorsions: number = 0;
    switch (portions) {
      case "¼":
        parsedPorsions = 0.25;
        break;
      case "½":
        parsedPorsions = 0.5;
        break;
      case "1":
        parsedPorsions = 1;
        break;
      case "1½":
        parsedPorsions = 1.5;
        break;
      case "2":
        parsedPorsions = 2;
        break;
      case "2½":
        parsedPorsions = 2.5;
        break;
      case "3":
        parsedPorsions = 3;
        break;
      case "3½":
        parsedPorsions = 3.5;
        break;
      case "4":
        parsedPorsions = 4;
        break;
      case "4½":
        parsedPorsions = 4.5;
        break;
      case "5":
        parsedPorsions = 5;
        break;
      default:
        const toNum = +(portions);
        if (!isNaN(toNum)) {
          parsedPorsions = toNum;
        }
    }
    return parsedPorsions;
  }

  calcKcal(kcal: string | number, amount: string | number, portion: string) {
    let count = this.parsePortion(portion);
    const kcalNum = +(kcal);
    const amountNum = +(amount);
    return Math.trunc(count * kcalNum * amountNum / 100); // kcal value is per 100gr
  }

  // ngAfterViewInit() {
  //   for (const field of ['nuq_food_does_not_like_arr', 'nuq_like_but_try_avoid_eating_arr', 'nuq_eat_a_lot_arr', 'nuq_new_food_arr']) {
  //     this.valueChangesFixed(field)
  //   }
  // }

  ngOnDestroy() {
    if (this.translate.currentLang !== 'en') {
      if (this.appState.user.usr_gender === 'נקבה') {
        this.translate.use('he_F');
      } else {
        this.translate.use('he');
      }
    }
  }

  valueChangesFixed(field: string) {
    this.nutritionquizForm.get(`${field}_fixed`).valueChanges.subscribe(newVal => {
      const oldVal = this.nutritionquizForm.value[`${field}_fixed`] || [];
      if (newVal && newVal.includes('אחר') && !oldVal.includes('אחר')) {
        if (this[`${field}_fixed`]) {
          this[`${field}_fixed`].close();
        }
        if (field === 'nuq_new_food_arr') {
          this.addNewFood(this, field, true);
        } else {
          this.addFoodItem(this, field, true);
        }
      }
      if (!newVal || !newVal.includes('אחר')) {
        this.resetFormArray(field);
      }
    });
  }

  oldValuesPopup() {
    const options = {
      title: '',
      message: 'חלק מהתשובות בשאלון זה מולאו אוטומטית על פי התשובות שלך מהפעם הקודמת. במידה שיש שינוי, חשוב לעדכן את התשובות לסטטוס הנוכחי.',
      // cancelText: this.translate.instant("general.no"),
      // confirmText: this.translate.instant("general.yes"),
      confirmText: 'אישור',
    };
    this.dialogService.open(options);
  }

  conditionalValidator(predicate: Function,
    validator: Function,
    parentKeyAndValueEquals?: any, parentKeyAndValueNotEquals?: any): Function {
    return (formControl => {

      if (!formControl.parent) {
        return null;
      }

      let error = null;

      if (parentKeyAndValueEquals) {
        if (formControl.parent.get(parentKeyAndValueEquals.key).value == parentKeyAndValueEquals.value) {
          error = validator(formControl);
        }
      } else if (parentKeyAndValueNotEquals) {
        if (formControl.parent.get(parentKeyAndValueNotEquals.key).value != parentKeyAndValueNotEquals.value) {
          error = validator(formControl);
        }
      } else {
        if (predicate()) {
          error = validator(formControl);
        }
      }

      return error;
    })
  }

  formCustomValidator(formGroup: FormGroup) {

    // console.log('this.validateObjectIngredients', this.validateObjectIngredients)
    // console.log('this.validateObjectFreq', this.validateObjectFreq)
    // console.log('this.validateObjectTypical', this.validateObjectTypical)

    if (!this.formInitiated) {
      return null;
    }

    // if (!formGroup.value.nuq_eat_no_limits &&
    //   !formGroup.value.nuq_food_does_not_like &&
    //   !formGroup.value.nuq_food_does_not_eat_health_reason &&
    //   !formGroup.value.nuq_like_but_try_avoid_eating &&
    //   !formGroup.value.nuq_eat_a_lot
    // ) {
    //   return {
    //     msg: this.translate.instant("nutrition.ERROR_A"),
    //     scrollIntoNativeElement: this.nuq_eat_no_limits_el
    //   };
    // }

    // if (formGroup.value.nuq_food_does_not_like) {
    //   // let res = this.validateFoodArrayFormField(formGroup, "nuq_food_does_not_like_arr");
    //   // if (res) return res;
    //   let resFixed = this.validateFoodArrayFormFieldFixed(formGroup, "nuq_food_does_not_like_arr_fixed");
    //   if (resFixed) {
    //     return resFixed;
    //   } else {
    //     let res = this.validateFoodArrayFormField(formGroup, "nuq_food_does_not_like_arr");
    //     if (res) return res;
    //   }
    // }
    // if (formGroup.value.nuq_food_does_not_eat_health_reason) {
    //   // let res = this.validateFoodArrayFormField(formGroup, "nuq_food_does_not_eat_health_reason_arr");
    //   // if (res) return res;
    //   if (!formGroup.value.nuq_food_does_not_eat || (formGroup.value.nuq_food_does_not_eat === 'אחר' && !formGroup.value.nuq_food_does_not_eat_other)) {
    //     return {
    //       msg: this.translate.instant("nutrition.ERROR_O"),
    //       scrollIntoNativeElement: this.nuq_eat_no_limits_el
    //     };
    //   }
    //   if (!formGroup.value.nuq_food_does_not_eat_reason_arr?.length || (formGroup.value.nuq_food_does_not_eat_reason_arr.includes('אחר') && !formGroup.value.nuq_food_does_not_eat_reason_other)) {
    //     return {
    //       msg: this.translate.instant("nutrition.ERROR_P"),
    //       scrollIntoNativeElement: this.nuq_eat_no_limits_el
    //     };
    //   }
    // }
    // if (formGroup.value.nuq_like_but_try_avoid_eating) {
    //   // let res = this.validateFoodArrayFormField(formGroup, "nuq_like_but_try_avoid_eating_arr");
    //   // if (res) return res;
    //   let resFixed = this.validateFoodArrayFormFieldFixed(formGroup, "nuq_like_but_try_avoid_eating_arr_fixed");
    //   if (resFixed) {
    //     return resFixed;
    //   } else {
    //     let res = this.validateFoodArrayFormField(formGroup, "nuq_like_but_try_avoid_eating_arr");
    //     if (res) return res;
    //   }
    // }
    // if (formGroup.value.nuq_eat_a_lot) {
    //   // let res = this.validateFoodArrayFormField(formGroup, "nuq_eat_a_lot_arr");
    //   // if (res) return res;
    //   let resFixed = this.validateFoodArrayFormFieldFixed(formGroup, "nuq_eat_a_lot_arr_fixed");
    //   if (resFixed) {
    //     return resFixed;
    //   } else {
    //     let res = this.validateFoodArrayFormField(formGroup, "nuq_eat_a_lot_arr");
    //     if (res) return res;
    //   }
    // }

    let selectedTab = 0;

    if (!formGroup.value.nuq_style || (formGroup.value.nuq_style === 'אחר' && !formGroup.value.nuq_style_other)) {
      return {
        msg: this.translate.instant("nutquiz.ERROR_NUT_STYLE"),
        scrollIntoNativeElement: this.nuq_style_el,
        selectedTab
      };
    }


    if ((this.nutritionquizForm?.value?.nuq_cant_eat_arr?.length === 0 || !this.nutritionquizForm?.value?.nuq_cant_eat_arr)
      && !this.nutritionquizForm?.value?.nuq_cant_eat_all
      && (!this.nutritionquizForm?.value?.nuq_cant_eat_is_other
        || (this.nutritionquizForm?.value?.nuq_cant_eat_is_other && this.nutritionquizForm?.value?.nuq_cant_eat_other.length === 0))) {
      return {
        msg: this.translate.instant("nutquiz.ERROR_NUT_STYLE"),
        scrollIntoNativeElement: this.nuq_style_el,
        selectedTab
      };
    }


    if ((this.nutritionquizForm?.value?.nuq_cant_avoid_arr?.length === 0 || !this.nutritionquizForm?.value?.nuq_cant_avoid_arr)
      && !this.nutritionquizForm.value.nuq_cant_avoid_all
      && (!this.nutritionquizForm.value.nuq_cant_avoid_is_other
        || (this.nutritionquizForm.value.nuq_cant_avoid_is_other && this.nutritionquizForm.value.nuq_cant_avoid_other.length === 0))) {
      return {
        msg: this.translate.instant("nutquiz.ERROR_NUT_STYLE"),
        scrollIntoNativeElement: this.nuq_style_el,
        selectedTab
      };
    }

    if (['דגים', 'צמחוני', 'טבעוני'].indexOf(formGroup.value.nuq_style) > -1) {
      // let res = this.validateFoodArrayFormField(formGroup, "nuq_food_does_not_eat_health_reason_arr");
      // if (res) return res;

      // if (!formGroup.value.nuq_food_does_not_eat || (formGroup.value.nuq_food_does_not_eat === 'אחר' && !formGroup.value.nuq_food_does_not_eat_other)) {
      //   return {
      //     msg: this.translate.instant("nutrition.ERROR_O"),
      //     scrollIntoNativeElement: this.nuq_eat_no_limits_el
      //   };
      // }
      if (!formGroup.value.nuq_food_does_not_eat_reason_arr?.length || (formGroup.value.nuq_food_does_not_eat_reason_arr.includes('אחר') && !formGroup.value.nuq_food_does_not_eat_reason_other)) {
        return {
          msg: this.translate.instant("nutquiz.ERROR_NUT_STYLE_REASON"),
          scrollIntoNativeElement: this.nuq_style_el,
          selectedTab
        };
      }
    }

    // nuq_cant_eat_all: [false],
    // nuq_cant_eat_is_other: [false],
    // nuq_cant_eat_other: [''],

    // nuq_cant_avoid_all: [false],
    // nuq_cant_avoid_is_other: [false],
    // nuq_cant_avoid_other: [''],

    selectedTab = 1;

    if (formGroup.value.nuq_new_food == "") {
      return {
        msg: this.translate.instant("nutrition.NEW_FOOD_10"),
        scrollIntoNativeElement: this.nuq_new_food_el,
        selectedTab
      };
    }

    if (formGroup.value.nuq_new_food == "כן") {
      // let res = this.validateNewFoodArray(formGroup, "nuq_new_food_arr");
      // if (res) return res;
      let resFixed = this.validateFoodArrayFormFieldFixed(formGroup, "nuq_new_food_arr_fixed");
      if (resFixed) {
        return { ...resFixed, selectedTab };
      } else {
        let res = this.validateNewFoodArray(formGroup, "nuq_new_food_arr");
        if (res) {
          return { ...res, selectedTab }
        };
      }
    }

    if (formGroup.value.nuq_eat_vegetables == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_VEGETABLES_7"),
        scrollIntoNativeElement: this.nuq_eat_vegetables_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_eat_vegetables == "כן" && formGroup.value.nuq_eat_vegetables_day == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_VEGETABLES_8"),
        scrollIntoNativeElement: this.nuq_eat_vegetables_el,
        selectedTab
      };
    }

    if (formGroup.value.nuq_eat_fruits == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_FRUITS_7"),
        scrollIntoNativeElement: this.nuq_eat_fruits_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_eat_fruits == "כן" && formGroup.value.nuq_eat_fruits_day == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_FRUITS_8"),
        scrollIntoNativeElement: this.nuq_eat_fruits_el,
        selectedTab
      };
    }

    if (formGroup.value.nuq_eat_snacks == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_SNACKS_20"),
        scrollIntoNativeElement: this.nuq_eat_snacks_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_eat_snacks === "כן" && formGroup.value.nuq_eat_snacks_type == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_SNACKS_21"),
        scrollIntoNativeElement: this.nuq_eat_snacks_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_eat_snacks === "כן" && formGroup.value.nuq_eat_snacks_freq == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_SNACKS_22"),
        scrollIntoNativeElement: this.nuq_eat_snacks_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_eat_snacks === "כן" && formGroup.value.nuq_eat_snacks_freq && formGroup.value.nuq_eat_snacks_freq !== "לעיתים רחוקות" && formGroup.value.nuq_eat_snacks_freq !== "0-1 בשבוע" && formGroup.value.nuq_eat_snacks_time == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_SNACKS_23"),
        scrollIntoNativeElement: this.nuq_eat_snacks_el,
        selectedTab
      };
    }

    if (formGroup.value.nuq_eat_near_screen == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_F"),
        scrollIntoNativeElement: this.nuq_eat_near_screen_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_eat_near_screen == "מול מסך" && formGroup.value.nuq_eat_near_screen_status == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_G"),
        scrollIntoNativeElement: this.nuq_eat_near_screen_el,
        selectedTab
      };
    }

    if (formGroup.value.nuq_eat_outside == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_H"),
        scrollIntoNativeElement: this.nuq_eat_outside_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_eat_outside == "כן" && formGroup.value.nuq_eat_outside_status == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_I"),
        scrollIntoNativeElement: this.nuq_eat_outside_el,
        selectedTab
      };
    }

    selectedTab = 2;

    if (formGroup.value.nuq_drink_estimate == "") {
      return {
        msg: this.translate.instant("nutrition.DRINK_ESTIMATE_21"),
        scrollIntoNativeElement: this.nuq_drink_estimate_el,
        selectedTab
      };
    }

    if (formGroup.value.nuq_personal_bottle == "") {
      return {
        msg: this.translate.instant("nutrition.PERSONAL_BOTTLE_5"),
        scrollIntoNativeElement: this.nuq_personal_bottle_el,
        selectedTab
      };
    }

    if (formGroup.value.nuq_drink_sugar == "") {
      return {
        msg: this.translate.instant("nutrition.DRINK_SUGAR_8"),
        scrollIntoNativeElement: this.nuq_drink_sugar_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_drink_sugar == "כן" && formGroup.value.nuq_drink_sugar_freq == "") {
      return {
        msg: this.translate.instant("nutrition.DRINK_SUGAR_9"),
        scrollIntoNativeElement: this.nuq_drink_sugar_el,
        selectedTab
      };
    }

    if (formGroup.value.nuq_drink_caffeine == "") {
      return {
        msg: this.translate.instant("nutrition.DRINK_CAFFEINE_19"),
        scrollIntoNativeElement: this.nuq_drink_caffeine_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_drink_caffeine === "כן" && (!formGroup.value.nuq_drink_caffeine_items || !formGroup.value.nuq_drink_caffeine_items.length)) {
      return {
        msg: this.translate.instant("nutrition.DRINK_CAFFEINE_20"),
        scrollIntoNativeElement: this.nuq_drink_caffeine_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_drink_caffeine === "כן" && formGroup.value.nuq_drink_caffeine_cups == "") {
      return {
        msg: this.translate.instant("nutrition.DRINK_CAFFEINE_21"),
        scrollIntoNativeElement: this.nuq_drink_caffeine_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_drink_caffeine === "כן" && formGroup.value.nuq_drink_caffeine_last == "") {
      return {
        msg: this.translate.instant("nutrition.DRINK_CAFFEINE_22"),
        scrollIntoNativeElement: this.nuq_drink_caffeine_el,
        selectedTab
      };
    }

    if (!formGroup.value.nuq_urine_color) {
      return {
        msg: this.translate.instant("nutquiz.ERROR_URINE_COLOR"),
        scrollIntoNativeElement: this.nuq_urine_color_el,
        selectedTab
      };
    }

    selectedTab = 3;

    if (!formGroup.value.nuq_chosen_categories) {
      return {
        msg: this.translate.instant("nutquiz.ERROR_CHOOSE_INGREDIENTS"),
        // scrollIntoNativeElement: ,
        selectedTab
      };
    }

    const validateObjectIngredients = this.validateAllIngredients();
    this.validateObjectIngredients = validateObjectIngredients;

    // const { drink, ...noDrink } = this.validateObjectIngredients;
    // const objValues = Object.values(noDrink);

    const { drink, ...noDrink } = validateObjectIngredients;
    const objValues = Object.values(noDrink);
    // const objValues = Object.values(validateObjectIngredients);

    // console.log('objValues', objValues)

    if (objValues.some(value => !value)) {
      return {
        msg: this.translate.instant("nutquiz.ERROR_CHOOSE_INGREDIENTS"),
        // scrollIntoNativeElement: ,
        selectedTab
      };
    }

    selectedTab = 4;

    if (formGroup.value.nuq_eat_breakfast == "") {
      return {
        msg: this.translate.instant("nutquiz.ERROR_EAT_BREAKFAST"),
        scrollIntoNativeElement: this.nuq_eat_breakfast_el,
        selectedTab
      };
    }

    if ((formGroup.value.nuq_eat_breakfast == "לפעמים" || formGroup.value.nuq_eat_breakfast == "לעיתים רחוקות" || formGroup.value.nuq_eat_breakfast == "אף פעם") && formGroup.value.nuq_eat_breakfast_reasons.length === 0) {
      return {
        msg: this.translate.instant("nutquiz.ERROR_EAT_BREAKFAST_REASON"),
        scrollIntoNativeElement: this.nuq_eat_breakfast_reason_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_eat_breakfast_reasons && formGroup.value.nuq_eat_breakfast_reasons.includes("אחר") && formGroup.value.nuq_eat_breakfast_reason_other_details == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_D"),
        scrollIntoNativeElement: this.nuq_eat_breakfast_reason_el,
        selectedTab
      };
    }

    // if (formGroup.value.nuq_eat_breakfast == "") {
    //   return {
    //     msg: this.translate.instant("nutquiz.ERROR_EAT_BREAKFAST"),
    //     scrollIntoNativeElement: this.nuq_eat_breakfast_el
    //   };
    // }

    if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formGroup.value.nuq_eat_breakfast) > -1) {
      // if (formGroup.value.nuq_eat_breakfast_dairy_meat == "") {
      //   return {
      //     msg: this.translate.instant("nutquiz.ERROR_DAIRY_MEAT"),
      //     scrollIntoNativeElement: this.nuq_eat_breakfast_el
      //   };
      // }

      if (!this.validateWeekly('BREAKFAST')) {
        return {
          msg: this.translate.instant("nutquiz.ERROR_FREQ"),
          scrollIntoNativeElement: this.nuq_eat_breakfast_el,
          selectedTab
        };
      }

      if (formGroup.value.nuq_breakfast_time == "") {
        return {
          msg: this.translate.instant("nutquiz.ERROR_TIME"),
          scrollIntoNativeElement: this.nuq_breakfast_time_el,
          selectedTab
        };
      }

      if (formGroup.value.nuq_is_breakfast_details) {
        if (!formGroup.value.nuq_chosen_products?.['BREAKFAST'] || (formGroup.value.nuq_chosen_products && formGroup.value.nuq_chosen_products['BREAKFAST'] && Object.values(formGroup.value.nuq_chosen_products['BREAKFAST']).length === 0)) {
          return {
            msg: this.translate.instant("nutquiz.ERROR_CHOOSE_FOOD"),
            scrollIntoNativeElement: this.nuq_breakfast_time_el,
            selectedTab
          };
        }

        if (!this.validateTypical('BREAKFAST')) {
          return {
            msg: this.translate.instant("nutquiz.ERROR_TYPICAL"),
            scrollIntoNativeElement: this.nuq_breakfast_time_el,
            selectedTab
          };
        }
      }
    }

    selectedTab = 5;

    if (this.isUnderNineteen) {
      if (formGroup.value.nuq_eat_elevenses == "") {
        return {
          msg: this.translate.instant("nutquiz.ERROR_EAT_ELEVENSES_UNDER_19"),
          scrollIntoNativeElement: this.nuq_eat_elevenses_el,
          selectedTab
        };
      }
      if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formGroup.value.nuq_eat_elevenses) > -1) {
        if (formGroup.value.nuq_eat_elevenses_enough_food == "") {
          return {
            msg: this.translate.instant("nutquiz.ERROR_EAT_ELEVENSES_ENOUGH_FOOD"),
            scrollIntoNativeElement: this.nuq_eat_elevenses_enough_food_el,
            selectedTab
          };
        }
      }
      if (formGroup.value.nuq_eat_elevenses_no_school == "") {
        return {
          msg: this.translate.instant("nutquiz.ERROR_EAT_ELEVENSES_NO_SCHOOL"),
          scrollIntoNativeElement: this.nuq_eat_elevenses_no_school_el,
          selectedTab
        };
      }
      if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formGroup.value.nuq_eat_elevenses) > -1 || ['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formGroup.value.nuq_eat_elevenses_no_school) > -1) {
        // if (formGroup.value.nuq_eat_elevenses_dairy_meat == "") {
        //   return {
        //     msg: this.translate.instant("nutquiz.ERROR_DAIRY_MEAT"),
        //     scrollIntoNativeElement: this.nuq_eat_elevenses_el
        //   };
        // }

        if (!this.validateWeekly('ELEVENSES')) {
          return {
            msg: this.translate.instant("nutquiz.ERROR_FREQ"),
            scrollIntoNativeElement: this.nuq_eat_elevenses_el,
            selectedTab
          };
        }

        if (formGroup.value.nuq_elevenses_time == "") {
          return {
            msg: this.translate.instant("nutquiz.ERROR_TIME"),
            scrollIntoNativeElement: this.nuq_elevenses_time_el,
            selectedTab
          };
        }

        if (formGroup.value.nuq_is_elevenses_details) {
          if (!formGroup.value.nuq_chosen_products?.['ELEVENSES'] || (formGroup.value.nuq_chosen_products && formGroup.value.nuq_chosen_products['ELEVENSES'] && Object.values(formGroup.value.nuq_chosen_products['ELEVENSES']).length === 0)) {
            return {
              msg: this.translate.instant("nutquiz.ERROR_CHOOSE_FOOD"),
              scrollIntoNativeElement: this.nuq_elevenses_time_el,
              selectedTab
            };
          }

          if (!this.validateTypical('ELEVENSES')) {
            return {
              msg: this.translate.instant("nutquiz.ERROR_TYPICAL"),
              scrollIntoNativeElement: this.nuq_elevenses_time_el,
              selectedTab
            };
          }
        }
      }
    } else {
      if (formGroup.value.nuq_eat_elevenses == "") {
        return {
          msg: this.translate.instant("nutquiz.ERROR_EAT_ELEVENSES"),
          scrollIntoNativeElement: this.nuq_eat_elevenses_el,
          selectedTab
        };
      }
      if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formGroup.value.nuq_eat_elevenses) > -1) {
        // if (formGroup.value.nuq_eat_elevenses_dairy_meat == "") {
        //   return {
        //     msg: this.translate.instant("nutquiz.ERROR_DAIRY_MEAT"),
        //     scrollIntoNativeElement: this.nuq_eat_elevenses_el
        //   };
        // }

        if (!this.validateWeekly('ELEVENSES')) {
          return {
            msg: this.translate.instant("nutquiz.ERROR_FREQ"),
            scrollIntoNativeElement: this.nuq_eat_elevenses_el,
            selectedTab
          };
        }

        if (formGroup.value.nuq_elevenses_time == "") {
          return {
            msg: this.translate.instant("nutquiz.ERROR_TIME"),
            scrollIntoNativeElement: this.nuq_elevenses_time_el,
            selectedTab
          };
        }

        if (formGroup.value.nuq_is_elevenses_details) {
          if (!formGroup.value.nuq_chosen_products?.['ELEVENSES'] || (formGroup.value.nuq_chosen_products && formGroup.value.nuq_chosen_products['ELEVENSES'] && Object.values(formGroup.value.nuq_chosen_products['ELEVENSES']).length === 0)) {
            return {
              msg: this.translate.instant("nutquiz.ERROR_CHOOSE_FOOD"),
              scrollIntoNativeElement: this.nuq_elevenses_time_el,
              selectedTab
            };
          }

          if (!this.validateTypical('ELEVENSES')) {
            return {
              msg: this.translate.instant("nutquiz.ERROR_TYPICAL"),
              scrollIntoNativeElement: this.nuq_elevenses_time_el,
              selectedTab
            };
          }
        }
      }
    }

    selectedTab = 6;

    // if (formGroup.value.nuq_eat_lunch == "") {
    //   return {
    //     msg: this.translate.instant("nutquiz.ERROR_EAT_LUNCH"),
    //     scrollIntoNativeElement: this.nuq_eat_lunch_el
    //   };
    // }

    // if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formGroup.value.nuq_eat_lunch) > -1) {

    if (!this.validateWeekly('LUNCH')) {
      return {
        msg: this.translate.instant("nutquiz.ERROR_FREQ"),
        scrollIntoNativeElement: this.nuq_eat_lunch_el,
        selectedTab
      };
    }

    if (formGroup.value.nuq_eat_lunch_dairy_meat == "") {
      return {
        msg: this.translate.instant("nutquiz.ERROR_DAIRY_MEAT"),
        scrollIntoNativeElement: this.nuq_eat_lunch_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_lunch_time == "") {
      return {
        msg: this.translate.instant("nutquiz.ERROR_TIME"),
        scrollIntoNativeElement: this.nuq_lunch_time_el,
        selectedTab
      };
    }

    if (formGroup.value.nuq_is_lunch_details) {
      if (!formGroup.value.nuq_chosen_products?.['LUNCH'] || (formGroup.value.nuq_chosen_products && formGroup.value.nuq_chosen_products['LUNCH'] && Object.values(formGroup.value.nuq_chosen_products['LUNCH']).length === 0)) {
        return {
          msg: this.translate.instant("nutquiz.ERROR_CHOOSE_FOOD"),
          scrollIntoNativeElement: this.nuq_lunch_time_el,
          selectedTab
        };
      }

      if (!this.validateTypical('LUNCH')) {
        return {
          msg: this.translate.instant("nutquiz.ERROR_TYPICAL"),
          scrollIntoNativeElement: this.nuq_lunch_time_el,
          selectedTab
        };
      }
    }
    // }

    selectedTab = 7;

    if (formGroup.value.nuq_eat_tea == "") {
      return {
        msg: this.translate.instant("nutquiz.ERROR_EAT_TEA"),
        scrollIntoNativeElement: this.nuq_eat_tea_el,
        selectedTab
      };
    }

    if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formGroup.value.nuq_eat_tea) > -1) {

      if (!this.validateWeekly('TEA')) {
        return {
          msg: this.translate.instant("nutquiz.ERROR_FREQ"),
          scrollIntoNativeElement: this.nuq_eat_tea_el,
          selectedTab
        };
      }

      if (formGroup.value.nuq_eat_tea_dairy_meat == "") {
        return {
          msg: this.translate.instant("nutquiz.ERROR_DAIRY_MEAT"),
          scrollIntoNativeElement: this.nuq_eat_tea_el,
          selectedTab
        };
      }
      if (formGroup.value.nuq_tea_time == "") {
        return {
          msg: this.translate.instant("nutquiz.ERROR_TIME"),
          scrollIntoNativeElement: this.nuq_tea_time_el,
          selectedTab
        };
      }

      if (formGroup.value.nuq_is_tea_details) {
        if (!formGroup.value.nuq_chosen_products?.['TEA'] || (formGroup.value.nuq_chosen_products && formGroup.value.nuq_chosen_products['TEA'] && Object.values(formGroup.value.nuq_chosen_products['TEA']).length === 0)) {
          return {
            msg: this.translate.instant("nutquiz.ERROR_CHOOSE_FOOD"),
            scrollIntoNativeElement: this.nuq_tea_time_el,
            selectedTab
          };
        }

        if (!this.validateTypical('TEA')) {
          return {
            msg: this.translate.instant("nutquiz.ERROR_TYPICAL"),
            scrollIntoNativeElement: this.nuq_tea_time_el,
            selectedTab
          };
        }
      }
    }

    selectedTab = 8;

    // if (formGroup.value.nuq_eat_dinner == "") {
    //   return {
    //     msg: this.translate.instant("nutquiz.ERROR_EAT_DINNER"),
    //     scrollIntoNativeElement: this.nuq_eat_dinner_el
    //   };
    // }

    // if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formGroup.value.nuq_eat_dinner) > -1) {

    if (!this.validateWeekly('DINNER')) {
      return {
        msg: this.translate.instant("nutquiz.ERROR_FREQ"),
        scrollIntoNativeElement: this.nuq_eat_dinner_el,
        selectedTab
      };
    }

    if (formGroup.value.nuq_eat_dinner_dairy_meat == "") {
      return {
        msg: this.translate.instant("nutquiz.ERROR_DAIRY_MEAT"),
        scrollIntoNativeElement: this.nuq_eat_dinner_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_dinner_time == "") {
      return {
        msg: this.translate.instant("nutquiz.ERROR_TIME"),
        scrollIntoNativeElement: this.nuq_dinner_time_el,
        selectedTab
      };
    }

    if (formGroup.value.nuq_is_dinner_details) {
      if (!formGroup.value.nuq_chosen_products?.['DINNER'] || (formGroup.value.nuq_chosen_products && formGroup.value.nuq_chosen_products['DINNER'] && Object.values(formGroup.value.nuq_chosen_products['DINNER']).length === 0)) {
        return {
          msg: this.translate.instant("nutquiz.ERROR_CHOOSE_FOOD"),
          scrollIntoNativeElement: this.nuq_dinner_time_el,
          selectedTab
        };
      }

      if (!this.validateTypical('DINNER')) {
        return {
          msg: this.translate.instant("nutquiz.ERROR_TYPICAL"),
          scrollIntoNativeElement: this.nuq_dinner_time_el,
          selectedTab
        };
      }
    }
    // }

    selectedTab = 9;

    if (formGroup.value.nuq_eat_night == "") {
      return {
        msg: this.translate.instant("nutquiz.ERROR_EAT_NIGHT"),
        scrollIntoNativeElement: this.nuq_eat_night_el,
        selectedTab
      };
    }

    if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formGroup.value.nuq_eat_night) > -1) {

      if (!this.validateWeekly('NIGHT')) {
        return {
          msg: this.translate.instant("nutquiz.ERROR_FREQ"),
          scrollIntoNativeElement: this.nuq_eat_night_el,
          selectedTab
        };
      }

      if (formGroup.value.nuq_eat_night_dairy_meat == "") {
        return {
          msg: this.translate.instant("nutquiz.ERROR_DAIRY_MEAT"),
          scrollIntoNativeElement: this.nuq_eat_night_el,
          selectedTab
        };
      }
      if (formGroup.value.nuq_night_time == "") {
        return {
          msg: this.translate.instant("nutquiz.ERROR_TIME"),
          scrollIntoNativeElement: this.nuq_night_time_el,
          selectedTab
        };
      }

      if (formGroup.value.nuq_is_night_details) {
        if (!formGroup.value.nuq_chosen_products?.['NIGHT'] || (formGroup.value.nuq_chosen_products && formGroup.value.nuq_chosen_products['NIGHT'] && Object.values(formGroup.value.nuq_chosen_products['NIGHT']).length === 0)) {
          return {
            msg: this.translate.instant("nutquiz.ERROR_CHOOSE_FOOD"),
            scrollIntoNativeElement: this.nuq_night_time_el,
            selectedTab
          };
        }

        if (!this.validateTypical('NIGHT')) {
          return {
            msg: this.translate.instant("nutquiz.ERROR_TYPICAL"),
            scrollIntoNativeElement: this.nuq_night_time_el,
            selectedTab
          };
        }
      }
    }

    // if (this.isUnderNineteen && formGroup.value.nuq_school_sandwich == "") {
    //   return {
    //     msg: this.translate.instant("nutrition.ERROR_E"),
    //     scrollIntoNativeElement: this.nuq_school_sandwich_el
    //   };
    // }

    selectedTab = 10;

    if (formGroup.value.nuq_more_food_comments == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_J"),
        scrollIntoNativeElement: this.nuq_more_food_comments_el,
        selectedTab
      };
    }
    if (formGroup.value.nuq_more_food_comments == "כן" && formGroup.value.nuq_more_food_comments_text == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_K"),
        scrollIntoNativeElement: this.nuq_more_food_comments_el,
        selectedTab
      };
    }

    return null;
  }

  validateFoodArrayFormField(formGroup: FormGroup, arrayFieldName: string) {

    let descriptionViewChildrenArr = this.nuq_food_description_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);

    // validate each mini-form
    for (let i = 0; i < (formGroup.get(arrayFieldName) as FormArray).controls.length; i++) {
      let innerFormGroup: FormGroup = ((formGroup.get(arrayFieldName) as FormArray).controls[i] as FormGroup);

      if (innerFormGroup.value.description == "") {
        return {
          msg: this.translate.instant("nutrition.ERROR_L"),
          scrollIntoNativeElement: descriptionViewChildrenArr[i]
        };
      }

    }

    return null;
  }

  validateFoodArrayFormFieldFixed(formGroup: FormGroup, arrayFieldName: string) {

    if (!formGroup.get(arrayFieldName)?.value?.length) {
      return {
        msg: this.translate.instant("nutrition.ERROR_L"),
        scrollIntoNativeElement: this.nuq_new_food_el
      };
    }

    return null;
  }

  validateNewFoodArray(formGroup: FormGroup, arrayFieldName: string) {

    let descriptionViewChildrenArr = this.nuq_new_food_description_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);

    // validate each mini-form
    for (let i = 0; i < (formGroup.get(arrayFieldName) as FormArray).controls.length; i++) {
      let innerFormGroup: FormGroup = ((formGroup.get(arrayFieldName) as FormArray).controls[i] as FormGroup);

      if (innerFormGroup.value.description === "" || innerFormGroup.value.feedback === "") {
        return {
          msg: this.translate.instant("nutrition.NEW_FOOD_11"),
          scrollIntoNativeElement: descriptionViewChildrenArr[i]
        };
      }

    }

    return null;
  }

  // confirmAndResetFood(event, noLimitsEl) {
  //   if (this.isLocked) return;

  //   // notice - noLimitsEl.checked is the state of the checkbox before any
  //   // change occured.

  //   if (noLimitsEl.checked) {
  //     // remove all the disabled marks from the other checkboxes
  //     this.resetAndSetDisableNoLimitCheckBoxes(false);
  //     return;
  //   }

  //   // from here on, we know the state of the checkbox was false, and it is being turned to true:
  //   if (!this.nutritionquizForm.value.nuq_food_does_not_like &&
  //     !this.nutritionquizForm.value.nuq_food_does_not_eat_health_reason &&
  //     !this.nutritionquizForm.value.nuq_like_but_try_avoid_eating &&
  //     !this.nutritionquizForm.value.nuq_eat_a_lot) {
  //     this.resetAndSetDisableNoLimitCheckBoxes(true);
  //     return;
  //   }

  //   // // popup question only if there's at least one checkbox on
  //   // let popupQuestion = confirm("בלחיצה על אין מגבלות ימחקו ככל המגבלות שסומנו. להמשיך?");
  //   // if (popupQuestion) {
  //   //   this.resetAndSetDisableNoLimitCheckBoxes(true);
  //   // } else {
  //   //   event.preventDefault()
  //   // }

  //   event.preventDefault();

  //   const options = {
  //     title: '',
  //     message: this.translate.instant("nutrition.ERROR_M"),
  //     cancelText: this.translate.instant("general.no"),
  //     confirmText: this.translate.instant("general.yes")
  //   };
  //   this.dialogService.open(options);
  //   this.dialogService.confirmed().subscribe(confirmed => {
  //       if (confirmed) {
  //           this.resetAndSetDisableNoLimitCheckBoxes(true);
  //           this.resetField("nuq_eat_no_limits", true);
  //       }
  //   });
  // }

  // resetAndSetDisableNoLimitCheckBoxes(disabledState: boolean) {
  //   this.resetField("nuq_food_does_not_like", { value: false, disabled: disabledState });
  //   this.resetField("nuq_food_does_not_eat_health_reason", { value: false, disabled: disabledState });
  //   this.resetField("nuq_like_but_try_avoid_eating", { value: false, disabled: disabledState });
  //   this.resetField("nuq_eat_a_lot", { value: false, disabled: disabledState });
  // }

  patchFormArrays(data: any) {

    // let foodArraysFieldNames: Array<string> = [
    //   "nuq_food_does_not_like_arr",
    //   "nuq_food_does_not_eat_health_reason_arr",
    //   "nuq_like_but_try_avoid_eating_arr",
    //   "nuq_eat_a_lot_arr",
    // ];

    // this.patchGenericArray(data, foodArraysFieldNames, this, this.createFoodItem);
    this.patchGenericArray(data, ["nuq_new_food_arr"], this, this.createNewFood);

    this.patchGenericArray(data, ['nuq_breakfast_assets', 'nuq_elevenses_assets', 'nuq_lunch_assets', 'nuq_tea_assets', 'nuq_dinner_assets', 'nuq_night_assets'], this, this.createAsset);
    // this.patchGenericArray(data, ["nuq_food_does_not_eat_reason_arr"], this, () => this.formBuilder.control({}) );

    const len = data['nuq_eat_breakfast_reasons']?.length || 0;
    for (let i = 0; i < len; i++) {
      this.updateChkbxArray(data['nuq_eat_breakfast_reasons'][i], true, 'nuq_eat_breakfast_reasons')
    }

    this.patchGenericArray(data, ['nuq_other_protein', 'nuq_other_carbs', 'nuq_other_fat', 'nuq_other_veg', 'nuq_other_fruits', 'nuq_other_sweets', 'nuq_other_snacks', 'nuq_other_sauce', 'nuq_other_powder', 'nuq_other_drink'], this, this.createNewOther);
  }

  patchGenericArray(data, fieldArrayNames, context, createFunctionRef) {
    for (let i = 0; i < fieldArrayNames.length; i++) {
      if (!data[fieldArrayNames[i]] || !data[fieldArrayNames[i]].length) {
        continue;
      }
      let formArray: FormArray = this.nutritionquizForm.get(fieldArrayNames[i]) as FormArray;
      formArray.clear();
      for (let innerIndex = 0; innerIndex < data[fieldArrayNames[i]].length; innerIndex++) {
        let newFormElement = createFunctionRef(context);
        formArray.push(newFormElement);
      }
      formArray.patchValue(data[fieldArrayNames[i]], { emitEvent: false });
    }
  }

  resetField(fieldName, value) {
    this.nutritionquizForm.controls[fieldName].reset(value);
  }
  clearArrayField(fieldName,) {
    (this.nutritionquizForm.controls[fieldName] as FormArray).clear();
  }

  addFoodItem(context, arrFieldName, focus?: boolean) {
    context = context == null ? this : context;

    let foodArr = context.nutritionquizForm.get(arrFieldName) as FormArray;
    foodArr.push(context.createFoodItem(context));
    if (focus) {
      setTimeout(() => {
        let descriptionViewChildrenArr = this.nuq_food_description_control.toArray().filter((element) => element.nativeElement.id == arrFieldName);
        if (descriptionViewChildrenArr.length) {
          descriptionViewChildrenArr[descriptionViewChildrenArr.length - 1].nativeElement.click(); // .focus();
        }
      }, 200);

      // const newControl = foodArr.controls[foodArr.length - 1];
      // console.log('newControl', newControl);
      // if (newControl) {
      //   const toFocus = newControl.get('description');
      //   if (toFocus) {
      //     console.log('toFocus', toFocus);
      //   }
      // }
    }
  }

  removeFoodItem(context, checkboxFieldName, arrFieldName, index) {
    let foodArr = context.nutritionquizForm.get(arrFieldName) as FormArray;
    foodArr.removeAt(index);
    // if (foodArr.length == 0) {
    //   context.nutritionquizForm.controls[checkboxFieldName].reset(false);
    // }
  }

  createFoodItem(context) {
    return context.formBuilder.group({
      description: '',
    })
  }

  foodCheckboxOnChange(event, foodArrayFieldName: string) {
    if (event.checked) {
      // if (this.nutritionquizForm.value[foodArrayFieldName].length == 0) {
      //   this.addFoodItem(this, foodArrayFieldName);
      // }
    } else {
      // nothing.
    }
  }

  newFoodOnChange(event, foodArrayFieldName: string) {
    if (event.value === 'כן') {
      // if (this.nutritionquizForm.value[foodArrayFieldName].length == 0) {
      //   this.addNewFood(this, foodArrayFieldName);
      // }
    }
  }

  addNewFood(context, arrFieldName, focus?: boolean) {
    context = context == null ? this : context;

    let foodArr = context.nutritionquizForm.get(arrFieldName) as FormArray;
    foodArr.push(context.createNewFood(context));
    if (focus) {
      setTimeout(() => {
        let descriptionViewChildrenArr = this.nuq_new_food_description_control.toArray().filter((element) => element.nativeElement.id == arrFieldName);
        if (descriptionViewChildrenArr.length) {
          descriptionViewChildrenArr[descriptionViewChildrenArr.length - 1].nativeElement.click(); // .focus();
        }
      }, 200);

      // const newControl = foodArr.controls[foodArr.length - 1];
      // console.log('newControl', newControl);
      // if (newControl) {
      //   const toFocus = newControl.get('description');
      //   if (toFocus) {
      //     console.log('toFocus', toFocus);
      //   }
      // }
    }
  }

  removeNewFood(context, fieldName, arrFieldName, index) {
    let foodArr = context.nutritionquizForm.get(arrFieldName) as FormArray;
    foodArr.removeAt(index);
    // if (foodArr.length === 0) {
    //   context.nutritionquizForm.controls[fieldName].reset('לא');
    // }
  }

  createAsset(context) {
    return context.formBuilder.control({})
  }

  createNewFood(context) {
    return context.formBuilder.group({
      description: '',
      feedback: '',
    })
  }

  createNewOther(context) {
    return context.formBuilder.control('');
  }

  async onSubmit(form: FormGroup, prefix: string, skipRoute?: boolean) {
    if (!this.allProductsNutStyle) {
      return;
    }

    this.submitInProgress = true;

    this.clearValuesFromUnusedFields();
    const validateRes = this.validateForm();

    console.log(validateRes)

    if (!validateRes.valid && !skipRoute) {
      if (validateRes.selectedTab !== undefined) {
        this.selectedTab = validateRes.selectedTab;
      }
      if (validateRes.scrollIntoNativeElement) {
        validateRes.scrollIntoNativeElement.nativeElement.scrollIntoView();
      } else if (validateRes.selectedTab === 3) {
        setTimeout(() => {
          const element = document.querySelector(`.error-class`);

          console.log(element)
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 200);
      }
      // this.showErrors = true;
      this.showStatus(validateRes.msg, null);

      this.submitInProgress = false;
      return;
    } else {
      // this.showErrors = false;
    }

    this.resetField(`${prefix}_form_is_real`, true);

    this.clearAllIds();

    if (form.value._id) {
      // const res = await this.restService.patch(form.value._id, {...form.value, selectedTab: this.selectedTab}).catch(e => { console.error(e); });
      let res;
      if (!skipRoute) {
        const cleanedFormValue = this.cleanBeforeFinalSave(form.value);
        res = await this.restService.patch(form.value._id, { ...cleanedFormValue, selectedTab: this.selectedTab }).catch(e => { console.error(e); });
      } else {
        res = await this.restService.patch(form.value._id, { ...form.value, selectedTab: this.selectedTab }).catch(e => { console.error(e); });
      }

      if (res && res._id && res[`${prefix}_usr_id`]) {
        // this.buildAssetsArray(form, `${prefix}_assets_arr`, res);

        // form.patchValue(res, { emitEvent: false });
        // this.patchFormArrays(res);

        if (!skipRoute) {
          this.showStatus(this.translate.instant('general.savedSuccessfully'), null);
          if (this.appState.user.usr_type === 'player') {
            setTimeout(() => {
              this.router.navigate([`Userprogress`]);
              this.submitInProgress = false;
            }, 2000);
            return;
          }
        }
      } else {
        this.showStatus('אירעה שגיאה', null);
      }
    }
    this.submitInProgress = false;
  }

  clearIdsIfNotExistFromArrayItems(arr: any) {
    const arrLen = arr.length;
    for (let i = 0; i < arrLen; i++) {
      if (String(arr[i]._id).length != 0) {
        continue;
      }

      delete arr[i]._id;
    }
  }

  validateForm() {

    this.nutritionquizForm.get('nuq_eat_breakfast_reasons').updateValueAndValidity();
    if (!this.nutritionquizForm.valid)
      return {
        valid: false,
        msg: this.nutritionquizForm.errors ? this.nutritionquizForm.errors.msg : "נא לתקן את השדות האדומים",
        scrollIntoNativeElement: this.nutritionquizForm.errors ? this.nutritionquizForm.errors.scrollIntoNativeElement : null,
        selectedTab: this.nutritionquizForm.errors?.selectedTab,
      }

    return {
      valid: true,
      msg: "ולידציה עברה בהצלחה"
    }

  }

  // validateFreq(mealType: string) {
  //   return true;
  //   // if (this.validateObjectFreq[mealType]) {
  //   //   const objValues = Object.values(this.validateObjectFreq[mealType]);
  //   //   // return objValues.every(value => value);
  //   //   return objValues.some(value => value);
  //   // }
  // }

  validateAllIngredients() {
    const validateObjectIngredients = {
      protein: false,
      carbs: false,
      fat: false,
      veg: false,
      fruits: false,
      sweets: false,
      snacks: false,
      sauce: false,
      powder: false,
      drink: false,
    };

    for (const catName in this.allOptionsIngredients) {
      if (Object.prototype.hasOwnProperty.call(this.allOptionsIngredients, catName)) {
        const allOptions = this.allOptionsIngredients[catName];
        if (allOptions) {
          const disableAllNotEat = this.nutritionquizForm.get(`nuq_disabled_${catName}`);

          const isOtherControl = this.nutritionquizForm.get(`nuq_is_other_${catName}`);
          const otherFoodFormArr = this.nutritionquizForm.get(`nuq_other_${catName}`);

          const allOptionsChosen = allOptions.filter((opt: any) => opt && opt.checked);
          const isValidAllOptions = allOptionsChosen.length > 0 ? allOptionsChosen.every(opt => !opt.children || (opt.children.length > 0 && opt.children.some(child => child.checked))) : (isOtherControl?.value && otherFoodFormArr?.value?.length > 0);
          validateObjectIngredients[catName] = Boolean(disableAllNotEat?.value || isValidAllOptions);
        }
      }
    }

    // console.log('validateAllIngredients', validateObjectIngredients);

    return validateObjectIngredients;
  }

  validateWeekly(mealType: string) {
    const validateObject = {
      protein: false,
      carbs: false,
      fat: false,
      veg: false,
      fruits: false,
      sweets: false,
      snacks: false,
      sauce: false,
      powder: false,
      drink: false,
    };

    if (this.allOptionsMeals[mealType]) {
      for (const catName in this.allOptionsMeals[mealType]) {
        if (Object.prototype.hasOwnProperty.call(this.allOptionsMeals[mealType], catName)) {
          const generatedOptions = this.allOptionsMeals[mealType][catName];
          const allOptions = generatedOptions?.options;
          if (allOptions) {
            const allOptionsChosen = allOptions.filter((opt: any) => opt && opt.checked);
            const allOptionsChosenWeeklyFilled = allOptionsChosen.filter((opt: any) => opt && opt.weekly && opt.weekly[mealType]);

            validateObject[catName] = Boolean(allOptionsChosenWeeklyFilled.length > 0);
          }
        }
      }
    }

    // console.log('validateWeekly', mealType, validateObject);

    // return validateObject;
    const objValues = Object.values(validateObject);
    return objValues.some(value => value);
  }

  validateTypical(mealType: string) {
    const myChosenProducts = this.myChosenProducts;

    // console.log('validateTypical myChosenProducts', myChosenProducts);

    const validateObject = {
      protein: false,
      carbs: false,
      fat: false,
      veg: false,
      fruits: false,
      sweets: false,
      snacks: false,
      sauce: false,
      powder: false,
      drink: false,
    };

    if (this.allOptionsMeals[mealType]) {
      for (const catName in this.allOptionsMeals[mealType]) {
        if (Object.prototype.hasOwnProperty.call(this.allOptionsMeals[mealType], catName)) {
          const generatedOptions = this.allOptionsMeals[mealType][catName];
          const allOptions = generatedOptions?.options;
          if (allOptions) {
            const allOptionsChosen = allOptions.filter((opt: any) => opt && opt.checked);
            const allOptionsChosenWeeklyFilled = allOptionsChosen.filter((opt: any) => opt && opt.weekly && opt.weekly[mealType]);
            const allOptionsChosenWeeklyNoChildrenChosen = allOptionsChosenWeeklyFilled.filter((opt: any) => opt && opt.checkedTypical && opt.checkedTypical[mealType] && opt.childrenProducts && opt.childrenProducts[catName] && opt.childrenProducts[catName].length && !(opt.childrenProducts[catName].some((childProduct: any) => childProduct?._id && myChosenProducts && myChosenProducts[mealType] && this.myChosenProducts[mealType][childProduct._id])));

            // console.log('allOptionsChosenWeeklyFilled', allOptionsChosenWeeklyFilled);
            // console.log('allOptionsChosenWeeklyNoChildrenChosen', allOptionsChosenWeeklyNoChildrenChosen);

            validateObject[catName] = Boolean(allOptionsChosenWeeklyNoChildrenChosen.length === 0);
          }
        }
      }
    }

    // console.log('validateTypical', mealType, validateObject);

    // return validateObject;
    const objValues = Object.values(validateObject);
    return objValues.every(value => value);
  }

  // validateFreq(chosenCategories: any, mealType: string) {
  //   if (chosenCategories) {
  //     const objValues = Object.values(chosenCategories);
  //     const allOptionsChosenFrequencyNotFilled = objValues.filter((opt: any) => opt && opt.checked && !opt.frequency?.[mealType]);
  //     console.log('allOptionsChosenFrequencyNotFilled.length', allOptionsChosenFrequencyNotFilled.length)
  //     return allOptionsChosenFrequencyNotFilled.length === 0;
  //   }
  // }

  clearAllIds() {
    // this.clearIdsIfNotExistFromArrayItems(this.nutritionquizForm.value.nuq_food_does_not_like_arr);
    // this.clearIdsIfNotExistFromArrayItems(this.nutritionquizForm.value.nuq_food_does_not_eat_health_reason_arr);
    // this.clearIdsIfNotExistFromArrayItems(this.nutritionquizForm.value.nuq_like_but_try_avoid_eating_arr);
    // this.clearIdsIfNotExistFromArrayItems(this.nutritionquizForm.value.nuq_eat_a_lot_arr);
    this.clearIdsIfNotExistFromArrayItems(this.nutritionquizForm.value.nuq_new_food_arr);
  }

  clearValuesFromUnusedFields() {
    // if (this.nutritionquizForm.value.nuq_food_does_not_like == false || this.nutritionquizForm.value.nuq_food_does_not_like == undefined) {
    //   this.resetField("nuq_food_does_not_like_arr_fixed", null);
    //   this.resetFormArray('nuq_food_does_not_like_arr');
    // }
    // if (this.nutritionquizForm.value.nuq_food_does_not_eat_health_reason == false || this.nutritionquizForm.value.nuq_food_does_not_eat_health_reason == undefined) {
    //   this.resetFormArray('nuq_food_does_not_eat_health_reason_arr');
    //   this.resetField("nuq_food_does_not_eat", '');
    //   this.resetField("nuq_food_does_not_eat_other", '');
    //   // this.resetFormArray('nuq_food_does_not_eat_reason_arr');
    //   this.resetField("nuq_food_does_not_eat_reason_arr", null);
    //   this.resetField("nuq_food_does_not_eat_reason_other", '');
    // }
    // if (this.nutritionquizForm.value.nuq_like_but_try_avoid_eating == false || this.nutritionquizForm.value.nuq_like_but_try_avoid_eating == undefined) {
    //   this.resetField("nuq_like_but_try_avoid_eating_arr_fixed", null);
    //   this.resetFormArray('nuq_like_but_try_avoid_eating_arr');
    // }
    // if (this.nutritionquizForm.value.nuq_eat_a_lot == false || this.nutritionquizForm.value.nuq_eat_a_lot == undefined) {
    //   this.resetField("nuq_eat_a_lot_arr_fixed", null);
    //   this.resetFormArray('nuq_eat_a_lot_arr');
    // }

    if (this.nutritionquizForm.value.nuq_style !== 'אחר') {
      this.resetField("nuq_style_other", '');
    }

    if (['דגים', 'צמחוני', 'טבעוני'].indexOf(this.nutritionquizForm.value.nuq_style) === -1) {
      this.resetField("nuq_food_does_not_eat_reason_arr", null);
      this.resetField("nuq_food_does_not_eat_reason_other", '');
    }

    if (this.nutritionquizForm.value.nuq_cant_eat_all) {
      this.resetField("nuq_cant_eat_arr", null);
      this.resetField("nuq_cant_eat_is_other", false);
      this.resetField("nuq_cant_eat_other", '');
    }
    if (!this.nutritionquizForm.value.nuq_cant_eat_is_other) {
      this.resetField("nuq_cant_eat_other", '');
    }

    if (this.nutritionquizForm.value.nuq_cant_avoid_all) {
      this.resetField("nuq_cant_avoid_arr", null);
      this.resetField("nuq_cant_avoid_is_other", false);
      this.resetField("nuq_cant_avoid_other", '');
    }
    if (!this.nutritionquizForm.value.nuq_cant_avoid_is_other) {
      this.resetField("nuq_cant_avoid_other", '');
    }

    if (this.nutritionquizForm.value.nuq_new_food === 'לא') {
      this.resetField("nuq_new_food_arr_fixed", null);
      this.resetFormArray('nuq_new_food_arr');
    }

    if (this.nutritionquizForm.value.nuq_eat_vegetables == 'לא') {
      this.resetField("nuq_eat_vegetables_day", '');
    }
    if (this.nutritionquizForm.value.nuq_eat_fruits == 'לא') {
      this.resetField("nuq_eat_fruits_day", '');
    }
    if (this.nutritionquizForm.value.nuq_eat_snacks == 'לא') {
      this.resetField("nuq_eat_snacks_type", '');
      this.resetField("nuq_eat_snacks_freq", '');
      this.resetField("nuq_eat_snacks_time", '');
    }
    if (this.nutritionquizForm.value.nuq_eat_snacks == 'כן' && (this.nutritionquizForm.value.nuq_eat_snacks_freq == 'לעיתים רחוקות' || this.nutritionquizForm.value.nuq_eat_snacks_freq == '0-1 בשבוע')) {
      this.resetField("nuq_eat_snacks_time", '');
    }

    if (this.nutritionquizForm.value.nuq_eat_near_screen == 'ללא מסכים') {
      this.resetField("nuq_eat_near_screen_status", '');
    }
    if (this.nutritionquizForm.value.nuq_eat_outside == 'לעיתים רחוקות') {
      this.resetField("nuq_eat_outside_status", '');
    }
    if (this.nutritionquizForm.value.nuq_drink_sugar == 'לא') {
      this.resetField("nuq_drink_sugar_freq", '');
    }
    if (this.nutritionquizForm.value.nuq_drink_caffeine == 'לא') {
      this.resetField("nuq_drink_caffeine_items", null);
      this.resetField("nuq_drink_caffeine_cups", '');
      this.resetField("nuq_drink_caffeine_last", '');
    }

    // if (this.nutritionquizForm.value.nuq_eat_breakfast == 'ברור' || this.nutritionquizForm.value.nuq_eat_breakfast == 'כמעט תמיד') {
    //   this.resetField("nuq_eat_breakfast_reason", '');
    // }
    // if (this.nutritionquizForm.value.nuq_eat_breakfast_reason != 'אחר') {
    //   this.resetField("nuq_eat_breakfast_reason_other_details", '');
    // }
    if (this.nutritionquizForm.value.nuq_eat_breakfast == 'תמיד' || this.nutritionquizForm.value.nuq_eat_breakfast == 'כמעט תמיד') {
      this.resetFormArray("nuq_eat_breakfast_reasons");
    }
    if (this.nutritionquizForm.value.nuq_eat_breakfast_reasons && !this.nutritionquizForm.value.nuq_eat_breakfast_reasons.includes('אחר')) {
      this.resetField("nuq_eat_breakfast_reason_other_details", '');
    }

    if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(this.nutritionquizForm.value.nuq_eat_breakfast) === -1) {
      // this.resetField("nuq_eat_breakfast_dairy_meat", '');
      this.resetField("nuq_breakfast_time", '');
      this.resetField("nuq_is_breakfast_details", false);
      this.clearArrayField("nuq_breakfast_assets");
    }

    if (this.isUnderNineteen) {
      if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(this.nutritionquizForm.value.nuq_eat_elevenses) === -1 && ['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(this.nutritionquizForm.value.nuq_eat_elevenses_no_school) === -1) {
        this.resetField("nuq_eat_elevenses_enough_food", '');
        // this.resetField("nuq_eat_elevenses_no_school", '');
        // this.resetField("nuq_eat_elevenses_dairy_meat", '');
        this.resetField("nuq_elevenses_time", '');
        this.resetField("nuq_is_elevenses_details", false);
        this.clearArrayField("nuq_elevenses_assets");
      }
    } else {
      if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(this.nutritionquizForm.value.nuq_eat_elevenses) === -1) {
        this.resetField("nuq_eat_elevenses_enough_food", '');
        this.resetField("nuq_eat_elevenses_no_school", '');
        // this.resetField("nuq_eat_elevenses_dairy_meat", '');
        this.resetField("nuq_elevenses_time", '');
        this.resetField("nuq_is_elevenses_details", false);
        this.clearArrayField("nuq_elevenses_assets");
      }

    }

    // if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(this.nutritionquizForm.value.nuq_eat_lunch) === -1) {
    //   this.resetField("nuq_eat_lunch_dairy_meat", '');
    //   this.resetField("nuq_lunch_time", '');
    //   this.resetField("nuq_is_lunch_details", false);
    //   this.clearArrayField("nuq_lunch_assets");
    // }
    if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(this.nutritionquizForm.value.nuq_eat_tea) === -1) {
      this.resetField("nuq_eat_tea_dairy_meat", '');
      this.resetField("nuq_tea_time", '');
      this.resetField("nuq_is_tea_details", false);
      this.clearArrayField("nuq_tea_assets");
    }
    // if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(this.nutritionquizForm.value.nuq_eat_dinner) === -1) {
    //   this.resetField("nuq_eat_dinner_dairy_meat", '');
    //   this.resetField("nuq_dinner_time", '');
    //   this.resetField("nuq_is_dinner_details", false);
    //   this.clearArrayField("nuq_dinner_assets");
    // }
    if (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(this.nutritionquizForm.value.nuq_eat_night) === -1) {
      this.resetField("nuq_eat_night_dairy_meat", '');
      this.resetField("nuq_night_time", '');
      this.resetField("nuq_is_night_details", false);
      this.clearArrayField("nuq_night_assets");
    }

    if (this.nutritionquizForm.value.nuq_more_food_comments == 'לא') {
      this.resetField("nuq_more_food_comments_text", '');
    }

    // a special case where one or more of the checkboxes was in the state of "true", then user submitted the form,
    // after which the "nuq_eat_no_limits" was clicked.
    // this causes the checkboxes above it to be disabled, and their values disappear from the this.nutritionquizForm.value property.
    // if the user submits again, the server does not get the properties of the disabled checkboxes, and thus "thinks" they are still in 
    // the state of "true". This is why we assign their values and "false" directly to this.nutritionquizForm.value.
    // The this.nutritionquizForm.value is updated right after the server call, so we don't need to worry
    // if (this.nutritionquizForm.value.nuq_eat_no_limits == true) {
    //   this.nutritionquizForm.value.nuq_food_does_not_like = false;
    //   this.nutritionquizForm.value.nuq_food_does_not_eat_health_reason = false;
    //   this.nutritionquizForm.value.nuq_like_but_try_avoid_eating = false;
    //   this.nutritionquizForm.value.nuq_eat_a_lot = false;
    // }
  }

  resetFormArray(arrayFieldName) {
    let formArray = this.nutritionquizForm.get(arrayFieldName) as FormArray;
    let length: number = formArray.length;
    for (let i = 0; i < length; i++) {
      formArray.removeAt(0);
    }
  }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];

  }

  requiredIfValidator(predicate: Function): ValidatorFn | null {
    return ((formControl) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    })
  }

  updateChkbxArray(value: string, isChecked: boolean, key: string) {
    const chkArray = <FormArray>this.nutritionquizForm.get(key);
    if (!chkArray) {
      return;
    }
    const idx = chkArray.controls.findIndex(x => x.value == value);
    if (isChecked && idx === -1) {
      chkArray.push(new FormControl(value));
    }
    if (!isChecked && idx > -1) {
      chkArray.removeAt(idx);
    }
  }

  checkedFnArray(value, isChecked, key) {
    const control = this.nutritionquizForm.get(key);
    let val = control.value ? [...control.value] : [];
    if (isChecked) {
      if (!val.includes(value)) {
        val.push(value);
      }
    } else {
      let idx = val.findIndex(x => x === value);
      if (idx > -1) {
        val.splice(idx, 1);
      }
    }
    control.patchValue(val, { emitEvent: false });
    control.updateValueAndValidity();
  }

  setUrineColor(urineColor) {
    if (this.isLocked) return;
    // if (this.nutritionistFormMode) {return;}

    this.nutritionquizForm.get('nuq_urine_color').setValue(urineColor);
  }

  // getCategoriesChildren(nutcategories: any[]) {

  //   for (const category of nutcategories) {
  //     if (category?.n_parent_id) {
  //       // allChildren.push(category);
  //       const parentCategory = nutcategories.find(cat => cat._id === category.n_parent_id);
  //       if (parentCategory) {
  //         if (!parentCategory.children) {
  //           parentCategory.children = [category];
  //         } else {
  //           if (!parentCategory.children.find(cat => cat._id === category._id)) {
  //             parentCategory.children.push(category);
  //           }
  //         }
  //       }
  //     }
  //   }

  //   return nutcategories;
  // }

  async getCategoriesData() {
    const nutcategories: any[] = await this.restApiService.service('nutcategories').find({
      query: {
        $paginate: false,
        $select: ['_id', 'label'],
      },
    });

    const getMappedItems = (idsArray: string[]) => {
      return idsArray.map(filterItem => nutcategories.find(item => item?.label === filterItem || item?._id === filterItem)).filter(x => x).map(catItem => ({ value: catItem._id, text: catItem.label }));
    }

    const mapped = getMappedItems(this.fixedCatList);
    mapped.push(({ value: 'אחר', text: this.translate.instant("nutrition.FOOD_14") }));

    this.listNewFood = mapped;

    this.listCantEat = getMappedItems(this.fixedCantEatList);
    this.listCantAvoid = getMappedItems(this.fixedCantAvoidList);
  }

  async getAllData() {

    await this.getCategoriesData();

    const allProductsData = await this.restApiService.service('actions').create({ cmd: 'get-my-products', cmdData: { filterNutTypes: [] } });

    // console.log(allProductsData);

    if (allProductsData) {
      const { products, productsByCategories, nutallergies, nutcategories, nutingredients, nuttags, nuttypes } = allProductsData;

      this.allProductsByCategories = productsByCategories;

      // this.getCategoriesChildren(nutcategories);

      this.nutallergies = nutallergies;
      // this.nutcategories = nutcategories;
      this.nutcategories = [{ _id: 'water', label: 'מים', reorder: 1, checked: true, weekly: {} }, ...nutcategories];
      // console.log('this.nutcategories', this.nutcategories)
      this.nutingredients = nutingredients;
      this.nuttags = nuttags;
      this.nuttypes = nuttypes;

      this.allProducts = products;
      // this.allProductsNutStyle = products;
      this.allProductsNutStyle = this.filterProductsByUserPref(this.allProducts, this.nuttypes, this.nutritionquizForm.get('nuq_style')?.value);

      console.log('this.allProductsNutStyle1', this.allProductsNutStyle);
      // this.filterToCategoriesIngredients(this.allProductsNutStyle);
      this.filterToCategories(this.allProductsNutStyle);

      // const listNewFoodIds = this.getIdsByFilter(this.fixedCatList, nutcategories);

      // const listNewFoodItems = this.fixedCatList.map(filterItem => nutcategories.find(item => item?.label === filterItem || item?._id === filterItem)).filter(x => x);
      // const mapped = listNewFoodItems.map(catItem => ({ value: catItem._id, text: catItem.label }));
      // mapped.push(({ value: 'אחר', text: this.translate.instant("nutrition.FOOD_14") }));

      // this.listNewFood = mapped;
    }

  }

  filterToCategoriesIngredients(allProducts: any[]) {
    console.log('filterToCategoriesIngredients');

    if (!allProducts) {
      return;
    }

    const allIngredients = this.nutingredients || [];

    if (this.allCategoriesGroups) {
      for (const cat of this.allCategoriesGroups) {
        if (cat?.filterIngredients && cat?.name) {

          const filterIngredientsIds = this.getIdsByFilter(cat.filterIngredients, allIngredients);

          // this.filteredProductsIngredients[cat.name] = allProducts.filter(product => product?.n_ingredients?.length && filterIngredientsIds.some(ingredient => product.n_ingredients.includes(ingredient)));

          const allProductsFiltered = allProducts.filter(product => product?.n_ingredients?.length && filterIngredientsIds.some(ingredient => product.n_ingredients.includes(ingredient)));

          const generatedOptions = this.generateOptions(allProductsFiltered, cat.name);

          // this.allOptionsIngredients[cat.name] = generatedOptions.allOptions;
          this.allOptionsIngredients[cat.name] = generatedOptions.options;
          // this.allCategoriesWithChildren = { ...this.allCategoriesWithChildren, ...generatedOptions.allCategoriesWithChildren };

        }
      }
    }

    console.log('this.allOptionsIngredients', this.allOptionsIngredients);
  }

  filterToCategories(allProducts: any[], meals?: string[]) {

    const chosenMeals = meals || ['BREAKFAST', 'ELEVENSES', 'LUNCH', 'TEA', 'DINNER', 'NIGHT'];
    console.log('filterToCategories chosenMeals', chosenMeals);

    if (!allProducts?.length) {
      return;
    }

    this.filterToCategoriesIngredients(allProducts);

    const allIngredients = this.nutingredients || [];

    const allProductsIds = allProducts.map(product => product._id);
    const allCategoriesIds = [];

    for (const mealName in this.allOptionsMeals) {
      if (Object.prototype.hasOwnProperty.call(this.allOptionsMeals, mealName) && chosenMeals.includes(mealName)) {

        const allMealCategories = this.allMealsGroups[mealName]?.categories;
        if (allMealCategories) {
          for (const catName in allMealCategories) {
            if (Object.prototype.hasOwnProperty.call(allMealCategories, catName)) {
              if (allMealCategories[catName]?.filterIngredients) {

                const filterIngredientsIds = this.getIdsByFilter(allMealCategories[catName].filterIngredients, allIngredients);

                // this.filteredProducts[mealName][catName] = allProducts.filter(product => product?.n_ingredients?.length && filterIngredientsIds.some(ingredient => product.n_ingredients.includes(ingredient)));

                const allProductsFiltered = allProducts.filter(product => product?.n_ingredients?.length && filterIngredientsIds.some(ingredient => product.n_ingredients.includes(ingredient)));

                const generatedOptions = this.generateOptions(allProductsFiltered, catName);

                // this.allOptionsMeals[mealName][catName] = generatedOptions.allOptions;
                this.allOptionsMeals[mealName][catName] = generatedOptions;

                allCategoriesIds.push(...generatedOptions.categoriesIds);
              }
            }
          }
        }

      }
    }

    console.log('this.allOptionsMeals', this.allOptionsMeals);

    if (!meals) {
      // all meals

      for (const catName in this.allOptionsIngredients) {
        if (Object.prototype.hasOwnProperty.call(this.allOptionsIngredients, catName)) {
          const allOptions = this.allOptionsIngredients[catName];
          if (allOptions) {
            for (const option of allOptions) {
              allCategoriesIds.push(option._id);
            }
          }
        }
      }
      this.cleanNotAvailable(allCategoriesIds, allProductsIds);
    }
  }

  fillMealsChildrenProducts() {
    for (const mealName in this.allOptionsMeals) {
      if (Object.prototype.hasOwnProperty.call(this.allOptionsMeals, mealName)) {

        const allMealCategories = this.allMealsGroups[mealName]?.categories;
        if (allMealCategories) {
          for (const catName in allMealCategories) {
            if (Object.prototype.hasOwnProperty.call(allMealCategories, catName)) {

              const currentGeneratedOptions = this.allOptionsMeals[mealName][catName];

              if (currentGeneratedOptions) {
                const { options, products, productsByCategories } = currentGeneratedOptions;
                const newOptions = this.fillChildrenProducts(options, productsByCategories, catName);
                this.allOptionsMeals[mealName][catName].options = newOptions;
                // this.fillChildrenProducts(options, productsByCategories , catName);
              }

            }
          }
        }

      }
    }
  }

  getIdsByFilter(filterArr: string[], allArr: any[], key = 'label') {
    return filterArr.map(filterItem => allArr.find(item => item[key] === filterItem)?._id).filter(x => x);
  }

  filterProductsByUserPref(allProducts: any[], nuttypes: any[] = [], nutStyle: string, userAllergies?: any[]) {

    console.log("allProducts", allProducts);


    let filterStyle = [];
    switch (nutStyle) {
      case 'דגים':
        filterStyle = ['בשרי'];
        break;

      case 'צמחוני':
        filterStyle = ['דגים', 'בשרי'];
        break;

      case 'טבעוני':
        filterStyle = ['דגים', 'בשרי', 'צמחוני'];
        break;

      default:
        break;
    }

    if (allProducts && filterStyle.length) {
      const filterStyleIds = filterStyle.map(filterItem => nuttypes.find(nuttype => nuttype?.label === filterItem)?._id).filter(x => x);

      return allProducts.filter(product => filterStyleIds.indexOf(product.n_type) === -1);
    } else {
      return allProducts;
    }
  }

  filterChosenCategories(chosenCategories: any) {
    let chosen: any = {};
    for (const categoryId in chosenCategories) {
      const category = chosenCategories[categoryId];
      if (category?.checked) {
        chosen[categoryId] = category;
      }
    }

    return chosen;
  }

  updateChosenCategories(chosenCategories: any, key: 'checked' | 'weekly' | 'checkedTypical', mealType?: string) {
    const myChosenCategories = { ...this.myChosenCategories, ...chosenCategories };

    // const myChosenCategories = { ...this.myChosenCategories };
    if (false && chosenCategories) {
      for (const catId in chosenCategories) {
        if (Object.prototype.hasOwnProperty.call(chosenCategories, catId)) {
          const element = chosenCategories[catId];
          if (myChosenCategories[catId]) {

            // if (element?.childrenProducts) {
            //   myChosenCategories[catId].childrenProducts = element.childrenProducts;
            // }

            if (!mealType) {
              // ingredient

              myChosenCategories[catId][key] = element?.[key];
              // if (element?.[key]) { // === true
              //   myChosenCategories[catId][key] = true;
              // } else {
              //   // myChosenCategories[catId][key] = false;
              //   myChosenCategories[catId] = undefined;
              //   delete myChosenCategories[catId];
              // }
            } else {
              // weekly or typical
              if (!myChosenCategories[catId][key]) {
                myChosenCategories[catId][key] = {};
              }
              myChosenCategories[catId][key][mealType] = element?.[key]?.[mealType];
            }
          } else {
            // myChosenCategories[catId] = element;

            if (!mealType) {
              // ingredient
              if (element) {
                const { weekly, checkedTypical, ...rest } = element
                myChosenCategories[catId] = { ...rest, weekly: {}, checkedTypical: {} };
              }

            } else {
              // weekly or typical
              myChosenCategories[catId] = element;
            }
          }
        }
      }
    }

    const filtered = this.filterChosenCategories(myChosenCategories);

    this.fillMealsChildrenProducts();

    this.myChosenCategories = filtered;
    console.log('updateChosenCategories - this.myChosenCategories', this.myChosenCategories);

    if (this.nutritionquizForm) {
      this.nutritionquizForm.get('nuq_chosen_categories').setValue(this.myChosenCategories);
    }

    // this.fillMealsChildrenProducts();
  }

  // filterChosenProducts(chosenProducts: any) {
  //   let chosen: any = {};
  //   for (const productId in chosenProducts) {
  //     const product = chosenProducts[productId];
  //     if (product?.checked) {
  //       chosen[productId] = product;
  //     }
  //   }

  //   return chosen;
  // }

  // async ngOnInit() {
  //   await this.getAllData();
  // }

  async ngOnInit() {
    await this.getAllData();
  }

  openImageDialog(imgSrc: string) {
    const options = {
      maxWidth: "90vw",
      panelClass: "p-0-dialog",
      data: {
        imgSrc,
        hideDownload: Boolean(this.appState.user.usr_type !== 'superadmin' && this.appState.user.usr_type !== 'nutri'),
      }
    };
    this.dialog.open(ImageDialogComponent, options);

  }

  getHours(allHours: string[], fromHour: string) {
    const idx = allHours.findIndex(hour => hour === fromHour);
    if (idx > 0) {
      const slicedStart = allHours.slice(0, idx);
      const slicedEnd = allHours.slice(idx, allHours.length);
      return [...slicedEnd, ...slicedStart];
    }
    return allHours;
  }

  goBackStep() {
    if (this.selectedTab > 0) {
      this.selectedTab = this.selectedTab - 1;
    }
  }

  async goNextStep() {
    this.clearValuesFromUnusedFields();
    const validateRes = this.validateForm();
    if (!validateRes.valid) {
      if (validateRes.selectedTab !== undefined && validateRes.selectedTab > this.selectedTab) {
        // await this.onSubmit(this.nutritionquizForm, this.quizPrefix, true);
        this.onSubmit(this.nutritionquizForm, this.quizPrefix, true);
        if (this.selectedTab < 10) {
          this.showErrors = false;
          this.selectedTab = this.selectedTab + 1;
        }
      } else if (validateRes.selectedTab !== undefined && validateRes.selectedTab <= this.selectedTab) {
        this.selectedTab = validateRes.selectedTab;
        this.showErrors = true;
        this.showStatus(validateRes.msg, null);
        if (validateRes.scrollIntoNativeElement) {
          validateRes.scrollIntoNativeElement.nativeElement.scrollIntoView();
        } else if (validateRes.selectedTab === 3) {
          setTimeout(() => {
            const element = document.querySelector(`.error-class`);

            console.log(element)
            if (element) {
              element.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
          }, 200);
        }
      }
    } else {
      // await this.onSubmit(this.nutritionquizForm, this.quizPrefix, this.selectedTab < 10);
      this.onSubmit(this.nutritionquizForm, this.quizPrefix, this.selectedTab < 10);
      if (this.selectedTab < 10) {
        this.showErrors = false;
        this.selectedTab = this.selectedTab + 1;
      }
    }

    console.log('showErrors', this.showErrors);

  }

  generateOptions(allProductsData: any[], catName: string) {
    const products = allProductsData;

    const productsByCategories = this.extractCategories(products, catName);

    const categoriesIds = Object.keys(productsByCategories);

    const allCategoriesWithChildren = this.getChildCategories(productsByCategories);

    const objValuesArr = Object.values(allCategoriesWithChildren);

    const allOptionsSorted = objValuesArr.sort((a: any, b: any) => {
      if (!b?.reorder) return -1;
      if (!a?.reorder) return 0;

      return (a?.reorder ?? 0) - (b?.reorder ?? 0);
      return 0;
    });

    const options = allOptionsSorted;

    // const allOptions = this.fillChildrenProducts(allOptionsSorted, productsByCategories, catName);

    // return { allOptions, products, productsByCategories, allCategoriesWithChildren };

    return { options, products, productsByCategories, categoriesIds }; //, allCategoriesWithChildren
  }

  extractCategories(products: any[], catName: string) {
    let productsByCategories: any = {};

    for (const product of products) {
      if (product.n_categories?.length) {
        for (const categoryObjectId of product.n_categories) {
          const catId = categoryObjectId.toString();
          if (!productsByCategories[catId]) {
            productsByCategories[catId] = [product];
          } else {
            if (!productsByCategories[catId].find((pr: any) => pr._id === product._id)) {
              productsByCategories[catId].push(product);
            }
          }
        }
      }
    }

    if (catName === 'drink') {
      productsByCategories['water'] = [{}];
    }

    return productsByCategories;
  }

  getChildCategories(productsByCategories: any) {
    const allCategories = this.nutcategories || [];

    let allCategoriesWithChildren: any = {};
    let allChildren: any[] = [];

    for (const category of allCategories) {
      if (category?._id) {

        if (!category.weekly) {
          category.weekly = {}
        }
        // if (!category.childrenProducts) {
        //   category.childrenProducts = {}
        // }
        if (!category.checkedTypical) {
          category.checkedTypical = {}
        }

        if (!category.n_parent_id) {

          // if (!category.weekly) {
          //   category.weekly = {}
          // }
          // if (!category.childrenProducts) {
          //   category.childrenProducts = {}
          // }
          // if (!category.checkedTypical) {
          //   category.checkedTypical = {}
          // }

          if (productsByCategories[category._id]?.length) {
            allCategoriesWithChildren[category._id] = category;
          }
        } else {
          allChildren.push(category);
        }
      }
    }

    for (const child of allChildren) {
      if (productsByCategories[child._id]?.length) {
        if (!allCategoriesWithChildren[child.n_parent_id]) {
          const parentCategoryFind = allCategories.find(cat => cat._id === child.n_parent_id);
          if (!parentCategoryFind) {
            continue;
          }
          allCategoriesWithChildren[child.n_parent_id] = parentCategoryFind;
        }
        if (!allCategoriesWithChildren[child.n_parent_id].children) {
          allCategoriesWithChildren[child.n_parent_id].children = [child];
        } else {
          if (!allCategoriesWithChildren[child.n_parent_id].children.find(cat => cat._id === child._id)) {
            allCategoriesWithChildren[child.n_parent_id].children.push(child);
          }
        }
      }
    }

    return allCategoriesWithChildren;

  }

  // fillOptionsChildrenProducts(allCategoriesWithChildren: any = {}, productsByCategories: any = {}, catName: string) {
  //   for (const option of allCategoriesWithChildren) {
  //     const { _id } = option;

  //     let childrenProducts = [];
  //     if (productsByCategories[_id]) {
  //       childrenProducts.push(...productsByCategories[_id]);
  //     }
  //     if (option.children) {
  //       for (const childOption of option.children) {
  //         // if (childOption.checked && productsByCategories[childOption._id]) {
  //         //   childrenProducts.push(...productsByCategories[childOption._id]);
  //         // }
  //         if (productsByCategories[childOption._id]) {
  //           childrenProducts.push(...productsByCategories[childOption._id]);
  //         }
  //       }
  //     }

  //     if (!option.childrenProducts) {
  //       option.childrenProducts = {}
  //     }
  //     if (catName) {
  //       option.childrenProducts[catName] = childrenProducts;
  //     }

  //   }

  //   return allCategoriesWithChildren;
  // }

  fillChildrenProducts(allOptions: any[] = [], productsByCategories: any = {}, catName: string) {
    let filteredOptionsOnlyWithChildren = [];
    for (const option of allOptions) {
      const { _id } = option;

      let childrenProducts = [];
      if (productsByCategories[_id]) {
        childrenProducts.push(...productsByCategories[_id]);
      }
      if (option.children) {
        for (const childOption of option.children) {
          if (childOption.checked && productsByCategories[childOption._id]) {
            childrenProducts.push(...productsByCategories[childOption._id]);
          }
          // if (productsByCategories[childOption._id]) {
          //   childrenProducts.push(...productsByCategories[childOption._id]);
          // }
        }
      }

      childrenProducts = childrenProducts.filter((item, pos) => childrenProducts.findIndex(p => p._id && p._id === item?._id) == pos);

      if (!option.childrenProducts) {
        option.childrenProducts = {}
      }
      if (catName) {
        option.childrenProducts[catName] = childrenProducts;
      }

      if (childrenProducts.length || _id === 'water') {
        filteredOptionsOnlyWithChildren.push(option);
      }

    }

    // return allOptions;
    return filteredOptionsOnlyWithChildren;
  }

  // updateValidation() {

  //   if (this.myChosenCategories) {
  //     for (const key in this.myChosenCategories) {
  //       if (Object.prototype.hasOwnProperty.call(this.myChosenCategories, key)) {
  //         const element = this.myChosenCategories[key];

  //       }
  //     }
  //   }

  //   if (this.allCategoriesGroups) {
  //     for (const cat of this.allCategoriesGroups) {
  //       if (cat?.name && this.filteredProductsIngredients[cat.name]) {



  //         // const filterIngredientsIds = this.getIdsByFilter(cat.filterIngredients, allIngredients);

  //         // this.filteredProductsIngredients[cat.name] = allProducts.filter(product => product?.n_ingredients?.length && filterIngredientsIds.some(ingredient => product.n_ingredients.includes(ingredient)));
  //       }
  //     }
  //   }

  //   const allOptionsChosen = this.allOptions.filter((opt: any) => opt && opt.checked);
  //   const isValidAllOptions = allOptionsChosen.length > 0 ? allOptionsChosen.every(opt => !opt.children || (opt.children.length > 0 && opt.children.some(child => child.checked))) : (this.isOtherControl?.value && this.otherFoodFormArr?.value?.length > 0);
  //   // console.log('isValidAllOptions', isValidAllOptions)

  //   console.log('compType ingredient', this.mealType, this.nameType, this.disableAllNotEat?.value || isValidAllOptions);
  // }

  refreshCategories() {
    console.log('refreshCategories');
    this.filterToCategories(this.allProductsNutStyle || []);
    console.log('this.allProductsNutStyle2', this.allProductsNutStyle);
  }

  stripLastChosen(nuq_chosen_categories: any) {
    if (!nuq_chosen_categories) {
      return;
    }

    let allCategories: any = {};

    for (const categoryId in nuq_chosen_categories) {

      const item = nuq_chosen_categories[categoryId];
      if (item) {
        if (item.checked) {
          allCategories[categoryId] = { _id: item._id, checked: true };

          if (item.children) {
            for (const catChild of item.children) {
              if (catChild?.checked) {
                if (!allCategories[categoryId].children) {
                  allCategories[categoryId].children = [];
                }
                allCategories[categoryId].children.push({ _id: catChild._id, checked: true })
              }
            }
          }
        }
      }

    }

    return allCategories;
  }

  cleanBeforeFinalSave(formValue: any) {
    if (!formValue) {
      return;
    }

    const isBreakfast = ['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formValue.nuq_eat_breakfast) > -1;
    const isElevenses = (this.isUnderNineteen && (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formValue.nuq_eat_elevenses) > -1 || ['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formValue.nuq_eat_elevenses_no_school) > -1) || !this.isUnderNineteen && (['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formValue.nuq_eat_elevenses) > -1));
    const isLunch = true;
    const isTea = ['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formValue.nuq_eat_tea) > -1;
    const isDinner = true;
    const isNight = ['תמיד', 'כמעט תמיד', 'לפעמים'].indexOf(formValue.nuq_eat_night) > -1;

    const filterCategories = {
      'BREAKFAST': isBreakfast,
      'ELEVENSES': isElevenses,
      'LUNCH': isLunch,
      'TEA': isTea,
      'DINNER': isDinner,
      'NIGHT': isNight,
    };

    const filterProducts = {
      'BREAKFAST': isBreakfast && formValue.nuq_is_breakfast_details,
      'ELEVENSES': isElevenses && formValue.nuq_is_elevenses_details,
      'LUNCH': isLunch && formValue.nuq_is_lunch_details,
      'TEA': isTea && formValue.nuq_is_tea_details,
      'DINNER': isDinner && formValue.nuq_is_dinner_details,
      'NIGHT': isNight && formValue.nuq_is_night_details,
    };

    const notAllTrue = Object.keys(filterProducts).some(key => !key);

    if (notAllTrue) {
      const myChosenCategories = formValue.nuq_chosen_categories || {};

      for (const filterKey in filterCategories) {
        const includeCategory = filterCategories[filterKey];
        const includeProducts = filterProducts[filterKey];
        if (!includeCategory) {
          for (const key in myChosenCategories) {
            delete formValue.nuq_chosen_categories?.[key]?.checkedTypical?.[filterKey];
            delete formValue.nuq_chosen_categories?.[key]?.weekly?.[filterKey];
          }
        }
        if (!includeProducts) {
          delete formValue.nuq_chosen_products?.[filterKey];
        }
      }
    }

    return formValue;

  }

  cleanNotAvailable(allCategoriesIds: string[], allProductsIds: string[]) {
    console.log('cleanNotAvailable', allCategoriesIds.length, allProductsIds.length);

    if (!allCategoriesIds.length || !allProductsIds.length) {
      return;
    }

    if (this.myChosenCategories) {
      for (const key in this.myChosenCategories) {
        if (!(allCategoriesIds.includes(key) || (this.myChosenCategories[key]?.n_parent_id && allCategoriesIds.includes(this.myChosenCategories[key].n_parent_id)))) {
          console.log('category not in', key);
          // console.log('n_parent_id', this.myChosenCategories[key]?.n_parent_id);
          // console.log('allCategoriesIds', allCategoriesIds);
          this.myChosenCategories[key] = undefined;
          delete this.myChosenCategories[key];
        }
      }
      if (this.nutritionquizForm) {
        // console.log('this.myChosenCategories', this.myChosenCategories);
        this.nutritionquizForm.get('nuq_chosen_categories').setValue(this.myChosenCategories);
        // console.log('nuq_chosen_categories', this.nutritionquizForm.get('nuq_chosen_categories')?.value);
      }
    }

    // console.log('this.myChosenProducts', this.myChosenProducts);
    if (this.myChosenProducts) {
      for (const mealType in this.myChosenProducts) {
        if (Object.prototype.hasOwnProperty.call(this.myChosenProducts, mealType)) {
          const element = this.myChosenProducts[mealType];
          if (typeof element === 'object') {
            for (const key in element) {
              if (!allProductsIds.includes(key) && key !== 'water') {
                console.log('product not in', mealType, key);
                this.myChosenProducts[mealType][key] = undefined;
                delete this.myChosenProducts[mealType][key];
              }
            }
          }
        }
      }

      if (this.nutritionquizForm) {
        // console.log('this.myChosenProducts', this.myChosenProducts);
        this.nutritionquizForm.get('nuq_chosen_products').setValue(this.myChosenProducts);
        // console.log('nuq_chosen_products', this.nutritionquizForm.get('nuq_chosen_products')?.value);
      }
    }

  }

  // updateFromLastChosenCategories(allCategoriesWithChildren: any = {}, userChosenCategories: any = {}) {
  //   console.log('updateFromLastChosenCategories', allCategoriesWithChildren, userChosenCategories);

  //   let allCategories: any = {};

  //   for (const categoryId in allCategoriesWithChildren) {

  //     if (userChosenCategories[categoryId]) {
  //       if (userChosenCategories[categoryId].checked) {
  //         allCategoriesWithChildren[categoryId].checked = true;

  //         if (userChosenCategories[categoryId].children && allCategoriesWithChildren[categoryId].children) {
  //           for (const catChild of allCategoriesWithChildren[categoryId].children) {
  //             const userChosedCat = userChosenCategories[categoryId].children.find(child => child?._id && child._id === catChild?._id);
  //             if (userChosedCat?.checked) {
  //               catChild.checked = true;
  //             }
  //           }
  //         }

  //         allCategories[categoryId] = { ...allCategoriesWithChildren[categoryId] };
  //       }
  //     }

  //   }
  //   return allCategories;
  // }

}

