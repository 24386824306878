<div class="row m-0">
    <h1 class="m-b-0 m-t-15">{{ 'plans.TITLE' | translate }}</h1>
    <!-- <mat-checkbox style="display: none;" [(ngModel)]="testPlan">TEST</mat-checkbox> -->
    <button *ngIf="!paymentUrl && !generatingPaymentUrl" type="button" class="plus-button m-l-10 m-r-10 m-t-15" mat-raised-button [routerLink]="['/Profile/', appState.user._id]">
        {{ 'plans.BACK_TO_PROFILE' | translate }}
        <mat-icon>undo</mat-icon>
    </button>
    <button *ngIf="paymentUrl || generatingPaymentUrl" type="button" class="plus-button m-l-10 m-r-10 m-t-15" mat-raised-button (click)= "paymentUrl = null">
        {{ 'plans.BACK_TO_PLANS' | translate }}
        <mat-icon>undo</mat-icon>
    </button>
</div>

<div class="pricing-wrapper" [ngStyle]="{display: paymentUrl || generatingPaymentUrl ? 'none' : 'block'}">
    <owl-carousel-o [options]="customOptions">
        <ng-template *ngFor="let package of pricingPackages" carouselSlide>
            <div class="package-name">
                {{package.type}}
            </div>
            <div class="package-details"
                [ngClass]="{'selected': package.plan === 'F' && !appState.user['has_plan']}"
                (click)="selectPlan(package.plan)">
                <div class="package-price"> {{package.price}}</div>
                <div class="select-package">
                    <span class="selected-package" *ngIf="package.plan === 'PRO' || package.plan === 'STAR'">{{ 'plans.CHOOSE_PLAN' | translate }}</span>
                    <span [ngStyle]="{'visibility': appState.user['has_plan'] ? 'hidden' : 'visible'}" *ngIf="package.plan === 'F'">
                        <!-- &#10003; -->
                        {{ 'plans.CURRENT_PLAN' | translate }}
                    </span>
                </div>
                <div class="package-include">
                    <span class="material-icons">
                        done
                    </span>
                    <div> {{package.measures}}</div>
                </div>
                <div class="package-include">
                    <span class="material-icons">
                        done
                    </span>
                    <div> {{package.questionnaire}}</div>
                </div>
                <div class="package-include">
                    <span class="material-icons">
                        done
                    </span>
                    <div> {{package.eatingDiary}}</div>
                </div>
                <div class="package-include">
                    <span class="material-icons">
                        done
                    </span>
                    <div>{{package.growthReport}}</div>
                </div>
                <div class="package-include">
                    <span class="material-icons">
                        done
                    </span>
                    <div>{{package.bloodTests}}</div>
                </div>
                <div class="package-include">
                    <span>
                        {{package.sleepReportAmount}}
                    </span>
                    <div> {{package.sleepReport}}</div>
                </div>
                <div class="package-include">
                    <span> {{package.practiceScheduleAmount}}</span>
                    <div> {{package.practiceSchedule}}</div>
                </div>
                <div class="package-include">
                    <span> {{package.bloodTestsReportAmount}}</span>
                    <div> {{package.bloodTestsReport}}</div>
                </div>
                <div class="package-include">
                    <span> {{package.nutritionReportAmount}}</span>
                    <div> {{package.nutritionReport}}</div>
                </div>

                <div class="package-include">
                    <span class="material-icons">
                        {{ package.plan === 'F' ? 'close' : 'done' }}
                    </span>
                    <div> {{package.notifications}}</div>
                </div>

                <div class="package-include">
                    <span class="material-icons">
                        {{ package.plan === 'F' ? 'close' : 'done' }}
                    </span>
                    <div> {{package.reportsSupervision}}</div>
                </div>
                <div class="package-include">
                    <span class="material-icons">
                        done
                    </span>
                    <div> {{package.practiceFeeding}}</div>
                </div>
                <div class="package-include">
                    <span class="material-icons">
                        done
                    </span>
                    <div> {{package.practiceSuperVision}}</div>
                </div>

            </div>
        </ng-template>

    </owl-carousel-o>
</div>

<div class="spinner" *ngIf="generatingPaymentUrl">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
</div>

<div class="text-center m-t-30">
    <iframe id="payment-iframe" *ngIf="paymentUrl" [src]="paymentUrl"></iframe>
</div>

<div *ngIf="paymentUrl" class="text-center font-14">
    <div style="margin: 30px 0 15px; color: #505050;">
        {{ 'plans.FOOTER' | translate }}
    </div>
    <img width="100px" src="/assets/images/pci.png">
    <img width="100px" src="/assets/images/zcredit.png">
</div>
