    <div *ngIf="query != undefined" fxLayout="row" fxLayoutWrap="wrap"
    fxLayoutAlign="center none">

        <vzn-data-table #tableEl [restService]="restService" [pageSize]="25"
            [columns]="columns" [query]="query">
        </vzn-data-table>
        <!-- <vzn-data-table #tableEl [hidePaginator]="true" [restService]="restService" [pageSize]="25"
            [columns]="columns" [query]="query">
        </vzn-data-table> -->

    </div>

    <div>
        <button (click)="addTeamPractice()" type="button" mat-raised-button
            color="primary">{{'club.ADD_TEAM_PRACTICE' | translate}}</button>
    </div>


    <ng-template #userDialogTemplate let-context="context" let-form="form">

        <div mat-dialog-content>

            <form novalidate class="basic-form" [formGroup]="form">


                <mat-form-field class="w65">
                    <mat-select formControlName="tem_practice_type" placeholder="{{'club.TEAM_PRACTICE_TYPE' | translate}}">
                        <ng-container *ngFor="let item of practiceTypes">
                            <mat-option *ngIf="!item.hideTeam" [value]="item.title">{{item.title}}</mat-option>
                        </ng-container>
                        <mat-option value='אחר'>{{'club.PRACTICE_TYPE_A_5' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field> 

                <mat-form-field *ngIf="form.value.tem_practice_type == 'אחר'" class="w45">
                    <input formControlName="tem_practice_type_other_text" matInput placeholder="{{'club.TEAM_PRACTICE_TYPE_OTHER_TEXT' | translate}}">
                </mat-form-field>        
                
                
                <mat-form-field  class="w65">
                    <mat-select formControlName="tem_practice_times_week" placeholder="{{'club.TEAM_PRACTICE_TIMES_WEEK' | translate}}">
                        <mat-option *ngFor="let item of [1, 2, 3, 4, 5, 6, 7]" [value]="item">{{item}}</mat-option>
                    </mat-select>
                </mat-form-field>     
                
                <mat-form-field class="w45">
                    <mat-select formControlName="tem_practice_duration_minutes" placeholder="{{'food_diary.PRACTICE_DURATION' | translate}}">
                        <mat-option *ngFor="let time of durations" [value]="time">{{time}}</mat-option>
                    </mat-select>
                </mat-form-field>                


            </form>
        </div>

    </ng-template>   
     
