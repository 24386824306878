
<!-- <div *ngIf="!userState">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div> -->
<div class="user-progress" *ngIf="userState">
    <!-- <div class="fixed-buttons" style="display: flex; flex-direction: column;">
        <button type="button" mat-fab color="accent" [routerLink]="['/Fooddiaryquiz/']">
            <mat-icon>restaurant</mat-icon>
        </button>
        &nbsp;
        <button type="button" mat-fab color="accent" (click)="createPracticeDialog()">
            <mat-icon>fitness_center</mat-icon>
        </button>
        &nbsp;
        <button type="button" mat-fab color="accent" [routerLink]="['/Measurement/']">
            <mat-icon>addchart</mat-icon>
        </button>
    </div> -->
    <div class="fixed-buttons">
        <nav class="fab-custom-menu">
            <input type="checkbox" href="#" class="menu-open" name="menu-open" id="menu-open"/>
            <label class="menu-open-button" for="menu-open">
                <!-- <span class="hamburger hamburger-1"></span>
                <span class="hamburger hamburger-2"></span>
                <span class="hamburger hamburger-3"></span> -->
                <div class="plus-sign">+</div>
            </label>
            <button type="button" class="menu-item" mat-fab color="accent" [routerLink]="['/Fooddiaryquiz/']">
                <mat-icon>restaurant</mat-icon>
            </button>
            <button type="button" class="menu-item" mat-fab color="accent" (click)="createPracticeDialogFunc()">
                <mat-icon>fitness_center</mat-icon>
            </button>
            <!-- <button *ngIf="userState['measure_enabled']" type="button" class="menu-item" mat-fab color="accent" [routerLink]="['/Measurement/']">
                <mat-icon>addchart</mat-icon>
            </button> -->
            <button *ngIf="appState.user['measure_enabled']" type="button" class="menu-item" mat-fab color="accent" [routerLink]="['/Measurement/']">
                <mat-icon>addchart</mat-icon>
            </button>
        </nav>
    </div>

    <!-- <a *ngIf="waLink" [href]="waLink" class="wa-float" target="_blank">
        <i class="fa fa-whatsapp"></i>
    </a> -->

    <h1>
        <span>{{'status.hello' | translate}} {{ appState.user.usr_fname }}</span>
        <span *ngIf="totalQuizzes && !isDone">, {{ 'status.welcome' | translate }}</span>
    </h1>
    <h1 class="text-center" *ngIf="isDone && lastReportAt<lastCreatedAt">{{'status.congrats' | translate}}</h1>

    <mat-card *ngIf="totalQuizzes && !isDone">
        <div class="heq-col" style="line-height: 35px;">

            <mat-checkbox *ngIf="userState['heq']?.exist && !userState['heq']?.locked" [routerLink]="['/Healthquiz/']" class="checkbox mb75" disabled [checked]="userState['heq']?.done">
                <button mat-stroked-button class="font-18">
                    {{'status.health' | translate}}
                </button>
            </mat-checkbox>
            
            <mat-checkbox *ngIf="userState['prq']?.exist && !userState['prq']?.locked" [routerLink]="['/Practicequiz/']" class="checkbox mb75" disabled [checked]="userState['prq']?.done">
                <button mat-stroked-button class="font-18">
                    {{'status.practice' | translate}}
                </button>
            </mat-checkbox>
            
            <mat-checkbox *ngIf="userState['slq']?.exist && !userState['slq']?.locked" [routerLink]="['/Sleepingquiz/']" class="checkbox mb75" disabled [checked]="userState['slq']?.done">
                <button mat-stroked-button class="font-18">
                    {{'status.sleeping' | translate}}
                </button>
            </mat-checkbox>
            
            <!-- <mat-checkbox *ngIf="userState['nuq']?.exist && !userState['nuq']?.locked" [routerLink]="['/Nutritionquiz/']" class="checkbox mb75" disabled [checked]="userState['nuq']?.done">
                <button mat-stroked-button class="font-18">
                    {{'status.nutrition' | translate}}
                </button>
            </mat-checkbox> -->
            <mat-checkbox *ngIf="userState['nuq']?.exist && !userState['nuq']?.locked" [routerLink]="['/Nutquiz/']" class="checkbox mb75" disabled [checked]="userState['nuq']?.done">
                <button mat-stroked-button class="font-18">
                    {{'status.nutrition' | translate}}
                </button>
            </mat-checkbox>

            <ng-container *ngIf="userState['btq']?.exist && !userState['btq']?.locked">
                <mat-checkbox [routerLink]="['/Bloodtestquiz/']" class="checkbox mb75" disabled [checked]="userState['btq_first']?.done || userState['btq']?.done" *ngIf="(!userState['btq_first']?.done && !userState['btq']?.done) || (userState['btq_first']?.done && userState['btq']?.done)">
                    <button mat-stroked-button class="font-18">
                        {{'status.bloodtest_first' | translate}}
                    </button>
                </mat-checkbox>
                <mat-checkbox [routerLink]="['/Bloodtestquiz/']" class="checkbox mb75" disabled *ngIf="userState['btq_first']?.done && !userState['btq']?.done">
                    <button mat-stroked-button class="font-18">
                        {{'status.bloodtest' | translate}}
                    </button>
                </mat-checkbox>
            </ng-container>

            <mat-checkbox *ngIf="userState['fdq']?.exist && !userState['fdq']?.hidden && !userState['fdq']?.locked && userState['fdq'].count == '0'" [routerLink]="['/Fooddiaryquiz/']" class="checkbox mb75" disabled>
                <button mat-stroked-button class="font-18">
                    {{'status.food_diary' | translate}}
                </button>
            </mat-checkbox>
            <mat-checkbox *ngIf="userState['fdq']?.exist && !userState['fdq']?.hidden && !userState['fdq']?.locked && userState['fdq'].count == '1'" [routerLink]="['/Fooddiaryquiz/']" class="checkbox mb75" disabled>
                <button mat-stroked-button class="font-18">
                    {{'status.food_diary_2' | translate}}
                </button>
            </mat-checkbox>
            <mat-checkbox *ngIf="userState['fdq']?.exist && !userState['fdq']?.hidden && !userState['fdq']?.locked && userState['fdq'].count == '2'" [routerLink]="['/Fooddiaryquiz/']" class="checkbox mb75" disabled>
                <button mat-stroked-button class="font-18">
                    {{'status.food_diary_1' | translate}}
                </button>
            </mat-checkbox>
            <mat-checkbox *ngIf="userState['fdq']?.exist && !userState['fdq']?.hidden && !userState['fdq']?.locked && userState['fdq'].count && +(userState['fdq'].count) > 2" [routerLink]="['/Fooddiaryquiz/']" class="checkbox mb75" disabled [checked]="true">
                <button mat-stroked-button class="font-18">
                    {{'status.food_diary_0' | translate}}
                </button>
            </mat-checkbox>

            <!-- <mat-checkbox *ngIf="userState['measure_enabled'] && userState['ntq']?.exist && !userState['ntq']?.locked" [routerLink]="['/Measurement/']" class="checkbox mb75" disabled [checked]="userState['ntq']?.done">
                <button mat-stroked-button class="font-18">
                    {{'status.measurement' | translate}}
                </button>
            </mat-checkbox> -->
            <mat-checkbox *ngIf="appState.user['measure_enabled'] && userState['ntq']?.exist && !userState['ntq']?.locked" [routerLink]="['/Measurement/']" class="checkbox mb75" disabled [checked]="userState['ntq']?.done">
                <button mat-stroked-button class="font-18">
                    {{'status.measurement' | translate}}
                </button>
            </mat-checkbox>
            
        </div>
        <div *ngIf="totalQuizzes > 1" class="stepper-container">
            <!-- <mat-card> -->
                <mat-horizontal-stepper #stepper labelPosition="bottom" disableRipple="true" [selectedIndex]="totalQuizzes">
                    <ng-container *ngFor="let item of [].constructor(totalQuizzes); let i = index;">
                        <mat-step [completed]="stepperCompleted > i" editable="false"></mat-step>
                    </ng-container>
                </mat-horizontal-stepper>
            <!-- </mat-card> -->
        </div>
    </mat-card>
    <mat-card class="checkmark-card" *ngIf="isDone && lastReportAt < lastCreatedAt">
        <div class="checkmark">
            &#10003;
        </div>

        <div *ngIf="userState['has_plan']" class="font-bold font-16">
            <div class="m-b-5">
                {{'status.got_it' | translate}}
            </div>
            <div class="m-b-30">
                {{'status.soon' | translate}}
            </div>
        </div>
        <div *ngIf="!userState['has_plan']" class="font-bold font-16">
            <div class="m-b-5">
                {{'status.line_A_NO_PLAN' | translate}}
            </div>
            <div class="m-b-5">
                {{'status.line_B_NO_PLAN' | translate}}
            </div>
            <!-- <div class="m-b-30">
                {{'status.line_C_NO_PLAN' | translate}}
                <button [routerLink]="['/Plans']" mat-stroked-button class="font-18 m-r-5">
                    Go All Star
                </button>
            </div> -->
        </div>
    </mat-card>
    <mat-card *ngIf="userReports.length > 0">
        <div *ngFor="let report of userReports" class="text-center m-b-20">
            <button type="button" mat-raised-button color="primary" (click)="viewReport(report['_id'])">
                {{'status.report' | translate}} - {{ report['createdAt'] | date:'dd/MM/yyyy' }}
            </button>
        </div>
    </mat-card>
</div>

<user-practice *ngIf="userId" [userIdInput]="userId" [onlyDialog]="true"></user-practice>

<ng-template #popupTemplate let-context="context" let-form="form">
    <div class="basic-form mat-body" [formGroup]="form">
        <div class="d-flex mb125" style="justify-content: flex-end;">
            <div class="font-12 dir-ltr">
                {{ form.value.sent_at | dateFormat : 'DD/MM HH:mm' }}
            </div>
        </div>

        <div class="p-20 text-center mb125">
            <!-- <span class="font-bold font-16"> {{ form.value.body }} </span> -->
            <span class="font-18" [innerHTML]="form.value.body"></span>
        </div>
    </div>
</ng-template>
