<div class="full-loader" *ngIf="busy || !userData">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>

<div class="nutritionist-form" [ngClass]="{'loading-margin': busy || !userData}">
    <div class="fixed-buttons">
        <div *ngIf="appState.user.usr_type === 'superadmin'" class="m-r-5 m-l-5" style="display: inline-block;">
            <mat-checkbox [(ngModel)]="oldNotesFlag"></mat-checkbox>
        </div>
        &nbsp;
        <button type="button" mat-mini-fab color="warn" [disabled]="busy" (click)="newFollow()">
            <mat-icon>add_task</mat-icon>
        </button>
        &nbsp;
        <button type="button" mat-mini-fab color="primary" [disabled]="busy" (click)="saveAll()">
            <mat-icon>save</mat-icon>
        </button>
        <!-- &nbsp;
        <button type="button" mat-mini-fab color="accent" (click)="openAllPanels()">
            <mat-icon>unfold_more</mat-icon>
        </button>
        &nbsp;
        <button type="button" mat-mini-fab color="accent" (click)="closeAllPanels()">
            <mat-icon>unfold_less</mat-icon>
        </button> -->
    </div>
    <div class="row">
        <h1 (dblclick)="updateState()">
            טופס תזונאי
        </h1>
        <div *ngIf="userData && (appState.user.usr_type === 'superadmin' || appState.user['usr_nutri_autofill'])">
            <button type="button" mat-stroked-button color="accent" [disabled]="busy" (click)="autofillCheck()">
                <!-- מילוי אוטומטי -->
                <mat-icon>insights</mat-icon>
            </button>
        </div>
        <div class="m-b-20" *ngIf="userData">
            <div class="buttons-row" style="text-align: left;">
                <!-- <button *ngIf="userData.usr_quiz_future && userData.usr_quiz_future.length" type="button" mat-icon-button (click)="showAllFuturePopup()">
                    <mat-icon class="text-danger">event_available</mat-icon>
                </button> -->
                <button type="button" mat-raised-button color="accent" [disabled]="busy" (click)="createAllQuizes()">
                    צור שאלונים חדשים
                    <mat-icon>add</mat-icon>
                </button>
                <button type="button" mat-raised-button color="primary" [disabled]="busy" (click)="saveAndLockAll()">
                    שמור הערות ונעל שאלונים
                    <mat-icon>check</mat-icon>
                </button>
            </div>
            <div class="m-t-10" style="text-align: left;">
                <div style="display: inline-block;">
                    <mat-form-field class="report-dropdown" *ngIf="userReports.length > 0">
                        <button type="button" [disabled]="!chosenReport" matSuffix class="top-button" mat-stroked-button (click)="clickReport($event, chosenReport)">הצג דוח</button>
                        <mat-select [(ngModel)]="chosenReport">
                            <ng-container *ngFor="let val of userReports">
                                <mat-option [value]="val._id">
                                    {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div style="display: inline-block; position: relative; margin-right: 25px;">
                    <button type="button" mat-stroked-button class="dark-border-button" (click)="checkForChangesAndCreate()" [disabled]="busy || !userData.usr_age_formatted">
                        הפקת דוח חדש
                        <mat-icon>add</mat-icon>
                    </button>
                    <!-- <div *ngIf="createReportError.length > 0" class="report-error text-danger">
                        {{createReportError}}
                    </div> -->
                </div>
            </div>
        </div>
    </div>
    <!-- <mat-accordion
    #accordion="matAccordion" [multi]="true"
    >
        <div>
            <user-details-section *ngIf="userData" [user]="userData" [notesList]="allNotesUnfiltered" (busyState)="setBusy($event)" (openImage)="openImageDialog($event)"></user-details-section>
        </div>
        <div>
            <antropometrics-section *ngIf="userData" [user]="userData" (linkedNotesEvent)="linkedNote($event)" [notesList]="allNotes['ntq']" [sharedNotesFormA]="sharedNotesFormA" [allFdqValues]="allFdqValues" [oldNotesFlag]="oldNotesFlag" (busyState)="setBusy($event)" (updateFollow)="reloadUserDetailsNotes($event)" (kcalData)="setKcalData($event)" (onDelete)="openDeleteDialog($event)" (onNew)="openNewDialog($event)" (addNoteEvent)="notestableOpenNoteDialog($event)" (editNoteEvent)="notestableOpenEditNoteDialog($event)"></antropometrics-section>
        </div>
        <div>
            <health-section *ngIf="userData" [user]="userData" (linkedNotesEvent)="linkedNote($event)" [notesList]="allNotes['heq']" [sharedNotesFormA]="sharedNotesFormA" [allFdqValues]="allFdqValues" [oldNotesFlag]="oldNotesFlag" (busyState)="setBusy($event)" (updateFollow)="reloadUserDetailsNotes($event)" (onDelete)="openDeleteDialog($event)" (onNew)="openNewDialog($event)" (openImage)="openImageDialog($event)" (addNoteEvent)="notestableOpenNoteDialog($event)" (editNoteEvent)="notestableOpenEditNoteDialog($event)"></health-section>
        </div>
        <div>
            <bloodtest-section *ngIf="userData" [user]="userData" (linkedNotesEvent)="linkedNote($event)" [notesList]="allNotes['btq']" [sharedNotesFormA]="sharedNotesFormA" [allFdqValues]="allFdqValues" [oldNotesFlag]="oldNotesFlag" (busyState)="setBusy($event)" (updateFollow)="reloadUserDetailsNotes($event)" (onDelete)="openDeleteDialog($event)" (onNew)="openNewDialog($event)" (futureQuiz)="futurePopup(true, $event)" (addNoteEvent)="notestableOpenNoteDialog($event)" (editNoteEvent)="notestableOpenEditNoteDialog($event)"></bloodtest-section>
        </div>
        <div>
            <sleeping-section *ngIf="userData" [user]="userData" (linkedNotesEvent)="linkedNote($event)" [notesList]="allNotes['slq']" [sharedNotesFormA]="sharedNotesFormA" [allFdqValues]="allFdqValues" [oldNotesFlag]="oldNotesFlag" (busyState)="setBusy($event)" (updateFollow)="reloadUserDetailsNotes($event)" (onDelete)="openDeleteDialog($event)" (onNew)="openNewDialog($event)" (addNoteEvent)="notestableOpenNoteDialog($event)" (editNoteEvent)="notestableOpenEditNoteDialog($event)"></sleeping-section>
        </div>
        <div>
            <practices-section *ngIf="userData" [user]="userData" (linkedNotesEvent)="linkedNote($event)" [notesList]="allNotes['prq']" [sharedNotesFormA]="sharedNotesFormA" [allFdqValues]="allFdqValues" [oldNotesFlag]="oldNotesFlag" (busyState)="setBusy($event)" (updateFollow)="reloadUserDetailsNotes($event)" [kcal]="kcalData" (onDelete)="openDeleteDialog($event)" (onNew)="openNewDialog($event)" (addNoteEvent)="notestableOpenNoteDialog($event)" (editNoteEvent)="notestableOpenEditNoteDialog($event)"></practices-section>
        </div>
        <div>
            <nutrition-section *ngIf="userData" [user]="userData" (linkedNotesEvent)="linkedNote($event)" [notesList]="allNotes['nuq']" [sharedNotesFormA]="sharedNotesFormA" [allFdqValues]="allFdqValues" [oldNotesFlag]="oldNotesFlag" (busyState)="setBusy($event)" (updateFollow)="reloadUserDetailsNotes($event)" [userWeight]="kcalData ? kcalData.weight : 0" (onDelete)="openDeleteDialog($event)" (onNew)="openNewDialog($event)" (addNoteEvent)="notestableOpenNoteDialog($event)" (editNoteEvent)="notestableOpenEditNoteDialog($event)"></nutrition-section>
        </div>
        <div>
            <food-diary-section *ngIf="userData" [user]="userData" (linkedNotesEvent)="linkedNote($event)" [allNotes]="allNotesUnfiltered" [notesList]="allNotes['fdq']" [notesFormA]="sharedNotesFormA" [notesFormB]="sharedNotesFormB" [oldNotesFlag]="oldNotesFlag" (busyState)="setBusy($event)" (updateFollow)="reloadUserDetailsNotes($event)" [userWeight]="kcalData ? kcalData.weight : 0" (onDelete)="openDeleteDialog($event)" (onNew)="openNewDialog($event)" (addNoteEvent)="notestableOpenNoteDialog($event)" (editNoteEvent)="notestableOpenEditNoteDialog($event)" (removeNoteEvent)="removeNote($event)" (allFdqValuesRes)="setFdqValues($event)"></food-diary-section>
        </div>
        <div *ngIf="userData">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title class="font-16 font-bold">
                        <h4 class="m-auto">ניהול התראות</h4>
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <user-notes [user]="userData"></user-notes>

            </mat-expansion-panel>        
        </div>
    </mat-accordion> -->

    <div *ngIf="userData" class="override-tabs-container">
        <mat-tab-group [(selectedIndex)]="selectedTab" preserveContent color="transparent">
            <!-- <mat-tab-nav-bar backgroundColor="primary" color="warn">
                <mat-tab-link href="#" (click)="selectedTab = 0" [active]="selectedTab == 0"> פרטים אישיים </mat-tab-link>
                <mat-tab-link href="#" (click)="selectedTab = 1" [active]="selectedTab == 1"> מדדים </mat-tab-link>
                <mat-tab-link href="#" (click)="selectedTab = 2" [active]="selectedTab == 2"> Tab C </mat-tab-link>
                <mat-tab-link disabled>Disabled Tab</mat-tab-link>
              </mat-tab-nav-bar>  -->
                            
            <mat-tab [disabled]="busy" label="פרטים אישיים">
                <user-details-section [user]="userData" [notesList]="allNotesUnfiltered" (busyState)="setBusy($event)" (openImage)="openImageDialog($event)"></user-details-section>
            </mat-tab>
            <mat-tab [disabled]="busy" label="מדדים">
                <antropometrics-section [user]="userData" (linkedNotesEvent)="linkedNote($event)" [notesList]="allNotes['ntq']" [sharedNotesFormA]="sharedNotesFormA" [allFdqValues]="allFdqValues" [oldNotesFlag]="oldNotesFlag" (busyState)="setBusy($event)" (updateFollow)="reloadUserDetailsNotes($event)" (kcalData)="setKcalData($event)" (onDelete)="openDeleteDialog($event)" (onNew)="openNewDialog($event)" (addNoteEvent)="notestableOpenNoteDialog($event)" (editNoteEvent)="notestableOpenEditNoteDialog($event)"></antropometrics-section>
            </mat-tab>
            <mat-tab [disabled]="busy" label="היסטוריה וגרפים">
                <ng-container *ngIf="selectedTab == 2">
                    <charts-section [user]="userData"></charts-section>
                </ng-container>
            </mat-tab>
            <mat-tab [disabled]="busy" label="בריאות">
                <health-section [user]="userData" (linkedNotesEvent)="linkedNote($event)" [notesList]="allNotes['heq']" [sharedNotesFormA]="sharedNotesFormA" [allFdqValues]="allFdqValues" [oldNotesFlag]="oldNotesFlag" (busyState)="setBusy($event)" (updateFollow)="reloadUserDetailsNotes($event)" (onDelete)="openDeleteDialog($event)" (onNew)="openNewDialog($event)" (openImage)="openImageDialog($event)" (addNoteEvent)="notestableOpenNoteDialog($event)" (editNoteEvent)="notestableOpenEditNoteDialog($event)"></health-section>
            </mat-tab>
            <mat-tab [disabled]="busy" label="בדיקות דם">
                <bloodtest-section [user]="userData" (linkedNotesEvent)="linkedNote($event)" [notesList]="allNotes['btq']" [sharedNotesFormA]="sharedNotesFormA" [allFdqValues]="allFdqValues" [oldNotesFlag]="oldNotesFlag" (busyState)="setBusy($event)" (updateFollow)="reloadUserDetailsNotes($event)" (onDelete)="openDeleteDialog($event)" (onNew)="openNewDialog($event)" (futureQuiz)="futurePopup(true, $event)" (addNoteEvent)="notestableOpenNoteDialog($event)" (editNoteEvent)="notestableOpenEditNoteDialog($event)"></bloodtest-section>
            </mat-tab>
            <mat-tab [disabled]="busy" label="הרגלי שינה">
                <sleeping-section [user]="userData" (linkedNotesEvent)="linkedNote($event)" [notesList]="allNotes['slq']" [sharedNotesFormA]="sharedNotesFormA" [allFdqValues]="allFdqValues" [oldNotesFlag]="oldNotesFlag" (busyState)="setBusy($event)" (updateFollow)="reloadUserDetailsNotes($event)" (onDelete)="openDeleteDialog($event)" (onNew)="openNewDialog($event)" (addNoteEvent)="notestableOpenNoteDialog($event)" (editNoteEvent)="notestableOpenEditNoteDialog($event)"></sleeping-section>
            </mat-tab>
            <mat-tab [disabled]="busy" label="אימונים">
                <practices-section [user]="userData" (linkedNotesEvent)="linkedNote($event)" [notesList]="allNotes['prq']" [sharedNotesFormA]="sharedNotesFormA" [allFdqValues]="allFdqValues" [oldNotesFlag]="oldNotesFlag" (busyState)="setBusy($event)" (updateFollow)="reloadUserDetailsNotes($event)" [kcal]="kcalData" (setDrinkInPractice)="setDrinkInPractice($event)" (onDelete)="openDeleteDialog($event)" (onNew)="openNewDialog($event)" (addNoteEvent)="notestableOpenNoteDialog($event)" (editNoteEvent)="notestableOpenEditNoteDialog($event)"></practices-section>
            </mat-tab>
            <mat-tab [disabled]="busy" label="הרגלי אכילה">
                <nutrition-section [user]="userData" (linkedNotesEvent)="linkedNote($event)" [notesList]="allNotes['nuq']" [sharedNotesFormA]="sharedNotesFormA" [allFdqValues]="allFdqValues" [oldNotesFlag]="oldNotesFlag" (busyState)="setBusy($event)" (updateFollow)="reloadUserDetailsNotes($event)" [userWeight]="kcalData ? kcalData.weight : 0" [drinkInPractice]="drinkInPractice" (onDelete)="openDeleteDialog($event)" (onNew)="openNewDialog($event)" (addNoteEvent)="notestableOpenNoteDialog($event)" (editNoteEvent)="notestableOpenEditNoteDialog($event)"></nutrition-section>
            </mat-tab>
            <mat-tab [disabled]="busy" label="יומן אכילה">
                <food-diary-section [user]="userData" (linkedNotesEvent)="linkedNote($event)" [allNotes]="allNotesUnfiltered" [notesList]="allNotes['fdq']" [notesFormA]="sharedNotesFormA" [notesFormB]="sharedNotesFormB" [oldNotesFlag]="oldNotesFlag" (busyState)="setBusy($event)" (updateFollow)="reloadUserDetailsNotes($event)" [userWeight]="kcalData ? kcalData.weight : 0" (onDelete)="openDeleteDialog($event)" (onNew)="openNewDialog($event)" (addNoteEvent)="notestableOpenNoteDialog($event)" (editNoteEvent)="notestableOpenEditNoteDialog($event)" (removeNoteEvent)="removeNote($event)" (allFdqValuesRes)="setFdqValues($event)"></food-diary-section>
            </mat-tab>
            <mat-tab [disabled]="busy" label="התראות">
                <mat-expansion-panel disabled expanded>
                    <mat-expansion-panel-header>
                        <mat-panel-title class="font-16 font-bold">
                            <h4 class="m-auto">ניהול התראות</h4>
                        </mat-panel-title>
                    </mat-expansion-panel-header>
    
                    <user-notes [user]="userData"></user-notes>
    
                </mat-expansion-panel>
            </mat-tab>
        </mat-tab-group>

        <div class="centered-row" style="margin: 30px auto 60px; max-width: 500px;">
            <button type="button" (click)="selectedTab = selectedTab - 1" class="full-width m-l-10 m-r-10" mat-raised-button color="primary" [disabled]="selectedTab === 0 || busy">הקודם</button>
            <button type="button" (click)="selectedTab = selectedTab + 1" class="full-width m-l-10 m-r-10" mat-raised-button color="primary" [disabled]="selectedTab === 9 || busy">הבא</button>
        </div>
    </div>

    <div class="m-t-20" style="display: flex; flex-wrap: wrap; margin-right: 130px;" *ngIf="userData">
        <div class="buttons-row" style="text-align: right; margin-left: 60px;">
            <button type="button" mat-raised-button color="primary" [disabled]="busy" (click)="saveAndLockAll()">
                שמור הערות ונעל שאלונים
                <mat-icon>check</mat-icon>
            </button>
            <!-- <button type="button" mat-raised-button color="accent" (click)="createAllQuizes()">
                צור שאלונים חדשים
                <mat-icon>add</mat-icon>
            </button> -->
        </div>
        <div class="m-t-10" style="text-align: right; margin-left: 120px;">
            <div style="display: inline-block; position: relative; margin-left: 25px;">
                <button type="button" mat-stroked-button class="dark-border-button" (click)="checkForChangesAndCreate()" [disabled]="busy || !userData.usr_age_formatted">
                    הפקת דוח חדש
                    <mat-icon>add</mat-icon>
                </button>
                <!-- <div *ngIf="createReportError.length > 0" class="report-error text-danger">
                    {{createReportError}}
                </div> -->
            </div>
            <div style="display: inline-block;">
                <mat-form-field class="report-dropdown" *ngIf="userReports.length > 0">
                    <button type="button" [disabled]="!chosenReport" matSuffix class="top-button" mat-stroked-button (click)="clickReport($event, chosenReport)">הצג דוח</button>
                    <mat-select [(ngModel)]="chosenReport">
                        <ng-container *ngFor="let val of userReports">
                            <mat-option [value]="val._id">
                                {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

<ng-template #autofillTemplate let-form="form">
    <div class="basic-form mat-body mb75" [formGroup]="form">
        <!-- <p></p> -->
        <!-- <div class="mb75">
            <mat-checkbox class="mb50 checkbox" formControlName="reset_notes" [ngClass]="{'text-danger font-bold': form.value.reset_notes}">
                איפוס הערות קיימות
            </mat-checkbox>
        </div>

        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="userdetails">הערות משתמש</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="antropometrics">אנתרופומטריים</mat-checkbox>
        </div> -->
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="sleeping">שינה</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="practices">אימונים</mat-checkbox>
        </div>
        <!-- <div>
            <mat-checkbox class="mb50 checkbox" formControlName="health">בריאות</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="bloodtest">בדיקת דם</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="nutrition">תזונה</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="fooddiary">יומן אכילה</mat-checkbox>
        </div> -->
    </div>
</ng-template>

<!-- <ng-template #createAllTemplate>
    <button type="button" mat-button [mat-dialog-close]="data">בהמשך</button>
</ng-template> -->

<ng-template #futureTemplate let-form="form">
    <div class="basic-form mat-body mb75" [formGroup]="form">

        <div class="mb75">
            <mat-form-field>
                <input disabled #dueDate formControlName="due_date" matInput [matDatepicker]="dpInput" [min]="minDate" placeholder="תאריך יצירה">
                <mat-datepicker-toggle matSuffix [for]="dpInput"></mat-datepicker-toggle>
                <mat-datepicker #dpInput disabled="false"></mat-datepicker>
            </mat-form-field>
        </div>
        
        <!-- <div>
            <mat-checkbox class="mb50 checkbox" formControlName="ntq">אנתרופומטריים</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="prq">אימונים</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="slq">שינה</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="heq">בריאות</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="nuq">תזונה</mat-checkbox>
        </div>
        <div>
            <mat-checkbox class="mb50 checkbox" formControlName="fdq">יומן אכילה</mat-checkbox>
        </div> -->

        <!-- <div *ngIf="form.controls.btq" class="mt125">
            <mat-checkbox class="mb50 checkbox" formControlName="btq">
                המלצה על בדיקות דם חדשות
            </mat-checkbox>
            <mat-checkbox class="mb50 checkbox" formControlName="recommend">
                שליחת מייל המלצה
            </mat-checkbox>
        </div> -->
    </div>
</ng-template>

<ng-template #futureQuizTemplate let-form="form">
    <div class="basic-form mat-body mb75" [formGroup]="form">
        <ng-container *ngIf="form.value.futureQuiz.length">
            <!-- <div class="text-danger font-bold ul-text m-b-10">
                {{ form.value.futureQuiz.length }} 
                טפסים עתידיים
            </div> -->
            <div class="last-items-container m-b-10 m-r-10">
                <div class="m-b-5" *ngFor="let future of form.value.futureQuiz; let i = index;">
                    <!-- <span class="pointer font-12 font-bold" (click)="removeFutureQuiz(form.value.futureQuiz, i)"> X </span> -->
                    <span *ngIf="future.heq || future.prq || future.slq || future.nuq || future.fdq || future.ntq">
                        תאריך לפתיחת שאלונים
                    </span>
                    <span *ngIf="future.btq">
                        תאריך לפתיחת שאלון בדיקות דם
                    </span>
                    <span class="font-bold"> ({{future.due_date}}) </span>
                    <span>
                        <button mat-icon-button (click)="removeFutureQuiz(form.value.futureQuiz, i)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </span>
                </div>
            </div>
        </ng-container>
    </div>
</ng-template>

<ng-template #cloneFdqTemplate let-form="form">
    <div class="basic-form mat-body mb75" [formGroup]="form">

        <div class="mb75">
            <!-- <div>
                יש דוח המשוייך ליומן האכילה האחרון, על מנת לשמור יש לפתוח יומן אכילה חדש.
            </div> -->
            <div>
                <span>יש שאלון שלא משוייך ליומן האכילה הנוכחי</span>
                <span *ngIf="form.value.diffNames" class="font-bold"> ({{ form.value.diffNames }})</span>
                <span>, על מנת לשמור יש לפתוח יומן אכילה חדש.</span>
            </div>
            <div>
                אנא בחר האם גם להעתיק את ההערות מהיומן הנוכחי או רק לפתוח יומן חדש?
            </div>
        </div>

        <div class="">
            <mat-checkbox class="mb50 checkbox" formControlName="clone">
                שכפול הערות
            </mat-checkbox>
        </div>

    </div>
</ng-template>

<notestable [onlyDialog]="true" (noteSubmitted)="notestableNoteSubmitted($event)"></notestable>
