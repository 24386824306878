import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { HttpClient } from '@angular/common/http';

export interface DialogData {
  imgSrc: string,
  downloadSrc?: string,
  hideExternal?: boolean,
  hideDownload?: boolean,
  isPdf?: boolean
}

@Component({
selector: 'app-image-dialog',
template: `
<div class="image-popup">
  <img *ngIf="!data.isPdf" src="{{data.imgSrc}}" />
  <pdf-viewer *ngIf="data.isPdf" [src]="data.imgSrc" [render-text]="false" [original-size]="false"></pdf-viewer>
  <div class="action-buttons">
    <button mat-mini-fab aria-label="Close" (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
    <button *ngIf="!data.hideDownload" mat-mini-fab aria-label="Download" (click)="downloadFile(data.downloadSrc ? data.downloadSrc : data.imgSrc)">
      <mat-icon>get_app</mat-icon>
    </button>
    <a *ngIf="!data.hideExternal" target="_blank" href="{{data.downloadSrc ? data.downloadSrc : data.imgSrc}}" mat-mini-fab aria-label="Go to">
      <mat-icon>launch</mat-icon>
    </a>
  </div>
</div>
`,
styles: [`
.image-popup {
  position: relative;
  min-height: 200px;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-popup .action-buttons {
  position: absolute;
  padding: 10px 5px;
  left: 0;
  top: 0;
  direction: ltr;
}
.image-popup .action-buttons button, .image-popup .action-buttons a {
  margin: 0 5px
}
.image-popup img, pdf-viewer {
  display: block;
  max-width: 90vw;
  max-height: 90vh;
}
`]
})

export class ImageDialogComponent {
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData, private mdDialogRef: MatDialogRef<ImageDialogComponent>, private http: HttpClient) { }
  public close() {
    this.mdDialogRef.close();
  }

  downloadFile(route: string, filename: string = '') {
      const baseUrl = '';
      this.http.get(baseUrl + route,{responseType: 'blob' as 'json'}).subscribe(
          (response: any) =>{
              let dataType = response.type;
              let binaryData = [];
              binaryData.push(response);
              let downloadLink = document.createElement('a');
              let objUrl = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
              downloadLink.href = objUrl;
              downloadLink.setAttribute('download', filename);
              document.body.appendChild(downloadLink);
              // downloadLink.click();

              // this is necessary as link.click() does not work on the latest firefox
              downloadLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

              setTimeout(function () {
                  // For Firefox it is necessary to delay revoking the ObjectURL
                  window.URL.revokeObjectURL(objUrl);
                  downloadLink.remove();
              }, 100);
          }
      )
  }
}
