
<div *ngIf="!userChildren">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>

<div class="user-progress" *ngIf="userChildren">
    <div *ngIf="userChildren.length > 0">
        <mat-card *ngFor="let child of userChildren">
            <h3>{{ child.usr_fname }}</h3>
            <ng-container *ngIf="child.usr_quizzes.length">
                <div style="display: flex; justify-content: center; flex-wrap: wrap;">
                    <ng-container *ngFor="let quiz of child.usr_quizzes">
                        <div *ngIf="quiz._id" style="margin: 10px;">
                            <button type="button" mat-stroked-button color="accent" [routerLink]="['/', quiz.route, quiz._id]">
                                {{ quiz.title }}
                            </button>
                        </div>
                    </ng-container>
                </div>

                <mat-divider></mat-divider>
            </ng-container>

            <ng-container *ngIf="child.usr_reports.length">
                <div *ngFor="let report of child.usr_reports" class="text-center m-b-20 m-t-20">
                    <button type="button" mat-raised-button color="primary" (click)="viewReport(report._id)">
                        צפייה בדוח - {{ report.createdAt | date:'dd/MM/yyyy' }}
                    </button>
                </div>
            </ng-container>

            <div *ngIf="!child.usr_reports.length" class="text-center font-16 font-italic m-t-20">
                אין דוחות במערכת
            </div>

        </mat-card>
    </div>

    <div *ngIf="!userChildren.length" class="text-center font-18 font-italic">
        אין ילדים המשויכים אליך במערכת
    </div>
</div>

<ng-template #popupTemplate let-context="context" let-form="form">
    <div class="basic-form mat-body" [formGroup]="form">
        <div class="d-flex mb125" style="justify-content: flex-end;">
            <div class="font-12 dir-ltr">
                {{ form.value.sent_at | dateFormat : 'DD/MM HH:mm' }}
            </div>
        </div>

        <div class="p-20 text-center mb125">
            <!-- <span class="font-bold font-16"> {{ form.value.body }} </span> -->
            <span class="font-18" [innerHTML]="form.value.body"></span>
        </div>
    </div>
</ng-template>
