import { Component, Renderer2, NgZone } from '@angular/core';
import { appState } from '@services/app-state';
import { AppStateService } from '@services/app-state.service';
import { Apputils } from '@services/app-utils';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';

import { RestApiService, FeathersServiceConfig } from '@noammazuz/vzn-feathers';

import { TranslateService, TranslationChangeEvent } from '@ngx-translate/core';

import * as moment from 'moment-timezone';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'all-around-player-client';
  appState = appState;
  appUtils = Apputils;

  constructor(
    public appStateService: AppStateService,
    public router: Router,
    public vznConfig: FeathersServiceConfig,
    private restApiService: RestApiService<any>,
    private translate: TranslateService,
    private renderer: Renderer2,
    private zone: NgZone
  ) {

    moment.tz.setDefault('Asia/Jerusalem');
    
    (<any>window).appState = appState;

    vznConfig.apiUrl = environment.apiUrl;
    vznConfig.storage = environment.storage;

    translate.onLangChange.subscribe((event: TranslationChangeEvent) => {
      this.changeLayotDirection(event.lang);
    });

    console.log("app.component going to initiate initFlutterBroadcast");
    Apputils.initFlutterBroadcast();
    Apputils.registerFlutterCallback("AppComponentsJSReceived", this.handleResult.bind(this));
  }

  changeLayotDirection(lang: string) {
    if (lang == 'he' || lang == 'he_F') {
      this.renderer.removeClass(document.body, 'en');
      this.renderer.addClass(document.body, 'he');
      this.renderer.setAttribute(document.body, 'dir', 'rtl')
    }
    else {
      this.renderer.removeClass(document.body, 'he');
      this.renderer.addClass(document.body, 'en');
      this.renderer.setAttribute(document.body, 'dir', 'ltr')
    }
  }

  async handleResult(resultJS: Apputils.ResultJS) {
    console.log("app.components: got a result from Flutter");
    Apputils.printResultJS(resultJS);

    if (resultJS.error == Apputils.FlutterResultCode.ERROR) {
      console.error("error in return code from server.");
      return;
    }
    if (resultJS.action == 'getToken') {
      try {
        console.log("app.components: before restApiService call");
        let res = await this.restApiService.service('users').patch(this.appState.user._id, {usr_notification_token: resultJS.result})
        console.log("app.components: after restApiService call");
      } catch (e) {
        console.log("app.components: error: ");
        console.error(e);
      }
    }
    if (resultJS.action == 'routeTo') {
      console.log('notification route to: ', resultJS.result);
      this.zone.run(() => {
        this.router.navigate([resultJS.result]);
      });
    }
    if (resultJS.action == 'showBarcodeScanner') {
      console.log('barcode result: ', resultJS.result);
      // this.appState.lastBarcode = resultJS.result;
      const result = resultJS.result != '-1' ? `${resultJS.result}` : '';
      // this.appStateService.setBarcode(result);
      this.zone.run(() => {
        this.appStateService.setBarcode(result);
      });
    }
  }    
  
  ngAfterViewInit() {
    this.changeLayotDirection(this.translate.currentLang);
    let loader = this.renderer.selectRootElement('#staticloader');
    loader.style.display = "none";
  }
}
