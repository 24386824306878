<div class="notestable-container" *ngIf="!onlyDialog">
    <div class="row m-0">
        <h1>ניהול הערות</h1>
        <div class="">
            <mat-button-toggle-group [(ngModel)]="quizPrefix" (ngModelChange)="quizChange($event)">
                <mat-button-toggle value="ntq">אנתרופומטריים</mat-button-toggle>
                <mat-button-toggle value="prq">אימונים</mat-button-toggle>
                <mat-button-toggle value="slq">שינה</mat-button-toggle>
                <mat-button-toggle value="heq">בריאות</mat-button-toggle>
                <mat-button-toggle value="nuq">תזונה</mat-button-toggle>
                <mat-button-toggle value="btq">בדיקת דם</mat-button-toggle>
                <mat-button-toggle value="fdq">יומן אכילה</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
        <button type="button" class="plus-button" mat-raised-button (click)="openNoteDialog()">
            הוסף הערה
            <mat-icon>add</mat-icon>
        </button>
    </div>
    <!-- <div class="centered-row m-b-20">
        <mat-button-toggle-group [(ngModel)]="quizPrefix" (ngModelChange)="quizChange($event)">
            <mat-button-toggle value="ntq">אנתרופומטריים</mat-button-toggle>
            <mat-button-toggle value="prq">אימונים</mat-button-toggle>
            <mat-button-toggle value="slq">שינה</mat-button-toggle>
            <mat-button-toggle value="heq">בריאות</mat-button-toggle>
            <mat-button-toggle value="nuq">תזונה</mat-button-toggle>
            <mat-button-toggle value="btq">בדיקת דם</mat-button-toggle>
            <mat-button-toggle value="fdq">יומן אכילה</mat-button-toggle>
        </mat-button-toggle-group>
    </div> -->
    <div *ngIf="query != undefined">
        <vzn-data-table #tableEl [restService]="notesRestService" [hidePaginator]="true" [pageSize]="9999" [columns]="columns" [query]="query">
        </vzn-data-table>
    </div>
</div>

<ng-template #noteTypeTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    {{quizNoteTypeOptions[row.quiz_prefix][value]}}
</ng-template> 

<ng-template #contentHoverTmpl let-value="value" ngx-datatable-cell-template>
    <div [matTooltip]="value" matTooltipClass="font-14" aria-label="Content info" class="pointer">
        {{ value | slice : 0 : 30 }}...
    </div>
</ng-template> 

<ng-template #checkTmpl let-value="value" ngx-datatable-cell-template>
    <mat-icon class="userstable-status-icon valid" *ngIf="value">check</mat-icon>
</ng-template> 

<ng-template #daysTmpl let-value="value" ngx-datatable-cell-template>
    <ng-container *ngIf="value && value.length > 0">
        <span *ngFor="let day of value; let last = last;">
            {{weekDays[day]}}
            <ng-container *ngIf="!last">, </ng-container>
        </span>
    </ng-container>
</ng-template> 

<ng-template #freqTmpl let-value="value" ngx-datatable-cell-template>
    {{noteFreq[value]}}
</ng-template> 

<ng-template #isVisibleTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <ng-container *ngIf="row.created_by === appState.user._id">
        <mat-checkbox [disabled]="busy" [checked]="row.is_visible" (change)="ownerChange($event, row)"></mat-checkbox>
    </ng-container>
    <ng-container *ngIf="appState.user.usr_type === 'nutri' && row.created_by !== appState.user._id">
        <mat-checkbox [disabled]="busy" [checked]="!(appState.user['usr_nutri_hidden_notes'] && appState.user['usr_nutri_hidden_notes'].includes(row._id))" (change)="systemChange($event, row)"></mat-checkbox>
    </ng-container>
</ng-template> 

<ng-template #isEditTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <ng-container *ngIf="row.created_by === appState.user._id || appState.user.usr_type === 'superadmin'">
        <a mat-icon-button (click)="editNote(row)">
            <mat-icon>edit</mat-icon>
        </a>
    </ng-container>
</ng-template> 

<ng-template #isRemoveTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
    <ng-container *ngIf="row.created_by === appState.user._id || appState.user.usr_type === 'superadmin'">
        <a mat-icon-button (click)="removeNote(row)">
            <mat-icon>remove_circle_outline</mat-icon>
        </a>
    </ng-container>
</ng-template> 

<ng-template #noteDialogTemplate let-form="form">
    <div class="basic-form mat-body" [formGroup]="form">

        <mat-checkbox class="mb50" formControlName="is_visible">
            הצג הערה
        </mat-checkbox>
        <mat-checkbox *ngIf="appState.user.usr_type === 'superadmin'" class="mb50" formControlName="is_visible_all">
            הוסף הערה למאגר ההערות
        </mat-checkbox>

        <mat-checkbox *ngIf="appState.user.usr_type === 'superadmin'" class="mb50" formControlName="is_scenario">
            תרחיש
        </mat-checkbox>

        <mat-form-field>
            <input formControlName="title" matInput placeholder="כותרת (לשימוש פנימי)">
        </mat-form-field>

        <mat-checkbox class="m-b-5" formControlName="is_follow">
            מעקב
        </mat-checkbox>
        <div class="font-12 font-italic mb50">
            * כאשר מסומן ההערה מכניסה את היוזר למעקב בטבלת המשתמשים
        </div>

        <mat-form-field>
            <textarea formControlName="content" matInput cdkTextareaAutosize cdkAutosizeMinRows="3" placeholder="תוכן ההערה - זכר"></textarea>
        </mat-form-field>
        <mat-form-field>
            <textarea formControlName="content_F" matInput cdkTextareaAutosize cdkAutosizeMinRows="3" placeholder="תוכן ההערה - נקבה"></textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-select formControlName="quiz_prefix" placeholder="שאלון">
                <mat-option value="ntq">אנתרופומטריים</mat-option>
                <mat-option value="prq">אימונים</mat-option>
                <mat-option value="slq">שינה</mat-option>
                <mat-option value="heq">בריאות</mat-option>
                <mat-option value="nuq">תזונה</mat-option>
                <mat-option value="btq">בדיקת דם</mat-option>
                <mat-option value="fdq">יומן אכילה</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select formControlName="quiz_note_type" placeholder="סוג הערה">
                <mat-option *ngFor="let noteType of quizNoteTypeOptions[form.value?.quiz_prefix] | keyvalue" [value]="noteType.key">{{noteType.value}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="form.value && subPrefixOptions[form.value.quiz_prefix] && subPrefixOptions[form.value.quiz_prefix][form.value.quiz_note_type]">
            <mat-select formControlName="sub_prefix" placeholder="תת-סוג הערה">
                <mat-option *ngFor="let prefix of subPrefixOptions[form.value.quiz_prefix][form.value.quiz_note_type] | keyvalue" [value]="prefix.key">{{prefix.value}}</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="d-flex mb50">
            <mat-checkbox class="mb50 m-r-5 m-l-5" formControlName="is_push">
                התראה פעילה
            </mat-checkbox>
            <mat-checkbox class="mb50 m-r-5 m-l-5" formControlName="is_wap">
                וואטסאפ
            </mat-checkbox>
        </div>

        <ng-container *ngIf="form.value && (form.value.is_push || form.value.is_wap)">
            <mat-radio-group formControlName="notification_timing" class="mb50">
                <mat-radio-button value="freq">חוזר</mat-radio-button>
                <mat-radio-button value="publish">בזמן פרסום הדוח</mat-radio-button>
                <mat-radio-button value="date">לפי תאריך</mat-radio-button>
            </mat-radio-group>
    
            <div *ngIf="form.value.notification_timing === 'date'" class="centered-row between">
                <mat-form-field class="w49">
                    <input readonly #timingDate formControlName="notification_timing_date" matInput [matDatepicker]="dpInput" [min]="minDate" placeholder="תאריך לפרסום ההערה">
                    <mat-datepicker-toggle matSuffix [for]="dpInput"></mat-datepicker-toggle>
                    <mat-datepicker #dpInput disabled="false"></mat-datepicker>
                </mat-form-field>
                <mat-form-field class="w49">
                    <mat-select formControlName="notification_timing_hour" placeholder="שעה">
                        <mat-option value="">---</mat-option>
                        <mat-option *ngFor="let time of allHours" [value]="time">{{time}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <mat-radio-group *ngIf="form.value.notification_timing === 'publish' || form.value.notification_timing === 'date'" formControlName="notification_to_type" class="mb50">
                <mat-radio-button value="player">התראה ליוזר</mat-radio-button>
                <mat-radio-button value="parent">התראה להורים</mat-radio-button>
                <mat-radio-button value="coach">התראה למאמן</mat-radio-button>
            </mat-radio-group>
        </ng-container>
        
        <!-- <mat-form-field>
            <input formControlName="push_content" matInput placeholder="תוכן ההתראה">
        </mat-form-field> -->
        <mat-form-field>
            <textarea #pushContent formControlName="push_content" matInput cdkTextareaAutosize cdkAutosizeMinRows="3" [placeholder]="form.value && form.value.notification_to_type === 'parent' ? 'תוכן ההתראה - אבא - בן' : (form.value && form.value.notification_to_type === 'coach' ? 'תוכן ההתראה - מאמן' :'תוכן ההתראה - זכר')"></textarea>
            <button type="button" matSuffix mat-icon-button [matMenuTriggerFor]="menuContent">
                <mat-icon>more_vert</mat-icon>
            </button>
        </mat-form-field>
        <mat-form-field>
            <textarea #pushContentF formControlName="push_content_F" matInput cdkTextareaAutosize cdkAutosizeMinRows="3" [placeholder]="form.value && form.value.notification_to_type === 'parent' ? 'תוכן ההתראה - אמא - בן' : (form.value && form.value.notification_to_type === 'coach' ? 'תוכן ההתראה - מאמנת' : 'תוכן ההתראה - נקבה')"></textarea>
            <button type="button" matSuffix mat-icon-button [matMenuTriggerFor]="menuContentF">
                <mat-icon>more_vert</mat-icon>
            </button>
        </mat-form-field>
        <ng-container *ngIf="form.value && form.value.notification_to_type === 'parent'">
            <mat-form-field>
                <textarea #pushContentFemaleChild formControlName="push_content_female_child" matInput cdkTextareaAutosize cdkAutosizeMinRows="3" [placeholder]="'תוכן ההתראה - אבא - בת'"></textarea>
                <button type="button" matSuffix mat-icon-button [matMenuTriggerFor]="menuContentFemaleChild">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field>
                <textarea #pushContentFemaleChildF formControlName="push_content_female_child_F" matInput cdkTextareaAutosize cdkAutosizeMinRows="3" [placeholder]="'תוכן ההתראה - אמא - בת'"></textarea>
                <button type="button" matSuffix mat-icon-button [matMenuTriggerFor]="menuContentFemaleChildF">
                    <mat-icon>more_vert</mat-icon>
                </button>
            </mat-form-field>
        </ng-container>

        <mat-menu #menuContent="matMenu">
            <button *ngIf="form.value.notification_to_type === 'coach'" mat-menu-item (click)="addText(pushContent, form.controls.push_content, 'שם_מלא_שחקן')">
                <span>שם מלא שחקן</span>
            </button>
            <button mat-menu-item (click)="addText(pushContent, form.controls.push_content, 'שם_פרטי')">
                <span>שם פרטי</span>
            </button>
            <!-- <button mat-menu-item (click)="addText(pushContent, form.controls.push_content, 'שם_משפחה')">
                <span>שם משפחה</span>
            </button> -->
            <button *ngIf="form.value.notification_to_type === 'parent'" mat-menu-item (click)="addText(pushContent, form.controls.push_content, 'שם_פרטי_ילד')">
                <span>שם פרטי ילד</span>
            </button>
            <!-- <button *ngIf="form.value.notification_to_type === 'parent'" mat-menu-item (click)="addText(pushContent, form.controls.push_content, 'שם_משפחה_ילד')">
                <span>שם משפחה ילד</span>
            </button> -->
            <button mat-menu-item (click)="addText(pushContent, form.controls.push_content, 'שם_מועדון')">
                <span>שם מועדון</span>
            </button>
        </mat-menu>

        <mat-menu #menuContentF="matMenu">
            <button *ngIf="form.value.notification_to_type === 'coach'" mat-menu-item (click)="addText(pushContentF, form.controls.push_content_F, 'שם_מלא_שחקן')">
                <span>שם מלא שחקן</span>
            </button>
            <button mat-menu-item (click)="addText(pushContentF, form.controls.push_content_F, 'שם_פרטי')">
                <span>שם פרטי</span>
            </button>
            <!-- <button mat-menu-item (click)="addText(pushContentF, form.controls.push_content_F, 'שם_משפחה')">
                <span>שם משפחה</span>
            </button> -->
            <button *ngIf="form.value.notification_to_type === 'parent'" mat-menu-item (click)="addText(pushContentF, form.controls.push_content_F, 'שם_פרטי_ילד')">
                <span>שם פרטי ילד</span>
            </button>
            <!-- <button *ngIf="form.value.notification_to_type === 'parent'" mat-menu-item (click)="addText(pushContentF, form.controls.push_content_F, 'שם_משפחה_ילד')">
                <span>שם משפחה ילד</span>
            </button> -->
            <button mat-menu-item (click)="addText(pushContentF, form.controls.push_content_F, 'שם_מועדון')">
                <span>שם מועדון</span>
            </button>
        </mat-menu>

        <mat-menu #menuContentFemaleChild="matMenu">
            <button mat-menu-item (click)="addText(pushContentFemaleChild, form.controls.push_content_female_child, 'שם_פרטי')">
                <span>שם פרטי</span>
            </button>
            <button *ngIf="form.value.notification_to_type === 'parent'" mat-menu-item (click)="addText(pushContentFemaleChild, form.controls.push_content_female_child, 'שם_פרטי_ילד')">
                <span>שם פרטי ילד</span>
            </button>
            <button mat-menu-item (click)="addText(pushContentFemaleChild, form.controls.push_content_female_child, 'שם_מועדון')">
                <span>שם מועדון</span>
            </button>
        </mat-menu>

        <mat-menu #menuContentFemaleChildF="matMenu">
            <button mat-menu-item (click)="addText(pushContentFemaleChildF, form.controls.push_content_female_child_F, 'שם_פרטי')">
                <span>שם פרטי</span>
            </button>
            <button *ngIf="form.value.notification_to_type === 'parent'" mat-menu-item (click)="addText(pushContentFemaleChildF, form.controls.push_content_female_child_F, 'שם_פרטי_ילד')">
                <span>שם פרטי ילד</span>
            </button>
            <button mat-menu-item (click)="addText(pushContentFemaleChildF, form.controls.push_content_female_child_F, 'שם_מועדון')">
                <span>שם מועדון</span>
            </button>
        </mat-menu>

        <mat-form-field>
            <mat-select formControlName="hour" placeholder="שעה">
                <mat-option value="">---</mat-option>
                <mat-option *ngFor="let time of allHours" [value]="time">{{time}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select formControlName="days" placeholder="ימים" multiple>
                <mat-option value="0">א'</mat-option>
                <mat-option value="1">ב'</mat-option>
                <mat-option value="2">ג'</mat-option>
                <mat-option value="3">ד'</mat-option>
                <mat-option value="4">ה'</mat-option>
                <mat-option value="5">ו'</mat-option>
                <mat-option value="6">ש'</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select formControlName="freq" placeholder="תדירות">
                <mat-option value="">---</mat-option>
                <mat-option value="1">שבועית</mat-option>
                <mat-option value="2">2 שבועות</mat-option>
                <mat-option value="3">3 שבועות</mat-option>
                <mat-option value="4">4 שבועות</mat-option>
                <mat-option value="13">3 חודשים (13 שבועות)</mat-option>
                <mat-option value="26">חצי-שנה (26 שבועות)</mat-option>
                <mat-option value="52">שנה (52 שבועות)</mat-option>
            </mat-select>
        </mat-form-field>

        <div class="mb125" *ngIf="form.value && (form.value.is_push || form.value.is_wap)">
            <mat-checkbox class="mb50" formControlName="is_link_homepage">
                הוסף קישור לדף הבית (כולל דף מאמן)
            </mat-checkbox>
            <mat-checkbox class="mb50" formControlName="is_link_last_rpt">
                הוסף קישור לדוח אחרון
            </mat-checkbox>
            <!-- <mat-checkbox class="mb50" formControlName="is_link_first_btq">
                הוסף קישור לשאלון בדיקות דם ראשוני
            </mat-checkbox> -->
            <mat-checkbox class="mb50" formControlName="is_link_last_btq">
                הוסף קישור לשאלון בדיקות דם אחרון
            </mat-checkbox>
            <mat-checkbox class="mb50" formControlName="is_link_profile">
                הוסף קישור לפרופיל
            </mat-checkbox>
        </div>

        <ng-container *ngIf="form.value && (form.value.push_content || form.value.push_content_F)">
            <h5 class="m-b-10">בחר אפשרות להצגת הפופאפ עם התראה בזמן הכניסה הבאה של המשתמש לאפליקציה</h5>
    
            <mat-radio-group formControlName="popup_option" class="d-column mb125">
                <mat-radio-button value="none" class="m-b-5">ללא פופאפ</mat-radio-button>
                <mat-radio-button value="unlimited" class="m-b-5">הצגה ללא הגבלת זמן</mat-radio-button>
                <mat-radio-button value="limited" class="m-b-5">
                    <div class="d-flex align-items-center">
                        <span class="nowrap-text">
                            הצג פופאפ עד
                        </span>
                        <mat-form-field class="in-row-field">
                            <input formControlName="popup_limited_amount" type="text" digitOnly maxlength="3" inputmode="numeric" pattern="[0-9]*" matInput>
                        </mat-form-field>
                        <mat-form-field class="in-row-field">
                            <mat-select formControlName="popup_limited_unit">
                                <mat-option value="minutes">דקות</mat-option>
                                <mat-option value="hours">שעות</mat-option>
                                <mat-option value="days">ימים</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span class="nowrap-text">
                            לאחר מועד ההתראה
                        </span>
                    </div>
                </mat-radio-button>
            </mat-radio-group>
        </ng-container>

        <mat-checkbox class="mb50" formControlName="is_response">
            הוסף אישור קבלה / ביצוע
        </mat-checkbox>

        <mat-form-field>
            <input formControlName="note_video_title" matInput placeholder="כותרת סרטון">
        </mat-form-field>
        <mat-form-field>
            <input formControlName="note_video_url" matInput placeholder="כתובת קישור לסרטון">
        </mat-form-field>


        <!-- <mat-form-field>
            <input formControlName="response_button_yes" matInput maxlength="18" placeholder="טקסט כפתור אישור זכר/אבא">
        </mat-form-field>
        <mat-form-field>
            <input formControlName="response_button_yes_F" matInput maxlength="18" placeholder="טקסט ביטול/אי-ביצוע זכר/אבא">
        </mat-form-field>
        <mat-form-field>
            <input formControlName="response_button_no" matInput maxlength="18" placeholder="טקסט כפתור אישור נקבה/אמא">
        </mat-form-field>
        <mat-form-field>
            <input formControlName="response_button_no_F" matInput maxlength="18" placeholder="טקסט ביטול/אי-ביצוע נקבה/אמא">
        </mat-form-field>
        <mat-form-field>
            <input formControlName="response_wap" matInput placeholder="טקסט לסיום הודעת הוואטסאפ זכר/אבא">
        </mat-form-field>
        <mat-form-field>
            <input formControlName="response_wap_F" matInput placeholder="טקסט לסיום הודעת הוואטסאפ נקבה/אמא">
        </mat-form-field> -->
        
        <!-- <mat-form-field>
            <input formControlName="response_button_yes" matInput maxlength="18" [placeholder]="form.value && form.value.notification_to_type === 'parent' ? 'טקסט כפתור אישור אבא' : 'טקסט כפתור אישור זכר'">
        </mat-form-field>
        <mat-form-field>
            <input formControlName="response_button_no" matInput maxlength="18" [placeholder]="form.value && form.value.notification_to_type === 'parent' ? 'טקסט ביטול/אי-ביצוע אבא' : 'טקסט ביטול/אי-ביצוע זכר'">
        </mat-form-field>
        <mat-form-field>
            <input formControlName="response_button_yes_F" matInput maxlength="18" [placeholder]="form.value && form.value.notification_to_type === 'parent' ? 'טקסט כפתור אישור אמא' : 'טקסט כפתור אישור נקבה'">
        </mat-form-field>
        <mat-form-field>
            <input formControlName="response_button_no_F" matInput maxlength="18" [placeholder]="form.value && form.value.notification_to_type === 'parent' ? 'טקסט ביטול/אי-ביצוע אמא' : 'טקסט ביטול/אי-ביצוע נקבה'">
        </mat-form-field>
        <mat-form-field>
            <input formControlName="response_wap" matInput [placeholder]="form.value && form.value.notification_to_type === 'parent' ? 'טקסט לסיום הודעת הוואטסאפ אבא' : 'טקסט לסיום הודעת הוואטסאפ זכר'">
        </mat-form-field>
        <mat-form-field>
            <input formControlName="response_wap_F" matInput [placeholder]="form.value && form.value.notification_to_type === 'parent' ? 'טקסט לסיום הודעת הוואטסאפ אמא' : 'טקסט לסיום הודעת הוואטסאפ נקבה'">
        </mat-form-field> -->

        <mat-checkbox *ngIf="form.value && form.value.is_response" class="mb50" formControlName="is_interval">
            <div class="d-flex">
                <span class="nowrap-text">
                    במידה ולא התקבל אישור להקפיץ שוב כל 
                </span>
                <mat-form-field class="in-row-field">
                    <input formControlName="response_interval" type="text" digitOnly maxlength="3" inputmode="numeric" pattern="[0-9]*" matInput>
                </mat-form-field>
                <span class="nowrap-text">
                    שעות מקסימום 
                </span>
                <mat-form-field class="in-row-field">
                    <input formControlName="response_max_times" type="text" digitOnly maxlength="3" inputmode="numeric" pattern="[0-9]*" matInput>
                </mat-form-field>
                <span class="nowrap-text">
                    פעמים עד לפעם הבאה
                </span>
            </div>
        </mat-checkbox>

        <mat-checkbox class="mb50" formControlName="is_linked">
            קישור הערות נוספות
        </mat-checkbox>

        <ng-container *ngIf="form.value.is_linked">
            <mat-form-field>
                <mat-select formControlName="linked_quiz_prefix" placeholder="שאלון">
                    <mat-option value="ntq">אנתרופומטריים</mat-option>
                    <mat-option value="prq">אימונים</mat-option>
                    <mat-option value="slq">שינה</mat-option>
                    <mat-option value="heq">בריאות</mat-option>
                    <mat-option value="nuq">תזונה</mat-option>
                    <mat-option value="btq">בדיקת דם</mat-option>
                    <mat-option value="fdq">יומן אכילה</mat-option>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field>
                <mat-select formControlName="linked_quiz_note_type" placeholder="סוג הערה">
                    <mat-option *ngFor="let noteType of quizNoteTypeOptions[form.value?.linked_quiz_prefix] | keyvalue" [value]="noteType.key">{{noteType.value}}</mat-option>
                </mat-select>
            </mat-form-field>
    
            <mat-form-field *ngIf="form.value && subPrefixOptions[form.value.linked_quiz_prefix] && subPrefixOptions[form.value.linked_quiz_prefix][form.value.linked_quiz_note_type]">
                <mat-select formControlName="linked_sub_prefix" placeholder="תת-סוג הערה">
                    <mat-option *ngFor="let prefix of subPrefixOptions[form.value.linked_quiz_prefix][form.value.linked_quiz_note_type] | keyvalue" [value]="prefix.key">{{prefix.value}}</mat-option>
                </mat-select>
            </mat-form-field>

            <notes [isLinkedMode]="true" [noteForm]="form" [allNotes]="allNotesUnfiltered"></notes>
            
        </ng-container>
        
    </div>
</ng-template>
