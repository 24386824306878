<div class="antro-form">

    <mat-expansion-panel #exp disabled expanded (opened)="initSticky()" (closed)="disconnectSticky()">
        <mat-expansion-panel-header>
            <!-- <mat-panel-title class="font-18 font-bold">
                יומן אכילה ולוז
            </mat-panel-title>
            <mat-panel-description>

            </mat-panel-description> -->
            <mat-panel-title class="font-16 font-bold">
                <h4 class="m-auto">יומן אכילה</h4>
            </mat-panel-title>
        </mat-expansion-panel-header>
        
        <!-- <ng-container *ngIf="exp.expanded"> -->
        
        <div class="row mb125">
            <button class="plus-button" mat-raised-button (click)="newQuizGuard()">
                {{'general.newquiz' | translate}}
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <div class="row mb125">
            <mat-form-field *ngIf="oldValues.length > 0" class="w30">
                <a (click)="$event.stopPropagation()" target="quizwindow" *ngIf="selectedA" matSuffix [routerLink]="['/Fooddiaryquiz/', userId]" class="top-button" mat-stroked-button>עבור לטופס</a>
                <mat-select [disabled]="true" [(ngModel)]="selectedIdA" (ngModelChange)="selectDateA($event)">
                    <ng-container *ngFor="let val of oldValues; let i = index">
                        <!-- <mat-option *ngIf="selectedIdB != val._id" [value]="val._id"> -->
                        <mat-option [value]="val._id">
                            <div class="row m-0" [ngStyle]="{'border-bottom': '3px solid', 'border-color': val.color}">
                                {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                                <!-- {{ val['is_by_user'] ? ' (משתמש) ' : '' }} -->
                                <div style="width: 25px; height: 25px; border-radius: 50%;" [style.backgroundColor]="val.color"></div>
                            </div>
                        </mat-option>
                    </ng-container>
                </mat-select>
                <!-- <mat-select disabled [value]="notesFormA.value._id">
                    <mat-option *ngFor="let val of oldValues" [value]="val._id">
                        {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                    </mat-option>
                </mat-select> -->
            </mat-form-field>
            <div class="centered-row m-0 w30" style="margin-left: auto;" *ngIf="selectedA">
                <mat-form-field class="w49">
                    <input #formDate matInput disabled [matDatepicker]="dpStarted" placeholder="תאריך" [(ngModel)]="chosenNewDay">
                    <mat-datepicker-toggle matSuffix [for]="dpStarted"></mat-datepicker-toggle>
                    <mat-datepicker #dpStarted disabled="false"></mat-datepicker>
                </mat-form-field>
                <button class="plus-button m-r-10 font-12" mat-raised-button (click)="addDay()" [disabled]="!chosenNewDay">
                    הוספת יום
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <mat-form-field *ngIf="oldValues.length > 0" class="w30">
                <!-- <a (click)="$event.stopPropagation()" target="quizwindow" *ngIf="selectedB" matSuffix [routerLink]="['/Fooddiaryquiz/', selectedIdB]" class="top-button" mat-stroked-button>עבור לטופס</a> -->
                <mat-select [(ngModel)]="selectedIdB" (ngModelChange)="selectDateB($event)">
                    <ng-container *ngFor="let val of oldValues; let i = index">
                        <mat-option *ngIf="selectedIdA != val._id" [value]="val._id">
                        <!-- <mat-option [value]="val._id"> -->
                            {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                            <!-- {{ val['is_by_user'] ? ' (משתמש) ' : '' }} -->
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>

        <div *ngIf="isLoading" class="mt125">
            <div class="spinner no-margin">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
        </div>

        <div *ngIf="userId && selectedA && notesFormA" class="mb125">
            <mat-slide-toggle [checked]="notesFormA.value.fdq_shown" (change)="changedFdqShown($event, notesFormA)">הצג בסטטוס יוזר</mat-slide-toggle>
        </div>

        <div #indicator id="top-indicator"></div>

        <div *ngIf="userId && selectedA && notesFormA" class="basic-form" [ngClass]="{'sticky-headers': isTabGroup && !isIndicator}">
            <!-- <food-diary-quiz [nutritionistFormMode]="true" [formId]="selectedIdA" [notesForm]="notesFormA"></food-diary-quiz> -->
            <div *ngIf="quizDaysArr === undefined" class="mt125">
                <div class="spinner no-margin">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                </div>
            </div>
            <!-- <div *ngIf="quizDaysArr && quizDaysArr.length === 0" class="text-center font-bold">
                {{'food_diary.NO_DAYS' | translate}}
            </div> -->
            <mat-tab-group #tabGroup *ngIf="quizDaysArr && quizDaysDataArr && quizDaysDataArr.length > 0" [(selectedIndex)]="selectedTab" [animationDuration]="100" [dynamicHeight]="false" (selectedIndexChange)="hideAndReloadMeals($event)" (animationDone)="scrollToMeal()">
                <mat-tab *ngFor="let quizDayData of quizDaysDataArr; let i = index" [disabled]="busy">
                    <ng-template mat-tab-label>
                        <span *ngIf="quizDayData.id && quizDayData.date" [matTooltip]="quizDayData.reportDate ? (quizDayData.reportDate | date:'dd/MM/yyyy') : 'ללא דוח' " matTooltipClass="font-14" aria-label="Report date">
                            <span class="row">
                                יום {{ weekDays[(quizDayData.date | dateFormat : 'd')] }}
                                <button class="m-r-5" mat-icon-button matSuffix (click)="deleteDay(i, quizDayData.id, $event)">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </span>
                            <div class="font-bold" [ngStyle]="{'padding': '3px 7px 0', 'border-top': '3px solid', 'border-color': quizDayData.color}">
                                {{ quizDayData.date | dateFormat : 'DD/MM/yyyy' }}
                            </div>
                            <div *ngIf="quizDayData.isDifferent===true">
                                יום שגרתי
                            </div>
                            <div *ngIf="quizDayData.isDifferent===false">
                                יום לא שגרתי
                            </div>
                            <!-- <div *ngIf="!quizDayData.isDifferent">
                                יום לא שגרתי
                            </div> -->
                        </span>
                        <div *ngIf="quizDayData.id && !quizDayData.date">ללא תאריך</div>
                        <div *ngIf="!quizDayData.id && !quizDayData.isNuqIngredients && !quizDayData.isNuqDetails">
                            <!-- <div>( {{ selectedA.createdAt | date:'dd/MM/yyyy' }} )</div> -->
                            <div [ngStyle]="{ 'padding': '3px 7px 0', 'border-top': '3px solid', 'border-color': quizDayData.color }">
                                ( {{ quizDayData.date | dateFormat : 'DD/MM/yyyy' }} )
                            </div>
                            <div>יומן אכילה ללא ימים</div>
                        </div>
                        <div *ngIf="!quizDayData.id && quizDayData.isNuqIngredients">
                            <div [ngStyle]="{ 'padding': '3px 7px 0', 'border-top': '3px solid', 'border-color': quizDayData.color }">
                                <!-- ( {{ quizDayData.date | dateFormat : 'DD/MM/yyyy' }} ) -->
                            </div>
                            <div>מרכיבי ארוחות</div>
                        </div>
                        <div *ngIf="!quizDayData.id && quizDayData.isNuqDetails">
                            <div [ngStyle]="{ 'padding': '3px 7px 0', 'border-top': '3px solid', 'border-color': quizDayData.color }">
                                <!-- ( {{ quizDayData.date | dateFormat : 'DD/MM/yyyy' }} ) -->
                            </div>
                            <div>ארוחות מייצגות</div>
                        </div>
                    </ng-template>
                    <div [class]="'sliding-div ' + slideTo" [ngClass]="{ 'visible': !hideTabs }">
                        <ng-container *ngIf="(!quizDayData.id && !quizDayData.dayData) || (quizDayData.id && selectedQuizId && selectedQuizId === quizDayData.quizId)">
                            <fooddiaryquiz-day [nutcategories]="nutcategories" [nuqIngredientsData]="quizDayData.nuqIngredientsData" [nuqData]="quizDayData.nuqData" [isNuqIngredients]="quizDayData.isNuqIngredients" [isNuqDetails]="quizDayData.isNuqDetails" [nutritionistFormMode]="true" [dayData]="quizDayData.dayData" [userId]="userId" [userAge]="userAge" [userGender]="user.usr_gender" [userWeight]="userWeight" [notesForm]="notesFormA" [notesFormB]="(selectedB && selectedIdA !== selectedIdB) ? notesFormB : null" [allNotes]="allNotes" [notesList]="notesList" [isLocked]="true" [currentMeal]="scrollMeal" [oldNotesFlag]="oldNotesFlag" (mealChange)="mealChange($event)" (addNoteEvent)="addNote($event)" (editNoteEvent)="editNote($event)" (removeNoteEvent)="removeNote($event)" (cloneNotesEvent)="cloneNotes($event)" (linkedNotesEvent)="linkedNote($event)"></fooddiaryquiz-day>
                        </ng-container>
                    </div>
                    <!-- <div *ngIf="!quizDayData.id" class="mt125 text-center font-bold">
                        {{'food_diary.NO_DAYS' | translate}}
                    </div> -->
                </mat-tab>
            </mat-tab-group>
            
            <div class="mt125 text-center" *ngIf="darkestUrineIndex !== undefined && darkestUrineIndex !== null && darkestUrineIndex > -1">
                <h3>צבע השתן הכהה ביותר בשאלון זה</h3>
                <div class="status-row" style="max-width: 700px; margin: 0 auto;">
                    <div class="basis10">
                        <mat-icon class="status-icon valid" *ngIf="(darkestUrineIndex > -1 && 3 > darkestUrineIndex)">check</mat-icon>
                        <mat-icon class="status-icon invalid" *ngIf="darkestUrineIndex > 2">priority_high</mat-icon>
                    </div>
                    <div class="basis90">
                        <mat-grid-list [cols]="urineHexColorPlate.length" rowHeight="3em">
                            <mat-grid-tile *ngFor="let urineColor of urineHexColorPlate; let i = index;"
                            [colspan]="1"
                            [rowspan]="1"
                            [style.padding]="'4px'"
                            [style.border]="urineHexColorPlate[darkestUrineIndex] == urineColor ? '2.5px solid red' : '2px solid blue'"
                            [style.background]="urineColor">
                            </mat-grid-tile>
                        </mat-grid-list>
                    </div>
                </div>
            </div>

        </div>

        <div class="mt125"></div>

        <div class="row" *ngIf="selectedA">
            <div class="w40 buttons-row">
                <button *ngIf="selectedIdA" class="plus-button" mat-raised-button (click)="deleteQuiz(selectedIdA)">
                    {{'general.deleteQuiz' | translate}}
                    <mat-icon>delete</mat-icon>
                </button>
                <a target="quizwindow" [routerLink]="['/Fooddiaryquiz/', userId]" class="plus-button" mat-raised-button>עבור לטופס</a>
                <mat-slide-toggle [checked]="notesFormA.value.fdq_locked" (change)="toggleQuizLock($event, notesFormA)">{{'general.lockQuiz' | translate}}</mat-slide-toggle>
            </div>
            <!-- <div class="w40 text-center">
                <button mat-raised-button color="primary" class="w70" (click)="submitForm()">
                    שמור הערות
                </button>
            </div> -->
        </div>

        <!-- </ng-container> -->
        
    </mat-expansion-panel>

</div>
