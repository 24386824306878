import { Component, ViewChildren, QueryList, Inject, ViewChild, ElementRef, TemplateRef, Pipe, PipeTransform } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { appState } from '@services/app-state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Apputils } from '@services/app-utils';
import { TranslateService } from '@ngx-translate/core';

import { RestApiService } from '@noammazuz/vzn-feathers';

import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
// import { TeamReportComponent } from './team-report.component';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';

export interface User {
  name: string;
}

@Pipe({
  name: 'clubFilter'
})
export class ClubFilterPipe implements PipeTransform {
  transform(sourceArray: any, clubId: string, key: string): any {
    if (!sourceArray || !clubId || !key) return [];
    return sourceArray.filter(val => val[key] === clubId);
  }
}
@Pipe({
  name: 'userFilter'
})
export class UserFilterPipe implements PipeTransform {
  transform(sourceArray: any, idsArr: any): any {
    if (!sourceArray || !idsArr) return [];
    return idsArr.map((id) => sourceArray.find((element) => element._id === id)).filter(e => e);
  }
}

@Component({
  templateUrl: "./club.component.html"
})
export class ClubComponent {
  // @ViewChild("teamReportEl") teamReportEl: TeamReportComponent;

  appUtils = Apputils;
  allHours = Apputils.allHours;
  appState = appState;

  usersForm: FormGroup;
  teamsForm: FormGroup;
  clubsForm: FormGroup;

  validationMessages = {
    required: 'שדה זה הוא שדה חובה',
    email: 'בבקשה הזן כתובת מייל תקנית',
  }

  formContext: any;

  teamPracticeRestService: any;
  teamReportRestService: any;
  usersRestService: any;
  teamsRestService: any;
  clubsRestService: any;

  durations = Apputils.getPracticeDurations();

  busy = false;

  // chosenClub = '';

  // currentEditedForm: number;
  currentEditedForm = null;
  currentEditedFormValue: any;

  clubs: any[] = [];
  teams: any[] = [];

  admins: any[] = [];
  nutris: any[] = [];
  coaches: any[] = [];
  players: any[] = [];

  constructor(
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    // private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public restApiService: RestApiService<any>,
    private translate: TranslateService
  ) {
    this.usersRestService = this.restApiService.service('users');
    this.teamsRestService = this.restApiService.service('team');
    this.clubsRestService = this.restApiService.service('club');
    this.teamPracticeRestService = this.restApiService.service('team-practice');
    this.teamReportRestService = this.restApiService.service('team-report');
    this.currentEditedFormValue = {};
    this.currentEditedForm = null;
    this.formContext = this;

    this.usersForm = this.formBuilder.group({
      admins_arr: this.formBuilder.array([]),
      nutris_arr: this.formBuilder.array([]),
      coaches_arr: this.formBuilder.array([]),
      players_arr: this.formBuilder.array([])
    });

    this.teamsForm = this.formBuilder.group({
      teams_arr: this.formBuilder.array([])
    });

    this.clubsForm = this.formBuilder.group({
      clubs_arr: this.formBuilder.array([])
    });
  }

  async ngAfterViewInit() {
    // this.busy = true;
    this.setBusy(this, true);
    await this.refreshTeams();
    if (this.appState.user.usr_type === 'superadmin') {
      await this.refreshClubs();
    }
    // this.busy = false;
    this.setBusy(this, false);
  }

  setBusy(context: any, value) {
    // this.busy = e;
    // this.changeDetectorRef.detectChanges();
    setTimeout(() => {context.busy = value;});
  }

  displayFn(user?: any): string | undefined {
    return user ? (user.usr_fname + " " + user.usr_lname) : undefined;
  }

  displayFnTeam(team?: any): string | undefined {
    return team ? (team.tem_name) : undefined;
  }

  private _filter(usersArray: any, name: string, clubId: string): any {
    if (!name) {
      return usersArray.filter(user => clubId && (user.usr_club_id === clubId || (user.usr_club_ids && user.usr_club_ids.includes(clubId))));
    }
    const filterValue = name.trim().toLowerCase();
    return usersArray.filter(user => clubId && (user.usr_club_id === clubId || (user.usr_club_ids && user.usr_club_ids.includes(clubId))) && String(user.usr_fname + " " + user.usr_lname).toLowerCase().indexOf(filterValue) != -1);
  }

  // private _filterTeam(teamsArray: any, name: string): any {
  //   const filterValue = name.trim().toLowerCase();
  //   return teamsArray.filter(team => String(team.tem_name).toLowerCase().indexOf(filterValue) != -1);
  // }

  async refreshClubs() {
    await this.refreshClubsForm();
    await this.refreshAdminsDataAndForm();
  }

  async refreshClubsForm() {
    try {
      let clubsRes = await this.clubsRestService.find({ 
        query: {
          $paginate: false,
          clb_is_deleted: {
            $ne: true
          },
          $sort: {
            clb_name: 1
          }
        },
      });
      this.patchFormArray(this.clubsForm.get("clubs_arr") as FormArray, clubsRes, this.createClubItem);
      // this.clearClubsAutoCompleteFields();

      this.clubs = clubsRes;
    } catch (e) {
      console.error(e);
      this.clubs = [];
    }
  }

  async refreshTeams() {
    // if (this.appState.user.usr_type === 'admin') {
    //   this.chosenClub = this.appState.user['usr_club_id'] || '';
    // }
    await this.refreshTeamsForm();
    await this.refreshNutrisDataAndForm();
    await this.refreshCoachesDataAndForm();
    await this.refreshPlayersDataAndForm();
  }

  async refreshTeamsForm() {
    try {
      let teamsRes = await this.teamsRestService.find({ 
        query: {
          // $limit: 5,
          $paginate: false,
          tem_is_deleted: {
            $ne: true
          },
          $sort: {
            tem_name: 1
          }
        },
      });

      if (this.appState.user.usr_type === 'coach') {
        const usr_team_ids = (this.appState.user as any).usr_team_ids || [];
        teamsRes = teamsRes.filter((team: any) => usr_team_ids.includes(team._id))
      }

      this.patchFormArray(this.teamsForm.get("teams_arr") as FormArray, teamsRes, this.createTeamItem);
      this.clearTeamsAutoCompleteFields();

      this.teams = teamsRes;
    } catch (e) {
      console.error(e);
      this.teams = [];
    }
  }

  clearTeamsAutoCompleteFields() {
    let formArray = this.teamsForm.get("teams_arr") as FormArray;
    for (let i = 0; i < formArray.controls.length; i++) {
      let teamForm: FormGroup = formArray.at(i) as FormGroup;
      teamForm.get("extra_field_input_nutris").setValue("");
      teamForm.get("extra_field_input_coaches").setValue("");
      teamForm.get("extra_field_input_players").setValue("");
    }
  }

  createTeamItem(context: any) {
    let teamForm: FormGroup = context.formBuilder.group({
      _id: [''],
      // tem_is_deleted: [false],
      tem_name: ['', Validators.required],
      tem_club_id: [''],

      tem_nutris_ids_arr: [],
      tem_coaches_ids_arr: [],
      tem_players_ids_arr: [],
      // tem_nutris_ids_arr: context.formBuilder.array([]),
      // tem_coaches_ids_arr: context.formBuilder.array([]),
      // tem_players_ids_arr: context.formBuilder.array([]),

      extra_field_input_nutris: [''],
      extra_field_nutris_options: [''],
      extra_field_input_coaches: [''],
      extra_field_coaches_options: [''],
      extra_field_input_players: [''],
      extra_field_players_options: [''],
    });

    teamForm.get("extra_field_nutris_options").setValue(teamForm.get("extra_field_input_nutris").valueChanges
      .pipe(
        startWith(''),
        map((value: User) => typeof value === 'string' ? value : value.name),
        map((name: string) => context._filter(context.nutris, name, teamForm.value.tem_club_id))
      ));    

    teamForm.get("extra_field_coaches_options").setValue(teamForm.get("extra_field_input_coaches").valueChanges
      .pipe(
        startWith(''),
        map((value: User) => typeof value === 'string' ? value : value.name),
        map((name: string) => context._filter(context.coaches, name, teamForm.value.tem_club_id))
      ));    

    teamForm.get("extra_field_players_options").setValue(teamForm.get("extra_field_input_players").valueChanges
      .pipe(
        startWith(''),
        map((value: User) => typeof value === 'string' ? value : value.name),
        map((name: string) => context._filter(context.players, name, teamForm.value.tem_club_id))
      ));
    return teamForm;
  }

  async addTeamItem(context: any, arrFieldName: string, chosenClub: string) {
    context.setBusy(context, true);;
    try {
      if (chosenClub) {
        let formArr = context.teamsForm.get(arrFieldName) as FormArray;
        let item: FormGroup = context.createTeamItem(context);
        item.get('tem_club_id').setValue(chosenClub);
        
        delete item.value._id;
        delete item.value.tem_nutris_ids_arr;
        delete item.value.tem_coaches_ids_arr;
        delete item.value.tem_players_ids_arr;

        const res = await context.teamsRestService.create(item.value);
        console.log('Team created: ', res._id);
        item.patchValue(res);
        
        formArr.push(item);
        
        await context.refreshTeamsForm();
      }
    } catch (e) {
      console.error(e);
    }
    context.setBusy(context, false);
  }

  async removeTeamItem(context: any, arrFieldName: string, index: number) {
    let formArr = context.teamsForm.get(arrFieldName) as FormArray;
    let item: FormGroup = formArr.at(index) as FormGroup;

    const dialogRef = context.dialog.open(CustomDialogComponent, {
      // width: '50%',
      data: { 
        title: context.translate.instant("club.TEAM_DELETE_DIALOG_TITLE"),
        subtitle: context.translate.instant("club.TEAM_DELETE_DIALOG_EXPLAIN") + " " + item.value.tem_name + "?",
        ok: context.translate.instant('general.yes'),
        cancel: context.translate.instant('general.no'),
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }

      context.setBusy(context, true);;
      try {
        const res = await context.teamsRestService.patch(item.value._id, { tem_is_deleted: true });
        if (res.tem_is_deleted) {
          console.log('Team deleted: ', res._id);
          formArr.removeAt(index);
          await context.refreshTeamsForm();
        }
      } catch (e) {
        console.error(e);
      }      
      context.setBusy(context, false);
    });
  }

  async saveTeamItem(context: any, arrFieldName: string, index: number) {
    context.setBusy(context, true);;
    try {
      let formArr = context.teamsForm.get(arrFieldName) as FormArray;
      let item: FormGroup = formArr.at(index) as FormGroup;

      delete item.value.extra_field_input_nutris;
      delete item.value.extra_field_nutris_options;
      delete item.value.extra_field_input_coaches;
      delete item.value.extra_field_coaches_options;
      delete item.value.extra_field_input_players;
      delete item.value.extra_field_players_options;
      
      const res = await context.teamsRestService.patch(item.value._id, item.value);
      context.showStatus(context.translate.instant('club.CLUB_SAVED'), null);
      item.patchValue(res);
    } catch (e) {
      console.error(e);
    }
    context.setBusy(context, false);
  }  

  async addTeamUser(context: any, arrFieldName: string, index: number, innerArrayFieldName: string, inputValue: any, msgIfNotSelectedTranslateKey: string, msgIfExistsTranslateKey: string) {
    let formArr = context.teamsForm.get(arrFieldName) as FormArray;
    let item: FormGroup = formArr.at(index) as FormGroup;

    // check if it's a valid user object
    if (typeof inputValue !== 'object' || (typeof inputValue === 'object' && !inputValue._id) ) {
      context.showStatus(context.translate.instant(msgIfNotSelectedTranslateKey), null);
      return;
    } 
    
    // we can use the object's _id property
    context.setBusy(context, true);;
    try {
      // retrieve team form again,
      const teamRes = await context.teamsRestService.get(item.value._id);
      if (teamRes[innerArrayFieldName] && teamRes[innerArrayFieldName].indexOf(inputValue._id) !== -1) {
        context.showStatus(context.translate.instant(msgIfExistsTranslateKey), null);
        context.setBusy(context, false);
        return;
      }
      item.patchValue(teamRes);

      let currentUsersIdsArr: string[] = item.get(innerArrayFieldName).value;
      currentUsersIdsArr.push(inputValue._id);
      item.get(innerArrayFieldName).setValue(currentUsersIdsArr);

      context.saveTeamItem(context, arrFieldName, index);
    } catch (e) {
      console.error(e);
    }
    context.setBusy(context, false);
  }

  async removeTeamUser(context: any, arrFieldName: string, index: number, innerArrayFieldName: string, idToRemove) {
    let formArr = context.teamsForm.get(arrFieldName) as FormArray;
    let item: FormGroup = formArr.at(index) as FormGroup;
    
    context.setBusy(context, true);;
    try {
      let teamRes = await context.teamsRestService.get(item.value._id);
      teamRes[innerArrayFieldName] = teamRes[innerArrayFieldName].filter((elem) => elem != idToRemove);

      item.patchValue(teamRes);

      context.saveTeamItem(context, arrFieldName, index);
    } catch (e) {
      console.error(e);
    }
    context.setBusy(context, false);
  }  

  async refreshAdminsDataAndForm() {
    this.admins = await this.getUsersOfType(Apputils.UserTypes.Admin);
    this.patchFormArray(this.usersForm.get("admins_arr") as FormArray, this.admins, this.createAdminItem);
  }

  async refreshNutrisDataAndForm() {
    this.nutris = await this.getUsersOfType(Apputils.UserTypes.Nutri);
    this.patchFormArray(this.usersForm.get("nutris_arr") as FormArray, this.nutris, this.createNutriItem);
  }

  async refreshCoachesDataAndForm() {
    this.coaches = await this.getUsersOfType(Apputils.UserTypes.Coach);
    this.patchFormArray(this.usersForm.get("coaches_arr") as FormArray, this.coaches, this.createCoachItem);
  }

  async refreshPlayersDataAndForm() {
    this.players = await this.getUsersOfType(Apputils.UserTypes.Player);
    this.patchFormArray(this.usersForm.get("players_arr") as FormArray, this.players, this.createPlayerItem);    
  }

  async getUsersOfType(userType: Apputils.UserTypes) {
    return await this.usersRestService.find({ 
      query: {
        $paginate: false,
        usr_type: userType,
        usr_is_deleted: {
          $ne: true
        },
        $sort: {
          usr_fname: 1,
          usr_lname: 1
        }
      },
    });
  }

  createUserItem(context: any, userType: Apputils.UserTypes): FormGroup {
    let profileForm = context.formBuilder.group({
      _id: [''],
      // usr_phone: [{value: '', disabled: true}],
      usr_phone: [''],
      usr_fname: [''],
      usr_lname: [''],
      usr_email: ['', [Validators.email]],
      usr_type: userType,
      usr_club_id: [''],
      usr_club_ids: [],
      // usr_is_deleted: [false],
      isEditMode: [false],
    });
    
    return profileForm;
  }

  patchFormArray(formArray: FormArray, data: any[], createFunction: Function) {
    const deltaLength: number = formArray.length - data.length;
    if (deltaLength > 0) {
      for (let i = 0; i < Math.abs(deltaLength); i++) {
        formArray.removeAt(0);
      }
    } else {
      for (let innerIndex = 0; innerIndex < Math.abs(deltaLength); innerIndex++) {
        let newFormElement = createFunction(this);
        formArray.push(newFormElement);
      }
    }
    formArray.patchValue(data);
  }

  // async addUserItem(context: any, arrFieldName: string, userRole: Apputils.UserTypes) {
  //   let formArr = this.usersForm.get(arrFieldName) as FormArray;
  //   let item: FormGroup = this.createUserItem(userRole, true);
  //   this.busy = true;
  //   try {
      
  //     delete item.value._id;
  //     delete item.value.isEditMode;
  
  //     const res = await this.usersRestService.create(item.value);
  //     console.log('User created: ', res._id);
  //     item.patchValue(res);
  //     this.currentEditedFormValue = item.value;
  //     formArr.push(item);
  // //     await this.refreshTeams();
  //       switch (arrFieldName) {
  //         case 'admins_arr':
  //           await this.refreshAdminsDataAndForm();
  //           break;
  //         case 'nutris_arr':
  //           await this.refreshNutrisDataAndForm();
  //           break;
  //         case 'coaches_arr':
  //           await this.refreshCoachesDataAndForm();
  //           break;
  //         case 'players_arr':
  //           await this.refreshPlayersDataAndForm();
  //           break;
  //         default:
  //           break;
  //       }

  //   } catch (e) {
  //     console.error(e);
  //   }
  //   this.busy = false;

  //   // the reason we get the length again, is that the length might have changed
  //   // after the refresh operation
  //   formArr = this.usersForm.get(arrFieldName) as FormArray;
  //   this.startEdit(item, formArr.length - 1); 
  // }

  async removeUserItem(context: any, teamIndex: number, userFormGroup: FormGroup) {
    const { _id, usr_type = '', usr_fname = '', usr_lname = '' } = userFormGroup.value || {};

    let innerArrayName = '';
    switch (usr_type) {
      case 'nutri':
        innerArrayName = 'tem_nutris_ids_arr';
        break;
      case 'coach':
        innerArrayName = 'tem_coaches_ids_arr';
        break;
      case 'player':
        innerArrayName = 'tem_players_ids_arr';
        break;
    
      default:
        return;
        // break;
    }
    const uppercaseType = usr_type.toUpperCase();
    const dialogTranslateTitleString = "club." + uppercaseType + "_REMOVE_DIALOG_TITLE";
    const dialogTranslateSubtitleString = "club." + uppercaseType + "_REMOVE_DIALOG_EXPLAIN";

    const dialogRef = context.dialog.open(CustomDialogComponent, {
      // width: '50%',
      data: { 
        title: context.translate.instant(dialogTranslateTitleString),
        subtitle: context.translate.instant(dialogTranslateSubtitleString) + ": " + usr_fname + " " + usr_lname + "?",
        ok: context.translate.instant('general.yes'),
        cancel: context.translate.instant('general.no'),
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }
      await context.removeTeamUser(context, 'teams_arr', teamIndex , innerArrayName, _id);
    });
  }
  
  async deleteUserItem(context: any, arrFieldName: string, index: number) {
    let formArr = context.usersForm.get(arrFieldName) as FormArray;
    let item: FormGroup = formArr.at(index) as FormGroup;

    const uppercaseType = item.value.usr_type.toUpperCase();
    const dialogTranslateTitleString = "club." + uppercaseType + "_DELETE_DIALOG_TITLE";
    const dialogTranslateSubtitleString = "club." + uppercaseType + "_DELETE_DIALOG_EXPLAIN";

    const dialogRef = context.dialog.open(CustomDialogComponent, {
      // width: '50%',
      data: { 
        title: context.translate.instant(dialogTranslateTitleString),
        subtitle: context.translate.instant(dialogTranslateSubtitleString) + ": " + item.value.usr_fname + " " + item.value.usr_lname + "?",
        ok: context.translate.instant('general.yes'),
        cancel: context.translate.instant('general.no'),
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }
      context.setBusy(context, true);;
      try {
        const res = await context.usersRestService.patch(item.value._id, { usr_is_deleted: true });
        if (res.usr_is_deleted) {
          console.log('User deleted: ', res._id);
          formArr.removeAt(index);
          switch (arrFieldName) {
            case 'admins_arr':
              await context.refreshAdminsDataAndForm();
              break;
            case 'nutris_arr':
              await context.refreshNutrisDataAndForm();
              break;
            case 'coaches_arr':
              await context.refreshCoachesDataAndForm();
              break;
            case 'players_arr':
              await context.refreshPlayersDataAndForm();
              break;
            default:
              break;
          }
        }
      } catch (e) {
        console.error(e);
      }
      context.setBusy(context, false);
    });
  }

  editUserItem(context: any, arrFieldName: string, index: number) {
    let formArr = context.usersForm.get(arrFieldName) as FormArray;
    let item: FormGroup = formArr.at(index) as FormGroup;    
    context.currentEditedFormValue = item.value;
    context.startEdit(context, item);  
  }  

  async saveUserItem(context: any, arrFieldName: string, index: number) {
    context.setBusy(context, true);;
    try {
      let formArr = context.usersForm.get(arrFieldName) as FormArray;
      let item: FormGroup = formArr.at(index) as FormGroup;
      delete item.value.usr_phone;
      delete item.value.isEditMode;
      const res = await context.usersRestService.patch(item.value._id, item.value);
      console.log('User updated: ', res._id);
      context.stopEdit(context, item);
      // await this.refreshTeams();
      context.showStatus(context.translate.instant('club.CLUB_SAVED'), null);
      item.patchValue(res);
    } catch (e) {
      console.error(e);
    }
    context.setBusy(context, false);
  }

  cancelEditUserItem(context: any, arrFieldName: string, index: number) {
    let formArr = context.usersForm.get(arrFieldName) as FormArray;
    let item: FormGroup = formArr.at(index) as FormGroup;
    item.patchValue(context.currentEditedFormValue);
    context.currentEditedFormValue = {};
    context.stopEdit(context, item);
  }

  startEdit(context: any, item: FormGroup) {
    item.get("isEditMode").setValue(true);
    context.currentEditedForm = item.value?._id; 
  }

  stopEdit(context: any, item: FormGroup) {
    item.get("isEditMode").setValue(false);
    context.currentEditedForm = null;
  }

  createPlayerItem(context: any) {
    return context.createUserItem(context, Apputils.UserTypes.Player);
  }
  createCoachItem(context: any) {
    return context.createUserItem(context, Apputils.UserTypes.Coach);
  }
  createNutriItem(context: any) {
    return context.createUserItem(context, Apputils.UserTypes.Nutri);
  }
  createAdminItem(context: any) {
    return context.createUserItem(context, Apputils.UserTypes.Admin);
  }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2500,
    });
  }

  // async addClubAdmin(context, arrFieldName, index, innerArrayFieldName, inputValue, msgIfNotSelectedTranslateKey, msgIfExistsTranslateKey) {
  //   console.log("addClubAdmin");
  //   let formArr = context.clubsForm.get(arrFieldName) as FormArray;
  //   let item: FormGroup = formArr.at(index) as FormGroup;

  //   // check if it's a valid user object
  //   if (typeof inputValue !== 'object' || (typeof inputValue === 'object' && (inputValue._id == undefined || inputValue._id == "")) ) {
  //     console.log("it's not a valid object");
  //     context.showStatus(context.translate.instant(msgIfNotSelectedTranslateKey), null);
  //     // context.showStatus(context.translate.instant("club. _CHOOSE_FROM_AC_LIST"), null);
  //     return;
  //   } 
    
  //   // we can use the object's _id property
  //   context.busy = true;
  //   try {
  //     // retrieve team form again,
  //     let clubRes = await context.clubsRestService.get(item.value._id);
  //     if (clubRes[innerArrayFieldName] && clubRes[innerArrayFieldName].indexOf(inputValue._id) != -1) {
  //       context.showStatus(context.translate.instant(msgIfExistsTranslateKey), null);
  //       // context.showStatus(context.translate.instant("club. _EXIST_IN_TEAM"), null);
  //       context.busy = false;
  //       return;
  //     }
  //     item.patchValue(clubRes);

  //     let currentUsersIdsArr: Array<string> = item.get(innerArrayFieldName).value || [];
  //     currentUsersIdsArr.push(inputValue._id);
  //     item.get(innerArrayFieldName).setValue(currentUsersIdsArr);

  //     // if it doesn't exist, add it and save
  //     context.saveClubItem(context, arrFieldName, index);
  //   } catch (e) {
  //     console.error(e);
  //   }
  //   context.busy = false;
  // }

  // async removeClubAdmin(context, arrFieldName, index, innerArrayFieldName, idToRemove) {
  //   console.log("removeClubAdmin");
  //   let formArr = context.clubsForm.get(arrFieldName) as FormArray;
  //   let item: FormGroup = formArr.at(index) as FormGroup;
    
  //   // we can use the object's _id property
  //   context.busy = true;
  //   try {
  //     // retrieve team form again,
  //     let clubRes = await context.clubsRestService.get(item.value._id);

  //     clubRes[innerArrayFieldName] = clubRes[innerArrayFieldName].filter((elem) => elem != idToRemove);


  //     item.patchValue(clubRes);

  //     // // let currentUsersIdsArr: Array<string> = item.get(innerArrayFieldName).value;
  //     // // currentUsersIdsArr.push(inputValue._id);
  //     // item.get(innerArrayFieldName).setValue(teamRes[innerArrayFieldName]);

  //     // if it doesn't exist, add it and save
  //     context.saveClubItem(context, arrFieldName, index);
  //   } catch (e) {
  //     console.error(e);
  //   }
  //   context.busy = false;
  // }  

  // clearClubsAutoCompleteFields() {
  //   let formArray = this.clubsForm.get("clubs_arr") as FormArray;
  //   for (let i = 0; i < formArray.controls.length; i++) {
  //     let clubForm: FormGroup = formArray.at(i) as FormGroup;
  //     clubForm.get("extra_field_input_teams").setValue("");
  //     clubForm.get("extra_field_input_admins").setValue("");
  //   }
  // }

  createClubItem(context: any) {
    const clubForm: FormGroup = context.formBuilder.group({
      _id: [''],
      // clb_is_deleted: [false],
      clb_name: ['', Validators.required],
      clb_is_other_nut: [false],
      // clb_measure_enabled: [false]
    });

    return clubForm;
  }

  async addClubItem(context: any, arrFieldName: string) {
    context.setBusy(context, true);;
    try {
      let formArr = context.clubsForm.get(arrFieldName) as FormArray;
      let item: FormGroup = context.createClubItem(context);
      
      delete item.value._id;
      const res = await context.clubsRestService.create(item.value);
      console.log('Club created: ', res._id);
      item.patchValue(res);
  
      formArr.push(item);
      
      await context.refreshClubs();
    } catch (e) {
      console.error(e);
    }
    context.setBusy(context, false);
  }

  async removeClubItem(context: any, arrFieldName: string, index: number) {
    let formArr = context.clubsForm.get(arrFieldName) as FormArray;
    let item: FormGroup = formArr.at(index) as FormGroup;

    const dialogRef = context.dialog.open(CustomDialogComponent, {
      // width: '50%',
      data: { 
        title: context.translate.instant("club.CLUB_DELETE_DIALOG_TITLE"),
        subtitle: context.translate.instant("club.CLUB_DELETE_DIALOG_EXPLAIN") + " " + item.value.clb_name + "?",
        ok: context.translate.instant('general.yes'),
        cancel: context.translate.instant('general.no'),
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }

      context.setBusy(context, true);;
      try {
        const res = await context.clubsRestService.patch(item.value._id, { clb_is_deleted: true });
        if (res.clb_is_deleted) {
          console.log('Club deleted: ', res._id);
          formArr.removeAt(index);
          await context.refreshClubs();
        }
      } catch (e) {
        console.error(e);
      }
      context.setBusy(context, false);
    });
  }

  async saveClubItem(context: any, arrFieldName: string, index: number) {
    context.setBusy(context, true);;
    try {
      let formArr = context.clubsForm.get(arrFieldName) as FormArray;
      let item: FormGroup = formArr.at(index) as FormGroup;
      const res = await context.clubsRestService.patch(item.value._id, item.value);
      context.showStatus(context.translate.instant('club.CLUB_SAVED'), null);
      item.patchValue(res);
    } catch (e) {
      console.error(e);
    }
    context.setBusy(context, false);
  }  

  // async addClubTeam(context, arrFieldName, index, innerArrayFieldName, inputValue, msgIfNotSelectedTranslateKey, msgIfExistsTranslateKey) {
  //   console.log("addClubTeam");
  //   let formArr = context.clubsForm.get(arrFieldName) as FormArray;
  //   let item: FormGroup = formArr.at(index) as FormGroup;

  //   // check if it's a valid user object
  //   if (typeof inputValue !== 'object' || (typeof inputValue === 'object' && (inputValue._id == undefined || inputValue._id == "")) ) {
  //     console.log("it's not a valid object");
  //     context.showStatus(context.translate.instant(msgIfNotSelectedTranslateKey), null);
  //     return;
  //   } 
    
  //   // we can use the object's _id property
  //   context.busy = true;
  //   try {
  //     let clubRes = await context.clubsRestService.get(item.value._id);
  //     if (clubRes[innerArrayFieldName] && clubRes[innerArrayFieldName].indexOf(inputValue._id) != -1) {
  //       context.showStatus(context.translate.instant(msgIfExistsTranslateKey), null);
  //       context.busy = false;
  //       return;
  //     }
  //     item.patchValue(clubRes);

  //     let currentTeamsIdsArr: Array<string> = item.get(innerArrayFieldName).value || [];
  //     currentTeamsIdsArr.push(inputValue._id);
  //     item.get(innerArrayFieldName).setValue(currentTeamsIdsArr);

  //     context.saveClubItem(context, arrFieldName, index);
  //   } catch (e) {
  //     console.error(e);
  //   }
  //   context.busy = false;
  // }

  // async removeClubTeam(context, arrFieldName, index, innerArrayFieldName, idToRemove) {
  //   console.log("removeClubTeam");
  //   let formArr = context.clubsForm.get(arrFieldName) as FormArray;
  //   let item: FormGroup = formArr.at(index) as FormGroup;
    
  //   // we can use the object's _id property
  //   context.busy = true;
  //   try {
  //     let clubRes = await context.clubsRestService.get(item.value._id);

  //     clubRes[innerArrayFieldName] = clubRes[innerArrayFieldName].filter((elem) => elem != idToRemove);
  //     item.patchValue(clubRes);

  //     context.saveClubItem(context, arrFieldName, index);
  //   } catch (e) {
  //     console.error(e);
  //   }
  //   context.busy = false;
  // }  

  // getSubsetArrayFromIds(sourceArray: Array<any>, idsArr: Array<any>) {
  //   if (!sourceArray || !idsArr) return [];
  //   return idsArr.map((id) => sourceArray.find((element) => element._id === id)).filter(e => e);
  // }
  
  // getSubsetClubArray(sourceArray: Array<any>, id: string, key: string) {
  //   if (!sourceArray || !id || !key) return [];
  //   return sourceArray.filter(val => val[key] === id);
  // }
}

