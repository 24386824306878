import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { appState } from '@services/app-state';

import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { MatAccordion } from '@angular/material/expansion';
import { MatDialog } from '@angular/material/dialog';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '@ngx-translate/core';

import { Apputils } from '@services/app-utils';
import { Appcalcs } from '@services/app-calcs';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';
import { HttpClient } from '@angular/common/http';

@Component({
  templateUrl: "./userreport.component.html"
})
export class UserreportComponent {
  @ViewChild('accordion') Accordion: MatAccordion;

  appState = appState;

  appCalcs = Appcalcs;
  calcDiff = Appcalcs.calcDiff

  urineHexColorPlate = Apputils.urineHexColorPlate;

  nutName = 'מתן נאור';

  mainData: any;
  userData: any;

  nuttest: any;
  lastNuttest: any;
  practice: any;
  sleeping: any;
  bloodtest: any;
  fooddiary: any;
  nutrition: any;
  health: any;

  darkestUrineIndex: number;
  fluidsRcmd: number;
  // fluidsRange: string;
  rmrObj: any;
  fatValidityObj: any;
  fatValidityStyle: any;

  notesMap = {};

  isBloodtestNotes = false;
  isNutritionNotes = false;

  initialized = false;

  chosenReport = '';
  showFatPer = false;
  userReports = [];

  busy = false;

  constructor(
    public dialog: MatDialog,
    private dialogService: ConfirmDialogService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public restApiService: RestApiService<any>,
    private http: HttpClient  // Inject HttpClient

  ) {

    this.route.params.subscribe(async params => {
      try {
        if (params.id) {
          const data = await this.restApiService.service('userreport').get(params.id);

          this.showFatPer = data.ntq.ntq_show_fat_per
          if (this.appState.user.usr_type === 'coach' || this.appState.user.usr_type === 'parent') {
            this.chosenReport = data._id;
            this.userReports = data.user.user_reports;
          }

          this.loadQuizes(data);

        } else {
          let res = await this.restApiService.service('userreport').getLast({
            rpt_usr_id: this.appState.user._id,
            rpt_is_published: true,
            rpt_is_hidden: {
              $ne: true
            },
          });
          if (res.length > 0) {
            this.goToReport(res[0]._id);
          } else {
            this.router.navigate([`/`]);
          }
        }
      } catch (e) {
        console.error(e);
        this.router.navigate([`/`]);
      }
    });
  }

  closeAllPanels() {
    this.Accordion.closeAll();
  }
  openAllPanels() {
    this.Accordion.openAll();
  }
  ngOnInit() {
    const params: any = this.route.params;
    const thisWebHookCanRun = this.appState.user.usr_type === 'nutri' || this.appState.user.usr_type === 'superadmin';
    if (!thisWebHookCanRun) {
      this.webhookFunc(params.value.id);

    }
  }
  webhookFunc(reportId: string) {

    reportId = reportId.split('?')[0];

    const webhookUrl = 'https://hook.eu1.make.com/exnugh6bvkfspv14hdo5o5rio8xftkdd';
    const reportData = {
      reportId,
      userId: this.appState.user._id,
      // timestamp: new Date().toISOString(),
    };

    this.http.post(webhookUrl, reportData).subscribe({
      next: (response) => {
        console.log('Webhook sent successfully', response);
        // this.router.navigate(['/Userreport/', reportId]);
      },
      error: (error) => {
        console.error('Error sending webhook', error);
      }
    });
  }


  async togglePublish(e: any) {
    this.busy = true;
    const toggleState = Boolean(e.checked);
    const main = this.mainData;
    const permission = this.appState.user.usr_type == 'superadmin' || this.appState.user.usr_type == 'nutri';

    if (!(permission && main && main._id)) {
      e.source.checked = !toggleState;
      return;
    }

    let options: any;
    if (toggleState) {
      const reportData = await this.restApiService.service('userreport').get(main._id);
      //const lockState = this.checkUnlocked(reportData);

      const dialogRef = this.dialog.open(CustomDialogComponent, {
        data: {
          title: 'פרסום דוח',
          subtitle: 'האם לנעול שאלונים, לפרסם דוח ולעדכן את המטופל?',
          cancel: 'לא',
          ok: 'פרסם בלבד',
          extraButton: { value: 'rptPublish', text: 'פרסם ושלח' },
          checkboxLabel: 'דוח בדיקות דם',
          checkboxValue: false,
          onCheckboxChange: this.handleCheckboxChange.bind(this) // Pass the method here
        }
      });

      dialogRef.afterClosed().subscribe(async result => {
        if (!result) {
          e.source.checked = false;
          this.busy = false;
          return;
        }

        let res;

        try {
          if (result.extraButton && result.extraButton.value === 'rptPublish' && result.isExtraButton) {
            // Handle "פרסם ושלח"
            res = await this.restApiService.service('actions').create({
              cmd: 'rpt-publish',
              cmdData: {
                _id: main._id,
                lockAll: true,
                rpt_is_published: true,
                bloodtest_report: result.checkboxValue
              }
            });
          } else if (result.isOkButton) {
            // Handle "פרסם בלבד"
            res = await this.restApiService.service('userreport').patch(main._id, {
              lockAll: true,
              rpt_is_published: true,
              bloodtest_report: result.checkboxValue
            });
          }

          e.source.checked = Boolean(res && res._id && res.rpt_is_published);
          this.busy = false;
        } catch (e) {
          console.error(e);
          e.source.checked = false;
          this.busy = false;
        }
      });

      return;
    } else {
      options = {
        title: 'ביטול פרסום דוח',
        message: 'האם אתה בטוח שאתה רוצה לבטל את פרסום הדוח?',
        cancelText: this.translate.instant("general.no"),
        confirmText: this.translate.instant("general.yes")
      };
    }

    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(async confirmed => {
      if (confirmed) {
        try {
          const res = await this.restApiService.service('userreport').patch(main._id, {
            rpt_is_published: toggleState
          });
          e.source.checked = Boolean(res && res._id && res.rpt_is_published);
          this.busy = false;
        } catch (e) {
          console.error(e);
          e.source.checked = !toggleState;
          this.busy = false;
        }
      } else {
        e.source.checked = !toggleState;
        this.busy = false;
      }
    });
  }

  handleCheckboxChange(checkboxValue: boolean): void {
    // Handle the checkbox value change here
    console.log('Checkbox changed to:', checkboxValue);
  }


  async lockAll(main: any) {
    const quizzes = [
      { service: 'healthquiz', comp: 'health', prefix: 'heq' },
      { service: 'practicequiz', comp: 'practice', prefix: 'prq' },
      { service: 'sleepingquiz', comp: 'sleeping', prefix: 'slq' },
      // { service: 'nutritionquiz', comp: 'nutrition', prefix: 'nuq' },
      { service: 'nutquiz', comp: 'nutrition', prefix: 'nuq' },
      { service: 'bloodtestquiz', comp: 'bloodtest', prefix: 'btq' },
      { service: 'nuttestquiz', comp: 'nuttest', prefix: 'ntq' },
      { service: 'fooddiaryquiz', comp: 'fooddiary', prefix: 'fdq' },
    ];
    let count = 0;
    for (let quiz of quizzes) {
      const lockedField = quiz.prefix + '_locked';
      if (main[quiz.prefix] && main[quiz.prefix]._id && !main[quiz.prefix][lockedField]) {
        const res = await this.restApiService.service(quiz.service).patch(main[quiz.prefix]._id, {
          [lockedField]: true
        });
        if (res && res[lockedField] === true) {
          count++;
        }
      };
    }
    return count;
  }

  // checkUnlocked(main: any) {
  //   const quizzes = [
  //     { comp: 'health', prefix: 'heq' },
  //     { comp: 'practice', prefix: 'prq' },
  //     { comp: 'sleeping', prefix: 'slq' },
  //     { comp: 'nutrition', prefix: 'nuq' },
  //     { comp: 'bloodtest', prefix: 'btq' },
  //     { comp: 'nuttest', prefix: 'ntq' },
  //     { comp: 'fooddiary', prefix: 'fdq' },
  //   ];
  //   for (let quiz of quizzes) {
  //     const lockedField = quiz.prefix + '_locked';
  //     if (main && main[quiz.prefix] && !main[quiz.prefix][lockedField]) { return true };
  //   }
  //   return false;
  // }

  convertBold(notesMap: any) {
    const regex = /\*(.*?)\*/g;
    for (const key in notesMap) {
      if (Object.prototype.hasOwnProperty.call(notesMap, key)) {
        const str: string = notesMap[key] || '';
        notesMap[key] = str.replace(regex, '<b>$1</b>');
      }
    }
    return notesMap;
  }

  loadQuizes(main: any) {
    const user = main.user;

    this.fetchNuttest(main.ntq, user);
    this.fetchLastNuttest(main.last_ntq, user);
    this.fetchPractice(main.prq, this.rmrObj);
    this.fetchSleeping(main.slq, user, main.ntq?.ntq_test_date);
    this.fetchBloodtest(main.btq);
    this.fetchNutrition(main.nuq);
    this.fetchHealth(main.heq);
    this.fetchFooddiary(main.fdq);
    this.darkestUrineIndex = main.darkest_urine_index;

    const timeCreated = new Date(main.createdAt).getTime();
    let notesMap: any;
    if (timeCreated < 1596110080351) {
      notesMap = this.createNotesMap();
      console.log('old notes map created');
    } else {
      notesMap = main.notes_map;
      console.log('new notes map fetched');
    }

    this.notesMap = this.convertBold(notesMap);

    this.isBloodtestNotes = [20, 21, 22, 23, 24, 25, 26, 27, 29].some(el => this.notesMap[el]);
    this.isNutritionNotes = [4, 8, 9, 11, 13, 15, 16, 17, 18, 19, 30, 31, 32, 33, 34, 35, 36, 37].some(el => this.notesMap[el]);

    this.userData = user;
    this.mainData = main;
    this.initialized = true;
  }

  fetchNuttest(data, user) {
    if (!data) { return; }

    this.appCalcs.updatePercents(data, user, true);

    // const rmr = this.appCalcs.calcRMR(data, user.usr_gender_type);
    const rmr = +(data['ntq_rmr']);
    const weight = +(data['ntq_weight']);
    const factor = data['ntq_factor'] ? Math.max(+(data['ntq_factor']), 1) : 1;

    if (data.ntq_show_graph) {
      const fatValidity = this.appCalcs.getFatValidity(data, user.usr_gender_type);
      if (fatValidity && fatValidity.fat) {
        const a = fatValidity.a / fatValidity.d * 100;
        const b = fatValidity.b / fatValidity.d * 100;
        const c = fatValidity.c / fatValidity.d * 100;
        const d = 100;
        const fatStyle = fatValidity.fat / fatValidity.d * 100;
        this.fatValidityObj = fatValidity;
        this.fatValidityStyle = {
          a: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + a),
          b: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + b),
          c: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + c),
          d: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + d),
          fat: this.sanitizer.bypassSecurityTrustStyle('--percentage : ' + fatStyle)
        }
      }
    }

    this.calcFluids(weight);

    this.rmrObj = { weight, rmr, factor };
    this.nuttest = data;
  }

  fetchLastNuttest(data: any, user: any) {
    if (!data) { return; }
    this.appCalcs.updatePercents(data, user);
    this.lastNuttest = data;
  }

  fetchPractice(data: any, kcal: any) {
    if (!data) { return; }
    if (data.prq_show_calories) {
      // data.prq_team_practice_arr = teamArr;
      this.appCalcs.calcKcalDayAvg(data, kcal);
      data.prq_total_daily_kcal = (data.prq_daily_kcal || 0) + (data.prq_daily_no_practice || 0);
      data.prq_total_kcal = data.notes_prq_c ? data.prq_total_daily_kcal + +(data.notes_prq_c) : '';
    }
    this.practice = data;
  }

  fetchSleeping(data: any, user: any, testDate: any) {
    if (!data) { return; }
    this.appCalcs.updateSleepingCalcs(data, user, testDate);
    this.sleeping = data;
  }

  fetchBloodtest(data: any) {
    if (!data) { return; }
    this.bloodtest = data;
  }

  fetchNutrition(data: any) {
    if (!data) { return; }
    this.nutrition = data;
  }

  fetchHealth(data: any) {
    if (!data) { return; }
    this.health = data;
  }

  fetchFooddiary(data: any) {
    if (!data) { return; }
    this.fooddiary = data;
  }

  calcFluids(weight: number) {
    if (!weight) { return; }

    const roundTo25 = (x: number) => Math.round(x / 25) * 25;

    this.fluidsRcmd = roundTo25(weight * 3) / 100;
    // this.fluidsRange = (roundTo25(weight*1)/100) + '-' + (roundTo25(weight*1.5)/100);
  }

  createNotesMap() {
    const nuttest = this.nuttest;
    const practice = this.practice;
    const sleeping = this.sleeping;
    const bloodtest = this.bloodtest;
    const fooddiary = this.fooddiary;
    const nutrition = this.nutrition;
    const health = this.health;
    return {
      2: nuttest && nuttest['notes_nut_body'],
      3: practice && practice['notes_prq_a'],
      4: practice && practice['notes_prq_b'],
      5: practice && practice['notes_prq_c'],
      6: sleeping && sleeping['notes_nut_sleeping'],
      7: health && health['notes_heq_a'],
      8: health && health['notes_heq_b'],
      9: health && health['notes_heq_c'],
      10: health && health['notes_heq_d'],
      11: health && health['notes_heq_e'],
      12: health && health['notes_heq_f'],
      13: health && health['notes_heq_g'],
      14: health && health['notes_heq_h'],
      15: health && health['notes_heq_i'],
      16: nutrition && nutrition['notes_nuq_a'],
      17: nutrition && nutrition['notes_nuq_b'],
      18: nutrition && nutrition['notes_nuq_c'],
      19: nutrition && nutrition['notes_nuq_d'],
      20: bloodtest && bloodtest['notes_btq_a'],
      21: bloodtest && bloodtest['notes_btq_b'],
      22: bloodtest && bloodtest['notes_btq_c'],
      23: bloodtest && bloodtest['notes_btq_d'],
      24: bloodtest && bloodtest['notes_btq_e'],
      25: bloodtest && bloodtest['notes_btq_f'],
      26: bloodtest && bloodtest['notes_btq_g'],
      27: bloodtest && bloodtest['notes_btq_h'],
      28: bloodtest && bloodtest['notes_btq_i'],
      29: bloodtest && bloodtest['notes_btq_j'],
      30: fooddiary && fooddiary['notes_fdq_a'],
      31: fooddiary && fooddiary['notes_fdq_b'],
      32: fooddiary && fooddiary['notes_fdq_c'],
      33: fooddiary && fooddiary['notes_fdq_d'],
      34: fooddiary && fooddiary['notes_fdq_e'],
      35: fooddiary && fooddiary['notes_fdq_f'],
      36: fooddiary && fooddiary['notes_fdq_g'],
      37: fooddiary && fooddiary['notes_fdq_h'],
      38: fooddiary && fooddiary['notes_fdq_i'],
    }
  }

  goToReport(id: string, e?) {
    // e.stopPropagation();
    if (!id) { return; }
    this.initialized = false;
    this.resetReport(this);
    this.router.navigate([`Userreport/${id}`]);
  }

  resetReport(context) {

    delete context.userData;
    delete context.mainData;
    delete context.nuttest;
    delete context.lastNuttest;
    delete context.practice;
    delete context.sleeping;
    delete context.bloodtest;
    delete context.fooddiary;
    delete context.nutrition;
    delete context.health;

    context.userData = null;
    context.mainData = null;
    context.nuttest = null;
    context.lastNuttest = null;
    context.practice = null;
    context.sleeping = null;
    context.bloodtest = null;
    context.fooddiary = null;
    context.nutrition = null;
    context.health = null;

  }

}
