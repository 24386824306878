<div class="basic-form" *ngIf="!onlyDialog">
    <!-- <div class="row"> -->
        <h1>יומן אימונים אישיים</h1>
        <!-- <div class="text-center" *ngIf="userDetails && appState.user.usr_type != 'player'"> 
            <span class="font-bold">{{userDetails.usr_fname + " " + userDetails.usr_lname}}</span>, 
            {{userDetails.usr_gender}}, 
            {{userDetails.usr_age_formatted}}   
            <span class="nowrap-text">{{userDetails.usr_team_name ? '('+userDetails.usr_team_name+')' : ''}}</span>
        </div> -->
    <!-- </div> -->

    <div class="fixed-buttons">
        <button type="button" mat-fab color="accent" (click)="createPracticeDialog()">
            <mat-icon>add</mat-icon>
        </button>
    </div>

    <mat-card *ngIf="!allPractices">
        <div class="spinner">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
        </div>
    </mat-card>

    <!-- <div class="text-center" *ngIf="allPractices && appState.user.usr_type">
        <mat-form-field class="w65">
            <div class="m-l-20" matPrefix>טווח אימונים: </div>
            <mat-select [(ngModel)]="timePeriod" (selectionChange)="selectedPeriodChange($event)">
                <mat-option value="7 d">שבוע אחרון</mat-option>
                <mat-option value="1 M">חודש אחרון</mat-option>
            </mat-select>
        </mat-form-field>
    </div> -->
    
    <mat-card *ngIf="allPractices && allPractices.length > 0">
        <div class="repeat-container p-0 w65 m-auto"></div>
        <div class="repeat-container w65 m-auto" *ngFor="let practice of allPractices; let i = index; let last = last;">
            <!-- <div> -->
                <div class="centered-row between m-b-10">
                    <div class="">
                        <div class="font-bold">{{ practice.usr_practice_type == 'אחר' ? practice.usr_practice_type_other_text : practice.usr_practice_type }}</div>
                        <div *ngIf="practice.usr_practice_remarks" class="font-14">{{ practice.usr_practice_remarks }}</div>
                    </div>
                    <div class="m-r-5">
                        <button mat-icon-button type="button" (click)="removePracticeDialog(practice)" [disabled]="appState.user.usr_type !== 'superadmin' && appState.user.usr_type !== 'player'">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="row m-0 m-b-10">
                    <div class="">
                        יום {{ weekDays[(practice.usr_practice_date_taken | date:'E')] }}',
                        {{ practice.usr_practice_date_taken | date:'d' }} 
                        ב{{ yearMonths[(practice.usr_practice_date_taken | date:'L')] }}
                    </div>
                    <div>
                        <div>
                            שעה: 
                            <span class="font-bold font-16">{{ practice.usr_practice_time }}</span>
                        </div>
                        <div>
                            זמן: 
                            <span class="font-bold font-16">{{ practice.usr_practice_duration }} דק'</span>
                        </div>
                    </div>
                </div>
            <!-- </div> -->
        </div>
    </mat-card>

    <mat-card *ngIf="allPractices && allPractices.length === 0">
        <div class="repeat-container">
            <div class="text-center font-bold">אין אימונים</div>
        </div>
    </mat-card>

</div>

<ng-template #practiceDialogTemplate let-form="form">
    <div class="basic-form mat-body" [formGroup]="form">

        <mat-form-field>
            <input [readonly]="disableDate" #practiceDate formControlName="usr_practice_date_taken" matInput [matDatepicker]="dpStarted" placeholder="{{'food_diary.TODAY_DATE' | translate}}">
            <mat-datepicker-toggle matSuffix [for]="dpStarted"></mat-datepicker-toggle>
            <mat-datepicker [disabled]="disableDate" #dpStarted></mat-datepicker>
        </mat-form-field>

        <mat-form-field>
            <mat-select formControlName="usr_practice_type" placeholder="{{'food_diary.PRACTICE_TYPE' | translate}}">
                <ng-container *ngFor="let item of practiceTypes">
                    <mat-option [value]="item.title">{{item.title}}</mat-option>
                </ng-container>
                <mat-option value="אחר">{{'food_diary.PRACTICE_TYPE_A_5' | translate}}</mat-option>
            </mat-select>
        </mat-form-field>

        <ng-container *ngIf="form.value.usr_practice_type === 'אחר'">
            <mat-form-field>
                <input formControlName="usr_practice_type_other_text" matInput placeholder="{{'food_diary.PRACTICE_TYPE_OTHER' | translate}}">
            </mat-form-field>
        </ng-container>

        <mat-form-field>
            <mat-select formControlName="usr_practice_time" placeholder="{{'food_diary.PRACTICE_TIME' | translate}}">
                <mat-option *ngFor="let time of allHours" [value]="time">{{time}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <mat-select formControlName="usr_practice_duration" placeholder="{{'food_diary.PRACTICE_DURATION' | translate}}">
                <mat-option *ngFor="let time of durations" [value]="time">{{time}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field>
            <input formControlName="usr_practice_remarks" matInput placeholder="{{'food_diary.PRACTICE_REMARK' | translate}}">
        </mat-form-field>

    </div>
</ng-template>
