<!-- <div *ngIf="fooddiaryquizForm">
    <div *ngIf="!nutritionistFormMode && formId" class="fixed-buttons">
        <button type="button" class="practice-button" [disabled]="isLocked || isBusy" mat-raised-button (click)="createPracticeDialogFunc()">
            <mat-icon>fitness_center</mat-icon>
            {{ 'food_diary.ADD_PRACTICE_BUTTON' | translate }}
        </button>
    </div>

    <form novalidate class="basic-form" [formGroup]="fooddiaryquizForm" (ngSubmit)="onSubmit(false, true, true)"> -->

<ng-container *ngIf="nutritionistFormMode">

    <ng-container *ngIf="!formId && isNuqIngredients">
        <!-- <ng-container *ngFor="let item of defaultTimelineIngredients;">
            <ng-container *ngIf="item.templateType=='meal'" [ngTemplateOutlet]="ingredientsTemplate" [ngTemplateOutletContext]="{
                data: item.data
            }">
            </ng-container>
        </ng-container> -->
        <ng-container *ngFor="let item of ['BREAKFAST', 'ELEVENSES', 'LUNCH', 'TEA', 'DINNER', 'NIGHT']">
            <ng-container [ngTemplateOutlet]="ingredientsTemplate" [ngTemplateOutletContext]="{
                mealName: item
            }">
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!formId && isNuqDetails">

        <mat-card *ngIf="false">
            <div class="nut-chips-container" *ngIf="allCalculatedNut">
                <mat-chip-list aria-label="Nutrition values">
                    <ng-container *ngFor="let key of nutArray">
                        <mat-chip color="accent" selected>
                            <span>{{nutNames[key]}}</span>
                            <span class="bubble">{{allCalculatedNut[key]}}</span>
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
            <div class="text-center font-14 m-b-10">
                <span>{{'food_diary.FLUIDS_DRINKS_WITH_WATER' | translate}}: </span>
                <span class="font-bold">{{ (fluidsDrinksNoWater+fluidsWater) }}</span>
                <span> {{'food_diary.D_VOLUME_VALUE' | translate}}</span>
            </div>
            <div class="text-center font-14">
                <span>{{'food_diary.FLUIDS_WATER' | translate}}: </span>
                <span class="font-bold">{{ fluidsWater }}</span>
                <span> {{'food_diary.D_VOLUME_VALUE' | translate}}</span>
            </div>
        </mat-card>

        <ng-container *ngFor="let item of ['BREAKFAST', 'ELEVENSES', 'LUNCH', 'TEA', 'DINNER', 'NIGHT']">
            <ng-container [ngTemplateOutlet]="detailsTemplate" [ngTemplateOutletContext]="{
                mealName: item
            }">
            </ng-container>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="!formId && !isNuqIngredients && !isNuqDetails">

        <div class="row mt125">
            <div class="w38"></div>
            <div class="w58 text-center">
                <button type="button" mat-raised-button (click)="cloneNotes()">
                    שכפול הערות
                </button>
            </div>
        </div>
        <ng-container *ngFor="let item of defaultTimeline;">
            <ng-container *ngIf="item.templateType=='practice'" [ngTemplateOutlet]="practiceTemplate" [ngTemplateOutletContext]="{
                data: item.data,
                time: item.time
            }">
            </ng-container>
            <ng-container *ngIf="item.templateType=='meal'" [ngTemplateOutlet]="mealTemplate" [ngTemplateOutletContext]="{
                data: item.data,
                time: item.time
            }">
            </ng-container>
            <ng-container *ngIf="item.templateType=='school'" [ngTemplateOutlet]="schoolTemplate" [ngTemplateOutletContext]="{
                data: item.data,
                time: item.time
            }">
            </ng-container>
            <ng-container *ngIf="item.templateType=='supplements'" [ngTemplateOutlet]="supplementTemplate" [ngTemplateOutletContext]="{
                data: item.data,
                time: item.time
            }">
            </ng-container>
        </ng-container>

    </ng-container>

    <ng-container *ngIf="!formId">

        <ng-container *ngIf="oldNotesFlag || (notesForm && notesForm.createdAt < '2021-08-07T11:00:00.000Z');else templateS3">
            <div class="row mt125">
                <div *ngIf="notesForm" class="w58 m-auto text-center">
                    <h4 class="ul-text">
                        הערות תזונה כלליות
                        ({{ notesForm.value.createdAt | date:'dd/MM' }})
                    </h4>
                    <!-- <notes [oldNotes]="notesFormB.get('notes_fdq_h')" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="H"></notes> -->
                    <notes [isFemale]="userGender === 'נקבה'" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="H" (addNoteEvent)="addNote($event, 'fdq', 'H')" (editNoteEvent)="editNote($event, 'fdq', 'H')"></notes>
                </div>
                <div *ngIf="notesFormB" class="w28">
                    <!-- <h4 class="ul-text">הערות תזונה כלליות</h4> -->
                    <h5 class="text-blue">
                        הערות תזונה כלליות
                        ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                    </h5>
                    <notes [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [oldNotes]="notesFormB.get('notes_fdq_h')" [timeCreated]="notesFormB.value.createdAt" [notesArr]="notesList" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="H"></notes>
                </div>
            </div>
        </ng-container>

    <!-- <div #mealTitle id="FLUIDS" class="mealbox"> -->
    
        <div class="healthquiz-box row">
            <div class="w38">
    
            </div>

            <ng-container *ngIf="oldNotesFlag || (notesForm && notesForm.createdAt < '2021-08-07T11:00:00.000Z');else templateS2">
                <div class="row w58 mt125">
                    <div *ngIf="notesForm" [class]="notesFormB ? 'w48' : 'full-width'">
                        <h4 class="ul-text">
                            הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון
                            ({{ notesForm.value.createdAt | date:'dd/MM' }})
                        </h4>
                        <!-- <notes [oldNotes]="notesFormB.get('notes_fdq_g')" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="G"></notes> -->
                        <notes [isFemale]="userGender === 'נקבה'" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="G" (addNoteEvent)="addNote($event, 'fdq', 'G')" (editNoteEvent)="editNote($event, 'fdq', 'G')"></notes>
                    </div>
                    <div *ngIf="notesFormB" class="w48">
                        <!-- <h4 class="ul-text">הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון</h4> -->
                        <h5 class="text-blue">
                            הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון
                            ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                        </h5>
                        <notes [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [oldNotes]="notesFormB.get('notes_fdq_g')" [timeCreated]="notesFormB.value.createdAt" [notesArr]="notesList" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="G"></notes>
                    </div>
                </div>
            </ng-container>

        </div>

        <div #mealTitle id="FLUIDS" class="mealbox healthquiz-box row">
            <div class="w38">
    
            </div>
    
            <ng-container *ngIf="oldNotesFlag || (notesForm && notesForm.createdAt < '2021-08-07T11:00:00.000Z');else templateS10">
                <div class="row w58 mt125">
                    <div *ngIf="notesForm" [class]="notesFormB ? 'w48' : 'full-width'">
                        <h4 class="ul-text">
                            הערות נוזלים ללא אימון
                            ({{ notesForm.value.createdAt | date:'dd/MM' }})
                        </h4>
                        <!-- <notes [oldNotes]="notesFormB.get('notes_fdq_i')" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="I"></notes> -->
                        <notes [isFemale]="userGender === 'נקבה'" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="I" (addNoteEvent)="addNote($event, 'fdq', 'I')" (editNoteEvent)="editNote($event, 'fdq', 'I')"></notes>
                    </div>
                    <div *ngIf="notesFormB" class="w48">
                        <!-- <h4 class="ul-text">הערות נוזלים ללא אימון</h4> -->
                        <h5 class="text-blue">
                            הערות נוזלים ללא אימון
                            ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                        </h5>
                        <notes [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [oldNotes]="notesFormB.get('notes_fdq_i')" [timeCreated]="notesFormB.value.createdAt" [notesArr]="notesList" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="I"></notes>
                    </div>
                </div>    
            </ng-container>
    
        </div>

    <!-- </div> -->
        
        <ng-container *ngIf="oldNotesFlag || (notesForm && notesForm.createdAt < '2021-08-07T11:00:00.000Z');else templateS1">
    
        </ng-container>

        <ng-container *ngIf="oldNotesFlag || (notesForm && notesForm.createdAt < '2021-08-07T11:00:00.000Z');else templateS11">

        </ng-container>

        <ng-container *ngIf="oldNotesFlag;else templateS12">

        </ng-container>
        
    </ng-container>
    
    <ng-container *ngIf="formId && fooddiaryquizForm">

        <mat-card *ngIf="fooddiaryquizForm.value.fdq_day_date_taken" class="font-16 mat-elevation-z2">

            <div *ngIf="fooddiaryquizForm.value.fdq_day_has_notes && fooddiaryquizForm.value.fdq_day_notes">
                מידע נוסף: 
                <span class="font-bold">
                    {{ fooddiaryquizForm.value.fdq_day_notes }}
                </span>
            </div>
            <div *ngIf="fooddiaryquizForm.value.fdq_day_yesterday_sleep_time && fooddiaryquizForm.value.fdq_day_today_wakeup_time">
                שעות שינה: 
                <span class="font-bold">
                    {{ fooddiaryquizForm.value.fdq_day_yesterday_sleep_time }} - {{ fooddiaryquizForm.value.fdq_day_today_wakeup_time }}
                </span>
            </div>
            <div *ngIf="fooddiaryquizForm.value.fdq_day_school_today == 'לא'">
                אין לימודים
            </div>
            <div *ngIf="fooddiaryquizForm.value.fdq_day_school_today == 'כן'">
                <span>הלימודים התחילו ב </span>
                <span class="font-bold">{{fooddiaryquizForm.value.fdq_day_school_start_time}}</span>
                <span> והסתיימו ב </span>
                <span class="font-bold">{{fooddiaryquizForm.value.fdq_day_school_end_time}}</span>
            </div>
        </mat-card>

        <mat-card>
            <div class="nut-chips-container" *ngIf="allCalculatedNut">
                <mat-chip-list aria-label="Nutrition values">
                    <ng-container *ngFor="let key of nutArray">
                        <mat-chip color="accent" selected>
                            <span>{{nutNames[key]}}</span>
                            <span class="bubble">{{allCalculatedNut[key]}}</span>
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
            <div class="text-center font-14 m-b-10">
                <span>{{'food_diary.FLUIDS_DRINKS_WITH_WATER' | translate}}: </span>
                <span class="font-bold">{{ (fluidsDrinksNoWater+fluidsWater) }}</span>
                <span> {{'food_diary.D_VOLUME_VALUE' | translate}}</span>
            </div>
            <div class="text-center font-14">
                <span>{{'food_diary.FLUIDS_WATER' | translate}}: </span>
                <span class="font-bold">{{ fluidsWater }}</span>
                <span> {{'food_diary.D_VOLUME_VALUE' | translate}}</span>
            </div>
        </mat-card>

        <div class="row mt125">
            <div class="w38"></div>
            <div class="w58 text-center">
                <button type="button" mat-raised-button (click)="cloneNotes()">
                    שכפול הערות
                </button>
            </div>
        </div>
        <ng-container *ngFor="let item of timeline;">
            <ng-container *ngIf="item.templateType=='practice'" [ngTemplateOutlet]="practiceTemplate" [ngTemplateOutletContext]="{
                data: item.data,
                time: item.time
            }">
            </ng-container>
            <ng-container *ngIf="item.templateType=='meal'" [ngTemplateOutlet]="mealTemplate" [ngTemplateOutletContext]="{
                data: item.data,
                time: item.time
            }">
            </ng-container>
            <ng-container *ngIf="item.templateType=='school'" [ngTemplateOutlet]="schoolTemplate" [ngTemplateOutletContext]="{
                data: item.data,
                time: item.time
            }">
            </ng-container>
            <ng-container *ngIf="item.templateType=='supplements'" [ngTemplateOutlet]="supplementTemplate" [ngTemplateOutletContext]="{
                data: item.data,
                time: item.time
            }">
            </ng-container>
        </ng-container>

        <ng-container *ngIf="oldNotesFlag || (notesForm && notesForm.createdAt < '2021-08-07T11:00:00.000Z');else templateS3">
            <div class="row mt125">
                <div *ngIf="notesForm" class="w58 m-auto text-center">
                    <h4 class="ul-text">
                        הערות תזונה כלליות
                        ({{ notesForm.value.createdAt | date:'dd/MM' }})
                    </h4>
                    <!-- <notes [oldNotes]="notesFormB.get('notes_fdq_h')" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="H"></notes> -->
                    <notes [isFemale]="userGender === 'נקבה'" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="H" (addNoteEvent)="addNote($event, 'fdq', 'H')" (editNoteEvent)="editNote($event, 'fdq', 'H')"></notes>
                </div>
                <div *ngIf="notesFormB" class="w28">
                    <!-- <h4 class="ul-text">הערות תזונה כלליות</h4> -->
                    <h5 class="text-blue">
                        הערות תזונה כלליות
                        ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                    </h5>
                    <notes [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [oldNotes]="notesFormB.get('notes_fdq_h')" [timeCreated]="notesFormB.value.createdAt" [notesArr]="notesList" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="H"></notes>
                </div>
            </div>
        </ng-container>

    <!-- <div #mealTitle id="FLUIDS" class="mealbox"> -->

        <div class="healthquiz-box row" [hidden]="allPractices.length > 0">
            <div class="w38">
    
            </div>

            <ng-container *ngIf="oldNotesFlag || (notesForm && notesForm.createdAt < '2021-08-07T11:00:00.000Z');else templateS2">
                <div class="row w58 mt125">
                    <div *ngIf="notesForm" [class]="notesFormB ? 'w48' : 'full-width'">
                        <h4 class="ul-text">
                            הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון
                            ({{ notesForm.value.createdAt | date:'dd/MM' }})
                        </h4>
                        <!-- <notes [oldNotes]="notesFormB.get('notes_fdq_g')" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="G"></notes> -->
                        <notes [isFemale]="userGender === 'נקבה'" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="G" (addNoteEvent)="addNote($event, 'fdq', 'G')" (editNoteEvent)="editNote($event, 'fdq', 'G')"></notes>
                    </div>
                    <div *ngIf="notesFormB" class="w48">
                        <!-- <h4 class="ul-text">הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון</h4> -->
                        <h5 class="text-blue">
                            הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון
                            ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                        </h5>
                        <notes [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [oldNotes]="notesFormB.get('notes_fdq_g')" [timeCreated]="notesFormB.value.createdAt" [notesArr]="notesList" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="G"></notes>
                    </div>
                </div>
            </ng-container>

        </div>

        <div #mealTitle id="FLUIDS" class="mealbox healthquiz-box row">
            <div class="w38">

                <mat-card class="">
                    <h3>{{'food_diary.FLUIDS' | translate}}</h3>
                    
                    <div>
                        <span>{{'food_diary.FLUIDS_DRINKS_WITH_WATER' | translate}}: </span>
                        <span class="font-bold">{{ (fluidsDrinksNoWater+fluidsWater) }}</span>
                        <span> {{'food_diary.D_VOLUME_VALUE' | translate}}</span>
                    </div>
                    <div>
                        <span>{{'food_diary.FLUIDS_WATER' | translate}}: </span>
                        <span class="font-bold">{{ fluidsWater }}</span>
                        <span> {{'food_diary.D_VOLUME_VALUE' | translate}}</span>
                    </div>
                    <div>
                        <span>{{'food_diary.OPTIMAL_FLUIDS' | translate}}: </span>
                        <ng-container *ngIf="userOptimalFluidsLiterNoPractice">
                            <span class="font-bold">{{ userOptimalFluidsLiterNoPractice }}</span>
                            <span> {{'food_diary.UNITS_LITER' | translate}}</span>
                        </ng-container>
                        <ng-container *ngIf="!userOptimalFluidsLiterNoPractice">
                            <span>{{'food_diary.OPTIMAL_FLUIDS_NO_WEIGHT' | translate}}</span>
                        </ng-container>
                    </div>

                    <h3>צבע השתן הכהה ביותר שהיה במהלך היום</h3>
                    <mat-grid-list [cols]="urineHexColorPlate.length" rowHeight="3em">
                        <mat-grid-tile *ngFor="let urineColor of urineHexColorPlate; let i = index;"
                        [colspan]="1"
                        [rowspan]="1"
                        [style.padding]="'4px'"
                        [style.border]="fooddiaryquizForm.value.fdq_day_urine_color == urineColor ? '2.5px solid red' : '2px solid blue'"
                        [style.background]="urineColor">
                        </mat-grid-tile>
                    </mat-grid-list>  
                </mat-card>

            </div>

            <ng-container *ngIf="oldNotesFlag || (notesForm && notesForm.createdAt < '2021-08-07T11:00:00.000Z');else templateS10">
                <div class="row w58 mt125">
                    <div *ngIf="notesForm" [class]="notesFormB ? 'w48' : 'full-width'">
                        <h4 class="ul-text">
                            הערות נוזלים ללא אימון
                            ({{ notesForm.value.createdAt | date:'dd/MM' }})
                        </h4>
                        <!-- <notes [oldNotes]="notesFormB.get('notes_fdq_i')" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="I"></notes> -->
                        <notes [isFemale]="userGender === 'נקבה'" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="I" (addNoteEvent)="addNote($event, 'fdq', 'I')" (editNoteEvent)="editNote($event, 'fdq', 'I')"></notes>
                    </div>
                    <div *ngIf="notesFormB" class="w48">
                        <!-- <h4 class="ul-text">הערות נוזלים ללא אימון</h4> -->
                        <h5 class="text-blue">
                            הערות נוזלים ללא אימון
                            ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                        </h5>
                        <notes [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [oldNotes]="notesFormB.get('notes_fdq_i')" [timeCreated]="notesFormB.value.createdAt" [notesArr]="notesList" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="I"></notes>
                    </div>
                </div>    
            </ng-container>

        </div>

    <!-- </div> -->
        
        <ng-container *ngIf="oldNotesFlag || (notesForm && notesForm.createdAt < '2021-08-07T11:00:00.000Z');else templateS1">

        </ng-container>

        <ng-container *ngIf="oldNotesFlag || (notesForm && notesForm.createdAt < '2021-08-07T11:00:00.000Z');else templateS11">

        </ng-container>

        <ng-container *ngIf="oldNotesFlag;else templateS12">

        </ng-container>

    </ng-container>
        
        <ng-template #templateS1>
            <div class="row mt125">
                <div *ngIf="notesForm" class="w58 m-auto text-center">
                    <h4 class="font-bold text-info ul-text">
                        הערות לתחילת הדוח
                        ({{ notesForm.value.createdAt | date:'dd/MM' }})
                    </h4>
                    <notes *ngIf="notesForm.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="userGender === 'נקבה'" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="s1" (addNoteEvent)="addNote($event, 'fdq', 's1')" (editNoteEvent)="editNote($event, 'fdq', 's1')" (removeNoteEvent)="removeNote($event)"></notes>
                </div>
                <div *ngIf="notesFormB" class="w28">
                    <h5 class="text-blue">
                        הערות לתחילת הדוח
                        ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                    </h5>
                    <notes *ngIf="notesFormB.value._id" [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="s1"></notes>
                </div>
            </div>
        </ng-template>
        
        <ng-template #templateS2>
            <div class="row w58">
                <div *ngIf="notesForm" [class]="notesFormB ? 'w48' : 'full-width'">
                    <h4 class="font-bold text-info ul-text">
                        הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון
                        ({{ notesForm.value.createdAt | date:'dd/MM' }})
                    </h4>
                    <notes *ngIf="notesForm.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="userGender === 'נקבה'" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="s2" (addNoteEvent)="addNote($event, 'fdq', 's2')" (editNoteEvent)="editNote($event, 'fdq', 's2')" (removeNoteEvent)="removeNote($event)"></notes>
                </div>
                <div *ngIf="notesFormB" class="w48">
                    <h5 class="text-blue">
                        הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון
                        ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                    </h5>
                    <notes *ngIf="notesFormB.value._id" [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="s2"></notes>
                </div>
            </div>
        </ng-template>

        <ng-template #templateS3>
            <div class="row mt125">
                <div *ngIf="notesForm" class="w58 m-auto text-center">
                    <h4 class="font-bold text-info ul-text">
                        הערות תזונה כלליות
                        ({{ notesForm.value.createdAt | date:'dd/MM' }})
                    </h4>
                    <notes *ngIf="notesForm.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="userGender === 'נקבה'" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="s3" (addNoteEvent)="addNote($event, 'fdq', 's3')" (editNoteEvent)="editNote($event, 'fdq', 's3')" (removeNoteEvent)="removeNote($event)"></notes>
                </div>
                <div *ngIf="notesFormB" class="w28">
                    <h5 class="text-blue">
                        הערות תזונה כלליות
                        ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                    </h5>
                    <notes *ngIf="notesFormB.value._id" [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="s3"></notes>
                </div>
            </div>
        </ng-template>

        <ng-template #templateS4>
            <div class="row w58 mt125">
                <div *ngIf="notesForm" [class]="notesFormB ? 'w48' : 'full-width'">
                    <h4 class="font-bold text-info ul-text">
                        הערות ארוחת בוקר
                        ({{ notesForm.value.createdAt | date:'dd/MM' }})
                    </h4>
                    <notes *ngIf="notesForm.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="userGender === 'נקבה'" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="s4" (addNoteEvent)="addNote($event, 'fdq', 's4')" (editNoteEvent)="editNote($event, 'fdq', 's4')" (removeNoteEvent)="removeNote($event)"></notes>
                </div>
                <div *ngIf="notesFormB" class="w48">
                    <h5 class="text-blue">
                        הערות ארוחת בוקר
                        ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                    </h5>
                    <notes *ngIf="notesFormB.value._id" [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="s4"></notes>
                </div>
            </div>
        </ng-template>
        <ng-template #templateS5>
            <div class="row w58 mt125">
                <div *ngIf="notesForm" [class]="notesFormB ? 'w48' : 'full-width'">
                    <h4 class="font-bold text-info ul-text">
                        הערות ארוחת ביניים 1
                        ({{ notesForm.value.createdAt | date:'dd/MM' }})
                    </h4>
                    <notes *ngIf="notesForm.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="userGender === 'נקבה'" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="s5" (addNoteEvent)="addNote($event, 'fdq', 's5')" (editNoteEvent)="editNote($event, 'fdq', 's5')" (removeNoteEvent)="removeNote($event)"></notes>
                </div>
                <div *ngIf="notesFormB" class="w48">
                    <h5 class="text-blue">
                        הערות ארוחת ביניים 1
                        ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                    </h5>
                    <notes *ngIf="notesFormB.value._id" [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="s5"></notes>
                </div>
            </div>
        </ng-template>
        <ng-template #templateS6>
            <div class="row w58 mt125">
                <div *ngIf="notesForm" [class]="notesFormB ? 'w48' : 'full-width'">
                    <h4 class="font-bold text-info ul-text">
                        הערות ארוחת צהריים
                        ({{ notesForm.value.createdAt | date:'dd/MM' }})
                    </h4>
                    <notes *ngIf="notesForm.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="userGender === 'נקבה'" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="s6" (addNoteEvent)="addNote($event, 'fdq', 's6')" (editNoteEvent)="editNote($event, 'fdq', 's6')" (removeNoteEvent)="removeNote($event)"></notes>
                </div>
                <div *ngIf="notesFormB" class="w48">
                    <h5 class="text-blue">
                        הערות ארוחת צהריים
                        ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                    </h5>
                    <notes *ngIf="notesFormB.value._id" [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="s6"></notes>
                </div>
            </div>
        </ng-template>
        <ng-template #templateS7>
            <div class="row w58 mt125">
                <div *ngIf="notesForm" [class]="notesFormB ? 'w48' : 'full-width'">
                    <h4 class="font-bold text-info ul-text">
                        הערות ארוחת ביניים 2
                        ({{ notesForm.value.createdAt | date:'dd/MM' }})
                    </h4>
                    <notes *ngIf="notesForm.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="userGender === 'נקבה'" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="s7" (addNoteEvent)="addNote($event, 'fdq', 's7')" (editNoteEvent)="editNote($event, 'fdq', 's7')" (removeNoteEvent)="removeNote($event)"></notes>
                </div>
                <div *ngIf="notesFormB" class="w48">
                    <h5 class="text-blue">
                        הערות ארוחת ביניים 2
                        ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                    </h5>
                    <notes *ngIf="notesFormB.value._id" [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="s7"></notes>
                </div>
            </div>
        </ng-template>
        <ng-template #templateS8>
            <div class="row w58 mt125">
                <div *ngIf="notesForm" [class]="notesFormB ? 'w48' : 'full-width'">
                    <h4 class="font-bold text-info ul-text">
                        הערות ארוחת ערב
                        ({{ notesForm.value.createdAt | date:'dd/MM' }})
                    </h4>
                    <notes *ngIf="notesForm.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="userGender === 'נקבה'" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="s8" (addNoteEvent)="addNote($event, 'fdq', 's8')" (editNoteEvent)="editNote($event, 'fdq', 's8')" (removeNoteEvent)="removeNote($event)"></notes>
                </div>
                <div *ngIf="notesFormB" class="w48">
                    <h5 class="text-blue">
                        הערות ארוחת ערב
                        ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                    </h5>
                    <notes *ngIf="notesFormB.value._id" [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="s8"></notes>
                </div>
            </div>
        </ng-template>
        <ng-template #templateS9>
            <div class="row w58 mt125">
                <div *ngIf="notesForm" [class]="notesFormB ? 'w48' : 'full-width'">
                    <h4 class="font-bold text-info ul-text">
                        הערות ארוחת לילה
                        ({{ notesForm.value.createdAt | date:'dd/MM' }})
                    </h4>
                    <notes *ngIf="notesForm.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="userGender === 'נקבה'" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="s9" (addNoteEvent)="addNote($event, 'fdq', 's9')" (editNoteEvent)="editNote($event, 'fdq', 's9')" (removeNoteEvent)="removeNote($event)"></notes>
                </div>
                <div *ngIf="notesFormB" class="w48">
                    <h5 class="text-blue">
                        הערות ארוחת לילה
                        ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                    </h5>
                    <notes *ngIf="notesFormB.value._id" [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="s9"></notes>
                </div>
            </div>
        </ng-template>
        <ng-template #templateS10>
            <div class="row w58 mt125">
                <div *ngIf="notesForm" [class]="notesFormB ? 'w48' : 'full-width'">
                    <h4 class="font-bold text-info ul-text">
                        הערות נוזלים ללא אימון
                        ({{ notesForm.value.createdAt | date:'dd/MM' }})
                    </h4>
                    <notes *ngIf="notesForm.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="userGender === 'נקבה'" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="s10" (addNoteEvent)="addNote($event, 'fdq', 's10')" (editNoteEvent)="editNote($event, 'fdq', 's10')" (removeNoteEvent)="removeNote($event)"></notes>
                </div>
                <div *ngIf="notesFormB" class="w48">
                    <h5 class="text-blue">
                        הערות נוזלים ללא אימון
                        ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                    </h5>
                    <notes *ngIf="notesFormB.value._id" [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="s10"></notes>
                </div>
            </div>
        </ng-template>
        <ng-template #templateS11>
            <div class="row mt125">
                <div *ngIf="notesForm" class="w58 m-auto text-center">
                    <h4 class="font-bold text-info ul-text">
                        דיווח למועדון
                        ({{ notesForm.value.createdAt | date:'dd/MM' }})
                    </h4>
                    <notes *ngIf="notesForm.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="userGender === 'נקבה'" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="s11" (addNoteEvent)="addNote($event, 'fdq', 's11')" (editNoteEvent)="editNote($event, 'fdq', 's11')" (removeNoteEvent)="removeNote($event)"></notes>
                </div>
                <div *ngIf="notesFormB" class="w28">
                    <h5 class="text-blue">
                        דיווח למועדון
                        ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                    </h5>
                    <notes *ngIf="notesFormB.value._id" [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="s11"></notes>
                </div>
            </div>
        </ng-template>
        <ng-template #templateS12>
            <div class="row mt125">
                <div *ngIf="notesForm" class="w58 m-auto text-center">
                    <h4 class="font-bold text-info ul-text">
                        התראות
                        ({{ notesForm.value.createdAt | date:'dd/MM' }})
                    </h4>
                    <notes *ngIf="notesForm.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="userGender === 'נקבה'" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="s12" (addNoteEvent)="addNote($event, 'fdq', 's12')" (editNoteEvent)="editNote($event, 'fdq', 's12')" (removeNoteEvent)="removeNote($event)"></notes>
                </div>
                <div *ngIf="notesFormB" class="w28">
                    <h5 class="text-blue">
                        התראות
                        ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                    </h5>
                    <!-- <notes *ngIf="notesFormB.value._id" [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="s12"></notes> -->
                    <notes *ngIf="notesFormB.value._id" [isFemale]="userGender === 'נקבה'" [isReadonly]="false" [showOrigin]="true" [notesArr]="allNotes" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="s12" (addNoteEvent)="addNote($event, 'fdq', 's12')" (editNoteEvent)="editNote($event, 'fdq', 's12')" (removeNoteEvent)="removeNote($event)"></notes>
                </div>
            </div>
        </ng-template>

        
        <ng-template #ingredientsTemplate let-mealName="mealName">
            <div #mealTitle id="{{mealName}}" class="mealbox healthquiz-box row mt125">

                <div class="w38">
                    <ng-container *ngIf="nuqIngredientsData && nuqIngredientsData[mealName]">
                        
                        <h2 class="nowrap-text m-t-15 m-b-15">
                            {{ mealName === 'NIGHT' ? ('food_diary.NIGHT_SNACK' | translate) : ('food_diary.' + mealName | translate) }}
                        </h2>

                        <ng-container [ngTemplateOutlet]="questionsTemplate" [ngTemplateOutletContext]="{
                            mealName: mealName
                        }">
                        </ng-container>
                                
                        <ng-container [ngTemplateOutlet]="timeAndAssetTemplate" [ngTemplateOutletContext]="{
                            mealName: mealName
                        }">
                        </ng-container>

                        <ng-container [ngTemplateOutlet]="conditionCategoriesTemplate" [ngTemplateOutletContext]="{
                            mealName: mealName
                        }">
                        </ng-container>

                    </ng-container>
                </div>

                <ng-container *ngIf="mealName === 'BREAKFAST'" [ngTemplateOutlet]="templateS4"></ng-container>
                <ng-container *ngIf="mealName === 'ELEVENSES'" [ngTemplateOutlet]="templateS5"></ng-container>
                <ng-container *ngIf="mealName === 'LUNCH'" [ngTemplateOutlet]="templateS6"></ng-container>
                <ng-container *ngIf="mealName === 'TEA'" [ngTemplateOutlet]="templateS7"></ng-container>
                <ng-container *ngIf="mealName === 'DINNER'" [ngTemplateOutlet]="templateS8"></ng-container>
                <ng-container *ngIf="mealName === 'NIGHT'" [ngTemplateOutlet]="templateS9"></ng-container>

            </div>
        </ng-template>

        <ng-template #conditionCategoriesTemplate let-mealName="mealName">
            <ng-container *ngIf="(mealName === 'BREAKFAST' && (nuqData.nuq_eat_breakfast == 'תמיד' || nuqData.nuq_eat_breakfast == 'כמעט תמיד' || nuqData.nuq_eat_breakfast == 'לפעמים')) || (mealName === 'ELEVENSES' && (isUnderNineteen ? ((nuqData.nuq_eat_elevenses == 'תמיד' || nuqData.nuq_eat_elevenses == 'כמעט תמיד' || nuqData.nuq_eat_elevenses == 'לפעמים') || (nuqData.nuq_eat_elevenses_no_school == 'תמיד' || nuqData.nuq_eat_elevenses_no_school == 'כמעט תמיד' || nuqData.nuq_eat_elevenses_no_school == 'לפעמים')) : (nuqData.nuq_eat_elevenses == 'תמיד' || nuqData.nuq_eat_elevenses == 'כמעט תמיד' || nuqData.nuq_eat_elevenses == 'לפעמים'))) || (mealName === 'LUNCH' && (true)) || (mealName === 'TEA' && (nuqData.nuq_eat_tea == 'תמיד' || nuqData.nuq_eat_tea == 'כמעט תמיד' || nuqData.nuq_eat_tea == 'לפעמים')) || (mealName === 'DINNER' && (true)) || (mealName === 'NIGHT' && (nuqData.nuq_eat_night == 'תמיד' || nuqData.nuq_eat_night == 'כמעט תמיד' || nuqData.nuq_eat_night == 'לפעמים'))">

                <ng-container *ngFor="let cat of allCategoriesGroups">
                    <div *ngIf="nuqIngredientsData[mealName][cat.name] && nuqIngredientsData[mealName][cat.name].length > 0" class="mb125">
                        <h4>{{ cat.title }}</h4>

                        <div *ngIf="nuqIngredientsData[mealName][cat.name]">
                            <div *ngFor="let chosenCategory of nuqIngredientsData[mealName][cat.name]" class="">
                                {{ chosenCategory | labelById : nutcategories }}
                            </div>
                        </div>
                    </div>
                </ng-container>
                
            </ng-container>
        </ng-template>

        <ng-template #timeAndAssetTemplate let-mealName="mealName">
            <ng-container *ngIf="nuqData && nuqData['nuq_' + mealName.toLowerCase() + '_time']">
                <div class="mb125">
                    <span>שעת ארוחה: </span>
                    <span class="font-bold">{{ nuqData['nuq_' + mealName.toLowerCase() + '_time'] }}</span>
                </div>
            </ng-container>

            <ng-container *ngIf="nuqData && nuqData['nuq_' + mealName.toLowerCase() + '_assets'] && nuqData['nuq_' + mealName.toLowerCase() + '_assets'][0]">
                <div class="mb125">
                    <a [href]="nuqData['nuq_' + mealName.toLowerCase() + '_assets'][0]['url']" target="_blank" rel="noopener noreferrer">
                        <img style="max-height: 120px; width: auto; max-width: 100%;" [src]="nuqData['nuq_' + mealName.toLowerCase() + '_assets'][0]['url']" />
                    </a>
                </div>
            </ng-container>
        </ng-template>

        <ng-template #questionsTemplate let-mealName="mealName">
            
            <ng-container *ngIf="mealName === 'BREAKFAST'">
                <div class="mb125">
                    <span *ngIf="nuqData.nuq_eat_breakfast" class="font-bold"> {{ nuqData.nuq_eat_breakfast === 'אף פעם' ? 'לא' : nuqData.nuq_eat_breakfast }}</span>
                    <span *ngIf="nuqData.nuq_eat_breakfast"> אוכל ארוחת בוקר סמוך לשעת ההתעוררות </span>
                    <span *ngIf="nuqData.nuq_eat_breakfast == 'לפעמים' || nuqData.nuq_eat_breakfast == 'לעיתים רחוקות' || nuqData.nuq_eat_breakfast == 'אף פעם'" class="font-bold">
                        <span>כי </span>
                        <!-- <span *ngIf="selectedA.nuq_eat_breakfast_reason != 'אחר'">{{selectedA.nuq_eat_breakfast_reason}}</span>
                        <span *ngIf="selectedA.nuq_eat_breakfast_reason == 'אחר'">{{selectedA.nuq_eat_breakfast_reason_other_details}}</span> -->
                        <span *ngFor="let reason of nuqData.nuq_eat_breakfast_reasons; let last = last;">
                            {{ reason == 'אחר' ? nuqData.nuq_eat_breakfast_reason_other_details : reason }}
                            {{ !last ? ', ' : '' }}
                        </span>
                    </span>
                </div>
            </ng-container>

            <ng-container *ngIf="mealName === 'ELEVENSES'">
                <div *ngIf="isUnderNineteen" class="mb125">
                    <span *ngIf="nuqData.nuq_eat_elevenses" class="font-bold"> {{ nuqData.nuq_eat_elevenses === 'אף פעם' ? 'לא' : nuqData.nuq_eat_elevenses }}</span>
                    <span *ngIf="nuqData.nuq_eat_elevenses"> לוקח אוכל לבית הספר </span>
                </div>
                <div *ngIf="isUnderNineteen" class="mb125">
                    <!-- <span *ngIf="nuqData.nuq_eat_elevenses_enough_food" class="font-bold"> {{ nuqData.nuq_eat_elevenses_enough_food }}</span>
                    <span *ngIf="nuqData.nuq_eat_elevenses_enough_food"> מספיק האוכל </span> -->
                    <span *ngIf="nuqData.nuq_eat_elevenses_enough_food === 'כן'">
                        <!-- האוכל מספיק - אני מגיע רעב לארוחת צהרים אבל במידה סבירה -->
                        הכריכים לבית הספר מספיקים
                    </span>
                    <span *ngIf="nuqData.nuq_eat_elevenses_enough_food === 'לא'">
                        <!-- האוכל לא מספיק - אני בדרך כלל מת מרעב בסיום הלימודים -->
                        הכריכים לבית הספר לא מספיקים
                    </span>
                </div>
                <div *ngIf="isUnderNineteen" class="mb125">
                    <span *ngIf="nuqData.nuq_eat_elevenses_no_school" class="font-bold"> {{ nuqData.nuq_eat_elevenses_no_school === 'אף פעם' ? 'לא' : nuqData.nuq_eat_elevenses_no_school }}</span>
                    <span *ngIf="nuqData.nuq_eat_elevenses_no_school"> אוכל ארוחת ביניים 1 בחופשים </span>
                </div>

                <div *ngIf="!isUnderNineteen" class="mb125">
                    <span *ngIf="nuqData.nuq_eat_elevenses" class="font-bold"> {{ nuqData.nuq_eat_elevenses === 'אף פעם' ? 'לא' : nuqData.nuq_eat_elevenses }}</span>
                    <span *ngIf="nuqData.nuq_eat_elevenses"> אוכל ארוחת ביניים 1 </span>
                </div>
            </ng-container>

            <ng-container *ngIf="mealName === 'LUNCH'">
                <div class="mb125">
                    <span *ngIf="nuqData.nuq_eat_lunch" class="font-bold"> {{ nuqData.nuq_eat_lunch === 'אף פעם' ? 'לא' : nuqData.nuq_eat_lunch }}</span>
                    <span *ngIf="nuqData.nuq_eat_lunch"> אוכל ארוחת צהריים </span>
                    <div *ngIf="nuqData.nuq_eat_lunch_dairy_meat" class="font-bold"> {{ nuqData.nuq_eat_lunch_dairy_meat === 'גם וגם' ? 'גם בשרי וגם חלבי' : nuqData.nuq_eat_lunch_dairy_meat }}</div>
                </div>
            </ng-container>

            <ng-container *ngIf="mealName === 'TEA'">
                <div class="mb125">
                    <span *ngIf="nuqData.nuq_eat_tea" class="font-bold"> {{ nuqData.nuq_eat_tea === 'אף פעם' ? 'לא' : nuqData.nuq_eat_tea }}</span>
                    <span *ngIf="nuqData.nuq_eat_tea"> אוכל ארוחת ביניים 2 </span>
                    <div *ngIf="nuqData.nuq_eat_tea_dairy_meat" class="font-bold"> {{ nuqData.nuq_eat_tea_dairy_meat === 'גם וגם' ? 'גם בשרי וגם חלבי' : nuqData.nuq_eat_tea_dairy_meat }}</div>
                </div>
            </ng-container>

            <ng-container *ngIf="mealName === 'DINNER'">
                <div class="mb125">
                    <span *ngIf="nuqData.nuq_eat_dinner" class="font-bold"> {{ nuqData.nuq_eat_dinner === 'אף פעם' ? 'לא' : nuqData.nuq_eat_dinner }}</span>
                    <span *ngIf="nuqData.nuq_eat_dinner"> אוכל ארוחת ערב </span>
                    <div *ngIf="nuqData.nuq_eat_dinner_dairy_meat" class="font-bold"> {{ nuqData.nuq_eat_dinner_dairy_meat === 'גם וגם' ? 'גם בשרי וגם חלבי' : nuqData.nuq_eat_dinner_dairy_meat }}</div>
                </div>
            </ng-container>

            <ng-container *ngIf="mealName === 'NIGHT'">
                <div class="mb125">
                    <span *ngIf="nuqData.nuq_eat_night" class="font-bold"> {{ nuqData.nuq_eat_night === 'אף פעם' ? 'לא' : nuqData.nuq_eat_night }}</span>
                    <span *ngIf="nuqData.nuq_eat_night"> אוכל ארוחת לילה </span>
                    <div *ngIf="nuqData.nuq_eat_night_dairy_meat" class="font-bold"> {{ nuqData.nuq_eat_night_dairy_meat === 'גם וגם' ? 'גם בשרי וגם חלבי' : nuqData.nuq_eat_night_dairy_meat }}</div>
                </div>
            </ng-container>

        </ng-template>

        <ng-template #detailsTemplate let-mealName="mealName">
            <div #mealTitle id="{{mealName}}" class="mealbox healthquiz-box row">
                <meal-segment *ngIf="nuqData && nuqData.nuq_chosen_products" class="w38" [nutritionistFormMode]="true" [mealName]="mealName" mealTitle="{{ mealName === 'NIGHT' ? ('food_diary.NIGHT_SNACK' | translate) : ('food_diary.' + mealName | translate) }}" [allMeals]="nuqData && nuqData.nuq_chosen_products && nuqData.nuq_chosen_products[mealName]"></meal-segment> 
                
                <ng-container *ngIf="mealName === 'BREAKFAST'" [ngTemplateOutlet]="templateS4"></ng-container>
                <ng-container *ngIf="mealName === 'ELEVENSES'" [ngTemplateOutlet]="templateS5"></ng-container>
                <ng-container *ngIf="mealName === 'LUNCH'" [ngTemplateOutlet]="templateS6"></ng-container>
                <ng-container *ngIf="mealName === 'TEA'" [ngTemplateOutlet]="templateS7"></ng-container>
                <ng-container *ngIf="mealName === 'DINNER'" [ngTemplateOutlet]="templateS8"></ng-container>
                <ng-container *ngIf="mealName === 'NIGHT'" [ngTemplateOutlet]="templateS9"></ng-container>

            </div>
        </ng-template>


        <ng-template #practiceTemplate let-data="data" let-time="time">
            <div class="healthquiz-box row p-t-10 p-b-10">
                <mat-card class="w38">
                    <div class="font-16 mb50" style="text-align: justify;">
                        <span>היה לי אימון מסוג</span>
                        <span *ngIf="data.usr_practice_type != 'אחר'" class="font-bold"> {{data.usr_practice_type}}</span>
                        <span *ngIf="data.usr_practice_type == 'אחר'" class="font-bold"> {{data.usr_practice_type_other_text}}</span>
                        <span> משעה </span>
                        <span class="font-bold"> {{data.usr_practice_time}}</span>
                        <span> במשך </span>
                        <span class="font-bold"> {{data.usr_practice_duration}}</span>
                        <span> דקות </span>
                    </div>
                    <div *ngIf="data.usr_practice_remarks != ''">
                        <span>בנוסף הייתי רוצה לציין: </span>
                        <span class="font-bold">{{data.usr_practice_remarks}}</span>
                    </div>
                </mat-card>
                
                <ng-container *ngIf="oldNotesFlag || (notesForm && notesForm.createdAt < '2021-08-07T11:00:00.000Z');else templateS2">
                    <div class="row w58">
                        <div *ngIf="notesForm" [class]="notesFormB ? 'w48' : 'full-width'">
                            <h4 class="ul-text">
                                הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון
                                ({{ notesForm.value.createdAt | date:'dd/MM' }})
                            </h4>
                            <!-- <notes [oldNotes]="notesFormB.get('notes_fdq_g')" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="G"></notes> -->
                            <notes [isFemale]="userGender === 'נקבה'" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" fieldKey="G" (addNoteEvent)="addNote($event, 'fdq', 'G')" (editNoteEvent)="editNote($event, 'fdq', 'G')"></notes>
                        </div>
                        <div *ngIf="notesFormB" class="w48">
                            <!-- <h4 class="ul-text">הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון</h4> -->
                            <h5 class="text-blue">
                                הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון
                                ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                            </h5>
                            <notes [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [oldNotes]="notesFormB.get('notes_fdq_g')" [timeCreated]="notesFormB.value.createdAt" [notesArr]="notesList" [selectedGroup]="notesFormB.get('fdq_notes')" fieldKey="G"></notes>
                        </div>
                    </div>
                </ng-container>

            </div>
        </ng-template>

        <ng-template #mealTemplate let-data="data" let-time="time">
            <div #mealTitle id="{{data.name}}" class="mealbox healthquiz-box row">
                <meal-segment *ngIf="formId" class="w38" [parentFormId]="formId" [nutritionistFormMode]="nutritionistFormMode" [mealName]="data.name" mealTitle="{{'food_diary.' + data.name | translate}}" [restService]="foodDiaryQuizDayInfoRestService" [uploadUrlService]="uploadUrlService" [uploadFolderBase]="uploadFolderBase" [defaultTime]="defaultTimes[data.name]" [allMeals]="allMeals" (tableBusy)="changeInit($event, data.name)"></meal-segment> 
                
                <div *ngIf="!formId" class="w38"></div>
                
                <ng-container *ngIf="oldNotesFlag || (notesForm && notesForm.createdAt < '2021-08-07T11:00:00.000Z');else sharedNotes">
                    <div class="row w58 mt125">
                        <div *ngIf="notesForm" [class]="notesFormB ? 'w48' : 'full-width'">
                            <h4 class="ul-text">
                                הערות {{'food_diary.' + data.name | translate}}
                                ({{ notesForm.value.createdAt | date:'dd/MM' }})
                            </h4>
                            <!-- <notes [notesArr]="notesList[defaultNotes[data.name]]" [selectedForm]="notesForm"
                                [fieldName]="defaultNotes[data.name]"></notes> -->
                            <!-- <notes [fieldKey]="defaultNotes[data.name]" [oldNotes]="notesForm.get('notes_fdq_'+defaultNotesOld[data.name])" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')"></notes> -->
                            <notes [isFemale]="userGender === 'נקבה'" [fieldKey]="defaultNotes[data.name]" [notesArr]="notesList" [selectedGroup]="notesForm.get('fdq_notes')" (addNoteEvent)="addNote($event, 'fdq', defaultNotes[data.name])" (editNoteEvent)="editNote($event, 'fdq', defaultNotes[data.name])"></notes>
                        </div>
                        <div *ngIf="notesFormB" class="w48">
                            <!-- <h4 class="ul-text">הערות {{'food_diary.' + data.name | translate}}</h4> -->
                            <h5 class="text-blue">
                                הערות {{'food_diary.' + data.name | translate}}
                                ({{ notesFormB.value.createdAt | date:'dd/MM' }})
                            </h5>
                            <!-- <notes [notesArr]="notesList[defaultNotes[data.name]]" [selectedForm]="notesForm"
                                [fieldName]="defaultNotes[data.name]"></notes> -->
                            <notes [isFemale]="userGender === 'נקבה'" [isReadonly]="true" [oldNotes]="notesFormB.get('notes_fdq_'+defaultNotesOld[data.name])" [timeCreated]="notesFormB.value.createdAt" [fieldKey]="defaultNotes[data.name]" [notesArr]="notesList" [selectedGroup]="notesFormB.get('fdq_notes')"></notes>
                        </div>
                    </div>
                </ng-container>

                <ng-template #sharedNotes>
                    <ng-container *ngIf="defaultNotesShared[data.name] === 's4'" [ngTemplateOutlet]="templateS4"></ng-container>
                    <ng-container *ngIf="defaultNotesShared[data.name] === 's5'" [ngTemplateOutlet]="templateS5"></ng-container>
                    <ng-container *ngIf="defaultNotesShared[data.name] === 's6'" [ngTemplateOutlet]="templateS6"></ng-container>
                    <ng-container *ngIf="defaultNotesShared[data.name] === 's7'" [ngTemplateOutlet]="templateS7"></ng-container>
                    <ng-container *ngIf="defaultNotesShared[data.name] === 's8'" [ngTemplateOutlet]="templateS8"></ng-container>
                    <ng-container *ngIf="defaultNotesShared[data.name] === 's9'" [ngTemplateOutlet]="templateS9"></ng-container>
                </ng-template>

            </div>
        </ng-template>

        <ng-template #schoolTemplate let-data="data" let-time="time">
            <div class="healthquiz-box row p-t-10 p-b-10">
                <mat-card class="full-width mat-elevation-z2">
                    <div class="font-16">
                        <span *ngIf="data.name == 'start'">הלימודים התחילו היום בשעה </span>
                        <span *ngIf="data.name == 'end'">הלימודים הסתיימו היום בשעה </span>
                        <span class="font-bold">{{time}}</span>
                    </div>
                </mat-card>
            </div>
        </ng-template>

        <ng-template #supplementTemplate let-data="data" let-time="time">
            <div class="healthquiz-box row p-t-10 p-b-10">
                <mat-card class="full-width mat-elevation-z2">
                    <div class="font-16">
                        <span>לקחתי היום תוסף תזונה בשם </span>
                        <span class="font-bold">{{data.name}}</span>
                        <span> בשעה </span>
                        <span class="font-bold">{{time}}</span>
                    </div>                    
                </mat-card>
            </div>
        </ng-template>
        
</ng-container>

<div *ngIf="fooddiaryquizForm">
    <div *ngIf="!nutritionistFormMode && formId" class="fixed-buttons">
        <button type="button" class="practice-button" [disabled]="isLocked || isBusy" mat-raised-button (click)="createPracticeDialogFunc()">
            <mat-icon>fitness_center</mat-icon>
            {{ 'food_diary.ADD_PRACTICE_BUTTON' | translate }}
        </button>
    </div>

    <form novalidate class="basic-form" [formGroup]="fooddiaryquizForm" (ngSubmit)="onSubmit(false, true, true)">

<ng-container *ngIf="!nutritionistFormMode && formId">

        <span #fdq_day_school_today_el></span>
        <mat-card>
            <div class="w45">
                <mat-form-field>
                    <mat-select formControlName="fdq_day_yesterday_sleep_time" placeholder="{{'food_diary.YESTERDAY_SLEEPING_TIME' | translate}}">
                        <mat-option *ngFor="let time of sleepingHours" [value]="time">{{time}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="w45">
                <mat-form-field>
                    <mat-select formControlName="fdq_day_today_wakeup_time" placeholder="{{'food_diary.TODAY_WAKE_UP_TIME' | translate}}">
                        <mat-option *ngFor="let time of wakingHours" [value]="time">{{time}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <ng-container *ngIf="isUnderNineteen">
                <div class="mt125 mb50">
                    {{'food_diary.TODAY_SCHOOL' | translate}}
                </div>                    
                <div class="mb50">
                    <mat-radio-group formControlName="fdq_day_school_today">
                        <mat-radio-button value="כן">{{'food_diary.TODAY_SCHOOL_YES' | translate}}</mat-radio-button>
                        <mat-radio-button value="לא">{{'food_diary.TODAY_SCHOOL_NO' | translate}}</mat-radio-button>
                    </mat-radio-group>
                </div>

                <ng-container *ngIf="fooddiaryquizForm.value.fdq_day_school_today == 'כן'">
                    <div>
                        <mat-form-field class="w45">
                            <mat-select formControlName="fdq_day_school_start_time" placeholder="{{'food_diary.TODAY_SCHOOL_START' | translate}}">
                                <mat-option *ngFor="let time of schoolStartHours" [value]="time">{{time}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field class="w45">
                            <mat-select formControlName="fdq_day_school_end_time" placeholder="{{'food_diary.TODAY_SCHOOL_END' | translate}}">
                                <mat-option *ngFor="let time of schoolEndHours" [value]="time">{{time}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>            
                </ng-container>                
            </ng-container>

            <span #fdq_day_practice_today_el></span>
            <div class="mt125 mb50">
                {{'food_diary.TODAY_PRACTICE' | translate}}
            </div>                    
            <div class="mb50">
                <mat-radio-group formControlName="fdq_day_practice_today">
                    <mat-radio-button value="כן">{{'food_diary.TODAY_PRACTICE_YES' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'food_diary.TODAY_PRACTICE_NO' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <ng-container *ngIf="fooddiaryquizForm.value.fdq_day_practice_today == 'כן'">
                <div class="mt125 mb75">
                    <div class="mb50 font-14">
                        {{ 'food_diary.ADD_PRACTICE_BUTTON_1' | translate }}
                    </div>
                    <div class="font-12 font-italic">
                        {{ 'food_diary.ADD_PRACTICE_BUTTON_2' | translate }}
                    </div>
                </div>
                <div class="mb75">
                    <button type="button" class="practice-button" [disabled]="isLocked || isBusy" mat-raised-button (click)="createPracticeDialogFunc()">
                        <mat-icon>fitness_center</mat-icon>
                        {{ 'food_diary.ADD_PRACTICE_BUTTON' | translate }}
                    </button>
                </div>
                <div>
                    <mat-card *ngIf="allPractices && allPractices.length > 0">
                        <div class="repeat-container p-0 w65 m-auto"></div>
                        <div class="repeat-container w65 m-auto" *ngFor="let practice of allPractices; let i = index; let last = last;">
                            <div class="centered-row between m-b-10">
                                <div class="">
                                    <div class="font-bold">{{ practice.usr_practice_type == 'אחר' ? practice.usr_practice_type_other_text : practice.usr_practice_type }}</div>
                                    <div *ngIf="practice.usr_practice_remarks" class="font-14">{{ practice.usr_practice_remarks }}</div>
                                    
                                    <div class="row m-0">
                                        <div>
                                            {{ 'food_diary.TODAY_PRACTICE_TIME' | translate }}: 
                                            <span class="font-bold font-16">{{ practice.usr_practice_time }}</span>
                                        </div>
                                        <div class="m-r-10 m-l-10">
                                            {{ 'food_diary.TODAY_PRACTICE_DURATION' | translate }}: 
                                            <span class="font-bold font-16">{{ practice.usr_practice_duration }} {{ 'food_diary.TODAY_PRACTICE_MINUTES' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-r-5">
                                    <button mat-icon-button type="button" (click)="removePracticeDialogFunc(practice)" [disabled]="isLocked || isBusy">
                                        <mat-icon>delete</mat-icon>
                                    </button>
                                </div>
                            </div>
                            <!-- <div class="row m-0 m-b-5">
                                <div>
                                    שעה: 
                                    <span class="font-bold font-16">{{ practice.usr_practice_time }}</span>
                                </div>
                                <div>
                                    זמן: 
                                    <span class="font-bold font-16">{{ practice.usr_practice_duration }} דק'</span>
                                </div>
                            </div> -->
                        </div>
                    </mat-card>
                
                    <mat-card *ngIf="allPractices && allPractices.length === 0">
                        <div class="repeat-container">
                            <div class="text-center font-bold">{{ 'food_diary.TODAY_PRACTICE_NONE' | translate }}</div>
                        </div>
                    </mat-card>                
                </div>
            </ng-container>
        </mat-card>

        <mat-card *ngIf="formId && (allCalculatedNut || fluidsDrinksNoWater || fluidsWater)">
            <div class="nut-chips-container" *ngIf="allCalculatedNut">
                <div class="note-star text-warning">*</div>
                <mat-chip-list aria-label="Nutrition values">
                    <!-- <ng-container *ngFor="let nut of allCalculatedNut | keyvalue">
                        <mat-chip color="accent" selected>
                            <span>{{nutNames[nut.key]}}</span>
                            <span class="bubble">{{nut.value}}</span>
                        </mat-chip>
                    </ng-container> -->
                    <ng-container *ngFor="let key of nutArray">
                        <mat-chip color="accent" selected>
                            <span>{{nutNames[key]}}</span>
                            <span class="bubble">{{allCalculatedNut[key]}}</span>
                        </mat-chip>
                    </ng-container>
                </mat-chip-list>
            </div>
            <div class="text-center font-14">
                <span>{{'food_diary.FLUIDS_DRINKS_WITH_WATER' | translate}}: </span>
                <span class="font-bold">{{ (fluidsDrinksNoWater+fluidsWater) }}</span>
                <span> {{'food_diary.D_VOLUME_VALUE' | translate}}</span>
            </div>
        </mat-card>

        <ng-container *ngFor="let item of timeline;">
            <ng-container *ngIf="item.templateType=='practice'" [ngTemplateOutlet]="practiceTemplateUser" [ngTemplateOutletContext]="{
                data: item.data,
                time: item.time
            }">
            </ng-container>
            <ng-container *ngIf="item.templateType=='meal'" [ngTemplateOutlet]="mealTemplateUser" [ngTemplateOutletContext]="{
                data: item.data,
                time: item.time
            }">
            </ng-container>
            <ng-container *ngIf="item.templateType=='school'" [ngTemplateOutlet]="schoolTemplateUser" [ngTemplateOutletContext]="{
                data: item.data,
                time: item.time
            }">
            </ng-container>
            <ng-container *ngIf="item.templateType=='supplements'" [ngTemplateOutlet]="supplementTemplateUser" [ngTemplateOutletContext]="{
                data: item.data,
                time: item.time
            }">
            </ng-container>
        </ng-container>

        <span #fdq_day_urine_color_el></span>
        <h2>{{'food_diary.URINE_COLOR' | translate}}</h2>
        
        <mat-card>

            <mat-grid-list class="pointer" [cols]="urineHexColorPlate.length" rowHeight="3em">
                <mat-grid-tile *ngFor="let urineColor of urineHexColorPlate; let i = index;"   
                (click)="setUrineColor(urineColor)"
                [colspan]="1"
                [rowspan]="1"
                [style.padding]="'4px'"
                [style.border]="fooddiaryquizForm.value.fdq_day_urine_color == urineColor ? '2.5px solid red' : '2px solid blue'"
                [style.background]="urineColor">
                </mat-grid-tile>
            </mat-grid-list>  

        </mat-card>

        <h2>{{'food_diary.SUMMARY_TITLE' | translate}}</h2>
        <span #fdq_day_drink_as_stated_el></span>

        <mat-card>
            <div class="heq-col">
                <mat-checkbox class="mb50 checkbox" formControlName="fdq_day_drink_as_stated" [ngClass]="{ 'text-danger': showErrors && !fooddiaryquizForm.value.fdq_day_drink_as_stated }">{{'food_diary.EXTRA_DRINK_Q' | translate}}
                </mat-checkbox>
                <mat-checkbox class="mb50 checkbox" formControlName="fdq_day_meals_as_stated" [ngClass]="{ 'text-danger': showErrors && !fooddiaryquizForm.value.fdq_day_meals_as_stated }">{{'food_diary.EXTRA_FOOD_Q' | translate}}
                </mat-checkbox>
                <mat-checkbox class="mb50 checkbox" (change)="takeSupplementsOnChange($event)" formControlName="fdq_day_take_supplements">{{'food_diary.SUPPLEMENT_Q' | translate}}</mat-checkbox>
            </div>

            <div class="m-r-20 m-l-20" *ngIf="fooddiaryquizForm.value.fdq_day_take_supplements == true">
                <span #fdq_day_take_supplements_type_el></span>
                <div>
                    <mat-form-field class="w45">
                        <input formControlName="fdq_day_take_supplements_type" matInput placeholder="{{'food_diary.SUPPLEMENT_TYPE' | translate}}">
                    </mat-form-field>
                </div>
                <span #fdq_day_take_supplements_time_el></span>
                <div>
                    <mat-form-field class="w45">
                        <mat-select formControlName="fdq_day_take_supplements_time" placeholder="{{'food_diary.SUPPLEMENT_TIME' | translate}}">
                            <mat-option *ngFor="let time of allHours" [value]="time">{{time}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <mat-checkbox class="mb50 checkbox" formControlName="fdq_day_different">{{'food_diary.STANDARD_Q' | translate}}
            </mat-checkbox>
            
            <mat-checkbox class="mb50 checkbox" formControlName="fdq_day_has_notes">{{'food_diary.HAS_NOTES' | translate}}
            </mat-checkbox>
            <div class="m-r-20 m-l-20" *ngIf="fooddiaryquizForm.value.fdq_day_has_notes == true">
                <span #fdq_day_has_notes_el></span>
                <div>
                    <mat-form-field class="w45">
                        <input formControlName="fdq_day_notes" matInput placeholder="{{'general.description' | translate}}">
                    </mat-form-field>
                </div>
            </div>
        </mat-card>
        
        <span class="mt125"></span>

        <button mat-raised-button color="primary" [disabled]="isLocked">{{'general.save' | translate}}</button>
        <div *ngIf="isLocked" class="font-12 text-center text-danger">
            {{'general.savedQuiz' | translate}}
        </div>

        <p *ngIf="allCalculatedNut" class="mt125 font-12 font-italic mat-body text-warning">
            {{'food_diary.CAL_NOTES' | translate}}
        </p>

</ng-container>

    </form>

</div>

<!-- <div *ngIf="nutritionistFormMode && !initClear" class="basic-form mt125 full-width">
    <div class="spinner no-margin">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div> -->

<ng-template #practiceTemplateUser let-data="data" let-time="time">
    <div class="repeat-container">
        <mat-card>
            <div class="centered-row">
                <div class="full-width">
                    <div class="mb50" style="text-align: justify;">
                        <span>{{ 'food_diary.PRACTICE_1' | translate }}</span>
                        <span *ngIf="data.usr_practice_type != 'אחר'" class="font-bold"> {{data.usr_practice_type}}</span>
                        <span *ngIf="data.usr_practice_type == 'אחר'" class="font-bold"> {{data.usr_practice_type_other_text}}</span>
                        <span> {{ 'food_diary.PRACTICE_2' | translate }} </span>
                        <span class="font-bold"> {{data.usr_practice_time}}</span>
                        <span> {{ 'food_diary.PRACTICE_3' | translate }} </span>
                        <span class="font-bold"> {{data.usr_practice_duration}}</span>
                        <span> {{ 'food_diary.PRACTICE_4' | translate }} </span>
                    </div>
                    <div *ngIf="data.usr_practice_remarks != ''">
                        <span>{{ 'food_diary.PRACTICE_5' | translate }}: </span>
                        <span class="font-bold">{{data.usr_practice_remarks}}</span>
                    </div>
                </div>
                <div class="m-r-5">
                    <button mat-icon-button type="button" (click)="removePracticeDialogFunc(data)" [disabled]="isLocked">
                        <mat-icon>remove_circle_outline</mat-icon>
                    </button>
                </div>
            </div>
        </mat-card>        
    </div>
</ng-template>

<ng-template #supplementTemplateUser let-data="data" let-time="time">
    <div class="repeat-container">
        <mat-card>
            <div>
                <span>{{ 'food_diary.SUPPLEMENT_1' | translate }} </span>
                <span class="font-bold">{{data.name}}</span>
                <span> {{ 'food_diary.SUPPLEMENT_2' | translate }} </span>
                <span class="font-bold">{{time}}</span>
            </div>
        </mat-card>
    </div>
</ng-template>

<ng-template #schoolTemplateUser let-data="data" let-time="time">
    <div class="repeat-container">
        <mat-card>
            <div>
                <span *ngIf="data.name == 'start'">{{ 'food_diary.SCHOOL_1' | translate }} </span>
                <span *ngIf="data.name == 'end'">{{ 'food_diary.SCHOOL_2' | translate }} </span>
                <span class="font-bold">{{time}}</span>
            </div>
        </mat-card>
    </div>
</ng-template>

<ng-template #mealTemplateUser let-data="data" let-time="time">
    <!-- <div class="repeat-container" style="position: relative;" *ngIf="formId !== undefined"> -->
    <div class="repeat-container" style="position: relative;" *ngIf="allMeals !== undefined">
        <meal-segment [parentFormId]="formId" [userId]="userId" [isMinor]="isMinor" [isUnderage]="isUnderage" [nutritionistFormMode]="nutritionistFormMode" (mealUpdate)="mealUpdate($event, data.name)" (tableBusy)="changeInit($event, data.name)" [mealName]="data.name" mealTitle="{{'food_diary.' + data.name | translate}}" [restService]="foodDiaryQuizDayInfoRestService" [uploadUrlService]="uploadUrlService" [uploadFolderBase]="uploadFolderBase" [defaultTime]="defaultTimes[data.name]" [isLocked]="isLocked" [allMeals]="allMeals" [allLastItems]="allLastItems"></meal-segment>
    </div>
</ng-template>

<user-practice *ngIf="userId" [userIdInput]="userId" [onlyDialog]="true" [disableDate]="true" (created)="practiceCreated($event)" (removed)="practiceRemoved($event)"></user-practice>

<ng-template #fluidsUnderTemplate let-context="context" let-form="form">
    <div mat-dialog-content>
        <form novalidate class="basic-form" [formGroup]="form">
            <div class="mb50 mat-body">
                <span>{{ 'food_diary.FLUIDS_UNDER_1' | translate }} </span>
                <span>{{ form.value.current }} </span>
                <span>{{ 'food_diary.FLUIDS_UNDER_2' | translate }} </span>
                <div>{{ 'food_diary.FLUIDS_UNDER_3' | translate }} </div>
                <div>{{ 'food_diary.FLUIDS_UNDER_4' | translate }} </div>
            </div>

            <mat-form-field class="mb50">
                <mat-select formControlName="chosen" placeholder="{{ 'food_diary.DRINK_ESTIMATE' | translate }}">
                    <mat-option value="0.2">{{ 'food_diary.DRINK_ESTIMATE_1' | translate }}</mat-option>
                    <mat-option value="0.4">{{ 'food_diary.DRINK_ESTIMATE_2' | translate }}</mat-option>
                    <mat-option value="0.6">{{ 'food_diary.DRINK_ESTIMATE_3' | translate }}</mat-option>
                    <mat-option value="0.8">{{ 'food_diary.DRINK_ESTIMATE_4' | translate }}</mat-option>
                    <mat-option value="1.0">{{ 'food_diary.DRINK_ESTIMATE_5' | translate }}</mat-option>
                    <mat-option value="1.2">{{ 'food_diary.DRINK_ESTIMATE_6' | translate }}</mat-option>
                    <mat-option value="1.4">{{ 'food_diary.DRINK_ESTIMATE_7' | translate }}</mat-option>
                    <mat-option value="1.6">{{ 'food_diary.DRINK_ESTIMATE_8' | translate }}</mat-option>
                    <mat-option value="1.8">{{ 'food_diary.DRINK_ESTIMATE_9' | translate }}</mat-option>
                    <mat-option value="2.0">{{ 'food_diary.DRINK_ESTIMATE_10' | translate }}</mat-option>
                    <mat-option value="2.2">{{ 'food_diary.DRINK_ESTIMATE_11' | translate }}</mat-option>
                    <mat-option value="2.4">{{ 'food_diary.DRINK_ESTIMATE_12' | translate }}</mat-option>
                    <mat-option value="2.6">{{ 'food_diary.DRINK_ESTIMATE_13' | translate }}</mat-option>
                    <mat-option value="2.8">{{ 'food_diary.DRINK_ESTIMATE_14' | translate }}</mat-option>
                    <mat-option value="3.0">{{ 'food_diary.DRINK_ESTIMATE_15' | translate }}</mat-option>
                    <mat-option value="3.2">{{ 'food_diary.DRINK_ESTIMATE_16' | translate }}</mat-option>
                    <mat-option value="3.4">{{ 'food_diary.DRINK_ESTIMATE_17' | translate }}</mat-option>
                    <mat-option value="3.6">{{ 'food_diary.DRINK_ESTIMATE_18' | translate }}</mat-option>
                    <mat-option value="3.8">{{ 'food_diary.DRINK_ESTIMATE_19' | translate }}</mat-option>
                    <mat-option value="4.0">{{ 'food_diary.DRINK_ESTIMATE_20' | translate }}</mat-option>
                    <mat-option value="4.2">{{ 'food_diary.DRINK_ESTIMATE_21' | translate }}</mat-option>
                    <mat-option value="4.4">{{ 'food_diary.DRINK_ESTIMATE_22' | translate }}</mat-option>
                    <mat-option value="4.6">{{ 'food_diary.DRINK_ESTIMATE_23' | translate }}</mat-option>
                    <mat-option value="4.8">{{ 'food_diary.DRINK_ESTIMATE_24' | translate }}</mat-option>
                    <mat-option value="5.0">{{ 'food_diary.DRINK_ESTIMATE_25' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>

        </form>
    </div>
</ng-template>


