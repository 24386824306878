<mat-expansion-panel #exp [disabled]="!nuttest" [expanded]="!nuttest" *ngIf="statage && wtage && bmiage">
    <mat-expansion-panel-header>
        <mat-panel-title *ngIf="nuttest" class="font-18 font-bold">
            עקומות גדילה
        </mat-panel-title>
        <mat-panel-title *ngIf="!nuttest" class="font-16 font-bold">
            <h4 class="m-auto">היסטוריה וגרפים</h4>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <div *ngIf="isMeasureReport ? exp.expanded : true" class="text-center">
        <h4>עקומת גובה לפי גיל - אחוזון גובה</h4>
        <div class="centered-row max-600" [chart]="statage"></div>
        <div *ngIf="nuttest && nuttest.ntq_height_p_label">
            {{ nuttest.ntq_height_p_label }}
        </div>

        <br>
        <mat-divider></mat-divider>

        <h4>עקומת משקל לפי גיל - אחוזון משקל</h4>
        <div class="centered-row max-600" [chart]="wtage"></div>
        <div *ngIf="nuttest && nuttest.ntq_weight_p_label">
            {{ nuttest.ntq_weight_p_label }}
        </div>

        <br>
        <mat-divider></mat-divider>

        <h4>עקומת BMI לפי גיל - אחוזון BMI</h4>
        <div class="centered-row max-600" [chart]="bmiage"></div>
        <div *ngIf="nuttest && nuttest.ntq_bmi_p_label">
            {{ nuttest.ntq_bmi_p_label }}
        </div>
    </div>

</mat-expansion-panel>
