<div class="full-loader" *ngIf="busy">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>

<div *ngIf="!busy">
    <div style="justify-content: center;" class="row">
        <h1>{{'nuttest.QUIZ_TITLE' | translate}}</h1>
    </div>

    <div style="display: flex; flex-direction: row-reverse; justify-content: space-between; overflow-y: hidden;height: calc(100vh - 190px);"
        [ngClass]="{'loading-margin': busy}">
        <div style="width: 45%; overflow-y: auto;"
            [hidden]="!nuttestquizForm.value._id || nuttestquizForm.value._id==''">
            <mat-expansion-panel [disabled]="allNotesIds.length === 0 || notesArr.length === 0" [expanded]="true">
                <mat-expansion-panel-header>
                    <mat-panel-title class="font-18 font-bold">
                        הערות מהדוח האחרון
                    </mat-panel-title>
                    <mat-panel-description>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div style="overflow-y: auto; max-height: 80vh;">
                    <mat-card>
                        <div class="w70 m-auto">
                            <h4 class="ul-text">הערות תזונאי</h4>
                            <div class="mb125">
                                <mat-form-field class="mb50">
                                    <textarea [formControl]="userFormGroup.get('notes_usr_decription')"
                                        cdkTextareaAutosize cdkAutosizeMinRows="3" placeholder="תיאור של הלקוח"
                                        matInput></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card>

                    <mat-list *ngIf="allNotesIds.length > 0 && notesArr.length > 0" class="">

                        <mat-list-item>
                            <div mat-list-icon class="centered-row hide-line">
                                <mat-checkbox [(ngModel)]="checkAllNotes"
                                    (ngModelChange)="selectAllCheckboxes($event)"></mat-checkbox>
                            </div>
                            <div mat-line>
                                <span class="font-bold">סמן הכל</span>
                            </div>
                        </mat-list-item>

                        <mat-divider></mat-divider>

                        <ng-container *ngFor="let notesGroup of allNotesGroups">
                            <ng-container *ngFor="let note of notesGroup; let i = index;">
                                <mat-list-item
                                    *ngIf="note && note.note_id && (note | noteDetails : notesArr : 'quiz_prefix')">

                                    <div mat-list-icon class="centered-row hide-line">
                                        <mat-checkbox [(ngModel)]="note.is_checked"
                                            (ngModelChange)="changedCheckbox($event)"></mat-checkbox>
                                    </div>
                                    <div mat-line matTooltip="{{ note | noteDetails : notesArr : 'title' }}"
                                        matTooltipClass="font-16">
                                        <span class="font-bold">{{ note | noteDetails : notesArr : 'title' }}</span>
                                    </div>
                                    <div mat-line class="hide-line"
                                        matTooltip="{{ (note | noteDetails : notesArr : (isFemale ? 'content_F' : 'content')) || (note | noteDetails : notesArr : 'content') }}"
                                        matTooltipClass="font-14">{{ (note | noteDetails : notesArr : (isFemale ?
                                        'content_F' : 'content')) || (note | noteDetails : notesArr : 'content') }}
                                    </div>

                                </mat-list-item>
                            </ng-container>
                            <mat-divider></mat-divider>
                        </ng-container>

                        <mat-list-item>
                            <div mat-list-icon class="centered-row hide-line">
                                <mat-checkbox [(ngModel)]="checkAllNotes"
                                    (ngModelChange)="selectAllCheckboxes($event)"></mat-checkbox>
                            </div>
                            <div mat-line>
                                <span class="font-bold">סמן הכל</span>
                            </div>
                        </mat-list-item>

                    </mat-list>
                </div>
            </mat-expansion-panel>
        </div>

        <form style="width: 45%; margin: 0; overflow-y: auto;" novalidate autocomplete="off" class="basic-form"
            [formGroup]="nuttestquizForm" (ngSubmit)="onSubmit(true)">
            <mat-card *ngIf="!nuttestquizForm.value._id || nuttestquizForm.value._id==''">
                <div class="spinner">
                    <div class="double-bounce1"></div>
                    <div class="double-bounce2"></div>
                </div>
            </mat-card>

            <div [hidden]="!nuttestquizForm.value._id || nuttestquizForm.value._id==''">
                <mat-card style="margin-top: 0;">
                    <div style="display: flex; flex-direction: column; gap:15px">
                        <div class="w45">
                            <mat-form-field>
                                <input #formDate matInput [matDatepicker]="dpTestdate"
                                    placeholder="{{'nuttest.INSERT_DATE' | translate}}" formControlName="ntq_test_date">
                                <mat-datepicker-toggle matSuffix [for]="dpTestdate"></mat-datepicker-toggle>
                                <mat-datepicker #dpTestdate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <div class="nut-field-row mb75">
                            <mat-form-field *ngIf="isFemale" class="w45">
                                <input matInput [matDatepicker]="dpCycleDate" placeholder="תאריך וסט אחרון"
                                    formControlName="usr_cycle_date">
                                <mat-datepicker-toggle matSuffix [for]="dpCycleDate"></mat-datepicker-toggle>
                                <mat-datepicker #dpCycleDate></mat-datepicker>
                            </mat-form-field>
                        </div>
                        <!-- HEIGHT -->
                        <div style="display: flex; flex-direction: column; ">
                            <div style="display: flex; gap: 10px; justify-content: space-between;">
                                <mat-form-field style="width: 30%;">
                                    <input type="number" formControlName="ntq_height" matInput
                                        placeholder="{{'nuttest.HEIGHT' | translate}}">
                                </mat-form-field>
                                <ng-container *ngIf="oldValues.length > 0" [ngTemplateOutlet]="oldValueTemplate"
                                    [ngTemplateOutletContext]="{
                                    values: oldValues,
                                    fieldName: 'ntq_height',
                                    dropdownFieldName: 'ntq_height',
                                    selectedValues: selectedValues
                                }">
                                </ng-container>
                            </div>

                            <div style="display: flex; gap:5px">

                                <div class="font-16 w30 mb50"
                                    *ngIf="showComputed && computedSelected && computedSelected['ntq_height_p']">
                                    אחוזון גובה: <span class="dl dir-ltr font-bold m-r-5 m-l-5">{{
                                        computedSelected['ntq_height_p']}}</span>
                                </div>
                                <div class="w40" style="display: flex; flex-direction: column; gap:5px;"
                                    *ngIf="showComputed && selectedValues['ntq_height'] > -1">
                                    <div
                                        *ngIf="computedSelected['ntq_height'] && oldValues[selectedValues['ntq_height']]['ntq_height']">
                                        הפרש גובה (ס&quot;מ): <div class="font-bold dl dir-ltr">
                                            {{calcDiff(computedSelected['ntq_height'],
                                            oldValues[selectedValues['ntq_height']]['ntq_height'])}}</div>
                                    </div>
                                    <div
                                        *ngIf="computedSelected['ntq_height_p'] && oldValues[selectedValues['ntq_height']]['ntq_height_p']">
                                        הפרש אחוזון: <div class="font-bold dl dir-ltr">
                                            {{calcDiff(computedSelected['ntq_height_p'],
                                            oldValues[selectedValues['ntq_height']]['ntq_height_p'])}}%</div>
                                    </div>
                                    <div class="dir-ltr"
                                        *ngIf="computedSelected['ntq_height_p'] && (oldValues | percentageFlag : 'ntq_height_p' : computedSelected['ntq_height_p']).num">
                                        <div class="text-danger font-bold dl dir-ltr">
                                            {{ (oldValues | percentageFlag : 'ntq_height_p' :
                                            computedSelected['ntq_height_p']).num }}%
                                        </div>
                                        &nbsp;
                                        <div class="font-bold font-12 dl">(
                                            {{ (oldValues | percentageFlag : 'ntq_height_p' :
                                            computedSelected['ntq_height_p']).date }}
                                            )</div>
                                    </div>


                                </div>
                                <div class="w30">
                                    <div class="font-16 mb50"
                                        *ngIf="computedSelected && oldValues && oldValues[selectedValues] && oldValues[selectedValues['ntq_height']]
                                        && oldValues[selectedValues['ntq_height']]['ntq_height_p'] && computedSelected['ntq_height_p']">
                                        אחוזון גובה: <span class="dl dir-ltr font-bold m-r-5 m-l-5">{{
                                            oldValues[selectedValues['ntq_height']]['ntq_height_p'] }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="nut-field-row mb75">
                                <mat-form-field class="custom-select-container w30">
                                    <mat-select formControlName="ntq_height_trend" placeholder="הזן מגמת גובה"
                                        panelClass="custom-select-panel">
                                        <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                                        <mat-option value="שמירה">שמירה</mat-option>
                                        <mat-option value="ירידה קלה">ירידה קלה</mat-option>
                                        <mat-option value="ירידה משמעותית">ירידה משמעותית</mat-option>
                                        <mat-option value="עליה משמעותית">עליה משמעותית</mat-option>
                                        <mat-option value="מדידה ראשונה">מדידה ראשונה</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="nut-field-row w70">
                                    <ng-container *ngIf="oldValues.length > 0" [ngTemplateOutlet]="oldValueTemplate"
                                        [ngTemplateOutletContext]="{
                                        values: oldValues,
                                        fieldName: 'ntq_height_trend',
                                        dropdownFieldName: 'ntq_height_trend',
                                        selectedValues: selectedValues
                                    }">
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- HEIGHT -->


                        <!-- WEIGHT -->
                        <div style="display: flex; flex-direction: column;">
                            <div style="display: flex; gap: 10px; justify-content: space-between;">
                                <mat-form-field style="width: 30%;">
                                    <input type="number" formControlName="ntq_weight" matInput
                                        placeholder="{{'nuttest.WEIGHT' | translate}}">
                                </mat-form-field>
                                <ng-container *ngIf="oldValues.length > 0" [ngTemplateOutlet]="oldValueTemplate"
                                    [ngTemplateOutletContext]="{
                                        values: oldValues,
                                        fieldName: 'ntq_weight',
                                        dropdownFieldName: 'ntq_weight',
                                        selectedValues: selectedValues
                                    }">
                                </ng-container>
                            </div>

                            <div style="display: flex; gap:5px">

                                <div class="w49">
                                    <div class="font-16 mb50" style="visibility: hidden;">-just for space-</div>
                                    <div class="font-16 mb50"
                                        *ngIf="computedSelected && computedSelected['ntq_weight_p']">
                                        אחוזון משקל: <span
                                            class="dl dir-ltr font-bold m-r-5 m-l-5">{{computedSelected['ntq_weight_p']}}</span>
                                    </div>
                                    <div class="font-16 mb50" style="visibility: hidden;">-just for space-</div>

                                    <div class="font-16 mb50" *ngIf="computedSelected && computedSelected['ntq_bmi_p']">
                                        <div class="dl">אחוזון BMI: </div>
                                        <span class="dl dir-ltr font-bold m-r-5 m-l-5">{{
                                            computedSelected['ntq_bmi_p']}}</span>
                                    </div>
                                </div>

                                <div class="w49" style="display: flex; flex-direction: column; gap:5px;"
                                    *ngIf="showComputed && selectedValues['ntq_height'] > -1">
                                    <div
                                        *ngIf="computedSelected['ntq_weight'] && oldValues[selectedValues['ntq_height']]['ntq_weight']">
                                        הפרש משקל (ק&quot;ג): <div class="font-bold dl dir-ltr">
                                            {{calcDiff(computedSelected['ntq_weight'],
                                            oldValues[selectedValues['ntq_height']]['ntq_weight'])}}</div>
                                    </div>
                                    <div
                                        *ngIf="computedSelected['ntq_weight_p'] && oldValues[selectedValues['ntq_height']]['ntq_weight_p']">
                                        הפרש אחוזון: <div class="font-bold dl dir-ltr">
                                            {{calcDiff(computedSelected['ntq_weight_p'],
                                            oldValues[selectedValues['ntq_height']]['ntq_weight_p'])}}%</div>
                                    </div>
                                    <div class="dir-ltr"
                                        *ngIf="computedSelected['ntq_weight_p'] && (oldValues | percentageFlag : 'ntq_weight_p' : computedSelected['ntq_weight_p']).num">
                                        <div class="text-danger font-bold dl dir-ltr">{{ (oldValues | percentageFlag :
                                            'ntq_weight_p' : computedSelected['ntq_weight_p']).num }}%</div>
                                        &nbsp;
                                        <div class="font-bold font-12 dl">({{ (oldValues | percentageFlag :
                                            'ntq_weight_p' :
                                            computedSelected['ntq_weight_p']).date }})</div>
                                    </div>
                                    <div
                                        *ngIf="computedSelected['ntq_bmi'] && oldValues[selectedValues['ntq_height']]['ntq_bmi']">
                                        <span style="display: inline-block;">הפרש BMI</span>: <div
                                            class="font-bold dl dir-ltr">{{calcDiff(computedSelected['ntq_bmi'],
                                            oldValues[selectedValues['ntq_height']]['ntq_bmi'])}}</div>
                                    </div>
                                    <div
                                        *ngIf="computedSelected['ntq_bmi_p'] && oldValues[selectedValues['ntq_height']]['ntq_bmi_p']">
                                        הפרש אחוזון: <div class="font-bold dl dir-ltr">
                                            {{calcDiff(computedSelected['ntq_bmi_p'],
                                            oldValues[selectedValues['ntq_height']]['ntq_bmi_p'])}}%</div>
                                    </div>
                                    <div class="dir-ltr"
                                        *ngIf="computedSelected['ntq_bmi_p'] && (oldValues | percentageFlag : 'ntq_bmi_p' : computedSelected['ntq_bmi_p']).num">
                                        <div class="text-danger font-bold dl dir-ltr">{{ (oldValues | percentageFlag :
                                            'ntq_bmi_p' : computedSelected['ntq_bmi_p']).num }}%</div>
                                        &nbsp;
                                        <div class="font-bold font-12 dl">({{ (oldValues | percentageFlag : 'ntq_bmi_p'
                                            :
                                            computedSelected['ntq_bmi_p']).date }})</div>
                                    </div>
                                </div>


                                <div class="w49">
                                    <div class="font-16 mb50" style="visibility: hidden;">-just for space-</div>

                                    <div class="font-16 mb50"
                                        *ngIf="oldValues && oldValues[selectedValues] && oldValues[selectedValues]?.ntq_weight && oldValues[selectedValues['ntq_weight']]['ntq_weight_p']">
                                        אחוזון משקל:
                                        <span class="dl dir-ltr font-bold m-r-5 m-l-5">{{
                                            oldValues[selectedValues['ntq_weight']]['ntq_weight_p']}}</span>
                                    </div>
                                    <div class="font-16 mb50" style="visibility: hidden;">-just for space-</div>

                                    <div class="font-16 mb50"
                                        *ngIf="oldValues && oldValues[selectedValues] && oldValues[selectedValues]?.ntq_bmi &&  oldValues[selectedValues['ntq_height']]['ntq_bmi'] ">
                                        <div class="dl">אחוזון BMI: </div>
                                        <span class="dl dir-ltr font-bold m-r-5 m-l-5">{{
                                            oldValues[selectedValues['ntq_height']]['ntq_bmi_p'] }}</span>
                                    </div>
                                </div>

                                <!-- <div class="w49">
                                    <div class="font-16 mb50"
                                        *ngIf="computedSelected && computedSelected['ntq_weight_p']">
                                        אחוזון משקל: <span
                                            class="dl dir-ltr font-bold m-r-5 m-l-5">{{computedSelected['ntq_weight_p']}}</span>
                                    </div>
                                    <div class="font-16 mb50" *ngIf="computedSelected && computedSelected['ntq_bmi_p']">
                                        <div class="dl">אחוזון BMI: </div>
                                        <span class="dl dir-ltr font-bold m-r-5 m-l-5">{{
                                            computedSelected['ntq_bmi_p']}}</span>
                                    </div>
                                </div> -->
                            </div>
                            <div class="nut-field-row mb75">
                                <mat-form-field class="custom-select-container w30">
                                    <mat-select formControlName="ntq_bmi_status" placeholder="הזן סטטוס BMI"
                                        panelClass="custom-select-panel">
                                        <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                                        <mat-option value="נמוך מאוד">נמוך מאוד</mat-option>
                                        <mat-option value="מעט נמוך">מעט נמוך</mat-option>
                                        <mat-option value="תקין">תקין</mat-option>
                                        <mat-option value="מעט גבוה">מעט גבוה</mat-option>
                                        <mat-option value="גבוה">גבוה</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="nut-field-row w70">
                                    <ng-container *ngIf="oldValues.length > 0" [ngTemplateOutlet]="oldValueTemplate"
                                        [ngTemplateOutletContext]="{
                                        values: oldValues,
                                        fieldName: 'ntq_bmi_status',
                                        dropdownFieldName: 'ntq_bmi_status',
                                        selectedValues: selectedValues
                                    }">
                                    </ng-container>
                                </div>
                            </div>

                            <div class="nut-field-row mb75">
                                <mat-form-field class="custom-select-container w30">
                                    <mat-select formControlName="ntq_bmi_trend" placeholder="הזן מגמת BMI"
                                        panelClass="custom-select-panel">
                                        <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                                        <mat-option value="שמירה">שמירה</mat-option>
                                        <mat-option value="ירידה קלה">ירידה קלה</mat-option>
                                        <mat-option value="ירידה משמעותית">ירידה משמעותית</mat-option>
                                        <mat-option value="עליה קלה">עליה קלה</mat-option>
                                        <mat-option value="עליה משמעותית">עליה משמעותית</mat-option>
                                        <mat-option value="מדידה ראשונה">מדידה ראשונה</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div class="nut-field-row w70">
                                    <ng-container *ngIf="oldValues.length > 0" [ngTemplateOutlet]="oldValueTemplate"
                                        [ngTemplateOutletContext]="{
                                        values: oldValues,
                                        fieldName: 'ntq_bmi_trend',
                                        dropdownFieldName: 'ntq_bmi_trend',
                                        selectedValues: selectedValues
                                    }">
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <!-- WEIGHT -->

                        <!-- WINGSPAN -->
                        <div style="display: flex; flex-direction: column;">
                            <div style="display: flex; gap: 10px; justify-content: space-between;">
                                <mat-form-field style="width: 30%;">
                                    <input type="number" formControlName="ntq_wingspan" matInput
                                        placeholder="{{'nuttest.WINGSPAN' | translate}}">
                                </mat-form-field>
                                <ng-container *ngIf="oldValues.length > 0" [ngTemplateOutlet]="oldValueTemplate"
                                    [ngTemplateOutletContext]="{
            values: oldValues,
            fieldName: 'ntq_wingspan',
            dropdownFieldName: 'ntq_wingspan',
            selectedValues: selectedValues
        }">
                                </ng-container>
                            </div>
                        </div>
                        <!-- WINGSPAN -->
                    </div>
                </mat-card>

                <mat-card>
                    <div class="nut-field-row mb75">
                        <mat-form-field class="custom-select-container w35">
                            <mat-select formControlName="ntq_factor" panelClass="custom-select-panel"
                                placeholder="{{'nuttest.FACTOR_INSERT' | translate}}">
                                <mat-option value="1.3">עבודה משרדית יושבני (1.3)</mat-option>
                                <mat-option value="1.5">גיל התבגרות (בנים 17, בנות 15) (1.5)</mat-option>
                                <mat-option value="1.50">עקרת בית, מכירה, הוראה (1.5)</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="nut-field-row w60">
                            <ng-container *ngIf="oldValues.length > 0" [ngTemplateOutlet]="oldValueTemplate"
                                [ngTemplateOutletContext]="{
                                values: oldValues,
                                fieldName: 'ntq_factor',
                                dropdownFieldName: 'ntq_factor',
                                selectedValues: selectedValues
                            }">
                            </ng-container>
                        </div>
                    </div>
                    <div class="nut-field-row mb75">
                        <mat-form-field class="custom-select-container w30">
                            <mat-select formControlName="ntq_dev_status" placeholder="הזן סטטוס התפתחותי"
                                panelClass="custom-select-panel">
                                <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                                <mat-option value="טרום התפתחות">טרום התפתחות</mat-option>
                                <mat-option value="סיום התפתחות">סיום התפתחות</mat-option>
                                <mat-option value="בגרות">בגרות</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="nut-field-row w70">
                            <ng-container *ngIf="oldValues.length > 0" [ngTemplateOutlet]="oldValueTemplate"
                                [ngTemplateOutletContext]="{
                                values: oldValues,
                                fieldName: 'ntq_dev_status',
                                dropdownFieldName: 'ntq_dev_status',
                                selectedValues: selectedValues
                            }">
                            </ng-container>
                        </div>
                    </div>
                </mat-card>

                <h3 [ngClass]="{ 'required-question': showErrors && nuttestquizForm.get('ntq_fat_percent').invalid }">
                    {{'nuttest.FAT_PERCENT_MEASUREMENT' | translate}}</h3>
                <div class="m-0 row">
                    <mat-radio-group class="column" formControlName="ntq_fat_percent">
                        <mat-radio-button class="mb50" value="קליפר">קליפר</mat-radio-button>
                        <mat-radio-button class="mb50" value="הזנה ידנית">הזנה ידנית</mat-radio-button>
                        <mat-radio-button class="mb50" value="ללא אחוז שומן" selected>ללא אחוז שומן</mat-radio-button>
                    </mat-radio-group>
                </div>

                <mat-card>
                    <ng-container *ngIf="nuttestquizForm.value.ntq_fat_percent === 'קליפר'">
                        <!-- <div class="nut-field-row mb75">
                            <mat-form-field class="w35">
                                <mat-select formControlName="ntq_fat_caliper_status"
                                    placeholder="{{'nuttest.STATUS_INSERT' | translate}}">
                                    <mat-option value="תואם גיל">תואם גיל</mat-option>
                                    <mat-option value="טרום התפתחותי">טרום התפתחותי</mat-option>
                                    <mat-option value="מפותח">מפותח</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="nut-field-row w60">
                                <ng-container *ngIf="oldValues.length > 0" [ngTemplateOutlet]="oldValueTemplate"
                                    [ngTemplateOutletContext]="{
                                    values: oldValues,
                                    fieldName: 'ntq_fat_caliper_status',
                                    dropdownFieldName: 'ntq_fat_percent',
                                    selectedValues: selectedValues
                                }">
                                </ng-container>
                            </div>
                        </div> -->
                        <h4>{{'nuttest.CALIPER_INSERT' | translate}}</h4>
                        <div class="nut-field-row mb75">

                            <div class="nut-field-row w60">
                                <ng-container *ngIf="oldValues.length > 0" [ngTemplateOutlet]="oldValueTemplate"
                                    [ngTemplateOutletContext]="{
                                    values: oldValues,
                                    fieldName: 'ntq_fat_caliper_status',
                                    dropdownFieldName: 'ntq_fat_percent',
                                    selectedValues: selectedValues
                                }">
                                </ng-container>
                            </div>
                        </div>
                        <div class="nut-field-row mb75">
                            <div class="w35">
                                <mat-form-field>
                                    <input type="number" formControlName="ntq_fat_caliper_a" matInput
                                        placeholder="יד קדמית">
                                </mat-form-field>
                                <mat-form-field>
                                    <input type="number" formControlName="ntq_fat_caliper_b" matInput
                                        placeholder="יד אחורית">
                                </mat-form-field>
                                <mat-form-field>
                                    <input type="number" formControlName="ntq_fat_caliper_c" matInput
                                        placeholder="שיכמה">
                                </mat-form-field>
                                <mat-form-field>
                                    <input type="number" formControlName="ntq_fat_caliper_d" matInput
                                        placeholder="מותן">
                                </mat-form-field>
                            </div>


                            <div class="nut-field-row w60" *ngIf="oldValues.length > 0">
                                <div style="align-self: baseline;" class="w49"
                                    *ngIf="selectedValues['ntq_fat_percent'] > -1">
                                </div>
                                <div class="w49"
                                    *ngIf="selectedValues['ntq_fat_percent'] > -1 && oldValues[selectedValues['ntq_fat_percent']]">
                                    <mat-form-field
                                        *ngIf="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_caliper_a']">
                                        <input disabled
                                            [value]="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_caliper_a']"
                                            matInput>
                                    </mat-form-field>
                                    <mat-form-field
                                        *ngIf="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_caliper_b']">
                                        <input disabled
                                            [value]="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_caliper_b']"
                                            matInput>
                                    </mat-form-field>
                                    <mat-form-field
                                        *ngIf="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_caliper_c']">
                                        <input disabled
                                            [value]="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_caliper_c']"
                                            matInput>
                                    </mat-form-field>
                                    <mat-form-field
                                        *ngIf="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_caliper_d']">
                                        <input disabled
                                            [value]="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_caliper_d']"
                                            matInput>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="nuttestquizForm.value.ntq_fat_percent === 'הזנה ידנית'">
                        <div class="nut-field-row mb75">
                            <mat-form-field class="w35">
                                <input type="number" formControlName="ntq_fat_percent_num" matInput
                                    placeholder="{{'nuttest.FAT_PERCENT_INSERT' | translate}}">
                            </mat-form-field>
                            <div class="nut-field-row w60">
                                <ng-container *ngIf="oldValues.length > 0" [ngTemplateOutlet]="oldValueTemplate"
                                    [ngTemplateOutletContext]="{
                                    values: oldValues,
                                    fieldName: 'ntq_fat_percent_num',
                                    dropdownFieldName: 'ntq_fat_percent',
                                    selectedValues: selectedValues
                                }">
                                </ng-container>
                            </div>
                        </div>
                    </ng-container>

                    <div class="nut-field-row mb75">
                        <div class="w35 font-14">
                            <ng-container *ngIf="showComputed && computedSelected">
                                <p
                                    *ngIf="computedSelected['ntq_fat_percent'] === 'הזנה ידנית' && computedSelected['ntq_fat_percent_num']">
                                    אחוז שומן (הזנה ידנית): <span class="font-bold">{{
                                        computedSelected['ntq_fat_percent_num'] }}</span>
                                </p>
                                <p *ngIf="computedSelected['ntq_fat_sf']">
                                    סכום קפלי עור: <span class="font-bold">{{ computedSelected['ntq_fat_sf'] }}</span>
                                </p>
                                <p *ngIf="computedSelected['ntq_fat_default']">
                                    אחוז שומן לפי 20-30: <span class="font-bold">{{ computedSelected['ntq_fat_default']
                                        }}</span>
                                </p>
                                <p *ngIf="computedSelected['ntq_fat_age']">
                                    אחוז שומן לפי גיל: <span class="font-bold">{{ computedSelected['ntq_fat_age']
                                        }}</span>
                                </p>
                                <p *ngIf="computedSelected['ntq_fat_mass']">
                                    מסת שומן<span *ngIf="computedSelected['ntq_fat_percent'] === 'קליפר'"> ({{
                                        computedSelected['ntq_fat_caliper_status'] }})</span>: <span
                                        class="font-bold">{{
                                        computedSelected['ntq_fat_mass'] }}</span>
                                </p>
                                <p *ngIf="computedSelected['ntq_skinny_mass']">
                                    מסה רזה<span *ngIf="computedSelected['ntq_fat_percent'] === 'קליפר'"> ({{
                                        computedSelected['ntq_fat_caliper_status'] }})</span>: <span
                                        class="font-bold">{{
                                        computedSelected['ntq_skinny_mass'] }}</span>
                                </p>
                            </ng-container>
                        </div>
                        <div class="nut-field-row w60" *ngIf="oldValues.length > 0">
                            <div style="align-self: baseline;" class="w49"
                                *ngIf="selectedValues['ntq_fat_percent'] > -1">
                            </div>
                            <div class="w49 font-14"
                                *ngIf="selectedValues['ntq_fat_percent'] > -1 && oldValues[selectedValues['ntq_fat_percent']]">
                                <p
                                    *ngIf="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_percent'] === 'הזנה ידנית' && oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_percent_num']">
                                    אחוז שומן (הזנה ידנית): <span class="font-bold">{{
                                        oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_percent_num'] }}</span>
                                </p>
                                <p *ngIf="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_sf']">
                                    סכום קפלי עור: <span class="font-bold">{{
                                        oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_sf'] }}</span>
                                </p>
                                <p *ngIf="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_default']">
                                    אחוז שומן לפי 20-30: <span class="font-bold">{{
                                        oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_default'] }}</span>
                                </p>
                                <p *ngIf="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_age']">
                                    אחוז שומן לפי גיל: <span class="font-bold">{{
                                        oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_age'] }}</span>
                                </p>
                                <p *ngIf="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_mass']">
                                    מסת שומן<span
                                        *ngIf="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_percent'] === 'קליפר'">
                                        ({{ oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_caliper_status']
                                        }})</span>: <span class="font-bold">{{
                                        oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_mass'] }}</span>
                                </p>
                                <p *ngIf="oldValues[selectedValues['ntq_fat_percent']]['ntq_skinny_mass']">
                                    מסה רזה<span
                                        *ngIf="oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_percent'] === 'קליפר'">
                                        ({{ oldValues[selectedValues['ntq_fat_percent']]['ntq_fat_caliper_status']
                                        }})</span>: <span class="font-bold">{{
                                        oldValues[selectedValues['ntq_fat_percent']]['ntq_skinny_mass'] }}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </mat-card>

                <br>

                <mat-card>
                    <h3>סטטוס ומגמות</h3>
                    <div class="nut-field-row mb75">
                        <mat-form-field class="custom-select-container w30">
                            <mat-select formControlName="ntq_fat_per_status" placeholder="הזן סטטוס אחוז שומן"
                                panelClass="custom-select-panel">
                                <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                                <mat-option value="נמוך מאוד">נמוך מאוד</mat-option>
                                <mat-option value="נמוך תקין">נמוך תקין</mat-option>
                                <mat-option value="ממוצע (התנסחות עדינה)">ממוצע (התנסחות עדינה)</mat-option>
                                <mat-option value="מעט גבוה עבור ספורטאי">מעט גבוה עבור ספורטאי</mat-option>
                                <mat-option value="גבוה עבור ספורטאי">גבוה עבור ספורטאי</mat-option>
                                <mat-option value="בגבול העליון של הנורמה">בגבול העליון של הנורמה</mat-option>
                                <mat-option value="גבוה בריאותית">גבוה בריאותית</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="nut-field-row w70">
                            <ng-container *ngIf="oldValues.length > 0" [ngTemplateOutlet]="oldValueTemplate"
                                [ngTemplateOutletContext]="{
                                values: oldValues,
                                fieldName: 'ntq_fat_per_status',
                                dropdownFieldName: 'ntq_fat_per_status',
                                selectedValues: selectedValues
                            }">
                            </ng-container>
                        </div>
                    </div>

                    <div class="nut-field-row mb75">
                        <mat-form-field class="custom-select-container w30">
                            <mat-select formControlName="ntq_fat_per_trend" placeholder="הזן מגמת אחוז שומן"
                                panelClass="custom-select-panel-special">
                                <mat-option value="ללא התייחסות">ללא התייחסות</mat-option>
                                <mat-option value="שמירה">שמירה</mat-option>
                                <mat-option value="ירידה קלה">ירידה קלה</mat-option>
                                <mat-option value="ירידה משמעותית">ירידה משמעותית</mat-option>
                                <mat-option value="עליה קלה">עליה קלה</mat-option>
                                <mat-option value="עליה משמעותית">עליה משמעותית</mat-option>
                                <mat-option value="מדידה ראשונה">מדידה ראשונה</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div class="nut-field-row w70">
                            <ng-container *ngIf="oldValues.length > 0" [ngTemplateOutlet]="oldValueTemplate"
                                [ngTemplateOutletContext]="{
                                values: oldValues,
                                fieldName: 'ntq_fat_per_trend',
                                dropdownFieldName: 'ntq_fat_per_trend',
                                selectedValues: selectedValues
                            }">
                            </ng-container>
                        </div>
                    </div>
                </mat-card>

                <br>

                <button mat-raised-button color="primary" class="full-width" [disabled]="busy">שמור ונעל</button>
            </div>
        </form>

        <ng-template #oldValueTemplate let-values="values" let-fieldName="fieldName"
            let-dropdownFieldName="dropdownFieldName" let-selectedValues="selectedValues">
            <mat-form-field *ngIf="selectedValues[dropdownFieldName] > -1" class="custom-select-container w49">
                <mat-select [(ngModel)]="selectedValues[dropdownFieldName]" panelClass="custom-select-panel"
                    [ngModelOptions]="{standalone: true}">
                    <ng-container *ngFor="let val of values; let i = index">
                        <mat-option *ngIf="val[dropdownFieldName] && val[dropdownFieldName].length > 0" [value]="i">
                            {{ val['ntq_test_date'] | date:'dd/MM/yyyy' }}
                            {{ val['is_by_user'] ? ' (מדידת משתמש) ' : '' }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <mat-form-field
                *ngIf="selectedValues[dropdownFieldName] > -1 && values[selectedValues[dropdownFieldName]][fieldName]"
                class="w49">
                <input disabled [value]="values[selectedValues[dropdownFieldName]][fieldName]" matInput>
            </mat-form-field>
        </ng-template>
    </div>
</div>