<div class="antro-form">
    <mat-expansion-panel disabled expanded>
        <mat-expansion-panel-header>
            <!-- <mat-panel-title class="font-18 font-bold">
            הרגלי שינה
            </mat-panel-title> -->
            <mat-panel-title class="font-16 font-bold">
                <h4 class="m-auto">הרגלי שינה</h4>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row mb125">
            <button class="plus-button" mat-raised-button (click)="newQuizGuard()">
                {{'general.newquiz' | translate}}
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <div *ngIf="oldValues.length > 0" class="big-content">
            <!-- <div class="row mb125">
                <mat-form-field *ngIf="oldValues.length > 0" class="w30">
                    <mat-select [(ngModel)]="selectedIdA" (ngModelChange)="selectDateA($event)">
                        <ng-container *ngFor="let val of oldValues; let i = index">
                            <mat-option [value]="val._id">
                                {{val['createdAt'] | date:'dd/MM/yyyy' }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div> -->

            <div class="row mb125">
                <mat-form-field *ngIf="oldValues.length > 0" class="w40">
                    <!-- <button type="button" *ngIf="selectedA" matSuffix [routerLink]="['/Sleepingquiz/', selectedIdA]" class="top-button" mat-stroked-button>עבור לטופס</button> -->
                    <a (click)="$event.stopPropagation()" target="quizwindow" *ngIf="selectedA" matSuffix [routerLink]="['/Sleepingquiz/', selectedIdA]" class="top-button" mat-stroked-button>עבור לטופס</a>
                    <mat-select [disabled]="true" [(ngModel)]="selectedIdA" (ngModelChange)="selectDateA($event)">
                        <ng-container *ngFor="let val of oldValues; let i = index">
                            <mat-option *ngIf="selectedIdB != val._id" [value]="val._id">
                                {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <button *ngIf="oldValues.length > 1" mat-stroked-button color="primary" class="w15 wrap-text"
                    (click)="fetchOldValues(true)">אפס לשתי המדידות האחרונות</button>

                <mat-form-field *ngIf="oldValues.length > 1" class="w40">
                    <!-- <button type="button" *ngIf="selectedB" matSuffix [routerLink]="['/Sleepingquiz/', selectedIdB]" class="top-button" mat-stroked-button>עבור לטופס</button> -->
                    <a (click)="$event.stopPropagation()" target="quizwindow" *ngIf="selectedB" matSuffix [routerLink]="['/Sleepingquiz/', selectedIdB]" class="top-button" mat-stroked-button>עבור לטופס</a>
                    <mat-select [(ngModel)]="selectedIdB" (ngModelChange)="selectDateB($event)">
                        <ng-container *ngFor="let val of oldValues; let i = index">
                            <mat-option *ngIf="selectedIdA != val._id" [value]="val._id">
                                {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <div class="mb125" *ngIf="selectedA['slq_weekday_hours']">
                        <div>
                            שעות שינה אמצע שבוע: <span class="font-bold">{{ selectedA['slq_weekday_hours'] }}</span>
                        </div>
                        <div>
                            שעת ההירדמות: {{ selectedA['slq_weekday_sleeping_time'] }}
                        </div>
                        <div>
                            שעת ההתעוררות: {{ selectedA['slq_weekday_wakeup_time'] }}
                        </div>
                        <div [ngClass]="{'text-danger font-bold' : selectedA['slq_weekday_status'].substring(0, 4) != 'תקין'}">
                            {{selectedA['slq_weekday_status']}}
                        </div>
                    </div>
                    <div class="mb125" *ngIf="selectedA['slq_weekend_hours']">
                        <div>
                            שעות שינה סוף שבוע: <span class="font-bold">{{ selectedA['slq_weekend_hours'] }}</span>
                        </div>
                        <div>
                            שעת ההירדמות: {{ selectedA['slq_weekend_sleeping_time'] }}
                        </div>
                        <div>
                            שעת ההתעוררות: {{ selectedA['slq_weekend_wakeup_time'] }}
                        </div>
                        <div [ngClass]="{'text-danger font-bold' : selectedA['slq_weekend_status'].substring(0, 4) != 'תקין'}">
                            {{selectedA['slq_weekend_status']}}
                        </div>
                    </div>

                    <p class="font-bold text-danger" *ngIf="selectedA['slq_waking_difficulty'] == 'כן'">
                        יש קושי להתעורר
                    </p>
                    <p *ngIf="selectedA['slq_waking_difficulty'] == 'לא'">
                        אין קושי להתעורר
                    </p>
                    <p class="font-bold text-danger" *ngIf="selectedA['slq_tiredness_during_day'] == 'כן'">
                        יש עייפות במהלך היום
                    </p>
                    <p *ngIf="selectedA['slq_tiredness_during_day'] == 'לא'">
                        אין עייפות במהלך היום
                    </p>

                    <div class="mb125" *ngIf="selectedA['slq_sleeping_difficulty'] == 'כן'">
                        <div class="font-bold text-danger">
                            יש קושי להירדם
                        </div>
                        <div *ngIf="selectedA['slq_sleeping_difficulty_description']">
                            תיאור הקושי: {{ selectedA['slq_sleeping_difficulty_description'] }}
                        </div>
                        <div *ngIf="selectedA['slq_sleeping_difficulty_duration']">
                            משך זמן ההירדמות: <span class="font-bold text-danger">{{ selectedA['slq_sleeping_difficulty_duration'] }} דקות</span>
                        </div>
                    </div>
                    <p *ngIf="selectedA['slq_sleeping_difficulty'] == 'לא'">
                        אין קושי להירדם
                    </p>
                    
                    <p class="font-bold text-danger" *ngIf="selectedA['slq_screen_habits'] == 'כן'">
                        נכנס למיטה עם מסך
                    </p>
                    <p *ngIf="selectedA['slq_screen_habits'] == 'לא'">
                        לא נכנס למיטה עם מסך
                    </p>

                    <div class="mb125" *ngIf="selectedA['slq_nap_habits'] == 'כן'">
                        <div class="font-bold">
                            ישן שנ"צ
                        </div>
                        <div *ngIf="selectedA['slq_nap_habits_frequency']">
                            מספר פעמים בשבוע: <span class="font-bold">{{ selectedA['slq_nap_habits_frequency'] }}</span>
                        </div>
                        <div *ngIf="selectedA['slq_nap_habits_time']">
                            <span *ngIf="selectedA['slq_nap_habits_time'] === '0-30'" class="font-bold">עד 30 דקות</span>
                            <span *ngIf="selectedA['slq_nap_habits_time'] === '30-60'" class="font-bold">30-60 דקות</span>
                            <span *ngIf="selectedA['slq_nap_habits_time'] === '60-90'" class="font-bold">60-90 דקות</span>
                            <span *ngIf="selectedA['slq_nap_habits_time'] === '90+'" class="font-bold">יקיצה טבעית</span>
                        </div>
                    </div>
                    <p *ngIf="selectedA['slq_nap_habits'] == 'לא'">
                        לא ישן שנ"צ
                    </p>

                    <p *ngIf="selectedA['slq_more_comments'] == 'כן' && selectedA['slq_more_comments_text']">
                        הערות נוספות בקשר להרגלי השינה: {{ selectedA['slq_more_comments_text'] }}
                    </p>
                    
                    <!-- <notes *ngIf="notesFormA.value._id" [notesArr]="notesList['notes_nut_sleeping']" [selectedForm]="notesFormA"
                    [fieldName]="'notes_nut_sleeping'"></notes> -->
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">

                    <div class="mb125" *ngIf="selectedB['slq_weekday_hours']">
                        <div>
                            שעות שינה אמצע שבוע: <span class="font-bold">{{ selectedB['slq_weekday_hours'] }}</span>
                        </div>
                        <div>
                            שעת ההירדמות: {{ selectedB['slq_weekday_sleeping_time'] }}
                        </div>
                        <div>
                            שעת ההתעוררות: {{ selectedB['slq_weekday_wakeup_time'] }}
                        </div>
                        <div [ngClass]="{'text-danger font-bold' : selectedB['slq_weekday_status'].substring(0, 4) != 'תקין'}">
                            {{selectedB['slq_weekday_status']}}
                        </div>
                    </div>
                    <div class="mb125" *ngIf="selectedB['slq_weekend_hours']">
                        <div>
                            שעות שינה סוף שבוע: <span class="font-bold">{{ selectedB['slq_weekend_hours'] }}</span>
                        </div>
                        <div>
                            שעת ההירדמות: {{ selectedB['slq_weekend_sleeping_time'] }}
                        </div>
                        <div>
                            שעת ההתעוררות: {{ selectedB['slq_weekend_wakeup_time'] }}
                        </div>
                        <div [ngClass]="{'text-danger font-bold' : selectedB['slq_weekend_status'].substring(0, 4) != 'תקין'}">
                            {{selectedB['slq_weekend_status']}}
                        </div>
                    </div>

                    <p class="font-bold text-danger" *ngIf="selectedB['slq_waking_difficulty'] == 'כן'">
                        יש קושי להתעורר
                    </p>
                    <p *ngIf="selectedB['slq_waking_difficulty'] == 'לא'">
                        אין קושי להתעורר
                    </p>
                    <p class="font-bold text-danger" *ngIf="selectedB['slq_tiredness_during_day'] == 'כן'">
                        יש עייפות במהלך היום
                    </p>
                    <p *ngIf="selectedB['slq_tiredness_during_day'] == 'לא'">
                        אין עייפות במהלך היום
                    </p>

                    <div class="mb125" *ngIf="selectedB['slq_sleeping_difficulty'] == 'כן'">
                        <div class="font-bold text-danger">
                            יש קושי להירדם
                        </div>
                        <div *ngIf="selectedB['slq_sleeping_difficulty_description']">
                            תיאור הקושי: {{ selectedB['slq_sleeping_difficulty_description'] }}
                        </div>
                        <div *ngIf="selectedB['slq_sleeping_difficulty_duration']">
                            משך זמן ההירדמות: <span class="font-bold text-danger">{{ selectedB['slq_sleeping_difficulty_duration'] }} דקות</span>
                        </div>
                    </div>
                    <p *ngIf="selectedB['slq_sleeping_difficulty'] == 'לא'">
                        אין קושי להירדם
                    </p>
                    
                    <p class="font-bold text-danger" *ngIf="selectedB['slq_screen_habits'] == 'כן'">
                        נכנס למיטה עם מסך
                    </p>
                    <p *ngIf="selectedB['slq_screen_habits'] == 'לא'">
                        לא נכנס למיטה עם מסך
                    </p>

                    <div class="mb125" *ngIf="selectedB['slq_nap_habits'] == 'כן'">
                        <div class="font-bold">
                            ישן שנ"צ
                        </div>
                        <div *ngIf="selectedB['slq_nap_habits_frequency']">
                            מספר פעמים בשבוע: <span class="font-bold">{{ selectedB['slq_nap_habits_frequency'] }}</span>
                        </div>
                        <div *ngIf="selectedB['slq_nap_habits_time']">
                            <span *ngIf="selectedB['slq_nap_habits_time'] === '0-30'" class="font-bold">עד 30 דקות</span>
                            <span *ngIf="selectedB['slq_nap_habits_time'] === '30-60'" class="font-bold">30-60 דקות</span>
                            <span *ngIf="selectedB['slq_nap_habits_time'] === '60-90'" class="font-bold">60-90 דקות</span>
                            <span *ngIf="selectedB['slq_nap_habits_time'] === '90+'" class="font-bold">יקיצה טבעית</span>
                        </div>
                    </div>
                    <p *ngIf="selectedB['slq_nap_habits'] == 'לא'">
                        לא ישן שנ"צ
                    </p>

                    <p *ngIf="selectedB['slq_more_comments'] == 'כן' && selectedB['slq_more_comments_text']">
                        הערות נוספות בקשר להרגלי השינה: {{ selectedB['slq_more_comments_text'] }}
                    </p>
                    
                    <!-- <notes *ngIf="notesFormB.value._id" [notesArr]="notesList['notes_nut_sleeping']" [selectedForm]="notesFormB"
                    [fieldName]="'notes_nut_sleeping'"></notes> -->
                </div>
            </div>
            <h4 class="ul-text">הערות הרגלי שינה</h4>
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('slq_notes')" fieldKey="A" [oldNotes]="notesFormA.get('notes_nut_sleeping')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'slq', 'A')" (editNoteEvent)="editNote($event, 'slq', 'A')"></notes>
                </div>
                
                <div class="w40" *ngIf="selectedB">
                    <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('slq_notes')" fieldKey="A" [oldNotes]="notesFormB.get('notes_nut_sleeping')" [timeCreated]="selectedB.createdAt"></notes>
                </div>
            </div>

            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">
                <h4 class="font-bold text-info ul-text">הערות לתחילת הדוח</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && notesFormA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s1" (addNoteEvent)="addNote($event, quizPrefix, 's1')" (editNoteEvent)="editNote($event, quizPrefix, 's1')"></notes> -->
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [selectedGroup]="notesFormA.get(quizPrefix + '_notes')" [sharedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s1" [notesArr]="notesList" (addNoteEvent)="addNote($event, quizPrefix, 's1')" (editNoteEvent)="editNote($event, quizPrefix, 's1')"></notes>
                        <!-- <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s1" (addNoteEvent)="addNote($event, quizPrefix, 's1')" (editNoteEvent)="editNote($event, quizPrefix, 's1')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s1" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes> -->
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s1"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="selectedB._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="selectedB[quizPrefix + '_notes']" fieldKey="s1"></notes>
                    </div>
                    <!-- <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s1"></notes>
                    </div> -->
                </div>                
            </ng-container>


            <div class="row">
                <div class="w40 buttons-row" *ngIf="selectedA">
                    <button class="plus-button" mat-raised-button (click)="deleteQuiz(selectedIdA)">
                        {{'general.deleteQuiz' | translate}}
                        <mat-icon>delete</mat-icon>
                    </button>
                    <!-- <button [routerLink]="['/Sleepingquiz/', selectedIdA]" class="plus-button" mat-raised-button>עבור לטופס</button> -->
                    <a target="quizwindow" [routerLink]="['/Sleepingquiz/', selectedIdA]" class="plus-button" mat-raised-button>עבור לטופס</a>
                    <mat-slide-toggle [checked]="notesFormA.value.slq_locked" (change)="toggleQuizLock($event, notesFormA)">{{'general.lockQuiz' | translate}}</mat-slide-toggle>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">
                </div> -->
                <div class="w40 buttons-row" *ngIf="selectedB">
                    <button class="plus-button" mat-raised-button (click)="deleteQuiz(selectedIdB)">
                        {{'general.deleteQuiz' | translate}}
                        <mat-icon>delete</mat-icon>
                    </button>
                    <!-- <button [routerLink]="['/Sleepingquiz/', selectedIdB]" class="plus-button" mat-raised-button>עבור לטופס</button> -->
                    <a target="quizwindow" [routerLink]="['/Sleepingquiz/', selectedIdB]" class="plus-button" mat-raised-button>עבור לטופס</a>
                    <mat-slide-toggle [checked]="notesFormB.value.slq_locked" (change)="toggleQuizLock($event, notesFormB)">{{'general.lockQuiz' | translate}}</mat-slide-toggle>
                </div>
            </div>

            <!-- <div class="mt125 text-center">
                <button mat-raised-button color="primary" class="w30" (click)="submitForm()">שמור הערות</button>
            </div> -->
        </div>
    </mat-expansion-panel>

</div>
