import { Component, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';  // Import HttpClient

import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { appState } from '@services/app-state';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';

@Component({
  templateUrl: "./userparent.component.html"
})
export class UserparentComponent {

  @ViewChild("popupTemplate") popupTemplate: TemplateRef<any>;

  appState = appState;

  userChildren: any;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private translate: TranslateService,
    public restApiService: RestApiService<any>,
    public router: Router,
    private http: HttpClient  // Inject HttpClient
  ) { }

  async ngOnInit() {
    const user: any = this.appState.user;
    if (user.usr_type !== 'parent') {
      console.log('user is not a parent');
      this.router.navigate([`/`]);
      return;
    }
    await this.fetchAllChildren();


    // this.viewReport(this.userChildren[0].usr_reports[0]._id);
  }

  async fetchAllChildren() {
    const res = await this.restApiService.service('users').find({
      query: {
        // $paginate: false,
        $sort: {
          usr_birth_date: -1
        },
        $extradetails: true,
      },
    });
    let childArr = res.data;
    for (let child of childArr) {
      child.usr_quizzes = this.filterQuizzes(child);
      child.usr_reports = child.user_reports || [];
    }
    this.userChildren = childArr;
  }

  filterQuizzes(user: any) {
    const { _id, measure_enabled, last_quizzes, stateUser, usr_state } = user;
    // const state = stateUser || usr_state?.stateUser;
    const state = last_quizzes;
    if (!state) { return []; };

    let quizzes = [
      { route: 'Healthquiz', title: 'שאלון בריאות', _id: state.heq?._id },
      { route: 'Practicequiz', title: 'שאלון אימונים', _id: state.prq?._id },
      { route: 'Sleepingquiz', title: 'שאלון שינה', _id: state.slq?._id },
      { route: 'Nutritionquiz', title: 'שאלון תזונה', _id: state.nuq?._id },
      { route: 'Bloodtestquiz', title: 'שאלון בדיקות דם', _id: state.btq?._id },
      { route: 'Fooddiaryquiz', title: 'שאלון יומן אכילה ולוז', _id: _id },
    ];
    if (measure_enabled) {
      quizzes.push({ route: 'MeasurementView', title: 'מדידות', _id: _id });
    }
    return quizzes;
  }

  viewReport(reportId: string) {

    reportId = reportId.split('?')[0];

    const webhookUrl = 'https://hook.eu1.make.com/exnugh6bvkfspv14hdo5o5rio8xftkdd';
    const reportData = {
      reportId,
      userId: this.appState.user._id,
      // timestamp: new Date().toISOString(),
    };

    this.http.post(webhookUrl, reportData).subscribe({
      next: (response) => {
        console.log('Webhook sent successfully', response);
        this.router.navigate(['/Userreport/', reportId]);
      },
      error: (error) => {
        console.error('Error sending webhook', error);
      }
    });
    this.router.navigate(['/Userreport/', reportId]);

  }

  async popupLauncher() {
    const allPopups = await this.restApiService.service('msg-log').find({});

    if (allPopups.length) {
      console.log('allPopups', allPopups);
      await this.newPopupByIndex(allPopups, 0);
    }
  }

  async newPopupByIndex(allPopups: any[], idx: number) {
    if (allPopups[idx]) {
      const isFemale = this.appState.user?.usr_gender === 'נקבה';
      const { _id, title, body, sent_at, is_response, response_button_yes, response_button_yes_F, response_button_no, response_button_no_F } = allPopups[idx];
      let ok = this.translate.instant('general.ok');
      let cancel = this.translate.instant('general.cancel');
      // if (is_response) {
      //   if (isFemale) {
      //     ok = response_button_yes_F || response_button_yes;
      //     cancel = response_button_no_F || response_button_no;
      //   } else {
      //     ok = response_button_yes;
      //     cancel = response_button_no;
      //   }
      // }

      const regex = /\*(.*?)\*/g;
      const boldBody = (body || '').replace(regex, '<b>$1</b>');

      let newFormGroup: FormGroup = this.formBuilder.group({
        sent_at: [sent_at],
        // title: [title],
        body: [boldBody],
        isFemale: [isFemale],
      });

      const dialogRef = this.dialog.open(CustomDialogComponent, {
        // minWidth: '35%',
        disableClose: true,
        data: {
          // title: title || '',
          // subtitle: body || '',
          title: '',
          subtitle: '',
          ok,
          cancel,
          form: newFormGroup,
          template: this.popupTemplate
          // extraButton: { value: 'custom', text: '' },
        }
      });

      dialogRef.afterClosed().subscribe(async result => {
        let data: any = {};
        if (!result) {
          data.user_response_no = true;
          // } else if (result === 'custom') {
          //   data.user_response_custom = {  };
        } else {
          data.user_response_yes = true;
        }

        const res = await this.restApiService.service('msg-log').patch(_id, data).catch((err: any) => { console.error(err); });
        if (res) {
          console.log('popup response sent ', res._id, res.user_response_at);
        }

        await this.newPopupByIndex(allPopups, idx + 1);
      });
    }
  }
}
