import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DomSanitizer, SafeUrl, SafeResourceUrl} from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from '@environments/environment';

import { TranslateService } from '@ngx-translate/core';

import { appState } from '@services/app-state';

import { ZCreditService, ZCreditPaymentResponse } from '@services/z-credit.service';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';

@Component({
  selector: 'app-plans',
  templateUrl: './plans.component.html',
  styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: false,
    // center: true,
    mouseDrag: false,
    rtl: true,
    nav: false,
    dots: true,
    stagePadding: 50,
    margin: 40,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 3
      },
      940: {
        items:3,
        stagePadding: 0,
      }
    },
  };

  pricingPackages = [];

  appState = appState;

  public paymentUrl: SafeResourceUrl;

  userDetails: any;

  // testPlan = false;

  generatingPaymentUrl = false

  constructor(
    private translate: TranslateService,
    private restApiService: RestApiService<any>,
    private zCreditService: ZCreditService,
    private dialogService: ConfirmDialogService,
    private sanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private router: Router,
    ) { }

  async ngOnInit() {
    this.generatingPaymentUrl = false;
    this.pricingPackages = this.createPackages(this.appState.user['has_plan']);

    this.userDetails = {
      userId: this.appState.user._id,
      firstName: this.appState.user.usr_fname,
      lastName: this.appState.user.usr_lname,
      mobile: this.appState.user.usr_phone,
      email: this.appState.user.usr_email,
    };
  }

  createPackages(has_plan?) {
    let pricingPackages = [
      {
        type: 'all star',
        plan: 'STAR',
        price: `800₪ לשנה`,
        measures: 'נתוני גובה ומשקל- הזנה עצמית',
        questionnaire: 'שאלונים',
        eatingDiary: 'יומן אכילה',
        growthReport: 'דו"ח עקומות גדילה',
        bloodTests: 'בדיקות דם',
        sleepReport: 'דו״ח שינה',
        sleepReportAmount:'3',
        practiceSchedule: 'דו״ח לו״ז אימונים',
        practiceScheduleAmount:'3',
        bloodTestsReport: 'דו"ח בדיקות דם',
        bloodTestsReportAmount:'1',
        nutritionReport: 'דו״ח תזונה',
        nutritionReportAmount: '3',
        notifications:'התראות תזכורות לביצוע הערות מהדו״ח',
        reportsSupervision:'מעקב דוחות ע״י המועדון',
        practiceFeeding:'הזנת אימונים',
        practiceSuperVision:'מעקב אימונים ע״י המועדון'
      },
      {
        type: 'pro',
        plan: 'PRO',
        price: '400₪',
        measures: 'נתוני גובה ומשקל- הזנה עצמית',
        questionnaire: 'שאלונים',
        eatingDiary: 'יומן אכילה',
        growthReport: 'דו"ח עקומות גדילה',
        bloodTests: 'בדיקות דם',
        sleepReport: 'דו״ח שינה',
        sleepReportAmount:'1',
        practiceSchedule: 'דו״ח לו״ז אימונים',
        practiceScheduleAmount:'1',
        bloodTestsReport: 'דו"ח בדיקות דם',
        bloodTestsReportAmount:'1',
        nutritionReport: 'דו״ח תזונה',
        nutritionReportAmount: '1',
        notifications:'התראות תזכורות לביצוע הערות מהדו״ח',
        reportsSupervision:'מעקב דוחות ע״י המועדון',
        practiceFeeding:'הזנת אימונים',
        practiceSuperVision:'מעקב אימונים ע״י המועדון'
      }
    ];
    if (!has_plan) {
      pricingPackages.push(
        {
          type: 'rookie',
          plan: 'F',
          price: 'חינם',
          measures: 'נתוני גובה ומשקל- הזנה עצמית',
          questionnaire: 'שאלונים',
          eatingDiary: 'יומן אכילה',
          growthReport: 'דו"ח עקומות גדילה',
          bloodTests: 'בדיקות דם',
          sleepReport: 'דו״ח שינה',
          sleepReportAmount:'0',
          practiceSchedule: 'דו״ח לו״ז אימונים',
          practiceScheduleAmount:'0',
          bloodTestsReport: 'דו"ח בדיקות דם',
          bloodTestsReportAmount:'0',
          nutritionReport: 'דו״ח תזונה',
          nutritionReportAmount: '0',
          notifications:'התראות תזכורות לביצוע הערות מהדו״ח',
          reportsSupervision:'מעקב דוחות ע״י המועדון',
          practiceFeeding:'הזנת אימונים',
          practiceSuperVision:'מעקב אימונים ע״י המועדון'
        }
      );
    }
    return pricingPackages;
  }

  async generatePaymentUrl(product: any, user: any) {
    this.generatingPaymentUrl = true;
    const paymentPlan = product['plan'];
    const paymentDesc = product['description'];
    // const paymentSum = this.testPlan ? 1 : product['price'];

    const order = await this.restApiService.service('order').create({
      // status: 'created',
      // userId: user.userId,
      paymentPlan: paymentPlan,
      // price: paymentSum
    }).catch(e => { console.error(e); });

    if (!(order && order._id && order.price)) {
      this.snackBar.open(this.translate.instant('plans.ERROR'), null , {
        duration: 5000
      });
      return;
    }

    console.log(`Order created: ${order._id}`);

    const paymentResponse: ZCreditPaymentResponse = await this.zCreditService.create({
      name: user.firstName + ' ' + user.lastName,
      email: user.email,
      mobile: user.mobile,
      paymentSum: order.price,
      paymentCount: 1,
      uniquId: `aap-${order._id}`,
      description: paymentDesc,
      quantity: 1,
      hostUri: environment.apiUrl,
      redirectUri: `${environment.apiUrl}/z-credit-success?origin=${window.location.origin}`,
      cancelUri: `${environment.apiUrl}/z-credit-cancel?origin=${window.location.origin}`
    });
    this.generatingPaymentUrl = false;
    this.paymentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(paymentResponse.url);
  }

  @HostListener('window:message', ['$event']) onPostMessage(event) {
      if (event.data === 'payment-response-canceled') {
        this.paymentUrl = undefined;
        this.snackBar.open(this.translate.instant('plans.CANCELED'), null , {
          duration: 3000
        });
      }
      if (event.data === 'payment-response-received') {
        this.paymentUrl = undefined;
        this.openOrderReceived();
      }
  }

  selectPlan(plan: string) {
    if (plan === 'PRO') {
      const chosen = {
        plan: 'PRO',
        type: 'pro',
        description: this.translate.instant('plans.PLAN_PRO'),
        // price: 270,
      };
      this.generatePaymentUrl(chosen, this.userDetails);
    }
    if (plan === 'STAR') {
      const chosen = {
        plan: 'STAR',
        type: 'all star',
        description: this.translate.instant('plans.PLAN_STAR'),
        // price: 540,
      };
      this.generatePaymentUrl(chosen, this.userDetails);
    }
  }

  async openOrderReceived() {
    const res = await this.restApiService.service('users').get(this.appState.user._id);
    this.appState.user = res;
    const options = {
      title: this.translate.instant('plans.RECEIVED_TITLE'),
      message: this.translate.instant('plans.RECEIVED_MESSAGE'),
      cancelText: this.translate.instant('general.cancel'),
      confirmText: this.translate.instant('general.ok')
    };

    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
        if (confirmed) {
          this.router.navigate([`/Profile/${this.appState.user._id}`]);
        }
    });
  }

}
