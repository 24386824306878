import { Injectable } from '@angular/core';
import { BaseService, PaginationResponse } from './base-service';
import { VznFeathersService } from '@noammazuz/vzn-feathers';

export interface ZCreditPaymentOptions {
  name: string;
  email: string;
  mobile: string;
  paymentSum: number;
  paymentCount: number;
  uniquId: string;
  description: string;
  quantity: number;
  hostUri: string;
  redirectUri: string;
  cancelUri: string;
}

export interface ZCreditPaymentResponse {
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class ZCreditService extends BaseService {

  constructor(
    feathersService: VznFeathersService<any>
  ) {
    super(feathersService, 'z-credit');
  }

  create(data: ZCreditPaymentOptions): Promise<ZCreditPaymentResponse> {
    return this.pRest.create(data);
  }
}
