export module lmsCharts {

export let statage = {
    "M": [
        {
        "Agemos": 24.5,
        "L": 1.00720807,
        "M": 86.86160934,
        "S": 0.040395626
        },
        {
        "Agemos": 25.5,
        "L": 0.837251351,
        "M": 87.65247282,
        "S": 0.040577525
        },
        {
        "Agemos": 26.5,
        "L": 0.681492975,
        "M": 88.42326434,
        "S": 0.040723122
        },
        {
        "Agemos": 27.5,
        "L": 0.538779654,
        "M": 89.17549228,
        "S": 0.040833194
        },
        {
        "Agemos": 28.5,
        "L": 0.407697153,
        "M": 89.91040853,
        "S": 0.040909059
        },
        {
        "Agemos": 29.5,
        "L": 0.286762453,
        "M": 90.62907762,
        "S": 0.040952433
        },
        {
        "Agemos": 30.5,
        "L": 0.174489485,
        "M": 91.33242379,
        "S": 0.04096533
        },
        {
        "Agemos": 31.5,
        "L": 0.069444521,
        "M": 92.02127167,
        "S": 0.040949976
        },
        {
        "Agemos": 32.5,
        "L": -0.029720564,
        "M": 92.69637946,
        "S": 0.040908737
        },
        {
        "Agemos": 33.5,
        "L": -0.124251789,
        "M": 93.35846546,
        "S": 0.040844062
        },
        {
        "Agemos": 34.5,
        "L": -0.215288396,
        "M": 94.00822923,
        "S": 0.040758431
        },
        {
        "Agemos": 35.5,
        "L": -0.30385434,
        "M": 94.64636981,
        "S": 0.040654312
        },
        {
        "Agemos": 36.5,
        "L": -0.390918369,
        "M": 95.27359106,
        "S": 0.04053412
        },
        {
        "Agemos": 37.5,
        "L": -0.254801167,
        "M": 95.91474929,
        "S": 0.040572876
        },
        {
        "Agemos": 38.5,
        "L": -0.125654535,
        "M": 96.54734328,
        "S": 0.04061691
        },
        {
        "Agemos": 39.5,
        "L": -0.00316735,
        "M": 97.17191309,
        "S": 0.040666414
        },
        {
        "Agemos": 40.5,
        "L": 0.11291221,
        "M": 97.78897727,
        "S": 0.040721467
        },
        {
        "Agemos": 41.5,
        "L": 0.222754969,
        "M": 98.3990283,
        "S": 0.040782045
        },
        {
        "Agemos": 42.5,
        "L": 0.326530126,
        "M": 99.00254338,
        "S": 0.040848042
        },
        {
        "Agemos": 43.5,
        "L": 0.42436156,
        "M": 99.599977,
        "S": 0.040919281
        },
        {
        "Agemos": 44.5,
        "L": 0.516353108,
        "M": 100.191764,
        "S": 0.040995524
        },
        {
        "Agemos": 45.5,
        "L": 0.602595306,
        "M": 100.7783198,
        "S": 0.041076485
        },
        {
        "Agemos": 46.5,
        "L": 0.683170764,
        "M": 101.3600411,
        "S": 0.041161838
        },
        {
        "Agemos": 47.5,
        "L": 0.758158406,
        "M": 101.9373058,
        "S": 0.041251224
        },
        {
        "Agemos": 48.5,
        "L": 0.827636736,
        "M": 102.5104735,
        "S": 0.041344257
        },
        {
        "Agemos": 49.5,
        "L": 0.891686306,
        "M": 103.0798852,
        "S": 0.041440534
        },
        {
        "Agemos": 50.5,
        "L": 0.95039153,
        "M": 103.645864,
        "S": 0.041539635
        },
        {
        "Agemos": 51.5,
        "L": 1.003830006,
        "M": 104.208713,
        "S": 0.041641136
        },
        {
        "Agemos": 52.5,
        "L": 1.05213569,
        "M": 104.7687256,
        "S": 0.041744602
        },
        {
        "Agemos": 53.5,
        "L": 1.0953669,
        "M": 105.3261638,
        "S": 0.041849607
        },
        {
        "Agemos": 54.5,
        "L": 1.133652119,
        "M": 105.8812823,
        "S": 0.041955723
        },
        {
        "Agemos": 55.5,
        "L": 1.167104213,
        "M": 106.4343146,
        "S": 0.042062532
        },
        {
        "Agemos": 56.5,
        "L": 1.195845353,
        "M": 106.9854769,
        "S": 0.042169628
        },
        {
        "Agemos": 57.5,
        "L": 1.220004233,
        "M": 107.534968,
        "S": 0.042276619
        },
        {
        "Agemos": 58.5,
        "L": 1.239715856,
        "M": 108.0829695,
        "S": 0.042383129
        },
        {
        "Agemos": 59.5,
        "L": 1.255121285,
        "M": 108.6296457,
        "S": 0.042488804
        },
        {
        "Agemos": 60.5,
        "L": 1.266367398,
        "M": 109.1751441,
        "S": 0.042593311
        },
        {
        "Agemos": 61.5,
        "L": 1.273606657,
        "M": 109.7195954,
        "S": 0.042696342
        },
        {
        "Agemos": 62.5,
        "L": 1.276996893,
        "M": 110.2631136,
        "S": 0.042797615
        },
        {
        "Agemos": 63.5,
        "L": 1.276701119,
        "M": 110.8057967,
        "S": 0.042896877
        },
        {
        "Agemos": 64.5,
        "L": 1.272887366,
        "M": 111.3477265,
        "S": 0.042993904
        },
        {
        "Agemos": 65.5,
        "L": 1.265728536,
        "M": 111.8889694,
        "S": 0.043088503
        },
        {
        "Agemos": 66.5,
        "L": 1.255402281,
        "M": 112.4295761,
        "S": 0.043180513
        },
        {
        "Agemos": 67.5,
        "L": 1.242090871,
        "M": 112.9695827,
        "S": 0.043269806
        },
        {
        "Agemos": 68.5,
        "L": 1.225981067,
        "M": 113.5090108,
        "S": 0.043356287
        },
        {
        "Agemos": 69.5,
        "L": 1.207263978,
        "M": 114.0478678,
        "S": 0.043439893
        },
        {
        "Agemos": 70.5,
        "L": 1.186140222,
        "M": 114.5861486,
        "S": 0.043520597
        },
        {
        "Agemos": 71.5,
        "L": 1.162796198,
        "M": 115.1238315,
        "S": 0.043598407
        },
        {
        "Agemos": 72.5,
        "L": 1.137442868,
        "M": 115.6608862,
        "S": 0.043673359
        },
        {
        "Agemos": 73.5,
        "L": 1.110286487,
        "M": 116.1972691,
        "S": 0.043745523
        },
        {
        "Agemos": 74.5,
        "L": 1.081536236,
        "M": 116.732925,
        "S": 0.043815003
        },
        {
        "Agemos": 75.5,
        "L": 1.05140374,
        "M": 117.2677879,
        "S": 0.043881929
        },
        {
        "Agemos": 76.5,
        "L": 1.020102497,
        "M": 117.8017819,
        "S": 0.043946461
        },
        {
        "Agemos": 77.5,
        "L": 0.987847213,
        "M": 118.3348215,
        "S": 0.044008785
        },
        {
        "Agemos": 78.5,
        "L": 0.954853043,
        "M": 118.8668123,
        "S": 0.044069112
        },
        {
        "Agemos": 79.5,
        "L": 0.921334742,
        "M": 119.397652,
        "S": 0.044127675
        },
        {
        "Agemos": 80.5,
        "L": 0.887505723,
        "M": 119.9272309,
        "S": 0.044184725
        },
        {
        "Agemos": 81.5,
        "L": 0.85357703,
        "M": 120.455433,
        "S": 0.044240532
        },
        {
        "Agemos": 82.5,
        "L": 0.819756239,
        "M": 120.9821362,
        "S": 0.044295379
        },
        {
        "Agemos": 83.5,
        "L": 0.786246296,
        "M": 121.5072136,
        "S": 0.044349559
        },
        {
        "Agemos": 84.5,
        "L": 0.753244292,
        "M": 122.0305342,
        "S": 0.044403374
        },
        {
        "Agemos": 85.5,
        "L": 0.720940222,
        "M": 122.5519634,
        "S": 0.04445713
        },
        {
        "Agemos": 86.5,
        "L": 0.689515708,
        "M": 123.0713645,
        "S": 0.044511135
        },
        {
        "Agemos": 87.5,
        "L": 0.659142731,
        "M": 123.588599,
        "S": 0.044565693
        },
        {
        "Agemos": 88.5,
        "L": 0.629997853,
        "M": 124.1035312,
        "S": 0.044621104
        },
        {
        "Agemos": 89.5,
        "L": 0.602203984,
        "M": 124.6160161,
        "S": 0.044677662
        },
        {
        "Agemos": 90.5,
        "L": 0.575908038,
        "M": 125.1259182,
        "S": 0.044735646
        },
        {
        "Agemos": 91.5,
        "L": 0.55123134,
        "M": 125.6331012,
        "S": 0.044795322
        },
        {
        "Agemos": 92.5,
        "L": 0.528279901,
        "M": 126.1374319,
        "S": 0.044856941
        },
        {
        "Agemos": 93.5,
        "L": 0.507143576,
        "M": 126.6387804,
        "S": 0.04492073
        },
        {
        "Agemos": 94.5,
        "L": 0.487895344,
        "M": 127.1370217,
        "S": 0.044986899
        },
        {
        "Agemos": 95.5,
        "L": 0.470590753,
        "M": 127.6320362,
        "S": 0.045055632
        },
        {
        "Agemos": 96.5,
        "L": 0.455267507,
        "M": 128.1237104,
        "S": 0.045127088
        },
        {
        "Agemos": 97.5,
        "L": 0.441945241,
        "M": 128.6119383,
        "S": 0.045201399
        },
        {
        "Agemos": 98.5,
        "L": 0.430625458,
        "M": 129.096622,
        "S": 0.045278671
        },
        {
        "Agemos": 99.5,
        "L": 0.421291648,
        "M": 129.5776723,
        "S": 0.045358979
        },
        {
        "Agemos": 100.5,
        "L": 0.413909588,
        "M": 130.0550101,
        "S": 0.045442372
        },
        {
        "Agemos": 101.5,
        "L": 0.408427813,
        "M": 130.5285669,
        "S": 0.045528869
        },
        {
        "Agemos": 102.5,
        "L": 0.404778262,
        "M": 130.9982857,
        "S": 0.045618459
        },
        {
        "Agemos": 103.5,
        "L": 0.402877077,
        "M": 131.4641218,
        "S": 0.045711105
        },
        {
        "Agemos": 104.5,
        "L": 0.402625561,
        "M": 131.9260439,
        "S": 0.045806742
        },
        {
        "Agemos": 105.5,
        "L": 0.40391127,
        "M": 132.3840348,
        "S": 0.045905281
        },
        {
        "Agemos": 106.5,
        "L": 0.406609232,
        "M": 132.838092,
        "S": 0.046006604
        },
        {
        "Agemos": 107.5,
        "L": 0.410583274,
        "M": 133.2882291,
        "S": 0.046110573
        },
        {
        "Agemos": 108.5,
        "L": 0.415687443,
        "M": 133.7344759,
        "S": 0.046217028
        },
        {
        "Agemos": 109.5,
        "L": 0.421767514,
        "M": 134.1768801,
        "S": 0.04632579
        },
        {
        "Agemos": 110.5,
        "L": 0.428662551,
        "M": 134.6155076,
        "S": 0.046436662
        },
        {
        "Agemos": 111.5,
        "L": 0.436206531,
        "M": 135.0504433,
        "S": 0.04654943
        },
        {
        "Agemos": 112.5,
        "L": 0.44423,
        "M": 135.4817925,
        "S": 0.046663871
        },
        {
        "Agemos": 113.5,
        "L": 0.45256176,
        "M": 135.9096813,
        "S": 0.046779748
        },
        {
        "Agemos": 114.5,
        "L": 0.461030578,
        "M": 136.3342577,
        "S": 0.046896817
        },
        {
        "Agemos": 115.5,
        "L": 0.469466904,
        "M": 136.7556923,
        "S": 0.047014827
        },
        {
        "Agemos": 116.5,
        "L": 0.477704608,
        "M": 137.1741794,
        "S": 0.047133525
        },
        {
        "Agemos": 117.5,
        "L": 0.48558272,
        "M": 137.5899378,
        "S": 0.047252654
        },
        {
        "Agemos": 118.5,
        "L": 0.492947182,
        "M": 138.0032114,
        "S": 0.047371961
        },
        {
        "Agemos": 119.5,
        "L": 0.499652617,
        "M": 138.4142703,
        "S": 0.047491194
        },
        {
        "Agemos": 120.5,
        "L": 0.505564115,
        "M": 138.8234114,
        "S": 0.047610108
        },
        {
        "Agemos": 121.5,
        "L": 0.510559047,
        "M": 139.2309592,
        "S": 0.047728463
        },
        {
        "Agemos": 122.5,
        "L": 0.514528903,
        "M": 139.6372663,
        "S": 0.04784603
        },
        {
        "Agemos": 123.5,
        "L": 0.517381177,
        "M": 140.042714,
        "S": 0.047962592
        },
        {
        "Agemos": 124.5,
        "L": 0.519041285,
        "M": 140.4477127,
        "S": 0.048077942
        },
        {
        "Agemos": 125.5,
        "L": 0.519454524,
        "M": 140.8527022,
        "S": 0.048191889
        },
        {
        "Agemos": 126.5,
        "L": 0.518588072,
        "M": 141.2581515,
        "S": 0.048304259
        },
        {
        "Agemos": 127.5,
        "L": 0.516433004,
        "M": 141.6645592,
        "S": 0.048414893
        },
        {
        "Agemos": 128.5,
        "L": 0.513006312,
        "M": 142.072452,
        "S": 0.048523648
        },
        {
        "Agemos": 129.5,
        "L": 0.508352901,
        "M": 142.4823852,
        "S": 0.048630402
        },
        {
        "Agemos": 130.5,
        "L": 0.502547502,
        "M": 142.8949403,
        "S": 0.04873505
        },
        {
        "Agemos": 131.5,
        "L": 0.495696454,
        "M": 143.3107241,
        "S": 0.048837504
        },
        {
        "Agemos": 132.5,
        "L": 0.487939275,
        "M": 143.7303663,
        "S": 0.048937694
        },
        {
        "Agemos": 133.5,
        "L": 0.479449924,
        "M": 144.1545167,
        "S": 0.049035564
        },
        {
        "Agemos": 134.5,
        "L": 0.470437652,
        "M": 144.5838414,
        "S": 0.049131073
        },
        {
        "Agemos": 135.5,
        "L": 0.461147305,
        "M": 145.0190192,
        "S": 0.049224189
        },
        {
        "Agemos": 136.5,
        "L": 0.451858946,
        "M": 145.4607359,
        "S": 0.049314887
        },
        {
        "Agemos": 137.5,
        "L": 0.442886661,
        "M": 145.9096784,
        "S": 0.049403145
        },
        {
        "Agemos": 138.5,
        "L": 0.434576385,
        "M": 146.3665278,
        "S": 0.049488934
        },
        {
        "Agemos": 139.5,
        "L": 0.427302633,
        "M": 146.8319513,
        "S": 0.049572216
        },
        {
        "Agemos": 140.5,
        "L": 0.421464027,
        "M": 147.3065929,
        "S": 0.049652935
        },
        {
        "Agemos": 141.5,
        "L": 0.417477538,
        "M": 147.7910635,
        "S": 0.049731004
        },
        {
        "Agemos": 142.5,
        "L": 0.415771438,
        "M": 148.2859294,
        "S": 0.0498063
        },
        {
        "Agemos": 143.5,
        "L": 0.416777012,
        "M": 148.7917006,
        "S": 0.04987865
        },
        {
        "Agemos": 144.5,
        "L": 0.420919142,
        "M": 149.3088178,
        "S": 0.049947823
        },
        {
        "Agemos": 145.5,
        "L": 0.428606007,
        "M": 149.8376391,
        "S": 0.050013518
        },
        {
        "Agemos": 146.5,
        "L": 0.440218167,
        "M": 150.3784267,
        "S": 0.050075353
        },
        {
        "Agemos": 147.5,
        "L": 0.456097443,
        "M": 150.9313331,
        "S": 0.050132858
        },
        {
        "Agemos": 148.5,
        "L": 0.476536014,
        "M": 151.4963887,
        "S": 0.050185471
        },
        {
        "Agemos": 149.5,
        "L": 0.501766234,
        "M": 152.0734897,
        "S": 0.050232532
        },
        {
        "Agemos": 150.5,
        "L": 0.531951655,
        "M": 152.6623878,
        "S": 0.050273285
        },
        {
        "Agemos": 151.5,
        "L": 0.567179725,
        "M": 153.2626819,
        "S": 0.050306885
        },
        {
        "Agemos": 152.5,
        "L": 0.607456565,
        "M": 153.8738124,
        "S": 0.050332406
        },
        {
        "Agemos": 153.5,
        "L": 0.652704121,
        "M": 154.495058,
        "S": 0.05034886
        },
        {
        "Agemos": 154.5,
        "L": 0.702759868,
        "M": 155.1255365,
        "S": 0.050355216
        },
        {
        "Agemos": 155.5,
        "L": 0.757379106,
        "M": 155.7642086,
        "S": 0.050350423
        },
        {
        "Agemos": 156.5,
        "L": 0.816239713,
        "M": 156.4098858,
        "S": 0.050333444
        },
        {
        "Agemos": 157.5,
        "L": 0.878947416,
        "M": 157.0612415,
        "S": 0.050303283
        },
        {
        "Agemos": 158.5,
        "L": 0.945053486,
        "M": 157.7168289,
        "S": 0.050259018
        },
        {
        "Agemos": 159.5,
        "L": 1.014046108,
        "M": 158.3750929,
        "S": 0.050199837
        },
        {
        "Agemos": 160.5,
        "L": 1.085383319,
        "M": 159.034399,
        "S": 0.050125062
        },
        {
        "Agemos": 161.5,
        "L": 1.158487278,
        "M": 159.6930501,
        "S": 0.05003418
        },
        {
        "Agemos": 162.5,
        "L": 1.232768816,
        "M": 160.3493168,
        "S": 0.049926861
        },
        {
        "Agemos": 163.5,
        "L": 1.307628899,
        "M": 161.0014586,
        "S": 0.049802977
        },
        {
        "Agemos": 164.5,
        "L": 1.382473225,
        "M": 161.6477515,
        "S": 0.04966261
        },
        {
        "Agemos": 165.5,
        "L": 1.456720479,
        "M": 162.2865119,
        "S": 0.049506051
        },
        {
        "Agemos": 166.5,
        "L": 1.529810247,
        "M": 162.9161202,
        "S": 0.049333801
        },
        {
        "Agemos": 167.5,
        "L": 1.601219573,
        "M": 163.535045,
        "S": 0.049146553
        },
        {
        "Agemos": 168.5,
        "L": 1.670433444,
        "M": 164.1418486,
        "S": 0.04894519
        },
        {
        "Agemos": 169.5,
        "L": 1.736995571,
        "M": 164.7352199,
        "S": 0.048730749
        },
        {
        "Agemos": 170.5,
        "L": 1.800483802,
        "M": 165.3139755,
        "S": 0.048504404
        },
        {
        "Agemos": 171.5,
        "L": 1.860518777,
        "M": 165.8770715,
        "S": 0.048267442
        },
        {
        "Agemos": 172.5,
        "L": 1.916765525,
        "M": 166.4236087,
        "S": 0.04802123
        },
        {
        "Agemos": 173.5,
        "L": 1.968934444,
        "M": 166.9528354,
        "S": 0.047767192
        },
        {
        "Agemos": 174.5,
        "L": 2.016781776,
        "M": 167.4641466,
        "S": 0.047506783
        },
        {
        "Agemos": 175.5,
        "L": 2.060109658,
        "M": 167.9570814,
        "S": 0.047241456
        },
        {
        "Agemos": 176.5,
        "L": 2.098765817,
        "M": 168.4313175,
        "S": 0.04697265
        },
        {
        "Agemos": 177.5,
        "L": 2.132642948,
        "M": 168.8866644,
        "S": 0.046701759
        },
        {
        "Agemos": 178.5,
        "L": 2.16167779,
        "M": 169.3230548,
        "S": 0.046430122
        },
        {
        "Agemos": 179.5,
        "L": 2.185849904,
        "M": 169.7405351,
        "S": 0.046159004
        },
        {
        "Agemos": 180.5,
        "L": 2.205180153,
        "M": 170.139255,
        "S": 0.045889585
        },
        {
        "Agemos": 181.5,
        "L": 2.219728869,
        "M": 170.5194567,
        "S": 0.045622955
        },
        {
        "Agemos": 182.5,
        "L": 2.2295937,
        "M": 170.881464,
        "S": 0.045360101
        },
        {
        "Agemos": 183.5,
        "L": 2.234907144,
        "M": 171.2256717,
        "S": 0.045101913
        },
        {
        "Agemos": 184.5,
        "L": 2.235833767,
        "M": 171.5525345,
        "S": 0.044849174
        },
        {
        "Agemos": 185.5,
        "L": 2.232567138,
        "M": 171.8625576,
        "S": 0.044602566
        },
        {
        "Agemos": 186.5,
        "L": 2.2253265,
        "M": 172.1562865,
        "S": 0.044362674
        },
        {
        "Agemos": 187.5,
        "L": 2.214353232,
        "M": 172.4342983,
        "S": 0.044129985
        },
        {
        "Agemos": 188.5,
        "L": 2.199905902,
        "M": 172.6971935,
        "S": 0.043904897
        },
        {
        "Agemos": 189.5,
        "L": 2.182262864,
        "M": 172.9455898,
        "S": 0.043687723
        },
        {
        "Agemos": 190.5,
        "L": 2.161704969,
        "M": 173.180112,
        "S": 0.043478698
        },
        {
        "Agemos": 191.5,
        "L": 2.138524662,
        "M": 173.4013896,
        "S": 0.043277987
        },
        {
        "Agemos": 192.5,
        "L": 2.113023423,
        "M": 173.6100518,
        "S": 0.043085685
        },
        {
        "Agemos": 193.5,
        "L": 2.085490286,
        "M": 173.8067179,
        "S": 0.042901835
        },
        {
        "Agemos": 194.5,
        "L": 2.0562195,
        "M": 173.9919998,
        "S": 0.042726424
        },
        {
        "Agemos": 195.5,
        "L": 2.025496648,
        "M": 174.1664951,
        "S": 0.042559396
        },
        {
        "Agemos": 196.5,
        "L": 1.993598182,
        "M": 174.3307855,
        "S": 0.042400652
        },
        {
        "Agemos": 197.5,
        "L": 1.960789092,
        "M": 174.4854344,
        "S": 0.042250063
        },
        {
        "Agemos": 198.5,
        "L": 1.927320937,
        "M": 174.6309856,
        "S": 0.042107465
        },
        {
        "Agemos": 199.5,
        "L": 1.89343024,
        "M": 174.7679617,
        "S": 0.041972676
        },
        {
        "Agemos": 200.5,
        "L": 1.859337259,
        "M": 174.8968634,
        "S": 0.041845488
        },
        {
        "Agemos": 201.5,
        "L": 1.825245107,
        "M": 175.0181691,
        "S": 0.041725679
        },
        {
        "Agemos": 202.5,
        "L": 1.791339209,
        "M": 175.1323345,
        "S": 0.041613015
        },
        {
        "Agemos": 203.5,
        "L": 1.757787065,
        "M": 175.2397926,
        "S": 0.041507249
        },
        {
        "Agemos": 204.5,
        "L": 1.724738292,
        "M": 175.340954,
        "S": 0.041408129
        },
        {
        "Agemos": 205.5,
        "L": 1.692324905,
        "M": 175.4362071,
        "S": 0.041315398
        },
        {
        "Agemos": 206.5,
        "L": 1.660661815,
        "M": 175.5259191,
        "S": 0.041228796
        },
        {
        "Agemos": 207.5,
        "L": 1.629847495,
        "M": 175.6104358,
        "S": 0.04114806
        },
        {
        "Agemos": 208.5,
        "L": 1.599964788,
        "M": 175.690083,
        "S": 0.041072931
        },
        {
        "Agemos": 209.5,
        "L": 1.571081817,
        "M": 175.7651671,
        "S": 0.04100315
        },
        {
        "Agemos": 210.5,
        "L": 1.543252982,
        "M": 175.8359757,
        "S": 0.040938463
        },
        {
        "Agemos": 211.5,
        "L": 1.516519998,
        "M": 175.9027788,
        "S": 0.040878617
        },
        {
        "Agemos": 212.5,
        "L": 1.490912963,
        "M": 175.9658293,
        "S": 0.040823368
        },
        {
        "Agemos": 213.5,
        "L": 1.466451429,
        "M": 176.0253641,
        "S": 0.040772475
        },
        {
        "Agemos": 214.5,
        "L": 1.44314546,
        "M": 176.081605,
        "S": 0.040725706
        },
        {
        "Agemos": 215.5,
        "L": 1.420996665,
        "M": 176.1347593,
        "S": 0.040682834
        },
        {
        "Agemos": 216.5,
        "L": 1.399999187,
        "M": 176.1850208,
        "S": 0.04064364
        },
        {
        "Agemos": 217.5,
        "L": 1.380140651,
        "M": 176.2325707,
        "S": 0.040607913
        },
        {
        "Agemos": 218.5,
        "L": 1.361403047,
        "M": 176.2775781,
        "S": 0.040575448
        },
        {
        "Agemos": 219.5,
        "L": 1.343763564,
        "M": 176.3202008,
        "S": 0.040546051
        },
        {
        "Agemos": 220.5,
        "L": 1.327195355,
        "M": 176.3605864,
        "S": 0.040519532
        },
        {
        "Agemos": 221.5,
        "L": 1.311668242,
        "M": 176.3988725,
        "S": 0.040495713
        },
        {
        "Agemos": 222.5,
        "L": 1.297149359,
        "M": 176.4351874,
        "S": 0.040474421
        },
        {
        "Agemos": 223.5,
        "L": 1.283603728,
        "M": 176.469651,
        "S": 0.040455493
        },
        {
        "Agemos": 224.5,
        "L": 1.270994782,
        "M": 176.5023751,
        "S": 0.040438773
        },
        {
        "Agemos": 225.5,
        "L": 1.25928483,
        "M": 176.533464,
        "S": 0.040424111
        },
        {
        "Agemos": 226.5,
        "L": 1.248435461,
        "M": 176.5630153,
        "S": 0.040411366
        },
        {
        "Agemos": 227.5,
        "L": 1.23840791,
        "M": 176.5911197,
        "S": 0.040400405
        },
        {
        "Agemos": 228.5,
        "L": 1.229163362,
        "M": 176.6178621,
        "S": 0.040391101
        },
        {
        "Agemos": 229.5,
        "L": 1.220663228,
        "M": 176.6433219,
        "S": 0.040383334
        },
        {
        "Agemos": 230.5,
        "L": 1.212869374,
        "M": 176.6675729,
        "S": 0.04037699
        },
        {
        "Agemos": 231.5,
        "L": 1.20574431,
        "M": 176.6906844,
        "S": 0.040371962
        },
        {
        "Agemos": 232.5,
        "L": 1.199251356,
        "M": 176.712721,
        "S": 0.040368149
        },
        {
        "Agemos": 233.5,
        "L": 1.19335477,
        "M": 176.733743,
        "S": 0.040365456
        },
        {
        "Agemos": 234.5,
        "L": 1.188019859,
        "M": 176.753807,
        "S": 0.040363795
        },
        {
        "Agemos": 235.5,
        "L": 1.183213059,
        "M": 176.7729657,
        "S": 0.04036308
        },
        {
        "Agemos": 236.5,
        "L": 1.178901998,
        "M": 176.7912687,
        "S": 0.040363233
        },
        {
        "Agemos": 237.5,
        "L": 1.175055543,
        "M": 176.8087622,
        "S": 0.040364179
        },
        {
        "Agemos": 238.5,
        "L": 1.171643828,
        "M": 176.8254895,
        "S": 0.04036585
        },
        {
        "Agemos": 239.5,
        "L": 1.16863827,
        "M": 176.8414914,
        "S": 0.04036818
        },
        {
        "Agemos": 240,
        "L": 1.167279219,
        "M": 176.8492322,
        "S": 0.040369574
        }
    ],
    "F": [
        {
        "Agemos": 24.5,
        "L": 1.051272912,
        "M": 85.3973169,
        "S": 0.040859727
        },
        {
        "Agemos": 25.5,
        "L": 1.041951175,
        "M": 86.29026318,
        "S": 0.041142161
        },
        {
        "Agemos": 26.5,
        "L": 1.012592236,
        "M": 87.15714182,
        "S": 0.041349399
        },
        {
        "Agemos": 27.5,
        "L": 0.970541909,
        "M": 87.9960184,
        "S": 0.041500428
        },
        {
        "Agemos": 28.5,
        "L": 0.921129988,
        "M": 88.8055115,
        "S": 0.041610508
        },
        {
        "Agemos": 29.5,
        "L": 0.868221392,
        "M": 89.58476689,
        "S": 0.041691761
        },
        {
        "Agemos": 30.5,
        "L": 0.81454413,
        "M": 90.33341722,
        "S": 0.04175368
        },
        {
        "Agemos": 31.5,
        "L": 0.761957977,
        "M": 91.0515436,
        "S": 0.041803562
        },
        {
        "Agemos": 32.5,
        "L": 0.711660228,
        "M": 91.7396352,
        "S": 0.041846882
        },
        {
        "Agemos": 33.5,
        "L": 0.664323379,
        "M": 92.39854429,
        "S": 0.041887626
        },
        {
        "Agemos": 34.5,
        "L": 0.620285102,
        "M": 93.02945392,
        "S": 0.041928568
        },
        {
        "Agemos": 35.5,
        "L": 0.57955631,
        "M": 93.63382278,
        "S": 0.041971514
        },
        {
        "Agemos": 36.5,
        "L": 0.54198094,
        "M": 94.21335709,
        "S": 0.042017509
        },
        {
        "Agemos": 37.5,
        "L": 0.511429832,
        "M": 94.79643239,
        "S": 0.042104522
        },
        {
        "Agemos": 38.5,
        "L": 0.482799937,
        "M": 95.37391918,
        "S": 0.042199507
        },
        {
        "Agemos": 39.5,
        "L": 0.455521041,
        "M": 95.94692677,
        "S": 0.042300333
        },
        {
        "Agemos": 40.5,
        "L": 0.429150288,
        "M": 96.51644912,
        "S": 0.042405225
        },
        {
        "Agemos": 41.5,
        "L": 0.403351725,
        "M": 97.08337211,
        "S": 0.042512706
        },
        {
        "Agemos": 42.5,
        "L": 0.377878239,
        "M": 97.6484807,
        "S": 0.042621565
        },
        {
        "Agemos": 43.5,
        "L": 0.352555862,
        "M": 98.21246579,
        "S": 0.042730809
        },
        {
        "Agemos": 44.5,
        "L": 0.327270297,
        "M": 98.77593069,
        "S": 0.042839638
        },
        {
        "Agemos": 45.5,
        "L": 0.301955463,
        "M": 99.33939735,
        "S": 0.042947412
        },
        {
        "Agemos": 46.5,
        "L": 0.276583851,
        "M": 99.9033122,
        "S": 0.043053626
        },
        {
        "Agemos": 47.5,
        "L": 0.251158446,
        "M": 100.4680516,
        "S": 0.043157889
        },
        {
        "Agemos": 48.5,
        "L": 0.225705996,
        "M": 101.033927,
        "S": 0.043259907
        },
        {
        "Agemos": 49.5,
        "L": 0.20027145,
        "M": 101.6011898,
        "S": 0.043359463
        },
        {
        "Agemos": 50.5,
        "L": 0.174913356,
        "M": 102.1700358,
        "S": 0.043456406
        },
        {
        "Agemos": 51.5,
        "L": 0.149700081,
        "M": 102.7406094,
        "S": 0.043550638
        },
        {
        "Agemos": 52.5,
        "L": 0.12470671,
        "M": 103.3130077,
        "S": 0.043642107
        },
        {
        "Agemos": 53.5,
        "L": 0.100012514,
        "M": 103.8872839,
        "S": 0.043730791
        },
        {
        "Agemos": 54.5,
        "L": 0.075698881,
        "M": 104.4634511,
        "S": 0.043816701
        },
        {
        "Agemos": 55.5,
        "L": 0.051847635,
        "M": 105.0414853,
        "S": 0.043899867
        },
        {
        "Agemos": 56.5,
        "L": 0.02853967,
        "M": 105.6213287,
        "S": 0.043980337
        },
        {
        "Agemos": 57.5,
        "L": 0.005853853,
        "M": 106.2028921,
        "S": 0.044058171
        },
        {
        "Agemos": 58.5,
        "L": -0.016133871,
        "M": 106.7860583,
        "S": 0.04413344
        },
        {
        "Agemos": 59.5,
        "L": -0.037351181,
        "M": 107.3706841,
        "S": 0.044206218
        },
        {
        "Agemos": 60.5,
        "L": -0.057729947,
        "M": 107.9566031,
        "S": 0.044276588
        },
        {
        "Agemos": 61.5,
        "L": -0.077206672,
        "M": 108.5436278,
        "S": 0.044344632
        },
        {
        "Agemos": 62.5,
        "L": -0.09572283,
        "M": 109.1315521,
        "S": 0.044410436
        },
        {
        "Agemos": 63.5,
        "L": -0.113225128,
        "M": 109.7201531,
        "S": 0.044474084
        },
        {
        "Agemos": 64.5,
        "L": -0.129665689,
        "M": 110.3091934,
        "S": 0.044535662
        },
        {
        "Agemos": 65.5,
        "L": -0.145002179,
        "M": 110.8984228,
        "S": 0.044595254
        },
        {
        "Agemos": 66.5,
        "L": -0.159197885,
        "M": 111.4875806,
        "S": 0.044652942
        },
        {
        "Agemos": 67.5,
        "L": -0.172221748,
        "M": 112.0763967,
        "S": 0.044708809
        },
        {
        "Agemos": 68.5,
        "L": -0.184048358,
        "M": 112.6645943,
        "S": 0.044762936
        },
        {
        "Agemos": 69.5,
        "L": -0.194660215,
        "M": 113.2518902,
        "S": 0.044815402
        },
        {
        "Agemos": 70.5,
        "L": -0.204030559,
        "M": 113.8380006,
        "S": 0.044866288
        },
        {
        "Agemos": 71.5,
        "L": -0.212174408,
        "M": 114.4226317,
        "S": 0.044915672
        },
        {
        "Agemos": 72.5,
        "L": -0.219069129,
        "M": 115.0054978,
        "S": 0.044963636
        },
        {
        "Agemos": 73.5,
        "L": -0.224722166,
        "M": 115.5863089,
        "S": 0.045010259
        },
        {
        "Agemos": 74.5,
        "L": -0.229140412,
        "M": 116.1647782,
        "S": 0.045055624
        },
        {
        "Agemos": 75.5,
        "L": -0.232335686,
        "M": 116.7406221,
        "S": 0.045099817
        },
        {
        "Agemos": 76.5,
        "L": -0.234324563,
        "M": 117.3135622,
        "S": 0.045142924
        },
        {
        "Agemos": 77.5,
        "L": -0.235128195,
        "M": 117.8833259,
        "S": 0.045185036
        },
        {
        "Agemos": 78.5,
        "L": -0.234772114,
        "M": 118.4496481,
        "S": 0.045226249
        },
        {
        "Agemos": 79.5,
        "L": -0.233286033,
        "M": 119.0122722,
        "S": 0.045266662
        },
        {
        "Agemos": 80.5,
        "L": -0.230703633,
        "M": 119.5709513,
        "S": 0.045306383
        },
        {
        "Agemos": 81.5,
        "L": -0.227062344,
        "M": 120.1254495,
        "S": 0.045345524
        },
        {
        "Agemos": 82.5,
        "L": -0.222403111,
        "M": 120.6755427,
        "S": 0.045384203
        },
        {
        "Agemos": 83.5,
        "L": -0.216770161,
        "M": 121.22102,
        "S": 0.045422551
        },
        {
        "Agemos": 84.5,
        "L": -0.210210748,
        "M": 121.7616844,
        "S": 0.045460702
        },
        {
        "Agemos": 85.5,
        "L": -0.202774891,
        "M": 122.2973542,
        "S": 0.045498803
        },
        {
        "Agemos": 86.5,
        "L": -0.194515104,
        "M": 122.827864,
        "S": 0.045537012
        },
        {
        "Agemos": 87.5,
        "L": -0.185486099,
        "M": 123.3530652,
        "S": 0.045575495
        },
        {
        "Agemos": 88.5,
        "L": -0.175744476,
        "M": 123.8728276,
        "S": 0.045614432
        },
        {
        "Agemos": 89.5,
        "L": -0.165348396,
        "M": 124.38704,
        "S": 0.045654016
        },
        {
        "Agemos": 90.5,
        "L": -0.15435722,
        "M": 124.8956114,
        "S": 0.04569445
        },
        {
        "Agemos": 91.5,
        "L": -0.142831123,
        "M": 125.398472,
        "S": 0.045735953
        },
        {
        "Agemos": 92.5,
        "L": -0.130830669,
        "M": 125.895574,
        "S": 0.045778759
        },
        {
        "Agemos": 93.5,
        "L": -0.118416354,
        "M": 126.3868929,
        "S": 0.045823114
        },
        {
        "Agemos": 94.5,
        "L": -0.105648092,
        "M": 126.8724284,
        "S": 0.04586928
        },
        {
        "Agemos": 95.5,
        "L": -0.092584657,
        "M": 127.3522056,
        "S": 0.045917535
        },
        {
        "Agemos": 96.5,
        "L": -0.079283065,
        "M": 127.8262759,
        "S": 0.045968169
        },
        {
        "Agemos": 97.5,
        "L": -0.065797888,
        "M": 128.2947187,
        "S": 0.04602149
        },
        {
        "Agemos": 98.5,
        "L": -0.0521805,
        "M": 128.757642,
        "S": 0.046077818
        },
        {
        "Agemos": 99.5,
        "L": -0.03847825,
        "M": 129.2151839,
        "S": 0.046137487
        },
        {
        "Agemos": 100.5,
        "L": -0.024733545,
        "M": 129.6675143,
        "S": 0.046200842
        },
        {
        "Agemos": 101.5,
        "L": -0.010982868,
        "M": 130.1148354,
        "S": 0.04626824
        },
        {
        "Agemos": 102.5,
        "L": 0.002744306,
        "M": 130.5573839,
        "S": 0.046340046
        },
        {
        "Agemos": 103.5,
        "L": 0.016426655,
        "M": 130.995432,
        "S": 0.046416629
        },
        {
        "Agemos": 104.5,
        "L": 0.030052231,
        "M": 131.4292887,
        "S": 0.046498361
        },
        {
        "Agemos": 105.5,
        "L": 0.043619747,
        "M": 131.8593015,
        "S": 0.046585611
        },
        {
        "Agemos": 106.5,
        "L": 0.05713988,
        "M": 132.2858574,
        "S": 0.046678741
        },
        {
        "Agemos": 107.5,
        "L": 0.070636605,
        "M": 132.7093845,
        "S": 0.046778099
        },
        {
        "Agemos": 108.5,
        "L": 0.08414848,
        "M": 133.1303527,
        "S": 0.04688401
        },
        {
        "Agemos": 109.5,
        "L": 0.097729873,
        "M": 133.5492749,
        "S": 0.046996769
        },
        {
        "Agemos": 110.5,
        "L": 0.111452039,
        "M": 133.9667073,
        "S": 0.047116633
        },
        {
        "Agemos": 111.5,
        "L": 0.125404005,
        "M": 134.3832499,
        "S": 0.047243801
        },
        {
        "Agemos": 112.5,
        "L": 0.13969316,
        "M": 134.7995463,
        "S": 0.047378413
        },
        {
        "Agemos": 113.5,
        "L": 0.154445482,
        "M": 135.2162826,
        "S": 0.047520521
        },
        {
        "Agemos": 114.5,
        "L": 0.169805275,
        "M": 135.634186,
        "S": 0.047670085
        },
        {
        "Agemos": 115.5,
        "L": 0.185934346,
        "M": 136.0540223,
        "S": 0.047826946
        },
        {
        "Agemos": 116.5,
        "L": 0.203010488,
        "M": 136.4765925,
        "S": 0.04799081
        },
        {
        "Agemos": 117.5,
        "L": 0.2212252,
        "M": 136.9027281,
        "S": 0.048161228
        },
        {
        "Agemos": 118.5,
        "L": 0.240780542,
        "M": 137.3332846,
        "S": 0.04833757
        },
        {
        "Agemos": 119.5,
        "L": 0.261885086,
        "M": 137.7691339,
        "S": 0.048519011
        },
        {
        "Agemos": 120.5,
        "L": 0.284748919,
        "M": 138.2111552,
        "S": 0.048704503
        },
        {
        "Agemos": 121.5,
        "L": 0.309577733,
        "M": 138.6602228,
        "S": 0.048892759
        },
        {
        "Agemos": 122.5,
        "L": 0.336566048,
        "M": 139.1171933,
        "S": 0.049082239
        },
        {
        "Agemos": 123.5,
        "L": 0.365889711,
        "M": 139.5828898,
        "S": 0.049271137
        },
        {
        "Agemos": 124.5,
        "L": 0.397699038,
        "M": 140.0580848,
        "S": 0.049457371
        },
        {
        "Agemos": 125.5,
        "L": 0.432104409,
        "M": 140.5434787,
        "S": 0.049638596
        },
        {
        "Agemos": 126.5,
        "L": 0.46917993,
        "M": 141.0396832,
        "S": 0.049812203
        },
        {
        "Agemos": 127.5,
        "L": 0.508943272,
        "M": 141.5471945,
        "S": 0.049975355
        },
        {
        "Agemos": 128.5,
        "L": 0.551354277,
        "M": 142.0663731,
        "S": 0.050125012
        },
        {
        "Agemos": 129.5,
        "L": 0.596307363,
        "M": 142.59742,
        "S": 0.050257992
        },
        {
        "Agemos": 130.5,
        "L": 0.643626542,
        "M": 143.1403553,
        "S": 0.050371024
        },
        {
        "Agemos": 131.5,
        "L": 0.693062173,
        "M": 143.6949981,
        "S": 0.050460835
        },
        {
        "Agemos": 132.5,
        "L": 0.744289752,
        "M": 144.2609497,
        "S": 0.050524236
        },
        {
        "Agemos": 133.5,
        "L": 0.79691098,
        "M": 144.8375809,
        "S": 0.050558224
        },
        {
        "Agemos": 134.5,
        "L": 0.85045728,
        "M": 145.4240246,
        "S": 0.050560083
        },
        {
        "Agemos": 135.5,
        "L": 0.904395871,
        "M": 146.0191748,
        "S": 0.050527494
        },
        {
        "Agemos": 136.5,
        "L": 0.958138449,
        "M": 146.621692,
        "S": 0.050458634
        },
        {
        "Agemos": 137.5,
        "L": 1.011054559,
        "M": 147.2300177,
        "S": 0.050352269
        },
        {
        "Agemos": 138.5,
        "L": 1.062474568,
        "M": 147.8423918,
        "S": 0.050207825
        },
        {
        "Agemos": 139.5,
        "L": 1.111727029,
        "M": 148.4568879,
        "S": 0.050025434
        },
        {
        "Agemos": 140.5,
        "L": 1.158135105,
        "M": 149.0714413,
        "S": 0.049805967
        },
        {
        "Agemos": 141.5,
        "L": 1.201050821,
        "M": 149.6838943,
        "S": 0.049551023
        },
        {
        "Agemos": 142.5,
        "L": 1.239852328,
        "M": 150.2920328,
        "S": 0.049262895
        },
        {
        "Agemos": 143.5,
        "L": 1.274006058,
        "M": 150.8936469,
        "S": 0.048944504
        },
        {
        "Agemos": 144.5,
        "L": 1.303044695,
        "M": 151.4865636,
        "S": 0.048599314
        },
        {
        "Agemos": 145.5,
        "L": 1.326605954,
        "M": 152.0686985,
        "S": 0.048231224
        },
        {
        "Agemos": 146.5,
        "L": 1.344443447,
        "M": 152.6380955,
        "S": 0.047844442
        },
        {
        "Agemos": 147.5,
        "L": 1.356437773,
        "M": 153.1929631,
        "S": 0.047443362
        },
        {
        "Agemos": 148.5,
        "L": 1.362602695,
        "M": 153.7317031,
        "S": 0.04703243
        },
        {
        "Agemos": 149.5,
        "L": 1.363085725,
        "M": 154.2529332,
        "S": 0.046616026
        },
        {
        "Agemos": 150.5,
        "L": 1.358162799,
        "M": 154.755501,
        "S": 0.046198356
        },
        {
        "Agemos": 151.5,
        "L": 1.348227142,
        "M": 155.2384904,
        "S": 0.04578335
        },
        {
        "Agemos": 152.5,
        "L": 1.333772923,
        "M": 155.7012216,
        "S": 0.045374597
        },
        {
        "Agemos": 153.5,
        "L": 1.315374704,
        "M": 156.1432438,
        "S": 0.044975281
        },
        {
        "Agemos": 154.5,
        "L": 1.293664024,
        "M": 156.564323,
        "S": 0.044588148
        },
        {
        "Agemos": 155.5,
        "L": 1.269304678,
        "M": 156.9644258,
        "S": 0.044215488
        },
        {
        "Agemos": 156.5,
        "L": 1.242968236,
        "M": 157.3436995,
        "S": 0.043859135
        },
        {
        "Agemos": 157.5,
        "L": 1.21531127,
        "M": 157.7024507,
        "S": 0.04352048
        },
        {
        "Agemos": 158.5,
        "L": 1.186955477,
        "M": 158.0411233,
        "S": 0.043200497
        },
        {
        "Agemos": 159.5,
        "L": 1.158471522,
        "M": 158.3602756,
        "S": 0.042899776
        },
        {
        "Agemos": 160.5,
        "L": 1.130367088,
        "M": 158.6605588,
        "S": 0.042618565
        },
        {
        "Agemos": 161.5,
        "L": 1.103079209,
        "M": 158.9426964,
        "S": 0.042356812
        },
        {
        "Agemos": 162.5,
        "L": 1.076970655,
        "M": 159.2074654,
        "S": 0.042114211
        },
        {
        "Agemos": 163.5,
        "L": 1.052329922,
        "M": 159.455679,
        "S": 0.041890247
        },
        {
        "Agemos": 164.5,
        "L": 1.029374161,
        "M": 159.688172,
        "S": 0.04168424
        },
        {
        "Agemos": 165.5,
        "L": 1.008254396,
        "M": 159.9057871,
        "S": 0.041495379
        },
        {
        "Agemos": 166.5,
        "L": 0.989062282,
        "M": 160.1093647,
        "S": 0.041322765
        },
        {
        "Agemos": 167.5,
        "L": 0.971837799,
        "M": 160.299733,
        "S": 0.041165437
        },
        {
        "Agemos": 168.5,
        "L": 0.95657215,
        "M": 160.4776996,
        "S": 0.041022401
        },
        {
        "Agemos": 169.5,
        "L": 0.94324228,
        "M": 160.6440526,
        "S": 0.040892651
        },
        {
        "Agemos": 170.5,
        "L": 0.931767062,
        "M": 160.7995428,
        "S": 0.040775193
        },
        {
        "Agemos": 171.5,
        "L": 0.922058291,
        "M": 160.9448916,
        "S": 0.040669052
        },
        {
        "Agemos": 172.5,
        "L": 0.914012643,
        "M": 161.0807857,
        "S": 0.040573288
        },
        {
        "Agemos": 173.5,
        "L": 0.907516917,
        "M": 161.2078755,
        "S": 0.040487005
        },
        {
        "Agemos": 174.5,
        "L": 0.902452436,
        "M": 161.3267744,
        "S": 0.040409354
        },
        {
        "Agemos": 175.5,
        "L": 0.898698641,
        "M": 161.4380593,
        "S": 0.040339537
        },
        {
        "Agemos": 176.5,
        "L": 0.896143482,
        "M": 161.5422726,
        "S": 0.040276811
        },
        {
        "Agemos": 177.5,
        "L": 0.894659668,
        "M": 161.639917,
        "S": 0.040220488
        },
        {
        "Agemos": 178.5,
        "L": 0.89413892,
        "M": 161.7314645,
        "S": 0.040169932
        },
        {
        "Agemos": 179.5,
        "L": 0.894475371,
        "M": 161.8173534,
        "S": 0.040124562
        },
        {
        "Agemos": 180.5,
        "L": 0.895569834,
        "M": 161.8979913,
        "S": 0.040083845
        },
        {
        "Agemos": 181.5,
        "L": 0.897330209,
        "M": 161.9737558,
        "S": 0.040047295
        },
        {
        "Agemos": 182.5,
        "L": 0.899671635,
        "M": 162.0449969,
        "S": 0.040014473
        },
        {
        "Agemos": 183.5,
        "L": 0.902516442,
        "M": 162.1120386,
        "S": 0.03998498
        },
        {
        "Agemos": 184.5,
        "L": 0.905793969,
        "M": 162.17518,
        "S": 0.039958458
        },
        {
        "Agemos": 185.5,
        "L": 0.909440266,
        "M": 162.2346979,
        "S": 0.039934584
        },
        {
        "Agemos": 186.5,
        "L": 0.913397733,
        "M": 162.2908474,
        "S": 0.039913066
        },
        {
        "Agemos": 187.5,
        "L": 0.91761471,
        "M": 162.343864,
        "S": 0.039893644
        },
        {
        "Agemos": 188.5,
        "L": 0.922045055,
        "M": 162.3939652,
        "S": 0.039876087
        },
        {
        "Agemos": 189.5,
        "L": 0.926647697,
        "M": 162.4413513,
        "S": 0.039860185
        },
        {
        "Agemos": 190.5,
        "L": 0.931386217,
        "M": 162.4862071,
        "S": 0.039845754
        },
        {
        "Agemos": 191.5,
        "L": 0.93622842,
        "M": 162.5287029,
        "S": 0.039832629
        },
        {
        "Agemos": 192.5,
        "L": 0.941145943,
        "M": 162.5689958,
        "S": 0.039820663
        },
        {
        "Agemos": 193.5,
        "L": 0.94611388,
        "M": 162.6072309,
        "S": 0.039809725
        },
        {
        "Agemos": 194.5,
        "L": 0.95111043,
        "M": 162.6435418,
        "S": 0.0397997
        },
        {
        "Agemos": 195.5,
        "L": 0.956116576,
        "M": 162.6780519,
        "S": 0.039790485
        },
        {
        "Agemos": 196.5,
        "L": 0.961115792,
        "M": 162.7108751,
        "S": 0.039781991
        },
        {
        "Agemos": 197.5,
        "L": 0.966093766,
        "M": 162.7421168,
        "S": 0.039774136
        },
        {
        "Agemos": 198.5,
        "L": 0.971038162,
        "M": 162.7718741,
        "S": 0.03976685
        },
        {
        "Agemos": 199.5,
        "L": 0.975938391,
        "M": 162.8002371,
        "S": 0.03976007
        },
        {
        "Agemos": 200.5,
        "L": 0.980785418,
        "M": 162.8272889,
        "S": 0.039753741
        },
        {
        "Agemos": 201.5,
        "L": 0.985571579,
        "M": 162.8531067,
        "S": 0.039747815
        },
        {
        "Agemos": 202.5,
        "L": 0.99029042,
        "M": 162.8777619,
        "S": 0.039742249
        },
        {
        "Agemos": 203.5,
        "L": 0.994936555,
        "M": 162.9013208,
        "S": 0.039737004
        },
        {
        "Agemos": 204.5,
        "L": 0.999505539,
        "M": 162.9238449,
        "S": 0.039732048
        },
        {
        "Agemos": 205.5,
        "L": 1.003993753,
        "M": 162.9453912,
        "S": 0.039727352
        },
        {
        "Agemos": 206.5,
        "L": 1.0083983,
        "M": 162.9660131,
        "S": 0.03972289
        },
        {
        "Agemos": 207.5,
        "L": 1.012716921,
        "M": 162.9857599,
        "S": 0.03971864
        },
        {
        "Agemos": 208.5,
        "L": 1.016947912,
        "M": 163.0046776,
        "S": 0.039714581
        },
        {
        "Agemos": 209.5,
        "L": 1.021090055,
        "M": 163.0228094,
        "S": 0.039710697
        },
        {
        "Agemos": 210.5,
        "L": 1.025142554,
        "M": 163.0401953,
        "S": 0.039706971
        },
        {
        "Agemos": 211.5,
        "L": 1.029104983,
        "M": 163.0568727,
        "S": 0.039703391
        },
        {
        "Agemos": 212.5,
        "L": 1.032977233,
        "M": 163.0728768,
        "S": 0.039699945
        },
        {
        "Agemos": 213.5,
        "L": 1.036759475,
        "M": 163.0882404,
        "S": 0.039696623
        },
        {
        "Agemos": 214.5,
        "L": 1.040452117,
        "M": 163.1029943,
        "S": 0.039693415
        },
        {
        "Agemos": 215.5,
        "L": 1.044055774,
        "M": 163.1171673,
        "S": 0.039690313
        },
        {
        "Agemos": 216.5,
        "L": 1.047571238,
        "M": 163.1307866,
        "S": 0.039687311
        },
        {
        "Agemos": 217.5,
        "L": 1.050999451,
        "M": 163.1438776,
        "S": 0.039684402
        },
        {
        "Agemos": 218.5,
        "L": 1.054341482,
        "M": 163.1564644,
        "S": 0.039681581
        },
        {
        "Agemos": 219.5,
        "L": 1.057598512,
        "M": 163.1685697,
        "S": 0.039678842
        },
        {
        "Agemos": 220.5,
        "L": 1.060771808,
        "M": 163.1802146,
        "S": 0.039676182
        },
        {
        "Agemos": 221.5,
        "L": 1.063862715,
        "M": 163.1914194,
        "S": 0.039673596
        },
        {
        "Agemos": 222.5,
        "L": 1.066872639,
        "M": 163.202203,
        "S": 0.039671082
        },
        {
        "Agemos": 223.5,
        "L": 1.069803036,
        "M": 163.2125835,
        "S": 0.039668635
        },
        {
        "Agemos": 224.5,
        "L": 1.072655401,
        "M": 163.2225779,
        "S": 0.039666254
        },
        {
        "Agemos": 225.5,
        "L": 1.075431258,
        "M": 163.2322024,
        "S": 0.039663936
        },
        {
        "Agemos": 226.5,
        "L": 1.078132156,
        "M": 163.2414722,
        "S": 0.039661679
        },
        {
        "Agemos": 227.5,
        "L": 1.080759655,
        "M": 163.2504019,
        "S": 0.039659481
        },
        {
        "Agemos": 228.5,
        "L": 1.083315329,
        "M": 163.2590052,
        "S": 0.039657339
        },
        {
        "Agemos": 229.5,
        "L": 1.085800751,
        "M": 163.2672954,
        "S": 0.039655252
        },
        {
        "Agemos": 230.5,
        "L": 1.088217496,
        "M": 163.2752848,
        "S": 0.039653218
        },
        {
        "Agemos": 231.5,
        "L": 1.090567133,
        "M": 163.2829854,
        "S": 0.039651237
        },
        {
        "Agemos": 232.5,
        "L": 1.092851222,
        "M": 163.2904086,
        "S": 0.039649306
        },
        {
        "Agemos": 233.5,
        "L": 1.095071313,
        "M": 163.297565,
        "S": 0.039647424
        },
        {
        "Agemos": 234.5,
        "L": 1.097228939,
        "M": 163.304465,
        "S": 0.039645591
        },
        {
        "Agemos": 235.5,
        "L": 1.099325619,
        "M": 163.3111185,
        "S": 0.039643804
        },
        {
        "Agemos": 236.5,
        "L": 1.101362852,
        "M": 163.3175349,
        "S": 0.039642063
        },
        {
        "Agemos": 237.5,
        "L": 1.103342119,
        "M": 163.3237231,
        "S": 0.039640367
        },
        {
        "Agemos": 238.5,
        "L": 1.105264876,
        "M": 163.3296918,
        "S": 0.039638715
        },
        {
        "Agemos": 239.5,
        "L": 1.107132561,
        "M": 163.3354491,
        "S": 0.039637105
        },
        {
        "Agemos": 240,
        "L": 1.108046193,
        "M": 163.338251,
        "S": 0.039636316
        }
    ]
}

export let wtage = {
    "M": [
        {
          "Agemos": 24.5,
          "L": -0.216501213,
          "M": 12.74154396,
          "S": 0.108166006
        },
        {
          "Agemos": 25.5,
          "L": -0.239790488,
          "M": 12.88102276,
          "S": 0.108274706
        },
        {
          "Agemos": 26.5,
          "L": -0.266315853,
          "M": 13.01842382,
          "S": 0.108421025
        },
        {
          "Agemos": 27.5,
          "L": -0.295754969,
          "M": 13.1544966,
          "S": 0.10860477
        },
        {
          "Agemos": 28.5,
          "L": -0.327729368,
          "M": 13.28989667,
          "S": 0.108825681
        },
        {
          "Agemos": 29.5,
          "L": -0.361817468,
          "M": 13.42519408,
          "S": 0.109083424
        },
        {
          "Agemos": 30.5,
          "L": -0.397568087,
          "M": 13.56088113,
          "S": 0.109377581
        },
        {
          "Agemos": 31.5,
          "L": -0.434520252,
          "M": 13.69737858,
          "S": 0.109707646
        },
        {
          "Agemos": 32.5,
          "L": -0.472188756,
          "M": 13.83504622,
          "S": 0.110073084
        },
        {
          "Agemos": 33.5,
          "L": -0.510116627,
          "M": 13.97418299,
          "S": 0.110473254
        },
        {
          "Agemos": 34.5,
          "L": -0.547885579,
          "M": 14.1150324,
          "S": 0.1109074
        },
        {
          "Agemos": 35.5,
          "L": -0.58507011,
          "M": 14.25779618,
          "S": 0.111374787
        },
        {
          "Agemos": 36.5,
          "L": -0.621319726,
          "M": 14.40262749,
          "S": 0.111874514
        },
        {
          "Agemos": 37.5,
          "L": -0.656295986,
          "M": 14.54964614,
          "S": 0.112405687
        },
        {
          "Agemos": 38.5,
          "L": -0.689735029,
          "M": 14.69893326,
          "S": 0.112967254
        },
        {
          "Agemos": 39.5,
          "L": -0.721410388,
          "M": 14.85054151,
          "S": 0.11355811
        },
        {
          "Agemos": 40.5,
          "L": -0.751175223,
          "M": 15.00449143,
          "S": 0.114176956
        },
        {
          "Agemos": 41.5,
          "L": -0.778904279,
          "M": 15.16078454,
          "S": 0.114822482
        },
        {
          "Agemos": 42.5,
          "L": -0.804515498,
          "M": 15.31940246,
          "S": 0.115493292
        },
        {
          "Agemos": 43.5,
          "L": -0.828003255,
          "M": 15.48030313,
          "S": 0.116187777
        },
        {
          "Agemos": 44.5,
          "L": -0.849380372,
          "M": 15.64343309,
          "S": 0.116904306
        },
        {
          "Agemos": 45.5,
          "L": -0.86869965,
          "M": 15.80872535,
          "S": 0.117641148
        },
        {
          "Agemos": 46.5,
          "L": -0.886033992,
          "M": 15.97610456,
          "S": 0.118396541
        },
        {
          "Agemos": 47.5,
          "L": -0.901507878,
          "M": 16.14548194,
          "S": 0.119168555
        },
        {
          "Agemos": 48.5,
          "L": -0.915241589,
          "M": 16.31676727,
          "S": 0.11995532
        },
        {
          "Agemos": 49.5,
          "L": -0.927377772,
          "M": 16.4898646,
          "S": 0.120754916
        },
        {
          "Agemos": 50.5,
          "L": -0.938069819,
          "M": 16.66467529,
          "S": 0.121565421
        },
        {
          "Agemos": 51.5,
          "L": -0.94747794,
          "M": 16.84109948,
          "S": 0.122384927
        },
        {
          "Agemos": 52.5,
          "L": -0.955765694,
          "M": 17.01903746,
          "S": 0.123211562
        },
        {
          "Agemos": 53.5,
          "L": -0.963096972,
          "M": 17.1983908,
          "S": 0.124043503
        },
        {
          "Agemos": 54.5,
          "L": -0.969633434,
          "M": 17.37906341,
          "S": 0.124878992
        },
        {
          "Agemos": 55.5,
          "L": -0.975532355,
          "M": 17.56096245,
          "S": 0.125716348
        },
        {
          "Agemos": 56.5,
          "L": -0.980937915,
          "M": 17.74400082,
          "S": 0.126554022
        },
        {
          "Agemos": 57.5,
          "L": -0.986006518,
          "M": 17.92809121,
          "S": 0.127390453
        },
        {
          "Agemos": 58.5,
          "L": -0.99086694,
          "M": 18.11315625,
          "S": 0.128224294
        },
        {
          "Agemos": 59.5,
          "L": -0.995644402,
          "M": 18.29912286,
          "S": 0.129054277
        },
        {
          "Agemos": 60.5,
          "L": -1.000453886,
          "M": 18.48592413,
          "S": 0.129879257
        },
        {
          "Agemos": 61.5,
          "L": -1.005399668,
          "M": 18.67349965,
          "S": 0.130698212
        },
        {
          "Agemos": 62.5,
          "L": -1.010575003,
          "M": 18.86179576,
          "S": 0.131510245
        },
        {
          "Agemos": 63.5,
          "L": -1.016061941,
          "M": 19.05076579,
          "S": 0.132314586
        },
        {
          "Agemos": 64.5,
          "L": -1.021931241,
          "M": 19.24037019,
          "S": 0.133110593
        },
        {
          "Agemos": 65.5,
          "L": -1.028242376,
          "M": 19.43057662,
          "S": 0.133897752
        },
        {
          "Agemos": 66.5,
          "L": -1.035043608,
          "M": 19.62136007,
          "S": 0.134675673
        },
        {
          "Agemos": 67.5,
          "L": -1.042372125,
          "M": 19.8127028,
          "S": 0.13544409
        },
        {
          "Agemos": 68.5,
          "L": -1.050254232,
          "M": 20.0045944,
          "S": 0.13620286
        },
        {
          "Agemos": 69.5,
          "L": -1.058705595,
          "M": 20.19703171,
          "S": 0.136951959
        },
        {
          "Agemos": 70.5,
          "L": -1.067731529,
          "M": 20.39001872,
          "S": 0.137691478
        },
        {
          "Agemos": 71.5,
          "L": -1.077321193,
          "M": 20.58356862,
          "S": 0.138421673
        },
        {
          "Agemos": 72.5,
          "L": -1.087471249,
          "M": 20.77769565,
          "S": 0.139142773
        },
        {
          "Agemos": 73.5,
          "L": -1.098152984,
          "M": 20.97242631,
          "S": 0.139855242
        },
        {
          "Agemos": 74.5,
          "L": -1.10933408,
          "M": 21.16779192,
          "S": 0.140559605
        },
        {
          "Agemos": 75.5,
          "L": -1.120974043,
          "M": 21.36383013,
          "S": 0.141256489
        },
        {
          "Agemos": 76.5,
          "L": -1.133024799,
          "M": 21.56058467,
          "S": 0.141946613
        },
        {
          "Agemos": 77.5,
          "L": -1.145431351,
          "M": 21.75810506,
          "S": 0.142630785
        },
        {
          "Agemos": 78.5,
          "L": -1.158132499,
          "M": 21.95644627,
          "S": 0.143309898
        },
        {
          "Agemos": 79.5,
          "L": -1.171061612,
          "M": 22.15566842,
          "S": 0.143984924
        },
        {
          "Agemos": 80.5,
          "L": -1.184141975,
          "M": 22.35583862,
          "S": 0.144656953
        },
        {
          "Agemos": 81.5,
          "L": -1.197307185,
          "M": 22.55702268,
          "S": 0.145327009
        },
        {
          "Agemos": 82.5,
          "L": -1.210475099,
          "M": 22.75929558,
          "S": 0.145996289
        },
        {
          "Agemos": 83.5,
          "L": -1.223565263,
          "M": 22.9627344,
          "S": 0.146666
        },
        {
          "Agemos": 84.5,
          "L": -1.236497304,
          "M": 23.16741888,
          "S": 0.147337375
        },
        {
          "Agemos": 85.5,
          "L": -1.249186293,
          "M": 23.37343341,
          "S": 0.148011715
        },
        {
          "Agemos": 86.5,
          "L": -1.261555446,
          "M": 23.58086145,
          "S": 0.148690256
        },
        {
          "Agemos": 87.5,
          "L": -1.273523619,
          "M": 23.78979096,
          "S": 0.149374297
        },
        {
          "Agemos": 88.5,
          "L": -1.285013783,
          "M": 24.00031064,
          "S": 0.150065107
        },
        {
          "Agemos": 89.5,
          "L": -1.295952066,
          "M": 24.21251028,
          "S": 0.150763933
        },
        {
          "Agemos": 90.5,
          "L": -1.306268473,
          "M": 24.42648043,
          "S": 0.151471982
        },
        {
          "Agemos": 91.5,
          "L": -1.31589753,
          "M": 24.642312,
          "S": 0.152190413
        },
        {
          "Agemos": 92.5,
          "L": -1.324778843,
          "M": 24.86009596,
          "S": 0.152920322
        },
        {
          "Agemos": 93.5,
          "L": -1.332857581,
          "M": 25.07992303,
          "S": 0.153662731
        },
        {
          "Agemos": 94.5,
          "L": -1.340080195,
          "M": 25.30188584,
          "S": 0.154418635
        },
        {
          "Agemos": 95.5,
          "L": -1.346412105,
          "M": 25.52606977,
          "S": 0.155188768
        },
        {
          "Agemos": 96.5,
          "L": -1.351813296,
          "M": 25.75256528,
          "S": 0.155973912
        },
        {
          "Agemos": 97.5,
          "L": -1.356253969,
          "M": 25.9814599,
          "S": 0.156774684
        },
        {
          "Agemos": 98.5,
          "L": -1.359710858,
          "M": 26.2128399,
          "S": 0.157591579
        },
        {
          "Agemos": 99.5,
          "L": -1.362167159,
          "M": 26.44679027,
          "S": 0.158424964
        },
        {
          "Agemos": 100.5,
          "L": -1.363612378,
          "M": 26.68339457,
          "S": 0.159275071
        },
        {
          "Agemos": 101.5,
          "L": -1.364042106,
          "M": 26.92273494,
          "S": 0.160141995
        },
        {
          "Agemos": 102.5,
          "L": -1.363457829,
          "M": 27.16489199,
          "S": 0.161025689
        },
        {
          "Agemos": 103.5,
          "L": -1.361865669,
          "M": 27.40994539,
          "S": 0.161925976
        },
        {
          "Agemos": 104.5,
          "L": -1.35928261,
          "M": 27.65796978,
          "S": 0.162842452
        },
        {
          "Agemos": 105.5,
          "L": -1.355720571,
          "M": 27.90904433,
          "S": 0.163774719
        },
        {
          "Agemos": 106.5,
          "L": -1.351202536,
          "M": 28.16324264,
          "S": 0.164722138
        },
        {
          "Agemos": 107.5,
          "L": -1.345754408,
          "M": 28.42063744,
          "S": 0.165683945
        },
        {
          "Agemos": 108.5,
          "L": -1.339405453,
          "M": 28.68130005,
          "S": 0.166659247
        },
        {
          "Agemos": 109.5,
          "L": -1.332188093,
          "M": 28.94530029,
          "S": 0.167647017
        },
        {
          "Agemos": 110.5,
          "L": -1.324137479,
          "M": 29.21270645,
          "S": 0.168646104
        },
        {
          "Agemos": 111.5,
          "L": -1.315291073,
          "M": 29.48358527,
          "S": 0.169655235
        },
        {
          "Agemos": 112.5,
          "L": -1.30568824,
          "M": 29.75800198,
          "S": 0.170673022
        },
        {
          "Agemos": 113.5,
          "L": -1.295369867,
          "M": 30.03602021,
          "S": 0.17169797
        },
        {
          "Agemos": 114.5,
          "L": -1.284374967,
          "M": 30.31770417,
          "S": 0.17272854
        },
        {
          "Agemos": 115.5,
          "L": -1.272750864,
          "M": 30.60311107,
          "S": 0.173762961
        },
        {
          "Agemos": 116.5,
          "L": -1.260539193,
          "M": 30.89230072,
          "S": 0.174799493
        },
        {
          "Agemos": 117.5,
          "L": -1.247783611,
          "M": 31.18532984,
          "S": 0.175836284
        },
        {
          "Agemos": 118.5,
          "L": -1.234527763,
          "M": 31.48225315,
          "S": 0.176871417
        },
        {
          "Agemos": 119.5,
          "L": -1.220815047,
          "M": 31.78312329,
          "S": 0.177902912
        },
        {
          "Agemos": 120.5,
          "L": -1.206688407,
          "M": 32.08799062,
          "S": 0.17892874
        },
        {
          "Agemos": 121.5,
          "L": -1.19219015,
          "M": 32.39690313,
          "S": 0.17994683
        },
        {
          "Agemos": 122.5,
          "L": -1.177361786,
          "M": 32.7099062,
          "S": 0.180955078
        },
        {
          "Agemos": 123.5,
          "L": -1.162243894,
          "M": 33.02704244,
          "S": 0.181951361
        },
        {
          "Agemos": 124.5,
          "L": -1.146876007,
          "M": 33.34835148,
          "S": 0.182933537
        },
        {
          "Agemos": 125.5,
          "L": -1.131296524,
          "M": 33.67386973,
          "S": 0.183899465
        },
        {
          "Agemos": 126.5,
          "L": -1.115542634,
          "M": 34.00363017,
          "S": 0.184847006
        },
        {
          "Agemos": 127.5,
          "L": -1.099650267,
          "M": 34.33766207,
          "S": 0.185774041
        },
        {
          "Agemos": 128.5,
          "L": -1.083654055,
          "M": 34.67599076,
          "S": 0.18667847
        },
        {
          "Agemos": 129.5,
          "L": -1.067587314,
          "M": 35.01863732,
          "S": 0.187558229
        },
        {
          "Agemos": 130.5,
          "L": -1.051482972,
          "M": 35.36561737,
          "S": 0.18841128
        },
        {
          "Agemos": 131.5,
          "L": -1.035367321,
          "M": 35.71694723,
          "S": 0.189235738
        },
        {
          "Agemos": 132.5,
          "L": -1.019277299,
          "M": 36.07262569,
          "S": 0.190029545
        },
        {
          "Agemos": 133.5,
          "L": -1.003235326,
          "M": 36.43265996,
          "S": 0.190790973
        },
        {
          "Agemos": 134.5,
          "L": -0.987269866,
          "M": 36.79704392,
          "S": 0.191518224
        },
        {
          "Agemos": 135.5,
          "L": -0.971406609,
          "M": 37.1657671,
          "S": 0.192209619
        },
        {
          "Agemos": 136.5,
          "L": -0.955670107,
          "M": 37.53881268,
          "S": 0.192863569
        },
        {
          "Agemos": 137.5,
          "L": -0.940083834,
          "M": 37.91615721,
          "S": 0.193478582
        },
        {
          "Agemos": 138.5,
          "L": -0.924670244,
          "M": 38.2977703,
          "S": 0.194053274
        },
        {
          "Agemos": 139.5,
          "L": -0.909450843,
          "M": 38.6836143,
          "S": 0.194586368
        },
        {
          "Agemos": 140.5,
          "L": -0.894446258,
          "M": 39.07364401,
          "S": 0.195076705
        },
        {
          "Agemos": 141.5,
          "L": -0.879676305,
          "M": 39.46780643,
          "S": 0.195523246
        },
        {
          "Agemos": 142.5,
          "L": -0.865160071,
          "M": 39.86604044,
          "S": 0.195925079
        },
        {
          "Agemos": 143.5,
          "L": -0.850915987,
          "M": 40.26827652,
          "S": 0.196281418
        },
        {
          "Agemos": 144.5,
          "L": -0.836961905,
          "M": 40.67443658,
          "S": 0.196591612
        },
        {
          "Agemos": 145.5,
          "L": -0.823315176,
          "M": 41.08443363,
          "S": 0.19685514
        },
        {
          "Agemos": 146.5,
          "L": -0.809992726,
          "M": 41.49817164,
          "S": 0.19707162
        },
        {
          "Agemos": 147.5,
          "L": -0.797011132,
          "M": 41.91554528,
          "S": 0.197240806
        },
        {
          "Agemos": 148.5,
          "L": -0.784386693,
          "M": 42.33643978,
          "S": 0.197362591
        },
        {
          "Agemos": 149.5,
          "L": -0.772135506,
          "M": 42.76073078,
          "S": 0.197437004
        },
        {
          "Agemos": 150.5,
          "L": -0.760273528,
          "M": 43.18828419,
          "S": 0.19746421
        },
        {
          "Agemos": 151.5,
          "L": -0.748815968,
          "M": 43.61895703,
          "S": 0.197444522
        },
        {
          "Agemos": 152.5,
          "L": -0.737780398,
          "M": 44.0525931,
          "S": 0.197378345
        },
        {
          "Agemos": 153.5,
          "L": -0.727181568,
          "M": 44.48903027,
          "S": 0.197266263
        },
        {
          "Agemos": 154.5,
          "L": -0.717035494,
          "M": 44.92809483,
          "S": 0.197108968
        },
        {
          "Agemos": 155.5,
          "L": -0.707358338,
          "M": 45.36960315,
          "S": 0.196907274
        },
        {
          "Agemos": 156.5,
          "L": -0.698166437,
          "M": 45.81336172,
          "S": 0.196662115
        },
        {
          "Agemos": 157.5,
          "L": -0.689476327,
          "M": 46.25916729,
          "S": 0.196374538
        },
        {
          "Agemos": 158.5,
          "L": -0.68130475,
          "M": 46.70680701,
          "S": 0.196045701
        },
        {
          "Agemos": 159.5,
          "L": -0.673668658,
          "M": 47.15605863,
          "S": 0.195676862
        },
        {
          "Agemos": 160.5,
          "L": -0.666585194,
          "M": 47.60669074,
          "S": 0.19526938
        },
        {
          "Agemos": 161.5,
          "L": -0.660069969,
          "M": 48.05846572,
          "S": 0.19482473
        },
        {
          "Agemos": 162.5,
          "L": -0.654142602,
          "M": 48.51113138,
          "S": 0.19434441
        },
        {
          "Agemos": 163.5,
          "L": -0.648819666,
          "M": 48.96443224,
          "S": 0.193830046
        },
        {
          "Agemos": 164.5,
          "L": -0.644118611,
          "M": 49.41810374,
          "S": 0.193283319
        },
        {
          "Agemos": 165.5,
          "L": -0.640056805,
          "M": 49.87187409,
          "S": 0.192705974
        },
        {
          "Agemos": 166.5,
          "L": -0.636651424,
          "M": 50.32546478,
          "S": 0.192099812
        },
        {
          "Agemos": 167.5,
          "L": -0.633919328,
          "M": 50.77859121,
          "S": 0.191466681
        },
        {
          "Agemos": 168.5,
          "L": -0.631876912,
          "M": 51.23096332,
          "S": 0.190808471
        },
        {
          "Agemos": 169.5,
          "L": -0.63053994,
          "M": 51.68228625,
          "S": 0.190127105
        },
        {
          "Agemos": 170.5,
          "L": -0.629923353,
          "M": 52.13226113,
          "S": 0.18942453
        },
        {
          "Agemos": 171.5,
          "L": -0.630041066,
          "M": 52.58058583,
          "S": 0.188702714
        },
        {
          "Agemos": 172.5,
          "L": -0.630905733,
          "M": 53.02695588,
          "S": 0.187963636
        },
        {
          "Agemos": 173.5,
          "L": -0.632528509,
          "M": 53.47106525,
          "S": 0.187209281
        },
        {
          "Agemos": 174.5,
          "L": -0.634918779,
          "M": 53.91260737,
          "S": 0.18644163
        },
        {
          "Agemos": 175.5,
          "L": -0.638083884,
          "M": 54.35127608,
          "S": 0.185662657
        },
        {
          "Agemos": 176.5,
          "L": -0.642028835,
          "M": 54.78676659,
          "S": 0.184874323
        },
        {
          "Agemos": 177.5,
          "L": -0.646756013,
          "M": 55.21877657,
          "S": 0.184078567
        },
        {
          "Agemos": 178.5,
          "L": -0.652262297,
          "M": 55.64701131,
          "S": 0.183277339
        },
        {
          "Agemos": 179.5,
          "L": -0.658551638,
          "M": 56.07116407,
          "S": 0.182472427
        },
        {
          "Agemos": 180.5,
          "L": -0.665609025,
          "M": 56.49095862,
          "S": 0.181665781
        },
        {
          "Agemos": 181.5,
          "L": -0.673425951,
          "M": 56.90610886,
          "S": 0.18085918
        },
        {
          "Agemos": 182.5,
          "L": -0.681987284,
          "M": 57.31634059,
          "S": 0.180054395
        },
        {
          "Agemos": 183.5,
          "L": -0.691273614,
          "M": 57.72138846,
          "S": 0.179253153
        },
        {
          "Agemos": 184.5,
          "L": -0.701261055,
          "M": 58.12099696,
          "S": 0.178457127
        },
        {
          "Agemos": 185.5,
          "L": -0.711921092,
          "M": 58.51492143,
          "S": 0.177667942
        },
        {
          "Agemos": 186.5,
          "L": -0.723218488,
          "M": 58.90293208,
          "S": 0.176887192
        },
        {
          "Agemos": 187.5,
          "L": -0.735121189,
          "M": 59.28479948,
          "S": 0.176116307
        },
        {
          "Agemos": 188.5,
          "L": -0.747580416,
          "M": 59.66032626,
          "S": 0.175356814
        },
        {
          "Agemos": 189.5,
          "L": -0.760550666,
          "M": 60.02931704,
          "S": 0.174610071
        },
        {
          "Agemos": 190.5,
          "L": -0.773984558,
          "M": 60.39158721,
          "S": 0.173877336
        },
        {
          "Agemos": 191.5,
          "L": -0.787817728,
          "M": 60.74698785,
          "S": 0.173159953
        },
        {
          "Agemos": 192.5,
          "L": -0.801993069,
          "M": 61.09536847,
          "S": 0.172459052
        },
        {
          "Agemos": 193.5,
          "L": -0.816446409,
          "M": 61.43660077,
          "S": 0.171775726
        },
        {
          "Agemos": 194.5,
          "L": -0.831110299,
          "M": 61.77057372,
          "S": 0.171110986
        },
        {
          "Agemos": 195.5,
          "L": -0.845914498,
          "M": 62.09719399,
          "S": 0.170465756
        },
        {
          "Agemos": 196.5,
          "L": -0.860786514,
          "M": 62.41638628,
          "S": 0.169840869
        },
        {
          "Agemos": 197.5,
          "L": -0.875652181,
          "M": 62.72809362,
          "S": 0.169237063
        },
        {
          "Agemos": 198.5,
          "L": -0.890436283,
          "M": 63.03227756,
          "S": 0.168654971
        },
        {
          "Agemos": 199.5,
          "L": -0.905063185,
          "M": 63.32891841,
          "S": 0.168095124
        },
        {
          "Agemos": 200.5,
          "L": -0.91945749,
          "M": 63.61801537,
          "S": 0.16755794
        },
        {
          "Agemos": 201.5,
          "L": -0.933544683,
          "M": 63.89958662,
          "S": 0.167043722
        },
        {
          "Agemos": 202.5,
          "L": -0.947251765,
          "M": 64.17366943,
          "S": 0.166552654
        },
        {
          "Agemos": 203.5,
          "L": -0.960507855,
          "M": 64.44032016,
          "S": 0.166084798
        },
        {
          "Agemos": 204.5,
          "L": -0.973244762,
          "M": 64.69961427,
          "S": 0.16564009
        },
        {
          "Agemos": 205.5,
          "L": -0.985397502,
          "M": 64.95164625,
          "S": 0.165218341
        },
        {
          "Agemos": 206.5,
          "L": -0.996904762,
          "M": 65.1965295,
          "S": 0.164819236
        },
        {
          "Agemos": 207.5,
          "L": -1.007705555,
          "M": 65.43440186,
          "S": 0.16444238
        },
        {
          "Agemos": 208.5,
          "L": -1.017756047,
          "M": 65.66540015,
          "S": 0.164087103
        },
        {
          "Agemos": 209.5,
          "L": -1.027002713,
          "M": 65.88970117,
          "S": 0.163752791
        },
        {
          "Agemos": 210.5,
          "L": -1.035402243,
          "M": 66.10749114,
          "S": 0.163438661
        },
        {
          "Agemos": 211.5,
          "L": -1.042916356,
          "M": 66.31897311,
          "S": 0.163143825
        },
        {
          "Agemos": 212.5,
          "L": -1.049511871,
          "M": 66.52436618,
          "S": 0.162867311
        },
        {
          "Agemos": 213.5,
          "L": -1.055160732,
          "M": 66.72390443,
          "S": 0.162608072
        },
        {
          "Agemos": 214.5,
          "L": -1.059840019,
          "M": 66.91783563,
          "S": 0.162365006
        },
        {
          "Agemos": 215.5,
          "L": -1.063531973,
          "M": 67.10641956,
          "S": 0.162136973
        },
        {
          "Agemos": 216.5,
          "L": -1.066224038,
          "M": 67.28992603,
          "S": 0.161922819
        },
        {
          "Agemos": 217.5,
          "L": -1.067908908,
          "M": 67.46863255,
          "S": 0.161721398
        },
        {
          "Agemos": 218.5,
          "L": -1.068589885,
          "M": 67.64281378,
          "S": 0.16153153
        },
        {
          "Agemos": 219.5,
          "L": -1.068261146,
          "M": 67.8127675,
          "S": 0.161352313
        },
        {
          "Agemos": 220.5,
          "L": -1.066933756,
          "M": 67.97877331,
          "S": 0.161182785
        },
        {
          "Agemos": 221.5,
          "L": -1.064620976,
          "M": 68.14111022,
          "S": 0.161022184
        },
        {
          "Agemos": 222.5,
          "L": -1.061341755,
          "M": 68.30004741,
          "S": 0.160869943
        },
        {
          "Agemos": 223.5,
          "L": -1.057116957,
          "M": 68.4558454,
          "S": 0.160725793
        },
        {
          "Agemos": 224.5,
          "L": -1.051988979,
          "M": 68.60872174,
          "S": 0.160589574
        },
        {
          "Agemos": 225.5,
          "L": -1.04599033,
          "M": 68.75889263,
          "S": 0.1604617
        },
        {
          "Agemos": 226.5,
          "L": -1.039168248,
          "M": 68.90653028,
          "S": 0.160342924
        },
        {
          "Agemos": 227.5,
          "L": -1.031579574,
          "M": 69.05176427,
          "S": 0.160234478
        },
        {
          "Agemos": 228.5,
          "L": -1.023291946,
          "M": 69.19467288,
          "S": 0.160138158
        },
        {
          "Agemos": 229.5,
          "L": -1.014385118,
          "M": 69.33527376,
          "S": 0.160056393
        },
        {
          "Agemos": 230.5,
          "L": -1.004952366,
          "M": 69.47351373,
          "S": 0.159992344
        },
        {
          "Agemos": 231.5,
          "L": -0.995101924,
          "M": 69.60925782,
          "S": 0.159949989
        },
        {
          "Agemos": 232.5,
          "L": -0.984958307,
          "M": 69.74227758,
          "S": 0.159934231
        },
        {
          "Agemos": 233.5,
          "L": -0.974663325,
          "M": 69.87223885,
          "S": 0.159951004
        },
        {
          "Agemos": 234.5,
          "L": -0.964376555,
          "M": 69.99868896,
          "S": 0.160007394
        },
        {
          "Agemos": 235.5,
          "L": -0.954274945,
          "M": 70.12104381,
          "S": 0.160111769
        },
        {
          "Agemos": 236.5,
          "L": -0.944551187,
          "M": 70.23857482,
          "S": 0.160273918
        },
        {
          "Agemos": 237.5,
          "L": -0.935410427,
          "M": 70.35039626,
          "S": 0.160505203
        },
        {
          "Agemos": 238.5,
          "L": -0.927059784,
          "M": 70.45546105,
          "S": 0.160818788
        },
        {
          "Agemos": 239.5,
          "L": -0.919718461,
          "M": 70.55252127,
          "S": 0.161229617
        },
        {
          "Agemos": 240,
          "L": -0.91648762,
          "M": 70.59761453,
          "S": 0.161476792
        }
      ],
    "F": [
        {
          "Agemos": 24.5,
          "L": -0.75220657,
          "M": 12.13455523,
          "S": 0.107740345
        },
        {
          "Agemos": 25.5,
          "L": -0.78423366,
          "M": 12.2910249,
          "S": 0.10847701
        },
        {
          "Agemos": 26.5,
          "L": -0.81409582,
          "M": 12.44469258,
          "S": 0.109280828
        },
        {
          "Agemos": 27.5,
          "L": -0.841935504,
          "M": 12.59622335,
          "S": 0.110144488
        },
        {
          "Agemos": 28.5,
          "L": -0.867889398,
          "M": 12.74620911,
          "S": 0.111060815
        },
        {
          "Agemos": 29.5,
          "L": -0.892102647,
          "M": 12.89517218,
          "S": 0.112022759
        },
        {
          "Agemos": 30.5,
          "L": -0.914718817,
          "M": 13.04357164,
          "S": 0.113023467
        },
        {
          "Agemos": 31.5,
          "L": -0.935876584,
          "M": 13.19180874,
          "S": 0.114056328
        },
        {
          "Agemos": 32.5,
          "L": -0.955723447,
          "M": 13.34022934,
          "S": 0.115114953
        },
        {
          "Agemos": 33.5,
          "L": -0.974383363,
          "M": 13.48913319,
          "S": 0.116193327
        },
        {
          "Agemos": 34.5,
          "L": -0.991980756,
          "M": 13.63877446,
          "S": 0.11728575
        },
        {
          "Agemos": 35.5,
          "L": -1.008640742,
          "M": 13.78936547,
          "S": 0.118386848
        },
        {
          "Agemos": 36.5,
          "L": -1.024471278,
          "M": 13.94108332,
          "S": 0.119491669
        },
        {
          "Agemos": 37.5,
          "L": -1.039573604,
          "M": 14.09407175,
          "S": 0.120595658
        },
        {
          "Agemos": 38.5,
          "L": -1.054039479,
          "M": 14.24844498,
          "S": 0.121694676
        },
        {
          "Agemos": 39.5,
          "L": -1.067946784,
          "M": 14.40429169,
          "S": 0.12278503
        },
        {
          "Agemos": 40.5,
          "L": -1.081374153,
          "M": 14.56167529,
          "S": 0.1238634
        },
        {
          "Agemos": 41.5,
          "L": -1.094381409,
          "M": 14.72064045,
          "S": 0.124926943
        },
        {
          "Agemos": 42.5,
          "L": -1.107021613,
          "M": 14.88121352,
          "S": 0.125973221
        },
        {
          "Agemos": 43.5,
          "L": -1.119338692,
          "M": 15.04340553,
          "S": 0.127000212
        },
        {
          "Agemos": 44.5,
          "L": -1.131367831,
          "M": 15.20721443,
          "S": 0.128006292
        },
        {
          "Agemos": 45.5,
          "L": -1.143135936,
          "M": 15.37262729,
          "S": 0.128990225
        },
        {
          "Agemos": 46.5,
          "L": -1.15466215,
          "M": 15.53962221,
          "S": 0.129951143
        },
        {
          "Agemos": 47.5,
          "L": -1.165958392,
          "M": 15.70817017,
          "S": 0.130888527
        },
        {
          "Agemos": 48.5,
          "L": -1.177029925,
          "M": 15.87823668,
          "S": 0.131802186
        },
        {
          "Agemos": 49.5,
          "L": -1.187871001,
          "M": 16.04978452,
          "S": 0.132692269
        },
        {
          "Agemos": 50.5,
          "L": -1.198484073,
          "M": 16.2227706,
          "S": 0.133559108
        },
        {
          "Agemos": 51.5,
          "L": -1.208853947,
          "M": 16.39715363,
          "S": 0.134403386
        },
        {
          "Agemos": 52.5,
          "L": -1.218965087,
          "M": 16.57289122,
          "S": 0.13522599
        },
        {
          "Agemos": 53.5,
          "L": -1.228798212,
          "M": 16.74994187,
          "S": 0.136028014
        },
        {
          "Agemos": 54.5,
          "L": -1.238330855,
          "M": 16.92826587,
          "S": 0.136810739
        },
        {
          "Agemos": 55.5,
          "L": -1.247537914,
          "M": 17.10782615,
          "S": 0.137575606
        },
        {
          "Agemos": 56.5,
          "L": -1.256392179,
          "M": 17.28858894,
          "S": 0.138324193
        },
        {
          "Agemos": 57.5,
          "L": -1.264864846,
          "M": 17.47052444,
          "S": 0.139058192
        },
        {
          "Agemos": 58.5,
          "L": -1.272926011,
          "M": 17.65360733,
          "S": 0.139779387
        },
        {
          "Agemos": 59.5,
          "L": -1.28054514,
          "M": 17.83781722,
          "S": 0.140489635
        },
        {
          "Agemos": 60.5,
          "L": -1.287691525,
          "M": 18.02313904,
          "S": 0.141190842
        },
        {
          "Agemos": 61.5,
          "L": -1.294332076,
          "M": 18.20956418,
          "S": 0.141884974
        },
        {
          "Agemos": 62.5,
          "L": -1.300441561,
          "M": 18.3970876,
          "S": 0.142573939
        },
        {
          "Agemos": 63.5,
          "L": -1.305989011,
          "M": 18.58571243,
          "S": 0.143259709
        },
        {
          "Agemos": 64.5,
          "L": -1.310946941,
          "M": 18.77544728,
          "S": 0.143944216
        },
        {
          "Agemos": 65.5,
          "L": -1.315289534,
          "M": 18.966307,
          "S": 0.144629359
        },
        {
          "Agemos": 66.5,
          "L": -1.318992925,
          "M": 19.15831267,
          "S": 0.14531699
        },
        {
          "Agemos": 67.5,
          "L": -1.322035315,
          "M": 19.35149163,
          "S": 0.146008903
        },
        {
          "Agemos": 68.5,
          "L": -1.324398133,
          "M": 19.54587708,
          "S": 0.146706813
        },
        {
          "Agemos": 69.5,
          "L": -1.326064539,
          "M": 19.74150854,
          "S": 0.147412363
        },
        {
          "Agemos": 70.5,
          "L": -1.327020415,
          "M": 19.93843145,
          "S": 0.148127109
        },
        {
          "Agemos": 71.5,
          "L": -1.327256387,
          "M": 20.13669623,
          "S": 0.148852482
        },
        {
          "Agemos": 72.5,
          "L": -1.326763834,
          "M": 20.33635961,
          "S": 0.149589838
        },
        {
          "Agemos": 73.5,
          "L": -1.325538668,
          "M": 20.53748298,
          "S": 0.1503404
        },
        {
          "Agemos": 74.5,
          "L": -1.323579654,
          "M": 20.74013277,
          "S": 0.151105277
        },
        {
          "Agemos": 75.5,
          "L": -1.320888012,
          "M": 20.94438028,
          "S": 0.151885464
        },
        {
          "Agemos": 76.5,
          "L": -1.317468695,
          "M": 21.15030093,
          "S": 0.152681819
        },
        {
          "Agemos": 77.5,
          "L": -1.313331446,
          "M": 21.35797332,
          "S": 0.15349505
        },
        {
          "Agemos": 78.5,
          "L": -1.308487081,
          "M": 21.56748045,
          "S": 0.154325756
        },
        {
          "Agemos": 79.5,
          "L": -1.302948173,
          "M": 21.77890902,
          "S": 0.155174414
        },
        {
          "Agemos": 80.5,
          "L": -1.296733913,
          "M": 21.99234686,
          "S": 0.15604132
        },
        {
          "Agemos": 81.5,
          "L": -1.289863329,
          "M": 22.20788541,
          "S": 0.156926667
        },
        {
          "Agemos": 82.5,
          "L": -1.282358762,
          "M": 22.4256177,
          "S": 0.157830504
        },
        {
          "Agemos": 83.5,
          "L": -1.274244931,
          "M": 22.64563824,
          "S": 0.158752743
        },
        {
          "Agemos": 84.5,
          "L": -1.265548787,
          "M": 22.86804258,
          "S": 0.159693163
        },
        {
          "Agemos": 85.5,
          "L": -1.256299378,
          "M": 23.09292679,
          "S": 0.16065141
        },
        {
          "Agemos": 86.5,
          "L": -1.24653066,
          "M": 23.32038549,
          "S": 0.161626956
        },
        {
          "Agemos": 87.5,
          "L": -1.236266832,
          "M": 23.55051871,
          "S": 0.162619308
        },
        {
          "Agemos": 88.5,
          "L": -1.225551344,
          "M": 23.78341652,
          "S": 0.1636276
        },
        {
          "Agemos": 89.5,
          "L": -1.214410914,
          "M": 24.01917703,
          "S": 0.1646511
        },
        {
          "Agemos": 90.5,
          "L": -1.202884389,
          "M": 24.25789074,
          "S": 0.165688808
        },
        {
          "Agemos": 91.5,
          "L": -1.191007906,
          "M": 24.49964778,
          "S": 0.166739662
        },
        {
          "Agemos": 92.5,
          "L": -1.178818621,
          "M": 24.74453536,
          "S": 0.167802495
        },
        {
          "Agemos": 93.5,
          "L": -1.166354376,
          "M": 24.99263735,
          "S": 0.168876037
        },
        {
          "Agemos": 94.5,
          "L": -1.153653688,
          "M": 25.24403371,
          "S": 0.169958922
        },
        {
          "Agemos": 95.5,
          "L": -1.140751404,
          "M": 25.49880264,
          "S": 0.171049756
        },
        {
          "Agemos": 96.5,
          "L": -1.127684095,
          "M": 25.7570168,
          "S": 0.172147043
        },
        {
          "Agemos": 97.5,
          "L": -1.114490244,
          "M": 26.01874261,
          "S": 0.173249185
        },
        {
          "Agemos": 98.5,
          "L": -1.101204848,
          "M": 26.28404312,
          "S": 0.174354569
        },
        {
          "Agemos": 99.5,
          "L": -1.087863413,
          "M": 26.55297507,
          "S": 0.175461512
        },
        {
          "Agemos": 100.5,
          "L": -1.074500927,
          "M": 26.82558904,
          "S": 0.176568284
        },
        {
          "Agemos": 101.5,
          "L": -1.061151213,
          "M": 27.1019295,
          "S": 0.177673124
        },
        {
          "Agemos": 102.5,
          "L": -1.047847141,
          "M": 27.38203422,
          "S": 0.178774242
        },
        {
          "Agemos": 103.5,
          "L": -1.034620551,
          "M": 27.66593402,
          "S": 0.179869829
        },
        {
          "Agemos": 104.5,
          "L": -1.021502197,
          "M": 27.9536524,
          "S": 0.180958063
        },
        {
          "Agemos": 105.5,
          "L": -1.008521695,
          "M": 28.24520531,
          "S": 0.182037118
        },
        {
          "Agemos": 106.5,
          "L": -0.995707494,
          "M": 28.54060085,
          "S": 0.183105172
        },
        {
          "Agemos": 107.5,
          "L": -0.983086844,
          "M": 28.83983907,
          "S": 0.18416041
        },
        {
          "Agemos": 108.5,
          "L": -0.970685789,
          "M": 29.14291171,
          "S": 0.185201039
        },
        {
          "Agemos": 109.5,
          "L": -0.958529157,
          "M": 29.44980208,
          "S": 0.186225287
        },
        {
          "Agemos": 110.5,
          "L": -0.946640568,
          "M": 29.76048479,
          "S": 0.187231416
        },
        {
          "Agemos": 111.5,
          "L": -0.935042447,
          "M": 30.0749257,
          "S": 0.188217723
        },
        {
          "Agemos": 112.5,
          "L": -0.923756041,
          "M": 30.39308176,
          "S": 0.18918255
        },
        {
          "Agemos": 113.5,
          "L": -0.912801445,
          "M": 30.71490093,
          "S": 0.190124286
        },
        {
          "Agemos": 114.5,
          "L": -0.902197638,
          "M": 31.0403221,
          "S": 0.191041375
        },
        {
          "Agemos": 115.5,
          "L": -0.891962513,
          "M": 31.36927506,
          "S": 0.191932319
        },
        {
          "Agemos": 116.5,
          "L": -0.882112919,
          "M": 31.7016805,
          "S": 0.192795682
        },
        {
          "Agemos": 117.5,
          "L": -0.872664706,
          "M": 32.03744999,
          "S": 0.193630095
        },
        {
          "Agemos": 118.5,
          "L": -0.863632768,
          "M": 32.37648607,
          "S": 0.19443426
        },
        {
          "Agemos": 119.5,
          "L": -0.855031092,
          "M": 32.71868225,
          "S": 0.195206948
        },
        {
          "Agemos": 120.5,
          "L": -0.846872805,
          "M": 33.06392318,
          "S": 0.195947008
        },
        {
          "Agemos": 121.5,
          "L": -0.839170224,
          "M": 33.4120847,
          "S": 0.196653365
        },
        {
          "Agemos": 122.5,
          "L": -0.831934903,
          "M": 33.76303402,
          "S": 0.197325023
        },
        {
          "Agemos": 123.5,
          "L": -0.825177688,
          "M": 34.1166299,
          "S": 0.197961065
        },
        {
          "Agemos": 124.5,
          "L": -0.818908758,
          "M": 34.47272283,
          "S": 0.198560655
        },
        {
          "Agemos": 125.5,
          "L": -0.813137675,
          "M": 34.83115524,
          "S": 0.199123037
        },
        {
          "Agemos": 126.5,
          "L": -0.807873433,
          "M": 35.19176177,
          "S": 0.199647538
        },
        {
          "Agemos": 127.5,
          "L": -0.803122613,
          "M": 35.55437176,
          "S": 0.200133598
        },
        {
          "Agemos": 128.5,
          "L": -0.79889771,
          "M": 35.91879976,
          "S": 0.200580618
        },
        {
          "Agemos": 129.5,
          "L": -0.795203499,
          "M": 36.28486194,
          "S": 0.200988216
        },
        {
          "Agemos": 130.5,
          "L": -0.792047959,
          "M": 36.65236365,
          "S": 0.201356017
        },
        {
          "Agemos": 131.5,
          "L": -0.789435274,
          "M": 37.02110818,
          "S": 0.201683791
        },
        {
          "Agemos": 132.5,
          "L": -0.787374433,
          "M": 37.39088668,
          "S": 0.201971282
        },
        {
          "Agemos": 133.5,
          "L": -0.785870695,
          "M": 37.76148905,
          "S": 0.202218375
        },
        {
          "Agemos": 134.5,
          "L": -0.784929893,
          "M": 38.1326991,
          "S": 0.202425006
        },
        {
          "Agemos": 135.5,
          "L": -0.784557605,
          "M": 38.50429603,
          "S": 0.202591183
        },
        {
          "Agemos": 136.5,
          "L": -0.78475917,
          "M": 38.87605489,
          "S": 0.20271698
        },
        {
          "Agemos": 137.5,
          "L": -0.785539703,
          "M": 39.24774707,
          "S": 0.202802535
        },
        {
          "Agemos": 138.5,
          "L": -0.786904102,
          "M": 39.61914076,
          "S": 0.202848049
        },
        {
          "Agemos": 139.5,
          "L": -0.788858208,
          "M": 39.98999994,
          "S": 0.202853758
        },
        {
          "Agemos": 140.5,
          "L": -0.791403051,
          "M": 40.36009244,
          "S": 0.202820053
        },
        {
          "Agemos": 141.5,
          "L": -0.794546352,
          "M": 40.72917544,
          "S": 0.202747236
        },
        {
          "Agemos": 142.5,
          "L": -0.79829102,
          "M": 41.09701099,
          "S": 0.202635758
        },
        {
          "Agemos": 143.5,
          "L": -0.802640891,
          "M": 41.46335907,
          "S": 0.202486098
        },
        {
          "Agemos": 144.5,
          "L": -0.807599577,
          "M": 41.82797963,
          "S": 0.202298783
        },
        {
          "Agemos": 145.5,
          "L": -0.813170461,
          "M": 42.19063313,
          "S": 0.202074385
        },
        {
          "Agemos": 146.5,
          "L": -0.819356692,
          "M": 42.55108107,
          "S": 0.201813521
        },
        {
          "Agemos": 147.5,
          "L": -0.826161176,
          "M": 42.90908653,
          "S": 0.201516851
        },
        {
          "Agemos": 148.5,
          "L": -0.833586038,
          "M": 43.2644155,
          "S": 0.201185082
        },
        {
          "Agemos": 149.5,
          "L": -0.841634949,
          "M": 43.61683402,
          "S": 0.200818928
        },
        {
          "Agemos": 150.5,
          "L": -0.850307441,
          "M": 43.9661169,
          "S": 0.200419208
        },
        {
          "Agemos": 151.5,
          "L": -0.859607525,
          "M": 44.31203579,
          "S": 0.199986681
        },
        {
          "Agemos": 152.5,
          "L": -0.869534339,
          "M": 44.65437319,
          "S": 0.199522233
        },
        {
          "Agemos": 153.5,
          "L": -0.880088651,
          "M": 44.99291356,
          "S": 0.199026736
        },
        {
          "Agemos": 154.5,
          "L": -0.891270585,
          "M": 45.32744704,
          "S": 0.198501096
        },
        {
          "Agemos": 155.5,
          "L": -0.903079458,
          "M": 45.65777013,
          "S": 0.197946255
        },
        {
          "Agemos": 156.5,
          "L": -0.915513542,
          "M": 45.98368656,
          "S": 0.197363191
        },
        {
          "Agemos": 157.5,
          "L": -0.928569454,
          "M": 46.30500858,
          "S": 0.196752931
        },
        {
          "Agemos": 158.5,
          "L": -0.942245864,
          "M": 46.62155183,
          "S": 0.196116472
        },
        {
          "Agemos": 159.5,
          "L": -0.956537923,
          "M": 46.93314404,
          "S": 0.19545489
        },
        {
          "Agemos": 160.5,
          "L": -0.971440492,
          "M": 47.23962058,
          "S": 0.194769279
        },
        {
          "Agemos": 161.5,
          "L": -0.986947308,
          "M": 47.54082604,
          "S": 0.194060758
        },
        {
          "Agemos": 162.5,
          "L": -1.003050887,
          "M": 47.83661466,
          "S": 0.193330477
        },
        {
          "Agemos": 163.5,
          "L": -1.019742425,
          "M": 48.12685082,
          "S": 0.192579614
        },
        {
          "Agemos": 164.5,
          "L": -1.037011698,
          "M": 48.41140938,
          "S": 0.191809374
        },
        {
          "Agemos": 165.5,
          "L": -1.054846957,
          "M": 48.69017613,
          "S": 0.191020995
        },
        {
          "Agemos": 166.5,
          "L": -1.073234825,
          "M": 48.9630481,
          "S": 0.190215739
        },
        {
          "Agemos": 167.5,
          "L": -1.092160195,
          "M": 49.22993391,
          "S": 0.189394901
        },
        {
          "Agemos": 168.5,
          "L": -1.111606122,
          "M": 49.49075409,
          "S": 0.188559804
        },
        {
          "Agemos": 169.5,
          "L": -1.131553723,
          "M": 49.74544132,
          "S": 0.187711798
        },
        {
          "Agemos": 170.5,
          "L": -1.151982079,
          "M": 49.99394068,
          "S": 0.186852266
        },
        {
          "Agemos": 171.5,
          "L": -1.172868141,
          "M": 50.23620985,
          "S": 0.185982617
        },
        {
          "Agemos": 172.5,
          "L": -1.19418462,
          "M": 50.47222213,
          "S": 0.185104331
        },
        {
          "Agemos": 173.5,
          "L": -1.215907492,
          "M": 50.70195581,
          "S": 0.184218803
        },
        {
          "Agemos": 174.5,
          "L": -1.238005268,
          "M": 50.92540942,
          "S": 0.183327556
        },
        {
          "Agemos": 175.5,
          "L": -1.260445591,
          "M": 51.14259229,
          "S": 0.182432113
        },
        {
          "Agemos": 176.5,
          "L": -1.283193626,
          "M": 51.3535268,
          "S": 0.181534018
        },
        {
          "Agemos": 177.5,
          "L": -1.306212032,
          "M": 51.55824831,
          "S": 0.180634839
        },
        {
          "Agemos": 178.5,
          "L": -1.329460945,
          "M": 51.75680513,
          "S": 0.179736168
        },
        {
          "Agemos": 179.5,
          "L": -1.35289798,
          "M": 51.94925841,
          "S": 0.178839614
        },
        {
          "Agemos": 180.5,
          "L": -1.376478254,
          "M": 52.13568193,
          "S": 0.177946804
        },
        {
          "Agemos": 181.5,
          "L": -1.400154426,
          "M": 52.31616197,
          "S": 0.177059379
        },
        {
          "Agemos": 182.5,
          "L": -1.423876772,
          "M": 52.49079703,
          "S": 0.17617899
        },
        {
          "Agemos": 183.5,
          "L": -1.447593267,
          "M": 52.65969757,
          "S": 0.175307296
        },
        {
          "Agemos": 184.5,
          "L": -1.471249702,
          "M": 52.82298572,
          "S": 0.174445958
        },
        {
          "Agemos": 185.5,
          "L": -1.494789826,
          "M": 52.9807949,
          "S": 0.173596636
        },
        {
          "Agemos": 186.5,
          "L": -1.518155513,
          "M": 53.13326946,
          "S": 0.172760982
        },
        {
          "Agemos": 187.5,
          "L": -1.541286949,
          "M": 53.28056425,
          "S": 0.17194064
        },
        {
          "Agemos": 188.5,
          "L": -1.564122852,
          "M": 53.42284417,
          "S": 0.171137232
        },
        {
          "Agemos": 189.5,
          "L": -1.586600712,
          "M": 53.5602837,
          "S": 0.170352363
        },
        {
          "Agemos": 190.5,
          "L": -1.608657054,
          "M": 53.69306637,
          "S": 0.169587605
        },
        {
          "Agemos": 191.5,
          "L": -1.630227728,
          "M": 53.82138422,
          "S": 0.168844497
        },
        {
          "Agemos": 192.5,
          "L": -1.651248208,
          "M": 53.94543725,
          "S": 0.168124538
        },
        {
          "Agemos": 193.5,
          "L": -1.67165392,
          "M": 54.06543278,
          "S": 0.167429179
        },
        {
          "Agemos": 194.5,
          "L": -1.691380583,
          "M": 54.18158486,
          "S": 0.166759816
        },
        {
          "Agemos": 195.5,
          "L": -1.710364557,
          "M": 54.29411356,
          "S": 0.166117788
        },
        {
          "Agemos": 196.5,
          "L": -1.728543207,
          "M": 54.40324431,
          "S": 0.165504365
        },
        {
          "Agemos": 197.5,
          "L": -1.745855274,
          "M": 54.50920717,
          "S": 0.164920747
        },
        {
          "Agemos": 198.5,
          "L": -1.762241248,
          "M": 54.61223603,
          "S": 0.164368054
        },
        {
          "Agemos": 199.5,
          "L": -1.777643747,
          "M": 54.71256787,
          "S": 0.16384732
        },
        {
          "Agemos": 200.5,
          "L": -1.792007891,
          "M": 54.81044184,
          "S": 0.163359491
        },
        {
          "Agemos": 201.5,
          "L": -1.805281675,
          "M": 54.90609842,
          "S": 0.162905415
        },
        {
          "Agemos": 202.5,
          "L": -1.817416335,
          "M": 54.99977846,
          "S": 0.162485839
        },
        {
          "Agemos": 203.5,
          "L": -1.828366707,
          "M": 55.09172217,
          "S": 0.162101402
        },
        {
          "Agemos": 204.5,
          "L": -1.838091576,
          "M": 55.18216811,
          "S": 0.161752634
        },
        {
          "Agemos": 205.5,
          "L": -1.846554015,
          "M": 55.271352,
          "S": 0.161439944
        },
        {
          "Agemos": 206.5,
          "L": -1.853721704,
          "M": 55.35950558,
          "S": 0.161163623
        },
        {
          "Agemos": 207.5,
          "L": -1.859567242,
          "M": 55.44685531,
          "S": 0.160923833
        },
        {
          "Agemos": 208.5,
          "L": -1.864068443,
          "M": 55.53362107,
          "S": 0.160720609
        },
        {
          "Agemos": 209.5,
          "L": -1.86720861,
          "M": 55.62001464,
          "S": 0.16055385
        },
        {
          "Agemos": 210.5,
          "L": -1.8689768,
          "M": 55.70623826,
          "S": 0.160423319
        },
        {
          "Agemos": 211.5,
          "L": -1.869371157,
          "M": 55.79247939,
          "S": 0.160328578
        },
        {
          "Agemos": 212.5,
          "L": -1.868386498,
          "M": 55.87892356,
          "S": 0.160269232
        },
        {
          "Agemos": 213.5,
          "L": -1.866033924,
          "M": 55.96573022,
          "S": 0.160244549
        },
        {
          "Agemos": 214.5,
          "L": -1.862327775,
          "M": 56.05304601,
          "S": 0.160253714
        },
        {
          "Agemos": 215.5,
          "L": -1.857289195,
          "M": 56.14099882,
          "S": 0.160295765
        },
        {
          "Agemos": 216.5,
          "L": -1.850946286,
          "M": 56.22969564,
          "S": 0.16036959
        },
        {
          "Agemos": 217.5,
          "L": -1.84333425,
          "M": 56.3192203,
          "S": 0.16047393
        },
        {
          "Agemos": 218.5,
          "L": -1.834495505,
          "M": 56.40963105,
          "S": 0.160607377
        },
        {
          "Agemos": 219.5,
          "L": -1.824479785,
          "M": 56.50095811,
          "S": 0.16076838
        },
        {
          "Agemos": 220.5,
          "L": -1.813344222,
          "M": 56.59320107,
          "S": 0.160955249
        },
        {
          "Agemos": 221.5,
          "L": -1.801153404,
          "M": 56.68632619,
          "S": 0.161166157
        },
        {
          "Agemos": 222.5,
          "L": -1.787979408,
          "M": 56.78026364,
          "S": 0.161399151
        },
        {
          "Agemos": 223.5,
          "L": -1.773901816,
          "M": 56.87490465,
          "S": 0.161652158
        },
        {
          "Agemos": 224.5,
          "L": -1.759007704,
          "M": 56.97009856,
          "S": 0.161922998
        },
        {
          "Agemos": 225.5,
          "L": -1.743391606,
          "M": 57.06564989,
          "S": 0.162209399
        },
        {
          "Agemos": 226.5,
          "L": -1.72715546,
          "M": 57.16131528,
          "S": 0.162509006
        },
        {
          "Agemos": 227.5,
          "L": -1.710410733,
          "M": 57.25679821,
          "S": 0.162819353
        },
        {
          "Agemos": 228.5,
          "L": -1.693267093,
          "M": 57.35175792,
          "S": 0.163138124
        },
        {
          "Agemos": 229.5,
          "L": -1.67585442,
          "M": 57.44578172,
          "S": 0.163462715
        },
        {
          "Agemos": 230.5,
          "L": -1.658302847,
          "M": 57.53840429,
          "S": 0.163790683
        },
        {
          "Agemos": 231.5,
          "L": -1.640747464,
          "M": 57.62910094,
          "S": 0.164119574
        },
        {
          "Agemos": 232.5,
          "L": -1.623332891,
          "M": 57.7172758,
          "S": 0.164446997
        },
        {
          "Agemos": 233.5,
          "L": -1.606209374,
          "M": 57.80226553,
          "S": 0.164770638
        },
        {
          "Agemos": 234.5,
          "L": -1.589533346,
          "M": 57.88333502,
          "S": 0.165088289
        },
        {
          "Agemos": 235.5,
          "L": -1.573467222,
          "M": 57.95967458,
          "S": 0.165397881
        },
        {
          "Agemos": 236.5,
          "L": -1.558179166,
          "M": 58.0303973,
          "S": 0.165697507
        },
        {
          "Agemos": 237.5,
          "L": -1.543846192,
          "M": 58.09453209,
          "S": 0.165985386
        },
        {
          "Agemos": 238.5,
          "L": -1.530642461,
          "M": 58.15103575,
          "S": 0.166260109
        },
        {
          "Agemos": 239.5,
          "L": -1.518754013,
          "M": 58.1987714,
          "S": 0.16652037
        },
        {
          "Agemos": 240,
          "L": -1.51336185,
          "M": 58.21897289,
          "S": 0.166644749
        }
      ]
}
    
export let bmiage = {
    "M": [
        {
          "Agemos": 24.5,
          "L": -1.982373595,
          "M": 16.54777487,
          "S": 0.080127429
        },
        {
          "Agemos": 25.5,
          "L": -1.924100169,
          "M": 16.49442763,
          "S": 0.079233994
        },
        {
          "Agemos": 26.5,
          "L": -1.86549793,
          "M": 16.44259552,
          "S": 0.078389356
        },
        {
          "Agemos": 27.5,
          "L": -1.807261899,
          "M": 16.3922434,
          "S": 0.077593501
        },
        {
          "Agemos": 28.5,
          "L": -1.750118905,
          "M": 16.34333654,
          "S": 0.076846462
        },
        {
          "Agemos": 29.5,
          "L": -1.69481584,
          "M": 16.29584097,
          "S": 0.076148308
        },
        {
          "Agemos": 30.5,
          "L": -1.642106779,
          "M": 16.24972371,
          "S": 0.075499126
        },
        {
          "Agemos": 31.5,
          "L": -1.592744414,
          "M": 16.20495268,
          "S": 0.074898994
        },
        {
          "Agemos": 32.5,
          "L": -1.547442391,
          "M": 16.16149871,
          "S": 0.074347997
        },
        {
          "Agemos": 33.5,
          "L": -1.506902601,
          "M": 16.11933258,
          "S": 0.073846139
        },
        {
          "Agemos": 34.5,
          "L": -1.471770047,
          "M": 16.07842758,
          "S": 0.07339337
        },
        {
          "Agemos": 35.5,
          "L": -1.442628957,
          "M": 16.03875896,
          "S": 0.072989551
        },
        {
          "Agemos": 36.5,
          "L": -1.419991255,
          "M": 16.00030401,
          "S": 0.072634432
        },
        {
          "Agemos": 37.5,
          "L": -1.404277619,
          "M": 15.96304277,
          "S": 0.072327649
        },
        {
          "Agemos": 38.5,
          "L": -1.39586317,
          "M": 15.92695418,
          "S": 0.07206864
        },
        {
          "Agemos": 39.5,
          "L": -1.394935252,
          "M": 15.89202582,
          "S": 0.071856805
        },
        {
          "Agemos": 40.5,
          "L": -1.401671596,
          "M": 15.85824093,
          "S": 0.071691278
        },
        {
          "Agemos": 41.5,
          "L": -1.416100312,
          "M": 15.82558822,
          "S": 0.071571093
        },
        {
          "Agemos": 42.5,
          "L": -1.438164899,
          "M": 15.79405728,
          "S": 0.071495113
        },
        {
          "Agemos": 43.5,
          "L": -1.467669032,
          "M": 15.76364255,
          "S": 0.071462106
        },
        {
          "Agemos": 44.5,
          "L": -1.504376347,
          "M": 15.73433668,
          "S": 0.071470646
        },
        {
          "Agemos": 45.5,
          "L": -1.547942838,
          "M": 15.70613566,
          "S": 0.071519218
        },
        {
          "Agemos": 46.5,
          "L": -1.597896397,
          "M": 15.67904062,
          "S": 0.071606277
        },
        {
          "Agemos": 47.5,
          "L": -1.653732283,
          "M": 15.65305192,
          "S": 0.071730167
        },
        {
          "Agemos": 48.5,
          "L": -1.714869347,
          "M": 15.62817269,
          "S": 0.071889214
        },
        {
          "Agemos": 49.5,
          "L": -1.780673181,
          "M": 15.604408,
          "S": 0.072081737
        },
        {
          "Agemos": 50.5,
          "L": -1.850468473,
          "M": 15.58176458,
          "S": 0.072306081
        },
        {
          "Agemos": 51.5,
          "L": -1.923551865,
          "M": 15.56025067,
          "S": 0.072560637
        },
        {
          "Agemos": 52.5,
          "L": -1.999220429,
          "M": 15.5398746,
          "S": 0.07284384
        },
        {
          "Agemos": 53.5,
          "L": -2.076707178,
          "M": 15.52064993,
          "S": 0.073154324
        },
        {
          "Agemos": 54.5,
          "L": -2.155348017,
          "M": 15.50258427,
          "S": 0.073490667
        },
        {
          "Agemos": 55.5,
          "L": -2.234438552,
          "M": 15.48568973,
          "S": 0.073851672
        },
        {
          "Agemos": 56.5,
          "L": -2.313321723,
          "M": 15.46997718,
          "S": 0.074236235
        },
        {
          "Agemos": 57.5,
          "L": -2.391381273,
          "M": 15.45545692,
          "S": 0.074643374
        },
        {
          "Agemos": 58.5,
          "L": -2.468032491,
          "M": 15.44213961,
          "S": 0.075072264
        },
        {
          "Agemos": 59.5,
          "L": -2.542781541,
          "M": 15.43003207,
          "S": 0.075522104
        },
        {
          "Agemos": 60.5,
          "L": -2.61516595,
          "M": 15.41914163,
          "S": 0.07599225
        },
        {
          "Agemos": 61.5,
          "L": -2.684789516,
          "M": 15.40947356,
          "S": 0.076482128
        },
        {
          "Agemos": 62.5,
          "L": -2.751316949,
          "M": 15.40103139,
          "S": 0.076991232
        },
        {
          "Agemos": 63.5,
          "L": -2.81445945,
          "M": 15.39381785,
          "S": 0.077519149
        },
        {
          "Agemos": 64.5,
          "L": -2.87402476,
          "M": 15.38783094,
          "S": 0.07806539
        },
        {
          "Agemos": 65.5,
          "L": -2.92984048,
          "M": 15.38306945,
          "S": 0.078629592
        },
        {
          "Agemos": 66.5,
          "L": -2.981796828,
          "M": 15.37952958,
          "S": 0.079211369
        },
        {
          "Agemos": 67.5,
          "L": -3.029831343,
          "M": 15.37720582,
          "S": 0.079810334
        },
        {
          "Agemos": 68.5,
          "L": -3.073924224,
          "M": 15.37609107,
          "S": 0.080426086
        },
        {
          "Agemos": 69.5,
          "L": -3.114093476,
          "M": 15.37617677,
          "S": 0.081058206
        },
        {
          "Agemos": 70.5,
          "L": -3.15039004,
          "M": 15.37745304,
          "S": 0.081706249
        },
        {
          "Agemos": 71.5,
          "L": -3.182893018,
          "M": 15.37990886,
          "S": 0.082369741
        },
        {
          "Agemos": 72.5,
          "L": -3.21170511,
          "M": 15.38353217,
          "S": 0.083048178
        },
        {
          "Agemos": 73.5,
          "L": -3.23694834,
          "M": 15.38831005,
          "S": 0.083741021
        },
        {
          "Agemos": 74.5,
          "L": -3.25876011,
          "M": 15.39422883,
          "S": 0.0844477
        },
        {
          "Agemos": 75.5,
          "L": -3.277281546,
          "M": 15.40127496,
          "S": 0.085167651
        },
        {
          "Agemos": 76.5,
          "L": -3.292683774,
          "M": 15.40943252,
          "S": 0.085900184
        },
        {
          "Agemos": 77.5,
          "L": -3.305124073,
          "M": 15.41868691,
          "S": 0.086644667
        },
        {
          "Agemos": 78.5,
          "L": -3.314768951,
          "M": 15.42902273,
          "S": 0.087400421
        },
        {
          "Agemos": 79.5,
          "L": -3.321785992,
          "M": 15.44042439,
          "S": 0.088166744
        },
        {
          "Agemos": 80.5,
          "L": -3.326345795,
          "M": 15.45287581,
          "S": 0.088942897
        },
        {
          "Agemos": 81.5,
          "L": -3.328602731,
          "M": 15.46636218,
          "S": 0.089728202
        },
        {
          "Agemos": 82.5,
          "L": -3.328725277,
          "M": 15.48086704,
          "S": 0.090521875
        },
        {
          "Agemos": 83.5,
          "L": -3.32687018,
          "M": 15.49637465,
          "S": 0.091323162
        },
        {
          "Agemos": 84.5,
          "L": -3.323188896,
          "M": 15.51286936,
          "S": 0.092131305
        },
        {
          "Agemos": 85.5,
          "L": -3.317827016,
          "M": 15.53033563,
          "S": 0.092945544
        },
        {
          "Agemos": 86.5,
          "L": -3.310923871,
          "M": 15.54875807,
          "S": 0.093765118
        },
        {
          "Agemos": 87.5,
          "L": -3.302612272,
          "M": 15.56812143,
          "S": 0.09458927
        },
        {
          "Agemos": 88.5,
          "L": -3.293018361,
          "M": 15.58841065,
          "S": 0.095417247
        },
        {
          "Agemos": 89.5,
          "L": -3.282260813,
          "M": 15.60961101,
          "S": 0.096248301
        },
        {
          "Agemos": 90.5,
          "L": -3.270454609,
          "M": 15.63170735,
          "S": 0.097081694
        },
        {
          "Agemos": 91.5,
          "L": -3.257703616,
          "M": 15.65468563,
          "S": 0.097916698
        },
        {
          "Agemos": 92.5,
          "L": -3.244108214,
          "M": 15.67853139,
          "S": 0.098752593
        },
        {
          "Agemos": 93.5,
          "L": -3.229761713,
          "M": 15.70323052,
          "S": 0.099588675
        },
        {
          "Agemos": 94.5,
          "L": -3.214751287,
          "M": 15.72876911,
          "S": 0.100424251
        },
        {
          "Agemos": 95.5,
          "L": -3.199158184,
          "M": 15.75513347,
          "S": 0.101258643
        },
        {
          "Agemos": 96.5,
          "L": -3.18305795,
          "M": 15.78231007,
          "S": 0.102091189
        },
        {
          "Agemos": 97.5,
          "L": -3.166520664,
          "M": 15.8102856,
          "S": 0.102921245
        },
        {
          "Agemos": 98.5,
          "L": -3.1496103,
          "M": 15.83904708,
          "S": 0.103748189
        },
        {
          "Agemos": 99.5,
          "L": -3.132389637,
          "M": 15.86858123,
          "S": 0.104571386
        },
        {
          "Agemos": 100.5,
          "L": -3.114911153,
          "M": 15.89887562,
          "S": 0.105390269
        },
        {
          "Agemos": 101.5,
          "L": -3.097226399,
          "M": 15.92991765,
          "S": 0.106204258
        },
        {
          "Agemos": 102.5,
          "L": -3.079383079,
          "M": 15.96169481,
          "S": 0.107012788
        },
        {
          "Agemos": 103.5,
          "L": -3.061423765,
          "M": 15.99419489,
          "S": 0.107815327
        },
        {
          "Agemos": 104.5,
          "L": -3.043386071,
          "M": 16.02740607,
          "S": 0.108611374
        },
        {
          "Agemos": 105.5,
          "L": -3.025310003,
          "M": 16.0613159,
          "S": 0.109400388
        },
        {
          "Agemos": 106.5,
          "L": -3.007225737,
          "M": 16.09591292,
          "S": 0.110181915
        },
        {
          "Agemos": 107.5,
          "L": -2.989164598,
          "M": 16.13118532,
          "S": 0.110955478
        },
        {
          "Agemos": 108.5,
          "L": -2.971148225,
          "M": 16.16712234,
          "S": 0.111720691
        },
        {
          "Agemos": 109.5,
          "L": -2.953208047,
          "M": 16.20371168,
          "S": 0.112477059
        },
        {
          "Agemos": 110.5,
          "L": -2.935363951,
          "M": 16.24094239,
          "S": 0.1132242
        },
        {
          "Agemos": 111.5,
          "L": -2.917635157,
          "M": 16.27880346,
          "S": 0.113961734
        },
        {
          "Agemos": 112.5,
          "L": -2.900039803,
          "M": 16.31728385,
          "S": 0.114689291
        },
        {
          "Agemos": 113.5,
          "L": -2.882593796,
          "M": 16.35637267,
          "S": 0.115406523
        },
        {
          "Agemos": 114.5,
          "L": -2.865311266,
          "M": 16.39605916,
          "S": 0.116113097
        },
        {
          "Agemos": 115.5,
          "L": -2.848204697,
          "M": 16.43633265,
          "S": 0.116808702
        },
        {
          "Agemos": 116.5,
          "L": -2.831285052,
          "M": 16.47718256,
          "S": 0.117493042
        },
        {
          "Agemos": 117.5,
          "L": -2.81456189,
          "M": 16.51859843,
          "S": 0.11816584
        },
        {
          "Agemos": 118.5,
          "L": -2.79804347,
          "M": 16.56056987,
          "S": 0.118826835
        },
        {
          "Agemos": 119.5,
          "L": -2.781736856,
          "M": 16.60308661,
          "S": 0.119475785
        },
        {
          "Agemos": 120.5,
          "L": -2.765648008,
          "M": 16.64613844,
          "S": 0.120112464
        },
        {
          "Agemos": 121.5,
          "L": -2.749782197,
          "M": 16.68971518,
          "S": 0.120736656
        },
        {
          "Agemos": 122.5,
          "L": -2.734142443,
          "M": 16.73380695,
          "S": 0.121348181
        },
        {
          "Agemos": 123.5,
          "L": -2.718732873,
          "M": 16.77840363,
          "S": 0.121946849
        },
        {
          "Agemos": 124.5,
          "L": -2.703555506,
          "M": 16.82349538,
          "S": 0.122532501
        },
        {
          "Agemos": 125.5,
          "L": -2.688611957,
          "M": 16.86907238,
          "S": 0.123104991
        },
        {
          "Agemos": 126.5,
          "L": -2.673903164,
          "M": 16.91512487,
          "S": 0.123664186
        },
        {
          "Agemos": 127.5,
          "L": -2.659429443,
          "M": 16.96164317,
          "S": 0.124209969
        },
        {
          "Agemos": 128.5,
          "L": -2.645190534,
          "M": 17.00861766,
          "S": 0.124742239
        },
        {
          "Agemos": 129.5,
          "L": -2.631185649,
          "M": 17.05603879,
          "S": 0.125260905
        },
        {
          "Agemos": 130.5,
          "L": -2.617413511,
          "M": 17.10389705,
          "S": 0.125765895
        },
        {
          "Agemos": 131.5,
          "L": -2.603872392,
          "M": 17.15218302,
          "S": 0.126257147
        },
        {
          "Agemos": 132.5,
          "L": -2.590560148,
          "M": 17.20088732,
          "S": 0.126734613
        },
        {
          "Agemos": 133.5,
          "L": -2.577474253,
          "M": 17.25000062,
          "S": 0.12719826
        },
        {
          "Agemos": 134.5,
          "L": -2.564611831,
          "M": 17.29951367,
          "S": 0.127648067
        },
        {
          "Agemos": 135.5,
          "L": -2.551969684,
          "M": 17.34941726,
          "S": 0.128084023
        },
        {
          "Agemos": 136.5,
          "L": -2.539539972,
          "M": 17.39970308,
          "S": 0.128506192
        },
        {
          "Agemos": 137.5,
          "L": -2.527325681,
          "M": 17.45036072,
          "S": 0.128914497
        },
        {
          "Agemos": 138.5,
          "L": -2.515320235,
          "M": 17.50138161,
          "S": 0.129309001
        },
        {
          "Agemos": 139.5,
          "L": -2.503519447,
          "M": 17.55275674,
          "S": 0.129689741
        },
        {
          "Agemos": 140.5,
          "L": -2.491918934,
          "M": 17.60447714,
          "S": 0.130056765
        },
        {
          "Agemos": 141.5,
          "L": -2.480514136,
          "M": 17.6565339,
          "S": 0.130410133
        },
        {
          "Agemos": 142.5,
          "L": -2.469300331,
          "M": 17.70891811,
          "S": 0.130749913
        },
        {
          "Agemos": 143.5,
          "L": -2.458272656,
          "M": 17.76162094,
          "S": 0.131076187
        },
        {
          "Agemos": 144.5,
          "L": -2.447426113,
          "M": 17.81463359,
          "S": 0.131389042
        },
        {
          "Agemos": 145.5,
          "L": -2.436755595,
          "M": 17.86794729,
          "S": 0.131688579
        },
        {
          "Agemos": 146.5,
          "L": -2.426255887,
          "M": 17.92155332,
          "S": 0.131974905
        },
        {
          "Agemos": 147.5,
          "L": -2.415921689,
          "M": 17.97544299,
          "S": 0.132248138
        },
        {
          "Agemos": 148.5,
          "L": -2.405747619,
          "M": 18.02960765,
          "S": 0.132508403
        },
        {
          "Agemos": 149.5,
          "L": -2.395728233,
          "M": 18.08403868,
          "S": 0.132755834
        },
        {
          "Agemos": 150.5,
          "L": -2.385858029,
          "M": 18.1387275,
          "S": 0.132990575
        },
        {
          "Agemos": 151.5,
          "L": -2.376131459,
          "M": 18.19366555,
          "S": 0.133212776
        },
        {
          "Agemos": 152.5,
          "L": -2.366542942,
          "M": 18.24884431,
          "S": 0.133422595
        },
        {
          "Agemos": 153.5,
          "L": -2.357086871,
          "M": 18.3042553,
          "S": 0.133620197
        },
        {
          "Agemos": 154.5,
          "L": -2.347757625,
          "M": 18.35989003,
          "S": 0.133805756
        },
        {
          "Agemos": 155.5,
          "L": -2.338549576,
          "M": 18.41574009,
          "S": 0.133979452
        },
        {
          "Agemos": 156.5,
          "L": -2.3294571,
          "M": 18.47179706,
          "S": 0.13414147
        },
        {
          "Agemos": 157.5,
          "L": -2.320474586,
          "M": 18.52805255,
          "S": 0.134292005
        },
        {
          "Agemos": 158.5,
          "L": -2.311596446,
          "M": 18.5844982,
          "S": 0.134431256
        },
        {
          "Agemos": 159.5,
          "L": -2.302817124,
          "M": 18.64112567,
          "S": 0.134559427
        },
        {
          "Agemos": 160.5,
          "L": -2.294131107,
          "M": 18.69792663,
          "S": 0.134676731
        },
        {
          "Agemos": 161.5,
          "L": -2.285532933,
          "M": 18.75489278,
          "S": 0.134783385
        },
        {
          "Agemos": 162.5,
          "L": -2.277017201,
          "M": 18.81201584,
          "S": 0.134879611
        },
        {
          "Agemos": 163.5,
          "L": -2.268578584,
          "M": 18.86928753,
          "S": 0.134965637
        },
        {
          "Agemos": 164.5,
          "L": -2.260211837,
          "M": 18.92669959,
          "S": 0.135041695
        },
        {
          "Agemos": 165.5,
          "L": -2.251911809,
          "M": 18.98424378,
          "S": 0.135108024
        },
        {
          "Agemos": 166.5,
          "L": -2.243673453,
          "M": 19.04191185,
          "S": 0.135164867
        },
        {
          "Agemos": 167.5,
          "L": -2.235491842,
          "M": 19.09969557,
          "S": 0.135212469
        },
        {
          "Agemos": 168.5,
          "L": -2.227362173,
          "M": 19.15758672,
          "S": 0.135251083
        },
        {
          "Agemos": 169.5,
          "L": -2.21927979,
          "M": 19.21557707,
          "S": 0.135280963
        },
        {
          "Agemos": 170.5,
          "L": -2.211240187,
          "M": 19.27365839,
          "S": 0.135302371
        },
        {
          "Agemos": 171.5,
          "L": -2.203239029,
          "M": 19.33182247,
          "S": 0.135315568
        },
        {
          "Agemos": 172.5,
          "L": -2.195272161,
          "M": 19.39006106,
          "S": 0.135320824
        },
        {
          "Agemos": 173.5,
          "L": -2.187335625,
          "M": 19.44836594,
          "S": 0.135318407
        },
        {
          "Agemos": 174.5,
          "L": -2.179425674,
          "M": 19.50672885,
          "S": 0.135308594
        },
        {
          "Agemos": 175.5,
          "L": -2.171538789,
          "M": 19.56514153,
          "S": 0.135291662
        },
        {
          "Agemos": 176.5,
          "L": -2.163671689,
          "M": 19.62359571,
          "S": 0.135267891
        },
        {
          "Agemos": 177.5,
          "L": -2.155821357,
          "M": 19.6820831,
          "S": 0.135237567
        },
        {
          "Agemos": 178.5,
          "L": -2.147985046,
          "M": 19.74059538,
          "S": 0.135200976
        },
        {
          "Agemos": 179.5,
          "L": -2.140160305,
          "M": 19.7991242,
          "S": 0.135158409
        },
        {
          "Agemos": 180.5,
          "L": -2.132344989,
          "M": 19.85766121,
          "S": 0.135110159
        },
        {
          "Agemos": 181.5,
          "L": -2.124537282,
          "M": 19.916198,
          "S": 0.135056522
        },
        {
          "Agemos": 182.5,
          "L": -2.116735712,
          "M": 19.97472615,
          "S": 0.134997797
        },
        {
          "Agemos": 183.5,
          "L": -2.108939167,
          "M": 20.03323719,
          "S": 0.134934285
        },
        {
          "Agemos": 184.5,
          "L": -2.10114692,
          "M": 20.09172262,
          "S": 0.134866291
        },
        {
          "Agemos": 185.5,
          "L": -2.093358637,
          "M": 20.15017387,
          "S": 0.134794121
        },
        {
          "Agemos": 186.5,
          "L": -2.085574403,
          "M": 20.20858236,
          "S": 0.134718085
        },
        {
          "Agemos": 187.5,
          "L": -2.077794735,
          "M": 20.26693944,
          "S": 0.134638494
        },
        {
          "Agemos": 188.5,
          "L": -2.070020599,
          "M": 20.32523642,
          "S": 0.134555663
        },
        {
          "Agemos": 189.5,
          "L": -2.062253431,
          "M": 20.38346455,
          "S": 0.13446991
        },
        {
          "Agemos": 190.5,
          "L": -2.054495145,
          "M": 20.44161501,
          "S": 0.134381553
        },
        {
          "Agemos": 191.5,
          "L": -2.046748156,
          "M": 20.49967894,
          "S": 0.134290916
        },
        {
          "Agemos": 192.5,
          "L": -2.039015385,
          "M": 20.5576474,
          "S": 0.134198323
        },
        {
          "Agemos": 193.5,
          "L": -2.031300282,
          "M": 20.6155114,
          "S": 0.134104101
        },
        {
          "Agemos": 194.5,
          "L": -2.023606828,
          "M": 20.67326189,
          "S": 0.134008581
        },
        {
          "Agemos": 195.5,
          "L": -2.015942013,
          "M": 20.73088905,
          "S": 0.133912066
        },
        {
          "Agemos": 196.5,
          "L": -2.008305745,
          "M": 20.7883851,
          "S": 0.133814954
        },
        {
          "Agemos": 197.5,
          "L": -2.000706389,
          "M": 20.84574003,
          "S": 0.133717552
        },
        {
          "Agemos": 198.5,
          "L": -1.993150137,
          "M": 20.90294449,
          "S": 0.1336202
        },
        {
          "Agemos": 199.5,
          "L": -1.985643741,
          "M": 20.95998909,
          "S": 0.133523244
        },
        {
          "Agemos": 200.5,
          "L": -1.97819451,
          "M": 21.01686433,
          "S": 0.133427032
        },
        {
          "Agemos": 201.5,
          "L": -1.970810308,
          "M": 21.07356067,
          "S": 0.133331914
        },
        {
          "Agemos": 202.5,
          "L": -1.96349954,
          "M": 21.1300685,
          "S": 0.133238245
        },
        {
          "Agemos": 203.5,
          "L": -1.956271141,
          "M": 21.18637813,
          "S": 0.133146383
        },
        {
          "Agemos": 204.5,
          "L": -1.949134561,
          "M": 21.24247982,
          "S": 0.13305669
        },
        {
          "Agemos": 205.5,
          "L": -1.942099744,
          "M": 21.29836376,
          "S": 0.132969531
        },
        {
          "Agemos": 206.5,
          "L": -1.935177101,
          "M": 21.35402009,
          "S": 0.132885274
        },
        {
          "Agemos": 207.5,
          "L": -1.92837748,
          "M": 21.40943891,
          "S": 0.132804292
        },
        {
          "Agemos": 208.5,
          "L": -1.921712136,
          "M": 21.46461026,
          "S": 0.132726962
        },
        {
          "Agemos": 209.5,
          "L": -1.915192685,
          "M": 21.51952414,
          "S": 0.132653664
        },
        {
          "Agemos": 210.5,
          "L": -1.908831065,
          "M": 21.57417053,
          "S": 0.132584784
        },
        {
          "Agemos": 211.5,
          "L": -1.902639482,
          "M": 21.62853937,
          "S": 0.132520711
        },
        {
          "Agemos": 212.5,
          "L": -1.896630358,
          "M": 21.68262062,
          "S": 0.132461838
        },
        {
          "Agemos": 213.5,
          "L": -1.890816268,
          "M": 21.73640419,
          "S": 0.132408563
        },
        {
          "Agemos": 214.5,
          "L": -1.885209876,
          "M": 21.78988003,
          "S": 0.132361289
        },
        {
          "Agemos": 215.5,
          "L": -1.879823505,
          "M": 21.84303819,
          "S": 0.132320427
        },
        {
          "Agemos": 216.5,
          "L": -1.874670324,
          "M": 21.8958685,
          "S": 0.132286382
        },
        {
          "Agemos": 217.5,
          "L": -1.869760299,
          "M": 21.94836168,
          "S": 0.1322596
        },
        {
          "Agemos": 218.5,
          "L": -1.865113245,
          "M": 22.00050569,
          "S": 0.132240418
        },
        {
          "Agemos": 219.5,
          "L": -1.860734944,
          "M": 22.05229242,
          "S": 0.13222933
        },
        {
          "Agemos": 220.5,
          "L": -1.85663384,
          "M": 22.10371305,
          "S": 0.132226801
        },
        {
          "Agemos": 221.5,
          "L": -1.852827186,
          "M": 22.15475603,
          "S": 0.132233201
        },
        {
          "Agemos": 222.5,
          "L": -1.849323204,
          "M": 22.20541249,
          "S": 0.132248993
        },
        {
          "Agemos": 223.5,
          "L": -1.846131607,
          "M": 22.255673,
          "S": 0.132274625
        },
        {
          "Agemos": 224.5,
          "L": -1.843261294,
          "M": 22.30552831,
          "S": 0.132310549
        },
        {
          "Agemos": 225.5,
          "L": -1.840720248,
          "M": 22.3549693,
          "S": 0.132357221
        },
        {
          "Agemos": 226.5,
          "L": -1.83851544,
          "M": 22.40398706,
          "S": 0.132415103
        },
        {
          "Agemos": 227.5,
          "L": -1.83665586,
          "M": 22.45257182,
          "S": 0.132484631
        },
        {
          "Agemos": 228.5,
          "L": -1.835138046,
          "M": 22.50071778,
          "S": 0.132566359
        },
        {
          "Agemos": 229.5,
          "L": -1.833972004,
          "M": 22.54841437,
          "S": 0.132660699
        },
        {
          "Agemos": 230.5,
          "L": -1.833157751,
          "M": 22.59565422,
          "S": 0.132768153
        },
        {
          "Agemos": 231.5,
          "L": -1.83269562,
          "M": 22.64242956,
          "S": 0.132889211
        },
        {
          "Agemos": 232.5,
          "L": -1.832584342,
          "M": 22.68873292,
          "S": 0.133024368
        },
        {
          "Agemos": 233.5,
          "L": -1.832820974,
          "M": 22.73455713,
          "S": 0.133174129
        },
        {
          "Agemos": 234.5,
          "L": -1.833400825,
          "M": 22.7798953,
          "S": 0.133338999
        },
        {
          "Agemos": 235.5,
          "L": -1.834317405,
          "M": 22.82474087,
          "S": 0.133519496
        },
        {
          "Agemos": 236.5,
          "L": -1.83555752,
          "M": 22.86908912,
          "S": 0.133716192
        },
        {
          "Agemos": 237.5,
          "L": -1.837119466,
          "M": 22.91293151,
          "S": 0.133929525
        },
        {
          "Agemos": 238.5,
          "L": -1.838987063,
          "M": 22.95626373,
          "S": 0.134160073
        },
        {
          "Agemos": 239.5,
          "L": -1.841146139,
          "M": 22.99908062,
          "S": 0.134408381
        },
        {
          "Agemos": 240,
          "L": -1.84233016,
          "M": 23.02029424,
          "S": 0.134539365
        }
      ],
    "F": [
        {
          "Agemos": 24.5,
          "L": -1.024496827,
          "M": 16.38804056,
          "S": 0.085025838
        },
        {
          "Agemos": 25.5,
          "L": -1.102698353,
          "M": 16.3189719,
          "S": 0.084214052
        },
        {
          "Agemos": 26.5,
          "L": -1.18396635,
          "M": 16.25207985,
          "S": 0.083455124
        },
        {
          "Agemos": 27.5,
          "L": -1.268071036,
          "M": 16.18734669,
          "S": 0.082748284
        },
        {
          "Agemos": 28.5,
          "L": -1.354751525,
          "M": 16.12475448,
          "S": 0.082092737
        },
        {
          "Agemos": 29.5,
          "L": -1.443689692,
          "M": 16.06428762,
          "S": 0.081487717
        },
        {
          "Agemos": 30.5,
          "L": -1.53454192,
          "M": 16.00593001,
          "S": 0.080932448
        },
        {
          "Agemos": 31.5,
          "L": -1.626928093,
          "M": 15.94966631,
          "S": 0.080426175
        },
        {
          "Agemos": 32.5,
          "L": -1.720434829,
          "M": 15.89548197,
          "S": 0.079968176
        },
        {
          "Agemos": 33.5,
          "L": -1.814635262,
          "M": 15.84336179,
          "S": 0.079557735
        },
        {
          "Agemos": 34.5,
          "L": -1.909076262,
          "M": 15.79329146,
          "S": 0.079194187
        },
        {
          "Agemos": 35.5,
          "L": -2.003296102,
          "M": 15.7452564,
          "S": 0.078876895
        },
        {
          "Agemos": 36.5,
          "L": -2.096828937,
          "M": 15.69924188,
          "S": 0.078605255
        },
        {
          "Agemos": 37.5,
          "L": -2.189211877,
          "M": 15.65523282,
          "S": 0.078378696
        },
        {
          "Agemos": 38.5,
          "L": -2.279991982,
          "M": 15.61321371,
          "S": 0.078196674
        },
        {
          "Agemos": 39.5,
          "L": -2.368732949,
          "M": 15.57316843,
          "S": 0.078058667
        },
        {
          "Agemos": 40.5,
          "L": -2.455021314,
          "M": 15.53508019,
          "S": 0.077964169
        },
        {
          "Agemos": 41.5,
          "L": -2.538471972,
          "M": 15.49893145,
          "S": 0.077912684
        },
        {
          "Agemos": 42.5,
          "L": -2.618732901,
          "M": 15.46470384,
          "S": 0.077903716
        },
        {
          "Agemos": 43.5,
          "L": -2.695488973,
          "M": 15.43237817,
          "S": 0.077936763
        },
        {
          "Agemos": 44.5,
          "L": -2.768464816,
          "M": 15.40193436,
          "S": 0.078011309
        },
        {
          "Agemos": 45.5,
          "L": -2.837426693,
          "M": 15.37335154,
          "S": 0.078126817
        },
        {
          "Agemos": 46.5,
          "L": -2.902178205,
          "M": 15.34660842,
          "S": 0.078282739
        },
        {
          "Agemos": 47.5,
          "L": -2.962580386,
          "M": 15.32168181,
          "S": 0.078478449
        },
        {
          "Agemos": 48.5,
          "L": -3.018521987,
          "M": 15.29854897,
          "S": 0.078713325
        },
        {
          "Agemos": 49.5,
          "L": -3.069936555,
          "M": 15.27718618,
          "S": 0.078986694
        },
        {
          "Agemos": 50.5,
          "L": -3.116795864,
          "M": 15.2575692,
          "S": 0.079297841
        },
        {
          "Agemos": 51.5,
          "L": -3.159107331,
          "M": 15.23967338,
          "S": 0.079646006
        },
        {
          "Agemos": 52.5,
          "L": -3.196911083,
          "M": 15.22347371,
          "S": 0.080030389
        },
        {
          "Agemos": 53.5,
          "L": -3.230276759,
          "M": 15.20894491,
          "S": 0.080450145
        },
        {
          "Agemos": 54.5,
          "L": -3.259300182,
          "M": 15.19606152,
          "S": 0.080904391
        },
        {
          "Agemos": 55.5,
          "L": -3.284099963,
          "M": 15.18479799,
          "S": 0.081392203
        },
        {
          "Agemos": 56.5,
          "L": -3.30481415,
          "M": 15.17512871,
          "S": 0.081912623
        },
        {
          "Agemos": 57.5,
          "L": -3.321596954,
          "M": 15.16702811,
          "S": 0.082464661
        },
        {
          "Agemos": 58.5,
          "L": -3.334615646,
          "M": 15.16047068,
          "S": 0.083047295
        },
        {
          "Agemos": 59.5,
          "L": -3.344047622,
          "M": 15.15543107,
          "S": 0.083659478
        },
        {
          "Agemos": 60.5,
          "L": -3.35007771,
          "M": 15.15188405,
          "S": 0.084300139
        },
        {
          "Agemos": 61.5,
          "L": -3.352893805,
          "M": 15.14980479,
          "S": 0.0849682
        },
        {
          "Agemos": 62.5,
          "L": -3.352691376,
          "M": 15.14916825,
          "S": 0.085662539
        },
        {
          "Agemos": 63.5,
          "L": -3.34966438,
          "M": 15.14994984,
          "S": 0.086382035
        },
        {
          "Agemos": 64.5,
          "L": -3.343998803,
          "M": 15.15212585,
          "S": 0.087125591
        },
        {
          "Agemos": 65.5,
          "L": -3.335889574,
          "M": 15.15567186,
          "S": 0.087892047
        },
        {
          "Agemos": 66.5,
          "L": -3.325522491,
          "M": 15.16056419,
          "S": 0.088680264
        },
        {
          "Agemos": 67.5,
          "L": -3.31307846,
          "M": 15.16677947,
          "S": 0.089489106
        },
        {
          "Agemos": 68.5,
          "L": -3.298732648,
          "M": 15.17429464,
          "S": 0.090317434
        },
        {
          "Agemos": 69.5,
          "L": -3.282653831,
          "M": 15.18308694,
          "S": 0.091164117
        },
        {
          "Agemos": 70.5,
          "L": -3.265003896,
          "M": 15.1931339,
          "S": 0.092028028
        },
        {
          "Agemos": 71.5,
          "L": -3.245937506,
          "M": 15.20441335,
          "S": 0.092908048
        },
        {
          "Agemos": 72.5,
          "L": -3.225606516,
          "M": 15.21690296,
          "S": 0.093803033
        },
        {
          "Agemos": 73.5,
          "L": -3.204146115,
          "M": 15.2305815,
          "S": 0.094711916
        },
        {
          "Agemos": 74.5,
          "L": -3.181690237,
          "M": 15.24542745,
          "S": 0.095633595
        },
        {
          "Agemos": 75.5,
          "L": -3.158363475,
          "M": 15.26141966,
          "S": 0.096566992
        },
        {
          "Agemos": 76.5,
          "L": -3.134282833,
          "M": 15.27853728,
          "S": 0.097511046
        },
        {
          "Agemos": 77.5,
          "L": -3.109557879,
          "M": 15.29675967,
          "S": 0.09846471
        },
        {
          "Agemos": 78.5,
          "L": -3.084290931,
          "M": 15.31606644,
          "S": 0.099426955
        },
        {
          "Agemos": 79.5,
          "L": -3.058577292,
          "M": 15.33643745,
          "S": 0.100396769
        },
        {
          "Agemos": 80.5,
          "L": -3.032505499,
          "M": 15.35785274,
          "S": 0.101373159
        },
        {
          "Agemos": 81.5,
          "L": -3.0061576,
          "M": 15.38029261,
          "S": 0.10235515
        },
        {
          "Agemos": 82.5,
          "L": -2.979609448,
          "M": 15.40373754,
          "S": 0.103341788
        },
        {
          "Agemos": 83.5,
          "L": -2.952930993,
          "M": 15.42816819,
          "S": 0.104332139
        },
        {
          "Agemos": 84.5,
          "L": -2.926186592,
          "M": 15.45356545,
          "S": 0.105325289
        },
        {
          "Agemos": 85.5,
          "L": -2.899435307,
          "M": 15.47991037,
          "S": 0.106320346
        },
        {
          "Agemos": 86.5,
          "L": -2.872731211,
          "M": 15.50718419,
          "S": 0.10731644
        },
        {
          "Agemos": 87.5,
          "L": -2.846123683,
          "M": 15.53536829,
          "S": 0.108312721
        },
        {
          "Agemos": 88.5,
          "L": -2.819657704,
          "M": 15.56444426,
          "S": 0.109308364
        },
        {
          "Agemos": 89.5,
          "L": -2.793374145,
          "M": 15.5943938,
          "S": 0.110302563
        },
        {
          "Agemos": 90.5,
          "L": -2.767310047,
          "M": 15.6251988,
          "S": 0.111294537
        },
        {
          "Agemos": 91.5,
          "L": -2.741498897,
          "M": 15.65684126,
          "S": 0.112283526
        },
        {
          "Agemos": 92.5,
          "L": -2.715970894,
          "M": 15.68930333,
          "S": 0.113268793
        },
        {
          "Agemos": 93.5,
          "L": -2.690753197,
          "M": 15.7225673,
          "S": 0.114249622
        },
        {
          "Agemos": 94.5,
          "L": -2.665870146,
          "M": 15.75661555,
          "S": 0.115225321
        },
        {
          "Agemos": 95.5,
          "L": -2.641343436,
          "M": 15.79143062,
          "S": 0.116195218
        },
        {
          "Agemos": 96.5,
          "L": -2.617192204,
          "M": 15.82699517,
          "S": 0.117158667
        },
        {
          "Agemos": 97.5,
          "L": -2.593430614,
          "M": 15.86329241,
          "S": 0.118115073
        },
        {
          "Agemos": 98.5,
          "L": -2.570076037,
          "M": 15.90030484,
          "S": 0.119063807
        },
        {
          "Agemos": 99.5,
          "L": -2.547141473,
          "M": 15.93801545,
          "S": 0.12000429
        },
        {
          "Agemos": 100.5,
          "L": -2.524635245,
          "M": 15.97640787,
          "S": 0.120935994
        },
        {
          "Agemos": 101.5,
          "L": -2.502569666,
          "M": 16.01546483,
          "S": 0.121858355
        },
        {
          "Agemos": 102.5,
          "L": -2.48095189,
          "M": 16.05516984,
          "S": 0.12277087
        },
        {
          "Agemos": 103.5,
          "L": -2.459785573,
          "M": 16.09550688,
          "S": 0.123673085
        },
        {
          "Agemos": 104.5,
          "L": -2.439080117,
          "M": 16.13645881,
          "S": 0.124564484
        },
        {
          "Agemos": 105.5,
          "L": -2.418838304,
          "M": 16.17800955,
          "S": 0.125444639
        },
        {
          "Agemos": 106.5,
          "L": -2.399063683,
          "M": 16.22014281,
          "S": 0.126313121
        },
        {
          "Agemos": 107.5,
          "L": -2.379756861,
          "M": 16.26284277,
          "S": 0.127169545
        },
        {
          "Agemos": 108.5,
          "L": -2.360920527,
          "M": 16.30609316,
          "S": 0.128013515
        },
        {
          "Agemos": 109.5,
          "L": -2.342557728,
          "M": 16.34987759,
          "S": 0.128844639
        },
        {
          "Agemos": 110.5,
          "L": -2.324663326,
          "M": 16.39418118,
          "S": 0.129662637
        },
        {
          "Agemos": 111.5,
          "L": -2.307240716,
          "M": 16.43898741,
          "S": 0.130467138
        },
        {
          "Agemos": 112.5,
          "L": -2.290287663,
          "M": 16.48428082,
          "S": 0.131257852
        },
        {
          "Agemos": 113.5,
          "L": -2.273803847,
          "M": 16.53004554,
          "S": 0.132034479
        },
        {
          "Agemos": 114.5,
          "L": -2.257782149,
          "M": 16.57626713,
          "S": 0.132796819
        },
        {
          "Agemos": 115.5,
          "L": -2.242227723,
          "M": 16.62292864,
          "S": 0.133544525
        },
        {
          "Agemos": 116.5,
          "L": -2.227132805,
          "M": 16.67001572,
          "S": 0.134277436
        },
        {
          "Agemos": 117.5,
          "L": -2.212495585,
          "M": 16.71751288,
          "S": 0.134995324
        },
        {
          "Agemos": 118.5,
          "L": -2.19831275,
          "M": 16.76540496,
          "S": 0.135697996
        },
        {
          "Agemos": 119.5,
          "L": -2.184580762,
          "M": 16.81367689,
          "S": 0.136385276
        },
        {
          "Agemos": 120.5,
          "L": -2.171295888,
          "M": 16.86231366,
          "S": 0.137057004
        },
        {
          "Agemos": 121.5,
          "L": -2.158454232,
          "M": 16.91130036,
          "S": 0.137713039
        },
        {
          "Agemos": 122.5,
          "L": -2.146051754,
          "M": 16.96062216,
          "S": 0.138353254
        },
        {
          "Agemos": 123.5,
          "L": -2.134084303,
          "M": 17.0102643,
          "S": 0.138977537
        },
        {
          "Agemos": 124.5,
          "L": -2.122547629,
          "M": 17.06021213,
          "S": 0.139585795
        },
        {
          "Agemos": 125.5,
          "L": -2.111437411,
          "M": 17.11045106,
          "S": 0.140177947
        },
        {
          "Agemos": 126.5,
          "L": -2.100749266,
          "M": 17.16096656,
          "S": 0.140753927
        },
        {
          "Agemos": 127.5,
          "L": -2.090478774,
          "M": 17.21174424,
          "S": 0.141313686
        },
        {
          "Agemos": 128.5,
          "L": -2.080621484,
          "M": 17.26276973,
          "S": 0.141857186
        },
        {
          "Agemos": 129.5,
          "L": -2.071172932,
          "M": 17.31402878,
          "S": 0.142384404
        },
        {
          "Agemos": 130.5,
          "L": -2.062128649,
          "M": 17.3655072,
          "S": 0.142895332
        },
        {
          "Agemos": 131.5,
          "L": -2.053484173,
          "M": 17.4171909,
          "S": 0.143389972
        },
        {
          "Agemos": 132.5,
          "L": -2.045235058,
          "M": 17.46906585,
          "S": 0.143868341
        },
        {
          "Agemos": 133.5,
          "L": -2.03737688,
          "M": 17.52111811,
          "S": 0.144330469
        },
        {
          "Agemos": 134.5,
          "L": -2.029906684,
          "M": 17.57333347,
          "S": 0.144776372
        },
        {
          "Agemos": 135.5,
          "L": -2.022817914,
          "M": 17.62569869,
          "S": 0.145206138
        },
        {
          "Agemos": 136.5,
          "L": -2.016107084,
          "M": 17.67819987,
          "S": 0.145619819
        },
        {
          "Agemos": 137.5,
          "L": -2.009769905,
          "M": 17.7308234,
          "S": 0.146017491
        },
        {
          "Agemos": 138.5,
          "L": -2.003802134,
          "M": 17.78355575,
          "S": 0.146399239
        },
        {
          "Agemos": 139.5,
          "L": -1.998199572,
          "M": 17.83638347,
          "S": 0.146765161
        },
        {
          "Agemos": 140.5,
          "L": -1.992958064,
          "M": 17.88929321,
          "S": 0.147115364
        },
        {
          "Agemos": 141.5,
          "L": -1.988073505,
          "M": 17.94227168,
          "S": 0.147449967
        },
        {
          "Agemos": 142.5,
          "L": -1.983541835,
          "M": 17.9953057,
          "S": 0.147769097
        },
        {
          "Agemos": 143.5,
          "L": -1.979359041,
          "M": 18.04838216,
          "S": 0.148072891
        },
        {
          "Agemos": 144.5,
          "L": -1.975521156,
          "M": 18.10148804,
          "S": 0.148361495
        },
        {
          "Agemos": 145.5,
          "L": -1.972024258,
          "M": 18.15461039,
          "S": 0.148635067
        },
        {
          "Agemos": 146.5,
          "L": -1.968864465,
          "M": 18.20773639,
          "S": 0.148893769
        },
        {
          "Agemos": 147.5,
          "L": -1.966037938,
          "M": 18.26085325,
          "S": 0.149137776
        },
        {
          "Agemos": 148.5,
          "L": -1.963540872,
          "M": 18.31394832,
          "S": 0.14936727
        },
        {
          "Agemos": 149.5,
          "L": -1.961369499,
          "M": 18.36700902,
          "S": 0.149582439
        },
        {
          "Agemos": 150.5,
          "L": -1.959520079,
          "M": 18.42002284,
          "S": 0.149783482
        },
        {
          "Agemos": 151.5,
          "L": -1.9579889,
          "M": 18.47297739,
          "S": 0.149970604
        },
        {
          "Agemos": 152.5,
          "L": -1.956772271,
          "M": 18.52586035,
          "S": 0.15014402
        },
        {
          "Agemos": 153.5,
          "L": -1.95586652,
          "M": 18.57865951,
          "S": 0.15030395
        },
        {
          "Agemos": 154.5,
          "L": -1.955267984,
          "M": 18.63136275,
          "S": 0.150450621
        },
        {
          "Agemos": 155.5,
          "L": -1.954973011,
          "M": 18.68395801,
          "S": 0.15058427
        },
        {
          "Agemos": 156.5,
          "L": -1.954977947,
          "M": 18.73643338,
          "S": 0.150705138
        },
        {
          "Agemos": 157.5,
          "L": -1.955279136,
          "M": 18.788777,
          "S": 0.150813475
        },
        {
          "Agemos": 158.5,
          "L": -1.955872909,
          "M": 18.84097713,
          "S": 0.150909535
        },
        {
          "Agemos": 159.5,
          "L": -1.956755579,
          "M": 18.89302212,
          "S": 0.150993582
        },
        {
          "Agemos": 160.5,
          "L": -1.957923436,
          "M": 18.94490041,
          "S": 0.151065883
        },
        {
          "Agemos": 161.5,
          "L": -1.959372737,
          "M": 18.99660055,
          "S": 0.151126714
        },
        {
          "Agemos": 162.5,
          "L": -1.9610997,
          "M": 19.04811118,
          "S": 0.151176355
        },
        {
          "Agemos": 163.5,
          "L": -1.963100496,
          "M": 19.09942105,
          "S": 0.151215094
        },
        {
          "Agemos": 164.5,
          "L": -1.96537124,
          "M": 19.15051899,
          "S": 0.151243223
        },
        {
          "Agemos": 165.5,
          "L": -1.967907983,
          "M": 19.20139397,
          "S": 0.151261042
        },
        {
          "Agemos": 166.5,
          "L": -1.970706706,
          "M": 19.25203503,
          "S": 0.151268855
        },
        {
          "Agemos": 167.5,
          "L": -1.973763307,
          "M": 19.30243131,
          "S": 0.151266974
        },
        {
          "Agemos": 168.5,
          "L": -1.977073595,
          "M": 19.35257209,
          "S": 0.151255713
        },
        {
          "Agemos": 169.5,
          "L": -1.980633277,
          "M": 19.40244671,
          "S": 0.151235395
        },
        {
          "Agemos": 170.5,
          "L": -1.984437954,
          "M": 19.45204465,
          "S": 0.151206347
        },
        {
          "Agemos": 171.5,
          "L": -1.988483106,
          "M": 19.50135548,
          "S": 0.151168902
        },
        {
          "Agemos": 172.5,
          "L": -1.992764085,
          "M": 19.55036888,
          "S": 0.151123398
        },
        {
          "Agemos": 173.5,
          "L": -1.997276103,
          "M": 19.59907464,
          "S": 0.15107018
        },
        {
          "Agemos": 174.5,
          "L": -2.002014224,
          "M": 19.64746266,
          "S": 0.151009595
        },
        {
          "Agemos": 175.5,
          "L": -2.00697335,
          "M": 19.69552294,
          "S": 0.150942
        },
        {
          "Agemos": 176.5,
          "L": -2.012148213,
          "M": 19.7432456,
          "S": 0.150867753
        },
        {
          "Agemos": 177.5,
          "L": -2.017533363,
          "M": 19.79062086,
          "S": 0.150787221
        },
        {
          "Agemos": 178.5,
          "L": -2.023123159,
          "M": 19.83763907,
          "S": 0.150700774
        },
        {
          "Agemos": 179.5,
          "L": -2.028911755,
          "M": 19.88429066,
          "S": 0.150608788
        },
        {
          "Agemos": 180.5,
          "L": -2.034893091,
          "M": 19.9305662,
          "S": 0.150511645
        },
        {
          "Agemos": 181.5,
          "L": -2.041060881,
          "M": 19.97645636,
          "S": 0.150409731
        },
        {
          "Agemos": 182.5,
          "L": -2.047408604,
          "M": 20.02195192,
          "S": 0.15030344
        },
        {
          "Agemos": 183.5,
          "L": -2.05392949,
          "M": 20.06704377,
          "S": 0.150193169
        },
        {
          "Agemos": 184.5,
          "L": -2.060616513,
          "M": 20.11172291,
          "S": 0.150079322
        },
        {
          "Agemos": 185.5,
          "L": -2.067462375,
          "M": 20.15598047,
          "S": 0.149962308
        },
        {
          "Agemos": 186.5,
          "L": -2.074459502,
          "M": 20.19980767,
          "S": 0.14984254
        },
        {
          "Agemos": 187.5,
          "L": -2.081600029,
          "M": 20.24319586,
          "S": 0.149720441
        },
        {
          "Agemos": 188.5,
          "L": -2.088875793,
          "M": 20.28613648,
          "S": 0.149596434
        },
        {
          "Agemos": 189.5,
          "L": -2.096278323,
          "M": 20.32862109,
          "S": 0.149470953
        },
        {
          "Agemos": 190.5,
          "L": -2.103798828,
          "M": 20.37064138,
          "S": 0.149344433
        },
        {
          "Agemos": 191.5,
          "L": -2.111428194,
          "M": 20.41218911,
          "S": 0.149217319
        },
        {
          "Agemos": 192.5,
          "L": -2.119156972,
          "M": 20.45325617,
          "S": 0.14909006
        },
        {
          "Agemos": 193.5,
          "L": -2.126975375,
          "M": 20.49383457,
          "S": 0.14896311
        },
        {
          "Agemos": 194.5,
          "L": -2.134873266,
          "M": 20.5339164,
          "S": 0.148836931
        },
        {
          "Agemos": 195.5,
          "L": -2.142840157,
          "M": 20.57349387,
          "S": 0.148711989
        },
        {
          "Agemos": 196.5,
          "L": -2.150865204,
          "M": 20.61255929,
          "S": 0.148588757
        },
        {
          "Agemos": 197.5,
          "L": -2.158937201,
          "M": 20.65110506,
          "S": 0.148467715
        },
        {
          "Agemos": 198.5,
          "L": -2.167044578,
          "M": 20.6891237,
          "S": 0.148349348
        },
        {
          "Agemos": 199.5,
          "L": -2.175176987,
          "M": 20.72660728,
          "S": 0.14823412
        },
        {
          "Agemos": 200.5,
          "L": -2.183317362,
          "M": 20.76355011,
          "S": 0.148122614
        },
        {
          "Agemos": 201.5,
          "L": -2.191457792,
          "M": 20.79994337,
          "S": 0.148015249
        },
        {
          "Agemos": 202.5,
          "L": -2.199583649,
          "M": 20.83578051,
          "S": 0.147912564
        },
        {
          "Agemos": 203.5,
          "L": -2.207681525,
          "M": 20.87105449,
          "S": 0.147815078
        },
        {
          "Agemos": 204.5,
          "L": -2.215737645,
          "M": 20.90575839,
          "S": 0.147723315
        },
        {
          "Agemos": 205.5,
          "L": -2.223739902,
          "M": 20.93988477,
          "S": 0.147637768
        },
        {
          "Agemos": 206.5,
          "L": -2.231667995,
          "M": 20.97342858,
          "S": 0.147559083
        },
        {
          "Agemos": 207.5,
          "L": -2.239511942,
          "M": 21.00638171,
          "S": 0.147487716
        },
        {
          "Agemos": 208.5,
          "L": -2.247257081,
          "M": 21.0387374,
          "S": 0.14742421
        },
        {
          "Agemos": 209.5,
          "L": -2.254885145,
          "M": 21.07048996,
          "S": 0.147369174
        },
        {
          "Agemos": 210.5,
          "L": -2.26238209,
          "M": 21.10163241,
          "S": 0.147323144
        },
        {
          "Agemos": 211.5,
          "L": -2.269731517,
          "M": 21.13215845,
          "S": 0.147286698
        },
        {
          "Agemos": 212.5,
          "L": -2.276917229,
          "M": 21.16206171,
          "S": 0.147260415
        },
        {
          "Agemos": 213.5,
          "L": -2.283925442,
          "M": 21.1913351,
          "S": 0.147244828
        },
        {
          "Agemos": 214.5,
          "L": -2.290731442,
          "M": 21.21997472,
          "S": 0.147240683
        },
        {
          "Agemos": 215.5,
          "L": -2.29732427,
          "M": 21.24797262,
          "S": 0.147248467
        },
        {
          "Agemos": 216.5,
          "L": -2.303687802,
          "M": 21.27532239,
          "S": 0.14726877
        },
        {
          "Agemos": 217.5,
          "L": -2.309799971,
          "M": 21.30201933,
          "S": 0.147302299
        },
        {
          "Agemos": 218.5,
          "L": -2.315651874,
          "M": 21.32805489,
          "S": 0.147349514
        },
        {
          "Agemos": 219.5,
          "L": -2.32121731,
          "M": 21.35342563,
          "S": 0.147411215
        },
        {
          "Agemos": 220.5,
          "L": -2.326481911,
          "M": 21.37812462,
          "S": 0.147487979
        },
        {
          "Agemos": 221.5,
          "L": -2.331428139,
          "M": 21.40214589,
          "S": 0.147580453
        },
        {
          "Agemos": 222.5,
          "L": -2.336038473,
          "M": 21.42548351,
          "S": 0.147689289
        },
        {
          "Agemos": 223.5,
          "L": -2.34029545,
          "M": 21.44813156,
          "S": 0.14781515
        },
        {
          "Agemos": 224.5,
          "L": -2.344181703,
          "M": 21.47008412,
          "S": 0.147958706
        },
        {
          "Agemos": 225.5,
          "L": -2.34768,
          "M": 21.49133529,
          "S": 0.148120633
        },
        {
          "Agemos": 226.5,
          "L": -2.350773286,
          "M": 21.51187918,
          "S": 0.148301619
        },
        {
          "Agemos": 227.5,
          "L": -2.353444725,
          "M": 21.53170989,
          "S": 0.148502355
        },
        {
          "Agemos": 228.5,
          "L": -2.355677743,
          "M": 21.55082155,
          "S": 0.148723546
        },
        {
          "Agemos": 229.5,
          "L": -2.35745607,
          "M": 21.56920824,
          "S": 0.148965902
        },
        {
          "Agemos": 230.5,
          "L": -2.358763788,
          "M": 21.58686406,
          "S": 0.149230142
        },
        {
          "Agemos": 231.5,
          "L": -2.359585369,
          "M": 21.60378309,
          "S": 0.149516994
        },
        {
          "Agemos": 232.5,
          "L": -2.359905726,
          "M": 21.61995939,
          "S": 0.149827195
        },
        {
          "Agemos": 233.5,
          "L": -2.359710258,
          "M": 21.635387,
          "S": 0.150161492
        },
        {
          "Agemos": 234.5,
          "L": -2.358980464,
          "M": 21.65006126,
          "S": 0.150520734
        },
        {
          "Agemos": 235.5,
          "L": -2.357714508,
          "M": 21.6639727,
          "S": 0.150905439
        },
        {
          "Agemos": 236.5,
          "L": -2.355892424,
          "M": 21.67711736,
          "S": 0.151316531
        },
        {
          "Agemos": 237.5,
          "L": -2.353501353,
          "M": 21.68948935,
          "S": 0.151754808
        },
        {
          "Agemos": 238.5,
          "L": -2.350528726,
          "M": 21.70108288,
          "S": 0.152221086
        },
        {
          "Agemos": 239.5,
          "L": -2.346962247,
          "M": 21.71189225,
          "S": 0.152716206
        },
        {
          "Agemos": 240,
          "L": -2.34495843,
          "M": 21.71699934,
          "S": 0.152974718
        }
      ]
}
}
