export const allCategoriesGroups = [
    {
        name: 'protein',
        title: 'מקור לחלבון',
        filterIngredients: ['חלבון בוקר', 'חלבון חלבי', 'חלבון בשרי', 'חלבון טבעוני', 'חלבון פרווה', 'חלבון דגים'],
    },
    {
        name: 'carbs',
        title: 'מקור לפחמימה',
        filterIngredients: ['פחמימה בוקר', 'פחמימה פרווה', 'פחמימה חלבי'],
    },
    {
        name: 'fat',
        title: 'מקור לשומן',
        filterIngredients: ['מזונות עשירים בשומן'],
    },
    {
        name: 'veg',
        title: 'ירקות',
        filterIngredients: ['ירקות'],
    },
    {
        name: 'fruits',
        title: 'פירות',
        filterIngredients: ['פירות'],
    },
    {
        name: 'sweets',
        title: 'מתוקים',
        filterIngredients: ['מתוקים'],
    },
    {
        name: 'snacks',
        title: 'חטיפים',
        filterIngredients: ['חטיפים'],
    },
    {
        name: 'sauce',
        title: 'רטבים',
        filterIngredients: ['רטבים'],
    },
    {
        name: 'powder',
        title: 'אבקות חלבון',
        filterIngredients: ['אבקת חלבון'],
    },
    {
        name: 'drink',
        title: 'שתייה',
        filterIngredients: ['שתייה', 'שתייה מעל גיל 18'],
    },
];

export const breakfastCategories = {
    protein: {
        name: 'protein',
        title: 'מקור לחלבון',
        filterIngredients: ['חלבון בוקר', 'חלבון פרווה'],
    },
    carbs: {
        name: 'carbs',
        title: 'מקור לפחמימה',
        filterIngredients: ['פחמימה בוקר'],
    },
    fat: {
        name: 'fat',
        title: 'מקור לשומן',
        filterIngredients: ['מזונות עשירים בשומן'],
    },
    veg: {
        name: 'veg',
        title: 'ירקות',
        filterIngredients: ['ירקות'],
    },
    fruits: {
        name: 'fruits',
        title: 'פירות',
        filterIngredients: ['פירות'],
    },
    sweets: {
        name: 'sweets',
        title: 'מתוקים',
        filterIngredients: ['מתוקים'],
    },
    snacks: {
        name: 'snacks',
        title: 'חטיפים',
        filterIngredients: ['חטיפים'],
    },
    sauce: {
        name: 'sauce',
        title: 'רטבים',
        filterIngredients: ['רטבים'],
    },
    powder: {
        name: 'powder',
        title: 'אבקות חלבון',
        filterIngredients: ['אבקת חלבון'],
    },
    drink: {
        name: 'drink',
        title: 'שתייה',
        filterIngredients: ['שתייה'],
    },
};

export const elevensesCategories = {
    protein: {
        name: 'protein',
        title: 'מקור לחלבון',
        filterIngredients: ['חלבון בוקר', 'חלבון פרווה'],
    },
    carbs: {
        name: 'carbs',
        title: 'מקור לפחמימה',
        filterIngredients: ['פחמימה בוקר'],
    },
    fat: {
        name: 'fat',
        title: 'מקור לשומן',
        filterIngredients: ['מזונות עשירים בשומן'],
    },
    veg: {
        name: 'veg',
        title: 'ירקות',
        filterIngredients: ['ירקות'],
    },
    fruits: {
        name: 'fruits',
        title: 'פירות',
        filterIngredients: ['פירות'],
    },
    sweets: {
        name: 'sweets',
        title: 'מתוקים',
        filterIngredients: ['מתוקים'],
    },
    snacks: {
        name: 'snacks',
        title: 'חטיפים',
        filterIngredients: ['חטיפים'],
    },
    sauce: {
        name: 'sauce',
        title: 'רטבים',
        filterIngredients: ['רטבים'],
    },
    powder: {
        name: 'powder',
        title: 'אבקות חלבון',
        filterIngredients: ['אבקת חלבון'],
    },
    drink: {
        name: 'drink',
        title: 'שתייה',
        filterIngredients: ['שתייה'],
    },
};

export const lunchCategories = {
    protein: {
        name: 'protein',
        title: 'מקור לחלבון',
        filterIngredients: ['חלבון חלבי', 'חלבון בשרי', 'חלבון טבעוני', 'חלבון פרווה', 'חלבון דגים'],
    },
    carbs: {
        name: 'carbs',
        title: 'מקור לפחמימה',
        filterIngredients: ['פחמימה פרווה', 'פחמימה חלבי'],
    },
    fat: {
        name: 'fat',
        title: 'מקור לשומן',
        filterIngredients: ['מזונות עשירים בשומן'],
    },
    veg: {
        name: 'veg',
        title: 'ירקות',
        filterIngredients: ['ירקות'],
    },
    fruits: {
        name: 'fruits',
        title: 'פירות',
        filterIngredients: ['פירות'],
    },
    sweets: {
        name: 'sweets',
        title: 'מתוקים',
        filterIngredients: ['מתוקים'],
    },
    snacks: {
        name: 'snacks',
        title: 'חטיפים',
        filterIngredients: ['חטיפים'],
    },
    sauce: {
        name: 'sauce',
        title: 'רטבים',
        filterIngredients: ['רטבים'],
    },
    powder: {
        name: 'powder',
        title: 'אבקות חלבון',
        filterIngredients: ['אבקת חלבון'],
    },
    drink: {
        name: 'drink',
        title: 'שתייה',
        filterIngredients: ['שתייה', 'שתייה מעל גיל 18'],
    },
};

export const teaCategories = {
    protein: {
        name: 'protein',
        title: 'מקור לחלבון',
        filterIngredients: ['חלבון חלבי', 'חלבון בשרי', 'חלבון טבעוני', 'חלבון פרווה', 'חלבון דגים'],
    },
    carbs: {
        name: 'carbs',
        title: 'מקור לפחמימה',
        filterIngredients: ['פחמימה פרווה', 'פחמימה חלבי'],
    },
    fat: {
        name: 'fat',
        title: 'מקור לשומן',
        filterIngredients: ['מזונות עשירים בשומן'],
    },
    veg: {
        name: 'veg',
        title: 'ירקות',
        filterIngredients: ['ירקות'],
    },
    fruits: {
        name: 'fruits',
        title: 'פירות',
        filterIngredients: ['פירות'],
    },
    sweets: {
        name: 'sweets',
        title: 'מתוקים',
        filterIngredients: ['מתוקים'],
    },
    snacks: {
        name: 'snacks',
        title: 'חטיפים',
        filterIngredients: ['חטיפים'],
    },
    sauce: {
        name: 'sauce',
        title: 'רטבים',
        filterIngredients: ['רטבים'],
    },
    powder: {
        name: 'powder',
        title: 'אבקות חלבון',
        filterIngredients: ['אבקת חלבון'],
    },
    drink: {
        name: 'drink',
        title: 'שתייה',
        filterIngredients: ['שתייה', 'שתייה מעל גיל 18'],
    },
};

export const dinnerCategories = {
    protein: {
        name: 'protein',
        title: 'מקור לחלבון',
        filterIngredients: ['חלבון חלבי', 'חלבון בשרי', 'חלבון טבעוני', 'חלבון פרווה', 'חלבון דגים'],
    },
    carbs: {
        name: 'carbs',
        title: 'מקור לפחמימה',
        filterIngredients: ['פחמימה פרווה', 'פחמימה חלבי'],
    },
    fat: {
        name: 'fat',
        title: 'מקור לשומן',
        filterIngredients: ['מזונות עשירים בשומן'],
    },
    veg: {
        name: 'veg',
        title: 'ירקות',
        filterIngredients: ['ירקות'],
    },
    fruits: {
        name: 'fruits',
        title: 'פירות',
        filterIngredients: ['פירות'],
    },
    sweets: {
        name: 'sweets',
        title: 'מתוקים',
        filterIngredients: ['מתוקים'],
    },
    snacks: {
        name: 'snacks',
        title: 'חטיפים',
        filterIngredients: ['חטיפים'],
    },
    sauce: {
        name: 'sauce',
        title: 'רטבים',
        filterIngredients: ['רטבים'],
    },
    powder: {
        name: 'powder',
        title: 'אבקות חלבון',
        filterIngredients: ['אבקת חלבון'],
    },
    drink: {
        name: 'drink',
        title: 'שתייה',
        filterIngredients: ['שתייה', 'שתייה מעל גיל 18'],
    },
};

export const nightCategories = {
    protein: {
        name: 'protein',
        title: 'מקור לחלבון',
        filterIngredients: ['חלבון חלבי', 'חלבון בשרי', 'חלבון טבעוני', 'חלבון פרווה', 'חלבון דגים'],
    },
    carbs: {
        name: 'carbs',
        title: 'מקור לפחמימה',
        filterIngredients: ['פחמימה פרווה', 'פחמימה חלבי'],
    },
    fat: {
        name: 'fat',
        title: 'מקור לשומן',
        filterIngredients: ['מזונות עשירים בשומן'],
    },
    veg: {
        name: 'veg',
        title: 'ירקות',
        filterIngredients: ['ירקות'],
    },
    fruits: {
        name: 'fruits',
        title: 'פירות',
        filterIngredients: ['פירות'],
    },
    sweets: {
        name: 'sweets',
        title: 'מתוקים',
        filterIngredients: ['מתוקים'],
    },
    snacks: {
        name: 'snacks',
        title: 'חטיפים',
        filterIngredients: ['חטיפים'],
    },
    sauce: {
        name: 'sauce',
        title: 'רטבים',
        filterIngredients: ['רטבים'],
    },
    powder: {
        name: 'powder',
        title: 'אבקות חלבון',
        filterIngredients: ['אבקת חלבון'],
    },
    drink: {
        name: 'drink',
        title: 'שתייה',
        filterIngredients: ['שתייה', 'שתייה מעל גיל 18'],
    },
};

// export const elevensesCategories = [
//     {
//         name: 'protein',
//         title: 'מקור לחלבון',
//         filterIngredients: ['חלבון בוקר', 'חלבון פרווה'],
//     },
//     {
//         name: 'carbs',
//         title: 'מקור לפחמימה',
//         filterIngredients: ['פחמימה בוקר'],
//     },
//     {
//         name: 'fat',
//         title: 'מקור לשומן',
//         filterIngredients: ['מזונות עשירים בשומן'],
//     },
//     {
//         name: 'veg',
//         title: 'ירקות',
//         filterIngredients: ['ירקות'],
//     },
//     {
//         name: 'fruits',
//         title: 'פירות',
//         filterIngredients: ['פירות'],
//     },
//     {
//         name: 'sweets',
//         title: 'מתוקים',
//         filterIngredients: ['מתוקים'],
//     },
//     {
//         name: 'snacks',
//         title: 'חטיפים',
//         filterIngredients: ['חטיפים'],
//     },
//     {
//         name: 'sauce',
//         title: 'רטבים',
//         filterIngredients: ['רטבים'],
//     },
//     {
//         name: 'powder',
//         title: 'אבקות חלבון',
//         filterIngredients: ['אבקת חלבון'],
//     },
//     {
//         name: 'drink',
//         title: 'שתייה',
//         filterIngredients: ['שתייה'],
//     },
// ];

// export const lunchCategories = [
//     {
//         name: 'protein',
//         title: 'מקור לחלבון',
//         filterIngredients: ['חלבון חלבי', 'חלבון בשרי', 'חלבון טבעוני', 'חלבון פרווה', 'חלבון דגים'],
//     },
//     {
//         name: 'carbs',
//         title: 'מקור לפחמימה',
//         filterIngredients: ['פחמימה פרווה', 'פחמימה חלבי'],
//     },
//     {
//         name: 'fat',
//         title: 'מקור לשומן',
//         filterIngredients: ['מזונות עשירים בשומן'],
//     },
//     {
//         name: 'veg',
//         title: 'ירקות',
//         filterIngredients: ['ירקות'],
//     },
//     {
//         name: 'fruits',
//         title: 'פירות',
//         filterIngredients: ['פירות'],
//     },
//     {
//         name: 'sweets',
//         title: 'מתוקים',
//         filterIngredients: ['מתוקים'],
//     },
//     {
//         name: 'snacks',
//         title: 'חטיפים',
//         filterIngredients: ['חטיפים'],
//     },
//     {
//         name: 'sauce',
//         title: 'רטבים',
//         filterIngredients: ['רטבים'],
//     },
//     {
//         name: 'powder',
//         title: 'אבקות חלבון',
//         filterIngredients: ['אבקת חלבון'],
//     },
//     {
//         name: 'drink',
//         title: 'שתייה',
//         filterIngredients: ['שתייה', 'שתייה מעל גיל 18'],
//     },
// ];

// export const teaCategories = [
//     {
//         name: 'protein',
//         title: 'מקור לחלבון',
//         filterIngredients: ['חלבון חלבי', 'חלבון בשרי', 'חלבון טבעוני', 'חלבון פרווה', 'חלבון דגים'],
//     },
//     {
//         name: 'carbs',
//         title: 'מקור לפחמימה',
//         filterIngredients: ['פחמימה פרווה', 'פחמימה חלבי'],
//     },
//     {
//         name: 'fat',
//         title: 'מקור לשומן',
//         filterIngredients: ['מזונות עשירים בשומן'],
//     },
//     {
//         name: 'veg',
//         title: 'ירקות',
//         filterIngredients: ['ירקות'],
//     },
//     {
//         name: 'fruits',
//         title: 'פירות',
//         filterIngredients: ['פירות'],
//     },
//     {
//         name: 'sweets',
//         title: 'מתוקים',
//         filterIngredients: ['מתוקים'],
//     },
//     {
//         name: 'snacks',
//         title: 'חטיפים',
//         filterIngredients: ['חטיפים'],
//     },
//     {
//         name: 'sauce',
//         title: 'רטבים',
//         filterIngredients: ['רטבים'],
//     },
//     {
//         name: 'powder',
//         title: 'אבקות חלבון',
//         filterIngredients: ['אבקת חלבון'],
//     },
//     {
//         name: 'drink',
//         title: 'שתייה',
//         filterIngredients: ['שתייה', 'שתייה מעל גיל 18'],
//     },
// ];

// export const dinnerCategories = [
//     {
//         name: 'protein',
//         title: 'מקור לחלבון',
//         filterIngredients: ['חלבון חלבי', 'חלבון בשרי', 'חלבון טבעוני', 'חלבון פרווה', 'חלבון דגים'],
//     },
//     {
//         name: 'carbs',
//         title: 'מקור לפחמימה',
//         filterIngredients: ['פחמימה פרווה', 'פחמימה חלבי'],
//     },
//     {
//         name: 'fat',
//         title: 'מקור לשומן',
//         filterIngredients: ['מזונות עשירים בשומן'],
//     },
//     {
//         name: 'veg',
//         title: 'ירקות',
//         filterIngredients: ['ירקות'],
//     },
//     {
//         name: 'fruits',
//         title: 'פירות',
//         filterIngredients: ['פירות'],
//     },
//     {
//         name: 'sweets',
//         title: 'מתוקים',
//         filterIngredients: ['מתוקים'],
//     },
//     {
//         name: 'snacks',
//         title: 'חטיפים',
//         filterIngredients: ['חטיפים'],
//     },
//     {
//         name: 'sauce',
//         title: 'רטבים',
//         filterIngredients: ['רטבים'],
//     },
//     {
//         name: 'powder',
//         title: 'אבקות חלבון',
//         filterIngredients: ['אבקת חלבון'],
//     },
//     {
//         name: 'drink',
//         title: 'שתייה',
//         filterIngredients: ['שתייה', 'שתייה מעל גיל 18'],
//     },
// ];

// export const nightCategories = [
//     {
//         name: 'protein',
//         title: 'מקור לחלבון',
//         filterIngredients: ['חלבון חלבי', 'חלבון בשרי', 'חלבון טבעוני', 'חלבון פרווה', 'חלבון דגים'],
//     },
//     {
//         name: 'carbs',
//         title: 'מקור לפחמימה',
//         filterIngredients: ['פחמימה פרווה', 'פחמימה חלבי'],
//     },
//     {
//         name: 'fat',
//         title: 'מקור לשומן',
//         filterIngredients: ['מזונות עשירים בשומן'],
//     },
//     {
//         name: 'veg',
//         title: 'ירקות',
//         filterIngredients: ['ירקות'],
//     },
//     {
//         name: 'fruits',
//         title: 'פירות',
//         filterIngredients: ['פירות'],
//     },
//     {
//         name: 'sweets',
//         title: 'מתוקים',
//         filterIngredients: ['מתוקים'],
//     },
//     {
//         name: 'snacks',
//         title: 'חטיפים',
//         filterIngredients: ['חטיפים'],
//     },
//     {
//         name: 'sauce',
//         title: 'רטבים',
//         filterIngredients: ['רטבים'],
//     },
//     {
//         name: 'powder',
//         title: 'אבקות חלבון',
//         filterIngredients: ['אבקת חלבון'],
//     },
//     {
//         name: 'drink',
//         title: 'שתייה',
//         filterIngredients: ['שתייה', 'שתייה מעל גיל 18'],
//     },
// ];
