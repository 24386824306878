import { Component, ViewChildren, QueryList, Inject, Input, Output, EventEmitter, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Apputils } from '@services/app-utils';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';

import { TableColumn } from '@noammazuz/vzn-data-table';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';

export interface ReportRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

@Component({
  selector: "team-report",
  templateUrl: "./team-report.component.html"
})
export class TeamReportComponent {
  appUtils = Apputils;

  @ViewChild("reportHeader") reportHeader: ElementRef; 

  @ViewChild("userDialogTemplate") userDialogTemplate: TemplateRef<any>;
  @ViewChild("tableEl") tableEl: any;

  @Input() busy;
  @Input() parentFormId: string;
  @Input() restService;

  @Input()
  set players(_players: any) {
    // console.log("players array changed.");
    this.playersArr = _players;
  }

  @Input()
  set teamPlayersIds(_players: any) {
    this.teamPlayersIdsArr = _players;
    // console.log("teamPlayers array changed.", this.teamPlayersIdsArr);
  }

  selectedReportRange: ReportRange;

  columns: Array<{}> = [];
  currentUploadFolder: string;
  query: any;

  playersArr: any;
  teamPlayersIdsArr: any;

  formContext: any;

  internalBusy: boolean;

  currentTeamReportForm: FormGroup;
  currentViewTeamReport: FormGroup;

  currentAveragePlayersMinutesReportArr: any;

  reportsRanges: Array<ReportRange>;

  firstQuerySetFlag: boolean = false;

  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, private translate: TranslateService) {
    this.formContext = this;
    this.currentTeamReportForm = null;
    this.currentViewTeamReport = null;
  }

  ngOnInit() {
    this.internalBusy = false;
    this.columns = this.createColumns();
    this.initReportRanges();
    this.query = this.getQueryFromRange(this.selectedReportRange);
  }

  addTeamReport() {

    console.log("addTeamReport");

    let teamReport: FormGroup = this.createTeamReport(this.formContext);
    teamReport.get("tem_report_tem_id").setValue(this.parentFormId);

    // console.log(this.playersArr);
    // console.log(this.teamPlayersIdsArr);
    // let currentTeamPlayersUserArr: any = Apputils.getSubsetArrayFromIdsWithoutDeleted(this.playersArr, this.teamPlayersIdsArr);
    let currentTeamPlayersUserArr: any = Apputils.getUsersByIdsArr(this.playersArr, this.teamPlayersIdsArr);
    console.log("currentTeamPlayersUserArr", currentTeamPlayersUserArr);
    for (let user of currentTeamPlayersUserArr) {
      (teamReport.get("tem_report_players_minutes_arr") as FormArray).push(this.createUserMinuteItem(this, user._id))
    }

    this.addOrEditTeamReportDialog(teamReport);
  }

  cancelViewTeamReport() {
    this.currentViewTeamReport = null;
  }

  cancelTeamReport() {
    this.currentTeamReportForm = null;
  }

  async saveTeamReport() {
    console.log("saveTeamReport");
    try {
      await this.onSubmitMeal(this.currentTeamReportForm);
      this.tableEl.setTableData();
      this.currentTeamReportForm = null;
    } catch (e) {
      console.error(e);
    }

  }

  addOrEditTeamReportDialog(teamReport: FormGroup) {
    // note: At the moment, a dialog is not opened. Instead, the user is shown a table inline
    console.log("addOrEditTeamReportDialog");

    // if not opened, open the new report area, along with the save report button.

    // display players by id

    if (this.reportHeader) {
      this.reportHeader.nativeElement.scrollIntoView();
    }

    this.currentTeamReportForm = teamReport;


  }

  createUserMinuteItem(context, userId, minutes?) {
    minutes = minutes == undefined || minutes == null ? '' : minutes;
    let item: FormGroup = context.formBuilder.group({
      _id: [userId],
      minutes: [''],
    });

    return item;
  }

  createReportItem(context) {
    let reportForm: FormGroup = context.formBuilder.group({
      _id: [''],

    });

    return reportForm;
  }


  createTeamReport(context) {
    return context.formBuilder.group({
      _id: '',
      tem_report_tem_id: [''],
      tem_report_match_date: [''],
      tem_report_against_name: [''],
      tem_report_result: [''],
      tem_report_players_minutes_arr: this.formBuilder.array([]),
    })
  }



  cleanTeamReportUnusedFields(teamReport) {
    if (teamReport.value.tem_practice_type != "אחר") {
      teamReport.get("tem_practice_type_other_text").reset('');
    }
  }

  async onSubmitMeal(teamReport: FormGroup) {
    this.internalBusy = true;
    try {
      if (!teamReport.value._id || teamReport.value._id === '' || teamReport.value._id === null) {
        delete teamReport.value._id;
        let res: any = await this.restService.create(teamReport.value);
      } else {
        let res: any = await this.restService.patch(teamReport.value._id, teamReport.value);
      }

    } catch (e) {
      console.error(e);
    }
    this.internalBusy = false;
  }

  async showAverageReport() {
    console.log("showAverageReport");
    try {
      this.internalBusy = true;

      let averageQuery = JSON.parse(JSON.stringify(this.query));
      averageQuery.$paginate = false;

      let res = await this.restService.find({
        query: averageQuery,
      });

      // used summarize all the players minutes through all the
      // table
      let playerMinutesMap = {};

      // init the map with all the players in the team:      
      // for (let userId of this.teamPlayersIdsArr) {
      //   playerMinutesMap[userId] = 0;
      // }
      let currentTeamPlayersUserArr: any = Apputils.getUsersByIdsArr(this.playersArr, this.teamPlayersIdsArr);
      for (let user of currentTeamPlayersUserArr) {
        playerMinutesMap[user._id] = 0;
      }

      // iterate over reports and summarize
      for (let report of res) {
        for (let player of report.tem_report_players_minutes_arr) {
          if (playerMinutesMap[player._id] == undefined) {
            playerMinutesMap[player._id] = 0;
          }
          playerMinutesMap[player._id] += Number(player.minutes);
        }
      }

      this.currentAveragePlayersMinutesReportArr = [];
      Object.keys(playerMinutesMap).forEach((key) => {
        this.currentAveragePlayersMinutesReportArr.push({
          _id: key,
          minutes: playerMinutesMap[key] / res.length
        })
      });

    } catch (e) {
      console.error(e)
    }
    this.internalBusy = false;
  }

  getTotalMinutesFromArr(playersArr) {
    let sum = 0;
    for (let player of playersArr) {
      sum += Number(player.minutes);
    }

    return sum;
  }

  async removeRowOnClick(row: any) {
    console.log(`Going to remove line with id: ${row._id}`);

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // width: '50%',
      data: {
        title: this.translate.instant("club.TEAM_REPORT_REMOVE_ITEM_DIALOG_TITLE"),
        subtitle: this.translate.instant("club.TEAM_REPORT_REMOVE_ITEM_DIALOG_EXPLAIN") + " " + row.tem_report_against_name + "?",
        ok: this.translate.instant('general.yes'),
        cancel: this.translate.instant('general.no'),
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        console.log("user canceled");
        return;
      }
      console.log("user clicked ok, now removing");
      let res = await this.restService.delete(row._id);
      console.log("removed. Now refreshing table.");
      this.tableEl.setTableData();

    });

  }

  async onViewClick(row: any) {
    console.log("onViewClick called for row: ", row);
    try {
      let res = await this.restService.get(row._id);

      let newForm = this.createTeamReport(this);
      for (let user of res.tem_report_players_minutes_arr) {
        (newForm.get("tem_report_players_minutes_arr") as FormArray).push(this.createUserMinuteItem(this, user._id, user.minutes));
      }
      newForm.patchValue(res);
      this.currentViewTeamReport = newForm;

    } catch (e) {
      console.error(e);
    }
  }

  editRowOnClick(row: any) {
    console.log(row);
    let teamReport: FormGroup = this.createTeamReport(this.formContext);
    for (let user of row.tem_report_players_minutes_arr) {
      (teamReport.get("tem_report_players_minutes_arr") as FormArray).push(this.createUserMinuteItem(this, user._id, user.minutes));
    }
    teamReport.patchValue(row);

    if (this.currentViewTeamReport && this.currentViewTeamReport.value._id === row._id) {
      this.currentViewTeamReport = null;
    }

    this.addOrEditTeamReportDialog(teamReport);
  }

  createColumns() {
    let columns: Array<TableColumn> = [
      { prop: 'tem_report_match_date', name: 'תאריך', cellTemplate: 'dateNoTimeTmpl' },
      { prop: 'tem_report_against_name', name: 'נגד מי' },
      { prop: 'view_button', name: '', iconName: 'search', cellTemplate: 'iconBtnTmpl', iconOnClick: this.onViewClick.bind(this), maxWidth: 60 },
      { prop: 'edit_button', name: '', iconName: 'edit', cellTemplate: 'iconBtnTmpl', iconOnClick: this.editRowOnClick.bind(this), maxWidth: 60 },
      { prop: 'remove_button', name: '', iconName: 'remove_circle_outline', cellTemplate: 'iconBtnTmpl', iconOnClick: this.removeRowOnClick.bind(this), maxWidth: 60 },
    ];

    return columns;
  }

  changeRange(newVal: ReportRange) {
    this.query = this.getQueryFromRange(newVal);
    this.tableEl.setPaginatorQuery(this.query);
  }

  getQueryFromRange(range: ReportRange) {
    return {
      tem_report_tem_id: this.parentFormId,
      createdAt: {
        $gte: range.startDate.format("YYYY-MM-DD"),
        $lt: range.endDate.add(1, "day").format("YYYY-MM-DD"),
      }
    };
  }

  initReportRanges(): void {
    this.reportsRanges = [];
    this.selectedReportRange = null;

    let nowMoment: moment.Moment = moment(Date.now());
    let nowYear: number = nowMoment.year();
    let startYear = Math.min(2016, nowYear);
    let endYear = Math.max(2016, nowYear + 3);
    for (let year = startYear; year < endYear; year++) {
      this.reportsRanges.push({
        startDate: moment().set({ 'year': year, 'month': 9, 'day': 1 }).startOf('day'),
        endDate: moment().set({ 'year': year + 1, 'month': 8, 'day': 31 }).endOf('day'),
      });

      if (year == nowYear - 1) {
        this.selectedReportRange = this.reportsRanges[this.reportsRanges.length - 1];
      }
    }

    if (this.selectedReportRange == null) {
      this.selectedReportRange = this.reportsRanges[0];
    }

  }

}
