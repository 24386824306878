import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject, Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

export interface DialogData {
    title: string;
    subtitle: string;
    ok: string;
    okAction: Function;
    cancel: string;
    submitDisabled: boolean;
    form: FormGroup;
    template: any;
    context: any;
    extraButton: any;
    checkboxLabel?: string;
    checkboxValue?: boolean;
    onCheckboxChange?: (value: boolean) => void; // Add this line
}

@Component({
    selector: 'custom-dialog',
    template: `
        <h3 *ngIf="data.title" mat-dialog-title>{{ data.title }}</h3>
        <form [formGroup]="data.form ? data.form : emptyForm">
            <div mat-dialog-content>
                <div *ngIf="data.subtitle" class="mat-body mb125">{{ data.subtitle }}</div>
                <ng-container
                    *ngIf="data.template"
                    [ngTemplateOutlet]="data.template"
                    [ngTemplateOutletContext]="{ context: data.context, form: data.form }"
                ></ng-container>
            </div>
            <mat-checkbox *ngIf="data.checkboxLabel" [formControl]="checkboxControl" (change)="handleCheckboxChange($event.checked)"> {{ data.checkboxLabel }}</mat-checkbox>
            <div mat-dialog-actions>
                <button *ngIf="data.cancel" type="button" mat-button (click)="onNoClick()" cdkFocusInitial>
                    {{ data.cancel }}
                </button>
                <button
                    *ngIf="data.ok"
                    type="submit"
                    mat-button
                    [disabled]="(data.form && data.form.invalid) || data.submitDisabled"
                    (click)="onSubmitClick()"
                >
                    {{ data.ok }}
                </button>
                <button
                    *ngIf="data.extraButton"
                    type="button"
                    mat-button
                    [disabled]="data.form && data.form.invalid"
                    (click)="onExtraClick()"
                >
                    {{ data.extraButton.text }}
                </button>
            </div>
        </form>
    `,
})
export class CustomDialogComponent {
    checkboxControl = new FormControl(this.data.checkboxValue);

    constructor(
        public dialogRef: MatDialogRef<CustomDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) { }

    emptyForm: FormGroup = new FormGroup({});

    onNoClick(): void {
        this.dialogRef.close();
    }

    onSubmitClick() {
        if (this.data.okAction) {
            this.data.okAction().subscribe((confirmed) => {
                if (confirmed) {
                    this.dialogRef.close({ ...this.data, isOkButton: true, checkboxValue: this.checkboxControl.value });
                }
            });
        } else {
            this.dialogRef.close({ ...this.data, isOkButton: true, checkboxValue: this.checkboxControl.value });
        }
    }

    onExtraClick() {
        if (this.data.extraButton?.action) {
            this.data.extraButton.action().subscribe((confirmed) => {
                if (confirmed) {
                    this.dialogRef.close({ ...this.data, isExtraButton: true, checkboxValue: this.checkboxControl.value });
                }
            });
        } else {
            this.dialogRef.close({ ...this.data, isExtraButton: true, checkboxValue: this.checkboxControl.value });
        }
    }

    handleCheckboxChange(value: boolean) {
        this.data.checkboxValue = value;
        if (this.data.onCheckboxChange) {
            this.data.onCheckboxChange(value);
        }
    }
}
