<!-- <div *ngIf="notesArr && notesArr.length > 0">
    <mat-form-field style="width: 85%;" floatLabel="never">
        <mat-select #noteSel placeholder="בחר והוסף הערה..." panelClass="notes-comp">
            <mat-option *ngFor="let val of notesArr;" [value]="val">
                <span *ngIf="val.title" class="font-bold">{{val.title}}:  </span>
                <span [innerHtml]="val.content"></span>
            </mat-option>
        </mat-select>
    </mat-form-field>
    <button type="button" style="width: 15%;" mat-icon-button (click)="addNote(noteSel.value)">
        <mat-icon>add</mat-icon>
    </button>
</div> -->
<ng-container *ngIf="(selectedGroup && selectedGroup.value[fieldKey]) || timeCreated > 1596110080351 || !oldNotes">

<div *ngIf="!isReadonly && !isFollowMode && !selectedValue">
    <mat-form-field style="width: 85%;" floatLabel="never">
        <!-- <input type="text" placeholder="בחר והוסף הערה..." matInput [formControl]="acForm.get('extra_field_input')" [matAutocomplete]="noteAutoComplete"> -->
        <textarea [formControl]="acForm.get('extra_field_input')" cdkTextareaAutosize cdkAutosizeMinRows="1" placeholder="בחר והוסף הערה..." matInput [matAutocomplete]="noteAutoComplete"></textarea>
        <mat-autocomplete #noteAutoComplete="matAutocomplete" [displayWith]="displayFn" [class]="'notes-comp'">
            <mat-option *ngFor="let option of acForm.value.extra_field_options | async" [value]="option">
                <span *ngIf="option.title" class="font-bold">{{option.title}}:  </span>
                <span [innerHtml]="(isFemale ? option.content_F : option.content)"></span>
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
    <button type="button" style="width: 15%;" mat-icon-button (click)="addNote(acForm.value.extra_field_input)">
        <mat-icon>add</mat-icon>
    </button>
</div>
<mat-list *ngIf="selectedGroup" class="mb125" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListDisabled]="isReadonly || isFollowMode">
    <ng-container *ngIf="!isFollowMode && selectedGroup.value[fieldKey] && selectedGroup.value[fieldKey].length > 0">
        <!-- <div mat-subheader><span class="font-bold">הערות תזונאי</span></div> -->
        <ng-container *ngFor="let note of selectedGroup.value[fieldKey]; let i = index;">
            <mat-list-item *ngIf="note && (note.note_custom || (note.note_id && (note | noteDetails : notesArr : 'quiz_prefix')))" cdkDrag [ngClass]="{'move': !isReadonly, 'bg-light': note.note_type === 'linked', 'bg-light-danger': note.note_type === 'autofill'}">
                <div class="drop-placeholder" *cdkDragPlaceholder></div>
                <!-- <mat-icon mat-list-icon>note</mat-icon> -->

                <div mat-list-icon *ngIf="note && note.note_id && ((note | noteDetails : notesArr : 'is_push') || (note | noteDetails : notesArr : 'is_wap'))" class="centered-row hide-line">
                    <!-- <mat-icon *ngIf="note.noteObj?._id">lock</mat-icon> -->
                    <mat-checkbox *ngIf="!isReadonly && !sharedGroup" [(ngModel)]="note.is_active"></mat-checkbox>
                    <mat-icon *ngIf="note.is_active && !sharedGroup" class="text-success" matTooltip="{{ note | noteDetails : notesArr : (isFemale ? 'push_content_F' : 'push_content') }}" matTooltipClass="font-14">alarm_on</mat-icon>
                    <mat-icon *ngIf="!note.is_active && !sharedGroup" class="text-warning" matTooltip="{{ note | noteDetails : notesArr : (isFemale ? 'push_content_F' : 'push_content') }}" matTooltipClass="font-14">alarm_off</mat-icon>

                    <mat-icon *ngIf="sharedGroup" class="" matTooltip="{{ note | noteDetails : notesArr : (isFemale ? 'push_content_F' : 'push_content') }}" matTooltipClass="font-14">alarm</mat-icon>
                </div>
                <div mat-line *ngIf="note && note.note_id && showOrigin">
                    <span class="font-12 font-italic text-purple">*  {{ quizPrefixes[(note | noteDetails : notesArr : 'quiz_prefix')] }}</span>
                </div>
                <div mat-line *ngIf="note && note.note_id" matTooltip="{{ note | noteDetails : notesArr : 'title' }}" matTooltipClass="font-16">
                    <span class="font-bold">{{ note | noteDetails : notesArr : 'title' }}</span>
                </div>
                <div mat-line *ngIf="note && note.note_id" class="hide-line" matTooltip="{{ note | noteDetails : notesArr : (isFemale ? 'content_F' : 'content') }}" matTooltipClass="font-14">{{ note | noteDetails : notesArr : (isFemale ? 'content_F' : 'content') }}</div>

                <div mat-line *ngIf="note && note.note_custom">
                    <span class="font-bold">הערה מותאמת אישית</span>
                </div>
                <div mat-line *ngIf="note && note.note_custom" matTooltip="{{ note.note_custom }}" matTooltipClass="font-14">{{ note.note_custom }}</div>

                <!-- <button *ngIf="!isReadonly && note && note.note_id && ((note | noteDetails : notesArr : 'created_by') === appState.user._id || appState.user.usr_type === 'superadmin')" type="button" mat-icon-button (click)="editNote(note.note_id)">
                    <mat-icon>edit</mat-icon>
                </button> -->
                <button *ngIf="!isReadonly && note && note.note_id" type="button" mat-icon-button (click)="editNote(note.note_id)">
                    <mat-icon>edit</mat-icon>
                </button>
                <button *ngIf="!isReadonly" type="button" mat-icon-button class="hide-line" (click)="removeNote(i, note && note.note_id ? note.note_id : null)">
                    <mat-icon>delete</mat-icon>
                </button>
            </mat-list-item>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="isFollowMode && selectedGroup.value[fieldKey] && selectedGroup.value[fieldKey].length > 0">
        <mat-list-item class="height-auto m-b-5" *ngFor="let note of (selectedGroup.value[fieldKey] | reverse); let i = index;">
            <!-- <div mat-list-icon *ngIf="isFollowMode && note && note.added_at" class="centered-row hide-line">
                <div>{{ note.added_at | date:'dd/MM/yyyy' }}</div>
            </div> -->
            <div class="centered-row full-width" *ngIf="note && note.note_id">
                <span class="dl font-12 m-l-15">{{ note.added_at | date:'dd/MM/yyyy' }}</span>
                <span class="dl font-bold flex-grow pre-wrap">{{ note | noteDetails : allNotes : 'title' }}</span>
            </div>
            <div class="centered-row full-width" *ngIf="note && note.note_custom">
                <span class="dl font-12 m-l-15">{{ note.added_at | date:'dd/MM/yyyy' }}</span>
                <span class="dl font-bold flex-grow pre-wrap">{{ note.note_custom }}</span>
            </div>
            <button *ngIf="!isReadonly" type="button" mat-icon-button (click)="removeNote(selectedGroup.value[fieldKey].length - 1 - i)">
                <mat-icon>delete</mat-icon>
            </button>
        </mat-list-item>
    </ng-container>
    <mat-divider></mat-divider>
</mat-list>

<mat-list *ngIf="!selectedGroup && selectedValue" class="mb125">
    <ng-container *ngIf="selectedValue[fieldKey] && selectedValue[fieldKey].length > 0">
        <ng-container *ngFor="let note of selectedValue[fieldKey]; let i = index;">
            <mat-list-item *ngIf="note && (note.note_custom || (note.note_id && (note | noteDetails : notesArr : 'quiz_prefix')))">

                <div mat-list-icon *ngIf="note && note.note_id && ((note | noteDetails : notesArr : 'is_push') || (note | noteDetails : notesArr : 'is_wap'))" class="centered-row hide-line">
                    <!-- <mat-checkbox *ngIf="!isReadonly" [(ngModel)]="note.is_active"></mat-checkbox> -->
                    <mat-icon *ngIf="note.is_active && showOrigin" class="text-success" matTooltip="{{ note | noteDetails : notesArr : (isFemale ? 'push_content_F' : 'push_content') }}" matTooltipClass="font-14">alarm_on</mat-icon>
                    <mat-icon *ngIf="!note.is_active && showOrigin" class="text-warning" matTooltip="{{ note | noteDetails : notesArr : (isFemale ? 'push_content_F' : 'push_content') }}" matTooltipClass="font-14">alarm_off</mat-icon>
                    
                    <mat-icon *ngIf="!showOrigin" class="" matTooltip="{{ note | noteDetails : notesArr : (isFemale ? 'push_content_F' : 'push_content') }}" matTooltipClass="font-14">alarm</mat-icon>
                </div>
                <div mat-line *ngIf="note && note.note_id && showOrigin">
                    <span class="font-12 font-italic text-purple">*  {{ quizPrefixes[(note | noteDetails : notesArr : 'quiz_prefix')] }}</span>
                </div>
                <div mat-line *ngIf="note && note.note_id" matTooltip="{{ note | noteDetails : notesArr : 'title' }}" matTooltipClass="font-16">
                    <span class="font-bold">{{ note | noteDetails : notesArr : 'title' }}</span>
                </div>
                <div mat-line *ngIf="note && note.note_id" class="hide-line" matTooltip="{{ note | noteDetails : notesArr : (isFemale ? 'content_F' : 'content') }}" matTooltipClass="font-14">{{ note | noteDetails : notesArr : (isFemale ? 'content_F' : 'content') }}</div>

                <div mat-line *ngIf="note && note.note_custom">
                    <span class="font-bold">הערה מותאמת אישית</span>
                </div>
                <div mat-line *ngIf="note && note.note_custom" matTooltip="{{ note.note_custom }}" matTooltipClass="font-14">{{ note.note_custom }}</div>

            </mat-list-item>
        </ng-container>
    </ng-container>
    <mat-divider></mat-divider>
</mat-list>

    <mat-list *ngIf="isLinkedMode && noteForm" class="mb125">
        <ng-container *ngIf="noteForm.value.linked_notes && noteForm.value.linked_notes.length > 0">
            <ng-container *ngFor="let note of noteForm.value.linked_notes; let i = index;">
                <mat-list-item *ngIf="note && note.note_id && (note | noteDetails : allNotes : 'quiz_prefix')">

                    <div mat-list-icon *ngIf="((note | noteDetails : allNotes : 'is_push') || (note | noteDetails : allNotes : 'is_wap'))" class="centered-row hide-line">
                        <mat-icon class="" matTooltip="{{ note | noteDetails : allNotes : (isFemale ? 'push_content_F' : 'push_content') }}" matTooltipClass="font-14">alarm</mat-icon>
                    </div>
                    <div mat-line>
                        <span class="font-12 font-italic text-purple">*  {{ quizPrefixes[(note | noteDetails : allNotes : 'quiz_prefix')] }}</span>
                    </div>
                    <div mat-line matTooltip="{{ note | noteDetails : allNotes : 'title' }}" matTooltipClass="font-16">
                        <span class="font-bold">{{ note | noteDetails : allNotes : 'title' }}</span>
                    </div>
                    <div mat-line class="hide-line" matTooltip="{{ note | noteDetails : allNotes : (isFemale ? 'content_F' : 'content') }}" matTooltipClass="font-14">
                        {{ note | noteDetails : allNotes : (isFemale ? 'content_F' : 'content') }}
                    </div>
                    <button type="button" mat-icon-button class="hide-line" (click)="removeNote(i)">
                        <mat-icon>delete</mat-icon>
                    </button>

                </mat-list-item>
            </ng-container>
        </ng-container>
        <mat-divider></mat-divider>
    </mat-list>

</ng-container>

<ng-container *ngIf="timeCreated < 1596110080351 && oldNotes">
    <mat-form-field class="mb50">
        <textarea [readonly]="isReadonly" [formControl]="oldNotes" cdkTextareaAutosize cdkAutosizeMinRows="3" placeholder="הערות של דיאטן" matInput></textarea>
    </mat-form-field>
</ng-container>
<!-- <mat-form-field class="mb50">
    <textarea [formControl]="selectedGroup.get(fieldName)" cdkTextareaAutosize cdkAutosizeMinRows="3" placeholder="הערות של דיאטן" matInput></textarea>
</mat-form-field> -->
