import { Component, ViewChild, TemplateRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import * as moment from 'moment-timezone';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { appState } from '@services/app-state';

import { TableColumn } from '@noammazuz/vzn-data-table';

// import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';

@Component({
  templateUrl: "./coach.component.html"
})
export class CoachComponent {

  @ViewChild("tableEl") tableEl: any;
  // @ViewChild("savedTmpl") savedTmpl: TemplateRef<any>;
  @ViewChild("quizDaysTmpl") quizDaysTmpl: TemplateRef<any>;
  @ViewChild("practiceTmpl") practiceTmpl: TemplateRef<any>;
  @ViewChild("reportTmpl") reportTmpl: TemplateRef<any>;
  @ViewChild("bloodDaysTmpl") bloodDaysTmpl: TemplateRef<any>;
  @ViewChild("nameHasReportTmpl") nameHasReportTmpl: TemplateRef<any>;

  appState = appState;
  usersRestService = null;
  columns: Array<{}> = [];
  defaultQuery: any;
  query: any;

  busy = false;

  chosenTeam = '';
  coachTeams = [];

  constructor(
    // private dialogService: ConfirmDialogService,
    private restApiService: RestApiService<any>,
    private route: ActivatedRoute,
    private router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.usersRestService = this.restApiService.service('users');

    this.defaultQuery = {
      // $userstable: true,
      $coach: true,
      // $select: ['_id', 'createdAt', 'usr_fname', 'usr_lname', 'usr_type'],
      usr_is_deleted: {
        $ne: true
      },
      usr_type: 'player',
      $sort: {
        usr_fname: 1
      }
    };

    // this.route.params.subscribe(async params => {
    //   if (params.id) {
    //     try {
    //       const user = await this.usersRestService.get(params.id);
    //       const userId = user._id.toString();
    //       const teamsRes = await this.restApiService.service('team').find({ 
    //         query: {
    //           $paginate: false,
    //           $select: ['_id', 'tem_name'],
    //           tem_is_deleted: false,
    //           $sort: {
    //             tem_name: 1
    //           }
    //         },
    //       });
    //       if (teamsRes && teamsRes.length > 0) {
    //         if (this.appState.user && this.appState.user.usr_type == 'superadmin') {
    //           this.chosenTeam = '5f7c61d32c50560016c8ab52';
    //         } else {
    //           this.chosenTeam = teamsRes[0]._id.toString();
    //         }
    //         this.changeTeam(this.chosenTeam);
    //         this.coachTeams = teamsRes;
    //       }
    //     } catch (e) {
    //       console.error(e);
    //       this.coachTeams = [];
    //     }
    //   }
    // });
    
  }

  async ngOnInit() {
    const user: any = this.appState.user;
    if (['coach', 'nutri', 'admin', 'superadmin'].indexOf(user.usr_type) === -1) {
      console.log('not allowed');
      this.router.navigate([`/`]);
      return;
    }
    if (!user.usr_agreement && user.usr_type === 'coach') {
      console.log('coach did not approve agreement');
      this.router.navigate([`Profile/${user._id}`]);
      return;
    }

    let usr_teams = [];

    if (['admin', 'superadmin'].indexOf(user.usr_type) > -1) {
      usr_teams = await this.restApiService.service('team').find({ 
        query: {
          $paginate: false,
          $select: ['_id', 'tem_name'],
          tem_is_deleted: {
              $ne: true
          },
          $sort: {
            tem_name: 1
          },
        },
      });
    } else {
      usr_teams = user.usr_teams || [];
    }
    
    if (usr_teams.length > 0) {
      // const teams = usr_teams.sort((a, b) => a['tem_name'].localeCompare(b['tem_name']));
      const teams = usr_teams;
      if (user.usr_type === 'superadmin') {
        this.chosenTeam = '5f7c61d32c50560016c8ab52';
      } else {
        this.chosenTeam = teams[0]._id.toString();
      }
      this.changeTeam(this.chosenTeam);
      this.coachTeams = teams;
    }
  }

  ngAfterViewInit() {
    this.columns = this.createColumns();
    this.changeDetectorRef.detectChanges();
    // this.query = {...this.defaultQuery};
  }

  createColumns() {
              
    const endDate: moment.Moment = moment().weekday(0);
    const endDateX: moment.Moment = moment().weekday(0).subtract(1, 'd');
    const startDate: moment.Moment = moment(endDate).subtract(7, 'd');
    const dateRange = ' (' + endDateX.format('DD/M') + ' - ' + startDate.format('DD/M') + ')';
    let columns: Array<TableColumn> = [
      // { prop: '_id', name: 'מזהה מערכת' },
      { prop: 'usr_fname', name: 'שם פרטי', customTemplate: this.nameHasReportTmpl },
      { prop: 'usr_lname', name: 'שם משפחה', customTemplate: this.nameHasReportTmpl },
      { prop: 'practiceLastWeek', name: 'אימונים' + dateRange, customTemplate: this.practiceTmpl, sortable: false },
      // { prop: 'createdAtDays', name: 'סטטוס מילוי טפסים', customTemplate: this.daysTmpl },
      // { prop: 'savedAtFlag', name: 'סטטוס טפסים', customTemplate: this.savedTmpl },
      { prop: 'quiz_days', name: 'סטטוס טפסים', customTemplate: this.quizDaysTmpl, sortable: false },
      // { prop: 'last_ntq_date', name: 'דוח', customTemplate: this.reportTmpl },
      { prop: 'blood_days', name: 'בדיקת דם', customTemplate: this.bloodDaysTmpl, sortable: false },
      { prop: 'last_rpt_date', name: 'דוח', customTemplate: this.reportTmpl, sortable: false },
    ];

    return columns;
  }

  // onRowDblClick(row) {
  //   if (row.usr_type === 'player') {
  //     // this.router.navigate([`Nutritionistform/${row._id}`]);
  //     window.open(`Nutritionistform/${row._id}`);
  //   } else {
  //     // this.router.navigate([`Profile/${row._id}`]);
  //     window.open(`Profile/${row._id}`);
  //   }
  //   console.log(event);
  // }

  goToReport(id: string) {
    this.router.navigate([`Userreport/${id}`]);
  }
  goToPractice(id: string) {
    this.router.navigate([`Userpractice/${id}`]);
  }

  changeTeam(chosenTeam , e?) {
    // e && e.stopPropagation();
    if (chosenTeam) {
      this.query = { ...this.defaultQuery, $teamid: chosenTeam };
      // this.changeDetectorRef.detectChanges();
    }
  }

  tableLoading(e) {
    this.busy = Boolean(e);
    this.changeDetectorRef.detectChanges();
    // setTimeout(() => { this.busy = Boolean(e); });
  }
}

