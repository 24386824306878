<div class="quiz">

    <form novalidate class="basic-form" [formGroup]="nutritionquizForm" (ngSubmit)="onSubmit(nutritionquizForm, quizPrefix)">

        <div class="row">
            <h1>{{'nutrition.QUIZ_TITLE' | translate}}</h1>
            <!-- <button type="button" *ngIf="isOtherUser" class="plus-button" mat-raised-button [routerLink]="['/Nutritionistform/', nutritionquizForm.value.nuq_usr_id]">
                חזור לטופס תזונאי
                <mat-icon>undo</mat-icon>
            </button> -->
            
            <button type="button" *ngIf="appState.user && appState.user.usr_type == 'parent'" class="plus-button" mat-raised-button [routerLink]="['/Userparent']">
                <span>{{'general.undo' | translate}} </span>
                <mat-icon>undo</mat-icon>
            </button>
        </div>

        <mat-card *ngIf="!nutritionquizForm.value._id || nutritionquizForm.value._id==''">
            <div class="spinner">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
        </mat-card>

        <div [hidden]="!nutritionquizForm.value._id || nutritionquizForm.value._id==''">

            <ng-template #usingTemplate let-context="context" let-checkboxFieldName="checkboxFieldName" let-arrFieldName="arrFieldName" let-descriptionFieldLabel="descriptionFieldLabel" let-addButtonTitle="addButtonTitle" let-removeButtonTitle="removeButtonTitle"
            let-addOnClick="addOnClick" let-removeOnClick="removeOnClick" let-listFixed="listFixed">
            
                <mat-card class="mat-elevation-z-custom">
            
                    <!-- <mat-form-field>
                        <mat-select multiple [formControlName]="arrFieldName+'_fixed'" placeholder="{{'general.description' | translate}}">
                            <mat-option *ngFor="let item of listFixed" [value]="item.value">{{ item.text }}</mat-option>
                        </mat-select>
                    </mat-form-field> -->
                    <mat-form-field *ngIf="arrFieldName === 'nuq_food_does_not_like_arr'">
                        <mat-select #nuq_food_does_not_like_arr_fixed multiple formControlName="nuq_food_does_not_like_arr_fixed" placeholder="{{'general.description' | translate}}">
                            <mat-option *ngFor="let item of listFixed" [value]="item.value">{{ item.text }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="arrFieldName === 'nuq_like_but_try_avoid_eating_arr'">
                        <mat-select #nuq_like_but_try_avoid_eating_arr_fixed multiple formControlName="nuq_like_but_try_avoid_eating_arr_fixed" placeholder="{{'general.description' | translate}}">
                            <mat-option *ngFor="let item of listFixed" [value]="item.value">{{ item.text }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="arrFieldName === 'nuq_eat_a_lot_arr'">
                        <mat-select #nuq_eat_a_lot_arr_fixed multiple formControlName="nuq_eat_a_lot_arr_fixed" placeholder="{{'general.description' | translate}}">
                            <mat-option *ngFor="let item of listFixed" [value]="item.value">{{ item.text }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="arrFieldName === 'nuq_new_food_arr'">
                        <mat-select #nuq_new_food_arr_fixed multiple formControlName="nuq_new_food_arr_fixed" placeholder="{{'general.description' | translate}}">
                            <mat-option *ngFor="let item of listFixed" [value]="item.value">{{ item.text }}</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <div class="repeat-container" formArrayName="{{arrFieldName}}" *ngFor="let item of context.nutritionquizForm.get(arrFieldName).controls; let i = index; let last = last;">
                        <div [formGroupName]="i">
            
                            <span #nuq_food_description_el id={{arrFieldName}}></span>
                            <div class="mb50">
                                <mat-form-field class="w65">
                                    <input #nuq_food_description_control id={{arrFieldName}} formControlName="description" matInput placeholder="{{descriptionFieldLabel}}">
                                </mat-form-field>
                            </div>
            
                            <div *ngIf="i > 0">
                                <button (click)="removeOnClick(context, checkboxFieldName, arrFieldName, i)" type="button" mat-raised-button color="warn" mat-button [disabled]="isLocked">{{removeButtonTitle}}<mat-icon>remove_circle_outline</mat-icon></button>
                            </div>
            
            
                        </div>
                    </div>
            
                    <div class="add-button-container" *ngIf="context.nutritionquizForm.get(arrFieldName+'_fixed').value && context.nutritionquizForm.get(arrFieldName+'_fixed').value.includes('אחר')">
                        <button (click)="addOnClick(context, arrFieldName)" type="button" mat-raised-button color="primary" mat-button [disabled]="isLocked">{{addButtonTitle}}<mat-icon>add_circle_outline</mat-icon></button>
                    </div>
                </mat-card>
            
            </ng-template>
            
    <span #nuq_eat_no_limits_el></span>
    <mat-card>
        <h3>{{'nutrition.Q_1' | translate}}</h3>

        <mat-checkbox class="checkbox" (change)="foodCheckboxOnChange($event, 'nuq_food_does_not_like_arr')" formControlName="nuq_food_does_not_like">{{'nutrition.Q_1_A' | translate}}</mat-checkbox>
        <ng-container *ngIf="nutritionquizForm.value.nuq_food_does_not_like == true" [ngTemplateOutlet]="usingTemplate" [ngTemplateOutletContext]="{
                context: formContext,
                checkboxFieldName: 'nuq_food_does_not_like',
                arrFieldName: 'nuq_food_does_not_like_arr',
                descriptionFieldLabel: 'nutrition.Q_1_A_1' | translate,
                addButtonTitle: 'nutrition.Q_1_A_2' | translate,
                removeButtonTitle: 'nutrition.Q_1_A_3' | translate,
                addOnClick: addFoodItem,
                removeOnClick: removeFoodItem,
                listFixed: listFixedA
            }">
        </ng-container>

        <!-- <mat-checkbox class="checkbox" (change)="foodCheckboxOnChange($event, 'nuq_food_does_not_eat_health_reason_arr')" formControlName="nuq_food_does_not_eat_health_reason">{{'nutrition.Q_1_B' | translate}}</mat-checkbox>
        <ng-container *ngIf="nutritionquizForm.value.nuq_food_does_not_eat_health_reason == true && nutritionquizForm.value.nuq_food_does_not_eat_health_reason_arr.length > 0" [ngTemplateOutlet]="usingTemplate" [ngTemplateOutletContext]="{
                context: formContext,
                checkboxFieldName: 'nuq_food_does_not_eat_health_reason',
                arrFieldName: 'nuq_food_does_not_eat_health_reason_arr',
                descriptionFieldLabel: 'nutrition.Q_1_B_1' | translate,
                addButtonTitle: 'nutrition.Q_1_B_2' | translate,
                removeButtonTitle: 'nutrition.Q_1_B_3' | translate,
                addOnClick: addFoodItem,
                removeOnClick: removeFoodItem
            }">
        </ng-container> -->
        <mat-checkbox class="checkbox" formControlName="nuq_food_does_not_eat_health_reason">{{'nutrition.Q_1_B' | translate}}</mat-checkbox>
        <mat-card class="mat-elevation-z-custom" *ngIf="nutritionquizForm.value.nuq_food_does_not_eat_health_reason">

            <h4>{{'nutrition.Q_1_B_4' | translate}}</h4>
            <div class="mb50">
                <mat-radio-group class="d-column" formControlName="nuq_food_does_not_eat">
                    <mat-radio-button value="צמחוני">{{ 'nutrition.Q_1_B_5' | translate }}</mat-radio-button>
                    <mat-radio-button value="טבעוני">{{ 'nutrition.Q_1_B_6' | translate }}</mat-radio-button>
                    <mat-radio-button value="אחר">{{ 'nutrition.Q_1_B_7' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <mat-form-field *ngIf="nutritionquizForm.value.nuq_food_does_not_eat == 'אחר'" class="w65">
                <input formControlName="nuq_food_does_not_eat_other" matInput placeholder="{{ 'nutrition.Q_1_B_8' | translate }}">
            </mat-form-field>
            
            <ng-container *ngIf="nutritionquizForm.value.nuq_food_does_not_eat">
                <h4>
                    <span>{{'nutrition.Q_1_B_9' | translate}}</span>
                    <span *ngIf="nutritionquizForm.value.nuq_food_does_not_eat === 'צמחוני'">
                        {{'nutrition.Q_1_B_10' | translate}}
                    </span>
                    <span *ngIf="nutritionquizForm.value.nuq_food_does_not_eat === 'טבעוני'">
                        {{'nutrition.Q_1_B_11' | translate}}
                    </span>
                    <span *ngIf="nutritionquizForm.value.nuq_food_does_not_eat === 'אחר'">
                        {{'nutrition.Q_1_B_12' | translate}}
                    </span>
                    <span>?</span>
                </h4>
                <div class="mb50">
                    <mat-checkbox class="checkbox normal" (change)="checkedFnArray('מאמין שזה בריא יותר', $event.checked, 'nuq_food_does_not_eat_reason_arr')" [checked]="nutritionquizForm.value.nuq_food_does_not_eat_reason_arr?.includes('מאמין שזה בריא יותר')" [disabled]="isLocked">{{ 'nutrition.Q_1_B_13' | translate }}</mat-checkbox>
                    <mat-checkbox class="checkbox normal" (change)="checkedFnArray('מטעמי איכות הסביבה', $event.checked, 'nuq_food_does_not_eat_reason_arr')" [checked]="nutritionquizForm.value.nuq_food_does_not_eat_reason_arr?.includes('מטעמי איכות הסביבה')" [disabled]="isLocked">{{ 'nutrition.Q_1_B_14' | translate }}</mat-checkbox>
                    <mat-checkbox class="checkbox normal" (change)="checkedFnArray('לא רוצה לפגוע בחיות', $event.checked, 'nuq_food_does_not_eat_reason_arr')" [checked]="nutritionquizForm.value.nuq_food_does_not_eat_reason_arr?.includes('לא רוצה לפגוע בחיות')" [disabled]="isLocked">{{ 'nutrition.Q_1_B_15' | translate }}</mat-checkbox>
                    <mat-checkbox class="checkbox normal" (change)="checkedFnArray('אחר', $event.checked, 'nuq_food_does_not_eat_reason_arr')" [checked]="nutritionquizForm.value.nuq_food_does_not_eat_reason_arr?.includes('אחר')" [disabled]="isLocked">{{ 'nutrition.Q_1_B_16' | translate }}</mat-checkbox>
                </div>
                <mat-form-field *ngIf="nutritionquizForm.value.nuq_food_does_not_eat_reason_arr?.includes('אחר')" class="w65">
                    <input formControlName="nuq_food_does_not_eat_reason_other" matInput placeholder="{{ 'general.description' | translate }}">
                </mat-form-field>
            </ng-container>

        </mat-card>

        <mat-checkbox class="checkbox" (change)="foodCheckboxOnChange($event, 'nuq_like_but_try_avoid_eating_arr')" formControlName="nuq_like_but_try_avoid_eating">{{'nutrition.Q_1_C' | translate}}</mat-checkbox>
        <ng-container *ngIf="nutritionquizForm.value.nuq_like_but_try_avoid_eating == true" [ngTemplateOutlet]="usingTemplate" [ngTemplateOutletContext]="{
                context: formContext,
                checkboxFieldName: 'nuq_like_but_try_avoid_eating',
                arrFieldName: 'nuq_like_but_try_avoid_eating_arr',
                descriptionFieldLabel: 'nutrition.Q_1_C_1' | translate,
                addButtonTitle: 'nutrition.Q_1_C_2' | translate,
                removeButtonTitle: 'nutrition.Q_1_C_3' | translate,
                addOnClick: addFoodItem,
                removeOnClick: removeFoodItem,
                listFixed: listFixedB
            }">
        </ng-container>

        <mat-checkbox class="checkbox" (change)="foodCheckboxOnChange($event, 'nuq_eat_a_lot_arr')" formControlName="nuq_eat_a_lot">{{'nutrition.Q_1_D' | translate}}</mat-checkbox>
        <ng-container *ngIf="nutritionquizForm.value.nuq_eat_a_lot == true" [ngTemplateOutlet]="usingTemplate" [ngTemplateOutletContext]="{
                context: formContext,
                checkboxFieldName: 'nuq_eat_a_lot',
                arrFieldName: 'nuq_eat_a_lot_arr',
                descriptionFieldLabel: 'nutrition.Q_1_D_1' | translate,
                addButtonTitle: 'nutrition.Q_1_D_2' | translate,
                removeButtonTitle: 'nutrition.Q_1_D_3' | translate,
                addOnClick: addFoodItem,
                removeOnClick: removeFoodItem,
                listFixed: listFixedC
            }">
        </ng-container>

        <mat-checkbox #noLimitsEl class="checkbox normal" (click)="confirmAndResetFood($event, noLimitsEl)" formControlName="nuq_eat_no_limits">{{'nutrition.Q_1_E' | translate}}</mat-checkbox>
    </mat-card>

    <br>

    <mat-card>
        
        <span #nuq_eat_vegetables_el></span>
        <h3>{{'nutrition.EAT_VEGETABLES' | translate}}</h3>
        <div class="mb50">
            <mat-radio-group formControlName="nuq_eat_vegetables">
                <mat-radio-button value="כן">{{ 'nutrition.EAT_VEGETABLES_1' | translate }}</mat-radio-button>
                <mat-radio-button value="לא">{{ 'nutrition.EAT_VEGETABLES_2' | translate }}</mat-radio-button>
            </mat-radio-group>                
        </div>
        <div *ngIf="nutritionquizForm.value.nuq_eat_vegetables === 'כן'" class="w65">
            <mat-form-field>
                <mat-select formControlName="nuq_eat_vegetables_day" placeholder="{{'nutrition.EAT_VEGETABLES_3' | translate}}">
                    <mat-option value="0-1">{{ 'nutrition.EAT_VEGETABLES_4' | translate }}</mat-option>
                    <mat-option value="2">{{ 'nutrition.EAT_VEGETABLES_5' | translate }}</mat-option>
                    <mat-option value="3+">{{ 'nutrition.EAT_VEGETABLES_6' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        
        <span #nuq_eat_fruits_el></span>
        <h3>{{'nutrition.EAT_FRUITS' | translate}}</h3>
        <div class="mb50">
            <mat-radio-group formControlName="nuq_eat_fruits">
                <mat-radio-button value="כן">{{ 'nutrition.EAT_FRUITS_1' | translate }}</mat-radio-button>
                <mat-radio-button value="לא">{{ 'nutrition.EAT_FRUITS_2' | translate }}</mat-radio-button>
            </mat-radio-group>                
        </div>
        <div *ngIf="nutritionquizForm.value.nuq_eat_fruits === 'כן'" class="w65">
            <mat-form-field>
                <mat-select formControlName="nuq_eat_fruits_day" placeholder="{{'nutrition.EAT_FRUITS_3' | translate}}">
                    <mat-option value="0-1">{{ 'nutrition.EAT_FRUITS_4' | translate }}</mat-option>
                    <mat-option value="1">{{ 'nutrition.EAT_FRUITS_5' | translate }}</mat-option>
                    <mat-option value="2">{{ 'nutrition.EAT_FRUITS_6' | translate }}</mat-option>
                    <mat-option value="3+">{{ 'nutrition.EAT_FRUITS_7' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <ng-template #newFoodTemplate let-context="context" let-arrFieldName="arrFieldName" let-fieldName="fieldName" let-listFixed="listFixed">
        
            <mat-card class="mat-elevation-z3 mt125">
        
                <mat-form-field>
                    <mat-select multiple [formControlName]="arrFieldName+'_fixed'" placeholder="{{'general.description' | translate}}">
                        <mat-option *ngFor="let item of listFixed" [value]="item.value">{{ item.text }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="repeat-container" formArrayName="{{arrFieldName}}" *ngFor="let item of context.nutritionquizForm.get(arrFieldName).controls; let i = index; let last = last;">
                    <div [formGroupName]="i">
        
                        <span #nuq_new_food_description_el id={{arrFieldName}}></span>
                        <div class="mb50">
                            <mat-form-field class="w65">
                                <input #nuq_new_food_description_control id={{arrFieldName}} formControlName="description" matInput placeholder="{{ 'nutrition.NEW_FOOD_3' | translate }}">
                            </mat-form-field>
                        </div>
                        <div class="mb50">
                            <h4 class="m-t-0 m-b-5">{{'nutrition.NEW_FOOD_4' | translate}}</h4>
                            <mat-radio-group formControlName="feedback">
                                <mat-radio-button value="ממש טעים">{{ 'nutrition.NEW_FOOD_5' | translate }}</mat-radio-button>
                                <mat-radio-button value="בסדר">{{ 'nutrition.NEW_FOOD_6' | translate }}</mat-radio-button>
                                <mat-radio-button value="לא טעים">{{ 'nutrition.NEW_FOOD_7' | translate }}</mat-radio-button>
                            </mat-radio-group>                
                        </div>

                        <div class="mt125">
                            <button (click)="removeNewFood(context, fieldName, arrFieldName, i)" type="button" mat-raised-button color="warn" mat-button [disabled]="isLocked">{{ 'nutrition.NEW_FOOD_8' | translate }}<mat-icon>remove_circle_outline</mat-icon></button>
                        </div>
        
                    </div>
                </div>
        
                <div class="add-button-container" *ngIf="context.nutritionquizForm.get(arrFieldName+'_fixed').value && context.nutritionquizForm.get(arrFieldName+'_fixed').value.includes('אחר')">
                    <button (click)="addNewFood(context, arrFieldName)" type="button" mat-raised-button color="primary" mat-button [disabled]="isLocked">{{ 'nutrition.NEW_FOOD_9' | translate }}<mat-icon>add_circle_outline</mat-icon></button>
                </div>
            </mat-card>
        
        </ng-template>

        <span #nuq_new_food_el></span>
        <h3>{{'nutrition.NEW_FOOD' | translate}}</h3>
        <div class="mb50">
            <mat-radio-group formControlName="nuq_new_food" (change)="newFoodOnChange($event, 'nuq_new_food_arr')">
                <mat-radio-button value="כן">{{ 'nutrition.NEW_FOOD_1' | translate }}</mat-radio-button>
                <mat-radio-button value="לא">{{ 'nutrition.NEW_FOOD_2' | translate }}</mat-radio-button>
            </mat-radio-group>                
        </div>

        <ng-container *ngIf="nutritionquizForm.value.nuq_new_food === 'כן'" [ngTemplateOutlet]="newFoodTemplate" [ngTemplateOutletContext]="{
            context: formContext,
            fieldName: 'nuq_new_food',
            arrFieldName: 'nuq_new_food_arr',
            listFixed: listFixedA
        }">
        </ng-container>

        <span #nuq_eat_snacks_el></span>
        <h3>{{'nutrition.EAT_SNACKS' | translate}}</h3>
        <div class="mb50">
            <mat-radio-group formControlName="nuq_eat_snacks">
                <mat-radio-button value="כן">{{ 'nutrition.EAT_SNACKS_1' | translate }}</mat-radio-button>
                <mat-radio-button value="לא">{{ 'nutrition.EAT_SNACKS_2' | translate }}</mat-radio-button>
            </mat-radio-group>                
        </div>
        <div *ngIf="nutritionquizForm.value.nuq_eat_snacks === 'כן'" class="w65">
            <!-- <mat-form-field>
                <mat-select multiple formControlName="nuq_eat_snacks_type" placeholder="{{'nutrition.EAT_SNACKS_3' | translate}}">
                    <mat-option value="חטיפים מתוקים">{{ 'nutrition.EAT_SNACKS_4' | translate }}</mat-option>
                    <mat-option value="חטיפים מלוחים">{{ 'nutrition.EAT_SNACKS_5' | translate }}</mat-option>
                    <mat-option value="חטיפים מלוחים ומתוקים">{{ 'nutrition.EAT_SNACKS_6' | translate }}</mat-option>
                </mat-select>
            </mat-form-field> -->
            
            <h3>{{'nutrition.EAT_SNACKS_3' | translate}}</h3>
            <div class="mb50">
                <mat-radio-group class="d-column" formControlName="nuq_eat_snacks_type">
                    <mat-radio-button value="חטיפים מתוקים">{{ 'nutrition.EAT_SNACKS_4' | translate }}</mat-radio-button>
                    <mat-radio-button value="חטיפים מלוחים">{{ 'nutrition.EAT_SNACKS_5' | translate }}</mat-radio-button>
                    <mat-radio-button value="חטיפים מלוחים ומתוקים">{{ 'nutrition.EAT_SNACKS_6' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
            
            <h3>{{'nutrition.EAT_SNACKS_7' | translate}}</h3>
            <div class="mb50">
                <mat-radio-group class="d-column" formControlName="nuq_eat_snacks_freq">
                    <mat-radio-button value="לעיתים רחוקות">{{ 'nutrition.EAT_SNACKS_8' | translate }}</mat-radio-button>
                    <mat-radio-button value="0-1 בשבוע">{{ 'nutrition.EAT_SNACKS_9' | translate }}</mat-radio-button>
                    <mat-radio-button value="2-3 בשבוע">{{ 'nutrition.EAT_SNACKS_10' | translate }}</mat-radio-button>
                    <mat-radio-button value="4-6 בשבוע">{{ 'nutrition.EAT_SNACKS_11' | translate }}</mat-radio-button>
                    <mat-radio-button value="1 ביום">{{ 'nutrition.EAT_SNACKS_12' | translate }}</mat-radio-button>
                    <mat-radio-button value="1-2 ביום">{{ 'nutrition.EAT_SNACKS_13' | translate }}</mat-radio-button>
                    <mat-radio-button value="2+ ביום">{{ 'nutrition.EAT_SNACKS_14' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>

            <ng-container *ngIf="nutritionquizForm.value.nuq_eat_snacks_freq && nutritionquizForm.value.nuq_eat_snacks_freq !== 'לעיתים רחוקות' && nutritionquizForm.value.nuq_eat_snacks_freq !== '0-1 בשבוע'">
                <h3>{{'nutrition.EAT_SNACKS_15' | translate}}</h3>
                <div class="mb50">
                    <mat-radio-group class="d-column" formControlName="nuq_eat_snacks_time">
                        <mat-radio-button value="מתכנן">{{ 'nutrition.EAT_SNACKS_16' | translate }}</mat-radio-button>
                        <mat-radio-button value="אחרי הארוחה">{{ 'nutrition.EAT_SNACKS_17' | translate }}</mat-radio-button>
                        <mat-radio-button value="בין צהריים לערב">{{ 'nutrition.EAT_SNACKS_18' | translate }}</mat-radio-button>
                        <mat-radio-button value="אין משהו קבוע">{{ 'nutrition.EAT_SNACKS_19' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
            </ng-container>

        </div>

    </mat-card>

    <br>

    <mat-card>

        <span #nuq_drink_estimate_el></span>
        <h3 class="m-b-0" [innerHTML]="'nutrition.DRINK_ESTIMATE' | translate"></h3>
        <div class="mb50">
            <mat-form-field class="w65">
                <mat-select formControlName="nuq_drink_estimate"><!--  placeholder="{{ 'nutrition.DRINK_ESTIMATE_22' | translate }}" -->
                    <mat-option value="0.2">{{ 'nutrition.DRINK_ESTIMATE_1' | translate }}</mat-option>
                    <mat-option value="0.4">{{ 'nutrition.DRINK_ESTIMATE_2' | translate }}</mat-option>
                    <mat-option value="0.6">{{ 'nutrition.DRINK_ESTIMATE_3' | translate }}</mat-option>
                    <mat-option value="0.8">{{ 'nutrition.DRINK_ESTIMATE_4' | translate }}</mat-option>
                    <mat-option value="1.0">{{ 'nutrition.DRINK_ESTIMATE_5' | translate }}</mat-option>
                    <mat-option value="1.2">{{ 'nutrition.DRINK_ESTIMATE_6' | translate }}</mat-option>
                    <mat-option value="1.4">{{ 'nutrition.DRINK_ESTIMATE_7' | translate }}</mat-option>
                    <mat-option value="1.6">{{ 'nutrition.DRINK_ESTIMATE_8' | translate }}</mat-option>
                    <mat-option value="1.8">{{ 'nutrition.DRINK_ESTIMATE_9' | translate }}</mat-option>
                    <mat-option value="2.0">{{ 'nutrition.DRINK_ESTIMATE_10' | translate }}</mat-option>
                    <mat-option value="2.2">{{ 'nutrition.DRINK_ESTIMATE_11' | translate }}</mat-option>
                    <mat-option value="2.4">{{ 'nutrition.DRINK_ESTIMATE_12' | translate }}</mat-option>
                    <mat-option value="2.6">{{ 'nutrition.DRINK_ESTIMATE_13' | translate }}</mat-option>
                    <mat-option value="2.8">{{ 'nutrition.DRINK_ESTIMATE_14' | translate }}</mat-option>
                    <mat-option value="3.0">{{ 'nutrition.DRINK_ESTIMATE_15' | translate }}</mat-option>
                    <mat-option value="3.2">{{ 'nutrition.DRINK_ESTIMATE_16' | translate }}</mat-option>
                    <mat-option value="3.4">{{ 'nutrition.DRINK_ESTIMATE_17' | translate }}</mat-option>
                    <mat-option value="3.6">{{ 'nutrition.DRINK_ESTIMATE_18' | translate }}</mat-option>
                    <mat-option value="3.8">{{ 'nutrition.DRINK_ESTIMATE_19' | translate }}</mat-option>
                    <mat-option value="4.0">{{ 'nutrition.DRINK_ESTIMATE_20' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <span #nuq_personal_bottle_el></span>
        <h3>{{'nutrition.PERSONAL_BOTTLE' | translate}}</h3>
        <div class="mb50">
            <mat-radio-group class="d-column" formControlName="nuq_personal_bottle">
                <mat-radio-button value="תמיד">{{ 'nutrition.PERSONAL_BOTTLE_1' | translate }}</mat-radio-button>
                <mat-radio-button value="בדרך כלל">{{ 'nutrition.PERSONAL_BOTTLE_2' | translate }}</mat-radio-button>
                <mat-radio-button value="לעיתים רחוקות">{{ 'nutrition.PERSONAL_BOTTLE_3' | translate }}</mat-radio-button>
                <mat-radio-button value="אף פעם">{{ 'nutrition.PERSONAL_BOTTLE_4' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>

        <span #nuq_drink_sugar_el></span>
        <h3>{{'nutrition.DRINK_SUGAR' | translate}}</h3>
        <div class="mb50">
            <mat-radio-group formControlName="nuq_drink_sugar">
                <mat-radio-button value="כן">{{ 'nutrition.DRINK_SUGAR_1' | translate }}</mat-radio-button>
                <mat-radio-button value="לא">{{ 'nutrition.DRINK_SUGAR_2' | translate }}</mat-radio-button>
            </mat-radio-group>                
        </div>
        <div *ngIf="nutritionquizForm.value.nuq_drink_sugar === 'כן'" class="w65">
            <mat-form-field>
                <mat-select formControlName="nuq_drink_sugar_freq" placeholder="{{'nutrition.DRINK_SUGAR_3' | translate}}">
                    <mat-option value="לעיתים נדירות">{{ 'nutrition.DRINK_SUGAR_4' | translate }}</mat-option>
                    <mat-option value="1-2 פעמים בשבוע">{{ 'nutrition.DRINK_SUGAR_5' | translate }}</mat-option>
                    <mat-option value="3-5 פעמים בשבוע">{{ 'nutrition.DRINK_SUGAR_6' | translate }}</mat-option>
                    <mat-option value="כמעט כל יום">{{ 'nutrition.DRINK_SUGAR_7' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        
        <span #nuq_drink_caffeine_el></span>
        <h3>{{'nutrition.DRINK_CAFFEINE' | translate}}</h3>
        <div class="mb50">
            <mat-radio-group formControlName="nuq_drink_caffeine">
                <mat-radio-button value="כן">{{ 'nutrition.DRINK_CAFFEINE_1' | translate }}</mat-radio-button>
                <mat-radio-button value="לא">{{ 'nutrition.DRINK_CAFFEINE_2' | translate }}</mat-radio-button>
            </mat-radio-group>                
        </div>
        <div *ngIf="nutritionquizForm.value.nuq_drink_caffeine === 'כן'" class="w65">
            <mat-form-field>
                <mat-select multiple formControlName="nuq_drink_caffeine_items" placeholder="{{'nutrition.DRINK_CAFFEINE_3' | translate}}">
                    <mat-option value="קפה">{{ 'nutrition.DRINK_CAFFEINE_4' | translate }}</mat-option>
                    <mat-option value="תה">{{ 'nutrition.DRINK_CAFFEINE_5' | translate }}</mat-option>
                    <mat-option value="משקה אנרגיה">{{ 'nutrition.DRINK_CAFFEINE_6' | translate }}</mat-option>
                </mat-select>
            </mat-form-field>
            
            <h3>{{'nutrition.DRINK_CAFFEINE_7' | translate}}</h3>
            <div class="mb50">
                <mat-radio-group class="d-column" formControlName="nuq_drink_caffeine_cups">
                    <mat-radio-button value="משחק">{{ 'nutrition.DRINK_CAFFEINE_8' | translate }}</mat-radio-button>
                    <mat-radio-button value="לעיתים רחוקות">{{ 'nutrition.DRINK_CAFFEINE_9' | translate }}</mat-radio-button>
                    <mat-radio-button value="1">{{ 'nutrition.DRINK_CAFFEINE_10' | translate }}</mat-radio-button>
                    <mat-radio-button value="2-3">{{ 'nutrition.DRINK_CAFFEINE_11' | translate }}</mat-radio-button>
                    <mat-radio-button value="3-5">{{ 'nutrition.DRINK_CAFFEINE_12' | translate }}</mat-radio-button>
                    <mat-radio-button value="5+">{{ 'nutrition.DRINK_CAFFEINE_13' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>

            <h3>{{'nutrition.DRINK_CAFFEINE_14' | translate}}</h3>
            <div class="mb50">
                <mat-radio-group class="d-column" formControlName="nuq_drink_caffeine_last">
                    <mat-radio-button value="בוקר">{{ 'nutrition.DRINK_CAFFEINE_15' | translate }}</mat-radio-button>
                    <mat-radio-button value="צהריים">{{ 'nutrition.DRINK_CAFFEINE_16' | translate }}</mat-radio-button>
                    <mat-radio-button value="אחרי הצהריים">{{ 'nutrition.DRINK_CAFFEINE_17' | translate }}</mat-radio-button>
                    <mat-radio-button value="ערב">{{ 'nutrition.DRINK_CAFFEINE_18' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>

        </div>

    </mat-card>

        <br>
        
        <mat-card>
            <span #nuq_eat_breakfast_el></span>

            <h3>{{'nutrition.Q_2' | translate}}</h3>
            <div class="mb50">
                <mat-radio-group class="d-column" formControlName="nuq_eat_breakfast">
                    <mat-radio-button value="ברור">{{ 'nutrition.Q_2_A' | translate }}</mat-radio-button>
                    <mat-radio-button value="כמעט תמיד">{{ 'nutrition.Q_2_B' | translate }}</mat-radio-button>
                    <mat-radio-button value="לפעמים">{{ 'nutrition.Q_2_C' | translate }}</mat-radio-button>
                    <mat-radio-button value="לעיתים רחוקות">{{ 'nutrition.Q_2_D' | translate }}</mat-radio-button>
                    <mat-radio-button value="אף פעם">{{ 'nutrition.Q_2_E' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="nutritionquizForm.value.nuq_eat_breakfast == 'לפעמים' || nutritionquizForm.value.nuq_eat_breakfast == 'לעיתים רחוקות' || nutritionquizForm.value.nuq_eat_breakfast == 'אף פעם'" class="w65">
                <span #nuq_eat_breakfast_reason_el></span>

                <h3>{{'nutrition.Q_3' | translate}}</h3>
                <div class="mb50">
                    <!-- <mat-radio-group class="d-column" formControlName="nuq_eat_breakfast_reason">
                        <mat-radio-button value="אין לי תיאבון">אין לי תיאבון</mat-radio-button>
                        <mat-radio-button value="לא מספיק">לא מספיק</mat-radio-button>
                        <mat-radio-button value="זה בסדר אני אוכל כריך בבית הספר">זה בסדר אני אוכל כריך בבית הספר</mat-radio-button>
                        <mat-radio-button value="אין סיבה מיוחדת">אין סיבה מיוחדת</mat-radio-button>
                        <mat-radio-button value="אחר">אחר</mat-radio-button>
                    </mat-radio-group> -->
                    <div formArrayName="nuq_eat_breakfast_reasons">
                        <p *ngFor="let opt of checkboxOptions">
                            <mat-checkbox class="checkbox normal" (change)="updateChkbxArray(opt, $event.checked, 'nuq_eat_breakfast_reasons')" [checked]="nutritionquizForm.value.nuq_eat_breakfast_reasons.includes(opt)" [disabled]="isLocked">{{opt}}</mat-checkbox>
                        </p>
                    </div>
                </div>

                <!-- <mat-form-field *ngIf="nutritionquizForm.value.nuq_eat_breakfast_reason == 'אחר'" class="w65">
                    <input formControlName="nuq_eat_breakfast_reason_other_details" matInput placeholder="פרט">
                </mat-form-field> -->
                <mat-form-field *ngIf="nutritionquizForm.value.nuq_eat_breakfast_reasons && nutritionquizForm.value.nuq_eat_breakfast_reasons.includes('אחר')" class="w65">
                    <input formControlName="nuq_eat_breakfast_reason_other_details" matInput placeholder="{{'general.description' | translate}}">
                </mat-form-field>
            </div>

            <ng-container *ngIf="isUnderNineteen">
                <span #nuq_school_sandwich_el></span>
            
                <h3>{{'nutrition.Q_4' | translate}}</h3>
                <div class="mb50">
                    <mat-radio-group formControlName="nuq_school_sandwich">
                        <mat-radio-button value="כן">{{'nutrition.Q_4_A_1' | translate}}</mat-radio-button>
                        <mat-radio-button value="לא">{{'nutrition.Q_4_A_2' | translate}}</mat-radio-button>
                    </mat-radio-group>
                </div>                
            </ng-container>

        <!-- </mat-card>

        <br>
        
        <mat-card> -->
            <span #nuq_eat_near_screen_el></span>

            <h3>{{'nutrition.Q_5' | translate}}</h3>
            <div class="mb50">
                <mat-radio-group formControlName="nuq_eat_near_screen">
                    <mat-radio-button value="מול מסך">{{ 'nutrition.Q_5_A' | translate }}</mat-radio-button>
                    <mat-radio-button value="ללא מסכים">{{ 'nutrition.Q_5_B' | translate }}</mat-radio-button>
                </mat-radio-group>                
            </div>
            <div *ngIf="nutritionquizForm.value.nuq_eat_near_screen == 'מול מסך'" class="w65">
                <mat-form-field>
                    <mat-select formControlName="nuq_eat_near_screen_status" placeholder="{{'nutrition.Q_6' | translate}}">
                        <mat-option value="לעיתים נדירות">{{ 'nutrition.Q_6_A' | translate }}</mat-option>
                        <mat-option value="1-2 פעמים בשבוע">{{ 'nutrition.Q_6_B' | translate }}</mat-option>
                        <mat-option value="3-5 פעמים בשבוע">{{ 'nutrition.Q_6_C' | translate }}</mat-option>
                        <mat-option value="6+ פעמים בשבוע">{{ 'nutrition.Q_6_D' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <span #nuq_eat_outside_el></span>
        
            <h3>{{'nutrition.Q_7' | translate}}</h3>
            <div class="mb50">
                <mat-radio-group formControlName="nuq_eat_outside" [ngClass]="{'mb50' : nutritionquizForm.value.nuq_eat_outside == 'כן'}">
                    <mat-radio-button value="כן">{{ 'nutrition.Q_7_A' | translate }}</mat-radio-button>
                    <mat-radio-button value="לעיתים רחוקות">{{ 'nutrition.Q_7_B' | translate }}</mat-radio-button>
                </mat-radio-group>
            </div>
            <div *ngIf="nutritionquizForm.value.nuq_eat_outside == 'כן'" class="w65">
                <mat-form-field>
                    <mat-select formControlName="nuq_eat_outside_status" placeholder="{{'nutrition.Q_8' | translate}}">
                        <mat-option value="0-1 פעמים בשבוע">{{ 'nutrition.Q_8_A' | translate }}</mat-option>
                        <mat-option value="1-2 פעמים בשבוע">{{ 'nutrition.Q_8_B' | translate }}</mat-option>
                        <mat-option value="3-5 פעמים בשבוע">{{ 'nutrition.Q_8_C' | translate }}</mat-option>
                        <mat-option value="6+ פעמים בשבוע">{{ 'nutrition.Q_8_D' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <span #nuq_more_food_comments_el></span>

            <h3>{{'nutrition.Q_9' | translate}}</h3>
            <div class="mb50">
                <mat-radio-group formControlName="nuq_more_food_comments">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>                
            </div>

            <mat-form-field *ngIf="nutritionquizForm.value.nuq_more_food_comments == 'כן'" class="w65">
                <input formControlName="nuq_more_food_comments_text" matInput placeholder="{{'general.extraNotes' | translate}}">
            </mat-form-field>
        </mat-card>
        <br>

        <button mat-raised-button color="primary" class="full-width" [disabled]="isLocked">{{'general.save' | translate}}</button>
        <div *ngIf="isLocked" class="font-12 text-center text-danger">
            {{'general.savedQuiz' | translate}}
        </div>
        <!-- <div *ngIf="isOtherUser" class="mt125 row">
            <div>
            </div>
            <button type="button" class="plus-button" mat-raised-button [routerLink]="['/Nutritionistform/', nutritionquizForm.value.nuq_usr_id]">
                חזור לטופס תזונאי
                <mat-icon>undo</mat-icon>
            </button>
        </div> -->
        </div>
    </form>

</div>
