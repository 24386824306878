import { Component, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { appState } from '@services/app-state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import {MatCalendarCellCssClasses} from '@angular/material/datepicker';

import * as moment from 'moment-timezone';

import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: "./measurement.component.html"
})
export class MeasurementComponent {

  nuttestquizForm: FormGroup;
  
  restService: any;

  appState = appState;

  showErrors = false;

  isLocked = false;

  busy = false;

  quizArr = [];
  quizDatesArr: string[];
  dateClass: any;
  dateFilter: any;

  userId: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public restApiService: RestApiService<any>,
    private translate: TranslateService,
  ) {

    this.restService = this.restApiService.service('nuttestquiz');

    this.route.params.subscribe(async params => {
      this.busy = true;
      
      if (params.userId) {
        const user = await this.restApiService.service('users').get(params.userId).catch(e => { console.error(e) });
        if (!user) {
          this.router.navigate(['/']);
          return;
        }
        this.userId = user._id;
        await this.fetchAllQuizzes();
        this.nuttestquizForm = this.createNewForm();
        this.isLocked = true;
        this.nuttestquizForm.disable();
        this.busy = false;
        return;
      }
      
      if (params.id) {
        const data = await this.restService.get(params.id);
        if (data && data.ntq_usr_id) {
          this.userId = data.ntq_usr_id;
        } else {
          this.router.navigate(['Measurement/']);
          return;
        }
        const chosenDate = this.route.snapshot.paramMap.get('date');
        this.nuttestquizForm = this.createNewForm(chosenDate);

        this.nuttestquizForm.patchValue(data, { emitEvent: false });
        if (data.ntq_weight) {
          this.nuttestquizForm.get('ntq_fat_percent_bool').enable();
        }
        this.nuttestquizForm.get('ntq_fat_percent_bool').setValue(Boolean(data.ntq_fat_percent_num));
        // this.fetchOldValues();
        
        this.isLocked = data && data.ntq_locked;
        if (this.appState.user.usr_type === 'parent' || this.appState.user.usr_type === 'nutri') {
            this.isLocked = true;
        }
        if (this.appState.user.usr_type === 'nutri' && data.isReportEverPublished !== undefined) {
            this.isLocked = data.isReportEverPublished;
        }
        if (this.isLocked) {
            this.nuttestquizForm.disable();
        }

      } else {
        if (this.appState.user.usr_type !== 'player') {
          this.router.navigate(['/']);
          return;
        }
        this.userId = this.appState.user._id;
        this.showErrors = false;
        this.isLocked = false;
        // const chosenDate = this.router.getCurrentNavigation().extras.state?.date;
        const chosenDate = this.route.snapshot.paramMap.get('date');
        const now = moment().toISOString();
        this.nuttestquizForm = this.createNewForm(chosenDate);

        if (!chosenDate && this.appState.user.usr_type === 'player') {
          this.nuttestquizForm.get('ntq_test_date').setValue(now);
        }

      }

      if (!this.quizDatesArr) {
        await this.fetchAllQuizzes();
      }
      this.busy = false;
    })

    this.dateClass = (d: moment.Moment): MatCalendarCellCssClasses => {
      const days = this.quizDatesArr || [];
      // const date = d.milliseconds(0).utcOffset(3).format('DD-MM-YYYY');
      // const date = moment(d).utcOffset(3).milliseconds(0).second(0).minute(0).hour(0).toISOString(true);
      // const date = moment(d).milliseconds(0).second(0).minute(0).hour(0).toISOString(true);

      const date = moment(d).format('dd/MM/yyyy');
      return (days.includes(date)) ? 'datepicker-filled-date' : '';
    }
    
    this.dateFilter = (d: moment.Moment): boolean => {
      if (this.appState.user.usr_type === 'player') {
        return true;
      }
      const days = this.quizDatesArr || [];
      // const date = d.milliseconds(0).utcOffset(3).format('DD-MM-YYYY');
      // const date = moment(d).utcOffset(3).milliseconds(0).second(0).minute(0).hour(0).toISOString(true);
      // const dateB = moment(d).utcOffset(2).milliseconds(0).second(0).minute(0).hour(0).toISOString(true);
      // return days.includes(date) || days.includes(dateB);
      // const date = moment(d).milliseconds(0).second(0).minute(0).hour(0).toISOString(true);

      const date = moment(d).format('dd/MM/yyyy');
      return days.includes(date);
    }
  }

  createNewForm(date?) {
    const nuttestquizForm = this.formBuilder.group({
      _id: [''],

      ntq_form_is_real: [false],
      ntq_usr_id: [this.userId],

      ntq_test_date: [date || '', Validators.required],

      ntq_height: ['', [Validators.required, Validators.min(100), Validators.max(240)]],

      ntq_weight: ['', [Validators.required, Validators.min(20), Validators.max(220)]],

      ntq_fat_percent_bool: [{value: false, disabled: true}],

      ntq_fat_percent_num: [''],

      ntq_fat_percent: ['ללא אחוז שומן'],

    });

    nuttestquizForm.get('ntq_fat_percent_bool').valueChanges.subscribe(value => {
      if (value) {
        nuttestquizForm.get('ntq_fat_percent_num').setValidators([Validators.required, Validators.min(1)]);
        nuttestquizForm.get('ntq_fat_percent').setValue('הזנה ידנית');
      } else {
        nuttestquizForm.get('ntq_fat_percent_num').clearValidators();
        nuttestquizForm.get('ntq_fat_percent').setValue('ללא אחוז שומן');
      }
      nuttestquizForm.get('ntq_fat_percent_num').updateValueAndValidity({ onlySelf: true });
    });

    nuttestquizForm.get('ntq_weight').valueChanges.subscribe(value => {
      if (value) {
        nuttestquizForm.get('ntq_fat_percent_bool').enable();
      } else {
        nuttestquizForm.get('ntq_fat_percent_bool').disable();
      }
      nuttestquizForm.get('ntq_fat_percent_bool').updateValueAndValidity({ onlySelf: true });
    });

    nuttestquizForm.get('ntq_test_date').valueChanges.subscribe(value => {

      const old = nuttestquizForm.value.ntq_test_date;
      if (!value || moment(value).isBefore('2020-01-01')) {
        console.log('no valid date');
        nuttestquizForm.get('ntq_test_date').patchValue(moment(old), { emitEvent: false })
        return;
      }

      this.findByDate(value);
    });

    return nuttestquizForm;
  }
  
  async fetchAllQuizzes() {
    console.log('fetchAllQuizzes');
    let data = await this.restService.find({
      query: {
        $paginate: false,
        ntq_usr_id: this.userId,
        ntq_form_is_real: true,
        $sort: {
          ntq_test_date: -1,
          createdAt: -1
        },
        // is_by_user: true
      }
    });
    const lastNutri = data.find(day => day.ntq_test_date && !day.is_by_user);
    let daysRes = data.filter(day => day.ntq_test_date && day.is_by_user);
    if (lastNutri) {
      daysRes.push(lastNutri);
    }

    // this.quizArr = daysRes;
    this.quizDatesArr = daysRes.map((day: any) => {
      // let dayMoment: moment.Moment = moment(day.ntq_test_date).utcOffset(3).milliseconds(0).second(0).minute(0).hour(0);
      // let dayMoment: moment.Moment = moment(day.ntq_test_date).milliseconds(0).second(0).minute(0).hour(0);
      // return dayMoment.toISOString(true);

      return moment(day.ntq_test_date).format('dd/MM/yyyy');
    });
  }

  async findByDate(date) {
    let id = '';
    this.busy = true;

    try {
      // const startDate = moment(date).utcOffset(3).milliseconds(0).second(0).minute(0).hour(0);
      const startDate = moment(date).milliseconds(0).second(0).minute(0).hour(0);
      const endDate = moment(startDate).add(1, 'd');

      const res = await this.restService.find({
        query: {
          $paginate: false,
          $limit: 1,
          ntq_usr_id: this.userId,
          ntq_form_is_real: true,
          ntq_test_date: {
            $gte: startDate.toISOString(),
            $lt: endDate.toISOString(),
          },
          // is_by_user: true
        }
      });

      if (res && res.length > 0) {
        id = res[0]._id;
      }

      this.busy = false;

    } catch (e) {
      console.error(e);
      this.busy = false;
    }

    if (id) {
      if (this.appState.user.usr_type === 'player') {
        this.router.navigate([`Measurement/${id}`, {date: moment(date).toISOString()}]);
      } else {
        this.router.navigate([`Measurement/${id}`]);
      }
    } else {
      if (this.appState.user.usr_type === 'player') {
        this.router.navigate([`Measurement`, {date: moment(date).toISOString()}]);
      } else {
        this.router.navigate([`MeasurementView/${this.userId}`]);
      }
    }

  }

  resetField(fieldName, value) {
    this.nuttestquizForm.controls[fieldName].reset(value);
  }

  async onSubmit() {
    if (this.isLocked) { return }
    this.busy = true;

    this.resetField("ntq_form_is_real", true);
    if (!this.nuttestquizForm.value.ntq_weight || !this.nuttestquizForm.value.ntq_fat_percent_bool) {
      this.resetField("ntq_fat_percent_num", '');
    }

    const validateRes = this.validateForm();

    if (!validateRes.valid) {
      this.showErrors = true;
      this.showStatus(validateRes.msg, null);
      this.busy = false;
      return;
    } else {
      this.showErrors = false;
    }

    if (!this.nuttestquizForm.value._id || this.nuttestquizForm.value._id === '' || this.nuttestquizForm.value._id === null) {
      delete this.nuttestquizForm.value._id;
      let res = await this.restService.create(this.nuttestquizForm.value);

      if (res && res._id) {
        this.showStatus(this.translate.instant('general.savedSuccessfully'), null)
        this.router.navigate([`Measurement/${res._id}`], { replaceUrl: true });
      }
    } else {
      let res = await this.restService.patch(this.nuttestquizForm.value._id, this.nuttestquizForm.value);

      if (res && res._id) {
        this.showStatus(this.translate.instant('general.savedSuccessfully'), null)
        if (this.appState.user.usr_type === 'player'){
          setTimeout(()=>{
            this.router.navigate([`Userprogress`]);
          }, 2000);
        }
      }
    }
    this.busy = false;
  }

  validateForm() {

    if (!this.nuttestquizForm.valid)
      return {
        valid: false,
        msg: "נא לתקן את השדות האדומים"
      }

    return {
      valid: true,
      msg: "ולידציה עברה בהצלחה"
    }

  }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];

  }

}

