import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { appState } from '@services/app-state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
  selector: 'health-section',
  templateUrl: "./health.component.html"
})
export class HealthComponent {
  @Output() addNoteEvent: EventEmitter<any> = new EventEmitter();
  @Output() editNoteEvent: EventEmitter<any> = new EventEmitter();
  
  @Output() busyState: EventEmitter<boolean> = new EventEmitter();
  @Output() updateFollow: EventEmitter<any> = new EventEmitter();
  
  @Input() user: any;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onNew: EventEmitter<any> = new EventEmitter();
  @Output() openImage: EventEmitter<any> = new EventEmitter();
  
  // @Input() allNotes = [];
  @Output() linkedNotesEvent: EventEmitter<any> = new EventEmitter();
  @Input() notesList = [];

  @Input() sharedNotesFormA: FormGroup;
  // @Input() sharedNotesFormB: FormGroup;
  @Input() allFdqValues: any;

  @Input() oldNotesFlag?: boolean;

  quizPrefix = 'heq';

  notesFormA: FormGroup;
  notesFormB: FormGroup;

  oldValues = [];

  selectedIdA: string;
  selectedIdB: string;

  selectedA: any;
  selectedB: any;

  restService: any;

  appState = appState;

  nutallergies: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public restApiService: RestApiService<any>
  ) {
    this.restService = this.restApiService.service('healthquiz');

    this.notesFormA = this.createNewForm();
    this.notesFormB = this.createNewForm();
  }

  imageClick(src: string) {
    this.openImage.emit(src);
  }

  async newQuizGuard() {
    this.onNew.emit(() => { this.newQuiz() });
  }
  async newQuiz() {
    await this.restService.create({
      heq_usr_id: this.user._id,
      heq_form_is_real: true
    });
    this.fetchOldValues(true);
  }

  async deleteQuiz(quizId: string) {
    this.onDelete.emit(() => { this.deleteOk(quizId) });
  }

  async deleteOk(quizId: string) {
    await this.restService.delete(quizId);
    this.fetchOldValues(true);
  }

  async toggleQuizLock(event: MatSlideToggleChange, selected: FormGroup) {
    const lockedField = 'heq_locked';
    if (selected && selected.value && selected.value._id) {
      const res = await this.restService.patch(selected.value._id, {
        [lockedField]: event.checked
      });
      if (res && res[lockedField] === event.checked) {
        event.source.checked = res[lockedField];
        selected.controls[lockedField].reset(res[lockedField]);
        return;
      }
    }
    event.source.checked = !event.checked;
  }

  async fetchOldValues(reset?: boolean, silent?: boolean) {
    
    try {
      let res = await this.restService.find({ query: {   
        // $limit: 1,
        $paginate: false,
        // $select: [ '_id', 'createdAt', 'heq_usr_id' ],
        heq_usr_id: this.user._id,
        heq_form_is_real: true,
        $sort: {
          createdAt: -1
        }
      } });

      let filteredData = res.filter((element) => {
        return element.heq_usr_id != undefined;
      });

      console.log(res);
      this.oldValues = filteredData;

      if(reset){
        await this.resetToLastTwo(silent);
      }

    } catch (e) {
      console.error(e);
    }
  }

  async resetToLastTwo(silent?: boolean) {
    const values = this.oldValues;
    if (values.length === 0) {
      delete this.selectedIdA;
      delete this.selectedA;
      this.notesFormA.reset();
      delete this.selectedIdB;
      delete this.selectedB;
      this.notesFormB.reset();
      return;
    }
    if (values.length === 1) {
      delete this.selectedIdB;
      delete this.selectedB;
      this.notesFormB.reset();
    }
    // delete this.selectedIdA;
    if (values[0] && values[0]._id && values[0]._id !== this.selectedIdA) {
      delete this.selectedA;
      this.notesFormA.reset();
      this.selectedIdA = values[0]._id;
      await this.selectDateA(this.selectedIdA);
    }
    if (silent) {
      return;
    }
    // delete this.selectedIdB;
    if (values[1] && values[1]._id && values[1]._id !== this.selectedIdB) {
      delete this.selectedB;
      this.notesFormB.reset();
      this.selectedIdB = values[1]._id;
      await this.selectDateB(this.selectedIdB);
    }
  }

  async ngOnInit() {
    await this.fetchOldValues(true);
    this.nutallergies = await this.getAllergies();
  }

  async getAllergies() {
    let allergies = [];
    try {
      allergies = await this.restApiService.service('nutallergies').find({
        query: {
          $paginate: false,
        }
      });
    } catch (error) {
      console.log(error);
    }

    return allergies;
  }

  resetNotes(notesGroup: FormGroup, dataNotes) {
    for (const noteKey in notesGroup.value) {
      let notes: FormArray = notesGroup.get(noteKey) as FormArray;
      notes.clear();
      if (dataNotes && dataNotes[noteKey]) {
        for (const n of dataNotes[noteKey]) {
          notes.push(new FormControl());
        }
      }
    }
  }

  refreshFormA(value) {
    const notesGroup = this.notesFormA.get('heq_notes') as FormGroup;
    this.resetNotes(notesGroup, value['heq_notes']);
    this.notesFormA.reset(value);
    this.patchFormArrays(this.notesFormA, value);
    this.selectedA = value;
  }

  refreshFormB(value) {
    const notesGroup = this.notesFormB.get('heq_notes') as FormGroup;
    this.resetNotes(notesGroup, value['heq_notes']);
    this.notesFormB.reset(value);
    this.patchFormArrays(this.notesFormB, value);
    this.selectedB = value;
  }

  async selectDateA(newVal){
    // const data = await this.restService.get(newVal).catch(e => { console.error(e) });
    // const res = await this.restService.find({
    //   query: {
    //     $nutri: true,
    //     $limit: 1,
    //     _id: newVal,
    //   },
    // }).catch(e => { console.error(e) });
    // const data = res?.data[0];
    const data = this.oldValues.find(val => val._id === newVal);
    if (data) {
      this.notesFormA.reset();
      this.refreshFormA(data); 
    }
  }
  async selectDateB(newVal){
    // const data = await this.restService.get(newVal).catch(e => { console.error(e) });
    // const res = await this.restService.find({
    //   query: {
    //     $nutri: true,
    //     $limit: 1,
    //     _id: newVal,
    //   },
    // }).catch(e => { console.error(e) });
    // const data = res?.data[0];
    const data = this.oldValues.find(val => val._id === newVal);
    if (data) {
      this.notesFormB.reset();
      this.refreshFormB(data); 
    }
  }

  resetField(notesFormA, fieldName, value) {
    notesFormA.controls[fieldName].reset(value);
  }

  // async submitForm(){
  //   this.busyState.emit(true);
  //   const form = this.notesFormA;
  //   let res: any;

  //   if (form && form.value._id && form.value._id !== '') { 
  //     res = await this.restService.patch(form.value._id, form.value);
  //     if (res && res._id) {
  //       form.reset(res);
  //       this.showStatus("נשמר בהצלחה", null);
  //       if (res.follow_flag) {
  //         this.updateFollow.emit(res);
  //         setTimeout(() => {
  //           this._snackBar.open('המשתמש נכנס למעקב', 'סגור', {
  //             duration: 0,
  //           });
  //         }, 2000);
  //       }
  //     }
  //   }
  //   this.busyState.emit(false);
  //   return res;
  // }

  createNewForm(): FormGroup {
    return this.formBuilder.group({
        _id: [''],
        // notes_heq_suffer_bad_feeling_or_weekness_desc: [''],
        // notes_heq_suffer_digestion: [''],
        // notes_heq_suffer_bad_feeling_or_weekness: [''],
        // notes_heq_period: [''],
        // notes_heq_more_comments: [''],
        heq_suffer_chronic_condition_arr: this.formBuilder.array([]),
        heq_suffer_acute_condition_arr: this.formBuilder.array([]),
        heq_suffer_acute_injury_arr: this.formBuilder.array([]),

        heq_allergy_arr: this.formBuilder.array([]),

        heq_food_intolerance_arr: this.formBuilder.array([]), // suffer array:
        heq_food_allergy_arr: this.formBuilder.array([]), // suffer array:

        heq_using_chronic_medication_arr: this.formBuilder.array([]),
        heq_using_supplements_vitamins_arr: this.formBuilder.array([]),
        heq_using_protein_powder_arr: this.formBuilder.array([]),
        heq_locked: [false],
        heq_notes: this.formBuilder.group({
          A: this.formBuilder.array([]),
          B: this.formBuilder.array([]),
          C: this.formBuilder.array([]),
          D: this.formBuilder.array([]),
          E: this.formBuilder.array([]),
          F: this.formBuilder.array([]),
          G: this.formBuilder.array([]),
          H: this.formBuilder.array([]),
          I: this.formBuilder.array([]),
        
          s1: this.formBuilder.array([]),
          s2: this.formBuilder.array([]),
          s3: this.formBuilder.array([]),
          s4: this.formBuilder.array([]),
          // s5: this.formBuilder.array([]),
          // s6: this.formBuilder.array([]),
          // s7: this.formBuilder.array([]),
          // s8: this.formBuilder.array([]),
          // s9: this.formBuilder.array([]),
          s10: this.formBuilder.array([]),
        }),

        notes_heq_a: [''],
        notes_heq_b: [''],
        notes_heq_c: [''],
        notes_heq_d: [''],
        notes_heq_e: [''],
        notes_heq_f: [''],
        notes_heq_g: [''],
        notes_heq_h: [''],
        notes_heq_i: [''],
        notes_heq_j: [''],
    });
  }


  patchFormArrays(notesForm: FormGroup, data: any) {

    let sufferArraysFieldNames: Array<string> = [
      "heq_suffer_chronic_condition_arr",
      "heq_suffer_acute_condition_arr",
      "heq_suffer_acute_injury_arr",
    ];

    let sufferArraysFieldNamesNuq: Array<string> = [
      "heq_allergy_arr",
      "heq_food_intolerance_arr",
      "heq_food_allergy_arr",
      // "heq_suffer_acute_injury_arr",
    ];

    let usingArraysFieldNames: Array<string> = [
      "heq_using_chronic_medication_arr",
      "heq_using_supplements_vitamins_arr",
      "heq_using_protein_powder_arr",
    ];

    this.refreshFormArraySkeletonAndPatch(notesForm, data, sufferArraysFieldNames, this, this.createSufferItem);
    this.refreshFormArraySkeletonAndPatch(notesForm, data, sufferArraysFieldNamesNuq, this, this.createSufferItemNuq);
    this.refreshFormArraySkeletonAndPatch(notesForm, data, usingArraysFieldNames, this, this.createUsingItem, "assets_arr", this.createUsingAssetItem);

  }

  refreshFormArraySkeletonAndPatch(notesForm, data, fieldArrayNames, context, createFunctionRef, innerArrFieldName?, innerCreateFunctionRef?) {
    for (let i = 0; i < fieldArrayNames.length; i++) {
      let formArray: FormArray = notesForm.get(fieldArrayNames[i]) as FormArray;

      if (!data[fieldArrayNames[i]]) continue;

      let deltaLength: number = formArray.length - data[fieldArrayNames[i]].length;

      // new data array is shorter than the previous array length
      // we then shorten the existing array
      if (deltaLength > 0) {
        for (let i = 0; i < Math.abs(deltaLength); i++) {
          // we remove the first element for no particular reson,
          // the elements are overwritten anyway
          formArray.removeAt(0);
        }
      } else {
        // second case, the recieved data is longer than the current
        // for array data. we then push new elements:

        // create skeleton items:
        for (let innerIndex = 0; innerIndex < Math.abs(deltaLength); innerIndex++) {

          let newFormElement = createFunctionRef(context);
          formArray.push(newFormElement);

          // this means the array has a sub-array, so we need to create a skeleton for it too
          if (innerArrFieldName && innerCreateFunctionRef) {
            let innerArray: FormArray = newFormElement.get(innerArrFieldName) as FormArray;

            // first, remove all inner elements:
            for (let index = 0; index < innerArray.length; index++) {
              innerArray.removeAt(0);
            }

            // create inner structure
            for (let innerSubIndex = 0; innerSubIndex < data[fieldArrayNames[i]][innerIndex][innerArrFieldName].length; innerSubIndex++) {
              innerArray.push(innerCreateFunctionRef(context));
            }
          }
        }

      }


      formArray.patchValue(data[fieldArrayNames[i]]);
    }
  }

  createSufferItem(context) {
    return context.formBuilder.group({
      description: [''],
      started_date: [''],
      current_status: [''],
      end_date: [''],
      notes: [''],
    })
  }

  createUsingItem(context) {
    return context.formBuilder.group({
      _id: '',
      description: [''],
      recommended_by: [''],
      recommended_by_text: [''],
      duration_taken: [''],
      assets_arr: context.formBuilder.array([]),
      more_assets: '',
      comment: '',
      comment_text: '',
      notes: [''],
    })
  }

  createUsingAssetItem(context) {
    return context.formBuilder.group({
      _id: '',
      url: '',
      width: '',
      height: '',
    })
  }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];
  }

  getDirty() {
    const formA = this.notesFormA;
    const formB = this.notesFormB;
    // return (formA && formA.dirty) || (formB && formB.dirty);
    return (formA && formA.dirty);
  }

  getLocked() {
    const lockedField = 'heq_locked';
    const formA = this.notesFormA;
    // const formB = this.notesFormB;
    // return (formA && formA.dirty) || (formB && formB.dirty);
    return (formA && formA.value[lockedField]);
  }

  getLastId() {
    const formA = this.notesFormA;
    return formA && formA.value._id || '';
  }

  doNothing() {

  }
  
  createSufferItemNuq(context) {
    return context.formBuilder.group({
      product_name: '',
      product_name_other_details: '',
      how_diagnosed: '',
      how_diagnosed_other_details: '',
      diagnosed_date: '',
      more_comments: '',
      more_comments_text: '',
      notes: '',
    })
  }

  linkedNote(note: any) {
    this.linkedNotesEvent.emit(note);
  }

  addNote(contentAndField: any = {}, quiz_prefix: string, quiz_note_type: string, sub_prefix?: string) {
    const { content, content_F, acFormField } = contentAndField;
    this.addNoteEvent.emit({ content, content_F, quiz_prefix, quiz_note_type, sub_prefix, acFormField });
  }

  editNote(idAndField: any = {}, quiz_prefix: string, quiz_note_type: string) {
    const { id, field } = idAndField;
    this.editNoteEvent.emit({ id, field, quiz_prefix, quiz_note_type });
  }

  addNoteToNotesObj(quiz_note_type: string, newNote: FormControl) {
    const notesGroup = this.notesFormA.get('heq_notes') as FormGroup;
    const fieldArray = notesGroup.get(quiz_note_type) as FormArray;
    if (!newNote.value?.note_id || !fieldArray.value || fieldArray.value.findIndex(val => val && val.note_id === newNote.value.note_id) > -1) {
      return;
    }
    fieldArray.push(newNote);
    fieldArray.markAsDirty();
  }

  removeNote(quiz_note_type: string, noteId: string) {
    const notesGroup = this.notesFormA.get('heq_notes') as FormGroup;
    const fieldArray = notesGroup.get(quiz_note_type) as FormArray;
    if (fieldArray) {
      const idx = fieldArray.value.findIndex(val => val && val.note_id === noteId); // || (val.note_custom && val.note_custom === noteObj.note_custom)
      if (idx > -1) {
        fieldArray.removeAt(idx);
        fieldArray.markAsDirty();
      }      
    }
  }

}

