import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HealthquizComponent } from "../components/healthquiz/healthquiz.component";
import { FooddiaryquizComponent } from "../components/fooddiaryquiz/fooddiaryquiz.component";
import { ClubComponent } from "../components/club/club.component";
import { NutritionquizComponent } from '../components/nutritionquiz/nutritionquiz.component';
import { SleepingquizComponent } from '../components/sleepingquiz/sleepingquiz.component';
import { PracticequizComponent } from '../components/practicequiz/practicequiz.component';
import { BloodtestquizComponent } from '../components/bloodtestquiz/bloodtestquiz.component';
import { NuttestquizComponent } from '../components/nuttestquiz/nuttestquiz.component';
import { MeasurementComponent } from '../components/measurement/measurement.component';
import { MeasurereportComponent } from '../components/measurereport/measurereport.component';
import { NutritionistformComponent } from '../components/nutritionistform/nutritionistform.component';
import { ProfileComponent } from "../components/profile/profile.component";

import { LoginPageComponent } from '../pages/login-page/login-page.component';
import { SplashScreenComponent } from '../pages/splash-screen/splash-screen.component';

import { FullComponent } from '../layouts/full/full.component';

import { UserprogressComponent } from "../components/userprogress/userprogress.component";
import { UserstableComponent } from "../components/userstable/userstable.component";
import { UserreportComponent } from '../components/userreport/userreport.component';
import { UserparentComponent } from "../components/userparent/userparent.component";
import { CoachComponent } from "../components/coach/coach.component";
import { UserpracticeComponent } from "../components/userpractice/userpractice.component";
import { NotestableComponent } from "../components/notestable/notestable.component";
import { UsernotesComponent } from "../components/usernotes/usernotes.component";
import { LogtableComponent } from "../components/logtable/logtable.component";

import { PlansComponent } from '../components/plans/plans.component';

import { PendingChangesGuard } from '../shared/pending-changes.guard';

// import { AuthGuard } from '@noammazuz/vzn-feathers';

import { AuthGuard } from './auth.guard';

import { NutquizComponent } from '../components/nutquiz/nutquiz.component';

const routes: Routes = [
  {
    path: '',
    component: SplashScreenComponent,
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginPageComponent,
    pathMatch: 'full'
  },
  {
    path: '',
    component: FullComponent, 
    canActivate: [AuthGuard], 
    // canLoad: [AuthGuard],
    children: [
      { path: "Healthquiz", component: HealthquizComponent },
      { path: "Healthquiz/:id", component: HealthquizComponent },
      { path: "Fooddiaryquiz", component: FooddiaryquizComponent },
      { path: "Fooddiaryquiz/:id", component: FooddiaryquizComponent },
      { path: "Club", component: ClubComponent },
      { path: "Nutritionquiz", component: NutritionquizComponent },
      { path: "Nutritionquiz/:id", component: NutritionquizComponent },
      { path: "Nutquiz", component: NutquizComponent },
      { path: "Nutquiz/:id", component: NutquizComponent},
      { path: "Sleepingquiz", component: SleepingquizComponent },
      { path: "Sleepingquiz/:id", component: SleepingquizComponent },
      { path: "Practicequiz", component: PracticequizComponent },
      { path: "Practicequiz/:id", component: PracticequizComponent },
      { path: "Bloodtestquiz", component: BloodtestquizComponent },
      { path: "Bloodtestquiz/:id", component: BloodtestquizComponent },
      { path: "Nuttestquiz", component: NuttestquizComponent },
      { path: "Nuttestquiz/:id", component: NuttestquizComponent, canDeactivate: [PendingChangesGuard]  },
      { path: "Measurement", component: MeasurementComponent },
      { path: "Measurement/:id", component: MeasurementComponent },
      { path: "MeasurementView/:userId", component: MeasurementComponent },
      { path: "Measurereport", component: MeasurereportComponent },
      { path: "Measurereport/:id", component: MeasurereportComponent },
      { path: "Nutritionistform/:id", component: NutritionistformComponent, canDeactivate: [PendingChangesGuard] },
      // { path: "Registration", component: LoginPageComponent },
      // { path: "Registration/:id", component: LoginPageComponent },
      { path: "Profile", component: ProfileComponent },
      { path: "Profile/:id", component: ProfileComponent },
      { path: "Userprogress", component: UserprogressComponent },
      { path: "Userstable", component: UserstableComponent },
      { path: "Userreport", component: UserreportComponent },
      { path: "Userreport/:id", component: UserreportComponent },
      { path: "Userparent", component: UserparentComponent },
      { path: "Userparent/:id", component: UserparentComponent },
      { path: "Coach", component: CoachComponent },
      { path: "Userpractice", component: UserpracticeComponent },
      { path: "Userpractice/:id", component: UserpracticeComponent },
      { path: "Notestable", component: NotestableComponent },
      { path: "Notestable/:id", component: NotestableComponent },
      { path: "Usernotes", component: UsernotesComponent },
      { path: "Logtable", component: LogtableComponent },
      { path: "Plans", component: PlansComponent },
    ]
  },

  {
    path: '**',
    redirectTo: '/',
    // component: FullComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
