import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { appState } from '@services/app-state';

import { MatCheckboxChange } from '@angular/material/checkbox';

import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';

// import * as moment from 'moment-timezone';

type CompType = 'ingredient' | 'frequency' | 'typical';
type MealType = 'BREAKFAST' | 'ELEVENSES' | 'LUNCH' | 'TEA' | 'DINNER' | 'NIGHT';
type NameType = 'protein' | 'carbs' | 'fat' | 'veg' | 'fruits' | 'sweets' | 'snacks' | 'sauce' | 'powder' | 'drink';

@Component({
  selector: "nut-ingredient-category",
  templateUrl: "./nut-ingredient-category.component.html"
})
export class NutingredientcategoryComponent {

  @Input() compType: CompType;
  @Input() mealType?: MealType;
  @Input() nameType?: NameType;

  // @Input() userChosenCategories: any = {};
  private _userChosenCategories: any = {};
  @Input() set userChosenCategories(value: any) {
     this._userChosenCategories = value;
     this.updateFromChosenData(this._userChosenCategories);
     this.calcOptions();
    //  this.updateFromChosenData(this._userChosenCategories, this.productsByCategories);
    //  this.updateValidation();
  }
  get userChosenCategories(): any {
      return this._userChosenCategories;
  }

  // @Input() userChosenProducts: any = {};
  private _userChosenProducts: any = {};
  @Input() set userChosenProducts(value: any) {
     this._userChosenProducts = value;
    //  this.outputChosenCategories.emit(this.allCategoriesWithChildren);

    this.outputChosenCategories.emit({});
    this.calcOptions();

    //  this.updateValidation();
    //  this.updateFromChosenData(this._userChosenProducts, this.productsByCategories);
    //  this.updateFromChosenData(this.userChosenCategories, this.productsByCategories);
  }
  get userChosenProducts(): any {
      return this._userChosenProducts;
  }
  
  // @Input() allFooditems: any[];

  // // @Input() allProducts: any[];
  // private _allProducts: any[];
  // @Input() set allProducts(value: any[]) {
  //     this._allProducts = value;
  //     this.generateAllData(this._allProducts);
  // }
  // get allProducts(): any[] {
  //     return this._allProducts;
  // }

  // @Input() allProducts?: any[];

  // @Input() allOptions: any[];
  private _allOptions: any[];
  @Input() set allOptions(value: any[]) {
      this._allOptions = value;
      // this.generateAllData(this._allOptions);
      this.calcOptions();
  }
  get allOptions(): any[] {
      return this._allOptions;
  }

  // @Input() allIngredients: any[];
  // @Input() allCategories: any[];
  
  // // @Input() filterIngredients: any[];
  // private _filterIngredients: any[];
  // @Input() set filterIngredients(value: any[]) {
  //     this._filterIngredients = value;
  //     this.generateAllData(this.allProducts);
  // }
  // get filterIngredients(): any[] {
  //     return this._filterIngredients;
  // }

  @Input() title: string;

  // @Input() isDrink?: boolean;

  @Input() isLocked?: boolean;

  @Input() showErrors?: boolean;
  
  @Input() isError?: boolean;
  
  @Input() isOtherControl?: FormControl;
  @Input() otherFoodFormArr?: FormArray;
  @Input() disableAllNotEat?: FormControl;

  @Output() outputChosenCategories: EventEmitter<any> = new EventEmitter();
  @Output() outputProductChosen: EventEmitter<any> = new EventEmitter();

  @Output() removedIngCat: EventEmitter<any> = new EventEmitter();

  // @Output() outputValidation: EventEmitter<boolean> = new EventEmitter();

  appState = appState;

  formContext = this;


  // disableAllNotEat = false;

  // isOther = false;

  // otherFoodFormArr: FormArray;

  // allProductsFiltered: any[] = [];
  // productsByCategories: any = {};
  allCategoriesWithChildren: any = {};

  // allOptions: any[] = [];
  allOptionsChosen: any[] = [];
  allOptionsForTypical: any[] = [];

  frequencyOptions = [
    {
      title: 'לעיתים רחוקות',
      value: 1,
    },
    {
      title: '1-3 פעמים בחודש',
      value: 2,
    },
    {
      title: '1-2 פעמים בשבוע',
      value: 3,
    },
    {
      title: '3-4 פעמים בשבוע',
      value: 4,
    },
    {
      title: '5+ פעמים בשבוע',
      value: 5,
    },
  ];

  constructor(
    private dialogService: ConfirmDialogService,
    private translate: TranslateService,
    private restApiService: RestApiService<any>,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    // private _snackBar: MatSnackBar,
    // public sanitizer: DomSanitizer
  ) {

    // this.otherFoodFormArr = this.formBuilder.array([]);
  }

  // updateTypicalChkbxArray(opt: any, isChecked: boolean, key = 'checkedTypical', childrenKey = 'childrenProducts') {
  //   const mealType = this.mealType;
  //   const nameType = this.nameType;
  //   if (opt && mealType && opt[key]) {
  //     opt[key][mealType] = isChecked;
  //     if (!isChecked && opt[childrenKey] && opt[childrenKey][nameType]) {
  //       for (const child of opt[childrenKey][nameType]) {
  //         // if (!child[key]) {
  //         //   child[key] = {};
  //         // }
  //         // child[key][mealType] = false;
  //         this.outputProductChosen.emit({product: child, mealType, isChecked: false});
  //       }
  //     }
  //     this.outputChosenCategories.emit(this.allCategoriesWithChildren);
  //     // this.updateValidation();
  //   }
  // }

  // typicalCategoryCheckboxChanged(category: any, isChecked: boolean) {
  //   this.updateTypicalChkbxArray(category, isChecked);
  // }

  typicalProductCheckboxChanged(product: any, e: MatCheckboxChange) {
    const isChecked = e?.checked;
    // this.updateTypicalChkbxArray(product, isChecked);
    const mealType = this.mealType;
    if (isChecked) {
      if (e?.source) {
        e.source.checked = false;
      }
    }
    this.outputProductChosen.emit({product, mealType, isChecked});
    // this.updateValidation();
  }

  customProductAdd(product: any) {
    const mealType = this.mealType;
    const nameType = this.nameType;
    this.outputProductChosen.emit({product, mealType, nameType, isChecked: true, isCustom: true});
  }

  customProductRemove(product: any) {
    const mealType = this.mealType;
    const nameType = this.nameType;
    this.outputProductChosen.emit({product, mealType, nameType, isChecked: false, isCustom: true});
  }

  updateCheckboxState(opt: any, isChecked: boolean, key: string, childrenKey: string, childrenProductsKey: string) {
    opt[key] = isChecked;
    if (!isChecked && opt[childrenKey]) {
      for (const child of opt[childrenKey]) {
        child[key] = false;
      }
    }
    // if (!isChecked && opt[childrenProductsKey]) {
    //   this.removedIngCat.emit(opt[childrenProductsKey]);
    // }
    if (!isChecked) {
      const allCats = [opt?._id, ...(opt[childrenKey] || []).map(cat => cat._id)];
      this.removedIngCat.emit(allCats);
    }
  }

  updateChkbxArray(opt: any, isChecked: boolean, key = 'checked', childrenKey = 'children', childrenProductsKey = 'childrenProducts') {
    if (opt) {
      this.updateCheckboxState(opt, isChecked, key, childrenKey, childrenProductsKey);
      this.outputChosenCategories.emit(this.allCategoriesWithChildren);
      // this.updateValidation();
    }
  }

  disableAll(checked) {
    // console.log('checked', checked);
    if (checked) {
      for (const opt of this.allOptions) {
        if (opt) {
          this.updateCheckboxState(opt, false, 'checked', 'children', 'childrenProducts');
        }
      }
      // this.outputChosenCategories.emit(this.allCategoriesWithChildren);

      // this.isOther = false;
      this.isOtherControl?.setValue(false, {emitEvent: false});
      this.otherFoodFormArr?.clear();

      this.outputChosenCategories.emit(this.allCategoriesWithChildren);
    }
    // this.updateValidation();
  }

  addNewFood(focus?: boolean) {
    let foodArr = this.otherFoodFormArr as FormArray;
    foodArr.push(this.createFoodItem());
  
  }

  createFoodItem() {
    // return this.formBuilder.group({
    //   description: '',
    // })
    return this.formBuilder.control('');
  }

  removeOnClick(index: number) {
    let foodArr = this.otherFoodFormArr as FormArray;
    foodArr.removeAt(index);
    
  }


  // generateOptions(allProductsData: any[]) {
  //   // const allProducts = allProductsData || [];
  //   // const allIngredients = this.allIngredients || [];
  //   // const allCategories = this.allCategories || [];

  //   // const filterIngredients = this.filterIngredients || [];
  //   // const filterIngredientsIds = this.getIdsByFilter(filterIngredients, allIngredients);
    
  //   // let products: any[] = [];

  //   // for (const product of allProducts) {
  //   //   if (product.n_ingredients?.length && filterIngredientsIds.some(ingredient => product.n_ingredients.includes(ingredient))) {
  //   //     products.push(product);
  //   //   }
  //   // }

  //   const products = allProductsData;
    
  //   const productsByCategories = this.extractCategories(products);

  //   // const allCategoriesWithChildren = this.getChildCategories(productsByCategories);

  //   const allCategoriesWithChildren = this.getChildCategoriesX(productsByCategories);

  //   return { products, productsByCategories, allCategoriesWithChildren };
  // }

  // extractCategories(products: any[]) {
  //   let productsByCategories: any = {};

  //   for (const product of products) {
  //     if (product.n_categories?.length) {

  //       // product.checkedTypical = {};

  //       // const productId = product._id.toString();
  //       for (const categoryObjectId of product.n_categories) {
  //         const catId = categoryObjectId.toString();
  //         if (!productsByCategories[catId]) {
  //           // productsByCategories[catId] = [productId];
  //           productsByCategories[catId] = [product];
  //         } else {
  //           // if (!productsByCategories[catId].includes(productId)) {
  //           //   productsByCategories[catId].push(productId);
  //           // }
  //           if (!productsByCategories[catId].find((pr: any) => pr._id === product._id)) {
  //             productsByCategories[catId].push(product);
  //           }
  //         }
  //       }
  //     }
  //   }

  //   if (this.nameType === 'drink') {
  //     productsByCategories['water'] = [{}];
  //   }

  //   return productsByCategories;
  // }

  // getChildCategoriesX(productsByCategories: any) {
  //   const allCategories = this.allCategories || [];

  //   let allCategoriesWithChildren: any = {};
  //   let allChildren: any[] = [];

  //   for (const category of allCategories) {
  //     if (category?._id) {
  //       if (!category.n_parent_id) {
  //         if (productsByCategories[category._id]?.length) {
  //           allCategoriesWithChildren[category._id] = category;
  //         }
  //       } else {
  //         allChildren.push(category);
  //       }
  //     }
  //   }

  //   for (const child of allChildren) {
  //     if (productsByCategories[child._id]?.length) {
  //       if (!allCategoriesWithChildren[child.n_parent_id]) {
  //         const parentCategoryFind = allCategories.find(cat => cat._id === child.n_parent_id);
  //         if (!parentCategoryFind) {
  //           continue;
  //         }
  //         allCategoriesWithChildren[child.n_parent_id] = parentCategoryFind;
  //       }
  //       if (!allCategoriesWithChildren[child.n_parent_id].children) {
  //         allCategoriesWithChildren[child.n_parent_id].children = [child];
  //       } else {
  //         if (!allCategoriesWithChildren[child.n_parent_id].children.find(cat => cat._id === child._id)) {
  //           allCategoriesWithChildren[child.n_parent_id].children.push(child);
  //         }
  //       }
  //     }
  //   }

  //   return allCategoriesWithChildren;
    
  //   // const sortable = (Object.entries(allCategoriesWithChildren) as any[]).sort(([,a],[,b]) => !b?.reorder ? -1 : (a?.reorder ?? 0) - (b?.reorder ?? 0)).reduce((r, [k, v]) => ({ ...r, [k]: v }), {});

  //   // console.log(sortable);
  //   // return sortable;
  // }

  // getChildCategories(productsByCategories: any) {
  //   const allCategories = this.allCategories || [];

  //   // let allCategoriesWithChildren: any[] = [];
  //   let allCategoriesWithChildren: any = {};
  //   let allChildren: any[] = [];

  //   for (const categoryId in productsByCategories) {
  //     const category = allCategories.find(cat => cat._id === categoryId);
  //     if (category) {
  //       if (!category.n_parent_id) {
  //         // allCategoriesWithChildren.push(category);
  //         allCategoriesWithChildren[categoryId] = category;
  //       } else {
  //         allChildren.push(category);
  //       }
  //     }
  //   }

  //   for (const child of allChildren) {
  //     // const parentCategory = allCategoriesWithChildren.find(cat => cat._id === child.n_parent_id);
  //     const parentCategory = allCategoriesWithChildren[child.n_parent_id];
  //     if (parentCategory) {
  //       if (!parentCategory.children) {
  //         parentCategory.children = [child];
  //       } else {
  //         if (!parentCategory.children.find(cat => cat._id === child._id)) {
  //           parentCategory.children.push(child);
  //         }
  //       }
  //     }
  //   }

  //   return allCategoriesWithChildren;

  // }

  // getIdsByFilter(filterArr: string[], allArr: any[], key = 'label') {
  //   return filterArr.map(filterItem => allArr.find(item => item[key] === filterItem)?._id).filter(x => x);
  // }

  // updateValidation() {
  //   if (false && this.compType === 'ingredient') {
  //     const allOptionsChosen = this.allOptions.filter((opt: any) => opt && opt.checked);
  //     const isValidAllOptions = allOptionsChosen.length > 0 ? allOptionsChosen.every(opt => !opt.children || (opt.children.length > 0 && opt.children.some(child => child.checked))) : (this.isOtherControl?.value && this.otherFoodFormArr?.value?.length > 0);
  //     // console.log('isValidAllOptions', isValidAllOptions)

  //     console.log('compType ingredient', this.mealType, this.nameType, this.disableAllNotEat?.value || isValidAllOptions);
  //     // this.outputValidation.emit(this.disableAllNotEat?.value || isValidAllOptions);
  //   }
  //   if (false && this.mealType && this.compType === 'frequency') {
  //     // const allOptionsChosenFrequencyNotFilled = this.allOptionsChosen.filter((opt: any) => opt && opt.frequency && !opt.frequency[this.mealType]);
  //     const allOptionsChosenFrequencyNotFilled = this.allOptionsChosen.filter((opt: any) => opt && opt.weekly && !(opt.weekly[this.mealType] === true || opt.weekly[this.mealType] === false));

  //     console.log('compType frequency', this.mealType, this.nameType, allOptionsChosenFrequencyNotFilled.length === 0);
  //     // this.outputValidation.emit(allOptionsChosenFrequencyNotFilled.length === 0);
  //   }
  //   if (false && this.mealType && this.nameType && this.compType === 'typical') {
  //     const allOptionsChosenFrequencyNoChildrenChosen = this.allOptionsForTypical.filter((opt: any) => opt?.weekly && opt.weekly[this.mealType] && opt.checkedTypical && opt.checkedTypical[this.mealType] && opt.childrenProducts && opt.childrenProducts[this.nameType] && opt.childrenProducts[this.nameType].length && !(opt.childrenProducts[this.nameType].some((childProduct: any) => childProduct?._id && this.userChosenProducts[this.mealType] && this.userChosenProducts[this.mealType][childProduct._id])));

  //     // console.log('this.userChosenProducts', this.userChosenProducts)
  //     // console.log('this.allOptionsChosenFrequency', this.allOptionsChosenFrequency)
  //     // console.log('allOptionsChosenFrequencyNoChildrenChosen', this.mealType, this.compType, this.nameType, allOptionsChosenFrequencyNoChildrenChosen)

  //     console.log('compType typical', this.mealType, this.nameType, allOptionsChosenFrequencyNoChildrenChosen.length === 0);
  //     // this.outputValidation.emit(allOptionsChosenFrequencyNoChildrenChosen.length === 0);
  //   }
    
  // }

  updateFromChosenData(userChosenCategories: any = {}) {
    // console.log('updateFromChosenData', this.compType, this.mealType, this.nameType, userChosenCategories);

    const allCategoriesWithChildren = this.allCategoriesWithChildren || {};
    const mealType = this.mealType;

    for (const categoryId in allCategoriesWithChildren) {
      
      if (!allCategoriesWithChildren[categoryId].weekly) {
        allCategoriesWithChildren[categoryId].weekly = {}
      }
      if (!allCategoriesWithChildren[categoryId].checkedTypical) {
        allCategoriesWithChildren[categoryId].checkedTypical = {}
      }
      // if (!allCategoriesWithChildren[categoryId].childrenProducts) {
      //   allCategoriesWithChildren[categoryId].childrenProducts = {}
      // }

      if (userChosenCategories[categoryId]) {

        if (userChosenCategories[categoryId].checked) {
          allCategoriesWithChildren[categoryId].checked = true;

          if (mealType) {
            if (userChosenCategories[categoryId].weekly?.[mealType]) {
              allCategoriesWithChildren[categoryId].weekly[mealType] = true;
            }

            if (userChosenCategories[categoryId].checkedTypical?.[mealType]) {
              allCategoriesWithChildren[categoryId].checkedTypical[mealType] = true;
            }
          }

          if (userChosenCategories[categoryId].children && allCategoriesWithChildren[categoryId].children) {
            for (const catChild of allCategoriesWithChildren[categoryId].children) {
              const userChosedCat = userChosenCategories[categoryId].children.find(child => child?._id && child._id === catChild?._id);
              if (userChosedCat?.checked) {
                catChild.checked = true;
              }
            }
          }

        } else {
          allCategoriesWithChildren[categoryId].checked = false;

          if (mealType) {
            if (userChosenCategories[categoryId].weekly) {
              allCategoriesWithChildren[categoryId].weekly[mealType] = false;
            }
            
            if (userChosenCategories[categoryId].checkedTypical) {
              allCategoriesWithChildren[categoryId].checkedTypical[mealType] = false;
            }
          }

          if (userChosenCategories[categoryId].children && allCategoriesWithChildren[categoryId].children) {
            for (const catChild of allCategoriesWithChildren[categoryId].children) {
              const userChosedCat = userChosenCategories[categoryId].children.find(child => child?._id && child._id === catChild?._id);
              if (userChosedCat) {
                catChild.checked = false;
              }
            }
          }

          if (allCategoriesWithChildren[categoryId].children) {
            for (const catChild of allCategoriesWithChildren[categoryId].children) {
              if (catChild) {
                catChild.checked = false;
              }
            }
          }

        }

      }
    }
  }

  // updateFromChosenData(userChosenCategories: any = {}, productsByCategories: any = {}) {
  //   // console.log('updateFromChosenData');
  //   const allCategoriesWithChildren = this.allCategoriesWithChildren || {};
  //   const mealType = this.mealType;

  //   for (const categoryId in allCategoriesWithChildren) {
      
  //     if (!allCategoriesWithChildren[categoryId].weekly) {
  //       allCategoriesWithChildren[categoryId].weekly = {}
  //     }
  //     if (!allCategoriesWithChildren[categoryId].childrenProducts) {
  //       allCategoriesWithChildren[categoryId].childrenProducts = {}
  //     }
  //     if (!allCategoriesWithChildren[categoryId].checkedTypical) {
  //       allCategoriesWithChildren[categoryId].checkedTypical = {}
  //     }

  //     if (userChosenCategories[categoryId]) {

  //       if (userChosenCategories[categoryId].checked) {
  //         allCategoriesWithChildren[categoryId].checked = true;

  //         if (mealType) {
  //           if (userChosenCategories[categoryId].weekly?.[mealType]) {
  //             allCategoriesWithChildren[categoryId].weekly[mealType] = true;
  //           }

  //           if (userChosenCategories[categoryId].checkedTypical?.[mealType]) {
  //             allCategoriesWithChildren[categoryId].checkedTypical[mealType] = true;
  //           }
  //         }

  //       } else {
  //         allCategoriesWithChildren[categoryId].checked = false;

  //         if (mealType) {
  //           if (userChosenCategories[categoryId].weekly) {
  //             allCategoriesWithChildren[categoryId].weekly[mealType] = false;
  //           }
            
  //           if (userChosenCategories[categoryId].checkedTypical) {
  //             allCategoriesWithChildren[categoryId].checkedTypical[mealType] = false;
  //           }
  //         }

  //       }

  //     }
  //   }

  //   // for (const categoryId in allCategoriesWithChildren) {
  //   //   if (userChosenCategories[categoryId] && userChosenCategories[categoryId].checked) {
  //   //     allCategoriesWithChildren[categoryId].checked = true;

  //   //     if (userChosenCategories[categoryId].children) {
  //   //       allCategoriesWithChildren[categoryId].children = userChosenCategories[categoryId].children;
  //   //     }

  //   //     // if (mealType && this.compType === 'frequency') {
  //   //     //   // allCategoriesWithChildren[categoryId].frequency = '';
  //   //     //   // allCategoriesWithChildren[categoryId].frequency = 0;
  //   //     //   if (!allCategoriesWithChildren[categoryId].frequency) {
  //   //     //     allCategoriesWithChildren[categoryId].frequency = {}
  //   //     //   }
  //   //     //   if (userChosenCategories[categoryId].frequency?.[mealType]) {
  //   //     //     // if (!allCategoriesWithChildren[categoryId].frequency) {
  //   //     //     //   allCategoriesWithChildren[categoryId].frequency = {}
  //   //     //     // }
  //   //     //     allCategoriesWithChildren[categoryId].frequency[mealType] = userChosenCategories[categoryId].frequency[mealType];
  //   //     //   }
  //   //     // }
        
  //   //     if (mealType && this.compType === 'frequency') {
  //   //       if (!allCategoriesWithChildren[categoryId].weekly) {
  //   //         allCategoriesWithChildren[categoryId].weekly = {}
  //   //       }
  //   //       if (userChosenCategories[categoryId].weekly?.[mealType]) {
  //   //         allCategoriesWithChildren[categoryId].weekly[mealType] = true;
  //   //       }
  //   //     }

  //   //     if (this.compType === 'typical') {
  //   //       // allCategoriesWithChildren[categoryId].checkedTypical = false;
  //   //       // if (userChosenCategories[categoryId].checkedTypical) {
  //   //       //   allCategoriesWithChildren[categoryId].checkedTypical = true;
  //   //       // }
  //   //       if (!allCategoriesWithChildren[categoryId].childrenProducts) {
  //   //         allCategoriesWithChildren[categoryId].childrenProducts = {}
  //   //       }
  //   //       if (!allCategoriesWithChildren[categoryId].checkedTypical) {
  //   //         allCategoriesWithChildren[categoryId].checkedTypical = {}
  //   //       }
  //   //       if (userChosenCategories[categoryId].checkedTypical?.[mealType]) {
  //   //         // if (!allCategoriesWithChildren[categoryId].checkedTypical) {
  //   //         //   allCategoriesWithChildren[categoryId].checkedTypical = {}
  //   //         // }
  //   //         allCategoriesWithChildren[categoryId].checkedTypical[mealType] = true;
  //   //       }
  //   //     }
        
  //   //   }
  //   // }

  //   const objValuesArr = Object.values(allCategoriesWithChildren);

  //   const allOptions = objValuesArr.sort((a: any, b: any) => {
  //     if (!b?.reorder) return -1;
  //     if (!a?.reorder) return 0;

  //     return (a?.reorder ?? 0) - (b?.reorder ?? 0);
  //     return 0;
  //   });

  //   const allOptionsChosen = allOptions.filter((opt: any) => opt?.checked);

  //   this.allOptions = allOptions;
  //   this.allOptionsChosen = allOptionsChosen;

  //   if (mealType) {
  //     // const allOptionsChosenFrequencyNoChildren = objValuesArr.filter((opt: any) => opt?.checked && opt?.frequency?.[mealType] && opt.frequency[mealType] > 1);
  //     // const allOptionsChosenFrequencyNoChildren = objValuesArr.filter((opt: any) => opt?.checked && opt?.weekly?.[mealType]);

  //     const allOptionsChosenFrequencyNoChildren = allOptionsChosen.filter((opt: any) => opt?.weekly?.[mealType]);

  //     this.allOptionsChosenFrequency = this.fillChildrenProducts(allOptionsChosenFrequencyNoChildren, productsByCategories);
  //   }
  // }

  // fillChildrenProducts(allOptionsChosenFrequency: any[] = [], productsByCategories: any = {}) {
  //   for (const option of allOptionsChosenFrequency) {
  //     const { _id } = option;
  //     // if (productsByCategories[_id]) {
  //     //   option.childrenProducts = productsByCategories[_id];
  //     // }
  //     let childrenProducts = [];
  //     if (productsByCategories[_id]) {
  //       childrenProducts.push(...productsByCategories[_id]);
  //     }
  //     if (option.children) {
  //       for (const childOption of option.children) {
  //         if (childOption.checked && productsByCategories[childOption._id]) {
  //           childrenProducts.push(...productsByCategories[childOption._id]);
  //         }
  //       }
  //     }

  //     // const allIngredients = this.allIngredients || [];
  //     // const filterIngredients = this.filterIngredients || [];
  //     // const filterIngredientsIds = this.getIdsByFilter(filterIngredients, allIngredients);

  //     // childrenProducts = childrenProducts.filter(child => child.n_ingredients?.length && filterIngredientsIds.some(ingredient => child.n_ingredients.includes(ingredient)));

  //     if (!option.childrenProducts) {
  //       option.childrenProducts = {}
  //     }
  //     if (this.nameType) {
  //       option.childrenProducts[this.nameType] = childrenProducts;
  //     }

  //     // if (childrenProducts.length > 0) {
  //     //   option.childrenProducts = childrenProducts;
  //     // }
  //   }
  //   // console.log('allOptionsChosenFrequency', allOptionsChosenFrequency)
  //   return allOptionsChosenFrequency;
  // }

  // changedFrequency(opt: any, checked: boolean) {
  //   this.outputChosenCategories.emit(this.allCategoriesWithChildren);
  //   // this.updateValidation();
  //   if (this.mealType && this.nameType && !checked && opt?.childrenProducts?.[this.nameType]) {
  //     for (const child of opt.childrenProducts[this.nameType]) {
  //       this.outputProductChosen.emit({product: child, mealType: this.mealType, isChecked: false});
  //     }
  //   }
  // }

  changedWeekly(opt: any, checked: boolean) {
    this.outputChosenCategories.emit(this.allCategoriesWithChildren);
    if (this.mealType && !checked) {
      if (opt?.checkedTypical?.[this.mealType]) {
        opt.checkedTypical[this.mealType] = false;
      }
      if (this.nameType && opt?.childrenProducts?.[this.nameType]) {
        for (const child of opt.childrenProducts[this.nameType]) {
          this.outputProductChosen.emit({product: child, mealType: this.mealType, isChecked: false});
        }
      }
    }
  }
  changedTypical(opt: any, checked: boolean) {
    this.outputChosenCategories.emit(this.allCategoriesWithChildren);
    if (this.mealType && this.nameType && !checked && opt?.childrenProducts?.[this.nameType]) {
      for (const child of opt.childrenProducts[this.nameType]) {
        this.outputProductChosen.emit({product: child, mealType: this.mealType, isChecked: false});
      }
    }
  }

  // generateAllData(allProductsData: any[]) {
  //   if (!allProductsData) {
  //     return;
  //   }

  //   const allGeneratedData = this.generateOptions(allProductsData);
    
  //   const { products, productsByCategories, allCategoriesWithChildren } = allGeneratedData;

  //   // console.log('allGeneratedData', allGeneratedData);

  //   this.allProductsFiltered = products;
  //   this.productsByCategories = productsByCategories;
  //   this.allCategoriesWithChildren = allCategoriesWithChildren;

  //   this.updateFromChosenData(this.userChosenCategories, productsByCategories);

  //   // const objValues = Object.values(allCategoriesWithChildren);

  //   // this.allOptions = objValues.sort((a: any, b: any) => {
  //   //   if (!b?.reorder) return -1;
  //   //   if (!a?.reorder) return 0;

  //   //   return (a?.reorder ?? 0) - (b?.reorder ?? 0);
  //   //   return 0;
  //   // });

  //   // console.log(this.allOptions)

  //   if (this.compType === 'ingredient') {
  //     // if (this.otherFoodFormArr?.value?.length > 0) {
  //     //   this.isOther = true;
  //     // }
  //     if (this.disableAllNotEat?.value) {
  //       this.disableAll(true);
  //     }
  //     // if (this.isLocked) {
  //     //   this.disableAllNotEat?.disable();
  //     // } else {
  //     //   this.disableAllNotEat?.enable();
  //     // }
  //   }

  //   // this.updateValidation();
  // }

  checkIfIsError(childrenOptions: any[]) {
    // console.log('childrenOptions', childrenOptions)
    return childrenOptions.some(childOpt => childOpt.checked);
  }

  checkIfIsTypicalError(opt: any) {
    let hasError = false;

    const mealType = this.mealType;
    const nameType = this.nameType;

    if (opt?.checkedTypical[mealType]) {
      hasError = true;
      if (opt?.childrenProducts?.[nameType]) {
        hasError = !opt.childrenProducts[nameType].some(product => this.userChosenProducts?.[mealType]?.[product._id]);
      }
    }
    return hasError;
  }

  disableAllPopup() {
    const message = `${this.translate.instant("nutquiz.DISABLE_ALL_POPUP_1")} ${this.title} ${this.translate.instant("nutquiz.DISABLE_ALL_POPUP_2")}`;
    const options = {
      title: '',
      message,
      cancelText: this.translate.instant("general.cancel"),
      confirmText: this.translate.instant("general.ok")
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.disableAll(true);
      } else {
        this.disableAllNotEat.setValue(false, {emitEvent: false});
        // this.updateValidation();
      }
    });
  }

  calcOptions() {
    const options = this.allOptions || [];
    // const products = this.allProducts || [];
    if (this.compType === 'frequency') {
      this.allOptionsChosen = options.filter(option => option?.checked);
    }
    // this.allOptionsForTypical = options.filter(option => option?.checked && option?.weekly?.[this.mealType]);
    if (this.compType === 'typical') {
      this.allOptionsForTypical = options.filter(option => option?.checked && option?.weekly?.[this.mealType]);
      // this.allOptionsForTypical = this.calcForTypical(options, products)
    }
    // this.allCategoriesWithChildren = options.map(option => ({[option._id]: option}));
    const allCategoriesWithChildren: any = {};
    for (const option of options) {
      allCategoriesWithChildren[option._id] = option;
    }
    this.allCategoriesWithChildren = allCategoriesWithChildren;
    // console.log('this.allCategoriesWithChildren', this.allCategoriesWithChildren);

    // console.log('calcOptions', this.allOptions, this.allOptionsChosen, this.allOptionsForTypical, this.mealType, this.nameType);
  }

  // calcForTypical(options: any[], products: any[]) {
  //   let allOptions = [];

  //   const catName = this.nameType;

  //   const productsByCategories = this.extractCategories(products, catName);
    
  //   for (const option of options) {
  //     const { _id } = option;
      
  //     let childrenProducts = [];
  //     if (productsByCategories[_id]) {
  //       childrenProducts.push(...productsByCategories[_id]);
  //     }
  //     if (option.children) {
  //       for (const childOption of option.children) {
  //         if (childOption.checked && productsByCategories[childOption._id]) {
  //           childrenProducts.push(...productsByCategories[childOption._id]);
  //         }
  //         // if (productsByCategories[childOption._id]) {
  //         //   childrenProducts.push(...productsByCategories[childOption._id]);
  //         // }
  //       }
  //     }

  //     if (!option.childrenProducts) {
  //       option.childrenProducts = {}
  //     }
  //     if (catName) {
  //       option.childrenProducts[catName] = childrenProducts;
  //     }

  //   }
    
  //   return allOptions;
  // }

  extractCategories(products: any[], catName: string) {
    let productsByCategories: any = {};

    for (const product of products) {
      if (product.n_categories?.length) {
        for (const categoryObjectId of product.n_categories) {
          const catId = categoryObjectId.toString();
          if (!productsByCategories[catId]) {
            productsByCategories[catId] = [product];
          } else {
            if (!productsByCategories[catId].find((pr: any) => pr._id === product._id)) {
              productsByCategories[catId].push(product);
            }
          }
        }
      }
    }

    if (catName === 'drink') {
      productsByCategories['water'] = [{}];
    }

    return productsByCategories;
  }

  ngOnInit() {
    // this.generateAllData(this.allProducts);

    this.calcOptions();

    if (this.compType === 'ingredient') {
      // this.otherFoodFormArr?.valueChanges.subscribe(newVal => {
      //   this.updateValidation();
      // })
      
      this.disableAllNotEat?.valueChanges.subscribe(newVal => {
        // this.disableAll(newVal);
        if (newVal) {
          this.disableAllPopup();
        } else {
          // this.updateValidation();
        }
      })
    }
  }

}
