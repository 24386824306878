import { Component, ViewChildren, QueryList, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { appState } from '@services/app-state';

import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from 'src/shared/image-dialog.component';

@Component({
  templateUrl: "./healthquiz.component.html"
})
export class HealthquizComponent {

  // those ViewChild elements represent the closest HTML elements to the instructions of
  // how to fill the field and/or the field itself. used in order to scroll to that field
  @ViewChild("heq_tiredness_during_day_el") heq_tiredness_during_day_el: ElementRef;

  @ViewChild("heq_suffer_various_el") heq_suffer_various_el: ElementRef;
  @ViewChild("heq_suffer_various_el_1") heq_suffer_various_el_1: ElementRef;
  @ViewChild("heq_suffer_weekness_tired_train_el") heq_suffer_weekness_tired_train_el: ElementRef;
  @ViewChild("heq_suffer_digestion_el") heq_suffer_digestion_el: ElementRef;
  @ViewChild("heq_suffer_digestion_other_el") heq_suffer_digestion_other_el: ElementRef;
  @ViewChild("heq_suffer_digestion_excited_el") heq_suffer_digestion_excited_el: ElementRef;
  @ViewChild("heq_suffer_digestion_comment_el") heq_suffer_digestion_comment_el: ElementRef;

  @ViewChildren("heq_suffer_description_el") heq_suffer_description_el: QueryList<any>
  @ViewChildren("heq_suffer_started_date_el") heq_suffer_started_date_el: QueryList<any>
  @ViewChildren("heq_suffer_current_status_el") heq_suffer_current_status_el: QueryList<any>
  @ViewChildren("heq_suffer_end_date_el") heq_suffer_end_date_el: QueryList<any>

  @ViewChild("heq_suffer_bad_feeling_or_weekness_el") heq_suffer_bad_feeling_or_weekness_el: ElementRef;

  @ViewChild("heq_food_allergy_intolerance_el") heq_food_allergy_intolerance_el: ElementRef;
  // @ViewChild("heq_food_allergy_intolerance_el_1") heq_food_allergy_intolerance_el_1: ElementRef;

  @ViewChildren("heq_suffer_product_name_el") heq_suffer_product_name_el: QueryList<any>
  @ViewChildren("heq_suffer_product_name_other_details_el") heq_suffer_product_name_other_details_el: QueryList<any>
  @ViewChildren("heq_suffer_how_diagnosed_el") heq_suffer_how_diagnosed_el: QueryList<any>
  @ViewChildren("heq_suffer_how_diagnosed_other_details_el") heq_suffer_how_diagnosed_other_details_el: QueryList<any>
  @ViewChildren("heq_suffer_diagnosed_date_el") heq_suffer_diagnosed_date_el: QueryList<any>
  @ViewChildren("heq_suffer_more_comments_el") heq_suffer_more_comments_el: QueryList<any>
  @ViewChildren("heq_suffer_more_comments_text_el") heq_suffer_more_comments_text_el: QueryList<any>

  @ViewChild("heq_using_various_el") heq_using_various_el: ElementRef;
  @ViewChild("heq_using_various_el_1") heq_using_various_el_1: ElementRef;

  @ViewChildren("heq_using_description_el") heq_using_description_el: QueryList<any>;
  @ViewChildren("heq_using_recommended_by_el") heq_using_recommended_by_el: QueryList<any>;
  @ViewChildren("heq_recommended_by_text_el") heq_recommended_by_text_el: QueryList<any>;
  @ViewChildren("heq_assets_arr_el") heq_assets_arr_el: QueryList<any>;
  @ViewChildren("heq_duration_taken_el") heq_duration_taken_el: QueryList<any>;
  @ViewChildren("heq_comment_text_el") heq_comment_text_el: QueryList<any>;

  @ViewChild("heq_period_el") heq_period_el: ElementRef;
  @ViewChild("heq_period_start_date_el") heq_period_start_date_el: ElementRef;
  @ViewChild("heq_irregular_period_el") heq_irregular_period_el: ElementRef;
  @ViewChild("heq_irregular_period_status_el") heq_irregular_period_status_el: ElementRef;
  @ViewChild("heq_irregular_period_status_other_text_el") heq_irregular_period_status_other_text_el: ElementRef;
  @ViewChild("heq_taking_pills_el") heq_taking_pills_el: ElementRef;
  @ViewChild("heq_period_comments_el") heq_period_comments_el: ElementRef;
  @ViewChild("heq_period_comments_text_el") heq_period_comments_text_el: ElementRef;
  @ViewChild("heq_more_comments_el") heq_more_comments_el: ElementRef;
  @ViewChild("heq_more_comments_text_el") heq_more_comments_text_el: ElementRef;

  healthquizForm: FormGroup;

  quizPrefix = 'heq';

  base_image_upload_folder_path = '';

  restService: any;
  uploadUrlService: any;

  formContext: any;
  appState = appState;

  formOwnerUsr: any;
  isUnderNineteen = false;

  isLocked = false;
  isBusy = false;

  formInitiated = false;

  nutallergies: any[] = [];

  constructor(
    private dialogService: ConfirmDialogService,
    private translate: TranslateService,
    private restApiService: RestApiService<any>,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public dialog: MatDialog,
  ) {
    this.restService = this.restApiService.service('healthquiz');
    this.uploadUrlService = this.restApiService.service('upload-url');
    
    this.formContext = this;

    this.healthquizForm = this.formBuilder.group({
      _id: [''],
      heq_usr_id: [appState.user._id],//''
      heq_form_is_real: [false],
      heq_tiredness_during_day: [''],
      heq_suffer_various: [''],
      heq_suffer_weekness_tired_train: [false],
      heq_suffer_digestion: [false],
      heq_suffer_chronic_condition: [false],
      heq_suffer_acute_condition: [false],
      heq_suffer_acute_injury: [false],
      heq_suffer_bad_feeling_or_weekness: [false],
      heq_suffer_weekness_tired_train_desc: ['', [this.conditionalValidator(() => this.healthquizForm.value.heq_suffer_weekness_tired_train, Validators.required, null)]],
      heq_suffer_bad_feeling_or_weekness_desc: ['', [this.conditionalValidator(() => this.healthquizForm.value.heq_suffer_bad_feeling_or_weekness,
        Validators.required,
        null)]],
      heq_suffer_digestion_gas: [false],
      heq_suffer_digestion_stomach_pain: [false],
      heq_suffer_digestion_diarrhea: [false],
      heq_suffer_digestion_constipation: [false],
      heq_suffer_digestion_frequent: [false],
      heq_suffer_digestion_other: [false],
      heq_suffer_digestion_other_text: ['', [this.conditionalValidator(() => this.healthquizForm.value.heq_suffer_digestion_other,
        Validators.required,
        null)]],
      heq_suffer_digestion_excited: [''],
      heq_suffer_digestion_excited_text: ['', [this.conditionalValidator(() => this.healthquizForm.value.heq_suffer_digestion_excited == "no",
        Validators.required,
        null)]],
      heq_suffer_digestion_comment: [''],
      heq_suffer_digestion_comment_text: ['', [this.conditionalValidator(() => this.healthquizForm.value.heq_suffer_digestion_comment == "yes",
        Validators.required,
        null)]],
      heq_suffer_chronic_condition_arr: this.formBuilder.array([]),
      heq_suffer_acute_condition_arr: this.formBuilder.array([]),
      heq_suffer_acute_injury_arr: this.formBuilder.array([]),
      heq_using_various: [''],
      heq_using_chronic_medication: [false],
      heq_using_supplements_vitamins: [false],
      heq_using_protein_powder: [false],
      heq_using_chronic_medication_arr: this.formBuilder.array([]),
      heq_using_supplements_vitamins_arr: this.formBuilder.array([]),
      heq_using_protein_powder_arr: this.formBuilder.array([]),
      heq_period: [''],
      heq_period_start_date: ['', [this.conditionalValidator(() => this.healthquizForm.value.heq_period == "yes",
        Validators.required,
        null)]],
      heq_irregular_period: [''],
      heq_irregular_period_status: ['', [this.conditionalValidator(() => this.healthquizForm.value.heq_irregular_period == "no",
        Validators.required,
        null)]],
      heq_irregular_period_status_other_text: ['', [this.conditionalValidator(() => this.healthquizForm.value.heq_irregular_period_status == "אחר",
        Validators.required,
        null)]],
      heq_taking_pills: [''],
      heq_period_comments: [''],
      heq_period_comments_text: ['', [this.conditionalValidator(() => this.healthquizForm.value.heq_period_comments == "yes",
        Validators.required,
        null)]],
      heq_more_comments: [''],
      heq_more_comments_text: ['', [this.conditionalValidator(() => this.healthquizForm.value.heq_more_comments == "yes",
        Validators.required,
        null)]],

      heq_food_allergy_intolerance: [''],
      heq_allergy_arr: this.formBuilder.array([]),

      heq_food_intolerance: [false],
      heq_food_intolerance_arr: this.formBuilder.array([]), // suffer array:
      heq_food_allergy: [false],
      heq_food_allergy_arr: this.formBuilder.array([]), // suffer array:
    },
      {
        validators: [this.formCustomValidator.bind(this)]
      }
    );

    this.healthquizForm.get('heq_food_allergy_intolerance').valueChanges.subscribe(newVal => {
      if (newVal == 'כן' && this.healthquizForm.get('heq_allergy_arr')?.value?.length == 0) {
        this.addSufferItemNuq(this, 'heq_allergy_arr');
      }
    })

    // window["rf"] = this.healthquizForm;

    this.route.params.subscribe(async params => {
      if (params.id) {
        this.base_image_upload_folder_path = `healthquiz/heq_assets_${params.id}/`;
        const data = await this.restService.get(params.id);
        const quizUsrId = data.heq_usr_id;
        if (!quizUsrId) {
            return;
        }
        // const usr = await this.restApiService.service('users').get(quizUsrId).catch(e => { console.error(e) });
        const usr = data.quizOwner;
        if (!usr) {
          return;
        }
        this.formOwnerUsr = usr;
        this.isUnderNineteen = Boolean(+(usr['usr_age_formatted'] || 0) < 19);

        if (this.appState.user.usr_type === 'superadmin' || this.appState.user.usr_type === 'nutri') {
          this.appState.quizUser = usr;
        }

        if (this.translate.currentLang !== 'en') {
            if (data.quizOwner?.usr_gender === 'נקבה') {
                this.translate.use('he_F');
            } else {
                this.translate.use('he');
            }
        }

        this.isLocked = data && data.heq_locked;
        if (this.appState.user.usr_type === 'parent' || this.appState.user.usr_type === 'nutri') {
            this.isLocked = true;
        }
        if (this.appState.user.usr_type === 'nutri' && data.isReportEverPublished !== undefined) {
            this.isLocked = data.isReportEverPublished;
        }
        if (!data.savedAt && !data.heq_suffer_various && !data.heq_using_various && !data.heq_food_allergy_intolerance) {
          const lastQuiz = await this.restService.getLast({
            heq_usr_id: quizUsrId,
            _id: {
              $ne: params.id
            }
          });
          if (lastQuiz[0]) {
            const { heq_suffer_various, heq_suffer_weekness_tired_train, heq_suffer_digestion, heq_suffer_chronic_condition, heq_suffer_acute_condition, heq_suffer_acute_injury, heq_suffer_bad_feeling_or_weekness, heq_suffer_weekness_tired_train_desc, heq_suffer_bad_feeling_or_weekness_desc, heq_suffer_digestion_gas, heq_suffer_digestion_stomach_pain, heq_suffer_digestion_diarrhea, heq_suffer_digestion_constipation, heq_suffer_digestion_frequent, heq_suffer_digestion_other, heq_suffer_digestion_other_text, heq_suffer_digestion_excited, heq_suffer_digestion_excited_text, heq_suffer_digestion_comment, heq_suffer_digestion_comment_text, heq_suffer_chronic_condition_arr, heq_suffer_acute_condition_arr, heq_suffer_acute_injury_arr, heq_using_various, heq_using_chronic_medication, heq_using_chronic_medication_arr, heq_period, heq_period_start_date, heq_food_allergy_intolerance, heq_allergy_arr = [] } = lastQuiz[0];
            const stripped = { heq_suffer_various, heq_suffer_weekness_tired_train, heq_suffer_digestion, heq_suffer_chronic_condition, heq_suffer_acute_condition, heq_suffer_acute_injury, heq_suffer_bad_feeling_or_weekness, heq_suffer_weekness_tired_train_desc, heq_suffer_bad_feeling_or_weekness_desc, heq_suffer_digestion_gas, heq_suffer_digestion_stomach_pain, heq_suffer_digestion_diarrhea, heq_suffer_digestion_constipation, heq_suffer_digestion_frequent, heq_suffer_digestion_other, heq_suffer_digestion_other_text, heq_suffer_digestion_excited, heq_suffer_digestion_excited_text, heq_suffer_digestion_comment, heq_suffer_digestion_comment_text, heq_suffer_chronic_condition_arr, heq_suffer_acute_condition_arr, heq_suffer_acute_injury_arr, heq_using_various, heq_using_chronic_medication, heq_using_chronic_medication_arr, heq_period, heq_period_start_date, heq_food_allergy_intolerance, heq_allergy_arr };
            this.healthquizForm.patchValue(stripped);
            this.patchFormArrays(stripped);
            
            this.oldValuesPopup();
          }
          this.healthquizForm.get('heq_usr_id').patchValue(data.heq_usr_id);
          this.healthquizForm.get('_id').patchValue(data._id);
        } else {
          this.healthquizForm.patchValue(data);
          this.patchFormArrays(data);
        }

        if (this.isLocked) {
            this.healthquizForm.disable();
        }
      } else {
        const res = await this.restService.getLast({ heq_usr_id: this.appState.user._id });
        const navTo = res[0]?._id ? `Healthquiz/${res[0]._id}` : '/';
        this.router.navigate([navTo], { replaceUrl: true });
        return;
      }

      this.formInitiated = true;
    });

  }

  async ngOnInit() {
    this.nutallergies = await this.getAllergies();
  }

  ngOnDestroy() {
    if (this.translate.currentLang !== 'en') {
      if (this.appState.user.usr_gender === 'נקבה') {
          this.translate.use('he_F');
      } else {
          this.translate.use('he');
      }
    }
  }

  async getAllergies() {
    let allergies = [];
    try {
      allergies = await this.restApiService.service('nutallergies').find({
        query: {
          $paginate: false,
        }
      });
    } catch (error) {
      console.log(error);
    }

    return allergies;
  }

  oldValuesPopup() {
    const options = {
      title: '',
      message: 'חלק מהתשובות בשאלון זה מולאו אוטומטית על פי התשובות שלך מהפעם הקודמת. במידה שיש שינוי, חשוב לעדכן את התשובות לסטטוס הנוכחי.',
      // cancelText: this.translate.instant("general.no"),
      // confirmText: this.translate.instant("general.yes"),
      confirmText: 'אישור',
    };
    this.dialogService.open(options);
  }

  conditionalValidator(predicate: Function,
    validator: Function,
    parentKeyAndValueEquals?: any, parentKeyAndValueNotEquals?: any): Function {
    return (formControl => {

      if (!formControl.parent) {
        return null;
      }

      let error = null;

      if (parentKeyAndValueEquals) {
        if (formControl.parent.get(parentKeyAndValueEquals.key).value == parentKeyAndValueEquals.value) {
          error = validator(formControl);
        }
      } else if (parentKeyAndValueNotEquals) {
        if (formControl.parent.get(parentKeyAndValueNotEquals.key).value != parentKeyAndValueNotEquals.value) {
          error = validator(formControl);
        }
      } else {
        if (predicate()) {
          error = validator(formControl);
        }
      }

      return error;
    })
  }

  formCustomValidator(formGroup: FormGroup) {

    if (!this.formInitiated) {
      return null;
    }

    if (formGroup.value.heq_tiredness_during_day == "") {
      return {
        msg: this.translate.instant("health.ERROR_TIREDNESS"),
        scrollIntoNativeElement: this.heq_tiredness_during_day_el
      }
    }

    if (formGroup.value.heq_suffer_various == "") {
      return {
        msg: this.translate.instant("health.ERROR_A"),
        scrollIntoNativeElement: this.heq_suffer_various_el
      }
    }

    if (formGroup.value.heq_suffer_various == "yes" &&
      !formGroup.value.heq_suffer_weekness_tired_train &&
      !formGroup.value.heq_suffer_digestion &&
      !formGroup.value.heq_suffer_chronic_condition &&
      !formGroup.value.heq_suffer_acute_condition &&
      !formGroup.value.heq_suffer_acute_injury &&
      !formGroup.value.heq_suffer_bad_feeling_or_weekness
    ) {
      return {
        msg: this.translate.instant("health.ERROR_B"),
        scrollIntoNativeElement: this.heq_suffer_various_el_1
      };
    }

    if (formGroup.value.heq_suffer_weekness_tired_train && formGroup.value.heq_suffer_weekness_tired_train_desc == "") {
      return {
        msg: this.translate.instant("health.ERROR_C"),
        scrollIntoNativeElement: this.heq_suffer_weekness_tired_train_el
      };
    }

    if (formGroup.value.heq_suffer_digestion &&
      !formGroup.value.heq_suffer_digestion_gas &&
      !formGroup.value.heq_suffer_digestion_stomach_pain &&
      !formGroup.value.heq_suffer_digestion_diarrhea &&
      !formGroup.value.heq_suffer_digestion_constipation &&
      !formGroup.value.heq_suffer_digestion_frequent &&
      !formGroup.value.heq_suffer_digestion_other
    ) {
      return {
        msg: this.translate.instant("health.ERROR_D"),
        scrollIntoNativeElement: this.heq_suffer_digestion_el
      };
    }

    if (formGroup.value.heq_suffer_digestion_other && formGroup.value.heq_suffer_digestion_other_text == "") {
      return {
        msg: this.translate.instant("health.ERROR_E"),
        scrollIntoNativeElement: this.heq_suffer_digestion_other_el
      };
    }

    if (formGroup.value.heq_suffer_digestion && formGroup.value.heq_suffer_digestion_excited == "") {
      return {
        msg: this.translate.instant("health.ERROR_F"),
        scrollIntoNativeElement: this.heq_suffer_digestion_excited_el
      };
    }

    if (formGroup.value.heq_suffer_digestion_excited == "no" && formGroup.value.heq_suffer_digestion_excited_text == "") {
      return {
        msg: this.translate.instant("health.ERROR_G"),
        scrollIntoNativeElement: this.heq_suffer_digestion_excited_el
      };
    }

    if (formGroup.value.heq_suffer_digestion && formGroup.value.heq_suffer_digestion_comment == "") {
      return {
        msg: this.translate.instant("health.ERROR_H"),
        scrollIntoNativeElement: this.heq_suffer_digestion_comment_el
      };
    }

    // note- this is not a mistake in the "scrollIntoNativeElement", we want to show the user the same form position as 
    // the previous line
    if (formGroup.value.heq_suffer_digestion_comment == "yes" && formGroup.value.heq_suffer_digestion_comment_text == "") {
      return {
        msg: this.translate.instant("health.ERROR_I"),
        scrollIntoNativeElement: this.heq_suffer_digestion_comment_el
      };
    }

    if (formGroup.value.heq_suffer_chronic_condition) {
      let res = this.validateSufferArrayFormField(formGroup, "heq_suffer_chronic_condition_arr");
      if (res) return res;
    }

    if (formGroup.value.heq_suffer_acute_condition) {
      let res = this.validateSufferArrayFormField(formGroup, "heq_suffer_acute_condition_arr");
      if (res) return res;
    }

    if (formGroup.value.heq_suffer_acute_injury) {
      let res = this.validateSufferArrayFormField(formGroup, "heq_suffer_acute_injury_arr");
      if (res) return res;
    }

    if (formGroup.value.heq_suffer_bad_feeling_or_weekness && formGroup.value.heq_suffer_bad_feeling_or_weekness_desc == "") {
      return {
        msg: this.translate.instant("health.ERROR_J"),
        scrollIntoNativeElement: this.heq_suffer_bad_feeling_or_weekness_el
      };
    }

    if (formGroup.value.heq_food_allergy_intolerance == "") {
      return {
        msg: this.translate.instant("health.ERROR_K"),
        scrollIntoNativeElement: this.heq_food_allergy_intolerance_el
      }
    }

    if (formGroup.value.heq_food_allergy_intolerance == "כן") {
      if (formGroup.value.heq_allergy_arr?.length) {
        let res = this.validateSufferArrayFormFieldNuq(formGroup, "heq_allergy_arr");
        if (res) return res;
      } else {
        return {
          msg: this.translate.instant("health.ERROR_ALLERGY_ARR"),
          scrollIntoNativeElement: this.heq_food_allergy_intolerance_el
        };
      }
    }

    // if (formGroup.value.heq_food_allergy_intolerance == "כן" &&
    //   !formGroup.value.heq_food_intolerance &&
    //   !formGroup.value.heq_food_allergy
    // ) {
    //   return {
    //     msg: this.translate.instant("health.ERROR_L"),
    //     scrollIntoNativeElement: this.heq_food_allergy_intolerance_el_1
    //   };
    // }

    // if (formGroup.value.heq_food_intolerance) {
    //   let res = this.validateSufferArrayFormFieldNuq(formGroup, "heq_food_intolerance_arr");
    //   if (res) return res;
    // }

    // if (formGroup.value.heq_food_allergy) {
    //   let res = this.validateSufferArrayFormFieldNuq(formGroup, "heq_food_allergy_arr");
    //   if (res) return res;
    // }

    if (formGroup.value.heq_using_various == "") {
      return {
        msg: this.translate.instant("health.ERROR_M"),
        scrollIntoNativeElement: this.heq_using_various_el
      }
    }

    if (formGroup.value.heq_using_various == "yes" &&
      !formGroup.value.heq_using_chronic_medication &&
      !formGroup.value.heq_using_supplements_vitamins &&
      !formGroup.value.heq_using_protein_powder
    ) {
      return {
        msg: this.translate.instant("health.ERROR_N"),
        scrollIntoNativeElement: this.heq_using_various_el_1
      };
    }

    if (formGroup.value.heq_using_chronic_medication) {
      let res = this.validateUsingArrayFormField(formGroup, "heq_using_chronic_medication_arr");
      if (res) return res;
    }

    if (formGroup.value.heq_using_supplements_vitamins) {
      let res = this.validateUsingArrayFormField(formGroup, "heq_using_supplements_vitamins_arr");
      if (res) return res;
    }

    if (formGroup.value.heq_using_protein_powder) {
      let res = this.validateUsingArrayFormField(formGroup, "heq_using_protein_powder_arr");
      if (res) return res;
    }

    // women fields:

    if (this.formOwnerUsr && this.formOwnerUsr.usr_gender === "נקבה") {
      if (formGroup.value.heq_period == "") {
        return {
          msg: this.translate.instant("health.ERROR_P"),
          scrollIntoNativeElement: this.heq_period_el
        };
      }

      if (formGroup.value.heq_period == "yes" && formGroup.value.heq_period_start_date == "") {
        return {
          msg: this.translate.instant("health.ERROR_Q"),
          scrollIntoNativeElement: this.heq_period_start_date_el
        };
      }

      if (formGroup.value.heq_period == "yes" && formGroup.value.heq_irregular_period == "") {
        return {
          msg: this.translate.instant("health.ERROR_R"),
          scrollIntoNativeElement: this.heq_irregular_period_el
        };
      }

      if (formGroup.value.heq_irregular_period == "no" && formGroup.value.heq_irregular_period_status == "") {
        return {
          msg: this.translate.instant("health.ERROR_S"),
          scrollIntoNativeElement: this.heq_irregular_period_status_el
        };
      }

      if (formGroup.value.heq_irregular_period_status == "אחר" && formGroup.value.heq_irregular_period_status_other_text == "") {
        return {
          msg: this.translate.instant("health.ERROR_T"),
          scrollIntoNativeElement: this.heq_irregular_period_status_other_text_el
        };
      }

      if (formGroup.value.heq_period == "yes" && formGroup.value.heq_taking_pills == "") {
        return {
          msg: this.translate.instant("health.ERROR_U"),
          scrollIntoNativeElement: this.heq_taking_pills_el
        };
      }

      if (formGroup.value.heq_period_comments == "") {
        return {
          msg: this.translate.instant("health.ERROR_V"),
          scrollIntoNativeElement: this.heq_period_comments_el
        };
      }

      if (formGroup.value.heq_period_comments == "yes" && formGroup.value.heq_period_comments_text == "") {
        return {
          msg: this.translate.instant("health.ERROR_W"),
          scrollIntoNativeElement: this.heq_period_comments_text_el
        };
      }
    }

    if (formGroup.value.heq_more_comments == "") {
      return {
        msg: this.translate.instant("health.ERROR_X"),
        scrollIntoNativeElement: this.heq_more_comments_el
      };
    }

    if (formGroup.value.heq_more_comments == "yes" && formGroup.value.heq_more_comments_text == "") {
      return {
        msg: this.translate.instant("health.ERROR_Y"),
        scrollIntoNativeElement: this.heq_more_comments_text_el
      };
    }

    return null;
  }

  validateUsingArrayFormField(formGroup: FormGroup, arrayFieldName: string) {

    let descriptionViewChildrenArr = this.heq_using_description_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);
    let recommendedByViewChildrenArr = this.heq_using_recommended_by_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);
    let recommendedByTextViewChildrenArr = this.heq_recommended_by_text_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);
    let assetsArrViewChildrenArr = this.heq_assets_arr_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);
    let durationTakenViewChildrenArr = this.heq_duration_taken_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);
    
    // validate each mini-form
    for (let i = 0; i < (formGroup.get(arrayFieldName) as FormArray).controls.length; i++) {
      let innerFormGroup: FormGroup = ((formGroup.get(arrayFieldName) as FormArray).controls[i] as FormGroup);

      if (innerFormGroup.value.description == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_A"),
          scrollIntoNativeElement: descriptionViewChildrenArr[i]
        };
      }

      if (innerFormGroup.value.recommended_by == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_B"),
          scrollIntoNativeElement: recommendedByViewChildrenArr[i]
        };
      }

      if (innerFormGroup.value.recommended_by == "אחר" && innerFormGroup.value.recommended_by_text == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_C"),
          scrollIntoNativeElement: recommendedByTextViewChildrenArr[i]
        };
      }

      if (innerFormGroup.value.assets_arr && innerFormGroup.value.assets_arr.length == 0) {
        return {
          msg: this.translate.instant("health.ERROR_1_D"),
          scrollIntoNativeElement: assetsArrViewChildrenArr[i]
        };
      }

      if (innerFormGroup.value.duration_taken == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_E"),
          scrollIntoNativeElement: durationTakenViewChildrenArr[i]
        };
      }

    }

    return null;
  }


  validateSufferArrayFormField(formGroup: FormGroup, arrayFieldName: string) {

    let descriptionViewChildrenArr = this.heq_suffer_description_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);
    let startedDateViewChildrenArr = this.heq_suffer_started_date_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);
    let currentStatusViewChildrenArr = this.heq_suffer_current_status_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);
    let endDateViewChildrenArr = this.heq_suffer_end_date_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);

    // validate each mini-form
    for (let i = 0; i < (formGroup.get(arrayFieldName) as FormArray).controls.length; i++) {
      let innerFormGroup: FormGroup = ((formGroup.get(arrayFieldName) as FormArray).controls[i] as FormGroup);

      if (innerFormGroup.value.description == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_F"),
          scrollIntoNativeElement: descriptionViewChildrenArr[i]
        };
      }

      if (innerFormGroup.value.started_date == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_G"),
          scrollIntoNativeElement: startedDateViewChildrenArr[i]
        };
      }

      if (innerFormGroup.value.current_status == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_H"),
          scrollIntoNativeElement: currentStatusViewChildrenArr[i]
        };
      }

      if (innerFormGroup.value.current_status != "מתאמן כרגיל" && innerFormGroup.value.end_date == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_I"),
          scrollIntoNativeElement: endDateViewChildrenArr[i]
        };
      }

    }

    return null;
  }

  // // ===============

  // // comments:

  // // try this also:
  // https://netbasal.com/three-ways-to-dynamically-alter-your-form-validation-in-angular-e5fd15f1e946 

  // // Things that I tried:
  // // ***
  // this.healthquizForm.get(dependentFieldName).setErrors(null);
  // this.healthquizForm.get(dependentFieldName).setValidators(null);
  // this.healthquizForm.get(dependentFieldName).setValidators([Validators.required]);
  // this.healthquizForm.get(dependentFieldName).updateValueAndValidity();
  // this.healthquizForm.get(dependentFieldName).reset(this.healthquizForm.get(dependentFieldName).value);
  // this.healthquizForm.get(dependentFieldName).markAsPristine();
  // this.healthquizForm.get(dependentFieldName).markAsUntouched();

  /*
    More options: 
      1. 
      2.
  
  ***
    Also worth noting- this happens when pressing on a button of type SUBMIT. If the button
    was of type "button", the form theoretically not respond this way
  */
  // // ===============


  confirmAndResetUsing(event) {
    if (this.isLocked) return;

    if (this.healthquizForm.value.heq_using_chronic_medication == false &&
      this.healthquizForm.value.heq_using_supplements_vitamins == false &&
      this.healthquizForm.value.heq_using_protein_powder == false) {
      return;
    }

    event.preventDefault();

    const options = {
      title: '',
      message: this.translate.instant("health.ERROR_PROBLEMS"),
      cancelText: this.translate.instant("general.no"),
      confirmText: this.translate.instant("general.yes")
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
        if (confirmed) {
          this.resetField("heq_using_chronic_medication", false);
          this.resetField("heq_using_supplements_vitamins", false);
          this.resetField("heq_using_protein_powder", false);
          this.resetField("heq_using_various", "no");
        }
    });
  }

  confirmAndResetSuffers(event) {
    if (this.isLocked) return;

    if (this.healthquizForm.value.heq_suffer_weekness_tired_train == false &&
      this.healthquizForm.value.heq_suffer_digestion == false &&
      this.healthquizForm.value.heq_suffer_chronic_condition == false &&
      this.healthquizForm.value.heq_suffer_acute_condition == false &&
      this.healthquizForm.value.heq_suffer_acute_injury == false &&
      this.healthquizForm.value.heq_suffer_bad_feeling_or_weekness == false) {
      return;
    }

    event.preventDefault();

    const options = {
      title: '',
      message: this.translate.instant("health.ERROR_PROBLEMS"),
      cancelText: this.translate.instant("general.no"),
      confirmText: this.translate.instant("general.yes")
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
        if (confirmed) {
            this.resetField("heq_suffer_weekness_tired_train", false);
            this.resetField("heq_suffer_digestion", false);
            this.resetField("heq_suffer_chronic_condition", false);
            this.resetField("heq_suffer_acute_condition", false);
            this.resetField("heq_suffer_acute_injury", false);
            this.resetField("heq_suffer_bad_feeling_or_weekness", false);
            this.resetField("heq_suffer_various", "no");
        }
    });
  }

  patchFormArrays(data: any) {

    let sufferArraysFieldNames: Array<string> = [
      "heq_suffer_chronic_condition_arr",
      "heq_suffer_acute_condition_arr",
      "heq_suffer_acute_injury_arr",
    ];

    let sufferArraysFieldNamesNuq: Array<string> = [
      "heq_allergy_arr",
      "heq_food_intolerance_arr",
      "heq_food_allergy_arr",
    ];

    let usingArraysFieldNames: Array<string> = [
      "heq_using_chronic_medication_arr",
      "heq_using_supplements_vitamins_arr",
      "heq_using_protein_powder_arr",
    ];

    this.patchGenericArray(data, sufferArraysFieldNames, this, this.createSufferItem);
    this.patchGenericArray(data, sufferArraysFieldNamesNuq, this, this.createSufferItemNuq);
    this.patchGenericArray(data, usingArraysFieldNames, this, this.createUsingItem, "assets_arr", this.createUsingAssetItem);

  }

  patchGenericArray(data, fieldArrayNames, context, createFunctionRef, innerArrFieldName?, innerCreateFunctionRef?) {
    for (let i = 0; i < fieldArrayNames.length; i++) {
      console.log(data[fieldArrayNames[i]])
      if (!data[fieldArrayNames[i]]) continue;
      let formArray: FormArray = this.healthquizForm.get(fieldArrayNames[i]) as FormArray;
      formArray.clear();
      for (let innerIndex = 0; innerIndex < data[fieldArrayNames[i]].length; innerIndex++) {

        let newFormElement = createFunctionRef(context);
        formArray.push(newFormElement);

        // this means the array has a sub-array, so we need to create a skeleton for it too
        if (innerArrFieldName && innerCreateFunctionRef) {
          let innerArray: FormArray = newFormElement.get(innerArrFieldName) as FormArray;

          innerArray.clear();

          for (let innerSubIndex = 0; innerSubIndex < data[fieldArrayNames[i]][innerIndex][innerArrFieldName].length; innerSubIndex++) {
            innerArray.push(innerCreateFunctionRef(context));
          }
        }
      }
      formArray.patchValue(data[fieldArrayNames[i]]);
    }
  }

  resetField(filedName, value) {
    this.healthquizForm.controls[filedName].reset(value);
  }


  addSufferItem(context, arrFieldName) {
    let sufferArr = context.healthquizForm.get(arrFieldName) as FormArray;
    sufferArr.push(context.createSufferItem(context));
  }

  removeSufferItem(context, checkboxFieldName, arrFieldName, index) {
    let sufferArr = context.healthquizForm.get(arrFieldName) as FormArray;
    sufferArr.removeAt(index);
    // if (sufferArr.length == 0) {
    //   context.healthquizForm.controls[checkboxFieldName].reset(false);
    // }
  }

  createSufferItem(context) {
    return context.formBuilder.group({
      description: ['', [Validators.required]],
      started_date: ['', [Validators.required]],
      current_status: ['', [Validators.required]],
      end_date: ['', [context.conditionalValidator(null,
        Validators.required,
        null,
        { key: "current_status", value: "מתאמן כרגיל" })]],
    })
  }

  sufferCheckboxOnChange(event, sufferArrayFieldName: string) {
    if (event.checked) {
      if (this.healthquizForm.value[sufferArrayFieldName].length == 0) {
        this.addSufferItem(this, sufferArrayFieldName);
      }
    }
  }

  addUsingItem(context, arrFieldName) {
    let usingArr = context.healthquizForm.get(arrFieldName) as FormArray;
    usingArr.push(context.createUsingItem(context));
  }

  removeUsingItem(context, checkboxFieldName, arrFieldName, index) {
    let usingArr = context.healthquizForm.get(arrFieldName) as FormArray;

    // first remove all the assets from that form
    let assetsArray: FormArray = usingArr.controls[index].get("assets_arr") as FormArray;
    context.clearAssetsArray(assetsArray);

    usingArr.removeAt(index);
    if (usingArr.length == 0) {
      context.healthquizForm.controls[checkboxFieldName].reset(false);
    }
  }

  createUsingItem(context) {
    return context.formBuilder.group({
      _id: '',
      description: ['', [Validators.required]],
      recommended_by: ['', [Validators.required]],
      recommended_by_text: ['', [context.conditionalValidator(null,
        Validators.required,
        { key: "recommended_by", value: "אחר" },
        null)]],
      duration_taken: ['', [Validators.required]],
      assets_arr: context.formBuilder.array([]),
      more_assets: '',
      comment: '',
      comment_text: '',
    })
  }

  usingCheckboxOnChange(event, usingArrayFieldName: string) {
    if (event.checked) {
      if (this.healthquizForm.value[usingArrayFieldName].length == 0) {
        this.addUsingItem(this, usingArrayFieldName);
      }
    }
  }

  createUsingAssetItem(context) {
    return context.formBuilder.control({});
  }

  async onSubmit(form: FormGroup, prefix: string) {
    this.isBusy = true;

    this.clearValuesFromUnusedFields();
    const validateRes = this.validateForm();

    if (!validateRes.valid) {
        if (validateRes.scrollIntoNativeElement) {
          validateRes.scrollIntoNativeElement.nativeElement.scrollIntoView();
        }
        // this.showErrors = true;
        this.showStatus(validateRes.msg, null);

        this.isBusy = false;
        return;
    } else {
        // this.showErrors = false;
    }

    this.resetField(`${prefix}_form_is_real`, true);

    this.clearAllIds();

    if (form.value._id) {
        const res = await this.restService.patch(form.value._id, form.value).catch(e => { console.error(e); });
        if (res && res._id && res[`${prefix}_usr_id`]) {
            // this.buildAssetsArray(form, `${prefix}_assets_arr`, res);
            form.patchValue(res);
            this.patchFormArrays(res);
            
            this.showStatus(this.translate.instant('general.savedSuccessfully'), null);
            if (this.appState.user.usr_type === 'player'){
              setTimeout(()=>{
                this.router.navigate([`Userprogress`]);
                this.isBusy = false;
              }, 2000);
              return;
            }
        } else {
            this.showStatus('אירעה שגיאה', null);
        }
    }
    
    this.isBusy = false;
  }

  validateForm() {

    if (!this.healthquizForm.valid)
      return {
        valid: false,
        msg: this.healthquizForm.errors ? this.healthquizForm.errors.msg : "נא לתקן את השדות האדומים",
        scrollIntoNativeElement: this.healthquizForm.errors ? this.healthquizForm.errors.scrollIntoNativeElement : null
      }

    return {
      valid: true,
      msg: "ולידציה עברה בהצלחה"
    }

  }

  clearIdsIfNotExistFromArrayItems(arr: any) {
    let arrLen = arr.length;
    for (let i = 0; i < arrLen; i++) {

      if (arr[i].assets_arr && arr[i].assets_arr.length) {
        let internalArrayLen = arr[i].assets_arr.length;
        for (let internalInd = 0; internalInd < internalArrayLen; internalInd++) {
          if (String(arr[i].assets_arr[internalInd]._id).length == 0) {
            delete arr[i].assets_arr[internalInd]._id;
          }
        }
      }

      if (String(arr[i]._id).length != 0) {
        continue;
      }

      delete arr[i]._id;
    }
  }

  clearAllIds() {
    this.clearIdsIfNotExistFromArrayItems(this.healthquizForm.value.heq_suffer_chronic_condition_arr);
    this.clearIdsIfNotExistFromArrayItems(this.healthquizForm.value.heq_suffer_acute_condition_arr);
    this.clearIdsIfNotExistFromArrayItems(this.healthquizForm.value.heq_suffer_acute_injury_arr);
    this.clearIdsIfNotExistFromArrayItems(this.healthquizForm.value.heq_using_chronic_medication_arr);
    this.clearIdsIfNotExistFromArrayItems(this.healthquizForm.value.heq_using_supplements_vitamins_arr);
    this.clearIdsIfNotExistFromArrayItems(this.healthquizForm.value.heq_using_protein_powder_arr);
  }
  
  /*
  Go through all the fields in the form, clean unused ones
  */
  clearValuesFromUnusedFields() {

    // if (this.healthquizForm.value.heq_food_intolerance == false) {
    //   this.resetFormArray('heq_food_intolerance_arr');
    // }
    // if (this.healthquizForm.value.heq_food_allergy == false) {
    //   this.resetFormArray('heq_food_allergy_arr');
    // }
    // this.clearSufferArrayDetailsFieldAccordingToStatusNuq("heq_food_intolerance_arr");
    // this.clearSufferArrayDetailsFieldAccordingToStatusNuq("heq_food_allergy_arr");

    if (this.healthquizForm.value.heq_food_allergy_intolerance !== "כן") {
      this.resetFormArray('heq_allergy_arr');
    }
    this.clearSufferArrayDetailsFieldAccordingToStatusNuq("heq_allergy_arr");

    if (this.healthquizForm.value.heq_suffer_weekness_tired_train == false) {
      this.resetField("heq_suffer_weekness_tired_train_desc", '');
    }

    if (this.healthquizForm.value.heq_suffer_digestion == false) {
      this.resetField("heq_suffer_digestion_gas", false);
      this.resetField("heq_suffer_digestion_stomach_pain", false);
      this.resetField("heq_suffer_digestion_diarrhea", false);
      this.resetField("heq_suffer_digestion_constipation", false);
      this.resetField("heq_suffer_digestion_frequent", false);
      this.resetField("heq_suffer_digestion_other", false);
      this.resetField("heq_suffer_digestion_other_text", '');
      this.resetField("heq_suffer_digestion_excited", '');
      this.resetField("heq_suffer_digestion_comment", '');
    }

    if (this.healthquizForm.value.heq_suffer_digestion_other == false) {
      this.resetField("heq_suffer_digestion_other_text", '');
    }

    if (this.healthquizForm.value.heq_suffer_digestion_excited != 'no') {
      this.resetField("heq_suffer_digestion_excited_text", '');
    }

    if (this.healthquizForm.value.heq_suffer_digestion_comment != 'yes') {
      this.resetField("heq_suffer_digestion_comment_text", '');
    }

    if (this.healthquizForm.value.heq_suffer_chronic_condition == false) {
      this.resetFormArray('heq_suffer_chronic_condition_arr');
    }
    if (this.healthquizForm.value.heq_suffer_acute_condition == false) {
      this.resetFormArray('heq_suffer_acute_condition_arr');
    }
    if (this.healthquizForm.value.heq_suffer_acute_injury == false) {
      this.resetFormArray('heq_suffer_acute_injury_arr');
    }
    this.clearSufferArrayDateFieldAccordingToStatus("heq_suffer_chronic_condition_arr");
    this.clearSufferArrayDateFieldAccordingToStatus("heq_suffer_acute_condition_arr");
    this.clearSufferArrayDateFieldAccordingToStatus("heq_suffer_acute_injury_arr");

    if (this.healthquizForm.value.heq_suffer_bad_feeling_or_weekness == false) {
      this.resetField("heq_suffer_bad_feeling_or_weekness_desc", '');
    }

    if (this.healthquizForm.value.heq_using_chronic_medication == false) {
      this.resetFormArray('heq_using_chronic_medication_arr');
    }
    if (this.healthquizForm.value.heq_using_supplements_vitamins == false) {
      this.resetFormArray('heq_using_supplements_vitamins_arr');
    }
    if (this.healthquizForm.value.heq_using_protein_powder == false) {
      this.resetFormArray('heq_using_protein_powder_arr');
    }

    this.clearUsingArrayRecommendedTextIfNeeded("heq_using_chronic_medication_arr");
    this.clearUsingArrayRecommendedTextIfNeeded("heq_using_supplements_vitamins_arr");
    this.clearUsingArrayRecommendedTextIfNeeded("heq_using_protein_powder_arr");

    // women fields:
    if (this.healthquizForm.value.heq_period == 'no') {
      this.resetField("heq_period_start_date", '');
      this.resetField("heq_irregular_period", '');
      this.resetField("heq_taking_pills", '');
    }

    if (this.healthquizForm.value.heq_irregular_period == 'yes') {
      this.resetField("heq_irregular_period_status", '');
    }

    if (this.healthquizForm.value.heq_irregular_period_status != 'אחר') {
      this.resetField("heq_irregular_period_status_other_text", '');
    }



    // note: nothing to be handled with "heq_taking_pills" field.
    // we point it out so the programmer will know it's ok
    if (this.healthquizForm.value.heq_period_comments == 'no') {
      this.resetField("heq_period_comments_text", '');
    }

    // general fields:
    if (this.healthquizForm.value.heq_more_comments == 'no') {
      this.resetField("heq_more_comments_text", '');
    }
  }

  clearSufferArrayDateFieldAccordingToStatus(arrayFieldName: string) {
    let formArray = this.healthquizForm.get(arrayFieldName) as FormArray;
    let length: number = formArray.length;
    for (let i = 0; i < length; i++) {
      if (formArray.controls[i].value.current_status == 'מתאמן כרגיל') {
        formArray.controls[i].get("end_date").reset('');
      }
    }
  }

  clearUsingArrayRecommendedTextIfNeeded(arrayFieldName: string) {
    let formArray = this.healthquizForm.get(arrayFieldName) as FormArray;
    let length: number = formArray.length;
    for (let i = 0; i < length; i++) {
      if (formArray.controls[i].value.recommended_by != 'אחר') {
        formArray.controls[i].get("recommended_by_text").reset('');
      }
    }
  }

  resetFormArray(arrayFieldName) {

    // option #1: 
    let formArray = this.healthquizForm.get(arrayFieldName) as FormArray;
    let length: number = formArray.length;
    for (let i = 0; i < length; i++) {
      formArray.removeAt(0);
    }

    // option #2: (Does not work. check this out to see)
    // let formArray = this.healthquizForm.get(arrayFieldName) as FormArray;
    // formArray.reset([]);    
  }

  clearAssetsArray(assetsArray: FormArray) {
    let assetsArrayLen = assetsArray.length;
    for (let i = 0; i < assetsArrayLen; i++) {
      // TODO - remove the asset from the server using: assetsArray.value[i]._id
    }

    // the following is done because formArray.reset([]); does not work.
    for (let i = 0; i < assetsArrayLen; i++) {
      assetsArray.removeAt(0);
    }
  }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];

  }

  confirmAndResetSuffersNuq(event) {
    if (this.isLocked) return;

    if (this.healthquizForm.value.heq_food_intolerance == false &&
      this.healthquizForm.value.heq_food_allergy == false
    ) {
      return;
    }

    event.preventDefault();

    const options = {
      title: '',
      message: this.translate.instant("health.ERROR_ALLERGY"),
      cancelText: this.translate.instant("general.no"),
      confirmText: this.translate.instant("general.yes")
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
        if (confirmed) {
            this.resetField("heq_food_intolerance", false);
            this.resetField("heq_food_allergy", false);
            this.resetField("heq_food_allergy_intolerance", "לא");
        }
    });
  }

  sufferCheckboxOnChangeNuq(event, sufferArrayFieldName: string) {
    if (event.checked) {
      if (this.healthquizForm.value[sufferArrayFieldName].length == 0) {
        this.addSufferItemNuq(this, sufferArrayFieldName);
      }
    }
  }

  addSufferItemNuq(context, arrFieldName) {
    context = context == null ? this : context;

    let sufferArr = context.healthquizForm.get(arrFieldName) as FormArray;
    sufferArr.push(context.createSufferItemNuq(context));
  }

  createSufferItemNuq(context) {
    return context.formBuilder.group({
      product_name: '',
      product_name_other_details: '',
      how_diagnosed: '',
      how_diagnosed_other_details: '',
      diagnosed_date: '',
      more_comments: '',
      more_comments_text: '',
    })
  }
  
  clearSufferArrayDetailsFieldAccordingToStatusNuq(arrayFieldName: string) {
    let formArray = this.healthquizForm.get(arrayFieldName) as FormArray;
    let length: number = formArray.length;
    for (let i = 0; i < length; i++) {
      if (formArray.controls[i].value.product_name != 'אחר') {
        formArray.controls[i].get("product_name_other_details").reset('');
      }
      if (formArray.controls[i].value.how_diagnosed != 'אחר') {
        formArray.controls[i].get("how_diagnosed_other_details").reset('');
      }
      if (formArray.controls[i].value.more_comments != 'כן') {
        formArray.controls[i].get("more_comments_text").reset('');
      }
    }
  }
  
  validateSufferArrayFormFieldNuq(formGroup: FormGroup, arrayFieldName: string) {

    let productNameViewChildrenArr = this.heq_suffer_product_name_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);
    let productNameOtherDetailsViewChildrenArr = this.heq_suffer_product_name_other_details_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);
    let howDiagnosedViewChildrenArr = this.heq_suffer_how_diagnosed_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);
    let howDiagnosedOtherDetailsViewChildrenArr = this.heq_suffer_how_diagnosed_other_details_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);
    let diagnosedDateViewChildrenArr = this.heq_suffer_diagnosed_date_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);
    let moreCommentsViewChildrenArr = this.heq_suffer_more_comments_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);
    let moreCommentsTextViewChildrenArr = this.heq_suffer_more_comments_text_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);

    // validate each mini-form
    for (let i = 0; i < (formGroup.get(arrayFieldName) as FormArray).controls.length; i++) {
      let innerFormGroup: FormGroup = ((formGroup.get(arrayFieldName) as FormArray).controls[i] as FormGroup);

      if (innerFormGroup.value.product_name == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_J"),
          scrollIntoNativeElement: productNameViewChildrenArr[i]
        };
      }

      if (innerFormGroup.value.product_name == "אחר" && innerFormGroup.value.product_name_other_details == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_K"),
          scrollIntoNativeElement: productNameOtherDetailsViewChildrenArr[i]
        };
      }

      if (innerFormGroup.value.how_diagnosed == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_L"),
          scrollIntoNativeElement: howDiagnosedViewChildrenArr[i]
        };
      }

      if (innerFormGroup.value.how_diagnosed == "אחר" && innerFormGroup.value.how_diagnosed_other_details == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_M"),
          scrollIntoNativeElement: howDiagnosedOtherDetailsViewChildrenArr[i]
        };
      }

      if (innerFormGroup.value.diagnosed_date == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_N"),
          scrollIntoNativeElement: diagnosedDateViewChildrenArr[i]
        };
      }

      if (innerFormGroup.value.more_comments == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_O"),
          scrollIntoNativeElement: moreCommentsViewChildrenArr[i]
        };
      }

      if (innerFormGroup.value.more_comments == "כן" && innerFormGroup.value.more_comments_text == "") {
        return {
          msg: this.translate.instant("health.ERROR_1_P"),
          scrollIntoNativeElement: moreCommentsTextViewChildrenArr[i]
        };
      }

    }

    return null;
  }

  assetClick(e) {
    this.openImageDialog(e.url, e.url);
  }

  openImageDialog(imgSrc: string, downloadSrc?: string) {
    const options = {
      maxWidth: "90vw",
      panelClass: "p-0-dialog",
      data: {
        imgSrc,
        downloadSrc,
        hideDownload: Boolean(this.appState.user.usr_type !== 'superadmin' && this.appState.user.usr_type !== 'nutri')
      }
    };
    this.dialog.open(ImageDialogComponent, options);
  }  

}

