import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { appState } from '@services/app-state';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { Appcalcs } from '@services/app-calcs';

@Component({
  selector: 'sleeping-section',
  templateUrl: "./sleeping.component.html"
})
export class SleepingComponent {
  @Output() addNoteEvent: EventEmitter<any> = new EventEmitter();
  @Output() editNoteEvent: EventEmitter<any> = new EventEmitter();
  
  @Output() busyState: EventEmitter<boolean> = new EventEmitter();
  @Output() updateFollow: EventEmitter<any> = new EventEmitter();

  @Input() user: any;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onNew: EventEmitter<any> = new EventEmitter();
    
  // @Input() allNotes = [];
  @Output() linkedNotesEvent: EventEmitter<any> = new EventEmitter();
  @Input() notesList = [];

  @Input() sharedNotesFormA: FormGroup;
  // @Input() sharedNotesFormB: FormGroup;
  @Input() allFdqValues: any;

  @Input() oldNotesFlag?: boolean;

  quizPrefix = 'slq';

  notesFormA: FormGroup;
  notesFormB: FormGroup;

  calcs = Appcalcs;

  oldValues = [];
  
  selectedIdA: string;
  selectedIdB: string;

  selectedA: any;
  selectedB: any;

  restService: any;

  appState = appState;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public restApiService: RestApiService<any>
  ) {
    this.restService = this.restApiService.service('sleepingquiz');

    this.notesFormA = this.formBuilder.group({
      _id: [''],
      slq_locked: [false],
      slq_notes: this.formBuilder.group({
        A: this.formBuilder.array([]),
        
        s1: this.formBuilder.array([]),
      }),

      notes_nut_sleeping: [''],
    });
    this.notesFormB = this.formBuilder.group({
      _id: [''],
      slq_locked: [false],
      slq_notes: this.formBuilder.group({
        A: this.formBuilder.array([]),
        
        // s1: this.formBuilder.array([]),
      }),

      notes_nut_sleeping: [''],
    });

  }

  async newQuizGuard() {
    this.onNew.emit(() => { this.newQuiz() });
  }
  async newQuiz() {
    await this.restService.create({
      slq_usr_id: this.user._id,
      slq_form_is_real: true
    });
    this.fetchOldValues(true);
  }

  async deleteQuiz(quizId: string) {
    this.onDelete.emit(() => { this.deleteOk(quizId) });
  }

  async deleteOk(quizId: string) {
    await this.restService.delete(quizId);
    this.fetchOldValues(true);
  }

  async toggleQuizLock(event: MatSlideToggleChange, selected: FormGroup) {
    const lockedField = 'slq_locked';
    if (selected && selected.value && selected.value._id) {
      const res = await this.restService.patch(selected.value._id, {
        [lockedField]: event.checked
      });
      if (res && res[lockedField] === event.checked) {
        event.source.checked = res[lockedField];
        selected.controls[lockedField].reset(res[lockedField]);
        return;
      }
    }
    event.source.checked = !event.checked;
  }

  async fetchOldValues(reset?: boolean, silent?: boolean) {
    try {
      let res = await this.restService.find({ query: {   
        // $limit: 1,
        $paginate: false,
        // $select: [ '_id', 'createdAt', 'slq_usr_id' ],
        slq_usr_id: this.user._id,
        slq_form_is_real: true,
        $sort: {
          createdAt: -1
        }
      } });

      console.log(res);
      this.oldValues = res;

      if(reset){
        await this.resetToLastTwo(silent);
      }

    } catch (e) {
      console.error(e);
    }
  }

  async resetToLastTwo(silent?: boolean) {
    const values = this.oldValues;
    if (values.length === 0) {
      delete this.selectedIdA;
      delete this.selectedA;
      this.notesFormA.reset();
      delete this.selectedIdB;
      delete this.selectedB;
      this.notesFormB.reset();
      return;
    }
    if (values.length === 1) {
      delete this.selectedIdB;
      delete this.selectedB;
      this.notesFormB.reset();
    }
    // delete this.selectedIdA;
    if (values[0] && values[0]._id && values[0]._id !== this.selectedIdA) {
      delete this.selectedA;
      this.notesFormA.reset();
      this.selectedIdA = values[0]._id;
      await this.selectDateA(this.selectedIdA);
    }
    if (silent) {
      return;
    }
    // delete this.selectedIdB;
    if (values[1] && values[1]._id && values[1]._id !== this.selectedIdB) {
      delete this.selectedB;
      this.notesFormB.reset();
      this.selectedIdB = values[1]._id;
      await this.selectDateB(this.selectedIdB);
    }
  }

  async ngOnInit() {
    await this.fetchOldValues(true);
  }

  resetNotes(notesGroup: FormGroup, dataNotes) {
    for (const noteKey in notesGroup.value) {
      let notes: FormArray = notesGroup.get(noteKey) as FormArray;
      notes.clear();
      if (dataNotes && dataNotes[noteKey]) {
        for (const n of dataNotes[noteKey]) {
          notes.push(new FormControl());
        }
      }
    }
  }

  refreshFormA(value) {
    const notesGroup = this.notesFormA.get('slq_notes') as FormGroup;
    this.resetNotes(notesGroup, value['slq_notes']);
    this.notesFormA.reset(value);
    this.selectedA = value;
    this.calcs.updateSleepingCalcs(this.selectedA, this.user, this.selectedA.savedAt);
  }

  refreshFormB(value) {
    const notesGroup = this.notesFormB.get('slq_notes') as FormGroup;
    this.resetNotes(notesGroup, value['slq_notes']);
    this.notesFormB.reset(value);
    this.selectedB = value;
    this.calcs.updateSleepingCalcs(this.selectedB, this.user, this.selectedB.savedAt);
  }

  async selectDateA(newVal){
    // const data = await this.restService.get(newVal).catch(e => { console.error(e) });
    // const res = await this.restService.find({
    //   query: {
    //     $nutri: true,
    //     $limit: 1,
    //     _id: newVal,
    //   },
    // }).catch(e => { console.error(e) });
    // const data = res?.data[0];
    const data = this.oldValues.find(val => val._id === newVal);
    if (data) {
      this.notesFormA.reset();
      this.refreshFormA(data); 
    }
  }
  async selectDateB(newVal){
    // const data = await this.restService.get(newVal).catch(e => { console.error(e) });
    // const res = await this.restService.find({
    //   query: {
    //     $nutri: true,
    //     $limit: 1,
    //     _id: newVal,
    //   },
    // }).catch(e => { console.error(e) });
    // const data = res?.data[0];
    const data = this.oldValues.find(val => val._id === newVal);
    if (data) {
      this.notesFormB.reset();
      this.refreshFormB(data); 
    }
  }

  // async submitForm(){
  //   this.busyState.emit(true);
  //   const form = this.notesFormA;
  //   let res: any;

  //   if (form && form.value._id && form.value._id !== '') { 
  //     res = await this.restService.patch(form.value._id, form.value);
  //     if (res && res._id) {
  //       form.reset(res);
  //       this.showStatus("נשמר בהצלחה", null);
  //       if (res.follow_flag) {
  //         this.updateFollow.emit(res);
  //         setTimeout(() => {
  //           this._snackBar.open('המשתמש נכנס למעקב', 'סגור', {
  //             duration: 0,
  //           });
  //         }, 2000);
  //       }
  //     }
  //   }
  //   this.busyState.emit(false);
  //   return res;
  // }

  // async submitForms(silent?, lock?){

  //   this.busyState.emit(true);

  //   const formA = this.notesFormA;
  //   const formB = this.notesFormB;
  //   const lockedField = 'slq_locked';
  //   let resA, resB;

  //   if (formA && formA.value._id && formA.value._id !== '') { 
  //     if (lock) {
  //       formA.controls[lockedField].reset(true);
  //     }
  //     resA = await this.restService.patch(formA.value._id, formA.value);
  //     // formA.patchValue(resA);
  //     formA.reset(resA);
  //   }  

  //   // if (formB && formB.value._id && formB.value._id !== '') { 
  //   //   if (lock) {
  //   //     formB.controls[lockedField].reset(true);
  //   //   }
  //   //   resB = await this.restService.patch(formB.value._id, formB.value);
  //   //   // formB.patchValue(resB);
  //   //   formB.reset(resB);
  //   // }  

  //   const sent = (resA && resA._id) || (resB && resB._id);

  //   if (sent) {
  //     if (!silent) {
  //       this.busyState.emit(false);
  //       this.showStatus("נשמר בהצלחה", null);
  //     }
  //     return true;
  //   }
  //   this.busyState.emit(false);
  //   return false;
  // }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];
  }

  getDirty() {
    const formA = this.notesFormA;
    const formB = this.notesFormB;
    // return (formA && formA.dirty) || (formB && formB.dirty);
    return (formA && formA.dirty);
  }

  getLocked() {
    const lockedField = 'slq_locked';
    const formA = this.notesFormA;
    // const formB = this.notesFormB;
    // return (formA && formA.dirty) || (formB && formB.dirty);
    return (formA && formA.value[lockedField]);
  }

  getLastId() {
    const formA = this.notesFormA;
    return formA && formA.value._id || '';
  }

  fillNotes(notes: any[]) {
    const notesField = 'slq_notes';
    const notesGroup = this.notesFormA.get(notesField) as FormGroup;
    for (const note of notes) {
      this.addFillNote(note, notesGroup);
    }
  }

  addFillNote(note: any, notesGroup: FormGroup) {
    if (!note || !note._id || !note.quiz_note_type) {
      return;
    }
    let field: FormArray = notesGroup.get(note.quiz_note_type) as FormArray;

    if (field.value.findIndex(val => val && val.note_id === note._id) > -1) {
      return;
    }
    const now = new Date().getTime();
    const newNote = new FormControl({
      note_id: note._id,
      is_active: true,
      is_done: false,
      note_obj: note,
      added_at: now,
    });
    field.push(newNote);
    field.markAsDirty();

  }

  linkedNote(note: any) {
    this.linkedNotesEvent.emit(note);
  }

  addNote(contentAndField: any = {}, quiz_prefix: string, quiz_note_type: string) {
    const { content, content_F, acFormField } = contentAndField;
    this.addNoteEvent.emit({ content, content_F, quiz_prefix, quiz_note_type, acFormField });
  }

  editNote(idAndField: any = {}, quiz_prefix: string, quiz_note_type: string) {
    const { id, field } = idAndField;
    this.editNoteEvent.emit({ id, field, quiz_prefix, quiz_note_type });
  }

  addNoteToNotesObj(quiz_note_type: string, newNote: FormControl) {
    const notesGroup = this.notesFormA.get('slq_notes') as FormGroup;
    const fieldArray = notesGroup.get(quiz_note_type) as FormArray;
    if (!newNote.value?.note_id || !fieldArray.value || fieldArray.value.findIndex(val => val && val.note_id === newNote.value.note_id) > -1) {
      return;
    }
    fieldArray.push(newNote);
    fieldArray.markAsDirty();
  }

  removeNote(quiz_note_type: string, noteId: string) {
    const notesGroup = this.notesFormA.get('slq_notes') as FormGroup;
    const fieldArray = notesGroup.get(quiz_note_type) as FormArray;
    if (fieldArray) {
      const idx = fieldArray.value.findIndex(val => val && val.note_id === noteId); // || (val.note_custom && val.note_custom === noteObj.note_custom)
      if (idx > -1) {
        fieldArray.removeAt(idx);
        fieldArray.markAsDirty();
      }      
    }
  }

}

