<div class="antro-form" *ngIf="userData">

    <mat-expansion-panel #exp disabled expanded>
        <mat-expansion-panel-header>
            <!-- <mat-panel-title class="font-18 font-bold">
                פרטים אישיים
            </mat-panel-title>
            <mat-panel-description>
                <span *ngIf="userData.usr_fname || userData.usr_lname" class="font-bold m-l-5">{{(userData.usr_fname || '') + " " + (userData.usr_lname || '')}},</span>
                <span *ngIf="userData.usr_gender" class="m-l-5">{{userData.usr_gender}},</span>
                <span *ngIf="userData.usr_age_formatted" class="m-l-5">{{userData.usr_age_formatted}}</span>
                <span *ngIf="!userData.usr_age_formatted" class="text-danger font-bold m-l-5">ללא תאריך לידה!</span>
                <span *ngIf="userData.usr_team_name" class="m-l-5">({{userData.usr_team_name}})</span>
            </mat-panel-description> -->
            <mat-panel-title class="font-16 font-bold">
                <h4 class="m-auto">פרטים אישיים</h4>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="user-details">
            <div class="row">
                <h3>{{(userData.usr_fname || '') + " " + (userData.usr_lname || '')}}</h3>
                <!-- <button type="button" [routerLink]="['/Profile/', userData._id]" class="plus-button" mat-stroked-button>עריכת פרטים אישיים</button> -->
                <a *ngIf="appState.user.usr_type === 'superadmin'" target="quizwindow"
                    [routerLink]="['/Profile/', userData._id]" class="plus-button" mat-stroked-button>עריכת פרטים
                    אישיים</a>
            </div>
            <!-- <h3>{{userData.usr_fname + " " + userData.usr_lname}}</h3>
            <div>
                <button type="button" [routerLink]="['/Profile/', userData._id]" class="plus-button" mat-stroked-button>עריכת פרטים אישיים</button>
            </div> -->
            <div class="row">
                <div class="w30">
                    <div *ngFor="let asset of userData.usr_profile_pic_asset_arr; let i = index;">
                        <img src="{{asset.url}}" class="profile-img pointer" (click)="imageClick(asset.url)">
                    </div>
                </div>
                <div class="w30">
                    <ul>
                        <li>
                            מין:
                            {{userData.usr_gender}}
                        </li>
                        <li>
                            גיל:
                            {{userData.usr_age_formatted}}
                        </li>
                        <li>
                            תאריך לידה:
                            {{userData.usr_birth_date | date:'dd/MM/yyyy'}}
                        </li>
                        <li>
                            ת.ז.
                            {{userData.usr_identification_number}}
                        </li>
                        <li>
                            מועדון:
                            {{userData.usr_club_name}}
                        </li>
                        <li>
                            קבוצה:
                            {{userData.usr_team_name}}
                        </li>
                        <li>
                            טלפון:
                            {{userData.usr_phone}}
                        </li>
                        <li>
                            מייל:
                            {{userData.usr_email}}
                        </li>
                        <li>
                            רמת ספורטאי:
                            <mat-form-field
                                *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'player'"
                                class="w50 in-row-field wide">
                                <mat-select [formControl]="notesForm.get('athlete_lvl')">
                                    <mat-option value="competitive">ספורטאי תחרותי</mat-option>
                                    <mat-option value="social">ספורטאי חברתי</mat-option>
                                    <mat-option value="non-athlete">לא ספורטאי</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <span
                                *ngIf="appState.user.usr_type !== 'superadmin' && appState.user.usr_type !== 'player'">
                                {{ notesForm.value.athlete_lvl }}
                            </span>
                        </li>
                        <!-- <li>
                            מספר דוחות בחבילה: 
                            <span class="dl dir-ltr">
                                {{ userData.total_reports || 0 }} / {{ userData.usr_report_count || 0 }}
                            </span>
                        </li> -->
                        <li>
                            מספר דוחות שהופקו:
                            <span class="dl dir-ltr">
                                {{ userData.total_reports || 0 }}
                            </span>
                        </li>
                        <li>
                            מספר דוחות בחבילה:
                            <mat-form-field *ngIf="appState.user.usr_type === 'superadmin'"
                                class="w15 in-row-field wide">
                                <input type="text" digitOnly maxlength="2" inputmode="numeric" pattern="[0-9]*"
                                    [formControl]="notesForm.get('usr_report_count')" matInput>
                            </mat-form-field>
                            <span *ngIf="appState.user.usr_type !== 'superadmin'">
                                {{ notesForm.value.usr_report_count || 0 }}
                            </span>
                        </li>
                        <!-- <li>
                            מדידה פיזית: 
                            <mat-radio-group [formControl]="notesForm.get('usr_is_measurement')">
                                <mat-radio-button [value]="true">{{'general.yes' | translate}}</mat-radio-button>
                                <mat-radio-button [value]="false">{{'general.no' | translate}}</mat-radio-button>
                            </mat-radio-group>
                        </li> -->
                        <li>
                            תאריך מדידה:
                            <mat-form-field style="display: inline-flex;"
                                *ngIf="appState.user.usr_type === 'superadmin'" class="w30 in-row-field wide">
                                <input readonly #timingDate [formControl]="notesForm.get('usr_measurement_date')"
                                    matInput [matDatepicker]="dpInput">
                                <mat-datepicker-toggle matSuffix [for]="dpInput"></mat-datepicker-toggle>
                                <mat-datepicker #dpInput disabled="false"></mat-datepicker>
                            </mat-form-field>
                            <span *ngIf="appState.user.usr_type !== 'superadmin'">
                                {{ notesForm.value.usr_measurement_date | dateFormat : 'DD/MM/yyyy' }}
                            </span>
                        </li>
                        <li>
                            שעת מדידה:
                            <mat-form-field *ngIf="appState.user.usr_type === 'superadmin'"
                                class="w30 in-row-field wide">

                                <input matInput type="time" ampm="false" [value]="notesForm.value.usr_measurement_hour"
                                    [formControl]="notesForm.get('usr_measurement_hour')" class="new-time-input" />
                            </mat-form-field>
                            <!-- <app-custom-time-input>
                                <input type="time" ampm="false" [value]="notesForm.value.usr_measurement_hour"
                                    [formControl]="notesForm.get('usr_measurement_hour')" class="new-time-input" />
                            </app-custom-time-input> -->
                            <span *ngIf="appState.user.usr_type !== 'superadmin'">
                                {{ notesForm.value.usr_measurement_hour }}
                            </span>
                        </li>
                        <!-- <li>
                            פגישת וידאו/פרונטלית שבוצעו: 
                            <mat-form-field *ngIf="appState.user.usr_type === 'superadmin'" class="w15">
                                <input type="text" digitOnly maxlength="2" inputmode="numeric" pattern="[0-9]*" [formControl]="notesForm.get('usr_sessions_done')" matInput>
                            </mat-form-field>
                            <span *ngIf="appState.user.usr_type !== 'superadmin'">
                                {{ notesForm.value.usr_sessions_done || 0 }}
                            </span>
                        </li> -->
                        <li class="m-t-10">
                            מספר ייעוצים שבוצעו:
                            <span class="dl dir-ltr">
                                {{ notesForm?.value?.usr_previous_sessions?.length || 0 }}
                            </span>
                        </li>
                        <li>
                            מספר ייעוצים בחבילה:
                            <mat-form-field *ngIf="appState.user.usr_type === 'superadmin'"
                                class="w15 in-row-field wide">
                                <input type="text" digitOnly maxlength="2" inputmode="numeric" pattern="[0-9]*"
                                    [formControl]="notesForm.get('usr_sessions_total')" matInput>
                            </mat-form-field>
                            <span *ngIf="appState.user.usr_type !== 'superadmin'">
                                {{ notesForm.value.usr_sessions_total || 0 }}
                            </span>
                        </li>

                        <li>
                            תאריך הייעוץ הקרוב:
                            <mat-form-field *ngIf="appState.user.usr_type === 'superadmin'"
                                class="w30 in-row-field wide">
                                <input readonly #timingDate [formControl]="notesForm.get('usr_session_date')" matInput
                                    [matDatepicker]="dpInput">
                                <mat-datepicker-toggle matSuffix [for]="dpInput"></mat-datepicker-toggle>
                                <mat-datepicker #dpInput disabled="false"></mat-datepicker>
                            </mat-form-field>
                            <span *ngIf="appState.user.usr_type !== 'superadmin'">
                                {{ notesForm.value.usr_session_date | dateFormat : 'DD/MM/yyyy' }}
                            </span>
                        </li>
                        <li>
                            שעת הייעוץ הקרוב:
                            <mat-form-field *ngIf="appState.user.usr_type === 'superadmin'"
                                class="w30 in-row-field wide close-to-line">
                                <input matInput type="time" ampm="false" [format]=24
                                    [value]="notesForm.value.usr_session_hour"
                                    [formControl]="notesForm.get('usr_session_hour')" class="new-time-input" />
                            </mat-form-field>
                            <!-- <mat-form-field *ngIf="appState.user.usr_type === 'superadmin'" class="w30 in-row-field wide">
                                <mat-select [formControl]="notesForm.get('usr_session_hour')">
                                    <mat-option value="">---</mat-option>
                                    <mat-option *ngFor="let time of allHours" [value]="time">{{time}}</mat-option>
                                </mat-select>
                            </mat-form-field> -->
                            <span *ngIf="appState.user.usr_type !== 'superadmin'">
                                {{ notesForm.value.usr_session_hour }}
                            </span>
                        </li>
                        <li style="display: flex;">
                            <mat-checkbox [formControl]="notesForm.get('usr_is_open_Q')" class="m-l-10"></mat-checkbox>
                            <span>פתח שאלונים לקראת הייעוץ הקרוב</span>
                        </li>
                        <!-- <li class="m-t-30">
                            <div class="font-16 font-bold mb125">ייעוצים קודמים</div>
                        </li> -->
                        <li>
                            תאריך לתיאום עתידי:
                            <mat-form-field *ngIf="appState.user.usr_type === 'superadmin'"
                                class="w30 in-row-field wide">
                                <input matInput [matDatepicker]="futureDatepicker"
                                    [formControl]="notesForm.get('future_schedule')">
                                <mat-datepicker-toggle matSuffix [for]="futureDatepicker"></mat-datepicker-toggle>
                                <mat-datepicker #futureDatepicker></mat-datepicker>
                            </mat-form-field>
                            <span *ngIf="appState.user.usr_type !== 'superadmin'">
                                {{ notesForm.value.future_schedule | dateFormat : 'DD/MM/yyyy' }}
                            </span>
                        </li>
                        <li class="m-t-10">
                            <mat-radio-group *ngIf="appState.user.usr_type === 'superadmin'"
                                [formControl]="notesForm.get('usr_session_type')">
                                <mat-radio-button value="וידאו">וידאו</mat-radio-button>
                                <mat-radio-button value="פרונטלי">פרונטלי</mat-radio-button>
                            </mat-radio-group>
                            <span *ngIf="appState.user.usr_type !== 'superadmin'">
                                {{ notesForm.value.usr_session_type }}
                            </span>
                        </li>
                        <li class="m-t-20">
                            <button mat-stroked-button color="primary" class="w30"
                                (click)="sessionIsDoneDialog()">הייעוץ בוצע</button>
                        </li>

                        <li class="m-t-30">
                            <div class="font-16 font-bold mb125">ייעוצים קודמים</div>
                            <div class="mb75"
                                *ngFor="let session of ((notesForm?.value?.usr_previous_sessions || []) | reverse);">
                                <span *ngIf="session.usr_session_date">
                                    {{ session.usr_session_date | dateFormat : 'DD/MM/yyyy' }}
                                </span>

                                <span> | </span>

                                <span *ngIf="session.usr_session_hour">
                                    {{ session.usr_session_hour }}
                                </span>

                                <span> | </span>

                                <span *ngIf="session.usr_session_type">
                                    {{ session.usr_session_type }}
                                </span>
                            </div>
                        </li>

                    </ul>
                </div>
                <div class="w30">
                    <ng-container *ngIf="userData.usr_parent_1_phone">
                        <ul>
                            <li class="usr-parent">
                                {{userData.usr_parent_1_type}}:
                                {{userData.usr_parent_1_fname}} {{userData.usr_parent_1_lname}}
                            </li>
                            <li>
                                טלפון:
                                {{userData.usr_parent_1_phone}}
                            </li>
                            <!-- <li>
                                מייל: 
                                {{userData.usr_parent_1_email}}
                            </li> -->
                        </ul>
                    </ng-container>
                    <ng-container *ngIf="userData.usr_parent_2_phone">
                        <ul class="mt125">
                            <li class="usr-parent">
                                {{userData.usr_parent_2_type}}:
                                {{userData.usr_parent_2_fname}} {{userData.usr_parent_2_lname}}
                            </li>
                            <li>
                                טלפון:
                                {{userData.usr_parent_2_phone}}
                            </li>
                            <!-- <li>
                                מייל: 
                                {{userData.usr_parent_2_email}}
                            </li> -->
                        </ul>
                    </ng-container>
                </div>
            </div>

            <div class="w70 m-auto">
                <h4 class="ul-text">הערות תזונאי</h4>
                <div class="mb125">
                    <mat-form-field class="mb50">
                        <textarea [formControl]="notesForm.get('notes_usr_decription')" cdkTextareaAutosize
                            cdkAutosizeMinRows="3" placeholder="תיאור של הלקוח" matInput></textarea>
                    </mat-form-field>
                </div>

                <span #follow_el></span>
                <h4 class="ul-text d-flex">
                    <mat-checkbox [formControl]="notesForm.get('usr_is_follow')" class="m-l-10"></mat-checkbox>
                    <span>מעקב</span>
                </h4>
                <div class="mb125">
                    <notes *ngIf="notesForm.value._id" [isFemale]="(userData.usr_gender === 'נקבה')"
                        [isFollowMode]="true" [allNotes]="notesList" [selectedGroup]="notesForm.get('usr_notes')"
                        fieldKey="A"></notes>
                </div>
            </div>

            <div *ngIf="appState.user.usr_type === 'superadmin'" class="mt125 text-center">
                <button mat-raised-button color="primary" class="w30" (click)="submitFormAdminDialog()">שמור</button>
            </div>
        </div>
    </mat-expansion-panel>
</div>

<ng-template #followTemplate let-form="form">
    <!-- <div>
        <notes *ngIf="form.value._id" [isFollowMode]="true" [isReadonly]="false" [allNotes]="notesList" [selectedGroup]="form.get('usr_notes')" fieldKey="A"></notes>
    </div> -->
    <div class="basic-form mat-body" [formGroup]="form">
        <mat-form-field>
            <textarea formControlName="note_custom" cdkTextareaAutosize cdkAutosizeMinRows="3"
                placeholder="אחרי מה תרצה לעקוב?" matInput cdkFocusInitial></textarea>
            <!-- <input type="text" cdkFocusInitial formControlName="note_custom" matInput placeholder="אחרי מה תרצה לעקוב?"> -->
        </mat-form-field>
    </div>
</ng-template>