<div
    class="fixed-user-header"
    *ngIf="(appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'nutri') && appState.quizUser?._id"
>
    <div class="text-white">
        <span *ngIf="appState.quizUser.usr_fname || appState.quizUser.usr_lname" class="font-bold m-l-5"
            >{{ (appState.quizUser.usr_fname || '') + ' ' + (appState.quizUser.usr_lname || '') }},</span
        >
        <span *ngIf="appState.quizUser.usr_gender" class="m-l-5">{{ appState.quizUser.usr_gender }},</span>
        <span *ngIf="appState.quizUser.usr_age_formatted" class="m-l-5">{{ appState.quizUser.usr_age_formatted }}</span>
        <!-- <span *ngIf="!appState.quizUser.usr_age_formatted" class="text-danger font-bold m-l-5">ללא תאריך לידה!</span> -->
        <span *ngIf="appState.quizUser.usr_team_name" class="m-l-5">({{ appState.quizUser.usr_team_name }})</span>
    </div>

    <div *ngIf="appState.isLoadingNotes">
        <img src="/assets/images/loading.gif" width="50px" height="50px" />
    </div>
</div>

<div
    #mainContainer
    class="main-container"
    [dir]="translate.currentLang === 'he' || translate.currentLang === 'he_F' ? 'rtl' : 'ltr'"
>
    <mat-toolbar color="primary" class="topbar telative">
        <button mat-icon-button value="sidebarclosed" (click)="snav.toggle()">
            <mat-icon>menu</mat-icon>
        </button>
        <button mat-icon-button (click)="homeButton()">
            <mat-icon>home</mat-icon>
        </button>

        <div class="navbar-header">
            <div class="navbar-brand">
                <span
                    *ngIf="
                        !(appState.user.usr_type === 'player' && !appState.user['has_plan']) &&
                        appState.user.usr_fname
                    "
                    fxShow="false"
                    fxShow.gt-xs
                >
                    {{ 'sidenav.welcome' | translate }} {{ appState.user.usr_fname }}
                </span>
            </div>
        </div>

        <span fxFlex></span>

        <div class="navbar-lang" style="display: flex; align-items: center">
            <span *ngIf="appState.user.usr_type === 'player' && !appState.user['has_plan']" style="padding: 2px 9px 0">
                <button [routerLink]="['/Plans']" mat-stroked-button class="font-18">Go All Star</button>
            </span>
            <div class="logo-navbar">
                <img src="/assets/images/AAP_w.png" />
            </div>
            <button [matMenuTriggerFor]="langSwitcher" mat-icon-button>
                <mat-icon class="m-0">language</mat-icon>
            </button>
            <mat-menu #langSwitcher="matMenu" class="lang-switcher">
                <button
                    mat-menu-item
                    (click)="useLanguage('he')"
                    [disabled]="translate.currentLang === 'he' || translate.currentLang === 'he_F'"
                >
                    עברית
                </button>
                <button mat-menu-item (click)="useLanguage('en')" [disabled]="translate.currentLang === 'en'">
                    English
                </button>
            </mat-menu>
        </div>
    </mat-toolbar>

    <mat-sidenav-container class="example-sidenav-container">
        <mat-sidenav
            #snav
            id="snav"
            class="dark-sidebar pl-xs"
            [mode]="mobileQuery.matches ? 'side' : 'over'"
            fixedTopGap="0"
            [disableClose]="mobileQuery.matches"
            (openedChange)="snavOpenedChange($event)"
        >
            <mat-nav-list style="padding-bottom: 55px;">
                <mat-list-item
                    *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'nutri'"
                    routerLinkActive="selected"
                >
                    <a (click)="closeSnav()" class="" fxLayoutGap="10px" [routerLink]="['/Userstable/']">
                        <mat-icon>view_comfy</mat-icon>
                        <span>{{ 'sidenav.userstable' | translate }}</span>
                        <span fxFlex></span>
                    </a>
                </mat-list-item>

                <mat-list-item
                    *ngIf="appState.user.usr_type === 'superadmin'"
                    routerLinkActive="selected"
                >
                    <a (click)="closeSnav()" class="" fxLayoutGap="10px" [routerLink]="['/Logtable/']">
                        <mat-icon>view_comfy</mat-icon>
                        <span>{{ 'sidenav.logtable' | translate }}</span>
                        <span fxFlex></span>
                    </a>
                </mat-list-item>

                <mat-list-item
                    *ngIf="
                        (appState.user.usr_type === 'coach' && appState.user.usr_agreement) ||
                        appState.user.usr_type === 'superadmin' ||
                        appState.user.usr_type === 'admin'
                    "
                    routerLinkActive="selected"
                >
                    <a (click)="closeSnav()" class="" fxLayoutGap="10px" [routerLink]="['/Coach/']">
                        <mat-icon>view_comfy</mat-icon>
                        <span *ngIf="appState.user.usr_type === 'coach'">{{ 'sidenav.main' | translate }}</span>
                        <span *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin'">{{
                            'sidenav.coachview' | translate
                        }}</span>
                        <span fxFlex></span>
                    </a>
                </mat-list-item>

                <mat-list-item
                    *ngIf="
                        (appState.user.usr_type === 'coach' && appState.user.usr_agreement) ||
                        appState.user.usr_type === 'superadmin' ||
                        appState.user.usr_type === 'admin'
                    "
                    routerLinkActive="selected"
                >
                    <a (click)="closeSnav()" class="" fxLayoutGap="10px" [routerLink]="['/Measurereport/']">
                        <mat-icon>view_comfy</mat-icon>
                        <span>{{ 'sidenav.measurereport' | translate }}</span>
                        <span fxFlex></span>
                    </a>
                </mat-list-item>

                <mat-list-item
                    *ngIf="
                        appState.user.usr_type === 'superadmin' ||
                        appState.user.usr_type === 'admin' ||
                        appState.user.usr_type === 'nutri' ||
                        (appState.user.usr_type === 'coach' && appState.user.usr_agreement)
                    "
                    routerLinkActive="selected"
                >
                    <a (click)="closeSnav()" class="" fxLayoutGap="10px" [routerLink]="['/Club']">
                        <mat-icon>view_comfy</mat-icon>
                        <span *ngIf="appState.user.usr_type === 'superadmin'">{{
                            'sidenav.manageclubs' | translate
                        }}</span>
                        <span *ngIf="appState.user.usr_type === 'admin'">{{ 'sidenav.manageclub' | translate }}</span>
                        <span *ngIf="appState.user.usr_type === 'nutri' || appState.user.usr_type === 'coach'">{{
                            'sidenav.manageteam' | translate
                        }}</span>
                        <span fxFlex></span>
                    </a>
                </mat-list-item>

                <mat-list-item
                    *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'nutri'"
                    routerLinkActive="selected"
                >
                    <a (click)="closeSnav()" class="" fxLayoutGap="10px" [routerLink]="['/Notestable/']">
                        <mat-icon>view_comfy</mat-icon>
                        <span>{{ 'sidenav.notes' | translate }}</span>
                        <span fxFlex></span>
                    </a>
                </mat-list-item>

                <mat-list-item
                    *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin'"
                    routerLinkActive="selected"
                >
                    <a (click)="closeSnav()" class="" fxLayoutGap="10px" [routerLink]="['/Profile']">
                        <mat-icon>view_comfy</mat-icon>
                        <span>{{ 'sidenav.newuser' | translate }}</span>
                        <span fxFlex></span>
                    </a>
                </mat-list-item>

                <mat-list-item
                    *ngIf="appState.user.usr_type === 'parent' && appState.user['usr_agreement']"
                    routerLinkActive="selected"
                >
                    <a
                        (click)="closeSnav()"
                        class=""
                        fxLayoutGap="10px"
                        [routerLink]="['/Userparent']"
                    >
                        <mat-icon>view_comfy</mat-icon>
                        <span>{{ 'sidenav.main' | translate }}</span>
                        <span fxFlex></span>
                    </a>
                </mat-list-item>

                <mat-list-item
                    *ngIf="
                        appState.user.usr_type === 'player' &&
                        appState.user['usr_agreement'] &&
                        appState.user['usr_confirmed']
                    "
                    routerLinkActive="selected"
                >
                    <a
                        (click)="closeSnav()"
                        class=""
                        fxLayoutGap="10px"
                        [routerLink]="['/Userprogress']"
                    >
                        <mat-icon>view_comfy</mat-icon>
                        <span>{{ 'sidenav.userprogress' | translate }}</span>
                        <span fxFlex></span>
                    </a>
                </mat-list-item>

                <ng-container
                    *ngIf="
                        appState.user.usr_type === 'player' &&
                        appState.user['usr_agreement'] &&
                        appState.user['usr_confirmed']
                    "
                >
                    <mat-list-item *ngFor="let menuitem of menuItems.getMenuitem()" routerLinkActive="selected">
                        <a
                            (click)="closeSnav()"
                            class=""
                            fxLayoutGap="10px"
                            [routerLink]="['/', menuitem.state]"
                            *ngIf="userState[menuitem.prefix]?.exist && !userState[menuitem.prefix]?.hidden && menuitem.type === 'link'"
                        >
                            <mat-icon>{{ menuitem.icon }}</mat-icon>
                            <span>{{ menuitem.name | translate }}</span>
                            <span fxFlex></span>
                            <!-- <span class="label label-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span> -->
                        </a>
                    </mat-list-item>
                </ng-container>

                <mat-list-item
                    *ngIf="
                        appState.user.usr_type === 'player' &&
                        appState.user['usr_agreement'] &&
                        appState.user['usr_confirmed'] &&
                        appState.user['measure_enabled'] && 
                        userState['ntq']?.exist
                    "
                    routerLinkActive="selected"
                >
                    <a (click)="closeSnav()" class="" fxLayoutGap="10px" [routerLink]="['/Measurement/']">
                        <mat-icon>view_comfy</mat-icon>
                        <span>{{ 'sidenav.measurement' | translate }}</span>
                        <span fxFlex></span>
                    </a>
                </mat-list-item>

                <mat-list-item
                    *ngIf="
                        appState.user.usr_type === 'player' &&
                        appState.user['usr_agreement'] &&
                        appState.user['usr_confirmed']
                    "
                    routerLinkActive="selected"
                >
                    <a
                        (click)="closeSnav()"
                        class=""
                        fxLayoutGap="10px"
                        [routerLink]="['/Userpractice/' + appState.user._id]"
                    >
                        <mat-icon>view_comfy</mat-icon>
                        <span>{{ 'sidenav.mypractices' | translate }}</span>
                        <span fxFlex></span>
                    </a>
                </mat-list-item>

                <mat-list-item
                    *ngIf="!(appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'admin')"
                    routerLinkActive="selected"
                >
                    <a
                        (click)="closeSnav()"
                        class=""
                        fxLayoutGap="10px"
                        [routerLink]="[appState.user.usr_type ? '/Profile/' + appState.user._id : '/Profile']"
                    >
                        <mat-icon>view_comfy</mat-icon>
                        <span>{{ 'sidenav.profile' | translate }}</span>
                        <span fxFlex></span>
                    </a>
                </mat-list-item>

                <mat-list-item
                    *ngIf="
                        appState.user.usr_type === 'player' &&
                        appState.user['usr_agreement'] &&
                        appState.user['usr_confirmed']
                    "
                    routerLinkActive="selected"
                >
                    <a (click)="closeSnav()" class="" fxLayoutGap="10px" [routerLink]="['/Usernotes/']">
                        <mat-icon>view_comfy</mat-icon>
                        <span>{{ 'sidenav.userpush' | translate }}</span>
                        <span fxFlex></span>
                    </a>
                </mat-list-item>

                <mat-list-item>
                    <a class="" fxLayoutGap="10px" (click)="logout()">
                        <mat-icon>view_comfy</mat-icon>
                        <span>{{ 'sidenav.logout' | translate }}</span>
                        <span fxFlex></span>
                    </a>
                </mat-list-item>
            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content class="page-wrapper">
            <div class="page-content">
                <router-outlet>
                    <app-spinner></app-spinner>
                </router-outlet>
                <div [ngClass]="mobileQuery.matches ? '' : 'mobile-bottom-fix'">
                    
                </div>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>

    <a *ngIf="waLink && appState.user.usr_type !== 'superadmin' && router.url !== '/Userstable'" [href]="waLink" class="wa-float" target="_blank">
        <i class="fa fa-whatsapp"></i>
    </a>
</div>
