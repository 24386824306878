import { Injectable } from '@angular/core';
import { Router, CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';

import { AuthService } from '@noammazuz/vzn-feathers';
import { appState } from '@services/app-state';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanLoad {

  appState = appState;

  constructor(
    private auth: AuthService<any>,
    private router: Router
  ){}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {
      const user = await this.auth.isAuth().catch(err => { console.error(err); });
      if (user && !user.usr_is_deleted) {
        this.appState.user = user;
        if (!['superadmin', 'admin', 'nutri'].includes(user.usr_type) && ['Userstable', 'Notestable', 'Logtable'].includes(next.firstChild?.url?.[0]?.path)) {
          console.log('not allowed route to /');
          return this.router.navigate(['/']);
        }
        console.log('canActivate');
        return true;
      } else {
        await this.auth.logout().catch(err => { console.error(err); });
        this.appState.user = {
          _id: '',
          usr_type: '',
          usr_fname: '',
          usr_lname: '',
          usr_gender: '',
          usr_phone: '',
          usr_email: '',
          usr_club_id: '',
          usr_agreement: false,
          usr_state: null,
        };
        this.appState.accessToken = '';
        this.appState.quizUser = {};
        // return this.router.parseUrl('/login');
        console.log('state.url', state.url)
        return this.router.navigate(['/login'], { queryParams: { redirectUri: state.url } });
      }
  }
  async canLoad(
    route: Route,
    segments: UrlSegment[]): Promise<boolean> {
      console.log('canLoad');
      try {
        return await this.auth.isAuth();
      } catch (err) {
        console.error(err);
        console.log('err catched');
      }
  }
}
