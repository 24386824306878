import { Component, ViewChildren, QueryList, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators, ValidatorFn } from '@angular/forms';

import { MatSelect } from '@angular/material/select';

import { appState } from '@services/app-state';

import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: "./nutritionquiz.component.html"
})
export class NutritionquizComponent {

  @ViewChild("nuq_eat_no_limits_el") nuq_eat_no_limits_el: ElementRef;
  @ViewChild("nuq_eat_breakfast_el") nuq_eat_breakfast_el: ElementRef;
  @ViewChild("nuq_eat_breakfast_reason_el") nuq_eat_breakfast_reason_el: ElementRef;
  @ViewChild("nuq_school_sandwich_el") nuq_school_sandwich_el: ElementRef;
  @ViewChild("nuq_eat_near_screen_el") nuq_eat_near_screen_el: ElementRef;
  @ViewChild("nuq_eat_outside_el") nuq_eat_outside_el: ElementRef;
  @ViewChild("nuq_more_food_comments_el") nuq_more_food_comments_el: ElementRef;

  @ViewChildren("nuq_food_description_el") nuq_food_description_el: QueryList<any>

  @ViewChild("nuq_eat_vegetables_el") nuq_eat_vegetables_el: ElementRef;
  @ViewChild("nuq_eat_fruits_el") nuq_eat_fruits_el: ElementRef;
  @ViewChild("nuq_drink_sugar_el") nuq_drink_sugar_el: ElementRef;

  @ViewChild("nuq_new_food_el") nuq_new_food_el: ElementRef;
  @ViewChildren("nuq_new_food_description_el") nuq_new_food_description_el: QueryList<any>

  @ViewChild("nuq_eat_snacks_el") nuq_eat_snacks_el: ElementRef;
  
  @ViewChild("nuq_drink_estimate_el") nuq_drink_estimate_el: ElementRef;
  @ViewChild("nuq_personal_bottle_el") nuq_personal_bottle_el: ElementRef;
  @ViewChild("nuq_drink_caffeine_el") nuq_drink_caffeine_el: ElementRef;

  @ViewChildren("nuq_food_description_control") nuq_food_description_control: QueryList<any>;
  @ViewChildren("nuq_new_food_description_control") nuq_new_food_description_control: QueryList<any>;

  @ViewChild("nuq_food_does_not_like_arr_fixed") nuq_food_does_not_like_arr_fixed: MatSelect;
  @ViewChild("nuq_like_but_try_avoid_eating_arr_fixed") nuq_like_but_try_avoid_eating_arr_fixed: MatSelect;
  @ViewChild("nuq_eat_a_lot_arr_fixed") nuq_eat_a_lot_arr_fixed: MatSelect;
  @ViewChild("nuq_new_food_arr_fixed") nuq_new_food_arr_fixed: MatSelect;

  nutritionquizForm: FormGroup;

  quizPrefix = 'nuq';

  restService: any;

  checkboxOptions = [
    this.translate.instant("nutrition.Q_3_A_1"),
    this.translate.instant("nutrition.Q_3_A_2"),
    this.translate.instant("nutrition.Q_3_A_3"),
    this.translate.instant("nutrition.Q_3_A_4"),
    this.translate.instant("nutrition.Q_3_A_5")
  ];

  listFixedA = [
    { value: 'חציל' , text: this.translate.instant("nutrition.FOOD_1") },
    { value: 'קישוא' , text: this.translate.instant("nutrition.FOOD_2") },
    { value: 'דג' , text: this.translate.instant("nutrition.FOOD_3") },
    { value: 'טונה' , text: this.translate.instant("nutrition.FOOD_4") },
    { value: 'סרדינים' , text: this.translate.instant("nutrition.FOOD_5") },
    { value: 'מלפפון' , text: this.translate.instant("nutrition.FOOD_6") },
    { value: 'עגבניה' , text: this.translate.instant("nutrition.FOOD_7") },
    { value: 'פלפל' , text: this.translate.instant("nutrition.FOOD_8") },
    { value: 'גזר' , text: this.translate.instant("nutrition.FOOD_9") },
    { value: 'פטריות' , text: this.translate.instant("nutrition.FOOD_10") },
    { value: 'גבינה לבנה' , text: this.translate.instant("nutrition.FOOD_11") },
    { value: 'גבינה צהובה' , text: this.translate.instant("nutrition.FOOD_12") },
    { value: "קוטג'" , text: this.translate.instant("nutrition.FOOD_13") },
    { value: 'אחר' , text: this.translate.instant("nutrition.FOOD_14") },
  ];
  listFixedB = [
    { value: 'גלידה' , text: this.translate.instant("nutrition.FOOD_15") },
    { value: 'שוקולד' , text: this.translate.instant("nutrition.FOOD_16") },
    { value: 'ממתקים' , text: this.translate.instant("nutrition.FOOD_17") },
    { value: 'חטיפים מלוחים' , text: this.translate.instant("nutrition.FOOD_18") },
    { value: 'שתיה מתוקה' , text: this.translate.instant("nutrition.FOOD_19") },
    { value: 'מאפים' , text: this.translate.instant("nutrition.FOOD_20") },
    { value: 'אחר' , text: this.translate.instant("nutrition.FOOD_21") },
  ];
  listFixedC = [
    { value: 'שניצל' , text: this.translate.instant("nutrition.FOOD_22") },
    { value: "צ'יפס" , text: this.translate.instant("nutrition.FOOD_23") },
    { value: 'לחם' , text: this.translate.instant("nutrition.FOOD_24") },
    { value: 'ממתקים וקינוחים' , text: this.translate.instant("nutrition.FOOD_25") },
    { value: 'חטיפים מלוחים' , text: this.translate.instant("nutrition.FOOD_26") },
    { value: 'מוצרים עשירים בחלבון' , text: this.translate.instant("nutrition.FOOD_27") },
    { value: 'פחמימות' , text: this.translate.instant("nutrition.FOOD_28") },
    { value: 'ירקות' , text: this.translate.instant("nutrition.FOOD_29") },
    { value: 'אחר' , text: this.translate.instant("nutrition.FOOD_30") },
  ];
  // listFixedNewFood = [
  //   { value: 'חציל' , text: this.translate.instant("") },
  //   { value: 'קישוא' , text: this.translate.instant("") },
  //   { value: 'דג' , text: this.translate.instant("") },
  //   { value: 'טונה' , text: this.translate.instant("") },
  //   { value: 'סרדינים' , text: this.translate.instant("") },
  //   { value: 'מלפפון' , text: this.translate.instant("") },
  //   { value: 'עגבניה' , text: this.translate.instant("") },
  //   { value: 'פלפל' , text: this.translate.instant("") },
  //   { value: 'גזר' , text: this.translate.instant("") },
  //   { value: 'פטריות' , text: this.translate.instant("") },
  //   { value: 'גבינה לבנה' , text: this.translate.instant("") },
  //   { value: 'גבינה צהובה' , text: this.translate.instant("") },
  //   { value: "קוטג'" , text: this.translate.instant("") },
  //   { value: 'אחר' , text: this.translate.instant("") },
  // ]

  formContext: any;

  appState = appState;
  
  formInitiated = false;

  isLocked = false;

  formOwnerUsr: any;
  isUnderNineteen = false;

  constructor(
    private dialogService: ConfirmDialogService,
    private translate: TranslateService,
    private restApiService: RestApiService<any>,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer
  ) {
    this.restService = this.restApiService.service('nutritionquiz');

    this.formContext = this;

    this.nutritionquizForm = this.formBuilder.group({
      _id: [''],
      nuq_usr_id: [appState.user._id],//''
      nuq_form_is_real: [false],

      nuq_food_does_not_like: [false],
      nuq_food_does_not_like_arr: this.formBuilder.array([]), // food array:
      nuq_food_does_not_like_arr_fixed: [null],
      nuq_food_does_not_eat_health_reason: [false],
      nuq_food_does_not_eat_health_reason_arr: this.formBuilder.array([]), // food array:
      nuq_like_but_try_avoid_eating: [false],
      nuq_like_but_try_avoid_eating_arr: this.formBuilder.array([]), // food array:
      nuq_like_but_try_avoid_eating_arr_fixed: [null],
      nuq_eat_a_lot: [false],
      nuq_eat_a_lot_arr: this.formBuilder.array([]), // food array:
      nuq_eat_a_lot_arr_fixed: [null],
      nuq_eat_no_limits: [false],

      nuq_food_does_not_eat: [''],
      nuq_food_does_not_eat_other: [''],
      // nuq_food_does_not_eat_reason_arr: this.formBuilder.array([]),
      nuq_food_does_not_eat_reason_arr: [null],
      nuq_food_does_not_eat_reason_other: [''],

      nuq_eat_vegetables: [''],
      nuq_eat_vegetables_day: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_eat_vegetables == 'כן',
      Validators.required,
      null)]],
      nuq_eat_fruits: [''],
      nuq_eat_fruits_day: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_eat_fruits == 'כן',
      Validators.required,
      null)]],
      nuq_drink_sugar: [''],
      nuq_drink_sugar_freq: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_drink_sugar == 'כן',
      Validators.required,
      null)]],

      nuq_eat_snacks: [''],
      nuq_eat_snacks_type: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_eat_snacks == 'כן',
      Validators.required,
      null)]],
      nuq_eat_snacks_freq: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_eat_snacks == 'כן',
      Validators.required,
      null)]],
      nuq_eat_snacks_time: ['', [this.conditionalValidator(() => (this.nutritionquizForm.value.nuq_eat_snacks == 'כן' && this.nutritionquizForm.value.nuq_eat_snacks_freq && this.nutritionquizForm.value.nuq_eat_snacks_freq != 'לעיתים רחוקות' && this.nutritionquizForm.value.nuq_eat_snacks_freq != '0-1 בשבוע'),
      Validators.required,
      null)]],

      nuq_drink_estimate: [''],
      nuq_personal_bottle: [''],
      nuq_drink_caffeine: [''],
      nuq_drink_caffeine_items: [null],
      nuq_drink_caffeine_cups: [''],
      nuq_drink_caffeine_last: [''],
  
      nuq_new_food: [''],
      nuq_new_food_arr: this.formBuilder.array([]),
      nuq_new_food_arr_fixed: [null],

      nuq_eat_breakfast: [''],
      
      nuq_eat_breakfast_reasons: this.formBuilder.array([], [this.requiredIfValidator(() => this.nutritionquizForm.value.nuq_eat_breakfast == 'לפעמים' || this.nutritionquizForm.value.nuq_eat_breakfast == 'לעיתים רחוקות' || this.nutritionquizForm.value.nuq_eat_breakfast == 'אף פעם')]),
      nuq_eat_breakfast_reason_other_details: ['', [this.requiredIfValidator(() => this.nutritionquizForm.value.nuq_eat_breakfast_reasons.includes('אחר'))]],
      nuq_school_sandwich: [''],
      nuq_eat_near_screen: [''],
      nuq_eat_near_screen_status: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_eat_near_screen == 'מול מסך',
        Validators.required,
        null)]],
      nuq_eat_outside: [''],
      nuq_eat_outside_status: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_eat_outside == 'כן',
      Validators.required,
      null)]],
      nuq_more_food_comments: [''],
      nuq_more_food_comments_text: ['', [this.conditionalValidator(() => this.nutritionquizForm.value.nuq_more_food_comments == 'כן',
      Validators.required,
      null)]],

    },
      {
        validators: [this.formCustomValidator.bind(this)]
      }
    );

    for (const field of ['nuq_food_does_not_like_arr', 'nuq_like_but_try_avoid_eating_arr', 'nuq_eat_a_lot_arr', 'nuq_new_food_arr']) {
      this.valueChangesFixed(field)
    }

    // window["rf"] = this.nutritionquizForm;

    this.route.params.subscribe(async params => {
      if (params.id) {
        const data = await this.restService.get(params.id);
        const quizUsrId = data.nuq_usr_id;
        if (!quizUsrId) {
            return;
        }
        // const usr = await this.restApiService.service('users').get(quizUsrId).catch(e => { console.error(e) });
        const usr = data.quizOwner;
        if (!usr) {
          return;
        }
        this.formOwnerUsr = usr;
        this.isUnderNineteen = Boolean(+(usr['usr_age_formatted'] || 0) < 19);

        if (this.appState.user.usr_type === 'superadmin' || this.appState.user.usr_type === 'nutri') {
          this.appState.quizUser = usr;
        }

        if (this.translate.currentLang !== 'en') {
            if (data.quizOwner?.usr_gender === 'נקבה') {
                this.translate.use('he_F');
            } else {
                this.translate.use('he');
            }
        }

        this.isLocked = data && data.nuq_locked;
        if (this.appState.user.usr_type === 'parent' || this.appState.user.usr_type === 'nutri') {
            this.isLocked = true;
        }
        if (this.appState.user.usr_type === 'nutri' && data.isReportEverPublished !== undefined) {
            this.isLocked = data.isReportEverPublished;
        }
        // if (this.isUnderNineteen) {
        //     this.nutritionquizForm.get('nuq_school_sandwich').setValidators(Validators.required);
        // }
        if (!data.savedAt && !data.nuq_food_does_not_like && !data.nuq_food_does_not_eat_health_reason && !data.nuq_like_but_try_avoid_eating) {
          const lastQuiz = await this.restService.getLast({
            nuq_usr_id: quizUsrId,
            _id: {
              $ne: params.id
            }
          });
          if (lastQuiz[0]) {
            const { nuq_food_does_not_like, nuq_food_does_not_like_arr, nuq_food_does_not_like_arr_fixed, nuq_food_does_not_eat_health_reason, nuq_food_does_not_eat_health_reason_arr, nuq_food_does_not_eat, nuq_food_does_not_eat_other, nuq_food_does_not_eat_reason_arr, nuq_food_does_not_eat_reason_other, nuq_like_but_try_avoid_eating, nuq_like_but_try_avoid_eating_arr, nuq_like_but_try_avoid_eating_arr_fixed, nuq_eat_snacks, nuq_eat_snacks_type, nuq_eat_snacks_freq, nuq_eat_snacks_time, nuq_drink_caffeine, nuq_drink_caffeine_items, nuq_drink_caffeine_cups, nuq_drink_caffeine_last } = lastQuiz[0];
            const stripped = { nuq_food_does_not_like, nuq_food_does_not_like_arr, nuq_food_does_not_like_arr_fixed, nuq_food_does_not_eat_health_reason, nuq_food_does_not_eat_health_reason_arr, nuq_food_does_not_eat, nuq_food_does_not_eat_other, nuq_food_does_not_eat_reason_arr, nuq_food_does_not_eat_reason_other, nuq_like_but_try_avoid_eating, nuq_like_but_try_avoid_eating_arr, nuq_like_but_try_avoid_eating_arr_fixed, nuq_eat_snacks, nuq_eat_snacks_type, nuq_eat_snacks_freq, nuq_eat_snacks_time, nuq_drink_caffeine, nuq_drink_caffeine_items, nuq_drink_caffeine_cups, nuq_drink_caffeine_last };
            this.nutritionquizForm.patchValue(stripped, { emitEvent: false });
            this.patchFormArrays(stripped);
            
            this.oldValuesPopup();
          }
          this.nutritionquizForm.get('nuq_usr_id').patchValue(data.nuq_usr_id, { emitEvent: false });
          this.nutritionquizForm.get('_id').patchValue(data._id, { emitEvent: false });
        } else {
          this.nutritionquizForm.patchValue(data, { emitEvent: false });
          this.patchFormArrays(data);
        }

        if (this.isLocked) {
            this.nutritionquizForm.disable();
        }
      } else {
        const res = await this.restService.getLast({ nuq_usr_id: this.appState.user._id });
        const navTo = res[0]?._id ? `Nutritionquiz/${res[0]._id}` : '/';
        this.router.navigate([navTo], { replaceUrl: true });
        return;
      }

      if (this.nutritionquizForm.value.nuq_eat_no_limits == true) {
        this.resetAndSetDisableNoLimitCheckBoxes(true);
      }

      this.formInitiated = true;
    });

  }

  // ngAfterViewInit() {
  //   for (const field of ['nuq_food_does_not_like_arr', 'nuq_like_but_try_avoid_eating_arr', 'nuq_eat_a_lot_arr', 'nuq_new_food_arr']) {
  //     this.valueChangesFixed(field)
  //   }
  // }

  ngOnDestroy() {
    if (this.translate.currentLang !== 'en') {
      if (this.appState.user.usr_gender === 'נקבה') {
          this.translate.use('he_F');
      } else {
          this.translate.use('he');
      }
    }
  }

  valueChangesFixed(field: string) {
    this.nutritionquizForm.get(`${field}_fixed`).valueChanges.subscribe(newVal => {
      const oldVal = this.nutritionquizForm.value[`${field}_fixed`] || [];
      if (newVal && newVal.includes('אחר') && !oldVal.includes('אחר')) {
        if (this[`${field}_fixed`]) {
          this[`${field}_fixed`].close();
        }
        if (field === 'nuq_new_food_arr') {
          this.addNewFood(this, field, true);
        } else {
          this.addFoodItem(this, field, true);
        }
      }
      if (!newVal || !newVal.includes('אחר')) {
        this.resetFormArray(field);
      }
    });
  }

  oldValuesPopup() {
    const options = {
      title: '',
      message: 'חלק מהתשובות בשאלון זה מולאו אוטומטית על פי התשובות שלך מהפעם הקודמת. במידה שיש שינוי, חשוב לעדכן את התשובות לסטטוס הנוכחי.',
      // cancelText: this.translate.instant("general.no"),
      // confirmText: this.translate.instant("general.yes"),
      confirmText: 'אישור',
    };
    this.dialogService.open(options);
  }
  
  conditionalValidator(predicate: Function,
    validator: Function,
    parentKeyAndValueEquals?: any, parentKeyAndValueNotEquals?: any): Function {
    return (formControl => {

      if (!formControl.parent) {
        return null;
      }

      let error = null;

      if (parentKeyAndValueEquals) {
        if (formControl.parent.get(parentKeyAndValueEquals.key).value == parentKeyAndValueEquals.value) {
          error = validator(formControl);
        }
      } else if (parentKeyAndValueNotEquals) {
        if (formControl.parent.get(parentKeyAndValueNotEquals.key).value != parentKeyAndValueNotEquals.value) {
          error = validator(formControl);
        }
      } else {
        if (predicate()) {
          error = validator(formControl);
        }
      }

      return error;
    })
  }

  formCustomValidator(formGroup: FormGroup) {

    if (!this.formInitiated) {
      return null;
    }

    if (!formGroup.value.nuq_eat_no_limits &&
      !formGroup.value.nuq_food_does_not_like &&
      !formGroup.value.nuq_food_does_not_eat_health_reason &&
      !formGroup.value.nuq_like_but_try_avoid_eating &&
      !formGroup.value.nuq_eat_a_lot
    ) {
      return {
        msg: this.translate.instant("nutrition.ERROR_A"),
        scrollIntoNativeElement: this.nuq_eat_no_limits_el
      };
    }

    if (formGroup.value.nuq_food_does_not_like) {
      // let res = this.validateFoodArrayFormField(formGroup, "nuq_food_does_not_like_arr");
      // if (res) return res;
      let resFixed = this.validateFoodArrayFormFieldFixed(formGroup, "nuq_food_does_not_like_arr_fixed");
      if (resFixed) {
        return resFixed;
      } else {
        let res = this.validateFoodArrayFormField(formGroup, "nuq_food_does_not_like_arr");
        if (res) return res;
      }
    }
    if (formGroup.value.nuq_food_does_not_eat_health_reason) {
      // let res = this.validateFoodArrayFormField(formGroup, "nuq_food_does_not_eat_health_reason_arr");
      // if (res) return res;
      if (!formGroup.value.nuq_food_does_not_eat || (formGroup.value.nuq_food_does_not_eat === 'אחר' && !formGroup.value.nuq_food_does_not_eat_other)) {
        return {
          msg: this.translate.instant("nutrition.ERROR_O"),
          scrollIntoNativeElement: this.nuq_eat_no_limits_el
        };
      }
      if (!formGroup.value.nuq_food_does_not_eat_reason_arr?.length || (formGroup.value.nuq_food_does_not_eat_reason_arr.includes('אחר') && !formGroup.value.nuq_food_does_not_eat_reason_other)) {
        return {
          msg: this.translate.instant("nutrition.ERROR_P"),
          scrollIntoNativeElement: this.nuq_eat_no_limits_el
        };
      }
    }
    if (formGroup.value.nuq_like_but_try_avoid_eating) {
      // let res = this.validateFoodArrayFormField(formGroup, "nuq_like_but_try_avoid_eating_arr");
      // if (res) return res;
      let resFixed = this.validateFoodArrayFormFieldFixed(formGroup, "nuq_like_but_try_avoid_eating_arr_fixed");
      if (resFixed) {
        return resFixed;
      } else {
        let res = this.validateFoodArrayFormField(formGroup, "nuq_like_but_try_avoid_eating_arr");
        if (res) return res;
      }
    }
    if (formGroup.value.nuq_eat_a_lot) {
      // let res = this.validateFoodArrayFormField(formGroup, "nuq_eat_a_lot_arr");
      // if (res) return res;
      let resFixed = this.validateFoodArrayFormFieldFixed(formGroup, "nuq_eat_a_lot_arr_fixed");
      if (resFixed) {
        return resFixed;
      } else {
        let res = this.validateFoodArrayFormField(formGroup, "nuq_eat_a_lot_arr");
        if (res) return res;
      }
    }

    if (formGroup.value.nuq_eat_vegetables == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_VEGETABLES_7"),
        scrollIntoNativeElement: this.nuq_eat_vegetables_el
      };
    }
    if (formGroup.value.nuq_eat_vegetables == "כן" && formGroup.value.nuq_eat_vegetables_day == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_VEGETABLES_8"),
        scrollIntoNativeElement: this.nuq_eat_vegetables_el
      };
    }

    if (formGroup.value.nuq_eat_fruits == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_FRUITS_7"),
        scrollIntoNativeElement: this.nuq_eat_fruits_el
      };
    }
    if (formGroup.value.nuq_eat_fruits == "כן" && formGroup.value.nuq_eat_fruits_day == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_FRUITS_8"),
        scrollIntoNativeElement: this.nuq_eat_fruits_el
      };
    }

    if (formGroup.value.nuq_new_food == "") {
      return {
        msg: this.translate.instant("nutrition.NEW_FOOD_10"),
        scrollIntoNativeElement: this.nuq_new_food_el
      };
    }

    if (formGroup.value.nuq_new_food == "כן") {
      // let res = this.validateNewFoodArray(formGroup, "nuq_new_food_arr");
      // if (res) return res;
      let resFixed = this.validateFoodArrayFormFieldFixed(formGroup, "nuq_new_food_arr_fixed");
      if (resFixed) {
        return resFixed;
      } else {
        let res = this.validateNewFoodArray(formGroup, "nuq_new_food_arr");
        if (res) return res;
      }
    }

    if (formGroup.value.nuq_eat_snacks == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_SNACKS_20"),
        scrollIntoNativeElement: this.nuq_eat_snacks_el
      };
    }
    if (formGroup.value.nuq_eat_snacks === "כן" && formGroup.value.nuq_eat_snacks_type == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_SNACKS_21"),
        scrollIntoNativeElement: this.nuq_eat_snacks_el
      };
    }
    if (formGroup.value.nuq_eat_snacks === "כן" && formGroup.value.nuq_eat_snacks_freq == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_SNACKS_22"),
        scrollIntoNativeElement: this.nuq_eat_snacks_el
      };
    }
    if (formGroup.value.nuq_eat_snacks === "כן" && formGroup.value.nuq_eat_snacks_freq && formGroup.value.nuq_eat_snacks_freq !== "לעיתים רחוקות" && formGroup.value.nuq_eat_snacks_freq !== "0-1 בשבוע" && formGroup.value.nuq_eat_snacks_time == "") {
      return {
        msg: this.translate.instant("nutrition.EAT_SNACKS_23"),
        scrollIntoNativeElement: this.nuq_eat_snacks_el
      };
    }

    if (formGroup.value.nuq_drink_estimate == "") {
      return {
        msg: this.translate.instant("nutrition.DRINK_ESTIMATE_21"),
        scrollIntoNativeElement: this.nuq_drink_estimate_el
      };
    }
    
    if (formGroup.value.nuq_personal_bottle == "") {
      return {
        msg: this.translate.instant("nutrition.PERSONAL_BOTTLE_5"),
        scrollIntoNativeElement: this.nuq_personal_bottle_el
      };
    }

    if (formGroup.value.nuq_drink_sugar == "") {
      return {
        msg: this.translate.instant("nutrition.DRINK_SUGAR_8"),
        scrollIntoNativeElement: this.nuq_drink_sugar_el
      };
    }
    if (formGroup.value.nuq_drink_sugar == "כן" && formGroup.value.nuq_drink_sugar_freq == "") {
      return {
        msg: this.translate.instant("nutrition.DRINK_SUGAR_9"),
        scrollIntoNativeElement: this.nuq_drink_sugar_el
      };
    }

    if (formGroup.value.nuq_drink_caffeine == "") {
      return {
        msg: this.translate.instant("nutrition.DRINK_CAFFEINE_19"),
        scrollIntoNativeElement: this.nuq_drink_caffeine_el
      };
    }
    if (formGroup.value.nuq_drink_caffeine === "כן" && (!formGroup.value.nuq_drink_caffeine_items || !formGroup.value.nuq_drink_caffeine_items.length)) {
      return {
        msg: this.translate.instant("nutrition.DRINK_CAFFEINE_20"),
        scrollIntoNativeElement: this.nuq_drink_caffeine_el
      };
    }
    if (formGroup.value.nuq_drink_caffeine === "כן" && formGroup.value.nuq_drink_caffeine_cups == "") {
      return {
        msg: this.translate.instant("nutrition.DRINK_CAFFEINE_21"),
        scrollIntoNativeElement: this.nuq_drink_caffeine_el
      };
    }
    if (formGroup.value.nuq_drink_caffeine === "כן" && formGroup.value.nuq_drink_caffeine_last == "") {
      return {
        msg: this.translate.instant("nutrition.DRINK_CAFFEINE_22"),
        scrollIntoNativeElement: this.nuq_drink_caffeine_el
      };
    }

    if (formGroup.value.nuq_eat_breakfast == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_B"),
        scrollIntoNativeElement: this.nuq_eat_breakfast_el
      };
    }
    
    if ((formGroup.value.nuq_eat_breakfast == "לפעמים" || formGroup.value.nuq_eat_breakfast == "לעיתים רחוקות" || formGroup.value.nuq_eat_breakfast == "אף פעם") && formGroup.value.nuq_eat_breakfast_reasons.length === 0) {
      return {
        msg: this.translate.instant("nutrition.ERROR_C"),
        scrollIntoNativeElement: this.nuq_eat_breakfast_reason_el
      };
    }
    if (formGroup.value.nuq_eat_breakfast_reasons && formGroup.value.nuq_eat_breakfast_reasons.includes("אחר") && formGroup.value.nuq_eat_breakfast_reason_other_details == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_D"),
        scrollIntoNativeElement: this.nuq_eat_breakfast_reason_el
      };
    }

    if (this.isUnderNineteen && formGroup.value.nuq_school_sandwich == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_E"),
        scrollIntoNativeElement: this.nuq_school_sandwich_el
      };
    }

    if (formGroup.value.nuq_eat_near_screen == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_F"),
        scrollIntoNativeElement: this.nuq_eat_near_screen_el
      };
    }
    if (formGroup.value.nuq_eat_near_screen == "מול מסך" && formGroup.value.nuq_eat_near_screen_status == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_G"),
        scrollIntoNativeElement: this.nuq_eat_near_screen_el
      };
    }

    if (formGroup.value.nuq_eat_outside == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_H"),
        scrollIntoNativeElement: this.nuq_eat_outside_el
      };
    }
    if (formGroup.value.nuq_eat_outside == "כן" && formGroup.value.nuq_eat_outside_status == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_I"),
        scrollIntoNativeElement: this.nuq_eat_outside_el
      };
    }

    if (formGroup.value.nuq_more_food_comments == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_J"),
        scrollIntoNativeElement: this.nuq_more_food_comments_el
      };
    }
    if (formGroup.value.nuq_more_food_comments == "כן" && formGroup.value.nuq_more_food_comments_text == "") {
      return {
        msg: this.translate.instant("nutrition.ERROR_K"),
        scrollIntoNativeElement: this.nuq_more_food_comments_el
      };
    }

    return null;
  }

  validateFoodArrayFormField(formGroup: FormGroup, arrayFieldName: string) {

    let descriptionViewChildrenArr = this.nuq_food_description_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);

    // validate each mini-form
    for (let i = 0; i < (formGroup.get(arrayFieldName) as FormArray).controls.length; i++) {
      let innerFormGroup: FormGroup = ((formGroup.get(arrayFieldName) as FormArray).controls[i] as FormGroup);

      if (innerFormGroup.value.description == "") {
        return {
          msg: this.translate.instant("nutrition.ERROR_L"),
          scrollIntoNativeElement: descriptionViewChildrenArr[i]
        };
      }

    }

    return null;
  }

  validateFoodArrayFormFieldFixed(formGroup: FormGroup, arrayFieldName: string) {

    if (!formGroup.get(arrayFieldName)?.value?.length) {
      return {
        msg: this.translate.instant("nutrition.ERROR_L"),
        scrollIntoNativeElement: this.nuq_eat_no_limits_el
      };
    }

    return null;
  }

  validateNewFoodArray(formGroup: FormGroup, arrayFieldName: string) {

    let descriptionViewChildrenArr = this.nuq_new_food_description_el.toArray().filter((element) => element.nativeElement.id == arrayFieldName);

    // validate each mini-form
    for (let i = 0; i < (formGroup.get(arrayFieldName) as FormArray).controls.length; i++) {
      let innerFormGroup: FormGroup = ((formGroup.get(arrayFieldName) as FormArray).controls[i] as FormGroup);

      if (innerFormGroup.value.description === "" || innerFormGroup.value.feedback === "") {
        return {
          msg: this.translate.instant("nutrition.NEW_FOOD_11"),
          scrollIntoNativeElement: descriptionViewChildrenArr[i]
        };
      }

    }

    return null;
  }

  confirmAndResetFood(event, noLimitsEl) {
    if (this.isLocked) return;

    // notice - noLimitsEl.checked is the state of the checkbox before any
    // change occured.

    if (noLimitsEl.checked) {
      // remove all the disabled marks from the other checkboxes
      this.resetAndSetDisableNoLimitCheckBoxes(false);
      return;
    }

    // from here on, we know the state of the checkbox was false, and it is being turned to true:
    if (!this.nutritionquizForm.value.nuq_food_does_not_like &&
      !this.nutritionquizForm.value.nuq_food_does_not_eat_health_reason &&
      !this.nutritionquizForm.value.nuq_like_but_try_avoid_eating &&
      !this.nutritionquizForm.value.nuq_eat_a_lot) {
      this.resetAndSetDisableNoLimitCheckBoxes(true);
      return;
    }

    // // popup question only if there's at least one checkbox on
    // let popupQuestion = confirm("בלחיצה על אין מגבלות ימחקו ככל המגבלות שסומנו. להמשיך?");
    // if (popupQuestion) {
    //   this.resetAndSetDisableNoLimitCheckBoxes(true);
    // } else {
    //   event.preventDefault()
    // }

    event.preventDefault();

    const options = {
      title: '',
      message: this.translate.instant("nutrition.ERROR_M"),
      cancelText: this.translate.instant("general.no"),
      confirmText: this.translate.instant("general.yes")
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
        if (confirmed) {
            this.resetAndSetDisableNoLimitCheckBoxes(true);
            this.resetField("nuq_eat_no_limits", true);
        }
    });
  }

  resetAndSetDisableNoLimitCheckBoxes(disabledState: boolean) {
    this.resetField("nuq_food_does_not_like", { value: false, disabled: disabledState });
    this.resetField("nuq_food_does_not_eat_health_reason", { value: false, disabled: disabledState });
    this.resetField("nuq_like_but_try_avoid_eating", { value: false, disabled: disabledState });
    this.resetField("nuq_eat_a_lot", { value: false, disabled: disabledState });
  }

  patchFormArrays(data: any) {

    let foodArraysFieldNames: Array<string> = [
      "nuq_food_does_not_like_arr",
      "nuq_food_does_not_eat_health_reason_arr",
      "nuq_like_but_try_avoid_eating_arr",
      "nuq_eat_a_lot_arr",
    ];

    this.patchGenericArray(data, foodArraysFieldNames, this, this.createFoodItem);
    this.patchGenericArray(data, ["nuq_new_food_arr"], this, this.createNewFood);
    // this.patchGenericArray(data, ["nuq_food_does_not_eat_reason_arr"], this, () => this.formBuilder.control({}) );

    const len = data['nuq_eat_breakfast_reasons']?.length || 0;
    for (let i = 0; i < len; i++) {
      this.updateChkbxArray(data['nuq_eat_breakfast_reasons'][i], true, 'nuq_eat_breakfast_reasons')
    }
  }

  patchGenericArray(data, fieldArrayNames, context, createFunctionRef) {
    for (let i = 0; i < fieldArrayNames.length; i++) {
      if (!data[fieldArrayNames[i]] || !data[fieldArrayNames[i]].length) {
        continue;
      }
      let formArray: FormArray = this.nutritionquizForm.get(fieldArrayNames[i]) as FormArray;
      formArray.clear();
      for (let innerIndex = 0; innerIndex < data[fieldArrayNames[i]].length; innerIndex++) {
        let newFormElement = createFunctionRef(context);
        formArray.push(newFormElement);
      }
      formArray.patchValue(data[fieldArrayNames[i]], { emitEvent: false });
    }
  }

  resetField(filedName, value) {
    this.nutritionquizForm.controls[filedName].reset(value);
  }

  addFoodItem(context, arrFieldName, focus?: boolean) {
    context = context == null ? this : context;

    let foodArr = context.nutritionquizForm.get(arrFieldName) as FormArray;
    foodArr.push(context.createFoodItem(context));
    if (focus) {
      setTimeout(() => {
        let descriptionViewChildrenArr = this.nuq_food_description_control.toArray().filter((element) => element.nativeElement.id == arrFieldName);
        if (descriptionViewChildrenArr.length) {
          descriptionViewChildrenArr[descriptionViewChildrenArr.length - 1].nativeElement.click(); // .focus();
        }
      }, 200);

      // const newControl = foodArr.controls[foodArr.length - 1];
      // console.log('newControl', newControl);
      // if (newControl) {
      //   const toFocus = newControl.get('description');
      //   if (toFocus) {
      //     console.log('toFocus', toFocus);
      //   }
      // }
    }
  }

  removeFoodItem(context, checkboxFieldName, arrFieldName, index) {
    let foodArr = context.nutritionquizForm.get(arrFieldName) as FormArray;
    foodArr.removeAt(index);
    // if (foodArr.length == 0) {
    //   context.nutritionquizForm.controls[checkboxFieldName].reset(false);
    // }
  }

  createFoodItem(context) {
    return context.formBuilder.group({
      description: '',
    })
  }

  foodCheckboxOnChange(event, foodArrayFieldName: string) {
    if (event.checked) {
      // if (this.nutritionquizForm.value[foodArrayFieldName].length == 0) {
      //   this.addFoodItem(this, foodArrayFieldName);
      // }
    } else {
      // nothing.
    }
  }

  newFoodOnChange(event, foodArrayFieldName: string) {
    if (event.value === 'כן') {
      // if (this.nutritionquizForm.value[foodArrayFieldName].length == 0) {
      //   this.addNewFood(this, foodArrayFieldName);
      // }
    }
  }

  addNewFood(context, arrFieldName, focus?: boolean) {
    context = context == null ? this : context;

    let foodArr = context.nutritionquizForm.get(arrFieldName) as FormArray;
    foodArr.push(context.createNewFood(context));
    if (focus) {
      setTimeout(() => {
        let descriptionViewChildrenArr = this.nuq_new_food_description_control.toArray().filter((element) => element.nativeElement.id == arrFieldName);
        if (descriptionViewChildrenArr.length) {
          descriptionViewChildrenArr[descriptionViewChildrenArr.length - 1].nativeElement.click(); // .focus();
        }
      }, 200);

      // const newControl = foodArr.controls[foodArr.length - 1];
      // console.log('newControl', newControl);
      // if (newControl) {
      //   const toFocus = newControl.get('description');
      //   if (toFocus) {
      //     console.log('toFocus', toFocus);
      //   }
      // }
    }
  }

  removeNewFood(context, fieldName, arrFieldName, index) {
    let foodArr = context.nutritionquizForm.get(arrFieldName) as FormArray;
    foodArr.removeAt(index);
    
    const foodArrFixed = context.nutritionquizForm.get(`${arrFieldName}_fixed`) as FormArray;
    if (foodArrFixed?.value?.includes('אחר')) {
      // const filtered = foodArrFixed?.value.filter(food => food !== 'אחר')
      // this.patchGenericArray({ nuq_new_food_arr: filtered }, ["nuq_new_food_arr"], this, this.createNewFood);
      const otherIndex = foodArrFixed?.value.indexOf(food => food === 'אחר');
      foodArrFixed.removeAt(otherIndex);
    }
    // if (foodArr.length === 0) {
    //   context.nutritionquizForm.controls[fieldName].reset('לא');
    // }
  }

  createNewFood(context) {
    return context.formBuilder.group({
      description: '',
      feedback:'',
    })
  }

  async onSubmit(form: FormGroup, prefix: string) {
    this.clearValuesFromUnusedFields();
    const validateRes = this.validateForm();

    if (!validateRes.valid) {
        if (validateRes.scrollIntoNativeElement) {
          validateRes.scrollIntoNativeElement.nativeElement.scrollIntoView();
        }
        // this.showErrors = true;
        this.showStatus(validateRes.msg, null);
        return;
    } else {
        // this.showErrors = false;
    }

    this.resetField(`${prefix}_form_is_real`, true);

    this.clearAllIds();

    if (form.value._id) {
        const res = await this.restService.patch(form.value._id, form.value).catch(e => { console.error(e); });
        if (res && res._id && res[`${prefix}_usr_id`]) {
            // this.buildAssetsArray(form, `${prefix}_assets_arr`, res);
            form.patchValue(res, { emitEvent: false });
            this.patchFormArrays(res);
            
            this.showStatus(this.translate.instant('general.savedSuccessfully'), null);
            if (this.appState.user.usr_type === 'player'){
              setTimeout(()=>{
                this.router.navigate([`Userprogress`]);
              }, 2000);
            }
        } else {
            this.showStatus('אירעה שגיאה', null);
        }
    }
  }

  clearIdsIfNotExistFromArrayItems(arr: any) {
      const arrLen = arr.length;
      for (let i = 0; i < arrLen; i++) {
          if (String(arr[i]._id).length != 0) {
              continue;
          }

          delete arr[i]._id;
      }
  }

  validateForm() {

    this.nutritionquizForm.get('nuq_eat_breakfast_reasons').updateValueAndValidity();
    if (!this.nutritionquizForm.valid)
      return {
        valid: false,
        msg: this.nutritionquizForm.errors ? this.nutritionquizForm.errors.msg : "נא לתקן את השדות האדומים",
        scrollIntoNativeElement: this.nutritionquizForm.errors ? this.nutritionquizForm.errors.scrollIntoNativeElement : null
      }

    return {
      valid: true,
      msg: "ולידציה עברה בהצלחה"
    }

  }

  clearAllIds() {
    this.clearIdsIfNotExistFromArrayItems(this.nutritionquizForm.value.nuq_food_does_not_like_arr);
    this.clearIdsIfNotExistFromArrayItems(this.nutritionquizForm.value.nuq_food_does_not_eat_health_reason_arr);
    this.clearIdsIfNotExistFromArrayItems(this.nutritionquizForm.value.nuq_like_but_try_avoid_eating_arr);
    this.clearIdsIfNotExistFromArrayItems(this.nutritionquizForm.value.nuq_eat_a_lot_arr);
    this.clearIdsIfNotExistFromArrayItems(this.nutritionquizForm.value.nuq_new_food_arr);
  }

  clearValuesFromUnusedFields() {
    if (this.nutritionquizForm.value.nuq_food_does_not_like == false || this.nutritionquizForm.value.nuq_food_does_not_like == undefined) {
      this.resetField("nuq_food_does_not_like_arr_fixed", null);
      this.resetFormArray('nuq_food_does_not_like_arr');
    }
    if (this.nutritionquizForm.value.nuq_food_does_not_eat_health_reason == false || this.nutritionquizForm.value.nuq_food_does_not_eat_health_reason == undefined) {
      this.resetFormArray('nuq_food_does_not_eat_health_reason_arr');
      this.resetField("nuq_food_does_not_eat", '');
      this.resetField("nuq_food_does_not_eat_other", '');
      // this.resetFormArray('nuq_food_does_not_eat_reason_arr');
      this.resetField("nuq_food_does_not_eat_reason_arr", null);
      this.resetField("nuq_food_does_not_eat_reason_other", '');
    }
    if (this.nutritionquizForm.value.nuq_like_but_try_avoid_eating == false || this.nutritionquizForm.value.nuq_like_but_try_avoid_eating == undefined) {
      this.resetField("nuq_like_but_try_avoid_eating_arr_fixed", null);
      this.resetFormArray('nuq_like_but_try_avoid_eating_arr');
    }
    if (this.nutritionquizForm.value.nuq_eat_a_lot == false || this.nutritionquizForm.value.nuq_eat_a_lot == undefined) {
      this.resetField("nuq_eat_a_lot_arr_fixed", null);
      this.resetFormArray('nuq_eat_a_lot_arr');
    }

    if (this.nutritionquizForm.value.nuq_eat_vegetables == 'לא') {
      this.resetField("nuq_eat_vegetables_day", '');
    }
    if (this.nutritionquizForm.value.nuq_eat_fruits == 'לא') {
      this.resetField("nuq_eat_fruits_day", '');
    }
    if (this.nutritionquizForm.value.nuq_new_food === 'לא') {
      this.resetField("nuq_new_food_arr_fixed", null);
      this.resetFormArray('nuq_new_food_arr');
    }
    if (this.nutritionquizForm.value.nuq_eat_snacks == 'לא') {
      this.resetField("nuq_eat_snacks_type", '');
      this.resetField("nuq_eat_snacks_freq", '');
      this.resetField("nuq_eat_snacks_time", '');
    }
    if (this.nutritionquizForm.value.nuq_eat_snacks == 'כן' && (this.nutritionquizForm.value.nuq_eat_snacks_freq == 'לעיתים רחוקות' || this.nutritionquizForm.value.nuq_eat_snacks_freq == '0-1 בשבוע')) {
      this.resetField("nuq_eat_snacks_time", '');
    }

    if (this.nutritionquizForm.value.nuq_drink_sugar == 'לא') {
      this.resetField("nuq_drink_sugar_freq", '');
    }
    if (this.nutritionquizForm.value.nuq_drink_caffeine == 'לא') {
      this.resetField("nuq_drink_caffeine_items", null);
      this.resetField("nuq_drink_caffeine_cups", '');
      this.resetField("nuq_drink_caffeine_last", '');
    }

    // if (this.nutritionquizForm.value.nuq_eat_breakfast == 'ברור' || this.nutritionquizForm.value.nuq_eat_breakfast == 'כמעט תמיד') {
    //   this.resetField("nuq_eat_breakfast_reason", '');
    // }
    // if (this.nutritionquizForm.value.nuq_eat_breakfast_reason != 'אחר') {
    //   this.resetField("nuq_eat_breakfast_reason_other_details", '');
    // }
    if (this.nutritionquizForm.value.nuq_eat_breakfast == 'ברור' || this.nutritionquizForm.value.nuq_eat_breakfast == 'כמעט תמיד') {
      this.resetFormArray("nuq_eat_breakfast_reasons");
    }
    if (this.nutritionquizForm.value.nuq_eat_breakfast_reasons && !this.nutritionquizForm.value.nuq_eat_breakfast_reasons.includes('אחר')) {
      this.resetField("nuq_eat_breakfast_reason_other_details", '');
    }

    if (this.nutritionquizForm.value.nuq_eat_near_screen == 'ללא מסכים') {
      this.resetField("nuq_eat_near_screen_status", '');
    }
    if (this.nutritionquizForm.value.nuq_eat_outside == 'לעיתים רחוקות') {
      this.resetField("nuq_eat_outside_status", '');
    }
    if (this.nutritionquizForm.value.nuq_more_food_comments == 'לא') {
      this.resetField("nuq_more_food_comments_text", '');
    }

    // a special case where one or more of the checkboxes was in the state of "true", then user submitted the form,
    // after which the "nuq_eat_no_limits" was clicked.
    // this causes the checkboxes above it to be disabled, and their values disappear from the this.nutritionquizForm.value property.
    // if the user submits again, the server does not get the properties of the disabled checkboxes, and thus "thinks" they are still in 
    // the state of "true". This is why we assign their values and "false" directly to this.nutritionquizForm.value.
    // The this.nutritionquizForm.value is updated right after the server call, so we don't need to worry
    if (this.nutritionquizForm.value.nuq_eat_no_limits == true) {
      this.nutritionquizForm.value.nuq_food_does_not_like = false;
      this.nutritionquizForm.value.nuq_food_does_not_eat_health_reason = false;
      this.nutritionquizForm.value.nuq_like_but_try_avoid_eating = false;
      this.nutritionquizForm.value.nuq_eat_a_lot = false;
    }
  }

  resetFormArray(arrayFieldName) {
    let formArray = this.nutritionquizForm.get(arrayFieldName) as FormArray;
    let length: number = formArray.length;
    for (let i = 0; i < length; i++) {
      formArray.removeAt(0);
    }
  }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];

  }
  
  requiredIfValidator(predicate: Function): ValidatorFn | null {
    return ((formControl) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl); 
      }
      return null;
    })
  }

  updateChkbxArray(value: string, isChecked: boolean, key: string) {
    const chkArray = <FormArray>this.nutritionquizForm.get(key);
    if (!chkArray) {
      return;
    }
    const idx = chkArray.controls.findIndex(x => x.value == value);
    if (isChecked && idx === -1) {
      chkArray.push(new FormControl(value));
    }
    if (!isChecked && idx > -1) {
      chkArray.removeAt(idx);
    }
  }

  checkedFnArray(value, isChecked, key) {
    const control = this.nutritionquizForm.get(key);
    let val = control.value ? [...control.value] : [];
    if (isChecked) {
      val.push(value);
    } else {
      let idx = val.findIndex(x => x === value);
      val.splice(idx, 1);
    }
    control.patchValue(val, { emitEvent: false });
    control.updateValueAndValidity();
  }
}

