import { Component, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, ValidatorFn } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { appState } from '@services/app-state';
import { Apputils } from '@services/app-utils';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '@ngx-translate/core';

import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from 'src/shared/image-dialog.component';
import { ShareReportsDialogComponent } from './share-reports-dialog.component';
import { tap, first, map, startWith } from 'rxjs/operators';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';

import * as moment from 'moment-timezone';

@Component({
  templateUrl: "./profile.component.html"
})
export class ProfileComponent {

  @ViewChild("subscribeTemplate") subscribeTemplate: TemplateRef<any>;

  profileForm: FormGroup;

  teamsForm: FormGroup;
  clubsForm: FormGroup;

  base_image_upload_folder_path = '';

  regexPatterns = {
    ONLY_HEBREW_REGEX: { pattern: '^[\u0590-\u05ff]+[\u0590-\u05ff\'"\\- ]*$', message: 'בבקשה השתמש רק בעברית' },
    // CELLPHONE_REGEX: { pattern: '^[0-9]{2}[0-9,-]+[0-9]+$', message: 'בבקשה הזן מספר נייד תקין' },
    CELLPHONE_REGEX: { pattern: '^[0-9]{10}$', message: 'בבקשה הזן מספר נייד תקין' },
    ID_REGEX: { pattern: '^[0-9]{9}$', message: 'בבקשה הזן מספר ת.ז תקני (כולל ספרת ביקורת)' },
  }

  validationMessages = {
    required: 'שדה זה הוא שדה חובה',
    email: 'בבקשה הזן כתובת מייל תקנית',
    matDatepickerParse: 'בבקשה בחר תאריך תקין',
    maxlength: 'מספר הטלפון ארוך מדי',
    minlength: 'מספר הטלפון קצר מדי',
    samePhone: 'מספר הטלפון זהה למספר הטלפון שלך',
    sameParentPhone: 'מספר הטלפון זהה למספר הטלפון של ההורה',
    regexErrors: {
      // this property is initiated in the constructor      
    }
  }

  minDate = new Date("01/01/1900");
  maxDate = new Date();

  isMinor = false;

  uploadUrlService: any;

  appState = appState;
  allHours = Apputils.allHours;

  allTeams: any[] = [];
  allClubs: any[] = [];
  userChildren: any[] = [];

  isBusy = false;

  busy = false;

  chosenType = '';

  formInitiated = false;

  constructor(
    private dialogService: ConfirmDialogService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public restApiService: RestApiService<any>,
    public dialog: MatDialog,
  ) {

    this.uploadUrlService = this.restApiService.service('upload-url');

    this.initRegexValidationMessages();

    const isAdmin = this.appState.user.usr_type === 'superadmin' || this.appState.user.usr_type === 'admin';

    this.profileForm = this.formBuilder.group({
      _id: [''],
      usr_phone: ['', [Validators.required]], //[Validators.required, Validators.pattern(this.regexPatterns.CELLPHONE_REGEX.pattern), Validators.maxLength(13)]],
      usr_code: [''],//, [Validators.required]],
      usr_is_validated: [false],
      usr_fname: ['', (isAdmin ? [] : [Validators.required, Validators.pattern(this.regexPatterns.ONLY_HEBREW_REGEX.pattern)])],
      usr_lname: ['', (isAdmin ? [] : [Validators.required, Validators.pattern(this.regexPatterns.ONLY_HEBREW_REGEX.pattern)])],
      usr_email: ['', (isAdmin ? [Validators.email] : [Validators.required, Validators.email])],
      usr_identification_number: ['', (isAdmin ? [Validators.pattern(this.regexPatterns.ID_REGEX.pattern)] : [Validators.required, Validators.pattern(this.regexPatterns.ID_REGEX.pattern)])],
      usr_birth_date: ['', (isAdmin ? [] : [Validators.required])],
      usr_gender: ['', (isAdmin ? [] : [Validators.required])],
      usr_type: ['player'],
      usr_parent_1_fname: [''],
      usr_parent_1_lname: [''],
      usr_parent_1_type: [''],
      usr_parent_1_phone: [''],
      // usr_parent_1_email: ['', [this.requiredIfValidator(() => (this.profileForm.value.usr_type == 'player' && this.isMinor)), Validators.email]],
      //
      usr_parent_2_fname: [''],
      usr_parent_2_lname: [''],
      usr_parent_2_type: [''],
      usr_parent_2_phone: [''],
      // usr_parent_2_email: ['', [Validators.email]],

      usr_share_reports: [''],
      usr_agreement: [false, (isAdmin ? [] : [Validators.requiredTrue])],
      usr_subscribe: [false],

      usr_profile_pic_asset_arr: this.formBuilder.array([]),

      usr_club_id: [''],
      usr_team_id: [''],

      usr_club_ids: [null],
      usr_team_ids: [null],

      usr_nutri_intro: [''],
      usr_nutri_notes: [null],
      usr_nutri_autofill: [null],

      has_plan: [null],
      reports_left: [null],

      total_reports: [''],
      usr_report_count: [''],

      // usr_is_measurement: [false],
      usr_measurement_date: [''],
      usr_measurement_hour: [''],

      // usr_sessions_done: [''],
      usr_sessions_total: [''],

      usr_session_date: [''],
      usr_session_hour: [''],
      usr_session_type: [''],

      usr_previous_sessions: [null],
      athlete_lvl: [''],
    });

    this.clubsForm = this.formBuilder.group({
      // clubs_arr: this.formBuilder.array([]),
      extra_field_input_clubs: [''],
      extra_field_clubs_options: [''],
    });
    this.clubsForm.get("extra_field_clubs_options").setValue(this.clubsForm.get("extra_field_input_clubs").valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value.name),
        map((name: string) => this._filterClub(this.allClubs, name))
      ));

    this.teamsForm = this.formBuilder.group({
      // teams_arr: this.formBuilder.array([]),
      extra_field_input_teams: [''],
      extra_field_teams_options: [''],
    });
    this.teamsForm.get("extra_field_teams_options").setValue(this.teamsForm.get("extra_field_input_teams").valueChanges
      .pipe(
        startWith(''),
        map((value: any) => typeof value === 'string' ? value : value.name),
        map((name: string) => this._filterTeam(this.allTeams, name, this.clubsForm && this.clubsForm.get("extra_field_input_clubs").value ? this.clubsForm.get("extra_field_input_clubs").value._id : (this.appState.user.usr_type === 'admin' ? this.appState.user.usr_club_id : '')))
      ));

    this.clubsForm.get("extra_field_input_clubs").valueChanges.subscribe(val => {
      const last = this.clubsForm.value.extra_field_input_clubs && this.clubsForm.value.extra_field_input_clubs._id;
      if (val && val._id) {
        // this.teamsForm.get("extra_field_input_teams").setValue(this.teamsForm.get("extra_field_input_teams").value);
        if (last != val._id) {
          this.teamsForm.get("extra_field_input_teams").setValue('');
        } else {
          this.teamsForm.get("extra_field_input_teams").updateValueAndValidity({ onlySelf: true });
        }
      }
    });

    this.route.params.subscribe(async params => {
      let userTeamId = '';
      let userClubId = '';

      if (params.id) {
        this.base_image_upload_folder_path = `profile/usr_assets_${params.id}/`;
        const data = await this.restApiService.service('users').get(params.id);
        if (!data._id) {
          return;
        }

        this.buildAssetsArray(this.profileForm, 'usr_profile_pic_asset_arr', data);
        this.profileForm.patchValue(data);

        this.profileForm.controls.usr_phone.disable();

        if (data.usr_type === 'parent') {
          this.userChildren = await this.fetchChildren(data);
          // console.log(this.userChildren);
        }

        // if (this.appState.user.usr_type !== 'superadmin' && this.appState.user.usr_type !== 'admin') {
        //   // this.appState.user = data;
        //   if (this.translate.currentLang !== 'en') {
        //     if (this.appState.user.usr_gender === 'נקבה') {
        //       this.translate.use('he_F');
        //     } else {
        //       this.translate.use('he');
        //     }            
        //   }
        // }

        if (this.appState.user.usr_type === 'player' && !this.appState.user['usr_confirmed']) {
          this._snackBar.open(this.translate.instant('profile.NOT_ACCESS'), this.translate.instant('profile.CLOSE'), {
            duration: 0,
          });
        }

        const localJSON = window.localStorage.getItem('tmpUsrDetails');
        if (this.appState.user.usr_type === 'player' && localJSON) {
          window.localStorage.removeItem('tmpUsrDetails');
          let local = JSON.parse(localJSON);
          delete local.usr_phone;
          this.profileForm.patchValue(local);
        }

        if (data.usr_type !== 'nutri') {
          userTeamId = data['usr_team_ids'] && data['usr_team_ids'][0];
          userClubId = data['usr_club_id'];
        }

        this.formInitiated = true;
      } else {
        if (this.appState.user.usr_type !== 'superadmin' && this.appState.user.usr_type !== 'admin') {
          const userId = this.appState.user._id;
          this.router.navigate([`Profile/${userId}`]);
          // return;
        }
      }

      if (this.appState.user.usr_type === 'superadmin' || this.appState.user.usr_type === 'player') {
        try {
          this.allClubs = await this.restApiService.service('club').find({
            query: {
              $profilepage: true,
              $paginate: false,
              $select: ['_id', 'clb_name'],
              clb_is_deleted: {
                $ne: true
              },
              $sort: {
                clb_name: 1
              }
            },
          });

          this.allTeams = await this.restApiService.service('team').find({
            query: {
              $profilepage: true,
              $paginate: false,
              $select: ['_id', 'tem_name', 'tem_club_id'],
              tem_is_deleted: {
                $ne: true
              },
              $sort: {
                tem_name: 1
              }
            },
          });

        } catch (e) {
          console.error(e);
          this.allClubs = [];
          this.allTeams = [];
          // delete this.allClubs;
          // delete this.allTeams;
        }

        if (userClubId) {
          const club = this.allClubs.find(e => e && e._id === userClubId);
          if (club) {
            this.clubsForm.get("extra_field_input_clubs").setValue(club);
          }

          if (userTeamId) {
            const team = this.allTeams.find(e => e && e._id === userTeamId);
            if (team) {
              this.teamsForm.get("extra_field_input_teams").setValue(team);
            }
          } else {
            this.teamsForm.get("extra_field_input_teams").setValue(
              {
                tem_name: 'ללא קבוצה',
                _id: '1'
              }
            );
          }
        } else {
          this.clubsForm.get("extra_field_input_clubs").setValue(
            {
              clb_name: 'ללא מועדון',
              _id: '1'
            }
          );
        }

        this.clubsForm.get("extra_field_input_clubs")?.updateValueAndValidity();
        this.teamsForm.get("extra_field_input_teams")?.updateValueAndValidity();
      }

      if (this.appState.user.usr_type === 'admin' && this.appState.user.usr_club_id) {
        try {
          this.allClubs = [];

          const clubId = this.appState.user.usr_club_id;

          this.allTeams = await this.restApiService.service('team').find({
            query: {
              // $profilepage: true,
              $paginate: false,
              $select: ['_id', 'tem_name', 'tem_club_id'],
              // tem_club_id: clubId, // restricted by server
              tem_is_deleted: {
                $ne: true
              },
              $sort: {
                tem_name: 1
              }
            },
          });

        } catch (e) {
          console.error(e);
          this.allClubs = [];
          this.allTeams = [];
          // delete this.allClubs;
          // delete this.allTeams;
        }

        // if (userClubId) {
        //   const club = this.allClubs.find(e => e && e._id === userClubId);
        //   if (club) {
        //     this.clubsForm.get("extra_field_input_clubs").setValue(club);
        //   }

        if (userTeamId) {
          const team = this.allTeams.find(e => e && e._id === userTeamId);
          if (team) {
            this.teamsForm.get("extra_field_input_teams").setValue(team);
          }
        } else {
          this.teamsForm.get("extra_field_input_teams").setValue(
            {
              tem_name: 'ללא קבוצה',
              _id: '1'
            }
          );
        }
        // } else {
        //   this.clubsForm.get("extra_field_input_clubs").setValue(
        //     {
        //       clb_name: 'ללא מועדון',
        //       _id: '1'
        //     }
        //   );
        // }

        this.clubsForm.get("extra_field_input_clubs")?.updateValueAndValidity();
        this.teamsForm.get("extra_field_input_teams")?.updateValueAndValidity();
      }

    });

    this.profileForm.get('usr_birth_date').valueChanges
      .subscribe(value => {
        // this.isMinor = value ? this.getIsMinor(value) : false;

        if (value && this.profileForm.value.usr_type === 'player') {
          const birthDate: moment.Moment = moment(value);
          const nowDate: moment.Moment = moment();
          const years = nowDate.diff(birthDate, "years");
          if (years < 18) {
            this.profileForm.get('usr_parent_1_fname').setValidators([Validators.required, Validators.pattern(this.regexPatterns.ONLY_HEBREW_REGEX.pattern)]);
            this.profileForm.get('usr_parent_1_lname').setValidators([Validators.required, Validators.pattern(this.regexPatterns.ONLY_HEBREW_REGEX.pattern)]);
            this.profileForm.get('usr_parent_1_type').setValidators([Validators.required]);
            this.profileForm.get('usr_parent_1_phone').setValidators([Validators.required, Validators.pattern(this.regexPatterns.CELLPHONE_REGEX.pattern), Validators.minLength(10), Validators.maxLength(13), this.samePhone()]);
            this.profileForm.get('usr_parent_2_fname').setValidators([Validators.pattern(this.regexPatterns.ONLY_HEBREW_REGEX.pattern)]);
            this.profileForm.get('usr_parent_2_lname').setValidators([Validators.pattern(this.regexPatterns.ONLY_HEBREW_REGEX.pattern)]);
            // this.profileForm.get('usr_parent_2_type').setValidators([]);
            this.profileForm.get('usr_parent_2_phone').setValidators([Validators.pattern(this.regexPatterns.CELLPHONE_REGEX.pattern), Validators.minLength(10), Validators.maxLength(13), this.samePhone(), this.sameParentPhone()]);
            this.isMinor = true;
          } else {
            this.profileForm.get('usr_parent_1_fname').clearValidators();
            this.profileForm.get('usr_parent_1_lname').clearValidators();
            this.profileForm.get('usr_parent_1_type').clearValidators();
            this.profileForm.get('usr_parent_1_phone').clearValidators();
            this.profileForm.get('usr_parent_2_fname').clearValidators();
            this.profileForm.get('usr_parent_2_lname').clearValidators();
            this.profileForm.get('usr_parent_2_type').clearValidators();
            this.profileForm.get('usr_parent_2_phone').clearValidators();
            this.isMinor = false;
          }
        } else {
          this.profileForm.get('usr_parent_1_fname').clearValidators();
          this.profileForm.get('usr_parent_1_lname').clearValidators();
          this.profileForm.get('usr_parent_1_type').clearValidators();
          this.profileForm.get('usr_parent_1_phone').clearValidators();
          this.profileForm.get('usr_parent_2_fname').clearValidators();
          this.profileForm.get('usr_parent_2_lname').clearValidators();
          this.profileForm.get('usr_parent_2_type').clearValidators();
          this.profileForm.get('usr_parent_2_phone').clearValidators();
          this.isMinor = false;
        }
        this.profileForm.get('usr_parent_1_fname').updateValueAndValidity({ onlySelf: true });
        this.profileForm.get('usr_parent_1_lname').updateValueAndValidity({ onlySelf: true });
        this.profileForm.get('usr_parent_1_type').updateValueAndValidity({ onlySelf: true });
        this.profileForm.get('usr_parent_1_phone').updateValueAndValidity({ onlySelf: true });
        this.profileForm.get('usr_parent_2_fname').updateValueAndValidity({ onlySelf: true });
        this.profileForm.get('usr_parent_2_lname').updateValueAndValidity({ onlySelf: true });
        this.profileForm.get('usr_parent_2_type').updateValueAndValidity({ onlySelf: true });
        this.profileForm.get('usr_parent_2_phone').updateValueAndValidity({ onlySelf: true });
      }
      );

    this.profileForm.get('usr_gender').valueChanges.subscribe(value => {
      if (this.translate.currentLang !== 'en') {
        if (value === 'נקבה') {
          this.translate.use('he_F');
        } else {
          this.translate.use('he');
        }
      }
    });

    this.profileForm.get('usr_type').valueChanges.subscribe(value => {
      if (value === 'player') {
        this.profileForm.get('usr_profile_pic_asset_arr').setValidators(Validators.required);
      } else {
        this.profileForm.get('usr_profile_pic_asset_arr').clearValidators();
        this.profileForm.get('usr_parent_1_fname').clearValidators();
        this.profileForm.get('usr_parent_1_lname').clearValidators();
        this.profileForm.get('usr_parent_1_type').clearValidators();
        this.profileForm.get('usr_parent_1_phone').clearValidators();
        this.profileForm.get('usr_parent_2_fname').clearValidators();
        this.profileForm.get('usr_parent_2_lname').clearValidators();
        this.profileForm.get('usr_parent_2_type').clearValidators();
        this.profileForm.get('usr_parent_2_phone').clearValidators();
        this.isMinor = false;
      }
      this.profileForm.get('usr_profile_pic_asset_arr').updateValueAndValidity({ onlySelf: true });
      this.profileForm.get('usr_parent_1_fname').updateValueAndValidity({ onlySelf: true });
      this.profileForm.get('usr_parent_1_lname').updateValueAndValidity({ onlySelf: true });
      this.profileForm.get('usr_parent_1_type').updateValueAndValidity({ onlySelf: true });
      this.profileForm.get('usr_parent_1_phone').updateValueAndValidity({ onlySelf: true });
      this.profileForm.get('usr_parent_2_fname').updateValueAndValidity({ onlySelf: true });
      this.profileForm.get('usr_parent_2_lname').updateValueAndValidity({ onlySelf: true });
      this.profileForm.get('usr_parent_2_type').updateValueAndValidity({ onlySelf: true });
      this.profileForm.get('usr_parent_2_phone').updateValueAndValidity({ onlySelf: true });
    });

    this.profileForm.get('usr_parent_2_phone').valueChanges.subscribe(value => {
      if (value && this.profileForm.value.usr_type === 'player') {
        this.profileForm.get('usr_parent_2_fname').setValidators([Validators.required, Validators.pattern(this.regexPatterns.ONLY_HEBREW_REGEX.pattern)]);
        this.profileForm.get('usr_parent_2_lname').setValidators([Validators.required, Validators.pattern(this.regexPatterns.ONLY_HEBREW_REGEX.pattern)]);
        this.profileForm.get('usr_parent_2_type').setValidators([Validators.required]);
        // this.profileForm.get('usr_parent_2_phone').setValidators([Validators.required, Validators.pattern(this.regexPatterns.CELLPHONE_REGEX.pattern), Validators.minLength(10), Validators.maxLength(13), this.samePhone(), this.sameParentPhone()]);
      } else {
        this.profileForm.get('usr_parent_2_fname').clearValidators();
        this.profileForm.get('usr_parent_2_lname').clearValidators();
        this.profileForm.get('usr_parent_2_type').clearValidators();
        // this.profileForm.get('usr_parent_2_phone').clearValidators();
      }
      this.profileForm.get('usr_parent_2_fname').updateValueAndValidity({ onlySelf: true });
      this.profileForm.get('usr_parent_2_lname').updateValueAndValidity({ onlySelf: true });
      this.profileForm.get('usr_parent_2_type').updateValueAndValidity({ onlySelf: true });
      // this.profileForm.get('usr_parent_2_phone').updateValueAndValidity({ onlySelf: true });
    });

    this.profileForm.get('usr_session_date').valueChanges
      .subscribe(value => {
        if (this.appState?.user?.usr_type !== 'superadmin') {
          return;
        }
        if (value) {
          this.profileForm.get('usr_session_hour').setValidators(Validators.required);
          this.profileForm.get('usr_session_type').setValidators(Validators.required);
        } else {
          this.profileForm.get('usr_session_hour').clearValidators();
          this.profileForm.get('usr_session_type').clearValidators();
        }
        this.profileForm.get('usr_session_hour').updateValueAndValidity();
        this.profileForm.get('usr_session_type').updateValueAndValidity();
      });
  }

  // private _filter(array: any, name: string, prefix: string): any {
  //   const filterValue = name.trim().toLowerCase();
  //   return array.filter(obj => String(obj[prefix + '_name']).toLowerCase().indexOf(filterValue) != -1);
  // }

  // displayFn(obj?: any, prefix: string): string | undefined {
  //   return obj ? (obj[prefix + '_name']) : undefined;
  // }

  private _filterTeam(teamsArray: any, name: string, clubId: string): any {
    if (!clubId) {
      return [
        {
          tem_name: 'ללא קבוצה',
          _id: '1'
        }
      ];
    }
    const filterValue = name ? name.trim().toLowerCase() : '';
    // return teamsArray.filter(team => String(team.tem_name).toLowerCase().indexOf(filterValue) != -1 && team.tem_club_id == clubId);
    let res = teamsArray ? teamsArray.filter(team => String(team.tem_name).toLowerCase().indexOf(filterValue) != -1 && team.tem_club_id == clubId) : [];
    res.splice(0, 0, {
      tem_name: 'ללא קבוצה',
      _id: '1'
    });
    return res;
  }

  private _filterClub(clubsArray: any, name: string): any {
    if (!name) {
      let res = this.allClubs.slice();
      res.splice(0, 0,
        {
          clb_name: 'ללא מועדון',
          _id: '1'
        }
      );
      return res;
    }
    const filterValue = name.trim().toLowerCase();
    // return clubsArray.filter(club => String(club.clb_name).toLowerCase().indexOf(filterValue) != -1);
    let res = clubsArray ? clubsArray.filter(club => String(club.clb_name).toLowerCase().indexOf(filterValue) != -1) : [];
    res.push({
      clb_name: 'ללא מועדון',
      _id: '1'
    })
  }

  displayFnTeam(team?: any): string | undefined {
    return team ? (team.tem_name) : undefined;
  }

  displayFnClub(club?: any): string | undefined {
    return club ? (club.clb_name) : undefined;
  }

  /*
  Add key/value pairs to the validationMessages JSON, in order to give the user
  feedback as for the nature of the regex error
  */
  initRegexValidationMessages() {
    Object.keys(this.regexPatterns).map((key) => {
      this.validationMessages.regexErrors[this.regexPatterns[key].pattern] = this.regexPatterns[key].message;
    })
  }

  onUploadDoneAsset(event) {
    console.log("onUploadDoneAsset", event);
  }

  onDeleteAsset(event) {
    console.log("onDeleteAsset", event);
  }

  validateProfileForm() {
    this.profileForm.markAllAsTouched();
    if (!this.profileForm.valid) {
      return {
        valid: false,
        msg: "נא לתקן את השדות האדומים"
      }
    }
    return {
      valid: true,
      msg: "ולידציה עברה בהצלחה"
    }
  }

  async onSubmit(overrideValidation?: boolean) {
    const validateRes = this.validateProfileForm();
    let overrideSuperadminEditExisting = false;
    if (this.appState.user.usr_type === 'superadmin' && this.profileForm.value._id) {
      overrideSuperadminEditExisting = true;
    }
    if (!overrideSuperadminEditExisting && !overrideValidation && !validateRes.valid) {
      this.showStatus(validateRes.msg, null)
      return;
    }

    this.busy = true;

    if (this.appState.user.usr_type !== 'superadmin' && this.appState.user.usr_type !== 'admin') {
      // const validateRes = this.validateProfileForm();
      // if (!overrideValidation && !validateRes.valid) {
      //   this.showStatus(validateRes.msg, null)
      //   return;
      // }
      const control = this.profileForm.get('usr_share_reports');
      const usrType = this.profileForm.value.usr_type;
      if (control && !control.value && (usrType === 'player' || usrType === 'parent')) {
        const result = await this.openShareReportsDialog(usrType);
        if (!result) {
          this.busy = false;
          return;
        }
      }
      const subscribe = this.profileForm.get('usr_subscribe');
      if (subscribe && !subscribe.value) {
        const result = await this.openSubscribeDialog();
        // if (!result) {
        //   return;
        // }
      }
    }

    // if (this.profileForm.value.usr_type === 'player' && !this.isMinor) {
    //   this.resetField('usr_parent_1_fname' ,'');
    //   this.resetField('usr_parent_1_lname' ,'');
    //   this.resetField('usr_parent_1_type' ,'');
    //   this.resetField('usr_parent_1_phone' ,'');
    //   // this.resetField('usr_parent_1_email' ,'');
    //   this.resetField('usr_parent_2_fname' ,'');
    //   this.resetField('usr_parent_2_lname' ,'');
    //   this.resetField('usr_parent_2_type' ,'');
    //   this.resetField('usr_parent_2_phone' ,'');
    //   // this.resetField('usr_parent_2_email' ,'');
    // }

    const { usr_type: formUserType } = this.profileForm.value;
    const { usr_type: appUserType, usr_club_id: appUserClubId } = this.appState.user;
    const extraTeamId = this.teamsForm.value.extra_field_input_teams?._id;
    const extraClubId = this.clubsForm.value.extra_field_input_clubs?._id;

    debugger;
    // Check if form user type is not 'nutri'
    if (formUserType !== 'nutri') {

      // For superadmin or player user types
      if (['superadmin', 'player'].includes(appUserType)) {
        if (extraTeamId) this.profileForm.get('usr_team_id').setValue(extraTeamId);
        if (extraClubId) this.profileForm.get('usr_club_id').setValue(extraClubId);
      }

      // For admin user type with club ID
      if (appUserType === 'admin' && appUserClubId) {
        const formClubId = this.profileForm.value.usr_club_id;

        // Set club ID if empty or matches the app state
        if (!formClubId || formClubId === appUserClubId) {
          this.profileForm.get('usr_club_id').setValue(appUserClubId);

          // Set team ID if user type is player
          if (formUserType === 'player' && !formClubId && extraTeamId) {
            this.profileForm.get('usr_team_id').setValue(extraTeamId);
          }
        }
      }
    }



    this.clearIdsIfNotExistFromArrayItems(this.profileForm.value.usr_profile_pic_asset_arr);

    if (!this.profileForm.value._id) {



      if (formUserType === 'player') {
        delete this.profileForm.value.usr_team_ids
        delete this.profileForm.value.usr_team_id
      }

      if (this.appState.user?.usr_type === 'superadmin') {
        this.submitFormAdminDialog();
      }
      // delete this.profileForm.value._id;
      // this.restApiService.service('users').create(this.profileForm.value).then(res => {
      //   this._snackBar.open('משתמש חדש נוצר, טלפון - ' + res['usr_phone'], null, {
      //     duration: 5000,
      //   });
      //   this.busy = false;
      // }).catch(res => {
      //   if (res.code == 409) {
      //     this._snackBar.open('שגיאה. מספר טלפון כבר קיים במערכת.', null, {
      //       duration: 4000,
      //     });
      //     this.busy = false;
      //     return;
      //   }
      //   if (res.message) {
      //     this.showStatus(res.message, null);
      //     this.busy = false;
      //     return;
      //   }
      // });

    } else {
      if (this.appState.user?.usr_type === 'superadmin') {
        this.submitFormAdminDialog();
      } else {
        const isFirstTime = this.appState.user?.usr_type && this.appState.user?.usr_type !== 'superadmin' && this.appState.user?.usr_type !== 'admin' && !this.appState.user?.usr_agreement && this.profileForm.value?.usr_agreement;
        if (isFirstTime) {
          this.submitForm('patch-first-time', overrideValidation);
        } else {
          const options = {
            title: 'עדכון פרופיל',
            message: 'לעדכן את השינויים בפרופיל?',
            cancelText: this.translate.instant("general.no"),
            confirmText: this.translate.instant("general.yes")
          };
          this.dialogService.open(options);
          this.dialogService.confirmed().subscribe(confirmed => {
            if (confirmed) {
              this.submitForm('patch-u', overrideValidation);
            } else {
              this.busy = false;
              return;
            }
          });
        }

      }
    }

  }

  submitForm(cmd: string, overrideValidation?: boolean) {
    // this.restApiService.service('users').patch(this.profileForm.value._id, this.profileForm.value)
    // this.restApiService.service('actions').create({cmd, cmdData: this.profileForm.value})

    const prmise = cmd === 'patch-first-time' ? this.restApiService.service('users').patch(this.profileForm.value._id, this.profileForm.value) : this.restApiService.service('actions').create({ cmd, cmdData: this.profileForm.value })
    const hasId = Boolean(this.profileForm.value?._id);
    prmise
      .then((res: any) => {
        // error handling
        if (res?.code == 409) {
          this._snackBar.open('שגיאה. מספר טלפון כבר קיים במערכת.', null, {
            duration: 4000,
          });
          this.busy = false;
          return;
        }
        if (res?.message) {
          this.showStatus(res.message, null);
          this.busy = false;
          return;
        }


        if (!res?._id) {
          return;
        }
        if (!hasId) {
          this._snackBar.open('משתמש חדש נוצר, טלפון - ' + res['usr_phone'], null, {
            duration: 5000,
          });
          this.busy = false;

          this.router.navigate([`Profile/${res._id}`]);

          return;
        }
        const lastAgreement = this.appState.user['usr_agreement'];
        const newAgreement = res['usr_agreement'];
        const userConfirmed = res['usr_confirmed'];

        this.buildAssetsArray(this.profileForm, 'usr_profile_pic_asset_arr', res);
        this.profileForm.patchValue(res);

        this.profileForm.controls.usr_phone.disable();

        if (this.appState.user.usr_type !== 'superadmin' && this.appState.user.usr_type !== 'admin') {
          this.appState.user = res;
          // if (this.translate.currentLang !== 'en') {
          //   if (this.appState.user.usr_gender === 'נקבה') {
          //     this.translate.use('he_F');
          //   } else {
          //     this.translate.use('he');
          //   }            
          // }
        }

        if (!overrideValidation) {
          this.showStatus("הפרופיל נשמר בהצלחה", null);
        }

        if (this.appState.user.usr_type === 'player' && userConfirmed && newAgreement && newAgreement != lastAgreement) {
          setTimeout(() => {
            this.router.navigate([`Userprogress`]);
          }, 2000);
          this.busy = false;
          return;
        }
        if (this.appState.user.usr_type === 'parent' && newAgreement && newAgreement != lastAgreement) {
          setTimeout(() => {
            this.router.navigate([`Userparent`]);
          }, 2000);
          this.busy = false;
          return;
        }

        if (res.usr_type !== 'nutri' && (this.appState.user.usr_type === 'superadmin' || this.appState.user.usr_type === 'player')) {

          const userTeamId = res['usr_team_ids'] && res['usr_team_ids'][0];
          const userClubId = res['usr_club_id'];

          if (userClubId) {
            const club = this.allClubs.find(e => e && e._id === userClubId);
            if (club) {
              this.clubsForm.get("extra_field_input_clubs").setValue(club);
            }

            if (userTeamId) {
              const team = this.allTeams.find(e => e && e._id === userTeamId);
              if (team) {
                this.teamsForm.get("extra_field_input_teams").setValue(team);
              }
            } else {
              this.teamsForm.get("extra_field_input_teams").setValue(
                {
                  tem_name: 'ללא קבוצה',
                  _id: '1'
                }
              );
            }
          } else {
            this.clubsForm.get("extra_field_input_clubs").setValue(
              {
                clb_name: 'ללא מועדון',
                _id: '1'
              }
            );
          }

          if (this.teamsForm.value.extra_field_input_teams && this.teamsForm.value.extra_field_input_teams._id) {
            this.profileForm.get('usr_team_id').setValue(this.teamsForm.value.extra_field_input_teams._id);
          }
          if (this.clubsForm.value.extra_field_input_clubs && this.clubsForm.value.extra_field_input_clubs._id) {
            this.profileForm.get('usr_club_id').setValue(this.clubsForm.value.extra_field_input_clubs._id);
          }

        }

        if (res.usr_type !== 'nutri' && this.appState.user.usr_type === 'admin') {

          const userTeamId = res['usr_team_ids'] && res['usr_team_ids'][0];
          const userClubId = res['usr_club_id'];

          if (userClubId) {
            // const club = this.allClubs.find(e => e && e._id === userClubId);
            // if (club) {
            //   this.clubsForm.get("extra_field_input_clubs").setValue(club);
            // }

            if (userTeamId) {
              const team = this.allTeams.find(e => e && e._id === userTeamId);
              if (team) {
                this.teamsForm.get("extra_field_input_teams").setValue(team);
              }
            } else {
              this.teamsForm.get("extra_field_input_teams").setValue(
                {
                  tem_name: 'ללא קבוצה',
                  _id: '1'
                }
              );
            }
            // } else {
            //   this.clubsForm.get("extra_field_input_clubs").setValue(
            //     {
            //       clb_name: 'ללא מועדון',
            //       _id: '1'
            //     }
            //   );
          }

          if (this.teamsForm.value.extra_field_input_teams && this.teamsForm.value.extra_field_input_teams._id) {
            this.profileForm.get('usr_team_id').setValue(this.teamsForm.value.extra_field_input_teams._id);
          }
          // if (this.clubsForm.value.extra_field_input_clubs && this.clubsForm.value.extra_field_input_clubs._id) {
          //   this.profileForm.get('usr_club_id').setValue(this.clubsForm.value.extra_field_input_clubs._id);
          // }
          if (userClubId) {
            this.profileForm.get('usr_club_id').setValue(userClubId);
          }

        }

        this.busy = false;
      })
      .catch(res => {
        if (res?.code == 409) {
          this._snackBar.open('שגיאה. מספר טלפון כבר קיים במערכת.', null, {
            duration: 4000,
          });
          this.busy = false;
          return;
        }
        if (res?.message) {
          this.showStatus(res.message, null);
          this.busy = false;
          return;
        }
      });
  }

  resetField(filedName, value) {
    this.profileForm.controls[filedName].reset(value);
  }

  buildAssetsArray(form: FormGroup, key: string, data: any) {
    if (!form || !key || !data || !data[key]) {
      return;
    }
    const assetsArray: FormArray = form.get(key) as FormArray;
    assetsArray.clear();
    for (let i = 0; i < data[key].length; i++) {
      const newFormElement = this.formBuilder.control({});
      assetsArray.push(newFormElement);
    }
    // assetsArray.patchValue(data[key]);
  }

  clearIdsIfNotExistFromArrayItems(arr: any) {
    const arrLen = arr.length;
    for (let i = 0; i < arrLen; i++) {
      if (String(arr[i]._id).length != 0) {
        continue;
      }

      delete arr[i]._id;
    }
  }

  // createAssetPlaceholder(context) {
  //   return context.formBuilder.control({});
  //   // return context.formBuilder.group({
  //   //   _id: '',
  //   //   url: '',
  //   //   width: '',
  //   //   height: '',
  //   // })
  // }

  getFieldError(fieldName, alwaysShowError?) {
    let formControl = this.profileForm.get(fieldName);
    let errorMessage = '';

    if ((alwaysShowError || formControl.touched || formControl.dirty) && !formControl.valid && formControl.errors) {

      if (formControl.errors.pattern && formControl.errors.pattern.requiredPattern) {
        errorMessage = this.validationMessages.regexErrors[formControl.errors.pattern.requiredPattern];
      } else if (formControl.errors.matDatepickerParse || formControl.errors.matDatepickerMin || formControl.errors.matDatepickerMax) {
        errorMessage = this.validationMessages.matDatepickerParse;
      } else if (formControl.errors.maxlength) {
        errorMessage = this.validationMessages.maxlength;
      } else if (formControl.errors.minlength) {
        errorMessage = this.validationMessages.minlength;
      } else {
        errorMessage = Object.keys(formControl.errors).map(
          key => errorMessage += this.validationMessages[key]).join('');
      }
    }
    return errorMessage;
  }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];

  }

  // getIsMinor(val) {
  //   const now = new Date();
  //   const date = new Date(val);
  //   let diff =(now.getTime() - date.getTime()) / 1000;
  //   diff /= (60 * 60 * 24);
  //   return ((diff/365.25) < 18);
  // }

  // requiredIfValidator(predicate: Function): ValidatorFn | null {
  //   return ((formControl) => {
  //     if (!formControl.parent) {
  //       return null;
  //     }
  //     if (predicate()) {
  //       return Validators.required(formControl); 
  //     }
  //     return null;
  //   })
  // }

  samePhone(): ValidatorFn | null {
    return ((formControl) => {
      if (!formControl.parent || !formControl.value) {
        return null;
      }
      const phoneControl = formControl.parent.controls['usr_phone'];
      if (formControl.value === phoneControl.value) {
        return { samePhone: true };
      }
      return null;
    })
  }

  sameParentPhone(): ValidatorFn | null {
    return ((formControl) => {
      if (!formControl.parent || !formControl.value) {
        return null;
      }
      const phoneControl = formControl.parent.controls['usr_parent_1_phone'];
      if (formControl.value === phoneControl.value) {
        return { sameParentPhone: true };
      }
      return null;
    })
  }

  // updateIsMinor(e) {
  //   const date = e.value;
  //   if (!date) return;
  //   console.log(date);

  //   // const birthDate: moment.Moment = moment(date);
  //   const now: moment.Moment = moment(Date.now());
  //   const years = now.diff(date, "years");
  //   this.isMinor = (years < 18);
  //   this.profileForm.get('usr_parent_1_fname').updateValueAndValidity();
  //   this.profileForm.get('usr_parent_1_lname').updateValueAndValidity();
  //   this.profileForm.get('usr_parent_1_type').updateValueAndValidity();
  //   this.profileForm.get('usr_parent_1_phone').updateValueAndValidity();
  //   this.profileForm.get('usr_parent_1_email').updateValueAndValidity();
  // }

  assetClick(e) {
    this.openImageDialog(e.url, e.url);
  }

  openImageDialog(imgSrc: string, downloadSrc?: string) {
    const options = {
      maxWidth: "90vw",
      panelClass: "p-0-dialog",
      data: {
        imgSrc,
        downloadSrc,
        hideDownload: Boolean(this.appState.user.usr_type !== 'superadmin')
      }
    };
    this.dialog.open(ImageDialogComponent, options);
  }

  openTerms(e) {
    e.preventDefault();
    e.stopPropagation();
    const path = '/assets/terms_of_use.pdf';
    const options = {
      maxWidth: "90vw",
      panelClass: "p-0-dialog",
      data: {
        imgSrc: path,
        downloadSrc: null,
        hideDownload: true,
        isPdf: true
      }
    };
    this.dialog.open(ImageDialogComponent, options);
  }

  async fetchChildren(user) {
    const userId = user._id;
    const userPhone = user.usr_phone;

    if (!userId || !userPhone) return [];
    try {
      const res = await this.restApiService.service('users').find({
        query: {
          $select: ['_id', 'usr_fname', 'usr_lname', 'usr_phone'],
          $sort: {
            usr_birth_date: -1
          },
          _id: {
            $ne: userId
          },
          $or: [
            { usr_parent_1_phone: userPhone },
            { usr_parent_2_phone: userPhone }
          ]
        }
      });
      return res.data;
    } catch (e) {
      console.error(e);
      return [];
    }
  }

  openShareReportsDialog(usrType: string) {
    // const usrType = this.profileForm.value.usr_type;
    const control = this.profileForm.get('usr_share_reports');

    let msg = '';
    if (usrType === 'player') {
      msg = this.translate.instant("profile.ACCEPT_SHARING_PLAYER");
      // msg = 'אני מאשר להעביר את הדוחות שלי אשר יופקו בעזרת האפליקציה אל אגודת הספורט אליה אני משתייך';
    } else if (usrType === 'parent') {
      msg = this.translate.instant("profile.ACCEPT_SHARING_PARENT");
      // msg = 'אני מאשר להעביר את דוחות ילדיי אשר יופקו בעזרת האפליקציה אל אגודת הספורט אליהם הם משתייכים';
    }

    const options = {
      data: {
        title: this.translate.instant("profile.SHARE_TITLE"),
        subtitle: msg,
        accept: this.translate.instant("profile.ACCEPT"),
        not_accept: this.translate.instant("profile.NOT_ACCEPT"),
        value: control.value,
        ok: this.translate.instant('general.ok'),
        cancel: this.translate.instant('general.cancel')
      }
    };
    const dialogRef = this.dialog.open(ShareReportsDialogComponent, options);
    return dialogRef.afterClosed().pipe(
      tap(data => {
        console.log(data);
        if (data) {
          control.setValue(data);
        }
        // control.updateValueAndValidity({ onlySelf: true });
      }),
      first()).toPromise();
  }


  openSubscribeDialog() {
    const control = this.profileForm.get('usr_subscribe');

    const form = this.formBuilder.group({
      subscribe: [Boolean(control.value), [Validators.requiredTrue]]
      // subscribe: [false, [Validators.requiredTrue]]
    });

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      minWidth: '50%',
      data: {
        title: this.translate.instant('profile.SUBSCRIBE_TITLE'),
        //         subtitle: `
        // מומלץ לאפשר למערכת לשלוח הודעות ומיילים. התזכורות מיועדות על מנת לשפר את איכות הליווי התזונתי.
        // לא ישלח תוכן שיווקי בערוצים אלו.
        // תמיד ניתן יהיה לשנות הגדרה זו דרך דף הפרופיל.
        //         `,
        ok: this.translate.instant('profile.SUBSCRIBE_OK'),
        cancel: this.translate.instant('profile.SUBSCRIBE_CANCEL'),
        form: form,
        template: this.subscribeTemplate
      }
    });
    return dialogRef.afterClosed().pipe(
      tap(data => {
        console.log(data);
        if (data) {
          control.setValue(Boolean(data));
        }
        // control.updateValueAndValidity({ onlySelf: true });
      }),
      first()).toPromise();
  }


  async goToPlans() {
    if (this.appState.user.usr_type === 'player' && this.appState.user['usr_confirmed']) {
      if (!this.appState.user.usr_agreement) {
        window.localStorage.setItem('tmpUsrDetails', JSON.stringify(this.profileForm.value));
      }
      // if (this.profileForm.valid && this.profileForm.dirty) {
      //   await this.onSubmit();
      // }
      this.router.navigate([`Plans`]);
    }
  }

  clearClubsAutoCompleteFields() {
    let formArray = this.clubsForm.get("clubs_arr") as FormArray;
    for (let i = 0; i < formArray.controls.length; i++) {
      let clubForm: FormGroup = formArray.at(i) as FormGroup;
      clubForm.get("extra_field_input_teams").setValue("");
      clubForm.get("extra_field_input_admins").setValue("");
    }
  }

  async chooseType(chosenType: string) {
    this.isBusy = true;
    const userId = this.appState.user._id;
    const res = await this.restApiService.service('users').patch(userId, { usr_type: chosenType });
    if (res && res.usr_type === chosenType) {
      this._snackBar.open('סוג משתמש הוגדר בהצלחה', null, {
        duration: 2000,
      });
      this.appState.user = res;
      this.router.navigate([`Profile/${userId}`]);
    } else {
      this._snackBar.open('שגיאה בהגדרת סוג משתמש', 'סגירה', {
        duration: 0,
      });
    }
    this.isBusy = false;

  }

  submitFormAdminDialog() {
    const form = this.profileForm;
    // if (!form?.valid) {
    //   // this.showStatus("נא למלא את השדות הנחוצים", null);
    //   this.showStatus("נא למלא שעה וסוג ייעוץ קרוב", null);
    //   this.busy = false;
    //   return;
    // }
    if (form.value?.usr_session_date && !(form.value?.usr_session_hour && form.value?.usr_session_type)) {
      this.showStatus("נא למלא שעה וסוג ייעוץ קרוב", null);
      this.busy = false;
      return;
    }

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // minWidth: '35%',
      data: {
        title: 'עדכון נתונים',
        subtitle: 'האם לעדכן נתונים ולשלוח הודעה בנוגע לחבילה חדשה או רק לעדכן נתונים ללא שליחת הודעה?',
        cancel: 'ביטול',
        ok: 'עדכן בלבד',
        extraButton: { value: 'notify', text: 'שלח הודעה' },
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        this.busy = false;
        return;
      }
      const cmd = result === 'notify' ? 'patch-u-n' : 'patch-u';
      this.submitForm(cmd);
    });
  }

  sessionIsDoneDialog() {
    const form = this.profileForm;
    if (form?.value?._id) {
      const { _id, usr_session_date, usr_session_hour, usr_session_type } = form.value;
      if (usr_session_date && usr_session_hour && usr_session_type) {
        const options = {
          title: 'האם הייעוץ התקיים?',
          // message: 'האם הייעוץ התקיים?',
          cancelText: this.translate.instant("general.no"),
          confirmText: this.translate.instant("general.yes")
        };
        this.dialogService.open(options);
        this.dialogService.confirmed().subscribe(async confirmed => {
          if (confirmed) {
            await this.sessionIsDone();
          }
        });
      } else {
        this.showStatus("נא למלא תאריך, שעה וסוג ייעוץ קרוב", null);
      }
    }
  }

  async sessionIsDone() {
    const form = this.profileForm;
    if (form?.value?._id) {
      const { _id, usr_session_date, usr_session_hour, usr_session_type } = form.value;
      if (usr_session_date && usr_session_hour && usr_session_type) {
        const res = await this.restApiService.service('actions').create({
          cmd: 'session-is-done', cmdData: {
            _id,
            usr_session_date,
            usr_session_hour,
            usr_session_type,
          }
        });

        console.log(res)
        if (res?._id) {
          form.reset(res);
          this.showStatus("נשמר בהצלחה", null);
          if (res.follow_flag) {
            // this.updateFollow.emit(res);
          }
        }
      } else {
        this.showStatus("נא למלא תאריך, שעה וסוג ייעוץ קרוב", null);
      }
    }
  }
}

