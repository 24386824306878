<div>

    <form novalidate class="basic-form" [formGroup]="bloodtestquizForm" (ngSubmit)="onSubmit(bloodtestquizForm, quizPrefix)">

        <div class="row">
            <h1>{{'bloodtest.QUIZ_TITLE' | translate}}</h1>
            <!-- <button type="button" *ngIf="isOtherUser" class="plus-button" mat-raised-button [routerLink]="['/Nutritionistform/', bloodtestquizForm.value.btq_usr_id]">
                חזור לטופס תזונאי
                <mat-icon>undo</mat-icon>
            </button> -->
            
            <button type="button" *ngIf="appState.user && appState.user.usr_type == 'parent'" class="plus-button" mat-raised-button [routerLink]="['/Userparent']">
                <span>{{'general.undo' | translate}} </span>
                <mat-icon>undo</mat-icon>
            </button>
        </div>

        <mat-card *ngIf="!oldBlood">
            <div class="spinner">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
        </mat-card>

        <mat-card *ngIf="oldBlood && !oldBlood.length">
            <h3 [ngClass]="{ 'required-question': showErrors && bloodtestquizForm.controls['btq_past_tests'].invalid }">{{'bloodtest.Q_1' | translate}}</h3>
            <div class="mb50">
                <mat-radio-group formControlName="btq_past_tests">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>                
            </div>

            <h3 *ngIf="bloodtestquizForm.value.btq_past_tests == 'כן'" [ngClass]="{ 'required-question': showErrors && profileForm.get('usr_blood_known_situation').invalid }">{{'bloodtest.Q_2' | translate}}</h3>
            <div *ngIf="bloodtestquizForm.value.btq_past_tests == 'כן'" class="mb50">
                <mat-radio-group [formControl]="profileForm.get('usr_blood_known_situation')">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <mat-form-field *ngIf="profileForm.value.usr_blood_known_situation == 'כן'" class="w65">
                <input [formControl]="profileForm.get('usr_blood_known_situation_text')" matInput placeholder="{{'general.description' | translate}}">
            </mat-form-field>

            <h3 *ngIf="bloodtestquizForm.value.btq_past_tests == 'כן'" [ngClass]="{ 'required-question': showErrors && profileForm.get('usr_blood_chronic_value').invalid }">{{'bloodtest.Q_3' | translate}}</h3>
            <div *ngIf="bloodtestquizForm.value.btq_past_tests == 'כן'" class="mb50">
                <mat-radio-group [formControl]="profileForm.get('usr_blood_chronic_value')">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>                
            </div>

            <mat-form-field *ngIf="profileForm.value.usr_blood_chronic_value == 'כן'" class="w65">
                <input [formControl]="profileForm.get('usr_blood_chronic_value_text')" matInput placeholder="{{'general.description' | translate}}">
            </mat-form-field>

            <h3 *ngIf="bloodtestquizForm.value.btq_past_tests == 'כן'" [ngClass]="{ 'required-question': showErrors && bloodtestquizForm.controls['btq_has_tests'].invalid }">{{'bloodtest.Q_4' | translate}}</h3>
            <div *ngIf="bloodtestquizForm.value.btq_past_tests == 'כן'" class="mb50">
                <mat-radio-group formControlName="btq_has_tests">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>                
            </div>

            <h3 *ngIf="bloodtestquizForm.value.btq_past_tests == 'כן' && bloodtestquizForm.value.btq_has_tests == 'כן'" [ngClass]="{ 'required-question': showErrors && bloodtestquizForm.controls['btq_test_reason'].invalid }">{{'bloodtest.Q_5' | translate}}</h3>
            <div *ngIf="bloodtestquizForm.value.btq_past_tests == 'כן' && bloodtestquizForm.value.btq_has_tests == 'כן'" class="mb50">
                <mat-radio-group formControlName="btq_test_reason">
                    <mat-radio-button value="בדיקת מעקב שגרתית">{{'bloodtest.Q_5_A_1' | translate}}</mat-radio-button>
                    <mat-radio-button value="סביב בירור רפואי">{{'bloodtest.Q_5_A_2' | translate}}</mat-radio-button>
                    <mat-radio-button value="אחר">{{'bloodtest.Q_5_A_3' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            
            <mat-form-field *ngIf="bloodtestquizForm.value.btq_past_tests == 'כן' && bloodtestquizForm.value.btq_has_tests == 'כן' && (bloodtestquizForm.value.btq_test_reason == 'אחר' || bloodtestquizForm.value.btq_test_reason == 'סביב בירור רפואי')" class="w65">
                <input formControlName="btq_test_reasons_text" matInput placeholder="{{'general.description' | translate}}">
            </mat-form-field>
        </mat-card>

        <mat-card *ngIf="(bloodtestquizForm.value.btq_past_tests === 'כן' && bloodtestquizForm.value.btq_has_tests === 'כן') || (oldBlood && oldBlood.length)">
            <div class="w45">
                <mat-form-field>
                    <input #formDate matInput [matDatepicker]="dpTestdate" placeholder="{{'bloodtest.Q_6' | translate}}" formControlName="btq_test_date">
                    <mat-datepicker-toggle matSuffix [for]="dpTestdate"></mat-datepicker-toggle>
                    <mat-datepicker #dpTestdate></mat-datepicker>
                </mat-form-field>
            </div>

            <h3 *ngIf="oldBlood && oldBlood.length" [ngClass]="{ 'required-question': showErrors && bloodtestquizForm.controls['btq_general_feeling'].invalid }">{{'bloodtest.Q_7' | translate}}</h3>
            <div *ngIf="oldBlood && oldBlood.length" class="mb50">
                <mat-radio-group formControlName="btq_general_feeling">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>                
            </div>

            <mat-form-field *ngIf="bloodtestquizForm.value.btq_general_feeling === 'כן'" class="w65">
                <input formControlName="btq_general_feeling_text" matInput placeholder="{{'bloodtest.Q_8' | translate}}">
            </mat-form-field>

            <h3 [ngClass]="{ 'required-question': showErrors && bloodtestquizForm.controls['btq_assets_arr'].invalid }">{{'bloodtest.Q_9' | translate}}</h3>
            <div>
                <vzn-media-files
                    [uploadFolder]="base_image_upload_folder_path"
                    [maxImageSize]="2000"
                    [width]="'auto'"
                    [height]="'110px'"
                    [assetMargin]="'5px'"
                    [uploadUrlService]="uploadUrlService"
                    [formArrayName]="'btq_assets_arr'"
                    [multiple]="true"
                    [acceptedFiles]="'image/jpeg, image/x-png, image/png, image/webp, application/pdf'"
                    [disabled]="isLocked || isBusy"
                    (onAssetClick)="assetClick($event)"
                    (onUploadStart)="isBusy=true"
                    (onUploadEnd)="isBusy=false"
                >
                </vzn-media-files>
            </div>

            <h3 [ngClass]="{ 'required-question': showErrors && bloodtestquizForm.controls['btq_more_comments'].invalid }">{{'bloodtest.Q_10' | translate}}</h3>
            <div class="mb50">
                <mat-radio-group formControlName="btq_more_comments">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>                
            </div>

            <mat-form-field *ngIf="bloodtestquizForm.value.btq_more_comments === 'כן'" class="w65">
                <input formControlName="btq_more_comments_text" matInput placeholder="{{'general.extraNotes' | translate}}">
            </mat-form-field>
        </mat-card>

        <br>

        <mat-card *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'nutri'">
            <h3 class="text-center">{{'bloodtest.Q_11' | translate}}</h3>
            <div class="w49 m-auto">
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'hem',
                        valueName: 'המוגלובין',
                        unitType: 'g/dl'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'prt',
                        valueName: 'פריטין',
                        unitType: 'ng/ml'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'trn',
                        valueName: 'טרנספרין',
                        unitType: 'mg/dl'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'irn',
                        valueName: 'ברזל',
                        unitType: 'micg/dl'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'b12',
                        valueName: 'B12',
                        unitType: 'pg/dl'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'mag',
                        valueName: 'מגנזיום',
                        unitType: 'mg/dl'
                    }">
                </ng-container>

                <div [formGroup]="bloodtestquizForm.get('btq_blood_values').get('vid')">
                    <div class="m-0 row">
                        <mat-checkbox class="" (change)="checkedFn($event, 'vid', true)" [checked]="bloodtestquizForm.get('btq_blood_values.vid.unit_value').value?.length">
                            וויטמין D
                        </mat-checkbox>
                        <mat-radio-group class="font-16" formControlName="unit_type">
                            <mat-radio-button value="ng/ml">ng/ml</mat-radio-button>
                            <mat-radio-button value="nmol/L">nmol/L</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <mat-form-field>
                        <input type="text" digitOnly decimal="true" formControlName="unit_value" matInput placeholder="וויטמין D">
                        <span matSuffix class="">{{bloodtestquizForm.value.btq_blood_values?.vid?.unit_type}}</span>
                    </mat-form-field>
                </div>
                <div [formGroup]="bloodtestquizForm.get('btq_blood_values').get('pol')">
                    <div class="m-0 row">
                        <mat-checkbox class="" (change)="checkedFn($event, 'pol', true)" [checked]="bloodtestquizForm.get('btq_blood_values.pol.unit_value').value?.length">
                            ח' פולית
                        </mat-checkbox>
                        <mat-radio-group class="font-16" formControlName="unit_type">
                            <mat-radio-button value="ng/ml">ng/ml</mat-radio-button>
                            <mat-radio-button value="nmol/L">nmol/L</mat-radio-button>
                        </mat-radio-group>                        
                    </div>
                    <mat-form-field>
                        <input type="text" digitOnly decimal="true" formControlName="unit_value" matInput placeholder="ח' פולית">
                        <span matSuffix class="">{{bloodtestquizForm.value.btq_blood_values?.pol?.unit_type}}</span>
                    </mat-form-field>
                </div>

                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'mcv',
                        valueName: 'MCV',
                        unitType: 'fl'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'mch',
                        valueName: 'MCH',
                        unitType: 'pg/cell'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'glu',
                        valueName: 'גלוקוז',
                        unitType: 'mg/dl'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'col',
                        valueName: 'כולסטרול',
                        unitType: 'mg/dl'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'tri',
                        valueName: 'טריגליצרידים',
                        unitType: 'mg/dl'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'hdl',
                        valueName: 'HDL',
                        unitType: 'mg/dl'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'ldl',
                        valueName: 'LDL',
                        unitType: 'mg/dl'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'alk',
                        valueName: 'ALKP',
                        unitType: 'u/l'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'a1c',
                        valueName: 'המוגלובין A1C',
                        unitType: '%'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'tsh',
                        valueName: 'TSH',
                        unitType: 'mcU/ml'
                    }">
                </ng-container>
                <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                        fieldName: 'cpk',
                        valueName: 'CPK',
                        unitType: 'mcg/L'
                    }">
                </ng-container>
            </div>
            <div *ngIf="oldBlood && isLocked" class="text-center mt125">
                <button type="button" mat-raised-button color="primary" class="w49" [disabled]="isBusy">{{'bloodtest.SAVE_RESULTS' | translate}}</button>
            </div>
        </mat-card>

        <br>

        <button *ngIf="oldBlood" mat-raised-button color="primary" class="full-width" [disabled]="isLocked || isBusy">{{'general.save' | translate}}</button>
        <div *ngIf="oldBlood && isLocked" class="font-12 text-center text-danger">
            {{'general.savedQuiz' | translate}}
        </div>
        <!-- <div *ngIf="isOtherUser" class="mt125 row">
            <div>
            </div>
            <button type="button" class="plus-button" mat-raised-button [routerLink]="['/Nutritionistform/', bloodtestquizForm.value.btq_usr_id]">
                חזור לטופס תזונאי
                <mat-icon>undo</mat-icon>
            </button>
        </div> -->
    </form>

    <ng-template #bloodValueTemplate let-fieldName="fieldName" let-valueName="valueName" let-unitType="unitType">
        <mat-form-field [formGroup]="bloodtestquizForm.get('btq_blood_values')">
            <mat-checkbox matPrefix class="" (change)="checkedFn($event, fieldName)" [checked]="bloodtestquizForm.get('btq_blood_values').get(fieldName).value?.length"></mat-checkbox>
            <input type="text" digitOnly decimal="true" [formControlName]="fieldName" matInput [placeholder]="valueName">
            <span matSuffix class="">{{unitType}}</span>
        </mat-form-field>
    </ng-template>
</div>
