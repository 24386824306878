<div class="spinner" *ngIf="!initialized">
    <div class="double-bounce1"></div>
    <div class="double-bounce2"></div>
</div>

<div class="user-report" *ngIf="userData && mainData && initialized">
    <div class="fixed-buttons">
        <button type="button" mat-mini-fab color="accent" (click)="openAllPanels()">
            <mat-icon>unfold_more</mat-icon>
        </button>
        &nbsp;
        <button type="button" mat-mini-fab color="accent" (click)="closeAllPanels()">
            <mat-icon>unfold_less</mat-icon>
        </button>
    </div>
    <div class="row preview-row" *ngIf="appState.user && (appState.user.usr_type == 'superadmin' || appState.user.usr_type == 'nutri')">
        <div class="text-center p-10">תצוגה מקדימה של דוח</div>
        <!-- <div>
            <mat-slide-toggle (change)="togglePublish($event)" [disabled]="true">פרסם דוח</mat-slide-toggle>
            <button type="button" mat-raised-button color="primary" (click)="togglePublish(true)">
                פרסם דוח
            </button>
        </div> -->
        <div class="buttons-row m-0 row">
            <mat-slide-toggle [disabled]="busy" [checked]="mainData['_id'] && mainData['rpt_is_published']" (change)="togglePublish($event)">פרסם דוח</mat-slide-toggle>
            <button type="button" class="plus-button" mat-raised-button [routerLink]="['/Nutritionistform/', userData._id]">
                חזור לטופס תזונאי
                <mat-icon>undo</mat-icon>
            </button>
        </div>
    </div>

    <div class="space-row" *ngIf="(appState.user.usr_type == 'superadmin' || appState.user.usr_type == 'nutri')"> </div>

    <div class="row preview-row" *ngIf="(appState.user.usr_type == 'coach' || appState.user.usr_type == 'parent') && userReports.length > 0">
        <div class="p-10 text-center">
            <mat-form-field class="report-dropdown w65">
                <!-- <button type="button" [disabled]="!chosenReport || mainData && mainData._id == chosenReport" matSuffix class="top-button" mat-stroked-button (click)="goToReport($event)">הצג דוח</button> -->
                <mat-select [(ngModel)]="chosenReport" (selectionChange)="goToReport($event.value)">
                    <ng-container *ngFor="let val of userReports">
                        <mat-option [value]="val._id">
                            {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                            {{ mainData._id == val._id ? ' (דוח נוכחי)' : '' }}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
        </div>
    </div>

    <div class="space-row" *ngIf="(appState.user.usr_type == 'coach' || appState.user.usr_type == 'parent') && userReports.length > 0"> </div>

    <div class="header-row">
        <div class="info-container">
            <div class="detail font-bold full-width">
                שם: {{ userData.usr_fname + ' ' + userData.usr_lname }}
            </div>
            <div class="info-container detail">
                <div class="m-l-20 m-b-10 ">
                    ת.ז. {{ userData.usr_identification_number }}
                </div>
                <div class="m-l-20">
                    גיל: {{ nuttest.age_formatted }}
                </div>
            </div>
        </div>
        <div class="logo-container">
            <img src="/assets/images/logo_report.png" />
        </div>
    </div>
    <div class="info-container">
        <div *ngIf="userData.usr_club_name" class="detail">
            מועדון: {{ userData.usr_club_name }}
        </div>
        <div *ngIf="userData.usr_team_name" class="detail">
            קבוצה: {{ userData.usr_team_name }}
        </div>
    </div>

    <!-- <h3>תאריך מדידה: {{ nuttest && nuttest['ntq_test_date'] | date:'dd/MM/yyyy' }}</h3> -->
    <h4>תאריך: {{ mainData['createdAt'] | date:'dd/MM/yyyy' }}</h4>

    <div class="intro-text">
        <div>
            שלום {{ userData.usr_fname }},
        </div>
        <ng-container *ngIf="mainData['intro_text']">
            <div class="pre-wrap">{{ mainData['intro_text'] }}</div>
        </ng-container>
        <ng-container *ngIf="!mainData['intro_text']">
            <div>
                <div>
                    מצורפים הנתונים וההמלצות שהוכנו במיוחד עבורך. 
                </div>
                <div *ngIf="userData.usr_gender!='נקבה'">
                    חשוב לי שתעבור על הדו"ח ושתיישם את כל ההמלצות שלי.
                </div>
                <div *ngIf="userData.usr_gender=='נקבה'">
                    חשוב לי שתעברי על הדו"ח ושתיישמי את כל ההמלצות שלי.
                </div>
            </div>
            <div>
                בהצלחה ממני ומכל צוות <span class="nowrap-text">ALL AROUND PLAYER</span>
            </div>
            <div>
                <div>
                    {{ nutName }}, 
                </div>
                <div>
                    תזונאי קליני
                </div>
            </div>            
        </ng-container>

    </div>

    <mat-card *ngIf="notesMap[7] || notesMap[10] || notesMap[12] || notesMap[14] || notesMap[28]" class="mat-elevation-z4">
        <!-- <div *ngFor="let i of [7, 10, 12, 14, 28]">{{ notesMap[i] }}</div> -->
        <div *ngFor="let i of [7, 10, 12, 14, 28]" [innerHTML]="notesMap[i]"></div>
    </mat-card>

    <mat-accordion
    #accordion="matAccordion" [multi]="true"
    >

        <mat-expansion-panel *ngIf="nuttest">
            <mat-expansion-panel-header>
                <mat-panel-title class="font-18 font-bold">
                    נתוני מדידה והרכב גוף
                </mat-panel-title>
            </mat-expansion-panel-header>
            <h4 class="">תאריך מדידה: {{ nuttest['ntq_test_date'] | date:'dd/MM/yyyy' }}</h4>
            <div class="centered-row">
                <div class="m-l-5 m-r-5">
                    <span>גובה: </span>
                    <span>{{ nuttest['ntq_height'] }}</span>
                    <span> ס"מ</span>
                </div>
                <div class="m-l-5 m-r-5">
                    <span>משקל: </span>
                    <span>{{ nuttest['ntq_weight'] }}</span>
                    <span> ק"ג</span>
                </div>
                <div *ngIf="((nuttest['ntq_fat_percent'] === 'קליפר' || nuttest['ntq_fat_percent'] === 'הזנה ידנית')) && showFatPer " class="m-l-5 m-r-5">
                    <span>אחוז שומן: </span>
                    <span>{{ nuttest['ntq_fat_percent_num'] ? nuttest['ntq_fat_percent_num'] : nuttest['ntq_fat_age'] }}</span>
                    <!-- <span>%</span> -->
                </div>
            </div>
            <div class="row">
                <table class="antropometrics-table">
                    <tr>
                        <th>מידה</th>
                        <th>ערך</th>
                        <th class="font-12" *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ lastNuttest['ntq_test_date'] | date:'dd/MM/yyyy' }}</th>
                        <th *ngIf="lastNuttest && lastNuttest['ntq_test_date']">הפרש</th>
                        <th>סטטוס</th>
                    </tr>
                    <tr *ngIf="+(nuttest.age_formatted || 0) < 20">
                        <td>גובה (ס"מ)</td>
                        <td>{{ nuttest['ntq_height'] }}</td>
                        <td *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ lastNuttest['ntq_height'] }}</td>
                        <td class="dir-ltr" *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ calcDiff(nuttest['ntq_height'], lastNuttest['ntq_height']) }}</td>
                        <td><ng-container *ngTemplateOutlet="statusIcon;context:{status:nuttest['notes_nut_height']}"></ng-container></td>
                    </tr>
                    <tr *ngIf="nuttest['ntq_height_p']">
                        <td>אחוזון גובה</td>
                        <td>{{ nuttest['ntq_height_p'] }}</td>
                        <td *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ lastNuttest['ntq_height_p'] }}</td>
                        <td class="dir-ltr" *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ calcDiff(nuttest['ntq_height_p'], lastNuttest['ntq_height_p']) }}</td>
                        <td><ng-container *ngTemplateOutlet="statusIcon;context:{status:nuttest['notes_nut_height']}"></ng-container></td>
                    </tr>
                    <tr>
                        <td>משקל (ק"ג)</td>
                        <td>{{ nuttest['ntq_weight'] }}</td>
                        <td *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ lastNuttest['ntq_weight'] }}</td>
                        <td class="dir-ltr" *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ calcDiff(nuttest['ntq_weight'], lastNuttest['ntq_weight']) }}</td>
                        <td><ng-container *ngTemplateOutlet="statusIcon;context:{status:nuttest['notes_nut_weight']}"></ng-container></td>
                    </tr>
                    <tr *ngIf="nuttest['ntq_weight_p']">
                        <td>אחוזון משקל</td>
                        <td>{{ nuttest['ntq_weight_p'] }}</td>
                        <td *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ lastNuttest['ntq_weight_p'] }}</td>
                        <td class="dir-ltr" *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ calcDiff(nuttest['ntq_weight_p'], lastNuttest['ntq_weight_p']) }}</td>
                        <td><ng-container *ngTemplateOutlet="statusIcon;context:{status:nuttest['notes_nut_weight']}"></ng-container></td>
                    </tr>
                    <tr>
                        <td>BMI</td>
                        <td>{{ nuttest['ntq_bmi'] }}</td>
                        <td *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ lastNuttest['ntq_bmi'] }}</td>
                        <td class="dir-ltr" *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ calcDiff(nuttest['ntq_bmi'], lastNuttest['ntq_bmi']) }}</td>
                        <td><ng-container *ngTemplateOutlet="statusIcon;context:{status:nuttest['notes_nut_bmi']}"></ng-container></td>
                    </tr>
                    <tr *ngIf="nuttest['ntq_bmi_p']">
                        <td>אחוזון BMI</td>
                        <td>{{ nuttest['ntq_bmi_p'] }}</td>
                        <td *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ lastNuttest['ntq_bmi_p'] }}</td>
                        <td class="dir-ltr" *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ calcDiff(nuttest['ntq_bmi_p'], lastNuttest['ntq_bmi_p']) }}</td>
                        <td><ng-container *ngTemplateOutlet="statusIcon;context:{status:nuttest['notes_nut_bmi']}"></ng-container></td>
                    </tr>
                    <ng-container *ngIf="((nuttest['ntq_fat_percent'] === 'קליפר' || nuttest['ntq_fat_percent'] === 'הזנה ידנית')) && showFatPer">
                        <tr>
                            <td>אחוז שומן (%)</td>
                            <td>{{ nuttest['ntq_fat_percent_num'] ? nuttest['ntq_fat_percent_num'] : nuttest['ntq_fat_age'] }}</td>
                            <td *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ lastNuttest['ntq_fat_percent_num'] ? lastNuttest['ntq_fat_percent_num'] : lastNuttest['ntq_fat_age'] }}</td>
                            <td class="dir-ltr" *ngIf="lastNuttest && lastNuttest['ntq_test_date']">
                                {{ calcDiff((nuttest['ntq_fat_percent_num'] ? nuttest['ntq_fat_percent_num'] : nuttest['ntq_fat_age']), (lastNuttest['ntq_fat_percent_num'] ? lastNuttest['ntq_fat_percent_num'] : lastNuttest['ntq_fat_age'])) }}
                            </td>
                            <td><ng-container *ngTemplateOutlet="statusIcon;context:{status:nuttest['notes_nut_fat']}"></ng-container></td>
                        </tr>
                        <tr *ngIf="nuttest['ntq_skinny_mass']">
                            <td>מסה רזה (ק"ג)</td>
                            <td>{{ nuttest['ntq_skinny_mass'] }}</td>
                            <td *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ lastNuttest['ntq_skinny_mass'] }}</td>
                            <td class="dir-ltr" *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ calcDiff(nuttest['ntq_skinny_mass'], lastNuttest['ntq_skinny_mass']) }}</td>
                            <td><ng-container *ngTemplateOutlet="statusIcon; context: { status: nuttest['notes_nut_skinny_mass'] }"></ng-container></td>
                        </tr>
                        <tr *ngIf="nuttest['ntq_fat_mass']">
                            <td>מסת שומן (ק"ג)</td>
                            <td>{{ nuttest['ntq_fat_mass'] }}</td>
                            <td *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ lastNuttest['ntq_fat_mass'] }}</td>
                            <td class="dir-ltr" *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ calcDiff(nuttest['ntq_fat_mass'], lastNuttest['ntq_fat_mass']) }}</td>
                            <td><ng-container *ngTemplateOutlet="statusIcon; context: { status: nuttest['notes_nut_fat_mass'] }"></ng-container></td>
                        </tr>
                    </ng-container>
                    <tr *ngIf="nuttest['ntq_wingspan']">
                        <td>מוטת ידיים (ס"מ)</td>
                        <td>{{ nuttest['ntq_wingspan'] }}</td>
                        <td *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ lastNuttest['ntq_wingspan'] }}</td>
                        <td class="dir-ltr" *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ calcDiff(nuttest['ntq_wingspan'], lastNuttest['ntq_wingspan']) }}</td>
                        <td><ng-container *ngTemplateOutlet="statusIcon; context: { status: nuttest['notes_nut_wingspan'] }"></ng-container></td>
                    </tr>
                    <tr *ngIf="nuttest['ntq_fat_sf']">
                        <td>סכום קפלי עור</td>
                        <td>{{ nuttest['ntq_fat_sf'] }}</td>
                        <td *ngIf="lastNuttest && lastNuttest['ntq_fat_sf']">{{ lastNuttest['ntq_fat_sf'] }}</td>
                        <td class="dir-ltr" *ngIf="lastNuttest && lastNuttest['ntq_test_date']">{{ calcDiff(nuttest['ntq_fat_sf'], lastNuttest['ntq_fat_sf']) }}</td>
                        <td><ng-container *ngTemplateOutlet="statusIcon; context: { status: nuttest['notes_nut_fat'] }"></ng-container></td>
                    </tr>
                </table>
            </div>
            
            <mat-card *ngIf="nuttest['ntq_height_p_label'] || nuttest['ntq_weight_p_label'] || nuttest['ntq_bmi_p_label']" class="mat-elevation-z4">
                <div *ngIf="nuttest['ntq_height_p_label']">
                    {{ nuttest['ntq_height_p_label'] }}
                </div>
                <div *ngIf="nuttest['ntq_weight_p_label']">
                    {{ nuttest['ntq_weight_p_label'] }}
                </div>
                <div *ngIf="nuttest['ntq_bmi_p_label']">
                    {{ nuttest['ntq_bmi_p_label'] }}
                </div>
            </mat-card>

            <ng-container *ngIf="notesMap[2]">
                <h4 class="ul-text">הערות מדידה והרכב גוף</h4>
                <mat-card class="mat-elevation-z4">
                    <!-- <div>{{ notesMap[2] }}</div> -->
                    <div [innerHTML]="notesMap[2]"></div>
                </mat-card>
            </ng-container>

            <div class="m-auto m-t-40" style="position: relative;" *ngIf="fatValidityObj && fatValidityStyle && showFatPer">
                <ul class="chart-fat">
                    <li [style]="fatValidityStyle.a">
                        <!-- <span [style]="fatValidityStyle.a">תת שומן</span> -->
                        <div [style]="fatValidityStyle.a">{{ fatValidityObj.a }}</div>
                    </li>
                    <li [style]="fatValidityStyle.b">
                        <!-- <span [style]="fatValidityStyle.b">תקין-בריא</span> -->
                        <div [style]="fatValidityStyle.b">{{ fatValidityObj.b }}</div>
                    </li>
                    <li [style]="fatValidityStyle.c">
                        <!-- <span [style]="fatValidityStyle.c">עודף שומן</span> -->
                        <div [style]="fatValidityStyle.c">{{ fatValidityObj.c }}</div>
                    </li>
                    <li style="--percentage : 100;">
                        <!-- <span style="--percentage : 100;">השמנה</span> -->
                        <div style="--percentage : 100;">{{ fatValidityObj.d }}</div>
                    </li>
                </ul>
                <div class="fat-pointer" [style]="fatValidityStyle.fat"><div>{{ fatValidityObj.fat }}</div></div>
            </div>
            <div class="chart-fat-guide mat-elevation-z3" *ngIf="fatValidityObj && fatValidityStyle && showFatPer">
                <div class="color-box a"></div>
                <div class="label-box">
                    תת שומן
                </div>
                <div class="color-box b"></div>
                <div class="label-box">
                    תקין-בריא
                </div>
                <div class="color-box c"></div>
                <div class="label-box">
                    עודף שומן
                </div>
                <div class="color-box d"></div>
                <div class="label-box">
                    השמנה
                </div>
            </div>
        </mat-expansion-panel>
        
        <charts-section [user]="userData" [nuttest]="nuttest"></charts-section>

        <mat-expansion-panel *ngIf="practice && (practice.prq_show_calories || notesMap[3])">
            <mat-expansion-panel-header>
                <mat-panel-title class="font-18 font-bold">
                    {{ practice.prq_show_calories ? 'אימונים ומאזן אנרגיה (קלוריות)' : 'אימונים' }}
                </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-container *ngIf="practice.prq_show_calories">

            <div>
                
                <table class="energy-table">
                <tr>
                    <td>חילוף חומרים במנוחה</td>
                    <td>{{ practice['prq_daily_rmr'] }}</td>
                </tr>
                <tr>
                    <td>הוצאה בתוספת פעילות יומיומית ללא אימון</td>
                    <td>{{ practice['prq_daily_no_practice'] }}</td>
                </tr>
                <tr>
                    <td>הוצאה יומית ממוצעת עבור האימונים</td>
                    <td>{{ practice['prq_daily_kcal'] || '0' }}</td>
                </tr>
                <tr>
                    <td>הוצאה קלורית כוללת</td>
                    <td>{{ practice['prq_total_daily_kcal'] }}</td>
                </tr>
                <tr>
                    <td>מאזן רצוי</td>
                    <td class="dir-ltr">{{ practice && practice['notes_prq_c'] ? (practice['notes_prq_c']==="0" ? 'מאוזן' : practice['notes_prq_c']) : '' }}</td>
                </tr>
                <tr>
                    <td>הכנסה קלורית רצויה</td>
                    <td>{{ practice['prq_total_kcal'] }}</td>
                </tr>
                </table>

            </div>
            <div class="hard-note">
                <span class="font-bold">חילוף חומרים במנוחה</span>
                 הינו מדד המשקף את כמות האנרגיה הנחוצה לגוף לשם תפקוד בסיסי כמות האנרגיה שהגוף ישרוף ב- 24 שעות של מנוחה מוחלטת בהתאם לנתוני המדידה האישיים.
            </div>
            <div class="hard-note">
                <span class="font-bold">הוצאה קלורית כוללת</span>
                 הינה ההוצאה במנוחה + ההוצאה עבור הפעיליות היומיומיות + ההוצאה הקלורית של אימון גופני.
            </div>

            </ng-container>

            <mat-card *ngIf="notesMap[3]" class="mat-elevation-z4">
                <!-- <div>{{ notesMap[3] }}</div> -->
                <div [innerHTML]="notesMap[3]"></div>
            </mat-card>
            
            <ng-container *ngIf="notesMap[4] || notesMap[9] || notesMap[36]">
                <h4 class="ul-text">עיתוי אכילה וצריכה קלורית סביב ובמהלך האימון</h4>
                <mat-card class="mat-elevation-z4">
                    <!-- <div *ngFor="let i of [4, 9, 36]">{{ notesMap[i] }}</div> -->
                    <div *ngFor="let i of [4, 9, 36]" [innerHTML]="notesMap[i]"></div>
                </mat-card>
            </ng-container>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="sleeping && (notesMap[6] || (sleeping['slq_weekday_hours'] && sleeping['slq_weekend_hours']))">
            <mat-expansion-panel-header>
                <mat-panel-title class="font-18 font-bold">
                    הרגלי שינה
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="sleeping['slq_weekday_hours']" class="status-row hard-note">
                    <div class="basis10">
                        <mat-icon class="status-icon valid" *ngIf="sleeping['slq_weekday_boolean']===true">check</mat-icon>
                        <mat-icon class="status-icon invalid" *ngIf="sleeping['slq_weekday_boolean']===false">priority_high</mat-icon>
                    </div>
                    <div class="basis90">
                        <div>
                            שעות שינה אמצע שבוע: {{ sleeping['slq_weekday_sleeping_time'] + '-' + sleeping['slq_weekday_wakeup_time'] }}
                        </div>
                        <div>
                            סה"כ <span class="font-bold">{{ sleeping['slq_weekday_hours'] }}</span> שעות
                        </div>
                    </div>
            </div>
            <div *ngIf="sleeping['slq_weekend_hours']" class="status-row hard-note">
                    <div class="basis10">
                        <mat-icon class="status-icon valid" *ngIf="sleeping['slq_weekend_boolean']===true">check</mat-icon>
                        <mat-icon class="status-icon invalid" *ngIf="sleeping['slq_weekend_boolean']===false">priority_high</mat-icon>
                    </div>
                    <div class="basis90">
                        <div>
                            שעות שינה סוף שבוע: {{ sleeping['slq_weekend_sleeping_time'] + '-' + sleeping['slq_weekend_wakeup_time'] }}
                        </div>
                        <div>
                            סה"כ <span class="font-bold">{{ sleeping['slq_weekend_hours'] }}</span> שעות
                        </div>
                    </div>
            </div>
            <!-- <div class="hard-note">
                <div>
                    שעות שינה אמצע שבוע: {{ sleeping ? (sleeping['slq_weekday_sleeping_time'] + '-' + sleeping['slq_weekday_wakeup_time']) : '' }}
                </div>
                <div>
                    סה"כ <span class="font-bold">{{ sleeping && sleeping['slq_weekday_hours'] }}</span> שעות
                </div>
            </div>
            <div class="hard-note">
                <div>
                    שעות שינה סוף שבוע: {{ sleeping ? (sleeping['slq_weekend_sleeping_time'] + '-' + sleeping['slq_weekend_wakeup_time']) : '' }}
                </div>
                <div>
                    סה"כ <span class="font-bold">{{ sleeping && sleeping['slq_weekend_hours'] }}</span> שעות
                </div>
            </div> -->
            
            <mat-card *ngIf="notesMap[6]" class="mat-elevation-z4">
                <!-- <div>{{ notesMap[6] }}</div> -->
                <div [innerHTML]="notesMap[6]"></div>
            </mat-card>
            
            <div class="hard-note">
                <!-- <span class="font-bold"></span> -->
                מספר שעות השינה המומלץ מתייחס לשנת לילה רצופה ולא למספר שעות השינה ביממה. כלומר, שנת צהריים לא מחושבת בספירת שעות השינה. 
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="bloodtest && isBloodtestNotes">
            <mat-expansion-panel-header>
                <mat-panel-title class="font-18 font-bold">
                    בדיקות דם
                </mat-panel-title>
                <mat-panel-description *ngIf="bloodtest['btq_test_date']" class="font-14 font-bold">
                    תאריך בדיקות הדם - {{ bloodtest['btq_test_date'] | date:'dd/MM/yyyy' }}
                </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="hard-note">
                ההתייחסות לבדיקות הדם בתזונת הספורט לא תמיד תואמת את ההתייחסות של קופות החולים. 
            </div>
            <div class="hard-note">
                יש לזכור שקופות החולים מתיחסות למצבי חולי ולמניעתם. לעומת זאת, תזונת הספורט עוסקת בנוסף על כך, בתפקוד מירבי ומיצוי הפוטנציאל של הספורטאי.
            </div>
            
            <mat-card class="mat-elevation-z4">
                <!-- <div *ngFor="let i of [29, 20, 21, 22, 23, 24, 25, 26, 27]">{{ notesMap[i] }}</div> -->
                <div *ngFor="let i of [29, 20, 21, 22, 23, 24, 25, 26, 27]" [innerHTML]="notesMap[i]"></div>
            </mat-card>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="fooddiary">
            <mat-expansion-panel-header>
                <mat-panel-title class="font-18 font-bold">
                    נוזלים
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="hard-note">
                <!-- <span class="font-bold"></span> -->
                חשוב להקפיד על שתיית מים מספקת- התייבשות מעלה סיכון לפציעות, פוגעת ביכולת הגופניות, המנטליות והקוגניטיביות.
            </div>
            <div *ngIf="fluidsRcmd" class="hard-note">
                על פי המדדים שלך {{ userData.usr_fname }}, יש להקפיד על שתיה של כ-<span class="font-bold">{{ fluidsRcmd }} ל'</span> ליום ללא אימון + תוספת במהלך האימון.
            </div>
            <!-- <div *ngIf="fluidsRange" class="hard-note">
                <div>
                    במהלך אימון של 90 דק, יש לשתות בין <span class="font-bold">{{ fluidsRange }} ל'</span>
                </div>
                <div>
                    מומלץ לשתות בהתאם לצורך, בכל הפסקה שניתנת במהלך האימון או המשחק.
                </div>
            </div> -->
            <div class="hard-note">
                <div>
                    במהלך אימון או משחק, מומלץ לשתות בהתאם לצורך. 
                </div>
                <div>
                    רצוי לנצל את ההפסקות בכדי להחזיר נוזלים.
                </div>
            </div>
            <div class="hard-note m-b-10">
                <!-- <span class="font-bold"></span> -->
                שתן שקוף עד בהיר (שלושת המשבצות הימניות בתרשים) הוא המדד הטוב ביותר לצריכת נוזלים נאותה.
            </div>
            <div class="status-row">
                <div class="basis10">
                    <mat-icon class="status-icon valid" *ngIf="(darkestUrineIndex > -1 && 3 > darkestUrineIndex)">check</mat-icon>
                    <mat-icon class="status-icon invalid" *ngIf="darkestUrineIndex > 2">priority_high</mat-icon>
                </div>
                <div class="basis90">
                    <mat-grid-list [cols]="urineHexColorPlate.length" rowHeight="3em">
                        <mat-grid-tile *ngFor="let urineColor of urineHexColorPlate; let i = index;"
                        [colspan]="1"
                        [rowspan]="1"
                        [style.padding]="'4px'"
                        [style.border]="urineHexColorPlate[darkestUrineIndex] == urineColor ? '2.5px solid red' : '2px solid blue'"
                        [style.background]="urineColor">
                        </mat-grid-tile>
                    </mat-grid-list>
                </div>
            </div>

            <div class="fluids-note">
                * הריבוע המסומן משקף את צבע השתן הכהה ביותר שסימנת ביומן האכילה והלו"ז
            </div>

            <mat-card *ngIf="notesMap[38]" class="mat-elevation-z4">
                <!-- <div>{{ notesMap[38] }}</div> -->
                <div [innerHTML]="notesMap[38]"></div>
            </mat-card>
            
            <div class="hard-note">
                <!-- <span class="font-bold"></span> -->
                מומלץ להרבות בשתיית מים/סודה ולהמעיט ככל האפשר בשתיה ממותקת.
            </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="isNutritionNotes">
            <mat-expansion-panel-header>
                <mat-panel-title class="font-18 font-bold">
                    המלצות תזונה
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div>

            </div>

            <ng-container *ngIf="notesMap[8] || notesMap[11] || notesMap[13] || notesMap[15] || notesMap[16] || notesMap[17] || notesMap[18] || notesMap[19] || notesMap[37]">
                <h4 class="ul-text">המלצות כלליות</h4>            
                <mat-card class="mat-elevation-z4">
                    <!-- <div *ngFor="let i of [8, 11, 13, 15, 16, 17, 18, 19, 37]">{{ notesMap[i] }}</div> -->
                    <div *ngFor="let i of [37, 8, 11, 13, 15, 16, 17, 18, 19]" [innerHTML]="notesMap[i]"></div>
                </mat-card>
            </ng-container>

            <ng-container *ngIf="notesMap[4] || notesMap[9] || notesMap[36]">
                <h4 class="ul-text">עיתוי אכילה וצריכה קלורית סביב ובמהלך האימון</h4>
                <mat-card class="mat-elevation-z4">
                    <!-- <div *ngFor="let i of [4, 9, 36]">{{ notesMap[i] }}</div> -->
                    <div *ngFor="let i of [4, 9, 36]" [innerHTML]="notesMap[i]"></div>
                </mat-card>
            </ng-container>
            
            <ng-container *ngIf="notesMap[30]">
                <h4 class="ul-text">ארוחת בוקר</h4>            
                <mat-card class="mat-elevation-z4">
                    <!-- <div>{{ notesMap[30] }}</div> -->
                    <div [innerHTML]="notesMap[30]"></div>
                </mat-card>
            </ng-container>
            
            <ng-container *ngIf="notesMap[31]">
                <h4 class="ul-text">ארוחת ביניים / כריכים לבית הספר</h4>            
                <mat-card class="mat-elevation-z4">
                    <!-- <div>{{ notesMap[31] }}</div> -->
                    <div [innerHTML]="notesMap[31]"></div>
                </mat-card>
            </ng-container>

            <ng-container *ngIf="notesMap[32]">
                <h4 class="ul-text">ארוחת צהריים</h4>            
                <mat-card class="mat-elevation-z4">
                    <!-- <div>{{ notesMap[32] }}</div> -->
                    <div [innerHTML]="notesMap[32]"></div>
                </mat-card>
            </ng-container>

            <ng-container *ngIf="notesMap[33]">
                <h4 class="ul-text">ארוחת ביניים</h4>            
                <mat-card class="mat-elevation-z4">
                    <!-- <div>{{ notesMap[33] }}</div> -->
                    <div [innerHTML]="notesMap[33]"></div>
                </mat-card>
            </ng-container>
            
            <ng-container *ngIf="notesMap[34]">
                <h4 class="ul-text">ארוחת ערב</h4>            
                <mat-card class="mat-elevation-z4">
                    <!-- <div>{{ notesMap[34] }}</div> -->
                    <div [innerHTML]="notesMap[34]"></div>
                </mat-card>
            </ng-container>
            
            <ng-container *ngIf="notesMap[35]">
                <h4 class="ul-text">ארוחת לילה</h4>            
                <mat-card class="mat-elevation-z4">
                    <!-- <div>{{ notesMap[35] }}</div> -->
                    <div [innerHTML]="notesMap[35]"></div>
                </mat-card>
            </ng-container>

        </mat-expansion-panel>

        <div class="report-footer">
            <div>
                בהצלחה!
            </div>
            <img src="/assets/images/logo_report.png" />
        </div>
    </mat-accordion>

</div>

<ng-template #statusIcon let-status="status">
    <mat-icon class="status-icon valid" *ngIf="status=='תקין'">check</mat-icon>
    <mat-icon class="status-icon invalid" *ngIf="status=='לא תקין'">priority_high</mat-icon>
</ng-template>
