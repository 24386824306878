import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { appState } from '@services/app-state';
import { Apputils } from '@services/app-utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { allCategoriesGroups, breakfastCategories, elevensesCategories, lunchCategories, teaCategories, dinnerCategories, nightCategories } from '../../../nutquiz/categoriesData';

@Component({
  selector: 'nutrition-section',
  templateUrl: "./nutrition.component.html"
})
export class NutritionComponent {
  @Output() addNoteEvent: EventEmitter<any> = new EventEmitter();
  @Output() editNoteEvent: EventEmitter<any> = new EventEmitter();
  
  @Output() busyState: EventEmitter<boolean> = new EventEmitter();
  @Output() updateFollow: EventEmitter<any> = new EventEmitter();
  
  @Input() user: any;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onNew: EventEmitter<any> = new EventEmitter();
  
  // @Input() allNotes = [];
  @Output() linkedNotesEvent: EventEmitter<any> = new EventEmitter();
  @Input() notesList = [];

  @Input() sharedNotesFormA: FormGroup;
  // @Input() sharedNotesFormB: FormGroup;
  @Input() allFdqValues: any;

  @Input() oldNotesFlag?: boolean;

  // @Input() userWeight?: number;
  @Input()
  set userWeight(val: number) {
    this.userOptimalFluidsLiterNoPractice = val ? Apputils.roundToQuarter((val * 30) / 1000) : 0;
  }

  @Input() drinkInPractice?: string;

  allCategoriesGroups = allCategoriesGroups;

  // breakfastCategories = breakfastCategories;
  // elevensesCategories = elevensesCategories;
  // lunchCategories = lunchCategories;
  // teaCategories = teaCategories;
  // dinnerCategories = dinnerCategories;
  // nightCategories = nightCategories;

  urineHexColorPlate = Apputils.urineHexColorPlate;

  // allOptionsIngredients = {
  //   protein: [],
  //   carbs: [],
  //   fat: [],
  //   veg: [],
  //   fruits: [],
  //   sweets: [],
  //   snacks: [],
  //   sauce: [],
  //   powder: [],
  //   drink: [],
  // };

  allOptionsIngredients = {
    protein: [],
    carbs: [],
    fat: [],
    veg: [],
    fruits: [],
    sweets: [],
    snacks: [],
    sauce: [],
    powder: [],
    drink: [],
  };

  nutcategories: any[] = [];
  // nutingredients: any[] = [];
  // allProducts: any[] = [];

  userOptimalFluidsLiterNoPractice = 0;
  
  quizPrefix = 'nuq';

  notesFormA: FormGroup;
  notesFormB: FormGroup;

  oldValues = [];

  selectedIdA: string;
  selectedIdB: string;

  selectedA: any;
  selectedB: any;

  restService: any;

  appState = appState;

  otherFilter = (item: string) => item !== 'אחר'; 

  isPickyEater: boolean | undefined = undefined;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public restApiService: RestApiService<any>
  ) {

    // this.restService = this.restApiService.service('nutritionquiz');
    this.restService = this.restApiService.service('nutquiz');

    this.notesFormA = this.createNewForm();
    this.notesFormB = this.createNewForm();
  }

  async newQuizGuard() {
    if (this.isPickyEater === undefined) {
      this._snackBar.open('יש לבחור האם המשתמש בררן או לא', '', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
      return;
    }
    this.onNew.emit(() => { this.newQuiz() });
  }
  
  async updatePickyEaterStatus() {
    if (this.isPickyEater === undefined || !this.selectedIdA) {
      return;
    }
    
    try {
      await this.restService.patch(this.selectedIdA, {
        isPickyEater: this.isPickyEater
      });
      this._snackBar.open('סוג אכילה עודכן בהצלחה', '', {
        duration: 2000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    } catch (e) {
      console.error('Error updating picky eater status:', e);
      this._snackBar.open('שגיאה בעדכון סוג אכילה', '', {
        duration: 3000,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });
    }
  }

  async newQuiz() {
    await this.restService.create({
      nuq_usr_id: this.user._id,
      nuq_form_is_real: true,
      isPickyEater: this.isPickyEater
    });
    this.fetchOldValues(true);
  }

  async deleteQuiz(quizId: string) {
    this.onDelete.emit(() => { this.deleteOk(quizId) });
  }

  async deleteOk(quizId: string) {
    await this.restService.delete(quizId);
    this.fetchOldValues(true);
  }

  async toggleQuizLock(event: MatSlideToggleChange, selected: FormGroup) {
    const lockedField = 'nuq_locked';
    if (selected && selected.value && selected.value._id) {
      const res = await this.restService.patch(selected.value._id, {
        [lockedField]: event.checked
      });
      if (res && res[lockedField] === event.checked) {
        event.source.checked = res[lockedField];
        selected.controls[lockedField].reset(res[lockedField]);
        return;
      }
    }
    event.source.checked = !event.checked;
  }

  async fetchOldValues(reset?: boolean, silent?: boolean) {
    
    try {
      let res = await this.restService.find({ query: {   
        // $limit: 1,
        $paginate: false,
        // $select: [ '_id', 'createdAt', 'nuq_usr_id' ],
        nuq_usr_id: this.user._id,
        nuq_form_is_real: true,
        $sort: {
          createdAt: -1
        }
      } });

      // let filteredData = res.filter((element) => {
      //   return element.nuq_usr_id != undefined;
      // });

      console.log(res);
      // this.oldValues = filteredData;
      this.oldValues = res;

      if(reset){
        await this.resetToLastTwo(silent);
      }

    } catch (e) {
      console.error(e);
    }
  }

  async resetToLastTwo(silent?: boolean) {
    const values = this.oldValues;
    if (values.length === 0) {
      delete this.selectedIdA;
      delete this.selectedA;
      this.notesFormA.reset();
      delete this.selectedIdB;
      delete this.selectedB;
      this.notesFormB.reset();
      return;
    }
    if (values.length === 1) {
      delete this.selectedIdB;
      delete this.selectedB;
      this.notesFormB.reset();
    }
    // delete this.selectedIdA;
    if (values[0] && values[0]._id && values[0]._id !== this.selectedIdA) {
      delete this.selectedA;
      this.notesFormA.reset();
      this.selectedIdA = values[0]._id;
      await this.selectDateA(this.selectedIdA);
    }
    if (silent) {
      return;
    }
    // delete this.selectedIdB;
    if (values[1] && values[1]._id && values[1]._id !== this.selectedIdB) {
      delete this.selectedB;
      this.notesFormB.reset();
      this.selectedIdB = values[1]._id;
      await this.selectDateB(this.selectedIdB);
    }
  }

  async ngOnInit() {
    await this.fetchOldValues(true);
    // await this.getCategoriesData();
    await this.getAllData();
    if (this.selectedA) {
      this.selectedA = this.calcIngredients(this.selectedA);
    }
    if (this.selectedB) {
      this.selectedB = this.calcIngredients(this.selectedB);
    }
  }

  resetNotes(notesGroup: FormGroup, dataNotes) {
    for (const noteKey in notesGroup.value) {
      let notes: FormArray = notesGroup.get(noteKey) as FormArray;
      notes.clear();
      if (dataNotes && dataNotes[noteKey]) {
        for (const n of dataNotes[noteKey]) {
          notes.push(new FormControl());
        }
      }
    }
  }

  refreshFormA(value) {
    const notesGroup = this.notesFormA.get('nuq_notes') as FormGroup;
    this.resetNotes(notesGroup, value['nuq_notes']);
    this.notesFormA.reset(value);
    // this.calcIngredients(value);
    // this.selectedA = value;
    const selectedA = this.calcIngredients(value);
    this.selectedA = selectedA;
    
    // עדכון ערך isPickyEater
    this.isPickyEater = value.isPickyEater;
  }

  refreshFormB(value) {
    const notesGroup = this.notesFormB.get('nuq_notes') as FormGroup;
    this.resetNotes(notesGroup, value['nuq_notes']);
    this.notesFormB.reset(value);
    // this.calcIngredients(value);
    // this.selectedB = value;
    const selectedB = this.calcIngredients(value);
    this.selectedB = selectedB;
  }

  async selectDateA(newVal){
    // const data = await this.restService.get(newVal).catch(e => { console.error(e) });
    // const res = await this.restService.find({
    //   query: {
    //     $nutri: true,
    //     $limit: 1,
    //     _id: newVal,
    //   },
    // }).catch(e => { console.error(e) });
    // const data = res?.data[0];
    const data = this.oldValues.find(val => val._id === newVal);
    if (data) {
      this.notesFormA.reset();
      this.refreshFormA(data); 
    }
  }
  async selectDateB(newVal){
    // const data = await this.restService.get(newVal).catch(e => { console.error(e) });
    // const res = await this.restService.find({
    //   query: {
    //     $nutri: true,
    //     $limit: 1,
    //     _id: newVal,
    //   },
    // }).catch(e => { console.error(e) });
    // const data = res?.data[0];
    const data = this.oldValues.find(val => val._id === newVal);
    if (data) {
      this.notesFormB.reset();
      this.refreshFormB(data); 
    }
  }

  resetField(notesFormA, fieldName, value) {
    notesFormA.controls[fieldName].reset(value);
  }

  // async submitForm(){
  //   this.busyState.emit(true);
  //   const form = this.notesFormA;
  //   let res: any;

  //   if (form && form.value._id && form.value._id !== '') { 
  //     res = await this.restService.patch(form.value._id, form.value);
  //     if (res && res._id) {
  //       form.reset(res);
  //       this.showStatus("נשמר בהצלחה", null);
  //       if (res.follow_flag) {
  //         this.updateFollow.emit(res);
  //         setTimeout(() => {
  //           this._snackBar.open('המשתמש נכנס למעקב', 'סגור', {
  //             duration: 0,
  //           });
  //         }, 2000);
  //       }
  //     }
  //   }
  //   this.busyState.emit(false);
  //   return res;
  // }

  createNewForm(): FormGroup {
    return this.formBuilder.group({
        _id: [''],

        nuq_locked: [false],
        nuq_notes: this.formBuilder.group({
          A: this.formBuilder.array([]),
          B: this.formBuilder.array([]),
          C: this.formBuilder.array([]),
          D: this.formBuilder.array([]),
        
          s1: this.formBuilder.array([]),
          // s2: this.formBuilder.array([]),
          s3: this.formBuilder.array([]),
          s4: this.formBuilder.array([]),
          s5: this.formBuilder.array([]),
          // s6: this.formBuilder.array([]),
          // s7: this.formBuilder.array([]),
          // s8: this.formBuilder.array([]),
          // s9: this.formBuilder.array([]),
          s10: this.formBuilder.array([]),
        }),

        notes_nuq_a: [''],
        notes_nuq_b: [''],
        notes_nuq_c: [''],
        notes_nuq_d: [''],
        notes_nuq_e: [''],
    });
  }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];
  }

  getDirty() {
    const formA = this.notesFormA;
    const formB = this.notesFormB;
    // return (formA && formA.dirty) || (formB && formB.dirty);
    return (formA && formA.dirty);
  }

  getLocked() {
    const lockedField = 'nuq_locked';
    const formA = this.notesFormA;
    // const formB = this.notesFormB;
    // return (formA && formA.dirty) || (formB && formB.dirty);
    return (formA && formA.value[lockedField]);
  }

  getLastId() {
    const formA = this.notesFormA;
    return formA && formA.value._id || '';
  }

  linkedNote(note: any) {
    this.linkedNotesEvent.emit(note);
  }

  addNote(contentAndField: any = {}, quiz_prefix: string, quiz_note_type: string, sub_prefix?: string) {
    const { content, content_F, acFormField } = contentAndField;
    this.addNoteEvent.emit({ content, content_F, quiz_prefix, quiz_note_type, sub_prefix, acFormField });
  }

  editNote(idAndField: any = {}, quiz_prefix: string, quiz_note_type: string) {
    const { id, field } = idAndField;
    this.editNoteEvent.emit({ id, field, quiz_prefix, quiz_note_type });
  }

  addNoteToNotesObj(quiz_note_type: string, newNote: FormControl) {
    const notesGroup = this.notesFormA.get('nuq_notes') as FormGroup;
    const fieldArray = notesGroup.get(quiz_note_type) as FormArray;
    if (!newNote.value?.note_id || !fieldArray.value || fieldArray.value.findIndex(val => val && val.note_id === newNote.value.note_id) > -1) {
      return;
    }
    fieldArray.push(newNote);
    fieldArray.markAsDirty();
  }

  removeNote(quiz_note_type: string, noteId: string) {
    const notesGroup = this.notesFormA.get('nuq_notes') as FormGroup;
    const fieldArray = notesGroup.get(quiz_note_type) as FormArray;
    if (fieldArray) {
      const idx = fieldArray.value.findIndex(val => val && val.note_id === noteId); // || (val.note_custom && val.note_custom === noteObj.note_custom)
      if (idx > -1) {
        fieldArray.removeAt(idx);
        fieldArray.markAsDirty();
      }      
    }
  }

  calcIngredients(selected: any) {
    const allChosenIngredients = {
      protein: [],
      carbs: [],
      fat: [],
      veg: [],
      fruits: [],
      sweets: [],
      snacks: [],
      sauce: [],
      powder: [],
      drink: [],
    };

    if (selected?.nuq_chosen_categories) {
      for (const key in selected.nuq_chosen_categories) {
        if (Object.prototype.hasOwnProperty.call(selected.nuq_chosen_categories, key)) {
          const category = selected.nuq_chosen_categories[key];
          if (category?.checked) {
            let ids = [];
            if (category.children?.length) {
              for (const child of category.children) {
                if (child?.checked) {
                  ids.push(child._id);
                }
              }
            } else {
              ids.push(category._id);
            }
            if (ids.length) {
              if (category.childrenProducts) {
                for (const key in category.childrenProducts) {
                  if (Object.prototype.hasOwnProperty.call(category.childrenProducts, key)) {
                    const element = category.childrenProducts[key];
                    if (element?.length && allChosenIngredients[key]) {
                      allChosenIngredients[key].push(...ids);
                    }
                    // if (allChosenIngredients[key]) {
                    //   allChosenIngredients[key].push(...ids);
                    // }
                  }
                }
              } else {
                for (const id of ids) {
                  for (const ingName in this.allOptionsIngredients) {
                    if (Object.prototype.hasOwnProperty.call(this.allOptionsIngredients, ingName)) {
                      const allOptions = this.allOptionsIngredients[ingName];
                      console.log(allOptions);
                      // const opt = allOptions?.find((opt: any) => opt?._id === id);
                      const opt = allOptions?.find((opt: any) => opt?._id === id || opt?.children?.find((_opt: any) => _opt?._id === id));
                      console.log(opt);
                      if (opt) {
                        allChosenIngredients[ingName].push(id);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      selected.chosenCategories = allChosenIngredients;
    }

    return selected;
  }

  async getCategoriesData() {
    const nutcategories: any[] = await this.restApiService.service('nutcategories').find({
      query: {
        $paginate: false,
        $select: ['_id', 'label'],
      },
    });

    // const getMappedItems = (idsArray: string[]) => {
    //   return idsArray.map(filterItem => nutcategories.find(item => item?.label === filterItem || item?._id === filterItem)).filter(x => x).map(catItem => ({ value: catItem._id, text: catItem.label }));
    // }

    this.nutcategories = [{_id: 'water', label: 'מים', reorder: 1, checked: true, weekly: {}} ,...nutcategories];
  }

  getIdsByFilter(filterArr: string[], allArr: any[], key = 'label') {
    return filterArr.map(filterItem => allArr.find(item => item[key] === filterItem)?._id).filter(x => x);
  }
  
  extractCategories(products: any[], catName: string) {
    let productsByCategories: any = {};

    for (const product of products) {
      if (product.n_categories?.length) {
        for (const categoryObjectId of product.n_categories) {
          const catId = categoryObjectId.toString();
          if (!productsByCategories[catId]) {
            productsByCategories[catId] = [product];
          } else {
            if (!productsByCategories[catId].find((pr: any) => pr._id === product._id)) {
              productsByCategories[catId].push(product);
            }
          }
        }
      }
    }

    if (catName === 'drink') {
      productsByCategories['water'] = [{}];
    }

    return productsByCategories;
  }
  
  getChildCategories(productsByCategories: any) {
    const allCategories = this.nutcategories || [];

    let allCategoriesWithChildren: any = {};
    let allChildren: any[] = [];

    for (const category of allCategories) {
      if (category?._id) {
          
        if (!category.weekly) {
          category.weekly = {}
        }
        // if (!category.childrenProducts) {
        //   category.childrenProducts = {}
        // }
        if (!category.checkedTypical) {
          category.checkedTypical = {}
        }

        if (!category.n_parent_id) {
          
          // if (!category.weekly) {
          //   category.weekly = {}
          // }
          // if (!category.childrenProducts) {
          //   category.childrenProducts = {}
          // }
          // if (!category.checkedTypical) {
          //   category.checkedTypical = {}
          // }

          if (productsByCategories[category._id]?.length) {
            allCategoriesWithChildren[category._id] = category;
          }
        } else {
          allChildren.push(category);
        }
      }
    }

    for (const child of allChildren) {
      if (productsByCategories[child._id]?.length) {
        if (!allCategoriesWithChildren[child.n_parent_id]) {
          const parentCategoryFind = allCategories.find(cat => cat._id === child.n_parent_id);
          if (!parentCategoryFind) {
            continue;
          }
          allCategoriesWithChildren[child.n_parent_id] = parentCategoryFind;
        }
        if (!allCategoriesWithChildren[child.n_parent_id].children) {
          allCategoriesWithChildren[child.n_parent_id].children = [child];
        } else {
          if (!allCategoriesWithChildren[child.n_parent_id].children.find(cat => cat._id === child._id)) {
            allCategoriesWithChildren[child.n_parent_id].children.push(child);
          }
        }
      }
    }

    return allCategoriesWithChildren;
    
  }

  async getAllData() {

    // await this.getCategoriesData();

    const allProductsData = await this.restApiService.service('actions').create({cmd: 'get-my-products', cmdData: {filterNutTypes: []}});

    // console.log(allProductsData);

    if (allProductsData) {
      const { products = [], productsByCategories, nutallergies, nutcategories, nutingredients = [], nuttags, nuttypes } = allProductsData;
        
      this.nutcategories = [{_id: 'water', label: 'מים', reorder: 1, checked: true, weekly: {}} ,...nutcategories];


      if (this.allCategoriesGroups) {
        for (const cat of this.allCategoriesGroups) {
          if (cat?.filterIngredients && cat?.name) {
  
            const filterIngredientsIds = this.getIdsByFilter(cat.filterIngredients, nutingredients);
  
            const allProductsFiltered = products.filter(product => product?.n_ingredients?.length && filterIngredientsIds.some(ingredient => product.n_ingredients.includes(ingredient)));

            const _productsByCategories = this.extractCategories(allProductsFiltered, cat.name);
            
            // const categoriesIds = Object.keys(_productsByCategories);

            const allCategoriesWithChildren = this.getChildCategories(_productsByCategories);

            const objValuesArr = Object.values(allCategoriesWithChildren);

            const allOptionsSorted = objValuesArr.sort((a: any, b: any) => {
              if (!b?.reorder) return -1;
              if (!a?.reorder) return 0;

              return (a?.reorder ?? 0) - (b?.reorder ?? 0);
              return 0;
            });

            this.allOptionsIngredients[cat.name] = allOptionsSorted;
            
          }
        }
      }

    }

  }

  // async getAllData() {

  //   const allProductsData = await this.restApiService.service('actions').create({cmd: 'get-my-products', cmdData: {filterNutTypes: []}});

  //   // console.log(allProductsData);

  //   if (allProductsData) {
  //     const { products, productsByCategories, nutallergies, nutcategories, nutingredients, nuttags, nuttypes } = allProductsData;

  //     // this.nutallergies = nutallergies;
  //     // this.nutcategories = nutcategories;
  //     this.nutcategories = [{_id: 'water', label: 'מים', reorder: 1, checked: true, weekly: {}} ,...nutcategories];
  //     this.nutingredients = nutingredients;
  //     // this.nuttags = nuttags;
  //     // this.nuttypes = nuttypes;

  //     this.allProducts = products;
  //     this.filterToCategoriesIngredients(this.allProducts);
  //   }

  // }

  // getIdsByFilter(filterArr: string[], allArr: any[], key = 'label') {
  //   return filterArr.map(filterItem => allArr.find(item => item[key] === filterItem)?._id).filter(x => x);
  // }
  
  // extractCategories(products: any[], catName: string) {
  //   let productsByCategories: any = {};

  //   for (const product of products) {
  //     if (product.n_categories?.length) {
  //       for (const categoryObjectId of product.n_categories) {
  //         const catId = categoryObjectId.toString();
  //         if (!productsByCategories[catId]) {
  //           productsByCategories[catId] = [product];
  //         } else {
  //           if (!productsByCategories[catId].find((pr: any) => pr._id === product._id)) {
  //             productsByCategories[catId].push(product);
  //           }
  //         }
  //       }
  //     }
  //   }

  //   if (catName === 'drink') {
  //     productsByCategories['water'] = [{}];
  //   }

  //   return productsByCategories;
  // }
  
  // getChildCategories(productsByCategories: any) {
  //   const allCategories = this.nutcategories || [];

  //   let allCategoriesWithChildren: any = {};
  //   let allChildren: any[] = [];

  //   for (const category of allCategories) {
  //     if (category?._id) {
          
  //       if (!category.weekly) {
  //         category.weekly = {}
  //       }
  //       if (!category.checkedTypical) {
  //         category.checkedTypical = {}
  //       }

  //       if (!category.n_parent_id) {
  //         if (productsByCategories[category._id]?.length) {
  //           allCategoriesWithChildren[category._id] = category;
  //         }
  //       } else {
  //         allChildren.push(category);
  //       }
  //     }
  //   }

  //   for (const child of allChildren) {
  //     if (productsByCategories[child._id]?.length) {
  //       if (!allCategoriesWithChildren[child.n_parent_id]) {
  //         const parentCategoryFind = allCategories.find(cat => cat._id === child.n_parent_id);
  //         if (!parentCategoryFind) {
  //           continue;
  //         }
  //         allCategoriesWithChildren[child.n_parent_id] = parentCategoryFind;
  //       }
  //       if (!allCategoriesWithChildren[child.n_parent_id].children) {
  //         allCategoriesWithChildren[child.n_parent_id].children = [child];
  //       } else {
  //         if (!allCategoriesWithChildren[child.n_parent_id].children.find(cat => cat._id === child._id)) {
  //           allCategoriesWithChildren[child.n_parent_id].children.push(child);
  //         }
  //       }
  //     }
  //   }

  //   return allCategoriesWithChildren;
  // }

  // generateOptions(allProductsData: any[], catName: string) {
  //   const products = allProductsData;
    
  //   const productsByCategories = this.extractCategories(products, catName);

  //   const allCategoriesWithChildren = this.getChildCategories(productsByCategories);

  //   const objValuesArr = Object.values(allCategoriesWithChildren);

  //   const allOptionsSorted = objValuesArr.sort((a: any, b: any) => {
  //     if (!b?.reorder) return -1;
  //     if (!a?.reorder) return 0;

  //     return (a?.reorder ?? 0) - (b?.reorder ?? 0);
  //     return 0;
  //   });

  //   const options = allOptionsSorted;
    
  //   return { options, products, productsByCategories };
  // }
  
  // filterToCategoriesIngredients(allProducts: any[]) {

  //   if (!allProducts) {
  //     return;
  //   }

  //   const allIngredients = this.nutingredients || [];

  //   if (this.allCategoriesGroups) {
  //     for (const cat of this.allCategoriesGroups) {
  //       if (cat?.filterIngredients && cat?.name) {

  //         const filterIngredientsIds = this.getIdsByFilter(cat.filterIngredients, allIngredients);

  //         // this.filteredProductsIngredients[cat.name] = allProducts.filter(product => product?.n_ingredients?.length && filterIngredientsIds.some(ingredient => product.n_ingredients.includes(ingredient)));

  //         const allProductsFiltered = allProducts.filter(product => product?.n_ingredients?.length && filterIngredientsIds.some(ingredient => product.n_ingredients.includes(ingredient)));

  //         const generatedOptions = this.generateOptions(allProductsFiltered, cat.name);

  //         // this.allOptionsIngredients[cat.name] = generatedOptions.allOptions;
  //         this.allOptionsIngredients[cat.name] = generatedOptions.options;
          
  //       }
  //     }
  //   }

  //   console.log('this.allOptionsIngredients', this.allOptionsIngredients);
  // }
}

