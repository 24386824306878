import { Component, ViewChild, TemplateRef, ChangeDetectorRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TranslateService } from '@ngx-translate/core';

import { RestApiService } from '@noammazuz/vzn-feathers';
import { TableColumn } from '@noammazuz/vzn-data-table';

import { appState } from '@services/app-state';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';

@Component({
    selector: 'user-notes',
    templateUrl: './usernotes.component.html',
})
export class UsernotesComponent {
    @ViewChild('tableEl') tableEl: any;
    @ViewChild('noteTypeTmpl') noteTypeTmpl: TemplateRef<any>;
    @ViewChild('contentHoverTmpl') contentHoverTmpl: TemplateRef<any>;
    @ViewChild('checkTmpl') checkTmpl: TemplateRef<any>;
    @ViewChild('daysTmpl') daysTmpl: TemplateRef<any>;
    @ViewChild('freqTmpl') freqTmpl: TemplateRef<any>;

    @ViewChild('isActiveTmpl') isActiveTmpl: TemplateRef<any>;

    // @Input() userId?: string;
    @Input() user?: any;

    busy = false;
    busyChange = false;

    appState = appState;
    notesRestService = null;
    columns: Array<{}> = [];
    query: any;

    weekDays = {
        '0': 'א',
        '1': 'ב',
        '2': 'ג',
        '3': 'ד',
        '4': 'ה',
        '5': 'ו',
        '6': 'ש',
    };

    noteFreq = {
        '1': 'שבועית',
        '2': '2 שבועות',
        '3': '3 שבועות',
        '4': '4 שבועות',
        '13': '3 חודשים (13 שבועות)',
        '26': 'חצי-שנה (26 שבועות)',
        '52': 'שנה (52 שבועות)',
    };

    notePrefix = {
        ntq: 'אנתרופומטריים',
        prq: 'אימונים',
        slq: 'שינה',
        heq: 'בריאות',
        nuq: 'תזונה',
        btq: 'בדיקת דם',
        fdq: 'יומן אכילה',
    };

    quizNoteTypeOptions = {
        ntq: {
            s1: '* הערות לתחילת הדוח',
            // s2: '* הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
            // s3: '* הערות תזונה כלליות',
            // s4: '* הערות ארוחת בוקר',
            // s5: '* הערות ארוחת ביניים 1',
            // s6: '* הערות ארוחת צהריים',
            // s7: '* הערות ארוחת ביניים 2',
            // s8: '* הערות ארוחת ערב',
            // s9: '* הערות ארוחת לילה',
            // s10: '* הערות נוזלים ללא אימון',
            s11: '* דיווח למועדון',
            A: 'הערות נתונים אנתרופומטריים',
            // B: 'דיווח למועדון',
        },
        prq: {
            s1: '* הערות לתחילת הדוח',
            s2: '* הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
            // s3: '* הערות תזונה כלליות',
            s4: '* הערות ארוחת בוקר',
            // s5: '* הערות ארוחת ביניים 1',
            s6: '* הערות ארוחת צהריים',
            // s7: '* הערות ארוחת ביניים 2',
            // s8: '* הערות ארוחת ערב',
            // s9: '* הערות ארוחת לילה',
            // s10: '* הערות נוזלים ללא אימון',
            A: 'הערות מאזן אנרגיה',
            // B: 'הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
        },
        slq: {
            s1: '* הערות לתחילת הדוח',
            // s2: '* הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
            // s3: '* הערות תזונה כלליות',
            // s4: '* הערות ארוחת בוקר',
            // s5: '* הערות ארוחת ביניים 1',
            // s6: '* הערות ארוחת צהריים',
            // s7: '* הערות ארוחת ביניים 2',
            // s8: '* הערות ארוחת ערב',
            // s9: '* הערות ארוחת לילה',
            // s10: '* הערות נוזלים ללא אימון',
            A: 'הערות הרגלי שינה',
        },
        heq: {
            s1: '* הערות לתחילת הדוח',
            s2: '* הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
            s3: '* הערות תזונה כלליות',
            s4: '* הערות ארוחת בוקר',
            // s5: '* הערות ארוחת ביניים 1',
            // s6: '* הערות ארוחת צהריים',
            // s7: '* הערות ארוחת ביניים 2',
            // s8: '* הערות ארוחת ערב',
            // s9: '* הערות ארוחת לילה',
            s10: '* הערות נוזלים ללא אימון',
            // A: 'מצב בריאותי כללי - הערות לתחילת הדוח',
            // B: 'מצב בריאותי כללי - הערות תזונה כלליות',
            // C: 'מצב בריאותי כללי - הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
            // D: 'אלרגיות ורגישויות - הערות לתחילת הדוח',
            // E: 'אלרגיות ורגישויות - הערות תזונה',
            // F: 'תרופות ותוספים - הערות לתחילת הדוח',
            // G: 'תרופות ותוספים - הערות תזונה',
            // H: 'מחזור - הערות לתחילת הדוח',
            // I: 'מחזור - הערות תזונה',
        },
        nuq: {
            s1: '* הערות לתחילת הדוח',
            // s2: '* הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
            s3: '* הערות תזונה כלליות',
            s4: '* הערות ארוחת בוקר',
            s5: '* הערות ארוחת ביניים 1',
            // s6: '* הערות ארוחת צהריים',
            // s7: '* הערות ארוחת ביניים 2',
            // s8: '* הערות ארוחת ערב',
            // s9: '* הערות ארוחת לילה',
            s10: '* הערות נוזלים ללא אימון',
            // A: 'העדפות תזונה - הערות תזונה כלליות',
            // B: 'אופן האכילה (מסכים) - הערות תזונה כלליות',
            // C: 'אופן האכילה (אכילה בחוץ) - הערות תזונה כלליות',
            // D: 'הערות הרגלי אכילה - הערות תזונה כלליות',
        },
        btq: {
            s1: '* הערות לתחילת הדוח',
            // s2: '* הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
            // s3: '* הערות תזונה כלליות',
            s4: '* הערות ארוחת בוקר',
            // s5: '* הערות ארוחת ביניים 1',
            s6: '* הערות ארוחת צהריים',
            // s7: '* הערות ארוחת ביניים 2',
            // s8: '* הערות ארוחת ערב',
            // s9: '* הערות ארוחת לילה',
            // s10: '* הערות נוזלים ללא אימון',
            A: 'המגלובין, פריטין, ברזל, טרנספרין, MCV, MCH',
            B: 'B12',
            C: 'חומצה פולית',
            D: 'וויטמין D',
            E: 'מגנזיום',
            F: 'כולסטרול, טריגליצרידים, HDL, LDL',
            G: 'גלוקוז',
            H: 'ALKP',
            // I: 'הערות לתחילת הדוח',
            J: 'הערות בדיקת דם',
        },
        fdq: {
            s1: '* הערות לתחילת הדוח',
            s2: '* הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
            s3: '* הערות תזונה כלליות',
            s4: '* הערות ארוחת בוקר',
            s5: '* הערות ארוחת ביניים 1',
            s6: '* הערות ארוחת צהריים',
            s7: '* הערות ארוחת ביניים 2',
            s8: '* הערות ארוחת ערב',
            s9: '* הערות ארוחת לילה',
            s10: '* הערות נוזלים ללא אימון',
            s11: '* דיווח למועדון',
            s12: 'התראות',
            // A: 'הערות ארוחת בוקר',
            // B: 'הערות ארוחת ביניים 1',
            // C: 'הערות ארוחת צהריים',
            // D: 'הערות ארוחת ביניים 2',
            // E: 'הערות ארוחת ערב',
            // F: 'הערות ארוחת לילה',
            // G: 'הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
            // H: 'הערות תזונה כלליות',
            // I: 'הערות נוזלים ללא אימון',
        },
      };

    constructor(
        public dialog: MatDialog,
        private translate: TranslateService,
        private dialogService: ConfirmDialogService,
        private restApiService: RestApiService<any>,
        public router: Router,
        private _snackBar: MatSnackBar,
        public sanitizer: DomSanitizer,
        private changeDetectorRef: ChangeDetectorRef,
    ) {
        this.notesRestService = this.restApiService.service('notes-job');
    }

    ngAfterViewInit() {
        this.columns = this.createColumns();
        const userId = this.user ? this.user._id : this.appState.user._id;
        this.query = {
            $user_id: userId,
            $sort: {
                quiz_note_type: 1,
            },
        };
        this.changeDetectorRef.detectChanges();
    }

    createColumns() {
        let columns: Array<TableColumn>;

        if (this.user) {
            const isFemale = this.user.usr_gender === 'נקבה';
            if (isFemale) {
                columns = [
                    { prop: 'is_active', name: 'סטטוס', customTemplate: this.isActiveTmpl, maxWidth: 75 },
                    { prop: 'quiz_note_type', name: 'סוג הערה', customTemplate: this.noteTypeTmpl, maxWidth: 150 },
                    { prop: 'title', name: 'כותרת', maxWidth: 150 },
                    { prop: 'content_F', name: 'תוכן', maxWidth: 600 },
                    { prop: 'push_content_F', name: 'תוכן ההתראה', maxWidth: 500 },
                    { prop: 'hour', name: 'שעה', maxWidth: 75 },
                    { prop: 'days', name: 'ימים', customTemplate: this.daysTmpl, maxWidth: 100 },
                    { prop: 'freq', name: 'תדירות', customTemplate: this.freqTmpl, maxWidth: 100 },
                ];
            } else {
                columns = [
                    // { prop: '_id', name: 'מזהה הערה', maxWidth: 75 },
                    { prop: 'is_active', name: 'סטטוס', customTemplate: this.isActiveTmpl, maxWidth: 75 },
                    { prop: 'quiz_note_type', name: 'סוג הערה', customTemplate: this.noteTypeTmpl, maxWidth: 150 },
                    { prop: 'title', name: 'כותרת', maxWidth: 150 },
                    // { prop: 'content', name: 'תוכן', customTemplate: this.contentHoverTmpl, maxWidth: 450 },
                    { prop: 'content', name: 'תוכן', maxWidth: 600 },
                    { prop: 'push_content', name: 'תוכן ההתראה', maxWidth: 500 },
                    { prop: 'hour', name: 'שעה', maxWidth: 75 },
                    { prop: 'days', name: 'ימים', customTemplate: this.daysTmpl, maxWidth: 100 },
                    { prop: 'freq', name: 'תדירות', customTemplate: this.freqTmpl, maxWidth: 100 },
                ];
            }
        } else {
            const isFemale = this.appState.user.usr_gender === 'נקבה';
            if (isFemale) {
                columns = [
                    { prop: 'is_active', name: 'סטטוס', customTemplate: this.isActiveTmpl, maxWidth: 75 },
                    { prop: 'push_content_F', name: 'תוכן', customTemplate: this.contentHoverTmpl, maxWidth: 800 },
                    { prop: 'hour', name: 'שעה', maxWidth: 75 },
                    { prop: 'days', name: 'ימים', customTemplate: this.daysTmpl, maxWidth: 100 },
                    { prop: 'freq', name: 'תדירות', customTemplate: this.freqTmpl, maxWidth: 100 },
                ];
            } else {
                columns = [
                    { prop: 'is_active', name: 'סטטוס', customTemplate: this.isActiveTmpl, maxWidth: 75 },
                    // { prop: 'push_content', name: 'תוכן ההתראה', maxWidth: 1000 },
                    { prop: 'push_content', name: 'תוכן', customTemplate: this.contentHoverTmpl, maxWidth: 800 },
                    { prop: 'hour', name: 'שעה', maxWidth: 75 },
                    { prop: 'days', name: 'ימים', customTemplate: this.daysTmpl, maxWidth: 100 },
                    { prop: 'freq', name: 'תדירות', customTemplate: this.freqTmpl, maxWidth: 100 },
                ];
            }
        }

        return columns;
    }

    async noteChange(user_id: string, note_id: string, to_value: boolean, quiz_info: any) {
        return await this.notesRestService.patch(user_id, { note_id, to_value, quiz_info }).catch((e) => {
            console.error(e);
            this._snackBar.open('שגיאה בשינוי סטטוס התראה', null, {
                duration: 2000,
            });
        });
    }

    async changeGuard(row: any, toValue: boolean) {
        // const usrId = this.userId || this.appState.user._id;
        const userId = this.user ? this.user._id : this.appState.user._id;
        const noteId = row._id;
        const quizInfo = row.quiz_info;
        this.busyChange = true;
        if (!this.user && !toValue) {
            const options = {
                title: 'השתקת התראה',
                message: this.translate.instant('general.mutePush'),
                cancelText: 'התחרטתי',
                confirmText: 'השתקת התראה',
            };

            this.dialogService.open(options);
            this.dialogService.confirmed().subscribe(async (confirmed) => {
                if (confirmed) {
                    const res = await this.noteChange(userId, noteId, toValue, quizInfo);
                    if (res && res.is_active === toValue) {
                        row.is_active = toValue;
                        this.busyChange = false;
                        this.changeDetectorRef.detectChanges();
                        return;
                    }
                }
                this.busyChange = false;
            });
        } else {
            const res = await this.noteChange(userId, noteId, toValue, quizInfo);
            if (res && res.is_active === toValue) {
                row.is_active = toValue;
                this.busyChange = false;
                this.changeDetectorRef.detectChanges();
                return;
            }
            this.busyChange = false;
        }
    }

    tableLoading(e) {
        this.busy = e;
        // setTimeout(() => {
        //     this.busy = e;
        // });
    }
}
