import { Component, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';  // Import HttpClient
import { FormBuilder, FormGroup } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { appState } from '@services/app-state';
// import { Apputils } from '@services/app-utils';

import { AuthService, RestApiService } from '@noammazuz/vzn-feathers';

import { UserpracticeComponent } from '../userpractice/userpractice.component';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';

import { environment } from '@environments/environment';

@Component({
  templateUrl: "./userprogress.component.html"
})
export class UserprogressComponent {

  @ViewChild(UserpracticeComponent) private userpractice: UserpracticeComponent;

  @ViewChild("popupTemplate") popupTemplate: TemplateRef<any>;

  restService: any;
  appState = appState;

  userId: any;

  userState: any;

  totalQuizzes = 0;
  stepperCompleted = 0;
  allLocked = false;
  allCompleted = false;

  isDone = false;

  userReports = [];

  lastCreatedAt = 0;
  lastReportAt = 0;

  // isFlutter = Apputils.isInNativeFlutter();
  // waLink: SafeUrl;

  constructor(
    private formBuilder: FormBuilder,
    public authService: AuthService<any>,
    public dialog: MatDialog,
    private translate: TranslateService,
    public restApiService: RestApiService<any>,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    // public sanitizer: DomSanitizer,
    private http: HttpClient  // Inject HttpClient
  ) {
    // this.route.params.subscribe(async params => {
    //   if (params.id) {
    //     const waLink = this.isFlutter ? 'whatsapp://send/?phone=972584468642&text' : 'https://wa.me/972584468642';
    //     this.waLink = this.sanitizer.bypassSecurityTrustUrl(waLink);
    //     try {
    //       const user = await this.restApiService.service('users').get(params.id).catch((error: any) => { console.error(error); });
    //       // const [user] = await this.restApiService.service('users').find({
    //       //   query: {
    //       //     $paginate: false,
    //       //     $limit: 1,
    //       //     _id: params.id,
    //       //     $extradetails: true
    //       //   }
    //       // });
    //       if (!user) {
    //         throw 'no user';
    //       }
    //       this.userId = user._id.toString();
    //       if (!user.usr_agreement || user.usr_type !== 'player') {
    //         console.log('missing user fields');
    //         this.router.navigate([`Profile/${params.id}`]);
    //         return;
    //       }
    //       if (!user.usr_confirmed) {
    //         this._snackBar.open(this.translate.instant('profile.NOT_ACCESS'), this.translate.instant('profile.CLOSE'), {
    //           duration: 0,
    //         });
    //         console.log('user is not confirmed');
    //         this.router.navigate([`Profile/${params.id}`]);
    //         return;
    //       }
    //       const userState = user.stateUser || user.usr_state?.stateUser;
    //       if (!userState) {
    //         return;
    //       }
    //       this.lastCreatedAt = userState.createdAt;
    //       this.lastReportAt = userState.reportAt;
    //       this.totalQuizzes = userState.totalSeen;
    //       this.stepperCompleted = userState.done;
    //       this.allCompleted = userState.allCompleted;
    //       this.allLocked = userState.allLocked;

    //       this.userReports = user.user_reports;
    //       this.userState = userState;

    //     } catch (e) {
    //       console.error(e);
    //       this.router.navigate([`/`]);
    //     }
    //   }
    // })
  }

  ngOnInit() {
    const user: any = this.appState.user;
    if (!user.usr_agreement || user.usr_type !== 'player') {
      console.log('missing user fields');
      this.router.navigate([`Profile/${user._id}`]);
      return;
    }
    if (!user.usr_confirmed) {
      this._snackBar.open(this.translate.instant('profile.NOT_ACCESS'), this.translate.instant('profile.CLOSE'), {
        duration: 0,
      });
      console.log('user is not confirmed');
      this.router.navigate([`Profile/${user._id}`]);
      return;
    }
    // const waLink = this.isFlutter ? 'whatsapp://send/?phone=972584468642&text' : 'https://wa.me/972584468642';
    // this.waLink = this.sanitizer.bypassSecurityTrustUrl(waLink);
    // if(user?.userReports[0]?._id && user.usr_type !== 'superadmin'){

    //   this.webhookFunc(user?.userReports[0]?._id);
    // }

    this.updateVars(user);

    this.popupLauncher();
    // this.viewReport(this.userReports[0]._id);

    const checkNewState = async () => {
      const loggedUser = await this.authService.isAuth().catch(err => { console.error(err); });
      // const isQa = !environment.production;

      // const userState = isQa ? loggedUser.usr_state?.stateUserQa : loggedUser.usr_state?.stateUser;

      const userState = loggedUser?.usr_state?.stateUser;

      if (loggedUser && JSON.stringify(this.userState) !== JSON.stringify(userState)) {
        this.updateVars(loggedUser);
        this.appState.user = loggedUser;
        return true;
      }
      return false;
    }

    setTimeout(async () => {
      if (!await checkNewState()) {
        setTimeout(async () => {
          await checkNewState();
        }, 5000);
        setTimeout(async () => {
          await checkNewState();
        }, 10000);
        setTimeout(async () => {
          await checkNewState();
        }, 15000);
      }
    }, 2500);



  }

  updateVars(user: any) {
    // const isQa = !environment.production;

    // const userState = isQa ? (user.stateUserQa || user.usr_state?.stateUserQa) : (user.stateUser || user.usr_state?.stateUser);
    const userState = user.stateUser || user.usr_state?.stateUser;
    if (userState) {
      this.lastCreatedAt = userState.createdAt;
      this.lastReportAt = userState.reportAt;
      this.totalQuizzes = userState.totalSeen;
      this.stepperCompleted = userState.done;
      this.allCompleted = userState.allCompleted;
      this.allLocked = userState.allLocked;

      // this.isDone = ((userState.allCompleted || userState.allLocked) && (userState['fdq'] && userState['fdq'].exist && !userState['fdq'].hidden ? (userState['fdq'].locked || +(userState['fdq'].count || 0) > 0) : true));
      this.isDone = ((userState.allCompleted || userState.allLocked) && (userState['fdq'] && userState['fdq'].exist && !userState['fdq'].hidden ? userState['fdq'].locked : true));

      this.userReports = user.user_reports;
      this.userState = userState;
    }
    this.userId = user?._id;
  }

  webhookFunc(reportId: string) {

    reportId = reportId.split('?')[0];

    const webhookUrl = 'https://hook.eu1.make.com/exnugh6bvkfspv14hdo5o5rio8xftkdd';
    const reportData = {
      reportId,
      userId: this.appState.user._id,
      // timestamp: new Date().toISOString(),
    };

    this.http.post(webhookUrl, reportData).subscribe({
      next: (response) => {
        console.log('Webhook sent successfully', response);
        // this.router.navigate(['/Userreport/', reportId]);
      },
      error: (error) => {
        console.error('Error sending webhook', error);
      }
    });
  }

  viewReport(reportId: string) {

    // this.webhookFunc(reportId);
    this.router.navigate(['/Userreport/', reportId]);

  }

  async createPracticeDialogFunc() {
    await this.userpractice.createPracticeDialog();
  }

  async popupLauncher() {
    const allPopups = await this.restApiService.service('msg-log').find({});

    if (allPopups.length) {
      console.log('allPopups', allPopups);
      await this.newPopupByIndex(allPopups, 0);
    }
  }

  async newPopupByIndex(allPopups: any[], idx: number) {
    if (allPopups[idx]) {
      const isFemale = this.appState.user?.usr_gender === 'נקבה';
      const { _id, title, body, sent_at, is_response, response_button_yes, response_button_yes_F, response_button_no, response_button_no_F } = allPopups[idx];
      let ok = this.translate.instant('general.ok');
      let cancel = this.translate.instant('general.cancel');
      // if (is_response) {
      //   if (isFemale) {
      //     ok = response_button_yes_F || response_button_yes;
      //     cancel = response_button_no_F || response_button_no;
      //   } else {
      //     ok = response_button_yes;
      //     cancel = response_button_no;
      //   }
      // }

      const regex = /\*(.*?)\*/g;
      const boldBody = (body || '').replace(regex, '<b>$1</b>');

      let newFormGroup: FormGroup = this.formBuilder.group({
        sent_at: [sent_at],
        // title: [title],
        body: [boldBody],
        isFemale: [isFemale],
      });

      const dialogRef = this.dialog.open(CustomDialogComponent, {
        // minWidth: '35%',
        disableClose: true,
        data: {
          // title: title || '',
          // subtitle: body || '',
          title: '',
          subtitle: '',
          ok,
          cancel,
          form: newFormGroup,
          template: this.popupTemplate
          // extraButton: { value: 'custom', text: '' },
        }
      });

      dialogRef.afterClosed().subscribe(async result => {
        let data: any = {};
        if (!result) {
          data.user_response_no = true;
          // } else if (result === 'custom') {
          //   data.user_response_custom = {  };
        } else {
          data.user_response_yes = true;
        }

        const res = await this.restApiService.service('msg-log').patch(_id, data).catch((err: any) => { console.error(err); });
        if (res) {
          console.log('popup response sent', res._id, res.user_response_at);
        }

        await this.newPopupByIndex(allPopups, idx + 1);
      });
    }
  }

}

