import { Injectable } from '@angular/core';

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  prefix: string;
}
// <div class="text-center">
//   <button mat-button routerLink="/Practicequiz">{{'sidenav.practice' | translate}}</button>
//   <button mat-button routerLink="/Sleepingquiz">{{'sidenav.sleeping' | translate}}</button>
//   <button mat-button routerLink="/Healthquiz">{{'sidenav.health' | translate}}</button>
//   <button mat-button routerLink="/Fooddiaryquiz">{{'sidenav.food_diary' | translate}}</button>
//   <button mat-button routerLink="/Nutritionquiz">{{'sidenav.nutrition' | translate}}</button>
//   <button mat-button routerLink="/Bloodtestquiz">{{'sidenav.bloodtest' | translate}}</button>
//   <button mat-button routerLink="/Nuttestquiz">{{'sidenav.nuttest' | translate}}</button>
//   <button mat-button routerLink="{{'/Profile/' + appState.user._id}}">{{'sidenav.profile' | translate}}</button>
//   <button mat-button (click)="logout()">{{'sidenav.logout' | translate}}</button>
//   <span style="display: inline-block; direction: rtl;">
//     <button mat-button (click)="useLanguage('he')" [ngClass]="{'mat-elevation-z2':translate.currentLang=='he'}">עברית</button>
//      / 
//     <button mat-button (click)="useLanguage('en')" [ngClass]="{'mat-elevation-z2':translate.currentLang=='en'}">English</button>
//   </span>
// </div>
const MENUITEMS = [
  // { state: 'starter', name: 'Starter Page', type: 'link', icon: 'av_timer' },
  // { state: 'button', type: 'link', name: 'Buttons', icon: 'crop_7_5' },
  { state: 'Healthquiz', prefix: 'heq', type: 'link', name: 'sidenav.health', icon: 'view_comfy' },
  { state: 'Practicequiz', prefix: 'prq', type: 'link', name: 'sidenav.practice', icon: 'view_comfy' },
  { state: 'Sleepingquiz', prefix: 'slq', type: 'link', name: 'sidenav.sleeping', icon: 'view_comfy' },
  // { state: 'Nutritionquiz', prefix: 'nuq', type: 'link', name: 'sidenav.nutrition', icon: 'view_comfy' },
  { state: 'Nutquiz', prefix: 'nuq', type: 'link', name: 'sidenav.nutquiz', icon: 'view_comfy' },
  { state: 'Bloodtestquiz', prefix: 'btq', type: 'link', name: 'sidenav.bloodtest', icon: 'view_comfy' },
  { state: 'Fooddiaryquiz', prefix: 'fdq', type: 'link', name: 'sidenav.food_diary', icon: 'view_comfy' },
  // { state: 'Measurement', type: 'link', name: 'sidenav.measurement', icon: 'view_comfy' },
  // { state: 'lists', type: 'link', name: 'Lists', icon: 'view_list' },
  // { state: 'menu', type: 'link', name: 'Menu', icon: 'view_headline' },
  // { state: 'tabs', type: 'link', name: 'Tabs', icon: 'tab' },
  // { state: 'stepper', type: 'link', name: 'Stepper', icon: 'web' },
  // {
  //   state: 'expansion',
  //   type: 'link',
  //   name: 'Expansion Panel',
  //   icon: 'vertical_align_center'
  // },
  // { state: 'chips', type: 'link', name: 'Chips', icon: 'vignette' },
  // { state: 'toolbar', type: 'link', name: 'Toolbar', icon: 'voicemail' },
  // {
  //   state: 'progress-snipper',
  //   type: 'link',
  //   name: 'Progress snipper',
  //   icon: 'border_horizontal'
  // },
  // {
  //   state: 'progress',
  //   type: 'link',
  //   name: 'Progress Bar',
  //   icon: 'blur_circular'
  // },
  // {
  //   state: 'dialog',
  //   type: 'link',
  //   name: 'Dialog',
  //   icon: 'assignment_turned_in'
  // },
  // { state: 'tooltip', type: 'link', name: 'Tooltip', icon: 'assistant' },
  // { state: 'snackbar', type: 'link', name: 'Snackbar', icon: 'adb' },
  // { state: 'slider', type: 'link', name: 'Slider', icon: 'developer_mode' },
  // {
  //   state: 'slide-toggle',
  //   type: 'link',
  //   name: 'Slide Toggle',
  //   icon: 'all_inclusive'
  // }
];

@Injectable()
export class MenuItems {
  getMenuitem(): Menu[] {
    return MENUITEMS;
  }
}
