import { MediaMatcher } from '@angular/cdk/layout';
import { Router, NavigationEnd } from '@angular/router';
import { ChangeDetectorRef, Component, OnDestroy, ViewChild, ElementRef, OnInit } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MenuItems } from '../../shared/menu-items/menu-items';

import { TranslateService } from '@ngx-translate/core';
import { MatSidenav } from '@angular/material/sidenav';

import { AuthService } from '@noammazuz/vzn-feathers';

// import { AppStateService } from '@services/app-state.service';
import { appState } from '@services/app-state';
import { Apputils } from '@services/app-utils';

@Component({
    selector: 'app-full-layout',
    templateUrl: 'full.component.html',
    styleUrls: [],
})
export class FullComponent implements OnDestroy, OnInit {
    mobileQuery: MediaQueryList;
    @ViewChild('mainContainer') mainContainer: ElementRef;
    @ViewChild('snav') snav: MatSidenav;
    // private _mobileQueryListener: () => void;

    appState = appState;
    // isFlutter = false;

    isFlutter = Apputils.isInNativeFlutter();
    waLink: SafeUrl;

    userState: any;

    constructor(
        public authService: AuthService<any>,
        // changeDetectorRef: ChangeDetectorRef,
        media: MediaMatcher,
        public menuItems: MenuItems,
        public router: Router,
        // public appStateService: AppStateService,
        public translate: TranslateService,
        public sanitizer: DomSanitizer,
    ) {
        // this.isFlutter = Apputils.isInNativeFlutter();
        this.mobileQuery = media.matchMedia('(min-width: 768px)');
        // this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        // this.mobileQuery.addListener(this._mobileQueryListener);

        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                // console.log('this.appState.allNotesUnfiltered.length', this.appState.allNotesUnfiltered.length);
                if (this.appState.user.usr_type === 'superadmin' || this.appState.user.usr_type === 'nutri') {
                    this.appState.quizUser = {};
                }
                if (this.appState.user.usr_type === 'player') {
                    this.userState = this.appState.user?.usr_state?.stateUser;
                }
            }
        });
    }

    ngOnInit() {
        if (this.translate.currentLang !== 'en') {
            if (this.appState.user.usr_gender === 'נקבה') {
                this.translate.use('he_F');
            } else {
                this.translate.use('he');
            }
        }

        this.userState = this.appState.user?.usr_state?.stateUser;

        // const waLink = this.isFlutter ? 'whatsapp://send/?phone=972584468642&text' : 'https://wa.me/972584468642';
        const waLink = 'https://wa.me/972559943214';
        this.waLink = this.sanitizer.bypassSecurityTrustUrl(waLink);
    }

    ngOnDestroy(): void {
        // this.mobileQuery.removeListener(this._mobileQueryListener);
    }

    closeSnav() {
        this.snav.close();
    }

    snavOpenedChange(e) {
        // if (this.mobileQuery.matches){
        //   this.appStateService.sideMenuIsOpen.next(e);
        // }
    }

    useLanguage(language: string) {
        let lang = language;
        if (lang === 'he' && this.appState.user.usr_gender === 'נקבה') {
            lang = 'he_F';
        }
        this.translate.use(lang);
    }

    logout() {
        this.authService.logout().then(() => {
            this.appState.user = {
                _id: '',
                usr_type: '',
                usr_fname: '',
                usr_lname: '',
                usr_gender: '',
                usr_phone: '',
                usr_email: '',
                usr_club_id: '',
                usr_agreement: false,
                usr_state: null,
            };
            this.appState.accessToken = '';
            this.appState.quizUser = {};
        });
    }

    homeButton() {
        const usrId = this.appState.user._id;
        let navTo = '';
        switch (this.appState.user.usr_type) {
            case 'superadmin':
                navTo = 'Userstable';
                break;

            case 'admin':
                navTo = 'Club';
                break;

            case 'player':
                navTo =
                    this.appState.user['usr_agreement'] && this.appState.user['usr_confirmed']
                        ? `Userprogress`
                        : `Profile/${usrId}`;
                break;

            case 'nutri':
                navTo = 'Userstable';
                break;

            case 'coach':
                // navTo = `Coach`;
                navTo = this.appState.user.usr_agreement ? `Coach` : `Profile/${usrId}`;
                break;

            case 'parent':
                navTo = this.appState.user['usr_agreement'] ? `Userparent` : `Profile/${usrId}`;
                break;

            default:
                // navTo = 'login';
                navTo = 'Profile';
                break;
        }
        this.router.navigate([navTo], { replaceUrl: true });
    }
}
