import { Component, Input, Output, EventEmitter, ViewChild, TemplateRef, ElementRef, ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Apputils } from '@services/app-utils';
import { appState } from '@services/app-state';
import { AppStateService } from '@services/app-state.service';
import { TranslateService } from '@ngx-translate/core';
import { map, debounceTime, distinctUntilChanged, switchMap, take } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

import * as moment from 'moment-timezone';

import { RestApiService } from '@noammazuz/vzn-feathers';
import { TableColumn } from '@noammazuz/vzn-data-table';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';
import { ImageDialogComponent } from '../../shared/image-dialog.component';
import { BarcodeDialogComponent } from '../../shared/barcode-dialog.component';

@Pipe({
  name: 'mediaAssets'
})
export class MediaAssetsPipe implements PipeTransform {
  transform(item: any): any {
    let img = { url: '', width: '', height: '' };
    if (item && item.product_code) {
      // const { GLN, GTIN, filename, width, height } = item.media_assets[0];
      // const url = `https://be.gs1-supplier.mk101.signature-it.com/images/Fittings/gs1-supplier-be/System_Images/Prod_Pic/${GLN}/${GLN}-${GTIN}/${filename}`;
      // if (+(width) && +(height)) {
      //   img = { url, width, height };
      // }
      const url = `https://all-around-player.storage.googleapis.com/pdts/${item.product_code}-xs.jpg`;
      img = { url, width: '50', height: '50' };
    }
    return img;
  }
}

@Component({
  selector: "meal-segment",
  templateUrl: "./fooddiaryquiz-meal-segment.component.html"
})
export class FoodDiaryQuizMealSegmentComponent {
  @ViewChild("mealDialogTemplate") mealDialogTemplate: TemplateRef<any>;
  @ViewChild("waterDialogTemplate") waterDialogTemplate: TemplateRef<any>;
  @ViewChild("tableEl") tableEl: any;
  
  @ViewChild("foodAmountTmpl") foodAmountTmpl: TemplateRef<any>;
  @ViewChild("imageClickableTmpl") imageClickableTmpl: TemplateRef<any>;

  @ViewChild("topline") topline: ElementRef;
  // @ViewChild("bottomline") bottomline: ElementRef;

  @Input() nutritionistFormMode: boolean;
  @Input() userId: string;

  // @Input() allMeals: any[];
  _allMeals: any[] = [];
  @Input()
  set allMeals(meals: any) {
    // this._allMeals = meals;
    this._allMeals = meals ? Array.isArray(meals) ? meals : Object.values(meals) : [];
    this.refreshMeals();
  }
  get allMeals() {
    return this._allMeals;
  }

  // @Input() allLastItems: any[];
  _allLastItems: any[] = [];
  @Input()
  set allLastItems(items: any) {
    this._allLastItems = items;
    this.refreshLastItems();
  }
  get allLastItems() {
    return this._allLastItems;
  }

  @Input() parentFormId: string;
  @Input() mealName: string;
  @Input() mealTitle: string;
  // @Input() defaultTime: string = '';
  @Input() restService;
  @Input() uploadUrlService;
  @Input() uploadFolderBase: string;
  @Input() isLocked?: boolean;

  @Input() isMinor?: boolean;
  @Input() isUnderage?: boolean;

  @Output('mealUpdate') mealUpdate: EventEmitter<any> = new EventEmitter();

  // @Output('tableBusy') tableBusy: EventEmitter<boolean> = new EventEmitter();

  columns: Array<{}> = [];
  query: any;

  formContext: any;
  appState = appState;
  appUtils = Apputils;
  allHours = Apputils.allHours;
  lastChosenHour = '';
  // midaBarcode = '';
  // midaOptions = [];

  openLastItems = true;

  openExtra = false;

  nutArray = ['food_energy', 'protein', 'carbohydrates', 'total_fat'];
  nutExtraArray = [
    'alcohol',
    'moisture',
    'total_dietary_fiber',
    'calcium',
    'iron',
    'magnesium',
    'phosphorus',
    'potassium',
    'sodium',
    'zinc',
    'copper',
    'vitamin_a_iu',
    'vitamin_a_re',
    'carotene',
    'vitamin_e',
    'vitamin_c',
    'thiamin',
    'riboflavin',
    'niacin',
    'vitamin_B6',
    'folate',
    'vitamin_B12',
    'cholesterol',
    'satured_fat',
    'butyric',
    'caproic',
    'caprylic',
    'capric',
    'lauric',
    'myristic',
    'palmitic',
    'stearic',
    'oleic',
    'linoleic',
    'linolenic',
    'arachidonic',
    'docosahexanoic',
    'palmitoleic',
    'parinaric',
    'gadoleic',
    'eicosapentaenoic',
    'erucic',
    'docosapentaenoic',
    'mono_satured',
    'poly_satured',
    'choline',
    'fructose',
    'vitamin_d'
  ];
  nutNames = {
    'food_energy': 'קלוריות',
    'protein': 'חלבונים',
    'carbohydrates': 'פחמימות',
    'total_fat': 'שומנים',
  };
  nutExtraNames = {
    'alcohol': 'כהל',
    'moisture': 'לחות',
    'total_dietary_fiber': 'סיבים תזונתיים',
    'calcium': 'סידן',
    'iron': 'ברזל',
    'magnesium': 'מגנזיום',
    'phosphorus': 'זרחן',
    'potassium': 'אשלגן',
    'sodium': 'נתרן',
    'zinc': 'אבץ',
    'copper': 'נחושת',
    'vitamin_a_iu': 'ויטמין A (יב"ל)',
    'vitamin_a_re': 'ויטמין A (RAE)',
    'carotene': 'קרוטן',
    'vitamin_e': 'ויטמין E',
    'vitamin_c': 'ויטמין C',
    'thiamin': 'תיאמין',
    'riboflavin': 'ריבופלאוין',
    'niacin': 'ניאצין',
    'vitamin_B6': 'ויטמין B6',
    'folate': 'פולאצין',
    'vitamin_B12': 'ויטמין B12',
    'cholesterol': 'כולסטרול',
    'satured_fat': 'שומן רווי',
    'butyric': 'ח.בוטירית 4:0',
    'caproic': 'ח.קפרואית 6:0',
    'caprylic': 'ח.קפרילית 8:0',
    'capric': 'ח.קפרית 10:0',
    'lauric': 'ח.לאורית 12:0',
    'myristic': 'ח.מיריסטית 14:0',
    'palmitic': 'ח.פלמיטית 16:0',
    'stearic': 'ח.סטיארית 18:0',
    'oleic': 'ח.אולאית 18:1',
    'linoleic': 'ח.לינולאית 18:2',
    'linolenic': 'ח.לינולנית 18:3',
    'arachidonic': 'ח.ארכידונית 20:4',
    'docosahexanoic': 'ח.דוקוסאהקסנואית DHA 22:6',
    'palmitoleic': 'ח.פלמיטולאית16:1',
    'parinaric': 'ח.פארינארית 18:4',
    'gadoleic': 'ח.גדולאית 20:1',
    'eicosapentaenoic': 'ח.אייקוסאפנטאינואית EPA 20:5',
    'erucic': 'ח.ארוסית 22:1',
    'docosapentaenoic': 'ח.דוקוספנטאינואית DPA 22:5',
    'mono_satured': 'ח.שומן חד לא רווי',
    'poly_satured': 'ח.שומן רב לא רווי',
    'choline': 'כולין',
    'fructose': 'פרוקטוז',
    'vitamin_d': 'ויטמין D'
  };

  allCalculatedNut: any;
  inMealNut: any;
  inMealExtraNut: any;
  
  allMealsCount: any;
  allMealsFiltered: any[] = [];
  allLastItemsFiltered: any[] = [];
  allLastItemsFilteredCustom: any[] = [];

  tableIsLoading = false;

  showButtonsFlag = false;
  mealSearched = false;
  lastMealsBySearch: any[] = [];
  serverMealsBySearch: any[] = [];
  searchInProgress = false;

  mealDialogRef: MatDialogRef<CustomDialogComponent, any>;

  searchServerSubject: Subject<string>;

  mealDialogForm: FormGroup;
  allFrags: string[] = [];
  
  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public appStateService: AppStateService,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private changeDetectorRef: ChangeDetectorRef,
    public restApiService: RestApiService<any>

    ) {

    this.formContext = this;
    
    this.searchServerSubject = new Subject<string>();
    this.searchServerSubject.pipe(debounceTime(500)).subscribe((value: string) => {
        this.searchServer(value);
    });

    this.route.fragment.subscribe(frag => {

      this.allFrags.push(frag);
      // console.log('allFrags', this.allFrags);

      const lastFrag = this.allFrags.length > 1 ? this.allFrags[this.allFrags.length - 2] : '';

      // console.log('lastFrag', lastFrag);
      // console.log('frag', frag);

      if ((frag === 'meal' && (lastFrag === 'item' || lastFrag === 'search')) || (frag === 'search' && lastFrag === 'item')) {
        this.goBack(this.mealDialogForm, true);
      }

      if (this.dialog.openDialogs.length && this.mealDialogRef && ['meal', 'item', 'search'].indexOf(frag) === -1) {
        this.mealDialogRef.close();
      }
      
    });
  }

  ngOnDestroy() {
    // if (this.dialog.openDialogs.length) {
    //   this.dialog.closeAll();
    // }
    if (this.dialog.openDialogs.length && this.mealDialogRef) {
      this.mealDialogRef.close();
    }
  }

  async searchServer(value: string) {
    if (!value) {
      this.serverMealsBySearch = [];
      this.searchInProgress = false;
      return;
    }
    this.searchInProgress = true;

    // const regex = new RegExp(value.length < 3 ? '^'+value : value, 'i');

    const result = await this.restApiService.service('fooditem').find({
      query: {
        // $paginate: false,
        // $limit: -1,
        $limit: 9999,
        // $select: ['barcode', 'title', 'item_type', 'food_energy', 'protein', 'carbohydrates', 'total_fat'],
        $sort: {
          title: 1
        },
        title: { $regex: value.length < 3 ? '^'+value : value, $options: '$i' }
        // title: { $in: [ regex ] },
      }
    }).catch((err: any) => { console.error(err); });
    this.searchInProgress = false;
    this.serverMealsBySearch = result?.data || [];
  }

  ngAfterViewInit() {
    this.columns = this.createColumns();
    this.getSetGlobalMealTime();
    // this.refreshMeals();
  }

  refreshLastItems() {
    const lastItems = this.allLastItems || [];
    // this.allLastItemsFiltered = lastItems.filter(item => item.fdq_day_info_meal_name === this.mealName && (item.fdq_day_info_item_type === 'FOOD' || item.fdq_day_info_item_type === 'DRINK'));
    // this.allLastItemsFilteredCustom = lastItems.filter(item => item.fdq_day_info_meal_name === this.mealName && item.fdq_day_info_item_type === 'MEAL');

    const unique = lastItems.filter((item: any, idx: number) => {
      if (item.fdq_day_info_item_barcode) {
        return lastItems.findIndex((i: any) => i.fdq_day_info_item_barcode === item.fdq_day_info_item_barcode) === idx;
      }
      if (item.fdq_day_info_item_real_barcode) {
        return lastItems.findIndex((i: any) => i.fdq_day_info_item_real_barcode === item.fdq_day_info_item_real_barcode) === idx;
      }
      return true;
    });
    
    this.allLastItemsFiltered = unique.filter(item => item.fdq_day_info_item_type === 'FOOD' || item.fdq_day_info_item_type === 'DRINK');
    this.allLastItemsFilteredCustom = unique.filter(item => item.fdq_day_info_item_type === 'MEAL');

    // this.lastMealsBySearch = lastItems;
    this.lastMealsBySearch = this.allLastItemsFiltered;
  }

  refreshMeals() {
    const meals = this.allMeals || [];
    if (!this.isUnderage) {
      this.allCalculatedNut = this.calcNut(this.nutArray, meals);
    }
    // this.allMealsFiltered = meals.filter(meal => {
    //   return (meal.fdq_day_info_meal_name === this.mealName && ['MEAL', 'FOOD', 'DRINK', 'WATER'].includes(meal.fdq_day_info_item_type))
    // });
    this.allMealsFiltered = meals.filter((meal: any) => meal.fdq_day_info_meal_name === this.mealName && ['MEAL', 'FOOD', 'DRINK', 'WATER'].includes(meal.fdq_day_info_item_type)).sort((a: any, b: any) => {
      let minA = 0;
      let minB = 0;
      if (a.fdq_day_info_time) {
        const splitted = a.fdq_day_info_time.split(":");
        minA = +(splitted[0]) * 60 + +(splitted[1]);
      }
      if (b.fdq_day_info_time) {
        const splitted = b.fdq_day_info_time.split(":");
        minB = +(splitted[0]) * 60 + +(splitted[1]);
      }

      if (minA < minB) return -1;
      if (minB > minA) return 1;
      return 0;
    });

    this.allMealsCount = this.allMealsFiltered.length;

    this.tableIsLoading = false;
  }

  clearForm() {
    delete this.query;
    delete this.allCalculatedNut;
    delete this.allMealsCount;
    this.lastChosenHour = '';
    
    // this.midaBarcode = '';
    // this.midaOptions = [];
  }

  private _filterItems(itemsArray: any, title: string): any {
    const filterValue = title.trim().toLowerCase();
    return itemsArray.filter(item => String(item.title).toLowerCase().indexOf(filterValue) != -1);
  }

  displayFnItems(item?: any): string | undefined {
    return item ? (item.title) : undefined;
  }

  // chooseAcItem(form: FormGroup) {
  //   // let item = form.value.extra_field_input_items;
  //   let item = form.get('extra_field_input_items').value;
  //   // check if it's a valid user object
  //   if (typeof item !== 'object' || (typeof item === 'object' && !item._id) ) {
  //     console.log("it's not a valid object");
  //     return;
  //   }
  //   const newItem = this.newProductMapper(item);
  //   const { title, item_type, barcode, real_barcode, imgs, midas } = newItem;
  //   // const allMidas = this.tmpMidaArr.filter((mida: any) => mida.barcode === barcode);
  //   // this.midaOptions = allMidas;
  //   let foodType = '';
  //   if (item_type && item_type.trim().toLowerCase() === 'liquid') {
  //     foodType = 'DRINK';
  //   }
  //   if (item_type && item_type.trim().toLowerCase() === 'solid') {
  //     foodType = 'FOOD';
  //   }
  //   if (!title || !foodType || (!barcode && !real_barcode)) { 
  //     console.log("the item is missing some details");
  //     return;
  //   }

  //   // form.get("fdq_day_info_item_type").setValue(foodType);
  //   // form.get("fdq_day_info_item_name").setValue(title);
  //   // form.get("fdq_day_info_item_barcode").setValue(barcode);
  //   // form.get("fdq_day_info_item_real_barcode").setValue(real_barcode);
  //   const assets = imgs || [];
  //   const assetsArray: FormArray = form.get('fdq_day_info_assets') as FormArray;
  //   if (assets && assets.length > 0) {
  //     for (let i = 0; i < assets.length; i++) {
  //       const newFormElement = this.formBuilder.control({});
  //       assetsArray.push(newFormElement);
  //     }
  //     // form.get("fdq_day_info_assets").setValue(imgs);
  //   } else {
  //     assetsArray.clear();
  //   }

  //   // const keysArray = ['food_energy', 'protein', 'carbohydrates', 'total_fat'];
  //   const keysArray = ['food_energy', 'protein', 'carbohydrates', 'total_fat',
  //     'alcohol',
  //     'moisture',
  //     'total_dietary_fiber',
  //     'calcium',
  //     'iron',
  //     'magnesium',
  //     'phosphorus',
  //     'potassium',
  //     'sodium',
  //     'zinc',
  //     'copper',
  //     'vitamin_a_iu',
  //     'vitamin_a_re',
  //     'carotene',
  //     'vitamin_e',
  //     'vitamin_c',
  //     'thiamin',
  //     'riboflavin',
  //     'niacin',
  //     'vitamin_B6',
  //     'folate',
  //     'vitamin_B12',
  //     'cholesterol',
  //     'satured_fat',
  //     'butyric',
  //     'caproic',
  //     'caprylic',
  //     'capric',
  //     'lauric',
  //     'myristic',
  //     'palmitic',
  //     'stearic',
  //     'oleic',
  //     'linoleic',
  //     'linolenic',
  //     'arachidonic',
  //     'docosahexanoic',
  //     'palmitoleic',
  //     'parinaric',
  //     'gadoleic',
  //     'eicosapentaenoic',
  //     'erucic',
  //     'docosapentaenoic',
  //     'mono_satured',
  //     'poly_satured',
  //     'choline',
  //     'fructose',
  //     'vitamin_d'
  //   ];

  //   let nutData = {};
  //   for (const key of keysArray) {
  //     nutData[key] = newItem[key];
  //   }
  //   // form.get("fdq_day_info_item_nut_data").setValue(nutData);
    
  //   form.patchValue({
  //     fdq_day_info_item_name: title,
  //     fdq_day_info_item_type: foodType,
  //     fdq_day_info_item_barcode: barcode,
  //     fdq_day_info_item_real_barcode: real_barcode,
  //     fdq_day_info_assets: imgs,
  //     fdq_day_info_item_nut_data: nutData,
  //     fdq_day_info_item_nut_midas: midas,
      
  //     // _id: '',
  //     fdq_day_info_item_description: '',
  //     fdq_day_info_quantity: '',
  //     fdq_day_info_units: '',
  //     fdq_day_info_weight_or_volume: '',
  //     fdq_day_info_calories: '',
  //     fdq_day_info_portion: '1',
  //   }); // }, { emitEvent: false });

  //   this.changeDetectorRef.detectChanges();
    
  //   // form.markAsUntouched();
  //   // this.midaOptions = [];

  // }

  clearSelection(form: FormGroup) {
    let assetsArray: FormArray = form.get('fdq_day_info_assets') as FormArray;
    // for (let i = 0; i < assetsArray.length; i++) {
    //   assetsArray.removeAt(0);
    // }
    assetsArray.clear();
    // console.log(form.value.extra_field_input_items)
    
    form.patchValue({
      fdq_day_info_item_name: '',
      fdq_day_info_item_description: '',
      fdq_day_info_item_id: '',
      _id: '',
      fdq_day_info_item_type: 'MEAL',
      fdq_day_info_item_barcode: '',
      fdq_day_info_item_real_barcode: '',
      fdq_day_info_item_nut_data: '',
      fdq_day_info_item_nut_midas: '',
      fdq_day_info_quantity: '',
      fdq_day_info_units: '',
      fdq_day_info_weight_or_volume: '',
      fdq_day_info_calories: '',
      fdq_day_info_portion: '1',

      // extra_field_meal_search: '',
      // extra_field_is_edit: false,

    }); // , { emitEvent: false }
    form.markAsUntouched();
    // this.midaBarcode = '';
    // this.midaOptions = [];
    // if (typeof form.get('extra_field_input_items').value === 'string') {
    //   form.get('extra_field_input_items').reset('');
    // }
  }

  // async fetchMidaByBarcode(barcode: string) {
  //   return await this.restApiService.service('foodmida').find({
  //     query: {
  //       // $paginate: false,
  //       $limit: -1,
  //       $select: ['title', 'sum'],
  //       barcode: barcode,
  //       title: {
  //         $nin: ['גרמים', 'קילוגרם']
  //       }
  //     }
  //   });
  // }

  async getSetGlobalMealTime(time?: string) {
    let mealCurrentTime = '';
    let mealCurrentId = '';

    if (this.allMeals && this.allMeals.length) {
      const res = this.allMeals.find(meal => meal.fdq_day_info_meal_name === this.mealName && meal.fdq_day_info_item_type === 'HOUR')
      if (res && res._id) {
        mealCurrentTime = res.fdq_day_info_time;
        mealCurrentId = res._id;
      }
    }
    // if (time && time !== mealCurrentTime) {
    //   if (mealCurrentId) {
    //     const res = await this.restService.patch(mealCurrentId, { fdq_day_info_time: time });
    //     if (res && res.fdq_day_info_time) {
    //       mealCurrentTime = res.fdq_day_info_time;
    //     }
    //   } else {
    //     let hourGroup: FormGroup = this.formBuilder.group({
    //       // _id: '',
    
    //       fdq_day_info_usr_id: this.userId,
    //       fdq_day_info_fdq_day_id: this.parentFormId,
    //       fdq_day_info_meal_name: this.mealName,
    //       fdq_day_info_item_type: 'HOUR',
    //       fdq_day_info_time: time,
    //     });

    //     const res = await this.onSubmitMeal(hourGroup, true);
    //     if (res && res.fdq_day_info_time) {
    //       mealCurrentTime = res.fdq_day_info_time;
    //       // this.mealUpdate.emit();
    //     }
    //   }
    // }
    this.lastChosenHour = mealCurrentTime;
  }

  // async submitMealPhoto(e) {
  //   // console.log('submitMealPhoto', e);
  //   if (!this.restService || !this.mealName || !this.photoGroup) { return; } // || !this.parentFormId

  //   let photoGroup: FormGroup = this.photoGroup;

  //   photoGroup.get("fdq_day_info_usr_id").setValue(this.userId);
  //   photoGroup.get("fdq_day_info_fdq_day_id").setValue(this.parentFormId);
  //   photoGroup.get("fdq_day_info_meal_name").setValue(this.mealName);
  //   photoGroup.get("fdq_day_info_item_type").setValue('PHOTO');
  //   // const assets = photoGroup.value.fdq_day_info_assets;
  //   // for (let asset of assets) {
  //   //   delete asset._id;
  //   // }
  //   const res = await this.onSubmitMeal(photoGroup, true);
  //   // console.log(res);
  //   if (res && res._id) {
  //     this.photoGroup.patchValue(res);
  //     this.mealUpdate.emit(true);
  //   }
  // }

  // async getMealPhoto() {
  //   if (this.allMeals && this.allMeals.length) {
  //     const res = this.allMeals.find(meal => meal.fdq_day_info_meal_name === this.mealName && meal.fdq_day_info_item_type === 'PHOTO')
  //     if (res && res._id) {
  //       const assets = res.fdq_day_info_assets || [];
  //       const assetsArray: FormArray = this.photoGroup.get('fdq_day_info_assets') as FormArray;
  //       for (let i = 0; i < assets.length; i++) {
  //         // const newFormElement = createAssetItem();
  //         const newFormElement = this.formBuilder.control({});
  //         assetsArray.push(newFormElement);
  //       }
  //       this.photoGroup.patchValue(res);
  //     }
  //     this.mealPhotoArr = [res];
  //   } else {
  //     this.mealPhotoArr = [];
  //   }
  // }

  
  async cloneItemServer(form: FormGroup, item: any) {
    const { fdq_day_info_item_id, fdq_day_info_item_barcode, fdq_day_info_item_real_barcode, fdq_day_info_quantity, fdq_day_info_units, fdq_day_info_weight_or_volume, fdq_day_info_portion, fdq_day_info_item_description } = item;
    
    let res: any;
    if (fdq_day_info_item_barcode) {
      res = await this.restApiService.service('fooditem').find({
        query: {
          barcode: fdq_day_info_item_barcode,
          $limit: 1,
        },
      });
    }
    if (fdq_day_info_item_real_barcode) {
      const regex = new RegExp(fdq_day_info_item_real_barcode, 'i');
      res = await this.restApiService.service('products').find({
        query: {
          product_code: { $regex: fdq_day_info_item_real_barcode, $options: '$i' },
          // product_code: { $in: [ regex ] },
          $limit: 1,
        }
      });
    }
    if (res && res.data && res.data[0]) {
      await this.addFromServer(form, res.data[0]);
      
      if (fdq_day_info_quantity && fdq_day_info_portion) {

        form.patchValue({
          fdq_day_info_item_id,
          fdq_day_info_portion,
          fdq_day_info_quantity,
          fdq_day_info_item_description: fdq_day_info_item_description || '',
          fdq_day_info_units: fdq_day_info_units || '',
          fdq_day_info_weight_or_volume: fdq_day_info_weight_or_volume || '',
          // fdq_day_info_calories: fdq_day_info_calories || '',
        });

      }
    }
  }


  cloneItemCustom(form: FormGroup, item: any) {
    // console.log(item);
    if (form.value.fdq_day_info_item_name) {
      this.clearSelection(form);
    }
    let newItem = { ...item };
    delete newItem._id;
    newItem.fdq_day_info_meal_name = this.mealName;
    const assets = newItem.fdq_day_info_assets || [];
    if (assets && assets.length > 0) {
      const assetsArray: FormArray = form.get('fdq_day_info_assets') as FormArray;
      for (let i = 0; i < assets.length; i++) {
        // const newFormElement = createAssetItem();
        const newFormElement = this.formBuilder.control({});
        assetsArray.push(newFormElement);
      }
    }
    form.patchValue(newItem);
    
    this.router.navigate([], { fragment: 'item' });
    
    setTimeout(() => {
      if (this.topline) {
        this.topline.nativeElement.scrollIntoView({ behavior: "smooth" });
      }
    }, 200);
  }

  addMealItem(mealName: string, mealItemType: string, mealTitle: string) {

    if (mealItemType != "MEAL" && mealItemType != "FOOD" && mealItemType != "DRINK" && mealItemType != "WATER") {
      console.error(`Wrong type: ${mealItemType}`);
      return;
    }

    let mealItem: FormGroup = this.createMealItem(this.formContext);
    // mealItem.get("fdq_day_info_usr_id").setValue(this.userId);
    // mealItem.get("fdq_day_info_fdq_day_id").setValue(this.parentFormId);
    // mealItem.get("fdq_day_info_meal_name").setValue(mealName);
    // mealItem.get("fdq_day_info_item_type").setValue(mealItemType);
    // mealItem.get("fdq_day_info_portion").setValue("1");

    // mealItem.get("fdq_day_info_time").setValue(this.lastChosenHour ? this.lastChosenHour : this.defaultTime);

    const now = moment();
    const hour = now.hour();
    const minutes = Math.trunc(now.minute()/15)*15;
    const nowTime = (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes);

    mealItem.patchValue({
      fdq_day_info_usr_id: this.userId,
      fdq_day_info_fdq_day_id: this.parentFormId,
      fdq_day_info_meal_name: mealName,
      fdq_day_info_item_type: mealItemType,
      fdq_day_info_portion: '1',
      // fdq_day_info_time: (this.lastChosenHour ? this.lastChosenHour : this.defaultTime),
      fdq_day_info_time: nowTime,
    });

    this.addOrEditMealItemDialog(mealItem, false);
  }

  addOrEditMealItemDialog(mealItem: FormGroup, isEdit?: boolean) {

    const mealType = mealItem.value.fdq_day_info_item_type;
    if (!mealType) {
      return;
    }

    let template: any;
    let title = '';

    if (mealType === "FOOD") {
      title = this.translate.instant(isEdit ? "food_diary.EDIT_FOOD_TITLE" : "food_diary.ADD_FOOD_TITLE");
      template = this.mealDialogTemplate;
    }
    if (mealType === "DRINK") {
      title = this.translate.instant(isEdit ? "food_diary.EDIT_DRINK_TITLE" : "food_diary.ADD_DRINK_TITLE");
      template = this.mealDialogTemplate;
    }
    if (mealType === "MEAL") {
      title = this.translate.instant(isEdit ? "food_diary.EDIT_MEAL_TITLE" : "food_diary.ADD_MEAL_TITLE");
      template = this.mealDialogTemplate;
      if (!isEdit && !mealItem.value.fdq_day_info_item_name && this.isMinor) {
        mealItem.get("fdq_day_info_item_name").setValue(this.mealTitle);
      }
    }
    if (mealType === "WATER") {
      title = this.translate.instant(isEdit ? "food_diary.EDIT_WATER_TITLE" : "food_diary.ADD_WATER_TITLE");
      template = this.waterDialogTemplate;
      mealItem.get("fdq_day_info_item_name").setValue('מים');
    // } else {
    //   mealItem.get("fdq_day_info_time").setValue(this.lastChosenHour ? this.lastChosenHour : this.defaultTime);
    }
    
    mealItem.get('extra_field_form_title').setValue(title);

    this.mealDialogForm = mealItem;

    this.mealDialogRef = this.dialog.open(CustomDialogComponent, {
      disableClose: true,
      closeOnNavigation: false,
      maxWidth: '100%',
      // maxWidth: "90vw",
      // minWidth: '35%',
      // minWidth: '300px',
      panelClass: "meal-dialog",
      data: { 
        // title: title, 
        ok: this.translate.instant('general.ok'),
        cancel: this.translate.instant('general.cancel'),
        form: mealItem, 
        template: template, 
        context: this.formContext 
      }
    });

    this.router.navigate([], { fragment: 'meal' });
    if (mealItem.get("fdq_day_info_item_name").value && mealItem.get("fdq_day_info_item_type").value !== 'WATER') {
      setTimeout(() => {
        this.router.navigate([], { fragment: 'item' });
      }, 100);
      // this.router.navigate([], { fragment: 'item' });
    }
    this.showButtonsFlag = false;

    this.mealDialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed');

      // this.showButtonsFlag = false;
      
      mealItem.get('extra_field_form_title').setValue('');

      mealItem.get('extra_field_meal_search').setValue('');
      this.mealSearched = false;
      this.searchInProgress = false;

      this.router.navigate([], { fragment: null, replaceUrl: true });

      this.mealDialogForm = undefined;

      if (!result) {
        console.log("user canceled");
        return;
      }
      
      // if (mealItem.value.fdq_day_info_item_type !== 'WATER') {
      //   await this.getSetGlobalMealTime(mealItem.value.fdq_day_info_time);
      //   mealItem.get("fdq_day_info_time").setValue('');
      // }

      // const midaOptions = mealItem.value.fdq_day_info_item_real_barcode ? (mealItem.value.fdq_day_info_item_nut_midas?.food_energy || []) : (this.midaOptions || []);
      const midaOptions = mealItem.value.fdq_day_info_item_nut_midas?.food_energy || [];

      const quantity = mealItem.value.fdq_day_info_quantity;
      if (quantity != 'כמות מדודה (מ"ל)' && quantity != 'כמות מדודה (גרם)') {
        let sum = '0';
        switch (quantity) {
          case 'כוס (200 מ"ל)':
            sum = '200';
            break;
          case 'פחית (333 מ"ל)':
            sum = '333';
            break;
          case 'בקבוק (500 מ"ל)':
            sum = '500';
            break;
          default:
            const mida = midaOptions.find(val => val.title === quantity);
            if (mida) {
              sum = mida.sum;
            }
            break;
        }
        mealItem.get("fdq_day_info_weight_or_volume").setValue(sum);
      }
      const nutData = mealItem.value.fdq_day_info_item_nut_data;
      // const midas = mealItem.value.fdq_day_info_item_nut_midas;
      const amount = mealItem.value.fdq_day_info_weight_or_volume;
      const portion = mealItem.value.fdq_day_info_portion;
      const calories = this.calcKcal((nutData && nutData['food_energy'] || 0), amount, portion)
      mealItem.get("fdq_day_info_calories").setValue(calories);
      // clean item fields before sending if needed:
      this.cleanMealItemUnusedFields(mealItem);
      // if (this.isMinor) {    
        const description = mealItem.value.fdq_day_info_item_description ? mealItem.value.fdq_day_info_item_description.trim() : '';
        if (mealItem.value.fdq_day_info_item_type == 'MEAL' && description) {
          mealItem.get("fdq_day_info_item_name").setValue(description);
        }
        // mealItem.get("fdq_day_info_item_description").setValue('');
        mealItem.get("fdq_day_info_item_description").setValue(description);
      // }
      console.log("user clicked ok, now saving");

      this.tableIsLoading = true;
      await this.onSubmitMeal(mealItem);
      this.mealUpdate.emit();
      setTimeout(() => {
        this.tableIsLoading = false;
      }, 3000);

    });
  }

  createMealItem(context) {
    let mealItem: FormGroup = context.formBuilder.group({
      _id: '',

      fdq_day_info_usr_id: '',
      fdq_day_info_fdq_day_id: '',

      // possible meal names (in order):
      // BREAKFAST
      // ELEVENSES (11:00 meal)
      // LUNCH
      // TEA (16:00 meal)
      // DINNER
      // NIGHT_SNACK
      fdq_day_info_meal_name: '',
      fdq_day_info_item_description: '',
      fdq_day_info_item_id: '',

      // whether it's FOOD / DRINK
      fdq_day_info_item_type: '',
      fdq_day_info_item_name: ['', Validators.required],
      fdq_day_info_item_barcode: '',
      fdq_day_info_item_real_barcode: '',
      fdq_day_info_item_nut_data: '',
      fdq_day_info_item_nut_midas: '',
      fdq_day_info_time: '',
      fdq_day_info_quantity: '',
      fdq_day_info_units: '',
      fdq_day_info_weight_or_volume: '',
      fdq_day_info_calories: '',
      fdq_day_info_portion: ['', Validators.required],
      // fdq_day_info_asset: [{}],

      extra_field_form_title: [''],

      extra_field_meal_search: [''],
      // extra_field_is_edit: [false],

      // ac fields
      // extra_field_input_items: [''],
      // extra_field_items_options: [''],
      
      fdq_day_info_assets: context.formBuilder.array([]),
    },
    {
      validators: [context.mealCustomValidator.bind(context)]
    });
    
    mealItem.valueChanges.subscribe(async (val: any) => {
      // if (val.fdq_day_info_item_barcode) {
      //   if (val.fdq_day_info_item_barcode !== context.midaBarcode) {
      //     context.midaBarcode = val.fdq_day_info_item_barcode;
      //     context.midaOptions = await context.fetchMidaByBarcode(val.fdq_day_info_item_barcode);
      //   }
      // } else {
      //   context.midaBarcode = '';
      //   context.midaOptions = [];
      // }
      context.inMealNut = context.updateInMealNut(val, context.nutArray);
      context.inMealExtraNut = context.updateInMealNut(val, context.nutExtraArray);
    });

    // mealItem.get("extra_field_items_options").setValue(mealItem.get("extra_field_input_items").valueChanges
    //   .pipe(
    //     map((value: any) => typeof value === 'string' ? value : value.title),
    //     // filter((title: string) => title.length > 2),
    //     debounceTime(500),
    //     distinctUntilChanged(),
    //     // tap(() => {
    //     //   this.errorMsg = "";
    //     //   this.isLoading = true;
    //     // }),
    //     switchMap((value: string) => {
    //       if (value.length > 0) { // > 2
    //         return this.restApiService.service('fooditem').find({
    //           query: {
    //             // $paginate: false,
    //             // $limit: -1,
    //             $limit: 9999,
    //             // $select: ['barcode', 'title', 'item_type', 'food_energy', 'protein', 'carbohydrates', 'total_fat'],
    //             $sort: {
    //               title: 1
    //             },
    //             title: { $regex: value.length < 3 ? '^'+value : value, $options: '$i' }
    //           }
    //         });
    //       } else {
    //         return of('');
    //       }
    //     }),
    //       // .pipe(
    //       //   finalize(() => {
    //       //     this.isLoading = false
    //       //   }),
    //       // ),
    //     map((results: any) => results ? results.data : []),
    //     ),
    //   );

      mealItem.get('extra_field_meal_search').valueChanges.subscribe((val: any) => {
        const trimmed = val.trim();
        // context.lastMealsBySearch = trimmed && context.allLastItems ? context.allLastItems.filter((item: any) => item.fdq_day_info_item_name?.includes(trimmed)) : context.allLastItems;
        context.lastMealsBySearch = trimmed && context.allLastItemsFiltered ? context.allLastItemsFiltered.filter((item: any) => item.fdq_day_info_item_name?.includes(trimmed)) : context.allLastItemsFiltered;

        if (context.mealSearched) {
          context.searchServerSubject.next(trimmed);
        }

      })

    return mealItem;
  }

  updateInMealNut(mealItemValue: any, keysArray: string[]) {
    const nutData = mealItemValue.fdq_day_info_item_nut_data;
    const midas = mealItemValue.fdq_day_info_item_nut_midas;
    const quantity = mealItemValue.fdq_day_info_quantity;
  
    let portions = this.parsePortion(mealItemValue.fdq_day_info_portion);

    // console.log(nutData, quantity, portions)
    if (!nutData || !quantity || !portions) {
      return;
    }

    // const midaOptions = this.midaOptions;
    // const midaOptions = mealItemValue.fdq_day_info_item_real_barcode ? (midas?.food_energy || []) : (this.midaOptions || []);
      const midaOptions = midas?.food_energy || [];

    let sum = 0;
    switch (quantity) {
      
      case 'כמות מדודה (גרם)':
        sum = +(mealItemValue.fdq_day_info_weight_or_volume);
        break;
      
      case 'כמות מדודה (מ"ל)':
        sum = +(mealItemValue.fdq_day_info_weight_or_volume);
        break;
      
      case 'כוס (200 מ"ל)':
        sum = 200;
        break;
      case 'פחית (333 מ"ל)':
        sum = 333;
        break;
      case 'בקבוק (500 מ"ל)':
        sum = 500;
        break;
      default:
        const mida = midaOptions.find(val => val.title === quantity);
        if (mida) {
          sum = mida.sum;
        }
        break;
    }
    const total = (sum * portions);
    let resultObj = {};

    for (const key of keysArray) {
      const nutVal = +(nutData[key])*total/100; // nut value is per 100gr
      if (key == 'food_energy') {
        resultObj[key] = Math.trunc(nutVal);
      } else {
        resultObj[key] = Math.round(nutVal*10)/10 || 0;
      }
    }
    
    const objValues = Object.values(resultObj);
    if (objValues.every(val => !val)) {
      return;
    }
    
    return resultObj;

  }

  cleanMealItemUnusedFields(mealItem: FormGroup) {
    const quantity = mealItem.value.fdq_day_info_quantity;

    if (quantity == 'כמות מדודה (מ"ל)') {
      mealItem.get("fdq_day_info_units").reset('מ"ל');
      return;
    }
    if (quantity == 'כמות מדודה (גרם)') {
      mealItem.get("fdq_day_info_units").reset('גרם');
      return;
    }

    mealItem.get("fdq_day_info_units").reset('');
    // mealItem.get("fdq_day_info_weight_or_volume").reset('');
    
  }

  async onSubmitMeal(mealItem: FormGroup) {
    let res: any;
    if (mealItem.value._id) {
      res = await this.restService.patch(mealItem.value._id, mealItem.value);
    } else {
      delete mealItem.value._id;
      res = await this.restService.create(mealItem.value);
    }
    return res;
  }

  async removeRowOnClick(row: any) {
    // console.log(`Going to remove line with id: ${row._id}`);

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // width: '50%',
      data: { 
        title: this.translate.instant('food_diary.REMOVE_ITEM'),
        subtitle: this.translate.instant('food_diary.REMOVE_ITEM_EXPLAIN') + ' ' + row.fdq_day_info_item_name + "?",
        ok: this.translate.instant('general.yes'),
        cancel: this.translate.instant('general.no'),
      }
    });

    this.router.navigate([], { fragment: 'remove' });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        console.log("user canceled");
        return;
      }
      console.log("user clicked ok, now removing");
      let res = await this.restService.delete(row._id);
      console.log("removed. Now refreshing table.");
      // await this.tableEl.setTableData();
      this.mealUpdate.emit();
    });

  }

  editRowOnClick(row: any) {
    // console.log('editRow', row);
    let mealItem: FormGroup = this.createMealItem(this.formContext);
    const assets = row.fdq_day_info_assets || [];
    if (assets && assets.length > 0) {
      const assetsArray: FormArray = mealItem.get('fdq_day_info_assets') as FormArray;
      for (let i = 0; i < assets.length; i++) { // for (const asset of assets) {
        // const newFormElement = createAssetItem();
        const newFormElement = this.formBuilder.control({});
        assetsArray.push(newFormElement);
      }
    }
    mealItem.patchValue(row);
    this.addOrEditMealItemDialog(mealItem, true);
  }

  createColumns() {
    
    let columns: Array<TableColumn> = [
      { prop: 'fdq_day_info_time', sortable: false, name: this.translate.instant("food_diary.TABLE_COLUMN_TIME") },
      { prop: 'fdq_day_info_item_name', sortable: false, name: this.translate.instant("food_diary.TABLE_COLUMN_DESCRIPTION") },
      { prop: 'fdq_day_info_portion', sortable: false, name: this.translate.instant("food_diary.TABLE_COLUMN_PORTIONS") },
      { prop: 'fdq_day_info_quantity', sortable: false, name: this.translate.instant("food_diary.TABLE_COLUMN_QUANTITY_OR_VOLUME"), customTemplate: this.foodAmountTmpl },
      // { prop: 'fdq_day_info_calories', name: this.translate.instant("food_diary.TABLE_COLUMN_CALORIES") },
      // { prop: 'fdq_day_info_asset.url', name: this.translate.instant("food_diary.TABLE_COLUMN_PICTURE") , cellTemplate: 'imageTmpl', height: 50 },
    ];

    if (!this.isUnderage) {
      columns.push({ prop: 'fdq_day_info_calories', sortable: false, name: this.translate.instant("food_diary.TABLE_COLUMN_CALORIES") });
    }

    if (!this.nutritionistFormMode) {
      // columns.push({ prop: 'fdq_day_info_asset.url', name: this.translate.instant("food_diary.TABLE_COLUMN_PICTURE") , cellTemplate: 'imageTmpl', height: 50 });
      // columns.splice(0, 0, { prop: 'fdq_day_info_asset.url', name: this.translate.instant("food_diary.TABLE_COLUMN_PICTURE") , cellTemplate: 'imageTmpl', height: 50 });
      columns.splice(1, 0, { prop: 'fdq_day_info_assets', sortable: false, name: this.translate.instant("food_diary.TABLE_COLUMN_PICTURE") , customTemplate: this.imageClickableTmpl, height: 50 });
      if (!this.isLocked){
        columns.push({ prop: 'edit_button', name: '', iconName: 'edit', cellTemplate: 'iconBtnTmpl', iconOnClick: this.editRowOnClick.bind(this), maxWidth: 100, sortable: false });
        columns.push({ prop: 'remove_button', name: '', iconName: 'remove_circle_outline', cellTemplate: 'iconBtnTmpl', iconOnClick: this.removeRowOnClick.bind(this), maxWidth: 100, sortable: false });
      }
    } else {
      // columns.push({ prop: 'fdq_day_info_asset.url', name: this.translate.instant("food_diary.TABLE_COLUMN_PICTURE") , customTemplate: this.imageClickableTmpl, height: 50 });
      columns.push({ prop: 'fdq_day_info_assets', sortable: false, name: this.translate.instant("food_diary.TABLE_COLUMN_PICTURE") , customTemplate: this.imageClickableTmpl, height: 50 });

      // added id column only to superadmin
      if (this.appState.user.usr_type === 'superadmin') {
        columns.push({ prop: 'fdq_day_info_item_id', sortable: false, name: 'ID', maxWidth: 70 });
      }
    }

    return columns;
  }

  openImageDialog(imgSrc: string) {
    const options = {
      maxWidth: "90vw",
      panelClass: "p-0-dialog",
      data: {
        imgSrc,
        hideDownload: Boolean(this.appState.user.usr_type !== 'superadmin' && this.appState.user.usr_type !== 'nutri'),
      }
    };
    this.dialog.open(ImageDialogComponent, options);
  }

  openDisabledImage(mealPhotoArr) {
    const photoSrc = mealPhotoArr[0] && mealPhotoArr[0].fdq_day_info_assets && mealPhotoArr[0].fdq_day_info_assets[0] && mealPhotoArr[0].fdq_day_info_assets[0].url;
    if (photoSrc) {
      this.openImageDialog(photoSrc);
    }
  }

  mealCustomValidator(formGroup: FormGroup) {
    if (formGroup.value.fdq_day_info_item_type == 'MEAL') {
      if (!(formGroup.value.fdq_day_info_assets && formGroup.value.fdq_day_info_assets[0] && formGroup.value.fdq_day_info_assets[0].url)) {
        return {
          msg: "דרוש להוסיף תמונה"
        }
      }
      if (!this.isMinor && !formGroup.value.fdq_day_info_item_description) {
        return {
          msg: "דרוש להוסיף תיאור"
        }
      }
    }
    
    if (((!this.isMinor || formGroup.value.fdq_day_info_item_type !== 'MEAL') && !formGroup.value.fdq_day_info_quantity) || ((formGroup.value.fdq_day_info_quantity == 'כמות מדודה (מ"ל)' || formGroup.value.fdq_day_info_quantity == 'כמות מדודה (גרם)') && !formGroup.value.fdq_day_info_weight_or_volume)) {
      return {
        msg: "דרוש לציין את הכמות"
      }
    }
    return null;
  }

  calcKcal(kcal: string | number, amount: string | number, portion: string) {
    let count = this.parsePortion(portion);
    const kcalNum = +(kcal);
    const amountNum = +(amount);
    return Math.trunc(count*kcalNum*amountNum/100); // kcal value is per 100gr
  }

  calcNut(keysArray, meals) {
    if (!this.mealName || !meals || !meals.length) {
      return;
    }
    const allMeals = meals.filter(meal => {
      return (meal.fdq_day_info_meal_name === this.mealName && ['FOOD', 'DRINK'].includes(meal.fdq_day_info_item_type))
    });

    let resultObj = {};
    for (const key of keysArray) {
      resultObj[key] = 0;
    }

    allMeals.forEach(meal => {
      const nutData = meal.fdq_day_info_item_nut_data;
      // const midas = meal.fdq_day_info_item_nut_midas;
      let amount = +(meal.fdq_day_info_weight_or_volume);
      let portions: number = this.parsePortion(meal.fdq_day_info_portion);
      let total = (amount * portions);
      
      for (const key of keysArray) {
        const nutVal = nutData ? +(nutData[key]) : 0;
        resultObj[key] += total*nutVal/100; // nut value is per 100gr
      }
      
    });

    for (const key of keysArray) {
      if (key == 'food_energy') {
        resultObj[key] = Math.trunc(resultObj[key]);
      } else {
        resultObj[key] = Math.round(resultObj[key]*10)/10;
      }
    }

    return resultObj;
  }
  
  // async countAllMeals(meals) {
  //   if (!this.mealName || !meals || !meals.length) { // || !this.userId
  //     return 0;
  //   }
  //   const allMeals = meals.filter(meal => {
  //     return (meal.fdq_day_info_meal_name === this.mealName && ['MEAL', 'FOOD', 'DRINK', 'WATER'].includes(meal.fdq_day_info_item_type))
  //   });

  //   return allMeals.length;
  // }
  
  parsePortion(portions: string): number {
    let parsedPorsions: number = 0;
    switch (portions) {
      case "¼":
        parsedPorsions = 0.25;
        break;
      case "½":
        parsedPorsions = 0.5;
        break;
      case "1":
        parsedPorsions = 1;
        break;
      case "1½":
        parsedPorsions = 1.5;
        break;
      case "2":
        parsedPorsions = 2;
        break;
      case "2½":
        parsedPorsions = 2.5;
        break;
      case "3":
        parsedPorsions = 3;
        break;
      case "3½":
        parsedPorsions = 3.5;
        break;
      case "4":
        parsedPorsions = 4;
        break;
      case "4½":
        parsedPorsions = 4.5;
        break;
      case "5":
        parsedPorsions = 5;
        break;
      default:
        const toNum = +(portions);
        if (!isNaN(toNum)) {
          parsedPorsions = toNum;
        }
    }
    return parsedPorsions;
  }

  // chooseCustomItem(form: FormGroup) {
  //   let item = form.value.extra_field_input_items;
  //   // console.log(item);
  //   if (!item) {
  //     if (this.isMinor) {
  //       item = this.mealTitle;
  //     } else {
  //       return;
  //     }
  //   }
  //   form.get("fdq_day_info_item_description").setValue(item);
  //   form.get("fdq_day_info_item_name").setValue(item);
  // }

  // loadingTable(loading: boolean) {
  //   setTimeout(() => {
  //     this.tableIsLoading = loading;
  //     this.tableBusy.emit(loading);
  //   });
  // }

  async newProductMapper(product: any) {
    if (!product?.product_code) {
      if (product.barcode) {
        const res = await this.restApiService.service('foodmida').find({
          query: {
            // $paginate: false,
            $limit: -1,
            $select: ['title', 'sum'],
            barcode: product.barcode,
            title: {
              $nin: ['גרמים', 'קילוגרם']
            }
          }
        });

        // const mapped = res.map(((item: any) => ({ title: item.title, sum: +((item.sum).toFixed(1)) })));
        let mapped = [];
        if (res?.length) {
          for (const item of res) {
            const itemSum = +(item.sum);
            if (!isNaN(itemSum)) {
              mapped.push({ title: item.title, sum: +(itemSum.toFixed(1)) })
            }
          }
        }

        if (product.item_type === 'liquid' && !mapped.find((item: any) => item?.sum === 200)) {
          const cupTitle = this.translate.instant('food_diary.D_QUANTITY_A_1');
          const cupSum = 200;
          mapped.push({ title: cupTitle, sum: cupSum });
        }
        product.midas = { food_energy: mapped };
      }

      return product;
    }
    const { _id, product_code, product_info, media_assets, custom_sizes = [], nut_unit } = product;
    const real_barcode = product_info['Main_Fields']['GTIN'];
    const title = product_info['Main_Fields']['Trade_Item_Description'];
    const uom = product_info.Main_Fields.Net_Content?.UOM || ''; // the product unit, to check if the unit includes מל or ליטר
    let netValue = +(product_info.Main_Fields.Net_Content?.value || 0);
    let item_type = 'solid';
    const barcode = '';

    const imgs = [];
    if (media_assets) {
      for (const media of media_assets) {
        const { GLN, GTIN, filename, width, height } = media;
        // const url = `https://be.gs1-supplier.mk101.signature-it.com/images/Fittings/gs1-supplier-be/System_Images/Prod_Pic/${GLN}/${GLN}-${GTIN}/${filename}`;
        const url = `https://all-around-player.storage.googleapis.com/pdts/${product_code}-md.jpg`;
        if (+(width) && +(height)) {
          imgs.push({ url, width, height });
        }
      }
    }

    // let mapped = { _id, title, item_type, barcode, real_barcode, imgs };
    let mapped: any = { _id, title, barcode, real_barcode, imgs, midas: {} };

    const nutArr = product_info.Nutritional_Values?.table?.rows;
    const nutByCode = {
      '79001': 'food_energy',
      '79002': 'protein',
      '79003': 'carbohydrates',
      '79007': 'total_fat',
      '79008': 'satured_fat',
      // '79009': '',
      '79010': 'cholesterol',
      '79011': 'sodium',
      '79014': 'calcium',
    }
    if (nutArr) {
      for (const nut of nutArr) {
        if (nut && nutByCode[nut.code]) {
          if (nut.fields) {
            const resSolid = nut.fields.find((field: any) => field.col_code === '78001');
            const resLiquid = nut.fields.find((field: any) => field.col_code === '78003');

            if ((resSolid || resLiquid) && (nut_unit === 'ליטר' || nut_unit === 'קג' || uom.includes("קג") || uom.includes("ליטר") || title.includes(" ליטר") || title.includes(" ל'"))) {
              netValue = netValue * 1000;
            }

            if (resLiquid) {
              item_type = 'liquid';
              mapped[nutByCode[nut.code]] = +(resLiquid.value);
              // if (!mapped['midas'][nutByCode[nut.code]]) {

              // }
              mapped['midas'][nutByCode[nut.code]] = nut.fields?.map((field: any) => ({ title: (field.col_label || ''), sum: +((+(field.value || 0) / +(resLiquid.value) * 100).toFixed(1)) }));
              // mapped['midas'][nutByCode[nut.code]] = nut.fields.map((field: any) => field.label ? ({ title: field.label, sum: +((field.labelSize || '').trim()) }) : ({ title: (field.col_label || ''), sum: +((+(field.value || 0) / +(resLiquid.value) * 100).toFixed(1)) }));
            } else if (resSolid) {
              if (nut_unit === 'מל' || nut_unit === 'ליטר' || uom.includes('מל') || uom.includes("ליטר") || title.includes(' מ"ל') || title.includes(" ליטר") || title.includes(" ל'")) {
                item_type = 'liquid';
              }
              
              mapped[nutByCode[nut.code]] = +(resSolid.value);
              // if (!mapped['midas'][nutByCode[nut.code]]) {
                
              // }
              mapped['midas'][nutByCode[nut.code]] = nut.fields?.map((field: any) => ({ title: (field.col_label || ''), sum: +((+(field.value || 0) / +(resSolid.value) * 100).toFixed(1)) }));
              // mapped['midas'][nutByCode[nut.code]] = nut.fields.map((field: any) => field.label ? ({ title: field.label, sum: +((field.labelSize || '').trim()) }) : ({ title: (field.col_label || ''), sum: +((+(field.value || 0) / +(resSolid.value) * 100).toFixed(1)) }));
            }

            if (nut_unit === 'מל' || nut_unit === 'ליטר') {
              item_type = 'liquid';
            }

            if (nut_unit === 'גרם' || nut_unit === 'קג') {
              item_type = 'solid';
            }
            
            const solidFullPackageTitle = 'יחידה/אריזה';
            const liquidFullPackageTitle = 'בקבוק/אריזה';
            if (item_type === 'solid' && netValue && netValue <= 300) {
              const exist = mapped['midas'][nutByCode[nut.code]] && mapped['midas'][nutByCode[nut.code]].find((item: any) => item?.sum && item.sum == netValue);
              if (!exist) {
                mapped['midas'][nutByCode[nut.code]]?.push({ title: solidFullPackageTitle, sum: netValue });
              }
            }
            if (item_type === 'liquid' && netValue && netValue <= 500) {
              const exist = mapped['midas'][nutByCode[nut.code]] && mapped['midas'][nutByCode[nut.code]].find((item: any) => item?.sum && item.sum == netValue);
              if (!exist) {
                mapped['midas'][nutByCode[nut.code]]?.push({ title: liquidFullPackageTitle, sum: netValue });
              }
            }

            const customArr: any[] = Array.isArray(custom_sizes) ? custom_sizes : Object.values(custom_sizes);
            if (customArr.length) {
              for (const custom of customArr) {
                if (typeof custom !== 'object') {
                  continue;
                }
                const { size, label = '' } = custom;
                const sum = +(size || 0);
                const exist = mapped['midas'][nutByCode[nut.code]] && mapped['midas'][nutByCode[nut.code]].find((item: any) => item?.sum && item.sum == sum);
                if (!exist && sum && label) {
                  mapped['midas'][nutByCode[nut.code]]?.push({ title: label.trim(), sum });
                }
              }
            }
            
            if (product.item_type === 'liquid' && !mapped['midas'][nutByCode[nut.code]].find((item: any) => item?.sum === 200)) {
              const cupTitle = this.translate.instant('food_diary.D_QUANTITY_A_1');
              const cupSum = 200;
              mapped['midas'][nutByCode[nut.code]]?.push({ title: cupTitle, sum: cupSum });
            }

          }
          // mapped[nutByCode[nut.code]] = +(nut.fields[0]?.value || 0);
        }
      }
    }

    mapped.item_type = item_type;

    return mapped;
  }

  // async addBarcode(barcode: string, form: FormGroup) {
  //   const item = await this.restApiService.service('products').find({
  //     query: {
  //       product_code: { $regex: barcode, $options: '$i' }
  //     }
  //   });

  //   if (item && item.data && item.data.length && form) {
  //     // const mapped = this.newProductMapper(item.data[0]);
  //     // form.get("extra_field_input_items").setValue(mapped);
  //     if (form.value.fdq_day_info_item_name) {
  //       this.clearSelection(form);
  //     }
  //     form.get("extra_field_input_items").patchValue(item.data[0], { emitEvent: false });
  //     this.chooseAcItem(form);
  //   } else {
  //     form.get("extra_field_input_items").patchValue('', { emitEvent: false });
  //     setTimeout(() => {
  //       this._snackBar.open('פריט לא נמצא במערכת', this.translate.instant('profile.CLOSE'), {
  //         duration: 5000,
  //         horizontalPosition: 'center',
  //         verticalPosition: 'bottom',
  //       });        
  //     }, 200);
  //   }
  // }

  showScanner(form: FormGroup) {
    if (this.appUtils.isInNativeFlutter()) {
      // this.appState.lastBarcode = '';
      // this.appStateService.setBarcode('');

      this.appUtils.flutterAction(this.appUtils.FlutterActions.SHOW_BARCODE_SCANNER);
      this.appStateService.onBarcodeChange().pipe(take(1)).subscribe(async result => {
        if (result) {
          // await this.addBarcode(result, form);
          await this.addBarcodeServer(form, result);
          this.changeDetectorRef.detectChanges();
        }
      });
    } else {
      const dialogRef = this.dialog.open(BarcodeDialogComponent, {
        minWidth: '25vw',
        minHeight: '35vh',
        maxWidth: '90vw',
        maxHeight: '90vh',
        panelClass: "p-0-dialog",
      });
      dialogRef.afterClosed().subscribe(result => {
        console.log('result', result);
        if (result) {
          // await this.addBarcode(result, form);
          this.addBarcodeServer(form, result);
          // this.changeDetectorRef.detectChanges();
        }
      });
    }
  }

  // lastItemsChange() {
  //   if (!this.openLastItems) {
  //     this.openLastItems = true;
  //     setTimeout(() => {
  //       if (this.bottomline) {
  //         this.bottomline.nativeElement.scrollIntoView({ behavior: "smooth" });
  //       }
  //     }, 200);
  //   } else {
  //     this.openLastItems = false;
  //   }
  // }

  showHideButtons() {
    if (this.showButtonsFlag) {
      this.showButtonsFlag = false;
      this.router.navigate([], { fragment: null, replaceUrl: true });
      return;
    }
    this.showButtonsFlag = true;
    this.router.navigate([], { fragment: 'buttons' });
  }

  goBack(form?: FormGroup, isFrag?: boolean) {
    if (form?.value._id && this.mealDialogRef) {
      this.mealDialogRef.close();
      return;
    }
    if (form?.value.fdq_day_info_item_name) {
      if (isFrag) {
        this.clearSelection(form);
      } else {
        const lastFrag = this.allFrags.length > 1 ? this.allFrags[this.allFrags.length - 2] : 'meal';
        this.router.navigate([], { fragment: lastFrag, replaceUrl: true });
        // this.router.navigate([], { fragment: 'meal', replaceUrl: true });
      }
      return;
    }
    if (this.mealSearched) {
      if (isFrag) {
        this.mealSearched = false;
      } else {
        const lastFrag = this.allFrags.length > 1 ? this.allFrags[this.allFrags.length - 2] : 'meal';
        this.router.navigate([], { fragment: lastFrag, replaceUrl: true });
        // this.router.navigate([], { fragment: 'meal', replaceUrl: true });
      }
      return;
    }
    if (this.mealDialogRef) {
      this.mealDialogRef.close();
    }
  }

  goDone(form?: FormGroup) {
    this.mealDialogRef.close({});
  }

  goBackWater() {
    this.mealDialogRef.close();
  }

  goDoneWater() {
    this.mealDialogRef.close({});
  }

  searchFoodServer(form: FormGroup) {
    this.serverMealsBySearch = [];
    this.mealSearched = true;
    const value = form.value.extra_field_meal_search;
    form.get('extra_field_meal_search').setValue(value);
    // this.searchServerSubject.next(value);
    
    this.router.navigate([], { fragment: 'search' });
  }

  async addFromServer(form: FormGroup, item: any) {
    const newItem = await this.newProductMapper(item);
    const { title, item_type, barcode, real_barcode, imgs, midas } = newItem;
    let foodType = 'FOOD';
    if (item_type && item_type.trim().toLowerCase() === 'liquid') {
      foodType = 'DRINK';
    }
    if (item_type && item_type.trim().toLowerCase() === 'solid') {
      foodType = 'FOOD';
    }
    if (!title || !foodType || (!barcode && !real_barcode)) { 
      console.log("the item is missing some details");
      return;
    }

    const assets = imgs || [];
    const assetsArray: FormArray = form.get('fdq_day_info_assets') as FormArray;
    if (assets && assets.length > 0) {
      for (let i = 0; i < assets.length; i++) {
        const newFormElement = this.formBuilder.control({});
        assetsArray.push(newFormElement);
      }
    } else {
      assetsArray.clear();
    }

    const keysArray = ['food_energy', 'protein', 'carbohydrates', 'total_fat',
      'alcohol',
      'moisture',
      'total_dietary_fiber',
      'calcium',
      'iron',
      'magnesium',
      'phosphorus',
      'potassium',
      'sodium',
      'zinc',
      'copper',
      'vitamin_a_iu',
      'vitamin_a_re',
      'carotene',
      'vitamin_e',
      'vitamin_c',
      'thiamin',
      'riboflavin',
      'niacin',
      'vitamin_B6',
      'folate',
      'vitamin_B12',
      'cholesterol',
      'satured_fat',
      'butyric',
      'caproic',
      'caprylic',
      'capric',
      'lauric',
      'myristic',
      'palmitic',
      'stearic',
      'oleic',
      'linoleic',
      'linolenic',
      'arachidonic',
      'docosahexanoic',
      'palmitoleic',
      'parinaric',
      'gadoleic',
      'eicosapentaenoic',
      'erucic',
      'docosapentaenoic',
      'mono_satured',
      'poly_satured',
      'choline',
      'fructose',
      'vitamin_d'
    ];

    let nutData = {};
    for (const key of keysArray) {
      nutData[key] = newItem[key] || 0;
    }

    form.patchValue({
      fdq_day_info_item_id: item._id,

      fdq_day_info_item_name: title,
      fdq_day_info_item_type: foodType,
      fdq_day_info_item_barcode: barcode,
      fdq_day_info_item_real_barcode: real_barcode,
      fdq_day_info_assets: assets,
      fdq_day_info_item_nut_data: nutData,
      fdq_day_info_item_nut_midas: midas,
      
      fdq_day_info_item_description: '',
      fdq_day_info_quantity: '',
      fdq_day_info_units: '',
      fdq_day_info_weight_or_volume: '',
      fdq_day_info_calories: '',
      fdq_day_info_portion: '1',
    });

    // this.changeDetectorRef.detectChanges();

    this.router.navigate([], { fragment: 'item' });
    
  }
  
  addCustomItem(form: FormGroup) {
    const value = form.value.extra_field_meal_search || '';
    let trimmed = value.trim();
    if (!trimmed) {
      if (this.isMinor) {
        trimmed = this.mealTitle;
      } else {
        return;
      }
    }
    form.patchValue({
      fdq_day_info_item_description: trimmed,
      fdq_day_info_item_name: trimmed,
    });

    this.router.navigate([], { fragment: 'item' });
  }

  async addBarcodeServer(form: FormGroup, barcode: string) {
    const regex = new RegExp(barcode, 'i');
    const item = await this.restApiService.service('products').find({
      query: {
        product_code: { $regex: barcode, $options: '$i' }
        // product_code: { $in: [ regex ] },
      }
    });

    if (item && item.data && item.data[0] && form) {
      setTimeout(async () => {
        await this.addFromServer(form, item.data[0]);
      }, 200);
    } else {
      setTimeout(() => {
        this._snackBar.open('פריט לא נמצא במערכת', this.translate.instant('profile.CLOSE'), {
          duration: 5000,
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
        });        
      }, 200);
    }
  }

}
