import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { appState } from '@services/app-state';

import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { Apputils } from '@services/app-utils';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  templateUrl: "./sleepingquiz.component.html"
})
export class SleepingquizComponent {

  sleepingquizForm: FormGroup;

  quizPrefix = 'slq';

  restService: any;

  appState = appState;

  // allHours = Apputils.allHours;
  sleepingHours = Apputils.sleepingHours;
  wakingHours = Apputils.wakingHours;

  isLocked = false;
  isBusy = false;

  showErrors = false;

  constructor(
    private dialogService: ConfirmDialogService,
    private restApiService: RestApiService<any>,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    private translate: TranslateService,
  ) {

    this.restService = this.restApiService.service('sleepingquiz');

    this.sleepingquizForm = this.formBuilder.group({
      _id: [''],

      slq_form_is_real: [false],
      slq_usr_id: [appState.user._id],

      slq_weekday_sleeping_time: ['22:00', Validators.required],
      slq_weekday_wakeup_time: ['07:00', Validators.required],
      slq_weekend_sleeping_time: ['22:00', Validators.required],
      slq_weekend_wakeup_time: ['07:00', Validators.required],
      slq_waking_difficulty: ['', Validators.required],
      slq_tiredness_during_day: ['', Validators.required],
      slq_sleeping_difficulty: ['', Validators.required],
      slq_sleeping_difficulty_description: ['', [this.conditionalValidator(() => this.sleepingquizForm.value.slq_sleeping_difficulty == "כן",
        Validators.required,
        null)]],
      slq_sleeping_difficulty_duration: ['', [this.conditionalValidator(() => this.sleepingquizForm.value.slq_sleeping_difficulty == "כן",
        Validators.required,
        null)]],
      slq_screen_habits: ['', Validators.required],
      slq_nap_habits: ['', Validators.required],
      slq_nap_habits_frequency: ['', [this.conditionalValidator(() => this.sleepingquizForm.value.slq_nap_habits == "כן",
        Validators.required,
        null)]],
      slq_nap_habits_time: ['', [this.conditionalValidator(() => this.sleepingquizForm.value.slq_nap_habits == "כן",
        Validators.required,
        null)]],
      slq_more_comments: ['', Validators.required],
      slq_more_comments_text: ['', [this.conditionalValidator(() => this.sleepingquizForm.value.slq_more_comments == "כן",
        Validators.required,
        null)]],
    });

    // window["rf"] = this.sleepingquizForm;

    this.route.params.subscribe(async params => {
      if (params.id) {
        const data = await this.restService.get(params.id);
        const quizUsrId = data.slq_usr_id;
        if (!quizUsrId) {
            return;
        }
        if (this.appState.user.usr_type === 'superadmin' || this.appState.user.usr_type === 'nutri') {
          this.appState.quizUser = data.quizOwner;
        }

        if (this.translate.currentLang !== 'en') {
          if (data.quizOwner?.usr_gender === 'נקבה') {
              this.translate.use('he_F');
          } else {
              this.translate.use('he');
          }
        }

        this.isLocked = data && data.slq_locked;
        if (this.appState.user.usr_type === 'parent' || this.appState.user.usr_type === 'nutri') {
            this.isLocked = true;
        }
        if (this.appState.user.usr_type === 'nutri' && data.isReportEverPublished !== undefined) {
            this.isLocked = data.isReportEverPublished;
        }
        if (!data.savedAt && !data.slq_weekday_sleeping_time && !data.slq_weekday_wakeup_time && !data.slq_weekend_sleeping_time && !data.slq_weekend_wakeup_time) {
          const lastQuiz = await this.restService.getLast({
            slq_usr_id: quizUsrId,
            _id: {
              $ne: params.id
            }
          });
          if (lastQuiz[0]) {
            const { slq_weekday_sleeping_time, slq_weekday_wakeup_time, slq_weekend_sleeping_time, slq_weekend_wakeup_time, slq_nap_habits, slq_nap_habits_frequency, slq_nap_habits_time } = lastQuiz[0];
            const stripped = { slq_weekday_sleeping_time, slq_weekday_wakeup_time, slq_weekend_sleeping_time, slq_weekend_wakeup_time, slq_nap_habits, slq_nap_habits_frequency, slq_nap_habits_time };
            this.sleepingquizForm.patchValue(stripped);
            // this.patchFormArrays(stripped);

            this.oldValuesPopup();
          }
          this.sleepingquizForm.get('slq_usr_id').patchValue(data.slq_usr_id);
          this.sleepingquizForm.get('_id').patchValue(data._id);
        } else {
          this.sleepingquizForm.patchValue(data);
          // this.patchFormArrays(data);
        }

        if (this.isLocked) {
            this.sleepingquizForm.disable();
        }
      } else {
        const res = await this.restService.getLast({ slq_usr_id: this.appState.user._id });
        const navTo = res[0]?._id ? `Sleepingquiz/${res[0]._id}` : '/';
        this.router.navigate([navTo], { replaceUrl: true });
        return;
      }
    });

  }

  ngOnDestroy() {
    if (this.translate.currentLang !== 'en') {
      if (this.appState.user.usr_gender === 'נקבה') {
          this.translate.use('he_F');
      } else {
          this.translate.use('he');
      }
    }
  }

  oldValuesPopup() {
    const options = {
      title: '',
      message: 'חלק מהתשובות בשאלון זה מולאו אוטומטית על פי התשובות שלך מהפעם הקודמת. במידה שיש שינוי, חשוב לעדכן את התשובות לסטטוס הנוכחי.',
      // cancelText: this.translate.instant("general.no"),
      // confirmText: this.translate.instant("general.yes"),
      confirmText: 'אישור',
    };
    this.dialogService.open(options);
  }

  conditionalValidator(predicate: Function,
    validator: Function,
    parentKeyAndValueEquals?: any, parentKeyAndValueNotEquals?: any): Function {
    return (formControl => {

      if (!formControl.parent) {
        return null;
      }

      let error = null;

      if (parentKeyAndValueEquals) {
        if (formControl.parent.get(parentKeyAndValueEquals.key).value == parentKeyAndValueEquals.value) {
          error = validator(formControl);
        }
      } else if (parentKeyAndValueNotEquals) {
        if (formControl.parent.get(parentKeyAndValueNotEquals.key).value != parentKeyAndValueNotEquals.value) {
          error = validator(formControl);
        }
      } else {
        if (predicate()) {
          error = validator(formControl);
        }
      }

      return error;
    })
  }

  resetField(fieldName, value) {
    this.sleepingquizForm.controls[fieldName].reset(value);
  }

  async onSubmit(form: FormGroup, prefix: string) {
    this.isBusy = true;

    this.clearValuesFromUnusedFields();
    const validateRes = this.validateForm();

    if (!validateRes.valid) {
        // if (validateRes.scrollIntoNativeElement) {
        //   validateRes.scrollIntoNativeElement.nativeElement.scrollIntoView();
        // }
        this.showErrors = true;
        this.showStatus(validateRes.msg, null);
        this.isBusy = false;
        return;
    } else {
        this.showErrors = false;
    }

    this.resetField(`${prefix}_form_is_real`, true);

    // this.clearAllIds();

    if (form.value._id) {
        const res = await this.restService.patch(form.value._id, form.value).catch(e => { console.error(e); });
        if (res && res._id && res[`${prefix}_usr_id`]) {
            // this.buildAssetsArray(form, `${prefix}_assets_arr`, res);
            form.patchValue(res);
            // this.patchFormArrays(res);
            
            this.showStatus(this.translate.instant('general.savedSuccessfully'), null);
            if (this.appState.user.usr_type === 'player'){
              setTimeout(()=>{
                this.router.navigate([`Userprogress`]);
                this.isBusy = false;
              }, 2000);
              return;
            }
        } else {
            this.showStatus('אירעה שגיאה', null);
        }
    }
    
    this.isBusy = false;
  }

  validateForm() {

    if (!this.sleepingquizForm.valid)
      return {
        valid: false,
        msg: "נא לתקן את השדות האדומים"
      }

    return {
      valid: true,
      msg: "ולידציה עברה בהצלחה"
    }

  }

  /*
  Go through all the fields in the form, clean unused ones
  */
  clearValuesFromUnusedFields() {

    if (this.sleepingquizForm.value.slq_sleeping_difficulty == 'לא') {
      this.resetField("slq_sleeping_difficulty_description", '');
      this.resetField("slq_sleeping_difficulty_duration", '');
    }

    if (this.sleepingquizForm.value.slq_nap_habits == 'לא') {
      this.resetField("slq_nap_habits_frequency", '');
      this.resetField("slq_nap_habits_time", '');
    }

    if (this.sleepingquizForm.value.slq_more_comments == 'לא') {
      this.resetField("slq_more_comments_text", '');
    }
  }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];

  }
  
}

