import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { RestApiService, AuthService } from '@noammazuz/vzn-feathers';
import { appState } from './app-state';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {
  // sideMenuIsOpen: Subject<boolean> = new Subject();
  appState = appState;
  private barcodeChanged: Subject<string> = new Subject<string>();

  constructor(
    private restApiService: RestApiService<any>,
    private authService: AuthService<any>
  ) { 
    // this.sideMenuIsOpen.subscribe( res => {
    //   // window.dispatchEvent(new Event('resize'));
    // })

    // this.barcodeChanged.subscribe((value: string) => {
    //   this.appState.lastBarcode = value;
    // });
  }
  
  setBarcode(barcode: string) {
    // this.appState.lastBarcode = '';
    this.barcodeChanged.next(barcode);
  }
  
  onBarcodeChange(): Observable<string> {
      return this.barcodeChanged.asObservable();
  }

  async getLoggedUser() {
    const user = await this.authService.isAuth();
    if (user) {
      console.log('getLoggedUser');
      this.appState.user = user;
    }
    return user;
  }

  async setQuizUser(userId) {
    if (!userId) {
      this.clearQuizUser();
      return;
    };
    try {
      let user = await this.restApiService.service('users').get(userId);
      this.appState.quizUser = user;
    } catch (e) {
      console.error(e);
      this.clearQuizUser();
    }
  }
  clearQuizUser() {
    // delete this.appState.quizUser;
    this.appState.quizUser = {};
  }
}
