<div class="antro-form">
    <mat-expansion-panel disabled expanded>
        <mat-expansion-panel-header>
            <!-- <mat-panel-title class="font-18 font-bold">
                בדיקת דם
            </mat-panel-title>
            <mat-panel-description>
                סטטוס בריאותי, תוצאות בדיקות הדם
            </mat-panel-description> -->
            <mat-panel-title class="font-16 font-bold">
                <h4 class="m-auto">בדיקות דם</h4>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="buttons-row mb125">
            <button *ngIf="oldValues.length > 0" class="plus-button" mat-raised-button (click)="newClonePopupGuard()">
                שכפל בדיקות דם וצור חדש
                <mat-icon>add</mat-icon>
            </button>
            <button class="plus-button" mat-raised-button (click)="newQuizPopupGuard()">
                המלץ על בדיקות חדשות
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <div *ngIf="oldValues.length > 0" class="big-content">
            <div class="row mb125">
                <mat-form-field *ngIf="oldValues.length > 0" class="w40">
                    <!-- <button type="button" *ngIf="selectedA" matSuffix [routerLink]="['/Bloodtestquiz/', selectedIdA]" class="top-button" mat-stroked-button>עבור לטופס</button> -->
                    <a target="quizwindow" *ngIf="selectedA" matSuffix [routerLink]="['/Bloodtestquiz/', selectedIdA]" class="top-button" mat-stroked-button>עבור לטופס</a>
                    <mat-select [disabled]="true" [(ngModel)]="selectedIdA" (ngModelChange)="selectDateA($event)">
                        <ng-container *ngFor="let val of oldValues; let i = index">
                            <mat-option *ngIf="selectedIdB != val._id" [value]="val._id">
                                <!-- {{ val['btq_test_date'] ? (val['btq_test_date'] | date:'dd/MM/yyyy') : val['btq_past_tests'] ? "ראשוני" : "ללא תאריך" }} -->
                                {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <button *ngIf="oldValues.length > 1" mat-stroked-button color="primary" class="w15 wrap-text"
                    (click)="fetchOldValues(true)">אפס לשתי המדידות האחרונות</button>

                <mat-form-field *ngIf="oldValues.length > 1" class="w40">
                    <!-- <button type="button" *ngIf="selectedB" matSuffix [routerLink]="['/Bloodtestquiz/', selectedIdB]" class="top-button" mat-stroked-button>עבור לטופס</button> -->
                    <a target="quizwindow" *ngIf="selectedB" matSuffix [routerLink]="['/Bloodtestquiz/', selectedIdB]" class="top-button" mat-stroked-button>עבור לטופס</a>
                    <mat-select [(ngModel)]="selectedIdB" (ngModelChange)="selectDateB($event)">
                        <ng-container *ngFor="let val of oldValues; let i = index">
                            <mat-option *ngIf="selectedIdA != val._id" [value]="val._id">
                                <!-- {{ val['btq_test_date'] ? (val['btq_test_date'] | date:'dd/MM/yyyy') : val['btq_past_tests'] ? "ראשוני" : "ללא תאריך" }} -->
                                {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="text-center">
                <div class="mt125 mb125" *ngIf="user.usr_blood_known_situation == 'כן'">
                    <div class="font-bold">
                        יש מחלה שבאה לידי ביטוי בבדיקות הדם מהעבר
                    </div>
                    <div *ngIf="user.usr_blood_known_situation_text">
                        פירוט: {{ user.usr_blood_known_situation_text }}
                    </div>
                </div>
                <p class="font-bold" *ngIf="user.usr_blood_known_situation == 'לא'">
                    אין מחלה שבאה לידי ביטוי בבדיקות הדם מהעבר
                </p>

                <div class="mt125 mb125" *ngIf="user.usr_blood_chronic_value == 'כן'">
                    <div class="font-bold">
                        יש ערך שבאופן כרוני נמצא בחסר או בעודף בבדיקות הדם מהעבר
                    </div>
                    <div *ngIf="user.usr_blood_chronic_value_text">
                        פירוט: {{ user.usr_blood_chronic_value_text }}
                    </div>
                </div>
                <p class="font-bold" *ngIf="user.usr_blood_chronic_value == 'לא'">
                    אין ערך שבאופן כרוני נמצא בחסר או בעודף בבדיקות הדם מהעבר
                </p>                
            </div>
            
            <!-- <h3 *ngIf="(selectedA && selectedA['btq_past_tests']) || (selectedB && selectedB['btq_past_tests'])">שאלון בדיקות דם ראשוני</h3> -->
            <div class="row">
                <div class="w40" *ngIf="selectedA && selectedA['btq_past_tests']">
                    <p class="font-18 ul-text">שאלון בדיקות דם ראשוני:</p>
                    <div class="mb125" *ngIf="selectedA['btq_past_tests'] == 'כן'">
                        <div class="font-bold">
                            ביצע בעבר בדיקות דם
                        </div>
                        <div class="font-bold" *ngIf="selectedA['btq_has_tests'] == 'לא'">
                            בדיקות הדם לא ברשותו
                        </div>
                        <!-- <div *ngIf="selectedA['btq_past_test_date']">
                            בתאריך: {{ selectedA['btq_past_test_date'] | date:'dd/MM/yyyy' }}
                        </div> -->
                        <div *ngIf="selectedA['btq_test_reason']">
                            סיבת הבדיקה: {{ selectedA['btq_test_reason'] }}
                        </div>
                        <div *ngIf="selectedA['btq_test_reasons_text']">
                            פירוט: {{ selectedA['btq_test_reasons_text'] }}
                        </div>
                    </div>
                    <p class="font-bold" *ngIf="selectedA['btq_past_tests'] == 'לא'">
                        לא ביצע בעבר בדיקות דם
                    </p>
                </div>
                <div class="">

                </div>
                <div class="w40" *ngIf="selectedB && selectedB['btq_past_tests']">
                    <p class="font-18 ul-text">שאלון בדיקות דם ראשוני:</p>
                    <div class="mb125" *ngIf="selectedB['btq_past_tests'] == 'כן'">
                        <div class="font-bold">
                            ביצע בעבר בדיקות דם
                        </div>
                        <div class="font-bold" *ngIf="selectedB['btq_has_tests'] == 'לא'">
                            בדיקות הדם לא ברשותו
                        </div>
                        <!-- <div *ngIf="selectedB['btq_past_test_date']">
                            בתאריך: {{ selectedB['btq_past_test_date'] | date:'dd/MM/yyyy' }}
                        </div> -->
                        <div *ngIf="selectedB['btq_test_reason']">
                            סיבת הבדיקה: {{ selectedB['btq_test_reason'] }}
                        </div>
                        <div *ngIf="selectedB['btq_test_reasons_text']">
                            פירוט: {{ selectedB['btq_test_reasons_text'] }}
                        </div>
                    </div>
                    <p class="font-bold" *ngIf="selectedB['btq_past_tests'] == 'לא'">
                        לא ביצע בעבר בדיקות דם
                    </p>
                </div>
            </div>

            <h3>סטטוס ביום הבדיקה</h3>

            <div class="row">
                <div class="w40" *ngIf="selectedA">

                    <div class="mb125" *ngIf="selectedA['btq_general_feeling'] == 'כן'">
                        <div class="font-bold">
                            חש ברע בימים שקדמו או ביום לקיחת בדיקת הדם
                        </div>
                        <div *ngIf="selectedA['btq_general_feeling_text']">
                            תיאור ההרגשה: {{ selectedA['btq_general_feeling_text'] }}
                        </div>
                    </div>
                    <p class="font-bold" *ngIf="selectedA['btq_general_feeling'] == 'לא'">
                        לא חש ברע בימים שקדמו או ביום לקיחת בדיקת הדם
                    </p>
                    <p *ngIf="selectedA['btq_more_comments'] == 'כן' && selectedA['btq_more_comments_text']">
                        הערות נוספות בקשר לבדיקות דם: <span class="font-bold">{{ selectedA['btq_more_comments_text'] }}</span>
                    </p>

                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">

                    <div class="mb125" *ngIf="selectedB['btq_general_feeling'] == 'כן'">
                        <div class="font-bold">
                            חש ברע בימים שקדמו או ביום לקיחת בדיקת הדם
                        </div>
                        <div *ngIf="selectedB['btq_general_feeling_text']">
                            תיאור ההרגשה: {{ selectedB['btq_general_feeling_text'] }}
                        </div>
                    </div>
                    <p class="font-bold" *ngIf="selectedB['btq_general_feeling'] == 'לא'">
                        לא חש ברע בימים שקדמו או ביום לקיחת בדיקת הדם
                    </p>
                    <p *ngIf="selectedB['btq_more_comments'] == 'כן' && selectedB['btq_more_comments_text']">
                        הערות נוספות בקשר לבדיקות דם: <span class="font-bold">{{ selectedB['btq_more_comments_text'] }}</span>
                    </p>

                </div>
            </div>


            <!-- <h3>תוצאות</h3> -->


            <div class="row text-center">
                <div class="w40" *ngIf="selectedA">
                    <h4>תוצאות - {{ selectedA['btq_test_date'] ? (selectedA['btq_test_date'] | date:'dd/MM/yyyy') : "ללא תאריך" }}</h4>
                </div>
                <div class="w40" *ngIf="selectedB">
                    <h4>תוצאות - {{ selectedB['btq_test_date'] ? (selectedB['btq_test_date'] | date:'dd/MM/yyyy') : "ללא תאריך" }}</h4>
                </div>
            </div>
            <div class="row text-center">
                <div class="w40" *ngIf="selectedA && notesFormA">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'hem',
                                valueName: 'המוגלובין',
                                unitType: 'g/dl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'prt',
                                valueName: 'פריטין',
                                unitType: 'ng/ml'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'irn',
                                valueName: 'ברזל',
                                unitType: 'micg/dl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'trn',
                                valueName: 'טרנספרין',
                                unitType: 'mg/dl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'irn_trn_calc',
                                valueName: 'יחס ברזל/טרנספרין',
                                unitType: '',
                                isReadonly: true
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'mcv',
                                valueName: 'MCV',
                                unitType: 'fl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'mch',
                                valueName: 'MCH',
                                unitType: 'pg/cell'
                            }">
                        </ng-container>
                    </div>

                    <!-- <notes *ngIf="notesFormA.value._id" [notesArr]="notesList" [selectedForm]="notesFormA"
                        [fieldName]="'notes_nut_bloodvalues'"></notes> -->
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.hem,
                                valueName: 'המוגלובין',
                                unitType: 'g/dl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.prt,
                                valueName: 'פריטין',
                                unitType: 'ng/ml'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.irn,
                                valueName: 'ברזל',
                                unitType: 'micg/dl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.trn,
                                valueName: 'טרנספרין',
                                unitType: 'mg/dl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.irn_trn_calc,
                                valueName: 'יחס ברזל/טרנספרין',
                                unitType: ''
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.mcv,
                                valueName: 'MCV',
                                unitType: 'fl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.mch,
                                valueName: 'MCH',
                                unitType: 'pg/cell'
                            }">
                        </ng-container>
                    </div>

                    <!-- <notes *ngIf="notesFormB.value._id" [notesArr]="notesList" [selectedForm]="notesFormB"
                        [fieldName]="'notes_nut_bloodvalues'"></notes> -->
                </div>
            </div>
            <!-- <h4 class="ul-text"></h4> -->
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <!-- <notes *ngIf="notesFormA.value._id" [notesArr]="notesList['notes_btq_a']"
                        [selectedForm]="notesFormA" [fieldName]="'notes_btq_a'"></notes> -->
                    <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('btq_notes')" fieldKey="A" [oldNotes]="notesFormA.get('notes_btq_a')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'btq', 'A')" (editNoteEvent)="editNote($event, 'btq', 'A')"></notes>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <!-- <notes *ngIf="notesFormB.value._id" [notesArr]="notesList['notes_btq_a']"
                        [selectedForm]="notesFormB" [fieldName]="'notes_btq_a'"></notes> -->
                    <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('btq_notes')" fieldKey="A" [oldNotes]="notesFormB.get('notes_btq_a')" [timeCreated]="selectedB.createdAt"></notes>
                </div>
            </div>
            
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's4', subPrefix: '', title: 'הערות ארוחת בוקר' }"></ng-container>
            </ng-container>

            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's6', subPrefix: '', title: 'הערות ארוחת צהריים' }"></ng-container>
            </ng-container>

            <div class="row text-center">
                <div class="w40" *ngIf="selectedA && notesFormA">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'b12',
                                valueName: 'B12',
                                unitType: 'pg/dl'
                            }">
                        </ng-container>
                    </div>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.b12,
                                valueName: 'B12',
                                unitType: 'pg/dl'
                            }">
                        </ng-container>
                    </div>
                </div>
            </div>

            <!-- <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS4">

            </ng-container>
            
            <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS6">

            </ng-container> -->

            <!-- <h4 class="ul-text"></h4> -->
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('btq_notes')" fieldKey="B" [oldNotes]="notesFormA.get('notes_btq_b')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'btq', 'B')" (editNoteEvent)="editNote($event, 'btq', 'B')"></notes>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('btq_notes')" fieldKey="B" [oldNotes]="notesFormB.get('notes_btq_b')" [timeCreated]="selectedB.createdAt"></notes>
                </div>
            </div>

            <div class="row text-center">
                <div class="w40" *ngIf="selectedA && notesFormA">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueGroupTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'pol',
                                valueName: 'ח פולית',
                                unitTypes: ['ng/ml', 'nmol/L']
                            }">
                        </ng-container>
                    </div>
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'pol_calc',
                                valueName: 'ח פולית',
                                unitType: 'ng/ml',
                                isReadonly: true
                            }">
                        </ng-container>
                    </div>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.pol_calc,
                                valueName: 'ח פולית',
                                unitType: 'ng/ml'
                            }">
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- <h4 class="ul-text"></h4> -->
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('btq_notes')" fieldKey="C" [oldNotes]="notesFormA.get('notes_btq_c')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'btq', 'C')" (editNoteEvent)="editNote($event, 'btq', 'C')"></notes>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('btq_notes')" fieldKey="C" [oldNotes]="notesFormB.get('notes_btq_c')" [timeCreated]="selectedB.createdAt"></notes>
                </div>
            </div>

            <div class="row text-center">
                <div class="w40" *ngIf="selectedA && notesFormA">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueGroupTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'vid',
                                valueName: 'וויטמין D',
                                unitTypes: ['ng/ml', 'nmol/L']
                            }">
                        </ng-container>
                    </div>
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'vid_calc',
                                valueName: 'וויטמין D',
                                unitType: 'ng/ml',
                                isReadonly: true
                            }">
                        </ng-container>
                    </div>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.vid_calc,
                                valueName: 'וויטמין D',
                                unitType: 'ng/ml'
                            }">
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- <h4 class="ul-text"></h4> -->
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('btq_notes')" fieldKey="D" [oldNotes]="notesFormA.get('notes_btq_d')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'btq', 'D')" (editNoteEvent)="editNote($event, 'btq', 'D')"></notes>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('btq_notes')" fieldKey="D" [oldNotes]="notesFormB.get('notes_btq_d')" [timeCreated]="selectedB.createdAt"></notes>
                </div>
            </div>

            <div class="row text-center">
                <div class="w40" *ngIf="selectedA && notesFormA">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'mag',
                                valueName: 'מגנזיום',
                                unitType: 'mg/dl'
                            }">
                        </ng-container>
                    </div>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.mag,
                                valueName: 'מגנזיום',
                                unitType: 'mg/dl'
                            }">
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- <h4 class="ul-text"></h4> -->
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('btq_notes')" fieldKey="E" [oldNotes]="notesFormA.get('notes_btq_e')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'btq', 'E')" (editNoteEvent)="editNote($event, 'btq', 'E')"></notes>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('btq_notes')" fieldKey="E" [oldNotes]="notesFormB.get('notes_btq_e')" [timeCreated]="selectedB.createdAt"></notes>
                </div>
            </div>

            <div class="row text-center">
                <div class="w40" *ngIf="selectedA && notesFormA">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'col',
                                valueName: 'כולסטרול',
                                unitType: 'mg/dl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'tri',
                                valueName: 'טריגליצרידים',
                                unitType: 'mg/dl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'hdl',
                                valueName: 'HDL',
                                unitType: 'mg/dl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'ldl',
                                valueName: 'LDL',
                                unitType: 'mg/dl'
                            }">
                        </ng-container>
                    </div>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.col,
                                valueName: 'כולסטרול',
                                unitType: 'mg/dl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.tri,
                                valueName: 'טריגליצרידים',
                                unitType: 'mg/dl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.hdl,
                                valueName: 'HDL',
                                unitType: 'mg/dl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.ldl,
                                valueName: 'LDL',
                                unitType: 'mg/dl'
                            }">
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- <h4 class="ul-text"></h4> -->
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('btq_notes')" fieldKey="F" [oldNotes]="notesFormA.get('notes_btq_f')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'btq', 'F')" (editNoteEvent)="editNote($event, 'btq', 'F')"></notes>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('btq_notes')" fieldKey="F" [oldNotes]="notesFormB.get('notes_btq_f')" [timeCreated]="selectedB.createdAt"></notes>
                </div>
            </div>

            <div class="row text-center">
                <div class="w40" *ngIf="selectedA && notesFormA">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'glu',
                                valueName: 'גלוקוז',
                                unitType: 'mg/dl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'a1c',
                                valueName: 'המוגלובין A1C',
                                unitType: '%'
                            }">
                        </ng-container>
                    </div>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.glu,
                                valueName: 'גלוקוז',
                                unitType: 'mg/dl'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.a1c,
                                valueName: 'המוגלובין A1C',
                                unitType: '%'
                            }">
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- <h4 class="ul-text"></h4> -->
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('btq_notes')" fieldKey="G" [oldNotes]="notesFormA.get('notes_btq_g')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'btq', 'G')" (editNoteEvent)="editNote($event, 'btq', 'G')"></notes>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('btq_notes')" fieldKey="G" [oldNotes]="notesFormB.get('notes_btq_g')" [timeCreated]="selectedB.createdAt"></notes>
                </div>
            </div>

            <div class="row text-center">
                <div class="w40" *ngIf="selectedA && notesFormA">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'alk',
                                valueName: 'ALKP',
                                unitType: 'u/l'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'tsh',
                                valueName: 'TSH',
                                unitType: 'mcU/ml'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueTemplate" [ngTemplateOutletContext]="{
                                notesForm: notesFormA,
                                fieldName: 'cpk',
                                valueName: 'CPK',
                                unitType: 'mcg/L'
                            }">
                        </ng-container>
                    </div>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <div>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.alk,
                                valueName: 'ALKP',
                                unitType: 'u/l'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.tsh,
                                valueName: 'TSH',
                                unitType: 'mcU/ml'
                            }">
                        </ng-container>
                        <ng-container [ngTemplateOutlet]="bloodValueDisabledTemplate" [ngTemplateOutletContext]="{
                                fieldValue: selectedB.btq_blood_values?.cpk,
                                valueName: 'CPK',
                                unitType: 'mcg/L'
                            }">
                        </ng-container>
                    </div>
                </div>
            </div>
            <!-- <h4 class="ul-text"></h4> -->
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('btq_notes')" fieldKey="H" [oldNotes]="notesFormA.get('notes_btq_h')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'btq', 'H')" (editNoteEvent)="editNote($event, 'btq', 'H')"></notes>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('btq_notes')" fieldKey="H" [oldNotes]="notesFormB.get('notes_btq_h')" [timeCreated]="selectedB.createdAt"></notes>
                </div>
            </div>

        <ng-container *ngIf="(selectedA && selectedA['btq_more_comments'] == 'כן' && selectedA['btq_more_comments_text']) || (selectedB && selectedB['btq_more_comments'] == 'כן' && selectedB['btq_more_comments_text'])">
            <h3>הערות בדיקת דם</h3>
            <div class="row healthquiz-box">
                <div class="w40" *ngIf="selectedA">
                    <p *ngIf="selectedA['btq_more_comments'] == 'כן' && selectedA['btq_more_comments_text']">
                        הערות נוספות בקשר לבדיקות דם: <span class="font-bold">{{ selectedA['btq_more_comments_text'] }}</span>
                    </p>
                </div>
                <div class="w40" *ngIf="selectedB">
                    <p *ngIf="selectedB['btq_more_comments'] == 'כן' && selectedB['btq_more_comments_text']">
                        הערות נוספות בקשר לבדיקות דם: <span class="font-bold">{{ selectedB['btq_more_comments_text'] }}</span>
                    </p>
                </div>
            </div>
        </ng-container>

            <h4 class="ul-text">הערות בדיקת דם</h4>
            <div class="row">
                <div class="w40" *ngIf="selectedA">
                    <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('btq_notes')" fieldKey="J" [oldNotes]="notesFormA.get('notes_btq_j')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'btq', 'J')" (editNoteEvent)="editNote($event, 'btq', 'J')"></notes>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">

                </div> -->
                <div class="w40" *ngIf="selectedB">
                    <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('btq_notes')" fieldKey="J" [oldNotes]="notesFormB.get('notes_btq_j')" [timeCreated]="selectedB.createdAt"></notes>
                </div>
            </div>

            <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <h4 class="ul-text">הערות לתחילת הדוח</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('btq_notes')" fieldKey="I" [oldNotes]="notesFormA.get('notes_btq_i')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'btq', 'I')" (editNoteEvent)="editNote($event, 'btq', 'I')"></notes>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('btq_notes')" fieldKey="I" [oldNotes]="notesFormB.get('notes_btq_i')" [timeCreated]="selectedB.createdAt"></notes>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's1', subPrefix: '', title: 'הערות לתחילת הדוח' }"></ng-container>
            </ng-container>


            <ng-template #templateShared let-fieldKey="fieldKey" let-subPrefix="subPrefix" let-title="title">
                <h4 class="font-bold text-info ul-text">{{ title }}</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && notesFormA && sharedNotesFormA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [fieldKey]="fieldKey" [subKey]="subPrefix" [isFemale]="(user.usr_gender === 'נקבה')" [selectedGroup]="notesFormA.get(quizPrefix + '_notes')" [sharedGroup]="sharedNotesFormA.get('fdq_notes')" [notesArr]="notesList" (addNoteEvent)="addNote($event, quizPrefix, fieldKey, subPrefix)" (editNoteEvent)="editNote($event, quizPrefix, fieldKey, subPrefix)"></notes>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="selectedB._id" [fieldKey]="fieldKey" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="selectedB[quizPrefix + '_notes']"></notes>
                    </div>
                </div>                
            </ng-template>


            <ng-template #templateS1>
                <h4 class="font-bold text-info ul-text">הערות לתחילת הדוח</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && notesFormA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s1" (addNoteEvent)="addNote($event, quizPrefix, 's1')" (editNoteEvent)="editNote($event, quizPrefix, 's1')"></notes> -->
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [selectedGroup]="notesFormA.get(quizPrefix + '_notes')" [sharedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s1" [notesArr]="notesList" (addNoteEvent)="addNote($event, quizPrefix, 's1')" (editNoteEvent)="editNote($event, quizPrefix, 's1')"></notes>
                        <!-- <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s1" (addNoteEvent)="addNote($event, quizPrefix, 's1')" (editNoteEvent)="editNote($event, quizPrefix, 's1')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s1" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes> -->
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s1"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="selectedB._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="selectedB[quizPrefix + '_notes']" fieldKey="s1"></notes>
                    </div>
                    <!-- <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s1"></notes>
                    </div> -->
                </div>                
            </ng-template>
            <ng-template #templateS4>
                <h4 class="font-bold text-info ul-text">הערות ארוחת בוקר</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && notesFormA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s4" (addNoteEvent)="addNote($event, quizPrefix, 's4')" (editNoteEvent)="editNote($event, quizPrefix, 's4')"></notes> -->
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [selectedGroup]="notesFormA.get(quizPrefix + '_notes')" [sharedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s4" [notesArr]="notesList" (addNoteEvent)="addNote($event, quizPrefix, 's4')" (editNoteEvent)="editNote($event, quizPrefix, 's4')"></notes>
                        <!-- <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s4" (addNoteEvent)="addNote($event, quizPrefix, 's4')" (editNoteEvent)="editNote($event, quizPrefix, 's4')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s4" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes> -->
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s4"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="selectedB._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="selectedB[quizPrefix + '_notes']" fieldKey="s4"></notes>
                    </div>
                    <!-- <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s4"></notes>
                    </div> -->
                </div>                
            </ng-template>
            <ng-template #templateS6>
                <h4 class="font-bold text-info ul-text">הערות ארוחת צהריים</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && notesFormA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s6" (addNoteEvent)="addNote($event, quizPrefix, 's6')" (editNoteEvent)="editNote($event, quizPrefix, 's6')"></notes> -->
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [selectedGroup]="notesFormA.get(quizPrefix + '_notes')" [sharedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s6" [notesArr]="notesList" (addNoteEvent)="addNote($event, quizPrefix, 's6')" (editNoteEvent)="editNote($event, quizPrefix, 's6')"></notes>
                        <!-- <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s6" (addNoteEvent)="addNote($event, quizPrefix, 's6')" (editNoteEvent)="editNote($event, quizPrefix, 's6')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s6" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes> -->
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s6"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="selectedB._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="selectedB[quizPrefix + '_notes']" fieldKey="s6"></notes>
                    </div>
                    <!-- <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s6"></notes>
                    </div> -->
                </div>                
            </ng-template>

            <div class="row">
                <div class="w40 buttons-row" *ngIf="selectedA">
                    <button class="plus-button" mat-raised-button (click)="deleteQuiz(selectedIdA)">
                        {{'general.deleteQuiz' | translate}}
                        <mat-icon>delete</mat-icon>
                    </button>
                    <!-- <button [routerLink]="['/Bloodtestquiz/', selectedIdA]" class="plus-button" mat-raised-button>עבור לטופס</button> -->
                    <a (click)="$event.stopPropagation()" target="quizwindow" [routerLink]="['/Bloodtestquiz/', selectedIdA]" class="plus-button" mat-raised-button>עבור לטופס</a>
                    <mat-slide-toggle [checked]="notesFormA.value.btq_locked" (change)="toggleQuizLock($event, notesFormA)">{{'general.lockQuiz' | translate}}</mat-slide-toggle>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">
                </div> -->
                <div class="w40 buttons-row" *ngIf="selectedB">
                    <button class="plus-button" mat-raised-button (click)="deleteQuiz(selectedIdB)">
                        {{'general.deleteQuiz' | translate}}
                        <mat-icon>delete</mat-icon>
                    </button>
                    <!-- <button [routerLink]="['/Bloodtestquiz/', selectedIdB]" class="plus-button" mat-raised-button>עבור לטופס</button> -->
                    <a (click)="$event.stopPropagation()" target="quizwindow" [routerLink]="['/Bloodtestquiz/', selectedIdB]" class="plus-button" mat-raised-button>עבור לטופס</a>
                    <mat-slide-toggle [checked]="notesFormB.value.btq_locked" (change)="toggleQuizLock($event, notesFormB)">{{'general.lockQuiz' | translate}}</mat-slide-toggle>
                </div>
            </div>

            <!-- <div class="mt125 text-center">
                <button mat-raised-button color="primary" class="w30" (click)="submitForm()">שמור הערות</button>
            </div> -->

        </div>
    </mat-expansion-panel>

</div>

<ng-template #bloodValueDisabledTemplate let-fieldValue="fieldValue" let-valueName="valueName" let-unitType="unitType">
    <mat-form-field class="font-18 w60">
        <!-- <mat-checkbox matPrefix class="" disabled [checked]="fieldValue?.length"></mat-checkbox> -->
        <input disabled [value]="fieldValue || ''" matInput [placeholder]="valueName">
        <span matSuffix class="">{{unitType}}</span>
    </mat-form-field>
</ng-template>

<ng-template #bloodValueTemplate let-notesForm="notesForm" let-fieldName="fieldName" let-valueName="valueName" let-unitType="unitType" let-isReadonly="isReadonly">
    <mat-form-field [formGroup]="notesForm.get('btq_blood_values')" class="font-18 w60">
        <!-- <mat-checkbox matPrefix class="" [disabled]="!unitType || isReadonly" (change)="checkedFn($event, notesForm, fieldName)" [checked]="notesForm.get('btq_blood_values').get(fieldName).value?.length"></mat-checkbox> -->
        <input type="text" digitOnly decimal="true" [readonly]="isReadonly" [formControlName]="fieldName" matInput [placeholder]="valueName">
        <span matSuffix class="">{{unitType}}</span>
    </mat-form-field>
</ng-template>

<ng-template #bloodValueGroupTemplate let-notesForm="notesForm" let-fieldName="fieldName" let-valueName="valueName" let-unitTypes="unitTypes">
    <div [formGroup]="notesForm.get('btq_blood_values').get(fieldName)" class="font-18 w60 m-auto centered-row">
        <!-- <div class="m-0 row">
            <mat-checkbox class="" (change)="checkedFn($event, notesForm, fieldName, true)" [checked]="notesForm.get('btq_blood_values').get(fieldName).get('unit_value').value?.length > 0" [disabled]="">
                {{ valueName }}
            </mat-checkbox>
            <mat-radio-group class="font-16" formControlName="unit_type">
                <mat-radio-button *ngFor="let unitType of unitTypes" [value]="unitType">{{ unitType }}</mat-radio-button>
            </mat-radio-group>                        
        </div> -->
        <mat-form-field class="w48">
            <input type="text" digitOnly decimal="true" formControlName="unit_value" matInput [placeholder]="valueName">
            <!-- <span matSuffix class="">{{ notesForm.value.btq_blood_values && notesForm.value.btq_blood_values[fieldName] && notesForm.value.btq_blood_values[fieldName].unit_type }}</span> -->           
        </mat-form-field>
        <mat-radio-group matSuffix class="m-r-10 m-l-10 d-column font-14" formControlName="unit_type">
            <mat-radio-button *ngFor="let unitType of unitTypes" [value]="unitType" class="mb50">
                <div>{{ unitType }}</div>
            </mat-radio-button>
        </mat-radio-group>    
    </div>
</ng-template>

<ng-template #newTestTemplate let-form="form">
    <div class="basic-form mat-body mb75" [formGroup]="form">
        <p>האם להמליץ על בדיקות דם חדשות?</p>
        <!-- <div *ngIf="form.controls.firstBox">
            <mat-checkbox class="mb50 checkbox" formControlName="firstBox">
                מילוי שאלון ראשוני = לא בוצעה בדיקת דם בעבר
            </mat-checkbox>
        </div> -->
        <div *ngIf="form.controls.emailBox">
            <mat-checkbox class="mb50 checkbox" formControlName="emailBox">
                שליחת מייל המלצה
            </mat-checkbox>
        </div>
    </div>
</ng-template>
