import { Component, ViewChild, TemplateRef, Input, Output, EventEmitter, ChangeDetectorRef, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormArray, Validators, ValidatorFn, FormControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { RestApiService } from '@noammazuz/vzn-feathers';
import { TableColumn } from '@noammazuz/vzn-data-table';

import { appState } from '@services/app-state';
import { Apputils } from '@services/app-utils';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';
import { of } from 'rxjs';

@Component({
  selector: "notestable",
  templateUrl: "./notestable.component.html"
})
export class NotestableComponent {
  @Input() onlyDialog?: boolean;
  @Output() noteSubmitted: EventEmitter<any> = new EventEmitter();

  @ViewChild("tableEl") tableEl: any;
  @ViewChild("noteTypeTmpl") noteTypeTmpl: TemplateRef<any>;
  @ViewChild("contentHoverTmpl") contentHoverTmpl: TemplateRef<any>;
  @ViewChild("checkTmpl") checkTmpl: TemplateRef<any>;
  @ViewChild("daysTmpl") daysTmpl: TemplateRef<any>;
  @ViewChild("freqTmpl") freqTmpl: TemplateRef<any>;
  @ViewChild("noteDialogTemplate") noteDialogTemplate: TemplateRef<any>;

  @ViewChild("isVisibleTmpl") isVisibleTmpl: TemplateRef<any>;
  @ViewChild("isEditTmpl") isEditTmpl: TemplateRef<any>;
  @ViewChild("isRemoveTmpl") isRemoveTmpl: TemplateRef<any>;

  busy = false;

  allHours = Apputils.allHours;

  appState = appState;
  notesRestService = null;
  columns: Array<{}> = [];
  query: any;
  quizPrefix: string;

  allNotes = {
    ntq: [],
    btq: [],
    fdq: [],
    heq: [],
    nuq: [],
    prq: [],
    slq: []
  };

  allNotesUnfiltered = [];

  weekDays = {
    '0': 'א',
    '1': 'ב',
    '2': 'ג',
    '3': 'ד',
    '4': 'ה',
    '5': 'ו',
    '6': 'ש'
  }

  noteFreq = {
    '1': 'שבועית',
    '2': '2 שבועות',
    '3': '3 שבועות',
    '4': '4 שבועות',
    '13': '3 חודשים (13 שבועות)',
    '26': 'חצי-שנה (26 שבועות)',
    '52': 'שנה (52 שבועות)',
  }

  notePrefix = {
    'ntq': 'אנתרופומטריים',
    'prq': 'אימונים',
    'slq': 'שינה',
    'heq': 'בריאות',
    'nuq': 'תזונה',
    'btq': 'בדיקת דם',
    'fdq': 'יומן אכילה'
  }

  quizNoteTypeOptions = {
    ntq: {
      s1: '* הערות לתחילת הדוח',
      // s2: '* הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
      // s3: '* הערות תזונה כלליות',
      // s4: '* הערות ארוחת בוקר',
      // s5: '* הערות ארוחת ביניים 1',
      // s6: '* הערות ארוחת צהריים',
      // s7: '* הערות ארוחת ביניים 2',
      // s8: '* הערות ארוחת ערב',
      // s9: '* הערות ארוחת לילה',
      // s10: '* הערות נוזלים ללא אימון',
      s11: '* דיווח למועדון',
      A: 'הערות נתונים אנתרופומטריים',
      // B: 'דיווח למועדון',
    },
    prq: {
      s1: '* הערות לתחילת הדוח',
      s2: '* הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
      // s3: '* הערות תזונה כלליות',
      s4: '* הערות ארוחת בוקר',
      // s5: '* הערות ארוחת ביניים 1',
      s6: '* הערות ארוחת צהריים',
      // s7: '* הערות ארוחת ביניים 2',
      // s8: '* הערות ארוחת ערב',
      // s9: '* הערות ארוחת לילה',
      // s10: '* הערות נוזלים ללא אימון',
      A: 'הערות מאזן אנרגיה',
      // B: 'הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
    },
    slq: {
      s1: '* הערות לתחילת הדוח',
      // s2: '* הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
      // s3: '* הערות תזונה כלליות',
      // s4: '* הערות ארוחת בוקר',
      // s5: '* הערות ארוחת ביניים 1',
      // s6: '* הערות ארוחת צהריים',
      // s7: '* הערות ארוחת ביניים 2',
      // s8: '* הערות ארוחת ערב',
      // s9: '* הערות ארוחת לילה',
      // s10: '* הערות נוזלים ללא אימון',
      A: 'הערות הרגלי שינה',
    },
    heq: {
      s1: '* הערות לתחילת הדוח',
      s2: '* הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
      s3: '* הערות תזונה כלליות',
      s4: '* הערות ארוחת בוקר',
      // s5: '* הערות ארוחת ביניים 1',
      // s6: '* הערות ארוחת צהריים',
      // s7: '* הערות ארוחת ביניים 2',
      // s8: '* הערות ארוחת ערב',
      // s9: '* הערות ארוחת לילה',
      s10: '* הערות נוזלים ללא אימון',
      // A: 'מצב בריאותי כללי - הערות לתחילת הדוח',
      // B: 'מצב בריאותי כללי - הערות תזונה כלליות',
      // C: 'מצב בריאותי כללי - הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
      // D: 'אלרגיות ורגישויות - הערות לתחילת הדוח',
      // E: 'אלרגיות ורגישויות - הערות תזונה',
      // F: 'תרופות ותוספים - הערות לתחילת הדוח',
      // G: 'תרופות ותוספים - הערות תזונה',
      // H: 'מחזור - הערות לתחילת הדוח',
      // I: 'מחזור - הערות תזונה',
    },
    nuq: {
      s1: '* הערות לתחילת הדוח',
      // s2: '* הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
      s3: '* הערות תזונה כלליות',
      s4: '* הערות ארוחת בוקר',
      s5: '* הערות ארוחת ביניים 1',
      // s6: '* הערות ארוחת צהריים',
      // s7: '* הערות ארוחת ביניים 2',
      // s8: '* הערות ארוחת ערב',
      // s9: '* הערות ארוחת לילה',
      s10: '* הערות נוזלים ללא אימון',
      // A: 'העדפות תזונה - הערות תזונה כלליות',
      // B: 'אופן האכילה (מסכים) - הערות תזונה כלליות',
      // C: 'אופן האכילה (אכילה בחוץ) - הערות תזונה כלליות',
      // D: 'הערות הרגלי אכילה - הערות תזונה כלליות',
    },
    btq: {
      s1: '* הערות לתחילת הדוח',
      // s2: '* הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
      // s3: '* הערות תזונה כלליות',
      s4: '* הערות ארוחת בוקר',
      // s5: '* הערות ארוחת ביניים 1',
      s6: '* הערות ארוחת צהריים',
      // s7: '* הערות ארוחת ביניים 2',
      // s8: '* הערות ארוחת ערב',
      // s9: '* הערות ארוחת לילה',
      // s10: '* הערות נוזלים ללא אימון',
      A: 'המגלובין, פריטין, ברזל, טרנספרין, MCV, MCH',
      B: 'B12',
      C: 'חומצה פולית',
      D: 'וויטמין D',
      E: 'מגנזיום',
      F: 'כולסטרול, טריגליצרידים, HDL, LDL',
      G: 'גלוקוז',
      H: 'ALKP',
      // I: 'הערות לתחילת הדוח',
      J: 'הערות בדיקת דם',
    },
    fdq: {
      s1: '* הערות לתחילת הדוח',
      s2: '* הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
      s3: '* הערות תזונה כלליות',
      s4: '* הערות ארוחת בוקר',
      s5: '* הערות ארוחת ביניים 1',
      s6: '* הערות ארוחת צהריים',
      s7: '* הערות ארוחת ביניים 2',
      s8: '* הערות ארוחת ערב',
      s9: '* הערות ארוחת לילה',
      s10: '* הערות נוזלים ללא אימון',
      s11: '* דיווח למועדון',
      s12: 'התראות',
      // A: 'הערות ארוחת בוקר',
      // B: 'הערות ארוחת ביניים 1',
      // C: 'הערות ארוחת צהריים',
      // D: 'הערות ארוחת ביניים 2',
      // E: 'הערות ארוחת ערב',
      // F: 'הערות ארוחת לילה',
      // G: 'הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון',
      // H: 'הערות תזונה כלליות',
      // I: 'הערות נוזלים ללא אימון',
    },
  };

  subPrefixOptions = {
    ntq: {},
    prq: {},
    slq: {},
    heq: {
      s1: {
        a: '* הערות לתחילת הדוח - מצב בריאותי כללי',
        b: '* הערות לתחילת הדוח - אלרגיות ורגישויות',
        c: '* הערות לתחילת הדוח - תרופות ותוספים',
        d: '* הערות לתחילת הדוח - מחזור',
      },
      s3: {
        a: '* הערות תזונה כלליות - מצב בריאותי כללי',
        b: '* הערות תזונה כלליות - אלרגיות ורגישויות',
        c: '* הערות תזונה כלליות - תרופות ותוספים',
        d: '* הערות תזונה כלליות - מחזור',
      },
    },
    nuq: {
      s3: {
        a: '* הערות תזונה כלליות - העדפות תזונה',
        b: '* הערות תזונה כלליות - אופן האכילה (מסכים)',
        c: '* הערות תזונה כלליות - אופן האכילה (אכילה בחוץ)',
        d: '* הערות תזונה כלליות - מרכיבי הארוחות',
      },
    },
    btq: {},
    fdq: {},
  };

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private dialogService: ConfirmDialogService,
    private restApiService: RestApiService<any>,
    public router: Router,
    private route: ActivatedRoute,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.quizPrefix = 'ntq';
    this.notesRestService = this.restApiService.service('note');
  }

  ngOnInit() {
    this.route.params.subscribe(async params => {
      // console.log('params', params);
      if (!this.onlyDialog && params.id) {
        const note = await this.restApiService.service('note').get(params.id).catch((error: any) => { console.error(error) });
        if (note) {
          this.editNote(note);
        } else {
          this._snackBar.open('שגיאה - הערה לא קיימת', null, {
            duration: 5000
          });
          this.router.navigate(['/Notestable']);
        }
      }
    });
  }

  async ngAfterViewInit() {
    if (!this.onlyDialog) {
      this.columns = this.createColumns();
      this.query = {
        $notestable: true,
        is_deleted: {
          $ne: true
        },
        quiz_prefix: this.quizPrefix,
        $sort: {
          quiz_note_type: 1
        }
      };
      this.changeDetectorRef.detectChanges();
    }
    await this.fetchNotes();
  }

  createColumns() {
    let columns: Array<TableColumn> = [
      { prop: 'is_visible', name: 'הצג הערה', customTemplate: this.isVisibleTmpl, maxWidth: 60 },
      { prop: '_id', name: 'מזהה הערה', maxWidth: 75 },
      { prop: 'quiz_note_type', name: 'סוג הערה', customTemplate: this.noteTypeTmpl, maxWidth: 150 },
      { prop: 'title', name: 'כותרת', maxWidth: 150 },
      { prop: 'is_follow', name: 'מעקב?', customTemplate: this.checkTmpl, maxWidth: 50 },
      // { prop: 'content', name: 'תוכן', customTemplate: this.contentHoverTmpl, maxWidth: 450 },
      { prop: 'content', name: 'תוכן - זכר', maxWidth: 600 },
      // { prop: 'content_F', name: 'תוכן - נקבה', maxWidth: 600 },
      { prop: 'is_push', name: 'התראה?', customTemplate: this.checkTmpl, maxWidth: 50 },
      { prop: 'push_content', name: 'תוכן ההתראה - זכר', maxWidth: 500 },
      // { prop: 'push_content_F', name: 'תוכן ההתראה - נקבה', maxWidth: 500 },
      // { prop: 'is_wap', name: 'וואטסאפ?', customTemplate: this.checkTmpl, maxWidth: 50 },
      { prop: 'hour', name: 'שעה', maxWidth: 75 },
      { prop: 'days', name: 'ימים', customTemplate: this.daysTmpl, maxWidth: 100 },
      { prop: 'freq', name: 'תדירות', customTemplate: this.freqTmpl, maxWidth: 100 },
      { prop: 'clone_button', name: '', iconName: 'file_copy', cellTemplate: 'iconBtnTmpl', iconOnClick: this.cloneNote.bind(this), maxWidth: 75 },
      // { prop: 'edit_button', name: '', iconName: 'edit', cellTemplate: 'iconBtnTmpl', iconOnClick: this.editNote.bind(this), maxWidth: 75 },
      // { prop: 'remove_button', name: '', iconName: 'remove_circle_outline', cellTemplate: 'iconBtnTmpl', iconOnClick: this.removeNote.bind(this), maxWidth: 75 },
      { prop: 'edit_button', name: '', customTemplate: this.isEditTmpl, maxWidth: 75, sortable: false },
      { prop: 'remove_button', name: '', customTemplate: this.isRemoveTmpl, maxWidth: 75, sortable: false },
    ];

    return columns;
  }

  quizChange(e) {
    delete this.query;
    // this.changeDetectorRef.detectChanges();
    const prefix = e;
    if (prefix) {
      const query: any = {
        $notestable: true,
        is_deleted: {
          $ne: true
        },
        quiz_prefix: prefix,
        $sort: {
          quiz_note_type: 1
        }
      };
      if (prefix === 'fdq') {
        query.$or = [
          { is_visible: true },
          { is_visible_all: true },
          { is_scenario: true },
        ];
      }
      this.query = query;
      this.changeDetectorRef.detectChanges();
    }
  }

  async fetchNotes() {
    let notes = {
      ntq: [],
      btq: [],
      fdq: [],
      heq: [],
      nuq: [],
      prq: [],
      slq: []
    };
    const res = await this.restApiService.service('note').find({
      query: {
        $sort: {
          title: 1,
          content: 1
        },
        // $limit: -1,
        $paginate: false,
        $limit: 25000,
        is_deleted: {
          $ne: true
        }
      }
    });

    for (const key in notes) {
      notes[key] = res.filter(el => el.quiz_prefix == key);
    }

    this.allNotes = notes;
    this.allNotesUnfiltered = res;
  }

  createNoteItem(content?: string, content_F?: string, quiz_prefix?: string, quiz_note_type?: string, sub_prefix?: string) {
    let noteItem = this.formBuilder.group({
      _id: [''],
      created_by: [''],
      is_visible: [false],
      is_visible_all: [false],
      is_scenario: [false],
      title: [''],
      content: [content || ''],
      content_F: [content_F || ''],
      quiz_prefix: [quiz_prefix || this.quizPrefix, Validators.required],
      quiz_note_type: [quiz_note_type || '', Validators.required],
      sub_prefix: [sub_prefix || ''],
      is_follow: [false],
      is_push: [false],
      is_wap: [false],
      push_content: [''],
      push_content_F: [''],
      push_content_female_child: [''],
      push_content_female_child_F: [''],
      hour: [''],
      days: [''],
      freq: [''],

      notification_timing: [''],
      notification_timing_date: [''],
      notification_timing_hour: [''],

      // notification_to_types: [null],
      notification_to_type: [''],

      is_popup: [false],
      popup_option: [''],
      popup_limited_unit: [''],
      popup_limited_amount: [''],

      is_response: [false],
      response_button_yes: [''],
      response_button_yes_F: [''],
      response_button_no: [''],
      response_button_no_F: [''],
      response_wap: [''],
      response_wap_F: [''],
      is_interval: [false],
      response_interval: [''],
      response_max_times: [''],

      is_linked: [false],
      linked_notes: this.formBuilder.array([]),

      linked_quiz_prefix: [],
      linked_quiz_note_type: [],
      linked_sub_prefix: [],

      is_link_homepage: [false],
      is_link_profile: [false],
      is_link_last_rpt: [false],
      // is_link_first_btq: [false],
      is_link_last_btq: [false],
    });

    noteItem.valueChanges.subscribe(value => {
      if (this.subPrefixOptions[value.quiz_prefix] && this.subPrefixOptions[value.quiz_prefix][value.quiz_note_type]) {
        noteItem.get('sub_prefix').setValidators(Validators.required);
      } else {
        noteItem.get('sub_prefix').clearValidators();
        noteItem.get('sub_prefix').reset('', { emitEvent: false });
      }
      noteItem.get('sub_prefix').updateValueAndValidity({ emitEvent: false });

      const groupA = (value.is_visible || value.is_visible_all);
      const groupB = (value.is_push || value.is_wap);

      const groupC = (value.is_visible || value.is_visible_all || value.is_scenario);

      if (!groupA && !groupB) {
        noteItem.get('push_content').clearValidators();
        noteItem.get('push_content_F').clearValidators();
        noteItem.get('push_content_female_child').clearValidators();
        noteItem.get('push_content_female_child_F').clearValidators();
        if (value.content || value.content_F) {
          noteItem.get('content').clearValidators();
          noteItem.get('content_F').clearValidators();
        } else {
          noteItem.get('content').setValidators(Validators.required);
          noteItem.get('content').markAsTouched();
          noteItem.get('content_F').setValidators(Validators.required);
          noteItem.get('content_F').markAsTouched();
        }
      }

      if (!groupA && groupB) {
        noteItem.get('content').clearValidators();
        noteItem.get('content_F').clearValidators();
        if (value.push_content || value.push_content_F) {
          noteItem.get('push_content').clearValidators();
          noteItem.get('push_content_F').clearValidators();
        } else {
          noteItem.get('push_content').setValidators(Validators.required);
          noteItem.get('push_content').markAsTouched();
          noteItem.get('push_content_F').setValidators(Validators.required);
          noteItem.get('push_content_F').markAsTouched();
        }
      }

      if (groupA && !groupB) {
        noteItem.get('push_content').clearValidators();
        noteItem.get('push_content_F').clearValidators();
        noteItem.get('push_content_female_child').clearValidators();
        noteItem.get('push_content_female_child_F').clearValidators();
        if (value.content && value.content_F) {
          noteItem.get('content').clearValidators();
          noteItem.get('content_F').clearValidators();
        } else {
          noteItem.get('content').setValidators(Validators.required);
          noteItem.get('content').markAsTouched();
          noteItem.get('content_F').setValidators(Validators.required);
          noteItem.get('content_F').markAsTouched();
        }
      }

      if (groupA && groupB) {
        if ((value.content && value.content_F) || (!value.content && !value.content_F)) {
          noteItem.get('content').clearValidators();
          noteItem.get('content_F').clearValidators();
        } else {
          noteItem.get('content').setValidators(Validators.required);
          noteItem.get('content').markAsTouched();
          noteItem.get('content_F').setValidators(Validators.required);
          noteItem.get('content_F').markAsTouched();
        }
        if (value.push_content && value.push_content_F) {
          noteItem.get('push_content').clearValidators();
          noteItem.get('push_content_F').clearValidators();
        } else {
          noteItem.get('push_content').setValidators(Validators.required);
          noteItem.get('push_content').markAsTouched();
          noteItem.get('push_content_F').setValidators(Validators.required);
          noteItem.get('push_content_F').markAsTouched();
        }
      }

      if (value.notification_to_type === 'parent') {
        if (groupB) {
          if (groupC) {
            noteItem.get('push_content').setValidators(Validators.required);
            noteItem.get('push_content').markAsTouched();
            noteItem.get('push_content_F').setValidators(Validators.required);
            noteItem.get('push_content_F').markAsTouched();
            noteItem.get('push_content_female_child').setValidators(Validators.required);
            noteItem.get('push_content_female_child').markAsTouched();
            noteItem.get('push_content_female_child_F').setValidators(Validators.required);
            noteItem.get('push_content_female_child_F').markAsTouched();
          }
        } else {
          noteItem.get('push_content').clearValidators();
          noteItem.get('push_content_F').clearValidators();
          noteItem.get('push_content_female_child').clearValidators();
          noteItem.get('push_content_female_child_F').clearValidators();
        }
      } else {
        noteItem.get('push_content_female_child').clearValidators();
        noteItem.get('push_content_female_child_F').clearValidators();
      }

      noteItem.get('content').updateValueAndValidity({ emitEvent: false });
      noteItem.get('content_F').updateValueAndValidity({ emitEvent: false });
      noteItem.get('push_content').updateValueAndValidity({ emitEvent: false });
      noteItem.get('push_content_F').updateValueAndValidity({ emitEvent: false });
      noteItem.get('push_content_female_child').updateValueAndValidity({ emitEvent: false });
      noteItem.get('push_content_female_child_F').updateValueAndValidity({ emitEvent: false });

      if (groupB) {
        noteItem.get('notification_timing').setValidators(Validators.required);

        if (value.notification_timing === 'freq') {
          noteItem.get('hour').setValidators(Validators.required);
          noteItem.get('days').setValidators(Validators.required);
          noteItem.get('freq').setValidators(Validators.required);
        } else {
          noteItem.get('hour').clearValidators();
          noteItem.get('days').clearValidators();
          noteItem.get('freq').clearValidators();
        }
        if (value.notification_timing === 'date') {
          noteItem.get('notification_timing_date').setValidators(Validators.required);
          noteItem.get('notification_timing_hour').setValidators(Validators.required);
        } else {
          noteItem.get('notification_timing_date').clearValidators();
          noteItem.get('notification_timing_hour').clearValidators();
        }
        if (value.notification_timing === 'publish' || value.notification_timing === 'date') {
          noteItem.get('notification_to_type').setValidators(Validators.required);
        } else {
          noteItem.get('notification_to_type').clearValidators();
        }

        if (value.push_content || value.push_content_F) {
          noteItem.get('popup_option').setValidators(Validators.required);
          if (value.popup_option === 'limited') {
            noteItem.get('popup_limited_unit').setValidators(Validators.required);
            noteItem.get('popup_limited_amount').setValidators(Validators.required);
          } else {
            noteItem.get('popup_limited_unit').clearValidators();
            noteItem.get('popup_limited_amount').clearValidators();
          }
        } else {
          noteItem.get('popup_option').clearValidators();
          noteItem.get('popup_limited_unit').clearValidators();
          noteItem.get('popup_limited_amount').clearValidators();
        }

        if (value.is_response) {

        } else {

        }

        if (value.is_response && value.is_interval) {
          noteItem.get('response_interval').setValidators(Validators.required);
          noteItem.get('response_max_times').setValidators(Validators.required);
        } else {
          noteItem.get('response_interval').clearValidators();
          noteItem.get('response_max_times').clearValidators();
        }
      } else {
        noteItem.get('notification_timing').clearValidators();

        noteItem.get('hour').clearValidators();
        noteItem.get('days').clearValidators();
        noteItem.get('freq').clearValidators();

        noteItem.get('notification_timing_date').clearValidators();
        noteItem.get('notification_timing_hour').clearValidators();

        noteItem.get('notification_to_type').clearValidators();

        noteItem.get('popup_option').clearValidators();
        noteItem.get('popup_limited_unit').clearValidators();
        noteItem.get('popup_limited_amount').clearValidators();

        noteItem.get('response_interval').clearValidators();
        noteItem.get('response_max_times').clearValidators();
      }

      noteItem.get('hour').updateValueAndValidity({ emitEvent: false });
      noteItem.get('days').updateValueAndValidity({ emitEvent: false });
      noteItem.get('freq').updateValueAndValidity({ emitEvent: false });

      noteItem.get('notification_timing').updateValueAndValidity({ emitEvent: false });
      noteItem.get('notification_timing_date').updateValueAndValidity({ emitEvent: false });
      noteItem.get('notification_timing_hour').updateValueAndValidity({ emitEvent: false });

      noteItem.get('notification_to_type').updateValueAndValidity({ emitEvent: false });

      noteItem.get('popup_option').updateValueAndValidity({ emitEvent: false });
      noteItem.get('popup_limited_unit').updateValueAndValidity({ emitEvent: false });
      noteItem.get('popup_limited_amount').updateValueAndValidity({ emitEvent: false });

      noteItem.get('response_interval').updateValueAndValidity({ emitEvent: false });
      noteItem.get('response_max_times').updateValueAndValidity({ emitEvent: false });

      // if (!(value.is_visible || value.is_visible_all) || value.content || value.content_F) {
      //   noteItem.get('content').clearValidators();
      //   noteItem.get('content_F').clearValidators();
      // } else {
      //   noteItem.get('content').setValidators(Validators.required);
      //   noteItem.get('content').markAsTouched();
      //   noteItem.get('content_F').setValidators(Validators.required);
      //   noteItem.get('content_F').markAsTouched();
      // }
      // noteItem.get('content').updateValueAndValidity({ emitEvent: false });
      // noteItem.get('content_F').updateValueAndValidity({ emitEvent: false });

      // if (value.is_push && !value.push_content && !value.push_content_F) {
      //   noteItem.get('push_content').setValidators(Validators.required);
      //   noteItem.get('push_content').markAsTouched();
      //   noteItem.get('push_content_F').setValidators(Validators.required);
      //   noteItem.get('push_content_F').markAsTouched();
      // } else {
      //   noteItem.get('push_content').clearValidators();
      //   noteItem.get('push_content_F').clearValidators();
      // }
      // noteItem.get('push_content').updateValueAndValidity({ emitEvent: false });
      // noteItem.get('push_content_F').updateValueAndValidity({ emitEvent: false });
    }
    );

    //   noteItem.get('is_push').valueChanges.subscribe(value => {
    //     if (value) {
    //       // noteItem.get('push_content').setValidators(Validators.required);
    //       // noteItem.get('push_content').markAsTouched();
    //       // noteItem.get('push_content_F').setValidators(Validators.required);
    //       // noteItem.get('push_content_F').markAsTouched();
    //       noteItem.get('hour').setValidators(Validators.required);
    //       noteItem.get('days').setValidators(Validators.required);
    //       noteItem.get('freq').setValidators(Validators.required);
    //     } else {
    //       // noteItem.get('push_content').clearValidators();
    //       // noteItem.get('push_content_F').clearValidators();
    //       noteItem.get('hour').clearValidators();
    //       noteItem.get('days').clearValidators();
    //       noteItem.get('freq').clearValidators();
    //     }
    //     // noteItem.get('push_content').updateValueAndValidity({ onlySelf: true });
    //     // noteItem.get('push_content_F').updateValueAndValidity({ onlySelf: true });
    //     noteItem.get('hour').updateValueAndValidity({ onlySelf: true });
    //     noteItem.get('days').updateValueAndValidity({ onlySelf: true });
    //     noteItem.get('freq').updateValueAndValidity({ onlySelf: true });

    //     // noteItem.updateValueAndValidity();
    //   }
    // );

    // noteItem.get('content').valueChanges.subscribe(value => {
    //   if ((value || '').trim()) {
    //     // noteItem.get('content').setValidators(Validators.required);
    //     noteItem.get('content_F').setValidators(Validators.required);
    //     noteItem.get('content_F').markAsTouched();
    //   } else {
    //     // noteItem.get('content').clearValidators();
    //     noteItem.get('content_F').clearValidators();
    //   }
    //   // noteItem.get('content').updateValueAndValidity({ onlySelf: true });
    //   noteItem.get('content_F').updateValueAndValidity({ onlySelf: true });

    // });

    return noteItem;

  }

  openNoteDialog(note?: FormGroup, field?: FormArray, content?: string, content_F?: string, quiz_prefix?: string, quiz_note_type?: string, sub_prefix?: string, sharedField?: FormArray, acFormField?: FormControl) {
    let newNote = note ? note : this.createNoteItem(content, content_F, quiz_prefix, quiz_note_type, sub_prefix);
    let title = (!note || !note.value?._id) ? 'הוספת הערה' : 'עריכת הערה';

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      disableClose: true,
      minWidth: '35%',
      panelClass: 'note-dialog',
      data: {
        title: title,
        ok: this.translate.instant('general.ok'),
        // okAction: this.onlyDialog && note?.value?._id ? () => this.editDialogConfirm() : undefined,
        submitDisabled: this.onlyDialog && note?.value?._id ? (this.appState.user.usr_type !== 'superadmin') : Boolean(this.appState.user.usr_type !== 'superadmin' && newNote.value._id && newNote.value.created_by !== this.appState.user._id),
        // context: { submitDisabled: !(note?.value?.created_by === this.appState.user._id || this.appState.user.usr_type === 'superadmin') },
        extraButton: newNote.value._id ? { text: 'צור הערה חדשה', value: 'clone', action: () => this.extraConfirm(newNote) } : undefined,
        cancel: this.translate.instant('general.cancel'),
        form: newNote,
        template: this.noteDialogTemplate
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return false;
      }
      let res: any;
      if (result?.isExtraButton) {
        // await this.cloneCustom(newNote, field, quiz_prefix, quiz_note_type, sharedField);
        // return;

        res = await this.cloneFunc(newNote, field, quiz_prefix, quiz_note_type, sharedField);

        // const lastId = newNote.value._id;
        // newNote.get('_id').reset('');
        // res = await this.onSubmitNote(newNote);

        // if (res && quiz_prefix && res.quiz_prefix === quiz_prefix && quiz_note_type && res.quiz_note_type === quiz_note_type) {
        //   const noteIndex = field.value.findIndex((note: any) => note.note_id === lastId);
        //   if (noteIndex > -1) {
        //     field.removeAt(noteIndex);
        //   }

        //   if (quiz_prefix !== 'fdq' && sharedField) {
        //     const noteIndex = sharedField.value.findIndex((note: any) => note.note_id === lastId);
        //     if (noteIndex > -1) {
        //       sharedField.removeAt(noteIndex);
        //     }
        //   }

        // }

        // if (res) {
        //   const noteIndex = field.value.findIndex((note: any) => note.note_id === lastId);
        //   if (noteIndex > -1) {
        //     field.removeAt(noteIndex);
        //   }
        //   // const now = new Date().getTime();
        //   // const newNote = new FormControl({
        //   //   note_id: res._id,
        //   //   is_active: true,
        //   //   is_done: false,
        //   //   // note_obj: note,
        //   //   added_at: now,
        //   // });
        //   // field.push(newNote);
        //   // field.markAsDirty();
        // }
      }

      if (result?.isOkButton) {
        if (this.onlyDialog && (newNote.value.is_visible || newNote.value.is_visible_all || newNote.value.is_scenario)) {
          // open new tab
          if (newNote.value._id) {
            window.open(`Notestable/${newNote.value._id}`);
            return;
          }
          // return;
        }
        if ((quiz_prefix && newNote.value.quiz_prefix !== quiz_prefix) || (quiz_note_type && newNote.value.quiz_note_type !== quiz_note_type)) {
          const noteIndex = field.value.findIndex((note: any) => note && note.note_id === newNote.value._id);
          if (noteIndex > -1) {
            field.removeAt(noteIndex);
          }
        }
        res = await this.onSubmitNote(newNote);
        // if (res && acFormField) {
        //   acFormField.setValue(res);
        // }
      }
      this.noteSubmitted.emit(res);
      this.fetchNotes();
    });
  }

  editDialogConfirm() {
    const options = {
      // title: 'עריכת הערה',
      message: 'האם אתה בטוח שאתה רוצה לערוך את ההערה?',
      cancelText: 'לא',
      confirmText: 'כן',
      errorMessage: 'עריכת ההערה תשנה הערה זו בכל הדוחות שבהם היא מופיעה'
    };
    this.dialogService.open(options);
    return this.dialogService.confirmed();
  }

  extraConfirm(newNote: FormGroup) {
    if (!newNote.value.is_visible && !newNote.value.is_visible_all) {
      return of(true);
    }

    const options = {
      // title: '',
      message: 'האם להציג הערה בדף תזונאי באופן קבוע?',
      confirmText: this.translate.instant('general.yes'),
      cancelText: this.translate.instant('general.no'),
      // errorMessage: ''
    };
    this.dialogService.open(options);
    return this.dialogService.confirmed();
  }

  editNote(row: any, field?: FormArray, quiz_prefix?: string, quiz_note_type?: string, sub_prefix?: string, sharedField?: FormArray) {
    let note: FormGroup = this.createNoteItem();
    if (row.linked_notes?.length) {
      for (const l of row.linked_notes) {
        (note.get('linked_notes') as FormArray).push(new FormControl());
      }
    }
    note.patchValue(row);
    this.openNoteDialog(note, field, null, null, quiz_prefix, quiz_note_type, sub_prefix, sharedField);
  }

  cloneNote(row) {
    let newItem = { ...row };
    delete newItem._id;
    let note: FormGroup = this.createNoteItem();
    if (row.linked_notes?.length) {
      for (const l of row.linked_notes) {
        (note.get('linked_notes') as FormArray).push(new FormControl());
      }
    }
    note.patchValue(newItem);
    this.openNoteDialog(note);
  }

  removeNote(row) {
    const options = {
      title: 'מחיקת הערה',
      message: 'האם למחוק את ההערה - ' + row.title + ' ?',
      cancelText: 'ביטול',
      confirmText: 'אישור'
    };

    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.notesRestService.patch(row._id, { is_deleted: true }).then(res => {
          this._snackBar.open(`הערה נמחקה ( ${res['title']} )`, null, {
            duration: 5000,
          });
          if (this.tableEl) {
            this.tableEl.setTableData();
          }

        }).catch(res => {
          if (res.message) {
            this._snackBar.open(res.message, null, {
              duration: 3000,
            });
          }
        });
      }
    });
  }

  async onSubmitNote(note: FormGroup) {
    let res: any;
    this.busy = true;
    try {
      let method = '';
      if (!note.value._id || note.value._id === '' || note.value._id === null) {
        // let val = note.getRawValue(); // to include the disabled field
        // delete val._id;
        // res = await this.notesRestService.create(val);
        if (!note.value.is_visible && !note.value.is_visible_all) {
          note.value.title = 'הערה מותאמת אישית';
        }
        delete note.value._id;
        res = await this.notesRestService.create(note.value);
        method = 'נוספה';
      } else {
        res = await this.notesRestService.patch(note.value._id, note.value);
        method = 'נערכה';
      }
      let resultMsg = 'ההערה ' + method + ' בהצלחה';
      let errorMsg = 'שגיאה בשליחת פרטי ההערה';
      this._snackBar.open(res && res._id ? resultMsg : errorMsg, null, {
        duration: 3000,
      });
      if (this.tableEl) {
        await this.tableEl.setTableData();
      }
      this.busy = false;
    } catch (e) {
      console.error(e);
      this._snackBar.open('שגיאה בשליחת פרטי ההערה', null, {
        duration: 3000,
      });
      this.busy = false;
    }
    return res;
  }


  // requiredIfValidator(predicate: Function): ValidatorFn | null {
  //   return ((formControl) => {
  //     if (!formControl.parent) {
  //       return null;
  //     }
  //     if (predicate()) {
  //       return Validators.required(formControl); 
  //     }
  //     return null;
  //   })
  // }

  async ownerChange(event, row) {
    const isChecked = event.checked;
    this.busy = true;
    try {
      const res = await this.notesRestService.patch(row._id, { is_visible: isChecked });
      if (res.is_visible === isChecked) {
        row.is_visible = res.is_visible;
      } else {
        event.source.checked = !isChecked;
      }
      this.busy = false;
    } catch (e) {
      console.error(e);
      event.source.checked = !isChecked;
      this.busy = false;
    }
  }

  async systemChange(event, row) {
    const isChecked = event.checked;
    this.busy = true;
    try {
      const res = await this.notesRestService.patch(row._id, { is_visible: isChecked });
      if ((res && res.includes(row._id) != isChecked)) {
        // row.is_visible = res.is_visible;
        this.appState.user['usr_nutri_hidden_notes'] = res;
      } else {
        event.source.checked = !isChecked;
      }
      this.busy = false;
    } catch (e) {
      console.error(e);
      event.source.checked = !isChecked;
      this.busy = false;
    }
  }

  async cloneFunc(newNote?: FormGroup, field?: FormArray, quiz_prefix?: string, quiz_note_type?: string, sharedField?: FormArray) {
    const lastId = newNote.value._id;
    newNote.get('_id').reset('');
    const res = await this.onSubmitNote(newNote);

    if (res && quiz_prefix && res.quiz_prefix === quiz_prefix && quiz_note_type && res.quiz_note_type === quiz_note_type) {
      const noteIndex = field.value.findIndex((note: any) => note && note.note_id === lastId);
      if (noteIndex > -1) {
        field.removeAt(noteIndex);
      }

      if (quiz_prefix !== 'fdq' && sharedField) {
        const noteIndex = sharedField.value.findIndex((note: any) => note && note.note_id === lastId);
        if (noteIndex > -1) {
          sharedField.removeAt(noteIndex);
        }
      }

    }
    return res;
  }

  addText(nativeEl: any, formControl: FormControl, textToAdd: string) {
    const val = formControl.value;
    const selStart = nativeEl?.selectionStart || val.length;
    const brackets = `{{${textToAdd}}}`;
    formControl.setValue(`${val.substring(0, selStart)}${brackets}${val.substring(selStart)}`);
    setTimeout(() => {
      if (nativeEl) {
        nativeEl.selectionStart = selStart + brackets.length;
        nativeEl.focus();
      }
    });
  }

  // async cloneCustom(newNote?: FormGroup, field?: FormArray, quiz_prefix?: string, quiz_note_type?: string, sharedField?: FormArray) {

  //   if (!newNote.value.is_visible && !newNote.value.is_visible_all) {
  //     const res = await this.cloneFunc(newNote, field, quiz_prefix, quiz_note_type, sharedField);
  //     this.noteSubmitted.emit(res);
  //     this.fetchNotes();
  //   } else {
  //     const dialogRef = this.dialog.open(CustomDialogComponent, {
  //       // minWidth: '35%',
  //       data: { 
  //         // title: '',
  //         subtitle: 'האם להציג הערה בדף תזונאי באופן קבוע?',
  //         ok: this.translate.instant('general.yes'),
  //         cancel: this.translate.instant('general.no'),
  //       }
  //     });

  //     dialogRef.afterClosed().subscribe(async result => {
  //       if (!result) {
  //         return;
  //       }
  //       this.busy = true;
  //       try {

  //         // newNote.get('is_visible').setValue(true, { emitEvent: false });
  //         // if (this.appState.user.usr_type === 'superadmin') {
  //         //   newNote.get('is_visible_all').setValue(true, { emitEvent: false });
  //         // }

  //         const res = await this.cloneFunc(newNote, field, quiz_prefix, quiz_note_type, sharedField);
  //         this.noteSubmitted.emit(res);
  //         this.fetchNotes();

  //         this.busy = false;

  //       } catch (e) {
  //         console.error(e);
  //         this.busy = false;
  //       }

  //     });
  //   }


  // }

}
