<div>

    <form novalidate class="basic-form" [formGroup]="practicequizForm" (ngSubmit)="onSubmit(practicequizForm, quizPrefix)">


        <div class="row">
            <h1>{{'practice.QUIZ_TITLE' | translate}}</h1>
            <!-- <button type="button" *ngIf="isOtherUser" class="plus-button" mat-raised-button [routerLink]="['/Nutritionistform/', practicequizForm.value.prq_usr_id]">
                חזור לטופס תזונאי
                <mat-icon>undo</mat-icon>
            </button> -->
            
            <button type="button" *ngIf="appState.user && appState.user.usr_type == 'parent'" class="plus-button" mat-raised-button [routerLink]="['/Userparent']">
                <span>{{'general.undo' | translate}} </span>
                <mat-icon>undo</mat-icon>
            </button>
        </div>
        
        <mat-card *ngIf="!practicequizForm.value._id || practicequizForm.value._id==''">
            <div class="spinner">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
        </mat-card>

        <div [hidden]="!practicequizForm.value._id || practicequizForm.value._id==''">

        <mat-card>
            <h3 [ngClass]="{ 'required-question': showErrors && practicequizForm.get('prq_extra_practice').invalid }">{{'practice.Q_1' | translate}}</h3>
            <div>
                <mat-radio-group formControlName="prq_extra_practice">
                    <mat-radio-button value="כן" (click)="practiceRadioOnClick('prq_extra_practice_arr')">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <ng-container *ngIf="practicequizForm.value.prq_extra_practice == 'כן' && practicequizForm.value.prq_extra_practice_arr.length > 0" [ngTemplateOutlet]="practiceTemplate" [ngTemplateOutletContext]="{
                context: formContext,
                checkboxFieldName: 'prq_extra_practice',
                arrFieldName: 'prq_extra_practice_arr',
                practiceTypeTitle: 'practice.PRACTICE_TYPE' | translate,
                practiceTypeMoreTitle: 'practice.PRACTICE_TYPE_MORE' | translate,
                practiceFreqTitle: 'practice.PRACTICE_FREQ' | translate,
                practiceLengthTitle: 'practice.PRACTICE_LENGTH' | translate,
                practiceRemarksTitle: 'practice.PRACTICE_REMARKS' | translate,
                addButtonTitle: 'practice.PRACTICE_ADD' | translate,
                removeButtonTitle: 'practice.PRACTICE_REMOVE' | translate,
                addOnClick: addPracticeItem,
                removeOnClick: removePracticeItem
            }">
            </ng-container>

            <ng-template #practiceTemplate let-context="context" let-checkboxFieldName="checkboxFieldName" let-arrFieldName="arrFieldName" let-practiceTypeTitle="practiceTypeTitle" let-practiceTypeMoreTitle="practiceTypeMoreTitle" let-practiceFreqTitle="practiceFreqTitle"
                let-practiceLengthTitle="practiceLengthTitle" let-practiceRemarksTitle="practiceRemarksTitle" let-addButtonTitle="addButtonTitle" let-removeButtonTitle="removeButtonTitle" let-addOnClick="addOnClick" let-removeOnClick="removeOnClick">

                <div class="repeat-container" formArrayName="{{arrFieldName}}" *ngFor="let control of context.practicequizForm.get(arrFieldName).controls; let i = index; let last = last;">
                    <div [formGroupName]="i">
                        <div class="w45">
                            <mat-form-field>
                                <mat-select formControlName="practice_type" placeholder="{{practiceTypeTitle}}">
                                    <ng-container *ngFor="let item of practiceTypes">
                                        <mat-option [value]="item.title">{{item.title}}</mat-option>
                                    </ng-container>
                                    <mat-option value="אחר">אחר</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <mat-form-field *ngIf="control.value.practice_type == 'אחר'" class="w45">
                            <input formControlName="practice_type_other_details" matInput placeholder="{{practiceTypeMoreTitle}}">
                        </mat-form-field>

                        <div class="w45">
                            <mat-form-field>
                                <mat-select formControlName="practice_frequency" placeholder="{{practiceFreqTitle}}">
                                    <mat-option value="1">1</mat-option>
                                    <mat-option value="2">2</mat-option>
                                    <mat-option value="3">3</mat-option>
                                    <mat-option value="4">4</mat-option>
                                    <mat-option value="5">5</mat-option>
                                    <mat-option value="6">6</mat-option>
                                    <mat-option value="7">7</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="w45">
                            <mat-form-field>
                                <mat-select formControlName="practice_length" placeholder="{{practiceLengthTitle}}">
                                    <mat-option value="15">15</mat-option>
                                    <mat-option value="30">30</mat-option>
                                    <mat-option value="45">45</mat-option>
                                    <mat-option value="60">60</mat-option>
                                    <mat-option value="75">75</mat-option>
                                    <mat-option value="90">90</mat-option>
                                    <mat-option value="105">105</mat-option>
                                    <mat-option value="120">120</mat-option>
                                    <mat-option value="135">135</mat-option>
                                    <mat-option value="150">150</mat-option>
                                    <mat-option value="165">165</mat-option>
                                    <mat-option value="180">180</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="w45">
                            <mat-form-field>
                                <input formControlName="practice_remarks" matInput placeholder="{{practiceRemarksTitle}}">
                            </mat-form-field>
                        </div>

                        <div class="buttons-row">
                            <button (click)="removeOnClick(context, checkboxFieldName, arrFieldName, i)" type="button" mat-raised-button color="warn" mat-button [disabled]="isLocked || isBusy">{{removeButtonTitle}}<mat-icon>remove_circle_outline</mat-icon></button>
                        </div>

                    </div>

                </div>
                <div class="add-button-container">
                    <button (click)="addOnClick(context, arrFieldName)" type="button" mat-raised-button color="primary" mat-button [disabled]="isLocked || isBusy">{{addButtonTitle}}<mat-icon>add_circle_outline</mat-icon></button>
                </div>



            </ng-template>
        </mat-card>
        <mat-card>

            <h3 [ngClass]="{ 'required-question': showErrors && practicequizForm.get('prq_strength_practice').invalid }">{{'practice.Q_6' | translate}}</h3>
            <div>
                <mat-radio-group formControlName="prq_strength_practice">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <ng-container *ngIf="practicequizForm.value.prq_strength_practice == 'כן'">
                <h3 [ngClass]="{ 'required-question': showErrors && strengthPracticeTypeError }">{{'practice.Q_7' | translate}}</h3>
                <div>
                    <mat-checkbox class="checkbox" formControlName="prq_strength_practice_type_group">{{ 'practice.Q_7_A_1' | translate }}</mat-checkbox>
                    <mat-checkbox class="checkbox" formControlName="prq_strength_practice_type_alone">{{ 'practice.Q_7_A_2' | translate }}</mat-checkbox>
                </div>
                <!-- <ng-container *ngIf="practicequizForm.value.prq_strength_practice_type_alone"> -->
                    <h3 [ngClass]="{ 'required-question': showErrors && practicequizForm.get('prq_strength_practice_plan').invalid }">{{'practice.Q_8' | translate}}</h3>
                    <div class="mb50">
                        <mat-radio-group class="d-column" formControlName="prq_strength_practice_plan">
                            <mat-radio-button value="בניתי לבד">{{ 'practice.Q_8_A_1' | translate }}</mat-radio-button>
                            <mat-radio-button value="המאמן בחדר כושר בנה לי">{{ 'practice.Q_8_A_2' | translate }}</mat-radio-button>
                            <mat-radio-button value="מאמן הכושר של הקבוצה בנה לי">{{ 'practice.Q_8_A_3' | translate }}</mat-radio-button>
                            <mat-radio-button value="מאמן כושר אישי לספורטאים בנה לי">{{ 'practice.Q_8_A_4' | translate }}</mat-radio-button>
                            <mat-radio-button value="אחר">{{ 'practice.Q_8_A_5' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <mat-form-field *ngIf="practicequizForm.value.prq_strength_practice_plan == 'אחר'" class="w45">
                        <input formControlName="prq_strength_practice_plan_other" matInput placeholder="{{ 'general.description' | translate }}">
                    </mat-form-field>
                <!-- </ng-container> -->
            </ng-container>

            <h3 [ngClass]="{ 'required-question': showErrors && practicequizForm.get('prq_morning_practice').invalid }">{{'practice.Q_2' | translate}}</h3>
            <div>
                <mat-radio-group formControlName="prq_morning_practice">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <ng-container *ngIf="practicequizForm.value.prq_morning_practice == 'כן'">

                <h3>{{'practice.Q_3' | translate}}</h3>
                <!-- <div *ngIf="showErrors && practicequizForm.get('prq_morning_practice_days').invalid" class="mat-error font-16">
                    <div>{{'practice.Q_3_ERROR' | translate}}</div>
                </div> -->
                <div *ngIf="showErrors && morningPracticeDaysError" class="mat-error font-16">
                    <div>{{ 'practice.Q_3_ERROR' | translate }}</div>
                </div>
                <div class="w45">
                    <div *ngFor="let day of weekDays" class="d-flex mb50">
                        <mat-checkbox [disabled]="isLocked || isBusy" class="checkbox" (change)="checkedFn($event, day)" [checked]="practicequizForm.get('prq_morning_practice_time_' + day).value">{{ ('practice.DAY_' + day) | translate }}</mat-checkbox>
                        <mat-form-field *ngIf="practicequizForm.get('prq_morning_practice_time_' + day).value" class="m-r-10 m-l-10">
                            <mat-select [formControlName]="'prq_morning_practice_time_' + day" placeholder="{{ 'practice.Q_3_B' | translate }}">
                                <mat-option value="07:00">07:00</mat-option>
                                <mat-option value="07:15">07:15</mat-option>
                                <mat-option value="07:30">07:30</mat-option>
                                <mat-option value="07:45">07:45</mat-option>
                                <mat-option value="08:00">08:00</mat-option>
                                <mat-option value="08:15">08:15</mat-option>
                                <mat-option value="08:30">08:30</mat-option>
                                <mat-option value="08:45">08:45</mat-option>
                                <mat-option value="09:00">09:00</mat-option>
                                <mat-option value="09:15">09:15</mat-option>
                                <mat-option value="09:30">09:30</mat-option>
                                <mat-option value="09:45">09:45</mat-option>
                                <mat-option value="10:00">10:00</mat-option>
                                <mat-option value="10:15">10:15</mat-option>
                                <mat-option value="10:30">10:30</mat-option>
                                <mat-option value="10:45">10:45</mat-option>
                                <mat-option value="11:00">11:00</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <!-- <div formArrayName="prq_morning_practice_days">
                    <p *ngFor="let day of weekDays">
                        <mat-checkbox class="checkbox" (change)="updateChkbxArray(day, $event.checked, 'prq_morning_practice_days')" [checked]="practicequizForm.value.prq_morning_practice_days.includes(day)" [disabled]="isLocked">{{ ('practice.DAY_'+day) | translate }}</mat-checkbox>
                    </p>
                </div> -->
                <!-- <div *ngIf="practicequizForm.value.prq_morning_practice_days && practicequizForm.value.prq_morning_practice_days.length">
                    <mat-form-field class="w45">
                        <mat-select formControlName="prq_earliest_practice_time" placeholder="{{'practice.Q_3_A' | translate}}">
                            <mat-option *ngFor="let time of allHours" [value]="time">{{time}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
            </ng-container>

            <h3 [ngClass]="{ 'required-question': showErrors && practicequizForm.get('prq_drink_in_practice').invalid }">{{ 'practice.Q_9' | translate }}</h3>
            <div>
                <mat-form-field class="w65">
                    <mat-select formControlName="prq_drink_in_practice" placeholder="">
                        <mat-option value="0">{{ 'practice.Q_9_A_1' | translate }}</mat-option>
                        <mat-option value="0.25">{{ 'practice.Q_9_A_2' | translate }}</mat-option>
                        <mat-option value="0.50">{{ 'practice.Q_9_A_3' | translate }}</mat-option>
                        <mat-option value="0.75">{{ 'practice.Q_9_A_4' | translate }}</mat-option>
                        <mat-option value="1.00">{{ 'practice.Q_9_A_5' | translate }}</mat-option>
                        <mat-option value="1.25">{{ 'practice.Q_9_A_6' | translate }}</mat-option>
                        <mat-option value="1.50">{{ 'practice.Q_9_A_7' | translate }}</mat-option>
                        <mat-option value="1.75">{{ 'practice.Q_9_A_8' | translate }}</mat-option>
                        <mat-option value="2.00">{{ 'practice.Q_9_A_9' | translate }}</mat-option>
                        <mat-option value="2.25">{{ 'practice.Q_9_A_10' | translate }}</mat-option>
                        <mat-option value="2.50">{{ 'practice.Q_9_A_11' | translate }}</mat-option>
                        <mat-option value="2.75">{{ 'practice.Q_9_A_12' | translate }}</mat-option>
                        <mat-option value="3.00">{{ 'practice.Q_9_A_13' | translate }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <h3 [ngClass]="{ 'required-question': showErrors && practicequizForm.get('prq_isotonic_drinks').invalid }">{{ 'practice.Q_10' | translate }}</h3>
            <div>
                <mat-radio-group formControlName="prq_isotonic_drinks">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <ng-container *ngIf="practicequizForm.value.prq_isotonic_drinks == 'כן'">
                <h3 [ngClass]="{ 'required-question': showErrors && isotonicTimesError }">{{ 'practice.Q_11' | translate }}</h3>
                <div>
                    <mat-checkbox class="checkbox" (change)="checkedFnArray('במהלך היום כשמתחשק לי', $event.checked, 'prq_isotonic_drinks_time_arr')" [checked]="practicequizForm.value.prq_isotonic_drinks_time_arr?.includes('במהלך היום כשמתחשק לי')" [disabled]="isLocked || isBusy">{{ 'practice.Q_11_A_1' | translate }}</mat-checkbox>
                    <mat-checkbox class="checkbox" (change)="checkedFnArray('בכל אימון או משחק', $event.checked, 'prq_isotonic_drinks_time_arr')" [checked]="practicequizForm.value.prq_isotonic_drinks_time_arr?.includes('בכל אימון או משחק')" [disabled]="isLocked || isBusy">{{ 'practice.Q_11_A_2' | translate }}</mat-checkbox>
                    <mat-checkbox class="checkbox" (change)="checkedFnArray('באימונים ממושכים ואינטנסיביים', $event.checked, 'prq_isotonic_drinks_time_arr')" [checked]="practicequizForm.value.prq_isotonic_drinks_time_arr?.includes('באימונים ממושכים ואינטנסיביים')" [disabled]="isLocked || isBusy">{{ 'practice.Q_11_A_3' | translate }}</mat-checkbox>
                    <mat-checkbox class="checkbox" (change)="checkedFnArray('במשחקים', $event.checked, 'prq_isotonic_drinks_time_arr')" [checked]="practicequizForm.value.prq_isotonic_drinks_time_arr?.includes('במשחקים')" [disabled]="isLocked || isBusy">{{ 'practice.Q_11_A_4' | translate }}</mat-checkbox>
                </div>
            </ng-container>

            <h3 [ngClass]="{ 'required-question': showErrors && beforePracticeError }">{{'practice.Q_12' | translate}}</h3>
            <div class="mb50">
                <mat-radio-group class="d-column" formControlName="prq_eat_before_practice">
                    <mat-radio-button value="תמיד">{{'practice.Q_12_A_1' | translate}}</mat-radio-button>
                    <mat-radio-button value="בדרך כלל">{{'practice.Q_12_A_2' | translate}}</mat-radio-button>
                    <mat-radio-button value="לעיתים רחוקות">{{'practice.Q_12_A_3' | translate}}</mat-radio-button>
                    <mat-radio-button value="אף פעם">{{'practice.Q_12_A_4' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <ng-container *ngIf="practicequizForm.value.prq_eat_before_practice === 'תמיד' || practicequizForm.value.prq_eat_before_practice === 'בדרך כלל' || practicequizForm.value.prq_eat_before_practice === 'לעיתים רחוקות'">
                <mat-form-field>
                    <mat-select multiple formControlName="prq_eat_before_practice_items_arr" placeholder="{{'practice.Q_13' | translate}}">
                        <mat-option *ngFor="let item of listFixedA" [value]="item.value">{{ item.text }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="practicequizForm.value.prq_eat_before_practice_items_arr && practicequizForm.value.prq_eat_before_practice_items_arr.includes('אחר')" class="w65">
                    <input formControlName="prq_eat_before_practice_other" matInput placeholder="{{'general.description' | translate}}">
                </mat-form-field>
            </ng-container>

            <h3 [ngClass]="{ 'required-question': showErrors && onBreaksError }">{{'practice.Q_14' | translate}}</h3>
            <div class="mb50">
                <mat-radio-group formControlName="prq_eat_on_breaks">
                    <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                    <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>
            <ng-container *ngIf="practicequizForm.value.prq_eat_on_breaks == 'כן'">
                <mat-form-field>
                    <mat-select multiple formControlName="prq_eat_on_breaks_items_arr" placeholder="{{'practice.Q_15' | translate}}">
                        <mat-option *ngFor="let item of listFixedB" [value]="item.value">{{ item.text }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="practicequizForm.value.prq_eat_on_breaks_items_arr && practicequizForm.value.prq_eat_on_breaks_items_arr.includes('אחר')" class="w65">
                    <input formControlName="prq_eat_on_breaks_other" matInput placeholder="{{'general.description' | translate}}">
                </mat-form-field>
            </ng-container>

            <h3 [ngClass]="{ 'required-question': showErrors && practicequizForm.get('prq_day_off_practice').invalid }">{{'practice.Q_4' | translate}}</h3>
            <div>
                <mat-radio-group class="d-column" formControlName="prq_day_off_practice">
                    <mat-radio-button value="תמיד יש">{{'practice.Q_4_A_1' | translate}}</mat-radio-button>
                    <mat-radio-button value="לפעמים יש">{{'practice.Q_4_A_2' | translate}}</mat-radio-button>
                    <mat-radio-button value="אין">{{'practice.Q_4_A_3' | translate}}</mat-radio-button>
                </mat-radio-group>
            </div>

            <ng-container *ngIf="isUnderNineteen">
                <h3 [ngClass]="{ 'required-question': showErrors && practicequizForm.get('prq_practice_timing').invalid }">{{'practice.Q_5' | translate}}</h3>
                <div>
                    <mat-radio-group formControlName="prq_practice_timing">
                        <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                        <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <ng-container *ngIf="practicequizForm.value.prq_practice_timing == 'כן'">

                    <h3>{{'practice.Q_3' | translate}}</h3>
                    <div *ngIf="showErrors && practicequizForm.get('prq_practice_timing_days').invalid" class="mat-error font-16">
                        <div>{{'practice.Q_3_ERROR' | translate}}</div>
                    </div>
                    <div formArrayName="prq_practice_timing_days">
                        <p *ngFor="let day of weekDays">
                            <mat-checkbox *ngIf="day != 'saturday'" class="checkbox" (change)="updateChkbxArray(day, $event.checked, 'prq_practice_timing_days')" [checked]="practicequizForm.value.prq_practice_timing_days.includes(day)" [disabled]="isLocked || isBusy">{{ ('practice.DAY_'+day) | translate }}</mat-checkbox>
                        </p>
                    </div>

                </ng-container>
            </ng-container>
        </mat-card>
        <br>

        <button mat-raised-button color="primary" class="full-width" [disabled]="isLocked || isBusy">{{'general.save' | translate}}</button>
        <div *ngIf="isLocked" class="font-12 text-center text-danger">
            {{'general.savedQuiz' | translate}}
        </div>
        <!-- <div *ngIf="isOtherUser" class="mt125 row">
            <div>
            </div>
            <button type="button" class="plus-button" mat-raised-button [routerLink]="['/Nutritionistform/', practicequizForm.value.prq_usr_id]">
                חזור לטופס תזונאי
                <mat-icon>undo</mat-icon>
            </button>
        </div> -->
        </div>
    </form>

</div>
