<div class="full-loader" *ngIf="busy || !allUsers.length">
    <div class="spinner">
        <div class="double-bounce1"></div>
        <div class="double-bounce2"></div>
    </div>
</div>

<div class="measure-report nutritionist-form" [ngClass]="{'loading-margin': busy || !allUsers.length}">
    <div class="fixed-buttons">
        <button type="button" mat-mini-fab color="accent" (click)="openAllPanels()">
            <mat-icon>unfold_more</mat-icon>
        </button>
        &nbsp;
        <button type="button" mat-mini-fab color="accent" (click)="closeAllPanels()">
            <mat-icon>unfold_less</mat-icon>
        </button>
    </div>
    <div class="row">
        <h1>
            דוח מדידות
        </h1>
        <div class="centered-row m-0 m-b-10">
            <div>
                הצג מדידה אחרונה
            </div>
            <mat-slide-toggle class="m-l-10 m-r-10" [(ngModel)]="isAllShown"></mat-slide-toggle>
            <div>
                הצג את כל מדידות העבר
            </div>
        </div>
    </div>

    <mat-accordion
    #accordion="matAccordion" [multi]="true"
    >

        <div class="mb50" *ngFor="let userData of allUsers">

            <mat-expansion-panel #exp expanded="false">
                <mat-expansion-panel-header>
                    <mat-panel-title class="font-16 font-bold">
                        <span *ngIf="userData.usr_fname || userData.usr_lname" class="font-bold m-l-5">{{(userData.usr_fname || '') + " " + (userData.usr_lname || '')}}</span>                        
                    </mat-panel-title>
                    <mat-panel-description>
                        <!-- <span *ngIf="userData.usr_gender" class="m-l-5">{{userData.usr_gender}},</span>
                        <span *ngIf="userData.usr_age_formatted" class="m-l-5">{{userData.usr_age_formatted}}</span>
                        <span *ngIf="!userData.usr_age_formatted" class="text-danger font-bold m-l-5">ללא תאריך לידה!</span> -->
                        <span *ngIf="(appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'nutri') && userData.usr_club_name" class="m-l-10 m-r-10">{{userData.usr_club_name}}</span>
                        <span *ngIf="userData.usr_team_name" class="m-l-10 m-r-10">({{userData.usr_team_name}})</span>
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <div *ngIf="!userData.showNoShare" style="display: flex; justify-content: flex-end;">
                    <mat-form-field class="w35 report-dropdown" *ngIf="userData.user_reports && userData.user_reports.length > 0">
                        <button type="button" [disabled]="!userData.chosenReport" matSuffix class="top-button" mat-stroked-button (click)="clickReport($event, userData.chosenReport)">הצג דוח</button>
                        <mat-select [(ngModel)]="userData.chosenReport" placeholder="תאריך">
                            <ng-container *ngFor="let val of userData.user_reports">
                                <mat-option [value]="val._id">
                                    {{ val['createdAt'] | date:'dd/MM/yyyy' }}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="user-details">
                    <div class="mb125 row">
                        <div class="w35">
                            <div *ngFor="let asset of userData.usr_profile_pic_asset_arr; let i = index;">
                                <img src="{{asset.url}}" class="profile-img pointer" (click)="imageClick(asset.url)">
                            </div>
                        </div>
                        <div class="w24 mt125 mb125">

                            <div *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'nutri'" class="mb125">
                                <button class="plus-button" mat-raised-button (click)="newNtq(userData)">
                                    <!-- {{'nuttest.newquiz' | translate}} -->
                                    הוספת מדידה חדשה
                                    <mat-icon>add</mat-icon>
                                </button>
                            </div>

                            <div class="mb75">
                                <span class="font-bold">סטטוס טפסים </span>
                                <span>
                                    <ng-container *ngIf="userData.quiz_saved == 3">
                                        <mat-icon class="userstable-status-icon invalid">priority_high</mat-icon>
                                        <span>({{ userData.quiz_days }})</span>
                                    </ng-container>
                                    <span *ngIf="userData.quiz_saved == 2">
                                        {{ userData.quiz_days }} 
                                        <!-- <span *ngIf="userData['usr_state'] && userData['usr_state']['stateUser']" class="dl dir-ltr nowrap-text">
                                            ({{ userData.usr_state.stateUser.done }} / {{ userData.usr_state.stateUser.totalSeen }})
                                        </span> -->
                                        <span *ngIf="userData['usr_state'] && userData['usr_state']['stateTable']" class="dl dir-ltr nowrap-text">
                                            ({{ userData.usr_state.stateTable.done }} / {{ userData.usr_state.stateTable.totalSeen }})
                                        </span>
                                        <!-- <span *ngIf="!isQa && userData['usr_state'] && userData['usr_state']['stateUser']" class="dl dir-ltr nowrap-text">
                                            ({{ userData.usr_state.stateUser.done }} / {{ userData.usr_state.stateUser.totalSeen }})
                                        </span>
                                        <span *ngIf="isQa && userData['usr_state'] && userData['usr_state']['stateUserQa']" class="dl dir-ltr nowrap-text">
                                            ({{ userData.usr_state.stateUserQa.done }} / {{ userData.usr_state.stateUserQa.totalSeen }})
                                        </span> -->
                                    </span>
                                    <mat-icon class="userstable-status-icon valid" *ngIf="userData.quiz_saved == 1">check</mat-icon>
                                </span>
                            </div>
                            <div class="mb75">
                                <span class="font-bold">יומן אכילה </span>
                                <span *ngIf="userData.food_days" class="dl dir-ltr">
                                    {{ userData.food_days }} / 3
                                </span>
                            </div>
                            <div class="mb75">
                                <span class="font-bold">בדיקת דם </span>
                                <span>
                                    <ng-container *ngIf="userData.blood_saved == 3">
                                        <mat-icon class="userstable-status-icon invalid">priority_high</mat-icon>
                                        <span>({{ userData.blood_days }})</span>
                                    </ng-container>
                                    <span *ngIf="userData.blood_saved == 2">
                                        {{ userData.blood_days }}
                                    </span>
                                    <ng-container *ngIf="userData.blood_saved == 1">
                                        <mat-icon class="userstable-status-icon valid">check</mat-icon>
                                        <span class="valid-color" *ngIf="userData.last_btq_date">{{ userData.last_btq_date | dateFormat : 'DD/MM/yyyy' }}</span>
                                    </ng-container>
                                </span>
                            </div>
                        </div>
                        <div class="w35">
                            <ul>
                                <li>
                                    מין: 
                                    {{userData.usr_gender}}
                                </li>
                                <li>
                                    גיל: 
                                    {{userData.usr_age_formatted}}
                                </li>
                                <li>
                                    תאריך לידה: 
                                    {{userData.usr_birth_date | date:'dd/MM/yyyy'}}
                                </li>
                                <li>
                                    ת.ז. 
                                    {{userData.usr_identification_number}}
                                </li>
                                <li>
                                    מועדון: 
                                    {{userData.usr_club_name}}
                                </li>
                                <li>
                                    קבוצה: 
                                    {{userData.usr_team_name}}
                                </li>
                                <li>
                                    טלפון: 
                                    {{userData.usr_phone}}
                                </li>
                                <li>
                                    מייל: 
                                    {{userData.usr_email}}
                                </li>
                            </ul>
                        </div>
                        <!-- <div class="w30">
                            <ng-container *ngIf="userData.usr_parent_1_phone">
                                <ul>
                                    <li class="usr-parent">
                                        {{userData.usr_parent_1_type}}: 
                                        {{userData.usr_parent_1_fname}} {{userData.usr_parent_1_lname}}
                                    </li>
                                    <li>
                                        טלפון: 
                                        {{userData.usr_parent_1_phone}}
                                    </li>
                                </ul>
                            </ng-container>
                            <ng-container *ngIf="userData.usr_parent_2_phone">
                                <ul class="mt125">
                                    <li class="usr-parent">
                                        {{userData.usr_parent_2_type}}: 
                                        {{userData.usr_parent_2_fname}} {{userData.usr_parent_2_lname}}
                                    </li>
                                    <li>
                                        טלפון: 
                                        {{userData.usr_parent_2_phone}}
                                    </li>
                                </ul>
                            </ng-container>
                        </div> -->
                    </div>
                </div>

                <hr class="m-b-30" />

                <div *ngIf="userData.showNoShare" class="font-italic">
                    <h4>לא אושר שיתוף</h4>
                </div>

                <!-- <div *ngIf="appState.user.usr_type === 'superadmin' || appState.user.usr_type === 'nutri'" class="row mb125">
                    <button class="plus-button" mat-raised-button (click)="newNtq(userData)">
                        הוספת מדידה חדשה
                        <mat-icon>add</mat-icon>
                    </button>
                </div> -->
                
                <div *ngIf="userData.userNtqs" class="user-measurements">
                    <div *ngFor="let ntq of userData.userNtqs; let i = index;">
                        <div class="mb125 basic-form" [ngClass]="{ 'hide': !isAllShown && i < userData.userNtqs.length - 1 }">
                            <ng-container [ngTemplateOutlet]="valuesTable" [ngTemplateOutletContext]="{ ntqs: userData.userNtqs, idx: i }"></ng-container>
                        </div>
                    </div>
                </div>

                <div *ngIf="exp.expanded && userData.userNtqs">
                    <charts-section [user]="userData" [nuttest]="userData.userNtqs[userData.userNtqs.length - 1]" [isMeasureReport]="true"></charts-section>
                </div>
                
                <div class="user-measurements-notes">
                    <mat-card *ngIf="userData.userNtqs" class="mat-elevation-z4">
                        <div *ngFor="let userNtq of userData.userNtqs; let idx = index;" class="mb125">
                            <div *ngIf="userData.userNtqs[idx]" class="d-flex mb50">
                                <span class="font-18 font-bold ul-text">מדידה {{ idx + 1 }}</span>
                                <span class="m-r-10 m-l-10 font-bold">({{ userData.userNtqs[idx]['ntq_test_date'] | date:'dd/MM/yyyy' }})</span>
                                <span *ngIf="userData.userNtqs[idx].is_by_user" class="font-italic">מדידה עצמאית</span>
                                <span *ngIf="!userData.userNtqs[idx].is_by_user" class="font-italic">מדידת תזונאי</span>
                            </div>
                            <div *ngIf="userData.userNtqs[idx] && userData.userNtqs[idx].ntq_notes && userData.userNtqs[idx].ntq_notes['B'] && userData.userNtqs[idx].ntq_notes['B'].length; else newNotes" class="m-r-5 m-l-5">
                                <div *ngFor="let note of userData.userNtqs[idx].ntq_notes['B']" class="mb50">
                                    {{ note | noteDetails : allNotes : (userData.usr_gender === 'נקבה' ? 'content_F' : 'content') }}
                                </div>
                            </div>

                            <ng-template #newNotes>
                                <div *ngIf="userData.userNtqs[idx] && userData.userNtqs[idx]._id && (userData.userNtqs[idx]._id | fdqDetails : allFdqValues : 'ntq') && (userData.userNtqs[idx]._id | fdqDetails : allFdqValues : 'ntq')['fdq_notes'] && (userData.userNtqs[idx]._id | fdqDetails : allFdqValues : 'ntq')['fdq_notes']['s11'] && (userData.userNtqs[idx]._id | fdqDetails : allFdqValues : 'ntq')['fdq_notes']['s11'].length; else noNotes" class="m-r-5 m-l-5">
                                    <div *ngFor="let note of (userData.userNtqs[idx]._id | fdqDetails : allFdqValues : 'ntq')['fdq_notes']['s11']" class="mb50">
                                        {{ note | noteDetails : allNotes : (userData.usr_gender === 'נקבה' ? 'content_F' : 'content') }}
                                    </div>
                                </div>
                            </ng-template>

                            <ng-template #noNotes>
                                <div class="m-r-5 m-l-5 mb50 font-12 font-italic">
                                    אין דיווחים
                                </div>
                            </ng-template>
                        </div>
                    </mat-card>
                </div>

            </mat-expansion-panel>
        </div>

    </mat-accordion>

</div>

<ng-template #valuesTable let-ntqs="ntqs" let-idx="idx">
    <div *ngIf="ntqs && ntqs[idx]" class="d-flex font-18 font-bold">
        <span>מדידה {{ idx + 1 }}</span>
        <span class="m-r-10 m-l-10">({{ ntqs[idx].ntq_test_date | date:'dd/MM/yyyy' }})</span>
        <span *ngIf="ntqs[idx].is_by_user">מדידה עצמאית</span>
        <span *ngIf="!ntqs[idx].is_by_user">מדידת תזונאי</span>
    </div>
    <div *ngIf="ntqs && ntqs[idx]" class="row user-report">
        <table class="antropometrics-table">
            <tr>
                <th>מידה</th>
                <th>ערך</th>
                <th *ngIf="idx > 1">הפרש ממדידה קודמת</th>
                <th *ngIf="idx > 0">הפרש ממדידה ראשונה</th>
                <th>סטטוס</th>
            </tr>
            <tr *ngIf="+(ntqs[idx].age_formatted || 0) < 20">
                <td>גובה (ס"מ)</td>
                <td>{{ ntqs[idx]['ntq_height'] }}</td>
                <td *ngIf="idx > 1" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, idx - 1, 'ntq_height') }}</td>
                <td *ngIf="idx > 0" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, 0, 'ntq_height') }}</td>
                <td>
                    <ng-container [ngTemplateOutlet]="statusIcon" [ngTemplateOutletContext]="{ status: ntqs[idx]['notes_nut_height'] }"></ng-container>
                </td>
            </tr>
            <tr *ngIf="ntqs[idx]['ntq_height_p']">
                <td>אחוזון גובה</td>
                <td>{{ ntqs[idx]['ntq_height_p'] }}</td>
                <td *ngIf="idx > 1" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, idx - 1, 'ntq_height_p', true) }}</td>
                <td *ngIf="idx > 0" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, 0, 'ntq_height_p', true) }}</td>
                <td>
                    <ng-container [ngTemplateOutlet]="statusIcon" [ngTemplateOutletContext]="{ status: ntqs[idx]['notes_nut_height'] }"></ng-container>
                </td>
            </tr>
            <tr>
                <td>משקל (ק"ג)</td>
                <td>{{ ntqs[idx]['ntq_weight'] }}</td>
                <td *ngIf="idx > 1" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, idx - 1, 'ntq_weight') }}</td>
                <td *ngIf="idx > 0" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, 0, 'ntq_weight') }}</td>
                <td>
                    <ng-container [ngTemplateOutlet]="statusIcon" [ngTemplateOutletContext]="{ status: ntqs[idx]['notes_nut_weight'] }"></ng-container>
                </td>
            </tr>
            <tr *ngIf="ntqs[idx]['ntq_weight_p']">
                <td>אחוזון משקל</td>
                <td>{{ ntqs[idx]['ntq_weight_p'] }}</td>
                <td *ngIf="idx > 1" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, idx - 1, 'ntq_weight_p', true) }}</td>
                <td *ngIf="idx > 0" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, 0, 'ntq_weight_p', true) }}</td>
                <td>
                    <ng-container [ngTemplateOutlet]="statusIcon" [ngTemplateOutletContext]="{ status: ntqs[idx]['notes_nut_weight'] }"></ng-container>
                </td>
            </tr>
            <tr>
                <td>BMI</td>
                <td>{{ ntqs[idx]['ntq_bmi'] }}</td>
                <td *ngIf="idx > 1" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, idx - 1, 'ntq_bmi') }}</td>
                <td *ngIf="idx > 0" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, 0, 'ntq_bmi') }}</td>
                <td>
                    <ng-container [ngTemplateOutlet]="statusIcon" [ngTemplateOutletContext]="{ status: ntqs[idx]['notes_nut_bmi'] }"></ng-container>
                </td>
            </tr>
            <tr *ngIf="ntqs[idx]['ntq_bmi_p']">
                <td>אחוזון BMI</td>
                <td>{{ ntqs[idx]['ntq_bmi_p'] }}</td>
                <td *ngIf="idx > 1" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, idx - 1, 'ntq_bmi_p', true) }}</td>
                <td *ngIf="idx > 0" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, 0, 'ntq_bmi_p', true) }}</td>
                <td>
                    <ng-container [ngTemplateOutlet]="statusIcon" [ngTemplateOutletContext]="{ status: ntqs[idx]['notes_nut_bmi'] }"></ng-container>
                </td>
            </tr>
            <ng-container *ngIf="(ntqs[idx]['ntq_fat_percent'] === 'קליפר' || ntqs[idx]['ntq_fat_percent'] === 'הזנה ידנית')">
                <tr>
                    <td>אחוז שומן (%)</td>
                    <td>{{ ntqs[idx]['ntq_fat_percent_num'] ? ntqs[idx]['ntq_fat_percent_num'] + '%' : ntqs[idx]['ntq_fat_age'] }}</td>
                    <td *ngIf="idx > 1" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, idx - 1, 'ntq_fat', true) }}</td>
                    <td *ngIf="idx > 0" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, 0, 'ntq_fat', true) }}</td>
                    <td>
                        <ng-container [ngTemplateOutlet]="statusIcon" [ngTemplateOutletContext]="{ status: ntqs[idx]['notes_nut_fat'] }"></ng-container>
                    </td>
                </tr>
                <tr *ngIf="ntqs[idx]['ntq_skinny_mass']">
                    <td>מסה רזה (ק"ג)</td>
                    <td>{{ ntqs[idx]['ntq_skinny_mass'] }}</td>
                    <td *ngIf="idx > 1" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, idx - 1, 'ntq_skinny_mass') }}</td>
                    <td *ngIf="idx > 0" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, 0, 'ntq_skinny_mass') }}</td>
                    <td></td>
                </tr>
                <tr *ngIf="ntqs[idx]['ntq_fat_mass']">
                    <td>מסת שומן (ק"ג)</td>
                    <td>{{ ntqs[idx]['ntq_fat_mass'] }}</td>
                    <td *ngIf="idx > 1" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, idx - 1, 'ntq_fat_mass') }}</td>
                    <td *ngIf="idx > 0" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, 0, 'ntq_fat_mass') }}</td>
                    <td></td>
                </tr>
            </ng-container>
            <tr *ngIf="ntqs[idx]['ntq_wingspan']">
                <td>מוטת ידיים (ס"מ)</td>
                <td>{{ ntqs[idx]['ntq_wingspan'] }}</td>
                <td *ngIf="idx > 1" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, idx - 1, 'ntq_wingspan') }}</td>
                <td *ngIf="idx > 0" class="dir-ltr">{{ calcDiffIdx(ntqs, idx, 0, 'ntq_wingspan') }}</td>
                <td></td>
            </tr>
        </table>
    </div>
</ng-template>

<ng-template #statusIcon let-status="status">
    <mat-icon class="status-icon valid" *ngIf="status=='תקין'">check</mat-icon>
    <mat-icon class="status-icon invalid" *ngIf="status=='לא תקין'">priority_high</mat-icon>
</ng-template>
