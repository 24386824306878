import { Component, Input } from '@angular/core';
import { appState } from '@services/app-state';
import { Chart } from 'angular-highcharts';
import { SeriesOptionsType, FormatterCallbackFunction, Point, TooltipPositionerCallbackFunction, PositionObject, Tooltip, TooltipPositionerPointObject } from 'highcharts';
import { lineCharts } from '@services/line-charts';

import { Appcalcs } from '@services/app-calcs';
import * as moment from 'moment-timezone';

@Component({
  selector: 'charts-section',
  templateUrl: './charts.component.html'
})
export class ChartsComponent {
  // @Output() busyState: EventEmitter<boolean> = new EventEmitter();
  @Input() user: any;
  @Input() nuttest?: any;
  @Input() isMeasureReport?: boolean;
  
  oldValues = [];

  appCalcs = Appcalcs;
  appState = appState;

  statage: Chart;
  wtage: Chart;
  bmiage: Chart;

  constructor() { }

  ngOnInit() {
    this.initCharts(this.user);
  }

  filterNtqs(user) {
    if (!user || !user.user_ntqs_filtered || !user.usr_birth_date) { return []; }
    let arr: any = [];
    for (let ntq of user.user_ntqs_filtered) {
      this.appCalcs.updatePercents(ntq, user, false, true);
      if (ntq.age_calc && ntq.age_calc < 20) {
        arr.push(ntq);
      }
    }
    arr.sort((a, b) => +(a.age_calc) - +(b.age_calc));
    return arr;
  }

  initCharts(user) {
    const oldValues = this.filterNtqs(user);
    if (!oldValues.length || !(user.usr_gender_type === 'F' || user.usr_gender_type === 'M')) {
      return;
    }
    const gender = user.usr_gender_type;
    const statage = this.initChart({ chartType: 'statage', minY: 70, maxY: 210, tickIntervalY: 10 }, gender);
    const wtage = this.initChart({ chartType: 'wtage', minY: 10, maxY: 120, tickIntervalY: 10 }, gender);
    const bmiage = this.initChart({ chartType: 'bmiage', minY: 10, maxY: 45, tickIntervalY: 5 }, gender);
    // const valByNut = oldValuesFiltered.filter(val => !val.is_by_user);
    // const valByUser = oldValuesFiltered.filter(val => val.is_by_user);
    // this.addUserSeries(statage, valByNut, 'ntq_height', 'מדידות עבר תזונאי');
    // this.addUserSeries(wtage, valByNut, 'ntq_weight', 'מדידות עבר תזונאי');
    // this.addUserSeries(bmiage, valByNut, 'ntq_bmi', 'מדידות עבר תזונאי');
    // this.addUserSeries(statage, valByUser, 'ntq_height', 'מדידות עבר משתמש');
    // this.addUserSeries(wtage, valByUser, 'ntq_weight', 'מדידות עבר משתמש');
    // this.addUserSeries(bmiage, valByUser, 'ntq_bmi', 'מדידות עבר משתמש');

    this.addUserSeries(statage, oldValues, 'ntq_height', 'מדידות עבר');
    this.addUserSeries(wtage, oldValues, 'ntq_weight', 'מדידות עבר');
    this.addUserSeries(bmiage, oldValues, 'ntq_bmi', 'מדידות עבר');

    // return { statage, wtage, bmiage };
    this.statage = statage;
    this.wtage = wtage;
    this.bmiage = bmiage;
  }

  initChart(properties, gender) {
    const { chartType, minY, maxY, tickIntervalY } = properties;
    const gridColors = {
      'M': '#9ECCEF',
      'F': '#FFD2E7'
    };
    const pointFormatter: FormatterCallbackFunction<Point> = function () {
      let content = '';
      const roundY = Math.round(this.y * 10) / 10;
      if (this.options?.custom?.testDate) {
        const ntqType = this.options.custom.isByUser ? 'מדידת משתמש' : 'מדידת תזונאי';
        content = `<br/><div><b>${moment(this.options.custom.testDate).format("DD/MM/YYYY")}</b></div><div>${ntqType} - <b>${roundY}</b></div><br/>`; // `<div>${this.x} שנים</div>`;
      } else {
        content = `<div>${this.series.name} - <b>${roundY}</b></div>`
      }
      return `<div class='dir-rtl text-right'>${content}</div>`;
    }

    const positioner: TooltipPositionerCallbackFunction = function (this: Tooltip, labelWidth: number, labelHeight: number, point: TooltipPositionerPointObject) {
      const pointX = point.plotX;
      const pointY = point.plotY;
      const x = Math.max(pointX - 100, 0);
      const y = Math.max(pointY - 160, 0);
      return { x , y };
    };

    let series = [];
    for (const percent of ['95','90','75','50','25','10','5']) { // ['5','10','25','50','75','90','95']
      series.push({
        name: `${percent}%`,
        type: 'line',
        data: lineCharts[chartType][gender][percent],
        marker: {
            enabled: false
        },
      });
    }
    let chart = new Chart({
      chart: {
        type: 'line',
        // width: 550,
        height: '160%',
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      series,
      xAxis: {
        min: 2,
        max: 20,
        endOnTick: true,
        tickInterval: 1,
        gridLineWidth: 1,
        gridLineColor: gridColors[gender],
      },
      yAxis: {
        min: minY,
        max: maxY,
        title: {
          text: ''
        },
        alignTicks: false,
        tickInterval: tickIntervalY,
        gridLineWidth: 1,
        gridLineColor: gridColors[gender],
      },
      tooltip: {
        shared: true,
        useHTML: true,
        headerFormat: '',
        pointFormatter,
        positioner
      },
    });
    return chart;
  }

  addUserSeries(chart, oldValuesFiltered, prop, title) {
    let data = [];
    for (const val of oldValuesFiltered) {
      const age = +(val.age_calc);
      const propVal = +(val[prop]);
      let newPoint: any = {
        x: age,
        y: propVal,
        options: {
          custom: {
            testDate: val.ntq_test_date,
            isByUser: Boolean(val.is_by_user)
          }
        },
      }
      if (val.is_by_user) {
        newPoint.color = '#BF0B23';
        newPoint.marker = { symbol: 'square'} ;
      }
      data.push(newPoint);
    }

    const newSerie: SeriesOptionsType = {
      name: `${title}`,
      type: 'line',
      data,
      marker: {
          enabled: true,
          radius: 3,
          symbol: 'circle'
      },
    }
    chart.addSeries(newSerie, true, false);
  }

}

