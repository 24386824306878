<div class="quiz">

    <form novalidate class="basic-form" [formGroup]="healthquizForm" (ngSubmit)="onSubmit(healthquizForm, quizPrefix)">

        <div class="row">
            <h1>{{'health.QUIZ_TITLE' | translate}}</h1>
            <!-- <button type="button" *ngIf="isOtherUser" class="plus-button" mat-raised-button [routerLink]="['/Nutritionistform/', healthquizForm.value.heq_usr_id]">
                חזור לטופס תזונאי
                <mat-icon>undo</mat-icon>
            </button> -->
            
            <button type="button" *ngIf="appState.user && appState.user.usr_type == 'parent'" class="plus-button" mat-raised-button [routerLink]="['/Userparent']">
                <span>{{'general.undo' | translate}} </span>
                <mat-icon>undo</mat-icon>
            </button>
        </div>
        
        <mat-card *ngIf="!healthquizForm.value._id || healthquizForm.value._id==''">
            <div class="spinner">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
        </mat-card>

        <div [hidden]="!healthquizForm.value._id || healthquizForm.value._id==''">

            <span #heq_tiredness_during_day_el></span>
            <mat-card>
                <h3>{{'sleeping.Q_6' | translate}}</h3>
                <div class="w27">
                    <mat-radio-group formControlName="heq_tiredness_during_day">
                        <mat-radio-button value="כן">{{'general.yes' | translate}}</mat-radio-button>
                        <mat-radio-button value="לא">{{'general.no' | translate}}</mat-radio-button>
                    </mat-radio-group>
                </div>
            </mat-card>

            <br>

            <span #heq_suffer_various_el></span>
            <mat-card>
                <h3 style="margin: 0;">{{'health.Q_1' | translate}}</h3>

                <ul>
                    <li>{{'health.Q_1_SUB_1' | translate}}</li>
                    <li>{{'health.Q_1_SUB_2' | translate}}</li>
                    <li>{{'health.Q_1_SUB_3' | translate}}</li>
                    <li>{{'health.Q_1_SUB_4' | translate}}</li>
                    <li>{{'health.Q_1_SUB_5' | translate}}</li>
                </ul>

                <mat-radio-group fxLayout fxLayoutAlign="center center" fxLayoutGap="30px"
                    formControlName="heq_suffer_various">
                    <mat-radio-button #yesSufferVariousRadioOption value="yes">{{'health.Q_1_YES' | translate}}</mat-radio-button>
                    <mat-radio-button #noSufferVariousRadioOption value="no" (click)="confirmAndResetSuffers($event)">{{'health.Q_1_NO' | translate}}</mat-radio-button>
                </mat-radio-group>

            <div class="p-10" *ngIf="healthquizForm.value.heq_suffer_various == 'yes'">

                <span #heq_suffer_various_el_1></span>
                <h4>{{'health.Q_1_TITLE' | translate}}</h4>
                <span #heq_suffer_weekness_tired_train_el></span>
                <mat-checkbox class="checkbox" formControlName="heq_suffer_weekness_tired_train">{{'health.Q_1_A' | translate}}</mat-checkbox>
                <ng-container *ngIf="healthquizForm.value.heq_suffer_weekness_tired_train == true">
                    <mat-card class="mat-elevation-z-custom">
                        <mat-form-field class="w65">
                            <mat-select formControlName="heq_suffer_weekness_tired_train_desc"
                                placeholder="{{ 'health.Q1_2' | translate }}">
                                <mat-option value="לעיתים רחוקות">{{ 'health.Q1_2' | translate }}</mat-option>
                                <mat-option value="במשחקים">{{ 'health.Q1_3' | translate }}</mat-option>
                                <mat-option value="מספר פעמים בשבוע">{{ 'health.Q1_4' | translate }}</mat-option>
                                <mat-option value="בדרך כלל">{{ 'health.Q1_5' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div>
                            <button (click)="resetField('heq_suffer_weekness_tired_train',false)" type="button"
                                mat-raised-button color="warn" mat-button [disabled]="isLocked">{{'health.Q_1_A_REMOVE' | translate}}<mat-icon>
                                    remove_circle_outline</mat-icon></button>
                        </div>
                    </mat-card>
                </ng-container>


                <mat-checkbox class="checkbox" formControlName="heq_suffer_digestion">{{ 'health.Q1_6' | translate }}</mat-checkbox>
                <ng-container *ngIf="healthquizForm.value.heq_suffer_digestion == true">
                    <mat-card class="mat-elevation-z-custom">
                        <span #heq_suffer_digestion_el></span>
                        <h4>{{'health.Q_1_B_CHOOSE' | translate}}</h4>
                        <div class="heq-col mb50">
                            <mat-checkbox class="checkbox normal" formControlName="heq_suffer_digestion_gas">{{ 'health.Q1_7' | translate }}</mat-checkbox>
                            <mat-checkbox class="checkbox normal" formControlName="heq_suffer_digestion_stomach_pain">{{ 'health.Q1_8' | translate }}</mat-checkbox>
                            <mat-checkbox class="checkbox normal" formControlName="heq_suffer_digestion_diarrhea">{{ 'health.Q1_9' | translate }}</mat-checkbox>
                            <mat-checkbox class="checkbox normal" formControlName="heq_suffer_digestion_constipation">{{ 'health.Q1_10' | translate }}</mat-checkbox>
                            <mat-checkbox class="checkbox normal" formControlName="heq_suffer_digestion_frequent">{{ 'health.Q1_11' | translate }}</mat-checkbox>
                            <span #heq_suffer_digestion_other_el></span>
                            <mat-checkbox class="checkbox normal" formControlName="heq_suffer_digestion_other">{{ 'health.Q1_12' | translate }}</mat-checkbox>
                        </div>
                        <div>
                            <mat-form-field *ngIf="healthquizForm.value.heq_suffer_digestion_other == true" class="w45">
                                <input formControlName="heq_suffer_digestion_other_text" matInput
                                    placeholder="{{'health.Q_1_B_EXPLAIN' | translate}}">
                            </mat-form-field>
                        </div>

                        <span #heq_suffer_digestion_excited_el></span>
                        <div class="mt125 mb50">
                            {{'health.Q_1_B_A' | translate}}
                        </div>
                        <div class="mb50">
                            <mat-radio-group formControlName="heq_suffer_digestion_excited">
                                <mat-radio-button value="yes">{{'health.Q_1_B_A_YES' | translate}}</mat-radio-button>
                                <mat-radio-button value="no">{{'health.Q_1_B_A_NO' | translate}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div>
                            <mat-form-field *ngIf="healthquizForm.value.heq_suffer_digestion_excited == 'no'" class="w45">
                                <input formControlName="heq_suffer_digestion_excited_text" matInput
                                    placeholder="{{'health.Q_1_B_A_EXPLAIN' | translate}}">
                            </mat-form-field>
                        </div>


                        <span #heq_suffer_digestion_comment_el></span>
                        <div class="mt125 mb50">
                            {{'health.Q_1_B_A_REMARKS' | translate}}
                        </div>

                        <div class="mb50">
                            <mat-radio-group formControlName="heq_suffer_digestion_comment">
                                <mat-radio-button #yesSufferDigestionOption value="yes">{{'general.yes' | translate}}</mat-radio-button>
                                <mat-radio-button value="no">{{'general.no' | translate}}</mat-radio-button>
                            </mat-radio-group>
                        </div>
                        <div>
                            <mat-form-field *ngIf="healthquizForm.value.heq_suffer_digestion_comment == 'yes'" class="w45">
                                <input formControlName="heq_suffer_digestion_comment_text" matInput placeholder="{{'general.extraNotes' | translate}}">
                            </mat-form-field>
                        </div>
                        <div [ngClass]="{'mt125' : healthquizForm.value.heq_suffer_digestion_comment != 'yes'}">
                            <button (click)="resetField('heq_suffer_digestion',false)" type="button" mat-raised-button
                                color="warn" mat-button [disabled]="isLocked">{{'health.Q_1_B_A_REMOVE' | translate}}<mat-icon>remove_circle_outline</mat-icon>
                                </button>
                        </div>
                    </mat-card>
                </ng-container>

                <mat-checkbox class="checkbox" (change)="sufferCheckboxOnChange($event, 'heq_suffer_chronic_condition_arr')"
                    formControlName="heq_suffer_chronic_condition">{{ 'health.Q1_13' | translate }}</mat-checkbox>
                <ng-container
                    *ngIf="healthquizForm.value.heq_suffer_chronic_condition == true && healthquizForm.value.heq_suffer_chronic_condition_arr.length > 0"
                    [ngTemplateOutlet]="sufferTemplate" [ngTemplateOutletContext]="{
                        context: formContext,           
                        checkboxFieldName: 'heq_suffer_chronic_condition',
                        arrFieldName: 'heq_suffer_chronic_condition_arr',
                        descriptionFieldLabel: 'health.Q_1_B_B_EXPLAIN' | translate,
                        addButtonTitle: 'health.Q_1_B_B_ADD' | translate,
                        removeButtonTitle: 'health.Q_1_B_B_REMOVE' | translate,
                        addOnClick: addSufferItem,
                        removeOnClick: removeSufferItem
                    }">

                </ng-container>

                <ng-template #sufferTemplate let-context="context" let-checkboxFieldName="checkboxFieldName"
                    let-arrFieldName="arrFieldName" let-descriptionFieldLabel="descriptionFieldLabel"
                    let-addButtonTitle="addButtonTitle" let-removeButtonTitle="removeButtonTitle"
                    let-addOnClick="addOnClick" let-removeOnClick="removeOnClick">
                    <mat-card class="mat-elevation-z-custom">
                        <div class="repeat-container" formArrayName="{{arrFieldName}}"
                            *ngFor="let control of context.healthquizForm.get(arrFieldName).controls; let i = index;">
                            <div [formGroupName]="i">
                                <span #heq_suffer_description_el id={{arrFieldName}}></span>
                                <mat-form-field class="w45">
                                    <input formControlName="description" matInput placeholder="{{descriptionFieldLabel}}">
                                </mat-form-field>
                                
                                <span #heq_suffer_started_date_el id={{arrFieldName}}></span>
                                <div class="w65">
                                    <mat-form-field>
                                        <input #formDate matInput [matDatepicker]="dpStarted" placeholder="{{ 'health.Q1_14' | translate }}"
                                            formControlName="started_date">
                                        <mat-datepicker-toggle matSuffix [for]="dpStarted"></mat-datepicker-toggle>
                                        <mat-datepicker #dpStarted></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <span #heq_suffer_current_status_el id={{arrFieldName}}></span>
                                <div>אני:</div>
                                <div class="mb50">
                                    <mat-radio-group formControlName="current_status">
                                        <mat-radio-button value="מתאמן כרגיל">{{'health.Q_1_B_B_1' | translate}}</mat-radio-button>
                                        <mat-radio-button value="מתאמן חלקית">{{'health.Q_1_B_B_2' | translate}}</mat-radio-button>
                                        <mat-radio-button value="מושבת מפעילות">{{'health.Q_1_B_B_3' | translate}}</mat-radio-button>
                                    </mat-radio-group>
                                </div>
                                <span #heq_suffer_end_date_el id={{arrFieldName}}></span>
                                <div>
                                    <mat-form-field
                                        *ngIf="control.value.current_status != '' && control.value.current_status != 'מתאמן כרגיל'"
                                        class="w65">
                                        <input #formDate matInput [matDatepicker]="dpEnd"
                                            placeholder="{{'health.Q_1_B_B_4' | translate}}" formControlName="end_date">
                                        <mat-datepicker-toggle matSuffix [for]="dpEnd"></mat-datepicker-toggle>
                                        <mat-datepicker #dpEnd></mat-datepicker>
                                    </mat-form-field>
                                </div>

                                <div class="buttons-row">
                                    <button (click)="removeOnClick(context, checkboxFieldName, arrFieldName, i)"
                                        type="button" mat-raised-button color="warn" mat-button [disabled]="isLocked">{{removeButtonTitle}}
                                        <mat-icon>remove_circle_outline</mat-icon></button>
                                </div>


                            </div>

                        </div>
                        <div class="add-button-container">
                            <button (click)="addOnClick(context, arrFieldName)" type="button" mat-raised-button
                                color="primary" mat-button [disabled]="isLocked">{{addButtonTitle}}<mat-icon>add_circle_outline</mat-icon>
                                </button>
                        </div>
                    </mat-card>


                </ng-template>


                <mat-checkbox class="checkbox" (change)="sufferCheckboxOnChange($event, 'heq_suffer_acute_condition_arr')"
                    formControlName="heq_suffer_acute_condition">{{ 'health.Q1_15' | translate }}</mat-checkbox>
                <ng-container
                    *ngIf="healthquizForm.value.heq_suffer_acute_condition == true && healthquizForm.value.heq_suffer_acute_condition_arr.length > 0"
                    [ngTemplateOutlet]="sufferTemplate" [ngTemplateOutletContext]="{
                        context: formContext,
                        checkboxFieldName: 'heq_suffer_acute_condition',
                        arrFieldName: 'heq_suffer_acute_condition_arr',
                        descriptionFieldLabel: 'health.Q_1_B_C_EXPLAIN' | translate,
                        addButtonTitle: 'health.Q_1_B_C_ADD' | translate,
                        removeButtonTitle: 'health.Q_1_B_C_REMOVE' | translate,
                        addOnClick: addSufferItem,
                        removeOnClick: removeSufferItem
                    }">

                </ng-container>
                <mat-checkbox class="checkbox" (change)="sufferCheckboxOnChange($event, 'heq_suffer_acute_injury_arr')"
                    formControlName="heq_suffer_acute_injury">{{ 'health.Q1_16' | translate }}</mat-checkbox>
                <ng-container
                    *ngIf="healthquizForm.value.heq_suffer_acute_injury == true && healthquizForm.value.heq_suffer_acute_injury_arr.length > 0"
                    [ngTemplateOutlet]="sufferTemplate" [ngTemplateOutletContext]="{
                        context: formContext,
                        checkboxFieldName: 'heq_suffer_acute_injury',
                        arrFieldName: 'heq_suffer_acute_injury_arr',
                        descriptionFieldLabel: 'health.Q_1_B_D_EXPLAIN' | translate,
                        addButtonTitle: 'health.Q_1_B_D_ADD' | translate,
                        removeButtonTitle: 'health.Q_1_B_D_REMOVE' | translate,
                        addOnClick: addSufferItem,
                        removeOnClick: removeSufferItem
                    }">

                </ng-container>
                <span #heq_suffer_bad_feeling_or_weekness_el></span>
                <mat-checkbox class="checkbox" formControlName="heq_suffer_bad_feeling_or_weekness">{{ 'health.Q1_17' | translate }}</mat-checkbox>
                <ng-container *ngIf="healthquizForm.value.heq_suffer_bad_feeling_or_weekness == true">
                    <mat-card class="mat-elevation-z-custom">
                        <mat-form-field class="w45">
                            <input formControlName="heq_suffer_bad_feeling_or_weekness_desc" matInput
                                placeholder="{{ 'health.Q1_18' | translate }}">
                        </mat-form-field>
                        <div>
                            <button (click)="resetField('heq_suffer_bad_feeling_or_weekness', false)" type="button"
                                mat-raised-button color="warn" mat-button [disabled]="isLocked">{{'health.Q_1_B_REMOVE' | translate}}<mat-icon>
                                    remove_circle_outline</mat-icon></button>
                        </div>
                    </mat-card>
                </ng-container>
            </div>
            </mat-card>

            <br>
            <span #heq_food_allergy_intolerance_el></span>
            <mat-card>
                    <h3>{{'health.Q_2' | translate}}</h3>
        
                    <!-- <mat-radio-group fxLayout fxLayoutAlign="center center" fxLayoutGap="30px" formControlName="heq_food_allergy_intolerance">
                        <mat-radio-button value="כן">{{ 'general.yes' | translate }}</mat-radio-button>
                        <mat-radio-button value="לא" (click)="confirmAndResetSuffersNuq($event)">{{ 'general.no' | translate }}</mat-radio-button>
                    </mat-radio-group> -->

                    <mat-radio-group fxLayout fxLayoutAlign="center center" fxLayoutGap="30px" formControlName="heq_food_allergy_intolerance">
                        <mat-radio-button value="כן">{{ 'general.yes' | translate }}</mat-radio-button>
                        <mat-radio-button value="לא">{{ 'general.no' | translate }}</mat-radio-button>
                    </mat-radio-group>

                    <div class="p-10" *ngIf="healthquizForm.value.heq_food_allergy_intolerance == 'כן'">
            
                        <ng-container *ngIf="healthquizForm.value.heq_allergy_arr.length > 0" [ngTemplateOutlet]="sufferTemplateNuq" [ngTemplateOutletContext]="{
                                context: formContext,           
                                checkboxFieldName: 'heq_food_intolerance',
                                arrFieldName: 'heq_allergy_arr',
                                productNameFieldLabel: 'health.Q_2_B_1' | translate,
                                howDiagnosedFieldLabel: 'health.Q_2_B_2' | translate,
                                moreCommentsTitle: 'health.Q_2_B_3' | translate,
                                addButtonTitle: 'health.Q_2_B_4' | translate,
                                removeButtonTitle: 'health.Q_2_B_5' | translate,
                                addOnClick: addSufferItemNuq,
                                removeOnClick: removeSufferItem
                            }">
                        </ng-container>
        
                    </div>
        
                <!-- <span #heq_food_allergy_intolerance_el_1></span>
                <div class="p-10" *ngIf="healthquizForm.value.heq_food_allergy_intolerance == 'כן'">
        
                    <h4>{{'health.Q_2_TITLE' | translate}}</h4>
        
                    <mat-checkbox class="checkbox" (change)="sufferCheckboxOnChangeNuq($event, 'heq_food_intolerance_arr')" formControlName="heq_food_intolerance">{{ 'health.Q2_1' | translate }}</mat-checkbox>
                    <ng-container *ngIf="healthquizForm.value.heq_food_intolerance == true && healthquizForm.value.heq_food_intolerance_arr.length > 0" [ngTemplateOutlet]="sufferTemplateNuq" [ngTemplateOutletContext]="{
                            context: formContext,           
                            checkboxFieldName: 'heq_food_intolerance',
                            arrFieldName: 'heq_food_intolerance_arr',
                            productNameFieldLabel: 'health.Q_2_A_1' | translate,
                            howDiagnosedFieldLabel: 'health.Q_2_A_2' | translate,
                            moreCommentsTitle: 'health.Q_2_A_3' | translate,
                            addButtonTitle: 'health.Q_2_A_4' | translate,
                            removeButtonTitle: 'health.Q_2_A_5' | translate,
                            addOnClick: addSufferItemNuq,
                            removeOnClick: removeSufferItem
                        }">
                    </ng-container>
        
                    <mat-checkbox class="checkbox" (change)="sufferCheckboxOnChangeNuq($event, 'heq_food_allergy_arr')" formControlName="heq_food_allergy">{{ 'health.Q2_2' | translate }}</mat-checkbox>
                    <ng-container *ngIf="healthquizForm.value.heq_food_allergy == true && healthquizForm.value.heq_food_allergy_arr.length > 0" [ngTemplateOutlet]="sufferTemplateNuq" [ngTemplateOutletContext]="{
                            context: formContext,
                            checkboxFieldName: 'heq_food_allergy',
                            arrFieldName: 'heq_food_allergy_arr',
                            productNameFieldLabel: 'health.Q_2_B_1' | translate,
                            howDiagnosedFieldLabel: 'health.Q_2_B_2' | translate,
                            moreCommentsTitle: 'health.Q_2_B_3' | translate,
                            addButtonTitle: 'health.Q_2_B_4' | translate,
                            removeButtonTitle: 'health.Q_2_B_5' | translate,
                            addOnClick: addSufferItemNuq,
                            removeOnClick: removeSufferItem
                        }">
                    </ng-container>
        
                </div> -->
            </mat-card>
        
                <ng-template #sufferTemplateNuq let-context="context" let-checkboxFieldName="checkboxFieldName" let-arrFieldName="arrFieldName" let-productNameFieldLabel="productNameFieldLabel" let-howDiagnosedFieldLabel="howDiagnosedFieldLabel" let-moreCommentsTitle="moreCommentsTitle"
                    let-addButtonTitle="addButtonTitle" let-removeButtonTitle="removeButtonTitle" let-addOnClick="addOnClick" let-removeOnClick="removeOnClick">
                    <mat-card>
                        <div class="repeat-container" formArrayName="{{arrFieldName}}" *ngFor="let control of context.healthquizForm.get(arrFieldName).controls; let i = index; let last = last;">
                            <div [formGroupName]="i">
                                <span #heq_suffer_product_name_el id={{arrFieldName}}></span>
                                <div>
                                    <mat-form-field class="w45">
                                        <mat-select formControlName="product_name" placeholder="{{productNameFieldLabel}}">
                                            <!-- <mat-option value="בוטנים">{{ 'health.Q2_3' | translate }}</mat-option>
                                            <mat-option value="חלב">{{ 'health.Q2_4' | translate }}</mat-option>
                                            <mat-option value="גלוטן (צליאק)">{{ 'health.Q2_5' | translate }}</mat-option>
                                            <mat-option value="סומסום">{{ 'health.Q2_6' | translate }}</mat-option>
                                            <mat-option value="אחר">{{ 'health.Q2_7' | translate }}</mat-option> -->
                                            <mat-option *ngFor="let option of nutallergies" [value]="option._id">{{option.label}}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <span #heq_suffer_product_name_other_details_el id={{arrFieldName}}></span>
                                <mat-form-field *ngIf="control.value.product_name == 'אחר'" class="w45">
                                    <input formControlName="product_name_other_details" matInput placeholder="{{'general.description' | translate}}">
                                </mat-form-field>

                                <span #heq_suffer_how_diagnosed_el id={{arrFieldName}}></span>
                                <div>
                                    <mat-form-field class="w45">
                                        <mat-select formControlName="how_diagnosed" placeholder="{{howDiagnosedFieldLabel}}">
                                            <mat-option value="בדיקת דם">{{ 'health.Q2_8' | translate }}</mat-option>
                                            <mat-option value="בדיקת אלרגיה">{{ 'health.Q2_9' | translate }}</mat-option>
                                            <mat-option value="רופא">{{ 'health.Q2_10' | translate }}</mat-option>
                                            <mat-option value="אחר">{{ 'health.Q2_11' | translate }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <span #heq_suffer_how_diagnosed_other_details_el id={{arrFieldName}}></span>
                                <mat-form-field *ngIf="control.value.how_diagnosed == 'אחר'" class="w45">
                                    <input formControlName="how_diagnosed_other_details" matInput placeholder="{{'general.description' | translate}}">
                                </mat-form-field>

                                <span #heq_suffer_diagnosed_date_el id={{arrFieldName}}></span>
                                <div class="w45">
                                    <mat-form-field>
                                        <input #formDate matInput [matDatepicker]="dpStarted" placeholder="{{ 'health.Q2_12' | translate }}" formControlName="diagnosed_date">
                                        <mat-datepicker-toggle matSuffix [for]="dpStarted"></mat-datepicker-toggle>
                                        <mat-datepicker #dpStarted></mat-datepicker>
                                    </mat-form-field>
                                </div>
        
                                <span #heq_suffer_more_comments_el id={{arrFieldName}}></span>    
                                <div class="mb50">{{moreCommentsTitle}}</div>
                                <mat-radio-group formControlName="more_comments">
                                    <mat-radio-button value="כן">{{ 'general.yes' | translate }}</mat-radio-button>
                                    <mat-radio-button value="לא">{{ 'general.no' | translate }}</mat-radio-button>
                                </mat-radio-group>

                                <span #heq_suffer_more_comments_text_el id={{arrFieldName}}></span>
                                <div *ngIf="control.value.more_comments == 'כן'">
                                    <mat-form-field class="w45">
                                        <input formControlName="more_comments_text" matInput placeholder="{{ 'general.description' | translate }}">
                                    </mat-form-field>
                                </div>
        
                                <div class="buttons-row">
                                    <button (click)="removeOnClick(context, checkboxFieldName, arrFieldName, i)" type="button" mat-raised-button color="warn" mat-button [disabled]="isLocked">{{removeButtonTitle}}<mat-icon>remove_circle_outline</mat-icon></button>
                                </div>
        
                            </div>
        
                        </div>
                        <div class="add-button-container">
                            <button (click)="addOnClick(context, arrFieldName)" type="button" mat-raised-button color="primary" mat-button [disabled]="isLocked">{{addButtonTitle}}<mat-icon>add_circle_outline</mat-icon></button>
                        </div>
                    </mat-card>
        
        
                </ng-template>

            <br>
            <span #heq_using_various_el></span>
            <mat-card>
                <div>
                    <h3 style="margin: 0;">{{'health.Q_3' | translate}}</h3>
                    <ul>
                        <li>{{'health.Q_3_SUB_1' | translate}}</li>
                        <li>{{'health.Q_3_SUB_2' | translate}}</li>
                        <li>{{'health.Q_3_SUB_3' | translate}}</li>
                    </ul>
                </div>

                <mat-radio-group formControlName="heq_using_various">
                    <mat-radio-button #yesUsingVariousRadioOption value="yes">{{'health.Q_3_YES' | translate}}</mat-radio-button>
                    <mat-radio-button #noUsingVariousRadioOption value="no" (click)="confirmAndResetUsing($event)">{{'health.Q_3_NO' | translate}}</mat-radio-button>
                </mat-radio-group>

            <div class="p-10" *ngIf="healthquizForm.value.heq_using_various == 'yes'">

                <span #heq_using_various_el_1></span>
                <h4>{{'health.Q_3_TITLE' | translate}}</h4>

                <mat-checkbox class="checkbox" (change)="usingCheckboxOnChange($event, 'heq_using_chronic_medication_arr')"
                    formControlName="heq_using_chronic_medication">{{'health.Q_3_A' | translate}}</mat-checkbox>
                <ng-container
                    *ngIf="healthquizForm.value.heq_using_chronic_medication == true && healthquizForm.value.heq_using_chronic_medication_arr.length > 0"
                    [ngTemplateOutlet]="usingTemplate" [ngTemplateOutletContext]="{
                        context: formContext,
                        checkboxFieldName: 'heq_using_chronic_medication',
                        arrFieldName: 'heq_using_chronic_medication_arr',
                        descriptionFieldLabel: 'health.Q_3_A_1' | translate,
                        commentTitle: 'health.Q_3_A_2' | translate,
                        addButtonTitle: 'health.Q_3_A_3' | translate,
                        removeButtonTitle: 'health.Q_3_A_4' | translate,
                        addOnClick: addUsingItem,
                        removeOnClick: removeUsingItem
                    }">

                </ng-container>


                <ng-template #usingTemplate let-context="context" let-checkboxFieldName="checkboxFieldName"
                    let-arrFieldName="arrFieldName" let-descriptionFieldLabel="descriptionFieldLabel"
                    let-commentTitle="commentTitle" let-addButtonTitle="addButtonTitle"
                    let-removeButtonTitle="removeButtonTitle" let-addOnClick="addOnClick" let-removeOnClick="removeOnClick">

                    <mat-card class="mat-elevation-z-custom">

                        <div class="repeat-container" formArrayName="{{arrFieldName}}"
                            *ngFor="let item of context.healthquizForm.get(arrFieldName).controls; let i = index;">
                            <div [formGroupName]="i">

                                <span #heq_using_description_el id={{arrFieldName}}></span>
                                <div>
                                    <mat-form-field class="w45">
                                        <input formControlName="description" matInput
                                            placeholder="{{descriptionFieldLabel}}">
                                    </mat-form-field>
                                </div>

                                <span #heq_using_recommended_by_el id={{arrFieldName}}></span>
                                <div>
                                    <mat-form-field class="w45">
                                        <mat-select formControlName="recommended_by" placeholder="{{ 'health.Q3_1' | translate }}">
                                            <mat-option value="רופא">{{ 'health.Q3_2' | translate }}</mat-option>
                                            <mat-option value="תזונאי">{{ 'health.Q3_3' | translate }}</mat-option>
                                            <mat-option value="החלטה עצמית">{{ 'health.Q3_4' | translate }}</mat-option>
                                            <mat-option value="אחר">{{ 'health.Q3_5' | translate }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <span #heq_recommended_by_text_el id={{arrFieldName}}></span>
                                <div>
                                    <mat-form-field *ngIf="item.value.recommended_by == 'אחר'" class="w45">
                                        <input formControlName="recommended_by_text" matInput placeholder="{{'health.Q_3_A_EXPLAIN' | translate}}">
                                    </mat-form-field>
                                </div>

                                <span #heq_duration_taken_el id={{arrFieldName}}></span>
                                <div>
                                    <mat-form-field class="w45">
                                        <mat-select formControlName="duration_taken" placeholder="{{ 'health.Q3_6' | translate }}">
                                            <mat-option value="פחות מחודש">{{ 'health.Q3_7' | translate }}</mat-option>
                                            <mat-option value="1-3 חודשים">{{ 'health.Q3_8' | translate }}</mat-option>
                                            <mat-option value="3-12 חודשים">{{ 'health.Q3_9' | translate }}</mat-option>
                                            <mat-option value="מעל שנה">{{ 'health.Q3_10' | translate }}</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>

                                <span #heq_assets_arr_el id={{arrFieldName}}></span>
                                <div>{{ 'health.Q3_11' | translate }}:</div>
                                <div>
                                    <vzn-media-files
                                        [uploadFolder]="context.base_image_upload_folder_path"
                                        [maxImageSize]="1500"
                                        [width]="'auto'"
                                        [height]="'110px'"
                                        [assetMargin]="'5px'"
                                        [uploadUrlService]="uploadUrlService"
                                        [formArrayName]="'assets_arr'"
                                        [acceptedFiles]="'image/jpeg, image/x-png, image/png, image/webp'"
                                        [multiple]="true"
                                        [disabled]="isLocked || isBusy"
                                        (onAssetClick)="assetClick($event)"
                                        (onUploadStart)="context.isBusy=true"
                                        (onUploadEnd)="context.isBusy=false"
                                    >
                                    </vzn-media-files>                           

                                </div>

                                <div class="mb50">{{commentTitle}} ({{ 'health.Q3_12' | translate }})</div>

                                <div>
                                    <mat-form-field class="w45">
                                        <input formControlName="comment_text" matInput placeholder="{{ 'general.description' | translate }}">
                                    </mat-form-field>
                                </div>

                                <div class="buttons-row">
                                    <button (click)="removeOnClick(context, checkboxFieldName, arrFieldName, i)"
                                        type="button" mat-raised-button color="warn" mat-button [disabled]="isLocked || isBusy">{{removeButtonTitle}}
                                        <mat-icon>remove_circle_outline</mat-icon></button>
                                </div>



                            </div>
                        </div>

                        <div class="add-button-container">
                            <button (click)="addOnClick(context, arrFieldName)" type="button" mat-raised-button
                                color="primary" mat-button [disabled]="isLocked">{{addButtonTitle}}<mat-icon>add_circle_outline</mat-icon>
                                </button>
                        </div>
                    </mat-card>


                </ng-template>

                <mat-checkbox class="checkbox"
                    (change)="usingCheckboxOnChange($event, 'heq_using_supplements_vitamins_arr')"
                    formControlName="heq_using_supplements_vitamins">{{'health.Q_3_B' | translate}}</mat-checkbox>
                <ng-container
                    *ngIf="healthquizForm.value.heq_using_supplements_vitamins == true && healthquizForm.value.heq_using_supplements_vitamins_arr.length > 0"
                    [ngTemplateOutlet]="usingTemplate" [ngTemplateOutletContext]="{
                        context: formContext,
                        checkboxFieldName: 'heq_using_supplements_vitamins',
                        arrFieldName: 'heq_using_supplements_vitamins_arr',
                        descriptionFieldLabel: 'health.Q_3_B_1' | translate,
                        commentTitle: 'health.Q_3_B_2' | translate,
                        addButtonTitle: 'health.Q_3_B_3' | translate,
                        removeButtonTitle: 'health.Q_3_B_4' | translate,
                        addOnClick: addUsingItem,
                        removeOnClick: removeUsingItem
                    }">

                </ng-container>
                <mat-checkbox class="checkbox" (change)="usingCheckboxOnChange($event, 'heq_using_protein_powder_arr')"
                    formControlName="heq_using_protein_powder">{{'health.Q_3_C' | translate}}</mat-checkbox>
                <ng-container
                    *ngIf="healthquizForm.value.heq_using_protein_powder == true && healthquizForm.value.heq_using_protein_powder_arr.length > 0"
                    [ngTemplateOutlet]="usingTemplate" [ngTemplateOutletContext]="{
                        context: formContext,
                        checkboxFieldName: 'heq_using_protein_powder',
                        arrFieldName: 'heq_using_protein_powder_arr',
                        descriptionFieldLabel: 'health.Q_3_C_1' | translate,
                        commentTitle: 'health.Q_3_C_2' | translate,
                        addButtonTitle: 'health.Q_3_C_3' | translate,
                        removeButtonTitle: 'health.Q_3_C_4' | translate,
                        addOnClick: addUsingItem,
                        removeOnClick: removeUsingItem
                    }">

                </ng-container>

            </div>
            </mat-card>

            <br>
            <mat-card *ngIf="formOwnerUsr && formOwnerUsr.usr_gender === 'נקבה'">
                <span #heq_period_el></span>
                <div>{{ 'health.Q_PERIOD_1' | translate }}</div>
                <div class="mb50">
                    <mat-radio-group formControlName="heq_period">
                        <mat-radio-button #yesGetPeriod value="yes">{{ 'general.yes' | translate }}</mat-radio-button>
                        <mat-radio-button #noGetPeriod value="no">{{ 'general.no' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div *ngIf="healthquizForm.value.heq_period == 'yes'">
                    <span #heq_period_start_date_el></span>
                    <mat-form-field class="w65">
                        <input #formDate matInput [matDatepicker]="dpStarted" placeholder="{{ 'health.Q_PERIOD_2' | translate }}"
                            formControlName="heq_period_start_date">
                        <mat-datepicker-toggle matSuffix [for]="dpStarted"></mat-datepicker-toggle>
                        <mat-datepicker #dpStarted></mat-datepicker>
                    </mat-form-field>
                </div>
                <ng-container *ngIf="healthquizForm.value.heq_period == 'yes'">
                    <span #heq_irregular_period_el></span>
                    <div>{{ 'health.Q_PERIOD_3' | translate }}</div>
                    <div class="mb50">
                        <mat-radio-group formControlName="heq_irregular_period">
                            <mat-radio-button #yesGetPeriodRegular value="yes">{{ 'general.yes' | translate }}</mat-radio-button>
                            <mat-radio-button #noGetPeriodRegular value="no">{{ 'general.no' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                    <span #heq_irregular_period_status_el></span>
                    <div *ngIf="healthquizForm.value.heq_irregular_period == 'no'">
                        <mat-form-field class="w65">
                            <mat-select formControlName="heq_irregular_period_status" placeholder="{{ 'health.Q_PERIOD_4' | translate }}">
                                <mat-option value="מקבלת מחזור כל חודש בתזוזה של מספר ימים">{{ 'health.Q_PERIOD_5' | translate }}</mat-option>
                                <mat-option value="יש חודשים ללא מחזור">{{ 'health.Q_PERIOD_6' | translate }}</mat-option>
                                <mat-option value="מקבלת מחזור אחת למספר חודשים">{{ 'health.Q_PERIOD_7' | translate }}</mat-option>
                                <mat-option value="אחר">{{ 'health.Q_PERIOD_8' | translate }}</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <span #heq_irregular_period_status_other_text_el></span>
                        <mat-form-field *ngIf="healthquizForm.value.heq_irregular_period_status == 'אחר'" class="w45">
                            <input formControlName="heq_irregular_period_status_other_text" matInput
                                placeholder="{{ 'health.Q_PERIOD_9' | translate }}">
                        </mat-form-field>

                    </div>

                    <span #heq_taking_pills_el></span>
                    <div>{{ 'health.Q_PERIOD_10' | translate }}</div>
                    <div class="mb50">
                        <mat-radio-group formControlName="heq_taking_pills">
                            <mat-radio-button #yesTakingPills value="yes">{{ 'general.yes' | translate }}</mat-radio-button>
                            <mat-radio-button #noTakingPills value="no">{{ 'general.no' | translate }}</mat-radio-button>
                        </mat-radio-group>
                    </div>
                </ng-container>

                <span #heq_period_comments_el></span>
                <div>{{ 'health.Q_PERIOD_11' | translate }}</div>
                <div class="mb50">
                    <mat-radio-group formControlName="heq_period_comments">
                        <mat-radio-button #yesPeriodMoreComments value="yes">{{ 'general.yes' | translate }}</mat-radio-button>
                        <mat-radio-button #noPeriodMoreComments value="no">{{ 'general.no' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <mat-form-field *ngIf="healthquizForm.value.heq_period_comments == 'yes'" class="w65">
                    <input formControlName="heq_period_comments_text" matInput placeholder="{{ 'general.extraNotes' | translate }}">
                </mat-form-field>

            </mat-card>

            <br>
            <span #heq_more_comments_el></span>
            <mat-card>

                <h3>{{'health.Q_4' | translate}}</h3>
                <div class="mb50">
                    <mat-radio-group formControlName="heq_more_comments">
                        <mat-radio-button #yesMoreComments value="yes">{{ 'general.yes' | translate }}</mat-radio-button>
                        <mat-radio-button #noMoreComments value="no">{{ 'general.no' | translate }}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <span #heq_more_comments_text_el></span>
                <mat-form-field *ngIf="healthquizForm.value.heq_more_comments == 'yes'" class="w45">
                    <input formControlName="heq_more_comments_text" matInput placeholder="{{'general.extraNotes' | translate}}">
                </mat-form-field>

            </mat-card>
            <br>

            <button mat-raised-button color="primary" class="full-width" [disabled]="isLocked || isBusy">{{'general.save' | translate}}</button>
            <div *ngIf="isLocked" class="font-12 text-center text-danger">
                {{'general.savedQuiz' | translate}}
            </div>
            <!-- <div *ngIf="isOtherUser" class="mt125 row">
                <div>
                </div>
                <button type="button" class="plus-button" mat-raised-button [routerLink]="['/Nutritionistform/', healthquizForm.value.heq_usr_id]">
                    חזור לטופס תזונאי
                    <mat-icon>undo</mat-icon>
                </button>
            </div> -->
        </div>
    </form>

</div>
