// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  storage: 'https://all-around-player.storage.googleapis.com',
  apiUrl: 'https://aaplayer.herokuapp.com',
  vznFeathers: {
    apiUrl: 'https://aaplayer.herokuapp.com',
    loggedOutRoute: 'login',
    authTypes: {
      email: {
        strategy: 'local',
        usernameField: 'usr_email',
        passwordField: 'usr_password',
      },
      phone: {
        strategy: 'mobile',
        usernameField: 'usr_phone',
        passwordField: 'usr_mobile_code'
      }
    },
    mobileCodeService: 'send-mobile-code'
  },
  hmr: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
