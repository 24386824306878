import { Component, Inject, Input, Output, EventEmitter, ViewChild, ElementRef, TemplateRef, ViewChildren, QueryList } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, Validators, ValidatorFn } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

import { environment } from '@environments/environment';

import { appState } from '@services/app-state';
import { Apputils } from '@services/app-utils';

import { debounceTime, concatMap, switchMap } from 'rxjs/operators';
import { defer } from 'rxjs';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { UserpracticeComponent } from '../userpractice/userpractice.component';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';

import * as moment from 'moment-timezone';

import { allCategoriesGroups, breakfastCategories, elevensesCategories, lunchCategories, teaCategories, dinnerCategories, nightCategories } from '../nutquiz/categoriesData';

@Component({
  selector: "fooddiaryquiz-day",
  templateUrl: "./fooddiaryquiz-day.component.html"
})
export class FooddiaryquizDayComponent {

  allCategoriesGroups = allCategoriesGroups;

  @Input() isNuqIngredients?: boolean;
  @Input() nuqIngredientsData?: any;
  // @Input() nuqDetailsData?: any;
  @Input() isNuqDetails?: boolean;
  @Input() nuqData?: any;
  @Input() nutcategories?: any[];


  @ViewChild("fluidsUnderTemplate") fluidsUnderTemplate: TemplateRef<any>;

  @ViewChild(UserpracticeComponent) private userpractice: UserpracticeComponent;
  
  @Output() addNoteEvent: EventEmitter<any> = new EventEmitter();
  @Output() editNoteEvent: EventEmitter<any> = new EventEmitter();
  @Output() removeNoteEvent: EventEmitter<any> = new EventEmitter();

  @Output() cloneNotesEvent: EventEmitter<any> = new EventEmitter();

  @Output() linkedNotesEvent: EventEmitter<any> = new EventEmitter();
  
  @Input() nutritionistFormMode: boolean;
  // @Input() foodQuizDiaryId: string;
  // @Input() quizDayId: string;
  @Input() dayData: any;
  // @Input() dateTaken: any;
  // @Input()
  // set dateTaken(date) {
  //   if (!this.init || !date || this.nutritionistFormMode) {
  //     return;
  //   }
  //   this._dataTaken = date;
  //   this.refreshDay(this.quizDayId);
  // }

  // _dataTaken: any;

  // get dateTaken() {
  //   return this._dataTaken;
  // }

  // @Input()
  // set dateTaken(date) {
  //   if (this.nutritionistFormMode) { return;}
    
  //   // this.chosenDate = '';
  //   if (!(date && date.isValid)) {
  //     console.error('no valid date');
  //     this.chosenDate = '';
  //     return;
  //   }

  //   if (this.quizDayId && this.chosenDate !== date.toISOString()) {
  //     if (this.formId === this.quizDayId) {
  //       if (this.fooddiaryquizForm) {
  //         this.fooddiaryquizForm.get('fdq_day_date_taken').setValue(date.toISOString(), { emitEvent: false });
  //         // this.switchDate = true;
  //         this.chosenDate = date.toISOString();
  //       }
  //       return;
  //     }

  //     delete this.formId;
  //     this.timeline = [];
  //     this.allPractices = [];
  //     // this.allMeals = [];
  //     delete this.allMeals;
  
  //     this.refreshDay(this.quizDayId, date);
  //     // this.chosenDate = date.toISOString();
  //   }
    
  //   // const id = this.quizDayId;

  //   // delete this.formId;
  //   // this.timeline = [];
  //   // this.allPractices = [];
  //   // this.allMeals = [];

  //   // if (id == null || id == undefined || String(id).trim().length == 0) {

  //   //   delete this.allCalculatedNut;
  //   //   this.fluidsDrinksNoWater = null;
  //   //   this.fluidsWater = null;
  //   //   this.userWeightKilogram = null;
  //   //   this.userOptimalFluidsLiterNoPractice = null;
  //   //   this.fooddiaryquizForm = this.createDefaultForm(this, date.toISOString());
  //   //   this.timeline = this.createDefaultTimeline();
  //   //   this.timelineChange.emit();
  //   //   this.formId = '';
  //   //   // this.enableAutosave();

  //   // } else {
  //   //   this.refreshDay(id);
  //   // }
  //   // this.chosenDate = date.toISOString();

  // }
  @Input() userId: string;
  @Input() userGender?: string;
  @Input() userWeight?: number;
  @Input() userAge?: number;

  @Input() notesForm?: any;
  @Input() notesFormB?: any;
  @Input() allNotes?: any;
  @Input() notesList?: any;
  @Input() isLocked?: boolean;

  @Input() isBusy?: boolean;

  @Input() oldNotesFlag?: boolean;
  
  @Output('busyState') busyState: EventEmitter<boolean> = new EventEmitter();
  @Output('timelineChange') timelineChange: EventEmitter<void> = new EventEmitter();
  @Output('dayCreated') dayCreated: EventEmitter<any> = new EventEmitter();

  @ViewChild("practiceDialogTemplate") practiceDialogTemplate: TemplateRef<any>;

  @ViewChild("fdq_day_school_today_el") fdq_day_school_today_el: ElementRef; 
  @ViewChild("fdq_day_practice_today_el") fdq_day_practice_today_el: ElementRef; 
  @ViewChild("fdq_day_urine_color_el") fdq_day_urine_color_el: ElementRef; 
  @ViewChild("fdq_day_drink_as_stated_el") fdq_day_drink_as_stated_el: ElementRef; 
  @ViewChild("fdq_day_take_supplements_type_el") fdq_day_take_supplements_type_el: ElementRef; 
  @ViewChild("fdq_day_take_supplements_time_el") fdq_day_take_supplements_time_el: ElementRef; 
  @ViewChild("fdq_day_has_notes_el") fdq_day_has_notes_el: ElementRef;  

  @Output('mealChange') mealChange: EventEmitter<string> = new EventEmitter();
  @ViewChildren("mealTitle") mealTitle: QueryList<any>
  // @Input() currentMeal?: string;
  // currentMeal = '';
  @Input()
  set currentMeal(meal: string) {
    if (meal) {
      this.scrollToMeal(meal);
      // setTimeout(()=>{
      //   this.scrollToMeal(meal);
      // }, 100);
    }
  }
  mealsObserver: IntersectionObserver | null;

  uploadFolderBase: string;
  uploadUrlService: any;

  fooddiaryquizForm: FormGroup;

  // init = false;

  // validationMessages = {
  //   required: 'שדה זה הוא שדה חובה',
  //   email: 'בבקשה הזן כתובת מייל תקנית',
  // }

  formContext: any;
  allHours = Apputils.allHours;
  sleepingHours = Apputils.sleepingHours;
  wakingHours = Apputils.wakingHours;
  schoolStartHours = Apputils.schoolStartHours;
  schoolEndHours = Apputils.schoolEndHours;
  // formId: string = '';
  formId: string;
  appState = appState;
  showErrors = false;

  foodDiaryQuizDayRestService: any;
  foodDiaryQuizDayInfoRestService: any;

  nutArray = ['food_energy', 'protein', 'carbohydrates', 'total_fat'];
  nutNames = {
    'food_energy': 'קלוריות',
    'protein': 'חלבונים',
    'carbohydrates': 'פחמימות',
    'total_fat': 'שומנים',
  };
  allCalculatedNut: any;
  fluidsDrinksNoWater: number;
  fluidsWater: number;
  userWeightKilogram: number;
  userOptimalFluidsLiterNoPractice: number;

  durations: Array<string> = Apputils.getPracticeDurations();
  
  practiceTypes = Apputils.practiceTypes;
  
  urineHexColorPlate = Apputils.urineHexColorPlate;

  defaultTimes = {
      'BREAKFAST': '07:00',
      'ELEVENSES': '10:00',
      'LUNCH': '13:00',
      'TEA': '16:00',
      'DINNER': '19:00',
      'NIGHT_SNACK': '22:00',
  }
  defaultNotes = {
      'BREAKFAST': 'A',
      'ELEVENSES': 'B',
      'LUNCH': 'C',
      'TEA': 'D',
      'DINNER': 'E',
      'NIGHT_SNACK': 'F',
  }
  defaultNotesShared = {
      'BREAKFAST': 's4',
      'ELEVENSES': 's5',
      'LUNCH': 's6',
      'TEA': 's7',
      'DINNER': 's8',
      'NIGHT_SNACK': 's9',
  }
  defaultNotesOld = {
      'BREAKFAST': 'a',
      'ELEVENSES': 'b',
      'LUNCH': 'c',
      'TEA': 'd',
      'DINNER': 'e',
      'NIGHT_SNACK': 'f',
  }
  // defaultNotes = {
  //     'BREAKFAST': 'notes_fdq_a',
  //     'ELEVENSES': 'notes_fdq_b',
  //     'LUNCH': 'notes_fdq_c',
  //     'TEA': 'notes_fdq_d',
  //     'DINNER': 'notes_fdq_e',
  //     'NIGHT_SNACK': 'notes_fdq_f',
  // }

  defaultTimeline = [];

  // defaultTimelineIngredients = [];
  // defaultTimelineDetails = [];

  timeline = [];
  allPractices = [];
  // allMeals = [];
  allMeals: any;
  allLastItems: any;

  // chosenDate = '';

  // switchDate = false;

  // get isMinor() {
  //   return true; // temp
  //   const age = +((this.appState && this.appState.user && this.appState.user['usr_age_formatted']) || 0);
  //   return age < 14;
  // }
  // get isUnderage() {
  //   const age = +((this.appState && this.appState.user && this.appState.user['usr_age_formatted']) || 0);
  //   return age < 18;
  // }
  
  initStatus: any;
  initClear = false;

  isMinor = false;
  isUnderage = false;
  isUnderNineteen = false;

  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public restApiService: RestApiService<any>
  ) {

    this.defaultTimeline = this.createDefaultTimeline();

    // this.defaultTimelineIngredients = this.createDefaultTimeline();
    // this.defaultTimelineDetails = this.createDefaultTimeline();

    // delete this.allCalculatedNut;
    this.fluidsDrinksNoWater = null;
    this.fluidsWater = null;
    this.userWeightKilogram = null;
    this.userOptimalFluidsLiterNoPractice = null;

    this.uploadUrlService = this.restApiService.service('upload-url');
    this.foodDiaryQuizDayRestService = this.restApiService.service('fooddiaryquiz-day');
    this.foodDiaryQuizDayInfoRestService = this.restApiService.service('fooddiaryquiz-day-info');

    this.formContext = this;

  }

  createDefaultForm() {
    const newForm: FormGroup = this.formBuilder.group({
      _id: [''],

      fdq_day_form_is_real: [false],
      fdq_day_usr_id: [this.userId],
      // fdq_day_fdq_id: [context.foodQuizDiaryId],
      // fdq_day_fdq_id: [''],
      fdq_day_date_taken: [''],
      fdq_day_yesterday_sleep_time: ['20:00'],
      fdq_day_today_wakeup_time: ['05:00'],
      fdq_day_school_today: [''],
      fdq_day_school_start_time: ['07:00', [this.requiredIfValidator(() => this.fooddiaryquizForm.value.fdq_day_school_today == 'כן')]],
      fdq_day_school_end_time: ['11:00', [this.requiredIfValidator(() => this.fooddiaryquizForm.value.fdq_day_school_today == 'כן')]],

      fdq_day_practice_today: [''],

      fdq_day_urine_color: [''],
      fdq_day_drink_as_stated: [false],
      fdq_day_meals_as_stated: [false],
      fdq_day_take_supplements: [false],
      fdq_day_different: [null],
      fdq_day_has_notes: [false],
      fdq_day_notes: ['', {updateOn: 'blur'}],
      fdq_day_take_supplements_type: ['', {validators: [this.requiredIfValidator(() => this.fooddiaryquizForm.value.fdq_day_take_supplements)], updateOn: 'blur'}],
      fdq_day_take_supplements_time: ['', [this.requiredIfValidator(() => this.fooddiaryquizForm.value.fdq_day_take_supplements)]],

    },       {
      validators: [this.formCustomValidator.bind(this)]
    });

    newForm.get('fdq_day_drink_as_stated').valueChanges.subscribe(val => {
      if (val) {
        const fluids = (this.fluidsWater || 0) + (this.fluidsDrinksNoWater || 0);
        if (fluids < 1000) {
          this.fluidsUnderPopup(fluids);
        }
      }
    });

    if (!this.isLocked && !this.nutritionistFormMode) {
      this.enableAutosave(newForm);
    }
    return newForm;
  }

  fluidsUnderPopup(currentFluids: number) {
    let form = this.formBuilder.group({
      current: currentFluids,
      chosen: ['', Validators.required],
    });
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      disableClose: true,
      maxWidth: '100%',
      // maxWidth: "90vw",
      // minWidth: '35%',
      // minWidth: '300px',
      // panelClass: "meal-dialog",
      data: { 
        title: 'כמות נוזלים יומית', 
        ok: this.translate.instant('general.ok'),
        cancel: this.translate.instant('general.cancel'),
        form, 
        template: this.fluidsUnderTemplate, 
        context: this.formContext 
      }
    });

    this.router.navigate([], { fragment: 'fluids' });

    dialogRef.afterClosed().subscribe(async result => {
      console.log('The dialog was closed');
      
      this.router.navigate([], { fragment: null, replaceUrl: true });

      if (!result) {
        console.log("user canceled");
        if (this.fooddiaryquizForm) {
          this.fooddiaryquizForm.get('fdq_day_drink_as_stated').setValue(false, { emitEvent: false });
        }
        return;
      }

      if (form.value.chosen) {
        const current = +(form.value.current);
        const chosen = +(form.value.chosen) * 1000;
        const calc = chosen - current;
        if (calc > 0) {
          await this.addNightWater(calc);
        }
      }
      
    });
  }

  async addNightWater(calcWater: number) {
    console.log('addNightWater', calcWater);
    let waterItem = this.formBuilder.group({

      fdq_day_info_usr_id: this.userId,
      fdq_day_info_fdq_day_id: this.formId,

      fdq_day_info_meal_name: 'NIGHT_SNACK',
      fdq_day_info_item_description: '',

      fdq_day_info_item_type: 'WATER',
      fdq_day_info_item_name: ['מים'],
      fdq_day_info_item_barcode: '',
      fdq_day_info_item_real_barcode: '',
      fdq_day_info_item_nut_data: '',
      fdq_day_info_time: '',
      fdq_day_info_quantity: 'כמות מדודה (מ"ל)',
      fdq_day_info_units: 'מ"ל',
      fdq_day_info_weight_or_volume: calcWater,
      fdq_day_info_calories: '',
      fdq_day_info_portion: ['1'],

      fdq_day_info_assets: this.formBuilder.array([]),
    });

    const res = await this.foodDiaryQuizDayInfoRestService.create(waterItem.value).catch((e: any) => { console.error(e); });
    if (res) {
      await this.mealUpdate(false, 'NIGHT_SNACK');
      console.log('addNightWater added', res.fdq_day_info_weight_or_volume);
    }
  }

  createDefaultTimeline() {
    return [
      {
        templateType: 'meal',
        time: '07:00',
        data: {
          name: 'BREAKFAST'
        },
        _id: 4
      },
      {
        templateType: 'meal',
        time: '10:00',
        data: {
          name: 'ELEVENSES'
        },
        _id: 5
      },
      {
        templateType: 'meal',
        time: '13:00',
        data: {
          name: 'LUNCH'
        },
        _id: 6
      },
      {
        templateType: 'meal',
        time: '16:00',
        data: {
          name: 'TEA'
        },
        _id: 7
      },
      {
        templateType: 'meal',
        time: '19:00',
        data: {
          name: 'DINNER'
        },
        _id: 8
      },
      {
        templateType: 'meal',
        time: '22:00',
        data: {
          name: 'NIGHT_SNACK'
        },
        _id: 9
      }
    ];
  }

  enableAutosave(form: FormGroup) {
    console.log('enableAutosave');

    form.valueChanges.pipe(
      debounceTime(400),
      switchMap(formValue => {
          return defer(async () => await this.onSubmit(true));
          // return this.onSubmit(true);
      }),
    ).subscribe((res) => console.log('Auto Saved ', res, this.formId));
  }

  async ngOnInit() {
    // const isProd = environment.production;
    // this.isMinor = isProd ? true : this.userAge < 14;
    // this.isMinor = true;
    this.isMinor = this.userAge < 14;
    this.isUnderage = this.userAge < 18;
    this.isUnderNineteen = this.userAge < 19;

    this.refreshMealsFromNuqData();
  }

  async ngAfterViewInit() {
    if (this.nutritionistFormMode && this.dayData?._id) {
      await this.refreshDay(this.dayData);
      // this.init = true;
    }
  }

  resetInitStatus() {
    delete this.initStatus;
    this.initStatus = {
      'BREAKFAST': false,
      'ELEVENSES': false,
      'LUNCH': false,
      'TEA': false,
      'DINNER': false,
      'NIGHT_SNACK': false,
    };
    this.initClear = false;
  }

  changeInit(status: boolean, meal: string) {
    if (this.initClear) {
      return;
    }
    // this.initStatus[meal] = status;
    // console.log(status, meal)
    if (status === false) {
      this.initStatus[meal] = true;

      this.initClear = this.initStatus['BREAKFAST'] && this.initStatus['ELEVENSES'] && this.initStatus['LUNCH'] && this.initStatus['TEA'] && this.initStatus['DINNER'] && this.initStatus['NIGHT_SNACK'];
      if (this.initClear) {
        this.busyState.emit(false);
      }
    }
  }

  async refresh() {
    delete this.formId;
    this.timeline = [];
    this.allPractices = [];
    // this.allMeals = [];
    delete this.allMeals;
    delete this.allLastItems;
    this.resetInitStatus();
    if (this.dayData?._id) {
      await this.refreshDay(this.dayData);
    }
  }

  async refreshDay(day: any) {
    this.busyState.emit(true);
    const { _id } = day;
    // this.resetInitStatus();
    try {
      let res = day; // || await this.foodDiaryQuizDayRestService.get(_id);
      this.uploadFolderBase = `fooddiaryquiz/fdq_day_assets_${_id}`;
      this.fooddiaryquizForm = this.createDefaultForm();
      // this.fooddiaryquizForm.reset();
      this.fooddiaryquizForm.patchValue(res, { emitEvent: false });

      if (this.isLocked && !this.nutritionistFormMode) {
          // this.fooddiaryquizForm.disable();
          setTimeout(() => this.fooddiaryquizForm.disable());
      }

      await this.refreshMeals(_id);
      if (res.fdq_day_date_taken) {
        await this.fetchAllPractices(res.fdq_day_date_taken, _id);
      }
      this.calcTimeline(true); // await this.calcTimeline();

      if (this.nutritionistFormMode) {
        // await this.refreshUserWeightAndOptimalFluids();
        if (this.userWeight) {
          this.userWeightKilogram = this.userWeight;
          this.userOptimalFluidsLiterNoPractice = Apputils.roundToQuarter((this.userWeightKilogram * 30) / 1000);
        } else {
          this.userWeightKilogram = 0;
          this.userOptimalFluidsLiterNoPractice = 0;
        }
        // this.initMeals();
        setTimeout(() => {
          this.initMeals();
        }, 750);
      }

      this.formId = _id;
      this.busyState.emit(false);

    } catch (e) {
      console.error(e);
      this.busyState.emit(false);
      delete this.formId;
    }
  }

  resetField(filedName, value) {
    this.fooddiaryquizForm.controls[filedName].reset(value, { emitEvent: false });
  }

  calcTimeline(skipReset?: boolean, onlyFormFields?: boolean) {
    // console.log('calcTimeline');
    
    let allDay = [];
    const formValue = this.fooddiaryquizForm.value;

    const schoolStart = formValue.fdq_day_school_start_time;
    const schoolEnd = formValue.fdq_day_school_end_time;
    if (formValue.fdq_day_school_today == 'כן' && schoolStart && schoolEnd){
      allDay.push({
          templateType: 'school',
          time: schoolStart,
          data: {
            name: 'start',
          },
          _id: 1
        },
        {
          templateType: 'school',
          time: schoolEnd,
          data: {
            name: 'end',
          },
          _id: 2
        }
      );
    }

    const supplements = formValue.fdq_day_take_supplements_time;
    if (formValue.fdq_day_take_supplements && supplements){
      allDay.push({
        templateType: 'supplements',
        time: supplements,
        data: {
          name: formValue.fdq_day_take_supplements_type,
        },
        _id: 3
      });
    }

    // if (onlyFormFields) {
    //   for (let i = 1; i <= 3; i++) { // ids 1 to 3 
    //     const allDayIndex = allDay.findIndex(e => e._id == i);
    //     const timelineIndex = this.timeline.findIndex(e => e._id == i);
        
    //     if (timelineIndex > -1 && allDayIndex > -1) {
    //       this.timeline[timelineIndex].time = allDay[allDayIndex].time;
    //       this.timeline[timelineIndex].data.name = allDay[allDayIndex].data.name;
    //     } else {
    //       if (allDayIndex > -1) {
    //         this.timeline.push(allDay[allDayIndex]);
    //       } else {
    //         this.timeline.splice(timelineIndex, 1)
    //       }
    //     }
    //   }
    //   this.sortTimeline(this.timeline);

    //   return;
    // }

    const allPractices = this.allPractices;

    for (let practice of allPractices){
      allDay.push({
        templateType: 'practice',
        time: practice.usr_practice_time,
        data: practice,
        _id: practice._id
      });
    }

    const allMeals = this.allMeals || [];
    const allHours = allMeals.filter((meal: any) => meal.fdq_day_info_item_type === 'HOUR');

    let breakfast = allHours.find(({ fdq_day_info_meal_name }) => fdq_day_info_meal_name === 'BREAKFAST') || {fdq_day_info_time: '07:00'};
    let elevenses = allHours.find(({ fdq_day_info_meal_name }) => fdq_day_info_meal_name === 'ELEVENSES') || {fdq_day_info_time: '10:00'};
    let lunch = allHours.find(({ fdq_day_info_meal_name }) => fdq_day_info_meal_name === 'LUNCH') || {fdq_day_info_time: '13:00'};
    let tea = allHours.find(({ fdq_day_info_meal_name }) => fdq_day_info_meal_name === 'TEA') || {fdq_day_info_time: '16:00'};
    let dinner = allHours.find(({ fdq_day_info_meal_name }) => fdq_day_info_meal_name === 'DINNER') || {fdq_day_info_time: '19:00'};
    let nightsnack = allHours.find(({ fdq_day_info_meal_name }) => fdq_day_info_meal_name === 'NIGHT_SNACK') || {fdq_day_info_time: '22:00'};

    allDay.push(
      {
        templateType: 'meal',
        time: breakfast.fdq_day_info_time,
        data: {
          name: 'BREAKFAST'
        },
        _id: 4
      },
      {
        templateType: 'meal',
        time: elevenses.fdq_day_info_time,
        data: {
          name: 'ELEVENSES'
        },
        _id: 5
      },
      {
        templateType: 'meal',
        time: lunch.fdq_day_info_time,
        data: {
          name: 'LUNCH'
        },
        _id: 6
      },
      {
        templateType: 'meal',
        time: tea.fdq_day_info_time,
        data: {
          name: 'TEA'
        },
        _id: 7
      },
      {
        templateType: 'meal',
        time: dinner.fdq_day_info_time,
        data: {
          name: 'DINNER'
        },
        _id: 8
      },
      {
        templateType: 'meal',
        time: nightsnack.fdq_day_info_time,
        data: {
          name: 'NIGHT_SNACK'
        },
        _id: 9
      },
    );
    if (skipReset) {
      for (let i = this.timeline.length - 1; i >= 0; i--) { // because we're mutating and removing so we're iretating backwards..
        let allDayIndex = allDay.findIndex(e => this.timeline[i] && this.timeline[i]._id == e._id);
        if (allDayIndex > -1) {
          this.timeline[i].time = allDay[allDayIndex].time;
          allDay.splice(allDayIndex, 1);
        } else {
          this.timeline.splice(i, 1)
        }
      }
      if (allDay.length > 0) {
        console.log('adding to timeline', allDay);
        this.timeline.push(...allDay);
      }
    } else {
      console.log('timeline reset', allDay);
      this.timeline = allDay;
      // this.timelineChange.emit();
    }

    this.sortTimeline(this.timeline);

  }

  sortTimeline(timeline) {
    // console.log(timeline.slice());
    timeline.sort((a, b) => {
      let timeA = a.time.split(":");
      let minA = +(timeA[0]) * 60 + +(timeA[1]);
      let timeB = b.time.split(":");
      let minB = +(timeB[0]) * 60 + +(timeB[1]);

      if (minA < minB) return -1;
      if (minB > minA) return 1;
      return 0;
    });
    // console.log(timeline);
    this.timelineChange.emit();
  }
  
  setUrineColor(urineColor) {
    if (this.isLocked) return;
    // if (this.nutritionistFormMode) {return;}

    this.fooddiaryquizForm.get('fdq_day_urine_color').setValue(urineColor)
  }

  takeSupplementsOnChange(event) {
    if (event.checked) {
      if (this.fooddiaryquizForm.value.fdq_day_take_supplements_time == "") {
        this.fooddiaryquizForm.get("fdq_day_take_supplements_time").setValue("12:00");
      }
    }
  }

  async onSubmit(overrideValidation?: boolean, showLoader?: boolean, showFeedback?: boolean) {
    if (this.nutritionistFormMode || this.isLocked) { return; }

    if (showLoader) {
      this.busyState.emit(true);
    }

    this.resetField("fdq_day_form_is_real", true);

    this.clearValuesFromUnusedFields();

    const validateRes = this.validateForm();
    if (!overrideValidation && !validateRes.valid) {
      if (validateRes.scrollIntoNativeElement) {
        validateRes.scrollIntoNativeElement.nativeElement.scrollIntoView();
      }
      this.showErrors = true;
      // this.showStatus(validateRes.msg, this.translate.instant('profile.CLOSE'));
      this._snackBar.open(validateRes.msg, 'X', {
        duration: 7000,
        panelClass: 'snackbar-red'
      });
      if (showLoader) {
        this.busyState.emit(false);
      }
      return;
    } else {
      // this.showErrors = false;
    }

    // let res: any;

    // if (!this.fooddiaryquizForm.value._id || this.fooddiaryquizForm.value._id === '' || this.fooddiaryquizForm.value._id === null) {
    //   delete this.fooddiaryquizForm.value._id;
    //   res = await this.foodDiaryQuizDayRestService.create(this.fooddiaryquizForm.value);
    // } else {
    //   res = await this.foodDiaryQuizDayRestService.patch(this.fooddiaryquizForm.value._id, this.fooddiaryquizForm.value);
    // }
    
    // const id = res && res._id;
    // if (!id) {
    //   if (showLoader) {
    //     this.busyState.emit(false);
    //   }
    //   return;
    // }

    // this.fooddiaryquizForm.patchValue(res, { emitEvent: false });
    // this.uploadFolderBase = `fooddiaryquiz/fdq_day_assets_${this.fooddiaryquizForm.value._id}`;
    // this.formId = this.fooddiaryquizForm.value._id;

    const res = await this.foodDiaryQuizDayRestService.patch(this.fooddiaryquizForm.value._id, this.fooddiaryquizForm.value).catch((e: any) => { console.error(e); });
    // this.fooddiaryquizForm.patchValue(res, { emitEvent: false });

    if (showLoader) {
      this.busyState.emit(false);
    }

    if (res) {
      if (showFeedback) {
        this.showStatus(this.translate.instant('general.savedSuccessfully'), null);
      }
      this.dayCreated.emit(res);

      this.calcTimeline(true); // await this.calcTimeline(true, true);
    }
    
    return res?._id;
  }

  requiredIfValidator(predicate: Function): ValidatorFn | null {
    return ((formControl) => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl); 
      }
      return null;
    })
  }

  formCustomValidator(formGroup: FormGroup) {
    // if (!this.formInitiated) {
    //   return null;
    // }

    // if (formGroup.value.fdq_day_date_taken == "") {
    //   return {
    //     msg: "דרוש לבחור תאריך",
    //     scrollIntoNativeElement: this.fdq_day_date_taken_el
    //   }
    // }

    if (this.isUnderNineteen && formGroup.value.fdq_day_school_today == "") {
      return {
        msg: "דרוש לסמן האם יש לימודים היום",
        scrollIntoNativeElement: this.fdq_day_school_today_el
      }
    }
    
    if (this.isUnderNineteen && formGroup.value.fdq_day_school_today == "כן" && formGroup.value.fdq_day_school_start_time == "") {
      return {
        msg: "דרוש לבחור מתי מתחילים הלימודים",
        scrollIntoNativeElement: this.fdq_day_school_today_el
      }
    }
    
    if (this.isUnderNineteen && formGroup.value.fdq_day_school_today == "כן" && formGroup.value.fdq_day_school_end_time == "") {
      return {
        msg: "דרוש לבחור מתי מסתיימים הלימודים",
        scrollIntoNativeElement: this.fdq_day_school_today_el
      }
    }

    if (formGroup.value.fdq_day_practice_today == "") {
      return {
        msg: "דרוש לסמן האם יש לך פעילות ספורטיבית מתוכננת להיום",
        scrollIntoNativeElement: this.fdq_day_practice_today_el
      }
    }

    if (formGroup.value.fdq_day_practice_today === 'כן' && this.allPractices.length === 0) {
      return {
        msg: this.translate.instant('food_diary.TODAY_PRACTICE_ERROR'),
        scrollIntoNativeElement: this.fdq_day_practice_today_el
      }
    }

    if (formGroup.value.fdq_day_urine_color == "") {
      return {
        msg: "דרוש לסמן את צבע השתן הכהה ביותר שהיה במהלך היום",
        scrollIntoNativeElement: this.fdq_day_urine_color_el
      }
    }

    if (formGroup.value.fdq_day_drink_as_stated == "") {
      return {
        msg: "דרוש לסמן האם זה כל מה ששתית",
        scrollIntoNativeElement: this.fdq_day_drink_as_stated_el
      }
    }

    if (formGroup.value.fdq_day_meals_as_stated == "") {
      return {
        // msg: "דרוש לסמן האם זה כל מה שאכלת",
        msg: "דרוש לוודא ולסמן שלא נישנשת שום דבר נוסף",        
        scrollIntoNativeElement: this.fdq_day_drink_as_stated_el
      }
    }

    if (formGroup.value.fdq_day_take_supplements && formGroup.value.fdq_day_take_supplements_type == "") {
      return {
        msg: "דרוש לציין איזה סוג תוסף לקחת",
        scrollIntoNativeElement: this.fdq_day_take_supplements_type_el
      };
    }

    if (formGroup.value.fdq_day_take_supplements && formGroup.value.fdq_day_take_supplements_time == "") {
      return {
        msg: "דרוש לציין מתי לקחת את התוסף",
        scrollIntoNativeElement: this.fdq_day_take_supplements_time_el
      };
    }

    if (formGroup.value.fdq_day_has_notes && formGroup.value.fdq_day_notes == "") {
      return {
        msg: "דרוש לפרט מה חשוב שנדע",        
        scrollIntoNativeElement: this.fdq_day_has_notes_el
      }
    }

    return null;
  }

  validateForm() {

    if (!this.fooddiaryquizForm.valid)
      return {
        valid: false,
        msg: this.fooddiaryquizForm.errors ? this.fooddiaryquizForm.errors.msg : "נא לתקן את השדות האדומים",
        scrollIntoNativeElement: this.fooddiaryquizForm.errors ? this.fooddiaryquizForm.errors.scrollIntoNativeElement : null
      }

    return {
      valid: true,
      msg: "ולידציה עברה בהצלחה"
    }

  }

  clearValuesFromUnusedFields() {

    if (!this.fooddiaryquizForm.value.fdq_day_school_today || this.fooddiaryquizForm.value.fdq_day_school_today === "לא") {
      this.resetField("fdq_day_school_start_time", '');
      this.resetField("fdq_day_school_end_time", '');      
    }

    if (!this.fooddiaryquizForm.value.fdq_day_take_supplements) {
      this.resetField("fdq_day_take_supplements_type", '');
      this.resetField("fdq_day_take_supplements_time", '');
    }
    
    if (!this.fooddiaryquizForm.value.fdq_day_has_notes) {
      this.resetField("fdq_day_notes", '');
    }
  }

  // getFieldError(fieldName, form?) {
  //   form = !form ? this.fooddiaryquizForm : form;

  //   let formControl = form.get(fieldName);
  //   let errorMessage = '';

  //   if ((formControl.touched || formControl.dirty) && !formControl.valid && formControl.errors) {
  //     errorMessage = Object.keys(formControl.errors).map(
  //       key => errorMessage += this.validationMessages[key]).join('');
  //   }
  //   // console.log(errorMessage)
  //   return errorMessage;
  // }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 4000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];

  }

  async fetchAllPractices(date: any, fdq_day_id: string) {
    // const startDate = moment(date).utcOffset(3).milliseconds(0).second(0).minute(0).hour(0);
    const startDate = moment(date).milliseconds(0).second(0).minute(0).hour(0);
    const endDate = moment(startDate).add(1, 'd');

    const res = await this.restApiService.service('user-practice').find({ 
      query: {
        $paginate: false,
        usr_practice_usr_id: this.userId,
        // usr_practice_fdq_day_id: fdq_day_id,
        usr_practice_date_taken: {
          $gte: startDate.toISOString(),
          $lt: endDate.toISOString(),
        },
        $sort: {
          usr_practice_time: 1
        }
      }
    });
    // console.log('allPractices', res);
    this.allPractices = res;
  }
  
  async fetchAllMeals(fdq_day_id: string) {
    return await this.foodDiaryQuizDayInfoRestService.find({
      query: { 
        $paginate: false, 
        fdq_day_info_fdq_day_id: fdq_day_id,
        $sort: {
          createdAt: -1
        }
      }
    });
    // console.log('allMeals', res);
    // this.allMeals = res;
  }
  
  async fetchAllLastItems(fdq_day_id?: string) {
    let userLast = [];
    let defaultLast = [];

    try {
      userLast = await this.foodDiaryQuizDayInfoRestService.find({
        query: { 
          $paginate: false, 
          // $limit: 3,
          $select: ['fdq_day_info_meal_name', 'fdq_day_info_item_type', 'fdq_day_info_item_name', 'fdq_day_info_item_description', 'fdq_day_info_item_id', 'fdq_day_info_item_barcode', 'fdq_day_info_item_real_barcode', 'fdq_day_info_item_nut_data', 'fdq_day_info_item_nut_midas', 'fdq_day_info_quantity', 'fdq_day_info_units', 'fdq_day_info_weight_or_volume', 'fdq_day_info_calories', 'fdq_day_info_portion', 'fdq_day_info_assets'],
          // fdq_day_info_fdq_day_id: fdq_day_id, 
          fdq_day_info_usr_id: this.userId,
          fdq_day_info_item_type: ['MEAL', 'FOOD', 'DRINK'],
          $sort: {
            createdAt: -1
          } 
        }
      });
      defaultLast = await this.restApiService.service('default-item').find({
        query: {
          $limit: -1
        }
      });      
    } catch (err) {
      console.error(err);
    }

    return [ ...userLast, ...defaultLast ];
  }

  async createPracticeDialogFunc() {
    const chosenDate = this.fooddiaryquizForm.value.fdq_day_date_taken;
    if (!chosenDate) {
      this.showStatus('לא נבחר תאריך', 'X');
      return;
    }
    this.busyState.emit(true);
    await this.userpractice.createPracticeDialog(chosenDate, this.formId);
  }

  async removePracticeDialogFunc(practice: any) {
    this.busyState.emit(true);
    await this.userpractice.removePracticeDialog(practice);
  }

  async practiceCreated(res?: any) {
    if (res?.usr_practice_date_taken) {
      await this.fetchAllPractices(res.usr_practice_date_taken, this.formId);
      this.calcTimeline(true);
      await this.onSubmit(true);
    }
    this.busyState.emit(false);
  }

  async practiceRemoved(res?: any) {
    if (res?.usr_practice_date_taken) {
      await this.fetchAllPractices(res.usr_practice_date_taken, this.formId);
      this.calcTimeline(true);
    }
    this.busyState.emit(false);
  }

  async mealUpdate(skip, mealName) {
    console.log('mealUpdate ', mealName);
    if (this.formId) {
      if (!skip) {
        await this.refreshMeals(this.formId);
        this.calcTimeline(true);
      }
      // if (this.switchDate) {
        // this.switchDate = true;
        await this.onSubmit(true);
      // }
    }
  }

  async refreshMeals(formId: string) {
    // console.log("refreshMeals");
    try {
      if (!this.isLocked && !this.nutritionistFormMode) {
        this.allLastItems = await this.fetchAllLastItems();
      }
      this.allMeals = await this.fetchAllMeals(formId);
      this.fluidsDrinksNoWater = this.calculateAllByType('DRINK');
      this.fluidsWater = this.calculateAllByType('WATER');
      if (!this.isUnderage || this.nutritionistFormMode) {
        this.allCalculatedNut = this.calcNut(this.nutArray);
      }
    } catch (e) {
      console.error(e)
    }
  }

  refreshMealsFromNuqData() {
    if (!this.nuqData) {
      return;
    }
    const meals: any[] = [];

    for (const item of ['BREAKFAST', 'ELEVENSES', 'LUNCH', 'TEA', 'DINNER', 'NIGHT']) {
      const productsObj = this.nuqData?.nuq_chosen_products?.[item];
      if (productsObj) {
        meals.push(...Object.values(productsObj))
      }
    }

    this.allMeals = meals;
    this.fluidsDrinksNoWater = this.calculateAllByType('DRINK');
    this.fluidsWater = this.calculateAllByType('WATER');
    if (this.nutritionistFormMode) {
      this.allCalculatedNut = this.calcNut(this.nutArray);
    }
  }

  calcNut(keysArray) {
    const allMeals = this.allMeals || [];
    const meals = allMeals.filter((meal: any) => meal.fdq_day_info_item_type === 'DRINK' || meal.fdq_day_info_item_type === 'FOOD');
    if (meals.length === 0) {return;}
    
    let resultObj = {};
    for (const key of keysArray) {
      resultObj[key] = 0;
    }

    meals.forEach(meal => {
      // const mealType = meal.fdq_day_info_item_type;
      const nutData = meal.fdq_day_info_item_nut_data;
      // if (mealType === 'DRINK' || mealType === 'FOOD') {
        let amount = +(meal.fdq_day_info_weight_or_volume);
        amount = isNaN(amount) ? 0 : amount;
        let portions: number = this.parsePortion(meal.fdq_day_info_portion);
        let total = (amount * portions);
        
        for (const key of keysArray) {
          let nutVal = +(nutData[key]);
          nutVal = isNaN(nutVal) ? 0 : nutVal;
          resultObj[key] += total*nutVal/100; // nut value is per 100gr
        }
      // }
    });

    for (const key of keysArray) {
      if (key == 'food_energy') {
        resultObj[key] = Math.trunc(resultObj[key]);
      } else {
        resultObj[key] = Math.round(resultObj[key]*10)/10;
      }
    }

    return resultObj;
  }

  calculateAllByType(mealType: string) {
    const allMeals = this.allMeals || [];

    // const res = allMeals.filter((item: any) => item.fdq_day_info_item_type == mealType);
    let fluidsMilliliterSum: number = 0;
    allMeals.forEach(element => {
      if (element.fdq_day_info_item_type === mealType) {
        let milliliter = +(element.fdq_day_info_weight_or_volume);
        milliliter = isNaN(milliliter) ? 0 : milliliter;
        let portions: number = this.parsePortion(element.fdq_day_info_portion);
        fluidsMilliliterSum += (milliliter * portions);
      }
    });
    return fluidsMilliliterSum;
  }

  parsePortion(portions: string): number {
    let parsedPorsions: number = 0;
    switch (portions) {
      case "¼":
        parsedPorsions = 0.25;
        break;
      case "½":
        parsedPorsions = 0.5;
        break;
      case "1":
        parsedPorsions = 1;
        break;
      case "1½":
        parsedPorsions = 1.5;
        break;
      case "2":
        parsedPorsions = 2;
        break;
      case "2½":
        parsedPorsions = 2.5;
        break;
      case "3":
        parsedPorsions = 3;
        break;
      case "3½":
        parsedPorsions = 3.5;
        break;
      case "4":
        parsedPorsions = 4;
        break;
      case "4½":
        parsedPorsions = 4.5;
        break;
      case "5":
        parsedPorsions = 5;
        break;
      default:
        const toNum = +(portions);
        if (!isNaN(toNum)) {
          parsedPorsions = toNum;
        }
    }
    return parsedPorsions;
  }

  ngOnDestroy() {
    this.disconnectMeals();
  }

  onMealChange(mealId: string) {
    // this.currentMeal = mealId;
    this.mealChange.emit(mealId);
  }
  scrollToMeal(mealId: string) {
    // console.log('scrollToMeal', mealId);
    if (mealId){
      const mealEl = document.querySelector('#' + mealId);
      // console.log(mealEl);
      if (mealEl) {
        mealEl.scrollIntoView();
        // this.onMealChange(mealId);
      }
    }
  }
  initMeals() {
		if (!this.mealsObserver) {
      // let meals = document.querySelectorAll(".mealbox");
      let meals = this.mealTitle.toArray();
      // console.log(meals);
      // if (!meals) return;
      if (!(meals && meals.length)) return;
      this.mealsObserver = new IntersectionObserver( this.handleMeals , {rootMargin: "-164px 0px -40% 0px", threshold: [0.2, 0.4, 0.6, 0.8, 1.0]} );
      // meals.forEach(meal => this.mealsObserver.observe(meal));
      meals.forEach(meal => this.mealsObserver.observe(meal.nativeElement));
		}
  }
  handleMeals = ( entries: IntersectionObserverEntry[] ) : void => {
    // console.log(entries);
    for ( let entry of entries ) {
      if (entry && entry.isIntersecting) {
        this.onMealChange(entry.target.id);
      }
    }
  }
  disconnectMeals() {
		if (this.mealsObserver) {
			this.mealsObserver.disconnect();
			this.mealsObserver = null;
		}
  }
  
  addNote(contentAndField: any = {}, quiz_prefix: string, quiz_note_type: string) {
    const { content, content_F, acFormField } = contentAndField;
    this.addNoteEvent.emit({ content, content_F, quiz_prefix, quiz_note_type, acFormField });
  }

  editNote(idAndField: any = {}, quiz_prefix: string, quiz_note_type: string) {
    const { id, field } = idAndField;
    this.editNoteEvent.emit({ id, field, quiz_prefix, quiz_note_type });
  }

  removeNote(note: any = {}) {
    this.removeNoteEvent.emit(note);
  }

  cloneNotes() {
    this.cloneNotesEvent.emit();
  }

  linkedNote(note: any) {
    this.linkedNotesEvent.emit(note);
  }

}
