import { Component, ViewChild, TemplateRef, HostListener, Pipe, PipeTransform } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

import { appState } from '@services/app-state';

import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

// import { MatAccordion } from '@angular/material/expansion';

import { ConfirmDialogService } from '../../shared/confirm-dialog/confirm-dialog.service';
import { TranslateService } from '@ngx-translate/core';

import { UserDetailsComponent } from './sections/user-details/user-details.component';
import { AntropometricsComponent } from './sections/antropometrics/antropometrics.component';
import { PracticesComponent } from './sections/practices/practices.component';
import { SleepingComponent } from './sections/sleeping/sleeping.component';
import { BloodtestComponent } from './sections/bloodtest/bloodtest.component';
import { HealthComponent } from './sections/health/health.component';
import { NutritionComponent } from './sections/nutrition/nutrition.component';
import { FoodDiaryComponent } from './sections/food-diary/food-diary.component';

import { MatDialog } from '@angular/material/dialog';
import { ImageDialogComponent } from '../../shared/image-dialog.component';

import { ComponentCanDeactivate } from '../../shared/pending-changes.guard';
import { Observable } from 'rxjs';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';

import { NotestableComponent } from '../notestable/notestable.component';

import * as moment from 'moment-timezone';

@Pipe({
  name: 'fdqDetails'
})
export class FdqDetailsPipe implements PipeTransform {
  transform(id: string, fdqArr: any, prefix: string): any {
    if (fdqArr) {
      return fdqArr.find((val: any) => val.linked && val.linked[prefix] === id);
    }
    return null;
  }
}

@Component({
  templateUrl: "./nutritionistform.component.html"
})
export class NutritionistformComponent implements ComponentCanDeactivate {

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
        $event.returnValue = "There are unsaved changes.";
    }
  }

  @ViewChild(NotestableComponent) private notestable: NotestableComponent;

  @ViewChild("cloneFdqTemplate") cloneFdqTemplate: TemplateRef<any>;
  
  @ViewChild("autofillTemplate") autofillTemplate: TemplateRef<any>;
  @ViewChild("futureTemplate") futureTemplate: TemplateRef<any>;
  @ViewChild("futureQuizTemplate") futureQuizTemplate: TemplateRef<any>;

  // @ViewChild('accordion', {static:true}) Accordion: MatAccordion;

  @ViewChild(UserDetailsComponent) private userdetails: UserDetailsComponent;
  @ViewChild(AntropometricsComponent) private antropometrics: AntropometricsComponent;
  @ViewChild(PracticesComponent) private practices: PracticesComponent;
  @ViewChild(SleepingComponent) private sleeping: SleepingComponent;
  @ViewChild(HealthComponent) private health: HealthComponent;
  @ViewChild(BloodtestComponent) private bloodtest: BloodtestComponent;
  @ViewChild(NutritionComponent) private nutrition: NutritionComponent;
  @ViewChild(FoodDiaryComponent) private fooddiary: FoodDiaryComponent;

  sharedNotesFormA: FormGroup;
  sharedNotesFormB: FormGroup;
  allFdqValues: any = [];

  appState = appState;

  userData: any;

  kcalData: any;

  drinkInPractice = '';

  busy = false;

  chosenReport = '';
  userReports = [];

  allNotes = {
    ntq: [],
    btq: [],
    fdq: [],
    heq: [],
    nuq: [],
    prq: [],
    slq: []
  };

  allNotesUnfiltered = [];

  minDate: moment.Moment;

  quizPrefixes = {
    ntq: 'אנתרופומטריים',
    prq: 'אימונים',
    slq: 'שינה',
    heq: 'בריאות',
    nuq: 'תזונה',
    btq: 'בדיקת דם',
    fdq: 'יומן אכילה',
  };

  allSharedTypes = ['s1', 's2', 's3', 's4', 's5', 's6', 's7', 's8', 's9', 's10', 's11', 's12'];

  oldNotesFlag = false;

  selectedTab = 0;
  
  constructor(
    public dialog: MatDialog,
    private dialogService: ConfirmDialogService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public restApiService: RestApiService<any>
  ) {

    this.sharedNotesFormA = this.createSharedForm();
    this.sharedNotesFormB = this.createSharedForm();

    this.minDate = moment().add(1, 'day');

    this.route.params.subscribe(async params => {
      if (params.id && (this.appState.user.usr_type === 'superadmin' || this.appState.user.usr_type === 'nutri')) {
        const usr = await this.restApiService.service('users').get(params.id).catch(e => { console.error(e) });
        if (usr && usr._id) {
          // await this.fetchNotes();
          this.fetchNotes();
  
          if (!usr.usr_age_formatted) {
            this._snackBar.open('שים לב! המשתמש לא הזין את תאריך הלידה', 'סגור', {
              duration: 0,
            });
          }
          this.userData = usr
          this.appState.quizUser = this.userData;
  
          this.userReports = await this.fetchUserReports(usr._id);
          this.chosenReport = this.userReports.length > 0 ? this.userReports[0]._id : '';
          return;
        }
      }

      this.router.navigate([`/`]);
    });
  }

  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm alert before navigating away
    return this.getDirtyForms();
  }
  
  getDirtyForms() {
    const components = ['userdetails', 'antropometrics', 'practices', 'sleeping', 'health', 'bloodtest', 'nutrition', 'fooddiary'];
    for (let comp of components) {
      if (this[comp] && this[comp].getDirty()) { return false };
    }
    return true;
  }

  getLockedForms() {
    const components = ['antropometrics', 'practices', 'sleeping', 'health', 'bloodtest', 'nutrition', 'fooddiary'];
    for (let comp of components) {
      if (this[comp] && !this[comp].getLocked()) { return false };
    }
    return true;
  }

  async ngOnInit() {
    if (window.name === 'quizwindow') {
      window.name = '';
    }
  }

  setKcalData(e){
    this.kcalData = e;
  }

  setDrinkInPractice(e){
    this.drinkInPractice = e;
  }

  closeAllPanels(){
      // this.Accordion.closeAll();
  }
  openAllPanels(){
      // this.Accordion.openAll();
  }

  openDeleteDialog(deleteFunc: Function){
    const options = {
      title: 'מחיקת שאלון',
      message: 'האם למחוק שאלון זה?',
      cancelText: this.translate.instant("general.no"),
      confirmText: this.translate.instant("general.yes")
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        deleteFunc();
      }
    });
  }

  openNewDialog(newFunc: Function) {
    if (!this.getDirtyForms()) {
      const options = {
        title: 'יצירת שאלון חדש',
        message: 'יש לשמור את ההערות',
        errorMessage: 'שימו לב, יש הערות שלא נשמרו',
        cancelText: 'הבנתי',
        // cancelText: 'לא',
        // confirmText: 'כן'
      };
      this.dialogService.open(options);
      this.dialogService.confirmed().subscribe(async confirmed => {
        // if (confirmed) {
        //   newFunc();
        // }
      });
    } else {
      newFunc();
    }
  }

  saveAndLockAll() {
    const options = {
      title: 'שמירת הערות',
      message: 'האם לשמור את כל ההערות ולנעול את השאלונים?',
      cancelText: this.translate.instant("general.no"),
      confirmText: this.translate.instant("general.yes")
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(async confirmed => {
      if (confirmed) {
        await this.saveAll(true);
      }
    });
  }

  cloneFdqNotesPopup(diffArr?: any) {
    let diffNames = '';
    if (diffArr) {
      diffNames = diffArr.map((el: string) => this.quizPrefixes[el]).join(', ');
    }
    let newFormGroup: FormGroup;
    newFormGroup = this.formBuilder.group({
      clone: [true],
      diffNames: [diffNames]
    });

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // minWidth: '35%',
      data: { 
        title: 'קיים שיוך ליומן האכילה',
        // subtitle: '',
        // ok: this.translate.instant('general.ok'),
        ok: 'יומן אכילה חדש',
        cancel: this.translate.instant('general.cancel'),
        form: newFormGroup, 
        template: this.cloneFdqTemplate
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        this.busy = false;

        return;
      }
      this.busy = true;

      // const currentIds = {
      //   ntq: this.antropometrics.selectedIdA || '',
      //   // last_ntq: this.antropometrics.chosenLastNtq?._id || '',
      //   prq: this.practices.selectedIdA || '',
      //   slq: this.sleeping.selectedIdA || '',
      //   btq: this.bloodtest.selectedIdA || '',
      //   nuq: this.nutrition.selectedIdA || '',
      //   heq: this.health.selectedIdA || '',
      //   // fdq,
      // };
      // const currentIds = this.getCurrentIds();

      let data: any = {
        fdq_usr_id: this.userData._id,
        // fdq_form_is_real: true,
        // linked: currentIds
      };

      let msg = 'שאלון יומן אכילה נוצר בהצלחה';
      if (result.form.value.clone && this.sharedNotesFormA?.value) {
        const componentByPrefix = { 
          'ntq': 'antropometrics', 
          'prq': 'practices', 
          'slq': 'sleeping', 
          'heq': 'health', 
          'btq': 'bloodtest', 
          'nuq': 'nutrition', 
          // 'fdq': 'fooddiary',
        };

        data.fdq_notes = { ...this.sharedNotesFormA.value.fdq_notes };
        delete data.fdq_notes.A;
        delete data.fdq_notes.B;
        delete data.fdq_notes.C;
        delete data.fdq_notes.D;
        delete data.fdq_notes.E;
        delete data.fdq_notes.F;
        delete data.fdq_notes.G;
        delete data.fdq_notes.H;
        delete data.fdq_notes.I;
        for (let noteType in data.fdq_notes) {
          if (data.fdq_notes[noteType]?.length && diffArr?.length) {
            let diffCurrentNotes: any = {};
            for (const diffPrefix of diffArr) {
              let prefixesIds: any = {};
              if (componentByPrefix[diffPrefix] && this[componentByPrefix[diffPrefix]]) {
                const currentNotesForm: FormGroup = this[componentByPrefix[diffPrefix]].notesFormA;
                const notesGroup = currentNotesForm.get(`${diffPrefix}_notes`);
                if (notesGroup?.value) {
                  for (const key in notesGroup.value) {
                    if (!prefixesIds[key]) {
                      prefixesIds[key] = [];
                    }
                    if (notesGroup.value[key].length) {
                      for (const n of notesGroup.value[key]) {
                        if (n && n?.note_id) {
                          prefixesIds[key].push(n?.note_id);
                        }
                      }
                    }
                  }
                }
              }
              diffCurrentNotes[diffPrefix] = prefixesIds;
            }
            data.fdq_notes[noteType] = data.fdq_notes[noteType].filter((note: any) => {
              const noteDetails = this.allNotesUnfiltered.find((n: any) => note && n && n._id === note.note_id);
              return noteDetails && (!diffArr.includes(noteDetails.quiz_prefix) || (diffCurrentNotes[noteDetails.quiz_prefix] && diffCurrentNotes[noteDetails.quiz_prefix][noteType] && diffCurrentNotes[noteDetails.quiz_prefix][noteType].includes(note?.note_id)));
            });
          }
        }
        msg = 'שאלון יומן אכילה נוצר בהצלחה, הערות שוכפלו';
        // if (diffNames) {
        //   msg += ' למעט הערות משאלון: ' + diffNames;
        // }
      }
      await this.fooddiary.restService.create(data);
      await this.fooddiary.fetchOldValues(true);

      await this.saveAll(false, true);

      this._snackBar.open(msg, 'סגור', {
        duration: 0
      });

      this.busy = false;
    });
  }

  getCurrentIds(skipBtq?: boolean) {
    const linkNewNote = (comp: any) => {
      return this[comp] && this[comp].selectedIdA && this[comp].selectedA.createdAt && this[comp].selectedA.createdAt  > '2021-08-07T11:00:00.000Z' ? this[comp].selectedIdA : ''
    };
    const currentIds: any = {
      ntq: linkNewNote('antropometrics'),
      // last_ntq: this.antropometrics.chosenLastNtq?._id || '',
      prq: linkNewNote('practices'),
      slq: linkNewNote('sleeping'),
      // btq: linkNewNote('bloodtest'),
      nuq: linkNewNote('nutrition'),
      heq: linkNewNote('health'),
      // fdq,
    };

    if (!skipBtq) {
      currentIds.btq = linkNewNote('bloodtest');
    }

    return currentIds;
  }

  getSelectedADateFromBtq() {
    return (this.bloodtest?.selectedIdA && this.bloodtest.selectedIdA === this.bloodtest.selectedA?._id) ? this.bloodtest?.selectedA?.btq_test_date : null;
  }

  async saveAll(lock?: boolean, silent?: boolean) {

    this.busy = true;

    const userId = this.userData._id;
    const userReports = await this.fetchUserReports(userId);
    const cloneFdqResult = this.needToCloneFdq(userReports);
    const { needToClone, skipBtq } = cloneFdqResult;

    // const cloneFdqLinked = this.needToCloneFdqLinked(this.allFdqValues, this.sharedNotesFormA);

    this.userReports = userReports;
    // const cloneFdq = this.needToCloneFdq(this.userReports);

    if (needToClone) {
      this.cloneFdqNotesPopup();
      return -1;
    }
    // if (cloneFdq || cloneFdqLinked) {
    //   this.cloneFdqNotesPopup(cloneFdqLinked);
    //   // this.busy = false;
    //   return -1;
    // }

    const linkedField = this.sharedNotesFormA.get('linked');
    let linkedFlag = false;

    if (!linkedField.value) {
      // const currentIds = {
      //   ntq: this.antropometrics.selectedIdA || '',
      //   // last_ntq: this.antropometrics.chosenLastNtq?._id || '',
      //   prq: this.practices.selectedIdA || '',
      //   slq: this.sleeping.selectedIdA || '',
      //   btq: this.bloodtest.selectedIdA || '',
      //   nuq: this.nutrition.selectedIdA || '',
      //   heq: this.health.selectedIdA || '',
      //   // fdq,
      // };

      const currentIds = this.getCurrentIds(skipBtq);

      linkedField.patchValue(currentIds);
      // linkedField.markAsDirty();
      linkedFlag = true;
    }

    let components: any = {
      'userdetails': [], 
      'antropometrics': [], 
      'practices': [], 
      'sleeping': [], 
      'health': [], 
      // 'bloodtest': [], 
      'nutrition': [], 
      'fooddiary': []
    };
    let lockedFields: any = { 
      'antropometrics': 'ntq_locked', 
      'practices': 'prq_locked', 
      'sleeping': 'slq_locked', 
      'health': 'heq_locked', 
      // 'bloodtest': 'btq_locked', 
      'nutrition': 'nuq_locked', 
      'fooddiary': 'fdq_locked'
    };
    
    if (!skipBtq) {
      components.bloodtest = [];
      lockedFields.bloodtest = 'btq_locked';
    }

    let count = 0;
    for (let comp in components) {
      if (this[comp]) {
        for (const val of ['notesForm', 'notesFormA' , 'notesFormB']) {
          const form = this[comp][val];
          if (form && form.value && form.value._id) {
            const lockedField = lockedFields[comp];
            
            if (!form.pristine || (linkedFlag && val === 'notesFormA' && comp === 'fooddiary') || (lock && lockedField && !form.value[lockedField])) {
              if (lock && lockedField && form.controls[lockedField] && !form.value[lockedField]) {
                form.controls[lockedField].setValue(true);
                form.controls[lockedField].markAsDirty();
              }

              components[comp].push({
                form_name: val,
                form_value: form.value
              });
              count++;
            }
          }
        }
      }
    }

    if (count) {
      console.log(components);

      try {
        const compRes = await this.restApiService.service('notes-saver').create({components, userId});
        let msg = '';

        let successMsgs = { 
          'userdetails': 'הערות משתמש נשמרו', 
          'antropometrics': 'הערות אנתרופומטריים נשמרו', 
          'practices': 'הערות אימונים נשמרו', 
          'sleeping': 'הערות שינה נשמרו', 
          'health': 'הערות בריאות נשמרו', 
          'bloodtest': 'הערות בדיקת דם נשמרו', 
          'nutrition': 'הערות תזונה נשמרו', 
          // 'fooddiary': 'הערות יומן אכילה נשמרו',
          // 'fooddiary': 'הערות משותפות נשמרו',
        };

        for (let comp in components) {
          if (this[comp] && compRes[comp]) {
            for (const formObj of compRes[comp]) {
              if (formObj.form_name && formObj.result && this[comp][formObj.form_name]) {
                this[comp][formObj.form_name].reset(formObj.result);
                if (comp === 'fooddiary') {
                  await this.fooddiary.fetchOldValues(false, linkedFlag ? false : true);
                  let fooddiaryMsg = '';
                  // if (formObj.result.createdAt < '2021-08-07T11:00:00.000Z') {
                    fooddiaryMsg = 'הערות יומן אכילה נשמרו';
                  // } else {
                  //   fooddiaryMsg = 'הערות משותפות נשמרו';
                  // }
                  msg += fooddiaryMsg + "\n";
                } else {
                  msg += successMsgs[comp] + "\n";
                }
              }
            }
          }
        }

        if (msg) {
          this._snackBar.open(msg, null, {
            duration: 5000,
            panelClass: 'pre-wrap'
          });
        }
        if (compRes.follow_flag) {
          await this.reloadUserDetailsNotes();
          setTimeout(() => {
            this._snackBar.open('המשתמש נכנס למעקב', 'סגור', {
              duration: 0,
            });
          }, 4000);
        }
        this.busy = false;
        
      } catch (e) {
        console.log('global error on saving notes');
        console.error(e);
        this.busy = false;
      }

    } else {
      this.busy = false;
      if (!silent) {
        this._snackBar.open('אין שינויים', null, {
          duration: 2000
        });
      }

      for (let comp in components) {
        if (this[comp]) {
          for (const val of ['notesForm', 'notesFormA' , 'notesFormB']) {
            const form = this[comp][val];
            if (form && form.value && form.value._id) {
              form.markAsPristine()
              form.markAsUntouched()
            }
          }
        }
      }
    }

    return count;

  }

  needToCloneFdq(allReports: any[]) {
    let needToClone = false;
    let skipBtq = false;

    // const lastReport = allReports[0];
    const fdq = this.fooddiary.selectedIdA || '';
    const lastReport = allReports.find((rpt: any) => fdq && rpt.rpt_fdq_id === fdq)
    if (lastReport) {
      // const currentIds = {
      //   ntq: this.antropometrics.selectedIdA || '',
      //   // last_ntq: this.antropometrics.chosenLastNtq?._id || '',
      //   prq: this.practices.selectedIdA || '',
      //   slq: this.sleeping.selectedIdA || '',
      //   btq: this.bloodtest.selectedIdA || '',
      //   nuq: this.nutrition.selectedIdA || '',
      //   heq: this.health.selectedIdA || '',
      //   // fdq,
      // };

      const currentIds = this.getCurrentIds();
      
      // if (currentIds.fdq && currentIds.fdq === lastReport.rpt_fdq_id) {
        for (const key of ['ntq', 'prq', 'slq', 'nuq', 'heq']) { // ['ntq', 'prq', 'slq', 'btq', 'nuq', 'heq']
          if (currentIds[key] && currentIds[key] !== lastReport[`rpt_${key}_id`]) {
            // return true;
            needToClone = true;
          }
        }

        if (!needToClone) {
          if (currentIds.btq !== lastReport.rpt_btq_id) {
            const btqDate = this.getSelectedADateFromBtq();
            if (btqDate) {
              console.log('needToClone btqDate', btqDate)
              needToClone = true;
            } else {
              skipBtq = true;
            }
          }
        }

      // }      
    }

    // return false;
    return { needToClone, skipBtq };
  }

  // needToCloneFdqLinked(allFdq: any[], notesFormA: FormGroup) {
  //   // const fdq = this.fooddiary.selectedIdA || '';
  //   // const lastFdqLinked = allFdq.find((quiz: any) => fdq && quiz._id === fdq)?.linked;

  //   // console.log(fdq)
  //   // console.log(allFdq)
  //   // console.log(lastFdqLinked)
  //   // console.log(notesFormA.value)
  //   // console.log(notesFormA.value.linked)
  //   const lastFdqLinked = notesFormA.value.linked;
  //   if (lastFdqLinked) {
  //     // const currentIds = {
  //     //   ntq: this.antropometrics.selectedIdA || '',
  //     //   // last_ntq: this.antropometrics.chosenLastNtq?._id || '',
  //     //   prq: this.practices.selectedIdA || '',
  //     //   slq: this.sleeping.selectedIdA || '',
  //     //   btq: this.bloodtest.selectedIdA || '',
  //     //   nuq: this.nutrition.selectedIdA || '',
  //     //   heq: this.health.selectedIdA || '',
  //     //   // fdq,
  //     // };

  //     const currentIds = this.getCurrentIds();
  //     let diff: string[] = [];
  //     for (const key of ['ntq', 'prq', 'slq', 'btq', 'nuq', 'heq']) {
  //       if (currentIds[key] && currentIds[key] !== lastFdqLinked[key]) {
  //         // return true;
  //         diff.push(key);
  //       }
  //     }
  //     if (diff.length) {
  //       return diff;
  //     }
  //   }

  //   return false;
  // }

  openImageDialog(imgSrc: string) {
    const options = {
      maxWidth: "90vw",
      panelClass: "p-0-dialog",
      data: {
        imgSrc
      }
    };
    this.dialog.open(ImageDialogComponent, options);
  }

  async getRecentNuttest(userId: string) {
    let ids = {
      ntq: '',
      last_ntq: ''
    };
    try {
      const usr = await this.restApiService.service('users').get(userId);
      const user_ntqs_filtered = usr.user_ntqs_filtered || [];
      ids.ntq = user_ntqs_filtered[0]?._id || '';
      ids.last_ntq = user_ntqs_filtered[1]?._id || '';

      // let res = await this.restApiService.service("nuttestquiz").find({
      //   query: {
      //     $paginate: false,
      //     ntq_usr_id: userId,
      //     ntq_form_is_real: true,
      //     $sort: {
      //       ntq_test_date: -1,
      //       createdAt: -1
      //     },
      //     // is_by_user: {
      //     //   $ne: true
      //     // }
      //   }
      // });

      // if (res.length > 0) {

      //   ids.ntq = res[0]._id;

      //   delete res[0];
        
      //   const filtered = res.find(el => el && el['ntq_test_date']);
      //   if (filtered) {
      //     ids.last_ntq = filtered._id;
      //   } else {
      //     const res_report = await this.restApiService.service('userreport').find({ 
      //       query: {
      //         $limit: 1,
      //         $paginate: false,
      //         rpt_usr_id: userId,
      //         $sort: {
      //           createdAt: -1
      //         },
      //         rpt_is_published: true
      //       },
      //     });
      //     if (res_report.length > 0) {
      //       ids.last_ntq = res_report[0].rpt_ntq_id;
      //     }
      //   }
      // } else {
      //   const now = new Date();
      //   const res = await this.restApiService.service('nuttestquiz').create({
      //     ntq_usr_id: userId,
      //     ntq_form_is_real: true,
      //     ntq_test_date: now
      //   });
      //   if (res && res._id) {
      //     ids.ntq = res._id;
      //   } else {
      //     throw 'creating ntq error';
      //   }
      // }
    } catch (e) {
      console.error(e);
    }
    return ids;
  }

  isSameReport(allReports: any[]) {
    // const lastReport = allReports[0];
    const fdq = this.fooddiary.selectedIdA || '';
    const lastReport = allReports.find((rpt: any) => fdq && rpt.rpt_fdq_id === fdq)
    if (lastReport) {
      const currentIds = {
        ntq: this.antropometrics.selectedIdA || '',
        last_ntq: this.antropometrics.chosenLastNtq?._id || '',
        prq: this.practices.selectedIdA || '',
        slq: this.sleeping.selectedIdA || '',
        btq: this.bloodtest.selectedIdA || '',
        nuq: this.nutrition.selectedIdA || '',
        heq: this.health.selectedIdA || '',
        fdq,
      };
      
      // if (currentIds.fdq && currentIds.fdq === lastReport.rpt_fdq_id) {
        for (const key of ['ntq', 'last_ntq', 'prq', 'slq', 'btq', 'nuq', 'heq']) {
          if (currentIds[key] && currentIds[key] !== lastReport[`rpt_${key}_id`]) {
            return false;
          }
        }
      // }
      return true;
    }

    return false;
  }
  
  async checkForChangesAndCreate() {
    if (!this.getDirtyForms()) {
      // const options = {
      //   title: 'נתונים לא נשמרו',
      //   message: 'האם להמשיך ביצירת דוח? יש שינויים שלא נשמרו',
      //   cancelText: this.translate.instant("general.no"),
      //   confirmText: this.translate.instant("general.yes"),
      //   errorMessage: 'במידה ותמשיך השינויים יאבדו'
      // };
      const options = {
        title: 'הפקת דוח חדש',
        message: 'האם לשמור הערות ולהמשיך בהפקת הדוח?',
        cancelText: this.translate.instant("general.no"),
        confirmText: this.translate.instant("general.yes"),
        errorMessage: 'יש הערות שלא נשמרו'
      };
      this.dialogService.open(options);
      this.dialogService.confirmed().subscribe(async confirmed => {
        if (confirmed) {
          await this.createNewReport();
        }
      });
    } else {
      await this.createNewReport();
    }
  }

  async createNewReport(skipReportCheck?: boolean) {
    const userData = this.userData;
    if (!userData || !userData._id) {
      return;
    }

    const saveAll = await this.saveAll(false, true);
    if (saveAll === -1) {
      return;
    }

    // this.userReports = await this.fetchUserReports(userData._id);
    const sameReport = this.isSameReport(this.userReports);
    if (sameReport && !skipReportCheck) {
      const options = {
        title: 'הפקת דוח חדש',
        message: 'האם אתה בטוח שאתה רוצה להפיק את הדוח?',
        cancelText: this.translate.instant("general.no"),
        confirmText: this.translate.instant("general.yes"),
        errorMessage: 'שים לב! קיים כבר דוח במערכת המקושר לאותם השאלונים'
      };
      this.dialogService.open(options);
      this.dialogService.confirmed().subscribe(async confirmed => {
        if (confirmed) {
          await this.createNewReport(true);
        } else {
          this.busy = false;
        }
      });

      return;
    }

    const btqDate = await this.checkLastBloodtestDate(userData._id);

    if (btqDate){
      await this.newReportFunc(userData);
      return;
    }

    const options = {
      title: 'הפקת דוח חדש',
      message: 'האם אתה בטוח שאתה רוצה להפיק את הדוח?',
      cancelText: this.translate.instant("general.no"),
      confirmText: this.translate.instant("general.yes"),
      errorMessage: 'שים לב! שאלון בדיקות הדם ללא תאריך!'
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(async confirmed => {
      if (confirmed) {
        await this.newReportFunc(userData);
      } else {
        this.busy = false;
      }
    });
  }

  async newReportFunc(userData: any) {
    
    this.busy = true;

    this.closeAllPanels();
    // reset forms to last value
    // await this.antropometrics.fetchOldValues(true, true);
    // await this.practices.fetchOldValues(true, true);
    // await this.sleeping.fetchOldValues(true, true);
    // await this.health.fetchOldValues(true, true);
    // await this.nutrition.fetchOldValues(true, true);
    // await this.bloodtest.fetchOldValues(true, true);
    // await this.fooddiary.fetchOldValues(true);

    const newId = await this.newReport(userData);
    if (newId) {
      // this.router.navigate([`Userreport/${newId}`]);
      window.open(`Userreport/${newId}`);
    } else {
      this._snackBar.open('תקלה ביצירת דוח חדש', null, {
        duration: 3000
      });
    }

    this.busy = false;
  }

  async newReport(userData: any) {
    let newId: string;
    try {
      const prq_team_practice_arr = await this.fetchTeamPractices(userData);
      // let { ntq , last_ntq } = await this.getRecentNuttest(userData._id);

      // if (this.antropometrics.chosenLastNtq?._id) {
      //   last_ntq = this.antropometrics.chosenLastNtq._id;
      //   // this.antropometrics.chosenLastNtq = null;
      // }

      const ntq = this.antropometrics.selectedIdA || '';
      const last_ntq = this.antropometrics.chosenLastNtq?._id || '';

      const prq = this.practices.selectedIdA || '';
      const slq = this.sleeping.selectedIdA || '';
      const btq = this.bloodtest.selectedIdA || '';
      const nuq = this.nutrition.selectedIdA || '';
      const heq = this.health.selectedIdA || '';
      const fdq = this.fooddiary.selectedIdA || '';

      let data = {
        rpt_usr_id: userData._id,
        rpt_is_published: false,
        rpt_created_by: appState.user._id,
        rpt_ntq_id: ntq,
        rpt_last_ntq_id: last_ntq,
        rpt_prq_id: prq,
        rpt_slq_id: slq,
        rpt_btq_id: btq,
        rpt_nuq_id: nuq,
        rpt_heq_id: heq,
        rpt_fdq_id: fdq,
        prq_team_practice_arr,
      }

      const res = await this.restApiService.service('userreport').create(data);
      newId = res && res._id;
      this.busy = false;
    } catch (e) {
      console.error(e);
      this._snackBar.open('תקלה ביצירת דוח חדש', null, {
        duration: 3000
      });
      this.busy = false;
    }
    
    return newId;
  }
  
  clickReport(e, chosenId: string) {
    e.stopPropagation();
    window.open(`Userreport/${chosenId}`);
    // this.router.navigate([`Userreport/${chosenId}`]);
  }
  
  // createAllQuizes() {
  //   const options = {
  //     title: 'יצירת שאלונים חדשים',
  //     message: 'האם ליצור שאלונים חדשים? (ללא שאלון בדיקות דם)',
  //     cancelText: this.translate.instant("general.no"),
  //     confirmText: this.translate.instant("general.yes")
  //   };
  //   this.dialogService.open(options);
  //   this.dialogService.confirmed().subscribe( async confirmed => {
  //     if (confirmed) {
  //       this.busy = true;
  //       try {

  //         // await this.antropometrics.newAntroQuiz(true);
  //         await this.antropometrics.newQuiz();
  //         await this.practices.newQuiz();
  //         await this.sleeping.newQuiz();
  //         await this.health.newQuiz();
  //         await this.nutrition.newQuiz();
  //         // await this.bloodtest.newQuiz();
  //         await this.fooddiary.newQuiz();

  //         this.busy = false;
  //         this._snackBar.open('שאלונים נוצרו בהצלחה', null, {
  //           duration: 3000
  //         });

  //       } catch (e) {
  //         console.error(e);
  //         this.busy = false;
  //       }
  //     }
  //   });
  // }

  async checkLastBloodtestDate(userId: string) {
    const res = await this.restApiService.service('bloodtestquiz').getLast({
      btq_usr_id: userId,
      btq_form_is_real: true,
      $sort: {
          createdAt: -1
      }
    });

    return Boolean(res && res[0] && res[0]['btq_test_date']);
  }

  setBusy(e) {
    this.busy = e;
  }
  
  async fetchUserReports(userId: string) {
    const res = await this.restApiService.service('userreport').find({ 
      query: {
        // $select: ['_id', 'createdAt', 'updatedAt'],
        $paginate: false,
        $sort: {
          createdAt: -1
        },
        rpt_usr_id: userId,
        // rpt_is_hidden: {
        //   $ne: true
        // },
      },
    });

    const filteredData = res.filter((report: any) => report.createdAt !== report.updatedAt);

    // if (filteredData.length > 0) {
    //   this.chosenReport = filteredData[0]._id;
    // }
    return filteredData;
  }

  async fetchTeamPractices(userData: any){
    const userTeamIds = userData.usr_team_ids;

    if (userTeamIds && userTeamIds.length > 0) {
      return await this.restApiService.service('team-practice').find({ 
        query: {
          $paginate: false,
          tem_practice_tem_id: userTeamIds,
        },
      });
    }
    return [];

  }

  autofillPopup() {
    const userId = this.userData._id;

    let newFormGroup: FormGroup = this.formBuilder.group({
      // reset_notes: [false],
      // userdetails: [true],
      // antropometrics: [true],
      practices: [true],
      sleeping: [true],
      // health: [true],
      // bloodtest: [true],
      // nutrition: [true],
      // fooddiary: [true],
    });

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // minWidth: '35%',
      data: { 
        title: 'מילוי הערות אוטומטי',
        // subtitle: 'אנא בחר שאלונים',
        ok: this.translate.instant('general.ok'),
        cancel: this.translate.instant('general.cancel'),
        form: newFormGroup, 
        template: this.autofillTemplate
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }
      await this.autofill(userId);
    });
  }

  async autofillCheck() {
    const userId = this.userData._id;
    if (!this.getDirtyForms()) {
      const options = {
        title: 'נתונים לא נשמרו',
        message: 'האם להמשיך במילוי אוטומטי? יש שינויים שלא נשמרו',
        cancelText: this.translate.instant("general.no"),
        confirmText: this.translate.instant("general.yes"),
        errorMessage: 'במידה ותמשיך השינויים יאבדו'
      };
      this.dialogService.open(options);
      this.dialogService.confirmed().subscribe(async confirmed => {
        if (confirmed) {
          await this.autofill(userId);
        }
      });
    } else {
      await this.autofill(userId);
    }
  }

  async autofill(userId: string) {
    this.busy = true;
    if(this.nutrition.isPickyEater === undefined){
      this._snackBar.open('נא לסמן האם השחקן בררן באוכל', null, {
        duration: 3000
      });
      this.busy = false;
      return;
    }

    const res = await this.restApiService.service('analyze').get(userId);
    console.log(res);
    debugger
    if (!res) {
      this.busy = false;
      return;
    }
    let msg = '';
    if (res.trainingAndEnergy && res.trainingAndEnergy.remarks?.length) {
      await this.practices.fetchOldValues(true, true);
      // this.practices.fillNotes(res.trainingAndEnergy.remarks);
      this.fillNotesAutofill('practices', res.trainingAndEnergy.remarks);
      msg = msg + 'הערות אימונים הוזנו בהצלחה' + '\n';
    }
    if (res.sleep && res.sleep.length > 0) {
      await this.sleeping.fetchOldValues(true, true);

      const newNotes = await this.createNewNotes(res.sleep);
      console.log('new notes created: ', newNotes && newNotes.length);

      this.fetchNotes();
      this.fillNotesAutofill('sleeping', newNotes);
      msg = msg + 'הערות שינה הוזנו בהצלחה' + '\n';
    }
    if (res?.training && res?.training.length) {
      await this.practices.fetchOldValues(true, true);
      const newNotes = await this.createNewNotes(res.training);
      console.log('new notes created: ', newNotes && newNotes.length);
      // await this.fetchNotes();
      this.fetchNotes();
      this.fillNotesAutofill('practices', newNotes);
      msg = msg + 'הערות אימונים הוזנו בהצלחה' + '\n';
    }
    if (res?.nuttest && res?.nuttest.length) {
      await this.antropometrics.fetchOldValues(true, true);
      const newNotes = await this.createNewNotes(res.nuttest);
      console.log('new notes created: ', newNotes && newNotes.length);
      this.fetchNotes();
      this.fillNotesAutofill('nuttest', newNotes);
      msg = msg + 'הערות מדידת תזונאי נוצרו בהצלחה' + '\n';
    }
    if (res?.health && res?.health.length) {
      await this.health.fetchOldValues(true, true);
      const newNotes = await this.createNewNotes(res.health);
      console.log('new notes created: ', newNotes && newNotes.length);
      this.fetchNotes();
      this.fillNotesAutofill('health', newNotes);
      msg = msg + 'הערות בריאות נוצרו בהצלחה' + '\n';
    }
    if (res?.bloodtest && res?.bloodtest.length) {
      await this.bloodtest.fetchOldValues(true, true);
      const newNotes = await this.createNewNotes(res.bloodtest);
      console.log('new notes created: ', newNotes && newNotes.length);
      this.fetchNotes();
      this.fillNotesAutofill('bloodtest', newNotes);
      msg = msg + 'הערות בדיקות דם נוצרו בהצלחה' + '\n';
    }
    if (res?.nutquiz && res?.nutquiz.length) {
      // await this.nutrition.fetchOldValues(true, true);
      const newNotes = await this.createNewNotes(res.nutquiz);
      console.log('new notes created: ', newNotes && newNotes.length);
      this.fetchNotes();
      this.fillNotesAutofill('nutrition', newNotes);
      msg = msg + 'הערות תזונה נוצרו בהצלחה' + '\n';
    }
    if (res?.fooddiary && res?.fooddiary.length) {
      await this.fooddiary.fetchOldValues(true, true);
      const newNotes = await this.createNewNotes(res?.fooddiary);
      console.log('new notes created: ', newNotes && newNotes.length);
      this.fetchNotes();
      this.fillNotesAutofill('fooddiary', newNotes);
      msg = msg + 'הערות יומן אכילה נוצרו בהצלחה' + '\n';
    }
    if(res?.antropometrics && res?.antropometrics.length) {
      await this.antropometrics.fetchOldValues(true, true);
      const newNotes = await this.createNewNotes(res?.antropometrics);
      console.log('new notes created: ', newNotes && newNotes.length);
      this.fetchNotes();
      this.fillNotesAutofill('antropometrics', newNotes);
      msg = msg + 'הערות מדידת תזונאי נוצרו בהצלחה' + '\n';
    }
    if (msg) {
      this._snackBar.open(msg, 'סגור', {
        duration: 0,
        panelClass: 'pre-wrap'
      });
    }
    this.busy = false;
  }

  async createNewNotes(notes: any[]) {
    let newNotes: any[] = [];
    for (const note of notes) {
      try {
        const res = await this.restApiService.service('note').create(note);
        newNotes.push(res);
      } catch (err) {
        console.error(err);
      }
    }
    return newNotes;
  }
  
  fillNotesAutofill(comp: string, notes: any[]) {
      debugger
    if (this[comp]) {
      const now = new Date().getTime();

      for (const note of notes) {
        const { quiz_prefix, quiz_note_type, _id } = note;
        if (note?.quiz_note_type) {

          if (this[comp] && quiz_prefix !== 'fdq') {
            const newNote = new FormControl({
              note_id: _id,
              is_active: !this.allSharedTypes.includes(quiz_note_type), // false, //true,
              is_done: false,
              note_obj: note,
              added_at: now,
              note_type: 'autofill',
            });
            this[comp].addNoteToNotesObj(quiz_note_type, newNote);
          }
          if (this.fooddiary && (quiz_prefix === 'fdq' || this.allSharedTypes.includes(quiz_note_type))) {
            const newNote = new FormControl({
              note_id: _id,
              is_active: true,
              is_done: false,
              added_at: now,
              note_type: 'autofill',
            });
            this.fooddiary.addNoteToNotesObj(quiz_note_type, newNote);
          }
        }
      }

    }
  }

  async fetchNotes() {
    this.appState.isLoadingNotes = true;

    this.allNotes = this.appState.allNotes;
    this.allNotesUnfiltered = this.appState.allNotesUnfiltered;

    let notes = {
      ntq: [],
      btq: [],
      fdq: [],
      heq: [],
      nuq: [],
      prq: [],
      slq: []
    };
    const res = await this.restApiService.service('note').find({
      query: {
        $sort: {
          title: 1,
          content: 1
        },
        // $limit: -1,
        $paginate: false,
        $limit: 25000,
        is_deleted: {
          $ne: true
        },
        $or: [
          // {is_visible: true},
          // {is_visible_all: true},
          // {is_scenario: true},
          // {createdAt: {$lt: '2022-08-27T00:00:00.000Z'}},
          // {
          //   $and: [
          //     {
          //       createdAt: {$gt: '2022-08-27T00:00:00.000Z'},
          //       trigger_usr_id: this.userData?._id,
          //     },
          //   ],
          // }
          {trigger_usr_id: this.userData?._id,}
        ]
      }
    });

    for (const key in notes) {
      notes[key] = res.filter(el => el.quiz_prefix == key);
    }

    this.allNotes = notes;
    this.allNotesUnfiltered = res;

    this.appState.allNotes = notes;
    this.appState.allNotesUnfiltered = res;

    this.appState.isLoadingNotes = false;

    console.log('notes loaded', res.length);
  }

  newFollow() {
    if (this.userdetails) {
      this.userdetails.newFollowPopup();
    }
  }

  async reloadUserDetailsNotes() {
    console.log('reload user notes');
    const userId = this.userData._id;
    if (this.userdetails && userId) {
      // if (!this.userdetails.getDirty()) {
      //   const options = {
      //     title: 'נתונים לא נשמרו',
      //     // message: 'יש שינויים שלא נשמרו',
      //     cancelText: this.translate.instant("general.no"),
      //     confirmText: this.translate.instant("general.yes"),
      //     errorMessage: 'במידה ותמשיך השינויים יאבדו'
      //   };
      //   this.dialogService.open(options);
      //   this.dialogService.confirmed().subscribe(async confirmed => {
      //     if (confirmed) {
      //       const res = await this.restApiService.service('users').get(userId);
      //       this.userdetails.refreshForm(res);
      //     }
      //   });
      // } else {
        const res = await this.restApiService.service('users').get(userId);
        await this.userdetails.refreshForm(res);
      // }
    }
  }

  createAllQuizes() {
    // let newFormGroup: FormGroup = this.formBuilder.group({
    //   due_date: [''],
    //   ntq: [true],
    //   prq: [true],
    //   slq: [true],
    //   heq: [true],
    //   nuq: [true],
    //   fdq: [true],

    //   btq: [false],
    //   recommend: [false],
    // });

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // minWidth: '35%',
      data: { 
        title: 'יצירת שאלונים חדשים',
        subtitle: 'האם ליצור שאלונים חדשים? (ללא שאלון בדיקות דם)',
        ok: this.translate.instant('general.yes'),
        cancel: this.translate.instant('general.no'),
        // form: newFormGroup, 
        // template: this.createAllTemplate
        // extraButton: { value: 'future', text: 'בהמשך' }
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }
      // if (result === 'future') {
      //   this.futurePopup(false);
      //   return;
      // }
      this.busy = true;
      try {

        // await this.antropometrics.newQuiz();
        await this.health.newQuiz();
        // await this.bloodtest.newQuiz();
        await this.sleeping.newQuiz();
        await this.practices.newQuiz();
        await this.nutrition.newQuiz();
        await this.fooddiary.newQuiz();

        // const chosenQuizzes = {
        //   heq: true,
        //   slq: true,
        //   prq: true,
        //   nuq: true,
        // }

        // const user = this.userData;
        
        // const res = await this.restApiService.service('new-quizzes').create({ chosenIds: [ user ], chosenQuizzes });

        // console.log('new-quizzes created', res);

        // await this.health.fetchOldValues(true);
        // await this.sleeping.fetchOldValues(true);
        // await this.practices.fetchOldValues(true);
        // await this.nutrition.fetchOldValues(true);

        this.busy = false;
        this._snackBar.open('שאלונים נוצרו בהצלחה', null, {
          duration: 3000
        });

      } catch (e) {
        console.error(e);
        this.busy = false;
      }
      
    });

  }

  showAllFuture(futureQuiz: any[]) {
    let msg = '';
    for (const future of futureQuiz) {
      const { due_date, heq, prq, slq, nuq, fdq, ntq, btq, recommend } = future;
      if (heq || prq || slq || nuq || fdq || ntq) {
        msg = msg + 'תאריך לפתיחת שאלונים ' + due_date + ' \n';
      }
      if (btq) {
        msg = msg + 'תאריך לפתיחת שאלון בדיקות דם ' + due_date + ' \n';
      }
    }
    if (msg) {
      this._snackBar.open(msg, 'סגור', {
        duration: 0,
        panelClass: 'pre-wrap'
      });
    }
  }

  futurePopup(btqMode: boolean, recommend?: boolean) {
    let newFormGroup: FormGroup;
    if (btqMode) {
      newFormGroup = this.formBuilder.group({
        due_date: [this.minDate, Validators.required],
        // ntq: [true],
        // prq: [true],
        // slq: [true],
        // heq: [true],
        // nuq: [true],
        // fdq: [true],
  
        // btq: [false],
        btq: [true],
        recommend: [recommend],
      });
    } else {
      newFormGroup = this.formBuilder.group({
        due_date: [this.minDate, Validators.required],
        ntq: [true],
        prq: [true],
        slq: [true],
        heq: [true],
        nuq: [true],
        fdq: [true],
  
        // btq: [false],
        // recommend: [false],
      });
    }

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // minWidth: '35%',
      data: { 
        title: 'יצירת שאלונים עתידית',
        // subtitle: 'אנא בחר שאלונים',
        ok: this.translate.instant('general.ok'),
        cancel: this.translate.instant('general.cancel'),
        form: newFormGroup, 
        template: this.futureTemplate
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }
      this.busy = true;
      const userId = this.userData._id;
      const usr = await this.restApiService.service('users').get(userId);
      if (usr) {
        let futureQuiz = usr.usr_quiz_future || [];
        const newFuture = newFormGroup.value;
        const stringDate = moment(newFuture.due_date).format("DD/MM/YYYY");
        futureQuiz.push({ ...newFuture, due_date: stringDate });
        const res = await this.restApiService.service('users').patch(userId, { usr_quiz_future: futureQuiz });
        const resFuture = res && res.usr_quiz_future;
        console.log('futureQuiz: ', futureQuiz);
        console.log('newFuture: ', newFuture);
        console.log('resFuture: ', resFuture);
        if (resFuture && resFuture.length && resFuture[resFuture.length - 1].due_date == stringDate) {
          this.userData.usr_quiz_future = resFuture;
          this._snackBar.open(`תאריך עתידי נוסף בהצלחה ${stringDate}`, null, {
            duration: 5000
          });
        }
      }
      this.busy = false;
    });
  }

  async showAllFuturePopup() {
    this.busy = true;
    const userId = this.userData._id;
    const usr = await this.restApiService.service('users').get(userId);
    this.userData.usr_quiz_future = usr?.usr_quiz_future;
    if (!usr?.usr_quiz_future?.length) {
      this._snackBar.open('אין תאריכים עתידיים', null, {
        duration: 3000
      });
      this.busy = false;
      return;
    }

    let newFormGroup: FormGroup = this.formBuilder.group({
      futureQuiz: [[...usr.usr_quiz_future]]
    });

    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // minWidth: '35%',
      data: { 
        title: 'שאלונים עתידיים',
        // subtitle: 'אנא בחר שאלונים',
        ok: this.translate.instant('general.save'),
        cancel: this.translate.instant('general.cancel'),
        form: newFormGroup, 
        template: this.futureQuizTemplate
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        return;
      }
      this.busy = true;
      let futureQuiz = newFormGroup.value.futureQuiz || [];
      const res = await this.restApiService.service('users').patch(userId, { usr_quiz_future: futureQuiz });
      const resFuture = res && res.usr_quiz_future;
      if (resFuture) {
        this.userData.usr_quiz_future = resFuture;
        this._snackBar.open('נשמר בהצלחה', null, {
          duration: 3000
        });
      }
      this.busy = false;
    });
    
    this.busy = false;
  }

  removeFutureQuiz(futureQuiz: any, i: number) {
    if (Array.isArray(futureQuiz)) {
      futureQuiz.splice(i, 1);
    }
  }

  removeNote(note?: any) {
    const { quiz_prefix, quiz_note_type, _id } = note;

    const componentByPrefix = { 
      'ntq': 'antropometrics', 
      'prq': 'practices', 
      'slq': 'sleeping', 
      'heq': 'health', 
      'btq': 'bloodtest', 
      'nuq': 'nutrition', 
      // 'fdq': 'fooddiary',
    };
    
    const comp = componentByPrefix[quiz_prefix];
    if (this.allSharedTypes.includes(quiz_note_type) && comp && this[comp]) {
      this[comp].removeNote(quiz_note_type, _id);
    }

  }

  notestableOpenNoteDialog({ content = '', content_F = '', quiz_prefix = '', quiz_note_type = '', sub_prefix = '', acFormField }) {
    this.notestable.openNoteDialog(null, null, content, content_F, quiz_prefix, quiz_note_type, sub_prefix);
  }

  async notestableOpenEditNoteDialog({ id, field, quiz_prefix = '', quiz_note_type = '', sub_prefix = '' }) {
    // const note = await this.restApiService.service('note').find({
    //   query: {
    //     $notestable: true,
    //     _id: id,
    //     $limit: -1,
    //     is_deleted: {
    //       $ne: true
    //     }
    //   }
    // });
    const note = await this.restApiService.service('note').get(id).catch((error: any) => { console.error(error) } );
    
    if (note) {
      let sharedField: FormArray;
      if (this.allSharedTypes.includes(quiz_note_type) && this.sharedNotesFormA) {
        sharedField = this.sharedNotesFormA.get('fdq_notes').get(quiz_note_type) as FormArray;
      }
      this.notestable.editNote(note, field, quiz_prefix, quiz_note_type, sub_prefix, sharedField);
    } else {
      this._snackBar.open('שגיאה - הערה לא קיימת / נמחקה', null, {
        duration: 3000
      });
    }
  }

  notestableNoteSubmitted(note?: any) {
    console.log('notestableNoteSubmitted', note?._id);

    // await this.fetchNotes();
    this.fetchNotes();

    this.addNote(note);

    this.linkedNote(note);
    
    return;


    this.fetchNotes().then(() => {
      this.addNote(note);

      this.linkedNote(note);
      
      // const { quiz_prefix, quiz_note_type, _id } = note;

      // const allSharedTypes = ['s1', 's2', 's3', 's4', 's5', 's6', 's7', 's8', 's9', 's10'];
      // const componentByPrefix = { 
      //   'ntq': 'antropometrics', 
      //   'prq': 'practices', 
      //   'slq': 'sleeping', 
      //   'heq': 'health', 
      //   'btq': 'bloodtest', 
      //   'nuq': 'nutrition', 
      //   'fdq': 'fooddiary',
      // };
      
      // // const comp = allSharedTypes.includes(quiz_note_type) ? 'fooddiary' : componentByPrefix[quiz_prefix];
      
      // const comp = componentByPrefix[quiz_prefix];
      // const now = new Date().getTime();
      // if (this[comp] && quiz_prefix !== 'fdq') {
      //   const newNote = new FormControl({
      //     note_id: _id,
      //     // note_quiz_prefix: quiz_prefix,
      //     is_active: !allSharedTypes.includes(quiz_note_type), // false, //true,
      //     is_done: false,
      //     // note_obj: note,
      //     added_at: now,
      //   });
      //   // field.push(newNote);
      //   // field.markAsDirty();
      //   this[comp].addNoteToNotesObj(quiz_note_type, newNote);
      // }

      // if (this.fooddiary && (quiz_prefix === 'fdq' || allSharedTypes.includes(quiz_note_type))) {
      //   const newNote = new FormControl({
      //     note_id: _id,
      //     is_active: true,
      //     is_done: false,
      //     added_at: now,
      //   });
      //   this.fooddiary.addNoteToNotesObj(quiz_note_type, newNote);
      // }
    });

  }

  linkedNote(note: any) {
    if (this.allNotesUnfiltered && note.linked_notes && note.linked_notes.length) {
      for (const linked of note.linked_notes) {
        const n = this.allNotesUnfiltered.find((not: any) => not && linked && not._id === linked.note_id);
        if (n) {
          this.addNote(n, true);
        }
      }
    }
  }

  addNote(note?: any, isLinked?: boolean) {
    const { quiz_prefix, quiz_note_type, _id } = note;

    const componentByPrefix = { 
      'ntq': 'antropometrics', 
      'prq': 'practices', 
      'slq': 'sleeping', 
      'heq': 'health', 
      'btq': 'bloodtest', 
      'nuq': 'nutrition', 
      'fdq': 'fooddiary',
    };
    
    // const comp = allSharedTypes.includes(quiz_note_type) ? 'fooddiary' : componentByPrefix[quiz_prefix];
    
    const comp = componentByPrefix[quiz_prefix];
    const now = new Date().getTime();
    if (this[comp] && quiz_prefix !== 'fdq') {
      let data: any = {
        note_id: _id,
        // note_quiz_prefix: quiz_prefix,
        is_active: !this.allSharedTypes.includes(quiz_note_type), // false, //true,
        is_done: false,
        // note_obj: note,
        added_at: now,
      };
      if (isLinked) {
        data.note_type = 'linked';
      }
      const newNote = new FormControl(data);
      // field.push(newNote);
      // field.markAsDirty();
      this[comp].addNoteToNotesObj(quiz_note_type, newNote);
    }

    if (this.fooddiary && (quiz_prefix === 'fdq' || this.allSharedTypes.includes(quiz_note_type))) {
      let data: any = {
        note_id: _id,
        is_active: true,
        is_done: false,
        added_at: now,
      };
      if (isLinked) {
        data.note_type = 'linked';
      }
      const newNote = new FormControl(data);
      this.fooddiary.addNoteToNotesObj(quiz_note_type, newNote);
    }
  }

  createSharedForm(): FormGroup {
    return this.formBuilder.group({
      _id: [''],
      fdq_locked: [false],

      fdq_shown: [false],

      fdq_notes: this.formBuilder.group({
        A: this.formBuilder.array([]),
        B: this.formBuilder.array([]),
        C: this.formBuilder.array([]),
        D: this.formBuilder.array([]),
        E: this.formBuilder.array([]),
        F: this.formBuilder.array([]),
        G: this.formBuilder.array([]),
        H: this.formBuilder.array([]),
        I: this.formBuilder.array([]),
        
        s1: this.formBuilder.array([]),
        s2: this.formBuilder.array([]),
        s3: this.formBuilder.array([]),
        s4: this.formBuilder.array([]),
        s5: this.formBuilder.array([]),
        s6: this.formBuilder.array([]),
        s7: this.formBuilder.array([]),
        s8: this.formBuilder.array([]),
        s9: this.formBuilder.array([]),
        s10: this.formBuilder.array([]),
        s11: this.formBuilder.array([]),
        s12: this.formBuilder.array([]),
      }),

      createdAt: [''],

      linked: [],

      notes_fdq_a: [''],
      notes_fdq_b: [''],
      notes_fdq_c: [''],
      notes_fdq_d: [''],
      notes_fdq_e: [''],
      notes_fdq_f: [''],
      notes_fdq_g: [''],
      notes_fdq_h: [''],
      notes_fdq_i: [''],
      notes_fdq_j: [''],
    });
  }

  setFdqValues(e: any) {
    this.allFdqValues = e;
  }

  async updateState() {
    if (this.userData?._id) {
      const updateStateRes = await this.restApiService.service('notes-saver').create({components: {}, userId: this.userData._id});
      console.log('updateStateRes?.updatedUserState', updateStateRes?.updatedUserState);
    }
  }
}
