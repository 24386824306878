
<ng-container>

    <!-- <div class="centered-row between">
        <div style="display: flex; flex-wrap: wrap; align-items: center; margin: 30px 0 15px;">
            <h2 class="nowrap-text m-0 m-b-10 m-l-20">{{ mealTitle }}</h2>
            <div *ngIf="lastChosenHour && allMealsCount" class="nowrap-text mat-body text-dark m-l-10">
                <span>{{'food_diary.MEAL_TIME_TITLE' | translate}} </span>
                <span class="font-bold">{{ lastChosenHour }}</span>
            </div>
        </div>
        <div *ngIf="!nutritionistFormMode">
            <button (click)="addMealItem(mealName, 'MEAL', mealTitle, true)" type="button" mat-mini-fab color="accent" [disabled]="isLocked">
                <mat-icon>add_a_photo</mat-icon>
            </button>
        </div>
    </div> -->
    <!-- <div>
        <h2 class="nowrap-text m-t-30 m-b-15">{{ mealTitle }}</h2>
    </div> -->
    <div class="centered-row between">
        <div class="d-flex align-items-center">
            <h2 class="nowrap-text m-t-15 m-b-15">{{ mealTitle }}</h2>
            <div *ngIf="lastChosenHour && allMealsCount" class="nowrap-text mat-body text-dark m-r-10 m-l-10">
                <span>{{'food_diary.MEAL_TIME_TITLE' | translate}} </span>
                <span class="font-bold">{{ lastChosenHour }}</span>
            </div>
        </div>
    </div>

    <div class="nut-chips-container" *ngIf="allCalculatedNut" [hidden]="!allMealsCount">
        <div class="note-star text-warning">*</div>
        <mat-chip-list aria-label="Nutrition values">
            <ng-container *ngFor="let key of nutArray">
                <mat-chip color="primary" selected>
                    <span>{{nutNames[key]}}</span>
                    <span class="bubble">{{allCalculatedNut[key]}}</span>
                </mat-chip>
            </ng-container>
        </mat-chip-list>
    </div>

    <div class="meals-loader-container" *ngIf="tableIsLoading || (allCalculatedNut && allMealsCount === undefined)">
        <div class="spinner no-margin">
            <div class="double-bounce1"></div>
            <div class="double-bounce2"></div>
        </div>
    </div>

    <div *ngIf="columns.length && allMealsCount" fxLayout="row" fxLayoutWrap="wrap" fxLayoutAlign="center none">
        <!-- <div [hidden]="!allMealsCount"> -->
        <div>
            <mat-card>
                <mat-card-content>
                    <!-- <vzn-data-table #tableEl [hidePaginator]="true" [restService]="restService" [pageSize]="25" [columns]="columns" [query]="query" (loading)="loadingTable($event)">
                    </vzn-data-table> -->
                    <vzn-table #vznTable [hidePaginator]="true" [restService]="restService" [columns]="columns" [rows]="allMealsFiltered" [tablePage]="{ totalElements: allMealsFiltered.length }"></vzn-table>
                </mat-card-content>
            </mat-card>
        </div>
    </div>

    <!-- <div class="buttons-row" *ngIf="!nutritionistFormMode">
        <button (click)="addMealItem(mealName, 'MEAL', mealTitle)" type="button" mat-mini-fab color="primary" [disabled]="isLocked">
            <mat-icon>add</mat-icon>
        </button>
        <button (click)="addMealItem(mealName, 'WATER', mealTitle)" type="button" mat-stroked-button color="primary" [disabled]="isLocked">
            {{'food_diary.ADD_WATER_BUTTON' | translate}} <mat-icon>local_drink</mat-icon>
        </button>
    </div> -->
    <div class="d-flex align-items-center m-t-15" *ngIf="!nutritionistFormMode">
        <button (click)="showHideButtons()" type="button" mat-mini-fab color="primary" [disabled]="isLocked">
            <mat-icon>add</mat-icon>
        </button>
        <div class="d-column m-r-15 m-l-15" [ngStyle]="{ 'visibility': showButtonsFlag ? 'visible' : 'hidden' }">
            <button (click)="addMealItem(mealName, 'MEAL', mealTitle)" type="button" mat-stroked-button color="primary" [disabled]="isLocked">
                {{'food_diary.ADD_FOOD_BUTTON' | translate}} <mat-icon>restaurant</mat-icon>
            </button>
            <div class="m-b-10"></div>
            <button (click)="addMealItem(mealName, 'WATER', mealTitle)" type="button" mat-stroked-button color="primary" [disabled]="isLocked">
                {{'food_diary.ADD_WATER_BUTTON' | translate}} <mat-icon>local_drink</mat-icon>
            </button>
        </div>
    </div>
     
    <!-- <ng-template #kcalAmountTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
        <span *ngIf="value && row.fdq_day_info_weight_or_volume && row.fdq_day_info_portion">
            {{ calcKcal(value, row.fdq_day_info_weight_or_volume, row.fdq_day_info_portion) }}
        </span>
    </ng-template>  -->

    <ng-template #foodAmountTmpl let-row="row" let-value="value" ngx-datatable-cell-template>
        <span>
            {{ value && value.substring(0,10) === 'כמות מדודה' ? (row.fdq_day_info_weight_or_volume + ' ' + row.fdq_day_info_units) : value }}
        </span>
    </ng-template> 
    
    <ng-template #imageClickableTmpl let-value="value" let-height="column.height" ngx-datatable-cell-template>
        <div *ngIf="value && value[0]" style="display: flex;justify-content: center;overflow: hidden;" [ngStyle]="{'height': height?height+'px':'50px'}">
            <img class="pointer" style="height: 100%" [src]="value[0]['url']" (click)="openImageDialog(value[0]['url'])" />
        </div>
    </ng-template>

    <!-- <ng-template #imageClickableTmpl let-value="value" let-height="column.height" ngx-datatable-cell-template>
        <div style="display: flex;justify-content: center;overflow: hidden;" [ngStyle]="{'height': height?height+'px':'50px'}">
            <img class="pointer" style="height: 100%" [src]="value" (click)="openImageDialog(value)" />
        </div>
    </ng-template> -->

    <ng-template #mealDialogTemplate let-context="context" let-form="form">

        <div class="m-b-20 m-0 centered-row between">

            <button type="button" mat-icon-button (click)="goDone(form)" class="meal-dialog-float-button done" [disabled]="form.invalid">
                <mat-icon>check</mat-icon>
            </button>

            <h3 class="m-0">
                {{ form.value.extra_field_form_title }}
            </h3>

            <button *ngIf="form.value.fdq_day_info_item_name && isMinor && form.value.fdq_day_info_item_type === 'MEAL'" type="button" mat-stroked-button color="primary" (click)="goBack(form)" class="meal-dialog-float-button back">
                <span class="font-12">
                    {{'food_diary.MEAL_SEARCH_FOOD' | translate}}
                </span>
            </button>
            <button *ngIf="!(form.value.fdq_day_info_item_name && isMinor && form.value.fdq_day_info_item_type === 'MEAL')" type="button" mat-icon-button (click)="goBack(form)" class="meal-dialog-float-button back">
                <mat-icon>redo</mat-icon>
            </button>
            
        </div>

        <div #topline style="height: 1px;"></div>

        <form novalidate class="basic-form mat-body" [formGroup]="form">

            <ng-container *ngIf="!form.value.fdq_day_info_item_name">
                <div class="centered-row m-0">
                    <mat-form-field class="" [floatLabel]="'never'">
                        <input type="text" placeholder="{{'food_diary.MEAL_SEARCH' | translate}}" matInput formControlName="extra_field_meal_search">
                    </mat-form-field>
                    <ng-container *ngIf="!mealSearched">
                        <div *ngIf="form.value.extra_field_meal_search.length > 0" matSuffix class="m-r-5 m-l-5">
                            <button type="button" (click)="searchFoodServer(form)" mat-stroked-button color="primary">
                                {{'food_diary.MEAL_SEARCH_FOOD' | translate}}
                            </button>
                        </div>
                        <div *ngIf="form.value.extra_field_meal_search.length === 0" (click)="showScanner(form)" class="barcode-container pointer">
                            <span class="barcode-span"></span>
                        </div>
                    </ng-container>
                    <div *ngIf="mealSearched" class="m-r-5 m-l-5">
                        <button type="button" (click)="addCustomItem(form)" mat-stroked-button color="primary" [disabled]="form.value.extra_field_meal_search.length === 0 || searchInProgress">
                            {{'food_diary.MEAL_CUSTOM' | translate}}
                        </button>
                    </div>
                </div>

                <div *ngIf="mealSearched && searchInProgress" class="meals-loader-container bg">
                    <div class="spinner no-margin">
                        <div class="double-bounce1"></div>
                        <div class="double-bounce2"></div>
                    </div>
                </div>

                <ng-container *ngIf="mealSearched && serverMealsBySearch && serverMealsBySearch.length > 0">
                    <div class="last-items-container">
                        <div class="repeat-container w65 p-b-0 m-b-0 m-auto"></div>
                        <div class="repeat-container w65 p-b-0 m-b-0 m-auto" *ngFor="let item of serverMealsBySearch;">
                            <div class="centered-row m-0">
                                <div class="last-items-img">
                                    <img *ngIf="(item | mediaAssets).url" [src]="(item | mediaAssets).url" />
                                </div>
                                <div class="full-width">
                                    <div class="m-b-5 m-t-5">
                                        <div class="font-bold">{{ item.title || '' }}</div>
                                    </div>
                                    <!-- <div class="row m-0">
                                        <div class="">
                                            <span>{{ item.fdq_day_info_portion }} </span>
                                            <span *ngIf='!(item.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || item.fdq_day_info_quantity == "כמות מדודה (גרם)")'>{{ item.fdq_day_info_quantity }} </span>
                                            <span *ngIf='item.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || item.fdq_day_info_quantity == "כמות מדודה (גרם)"'>
                                                כמות מדודה 
                                                ({{ item.fdq_day_info_weight_or_volume }} {{item.fdq_day_info_units }})
                                            </span>
                                        </div>
                                        <div *ngIf="item.fdq_day_info_item_calories" class="">
                                            <span class="font-bold">{{ item.fdq_day_info_item_calories }} </span>
                                            <span>{{'food_diary.MEAL_CALORIES' | translate}} </span>
                                        </div>
                                    </div> -->
                                </div>
                                <div class="m-r-10">
                                    <button mat-icon-button type="button" (click)="addFromServer(form, item)">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <ng-container *ngIf="!mealSearched && allLastItemsFiltered && allLastItemsFiltered.length > 0">
                    <div class="centered-row mb125">
                        <span class="text-center text-info font-16">{{ 'food_diary.MEAL_LAST_ADDED_ITEMS' | translate }}</span>
                    </div>

                    <div class="last-items-container" [hidden]="!openLastItems">
                        <div class="repeat-container w65 p-b-0 m-b-0 m-auto"></div>
                        <div class="repeat-container w65 p-b-10 m-b-0 m-auto" *ngFor="let item of lastMealsBySearch; let i = index; let last = last;">
                            <div class="centered-row m-0">
                                <div *ngIf="item.fdq_day_info_assets && item.fdq_day_info_assets[0]" class="last-items-img">
                                    <img [src]="item.fdq_day_info_assets[0]['url']" />
                                </div>
                                <div class="full-width">
                                    <div class="m-b-5 m-t-5">
                                        <div class="font-italic font-bold">{{ item.fdq_day_info_item_name }}</div>
                                    </div>
                                    <div *ngIf="item.fdq_day_info_portion && item.fdq_day_info_quantity" class="row m-0">
                                        <div class="">
                                            <span>{{ item.fdq_day_info_portion }} </span>
                                            <!-- <span>{{ item.fdq_day_info_quantity }} </span> -->
                                            <span *ngIf='!(item.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || item.fdq_day_info_quantity == "כמות מדודה (גרם)")'>{{ item.fdq_day_info_quantity }} </span>
                                            <span *ngIf='item.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || item.fdq_day_info_quantity == "כמות מדודה (גרם)"'>
                                                כמות מדודה 
                                                ({{ item.fdq_day_info_weight_or_volume }} {{item.fdq_day_info_units }})
                                            </span>
                                        </div>
                                        <div *ngIf="item.fdq_day_info_item_calories" class="">
                                            <span class="font-bold">{{ item.fdq_day_info_item_calories }} </span>
                                            <span>{{'food_diary.MEAL_CALORIES' | translate}} </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-r-10">
                                    <button mat-icon-button type="button" (click)="cloneItemServer(form, item)">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </ng-container>

            <!-- <div class="centered-row m-0 m-b-15" *ngIf="false && !form.value.fdq_day_info_item_name">
                <mat-form-field class="" [floatLabel]="'never'">
                    <input type="text" placeholder="{{'food_diary.MEAL_CHOOSE' | translate}}" matInput formControlName="extra_field_input_items" [matAutocomplete]="itemsAutoComplete">
                    <mat-autocomplete class="meal-ac" #itemsAutoComplete="matAutocomplete" [displayWith]="displayFnItems">
                        <mat-option *ngFor="let option of form.value.extra_field_items_options | async" [value]="option">
                        {{option.title}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>

                <div>
                    <div>
                        <button class="m-r-20" [disabled]="!(form.value.extra_field_input_items && form.value.extra_field_input_items._id)" (click)="chooseAcItem(form)" type="button" mat-raised-button color="primary" mat-button>
                            {{'food_diary.MEAL_SELECT' | translate}} <mat-icon>add_circle_outline</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="!isMinor">
                        <button class="m-r-20 m-t-10" [disabled]="(form.value.extra_field_input_items && form.value.extra_field_input_items._id)" (click)="chooseCustomItem(form)" type="button" mat-stroked-button color="primary" mat-button>
                            {{'food_diary.MEAL_CUSTOM' | translate}}
                        </button>
                    </div>
                    <div *ngIf="isMinor">
                        <button class="m-r-20 m-t-10" [disabled]="(form.value.extra_field_input_items._id)" (click)="chooseCustomItem(form)" type="button" mat-raised-button color="accent" mat-button>
                            {{'food_diary.MEAL_CUSTOM_MINOR' | translate}}
                        </button>
                    </div>
                </div>
            </div> -->

            <ng-container *ngIf="form.value.fdq_day_info_item_name">

                <div *ngIf="isMinor && form.value.fdq_day_info_item_type === 'MEAL'" class="p-x-12 m-0 mb125 centered-row between">
                    <mat-form-field class="w70">
                        <mat-select formControlName="fdq_day_info_time" placeholder="{{ 'food_diary.MEAL_TIME' | translate }}">
                            <mat-option *ngFor="let time of allHours" [value]="time">{{ time }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="w27 text-center">
                        <div (click)="showScanner(form)" class="barcode-container pointer">
                            <span class="barcode-span"></span>
                        </div>
                    </div>
                </div>
                <mat-form-field *ngIf="!(isMinor && form.value.fdq_day_info_item_type === 'MEAL')" class="mb125 p-x-12">
                    <mat-select formControlName="fdq_day_info_time" placeholder="{{ 'food_diary.MEAL_TIME' | translate }}">
                        <mat-option *ngFor="let time of allHours" [value]="time">{{ time }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="m-b-15">
                    <div class="font-16 font-bold m-b-15">
                        {{ form.value.fdq_day_info_item_type === 'MEAL' ? mealTitle : form.value.fdq_day_info_item_name }}
                    </div>
                    
                    <div *ngIf="form.value.fdq_day_info_item_type === 'FOOD' || form.value.fdq_day_info_item_type === 'DRINK'" style="display: flex; justify-content: flex-end;">
                        <div *ngIf="form.value.fdq_day_info_assets && form.value.fdq_day_info_assets[0]" class="">
                            <img style="max-height: 120px; width: auto;" [src]="form.value.fdq_day_info_assets[0]['url']" />
                        </div>
                    </div>
                    <div *ngIf="form.value.fdq_day_info_item_type === 'MEAL'" style="display: flex; justify-content: flex-end;">
                        <vzn-media-files
                            [uploadFolder]="uploadFolderBase + '/' + mealName + '_'"
                            [width]="'auto'"
                            [height]="'120px'"
                            [assetMargin]="'5px'"
                            [uploadUrlService]="uploadUrlService"
                            [formArrayName]="'fdq_day_info_assets'"
                            [acceptedFiles]="'image/jpeg, image/x-png, image/png, image/webp'"
                            [limitToOne]="true"
                            [disabled]="nutritionistFormMode || isLocked"
                            [addPhotoIconName]="'add_a_photo'"
                            (onAssetClick)="openImageDialog($event.url)"  
                        >
                        </vzn-media-files>
                    </div>
                    
                </div>

                <div class="mat-error custom-error mb75" *ngIf="form.errors">
                    {{ form.errors.msg }}
                </div>

                <mat-form-field *ngIf="form.value.fdq_day_info_item_type === 'MEAL'" class="p-x-12">
                    <input formControlName="fdq_day_info_item_description" matInput placeholder="{{'food_diary.MEAL_DESC' | translate}}">
                </mat-form-field>
                
                <mat-form-field *ngIf="form.value.fdq_day_info_item_type === 'FOOD' || form.value.fdq_day_info_item_type === 'MEAL'" class="p-x-12">
                    <mat-select formControlName="fdq_day_info_quantity" placeholder="{{'food_diary.F_QUANTITY_Q' | translate}}">

                        <ng-container *ngIf="form.value.fdq_day_info_item_real_barcode">
                            <ng-container *ngFor="let mida of ((form.value.fdq_day_info_item_nut_midas && form.value.fdq_day_info_item_nut_midas.food_energy) || [])">
                                <mat-option *ngIf="mida && mida.sum" [value]="mida.title">
                                    <span *ngIf="!(mida.title.includes('ל-100 גרם') || mida.title.includes('ל-100 מל'))">{{ mida.title }} </span>
                                    ({{mida.sum}} {{"food_diary.F_WEIGHT_VALUE" | translate}})
                                </mat-option>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!form.value.fdq_day_info_item_real_barcode">
                            <ng-container *ngFor="let mida of ((form.value.fdq_day_info_item_nut_midas && form.value.fdq_day_info_item_nut_midas.food_energy) || [])">
                                <mat-option [value]="mida.title">
                                    <span>{{ mida.title }} </span>
                                    <span *ngIf="mida.sum != 100">({{mida.sum}} {{"food_diary.F_WEIGHT_VALUE" | translate}})</span>
                                </mat-option>
                            </ng-container>
                        </ng-container>
                        
                        <mat-option value="כמות מדודה (גרם)">{{'food_diary.F_QUANTITY_A_12' | translate}}</mat-option>
                        <ng-container *ngIf="form.value.fdq_day_info_item_type === 'MEAL'">
                            <mat-option value="יחידה קטנה">{{'food_diary.F_QUANTITY_A_1' | translate}}</mat-option>
                            <mat-option value="יחידה בינונית">{{'food_diary.F_QUANTITY_A_2' | translate}}</mat-option>
                            <mat-option value="יחידה גדולה">{{'food_diary.F_QUANTITY_A_3' | translate}}</mat-option>
                            <mat-option value="כפית">{{'food_diary.F_QUANTITY_A_4' | translate}}</mat-option>
                            <mat-option value="כף">{{'food_diary.F_QUANTITY_A_5' | translate}}</mat-option>
                            <mat-option value="כוס">{{'food_diary.F_QUANTITY_A_6' | translate}}</mat-option>
                            <mat-option value="קערה קטנה">{{'food_diary.F_QUANTITY_A_13' | translate}}</mat-option>
                            <mat-option value="קערה בינונית">{{'food_diary.F_QUANTITY_A_14' | translate}}</mat-option>
                            <mat-option value="קערה גדולה">{{'food_diary.F_QUANTITY_A_15' | translate}}</mat-option>
                            <mat-option value="פרוסה">{{'food_diary.F_QUANTITY_A_7' | translate}}</mat-option>
                            <mat-option value="מנה קטנה">{{'food_diary.F_QUANTITY_A_8' | translate}}</mat-option>
                            <mat-option value="מנה בינונית">{{'food_diary.F_QUANTITY_A_9' | translate}}</mat-option>
                            <mat-option value="מנה גדולה">{{'food_diary.F_QUANTITY_A_10' | translate}}</mat-option>
                            <mat-option value="חבילה">{{'food_diary.F_QUANTITY_A_11' | translate}}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf="form.value.fdq_day_info_item_type === 'DRINK'" class="p-x-12">
                    <mat-select formControlName="fdq_day_info_quantity" placeholder="{{'food_diary.D_QUANTITY_Q' | translate}}">

                        <ng-container *ngIf="form.value.fdq_day_info_item_real_barcode">
                            <ng-container *ngFor="let mida of ((form.value.fdq_day_info_item_nut_midas && form.value.fdq_day_info_item_nut_midas.food_energy) || [])">
                                <mat-option *ngIf="mida && mida.sum" [value]="mida.title">
                                    <span *ngIf="!(mida.title.includes('ל-100 גרם') || mida.title.includes('ל-100 מל'))">{{ mida.title }} </span>
                                    ({{mida.sum}} {{"food_diary.D_VOLUME_VALUE" | translate}})
                                </mat-option>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="!form.value.fdq_day_info_item_real_barcode">
                            <ng-container *ngFor="let mida of ((form.value.fdq_day_info_item_nut_midas && form.value.fdq_day_info_item_nut_midas.food_energy) || [])">
                                <mat-option [value]="mida.title">
                                    <span>{{ mida.title }} </span>
                                    <span *ngIf="mida.sum != 100">({{mida.sum}} {{"food_diary.D_VOLUME_VALUE" | translate}})</span>
                                </mat-option>
                            </ng-container>
                        </ng-container>

                        <!-- <mat-option value='כוס (200 מ"ל)'>{{'food_diary.D_QUANTITY_A_1' | translate}}</mat-option> -->
                        <!-- <mat-option value='פחית (333 מ"ל)'>{{'food_diary.D_QUANTITY_A_2' | translate}}</mat-option> -->
                        <!-- <mat-option value='בקבוק (500 מ"ל)'>{{'food_diary.D_QUANTITY_A_3' | translate}}</mat-option> -->
                        <mat-option value='כמות מדודה (מ"ל)'>{{'food_diary.D_QUANTITY_A_4' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field *ngIf='form.value.fdq_day_info_quantity === "כמות מדודה (גרם)"' class=" p-x-12">
                    <input type="text" digitOnly inputmode="numeric" pattern="[0-9]*" min="0" maxlength="6" formControlName="fdq_day_info_weight_or_volume" matInput placeholder='{{"food_diary.F_WEIGHT_VALUE" | translate}}'>
                </mat-form-field>
                <mat-form-field *ngIf='form.value.fdq_day_info_quantity === "כמות מדודה (מ\"ל)"' class=" p-x-12">
                    <input type="text" digitOnly inputmode="numeric" pattern="[0-9]*" min="0" maxlength="6" formControlName="fdq_day_info_weight_or_volume" matInput placeholder='{{"food_diary.D_VOLUME_VALUE" | translate}}'>
                </mat-form-field>

                <mat-form-field class="p-x-12">
                    <mat-select formControlName="fdq_day_info_portion" [placeholder]="form.value.fdq_day_info_item_type === 'FOOD' ? ('food_diary.F_PORTION_NO' | translate) : ('food_diary.D_PORTION_NO' | translate)">
                        <mat-option value="¼">{{'food_diary.F_PORTION_NO_A_0' | translate}}</mat-option>
                        <mat-option value="½">{{'food_diary.F_PORTION_NO_A_1' | translate}}</mat-option>
                        <mat-option value="1">{{'food_diary.F_PORTION_NO_A_2' | translate}}</mat-option>
                        <mat-option value="1½">{{'food_diary.F_PORTION_NO_A_3' | translate}}</mat-option>
                        <mat-option value="2">{{'food_diary.F_PORTION_NO_A_4' | translate}}</mat-option>
                        <mat-option value="2½">{{'food_diary.F_PORTION_NO_A_5' | translate}}</mat-option>
                        <mat-option value="3">{{'food_diary.F_PORTION_NO_A_6' | translate}}</mat-option>
                        <mat-option value="3½">{{'food_diary.F_PORTION_NO_A_7' | translate}}</mat-option>
                        <mat-option value="4">{{'food_diary.F_PORTION_NO_A_8' | translate}}</mat-option>
                        <mat-option value="4½">{{'food_diary.F_PORTION_NO_A_9' | translate}}</mat-option>
                        <mat-option value="5">{{'food_diary.F_PORTION_NO_A_10' | translate}}</mat-option>
                        <ng-container *ngFor="let num of [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]">
                            <mat-option [value]="num">{{ num }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <div class="nut-chips-container" *ngIf="inMealNut">
                    <mat-chip-list aria-label="Nutrition values">
                        <ng-container *ngFor="let key of nutArray">
                            <mat-chip color="primary" selected>
                                <span>{{nutNames[key]}}</span>
                                <span class="bubble">{{inMealNut[key]}}</span>
                            </mat-chip>
                        </ng-container>
                    </mat-chip-list>
                </div>

                <div class="text-center" *ngIf="inMealExtraNut">
                    <button mat-icon-button type="button" (click)="openExtra = false" *ngIf="openExtra">
                        <mat-icon>expand_less</mat-icon>
                    </button>
                    <button mat-icon-button type="button" (click)="openExtra = true" *ngIf="!openExtra">
                        <mat-icon>expand_more</mat-icon>
                    </button>
                </div>
                <div class="nut-stacked" *ngIf="inMealExtraNut && openExtra">
                    <div class="nut-chips-container stacked">
                        <mat-chip-list class="mat-chip-list-stacked" aria-label="Nutrition extra values">
                            <ng-container *ngFor="let key of nutExtraArray">
                                <mat-chip selected>
                                    <span>{{nutExtraNames[key]}}</span>
                                    <span class="bubble">{{inMealExtraNut[key]}}</span>
                                </mat-chip>
                            </ng-container>
                        </mat-chip-list>
                    </div>
                </div>

                <div *ngIf="form.value.fdq_day_info_item_real_barcode" class="mt125 mb75 font-12 font-italic mat-body text-warning">{{ 'food_diary.EXTRA_NOTE_PRODUCTS' | translate }}</div>

                <ng-container *ngIf="form.value.fdq_day_info_item_type === 'MEAL' && allLastItemsFilteredCustom && allLastItemsFilteredCustom.length > 0">
                    <div class="centered-row mb125 mt125">
                        <span class="text-center text-info font-16">{{ 'food_diary.MEAL_LAST_ADDED_ITEMS' | translate }}</span>
                    </div>

                    <div class="last-items-container" [hidden]="!openLastItems">
                        <div class="repeat-container w65 p-b-0 m-b-0 m-auto"></div>
                        <div class="repeat-container w65 p-b-10 m-b-0 m-auto" *ngFor="let item of allLastItemsFilteredCustom; let i = index; let last = last;">
                            <div class="centered-row m-0">
                                <div *ngIf="item.fdq_day_info_assets && item.fdq_day_info_assets[0]" class="last-items-img">
                                    <img [src]="item.fdq_day_info_assets[0]['url']" />
                                </div>
                                <div class="full-width">
                                    <div class="m-b-10">
                                        <div class="font-italic font-bold">{{ item.fdq_day_info_item_name }}</div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="">
                                            <span>{{ item.fdq_day_info_portion }} </span>
                                            <span *ngIf='!(item.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || item.fdq_day_info_quantity == "כמות מדודה (גרם)")'>{{ item.fdq_day_info_quantity }} </span>
                                            <span *ngIf='item.fdq_day_info_quantity == "כמות מדודה (מ\"ל)" || item.fdq_day_info_quantity == "כמות מדודה (גרם)"'>
                                                כמות מדודה 
                                                ({{ item.fdq_day_info_weight_or_volume }} {{item.fdq_day_info_units }})
                                            </span>
                                        </div>
                                        <div *ngIf="item.fdq_day_info_item_calories" class="">
                                            <span class="font-bold">{{ item.fdq_day_info_item_calories }} </span>
                                            <span>{{'food_diary.MEAL_CALORIES' | translate}} </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="m-r-10">
                                    <button mat-icon-button type="button" (click)="cloneItemCustom(form, item)">
                                        <mat-icon>add</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>

            </ng-container>

        </form>
    </ng-template>

    <ng-template #waterDialogTemplate let-context="context" let-form="form">

        <div class="m-b-20 m-0 centered-row between">

            <button type="button" mat-icon-button (click)="goDoneWater()" class="meal-dialog-float-button done" [disabled]="form.invalid">
                <mat-icon>check</mat-icon>
            </button>
            
            <h3 class="m-0">
                {{ form.value.extra_field_form_title }}
            </h3>

            <button type="button" mat-icon-button (click)="goBackWater()" class="meal-dialog-float-button back">
                <mat-icon>redo</mat-icon>
            </button>

        </div>

        <div mat-dialog-content>
            <form novalidate class="basic-form" [formGroup]="form">

                <mat-form-field class="mb125 p-x-12">
                    <mat-select formControlName="fdq_day_info_time" placeholder="{{ 'food_diary.DRINK_TIME' | translate }}">
                        <mat-option *ngFor="let time of allHours" [value]="time">{{ time }}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="p-x-12">
                    <mat-select formControlName="fdq_day_info_quantity" placeholder="{{'food_diary.D_QUANTITY_WATER' | translate}}">
                        <mat-option value='כוס (200 מ"ל)'>{{'food_diary.D_QUANTITY_A_1' | translate}}</mat-option>
                        <mat-option value='בקבוק (500 מ"ל)'>{{'food_diary.D_QUANTITY_A_3' | translate}}</mat-option>
                        <mat-option value='כמות מדודה (מ"ל)'>{{'food_diary.D_QUANTITY_A_4' | translate}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field
                    *ngIf='form.value.fdq_day_info_quantity =="כמות מדודה (מ\"ל)"'
                    class="p-x-12">
                    <input type="text" digitOnly inputmode="numeric" pattern="[0-9]*" min="0" maxlength="6" formControlName="fdq_day_info_weight_or_volume" matInput placeholder='{{"food_diary.D_VOLUME_VALUE" | translate}}'>
                </mat-form-field>

                <mat-form-field class="p-x-12">
                    <mat-select formControlName="fdq_day_info_portion" placeholder='{{"food_diary.D_PORTION_NO" | translate}}'>
                        <mat-option value="¼">{{'food_diary.F_PORTION_NO_A_0' | translate}}</mat-option>
                        <mat-option value="½">{{'food_diary.F_PORTION_NO_A_1' | translate}}</mat-option>
                        <mat-option value="1">{{'food_diary.F_PORTION_NO_A_2' | translate}}</mat-option>
                        <mat-option value="1½">{{'food_diary.F_PORTION_NO_A_3' | translate}}</mat-option>
                        <mat-option value="2">{{'food_diary.F_PORTION_NO_A_4' | translate}}</mat-option>
                        <mat-option value="2½">{{'food_diary.F_PORTION_NO_A_5' | translate}}</mat-option>
                        <mat-option value="3">{{'food_diary.F_PORTION_NO_A_6' | translate}}</mat-option>
                        <mat-option value="3½">{{'food_diary.F_PORTION_NO_A_7' | translate}}</mat-option>
                        <mat-option value="4">{{'food_diary.F_PORTION_NO_A_8' | translate}}</mat-option>
                        <mat-option value="4½">{{'food_diary.F_PORTION_NO_A_9' | translate}}</mat-option>
                        <mat-option value="5">{{'food_diary.F_PORTION_NO_A_10' | translate}}</mat-option>
                        <ng-container *ngFor="let num of [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]">
                            <mat-option [value]="num">{{ num }}</mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

            </form>
        </div>
    </ng-template>

</ng-container>
