import { Component, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { appState } from '@services/app-state';

import { FooddiaryquizDayComponent } from './fooddiaryquiz-day.component';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { MatCalendarCellCssClasses } from '@angular/material/datepicker';

import * as moment from 'moment-timezone';

@Component({
  selector: "food-diary-quiz",
  templateUrl: "./fooddiaryquiz.component.html"
})
export class FooddiaryquizComponent {

  @ViewChild(FooddiaryquizDayComponent) private fdqDay: FooddiaryquizDayComponent;

  appState = appState;

  userId: string;

  allQuizIds: string[] = [];
  allQuizDays: any[] = [];
  daysArr: any[] = [];

  dayData: any;
  
  busy: boolean = false;

  isLocked = false;

  chosenFormDate: any;
  // chosenFormId = '';
  lastUpdated = '';
  dateClass: any;

  minDate: Date;
  maxDate: Date;

  colorList = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080'];

  // get currentLang() {
  //   return this.translate.currentLang === 'en' ? 'en' : 'he';
  // }

  formOwnerUsr: any;
  userAge = 0;
  userGender = '';

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    public restApiService: RestApiService<any>,
    public translate: TranslateService,
    // private changeDetectorRef: ChangeDetectorRef
  ) {
    const year = new Date().getFullYear();
    this.minDate = new Date("01/01/2020");
    this.maxDate = new Date("12/31/" + (year+1));
  }

  async ngAfterViewInit() {
    this.route.params.subscribe(async params => {
      if (params.id) {
        this.busy = true;

        const usr = await this.restApiService.service('users').get(params.id).catch(e => { console.error(e) });
        if (!usr) {
          this.router.navigate(['/'], { replaceUrl: true });
          return;
        }

        // this.formOwnerUsr = usr;
        this.userAge = +(usr['usr_age_formatted'] || 0);
        this.userGender = usr.usr_gender;

        if (this.appState.user.usr_type === 'parent' || this.appState.user.usr_type === 'nutri') {
          this.isLocked = true;
        }
        if (this.appState.user.usr_type === 'superadmin' || this.appState.user.usr_type === 'nutri') {
          this.appState.quizUser = usr;
        }

        this.userId = usr._id;

        // await this.reloadAllQuizAndDays(params.id);
        await this.reloadAllQuizAndDays(this.userId);
        this.formOwnerUsr = usr;
        await this.findDayIdByDate(moment(), this.userId);

        // this.userId = usr._id;
        this.busy = false;

      } else {
        let navTo = '/';
        if (this.appState.user.usr_type === 'player') {
          navTo = `Fooddiaryquiz/${this.appState.user._id}`
        }
        this.router.navigate([navTo], { replaceUrl: true });
      }
    });
      
    this.dateClass = (d: moment.Moment): MatCalendarCellCssClasses => {
      const days = this.allQuizDays || [];
      // const date = moment(d).utcOffset(3).milliseconds(0).second(0).minute(0).hour(0).toISOString(true);
      // const date = moment(d).milliseconds(0).second(0).minute(0).hour(0).toISOString(true);
      
      const date = moment(d).format('DD/MM/YYYY');
      const day = days.find(day => day.date == date);
      return day ? `datepicker-filled-date color-${day.colorIndex}` : (this.appState.user.usr_type !== 'superadmin' && this.appState.user.usr_type !== 'player' ? 'disabled block-events' : '');
      // return (days.includes(date)) ? 'datepicker-filled-date' : '';
    }

  }

  // async ngAfterViewInit() {
  //   if (this.userId) {
  //     await this.findDayIdByDate(moment(), this.userId);
  //   }
  // }

  async reloadAllQuizAndDays(userId: string) {
    // this.busy = true;

    let quizzes = await this.restApiService.service("fooddiaryquiz").find({
      query: {
        $paginate: false,
        $select: ['_id', 'fdq_locked'],
        fdq_usr_id: userId,
        $sort: {
          createdAt: -1
        },
        fdq_is_clone:{
            $ne: true
        },
        createdAt: {
          $gt: 1595397916274
        }
      }
    });

    if (!quizzes || !quizzes.length) {
      this.allQuizIds = [];
      this.allQuizDays = [];
      this.daysArr = [];
      this.dayData = undefined;
      this.lastUpdated = '';
      // this.busy = false;

      return;
    }
    
    const colorList = this.colorList;
    let colorIdx = 0;
    for (let quiz of quizzes) {
      quiz.color = colorList[colorIdx];
      quiz.colorIndex = colorIdx;
      colorIdx++;
      if (colorIdx > 19) {
        colorIdx = 0;
      }
    }
    const mapped: string[] = quizzes.map(quiz => quiz._id.toString());

    const days = await this.restApiService.service("fooddiaryquiz-day").find({
      query: {
        $paginate: false,
        // $select: ['_id', 'fdq_day_fdq_id', 'fdq_day_date_taken'],
        fdq_day_fdq_id: {
          $in: mapped
        },
        $sort: {
          // createdAt: -1,
          fdq_day_date_taken: -1
        },
        fdq_day_is_deleted: {
          $ne: true
        }
      }
    });
    const daysRes = days.filter(day => day.fdq_day_date_taken);

    // let daysDates: string[] = [];

    for (let day of daysRes) {
      // let dayMoment: moment.Moment = moment(day.fdq_day_date_taken).utcOffset(3).milliseconds(0).second(0).minute(0).hour(0);
      // let dayMoment: moment.Moment = moment(day.fdq_day_date_taken).milliseconds(0).second(0).minute(0).hour(0);
      // daysDates.push(dayMoment.toISOString(true));
      const quiz = quizzes.find(quiz => quiz._id === day.fdq_day_fdq_id);
      // day.date = dayMoment.toISOString(true);
      day.date = moment(day.fdq_day_date_taken).format('DD/MM/YYYY');
      day.isLocked = quiz?.fdq_locked;
      day.color = quiz?.color;
      day.colorIndex = quiz?.colorIndex;
    }
    
    console.log(daysRes)

    this.allQuizIds = mapped;
    this.allQuizDays = daysRes.filter(day => day.fdq_day_form_is_real);
    this.daysArr = daysRes;
    // this.busy = false;

    console.log(this.allQuizDays)
  }

  async dayFunc(date, userId: string) {
    this.busy = true;
    // this.chosenFormId = '';
    try {
        
      // const startDate = moment(date).utcOffset(3).milliseconds(0).second(0).minute(0).hour(0);
      const startDate = moment(date).milliseconds(0).second(0).minute(0).hour(0);
      const endDate = moment(startDate).add(1, 'd');

      this.chosenFormDate = startDate;

      // const res = await this.restApiService.service("fooddiaryquiz-day").find({
      //   query: {
      //     $paginate: false,
      //     $limit: 1,
      //     // fdq_day_fdq_id: this.quizId,
      //     fdq_day_fdq_id: {
      //       $in: this.allQuizIds
      //     },
      //     $sort: {
      //       fdq_day_date_taken: -1,
      //       createdAt: -1
      //     },
      //     fdq_day_date_taken: {
      //       $gte: startDate.toISOString(),
      //       $lt: endDate.toISOString(),
      //     },
      //     fdq_day_is_deleted: {
      //       $ne: true
      //     }
      //   }
      // });

      const res = this.daysArr.find((quizDay: any) => moment(quizDay.fdq_day_date_taken).isBetween(startDate, endDate, undefined, '[)'));

      // let formId = '';
      if (res) {
        // console.log('res', res);
        // this.chosenFormId = res._id;
        // formId = res._id;
        this.dayData = res;
        const lang = this.translate.currentLang === 'en' ? 'en' : 'he';
        this.lastUpdated = res?.fdq_day_form_is_real ? moment(res.updatedAt).locale(lang).calendar() : '';
      } else {
        if (!this.isLocked) {
          // console.log(startDate.toISOString());
          const data = {
            fdq_day_form_is_real: false,
            fdq_day_usr_id: userId,
            // fdq_day_fdq_id: this.quizId,
            fdq_day_date_taken: startDate.toISOString(),
          };
          const resCreate = await this.restApiService.service("fooddiaryquiz-day").create(data);
          if (resCreate && resCreate._id) {
            // this.chosenFormId = resCreate._id;
            // formId = resCreate._id;
            this.lastUpdated = '';
            this.dayData = resCreate;
            await this.newDay(resCreate);
          }
        } else {
          this.lastUpdated = '';
          this.dayData = undefined;
        }
      }
      // this.busy = false;
      setTimeout(async () => {
        if (this.fdqDay) {
          console.log('refresh')
          // console.log('fdqDay.dayData', this.fdqDay.dayData)
          await this.fdqDay.refresh();
        } else {
          this.busy = false;
        }
      }, 200);

      // if (res && res.length > 0) {
      //   this.chosenFormId = res[0]._id;
      //   const lang = this.translate.currentLang === 'en' ? 'en' : 'he';
      //   this.lastUpdated = moment(res[0].updatedAt).locale(lang).calendar();
      // } else {
      //   let createNew = false;
      //   if (this.chosenFormId) {
      //     const resGet = await this.restApiService.service("fooddiaryquiz-day").get(this.chosenFormId);
      //     if (resGet && resGet.fdq_day_date_taken) {
      //       createNew = true;
      //     }
      //   }
      //   if (!this.chosenFormId || createNew) {
      //     const resEmpty = await this.restApiService.service("fooddiaryquiz-day").find({
      //       query: {
      //         $paginate: false,
      //         $limit: 1,
      //         $sort: {
      //           createdAt: -1
      //         },
      //         $select: ['_id', 'fdq_day_date_taken', 'fdq_day_fdq_id'],
      //         // fdq_day_fdq_id: this.quizId,
      //         fdq_day_form_is_real: false,
      //         fdq_day_usr_id: this.userId,
      //       }
      //     });
      //     if (resEmpty && resEmpty[0] && !resEmpty[0].fdq_day_date_taken && !resEmpty[0].fdq_day_fdq_id) {
      //       this.chosenFormId = resEmpty[0]._id;
      //       this.lastUpdated = '';
      //     } else {
      //       const data = {
      //         fdq_day_form_is_real: false,
      //         fdq_day_usr_id: this.userId,
      //         // fdq_day_fdq_id: this.quizId,
      //         // fdq_day_date_taken: ,
      //       };
      //       const resCreate = await this.restApiService.service("fooddiaryquiz-day").create(data);
      //       if (resCreate && resCreate._id) {
      //         this.chosenFormId = resCreate._id;
      //         this.lastUpdated = '';

      //         // await this.newDay(resCreate);
      //       }
      //     }
      //   }
      // }

      // const newMoment = moment(date).utcOffset(3).milliseconds(0).second(0).minute(0).hour(0);
      // this.chosenFormDate = newMoment;
      
      // this.chosenFormDate = startDate;
      // this.busy = false;
      // this.chosenFormId = formId;

    } catch (e) {
      console.error(e);
      this.busy = false;
    }

  }

  async findDayIdByDate(dateChosen, userId: string) {
    const date = dateChosen; 
    if (!date) {
      console.log('no valid date');
      this.chosenFormDate = moment(this.chosenFormDate);
      return;
    }
    this.busy = true;
    // await this.reloadAllQuizAndDays(userId);
    await this.dayFunc(date, userId);
    // this.busy = false;
  }

  dayPrevious() {
    const date = moment(this.chosenFormDate);
    if (this.chosenFormDate && date && date.isValid) {
      this.findDayIdByDate(date.subtract(1,'d'), this.userId);
    }
  }

  dayNext() {
    const date = moment(this.chosenFormDate);
    if (this.chosenFormDate && date && date.isValid) {
      this.findDayIdByDate(date.add(1,'d'), this.userId);
    }
  }

  async newDay(day) {
    if (!this.allQuizIds.includes(day._id)) {
      await this.reloadAllQuizAndDays(this.userId);
    }
    const lang = this.translate.currentLang === 'en' ? 'en' : 'he';
    this.lastUpdated = day?.fdq_day_form_is_real ? moment(day.updatedAt).locale(lang).calendar() : '';
  }

  setBusy(e) {
    // this.busy = e;
    // this.changeDetectorRef.detectChanges();
    setTimeout(() => {this.busy = e;});
  }
}

