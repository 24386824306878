import { lmsCharts } from '@services/lms-charts';
import { Apputils } from '@services/app-utils';
import * as moment from 'moment-timezone';

export module Appcalcs {

  // nuttest

  export function updatePercents(selected: any, user: any, addLabel?: boolean, ageOnly?: boolean) {
   if(!user) return null;
    const { usr_birth_date, usr_gender_type } = user;
    const { ntq_height, ntq_weight, ntq_test_date, ntq_fat_percent, ntq_fat_percent_num } = selected;

    if (!ntq_height || !ntq_weight || !ntq_test_date ||!usr_birth_date || !usr_gender_type) { return; }

    const weight = +(ntq_weight);
    const height = +(ntq_height);

    const bmi = this.calcBmi(weight, height);
    selected.ntq_bmi = bmi;

    const birthDate: moment.Moment = moment(usr_birth_date);
    const nowDate: moment.Moment = moment(ntq_test_date);
    const ageInYears = nowDate.diff(birthDate, "years");
    const ageInMonths = nowDate.diff(birthDate, "months");
    const months = ageInMonths - ageInYears * 12;
    selected.age_in_months = ageInMonths;
    selected.age_in_years = ageInYears;
    selected.age_calc = +((ageInMonths / 12).toFixed(2));
    selected.age_formatted = String(ageInYears) + '.' + String(months);

    if (ageOnly) {
      return;
    }

    if (ageInMonths > 24 && ageInMonths < 241) {

      const height_p_num = this.getPercents(lmsCharts.statage, height, ageInMonths, usr_gender_type);
      const weight_p_num = this.getPercents(lmsCharts.wtage, weight, ageInMonths, usr_gender_type);
      const bmi_p_num = this.getPercents(lmsCharts.bmiage, bmi, ageInMonths, usr_gender_type);

      selected.ntq_height_p_num = height_p_num;
      selected.ntq_weight_p_num = weight_p_num;
      selected.ntq_bmi_p_num = bmi_p_num;

      selected.ntq_height_p = this.addPrefix(height_p_num);
      selected.ntq_weight_p = this.addPrefix(weight_p_num);
      selected.ntq_bmi_p = this.addPrefix(bmi_p_num);

      if (addLabel){
          selected.ntq_height_p_label = this.getPercentageLabelHeight(height_p_num, usr_gender_type);
          selected.ntq_weight_p_label = this.getPercentageLabelWeight(weight_p_num, usr_gender_type);
          selected.ntq_bmi_p_label = this.getPercentageLabelBmi(bmi_p_num, usr_gender_type);
      }
    }

    let fat = 0;

    if (ntq_fat_percent === 'קליפר') {
      selected.ntq_fat_default = this.calcBodyFat(selected, usr_gender_type, true);
      selected.ntq_fat_age = this.calcBodyFat(selected, usr_gender_type);
      const sf = this.calcSF(selected);
      if (sf) {
        selected.ntq_fat_sf = +(sf.toFixed(1));
      }
      fat = +(this.calcBodyFat(selected, usr_gender_type, false, true));
    }
    if (ntq_fat_percent === 'הזנה ידנית' && ntq_fat_percent_num) {
      fat = +(ntq_fat_percent_num);
    }

    selected.ntq_fat = fat;
    if (fat) {
      const mass = +(ntq_weight);
      const fatMass = +((mass * fat / 100).toFixed(1));
      selected.ntq_fat_mass = fatMass;
      selected.ntq_skinny_mass = +((mass - fatMass).toFixed(1));
      selected.ntq_fat_validity_label = this.getFatValidityLabel(selected, usr_gender_type);
    }

    const rmr = calcRMR(selected, usr_gender_type);
    selected.ntq_rmr = rmr;
  }
    
  export function calcDiff(numA: any, numB: any) {
    const n1 = typeof numA === 'string' ? numA.replace(/[^0-9.]/g,'') : numA;
    const n2 = typeof numB === 'string' ? numB.replace(/[^0-9.]/g,'') : numB;
    // return +(Math.abs(n1 * 1 - n2 * 1).toFixed(1));
    const res = +((n1 * 1 - n2 * 1).toFixed(1));
    return isNaN(res) ? '' : res;
  }
    
  export function getZScore(value, L, M, S) {
    if (L == 0) {
      return Math.log(value / M) / S;
    } else {
      return (Math.pow(value / M, L) - 1) / (L * S);
    }
  }
    
  export function getPercentile(z: number) {
    let k, m, values, total, item, z2, z4, a, b;

    // Power series is not stable at these extreme tail scenarios
    if (z < -6) { return 0; }
    if (z >  6) { return 1; }

    m = 1;        // m(k) == (2**k)/factorial(k)
    b = z;        // b(k) == z ** (2*k + 1)
    z2 = z * z;    // cache of z squared
    z4 = z2 * z2;  // cache of z to the 4th
    values = [];

    // Compute the power series in groups of two terms.
    // This reduces floating point errors because the series
    // alternates between positive and negative.
    for (k = 0; k < 100; k += 2) {
      a = 2 * k + 1;
      item = b / (a * m);
      item *= (1 - (a * z2) / ((a + 1) * (a + 2)));
      values.push(item);
      m *= (4 * (k + 1) * (k + 2));
      b *= z4;
    }

    // Add the smallest terms to the total first that
    // way we minimize the floating point errors.
    total = 0;
    for (k = 49; k >= 0; k--) {
      total += values[k];
    }

    // Multiply total by 1/sqrt(2*PI)
    // Then add 0.5 so that stdNormal(0) === 0.5
    return 0.5 + 0.3989422804014327 * total;
  }
    
  export function getPercents(data: any ,value: number, ageInMonths: number, gender: string) {
    const {L, M, S} = data[gender].find(val => Math.round(val.Agemos) == ageInMonths);

    const zScore = this.getZScore(value, L, M, S);

    const percents = this.getPercentile(zScore) * 100;

    return percents;
  }
      
  export function addPrefix(percents: number) {
    if (percents > 97) {
      return '>97%';
    }
    if (percents < 3) {
      return '<3%';
    }

    return Math.trunc(percents) + '%';
  }
    
  export function calcBmi(weight: number, height: number) {
    const metric = height / 100;
    return +((weight / (metric * metric)).toFixed(1));
  }
    
  export function calcSF(selected: any) {
    const { ntq_fat_caliper_a, ntq_fat_caliper_b, ntq_fat_caliper_c, ntq_fat_caliper_d } = selected;
    if (!ntq_fat_caliper_a || !ntq_fat_caliper_b || !ntq_fat_caliper_c || !ntq_fat_caliper_d) {
      return 0;
    }
    return +(ntq_fat_caliper_a) + +(ntq_fat_caliper_b) + +(ntq_fat_caliper_c) + +(ntq_fat_caliper_d);
  }
    
  export function calcBodyFat(selected: any, gender: string, isDefault?: boolean, useStatus?: boolean) {
    const sf = this.calcSF(selected);
    if (!sf) { return 0; }

    let ageInYears = 20;

    if (!isDefault) {
      if (!selected.age_in_years) { return 0; }
      ageInYears = selected.age_in_years;
    }

    let status = 0;
    if (useStatus) {
      if (selected['ntq_fat_caliper_status'] === 'טרום התפתחותי') {
        status = -1;
      } else if (selected['ntq_fat_caliper_status'] === 'מפותח') {
        status = 1;
      }
    }

    const {x, y} = this.getFatXyByAge(ageInYears, gender, status);
    const bd = x - (y * Math.log10(sf));
    const sum = +((495 / bd - 450).toFixed(1));

    return useStatus ? sum : sum + '%';
  }
    
  export function getFatXyByAge(age: number, gender: string, bodyStatus: number) {
    const fatObj = {
      'M': {
        0: { x: 1.1533 , y: 0.0643 },
        1: { x: 1.162 , y: 0.063 },
        2: { x: 1.1631 , y: 0.0632 },
        3: { x: 1.1422 , y: 0.0544 },
        4: { x: 1.162 , y: 0.07 },
        5: { x: 1.1715 , y: 0.0779 }
      },
      'F': {
        0: { x: 1.1369 , y: 0.0598 },
        1: { x: 1.1549 , y: 0.0678 },
        2: { x: 1.1599 , y: 0.0717 },
        3: { x: 1.1423 , y: 0.0632 },
        4: { x: 1.1333 , y: 0.0612 },
        5: { x: 1.1339 , y: 0.0645 }
      }
    };

    let ageIndex = this.getIndexByAge(age);
    if (!(ageIndex === 0 && bodyStatus < 0) && !(ageIndex === 5 && bodyStatus > 0)) {
      ageIndex += bodyStatus;
    }

    return fatObj[gender][ageIndex];
  }
    
  export function getIndexByAge(age: number) {
    // if (age < 17) {
    //   return 0;
    // }
    if (age < 20) {
      return 1;
    }
    if (age < 30) {
      return 2;
    }
    if (age < 40) {
      return 3;
    }
    if (age < 50) {
      return 4;
    }
    return 5;
  }
    
  export function calcRMR(selected: any, gender: string) {
    const { ntq_height, ntq_weight, ntq_fat, age_in_years } = selected;

    const weight = +(ntq_weight);
    const height = +(ntq_height);
    const fat = +(ntq_fat);

    if (fat > 0) {
      const lbm = weight - weight * fat / 100;
      return Math.trunc(22 * lbm + 500);
    }

    if (!weight || !height || !age_in_years || !gender) { return 0; }

    if (gender === 'F') {
      return Math.trunc(655.1 + 9.563 * weight + 1.85 * height - 4.676 * age_in_years);
    } else {
      return Math.trunc(66.5 + 13.75 * weight + 5.003 * height - 6.755 * age_in_years);
    }
  }

  export function getPercentageLabelBmi(percents: number, gender: string) {
    if (gender === 'F') {
      if (percents > 97) {
        return 'אחוזון BMI משקף את הפרופורציות שלך ביחס לבני גילך ומינך כך שלפחות 97% יהיו קטנות ממך פרופורציונלית.';
      }
      if (percents < 3) {
        return 'אחוזון BMI משקף את הפרופורציות שלך ביחס לבני גילך ומינך כך שלפחות 97% יהיו גדולות ממך פרופורציונלית.';
      }
      const per = Math.trunc(percents);
      const diff = 100 - per;
      return `אחוזון BMI משקף את הפרופורציות שלך ביחס לבני גילך ומינך כך ש-${per}% יהיו קטנות ממך פרופורציונלית ו-${diff}% יהיו גדולות ממך פרופורציונלית.`
    } else {
      if (percents > 97) {
        return 'אחוזון BMI משקף את הפרופורציות שלך ביחס לבני גילך ומינך כך שלפחות 97% יהיו קטנים ממך פרופורציונלית.';
      }
      if (percents < 3) {
        return 'אחוזון BMI משקף את הפרופורציות שלך ביחס לבני גילך ומינך כך שלפחות 97% יהיו גדולים ממך פרופורציונלית.';
      }
      const per = Math.trunc(percents);
      const diff = 100 - per;
      return `אחוזון BMI משקף את הפרופורציות שלך ביחס לבני גילך ומינך כך ש-${per}% יהיו קטנים ממך פרופורציונלית ו-${diff}% יהיו גדולים ממך פרופורציונלית.`
    }
  }

  export function getPercentageLabelWeight(percents: number, gender: string) {
    if (gender === 'F') {
      if (percents > 97) {
        return 'אחוזון המשקל משקף את משקלך ביחס לבני גילך ומינך כך שלפחות 97% משקלן נמוך משלך.';
      }
      if (percents < 3) {
        return 'אחוזון המשקל משקף את משקלך ביחס לבני גילך ומינך כך שלפחות 97% משקלן גבוה משלך.';
      }
      const per = Math.trunc(percents);
      const diff = 100 - per;
      return `אחוזון המשקל משקף את משקלך ביחס לבני גילך ומינך כך ש-${per}% משקלן נמוך משלך ו-${diff}% משקלן גבוה משלך.`
    } else {
      if (percents > 97) {
        return 'אחוזון המשקל משקף את משקלך ביחס לבני גילך ומינך כך שלפחות 97% משקלם נמוך משלך.';
      }
      if (percents < 3) {
        return 'אחוזון המשקל משקף את משקלך ביחס לבני גילך ומינך כך שלפחות 97% משקלם גבוה משלך.';
      }
      const per = Math.trunc(percents);
      const diff = 100 - per;
      return `אחוזון המשקל משקף את משקלך ביחס לבני גילך ומינך כך ש-${per}% משקלם נמוך משלך ו-${diff}% משקלם גבוה משלך.`
    }
  }
    
  export function getPercentageLabelHeight(percents: number, gender: string) {
    if (gender === 'F') {
      if (percents > 97) {
        return 'אחוזון הגובה משקף את הגובה ביחס לבני גילך ומינך כך שלפחות 97% נמוכות ממך.';
      }
      if (percents < 3) {
        return 'אחוזון הגובה משקף את הגובה ביחס לבני גילך ומינך כך שלפחות 97% גבוהות ממך.';
      }
      const per = Math.trunc(percents);
      const diff = 100 - per;
      return `אחוזון הגובה משקף את הגובה ביחס לבני גילך ומינך כך ש-${per}% נמוכות ממך ו-${diff}% גבוהות ממך.`
    } else {
      if (percents > 97) {
        return 'אחוזון הגובה משקף את הגובה ביחס לבני גילך ומינך כך שלפחות 97% נמוכים ממך.';
      }
      if (percents < 3) {
        return 'אחוזון הגובה משקף את הגובה ביחס לבני גילך ומינך כך שלפחות 97% גבוהים ממך.';
      }
      const per = Math.trunc(percents);
      const diff = 100 - per;
      return `אחוזון הגובה משקף את הגובה ביחס לבני גילך ומינך כך ש-${per}% נמוכים ממך ו-${diff}% גבוהים ממך.`
    }
  }


  //practice

  export function calcPracticeAvg(selected: any) {
    const practicesArr = selected['prq_extra_practice_arr'] || [];
    
    let totalTime = 0;
    for (let practice of practicesArr) {
      let freq = practice['practice_frequency'] ? +(practice['practice_frequency']) : 1;
      let time = +(practice['practice_length']);
      
      totalTime += freq*time;
    }

    selected.prq_practice_avg = Math.trunc(totalTime / 7);
  }

  export function calcMatchAvg(selected: any, user: any) {
    const matchesArr = selected['prq_team_report_arr'] || [];
    const userId = user._id.toString();

    let totalTime = 0, totalMatch = 0, longestMatch = 0;

    for (let match of matchesArr) {
      let reports = match['tem_report_players_minutes_arr'];
      if (reports && reports.length){
        let played = reports.find(player => player._id === userId);
        if (played){
          totalTime += +(played['minutes']);
          totalMatch++;
          longestMatch = Math.max(longestMatch, +(played['minutes']));
        }
      }
    }

    selected.prq_longest_match = longestMatch;
    selected.prq_match_avg = Math.trunc(totalTime / totalMatch);
  }

  export function getLongestPractice(selected: any) {
    const teamPractices = selected['prq_team_practice_arr'] || [];
    const userPractices = selected['prq_extra_practice_arr'] || [];

    let userLongest = 0, teamLongest = 0;
    
    for (let practice of userPractices) {
      let time = +(practice['practice_length']);
      userLongest = Math.max(userLongest, time);
    }
    for (let practice of teamPractices) {
      let time = +(practice['tem_practice_duration_minutes']);
      teamLongest = Math.max(teamLongest, time);
    }

    selected.prq_longest_practice = Math.max(userLongest, teamLongest);
  }

  export function calcKcalDayAvg(selected: any, kcal: any) {

    const roundTo50 = x => Math.round(+(x)/50)*50;

    if (kcal && kcal.rmr) {
      const rmr = roundTo50(kcal.rmr);
      selected.prq_daily_rmr = rmr;
      selected.prq_daily_no_practice = roundTo50(rmr * kcal.factor);
    }

    const practiceTypes = Apputils.practiceTypes;

    const getMet = (practiceType: string) => {
      if (!practiceTypes || !practiceType) return 3;
      const met = practiceTypes.find(val => val && val.title === practiceType)?.met || 3;
      return met;
    };

    const teamPractices = selected['prq_team_practice_arr'] || [];
    const userPractices = selected['prq_extra_practice_arr'] || [];
    
    let userTotalTime = 0, teamTotalTime = 0;

    //calculating with met!
    for (let practice of userPractices) {
      let freq = practice['practice_frequency'] ? +(practice['practice_frequency']) : 1;
      let time = +(practice['practice_length']);
      let met = getMet(practice['practice_type']);
      
      userTotalTime += freq*time*met;
    }
    for (let practice of teamPractices) {
      let freq = practice['tem_practice_times_week'] ? +(practice['tem_practice_times_week']) : 1;
      let time = +(practice['tem_practice_duration_minutes']);
      let met = getMet(practice['tem_practice_type']);
      
      teamTotalTime += freq*time*met;
    }

    let totalMatchTime = selected['prq_match_avg'] ? (selected['prq_match_avg'] * 8 / 7) : 0; //8 is met of game

    let totalDailyTime = (userTotalTime + teamTotalTime) / 7;

    const totalTime = totalMatchTime + totalDailyTime;

    let daily = Math.trunc(3.5*kcal.weight*totalTime*5 / 1000);
    selected.prq_daily_kcal = roundTo50(daily);
  }
  

  // sleeping

  export function updateSleepingCalcs(selected: any, user: any, testDate: any) {
    const { usr_birth_date } = user;
    const { slq_weekday_sleeping_time, slq_weekday_wakeup_time, slq_weekend_sleeping_time, slq_weekend_wakeup_time } = selected;

    if (!slq_weekday_sleeping_time || !slq_weekday_wakeup_time || !slq_weekend_sleeping_time || !slq_weekend_wakeup_time || !usr_birth_date) { return; }

    const weekday = this.calcHours(slq_weekday_sleeping_time, slq_weekday_wakeup_time);
    const weekend = this.calcHours(slq_weekend_sleeping_time, slq_weekend_wakeup_time);

    selected.slq_weekday_hours = weekday;
    selected.slq_weekend_hours = weekend;
    this.updateStatus(selected, usr_birth_date, testDate, weekday, weekend);
  }

  export function calcHours(start: string, end: string) {
    const startSplit = start.split(":");
    const endSplit = end.split(":");
    const startDate = new Date(0, 0, 0, +(startSplit[0]), +(startSplit[1]), 0);
    const endDate = new Date(0, 0, 0, +(endSplit[0]), +(endSplit[1]), 0);
    let diff = endDate.getTime() - startDate.getTime();
    let hours = Math.floor(diff / 1000 / 60 / 60);
    diff -= hours * 1000 * 60 * 60;
    const minutes = Math.floor(diff / 1000 / 60);

    if (hours < 0) {
      hours = hours + 24;
    }

    return (hours <= 9 ? '0' : '') + hours + ":" + (minutes <= 9 ? '0' : '') + minutes;
  }

  export function updateStatus(selected: any, birthDate: any, testDate: any, sleepTimeWeekday: string, sleepTimeWeekend: string) {

    const birth: moment.Moment = moment(birthDate);
    const now: moment.Moment = moment(testDate);
    const ageInMonths = now.diff(birth, "months");

    const limits = [
      { min: 14 , max: 17 },
      { min: 12 , max: 15 },
      { min: 12 , max: 16 },
      { min: 10 , max: 13 },
      { min: 9 , max: 11 },
      { min: 8 , max: 10 },
      { min: 7 , max: 9 },
      { min: 7 , max: 8 },
    ];

    const ageLimits = limits[this.getIndexByAgeInMonths(ageInMonths)];

    const sleepWeekday = sleepTimeWeekday.split(":");
    const sleepInMinutesWeekday = +(sleepWeekday[0]) * 60 + +(sleepWeekday[1]);
    const sleepWeekend = sleepTimeWeekend.split(":");
    const sleepInMinutesWeekend = +(sleepWeekend[0]) * 60 + +(sleepWeekend[1]);

    selected.slq_weekday_status = this.getStatusLabel(ageLimits, sleepInMinutesWeekday);
    selected.slq_weekend_status = this.getStatusLabel(ageLimits, sleepInMinutesWeekend);

    selected.slq_weekday_boolean = this.getStatusBoolean(ageLimits, sleepInMinutesWeekday);
    selected.slq_weekend_boolean = this.getStatusBoolean(ageLimits, sleepInMinutesWeekend);
  }

  export function getStatusLabel(ageLimits: any, sleepInMinutes: number) {
    if (ageLimits.min*60 > sleepInMinutes) {
      return "חסר (<" + ageLimits.min + ")";
    }
    if (ageLimits.max*60 < sleepInMinutes) {
      return "עודף (>" + ageLimits.max + ")";
    }
    return "תקין (" + ageLimits.min + "-" + ageLimits.max + ")";
  }

  export function getStatusBoolean(ageLimits: any, sleepInMinutes: number) {
    if(ageLimits.min*60 > sleepInMinutes){
      return false;
    }
    if(ageLimits.max*60 < sleepInMinutes){
      return null;
    }
    return true;
  }

  export function getIndexByAgeInMonths(ageInMonths: number) {
    if (ageInMonths < 4) {
      return 0;
    }
    if (ageInMonths < 12) {
      return 1;
    }
    if (ageInMonths < 36) {
      return 2;
    }
    if (ageInMonths < 72) {
      return 3;
    }
    if (ageInMonths < 168) {
      return 4;
    }
    if (ageInMonths < 216) {
      return 5;
    }
    if (ageInMonths < 780) {
      return 6;
    }
    return 7;
  }

  export function getIndexFatValidityByAge(ageInYears: number) {
    if (ageInYears < 5) {
      return -1;
    }
    if (ageInYears < 20) {
      return ageInYears;
    }
    if (ageInYears < 40) {
      return 20;
    }
    if (ageInYears < 60) {
      return 40;
    }
    if (ageInYears < 80) {
      return 60;
    }
    return -1;
  }

  export function getFatValidity(selected: any, gender: string) {
    const { age_in_years, ntq_fat, ntq_fat_age = '' } = selected;
    if (!age_in_years || !ntq_fat || !gender) { return null; }

    const fatObj = {
      'M': {
        5: { a: 12 , b: 19 , c: 23, d: 40 },
        6: { a: 12 , b: 20 , c: 24, d: 40 },
        7: { a: 13 , b: 20 , c: 25, d: 40 },
        8: { a: 13 , b: 21 , c: 26, d: 40 },
        9: { a: 13 , b: 22 , c: 27, d: 40 },
        10: { a: 13 , b: 23 , c: 28, d: 40 },
        11: { a: 13 , b: 23 , c: 28, d: 40 },
        12: { a: 12 , b: 23 , c: 28, d: 40 },
        13: { a: 12 , b: 22 , c: 27, d: 40 },
        14: { a: 11 , b: 21 , c: 26, d: 40 },
        15: { a: 10 , b: 21 , c: 25, d: 40 },
        16: { a: 10 , b: 20 , c: 24, d: 40 },
        17: { a: 10 , b: 20 , c: 24, d: 40 },
        18: { a: 10 , b: 20 , c: 24, d: 40 },
        19: { a: 9 , b: 20 , c: 24, d: 40 },
        20: { a: 8 , b: 20 , c: 25, d: 40 },
        40: { a: 10 , b: 22 , c: 28, d: 40 },
        60: { a: 12 , b: 25 , c: 30, d: 40 },
      },
      'F': {
        5: { a: 14 , b: 22 , c: 26, d: 50 },
        6: { a: 14 , b: 23 , c: 27, d: 50 },
        7: { a: 15 , b: 25 , c: 29, d: 50 },
        8: { a: 15 , b: 26 , c: 30, d: 50 },
        9: { a: 16 , b: 27 , c: 31, d: 50 },
        10: { a: 16 , b: 28 , c: 32, d: 50 },
        11: { a: 16 , b: 29 , c: 33, d: 50 },
        12: { a: 16 , b: 29 , c: 33, d: 50 },
        13: { a: 16 , b: 29 , c: 33, d: 50 },
        14: { a: 16 , b: 30 , c: 34, d: 50 },
        15: { a: 16 , b: 30 , c: 34, d: 50 },
        16: { a: 16 , b: 30 , c: 34, d: 50 },
        17: { a: 16 , b: 30 , c: 35, d: 50 },
        18: { a: 17 , b: 31 , c: 36, d: 50 },
        19: { a: 19 , b: 32 , c: 37, d: 50 },
        20: { a: 21 , b: 33 , c: 39, d: 50 },
        40: { a: 23 , b: 34 , c: 40, d: 50 },
        60: { a: 24 , b: 36 , c: 42, d: 50 },
      }
    };

    const fat = +(ntq_fat_age.replace('%', '') ?? ntq_fat);
    const ageIndex = this.getIndexFatValidityByAge(age_in_years);

    return ageIndex > -1 ? {...fatObj[gender][ageIndex], fat} : null;
  }

  export function getFatValidityLabel(selected: any, gender: string) {
    const validity = this.getFatValidity(selected, gender);

    if (validity) {
      if (validity.fat<validity.a){
        return `תת שומן (<${validity.a})`;
      }
      if (validity.fat<validity.b){
        return `תקין-בריא (${validity.a} - ${validity.b})`;
      }
      if (validity.fat<validity.c){
        return `עודף שומן (${validity.b} - ${validity.c})`;
      }

      return `השמנה (>${validity.c})`;
    }
    return '';
  }
  
}
