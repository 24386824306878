import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuaggaJSResultObject } from '@ericblade/quagga2';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';

export interface DialogData {}

@Component({
    selector: 'barcode-dialog',
    template: `
        <div class="relative centered-row d-column" style="min-height: 35vh;">
            <div *ngIf="!started" class="spinner no-margin">
                <div class="double-bounce1"></div>
                <div class="double-bounce2"></div>
            </div>
            <barcode-scanner-livestream
                [type]="['ean']"
                (valueChanges)="onBarcodeValueChanges($event)"
                (started)="onStarted($event)"
            ></barcode-scanner-livestream>
            <div class="close-button-barcode">
                <button type="button" mat-icon-button (click)="onNoClick()" cdkFocusInitial>
                    <mat-icon>close</mat-icon>
                </button>
            </div>
        </div>
    `,
})
export class BarcodeDialogComponent {
    @ViewChild(BarcodeScannerLivestreamComponent) barcodeScanner: BarcodeScannerLivestreamComponent;

    barcodeValue = '';
    started = false;

    constructor(
        public dialogRef: MatDialogRef<BarcodeDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
    ) {}

    ngAfterViewInit() {
        this.startBarcodeScanner();
    }

    ngOnDestroy() {
        this.stopBarcodeScanner();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    startBarcodeScanner(): void {
        this.barcodeScanner.start();
    }

    stopBarcodeScanner(): void {
        this.barcodeScanner.stop();
    }

    getMedian(arr: number[]): number {
        arr.sort((a, b) => a - b);
        const half = Math.floor(arr.length / 2);
        if (arr.length % 2 === 1) {
            // Odd length
            return arr[half];
        }
        return (arr[half - 1] + arr[half]) / 2.0;
    }

    isValidBarcode(result: QuaggaJSResultObject) {
        const errors: number[] = result.codeResult.decodedCodes
            .filter((err) => err.code > -1 && err.error !== undefined)
            .map((err) => err.error);

        const median = this.getMedian(errors);
        const maxError = errors.some((err) => err > 0.11);

        return !(median > 0.09 || maxError);
    }

    onBarcodeValueChanges(result: QuaggaJSResultObject): void {
        if (this.isValidBarcode(result)) {
            this.barcodeValue = result.codeResult.code;
            this.dialogRef.close(this.barcodeValue);
        }
    }

    onStarted(event: boolean): void {
        this.started = event;
    }
}
