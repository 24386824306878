import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray } from '@angular/forms';
import { Pipe, PipeTransform } from '@angular/core';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

import { map, startWith } from 'rxjs/operators';

import { ConfirmDialogService } from '../confirm-dialog/confirm-dialog.service';
import { appState } from '@services/app-state';
import * as moment from 'moment-timezone';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
    transform(value: Date | moment.Moment | null, dateFormat: string): any {
        return value ? moment(value).format(dateFormat) : '';
    }
}

@Pipe({
  name: 'noteDetails'
})
export class NoteDetailsPipe implements PipeTransform {
  transform(noteObj: any, notesArr: any, key: string): any {
    // if (noteObj.note_obj && noteObj.note_obj._id) {
    //   return noteObj.note_obj[key];
    // }
    if (notesArr) {
      const noteId = noteObj?.note_id;
      const note = notesArr.find(val => val && val._id === noteId);
      return note && note[key];
    }
    return null;
  }
}

@Pipe({
  name: 'reverse'
})
export class ReversePipe implements PipeTransform {
  transform(arr: any[]): any[] {
    console.log('reversed');
    return [...arr].reverse();
  }
}

@Component({
  selector: 'notes',
  templateUrl: "./notes.component.html"
})
export class NotesComponent {

  @Output() linkedNotesEvent: EventEmitter<any> = new EventEmitter();

  @Output() addNoteEvent: EventEmitter<any> = new EventEmitter();
  @Output() editNoteEvent: EventEmitter<any> = new EventEmitter();
  @Output() removeNoteEvent: EventEmitter<any> = new EventEmitter();

  appState = appState;
  mainUserId = '5ea8a52e3537c70016e861f7';

  acForm: FormGroup;

  _notesArr: any[] = [];
  _timeCreated = 0;

  _notesFiltered: any[] = [];

  linkedNotes: any[] = [];

  // @Input() notesArr: Array<string>;
  // @Input() selectedForm: FormGroup;
  // @Input() fieldName: string;

  quizPrefixes = {
    ntq: 'אנתרופומטריים',
    prq: 'אימונים',
    slq: 'שינה',
    heq: 'בריאות',
    nuq: 'תזונה',
    btq: 'בדיקת דם',
    fdq: 'יומן אכילה',
  };

  subPrefixOptions = {
    ntq: {},
    prq: {},
    slq: {},
    heq: {
      s1: {
        a: '* הערות לתחילת הדוח - מצב בריאותי כללי',
        b: '* הערות לתחילת הדוח - אלרגיות ורגישויות',
        c: '* הערות לתחילת הדוח - תרופות ותוספים',
        d: '* הערות לתחילת הדוח - מחזור',
      },
      s3: {
        a: '* הערות תזונה כלליות - מצב בריאותי כללי',
        b: '* הערות תזונה כלליות - אלרגיות ורגישויות',
        c: '* הערות תזונה כלליות - תרופות ותוספים',
        d: '* הערות תזונה כלליות - מחזור',
      },
    },
    nuq: {
      s3: {
        a: '* הערות תזונה כלליות - העדפות תזונה',
        b: '* הערות תזונה כלליות - אופן האכילה (מסכים)',
        c: '* הערות תזונה כלליות - אופן האכילה (אכילה בחוץ)',
        d: '* הערות תזונה כלליות - מרכיבי הארוחות',
      },
    },
    btq: {},
    fdq: {},
  };
  
  @Input() showOrigin?: boolean;
  @Input() isReadonly?: boolean;
  @Input() isFollowMode?: boolean;
  @Input() allNotes?: any[] = [];

  @Input() isLinkedMode?: boolean;
  @Input() noteForm?: FormGroup;
  
  @Input() isFemale?: boolean;

  @Input() selectedValue?: any;

  @Input() selectedGroup?: FormGroup;
  @Input() sharedGroup?: FormGroup;
  @Input() fieldKey?: string;
  @Input() subKey?: string;
  @Input() set notesArr(notes: any[]) {
    if (!notes || !this.fieldKey) return;
    this._notesArr = notes.filter((note: any) => note.quiz_note_type === this.fieldKey && (this.subKey ? this.subKey === note.sub_prefix : true));
    this._notesFiltered = this._notesArr.filter((note: any) => (this.showOrigin ? note.quiz_prefix === 'fdq' : true) && (this.subKey ? this.subKey === note.sub_prefix : true) && note.is_visible && (note.created_by === this.appState.user._id || note.created_by === this.mainUserId));
    if (this.acForm) {
      this.acForm.get("extra_field_input").updateValueAndValidity();
    }
  }
  get notesArr() {
      return this._notesArr;
  }
  
  get notesFiltered() {
    return this._notesFiltered;
  }

  @Input() oldNotes?: FormControl;
  @Input() set timeCreated(time: any) {
    if (!time) return;
    this._timeCreated = new Date(time).getTime();
  }
  get timeCreated() {
      return this._timeCreated;
  }


  constructor(
    private formBuilder: FormBuilder,
    private dialogService: ConfirmDialogService
  ) {
    this.acForm = this.formBuilder.group({
      extra_field_options: [''],
      extra_field_input: [''],
    });

    this.acForm.get("extra_field_options").setValue(this.acForm.get("extra_field_input").valueChanges
      .pipe(
        startWith(''),
        // map(value => typeof value === 'object' ? value.content : (value || '')),
        // map((query: string) => query ? this._filter(this.notesArr, query) : this.notesArr.slice())
        map((value: any) => this._addObject(value)),
        map((query: string) => this._filter(this.isLinkedMode ? this.linkedNotes : this.notesFiltered, query))
      ));    

  }

  async ngOnInit() {
    if (this.noteForm) {
      this.updateLinked();

      this.noteForm.get('linked_quiz_prefix').valueChanges.subscribe(val => {
        this.updateLinked();
      });
      this.noteForm.get('linked_quiz_note_type').valueChanges.subscribe(val => {
        this.updateLinked();
      });
      this.noteForm.get('linked_sub_prefix').valueChanges.subscribe(val => {
        this.updateLinked();
      });
    }
  }

  updateLinked() {
    this.linkedNotes = this.filterLinkedNotes();
    const inputVal = this.acForm.get("extra_field_input").value;
    this.acForm.get("extra_field_input").setValue(inputVal);
  }

  filterLinkedNotes() {
    if (!this.noteForm || !this.allNotes) {
      return [];
    }

    // const { linked_quiz_prefix, linked_quiz_note_type, linked_sub_prefix } = this.noteForm.value;
    const linked_quiz_prefix = this.noteForm.get('linked_quiz_prefix').value;
    const linked_quiz_note_type = this.noteForm.get('linked_quiz_note_type').value;
    let linked_sub_prefix = '';
    if (this.subPrefixOptions[linked_quiz_prefix] && this.subPrefixOptions[linked_quiz_prefix][linked_quiz_note_type]) {
      linked_sub_prefix = this.noteForm.get('linked_sub_prefix').value;
    }
    
    return this.allNotes.filter((note: any) => note.quiz_prefix === linked_quiz_prefix && note.quiz_note_type === linked_quiz_note_type && (linked_sub_prefix ? linked_sub_prefix === note.sub_prefix : true) && note.is_visible && (note.created_by === this.appState.user._id || note.created_by === this.mainUserId));
  }

  private _addObject(val: any): string {
    if (typeof val === 'object' && val._id) {
      this.addNote(val);
      // return val.content;
      return '';
    }
    return val || '';
  }

  private _filter(notesArr: any[], query: string): any[] {
    if (!query) {
      return [...notesArr];
    }
    const filterValue = query.trim().toLowerCase();
    return notesArr.filter(note => String((this.isFemale ? (note.content_F || '') : note.content)).toLowerCase().indexOf(filterValue) != -1 || String(note.title).toLowerCase().indexOf(filterValue) != -1);
  }

  displayFn(note?: any): string | undefined {
    return note ? (this.isFemale ? note.content_F : note.content) : undefined;
  }

  addLinked(note: any) {
    let field: FormArray = this.noteForm.get('linked_notes') as FormArray;
    
    if (!note?._id || field.value.findIndex(val => val && val.note_id === note._id) > -1) {
      return;
    }

    const now = new Date().getTime();

    const newNote = new FormControl({
      note_id: note?._id,
      is_active: null,
      is_done: null,
      added_at: now,
      note_type: 'linked',
    });
    field.push(newNote);
    // this.acForm.get("extra_field_options").reset();
    // this.acForm.get("extra_field_input").reset('');
    this.acForm.get("extra_field_input").setValue('');
    field.markAsDirty();

  }

  addNote(note: any, isLinked?: boolean) {
    if (this.isLinkedMode) {
      if (typeof note === 'object') {
        this.addLinked(note);
      }
      return;
    }

    let field: FormArray = this.selectedGroup.get(this.fieldKey) as FormArray;
    if (note === undefined || !field) {
      return;
    }

    const now = new Date().getTime();
    if (typeof note === 'string') {
      const acFormField = this.acForm.get("extra_field_input");
      const eventData = this.isFemale ? { content_F: note, acFormField } : { content: note, acFormField };
      this.addNoteEvent.emit(eventData);
      this.acForm.get("extra_field_input").reset('');

      // const options = {
      //   title: 'הוספת הערה',
      //   message: 'האם להוסיף הערה מותאמת אישית?',
      //   cancelText: 'ביטול',
      //   confirmText: 'אישור'
      // };
  
      // this.dialogService.open(options);
      // this.dialogService.confirmed().subscribe(confirmed => {
      //     if (confirmed) {
      //       const newNote = new FormControl({
      //         note_custom: note,
      //         added_at: now,
      //       });
      //       field.push(newNote);
      //       // this.acForm.get("extra_field_options").reset();
      //       this.acForm.get("extra_field_input").reset('');
      //       field.markAsDirty();
      //     }
      // });
    } else {
      if (!note?._id || field.value.findIndex(val => val && val.note_id === note._id) > -1) {
        return;
      }

      const allSharedTypes = ['s1', 's2', 's3', 's4', 's5', 's6', 's7', 's8', 's9', 's10', 's11', 's12'];
      let data: any = {
        note_id: note?._id,
        is_active: allSharedTypes.includes(this.fieldKey) ? Boolean(this.showOrigin) : true, // true,
        is_done: false,
        // note_obj: note,
        added_at: now,
      };
      // if (isLinked) {
      //   data.note_type = 'linked';
      // }
      const newNote = new FormControl(data);
      field.push(newNote);
      // this.acForm.get("extra_field_options").reset();
      // this.acForm.get("extra_field_input").reset('');
      this.acForm.get("extra_field_input").setValue('');
      field.markAsDirty();

      if (this.sharedGroup) { // (not called from fdq)
        let field: FormArray = this.sharedGroup.get(this.fieldKey) as FormArray;
        if (field.value.findIndex(val => val && val.note_id === note._id) === -1) {
          let data: any = {
            note_id: note?._id,
            is_active: true,
            is_done: false,
            // note_obj: note,
            added_at: now,
          };
          // if (isLinked) {
          //   data.note_type = 'linked';
          // }
          const newNote = new FormControl(data);
          field.push(newNote);
          field.markAsDirty();
        }
      }

      // if (!isLinked && this.allNotes && note.linked_notes && note.linked_notes.length) {
      //   for (const linked of note.linked_notes) {
      //     const n = this.allNotes.find((not: any) => linked && not._id === linked.note_id);
      //     if (n) {
      //       this.addNote(n, true);
      //     }
      //   }
      // }

      if (note.linked_notes?.length) {
        this.linkedNotesEvent.emit(note);
      }
    }

  }

  editNote(id: string) {
    let field: FormArray = this.selectedGroup.get(this.fieldKey) as FormArray;
    this.editNoteEvent.emit({ id, field });
  }

  removeNote(noteIndex: number, noteId?: any) {
    let field: FormArray = (this.isLinkedMode ? this.noteForm.get('linked_notes') : this.selectedGroup.get(this.fieldKey)) as FormArray;
    if (!field) return;
    const options = {
      title: 'מחיקת הערה',
      message: 'האם למחוק את ההערה?',
      cancelText: 'ביטול',
      confirmText: 'אישור'
    };

    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
        if (confirmed) {
          field.removeAt(noteIndex);
          field.markAsDirty();

          if (noteId) {
            if (this.sharedGroup) { // (not fdq)
              let field: FormArray = this.sharedGroup.get(this.fieldKey) as FormArray;
              const idx = field.value.findIndex(val => val && val.note_id === noteId); // || (val.note_custom && val.note_custom === noteObj.note_custom)
              if (idx > -1) {
                field.removeAt(idx);
                field.markAsDirty();
              }
            } else {
              const note = this.notesArr.find(val => val._id === noteId);
              this.removeNoteEvent.emit(note);
            }
          }
        }
    });
  }

  // addNote(note, field: FormControl){
  //   if (!note) return;
  //   let escaped = note.replace(/<b>/g, "").replace(/<\/b>/g, "");
  //   const last = field.value ? field.value + '\n\n'  : '';
  //   field.setValue(last + escaped);
  //   field.markAsDirty();
  // }

  drop(event: CdkDragDrop<string[]>) {
    // let field: FormArray = this.selectedGroup.get(this.fieldKey) as FormArray;
    let field: any = this.selectedGroup.get(this.fieldKey).value;
    if (!field) {
      return;
    }
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(field, event.previousIndex, event.currentIndex);
      this.selectedGroup.get(this.fieldKey).setValue(field);
      this.selectedGroup.get(this.fieldKey).markAsDirty();
    }
  }
}
