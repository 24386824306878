import { Component, ViewChild, TemplateRef, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';

import { TranslateService } from '@ngx-translate/core';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { appState } from '@services/app-state';
import { Apputils } from '@services/app-utils';

import * as moment from 'moment-timezone';

import { CustomDialogComponent } from '../../shared/custom-dialog.component';

@Component({
  selector: "user-practice",
  templateUrl: "./userpractice.component.html"
})
export class UserpracticeComponent {
  @Input() userIdInput?: string;
  @Input() onlyDialog?: boolean;
  @Input() disableDate?: boolean;

  @Output('removed') removed: EventEmitter<any> = new EventEmitter();
  @Output('created') created: EventEmitter<any> = new EventEmitter();

  @ViewChild("practiceDialogTemplate") practiceDialogTemplate: TemplateRef<any>;

  appState = appState;

  weekDays = {
    'Sun' : 'א', 
    'Mon' : 'ב', 
    'Tue' : 'ג', 
    'Wed' : 'ד', 
    'Thu' : 'ה', 
    'Fri' : 'ו', 
    'Sat' : 'ש'
  }
  yearMonths = {
    1: 'ינואר',
    2: 'פברואר',
    3: 'מרץ',
    4: 'אפריל',
    5: 'מאי',
    6: 'יוני',
    7: 'יולי',
    8: 'אוגוסט',
    9: 'ספטמבר',
    10: 'אוקטובר',
    11: 'נובמבר',
    12: 'דצמבר',
  }
  userId: string;
  allPractices: any[];
  timePeriod = '7 d';

  allHours = Apputils.allHours;
  durations = Apputils.getPracticeDurations();
  
  practiceTypes = Apputils.practiceTypes;
  
  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private restApiService: RestApiService<any>,
    private route: ActivatedRoute,
    public router: Router,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
  ) {}

  ngOnInit() {
    if (this.onlyDialog) {
      this.userId = this.userIdInput;
    } else {
      this.route.params.subscribe(async params => {
        if (params.id) {
          try {
            if (this.appState.user.usr_type !== 'player') {
              const usr = await this.restApiService.service('users').get(params.id).catch(e => { console.error(e) });
              if (!usr) {
                this.router.navigate(['/'], { replaceUrl: true });
                return;
              }
              this.appState.quizUser = usr;
            }
            this.allPractices = await this.getPractices(params.id, 1);
            this.userId = params.id;
            // await this.selectedPeriodChange({value: this.timePeriod});
          } catch (e) {
            console.error(e);
          }
        } else {
          if (this.appState.user.usr_type !== 'player') {
            this.router.navigate(['/'], { replaceUrl: true });
            return;
          }
          this.allPractices = await this.getPractices(this.appState.user._id, 1);
          this.userId = this.appState.user._id;
        }
      });  
    }
  }

  async getPractices(userId: string, endDate: number) {
    return await this.restApiService.service('user-practice').find({ 
      query: {
        $paginate: false,
        usr_practice_usr_id: userId,
        usr_practice_date_taken: {
          $gte: endDate
        },
        $sort: {
          usr_practice_date_taken: -1,
          usr_practice_time: -1
        }
      },
    });
  }

  // calcDate(timePeriod) {
  //   const period = timePeriod.split(' ');
  //   if (!period[0] || !period[1]) {
  //     return null;
  //   }
    
  //   const now: moment.Moment = moment();
  //   const date = now.subtract(period[0], period[1]).milliseconds(0).second(0).minute(0).hour(0).toISOString();
  //   console.log(date);
  //   return date;
  // }

  // async selectedPeriodChange(e) {
  //   // const changedTo = this.calcDate(e);
  //   const changedTo = this.calcDate(e.value);

  //   if (!this.userId || !changedTo) {
  //     delete this.allPractices;
  //     // this.allPractices = [];
  //     return;
  //   }

  //   this.allPractices = await this.getPractices(this.userId, changedTo);
  // }
  
  async createPracticeDialog(dateTaken?: any, fdqDayId?: string) {
    // const chosenDate = moment().utcOffset(3).milliseconds(0).second(0).minute(0).hour(0);
    
    const now = moment();
    const hour = now.hour();
    const minutes = Math.trunc(now.minute()/15)*15;
    const nowTime = (hour < 10 ? '0' + hour : hour) + ':' + (minutes < 10 ? '0' + minutes : minutes);
    const chosenDate = dateTaken || now.milliseconds(0).second(0).minute(0).hour(0);
    const newFormGroup = this.formBuilder.group({
      _id: [''],
      usr_practice_usr_id: [this.userId],
      // usr_practice_fdq_id: [''],
      usr_practice_fdq_day_id: [fdqDayId || ''],
      usr_practice_date_taken: [chosenDate, Validators.required],
      usr_practice_type: ['', Validators.required],
      usr_practice_type_other_text: [''],
      usr_practice_time: [nowTime, Validators.required],
      usr_practice_duration: ['60', Validators.required],
      usr_practice_remarks: [''],
    },
    {
      validators: [this.practiceCustomValidator.bind(this)]
    });
    const title = this.translate.instant('food_diary.ADD_PRACTICE_TITLE');
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      minWidth: '35%',
      data: { 
        title: title, 
        ok: this.translate.instant('general.ok'),
        cancel: this.translate.instant('general.cancel'),
        form: newFormGroup, 
        template: this.practiceDialogTemplate
      }
    });
    
    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        this.created.emit();
        return;
      }
      await this.onSubmitPractice(newFormGroup);
    });
  }

  async onSubmitPractice(practiceGroup: FormGroup) {
    let res: any;
    try {
      let resultMsg = '';
      if (!practiceGroup.value._id) {
        // let val = practiceGroup.getRawValue(); // to include the disabled field
        // delete val._id;
        // res = await this.restApiService.service('user-practice').create(val);
        delete practiceGroup.value._id;
        res = await this.restApiService.service('user-practice').create(practiceGroup.value);
        resultMsg = this.translate.instant('food_diary.PRACTICE_ADDED');
      } else {
        res = await this.restApiService.service('user-practice').patch(practiceGroup.value._id, practiceGroup.value);
        resultMsg = this.translate.instant('food_diary.PRACTICE_EDITED');
      }
      this._snackBar.open(resultMsg, null, {
        duration: 2000,
      });

      if (!this.onlyDialog) {
        this.allPractices = await this.getPractices(this.userId, 1);
      }
      
      this.created.emit(res);
    } catch (e) {
      console.error(e);
      const errorMsg = this.translate.instant('food_diary.PRACTICE_ERROR');
      this._snackBar.open(errorMsg, null, {
        duration: 2000,
      });
    }
    return res;
  }

  practiceCustomValidator(formGroup: FormGroup) {
    if (formGroup.value.usr_practice_type === 'אחר' && formGroup.value.usr_practice_type_other_text === '') {
      return {
        msg: 'דרוש לציין את סוג האימון'
      }
    }
    return null;
  }

  async removePracticeDialog(practice: any) {
    const title = practice.usr_practice_type === 'אחר' ? practice.usr_practice_type_other_text : practice.usr_practice_type;
    const dialogRef = this.dialog.open(CustomDialogComponent, {
      // width: '50%',
      data: { 
        title: this.translate.instant('food_diary.REMOVE_ITEM'),
        subtitle: this.translate.instant('food_diary.REMOVE_ITEM_EXPLAIN') + ' ' + title + "?",
        ok: this.translate.instant('general.yes'),
        cancel: this.translate.instant('general.no'),
      }
    });

    dialogRef.afterClosed().subscribe(async result => {
      if (!result) {
        this.removed.emit();
        return;
      }
      await this.onRemovePractice(practice);
    });

  }

  async onRemovePractice(practice: any) {
    let res: any;
    try {
      res = await this.restApiService.service('user-practice').delete(practice._id);
      const resultMsg = this.translate.instant('food_diary.PRACTICE_DELETE');
      this._snackBar.open(resultMsg, null, {
        duration: 5000,
      });

      if (!this.onlyDialog) {
        this.allPractices = await this.getPractices(this.userId, 1);
      }

      this.removed.emit(res);
    } catch (e) {
      console.error(e);
      const errorMsg = this.translate.instant('food_diary.PRACTICE_DELETE_ERROR');
      this._snackBar.open(errorMsg, null, {
        duration: 2000,
      });
    }
    return res;
  }

}

