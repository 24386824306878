import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { AuthService, RestApiService } from '@noammazuz/vzn-feathers';

import { appState } from '@services/app-state';
import { Apputils } from '@services/app-utils';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
})
export class SplashScreenComponent implements OnInit {
  appState = appState;
  constructor(
    public authService: AuthService<any>,
    private restApiService: RestApiService<any>,
    private router: Router,
    public translate: TranslateService
  ) {

  }

  async ngOnInit() {
    try {
      const user = await this.authService.isAuth().catch(err => { console.error(err); });
      
      if (user) {
        const logged = await this.restApiService.service('users').patch(user._id, { last_login: true });
        console.log('logged ', logged);
        this.appState.user = user;
        
        if (this.translate.currentLang !== 'en') {
          if (this.appState.user.usr_gender === 'נקבה') {
            this.translate.use('he_F');
          } else {
            this.translate.use('he');
          }            
        }
        
        console.log("calling flutter action from splash-screen");
        Apputils.flutterAction(Apputils.FlutterActions.GET_TOKEN);

        const usrId = this.appState.user._id;
        let navTo = '';
        switch (this.appState.user.usr_type) {
          case 'superadmin':
            navTo = 'Userstable';
            break;
            
          case 'admin':
            navTo = 'Club';
            break;
        
          case 'player':
            navTo = this.appState.user['usr_agreement'] && this.appState.user['usr_confirmed'] ? `Userprogress` : `Profile/${usrId}`;
            break;
        
          case 'nutri':
            navTo = 'Userstable';
            break;
        
          case 'coach':
            // navTo = `Coach`;
            navTo = this.appState.user.usr_agreement ? `Coach` : `Profile/${usrId}`;
            break;
        
          case 'parent':
            navTo = this.appState.user['usr_agreement'] ? `Userparent` : `Profile/${usrId}`;
            break;
        
          default:
            // navTo = 'login';
            navTo = 'Profile';
            break;
        }
        this.router.navigate([navTo], { replaceUrl: true });

      } else {
        this.router.navigate([`login`], { replaceUrl: true });
      }
    }
    catch (e) {
      this.router.navigate([`login`], { replaceUrl: true });
    }
  }

}
