<div class="antro-form">

    <mat-expansion-panel disabled expanded>
        <mat-expansion-panel-header>
            <!-- <mat-panel-title class="font-18 font-bold">
                שאלון בריאות
            </mat-panel-title>
            <mat-panel-description>
                מצב בריאות כללי, אלרגיות ורגישויות, מחזור (בנות), הערות בריאות, תוספים/תרופות
            </mat-panel-description> -->
            <mat-panel-title class="font-16 font-bold">
                <h4 class="m-auto">בריאות</h4>
            </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="row mb125">
            <button class="plus-button" mat-raised-button (click)="newQuizGuard()">
                {{'general.newquiz' | translate}}
                <mat-icon>add</mat-icon>
            </button>
        </div>

        <div *ngIf="oldValues.length > 0" class="big-content">
            <div class="row mb125">
                <mat-form-field *ngIf="oldValues.length > 0" class="w40">
                    <!-- <button type="button" *ngIf="selectedA" matSuffix [routerLink]="['/Healthquiz/', selectedIdA]" class="top-button" mat-stroked-button>עבור לטופס</button> -->
                    <a (click)="$event.stopPropagation()" target="quizwindow" *ngIf="selectedA" matSuffix [routerLink]="['/Healthquiz/', selectedIdA]" class="top-button" mat-stroked-button>עבור לטופס</a>
                    <mat-select [disabled]="true" [(ngModel)]="selectedIdA" (ngModelChange)="selectDateA($event)">
                        <ng-container *ngFor="let val of oldValues; let i = index">
                            <mat-option *ngIf="selectedIdB != val._id" [value]="val._id">
                                {{val['createdAt'] | date:'dd/MM/yyyy' }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>

                <button *ngIf="oldValues.length > 1" mat-stroked-button color="primary" class="w15 wrap-text"
                    (click)="fetchOldValues(true)">אפס לשתי המדידות האחרונות</button>

                <mat-form-field *ngIf="oldValues.length > 1" class="w40">
                    <!-- <button type="button" *ngIf="selectedB" matSuffix [routerLink]="['/Healthquiz/', selectedIdB]" class="top-button" mat-stroked-button>עבור לטופס</button> -->
                    <a (click)="$event.stopPropagation()" target="quizwindow" *ngIf="selectedB" matSuffix [routerLink]="['/Healthquiz/', selectedIdB]" class="top-button" mat-stroked-button>עבור לטופס</a>
                    <mat-select [(ngModel)]="selectedIdB" (ngModelChange)="selectDateB($event)">
                        <ng-container *ngFor="let val of oldValues; let i = index">
                            <mat-option *ngIf="selectedIdA != val._id" [value]="val._id">
                                {{val['createdAt'] | date:'dd/MM/yyyy' }}
                            </mat-option>
                        </ng-container>
                    </mat-select>
                </mat-form-field>
            </div>

            <h3>מצב בריאותי כללי</h3>
            <div class="row healthquiz-box">

                <div class="w40" *ngIf="selectedA">
                    
                    <p class="font-bold text-danger" *ngIf="selectedA['heq_tiredness_during_day'] == 'כן'">
                        יש עייפות במהלך היום
                    </p>
                    <p *ngIf="selectedA['heq_tiredness_during_day'] == 'לא'">
                        אין עייפות במהלך היום
                    </p>

                    <ng-container *ngIf="selectedA.heq_suffer_various == 'no'">
                        <div class="font-16 font-bold">אין תלונות</div>
                    </ng-container>

                    <ng-container
                        *ngIf="selectedA.heq_suffer_various == 'yes' && selectedA.heq_suffer_weekness_tired_train">
                        <div>
                            <span class="font-bold">{{selectedA.heq_suffer_weekness_tired_train_desc}}</span> אני מרגיש עייפות או חולשה במהלך אימון
                        </div>
                    </ng-container>

                    <ng-container *ngIf="selectedA.heq_suffer_various == 'yes' && selectedA.heq_suffer_digestion">
                        <div class="mt125 mb50">הבעיות מהן אני סובל במערכת העיכול:</div>
                        <div class="heq-col">
                            <ul>
                                <li *ngIf="selectedA.heq_suffer_digestion_gas" class="font-bold">גזים</li>
                                <li *ngIf="selectedA.heq_suffer_digestion_stomach_pain" class="font-bold">כאבי בטן</li>
                                <li *ngIf="selectedA.heq_suffer_digestion_diarrhea" class="font-bold">שלשולים</li>
                                <li *ngIf="selectedA.heq_suffer_digestion_constipation" class="font-bold">עצירות</li>
                                <li *ngIf="selectedA.heq_suffer_digestion_frequent" class="font-bold">יציאות תכופות</li>
                                <li *ngIf="selectedA.heq_suffer_digestion_other" class="font-bold">{{selectedA.heq_suffer_digestion_other_text}}</li>
                            </ul>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="selectedA.heq_suffer_various == 'yes' && selectedA.heq_suffer_digestion">
                        <div *ngIf="selectedA.heq_suffer_digestion_excited == 'yes'" class="mt125 mb50">
                            ההפרעות במערכת העיכול מתרחשות בעיקר במשחקים או מצבי התרגשות אחרים
                        </div>
                        <div *ngIf="selectedA.heq_suffer_digestion_excited == 'no'" class="mt125 mb50">
                            אני סובל מהפרעות במערכת העיכול לעיתים תכופות, הבעיה היא: <span class="font-bold">{{selectedA.heq_suffer_digestion_excited_text}}</span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="selectedA.heq_suffer_various == 'yes' && selectedA.heq_suffer_digestion">
                        <div *ngIf="selectedA.heq_suffer_digestion_comment == 'yes'" class="mt125 mb50">
                            הערה: <span class="font-bold">{{selectedA.heq_suffer_digestion_comment_text}}</span>
                        </div>
                    </ng-container>

                    <ng-container
                        *ngIf="selectedA.heq_suffer_various == 'yes' && selectedA.heq_suffer_bad_feeling_or_weekness">
                        יש לי הרגשה כללית לא טובה או חולשה. כך היא באה לידי ביטוי: <span class="font-bold">{{selectedA.heq_suffer_bad_feeling_or_weekness_desc}}</span>
                    </ng-container>

                    <ng-container
                        *ngIf="selectedA.heq_suffer_various == 'yes' && selectedA.heq_suffer_chronic_condition == true && selectedA.heq_suffer_chronic_condition_arr.length > 0"
                        [ngTemplateOutlet]="sufferTemplate" [ngTemplateOutletContext]="{
                        title: 'בעיות רפואיות כרוניות',
                        formContext: notesFormA,        
                        data: selectedA,
                        checkboxFieldName: '',
                        arrFieldName: 'heq_suffer_chronic_condition_arr',
                        descriptionFieldLabel: 'אני סובל מ',
                        addButtonTitle: 'הוסף בעיה רפואית כרונית',
                        removeButtonTitle: 'הסר בעיה רפואית כרונית',
                        addOnClick: doNothing,
                        removeOnClick: doNothing
                    }">
                    </ng-container>

                    <ng-container
                        *ngIf="selectedA.heq_suffer_various == 'yes' && selectedA.heq_suffer_acute_condition == true && selectedA.heq_suffer_acute_condition_arr.length > 0"
                        [ngTemplateOutlet]="sufferTemplate" [ngTemplateOutletContext]="{
                        title: 'בעיות רפואיות אקוטיות',
                        formContext: notesFormA, 
                        data: selectedA,                  
                        checkboxFieldName: '',
                        arrFieldName: 'heq_suffer_acute_condition_arr',
                        descriptionFieldLabel: 'אני סובל מ',
                        addButtonTitle: 'הוסף בעיה רפואית אקוטית',
                        removeButtonTitle: 'הסר בעיה רפואית אקוטית',
                        addOnClick: doNothing,
                        removeOnClick: doNothing
                    }">
                    </ng-container>

                    <ng-container
                        *ngIf="selectedA.heq_suffer_various == 'yes' && selectedA.heq_suffer_acute_injury == true && selectedA.heq_suffer_acute_injury_arr.length > 0"
                        [ngTemplateOutlet]="sufferTemplate" [ngTemplateOutletContext]="{
                        title: 'פציעות אקוטיות',
                        formContext: notesFormA,   
                        data: selectedA,                
                        checkboxFieldName: '',
                        arrFieldName: 'heq_suffer_acute_injury_arr',
                        descriptionFieldLabel: 'אני סובל מ',
                        addButtonTitle: 'הוסף פציעה אקוטית',
                        removeButtonTitle: 'הסר פציעה אקוטית',
                        addOnClick: doNothing,
                        removeOnClick: doNothing
                    }">
                    </ng-container>

                </div>

                <div class="w40" *ngIf="selectedB">
                    
                    <p class="font-bold text-danger" *ngIf="selectedB['heq_tiredness_during_day'] == 'כן'">
                        יש עייפות במהלך היום
                    </p>
                    <p *ngIf="selectedB['heq_tiredness_during_day'] == 'לא'">
                        אין עייפות במהלך היום
                    </p>

                    <ng-container *ngIf="selectedB.heq_suffer_various == 'no'">
                        <div class="font-16 font-bold">אין תלונות</div>
                    </ng-container>

                    <ng-container
                        *ngIf="selectedB.heq_suffer_various == 'yes' && selectedB.heq_suffer_weekness_tired_train">
                        <div>
                            <span class="font-bold">{{selectedB.heq_suffer_weekness_tired_train_desc}}</span> אני מרגיש עייפות או חולשה במהלך אימון
                        </div>
                    </ng-container>

                    <ng-container *ngIf="selectedB.heq_suffer_various == 'yes' && selectedB.heq_suffer_digestion">
                        <div class="mt125 mb50">הבעיות מהן אני סובל במערכת העיכול:</div>
                        <div class="heq-col">
                            <ul>
                                <li *ngIf="selectedB.heq_suffer_digestion_gas" class="font-bold">גזים</li>
                                <li *ngIf="selectedB.heq_suffer_digestion_stomach_pain" class="font-bold">כאבי בטן</li>
                                <li *ngIf="selectedB.heq_suffer_digestion_diarrhea" class="font-bold">שלשולים</li>
                                <li *ngIf="selectedB.heq_suffer_digestion_constipation" class="font-bold">עצירות</li>
                                <li *ngIf="selectedB.heq_suffer_digestion_frequent" class="font-bold">יציאות תכופות</li>
                                <li *ngIf="selectedB.heq_suffer_digestion_other" class="font-bold">{{selectedB.heq_suffer_digestion_other_text}}</li>
                            </ul>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="selectedB.heq_suffer_various == 'yes' && selectedB.heq_suffer_digestion">
                        <div *ngIf="selectedB.heq_suffer_digestion_excited == 'yes'" class="mt125 mb50">
                            ההפרעות במערכת העיכול מתרחשות בעיקר במשחקים או מצבי התרגשות אחרים
                        </div>
                        <div *ngIf="selectedB.heq_suffer_digestion_excited == 'no'" class="mt125 mb50">
                            אני סובל מהפרעות במערכת העיכול לעיתים תכופות, הבעיה היא: <span class="font-bold">{{selectedB.heq_suffer_digestion_excited_text}}</span>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="selectedB.heq_suffer_various == 'yes' && selectedB.heq_suffer_digestion">
                        <div *ngIf="selectedB.heq_suffer_digestion_comment == 'yes'" class="mt125 mb50">
                            הערה: <span class="font-bold">{{selectedB.heq_suffer_digestion_comment_text}}</span>
                        </div>
                    </ng-container>

                    <ng-container
                        *ngIf="selectedB.heq_suffer_various == 'yes' && selectedB.heq_suffer_bad_feeling_or_weekness">
                        יש לי הרגשה כללית לא טובה או חולשה. כך היא באה לידי ביטוי: <span class="font-bold">{{selectedB.heq_suffer_bad_feeling_or_weekness_desc}}</span>
                    </ng-container>

                    <ng-container
                        *ngIf="selectedB.heq_suffer_various == 'yes' && selectedB.heq_suffer_chronic_condition == true && selectedB.heq_suffer_chronic_condition_arr.length > 0"
                        [ngTemplateOutlet]="sufferTemplate" [ngTemplateOutletContext]="{
                        title: 'בעיות רפואיות כרוניות',
                        formContext: notesFormB,        
                        data: selectedB,
                        checkboxFieldName: '',
                        arrFieldName: 'heq_suffer_chronic_condition_arr',
                        descriptionFieldLabel: 'אני סובל מ',
                        addButtonTitle: 'הוסף בעיה רפואית כרונית',
                        removeButtonTitle: 'הסר בעיה רפואית כרונית',
                        addOnClick: doNothing,
                        removeOnClick: doNothing
                    }">
                    </ng-container>

                    <ng-container
                        *ngIf="selectedB.heq_suffer_various == 'yes' && selectedB.heq_suffer_acute_condition == true && selectedB.heq_suffer_acute_condition_arr.length > 0"
                        [ngTemplateOutlet]="sufferTemplate" [ngTemplateOutletContext]="{
                        title: 'בעיות רפואיות אקוטיות',
                        formContext: notesFormB, 
                        data: selectedB,                  
                        checkboxFieldName: '',
                        arrFieldName: 'heq_suffer_acute_condition_arr',
                        descriptionFieldLabel: 'אני סובל מ',
                        addButtonTitle: 'הוסף בעיה רפואית אקוטית',
                        removeButtonTitle: 'הסר בעיה רפואית אקוטית',
                        addOnClick: doNothing,
                        removeOnClick: doNothing
                    }">
                    </ng-container>

                    <ng-container
                        *ngIf="selectedB.heq_suffer_various == 'yes' && selectedB.heq_suffer_acute_injury == true && selectedB.heq_suffer_acute_injury_arr.length > 0"
                        [ngTemplateOutlet]="sufferTemplate" [ngTemplateOutletContext]="{
                        title: 'פציעות אקוטיות',
                        formContext: notesFormB,   
                        data: selectedB,                
                        checkboxFieldName: '',
                        arrFieldName: 'heq_suffer_acute_injury_arr',
                        descriptionFieldLabel: 'אני סובל מ',
                        addButtonTitle: 'הוסף פציעה אקוטית',
                        removeButtonTitle: 'הסר פציעה אקוטית',
                        addOnClick: doNothing,
                        removeOnClick: doNothing
                    }">
                    </ng-container>

                </div>

            </div>

            <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <h4 class="ul-text">הערות לתחילת הדוח</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('heq_notes')" fieldKey="A" [oldNotes]="notesFormA.get('notes_heq_a')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'heq', 'A')" (editNoteEvent)="editNote($event, 'heq', 'A')"></notes>
                    </div>
                    
                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('heq_notes')" fieldKey="A" [oldNotes]="notesFormB.get('notes_heq_a')" [timeCreated]="selectedB.createdAt"></notes>
                    </div>
                </div>                
            </ng-container>
            <!-- <ng-container *ngIf="!(selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <ng-container *ngTemplateOutlet="templateS1; context: { subPrefix: 'a' }">
                </ng-container>
            </ng-container> -->
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's1', subPrefix: 'a', title: 'הערות לתחילת הדוח' }"></ng-container>
            </ng-container>

            <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <h4 class="ul-text">הערות תזונה כלליות</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('heq_notes')" fieldKey="B" [oldNotes]="notesFormA.get('notes_heq_b')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'heq', 'B')" (editNoteEvent)="editNote($event, 'heq', 'B')"></notes>
                    </div>
                    
                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('heq_notes')" fieldKey="B" [oldNotes]="notesFormB.get('notes_heq_b')" [timeCreated]="selectedB.createdAt"></notes>
                    </div>
                </div>
            </ng-container>
            <!-- <ng-container *ngIf="!(selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <ng-container *ngTemplateOutlet="templateS3; context: { subPrefix: 'a' }">
                </ng-container>
            </ng-container> -->
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'a', title: 'הערות תזונה כלליות' }"></ng-container>
            </ng-container>

            <!-- <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS10">

            </ng-container> -->
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's10', subPrefix: '', title: 'הערות נוזלים ללא אימון' }"></ng-container>
            </ng-container>
            
            <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <h4 class="ul-text">הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('heq_notes')" fieldKey="C" [oldNotes]="notesFormA.get('notes_heq_c')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'heq', 'C')" (editNoteEvent)="editNote($event, 'heq', 'C')"></notes>
                    </div>
                    
                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('heq_notes')" fieldKey="C" [oldNotes]="notesFormB.get('notes_heq_c')" [timeCreated]="selectedB.createdAt"></notes>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's2', subPrefix: '', title: 'הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון' }"></ng-container>
            </ng-container>

            <h3>אלרגיות ורגישויות</h3>
            <div class="row healthquiz-box">

                <div class="w40" *ngIf="selectedA">

                    <ng-container *ngIf="selectedA.heq_food_allergy_intolerance == 'לא'">
                        <div class="font-16 font-bold">אין אלרגיות או רגישויות</div>
                    </ng-container>

                    <ng-container
                        *ngIf="(selectedA.createdAt > '2022-08-11T11:00:00.000Z') && selectedA.heq_food_allergy_intolerance == 'כן' && selectedA.heq_allergy_arr && selectedA.heq_allergy_arr.length > 0"
                        [ngTemplateOutlet]="sufferTemplateNuq" [ngTemplateOutletContext]="{
                            title: 'אלרגיות ורגישויות',
                            formContext: notesFormA,        
                            data: selectedA,          
                            checkboxFieldName: '',
                            arrFieldName: 'heq_allergy_arr',
                            productNameFieldLabel: 'אני אלרגי ל',
                            howDiagnosedFieldLabel: 'האלרגיה אובחנה על ידי',
                            moreCommentsTitle: 'האם ברצונך להכניס הערה נוספת בנוגע לאלרגיה?',
                            addButtonTitle: 'הוסף אלרגיה',
                            removeButtonTitle: 'הסר אלרגיה',
                            addOnClick: doNothing,
                            removeOnClick: doNothing
                        }">
                    </ng-container>

                    <ng-container *ngIf="!(selectedA.createdAt > '2022-08-11T11:00:00.000Z')">
                        <ng-container
                            *ngIf="selectedA.heq_food_allergy_intolerance == 'כן' && selectedA.heq_food_allergy == true && selectedA.heq_food_allergy_arr.length > 0"
                            [ngTemplateOutlet]="sufferTemplateNuq" [ngTemplateOutletContext]="{
                                title: 'אלרגיות',
                                formContext: notesFormA,        
                                data: selectedA,          
                                checkboxFieldName: '',
                                arrFieldName: 'heq_food_allergy_arr',
                                productNameFieldLabel: 'אני אלרגי ל',
                                howDiagnosedFieldLabel: 'האלרגיה אובחנה על ידי',
                                moreCommentsTitle: 'האם ברצונך להכניס הערה נוספת בנוגע לאלרגיה?',
                                addButtonTitle: 'הוסף אלרגיה',
                                removeButtonTitle: 'הסר אלרגיה',
                                addOnClick: doNothing,
                                removeOnClick: doNothing
                            }">
                        </ng-container>

                        <ng-container
                            *ngIf="selectedA.heq_food_allergy_intolerance == 'כן' && selectedA.heq_food_intolerance == true && selectedA.heq_food_intolerance_arr.length > 0"
                            [ngTemplateOutlet]="sufferTemplateNuq" [ngTemplateOutletContext]="{
                                title: 'רגישויות',
                                formContext: notesFormA,        
                                data: selectedA,          
                                checkboxFieldName: '',
                                arrFieldName: 'heq_food_intolerance_arr',
                                productNameFieldLabel: 'אני רגיש ל',
                                howDiagnosedFieldLabel: 'הרגישות אובחנה על ידי',
                                moreCommentsTitle: 'האם ברצונך להכניס הערה נוספת בנוגע לרגישות?',
                                addButtonTitle: 'הוסף רגישות',
                                removeButtonTitle: 'הסר רגישות',
                                addOnClick: doNothing,
                                removeOnClick: doNothing
                            }">
                        </ng-container>
                    </ng-container>

                </div>

                <div class="w40" *ngIf="selectedB">

                    <ng-container *ngIf="selectedB.heq_food_allergy_intolerance == 'לא'">
                        <div class="font-16 font-bold">אין אלרגיות או רגישויות</div>
                    </ng-container>

                    <ng-container
                        *ngIf="(selectedB.createdAt > '2022-08-11T11:00:00.000Z') && selectedB.heq_food_allergy_intolerance == 'כן' && selectedB.heq_allergy_arr && selectedB.heq_allergy_arr.length > 0"
                        [ngTemplateOutlet]="sufferTemplateNuq" [ngTemplateOutletContext]="{
                            title: 'אלרגיות ורגישויות',
                            formContext: notesFormB,        
                            data: selectedB,          
                            checkboxFieldName: '',
                            arrFieldName: 'heq_allergy_arr',
                            productNameFieldLabel: 'אני אלרגי ל',
                            howDiagnosedFieldLabel: 'האלרגיה אובחנה על ידי',
                            moreCommentsTitle: 'האם ברצונך להכניס הערה נוספת בנוגע לאלרגיה?',
                            addButtonTitle: 'הוסף אלרגיה',
                            removeButtonTitle: 'הסר אלרגיה',
                            addOnClick: doNothing,
                            removeOnClick: doNothing
                        }">
                    </ng-container>

                    <ng-container *ngIf="!(selectedB.createdAt > '2022-08-11T11:00:00.000Z')">
                        <ng-container
                            *ngIf="selectedB.heq_food_allergy_intolerance == 'כן' && selectedB.heq_food_allergy == true && selectedB.heq_food_allergy_arr.length > 0"
                            [ngTemplateOutlet]="sufferTemplateNuq" [ngTemplateOutletContext]="{
                                title: 'אלרגיות',
                                formContext: notesFormB,        
                                data: selectedB,          
                                checkboxFieldName: '',
                                arrFieldName: 'heq_food_allergy_arr',
                                productNameFieldLabel: 'אני אלרגי ל',
                                howDiagnosedFieldLabel: 'האלרגיה אובחנה על ידי',
                                moreCommentsTitle: 'האם ברצונך להכניס הערה נוספת בנוגע לאלרגיה?',
                                addButtonTitle: 'הוסף אלרגיה',
                                removeButtonTitle: 'הסר אלרגיה',
                                addOnClick: doNothing,
                                removeOnClick: doNothing
                            }">
                        </ng-container>

                        <ng-container
                            *ngIf="selectedB.heq_food_allergy_intolerance == 'כן' && selectedB.heq_food_intolerance == true && selectedB.heq_food_intolerance_arr.length > 0"
                            [ngTemplateOutlet]="sufferTemplateNuq" [ngTemplateOutletContext]="{
                                title: 'רגישויות',
                                formContext: notesFormB,        
                                data: selectedB,          
                                checkboxFieldName: '',
                                arrFieldName: 'heq_food_intolerance_arr',
                                productNameFieldLabel: 'אני רגיש ל',
                                howDiagnosedFieldLabel: 'הרגישות אובחנה על ידי',
                                moreCommentsTitle: 'האם ברצונך להכניס הערה נוספת בנוגע לרגישות?',
                                addButtonTitle: 'הוסף רגישות',
                                removeButtonTitle: 'הסר רגישות',
                                addOnClick: doNothing,
                                removeOnClick: doNothing
                            }">
                        </ng-container>
                    </ng-container>

                </div>

            </div>

        <ng-container *ngIf="(selectedA && selectedA.heq_more_comments == 'yes') || (selectedB && selectedB.heq_more_comments == 'yes')">
            <h3>הערות בריאות</h3>
            <div class="row healthquiz-box">
                <div class="w40" *ngIf="selectedA">
                    <div *ngIf="selectedA.heq_more_comments == 'yes'">
                        ברצוני לציין ש<span class="font-bold">{{selectedA.heq_more_comments_text}}</span>
                    </div>
                </div>
                <div class="w40" *ngIf="selectedB">
                    <div *ngIf="selectedB.heq_more_comments == 'yes'">
                        ברצוני לציין ש<span class="font-bold">{{selectedB.heq_more_comments_text}}</span>
                    </div>
                </div>
            </div>
        </ng-container>

            <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <h4 class="ul-text">הערות לתחילת הדוח</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('heq_notes')" fieldKey="D" [oldNotes]="notesFormA.get('notes_heq_d')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'heq', 'D')" (editNoteEvent)="editNote($event, 'heq', 'D')"></notes>
                    </div>
                    
                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('heq_notes')" fieldKey="D" [oldNotes]="notesFormB.get('notes_heq_d')" [timeCreated]="selectedB.createdAt"></notes>
                    </div>
                </div>                
            </ng-container>
            <!-- <ng-container *ngIf="!(selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <ng-container *ngTemplateOutlet="templateS1; context: { subPrefix: 'b' }">
                </ng-container>
            </ng-container> -->
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's1', subPrefix: 'b', title: 'הערות לתחילת הדוח' }"></ng-container>
            </ng-container>

            <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <h4 class="ul-text">הערות תזונה</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('heq_notes')" fieldKey="E" [oldNotes]="notesFormA.get('notes_heq_e')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'heq', 'E')" (editNoteEvent)="editNote($event, 'heq', 'E')"></notes>
                    </div>
                    
                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('heq_notes')" fieldKey="E" [oldNotes]="notesFormB.get('notes_heq_e')" [timeCreated]="selectedB.createdAt"></notes>
                    </div>
                </div>
            </ng-container>
            <!-- <ng-container *ngIf="!(selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <ng-container *ngTemplateOutlet="templateS3; context: { subPrefix: 'b' }">
                </ng-container>
            </ng-container> -->
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'b', title: 'הערות תזונה כלליות' }"></ng-container>
            </ng-container>

            <h3>תרופות ותוספים</h3>
            <div class="row healthquiz-box">

                <div class="w40" *ngIf="selectedA">

                    <ng-container *ngIf="selectedA.heq_using_various == 'no'">
                        <div class="font-16 font-bold">אין תרופות או תוספים</div>
                    </ng-container>

                    <ng-container
                        *ngIf="selectedA.heq_using_various == 'yes' && selectedA.heq_using_chronic_medication == true && selectedA.heq_using_chronic_medication_arr.length > 0"
                        [ngTemplateOutlet]="usingTemplate" [ngTemplateOutletContext]="{
                            title: 'תרופות',
                            formContext: notesFormA,
                            data: selectedA,
                            checkboxFieldName: '',
                            arrFieldName: 'heq_using_chronic_medication_arr',
                            descriptionFieldLabel: 'איזו תרופה?',
                            commentTitle: 'האם ברצונך להכניס הערה נוספת לגבי התרופה?',
                            addButtonTitle: 'הוסף תרופה',
                            removeButtonTitle: 'הסר תרופה',
                            addOnClick: doNothing,
                            removeOnClick: doNothing
                        }">
                    </ng-container>

                    <ng-container
                        *ngIf="selectedA.heq_using_various == 'yes' && selectedA.heq_using_supplements_vitamins == true && selectedA.heq_using_supplements_vitamins_arr.length > 0"
                        [ngTemplateOutlet]="usingTemplate" [ngTemplateOutletContext]="{
                                title: 'תוספים/ויטמינים',
                                formContext: notesFormA,
                                data: selectedA,
                                checkboxFieldName: '',
                                arrFieldName: 'heq_using_supplements_vitamins_arr',
                                descriptionFieldLabel: 'איזה תוסף/ ויטמין?',
                                commentTitle: 'האם ברצונך להכניס הערה נוספת לגבי התוסף/ ויטמין?',
                                addButtonTitle: 'הוסף תוסף/ ויטמין',
                                removeButtonTitle: 'הסר תוסף/ ויטמין',
                                addOnClick: doNothing,
                                removeOnClick: doNothing
                            }">

                    </ng-container>

                    <ng-container
                        *ngIf="selectedA.heq_using_various == 'yes' && selectedA.heq_using_protein_powder == true && selectedA.heq_using_protein_powder_arr.length > 0"
                        [ngTemplateOutlet]="usingTemplate" [ngTemplateOutletContext]="{
                                title: 'אבקות/חטיפי חלבון',
                                formContext: notesFormA,
                                data: selectedA,
                                checkboxFieldName: '',
                                arrFieldName: 'heq_using_protein_powder_arr',
                                descriptionFieldLabel: 'איזה אבקת/ חטיף חלבון?',
                                commentTitle: 'האם ברצונך להכניס הערה נוספת לגבי אבקת/ חטיף חלבון?',
                                addButtonTitle: 'הוסף אבקת/ חטיף חלבון',
                                removeButtonTitle: 'הסר אבקת/ חטיף חלבון',
                                addOnClick: doNothing,
                                removeOnClick: doNothing
                            }">
                    </ng-container>

                </div>

                <div class="w40" *ngIf="selectedB">

                    <ng-container *ngIf="selectedB.heq_using_various == 'no'">
                        <div class="font-16 font-bold">אין תרופות או תוספים</div>
                    </ng-container>

                    <ng-container
                        *ngIf="selectedB.heq_using_various == 'yes' && selectedB.heq_using_chronic_medication == true && selectedB.heq_using_chronic_medication_arr.length > 0"
                        [ngTemplateOutlet]="usingTemplate" [ngTemplateOutletContext]="{
                            title: 'תרופות',
                            formContext: notesFormB,
                            data: selectedB,
                            checkboxFieldName: '',
                            arrFieldName: 'heq_using_chronic_medication_arr',
                            descriptionFieldLabel: 'איזו תרופה?',
                            commentTitle: 'האם ברצונך להכניס הערה נוספת לגבי התרופה?',
                            addButtonTitle: 'הוסף תרופה',
                            removeButtonTitle: 'הסר תרופה',
                            addOnClick: doNothing,
                            removeOnClick: doNothing
                        }">
                    </ng-container>

                    <ng-container
                        *ngIf="selectedB.heq_using_various == 'yes' && selectedB.heq_using_supplements_vitamins == true && selectedB.heq_using_supplements_vitamins_arr.length > 0"
                        [ngTemplateOutlet]="usingTemplate" [ngTemplateOutletContext]="{
                                title: 'תוספים/ויטמינים',
                                formContext: notesFormB,
                                data: selectedB,
                                checkboxFieldName: '',
                                arrFieldName: 'heq_using_supplements_vitamins_arr',
                                descriptionFieldLabel: 'איזה תוסף/ ויטמין?',
                                commentTitle: 'האם ברצונך להכניס הערה נוספת לגבי התוסף/ ויטמין?',
                                addButtonTitle: 'הוסף תוסף/ ויטמין',
                                removeButtonTitle: 'הסר תוסף/ ויטמין',
                                addOnClick: doNothing,
                                removeOnClick: doNothing
                            }">

                    </ng-container>

                    <ng-container
                        *ngIf="selectedB.heq_using_various == 'yes' && selectedB.heq_using_protein_powder == true && selectedB.heq_using_protein_powder_arr.length > 0"
                        [ngTemplateOutlet]="usingTemplate" [ngTemplateOutletContext]="{
                                title: 'אבקות/חטיפי חלבון',
                                formContext: notesFormB,
                                data: selectedB,
                                checkboxFieldName: '',
                                arrFieldName: 'heq_using_protein_powder_arr',
                                descriptionFieldLabel: 'איזה אבקת/ חטיף חלבון?',
                                commentTitle: 'האם ברצונך להכניס הערה נוספת לגבי אבקת/ חטיף חלבון?',
                                addButtonTitle: 'הוסף אבקת/ חטיף חלבון',
                                removeButtonTitle: 'הסר אבקת/ חטיף חלבון',
                                addOnClick: doNothing,
                                removeOnClick: doNothing
                            }">
                    </ng-container>

                </div>

            </div>

            <!-- <ng-container *ngIf="selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z';else templateS4">

            </ng-container> -->
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's4', subPrefix: '', title: 'הערות ארוחת בוקר' }"></ng-container>
            </ng-container>

            <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <h4 class="ul-text">הערות תזונה</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('heq_notes')" fieldKey="G" [oldNotes]="notesFormA.get('notes_heq_g')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'heq', 'G')" (editNoteEvent)="editNote($event, 'heq', 'G')"></notes>
                    </div>
                    
                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('heq_notes')" fieldKey="G" [oldNotes]="notesFormB.get('notes_heq_g')" [timeCreated]="selectedB.createdAt"></notes>
                    </div>
                </div>
            </ng-container>
            <!-- <ng-container *ngIf="!(selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <ng-container *ngTemplateOutlet="templateS3; context: { subPrefix: 'c' }">
                </ng-container>
            </ng-container> -->
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'c', title: 'הערות תזונה כלליות' }"></ng-container>
            </ng-container>

            <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <h4 class="ul-text">הערות לתחילת הדוח</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('heq_notes')" fieldKey="F" [oldNotes]="notesFormA.get('notes_heq_f')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'heq', 'F')" (editNoteEvent)="editNote($event, 'heq', 'F')"></notes>
                    </div>
                    
                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('heq_notes')" fieldKey="F" [oldNotes]="notesFormB.get('notes_heq_f')" [timeCreated]="selectedB.createdAt"></notes>
                    </div>
                </div>                
            </ng-container>
            <!-- <ng-container *ngIf="!(selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                <ng-container *ngTemplateOutlet="templateS1; context: { subPrefix: 'c' }">
                </ng-container>
            </ng-container> -->
            <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's1', subPrefix: 'c', title: 'הערות לתחילת הדוח' }"></ng-container>
            </ng-container>

            <ng-container *ngIf="user.usr_gender == 'נקבה'">

                <h3>מחזור</h3>
                <div class="row healthquiz-box">

                    <div class="w40" *ngIf="selectedA">

                        <div *ngIf="selectedA.heq_period == 'no'">
                            אני לא מקבלת מחזור
                        </div>
                        <ng-container *ngIf="selectedA.heq_period == 'yes'">
                            <div>
                                אני מקבלת מחזור
                            </div>
                            <div>
                                קיבלתי מחזור בפעם הראשונה בתאריך<span class="font-bold"> {{selectedA.heq_period_start_date | date:'dd/MM/yyyy'}}</span>
                            </div>
                            <div *ngIf="selectedA.heq_irregular_period == 'yes'">המחזור הוא סדיר</div>
                            <ng-container *ngIf="selectedA.heq_irregular_period == 'no'">
                                <div>המחזור הוא לא סדיר</div>
                                <div>
                                    <span>אי הסדירות שלי מתבטא ב</span>
                                    <span *ngIf="selectedA.heq_irregular_period_status != 'אחר'" class="font-bold">{{ selectedA.heq_irregular_period_status }}</span>
                                    <span *ngIf="selectedA.heq_irregular_period_status == 'אחר'" class="font-bold">{{ selectedA.heq_irregular_period_status_other_text }}</span>
                                </div>
                            </ng-container>
                            <div *ngIf="selectedA.heq_taking_pills == 'yes'">אני נוטלת גלולות</div>
                            <div *ngIf="selectedA.heq_taking_pills == 'no'">אני לא נוטלת גלולות</div>
                            <div *ngIf="selectedA.heq_period_comments == 'yes'">ברצוני להוסיף ש<span class="font-bold">{{ selectedA.heq_period_comments_text }}</span></div>
                        </ng-container>

                    </div>

                    <div class="w40" *ngIf="selectedB">

                        <div *ngIf="selectedB.heq_period == 'no'">
                            אני לא מקבלת מחזור
                        </div>
                        <ng-container *ngIf="selectedB.heq_period == 'yes'">
                            <div>
                                אני מקבלת מחזור
                            </div>
                            <div>
                                קיבלתי מחזור בפעם הראשונה בתאריך<span class="font-bold"> {{selectedB.heq_period_start_date | date:'dd/MM/yyyy'}}</span>
                            </div>
                            <div *ngIf="selectedB.heq_irregular_period == 'yes'">המחזור הוא סדיר</div>
                            <ng-container *ngIf="selectedB.heq_irregular_period == 'no'">
                                <div>המחזור הוא לא סדיר</div>
                                <div>
                                    <span>אי הסדירות שלי מתבטא ב</span>
                                    <span *ngIf="selectedB.heq_irregular_period_status != 'אחר'" class="font-bold">{{ selectedB.heq_irregular_period_status }}</span>
                                    <span *ngIf="selectedB.heq_irregular_period_status == 'אחר'" class="font-bold">{{ selectedB.heq_irregular_period_status_other_text }}</span>
                                </div>
                            </ng-container>
                            <div *ngIf="selectedB.heq_taking_pills == 'yes'">אני נוטלת גלולות</div>
                            <div *ngIf="selectedB.heq_taking_pills == 'no'">אני לא נוטלת גלולות</div>
                            <div *ngIf="selectedB.heq_period_comments == 'yes'">ברצוני להוסיף ש<span class="font-bold">{{ selectedB.heq_period_comments_text }}</span></div>
                        </ng-container>

                    </div>

                </div>

                <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                    <h4 class="ul-text">הערות לתחילת הדוח</h4>
                    <div class="row">
                        <div class="w40" *ngIf="selectedA">
                            <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('heq_notes')" fieldKey="H" [oldNotes]="notesFormA.get('notes_heq_h')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'heq', 'H')" (editNoteEvent)="editNote($event, 'heq', 'H')"></notes>
                        </div>
                        
                        <div class="w40" *ngIf="selectedB">
                            <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('heq_notes')" fieldKey="H" [oldNotes]="notesFormB.get('notes_heq_h')" [timeCreated]="selectedB.createdAt"></notes>
                        </div>
                    </div>                
                </ng-container>
                <!-- <ng-container *ngIf="!(selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                    <ng-container *ngTemplateOutlet="templateS1; context: { subPrefix: 'd' }">
                    </ng-container>
                </ng-container> -->
                <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                    <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's1', subPrefix: 'd', title: 'הערות לתחילת הדוח' }"></ng-container>
                </ng-container>

                <ng-container *ngIf="oldNotesFlag || (selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                    <h4 class="ul-text">הערות תזונה</h4>
                    <div class="row">
                        <div class="w40" *ngIf="selectedA">
                            <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="notesFormA.get('heq_notes')" fieldKey="I" [oldNotes]="notesFormA.get('notes_heq_i')" [timeCreated]="selectedA.createdAt" (addNoteEvent)="addNote($event, 'heq', 'I')" (editNoteEvent)="editNote($event, 'heq', 'I')"></notes>
                        </div>
                        
                        <div class="w40" *ngIf="selectedB">
                            <notes *ngIf="notesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="notesFormB.get('heq_notes')" fieldKey="I" [oldNotes]="notesFormB.get('notes_heq_i')" [timeCreated]="selectedB.createdAt"></notes>
                        </div>
                    </div>
                </ng-container>
                <!-- <ng-container *ngIf="!(selectedA && selectedA.createdAt < '2021-08-07T11:00:00.000Z')">
                    <ng-container *ngTemplateOutlet="templateS3; context: { subPrefix: 'd' }">
                    </ng-container>
                </ng-container> -->
                <ng-container *ngIf="!oldNotesFlag && (selectedA && selectedA.createdAt > '2021-08-07T11:00:00.000Z')">  
                    <ng-container *ngTemplateOutlet="templateShared; context: { fieldKey: 's3', subPrefix: 'd', title: 'הערות תזונה כלליות' }"></ng-container>
                </ng-container>

            </ng-container>
            

            <ng-template #templateShared let-fieldKey="fieldKey" let-subPrefix="subPrefix" let-title="title">
                <h4 class="font-bold text-info ul-text">{{ title }}</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && notesFormA && sharedNotesFormA">
                        <notes *ngIf="notesFormA.value._id" (linkedNotesEvent)="linkedNote($event)" [fieldKey]="fieldKey" [subKey]="subPrefix" [isFemale]="(user.usr_gender === 'נקבה')" [selectedGroup]="notesFormA.get(quizPrefix + '_notes')" [sharedGroup]="sharedNotesFormA.get('fdq_notes')" [notesArr]="notesList" (addNoteEvent)="addNote($event, quizPrefix, fieldKey, subPrefix)" (editNoteEvent)="editNote($event, quizPrefix, fieldKey, subPrefix)"></notes>
                    </div>

                    <div class="w40" *ngIf="selectedB">
                        <notes *ngIf="selectedB._id" [fieldKey]="fieldKey" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="selectedB[quizPrefix + '_notes']"></notes>
                    </div>
                </div>                
            </ng-template>


            <ng-template #templateS1 let-subPrefix="subPrefix">
                <h4 class="font-bold text-info ul-text">הערות לתחילת הדוח</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [subKey]="subPrefix" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s1" (addNoteEvent)="addNote($event, quizPrefix, 's1', subPrefix)" (editNoteEvent)="editNote($event, quizPrefix, 's1', subPrefix)"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [subKey]="subPrefix" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s1" (addNoteEvent)="addNote($event, quizPrefix, 's1', subPrefix)" (editNoteEvent)="editNote($event, quizPrefix, 's1', subPrefix)"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [subKey]="subPrefix" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s1" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s1"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s1"></notes>
                    </div>
                </div>                
            </ng-template>
            <ng-template #templateS2>
                <h4 class="font-bold text-info ul-text">הערות עיתוי אכילה, תגבור קלורי ונוזלים באימון</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s2" (addNoteEvent)="addNote($event, quizPrefix, 's2')" (editNoteEvent)="editNote($event, quizPrefix, 's2')"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s2" (addNoteEvent)="addNote($event, quizPrefix, 's2')" (editNoteEvent)="editNote($event, quizPrefix, 's2')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s2" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s2"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s2"></notes>
                    </div>
                </div>                
            </ng-template>
            <ng-template #templateS3 let-subPrefix="subPrefix">
                <h4 class="font-bold text-info ul-text">הערות תזונה כלליות</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [subKey]="subPrefix" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s3" (addNoteEvent)="addNote($event, quizPrefix, 's3', subPrefix)" (editNoteEvent)="editNote($event, quizPrefix, 's3', subPrefix)"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [subKey]="subPrefix" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s3" (addNoteEvent)="addNote($event, quizPrefix, 's3', subPrefix)" (editNoteEvent)="editNote($event, quizPrefix, 's3', subPrefix)"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [subKey]="subPrefix" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s3" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s3"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s3"></notes>
                    </div>
                </div>                
            </ng-template>
            <ng-template #templateS4>
                <h4 class="font-bold text-info ul-text">הערות ארוחת בוקר</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s4" (addNoteEvent)="addNote($event, quizPrefix, 's4')" (editNoteEvent)="editNote($event, quizPrefix, 's4')"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s4" (addNoteEvent)="addNote($event, quizPrefix, 's4')" (editNoteEvent)="editNote($event, quizPrefix, 's4')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s4" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s4"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s4"></notes>
                    </div>
                </div>                
            </ng-template>
            <ng-template #templateS10>
                <h4 class="font-bold text-info ul-text">הערות נוזלים ללא אימון</h4>
                <div class="row">
                    <div class="w40" *ngIf="selectedA && sharedNotesFormA">
                        <!-- <notes *ngIf="sharedNotesFormA.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s10" (addNoteEvent)="addNote($event, quizPrefix, 's10')" (editNoteEvent)="editNote($event, quizPrefix, 's10')"></notes> -->
                        <notes *ngIf="sharedNotesFormA.value._id && ((selectedA._id | fdqDetails : allFdqValues : quizPrefix) ? (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id === sharedNotesFormA.value._id : true)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedGroup]="sharedNotesFormA.get('fdq_notes')" fieldKey="s10" (addNoteEvent)="addNote($event, quizPrefix, 's10')" (editNoteEvent)="editNote($event, quizPrefix, 's10')"></notes>
                        <notes *ngIf="(selectedA._id | fdqDetails : allFdqValues : quizPrefix) && (selectedA._id | fdqDetails : allFdqValues : quizPrefix)._id !== sharedNotesFormA.value._id" [selectedValue]="(selectedA._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s10" [notesArr]="notesList" [isFemale]="(user.usr_gender === 'נקבה')"></notes>
                    </div>

                    <!-- <div class="w40" *ngIf="selectedB && sharedNotesFormB">
                        <notes *ngIf="sharedNotesFormB.value._id" [isFemale]="(user.usr_gender === 'נקבה')" [isReadonly]="true" [notesArr]="notesList" [selectedGroup]="sharedNotesFormB.get('fdq_notes')" fieldKey="s10"></notes>
                    </div> -->
                    <div class="w40" *ngIf="selectedB && allFdqValues">
                        <notes *ngIf="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)" [isFemale]="(user.usr_gender === 'נקבה')" [notesArr]="notesList" [selectedValue]="(selectedB._id | fdqDetails : allFdqValues : quizPrefix)['fdq_notes']" fieldKey="s10"></notes>
                    </div>
                </div>                
            </ng-template>

            <div class="row">
                <div class="w40 buttons-row" *ngIf="selectedA">
                    <button class="plus-button" mat-raised-button (click)="deleteQuiz(selectedIdA)">
                        {{'general.deleteQuiz' | translate}}
                        <mat-icon>delete</mat-icon>
                    </button>
                    <!-- <button [routerLink]="['/Healthquiz/', selectedIdA]" class="plus-button" mat-raised-button>עבור לטופס</button> -->
                    <a target="quizwindow" [routerLink]="['/Healthquiz/', selectedIdA]" class="plus-button" mat-raised-button>עבור לטופס</a>
                    <mat-slide-toggle [checked]="notesFormA.value.heq_locked" (change)="toggleQuizLock($event, notesFormA)">{{'general.lockQuiz' | translate}}</mat-slide-toggle>
                </div>
                <!-- <div class="w30 middle" *ngIf="selectedA && selectedB">
                </div> -->
                <div class="w40 buttons-row" *ngIf="selectedB">
                    <button class="plus-button" mat-raised-button (click)="deleteQuiz(selectedIdB)">
                        {{'general.deleteQuiz' | translate}}
                        <mat-icon>delete</mat-icon>
                    </button>
                    <!-- <button [routerLink]="['/Healthquiz/', selectedIdB]" class="plus-button" mat-raised-button>עבור לטופס</button> -->
                    <a target="quizwindow" [routerLink]="['/Healthquiz/', selectedIdB]" class="plus-button" mat-raised-button>עבור לטופס</a>
                    <mat-slide-toggle [checked]="notesFormB.value.heq_locked" (change)="toggleQuizLock($event, notesFormB)">{{'general.lockQuiz' | translate}}</mat-slide-toggle>
                </div>
            </div>

            <!-- <div class="mt125 text-center">
                <button mat-raised-button color="primary" class="w30" (click)="submitForm()">שמור הערות</button>
            </div> -->
        </div>
    </mat-expansion-panel>

</div>

<!-- templates: -->

<ng-template #sufferTemplate let-title="title" let-formContext="formContext" let-data="data"
    let-checkboxFieldName="checkboxFieldName" let-arrFieldName="arrFieldName"
    let-descriptionFieldLabel="descriptionFieldLabel" let-addButtonTitle="addButtonTitle"
    let-removeButtonTitle="removeButtonTitle" let-addOnClick="addOnClick" let-removeOnClick="removeOnClick">
    <form novalidate class="basic-form" [formGroup]="formContext">
        <h4>{{title}}</h4>
            <div formArrayName="{{arrFieldName}}"
                *ngFor="let control of formContext.get(arrFieldName).controls; let i = index;">
                <div [formGroupName]="i">
                    <div>{{descriptionFieldLabel}}<span class="font-bold">{{data[arrFieldName][i].description}}</span> מתאריך <span class="font-bold">{{data[arrFieldName][i].started_date | date:'dd/MM/yyyy'}}</span></div>

                    <div>כרגע אני <span class="font-bold">{{data[arrFieldName][i].current_status}}</span></div>

                    <div *ngIf="data[arrFieldName][i].current_status != '' && data[arrFieldName][i].current_status != 'מתאמן כרגיל'">
                        <div>אני צפוי לחזור לפעילות מלאה ב<span class="font-bold"> {{data[arrFieldName][i].end_date | date:'dd/MM/yyyy'}}</span></div>
                    </div>

                    <!-- <notes [notesArr]="notesList" [selectedForm]="control" [fieldName]="'notes'"></notes> -->

                </div>
            </div>
    </form>
</ng-template>

<ng-template #usingTemplate let-title="title" let-formContext="formContext" let-data="data"
    let-checkboxFieldName="checkboxFieldName" let-arrFieldName="arrFieldName"
    let-descriptionFieldLabel="descriptionFieldLabel" let-commentTitle="commentTitle"
    let-addButtonTitle="addButtonTitle" let-removeButtonTitle="removeButtonTitle" let-addOnClick="addOnClick"
    let-removeOnClick="removeOnClick">
    <form novalidate class="basic-form" [formGroup]="formContext">
        <h4>{{title}}</h4>
            <div formArrayName="{{arrFieldName}}"
                *ngFor="let item of formContext.get(arrFieldName).controls; let i = index;">
                <div [formGroupName]="i">
                    <div>
                        אני נוטל<span class="font-bold"> {{data[arrFieldName][i].description}}</span>                    
                        <ng-container *ngIf="data[arrFieldName][i].recommended_by != 'אחר'" >
                            בהמלצת <span class="font-bold"> {{data[arrFieldName][i].recommended_by}}</span>   
                        </ng-container>

                        <ng-container *ngIf="data[arrFieldName][i].recommended_by == 'אחר'" >
                            בהמלצת <span class="font-bold"> {{data[arrFieldName][i].recommended_by_text}}</span>
                        </ng-container>
                        
                        במשך<span class="font-bold"> {{data[arrFieldName][i].duration_taken}}</span>
                    </div>
                    <ng-container *ngIf="data[arrFieldName][i].assets_arr.length > 0">
                        <div>תמונות:</div>
                        <div class="row">
                            <span class="healthquiz-asset-image pointer"
                                *ngFor="let asset of data[arrFieldName][i].assets_arr; let i = index;" (click)="imageClick(asset.url)">
                                <img src="{{asset.url}}">
                            </span>
                        </div>
                    </ng-container>

                    <div *ngIf="data[arrFieldName][i].comment_text != ''">בנוסף, <span class="font-bold">{{data[arrFieldName][i].comment_text}}</span></div>

                    <!-- <notes [notesArr]="notesList" [selectedForm]="item" [fieldName]="'notes'">
                    </notes> -->

                </div>
            </div>
    </form>
</ng-template>

<ng-template #sufferTemplateNuq let-title="title" let-formContext="formContext" let-data="data"
    let-checkboxFieldName="checkboxFieldName" let-arrFieldName="arrFieldName"
    let-productNameFieldLabel="productNameFieldLabel" let-howDiagnosedFieldLabel="howDiagnosedFieldLabel"
    let-moreCommentsTitle="moreCommentsTitle" let-addButtonTitle="addButtonTitle"
    let-removeButtonTitle="removeButtonTitle" let-addOnClick="addOnClick" let-removeOnClick="removeOnClick">
    <form novalidate class="basic-form" [formGroup]="formContext">
        <h4>{{title}}</h4>
        <div formArrayName="{{arrFieldName}}"
            *ngFor="let control of formContext.get(arrFieldName).controls; let i = index; let last = last;">
            <div [formGroupName]="i" [ngClass]="{'mb125' : !last}">
      
                <div class="w65">
                    <span>{{productNameFieldLabel}}</span>
                    <span *ngIf="data[arrFieldName][i].product_name != 'אחר'" class="font-bold">
                        {{data[arrFieldName][i].product_name | labelById : nutallergies}}
                    </span>                    
                    <span *ngIf="data[arrFieldName][i].product_name == 'אחר'" class="font-bold">{{data[arrFieldName][i].product_name_other_details}}</span>                    
                </div>
                <div class="w65">
                    <span>{{howDiagnosedFieldLabel}}</span>
                    <span *ngIf="data[arrFieldName][i].how_diagnosed != 'אחר'" class="font-bold"> {{data[arrFieldName][i].how_diagnosed}}</span>                    
                    <span *ngIf="data[arrFieldName][i].how_diagnosed == 'אחר'" class="font-bold"> {{data[arrFieldName][i].how_diagnosed_other_details}}</span>                    
                    <span> בתאריך </span>
                    <span class="font-bold">{{data[arrFieldName][i].diagnosed_date | date:'dd/MM/yyyy'}}</span>                    
                </div>
                <div *ngIf="data[arrFieldName][i].more_comments == 'כן'" class="w65">
                    <span>ברצוני להוסיף ש</span>
                    <span class="font-bold">{{data[arrFieldName][i].more_comments_text}}</span>                    
                </div>                

                <!-- <notes [notesArr]="notesList" [selectedForm]="control" [fieldName]="'notes'"></notes> -->
            </div>

        </div>
    </form>

</ng-template>
