import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';

import { RestApiService } from '@noammazuz/vzn-feathers';

import { MatSlideToggleChange } from '@angular/material/slide-toggle';

import { ConfirmDialogService } from '../../../../shared/confirm-dialog/confirm-dialog.service';

import { appState } from '@services/app-state';
import { Apputils } from '@services/app-utils';

import { allCategoriesGroups } from '../../../nutquiz/categoriesData';

export interface QuizData {
  id: string;
  date: string;
  isDifferent: boolean;
  quizId: string;
  color: string;
  reportDate: any;
  urineIndex: any;
  dayData: any;
}

@Component({
  selector: 'food-diary-section',
  templateUrl: "./food-diary.component.html"
})
export class FoodDiaryComponent {

  nutcategories: any[] = [];


  @Output() allFdqValuesRes: EventEmitter<any> = new EventEmitter();

  @Output() addNoteEvent: EventEmitter<any> = new EventEmitter();
  @Output() editNoteEvent: EventEmitter<any> = new EventEmitter();
  @Output() removeNoteEvent: EventEmitter<any> = new EventEmitter();
  
  @Output() busyState: EventEmitter<boolean> = new EventEmitter();
  @Output() updateFollow: EventEmitter<any> = new EventEmitter();

  @Input() user: any;
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  @Output() onNew: EventEmitter<any> = new EventEmitter();

  @ViewChild("tabGroup") tabGroup: MatTabGroup;
  @ViewChild("indicator") indicator: ElementRef;
  
  @Input() userWeight?: number;

  @Input() allNotes = [];
  @Output() linkedNotesEvent: EventEmitter<any> = new EventEmitter();
  @Input() notesList = [];

  @Input() notesFormA: FormGroup;
  @Input() notesFormB: FormGroup;

  @Input() oldNotesFlag?: boolean;

  quizPrefix = 'fdq';

  busy = false;

  isLoading = false;

  allCategoriesGroups = allCategoriesGroups;

  allOptionsIngredients = {
    protein: [],
    carbs: [],
    fat: [],
    veg: [],
    fruits: [],
    sweets: [],
    snacks: [],
    sauce: [],
    powder: [],
    drink: [],
  };
  
  // notesFormA: FormGroup;
  // notesFormB: FormGroup;

  oldValues = [];
  // oldValuesFiltered = [];

  selectedIdA: string;
  selectedIdB: string;

  selectedA: any;
  selectedB: any;

  restService: any;

  appState = appState;

  stickyTabsObserver: IntersectionObserver | null;
  isTabGroup: boolean;
  isIndicator: boolean;
  inViewMeal = '';
  scrollMeal = '';

  quizDaysArr: any;
  quizDaysDataArr: Array<QuizData> = [];
  userId: string;
  userAge = 0;

  // untitledTabPrefix = "יום ";
  maxTabs = 3;
  selectedTab = 0;
  // weekDays = {
  //   'Sun' : 'א', 
  //   'Mon' : 'ב', 
  //   'Tue' : 'ג', 
  //   'Wed' : 'ד', 
  //   'Thu' : 'ה', 
  //   'Fri' : 'ו', 
  //   'Sat' : 'ש'
  // }
  weekDays = {
    '0' : 'א', 
    '1' : 'ב', 
    '2' : 'ג', 
    '3' : 'ד', 
    '4' : 'ה', 
    '5' : 'ו', 
    '6' : 'ש'
  }
  currentMeal = '';
  mealsObserver: IntersectionObserver | null;
  hideTabs = false;
  slideTo = '';

  chosenNewDay: any;

  urineHexColorPlate = Apputils.urineHexColorPlate;

  colorList = ['#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080'];

  allColors = [];

  get darkestUrineIndex() {
    return this.quizDaysDataArr && this.quizDaysDataArr[this.selectedTab] && this.quizDaysDataArr[this.selectedTab]['urineIndex'];
  }

  get selectedQuizId() {
    return this.quizDaysDataArr && this.quizDaysDataArr[this.selectedTab] && this.quizDaysDataArr[this.selectedTab]['quizId'];
  }

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public router: Router,
    private dialogService: ConfirmDialogService,
    private _snackBar: MatSnackBar,
    public sanitizer: DomSanitizer,
    public restApiService: RestApiService<any>
  ) {
    this.restService = this.restApiService.service('fooddiaryquiz');

    // this.notesFormA = this.createNewForm();
    // this.notesFormB = this.createNewForm();

    this.stickyTabsObserver = null;
    this.isTabGroup = false;
    this.isIndicator = false;
  }

  async newQuizGuard() {
    this.onNew.emit(() => { this.newQuiz() });
  }
  async newQuiz() {
    // if (this.getDirty()) {
    //   const options = {
    //     title: 'יצירת יומן אכילה',
    //     message: 'האם ליצור יומן אכילה חדש?',
    //     errorMessage: 'שימו לב, יש הערות שלא נשמרו ביומן הנוכחי',
    //     cancelText: 'לא',
    //     confirmText: 'כן'
    //   };
    //   this.dialogService.open(options);
    //   this.dialogService.confirmed().subscribe(async confirmed => {
    //     if (confirmed) {
    //       await this.restService.create({
    //         fdq_usr_id: this.user._id,
    //         // fdq_form_is_real: true
    //       });
    //       await this.fetchOldValues(true);
    //     }
    //   });
    // } else {
      await this.restService.create({
        fdq_usr_id: this.user._id,
        // fdq_form_is_real: true
      });
      await this.fetchOldValues(true);
    // }
  }

  async deleteQuiz(quizId: string) {
    this.onDelete.emit(() => { this.deleteOk(quizId) });
  }

  async deleteOk(quizId: string) {
    await this.restService.delete(quizId);
    await this.fetchOldValues(true);
  }

  async toggleQuizLock(event: MatSlideToggleChange, selected: FormGroup) {
    const lockedField = 'fdq_locked';
    if (selected && selected.value && selected.value._id) {
      const res = await this.restService.patch(selected.value._id, {
        [lockedField]: event.checked
      });
      if (res && res[lockedField] === event.checked) {
        event.source.checked = res[lockedField];
        selected.controls[lockedField].reset(res[lockedField]);
        return;
      }
    }
    event.source.checked = !event.checked;
  }

  async changedFdqShown(event: MatSlideToggleChange, selected: FormGroup) {
    const boolField = 'fdq_shown';
    if (selected && selected.value && selected.value._id) {
      const res = await this.restService.patch(selected.value._id, {
        [boolField]: event.checked
      });
      if (res && res[boolField] === event.checked) {
        event.source.checked = res[boolField];
        selected.controls[boolField].reset(res[boolField]);
        return;
      }
    }
    event.source.checked = !event.checked;
  }

  async fetchOldValues(reset?: boolean, allOnlyWithNotesWithoutDays?: boolean) {

    try {
      let res = await this.restService.find({
        query: {
          $paginate: false,
          // $select: ['_id', 'createdAt', 'fdq_usr_id', 'fdq_notes', 'is_by_user', 'linked'],
          fdq_usr_id: this.user._id,
          $sort: {
            // is_by_user: 1,
            createdAt: -1
          },
          // createdAt: {
          //   $gt: 1595397916274
          // }
        }
      });

      // res.sort((a, b) => {
      //   const numA = a?.is_by_user ? 1 : 0;
      //   const numB = b?.is_by_user ? 1 : 0;
      //   return numA - numB;
      // });
      
      console.log(res);
      // this.oldValues = res;

      this.allFdqValuesRes.emit(res);

      const colorList = this.colorList;

      let colorIdx = 0;

      // for (let i = res.length - 1; i >= 0; i--) {
      //   res[i].color = colorList[colorIdx];
      for (let quiz of res) {
        quiz.color = colorList[colorIdx];
        colorIdx++;
        if (colorIdx > 19) {
          colorIdx = 0;
        }
      }

      if (allOnlyWithNotesWithoutDays) {
        // await this.resetToLastTwo(res);
        // this.oldValues = res;
        if (res[0] && res[0]?._id === this.selectedA?._id) {
          this.refreshFormA(res[0]);
        }
        if (res[1] && res[1]?._id === this.selectedB?._id) {
          this.refreshFormB(res[1]);
        }
        return;
      }
      
      await this.loadQuiz(res);

      // const filtered = res.filter((quiz: any, index: number) => {
      //   if (index === 0) {
      //     return false;
      //   }
      //   return true;
      //   // const notesGroup = quiz.fdq_notes;
      //   // if (typeof notesGroup === 'object') {
      //   //   for (const idx in notesGroup) {
      //   //     if (notesGroup[idx] && notesGroup[idx].length) {
      //   //       return true;
      //   //     }
      //   //   }
      //   // }
      //   // const createdAtTime = new Date(quiz.createdAt).getTime();
      //   // if (createdAtTime < 1595397916274) { // to show all old quizzes
      //   //   return true;
      //   // }
      //   // return false;
      // });
      
      if (reset) {
        // await this.resetToLastTwo(res, filtered);
        await this.resetToLastTwo(res);
      }
      // console.log(filtered);
      // this.oldValuesFiltered = filtered;
      this.oldValues = res;

    } catch (e) {
      console.error(e);
    }
  }

  async resetToLastTwo(values, filteredValues?) {
    if (values.length === 0) {
      this.inViewMeal = '';
      this.scrollMeal = '';
      delete this.selectedIdA;
      delete this.selectedA;
      this.notesFormA.reset();
      delete this.selectedIdB;
      delete this.selectedB;
      this.notesFormB.reset();
      return;
    }
    if (values[0] && values[0]._id && values[0]._id !== this.selectedIdA) {
      this.inViewMeal = '';
      this.scrollMeal = '';
      delete this.selectedA;
      this.notesFormA.reset();
      this.selectedIdA = values[0]._id;
      await this.selectDateA(this.selectedIdA);
    }
    // if (filteredValues && filteredValues[0] && filteredValues[0]._id && filteredValues[0]._id !== this.selectedIdB) {
    //   this.notesFormB.reset();
    //   this.selectedIdB = filteredValues[0]._id;
    //   await this.selectDateB(this.selectedIdB);
    // }
    if (values[1] && values[1]._id && values[1]._id !== this.selectedIdB) {
      this.notesFormB.reset();
      this.selectedIdB = values[1]._id;
      await this.selectDateB(this.selectedIdB);
    }
  }

  async ngOnInit() {
    await this.fetchOldValues(true);
    // if (this.oldValues && this.oldValues[1] && this.oldValues[1]._id && this.oldValues[1]._id !== this.selectedIdB) {
    //   this.notesFormB.reset();
    //   this.selectedIdB = this.oldValues[1]._id;
    //   await this.selectDateB(this.selectedIdB);
    // }
    // if (this.oldValuesFiltered && this.oldValuesFiltered[0] && this.oldValuesFiltered[0]._id && this.oldValuesFiltered[0]._id !== this.selectedIdB) {
    //   this.notesFormB.reset();
    //   this.selectedIdB = this.oldValuesFiltered[0]._id;
    //   await this.selectDateB(this.selectedIdB);
    // }
    this.userId = this.user._id;
    this.userAge = +(this.user?.usr_age_formatted || 0);

    
    // await this.getCategoriesData();
    await this.getAllData();
  }

  resetNotes(notesGroup: FormGroup, dataNotes) {
    for (const noteKey in notesGroup.value) {
      let notes: FormArray = notesGroup.get(noteKey) as FormArray;
      notes.clear();
      if (dataNotes && dataNotes[noteKey]) {
        for (const n of dataNotes[noteKey]) {
          notes.push(new FormControl());
        }
      }
    }
  }

  refreshFormA(value) {
    const notesGroup = this.notesFormA.get('fdq_notes') as FormGroup;
    this.resetNotes(notesGroup, value['fdq_notes']);
    this.notesFormA.reset(value);
    this.selectedA = value;
  }

  async selectDateA(newVal){
    this.busy = true;
    const selectedTab = this.jumpToTab(newVal);
    // this.hideAndReloadMeals(selectedTab);
    // this.selectedTab = this.jumpToTab(newVal);
    setTimeout(() => {this.selectedTab = selectedTab;})

    // const data = await this.restService.get(newVal).catch(e => { console.error(e) });
    const res = await this.restService.find({
      query: {
        $nutri: true,
        $limit: 1,
        _id: newVal,
      },
    }).catch(e => { console.error(e) });
    const data = res?.data[0];
    // const data = this.oldValues.find(val => val._id === newVal);
    if (data) {
      this.notesFormA.reset();
      this.refreshFormA(data); 
    }
    this.busy = false;
  }

  refreshFormB(value) {
    const notesGroup = this.notesFormB.get('fdq_notes') as FormGroup;
    this.resetNotes(notesGroup, value['fdq_notes']);
    this.notesFormB.reset(value);
    this.selectedB = value;
  }

  async selectDateB(newVal){
    this.busy = true;
    // const data = await this.restService.get(newVal).catch(e => { console.error(e) });
    const res = await this.restService.find({
      query: {
        $nutri: true,
        $limit: 1,
        _id: newVal,
      },
    }).catch(e => { console.error(e) });
    const data = res?.data[0];
    // const data = this.oldValues.find(val => val._id === newVal);
    if (data) {
      this.notesFormB.reset();
      this.refreshFormB(data); 
    }
    this.busy = false;
  }

  ngOnDestroy() {
    this.disconnectSticky();
  }

  disconnectSticky() {
		if (this.stickyTabsObserver) {
			this.stickyTabsObserver.disconnect();
      this.stickyTabsObserver = null;
      this.isTabGroup = false;
      this.isIndicator = false;
		}
  }

  initSticky(tryNum: number = 0) {
    if (!this.stickyTabsObserver) {
      // let el = document.querySelector("mat-tab-group");
      // let indicator = document.querySelector("#top-indicator");
      let el = this.tabGroup && this.tabGroup._elementRef && this.tabGroup._elementRef.nativeElement;
      let indicator = this.indicator.nativeElement;
      if (!el || !indicator) {
        if (tryNum < 10) {
          setTimeout(() => {
            this.initSticky(tryNum + 1);
          }, 750);
        }
        return;
      }
      this.stickyTabsObserver = new IntersectionObserver( this.handleIntersection );
      this.stickyTabsObserver.observe( el );
      this.stickyTabsObserver.observe( indicator );

      this.tabGroup?.realignInkBar();
    }
  }
  handleIntersection = ( entries: IntersectionObserverEntry[] ) : void => {
    for ( let entry of entries ) {
      if (entry.target.id === 'top-indicator'){
        this.isIndicator = entry.isIntersecting;
      } else {
        this.isTabGroup = entry.isIntersecting;
      }
    }
  }

  resetField(notesFormA, fieldName, value) {
    notesFormA.controls[fieldName].reset(value);
  }

  // async submitForm(){
  //   this.busyState.emit(true);
  //   const form = this.notesFormA;
  //   let res: any;

  //   if (form && form.value._id && form.value._id !== '') { 
  //     res = await this.restService.patch(form.value._id, form.value);
  //     if (res && res._id) {
  //       form.reset(res);
  //       this.showStatus("נשמר בהצלחה", null);
  //       if (res.follow_flag) {
  //         this.updateFollow.emit(res);
  //         setTimeout(() => {
  //           this._snackBar.open('המשתמש נכנס למעקב', 'סגור', {
  //             duration: 0,
  //           });
  //         }, 2000);
  //       }
  //     }
  //   }
  //   this.busyState.emit(false);
  //   return res;
  // }

  // createNewForm(): FormGroup {
  //   return this.formBuilder.group({
  //     _id: [''],
  //     fdq_locked: [false],
  //     fdq_notes: this.formBuilder.group({
  //       A: this.formBuilder.array([]),
  //       B: this.formBuilder.array([]),
  //       C: this.formBuilder.array([]),
  //       D: this.formBuilder.array([]),
  //       E: this.formBuilder.array([]),
  //       F: this.formBuilder.array([]),
  //       G: this.formBuilder.array([]),
  //       H: this.formBuilder.array([]),
  //       I: this.formBuilder.array([]),
  //     }),

  //     createdAt: [''],

  //     notes_fdq_a: [''],
  //     notes_fdq_b: [''],
  //     notes_fdq_c: [''],
  //     notes_fdq_d: [''],
  //     notes_fdq_e: [''],
  //     notes_fdq_f: [''],
  //     notes_fdq_g: [''],
  //     notes_fdq_h: [''],
  //     notes_fdq_i: [''],
  //     notes_fdq_j: [''],
  //   });
  // }

  showStatus(message: string, action: string) {
    this._snackBar.open(message, action, {
      duration: 2000,
    });
  }

  getObjKeys(obj) {
    return Object.keys(obj) || [];
  }

  getDirty() {
    const formA = this.notesFormA;
    const formB = this.notesFormB;
    // return (formA && formA.dirty) || (formB && formB.dirty);
    return (formA && formA.dirty);
  }

  getLocked() {
    const lockedField = 'fdq_locked';
    const formA = this.notesFormA;
    // const formB = this.notesFormB;
    // return (formA && formA.dirty) || (formB && formB.dirty);
    return (formA && formA.value[lockedField]);
  }

  getLastId() {
    const formA = this.notesFormA;
    return formA && formA.value._id || '';
  }

  mealChange(e){
    if (!this.hideTabs) {
      // console.log('mealChange', e);
      this.inViewMeal = e;
    }
  }
  
  async getCategoriesData() {
    const nutcategories: any[] = await this.restApiService.service('nutcategories').find({
      query: {
        $paginate: false,
        $select: ['_id', 'label'],
      },
    });

    // const getMappedItems = (idsArray: string[]) => {
    //   return idsArray.map(filterItem => nutcategories.find(item => item?.label === filterItem || item?._id === filterItem)).filter(x => x).map(catItem => ({ value: catItem._id, text: catItem.label }));
    // }

    this.nutcategories = [{_id: 'water', label: 'מים', reorder: 1, checked: true, weekly: {}} ,...nutcategories];
  }

  getIdsByFilter(filterArr: string[], allArr: any[], key = 'label') {
    return filterArr.map(filterItem => allArr.find(item => item[key] === filterItem)?._id).filter(x => x);
  }
  
  extractCategories(products: any[], catName: string) {
    let productsByCategories: any = {};

    for (const product of products) {
      if (product.n_categories?.length) {
        for (const categoryObjectId of product.n_categories) {
          const catId = categoryObjectId.toString();
          if (!productsByCategories[catId]) {
            productsByCategories[catId] = [product];
          } else {
            if (!productsByCategories[catId].find((pr: any) => pr._id === product._id)) {
              productsByCategories[catId].push(product);
            }
          }
        }
      }
    }

    if (catName === 'drink') {
      productsByCategories['water'] = [{}];
    }

    return productsByCategories;
  }
  
  getChildCategories(productsByCategories: any) {
    const allCategories = this.nutcategories || [];

    let allCategoriesWithChildren: any = {};
    let allChildren: any[] = [];

    for (const category of allCategories) {
      if (category?._id) {
          
        if (!category.weekly) {
          category.weekly = {}
        }
        // if (!category.childrenProducts) {
        //   category.childrenProducts = {}
        // }
        if (!category.checkedTypical) {
          category.checkedTypical = {}
        }

        if (!category.n_parent_id) {
          
          // if (!category.weekly) {
          //   category.weekly = {}
          // }
          // if (!category.childrenProducts) {
          //   category.childrenProducts = {}
          // }
          // if (!category.checkedTypical) {
          //   category.checkedTypical = {}
          // }

          if (productsByCategories[category._id]?.length) {
            allCategoriesWithChildren[category._id] = category;
          }
        } else {
          allChildren.push(category);
        }
      }
    }

    for (const child of allChildren) {
      if (productsByCategories[child._id]?.length) {
        if (!allCategoriesWithChildren[child.n_parent_id]) {
          const parentCategoryFind = allCategories.find(cat => cat._id === child.n_parent_id);
          if (!parentCategoryFind) {
            continue;
          }
          allCategoriesWithChildren[child.n_parent_id] = parentCategoryFind;
        }
        if (!allCategoriesWithChildren[child.n_parent_id].children) {
          allCategoriesWithChildren[child.n_parent_id].children = [child];
        } else {
          if (!allCategoriesWithChildren[child.n_parent_id].children.find(cat => cat._id === child._id)) {
            allCategoriesWithChildren[child.n_parent_id].children.push(child);
          }
        }
      }
    }

    return allCategoriesWithChildren;
    
  }

  async getAllData() {

    // await this.getCategoriesData();

    const allProductsData = await this.restApiService.service('actions').create({cmd: 'get-my-products', cmdData: {filterNutTypes: []}});

    // console.log(allProductsData);

    if (allProductsData) {
      const { products = [], productsByCategories, nutallergies, nutcategories, nutingredients = [], nuttags, nuttypes } = allProductsData;
        
      this.nutcategories = [{_id: 'water', label: 'מים', reorder: 1, checked: true, weekly: {}} ,...nutcategories];


      if (this.allCategoriesGroups) {
        for (const cat of this.allCategoriesGroups) {
          if (cat?.filterIngredients && cat?.name) {
  
            const filterIngredientsIds = this.getIdsByFilter(cat.filterIngredients, nutingredients);
  
            const allProductsFiltered = products.filter(product => product?.n_ingredients?.length && filterIngredientsIds.some(ingredient => product.n_ingredients.includes(ingredient)));

            const _productsByCategories = this.extractCategories(allProductsFiltered, cat.name);
            
            // const categoriesIds = Object.keys(_productsByCategories);

            const allCategoriesWithChildren = this.getChildCategories(_productsByCategories);

            const objValuesArr = Object.values(allCategoriesWithChildren);

            const allOptionsSorted = objValuesArr.sort((a: any, b: any) => {
              if (!b?.reorder) return -1;
              if (!a?.reorder) return 0;

              return (a?.reorder ?? 0) - (b?.reorder ?? 0);
              return 0;
            });

            this.allOptionsIngredients[cat.name] = allOptionsSorted;
            
          }
        }
      }

    }

  }
  
  async loadQuiz(allQuiz: any[]) { // quizFormId: string

    // let quizzes = allQuiz.slice();
    let quizzes = allQuiz;
    const mappedIds: string[] = allQuiz.map(quiz => quiz._id.toString());

    this.disconnectSticky();

    delete this.quizDaysArr;
    this.quizDaysDataArr = [];
    this.inViewMeal = '';
    this.scrollMeal = '';

    this.isLoading = true;

    try {
      const res = await this.restApiService.service("fooddiaryquiz-day").find({
        query: {
          $paginate: false,
          // fdq_day_fdq_id: quizFormId,
          fdq_day_fdq_id: {
            $in: mappedIds
          },
          fdq_day_form_is_real: true,
          $sort: {
            // createdAt: -1,
            fdq_day_date_taken: 1
          },
          // fdq_day_date_taken: {
          //   $gt: 0
          // },
          fdq_day_is_deleted: {
            $ne: true
          }
        }
      });

      const daysRes = res.filter(day => day.fdq_day_date_taken);

      // const daysRes = res.filter(day => day.fdq_day_date_taken && mappedIds.includes(day.fdq_day_fdq_id));

      // const daysRes = res.filter(day => (day.fdq_day_date_taken && day.fdq_day_school_today && day.fdq_day_urine_color && day.fdq_day_drink_as_stated && day.fdq_day_meals_as_stated));

      // console.log(daysRes)

      // if (daysRes.length == 0) { 
      //   this.quizDaysArr = [];
      //   this.quizDaysDataArr = [];
      //   // this.disconnectSticky();
      // } else {
        const allRpt = await this.restApiService.service('userreport').find({
          query: {
            $paginate: false,
            $sort: {
              createdAt: -1
            },
            $select: ['_id', 'createdAt', 'rpt_fdq_id'],
            rpt_usr_id: this.user._id,
            rpt_is_published: true,
            rpt_is_hidden: {
              $ne: true
            },
            // rpt_fdq_id: {
            //   $in: mappedIds
            // },
          }
        });

        const allNuq = await this.restApiService.service('nutquiz').find({
          query: {
            $paginate: false,
            $sort: {
                createdAt: -1
            },
            nuq_usr_id: this.user._id,
            nuq_form_is_real:{
                $ne: false
            },
            nuq_is_clone: {
                $ne: true
            },
            // is_by_user: {
            //   $ne: true
            // }
          }
        })

        const getUrine = (daysRes, fdqId: string) => {
          let urineIndex = -1;
          for (let i = 0; i < daysRes.length; i++) {
            if (daysRes[i]['fdq_day_fdq_id'] === fdqId) {
              let color = daysRes[i]['fdq_day_urine_color'] || '';
              let index = this.urineHexColorPlate.indexOf(color);
              urineIndex = Math.max(index, urineIndex);
            }
          }
          return urineIndex;
        }

        // for (let quiz of quizzes) {
        //   quiz.urine = getUrine(daysRes, quiz._id);
        //   quiz.report = allRpt.find(rpt => rpt.rpt_fdq_id === quiz._id)?.createdAt;
        // }

        // let quizDaysDataArr = daysRes.map((day: any) => {
        //   const quiz = quizzes.find(el => el._id === day.fdq_day_fdq_id);
        //   return ({
        //     id: day._id,
        //     date: day.fdq_day_date_taken,
        //     // isDifferent: day.fdq_day_different,
        //     isDifferent: (day.fdq_day_date_taken && day.fdq_day_urine_color && day.fdq_day_drink_as_stated && day.fdq_day_meals_as_stated) ? Boolean(day.fdq_day_different) : null,
        //     quizId: day.fdq_day_fdq_id,
        //     color: quiz?.color,
        //     reportDate: quiz?.report,
        //     urineIndex: quiz?.urine,
        //     dayData: day,
        //   })
        // });

        let quizDaysDataArr: any[] = [];

        for (let quiz of quizzes) {
          const color = quiz.color;
          const urine = getUrine(daysRes, quiz._id);
          const report = allRpt.find(rpt => rpt.rpt_fdq_id === quiz._id)?.createdAt;

          const nuqId = quiz.linked?.nuq;

          const nuq = allNuq.find(nuq => nuq._id === nuqId);

          if (nuq) {

            const ingredientsByMeal = this.getByMeals(nuq);
            // console.log('ingredientsByMeal', ingredientsByMeal)

            quizDaysDataArr.push({
              isNuqIngredients: true,
              nuqIngredientsData: ingredientsByMeal,
              nuqData: nuq,

              id: null,
              date: quiz.createdAt,
              isDifferent: null,
              quizId: quiz._id,
              color: quiz.color,
              reportDate: null,
              urineIndex: null,
              dayData: null,
            });

            const hasDetails = [
              nuq.nuq_is_breakfast_details,
              nuq.nuq_is_elevenses_details,
              nuq.nuq_is_lunch_details,
              nuq.nuq_is_tea_details,
              nuq.nuq_is_dinner_details,
              nuq.nuq_is_night_details,
            ].some(x => x);

            // console.log('hasDetails', hasDetails);
            if (hasDetails) {
              quizDaysDataArr.push({
                isNuqDetails: true,
                // nuqDetailsData: ,
                nuqData: nuq,

                id: null,
                date: quiz.createdAt,
                isDifferent: null,
                quizId: quiz._id,
                color: quiz.color,
                reportDate: null,
                urineIndex: null,
                dayData: null,
              });
            }
          }
          
          const days = daysRes.filter(day => day.fdq_day_fdq_id === quiz._id);
          if (days.length) {
            for (const day of days) {
              quizDaysDataArr.push({
                id: day._id,
                date: day.fdq_day_date_taken,
                // isDifferent: day.fdq_day_different,
                isDifferent: (day.fdq_day_date_taken && day.fdq_day_urine_color && day.fdq_day_drink_as_stated && day.fdq_day_meals_as_stated) ? Boolean(day.fdq_day_different) : null,
                quizId: day.fdq_day_fdq_id,
                color: color,
                reportDate: report,
                urineIndex: urine,
                dayData: day,
              });
            }
          } else {
            quizDaysDataArr.push({
              id: null,
              date: quiz.createdAt,
              isDifferent: null,
              quizId: quiz._id,
              color: quiz.color,
              reportDate: null,
              urineIndex: null,
              dayData: null,
            });
          }
        }

        quizDaysDataArr.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());

        this.quizDaysDataArr = quizDaysDataArr;
        this.quizDaysArr = daysRes;
        // this.initSticky();
        setTimeout(()=>{this.initSticky()}, 750);
      // }
    } catch (e) {
      console.error(e);
      delete this.quizDaysArr;
      this.quizDaysDataArr = [];
    }

    this.isLoading = false;
  }

  getByMeals(nuq: any) {
    const mealTypes = ['BREAKFAST', 'ELEVENSES', 'LUNCH', 'TEA', 'DINNER', 'NIGHT'];
    const allMeals: any = {};

    for (const mealType of mealTypes) {
      allMeals[mealType] = this.calcIngredientsByMealType(nuq, mealType);
    }

    return allMeals;
  }

  calcIngredientsByMealType(selected: any, mealType: string) {
    const allChosenIngredients = {
      protein: [],
      carbs: [],
      fat: [],
      veg: [],
      fruits: [],
      sweets: [],
      snacks: [],
      sauce: [],
      powder: [],
      drink: [],
    };

    if (selected?.nuq_chosen_categories) {
      for (const key in selected.nuq_chosen_categories) {
        if (Object.prototype.hasOwnProperty.call(selected.nuq_chosen_categories, key)) {
          const category = selected.nuq_chosen_categories[key];
          if (category?.checked && category?.weekly?.[mealType]) {
            let ids = [];
            if (category.children?.length) {
              for (const child of category.children) {
                if (child?.checked) {
                  ids.push(child._id);
                }
              }
            } else {
              ids.push(category._id);
            }
            // if (ids.length && category.childrenProducts) {
            //   for (const key in category.childrenProducts) {
            //     if (Object.prototype.hasOwnProperty.call(category.childrenProducts, key)) {
            //       const element = category.childrenProducts[key];
            //       if (element?.length && allChosenIngredients[key]) {
            //         allChosenIngredients[key].push(...ids);
            //       }
            //       // if (allChosenIngredients[key]) {
            //       //   allChosenIngredients[key].push(...ids);
            //       // }
            //     }
            //   }
            // }
            
            if (ids.length) {
              if (category.childrenProducts) {
                for (const key in category.childrenProducts) {
                  if (Object.prototype.hasOwnProperty.call(category.childrenProducts, key)) {
                    const element = category.childrenProducts[key];
                    if (element?.length && allChosenIngredients[key]) {
                      allChosenIngredients[key].push(...ids);
                    }
                    // if (allChosenIngredients[key]) {
                    //   allChosenIngredients[key].push(...ids);
                    // }
                  }
                }
              } else {
                for (const id of ids) {
                  for (const ingName in this.allOptionsIngredients) {
                    if (Object.prototype.hasOwnProperty.call(this.allOptionsIngredients, ingName)) {
                      const allOptions = this.allOptionsIngredients[ingName];
                      const opt = allOptions?.find((opt: any) => opt?._id === id || opt?.children?.find((_opt: any) => _opt?._id === id));
                      if (opt) {
                        allChosenIngredients[ingName].push(id);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      // selected.chosenCategories = allChosenIngredients;
      return allChosenIngredients;
    }
  }
  
  scrollToMeal() {
    this.scrollMeal = this.inViewMeal;
    this.hideTabs = false;
    this.slideTo = '';
    setTimeout(() => {this.scrollMeal = '';})
    
  }
  hideAndReloadMeals(e) {
    this.hideTabs = true;
    this.slideTo = this.selectedTab>e ? 'slide-right' : 'slide-left';
    // this.selectedTab = e;
    // setTimeout(() => {this.selectedTab = e;})
  }

  async deleteDay(index: number, id: string, e: any) {
    e && e.stopPropagation();

    const options = {
      title: 'מחיקת יום',
      message: 'האם למחוק יום זה?',
      cancelText: 'לא',
      confirmText: 'כן'
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        this.restApiService.service("fooddiaryquiz-day").patch(id, { fdq_day_is_deleted: true }).then(res => {
          if (res && res.fdq_day_is_deleted) {
            this._snackBar.open(`נמחק בהצלחה`, null, {
              duration: 3000,
            });
            // this.loadQuiz(this.selectedA._id);
            this.quizDaysDataArr.splice(index, 1);
            if (this.selectedTab > this.quizDaysDataArr.length - 1) { // if (index > this.quizDaysDataArr.length) {
              // this.hideAndReloadMeals(this.quizDaysDataArr.length - 1);
              // this.selectedTab = this.quizDaysDataArr.length - 1;
              setTimeout(() => {this.selectedTab = this.quizDaysDataArr.length - 1;})
            } else if (index < this.selectedTab) {
              // this.hideAndReloadMeals(this.selectedTab - 1);
              // this.selectedTab = this.selectedTab - 1;
              setTimeout(() => {this.selectedTab = this.selectedTab - 1;})
            }
          }

        }).catch(res => {
          console.error(res);
          if (res.message) {
            this._snackBar.open(res.message, null, {
              duration: 3000,
            });
          }
        });
      }
    });
  }

  async addDay() {
    if (!(this.chosenNewDay && this.chosenNewDay.isValid)) {
      return;
    }
    const chosenDate = this.chosenNewDay.toISOString();
    const data = {
      fdq_day_form_is_real: true,
      fdq_day_usr_id: this.user._id,
      fdq_day_fdq_id: this.selectedA._id,
      fdq_day_date_taken: chosenDate,
    };
    const res = await this.restApiService.service("fooddiaryquiz-day").create(data);
    if (res && res._id) {
      delete this.chosenNewDay;
      // this.loadQuiz(this.selectedA._id);
      const quiz = this.oldValues.find(el => el._id === res.fdq_day_fdq_id);

      const obj = {
        id: res._id,
        date: res.fdq_day_date_taken,
        isDifferent: null,
        quizId: res.fdq_day_fdq_id,
        color: quiz?.color,
        reportDate: quiz?.report,
        urineIndex: quiz?.urine,
        dayData: res,
      };
      let idx = this.quizDaysDataArr.findIndex(day => day.date && day.date > res.fdq_day_date_taken);
      if (idx > 0) {
        this.quizDaysDataArr.splice(idx, 0, obj);
        // this.hideAndReloadMeals(idx);
        // this.selectedTab = idx;
        // setTimeout(() => {this.selectedTab = idx;})
      } else {
        const arr = this.quizDaysDataArr;
        if (arr && arr[arr.length - 1] && !arr[arr.length - 1].id) {
          idx = arr.length - 1;
          // this.quizDaysDataArr.splice(idx, 0, obj);
          this.quizDaysDataArr.splice(idx, 1, obj);
          // selected = this.quizDaysDataArr.length - 2;
        } else {
          this.quizDaysDataArr.push(obj);
          idx = this.quizDaysDataArr.length - 1;
          // selected = this.quizDaysDataArr.length - 1;
        }
        // this.hideAndReloadMeals(this.quizDaysDataArr.length - 1);
        // this.selectedTab = this.quizDaysDataArr.length - 1;
        // setTimeout(() => {this.selectedTab = this.quizDaysDataArr.length - 1;})
        // setTimeout(() => {this.selectedTab = selected;})

        setTimeout(() => {this.selectedTab = idx;})

      }

    }
  }

  jumpToTab(val) {
    let quizDaysDataArr = this.quizDaysDataArr;
    // let idx = quizDaysDataArr.findIndex(day => day.quizId && day.quizId === this.selectedIdA);
    // let idx = quizDaysDataArr.findIndex(day => day.quizId && day.quizId === val);
    const quizIdsMappes = quizDaysDataArr.map(day => day && day.quizId || '');
    let idx = quizIdsMappes.lastIndexOf(val);
    // if (idx === -1) {
    //   if (quizDaysDataArr && (quizDaysDataArr.length=== 0 || (quizDaysDataArr[quizDaysDataArr.length - 1] && quizDaysDataArr[quizDaysDataArr.length - 1].id))) {
    //     this.quizDaysDataArr.push({
    //       id: null,
    //       date: null,
    //       isDifferent: null,
    //       quizId: null,
    //       color: null,
    //       reportDate: null,
    //       urineIndex: null,
    //       dayData: null,
    //     });
    //   }
    //   idx = this.quizDaysDataArr.length - 1;
    // }
    return idx;

  }

  linkedNote(note: any) {
    this.linkedNotesEvent.emit(note);
  }

  addNote(noteData: any) {
    this.addNoteEvent.emit(noteData);
  }

  editNote(noteEditData: any) {
    this.editNoteEvent.emit(noteEditData);
  }

  removeNote(noteRemoveData: any) {
    this.removeNoteEvent.emit(noteRemoveData);
  }

  addNoteToNotesObj(quiz_note_type: string, newNote: FormControl) {
    const notesGroup = this.notesFormA.get('fdq_notes') as FormGroup;
    const fieldArray = notesGroup.get(quiz_note_type) as FormArray;
    if (!newNote.value?.note_id || !fieldArray.value || fieldArray.value.findIndex(val => val && val.note_id === newNote.value.note_id) > -1) {
      return;
    }
    fieldArray.push(newNote);
    fieldArray.markAsDirty();
  }

  cloneNotes(e?) {
    const isFdq = (noteId: string) => {
      return this.allNotes.find((note: any) => note._id === noteId)?.quiz_prefix === 'fdq';
    };

    const options = {
      title: 'שכפול הערות יומן אכילה',
      message: 'האם ברצונך להעתיק את ההערות הישנות לשאלון הנוכחי?',
      errorMessageBottom: '* שימו לב, יש לשמור את ההערות לאחר השכפול',
      cancelText: 'לא',
      confirmText: 'כן'
    };
    this.dialogService.open(options);
    this.dialogService.confirmed().subscribe(confirmed => {
      if (confirmed) {
        if (this.notesFormB.value?.fdq_notes && this.notesFormA.value?.fdq_notes) {
          let fdq_notes = { ...this.notesFormB.value?.fdq_notes };
          // delete fdq_notes.A;
          // delete fdq_notes.B;
          // delete fdq_notes.C;
          // delete fdq_notes.D;
          // delete fdq_notes.E;
          // delete fdq_notes.F;
          // delete fdq_notes.G;
          // delete fdq_notes.H;
          // delete fdq_notes.I;
          const allSharedTypes = ['s1', 's2', 's3', 's4', 's5', 's6', 's7', 's8', 's9', 's10', 's11', 's12'];
          const fdqGroup = this.notesFormA.get('fdq_notes');
          const now = new Date().getTime();
          let cloneFlag = false;
          for (const key of allSharedTypes) {
            const keyArray = (fdqGroup.get(key) as FormArray);
            if (fdq_notes[key] && keyArray) {
              for (const note of fdq_notes[key]) {
                if (note.note_id && isFdq(note.note_id) && keyArray.value.findIndex((n: any) => n.note_id === note.note_id) === -1) {
                  const newNote = new FormControl({
                    note_id: note.note_id,
                    is_active: note.is_active,
                    is_done: false,
                    added_at: now,
                  });
                  keyArray.push(newNote);
                  cloneFlag = true;
                  // console.log(key, fdq_notes[key]);
                }
              }
            }
          }
          this._snackBar.open(cloneFlag ? 'שוכפל בהצלחה' : 'אין הערות לשכפול', null, {
            duration: 3000,
          });
        }
      }
    });
  }
  
}

