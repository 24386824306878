export module lineCharts {

  export let statage = {
      "M": {
        "5": [
          [2, 80.72977321],
          [3, 89.20472611],
          [4, 95.58070273],
          [5, 101.4523027],
          [6, 107.3099116],
          [7, 113.1991118],
          [8, 118.8052553],
          [9, 123.7927752],
          [10, 128.1624542],
          [11, 132.3990267],
          [12, 137.3326017],
          [13, 143.5608087],
          [14, 150.5461431],
          [15, 156.6565818],
          [16, 160.7802183],
          [17, 163.0860132],
          [18, 164.2423904],
          [19, 164.7914756],
          [20, 165.0388523],
        ],
        "10": [
          [2, 81.99171445],
          [3, 90.49788649],
          [4, 97.1040715],
          [5, 103.171181],
          [6, 109.1618735],
          [7, 115.1355915],
          [8, 120.830485],
          [9, 125.9500588],
          [10, 130.4809221],
          [11, 134.8608171],
          [12, 139.9279871],
          [13, 146.3813098],
          [14, 153.6181187],
          [15, 159.7529343],
          [16, 163.7104424],
          [17, 165.8490577],
          [18, 166.9093676],
          [19, 167.4199044],
          [20, 167.6592824],
        ],
        "25": [
          [2, 84.10289217],
          [3, 92.71755946],
          [4, 99.65875031],
          [5, 106.026498],
          [6, 112.2468516],
          [7, 118.3893397],
          [8, 124.2562127],
          [9, 129.6034839],
          [10, 134.4008357],
          [11, 139.0262015],
          [12, 144.3276874],
          [13, 151.1165487],
          [14, 158.6614636],
          [15, 164.7712603],
          [16, 168.4805469],
          [17, 170.3930664],
          [18, 171.3282079],
          [19, 171.7909712],
          [20, 172.0227268],
        ],
        "50": [
          [2, 86.45220101],
          [3, 95.27359106],
          [4, 102.5104735],
          [5, 109.1751441],
          [6, 115.6608862],
          [7, 122.0305342],
          [8, 128.1237104],
          [9, 133.7344759],
          [10, 138.8234114],
          [11, 143.7303663],
          [12, 149.3088178],
          [13, 156.4098858],
          [14, 164.1418486],
          [15, 170.139255],
          [16, 173.6100518],
          [17, 175.340954],
          [18, 176.1850208],
          [19, 176.6178621],
          [20, 176.8492322],
        ],
        "75": [
          [2, 88.80524943],
          [3, 97.92869928],
          [4, 105.3759377],
          [5, 112.2997816],
          [6, 119.0611237],
          [7, 125.6987395],
          [8, 132.0558684],
          [9, 137.9414029],
          [10, 143.3167691],
          [11, 148.5147188],
          [12, 154.3880792],
          [13, 161.7363527],
          [14, 169.5021963],
          [15, 175.310506],
          [16, 178.5761676],
          [17, 180.1896498],
          [18, 180.9888527],
          [19, 181.4147056],
          [20, 181.6537998],
        ],
        "90": [
          [2, 90.92619137],
          [3, 100.4071992],
          [4, 107.9664906],
          [5, 115.0923665],
          [6, 122.1100607],
          [7, 129.0230015],
          [8, 135.6503652],
          [9, 141.793025],
          [10, 147.421432],
          [11, 152.8894016],
          [12, 159.0439098],
          [13, 166.5580917],
          [14, 174.2312589],
          [15, 179.8124447],
          [16, 182.9179257],
          [17, 184.4741322],
          [18, 185.2692255],
          [19, 185.7072763],
          [20, 185.9599411],
        ],
        "95": [
          [2, 92.19687928],
          [3, 101.9323762],
          [4, 109.5219891],
          [5, 116.7549954],
          [6, 123.9297176],
          [7, 131.0226304],
          [8, 137.8267001],
          [9, 144.1277605],
          [10, 149.9053151],
          [11, 155.5385858],
          [12, 161.8686162],
          [13, 169.4560742],
          [14, 177.0206409],
          [15, 182.4428851],
          [16, 185.4622948],
          [17, 187.0040394],
          [18, 187.8120038],
          [19, 188.2653392],
          [20, 188.5290125],
        ]
      },
      "F": {
        "5": [
          [ 2, 79.25981989 ],  [ 3, 87.80528157 ],
          [ 4, 94.04022021 ],  [ 5, 100.3890853 ],
          [ 6, 106.8701003 ],  [ 7, 113.054628 ],
          [ 8, 118.5443251 ],  [ 9, 123.2186111 ],
          [ 10, 127.4524005 ], [ 11, 132.4013028 ],
          [ 12, 139.2235689 ], [ 13, 145.8894106 ],
          [ 14, 149.6655332 ], [ 15, 151.2611287 ],
          [ 16, 151.9417942 ], [ 17, 152.2763929 ],
          [ 18, 152.464695 ],  [ 19, 152.5798353 ],
          [ 20, 152.6507234 ]
        ],
        "10": [
          [ 2, 80.52476467 ],  [ 3, 89.20284893 ],
          [ 4, 95.55163545 ],  [ 5, 102.0108651 ],
          [ 6, 108.6049935 ],  [ 7, 114.9111662 ],
          [ 8, 120.5300007 ],  [ 9, 125.3477734 ],
          [ 10, 129.7752299 ], [ 11, 134.9982982 ],
          [ 12, 141.9594471 ], [ 13, 148.437627 ],
          [ 14, 152.0508033 ], [ 15, 153.6039864 ],
          [ 16, 154.2853872 ], [ 17, 154.6280858 ],
          [ 18, 154.8235077 ], [ 19, 154.9437809 ],
          [ 20, 155.0180729 ]
        ],
        "25": [
          [ 2, 82.63523563 ],  [ 3, 91.56065781 ],
          [ 4, 98.11904662 ],  [ 5, 104.7829358 ],
          [ 6, 111.5812164 ],  [ 7, 118.0964128 ],
          [ 8, 123.92854 ],    [ 9, 128.9808329 ],
          [ 10, 133.7239135 ], [ 11, 139.3663536 ],
          [ 12, 146.4957662 ], [ 13, 152.6720956 ],
          [ 14, 156.0401024 ], [ 15, 157.5271178 ],
          [ 16, 158.2060942 ], [ 17, 158.5577008 ],
          [ 18, 158.7611821 ], [ 19, 158.8871595 ],
          [ 20, 158.9651429 ]
        ],
        "50": [
          [ 2, 84.97555512 ],  [ 3, 94.21335709 ],
          [ 4, 101.033927 ],   [ 5, 107.9566031 ],
          [ 6, 115.0054978 ],  [ 7, 121.7616844 ],
          [ 8, 127.8262759 ],  [ 9, 133.1303527 ],
          [ 10, 138.2111552 ], [ 11, 144.2609497 ],
          [ 12, 151.4865636 ], [ 13, 157.3436995 ],
          [ 14, 160.4776996 ], [ 15, 161.8979913 ],
          [ 16, 162.5689958 ], [ 17, 162.9238449 ],
          [ 18, 163.1307866 ], [ 19, 163.2590052 ],
          [ 20, 163.338251 ]
        ],
        "75": [
          [ 2, 87.31121356 ],  [ 3, 96.9007148 ],
          [ 4, 104.0154114 ],  [ 5, 111.2321213 ],
          [ 6, 118.5587525 ],  [ 7, 125.5655273 ],
          [ 8, 131.8566506 ],  [ 9, 137.4018066 ],
          [ 10, 142.8050794 ], [ 11, 149.1983881 ],
          [ 12, 156.4280126 ], [ 13, 161.9818377 ],
          [ 14, 164.920633 ],  [ 15, 166.281224 ],
          [ 16, 166.9388003 ], [ 17, 167.2900467 ],
          [ 18, 167.4948296 ], [ 19, 167.6211174 ],
          [ 20, 167.6987436 ]
        ],
        "90": [
          [ 2, 89.40951159 ],  [ 3, 99.34898744 ],
          [ 4, 106.7566748 ],  [ 5, 114.2701285 ],
          [ 6, 121.8718331 ],  [ 7, 129.1126986 ],
          [ 8, 135.6015364 ],  [ 9, 141.3531879 ],
          [ 10, 147.0322225 ], [ 11, 153.6782905 ],
          [ 12, 160.8353311 ], [ 13, 166.1288638 ],
          [ 14, 168.9238654 ], [ 15, 170.236624 ],
          [ 16, 170.8775306 ], [ 17, 171.2198128 ],
          [ 18, 171.4179802 ], [ 19, 171.5390761 ],
          [ 20, 171.6128653 ]
        ],
        "95": [
          [ 2, 90.66354871 ],  [ 3, 100.8275527 ],
          [ 4, 108.4237579 ],  [ 5, 116.1301284 ],
          [ 6, 123.9086035 ],  [ 7, 131.2935761 ],
          [ 8, 137.8974887 ],  [ 9, 143.7674316 ],
          [ 10, 149.6043767 ], [ 11, 156.3754566 ],
          [ 12, 163.4554684 ], [ 13, 168.5986736 ],
          [ 14, 171.3216174 ], [ 15, 172.6083849 ],
          [ 16, 173.2372685 ], [ 17, 173.5716411 ],
          [ 18, 173.7637802 ], [ 19, 173.8802445 ],
          [ 20, 173.9506901 ]
        ]
      }
  }

  export let wtage = {
    "M": {
      "5": [
        [ 2, 10.64009004 ],  [ 3, 12.09962308 ],
        [ 4, 13.61005739 ],  [ 5, 15.23201937 ],
        [ 6, 16.93703875 ],  [ 7, 18.74211363 ],
        [ 8, 20.66168259 ],  [ 9, 22.70876108 ],
        [ 10, 24.94392079 ], [ 11, 27.50030694 ],
        [ 12, 30.55161562 ], [ 13, 34.22352911 ],
        [ 14, 38.47570882 ], [ 15, 43.01505816 ],
        [ 16, 47.31593016 ], [ 17, 50.8097108 ],
        [ 18, 53.22740059 ], [ 19, 54.80065842 ],
        [ 20, 55.66071006 ]
      ],
      "10": [
        [ 2, 11.05265567 ],  [ 3, 12.55435953 ],
        [ 4, 14.13085804 ],  [ 5, 15.8481433 ],
        [ 6, 17.65285271 ],  [ 7, 19.55135989 ],
        [ 8, 21.57636833 ],  [ 9, 23.769553 ],
        [ 10, 26.20745042 ], [ 11, 29.02052392 ],
        [ 12, 32.36223514 ], [ 13, 36.32176053 ],
        [ 14, 40.81132672 ], [ 15, 45.49646217 ],
        [ 16, 49.84726703 ], [ 17, 53.34544121 ],
        [ 18, 55.78730622 ], [ 19, 57.43408908 ],
        [ 20, 58.41104572 ]
      ],
      "25": [
        [ 2, 11.78597528 ],  [ 3, 13.37875374 ],
        [ 4, 15.0916281 ],   [ 5, 16.99697963 ],
        [ 6, 19.00146515 ],  [ 7, 21.09459707 ],
        [ 8, 23.34005152 ],  [ 9, 25.82993243 ],
        [ 10, 28.66893932 ], [ 11, 31.97867622 ],
        [ 12, 35.8683738 ],  [ 13, 40.35505561 ],
        [ 14, 45.26849262 ], [ 15, 50.21378283 ],
        [ 16, 54.66504759 ], [ 17, 58.18918 ],
        [ 18, 60.68520928 ], [ 19, 62.45680021 ],
        [ 20, 63.6361125 ]
      ],
      "50": [
        [ 2, 12.6707633 ],   [ 3, 14.40262749 ],
        [ 4, 16.31676727 ],  [ 5, 18.48592413 ],
        [ 6, 20.77769565 ],  [ 7, 23.16741888 ],
        [ 8, 25.75256528 ],  [ 9, 28.68130005 ],
        [ 10, 32.08799062 ], [ 11, 36.07262569 ],
        [ 12, 40.67443658 ], [ 13, 45.81336172 ],
        [ 14, 51.23096332 ], [ 15, 56.49095862 ],
        [ 16, 61.09536847 ], [ 17, 64.69961427 ],
        [ 18, 67.28992603 ], [ 19, 69.19467288 ],
        [ 20, 70.59761453 ]
      ],
      "75": [
        [ 2, 13.63691949 ],  [ 3, 15.5598683 ],
        [ 4, 17.74766896 ],  [ 5, 20.26085668 ],
        [ 6, 22.93978291 ],  [ 7, 25.75893543 ],
        [ 8, 28.84718437 ],  [ 9, 32.39733625 ],
        [ 10, 36.55850564 ], [ 11, 41.38377162 ],
        [ 12, 46.81253029 ], [ 13, 52.65557906 ],
        [ 14, 58.59079757 ], [ 15, 64.19327833 ],
        [ 16, 69.03037911 ], [ 17, 72.82296841 ],
        [ 18, 75.57503388 ], [ 19, 77.58579719 ],
        [ 20, 79.18111482 ]
      ],
      "90": [
        [ 2, 14.58339799 ],  [ 3, 16.73623287 ],
        [ 4, 19.25695041 ],  [ 5, 22.17743954 ],
        [ 6, 25.33301794 ],  [ 7, 28.72538282 ],
        [ 8, 32.51025437 ],  [ 9, 36.8859604 ],
        [ 10, 41.96894158 ], [ 11, 47.72519072 ],
        [ 12, 53.97661318 ], [ 13, 60.44799828 ],
        [ 14, 66.81784516 ], [ 15, 72.75113236 ],
        [ 16, 77.92233231 ], [ 17, 82.06741212 ],
        [ 18, 85.07640364 ], [ 19, 87.12008338 ],
        [ 20, 88.80643527 ]
      ],
      "95": [
        [ 2, 15.18777349 ],  [ 3, 17.51092666 ],
        [ 4, 20.28339348 ],  [ 5, 23.50832656 ],
        [ 6, 27.03307565 ],  [ 7, 30.90177938 ],
        [ 8, 35.28955426 ],  [ 9, 40.36068858 ],
        [ 10, 46.15753154 ], [ 11, 52.55602363 ],
        [ 12, 59.30356596 ], [ 13, 66.1035867 ],
        [ 14, 72.68681109 ], [ 15, 78.82658994 ],
        [ 16, 84.29228904 ], [ 17, 88.79527942 ],
        [ 18, 92.04764958 ], [ 19, 94.05261232 ],
        [ 20, 95.71431427 ]
      ]
    },
    "F": {
      "5": [
        [ 2, 10.2102741 ],   [ 3, 11.65541879 ],
        [ 4, 13.09012012 ],  [ 5, 14.70923661 ],
        [ 6, 16.43608019 ],  [ 7, 18.2280462 ],
        [ 8, 20.14535023 ],  [ 9, 22.31677169 ],
        [ 10, 24.86516468 ], [ 11, 27.83564104 ],
        [ 12, 31.15093935 ], [ 13, 34.60715224 ],
        [ 14, 37.91341628 ], [ 15, 40.76838955 ],
        [ 16, 42.95649273 ], [ 17, 44.43550259 ],
        [ 18, 45.36020233 ], [ 19, 45.97009344 ],
        [ 20, 46.28963394 ]
      ],
      "10": [
        [ 2, 10.57373483 ],  [ 3, 12.0925858 ],
        [ 4, 13.61116044 ],  [ 5, 15.31758235 ],
        [ 6, 17.14326985 ],  [ 7, 19.06300261 ],
        [ 8, 21.15110555 ],  [ 9, 23.53812979 ],
        [ 10, 26.33131782 ], [ 11, 29.5432056 ],
        [ 12, 33.05496144 ], [ 13, 36.62688403 ],
        [ 14, 39.95466626 ], [ 15, 42.7562665 ],
        [ 16, 44.86430165 ], [ 17, 46.29381699 ],
        [ 18, 47.24157543 ], [ 19, 47.9528045 ],
        [ 20, 48.38346004 ]
      ],
      "25": [
        [ 2, 11.23356786 ],  [ 3, 12.90221529 ],
        [ 4, 14.59093307 ],  [ 5, 16.47470852 ],
        [ 6, 18.49904125 ],  [ 7, 20.67168066 ],
        [ 8, 23.09445998 ],  [ 9, 25.90166889 ],
        [ 10, 29.17072015 ], [ 11, 32.85229967 ],
        [ 12, 36.7495031 ],  [ 13, 40.55564134 ],
        [ 14, 43.94012186 ], [ 15, 46.65445329 ],
        [ 16, 48.6196779 ],  [ 17, 49.95916191 ],
        [ 18, 50.95014153 ], [ 19, 51.84720202 ],
        [ 20, 52.47876433 ]
      ],
      "50": [
        [ 2, 12.05503983 ],  [ 3, 13.94108332 ],
        [ 4, 15.87823668 ],  [ 5, 18.02313904 ],
        [ 6, 20.33635961 ],  [ 7, 22.86804258 ],
        [ 8, 25.7570168 ],   [ 9, 29.14291171 ],
        [ 10, 33.06392318 ], [ 11, 37.39088668 ],
        [ 12, 41.82797963 ], [ 13, 45.98368656 ],
        [ 14, 49.49075409 ], [ 15, 52.13568193 ],
        [ 16, 53.94543725 ], [ 17, 55.18216811 ],
        [ 18, 56.22969564 ], [ 19, 57.35175792 ],
        [ 20, 58.21897289 ]
      ],
      "75": [
        [ 2, 12.98666951 ],  [ 3, 15.16452247 ],
        [ 4, 17.44149184 ],  [ 5, 19.95048336 ],
        [ 6, 22.66294138 ],  [ 7, 25.67549032 ],
        [ 8, 29.17045929 ],  [ 9, 33.29393074 ],
        [ 10, 38.03877869 ], [ 11, 43.18654635 ],
        [ 12, 48.33395608 ], [ 13, 52.99745045 ],
        [ 14, 56.76423337 ], [ 15, 59.44404265 ],
        [ 16, 61.16240788 ], [ 17, 62.32761268 ],
        [ 18, 63.44319934 ], [ 19, 64.77837954 ],
        [ 20, 65.85237979 ]
      ],
      "90": [
        [ 2, 13.93766409 ],  [ 3, 16.46788956 ],
        [ 4, 19.16857713 ],  [ 5, 22.14604136 ],
        [ 6, 25.3714519 ],   [ 7, 28.97979428 ],
        [ 8, 33.19435025 ],  [ 9, 38.16762389 ],
        [ 10, 43.85001322 ], [ 11, 49.94279684 ],
        [ 12, 55.9504751 ],  [ 13, 61.31281247 ],
        [ 14, 65.57527484 ], [ 15, 68.5487709 ],
        [ 16, 70.40498554 ], [ 17, 71.63707172 ],
        [ 18, 72.82563495 ], [ 19, 74.23743894 ],
        [ 20, 75.35164989 ]
      ],
      "95": [
        [ 2, 14.56636479 ],  [ 3, 17.3624437 ],
        [ 4, 20.3940868 ],   [ 5, 23.75023626 ],
        [ 6, 27.39274198 ],  [ 7, 31.47048848 ],
        [ 8, 36.2269949 ],   [ 9, 41.8188452 ],
        [ 10, 48.17500585 ], [ 11, 54.95703466 ],
        [ 12, 61.62701045 ], [ 13, 67.59447719 ],
        [ 14, 72.39274832 ], [ 15, 75.82804673 ],
        [ 16, 78.05210841 ], [ 17, 79.51506292 ],
        [ 18, 80.75172168 ], [ 19, 82.02139464 ],
        [ 20, 82.95375457 ]
      ]
    }
  }

  export let bmiage = {
      "M": {
        "5": [
          [ 2, 14.73731947 ],  [ 3, 14.32851119 ],
          [ 4, 14.03295533 ],  [ 5, 13.83854804 ],
          [ 6, 13.73623987 ],  [ 7, 13.72112886 ],
          [ 8, 13.79574779 ],  [ 9, 13.9621189 ],
          [ 10, 14.21865508 ], [ 11, 14.56000917 ],
          [ 12, 14.97744768 ], [ 13, 15.4591825 ],
          [ 14, 15.99065148 ], [ 15, 16.55481067 ],
          [ 16, 17.13249925 ], [ 17, 17.70284417 ],
          [ 18, 18.24348662 ], [ 19, 18.73019025 ],
          [ 20, 19.12055111 ]
        ],
        "10": [
          [ 2, 15.09032827 ],  [ 3, 14.66086088 ],
          [ 4, 14.34695084 ],  [ 5, 14.13780261 ],
          [ 6, 14.03772376 ],  [ 7, 14.0421586 ],
          [ 8, 14.14712305 ],  [ 9, 14.3490301 ],
          [ 10, 14.64260311 ], [ 11, 15.02022206 ],
          [ 12, 15.47186716 ], [ 13, 15.98519864 ],
          [ 14, 16.54567574 ], [ 15, 17.13669551 ],
          [ 16, 17.73973524 ], [ 17, 18.3344371 ],
          [ 18, 18.89854373 ], [ 19, 19.40765581 ],
          [ 20, 19.81910123 ]
        ],
        "25": [
          [ 2, 15.74164233 ],  [ 3, 15.26015988 ],
          [ 4, 14.91693911 ],  [ 5, 14.69503764 ],
          [ 6, 14.61119682 ],  [ 7, 14.66082372 ],
          [ 8, 14.82964685 ],  [ 9, 15.10432556 ],
          [ 10, 15.47270195 ], [ 11, 15.92267985 ],
          [ 12, 16.44157847 ], [ 13, 17.01583373 ],
          [ 14, 17.63085916 ], [ 15, 18.27093434 ],
          [ 16, 18.91900132 ], [ 17, 19.55629056 ],
          [ 18, 20.16189615 ], [ 19, 20.71283095 ],
          [ 20, 21.16744563 ]
        ],
        "50": [
          [ 2, 16.57502768 ],  [ 3, 16.00030401 ],
          [ 4, 15.62817269 ],  [ 5, 15.41914163 ],
          [ 6, 15.38353217 ],  [ 7, 15.51286936 ],
          [ 8, 15.78231007 ],  [ 9, 16.16712234 ],
          [ 10, 16.64613844 ], [ 11, 17.20088732 ],
          [ 12, 17.81463359 ], [ 13, 18.47179706 ],
          [ 14, 19.15758672 ], [ 15, 19.85766121 ],
          [ 16, 20.5576474 ],  [ 17, 21.24247982 ],
          [ 18, 21.8958685 ],  [ 19, 22.50071778 ],
          [ 20, 23.02029424 ]
        ],
        "75": [
          [ 2, 17.55718781 ],  [ 3, 16.8337599 ],
          [ 4, 16.43969657 ],  [ 5, 16.29148397 ],
          [ 6, 16.36346484 ],  [ 7, 16.6311172 ],
          [ 8, 17.05798645 ],  [ 9, 17.60682848 ],
          [ 10, 18.24521166 ], [ 11, 18.94588267 ],
          [ 12, 19.68614286 ], [ 13, 20.44731409 ],
          [ 14, 21.21432619 ], [ 15, 21.97531708 ],
          [ 16, 22.72114803 ], [ 17, 23.44489706 ],
          [ 18, 24.14165845 ], [ 19, 24.80918852 ],
          [ 20, 25.4235294 ]
        ],
        "90": [
          [ 2, 18.60948128 ],  [ 3, 17.68022325 ],
          [ 4, 17.27660684 ],  [ 5, 17.25343909 ],
          [ 6, 17.52335144 ],  [ 7, 18.02183493 ],
          [ 8, 18.69225117 ],  [ 9, 19.48149248 ],
          [ 10, 20.34269534 ], [ 11, 21.23678716 ],
          [ 12, 22.13290433 ], [ 13, 23.0084239 ],
          [ 14, 23.84886673 ], [ 15, 24.64777949 ],
          [ 16, 25.40667527 ], [ 17, 26.13514709 ],
          [ 18, 26.85119991 ], [ 19, 27.58158516 ],
          [ 20, 28.32769753 ]
        ],
        "95": [
          [ 2, 19.33801062 ],  [ 3, 18.23841666 ],
          [ 4, 17.8361423 ],   [ 5, 17.93892524 ],
          [ 6, 18.41421242 ],  [ 7, 19.15236073 ],
          [ 8, 20.06792538 ],  [ 9, 21.08892746 ],
          [ 10, 22.15409238 ], [ 11, 23.21358351 ],
          [ 12, 24.22984982 ], [ 13, 25.17811163 ],
          [ 14, 26.04662217 ], [ 15, 26.83688026 ],
          [ 16, 27.56392793 ], [ 17, 28.25675709 ],
          [ 18, 28.9586172 ],  [ 19, 29.72674204 ],
          [ 20, 30.58964285 ]
        ]
      },
      "F": {
        "5": [
          [ 2, 14.39787089 ],  [ 3, 14.00209476 ],
          [ 4, 13.71472021 ],  [ 5, 13.52091027 ],
          [ 6, 13.42587042 ],  [ 7, 13.43276016 ],
          [ 8, 13.54050176 ],  [ 9, 13.74412656 ],
          [ 10, 14.03535405 ], [ 11, 14.40290497 ],
          [ 12, 14.83262428 ], [ 13, 15.30748565 ],
          [ 14, 15.80752854 ], [ 15, 16.30973602 ],
          [ 16, 16.78787442 ], [ 17, 17.21234472 ],
          [ 18, 17.55015468 ], [ 19, 17.76515018 ],
          [ 20, 17.82009046 ]
        ],
        "10": [
          [ 2, 14.80134054 ],  [ 3, 14.32806133 ],
          [ 4, 14.00895279 ],  [ 5, 13.81725538 ],
          [ 6, 13.74693776 ],  [ 7, 13.79190343 ],
          [ 8, 13.9444489 ],   [ 9, 14.19478013 ],
          [ 10, 14.5310003 ],  [ 11, 14.93913036 ],
          [ 12, 15.40310619 ], [ 13, 15.90475685 ],
          [ 14, 16.42377582 ], [ 15, 16.93767383 ],
          [ 16, 17.4217057 ],  [ 17, 17.8487761 ],
          [ 18, 18.18936927 ], [ 19, 18.41158663 ],
          [ 20, 18.48195582 ]
        ],
        "25": [
          [ 2, 15.52807587 ],  [ 3, 14.92950329 ],
          [ 4, 14.56344665 ],  [ 5, 14.38345148 ],
          [ 6, 14.36552155 ],  [ 7, 14.48764859 ],
          [ 8, 14.7300509 ],   [ 9, 15.0737831 ],
          [ 10, 15.49992271 ], [ 11, 15.98919146 ],
          [ 12, 16.52179463 ], [ 13, 17.07738309 ],
          [ 14, 17.63508894 ], [ 15, 18.17359792 ],
          [ 16, 18.67121316 ], [ 17, 19.10585083 ],
          [ 18, 19.45490996 ], [ 19, 19.69500438 ],
          [ 20, 19.80017572 ]
        ],
        "50": [
          [ 2, 16.42339664 ],  [ 3, 15.69924188 ],
          [ 4, 15.29854897 ],  [ 5, 15.15188405 ],
          [ 6, 15.21690296 ],  [ 7, 15.45356545 ],
          [ 8, 15.82699517 ],  [ 9, 16.30609316 ],
          [ 10, 16.86231366 ], [ 11, 17.46906585 ],
          [ 12, 18.10148804 ], [ 13, 18.73643338 ],
          [ 14, 19.35257209 ], [ 15, 19.9305662 ],
          [ 16, 20.45325617 ], [ 17, 20.90575839 ],
          [ 18, 21.27532239 ], [ 19, 21.55082155 ],
          [ 20, 21.71699934 ]
        ],
        "75": [
          [ 2, 17.42745659 ],  [ 3, 16.60686761 ],
          [ 4, 16.20987583 ],  [ 5, 16.13843423 ],
          [ 6, 16.33273474 ],  [ 7, 16.73461785 ],
          [ 8, 17.29205828 ],  [ 9, 17.95912092 ],
          [ 10, 18.69520235 ], [ 11, 19.46462028 ],
          [ 12, 20.23648228 ], [ 13, 20.98471803 ],
          [ 14, 21.68819441 ], [ 15, 22.33090272 ],
          [ 16, 22.90219024 ], [ 17, 23.39695789 ],
          [ 18, 23.81564452 ], [ 19, 24.16377555 ],
          [ 20, 24.44009565 ]
        ],
        "90": [
          [ 2, 18.44139317 ],  [ 3, 17.5801877 ],
          [ 4, 17.25416922 ],  [ 5, 17.32657196 ],
          [ 6, 17.7167845 ],   [ 7, 18.34873428 ],
          [ 8, 19.15261918 ],  [ 9, 20.06646519 ],
          [ 10, 21.03668675 ], [ 11, 22.01828633 ],
          [ 12, 22.97492622 ], [ 13, 23.87895414 ],
          [ 14, 24.71140708 ], [ 15, 25.46204417 ],
          [ 16, 26.12945136 ], [ 17, 26.7212455 ],
          [ 18, 27.25432769 ], [ 19, 27.75501954 ],
          [ 20, 28.23727135 ]
        ],
        "95": [
          [ 2, 19.10623522 ],  [ 3, 18.25475381 ],
          [ 4, 18.02851473 ],  [ 5, 18.25738105 ],
          [ 6, 18.83777954 ],  [ 7, 19.67793583 ],
          [ 8, 20.69525186 ],  [ 9, 21.8172543 ],
          [ 10, 22.98257733 ], [ 11, 24.14141407 ],
          [ 12, 25.25564304 ], [ 13, 26.298799 ],
          [ 14, 27.25597392 ], [ 15, 28.12369169 ],
          [ 16, 28.90980533 ], [ 17, 29.63350259 ],
          [ 18, 30.32554216 ], [ 19, 31.02880054 ],
          [ 20, 31.76474311 ]
        ]
      }
  }

}
